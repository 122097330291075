import { RouteKeys } from '@/router/types'

export default {
  path: RouteKeys.structure,
  name: RouteKeys.structure,
  redirect: { name: `${RouteKeys.structure}-tree` },
  meta: { name: 'Структура', icon: 'block-company-structure' },
  component: () => import('@/views/Company/Structure/Index.vue'),
  children: [
    {
      path: 'tree',
      name: `${RouteKeys.structure}-tree`,
      meta: { name: 'Дерево подразделений', icon: 'block-company-structure', hideInMenu: true },
      component: () => import('@/views/Company/Structure/StructureTree/StructureTree.vue')
    },
    {
      path: 'list',
      name: `${RouteKeys.structure}-list`,
      meta: { name: 'Список подразделений', icon: 'block-company-structure', hideInMenu: true },
      component: () => import('@/views/Company/Structure/List.vue')
    },
    {
      path: ':id/edit',
      name: `${RouteKeys.structure}-edit`,
      meta: { name: 'Редактирование подразделения', icon: 'block-company-structure', hideInMenu: true },
      component: () => import('@/views/Company/Structure/Edit.vue'),
      props: (route:any) => ({ id: route.params.id })
    },
    {
      path: 'create',
      name: `${RouteKeys.structure}-create`,
      meta: { name: 'Добавление подразделения', icon: 'block-company-structure', hideInMenu: true },
      component: () => import('@/views/Company/Structure/Edit.vue'),
      props: (route:any) => ({ id: route.params.id })
    }
  ]
}
