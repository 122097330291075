<template>
  <div
    v-cloak
    v-if="info"
    class="tw-flex tw-gap-[12px] tw-flex-col tw-font-manrope tw-text-main-sm"
  >
    <TwPanelContentBlockFieldView
      no-data-text="Не указано"
    >
      <template #title>
        Ежемесячный доход
      </template>
      <div v-if="info.salaryMonth">
        {{ (info.salaryMonth || 0).toLocaleString() }} руб.
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView
      no-data-text="Не указано"
    >
      <template #title>
        Общий стаж
      </template>
      <div v-if="info.experience">
        {{ $Global.calculateExperience(info.experience) }} (с {{ $Global.DateFormat.DateShort(info.experience) }})
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView
      no-data-text="Не указано"
    >
      <template #title>
        Стаж на последнем месте работы
      </template>
      <div v-if="info.experienceLast">
        {{ $Global.calculateExperience(info.experienceLast) }} (с {{ $Global.DateFormat.DateShort(info.experienceLast) }})
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView
      no-data-text="Не указано"
    >
      <template #title>
        Способ подтверждения дохода
      </template>
      <div v-if="info.confirmationMethod">
        {{ info.confirmationMethod && info.confirmationMethod.label }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView
      :horizontal="false"
      no-data-text="Не указано"
    >
      <template #title>
        Зарплатный проект
      </template>
      <div
        v-if="info.salaryProjectCard || info.salaryProjectCompany"
        class="tw-ml-[15px] tw-flex tw-flex-col tw-gap-[8px]"
      >
        <div
          v-if="info.salaryProjectCompany"
          class="tw-flex tw-gap-[8px]"
        >
          <span class="tw-text-an-flat-gray-4">Банк</span>
          <div>
            <ViewPanelLink
              :id="info.salaryProjectCompany.id"
              label="Компания"
              component="company-show"
            >
              {{ info.salaryProjectCompany.name }}
            </ViewPanelLink>
          </div>
        </div>
        <div
          v-if="info.salaryProjectCard"
          class="tw-flex tw-gap-[8px]"
        >
          <span class="tw-text-an-flat-gray-4">
            Номер зарплатной карты
          </span>
          <div>
            {{ info.salaryProjectCard }}
          </div>
        </div>
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView
      :horizontal="false"
      no-data-text="Не указано"
    >
      <template #title>
        Основное место работы
      </template>
      <div
        v-if="mainCompanyShow"
        class="tw-ml-[15px] tw-flex tw-flex-col tw-gap-[8px]"
      >
        <div class="tw-flex tw-gap-[8px]">
          <span class="tw-text-an-flat-gray-4">Название</span>
          <div>
            {{ info.mainCompanyName || 'Нет' }}
          </div>
        </div>
        <div class="tw-flex tw-gap-[8px]">
          <span class="tw-text-an-flat-gray-4">
            Телефон
          </span>
          <div>
            <SipCallLink
              v-if="info.mainCompanyPhone"
              :phone="info.mainCompanyPhone"
            />
            <div v-else>
              Нет
            </div>
          </div>
        </div>
        <div class="tw-flex tw-gap-[8px]">
          <span class="tw-text-an-flat-gray-4">
            ИНН
          </span>
          <div>
            {{ info.mainCompanyInn || 'Нет' }}
          </div>
        </div>
        <div class="tw-flex tw-gap-[8px]">
          <span class="tw-text-an-flat-gray-4">
            Адрес
          </span>
          <div>
            {{ info.mainCompanyAddress || 'Нет' }}
          </div>
        </div>
        <div class="tw-flex tw-gap-[8px]">
          <span class="tw-text-an-flat-gray-4">
            Сфера деятельности
          </span>
          <div>
            {{ info.mainCompanyDirection || 'Нет' }}
          </div>
        </div>
        <div class="tw-flex tw-gap-[8px]">
          <span class="tw-text-an-flat-gray-4">
            Должность
          </span>
          <div>
            {{ info.mainCompanyPosition || 'Нет' }}
          </div>
        </div>
        <div class="tw-flex tw-gap-[8px]">
          <span class="tw-text-an-flat-gray-4">
            Веб сайт
          </span>
          <div>
            {{ info.mainCompanySite || 'Нет' }}
          </div>
        </div>
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView
      no-data-text="Не указано"
    >
      <template #title>
        Сумма дополнительного дохода
      </template>
      <div>
        {{ info.additionalSalarySum && info.additionalSalarySum > 0 ? `${info.additionalSalarySum.toLocaleString()} руб.` : 'Нет' }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView
      no-data-text="Не указано"
      :horizontal="false"
    >
      <template #title>
        Дополнительное место работы
      </template>
      <div
        v-if="additionalCompanyShow"
        class="tw-ml-[15px] tw-flex tw-flex-col tw-gap-[8px]"
      >
        <div class="tw-flex tw-gap-[8px]">
          <span class="tw-text-an-flat-gray-4">Название</span>
          <div>
            {{ info.additionalCompanyName || 'Нет' }}
          </div>
        </div>
        <div class="tw-flex tw-gap-[8px]">
          <span class="tw-text-an-flat-gray-4">
            Телефон
          </span>
          <div>
            <SipCallLink
              v-if="info.additionalCompanyPhone"
              :phone="info.additionalCompanyPhone"
            />
            <div v-else>
              Нет
            </div>
          </div>
        </div>
        <div class="tw-flex tw-gap-[8px]">
          <span class="tw-text-an-flat-gray-4">
            ИНН
          </span>
          <div>
            {{ info.additionalCompanyInn || 'Нет' }}
          </div>
        </div>
        <div class="tw-flex tw-gap-[8px]">
          <span class="tw-text-an-flat-gray-4">
            Адрес
          </span>
          <div>
            {{ info.additionalCompanyAddress || 'Нет' }}
          </div>
        </div>
        <div class="tw-flex tw-gap-[8px]">
          <span class="tw-text-an-flat-gray-4">
            Сфера деятельности
          </span>
          <div>
            {{ info.additionalCompanyDirection || 'Нет' }}
          </div>
        </div>
        <div class="tw-flex tw-gap-[8px]">
          <span class="tw-text-an-flat-gray-4">
            Должность
          </span>
          <div>
            {{ info.additionalCompanyPosition || 'Нет' }}
          </div>
        </div>
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView
      no-data-text="Не указано"
    >
      <template #title>
        Есть накопления
      </template>
      <div v-if="info.experienceLast">
        {{ info.accumulation ? 'Да' : 'Нет' }}
      </div>
    </TwPanelContentBlockFieldView>
  </div>
  <div
    v-else
    class="tw-text-an-flat-disabled-text tw-text-center"
  >
    Нет данных
  </div>
</template>

<script>
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink.vue'
import SipCallLink from '@/components/telephony/SipCallLink.vue'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'

export default {
  name: 'PersonSalaryShow',
  components: { SipCallLink, ViewPanelLink, TwPanelContentBlockFieldView },
  props: {
    info: {},
    dictionary: {}
  },
  computed: {
    mainCompanyShow () {
      return this.info && (
        this.info.mainCompanyName ||
        this.info.mainCompanyInn ||
        this.info.mainCompanyAddress ||
        this.info.mainCompanyAddress ||
        this.info.mainCompanyDirection ||
        this.info.mainCompanyPhone ||
        this.info.mainCompanySite ||
        this.info.mainCompanyPosition)
    },
    additionalCompanyShow () {
      return this.info && (
        this.info.additionalCompanyName ||
        this.info.additionalCompanyInn ||
        this.info.additionalCompanyAddress ||
        this.info.additionalCompanyAddress ||
        this.info.additionalCompanyDirection ||
        this.info.additionalCompanyPhone ||
        this.info.additionalCompanyPosition)
    }
  }
}
</script>
