<template>
  <div
    v-click-outside="clickOutSide"
    :class="[sizeClasses, disabled ? 'tw-pointer-events-none':'']"
    class="tw-relative tw-text-an-flat-black-main tw-select-none tw-font-manrope tw-font-medium tw-leading-none tw-block"
  >
    <div
      ref="trigger"
      role="button"
      :class="[sizeClasses, visibleList ? 'tw-drop-shadow-input' : '', disabled ? 'tw-pointer-events-none tw-bg-an-flat-disabled-input-bg tw-text-an-flat-disabled-input-text':'tw-bg-white']"
      class="tw-z-0 tw-flex tw-gap-[10px] tw-items-center tw-justify-between tw-p-[20px] tw-border tw-border-solid tw-rounded-sm hover:tw-drop-shadow-input"
      @click.stop="show"
    >
      <div class="tw-w-full tw-flex tw-flex-wrap tw-gap-[12px]">
        <div
          v-if="tagCount"
          class="tw-flex tw-flex-wrap tw-gap-[7px]"
        >
          <template v-for="(sItem, sIndex) in userTagList">
            <div
              :key="`${sItem.id}_${sIndex}`"
              class="tw-group/badge tw-relative tw-cursor-default tw-leading-none tw-flex tw-gap-1 tw-truncate tw-text-an-flat-dark-blue-link tw-bg-an-flat-dark-blue-link tw-bg-opacity-10 tw-rounded-xs tw-px-[7px] tw-py-[5px]"
            >
              <div class="tw-truncate tw-pr-[12px]">
                <b>#</b>{{ sItem.tag }}
              </div>
              <button
                v-if="!disabled"
                title="Удалить"
                class="tw-absolute tw-top-[6px] tw-right-[3px] tw-rounded-full tw-hidden group-hover/badge:tw-block"
                @click.stop="onDeleteTag(sItem.id)"
              >
                <svg
                  width="14"
                  height="13"
                  viewBox="0 0 14 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    class="tw-fill-an-flat-red-main"
                    d="M7 0.375C3.62262 0.375 0.875 3.12262 0.875 6.5C0.875 9.87738 3.62262 12.625 7 12.625C10.3774 12.625 13.125 9.87738 13.125 6.5C13.125 3.12262 10.3774 0.375 7 0.375ZM9.49683 8.37817C9.66772 8.54907 9.66772 8.82593 9.49683 8.99683C9.41138 9.08228 9.29944 9.125 9.1875 9.125C9.07556 9.125 8.96362 9.08228 8.87817 8.99683L7 7.11865L5.12183 8.99683C5.03638 9.08228 4.92444 9.125 4.8125 9.125C4.70056 9.125 4.58862 9.08228 4.50317 8.99683C4.33228 8.82593 4.33228 8.54907 4.50317 8.37817L6.38135 6.5L4.50317 4.62183C4.33228 4.45093 4.33228 4.17407 4.50317 4.00317C4.67407 3.83228 4.95093 3.83228 5.12183 4.00317L7 5.88135L8.87817 4.00317C9.04907 3.83228 9.32593 3.83228 9.49683 4.00317C9.66772 4.17407 9.66772 4.45093 9.49683 4.62183L7.61865 6.5L9.49683 8.37817Z"
                    fill="#2D82D0"
                  />
                </svg>
              </button>
            </div>
          </template>
        </div>
        <div class="tw-flex tw-flex-1 tw-gap-[6px] tw-max-w-full">
          <div class="tw-text-an-flat-blue-dark-1 tw-leading-[25px]">
            #
          </div>
          <input
            ref="input"
            v-model="searchString"
            placeholder="Введите тег"
            class="tw-h-auto tw-flex-1 tw-outline-none tw-border-none"
            :readonly="searchProcess"
            :disabled="changeProcess"
            @keyup.enter="onEnter"
            @keyup.esc="onReset"
            @focusin.stop="focusedInput = true"
            @focusout="focusedInput = false"
          >
          <div class="tw-min-w-[20px] tw-h-auto">
            <TwCircleLoader
              v-if="searchProcess"
              width="20px"
              height="20px"
              border-width="1px"
            />
            <button
              v-else-if="searchString && !tagList.length"
              class="tw-font-semibold tw-h-full tw-text-main-xs2 tw-text-an-flat-green-btn-bg tw-border tw-border-solid tw-border-an-flat-green-btn-bg tw-px-[7px] tw-py-[3px] tw-rounded-sm hover:tw-bg-an-flat-green-btn-bg hover:tw-text-white"
              @click.stop="onEnter"
            >
              ДОБАВИТЬ
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      v-show="visibleList"
      ref="tooltip"
      class="tw-absolute tw-z-[1000] tw-w-full tw-bg-white tw-border tw-border-solid tw-rounded-sm tw-drop-shadow-input"
    >
      <div
        role="listbox"
        class="tw-p-[23px]"
      >
        <div
          v-if="searchProcess || changeProcess"
          class="tw-w-full tw-flex tw-justify-center"
        >
          <TwCircleLoader
            width="20px"
            height="20px"
            border-width="2px"
          />
        </div>
        <div class="tw-w-full tw-flex tw-gap-[12px] tw-flex-wrap">
          <template v-if="Array.isArray(tagList) && tagList.length">
            <button
              v-for="(item, index) in tagList"
              :key="`${item.id}_${index}`"
              title="Добавить тег"
              class="tw-flex tw-gap-[7px] tw-items-center tw-justify-center tw-bg-an-flat-gray-light tw-py-[7px] tw-px-[10px] tw-rounded-[50px] hover:tw-bg-an-flat-green-light"
              @click="onSelectItem(item.tag)"
            >
              <div>
                # {{ item.tag }}
              </div>
              <div
                v-if="Array.isArray(item.users) && item.users.length"
                class="tw-relative tw-min-w-[30px] tw-w-[30px]"
              >
                <img
                  v-for="(user, uIndex) in item.users"
                  :key="`${item.id}_${user.id}`"
                  :alt="user.name"
                  :style="{ right: `${uIndex * 10}px`, zIndex: uIndex }"
                  class="tw-absolute -tw-top-[10px] tw-min-w-[20px] tw-w-[20px] tw-min-h-[20px] tw-h-[20px] tw-rounded-full tw-border tw-border-solid tw-border-white"
                  :src="employeeAvatarListGetLink(user.file)"
                >
              </div>
            </button>
          </template>
          <div
            v-else
            class="tw-text-center tw-w-full"
          >
            Теги не найдены, нажмите <b class="tw-text-an-flat-dark-blue-link">Enter</b> для добавления тега
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/Api/Auth/User/tag'
import { employeeAvatarListGetLink } from '@/views/Company/Employee/employeeHelpers'
import { createPopper } from '@popperjs/core'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import lodashDebounce from 'lodash/debounce'

export default {
  name: 'TwUserTagInput',
  components: { TwCircleLoader },
  props: {
    userId: {
      type: [String, Number],
      default: null
    },
    // сжатые поля
    dense: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      popperInstance: null,
      focusedInput: false,
      visibleList: false,
      searchProcess: false,
      changeProcess: false,
      searchString: null,
      tagList: [],
      userTagList: []
    }
  },
  methods: {
    onEnter () {
      console.warn('onEnter', this.searchString)
      this.onSelectItem(this.searchString)
    },
    onReset () {
      console.warn('onEsc', this.searchString)
      this.searchString = ''
      this.hide()
      this.getUserTags()
    },
    employeeAvatarListGetLink,
    show () {
      console.warn('ON SHOW')
      if (!this.visibleList) {
        this.fetchTagListList()
      }
      this.visibleList = true
      this.$refs.input.focus()
      this.popperInstance.update()
    },
    hide () {
      this.searchString = ''
      this.visibleList = false
      this.$refs.input.blur()
    },
    clickOutSide () {
      if (this.visibleList) {
        this.hide()
      }
    },
    getUserTags () {
      this.fetchTagListList({ userId: this.userId, q: '' })
    },
    searchTags: lodashDebounce(function (params) {
      console.warn('searchTags ', params)
      this.fetchTagListList(params)
    }, 500),
    fetchTagListList (params = {}) {
      console.warn('fetchTagListList', params)
      this.searchProcess = true
      API.getList({ ...params, limit: 500 })
        .then(({ data: result }) => {
          console.log('onSearch', result)
          if (params?.userId) {
            this.userTagList = result?.data || []
          } else {
            this.tagList = result?.data || []
          }
          this.tagList = this.tagList.filter(item => {
            return !this.userTagList.some(uItem => item.id === uItem.id)
          })
        })
        .catch((error) => {
          console.error('onSearch error', error)
        }).finally(() => {
          this.searchProcess = false
          this.popperInstance.update()
        })
    },
    onSelectItem (tag) {
      console.warn('onSelectItem', this.userId, tag)
      if (this.changeProcess) {
        return
      }
      // TODO send data
      this.changeProcess = true
      API.store({ userId: this.userId, tag: tag })
        .then(({ data: result }) => {
          console.log('onSearch', result)
          this.getUserTags()
          this.searchString = ''
        })
        .catch((error) => {
          console.error('onSearch error', error)
        }).finally(() => {
          this.changeProcess = false
          this.popperInstance.update()
        })
    },
    onDeleteTag (id) {
      if (this.changeProcess) {
        return
      }
      console.warn('onDeleteTag', this.userId, id)
      // TODO send data
      this.changeProcess = true
      API.delete(id, { userId: this.userId, _method: 'DELETE' })
        .then(({ data: result }) => {
          console.log('onSearch', result)
          this.onReset()
        })
        .catch((error) => {
          console.error('onSearch error', error)
        }).finally(() => {
          this.changeProcess = false
          this.popperInstance.update()
        })
    }
  },
  computed: {
    sizeClasses () {
      if (this.dense) {
        return 'tw-min-h-[40px] tw-text-main-sm tw-min-w-[200px]'
      } else {
        return 'tw-min-h-[52px] tw-text-main-sm tw-min-w-[200px]'
      }
    },
    tagCount () {
      // TODO count items
      return this.userTagList?.length || 0
    }
  },
  watch: {
    searchString (newValue) {
      this.searchTags({ q: newValue })
    }
  },
  mounted () {
    this.getUserTags()
    this.popperInstance = createPopper(this.$refs.trigger, this.$refs.tooltip, {
      // strategy: 'fixed',
      placement: 'bottom',
      rootBoundary: this.$refs.input,
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 5]
          }
        }
      ]
    })
  }
}
</script>
