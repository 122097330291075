import { mapMutations, mapState } from 'vuex'
import HTTP from '@/Api/http'

export default {
  data () {
    return {
      loadingVersion: false,
      actualVersion: null,
      pending: false,
      registration: null
    }
  },
  methods: {
    ...mapMutations({
      setUpdates: 'setUpdates'
    }),
    // Покаказать плашку с обновлением
    updateAvailable (event) {
      console.warn('DEBUG UPDATE MIXIN - updateAvailable - registration', event.detail)
      this.setUpdates({
        exist: true,
        uiVisible: true
      })
      this.fetchVersion()
    },
    // Добавить информацию о регистрации сервис воркера
    addSwRegistration (event) {
      console.warn('DEBUG UPDATE MIXIN - addSwRegistration', event.detail)
      this.$set(this, 'registration', event.detail)
    },
    // Вызываем обновление
    refreshApp () {
      console.warn('DEBUG UPDATE MIXIN - refreshApp', this.registration)
      this.pending = true
      // вешаем обработчик изменения состояния
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        console.warn('DEBUG UPDATE MIXIN - refreshApp controllerchange')
        this.pending = false
        window.location.reload()
      }, { once: true })

      // пропускаем ожидание
      if (this.registration && this.registration.waiting) {
        this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
      }
      // если через 3 секунды обновление не произошло
      // принудительно ребутаю вкладку
      setTimeout(() => {
        this.pending = false
        window.location.reload()
      }, 5000)
    },
    onCancelUpdate () {
      console.warn('onCancelUpdate')
      this.setUpdates({
        exist: true,
        uiVisible: false
      })
    },
    fetchVersion () {
      this.loadingVersion = true
      HTTP.get(`${location.origin}/version.json?timestamp=${new Date().getTime()}`)
        .then(({ data }) => {
          console.warn('fetchVersion', data)
          this.actualVersion = data.version
        })
        .catch((error) => {
          console.error('fetchVersion', this.$options.name, error)
        })
        .finally(() => {
          this.loadingVersion = false
        })
    }
  },
  computed: {
    ...mapState({
      currentVersion: 'version',
      updatesInfo: 'updates'
    })
  },
  created () {
    // Слушатели событий из сервис воркера
    document.addEventListener('swRegistered', this.addSwRegistration, { once: false })
    document.addEventListener('swUpdated', this.updateAvailable, { once: false })

    if (navigator?.serviceWorker) {
      navigator.serviceWorker.addEventListener('updatefound', () => {
        console.log('Service Worker update detected!')
      })
    }
    this.fetchVersion()
  }
}
