<template>
  <div class="tw-flex tw-flex-row tw-gap-[25px]">
    <div title="Ставка Банк">
      {{ rate }}<Percent />
    </div>
    <div
      v-if="rateFlat && rateFlat !== rate"
      title="Ставка Флэт"
      class="center"
    >
      {{ rateFlat }}<Percent />
    </div>
    <div
      v-else
      class="center"
    >
      -
    </div>
    <div
      v-if="rateElectronRegistration && rateElectronRegistration !== rateFlat"
      title="Ставка Эл.рег."
    >
      {{ rateElectronRegistration }}<Percent />
    </div>
    <div v-else>
      -
    </div>
  </div>
</template>

<script>
import Percent from '@/components/symbol/Percent'
export default {
  name: 'ProgSelectorCellRatesInline',
  components: { Percent },
  props: ['rate', 'rateFlat', 'rateElectronRegistration']
}
</script>
