import { AxiosResponse } from 'axios'
import HTTP from '@/Api/http'

const path = '/interface-settings/table-view'

export default {
  store (data: any): Promise<AxiosResponse> {
    return HTTP.post(`${path}`, data)
  },
  show (code: string): Promise<AxiosResponse> {
    const $config = {}
    return HTTP.get(`${path}/${code}`, $config)
  },
  delete (code: string): Promise<AxiosResponse> {
    const $config = {}
    return HTTP.delete(`${path}/${code}`, $config)
  }
}
