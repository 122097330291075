<template>
  <div>
    <TwPanelContentBlock>
      <template #title>
        API запрос в банк:
      </template>
      <template #action>
        <button
          v-if="send2BankAllowType === 'alfa'"
          class="tw-text-an-flat-black-table"
          :disabled="!info || process"
          @click="edit = !edit"
        >
          {{ edit ? 'отмена' : info ? 'Изменить' : 'Создать запрос' }}
        </button>
        <button
          v-else-if="send2BankAllowType === 'domclick'"
          class="tw-text-an-flat-black-table"
          :disabled="!info || process"
          @click="showDomClickWorksheetCard({
            dealProgramId: dealProgram.id,
            id: info.requestId
          }, refresh)"
        >
          {{ info && info.id ? 'Открыть заявку' : 'Создать заявку' }}
        </button>
      </template>
      <template #default>
        <div v-if="!process">
          <!--   АЛЬФАБАНК   -->
          <div v-if="send2BankAllowType === 'alfa'">
            <DealProgram2BankShow
              v-if="!edit"
              :info="info"
              @refresh="refresh"
            />
            <DealProgram2BankForm
              v-else
              v-bind="$props"
              :id="info ? info.id : 0"
              ref="EditMain"
              :target-person-ids="personIds"
              :deal-program-id="dealProgram.id"
              @cancel="edit = false"
              @create="refresh"
              @update="refresh"
            />
          </div>
          <!--   ДОМКЛИК   -->
          <div
            v-else-if="send2BankAllowType === 'domclick'"
            class=""
          >
            <div v-if="info.id">
              <div class="tw-text-an-flat-disabled-input-text">
                Заявка в Домклик по клиенту создана
              </div>
              <div v-if="info.requestId">
                ID заявки Домклик: <a
                  :href="`https://ipoteka.domclick.ru/deal/${info.requestId}`"
                  target="_blank"
                  title="Открыть заявку в домклик"
                >{{ info.requestId }}</a>
              </div>
              <div v-if="info.status">
                Статус:
                <strong>{{ info.status && info.status.label }}</strong>
              </div>
            </div>
            <div
              v-else
              class="tw-text-center tw-text-an-flat-disabled-input-text"
            >
              Запрос не создан
            </div>
          </div>
          <div
            v-else
            class="tw-text-an-flat-disabled-input-text tw-text-center"
          >
            Банк не поддерживает API запросы
          </div>
        </div>
        <div v-else>
          <TwCircleLoader />
        </div>
      </template>
    </TwPanelContentBlock>
  </div>
</template>

<script>
import API from '@/Api/Crm/ExternalApi/dealProgram2Bank/dealProgramApi'
import DealProgram2BankShow from '@/views/MortgageProgramWorksheet/dealProgram2Bank/DealProgram2BankShow'
import DealProgram2BankForm from '@/views/MortgageProgramWorksheet/dealProgram2Bank/DealProgram2BankForm'
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import { mapGetters } from 'vuex'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'

export default {
  name: 'DealProgram2BankBlock',
  components: { TwCircleLoader, TwPanelContentBlock, DealProgram2BankForm, DealProgram2BankShow },
  mixins: [ViewPanelShowMixin],
  props: {
    personIds: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    dealProgram: {
      type: Object,
      default: () => {
        return {}
      }
    },
    additionalData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      edit: false,
      process: false,
      info: null
    }
  },
  methods: {
    create () {
      API.create(this.dealProgram?.id)
    },
    refresh () {
      this.edit = false
      this.process = true
      API.show(this.dealProgram?.id).then(({ data }) => {
        this.info = data?.data || null
      }).finally(() => {
        this.process = false
      })
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'profile/isAdmin'
    }),
    send2BankAllowType () {
      return this.info?.connection
    }
  },
  mounted () {
    if (this.dealProgram?.id) {
      this.refresh()
    }
  }
}
</script>
