<template>
  <v-row dense>
    <v-col
      cols="12"
      lg="6"
    >
      <CardBlockField class="text-right">
        <template #title>
          Продукт
        </template>
        <template #content>
          <div
            style="font-size: 1.2em"
            class="font-weight-medium"
          >
            {{ productName }}
          </div>
        </template>
      </CardBlockField>
    </v-col>
    <v-col
      cols="12"
      lg="6"
    >
      <CardBlockField class="text-right">
        <template #title>
          Ежемесячный платёж
        </template>
        <template #content>
          <div
            style="font-size: 1.5em"
            class="font-weight-medium"
          >
            {{ (viewFields.payment).toLocaleString() }}<Rouble />
          </div>
        </template>
      </CardBlockField>
    </v-col>
    <v-col
      cols="12"
      lg="6"
    >
      <CardBlockField class="text-right">
        <template #title>
          Процентная ставка
        </template>
        <template #content>
          <div
            style="font-size: 1.5em"
            class="font-weight-medium"
          >
            {{ viewFields.rate }}<Percent />
          </div>
        </template>
      </CardBlockField>
    </v-col>
    <v-col
      cols="12"
      lg="6"
    >
      <CardBlockField class="text-right">
        <template #title>
          Сумма кредита
        </template>
        <template #content>
          <div
            style="font-size: 1.2em"
            class="font-weight-medium"
          >
            {{ (viewFields.creditSum).toLocaleString() }}<Rouble />
          </div>
        </template>
      </CardBlockField>
    </v-col>
    <v-col
      cols="12"
      lg="6"
    >
      <CardBlockField class="text-right">
        <template #title>
          Переплата
        </template>
        <template #content>
          <div
            style="font-size: 1.2em"
            class="font-weight-medium"
          >
            {{ (viewFields.overpayment).toLocaleString() }}<Rouble />
          </div>
        </template>
      </CardBlockField>
    </v-col>
    <v-col
      cols="12"
      lg="6"
    >
      <CardBlockField class="text-right">
        <template #title>
          Необходимый доход
        </template>
        <template #content>
          <div
            style="font-size: 1.2em"
            class="font-weight-medium"
          >
            {{ (viewFields.income).toLocaleString() }}<Rouble />
          </div>
        </template>
      </CardBlockField>
    </v-col>
  </v-row>
</template>

<script>
import { products } from './DomClickDict'

import CardBlockField from '@/components/core/ViewPanel/Card/CardBlockField'
import Rouble from '@/components/symbol/Rouble'
import Percent from '@/components/symbol/Percent'
export default {
  name: 'DomClickCalculationShow',
  components: { Percent, Rouble, CardBlockField },
  props: {
    info: {
      type: Object,
      default: null
    }
  },
  computed: {
    productName () {
      return products.find(p => p.value === this.info?.product)?.label || null
    },
    viewFields () {
      return {
        rate: this?.info?.rate || 0,
        income: this?.info?.income || 0,
        payment: this?.info?.payment || 0,
        overpayment: this?.info?.overpayment || 0,
        creditSum: this.info?.estateCost && this.info?.deposit ? this.info?.estateCost - this.info?.deposit : 0
      }
    }
  }
}
</script>

<style scoped>

</style>
