import { MutationTree } from 'vuex'
import { FiltersState } from '@/store/filters/types'

export const mutations: MutationTree<FiltersState> = {
  SET_FILTER_BY_KEY (state, { type, fields }) {
    if (!state[type] || !fields) {
      state[type] = {}
    }
    state[type] = { ...state[type], ...fields }
  },
  CLEAR_FILTER_BY_KEY (state, { type }) {
    state[type] = {}
  },
  SET_CURRENT_FILTER_PARAMS (state, payload) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { visible, ...info } = payload
    state.current = info
  }
}
