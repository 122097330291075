<template>
  <div
    v-if="formPermissionActionsStatus && formPermissionActionsStatus[formType]"
    class="tw-font-manrope"
  >
    <div style="display: none">
      {{ isEdited }}
    </div>
    <div class="tw-flex tw-flex-col tw-gap-[14px]">
      <TwTextarea
        v-model="form.note"
        rows="2"
        maxlength="70"
        autocomplete="off"
        persistent-label
        placeholder="Примечание"
        :disabled="formProcessing"
        :show-label="true"
      />
      <TwSelect
        v-if="formType === 'create'"
        v-model="form.type"
        label="Выберите тип сделки"
        placeholder="Выберите из списка"
        :options="dictionary.type"
        :disabled="formProcessing"
        :dense="true"
        :persistent-label="true"
      />
      <TwSelect
        v-if="subTypeOptions"
        v-model="form.subtype"
        label="Вид услуги"
        placeholder="Выберите вид услуги"
        :error-text="$v.form.subtype.$invalid ? 'Заполните поле':''"
        :multi="false"
        :dense="true"
        :cleanable="false"
        :persistent-label="true"
        :options="subTypeOptions"
        :disabled="formProcessing"
        @input="$v.form.subtype.$touch()"
      />
      <TwSelectRelations
        v-model="form.personId"
        :multi="false"
        :dense="true"
        label="Основной клиент"
        list-label="Найти контакт"
        relation-name="person"
        :persistent-label="true"
        :allow-create="true"
        placeholder="Не выбран"
        :error-text="$v.form.personId.$invalid ? 'Заполните поле':''"
        @input="$v.form.personId.$touch()"
      />
      <PersonLeadDealInfo
        v-show="form.personId"
        ref="isNeedViewPersonLeadAndDeals"
        class="tw-mt-[5px] tw-mb-[25px]"
        :person-id="form.personId"
        :errors="viewedLeadAndDealsErrors"
        :required-view="isNeedViewPersonLeadAndDeals"
        @need-view="isNeedViewPersonLeadAndDeals = $event"
      />
      <TwSelectRelations
        v-model="form.otherPersonsId"
        :multi="true"
        :dense="true"
        label="Другие клиенты"
        list-label="Найти контакт"
        :persistent-label="true"
        placeholder="Не выбраны"
        relation-name="person"
      />
      <div v-if="Array.isArray(form.otherPersonsId)">
        <PersonLeadDealInfo
          v-for="(personId, index) in form.otherPersonsId"
          :key="index"
          class="my-2"
          name-in-label
          :person-id="personId"
        />
      </div>
      <TwSelectRelations
        v-model="form.responsibleUserId"
        :multi="false"
        :dense="true"
        label="Основной ответственный"
        list-label="Найти сотрудника"
        relation-name="user"
        :persistent-label="true"
        placeholder="Не выбран"
        :error-text="$v.form.responsibleUserId.$invalid ? 'Заполните поле':''"
        @input="$v.form.responsibleUserId.$touch()"
      />
      <TwSelectRelations
        v-model="form.otherResponsibleUsersId"
        :multi="true"
        :dense="true"
        label="Другие ответственные"
        :persistent-label="true"
        placeholder="Не выбраны"
        list-label="Найти сотрудника"
        relation-name="user"
      />
      <TwSelectRelations
        v-model="form.byRealtorId"
        :multi="false"
        :dense="true"
        label="От риелтора"
        list-label="Найти сотрудника"
        relation-name="user"
      />
    </div>
    <div
      v-if="isNeedViewPersonLeadAndDeals"
      class="tw-mt-[15px] tw-text-center tw-text-main-xs tw-text-an-flat-red-main"
    >
      Ознакомьтесь с существующими Лидами и Заявками по клиенту!
    </div>
    <TwCardSimpleFormSaveBlock
      class="tw-mt-[15px]"
      :loading="formProcessing"
      :cancel-confirm="isEdited"
      :is-sticky="false"
      :disable-success="!isEdited || formProcessing"
      :disable-cancel="formProcessing"
      :visible="true"
      @success="handleSubmit"
      @cancel="handleCancel"
    />
  </div>
  <div
    v-else-if="formProcessing"
    class="py-3 text-center ma-auto"
  >
    <TwCircleLoader />
  </div>
  <div v-else>
    <PermissionDenied text="Недостаточно прав для данного действия!" />
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import API from '@/Api/Crm/Deal'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import PermissionDenied from '@/views/PermissionDenied'
import PersonLeadDealInfo from '@/views/Person/Other/PersonLeadDealInfo'
import TwSelectRelations from '@/components/tw-ui/ui-kit/input/select-relations/TwSelectRelations.vue'
import TwTextarea from '@/components/tw-ui/ui-kit/input/TwTextarea.vue'
import TwSelect from '@/components/tw-ui/ui-kit/input/TwSelect.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import TwCardSimpleFormSaveBlock from '@/components/tw-ui/forms/TwCardSimpleFormSaveBlock.vue'

export default {
  name: 'DealMainForm',
  components: {
    TwCardSimpleFormSaveBlock,
    TwCircleLoader,
    TwSelect,
    TwTextarea,
    TwSelectRelations,
    PersonLeadDealInfo,
    PermissionDenied
  },
  mixins: [FormCreateEditMixin],
  props: {
    disableRedirect: {
      type: Boolean,
      default: false
    },
    defaultFormFields: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      API: API,
      form: {
        note: '',
        statusId: null,
        personId: null,
        otherPersonsId: [],
        responsibleUserId: null,
        otherResponsibleUsersId: []
      },
      isNeedViewPersonLeadAndDeals: false
    }
  },
  // Модель для валидации, которую Vuelidate превратит в computed-поле $v
  validations () {
    return {
      isNeedViewPersonLeadAndDeals: {
        isViewed: value => value === false
      },
      form: {
        subtype: {
          ...(!!Array.isArray(this.subTypeOptions)) && {
            required
          }
        },
        responsibleUserId: {
          required
        },
        personId: {
          required
        }
      }
    }
  },
  computed: {
    viewedLeadAndDealsErrors () {
      const errors = []
      if (!this.$v.isNeedViewPersonLeadAndDeals.$dirty) return errors
      if (!this.$v.isNeedViewPersonLeadAndDeals.isViewed) {
        errors.push('Обязательное поле')
        this.$Global.scrollToElem(this.$refs.isNeedViewPersonLeadAndDeals.$el)
      }
      return errors
    },
    dealType () {
      if (this.formType === 'create') {
        return this.form?.type || null
      }
      return this.preset?.type?.value || null
    },
    subTypeDict () {
      return this.dictionary?.subtype || {}
    },
    // если нет опций для типа поле subtype - скрывается и становится необязательным
    subTypeOptions () {
      return Array.isArray(this.subTypeDict?.[this.dealType]) ? this.subTypeDict[this.dealType] : null
    }
  },
  methods: {
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          const adaperMap = {
            // from(show): to(send)
            person: 'personId',
            otherPersons: 'otherPersonsId',
            status: 'statusId'
          }
          for (const key in this.preset) {
            if (adaperMap[key] !== undefined) {
              if (!Array.isArray(this.preset[key]) && this.preset[key] && this.preset[key].id) {
                this.$set(this.form, adaperMap[key], this.preset[key].id)
              } else if (Array.isArray(this.preset[key])) {
                this.$set(this.form, adaperMap[key], this.preset[key].length ? this.preset[key].map(i => i.id) : [])
              }
            } else if (key === 'type' || key === 'subtype') {
              this.$set(this.form, key, this.preset[key]?.value || null)
            } else {
              this.$set(this.form, key, this.preset[key])
            }
          }
        }
        resolve()
      })
    },
    handleSubmit () {
      this.$v.$reset()
      this.$v.$touch()
      // console.warn('handleSubmit', this.$v.form.$invalid === false, this.formProcessing)
      if (!this.$v.$invalid) {
        if (this.formProcessing === true) {
          return
        }

        this.formProcessing = true
        const request = this.formType === 'create'
          ? API.store(this.form)
          : API.update(this.targetId, this.form)

        request.then(({ data }) => {
          if (this.formType === 'create') {
            this.$emit('create', data.data)
          } else {
            this.$emit('update', data.data)
          }
        }).finally(() => {
          this.formProcessing = false
        })
      }
    }
  }
}
</script>
