<template>
  <div>
    <template v-if="checkResult">
      <TwPanelInfoBlock
        v-if="!checkResult.checkStatus"
        type="info"
        class="tw-mb-[6px]"
      >
        <template #title>
          Не проверено!
        </template>
        <template #text>
          проверка результата будет проведена куратором перед закрытием отчетного периода
        </template>
      </TwPanelInfoBlock>
      <TwPanelInfoBlock
        v-if="checkResult.checkStatus && checkResult.checkStatus.value === 'confirmed'"
        type="success"
        class="tw-mb-[6px]"
      >
        <template #title>
          {{ checkResult.checkStatus.label }}
        </template>
        <template #text>
          <span
            v-if="checkResult.description"
            class="tw-font-normal"
          >{{ checkResult.description }}</span>
          <div class="tw-flex tw-gap-[15px]">
            <div v-if="checkResult.commission && checkResult.commissionConfirmed">
              Комиссия: <b>{{ (checkResult.commission).toLocaleString('ru') }}</b>₽
            </div>
            <div v-if="checkResult.month && checkResult.year">
              Период: <b>{{ checkResult.month || 11 }}/{{ checkResult.year || 2020 }}</b>
            </div>
            <div v-if="checkResult.checkUserId">
              Куратор:
              <ViewPanelLink
                :id="checkResult.checkUserId"
                label="Куратор"
                component="employee-show"
              >
                <EmployeeName :id="checkResult.checkUserId" />
              </ViewPanelLink>
            </div>
          </div>
        </template>
      </TwPanelInfoBlock>
      <TwPanelInfoBlock
        v-if="checkResult.checkStatus && checkResult.checkStatus.value === 'adjustment'"
        type="warning"
        class="tw-mb-[6px]"
      >
        <template #title>
          {{ checkResult.checkStatus.label }}
        </template>
        <template #text>
          <span
            v-if="checkResult.description"
            class="tw-font-normal tw-text-an-flat-red-main"
          >{{ checkResult.description }}</span>
          <div class="tw-flex tw-gap-[15px]">
            <div v-if="checkResult.commission">
              Комиссия: <b>{{ (checkResult.commission).toLocaleString('ru') }}</b>₽
            </div>
            <div v-if="checkResult.month && checkResult.year">
              Период: <b>{{ checkResult.month || 11 }}/{{ checkResult.year || 2020 }}</b>
            </div>
            <div v-if="checkResult.checkUserId">
              Куратор:
              <ViewPanelLink
                :id="checkResult.checkUserId"
                label="Куратор"
                component="employee-show"
              >
                <EmployeeName :id="checkResult.checkUserId" />
              </ViewPanelLink>
            </div>
          </div>
        </template>
      </TwPanelInfoBlock>
      <TwPanelInfoBlock
        v-if="checkResult.checkStatus && checkResult.checkStatus.value === 'rejected'"
        type="error"
        class="tw-mb-[6px]"
      >
        <template #title>
          {{ checkResult.checkStatus.label }}
        </template>
        <template #text>
          <span
            v-if="checkResult.description"
            class="tw-font-normal tw-text-an-flat-red-main"
          >{{ checkResult.description }}</span>
          <div class="tw-flex tw-gap-[15px]">
            <div v-if="checkResult.commission">
              Комиссия: <b>{{ (checkResult.commission).toLocaleString('ru') }}</b>₽
            </div>
            <div v-if="checkResult.month && checkResult.year">
              Период: <b>{{ checkResult.month }}/{{ checkResult.year }}</b>
            </div>
            <div v-if="checkResult.checkUserId">
              Куратор:
              <ViewPanelLink
                :id="checkResult.checkUserId"
                label="Куратор"
                component="employee-show"
              >
                <EmployeeName :id="checkResult.checkUserId" />
              </ViewPanelLink>
            </div>
          </div>
        </template>
      </TwPanelInfoBlock>
    </template>
    <TwPanelContentBlock class="tw-mb-[6px]">
      <template #title>
        <div>Информация по результату сделки</div>
      </template>
      <template
        v-if="!isError"
        #action
      >
        <button
          v-if="!isEdit"
          class="tw-text-main-xs enabled:hover:tw-text-an-flat-dark-blue-link"
          @click.prevent="isEdit = true"
        >
          Изменить
        </button>
      </template>
      <div
        v-if="loading"
        class="tw-text-center"
      >
        <TwCircleLoader />
      </div>
      <div
        v-else-if="isError"
        class="tw-text-an-flat-red-main tw-text-center"
      >
        Произошла ошибка!
      </div>
      <div v-else>
        <div v-if="dealSubtype && dealSubtype.value === 'contract'">
          <DealBuyNbLandHouseResultForm
            v-if="isEdit"
            :id="info && info.id ? dealId : 0"
            :deal-id="dealId"
            @refresh="refresh"
            @create="update"
            @update="update"
            @cancel="isEdit = false"
          />
          <DealBuyNbLandHouseResultShow
            v-else
            :deal-id="dealId"
            :info="info"
            :dictionary="dictionary"
          />
        </div>
        <div v-else>
          <DealBuyNbResultForm
            v-if="isEdit"
            :id="info && info.id ? dealId : 0"
            :deal-id="dealId"
            @refresh="refresh"
            @create="update"
            @update="update"
            @cancel="isEdit = false"
          />
          <DealBuyNbResultShow
            v-else
            :deal-id="dealId"
            :info="info"
            :dictionary="dictionary"
          />
        </div>
      </div>
    </TwPanelContentBlock>
  </div>
</template>

<script>
import DealBuyNbResultShow from '@/views/Deal/TypeViews/buyNb/Result/DealBuyNbResultShow.vue'
import DealBuyNbResultForm from '@/views/Deal/TypeViews/buyNb/Result/DealBuyNbResultForm.vue'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwPanelInfoBlock from '@/components/tw-ui/panels/TwPanelInfoBlock.vue'
import API from '@/Api/Crm/Deal/BuyNb/Result'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink.vue'
import EmployeeName from '@/components/other/employee/EmployeeName.vue'
import DealBuyNbLandHouseResultForm from '@/views/Deal/TypeViews/buyNb/Result/DealBuyNbLandHouseResultForm.vue'
import DealBuyNbLandHouseResultShow from '@/views/Deal/TypeViews/buyNb/Result/DealBuyNbLandHouseResultShow.vue'

export default {
  name: 'DealBuyNbResultBlock',
  components: {
    DealBuyNbLandHouseResultShow,
    DealBuyNbLandHouseResultForm,
    EmployeeName,
    ViewPanelLink,
    TwCircleLoader,
    TwPanelInfoBlock,
    DealBuyNbResultForm,
    DealBuyNbResultShow,
    TwPanelContentBlock
  },
  props: {
    dealData: {
      required: true
    },
    dealState: {
      default: null
    }
  },
  data () {
    return {
      isEdit: false,
      isError: false,
      loading: false,
      info: null,
      dictionary: null
    }
  },
  computed: {
    dealSubtype () {
      return this.dealData?.subtype || null
    },
    dealId () {
      return this.dealData?.id || null
    },
    checkResult () {
      return this.info?.check || null
    }
  },
  mounted () {
    this.refresh()
  },
  methods: {
    refresh () {
      this.isEdit = false
      this.fetchInfo()
    },
    update () {
      this.refresh()
      this.$emit('refresh')
    },
    fetchInfo () {
      this.loading = true
      this.isError = false
      API.show(this.dealId)
        .then(({ data: result }) => {
          // console.warn('fetchInfo', result)
          this.$set(this, 'info', result?.data?.id ? result?.data : null)
          this.$set(this, 'dictionary', result?.dictionary || null)
        })
        .catch((error) => {
          console.error('fetchInfo', error)
          this.$set(this, 'info', null)
          this.$set(this, 'dictionary', null)
          this.isError = true
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
