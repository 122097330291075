import { RouteKeys } from '@/router/types'
import dictPermissionRules from '@/Permissions/dictRules'

export default {
  path: RouteKeys.requirement,
  name: RouteKeys.requirement,
  redirect: { name: `${RouteKeys.requirement}-list` },
  meta: { name: 'Требования банков', icon: 'bank-requirements', ruleNamespace: dictPermissionRules.AppServicePermissionRulesCRMMortgageProgramsReadProgramRule },
  component: () => import('@/views/Mortgage/Requirement/Index.vue'),
  children: [
    {
      path: 'list',
      name: `${RouteKeys.requirement}-list`,
      meta: { name: 'Список требований банка', icon: 'bank-requirements', hideInMenu: true },
      component: () => import('@/views/Mortgage/Requirement/List.vue')
    },
    {
      path: ':id/edit',
      name: `${RouteKeys.requirement}-edit`,
      meta: { name: 'Редактирование требований банка', icon: 'bank-requirements', hideInMenu: true },
      component: () => import('@/views/Mortgage/Requirement/RequirementCard.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    },
    {
      path: 'create',
      name: `${RouteKeys.requirement}-create`,
      meta: { name: 'Добавление требований банка', icon: 'bank-requirements', hideInMenu: true },
      component: () => import('@/views/Mortgage/Requirement/RequirementCard.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    },
    {
      path: ':id',
      name: `${RouteKeys.requirement}-show`,
      meta: { name: 'Просмотр требований банка', icon: 'bank-requirements', hideInMenu: true },
      component: () => import('@/views/Mortgage/Requirement/RequirementCard.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    }
  ]
}
