<script>
export default {
  name: 'TwPingPoint',
  props: {
    title: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: '14px'
    },
    color: {
      type: String,
      default: 'red'
    }
  }
}
</script>

<template>
  <div
    :title="title"
    :style="{ width: size, height: size }"
    class="tw-relative"
  >
    <span
      aria-hidden="true"
      :style="{ width: size, height: size, backgroundColor: color }"
      class="tw-absolute tw-right-0 tw-border-white tw-border-[3px] tw-rounded-full"
    />
    <span
      aria-hidden="true"
      :style="{ width: size, height: size, backgroundColor: color }"
      class="tw-animate-ping tw-absolute tw-right-0 tw-border tw-border-white tw-border-opacity-5 tw-rounded-full"
    />
  </div>
</template>

<style scoped>

</style>
