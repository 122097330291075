import { RouteKeys } from '@/router/types'

export default {
  path: RouteKeys.companies,
  name: RouteKeys.companies,
  redirect: { name: `${RouteKeys.company}-list` },
  meta: { name: 'Компании', icon: 'common-companies' },
  component: () => import('@/views/Dictionary/General/Company/Index.vue'),
  children: [
    {
      path: 'list',
      name: `${RouteKeys.company}-list`,
      meta: { name: 'Список компаний', icon: 'common-companies', hideInMenu: true },
      component: () => import('@/views/Dictionary/General/Company/List.vue')
    },
    {
      path: ':id/edit',
      name: `${RouteKeys.company}-edit`,
      meta: { name: 'Редактирование компании', icon: 'common-companies', hideInMenu: true },
      component: () => import('@/views/Dictionary/General/Company/CompanyCard.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    },
    {
      path: 'create',
      name: `${RouteKeys.company}-create`,
      meta: { name: 'Добавление компании', icon: 'common-companies', hideInMenu: true },
      component: () => import('@/views/Dictionary/General/Company/CompanyCard.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    },
    {
      path: ':id',
      name: `${RouteKeys.company}-show`,
      meta: { name: 'Просмотр компании', icon: 'common-companies', hideInMenu: true },
      component: () => import('@/views/Dictionary/General/Company/CompanyCard.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    }
  ]
}
