<template>
  <TwPanelContentBlock
    class="tw-mb-[6px]"
    :content-classes="''"
  >
    <template #title>
      <div class="tw-w-full tw-grid tw-grid-cols-3 tw-gap-[20px] tw-items-center">
        <div>
          Фиксации и бронирования
        </div>
        <button
          class="tw-bg-an-flat-grey-3 tw-font-medium tw-text-white tw-rounded-sm tw-border tw-px-[20px] tw-py-[5px] hover:tw-shadow enabled:hover:tw-bg-an-flat-dark-blue-link"
          @click.stop="openPanel('fixation')"
        >
          Новая фиксация
        </button>
      </div>
    </template>
    <div>
      <TwTableBase
        :schema-key="$options.name"
        :items="data"
        :headers="headers"
        :pagination="null"
        :fixed-header-margin="0"
        :table-columns-management="true"
        @row-click="clickToRow"
        @headersUpdate="headers = $event"
        @changePagination="pagination.current_page = $event"
      >
        <template #item.type="{ item }">
          {{ (item.type && item.type.label) || '~' }}
        </template>
        <template #item.companyId="{ item }">
          <ViewPanelLink
            v-if="item.complexId"
            :id="item.complexId"
            label="ЖК"
            component="realty-complex-show-page"
          >
            <RealtyComplexName :id="item.complexId" />
          </ViewPanelLink>
          <ViewPanelLink
            v-if="item.companyId"
            :id="item.companyId"
            label="Застройщик"
            :no-link-style="true"
            component="company-show"
            :callback="fetch"
          >
            <div v-if="$store.getters['tmpCache/getCompanyById'](item.companyId)">
              {{ $store.getters['tmpCache/getCompanyById'](item.companyId).name }}
            </div>
          </ViewPanelLink>
        </template>
        <template #item.result="{ item }">
          <div v-if="item.status && item.status.value === 'confirmed' && (item.confirmedBefore || item.confirmedPrice)">
            <div
              v-if="item.confirmedBefore"
              class="tw-mb-[10px]"
            >
              <div class="tw-text-an-flat-gray-4 tw-mb-[5px]">
                Подтверждено до:
              </div>
              <div class="">
                <strong>{{ $Global.DateFormat.DateTimeShort(item.confirmedBefore) }}</strong>
              </div>
            </div>
            <div v-if="item.confirmedPrice">
              <div class="tw-text-an-flat-gray-4 tw-mb-[5px]">
                Потвержденная цена:
              </div>
              <div class="">
                <strong>{{ (item.confirmedPrice || 0).toLocaleString('ru') }}</strong> руб.
              </div>
            </div>
          </div>
        </template>
        <template #item.status="{ item }">
          <div v-if="item.draft">
            'Черновик'
          </div>
          <div
            v-else
            :class="item.confirmedBefore && $Global.DateFormat.hasDateExpiration(item.confirmedBefore) ? 'tw-opacity-50':''"
          >
            <strong :style="{ color: item.status?.color }">{{ item.status?.label }}</strong>
          </div>
          <div v-if="item.confirmedBefore && $Global.DateFormat.hasDateExpiration(item.confirmedBefore)">
            <small class="tw-text-an-flat-red-main">*истёк срок</small>
          </div>
        </template>
        <template #item.comment="{ item }">
          <ReadMoreExpander
            v-if="item.comment"
            :is-nl-trigger="true"
            :text="item.comment"
            :less-length="50"
          />
        </template>
        <template #item.createdAt="{ item }">
          <div v-if="item.userId">
            <ViewPanelLink
              :id="item.userId"
              label="Сотрудник"
              component="employee-show"
            >
              <EmployeeName :id="item.userId" />
            </ViewPanelLink>
          </div>
          <small v-if="item.createdAt">
            {{ $Global.DateFormat.DateTimeShort(item.createdAt) }}
          </small>
        </template>
        <template #item.petitionFileId="{ item }">
          <div v-if="Array.isArray(item.petitionFileId) && item.petitionFileId.length">
            Прикреплено
          </div>
          <div v-else>
            Нет
          </div>
        </template>
      </TwTableBase>
    </div>
  </TwPanelContentBlock>
</template>

<script>
import API from '@/Api/Rtd/RequestToDeveloper/Request'
import { mapActions } from 'vuex'
import TwTableBase from '@/components/tw-ui/table/TwTableBase.vue'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink.vue'
import RealtyComplexName from '@/components/other/complex/RealtyComplexName.vue'
import EmployeeName from '@/components/other/employee/EmployeeName.vue'
import ReadMoreExpander from '@/components/other/text/ReadMoreExpander.vue'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'

const headers = [
  {
    dataKey: 'id',
    text: 'ID',
    colStyle: 'width: 30px;',
    thClass: 'tw-text-main-sm2 tw-text-left tw-font-semibold tw-whitespace-nowrap tw-px-[12px] tw-py-[8px] tw-bg-an-flat-gray-9',
    tdClass: 'tw-border tw-border-an-flat-gray-7 tw-p-[12px] tw-whitespace-nowrap'
  },
  {
    dataKey: 'type',
    text: 'Тип',
    colStyle: 'width: 135px;',
    thClass: 'tw-text-main-sm2 tw-text-left tw-font-semibold tw-whitespace-nowrap tw-px-[12px] tw-py-[8px] tw-bg-an-flat-gray-9',
    tdClass: 'tw-border tw-border-an-flat-gray-7 tw-p-[12px] tw-whitespace-nowrap'
  },
  {
    dataKey: 'companyId',
    text: 'ЖК и Застройщик',
    colStyle: 'width: 162px;',
    thClass: 'tw-text-main-sm2 tw-text-left tw-font-semibold tw-whitespace-nowrap tw-px-[12px] tw-py-[8px] tw-bg-an-flat-gray-9',
    tdClass: 'tw-border tw-border-an-flat-gray-7 tw-p-[12px] tw-whitespace-nowrap'
  },
  {
    dataKey: 'status',
    text: 'Статус',
    colStyle: 'width: 44px;',
    thClass: 'tw-text-main-sm2 tw-text-left tw-font-semibold tw-whitespace-nowrap tw-px-[12px] tw-py-[8px] tw-bg-an-flat-gray-9',
    tdClass: 'tw-border tw-border-an-flat-gray-7 tw-p-[12px] tw-whitespace-nowrap'
  },
  {
    dataKey: 'result',
    text: 'Результат',
    colStyle: 'width: 97px;',
    thClass: 'tw-text-main-sm2 tw-text-left tw-font-semibold tw-whitespace-nowrap tw-px-[12px] tw-py-[8px] tw-bg-an-flat-gray-9',
    tdClass: 'tw-border tw-border-an-flat-gray-7 tw-p-[12px] tw-whitespace-nowrap'
  },
  {
    dataKey: 'comment',
    text: 'Комментарий',
    colStyle: 'width: 120px;',
    thClass: 'tw-text-main-sm2 tw-text-left tw-font-semibold tw-whitespace-nowrap tw-px-[12px] tw-py-[8px] tw-bg-an-flat-gray-9',
    tdClass: 'tw-border tw-border-an-flat-gray-7 tw-p-[12px]'
  },
  {
    dataKey: 'petitionFileId',
    text: 'Ходатайство',
    colStyle: 'width: 44px;',
    thClass: 'tw-text-main-sm2 tw-text-left tw-font-semibold tw-whitespace-nowrap tw-px-[12px] tw-py-[8px] tw-bg-an-flat-gray-9',
    tdClass: 'tw-border tw-border-an-flat-gray-7 tw-p-[12px] tw-whitespace-nowrap'
  },
  {
    dataKey: 'createdAt',
    text: 'Создано',
    colStyle: 'width: 44px;',
    thClass: 'tw-text-main-sm2 tw-text-left tw-font-semibold tw-whitespace-nowrap tw-px-[12px] tw-py-[8px] tw-bg-an-flat-gray-9',
    tdClass: 'tw-border tw-border-an-flat-gray-7 tw-p-[12px] tw-whitespace-nowrap'
  }
]
export default {
  name: 'DealBuyNbRequestToDeveloperList',
  components: {
    TwPanelContentBlock,
    ReadMoreExpander,
    EmployeeName,
    RealtyComplexName,
    ViewPanelLink,
    TwTableBase
  },
  props: {
    dealId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      API,
      data: [],
      headers,
      pagination: {}
    }
  },
  methods: {
    ...mapActions({
      showPanel: 'viewPanels/showPanel'
    }),
    fetch () {
      this.permissionDenied = false
      if (this.loading) {
        return
      }

      if (!this.API) {
        throw Error('not set API')
      }

      this.loading = true

      if (this.isScrollToTopEnable) {
        this.scrollToTop()
      }
      this.API.getListForDeal({}, this.dealId)
        .then((response) => {
          this.data = response.data.data
          if (response.data.meta) {
            this.pagination = response.data.meta
          }
        })
        .catch((error) => {
        // обработка отсутствия прав на просмотр
          if (error?.response?.status === 403) {
            this.permissionDenied = true
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    clickToRow (item) {
      console.warn('clickToRow', item)
      this.openPanel(item?.type?.value, item.id)
    },
    openPanel (requestType, id = 0) {
      console.warn('showPanel', requestType)
      this.showPanel({
        isCloseConfirm: false,
        viewType: 'panel',
        component: 'nb-request-to-developer-panel',
        props: {
          id,
          createInitData: {
            requestType: requestType,
            dealId: this.dealId,
            companyId: null,
            complexId: null
          }
        },
        callback: () => {
          console.warn(this.$options.name, '- showPanel')
          this.fetch()
        }
      })
    }
  },
  mounted () {
    this.fetch()
  }
}
</script>

<style scoped>

</style>
