<template>
  <div class="card">
    <div
      v-if="item"
      class="card-wrapper"
    >
      <div
        v-if="loading"
        class="tw-w-full tw-flex tw-justify-center tw-items-center tw-p-3"
      >
        <TwCircleLoader />
      </div>
      <div
        v-if="attachEntityData"
        class="card-left"
      >
        <div class="card-header mb-4">
          <div
            v-if="attachEntityData"
            class="card-email-readed"
          >
            <p class="card-title mr-2">
              {{ attachEntityData.emailFolder.name === 'INBOX' ? 'Входящее':'Исходящее' }} письмо:
            </p>
            <p
              v-if="attachEntityData.status.value === 'got'"
              class="card-email-send-got"
            >
              {{ attachEntityData.status.label }}
            </p>
            <p
              v-else
              class="card-email-send"
            >
              {{ attachEntityData.status.label }}
            </p>
            <p class="card-time">
              {{ $Global.DateFormat.DateTimeShort(item.date) }}
            </p>
            <p
              v-if="attachEntityData.status.value === 'send'"
              class="card-email-send-read"
            >
              Прочитано
            </p>
            <p class="card-time">
              {{ $Global.DateFormat.DateTimeShort(attachEntityData.dateRead) }}
            </p>
          </div>
        </div>
        <p
          class="card-title card-title-email mr-2 item-theme mb-2"
          @click.stop="showEmailCard({ id: attachEntityData.id })"
        >
          {{ attachEntityData.theme }}
        </p>
        <div
          v-for="person in attachEntityData.contacts"
          :key="person.id"
          class="card-email-info-person"
        >
          <p class="card-title mr-2">
            <span class="card-email-to-info">Кому</span>: {{ person.name }}<span
              v-if="person.email"
              class="card-email-theme ml-2"
              v-text="`<${person.email}>`"
            />
          </p>
        </div>
        <div class="card-email-down">
          <p>{{ $Global.emailFirstChildToText(attachEntityData.body) }}</p>
        </div>
      </div>
      <div class="card-right">
        <ChronologyPinned
          v-if="!(variant === 'other' && item.pin)"
          :id="item.id"
          :pin-status="item.pin"
          @refresh="refresh"
        />
      </div>
    </div>
    <div
      v-else
      class="error--text"
    >
      Нет данных
    </div>
  </div>
</template>

<script>
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import ChronologyPinned from '@/components/core/Chronology/ChronologyPinned'
import { API } from '@/Api/Email'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'

export default {
  name: 'EmailShow',
  components: { TwCircleLoader, ChronologyPinned },
  mixins: [ViewPanelShowMixin],
  props: {
    variant: {
      type: String,
      default: '' // other, plan, pin
    },
    item: {
      type: Object,
      required: true
    },
    entity: {
      type: String,
      required: true // person | lead | deal
    },
    entityId: {
      type: [String, Number, null],
      default: null
    }
  },
  data () {
    return {
      loading: false,
      attachEntityData: null
    }
  },
  mounted () {
    this.fetchInfo()
  },
  methods: {
    fetchInfo () {
      this.loading = true
      API.Mail.show(this.item.attachEntityId)
        .then(({ data }) => {
          this.attachEntityData = data?.data || null
        })
        .catch((error) => {
          console.error('fetchInfo attachEntityData', error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    refresh () {
      this.$emit('refresh')
    }
  }
}
</script>
