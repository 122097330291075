<template>
  <v-row dense>
    <!--    TODO DEBUG-->
    <!--    <v-col cols="12">-->
    <!--      {{dFields}}-->
    <!--    </v-col>-->
    <!--    <v-col cols="12">-->
    <!--      {{fields}}-->
    <!--    </v-col>-->
    <v-col
      cols="12"
      lg="6"
    >
      <DocumentVisionFieldWarning
        v-if="dFields.series && dFields.series.value && dFields.series.value !== fields.series"
        :vision-value="dFields.series.value"
        :confidence="dFields.series.confidence"
        @apply="fields.series = dFields.series.value"
      />
      <TwInputPassportFields
        v-model="fields.series"
        class="tw-mb-[12px]"
        label="Серия"
        dense
        persistent-label
        type="series"
      />
    </v-col>
    <v-col
      cols="12"
      lg="6"
    >
      <DocumentVisionFieldWarning
        v-if="dFields.number && dFields.number.value && dFields.number.value !== fields.number"
        :vision-value="dFields.number.value"
        :confidence="dFields.number.confidence"
        @apply="fields.number = dFields.number.value"
      />
      <TwInputPassportFields
        v-model="fields.number"
        class="tw-mb-[12px]"
        label="Номер"
        dense
        persistent-label
        type="number"
      />
    </v-col>
    <v-col
      cols="12"
      lg="6"
    >
      <DocumentVisionFieldWarning
        v-if="dFields.dateIssued && dFields.dateIssued.value && dFields.dateIssued.value !== fields.dateIssued"
        :vision-value="dFields.dateIssued.value"
        :confidence="dFields.dateIssued.confidence"
        @apply="fields.dateIssued = dFields.dateIssued.value"
      />

      <TwInputText
        v-model="fields.dateIssued"
        type="date"
        label="Дата выдачи"
        dense
        :error-text="fields.dateIssued ? '':'Обязательное поле'"
        :show-label="true"
        :persistent-label="true"
      />
    </v-col>
    <v-col
      cols="12"
      lg="6"
    >
      <DocumentVisionFieldWarning
        v-if="dFields.code && dFields.code.value && dFields.code.value !== fields.code"
        :vision-value="dFields.code.value"
        :confidence="dFields.code.confidence"
        @apply="fields.code = dFields.code.value"
      />
      <TwInputPassportFields
        v-model="fields.code"
        class="tw-mb-[12px]"
        label="Код подразделения"
        dense
        persistent-label
        type="code"
      />
    </v-col>
    <v-col
      cols="12"
      lg="6"
    >
      <DocumentVisionFieldWarning
        v-if="dFields.whoIssued && dFields.whoIssued.value && dFields.whoIssued.value !== fields.whoIssued"
        :vision-value="dFields.whoIssued.value"
        :confidence="dFields.whoIssued.confidence"
        @apply="fields.whoIssued = dFields.whoIssued.value"
      />
      <TwTextarea
        v-model="fields.whoIssued"
        rows="3"
        label="Кем выдан"
        placeholder="Кем выдан"
        persistent-label
        :show-label="true"
      />
    </v-col>
    <v-col
      cols="12"
      lg="6"
    >
      <DocumentVisionFieldWarning
        v-if="dFields.placeBirth && dFields.placeBirth.value && dFields.placeBirth.value !== fields.placeBirth"
        :vision-value="dFields.placeBirth.value"
        :confidence="dFields.placeBirth.confidence"
        @apply="fields.placeBirth = dFields.placeBirth.value"
      />
      <TwTextarea
        v-model="fields.placeBirth"
        rows="3"
        label="Место рождения"
        placeholder="Место рождения"
        persistent-label
        :show-label="true"
      />
    </v-col>
    <v-col
      cols="12"
      xl="6"
    >
      <RealtyObjectSearchByAddressInput
        class="tw-mt-[12px]"
        label="Место регистрации"
        fias-level="house"
        dense
        :value="{ fullAddress: fields.placeRegistration }"
        persistent-label
        cleanable
        @input="onInputAddress('placeRegistration', $event)"
      />
    </v-col>
    <v-col
      cols="12"
      xl="6"
    >
      <RealtyObjectSearchByAddressInput
        class="tw-mt-[12px]"
        label="Место жительства"
        fias-level="house"
        dense
        :value="{ fullAddress: fields.placeResidence }"
        persistent-label
        cleanable
        @input="onInputAddress('placeResidence', $event)"
      />
      <TwCheckBox
        v-model="registrationResidenceEq"
        class="tw-mt-[12px]"
        :multi="false"
        :radio="false"
        :disabled="!fields.placeRegistration"
        label="Место жительства совпадает с местом регистрации"
        @input="changeRegistrationResidenceEq"
      />
    </v-col>
  </v-row>
</template>

<script>
import DocumentVisionFieldWarning from '@/views/Person/documents/DocumentVisionFieldWarning'
import TwInputPassportFields from '@/components/tw-ui/ui-kit/custom/TwInputPassportFields.vue'
import RealtyObjectSearchByAddressInput
  from '@/components/tw-ui/ui-kit/input/fias/RealtyObjectSearchByAddressInput.vue'
import TwInputText from '@/components/tw-ui/ui-kit/input/TwInputText.vue'
import TwTextarea from '@/components/tw-ui/ui-kit/input/TwTextarea.vue'
import TwCheckBox from '@/components/tw-ui/ui-kit/input/TwCheckBox.vue'

export default {
  name: 'PassportType',
  components: { TwCheckBox, TwTextarea, TwInputText, RealtyObjectSearchByAddressInput, TwInputPassportFields, DocumentVisionFieldWarning },
  // components: { AAddressInput },
  props: {
    // набор значений fields из формы
    formType: {},
    value: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      detectedFields: {}, // поля распознанные сервером computer-vision
      registrationResidenceEq: this.formType === 'create',
      numberRules: [
        v => !!(typeof v === 'string' && /^([0-9]){6}$/.test(v)) || 'Неверный формат'
      ],
      codeRules: [
        v => !!(typeof v === 'string' && /^([0-9-]){7}$/.test(v)) || 'Неверный формат'
      ],
      seriesRules: [
        v => !!(typeof v === 'string' && /^([0-9]){4}$/.test(v)) || 'Неверный формат'
      ]
    }
  },
  computed: {
    fields: {
      get () {
        return this.value
      }
      // set (values) {
      //   console.warn('SET fields ', values)
      // }
    },
    dFields () {
      // сопоставление полей
      const fieldMap = {
        series: 'series',
        number: 'number',
        issue_date: 'dateIssued',
        issued_by: 'whoIssued',
        subdivision: 'code',
        birth_place: 'placeBirth'
      }
      const fieldValues = this.detectedFields?.document?.values
      if (Array.isArray(fieldValues)) {
        const fields = {}
        for (const yaField of fieldValues) {
          console.log('yaField ', yaField.code)
          // сопоставляю поля
          if (fieldMap[yaField.code]) {
            if (yaField.code === 'issue_date') {
              if (yaField.value) {
                console.warn({ ccccccc: yaField.value, co: yaField.code })
                const [day, month, year] = yaField.value.split('.')
                console.warn({ day, month, year })
                fields[fieldMap[yaField.code]] = {
                  // ...yaField,
                  value: `${year}-${month}-${day}`
                }
              }
            } else {
              fields[fieldMap[yaField.code]] = yaField
            }
          }
        }
        return fields
      }
      return {}
    }
  },
  watch: {
    'fields.placeRegistration' (newValue) {
      // placeResidence должен соответствовать placeRegistration если он пустой
      if (this.registrationResidenceEq) {
        this.fields.placeResidence = newValue
      }
    }
  },
  methods: {
    onInputAddress (key, eventData) {
      console.warn('onInputAddress', key, eventData)
      this.fields[key] = eventData.fullAddress || ''
    },
    setDetectedFields (fields) {
      console.warn('setDetectedFields', fields)
      this.$set(this, 'detectedFields', fields)
      // this.detectedFields = fields
    },
    changeRegistrationResidenceEq (val) {
      console.log('val', val)
      if (val) {
        this.fields.placeResidence = this.fields.placeRegistration
      } else {
        this.fields.placeResidence = ''
      }
    }
  }
}
</script>

<style scoped>

</style>
