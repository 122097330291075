<template>
  <v-container
    fluid
    class="pt-0"
  >
    <v-row
      no-gutters
      align="center"
    >
      <!-- ПОЛЯ -->
      <v-col
        cols="12"
        md="6"
      >
        <template v-if="info.fields && Object.keys(info.fields).length">
          <v-row
            v-for="(value, key) in info.fields"
            :key="key"
          >
            <v-col
              cols="4"
              class="body-2 font-weight-medium"
            >
              {{ fieldsText[info.type.value][key] }}:
            </v-col>
            <v-col cols="8">
              {{ value }}
            </v-col>
          </v-row>
        </template>
        <v-list-item v-else>
          <v-list-item-subtitle>Нет полей для заполнения</v-list-item-subtitle>
        </v-list-item>
      </v-col>

      <!-- ФАЙЛЫ -->
      <v-col
        cols="12"
        md="6"
      >
        <template v-if="Array.isArray(info.files) && info.files.length">
          <template v-for="(file, index) in info.files">
            <v-list-item
              :key="index"
              color="primary"
            >
              <v-list-item-avatar
                tile
                style="border: 1px solid lightgray"
              >
                <v-img
                  v-if="file.mime && file.mime.indexOf('image') !== -1"
                  :src="getFileResizeByKey(file, 'default')"
                  @click="viewImages(info.files, index)"
                >
                  <template #placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-icon>fa-file-text</v-icon>
                    </v-row>
                  </template>
                </v-img>
                <v-icon v-else>
                  fa-file-text
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{ file && file.type && file.type.label }}</v-list-item-title>
                <v-list-item-subtitle>
                  {{ file && file.name || 'Нет названия' }}
                  <small
                    v-if="file.size"
                    class="ml-1 text-muted"
                  >
                    {{ $Global.formatBytes(file.size) }}
                  </small>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  v-if="file"
                  icon
                  elevation="0"
                  x-small
                  color="white"
                  @click.stop="download(file)"
                >
                  <v-icon
                    small
                    color="primary"
                  >
                    fa-download
                  </v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <!--            <v-divider :key="index+'divider'"></v-divider>-->
          </template>
        </template>
        <v-list-item v-else>
          <v-list-item-subtitle>Нет загруженных файлов</v-list-item-subtitle>
        </v-list-item>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { fieldsTextFromTypes } from '@/views/Person/personDict'
import { getFileResizeByKey } from '@/plugins/helpers/fileHelpers'
import docAPI from '@/Api/Crm/Dictionary/General/Document'

export default {
  name: 'PersonDocumentShow',
  props: {
    info: {
      type: Object,
      default: null
    }
  },
  computed: {
    fieldsText () {
      return fieldsTextFromTypes()
    }
  },
  methods: {
    getFileResizeByKey: getFileResizeByKey,
    download (file) {
      docAPI.download(file.url, file.name)
    },
    viewImages (files, index) {
      this.$store.dispatch('imageViewer/showImages', {
        files: files.filter(f => f.mime.indexOf('image') !== -1).map((f) => {
          return {
            path: f.url
          }
        }),
        startIndex: index
      })
    }
  }
}
</script>

<style scoped>

</style>
