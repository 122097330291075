<template>
  <PageViewDefaultWrapper
    ref="wrapper"
    :permission-denied="permissionDenied"
    relative
  >
    <TwPanelContent class="tw-flex-1">
      <TwPanelContentBlock
        class="tw-flex-1 tw-mb-[6px] tw-overflow-auto"
      >
        <template #title>
          <div>
            Общий список сделок сотрудника
          </div>
        </template>
        <div>
          <TwTableBase
            :schema-key="filterSchemaKey"
            tbody-classes="tw-text-xs"
            tbody-row-classes="tw-cursor-pointer"
            :items="data"
            :headers="headers"
            :table-fixed="false"
            :loading="loading"
            :fixed-header-margin="0"
            :table-columns-management="false"
            :no-data-text="noResultsText"
            :pagination="pagination"
            @sortUpdate="onSortUpdate"
            @headersUpdate="headers = $event"
            @row-click="rowClick"
            @changePagination="changePagination"
          >
            <template #item.createdAt="{ item }">
              <div v-if="item.createdAt">
                {{ $Global.DateFormat.DateTimeShort(item.createdAt) }}
              </div>
            </template>
            <template #item.statusChangeDate="{ item }">
              <div v-if="item.statusChangeDate">
                {{ $Global.DateFormat.DateTimeShort(item.statusChangeDate) }}
              </div>
            </template>
            <template #item.name="{ item }">
              <div class="tw-flex tw-gap-[6px] tw-items-center">
                <TwPingPoint
                  v-if="item.isHungDeal"
                  title="Обработайте заявку, иначе она будет перераспределена"
                />
                <div>
                  <div
                    v-if="item.subType"
                    class="tw-text-an-flat-disabled-text tw-text-main-xs"
                  >
                    {{ item.subType.label }}
                  </div>
                  <ViewPanelLink
                    :id="item.id"
                    label="Заявка"
                    component="deal-show"
                    :callback="fetch"
                  >
                    {{ item.name || 'Нет названия' }}
                  </ViewPanelLink>
                </div>
              </div>
              <div
                v-if="item.note"
                class="tw-text-an-flat-black-table"
              >
                {{ item.note }}
              </div>
            </template>
            <template #item.status="{ item }">
              <div v-if="item.status">
                <div class="tw-flex ">
                  <div
                    :style="{ backgroundColor: item.status.color }"
                    class="tw-px-[10px] tw-py-[3px] tw-rounded-full"
                  >
                    {{ item.status.name }}
                  </div>
                </div>
              </div>
            </template>
            <template #item.planned="{ item }">
              <div>
                <UserPlannedList :planned-list="item.planned" />
              </div>
            </template>
            <template #item.person="{ item }">
              <div
                v-if="item.person"
                class="tw-flex tw-gap-[4px] tw-items-center"
              >
                <TwBlackListIcon v-if="item.person.blackList" />
                <ViewPanelLink
                  :id="item.person.id"
                  label="Контакт"
                  component="person-show"
                >
                  {{ $Global.peopleName(item.person, 'fi') }}
                </ViewPanelLink>
              </div>
            </template>
            <template #item.lastComment="{ item }">
              <ReadMoreExpander
                v-if="item.lastComment"
                :text="item.lastComment"
                :is-nl-trigger="false"
                :less-length="90"
              />
            </template>
            <template #item.reason="{ item }">
              <div v-if="item.reason">
                <ReadMoreExpander
                  v-if="item.reason.comment"
                  :text="item.reason.comment"
                  :is-nl-trigger="false"
                  :less-length="90"
                />
                <div v-if="item.reason.dateSuspense">
                  <span>Приостановлено до
                    {{ $Global.DateFormat.DateShort(item.reason.dateSuspense) }}
                  </span>
                </div>
              </div>
            </template>
          </TwTableBase>
        </div>
      </TwPanelContentBlock>
    </TwPanelContent>
  </PageViewDefaultWrapper>
</template>

<script>
import API from '@/Api/Crm/Deal'
import PageViewDefaultWrapper from '@/components/core/ViewDefault/PageViewDefaultWrapper'
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink'
import { mapActions } from 'vuex'
import UserPlannedList from '@/components/core/Chronology/UserPlannedList/UserPlannedList'
import TwPanelContent from '@/components/tw-ui/panels/TwPanelContent.vue'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwTableBase from '@/components/tw-ui/table/TwTableBase.vue'
import TwPingPoint from '@/components/tw-ui/ui-kit/TwPingPoint.vue'
import ReadMoreExpander from '@/components/other/text/ReadMoreExpander.vue'
import TwBlackListIcon from '@/components/tw-ui/user/TwBlackListIcon.vue'
import { ServicesSchema } from '@/store/twfilter/servicesSchema'
import tableOptions from './tableOptions'
import DataListTableSorterMethodsMixin from '@/mixins/DataListTableSorterMethodsMixin'

export default {
  name: 'EmployeeDealList',
  components: {
    TwBlackListIcon,
    ReadMoreExpander,
    TwPingPoint,
    TwTableBase,
    TwPanelContentBlock,
    TwPanelContent,
    UserPlannedList,
    ViewPanelLink,
    PageViewDefaultWrapper
  },
  mixins: [ViewPanelShowMixin, DataListTableSorterMethodsMixin],
  props: {
    employeeId: {
      type: [Number, null],
      default: null
    }
  },
  data () {
    return {
      filterSchemaKey: ServicesSchema.DealList.key,
      headers: tableOptions,
      API,
      defaultFilters: {
        sortDirection: 'desc',
        sortField: 'createdAt',
        responsibleUserId: [this.employeeId]
      },
      loading: false,
      permissionDenied: false,

      data: [],
      pagination: {},
      loadingText: 'Загрузка... Пожалуйста подождите',
      noDataText: 'Нет данных',
      noResultsText: 'Не найдено',
      itemsPerPage: 25
    }
  },
  methods: {
    ...mapActions({
      initCurrentFilters: 'twFilter/initCurrentFilters',
      fetchFilterService: 'twFilter/fetchFilterService'
    }),
    // инит схемы фильтров
    async initFiltersBySchemaKey () {
      console.warn('initFiltersBySchemaKey', this.filterSchemaKey)
      if (this.filterSchemaKey) {
        // получение схемы фильтров
        await this.fetchFilterService(this.filterSchemaKey)
        // инит полученного
        await this.initCurrentFilters(this.filterSchemaKey)
      } else {
        return new Error(`Не указан ключ схемы фильтров: ${this.filterSchemaKey}`)
      }
      this.fetch()
    },
    rowClick (itemInfo) {
      this.showDealCard(itemInfo.id, () => {
        this.fecth()
      })
    },
    changePagination (eventData) {
      this.fetch({
        ...eventData
      })
    },
    fetch (params = {}) {
      if (this.loading) {
        return
      }

      if (!this.API) {
        throw Error('not set API')
      }

      this.permissionDenied = false
      this.loading = true
      // eslint-disable-next-line no-param-reassign
      params = { ...params, ...this.defaultFilters }

      this.API.getListNew(params)
        .then((response) => {
          this.data = response.data.data
          if (response.data.meta) {
            this.pagination = response.data.meta
            this.itemsPerPage = response.data.meta.per_page || 25
          }
        })
        .catch((error) => {
          // обработка отсутствия прав на просмотр
          if (error?.response?.status === 403) {
            this.permissionDenied = true
          }
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  mounted () {
    this.initFiltersBySchemaKey()
  }
}
</script>
