import { SERVICES } from '@/Api/Services'
import DealCommission from './DealCommission'

export const AuthAxiosDefaultConfig: any = {
  serviceKey: SERVICES.ACCOUNTING.key
}

export const API: any = {
  DealCommission
}
