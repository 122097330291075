<template>
  <div
    v-if="builderFileId"
    class=""
  >
    <ImportBuilderSiteComparisonList
      ref="list"
      :builder-id="builderId"
      :import-builder-site-id="builderFileId"
      :is-allow-edit-permissions="isAllowEditPermissions"
    />
  </div>
</template>

<script>
import ImportBuilderSiteComparisonList
  from '@/views/BuilderImporter/ImportSiteDeveloperFile/comparision/ImportBuilderSiteComparisonList'

export default {
  name: 'BuilderSiteComparisonBlock',
  components: {
    ImportBuilderSiteComparisonList
  },
  props: {
    info: {
      type: Object,
      required: true
    },
    isAllowEditPermissions: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      processing: false
    }
  },
  computed: {
    builderFileId () {
      return this?.info?.id || null
    },
    builderId () {
      return this.info?.company?.id || null
    }
  }
}
</script>

<style scoped>

</style>
