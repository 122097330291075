<template>
  <PageViewDefaultWrapper
    ref="wrapper"
    header-class="mb-2"
    :permission-denied="permissionDenied"
    relative
  >
    <TwPanelContent class="tw-flex-1">
      <TwPanelContentBlock
        class="tw-flex-1 tw-mb-[6px]"
        :content-classes="''"
      >
        <template #title>
          <div>
            Список медиа контента с отражением хода строительства объекта
          </div>
        </template>
        <template #action>
          <button
            :disabled="!isAllowEdit || loading"
            class="tw-font-manrope tw-font-bold tw-uppercase tw-text-main-xs tw-text-white tw-bg-an-flat-green-btn-bg tw-rounded-sm tw-whitespace-nowrap tw-px-[20px] tw-py-[6px] enabled:hover:tw-bg-an-flat-green-btn-bg-active enabled:hover:tw-shadow disabled:tw-opacity-50"
            @click="addNew"
          >
            Добавить ход строительства
          </button>
        </template>
        <div>
          <TwTableBase
            tbody-classes="tw-text-xs"
            :loading-error-text="loadingErrorText"
            :items="data"
            :headers="columns"
            :loading="loading"
            :fixed-header-margin="0"
            :table-columns-management="false"
            tbody-row-classes="tw-cursor-pointer"
            :pagination="pagination"
            @changePagination="changePagination"
          >
            <template #item.menu="{ item }">
              <ListMenu
                v-if="true"
                offset-y
                :boundary-el="$el"
                :action-list="menuActions"
                :target="item"
              />
            </template>
            <template #item.date="{ item }">
              {{ item.date ? $Global.DateFormat.DateShort(item.date) : '~' }}
            </template>
            <template #item.files="{ item }">
              <div>
                <div
                  v-if="Array.isArray(item.files)"
                  class="tw-flex tw-flex-wrap tw-gap-[8px]"
                >
                  <div
                    v-for="(image, i_index) in item.files"
                    :key="i_index"
                    class="ma-1"
                  >
                    <v-img
                      class="cursor-pointer tw-rounded-sm"
                      style="border: 1px solid lightgrey"
                      width="90"
                      height="90"
                      :src="getFileResizeByKey(image, 'default')"
                      @click="imageShow(i_index, item.files)"
                    />
                  </div>
                </div>
                <small
                  v-if="!Array.isArray(item.files) || !item.files.length"
                  class="text-muted"
                >Отсутствуют</small>
              </div>
            </template>
          </TwTableBase>
        </div>
      </TwPanelContentBlock>
    </TwPanelContent>
    <TwDialog
      v-model="isModalShow"
      max-width="800px"
    >
      <template #header>
        <div class="tw-font-bold tw-text-[18px] tw-text-an-flat-black-main">
          Добавить/Изменить ход строительства
        </div>
      </template>
      <template #body>
        <div v-if="isModalShow && editItemId !== null">
          <RealtyComplexConstructionProgressForm
            :id="editItemId"
            :default-form-fields="{ complexId }"
            @create="hideModal"
            @update="hideModal"
            @cancel="hideModal"
          />
        </div>
      </template>
    </TwDialog>
  </PageViewDefaultWrapper>
</template>

<script>
import { getFileResizeByKey } from '@/plugins/helpers/fileHelpers'
import { API } from '@/Api/ObjectStorage'
import PageViewDefaultWrapper from '@/components/core/ViewDefault/PageViewDefaultWrapper'
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import ListMenu from '@/components/popup/menu/ListMenu'
import RealtyComplexConstructionProgressForm
  from '@/views/Newbuildings/Complex/constructionProgress/RealtyComplexConstructionProgressForm'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwPanelContent from '@/components/tw-ui/panels/TwPanelContent.vue'
import TwTableBase from '@/components/tw-ui/table/TwTableBase.vue'
import tableOptions from './tableOptions'
import DataListMixin from '@/mixins/DataListMixin'
import TwDialog from '@/components/tw-ui/modal/TwDialog.vue'

export default {
  name: 'RealtyComplexConstructionProgress',
  components: {
    TwDialog,
    TwTableBase,
    TwPanelContent,
    TwPanelContentBlock,
    RealtyComplexConstructionProgressForm,
    ListMenu,
    PageViewDefaultWrapper
  },
  mixins: [DataListMixin, ViewPanelShowMixin],
  props: {
    complexId: {
      type: [Number, String, null],
      default: null
    },
    builderId: {
      type: [Number, String, null],
      default: null
    },
    isAllowEdit: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      API: API.ConstructionProgress,
      defaultFilters: {
        complexId: this.complexId
      },
      columns: tableOptions,
      loading: false,
      permissionDenied: false,
      menuActions: [
        {
          code: 'edit',
          label: 'Изменить',
          color: 'primary',
          disabled: false,
          method: this.edit
        },
        {
          code: 'remove',
          isConfirmation: true,
          label: 'Удалить',
          color: 'red',
          disabled: false,
          method: this.delete
        }
      ],

      isModalShow: false,
      editItemId: null
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    getFileResizeByKey: getFileResizeByKey,
    hideModal (event) {
      this.isModalShow = false
      this.editItemId = null
      if (event?.id) {
        this.fetch()
      }
    },
    addNew () {
      console.log('addNew Start')
      this.edit({ id: 0 })
    },
    edit (item) {
      this.editItemId = item?.id || 0
      this.isModalShow = true
      console.warn(item)
    },
    delete (item) {
      this.API.delete(item.id).finally(() => {
        this.refresh()
      })
    },
    imageShow (startIndex, files) {
      console.log('imageShow', startIndex)
      const viewFiles = files.map(file => {
        return {
          ...file,
          path: file.url
        }
      })
      this.$store.dispatch('imageViewer/showImages', {
        files: viewFiles,
        startIndex: startIndex || 0
      })
    }
  }
}
</script>
