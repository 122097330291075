import Vue from 'vue'
import { Module } from 'vuex'
import { RootState } from '@/store/types'
import { TmpCacheState } from '../types'
import { API } from '@/Api/ObjectStorage'
import store from '@/store'

const namespaced = true

export const fias: Module<TmpCacheState, RootState> = {
  namespaced,
  state: {
    items: {},
    fiasObjects: {},
    districts: {}
  },
  actions: {
    objectSearch: function ({ commit }, param) {
      return API.Fias.fiasObject(param)
        .then(({ data }: any) => {
          console.log('objectSearch', data)
          commit('SET_ITEMS', data)
          return data || []
        })
        .catch((error: any) => {
          console.error(error)
        })
    },
    fetchFiasObjectById: function ({ commit }, id: string) {
      commit('SET_FIAS_OBJECT', { id })
      return API.Fias.fiasObjectShort(id)
        .then(({ data }: any) => {
          console.log('fetchFiasObjectById', data.data)
          commit('SET_FIAS_OBJECT', data.data)
        })
        .catch((error: any) => {
          console.error(error)
        })
    },
    fetchDistrictById: function ({ commit }, id: string) {
      commit('SET_DISTRICT', { id })
      return API.Fias.fiasDistrictShort(id)
        .then(({ data }: any) => {
          console.log('fetchDistrictById', data.data)
          commit('SET_DISTRICT', data.data)
        })
        .catch((error: any) => {
          console.error(error)
        })
    }
  },
  mutations: {
    SET_ITEMS (state, payload) {
      console.log('SET_ITEMS', payload)
      // параметр времени запроса
      const reqTime = new Date().getTime()
      if (Array.isArray(payload)) {
        payload.forEach((item) => {
          item.rt = reqTime
          Vue.set(state.items, item.guid, item)
        })
      } else if (payload.guid) {
        payload.rt = reqTime
        Vue.set(state.items, payload.guid, payload)
      } else {
        console.error('Необработанное условие при попытке добавить items в tmpCache')
      }
    },
    SET_FIAS_OBJECT (state, payload) {
      // console.log('SET_FIAS_OBJECT', payload)
      // параметр времени запроса
      const reqTime = new Date().getTime()
      if (Array.isArray(payload)) {
        payload.forEach((item) => {
          item.rt = reqTime
          Vue.set(state.fiasObjects, item.id, item)
        })
      } else if (payload.id) {
        payload.rt = reqTime
        Vue.set(state.fiasObjects, payload.id, payload)
      } else {
        console.error('Необработанное условие при попытке добавить fiasObjects в tmpCache')
      }
    },
    SET_DISTRICT (state, payload) {
      // console.log('SET_FIAS_OBJECT', payload)
      // параметр времени запроса
      const reqTime = new Date().getTime()
      if (Array.isArray(payload)) {
        payload.forEach((item) => {
          item.rt = reqTime
          Vue.set(state.districts, item.id, item)
        })
      } else if (payload.id) {
        payload.rt = reqTime
        Vue.set(state.districts, payload.id, payload)
      } else {
        console.error('Необработанное условие при попытке добавить districts в tmpCache')
      }
    }
  },
  getters: {
    getFiasObjectById: (state) => (id: string) => {
      if (state.fiasObjects[id] === undefined) {
        store.dispatch('tmpCache/fias/fetchFiasObjectById', id)
      }
      return state.fiasObjects[id] || null
    },
    getDistrictById: (state) => (id: string) => {
      if (state.districts[id] === undefined) {
        store.dispatch('tmpCache/fias/fetchDistrictById', id)
      }
      return state.districts[id] || null
    },
    getItemById: (state) => (id: number) => {
      if (state.items[id] === undefined) {
        console.error('НЕТ ТАКОГО ID В КЭШЕ')
      }
      return state.items[id] || null
    },
    cachedItemsList: (state) => {
      return Object.values(state.items) || []
    }
  }
}
