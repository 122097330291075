<template>
  <div class="tw-w-full">
    <div
      v-if="!complexImage && !editing"
      class="tw-text-center tw-text-an-flat-disabled-input-text tw-text-main-sm"
    >
      Не загружено изображение для рендера
    </div>
    <div v-else>
      <div class="tw-mb-[7px] tw-font-semibold">
        {{ editing ? 'Загрузить план ЖК' : 'План ЖК' }}
      </div>
      <TwPanelInfoBlock
        v-if="editing"
        class="tw-my-[12px]"
        has-icon
        type="info"
      >
        <template #title>
          Внимание! При загрузке файла присутствуют ограничения.
        </template>
        <template #text>
          Максмальный размер: <b>10мб</b>. Максимальное разрешение: <b>2500px</b> на <b>2500px</b>
        </template>
      </TwPanelInfoBlock>
      <TwUploadFilesList
        label="Загрузить план"
        file-type="complex_highlight"
        :disabled="!editing"
        :value="inputPlanFileId ? [inputPlanFileId] : []"
        :api="fileApi"
        :is-public="true"
        :multiple="false"
        @input="onInputFile"
        @fileServer="fileImageServerData"
        @removeOldFile="removeOldFile"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import TwUploadFilesList from '@/components/tw-ui/ui-kit/files/TwUploadFilesList.vue'
import { API } from '@/Api/ObjectStorage'
import TwPanelInfoBlock from '@/components/tw-ui/panels/TwPanelInfoBlock.vue'

export default defineComponent({
  name: 'RealtyComplexUploadHighlightPlan',
  components: { TwPanelInfoBlock, TwUploadFilesList },
  props: {
    complexId: {
      type: String,
      required: true
    },
    editing: {
      type: Boolean,
      default: false
    },
    buildingsList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      fileApi: API.File,
      inputPlanFileId: null,
      complexImage: null
      // saveButton: true
    }
  },
  mounted () {
    this.getComplexImage()
  },
  methods: {
    getComplexImage () {
      API.ComplexHighlight.getComplexImage(this.complexId).then(({ data: result }) => {
        console.warn('getComplexImage', result.data)
        this.complexImage = result.data || null
        this.inputPlanFileId = result.data?.fileId || null
        this.$emit('updateComplexImage', this.complexImage)
      })
    },
    savePictureComplex () {
      console.warn('savePictureComplex: ', this.complexImage)
      API.ComplexHighlight.setComplexImage({
        complexId: this.complexId,
        fileId: this.inputPlanFileId,
        imageId: this.inputPlanFileId.length ? this.inputPlanFileId[0] : 0
      }).then(result => {
        const oldPolygons = this.complexImage ? this.complexImage?.houses.slice() : []
        this.complexImage = result?.data?.data
        this.submitUpdateHousesCoordinates(oldPolygons)
      }).finally(() => {
        this.getComplexImage()
      })
    },
    submitUpdateHousesCoordinates (event) {
      console.warn('submitUpdateHousesCoordinates: ', event)
      API.ComplexHighlight.updateHousesCoordinates({
        complexId: this.complexImage.complexId,
        fileId: this.complexImage.fileId,
        imageId: this.complexImage.imageId,
        houses: event
      })
    },
    onInputFile (event) {
      console.warn('onInputFile', event)
      this.inputPlanFileId = Array.isArray(event) && event.length ? event[0] : null
      if (Array.isArray(event) && event.length) {
        this.savePictureComplex()
      }
    },
    fileImageServerData (event) {
      console.warn('fileImageServerData', event)
      this.$emit('fileServerImage', event)
    },
    removeOldFile (event) {
      API.ComplexHighlight.removeComplexImage(this.complexId)
      this.$emit('removeOldFile', event)
    }
  }
})
</script>

<style scoped>

</style>
