import { Module } from 'vuex'
import { RootState } from '@/store/types'

import { SOCKET_EVENT_MAP } from '@/plugins/SocketIoConfig'
import store from '@/store'

const namespaced = true

export const kontur: Module<{}, RootState> = {
  namespaced,
  actions: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    [`SOCKET_${SOCKET_EVENT_MAP.IN.konturCheckAnaliseReady}`]: function ({ state }, message) {
      const { notification = null } = message
      if (notification) {
        store.commit('notify/ADD', {
          title: notification.title,
          desc: notification.description,
          type: notification.type,
          'link-object': notification['link-object'],
          link: notification.link,
          timeout: 30000,
          visible: true
        })
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    [`SOCKET_${SOCKET_EVENT_MAP.IN.konturCheckReady}`]: function ({ state }, message) {
      const { notification = null } = message
      if (notification) {
        store.commit('notify/ADD', {
          title: notification.title,
          desc: notification.description,
          type: notification.type,
          'link-object': notification['link-object'],
          link: notification.link,
          timeout: 30000,
          visible: true
        })
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    [`SOCKET_${SOCKET_EVENT_MAP.IN.konturCheckSingleReady}`]: function ({ state }, message) {
      const { notification = null } = message
      if (notification) {
        store.commit('notify/ADD', {
          title: notification.title,
          desc: notification.description,
          type: notification.type,
          'link-object': notification['link-object'],
          link: notification.link,
          timeout: 30000,
          visible: true
        })
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    [`SOCKET_${SOCKET_EVENT_MAP.IN.konturCheckSingleSent}`]: function ({ state }, message) {
      const { notification = null } = message
      if (notification) {
        store.commit('notify/ADD', {
          title: notification.title,
          desc: notification.description,
          type: notification.type,
          'link-object': notification['link-object'],
          link: notification.link,
          timeout: 30000,
          visible: true
        })
      }
    }
  }
}
