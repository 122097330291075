<template>
  <TwPanelContent>
    <div class="tw-flex-1">
      <DiscountMatrixList :company-id="companyId" />
    </div>
  </TwPanelContent>
</template>

<script>
import DiscountMatrixList from '@/views/Mortgage/Requirement/DiscountMatrix/DiscountMatrixList'
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import TwPanelContent from '@/components/tw-ui/panels/TwPanelContent.vue'

export default {
  name: 'DiscountMatrixTab',
  components: { TwPanelContent, DiscountMatrixList },
  mixins: [ViewPanelShowMixin],
  props: {
    companyId: {
      type: Number,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
