<template>
  <div class="tw-font-manrope tw-text-main-sm2 tw-max-w-[100%] tw-px-[26px] tw-py-[18px] tw-bg-white tw-min-h-screen">
    <template v-if="isCardContentVisible">
      <TwPanelTitle>
        <template #left>
          <div class="tw-text-h4 tw-font-bold">
            <div
              v-if="isNew"
              class="title text-muted"
            >
              Новый договор
            </div>
            <div v-else-if="data">
              <span>Договор № {{ data.contractNum }}</span>
              <span class="tw-text-an-flat-disabled-text tw-font-medium"> # {{ data.id }}</span>
            </div>
            <div v-else>
              ~
            </div>
          </div>
        </template>
        <template #right>
          <MenuEntityActions
            v-if="!isNew"
            :permissions="permissions"
            @delete="deleteCardItem"
          />
        </template>
      </TwPanelTitle>
      <TwPanelTabsFull
        v-model="currentTab"
        :items="tabList"
      >
        <template #content.main>
          <TwPanelContent v-if="data">
            <div class="tw-flex-1">
              <TwPanelContentBlock class="tw-mb-[6px]">
                <template #title>
                  <div>
                    О договоре
                  </div>
                </template>
                <div>
                  <DealContractShowWrapper
                    :dictionary="dictionary"
                    :info="data"
                  />
                </div>
              </TwPanelContentBlock>
              <TwPanelContentBlock class="tw-mb-[6px]">
                <template #title>
                  <div>
                    Статус
                  </div>
                </template>
                <div>
                  <ContractDealActionsBlock
                    :info="data"
                    :dictionary="dictionary"
                    @refresh="refresh"
                  />
                </div>
              </TwPanelContentBlock>
            </div>
            <div
              v-if="dealDocument"
              class="tw-flex-1"
            >
              <TwPanelContentBlock class="tw-mb-[6px]">
                <template #title>
                  <div>
                    Документ по сделке
                  </div>
                </template>
                <div
                  v-if="dealDocument.deal"
                  class="tw-flex tw-gap-[12px] tw-flex-col"
                >
                  <TwPanelContentBlockFieldView>
                    <template #title>
                      Тип
                    </template>
                    <span v-if="dealDocument.deal.type">
                      {{ dealDocument.deal.type.label }}
                    </span>
                    <span v-if="dealDocument.deal.subtype">
                      / {{ dealDocument.deal.subtype.label }}
                    </span>
                  </TwPanelContentBlockFieldView>
                  <TwPanelContentBlockFieldView>
                    <template #title>
                      Статус заявки
                    </template>
                    <div v-if="dealDocument.deal.status">
                      <span>{{ dealDocument.deal.status.name }}</span>
                    </div>
                  </TwPanelContentBlockFieldView>
                  <TwPanelContentBlockFieldView>
                    <template #title>
                      Заявка
                    </template>
                    <div v-if="dealDocument.deal.id">
                      <ViewPanelLink
                        :id="dealDocument.deal.id"
                        label="Заявка"
                        component="deal-show"
                        :callback="refresh"
                      >
                        {{ dealDocument.deal.name }} #{{ dealDocument.deal.id }}
                      </ViewPanelLink>
                    </div>
                  </TwPanelContentBlockFieldView>
                  <TwPanelContentBlockFieldView v-if="dealDocument.free">
                    <template #title>
                      Бесплатная услуга на основании
                    </template>
                    <div>
                      <div>
                        {{ dealDocument.freeCondition && dealDocument.freeCondition.label }}
                      </div>
                      <div>
                        <div v-if="dealDocument.relatedContract">
                          <small>
                            Связанный договор:
                            <ViewPanelLink
                              :id="dealDocument.relatedContract.id"
                              class="font-weight-medium"
                              label="Договор"
                              component="deal-contract-show"
                            >
                              № {{ dealDocument.relatedContract.contractNum }}
                            </ViewPanelLink>
                          </small>
                        </div>
                        <div v-if="dealDocument.relatedContract && dealDocument.relatedContract.deal">
                          <small>
                            Связанная сделка:
                            <ViewPanelLink
                              :id="dealDocument.relatedContract.deal.id"
                              class="font-weight-medium"
                              label="Заявка"
                              component="deal-show"
                            >
                              <span
                                v-if="dealDocument.relatedContract.deal.status"
                                :class="dealDocument.relatedContract.deal.status.code === 'success' ? 'tw-text-an-flat-green-btn-bg-active tw-bg-an-flat-green-btn-bg-active/20':'tw-text-an-flat-orange-warning tw-bg-an-flat-orange-lighten'"
                              >
                                {{ dealDocument.relatedContract.deal.status.name }} - {{ dealDocument.relatedContract.deal.name }}
                              </span>
                            </ViewPanelLink>
                          </small>
                        </div>
                        <div v-if="dealDocument.relatedDeal && dealDocument.relatedDeal.id">
                          <small>Связанная сделка:</small>
                          <div>
                            <ViewPanelLink
                              :id="dealDocument.relatedDeal.id"
                              label="Заявка"
                              component="deal-show"
                            >
                              {{ dealDocument.relatedDeal.name }}
                            </ViewPanelLink>
                            <span
                              title="Статус связанной сделки"
                              class="tw-ml-[6px] tw-px-[10px] tw-py-[2px] tw-text-main-sm2 tw-font-medium tw-rounded-sm"
                              :class="dealDocument.relatedDeal.status.code === 'success' ? 'tw-text-an-flat-green-btn-bg-active tw-bg-an-flat-green-btn-bg-active/20':'tw-text-an-flat-orange-warning tw-bg-an-flat-orange-lighten'"
                            >
                              {{ dealDocument.relatedDeal.status.name }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TwPanelContentBlockFieldView>

                  <TwPanelContentBlockFieldView>
                    <template #title>
                      Дата сделки
                    </template>
                    <div v-if="dealDocument && dealDocument.finishDate">
                      <span>{{ $Global.DateFormat.DateFull(dealDocument.finishDate) }}</span>
                    </div>
                  </TwPanelContentBlockFieldView>
                </div>
              </TwPanelContentBlock>
            </div>
          </TwPanelContent>
        </template>
      </TwPanelTabsFull>
    </template>
    <!--
      Блок заполняющий пространство при отсутствуии контента
      для прижатия блока сохранения к нижней части страницы
    -->
    <div class="tw-flex tw-justify-center tw-items-center">
      <TwCircleLoader
        v-if="loading && !isCardContentVisible"
        width="50px"
        height="50px"
      />
      <div v-else-if="error">
        <PermissionDenied :text="error === 403 ? 'Недостаточно прав для просмотра!' : 'Ошибка соединения - Обратитесь к администратору!'" />
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/Api/Crm/Deal/Contract'
import CardMixin from '@/mixins/CardMixin'
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import DealContractShowWrapper from '@/views/Company/Contract/main/DealContractShowWrapper'
import MenuEntityActions from '@/components/core/ViewPanel/Card/entity-actions/menuEntityActions'
import PermissionDenied from '@/views/PermissionDenied'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink'
import ContractDealActionsBlock from '@/views/Company/Contract/main/ContractDealActionsBlock'
import TwPanelTitle from '@/components/tw-ui/panels/TwPanelTitle.vue'
import TwPanelTabsFull from '@/components/tw-ui/panels/TwPanelTabsFull.vue'
import TwPanelContent from '@/components/tw-ui/panels/TwPanelContent.vue'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'

export default {
  name: 'DealContractCard',
  components: {
    TwPanelContentBlockFieldView,
    TwCircleLoader,
    TwPanelContentBlock,
    TwPanelContent,
    TwPanelTabsFull,
    TwPanelTitle,
    ContractDealActionsBlock,
    DealContractShowWrapper,
    ViewPanelLink,
    PermissionDenied,
    MenuEntityActions
  },
  mixins: [CardMixin, ViewPanelShowMixin],
  data () {
    return {
      editBlock: false,
      currentTab: 'main',
      API: API
    }
  },
  computed: {
    tabList () {
      return [
        {
          label: 'Общая информация',
          key: 'main'
        }
      ]
    },
    dealDocument () {
      return this.otherData?.dealDocument || null
    }
  },
  methods: {
    changeStatus () {
      this.$emit('callback', {})
      this.refresh()
    },
    showVariantSelection () {
      console.warn(this.$options.name, 'showVariantSelection', this.id, this.data?.id)
      this.showVariantSelectionCard({
        initialValues: {
          leadId: this.data?.id,
          personId: this.data?.person?.id
        },
        workToClose: true
      })
    },
    refresh (event) {
      console.warn('refresh', this.$options.name, event)
      this.editBlock = false
      if (this.isNew) {
        this.editMain = true
        console.log('NEW', this.$options.name)
        this.$emit('callback')
      } else {
        this.fetch()
      }
    }
  }
}
</script>
