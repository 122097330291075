<template>
  <div>
    <TwInputText
      :value="valueCalc"
      type="date"
      :dense="true"
      :show-label="false"
      :persistent-label="false"
      @input="onInput"
    />
  </div>
</template>

<script>
import TwInputText from '@/components/tw-ui/ui-kit/input/TwInputText.vue'

export default {
  name: 'ADateSimpleSelect',
  components: { TwInputText },
  props: {
    defaultCurrent: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: () => null
    }
  },
  computed: {
    valueCalc () {
      return this.value?.value?.[0]
    }
  },
  mounted () {
    if (!this.value && this.defaultCurrent) {
      const currDate = new Date().toISOString().slice(0, 10)
      this.$emit('input', currDate)
    }
  },
  methods: {
    onInput (event) {
      this.$emit('input', event)
    }
  }
}
</script>
