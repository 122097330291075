<template>
  <div
    v-if="formProcessing"
    class="tw-p-5 tw-text-center tw-m-auto"
  >
    <TwCircleLoader />
  </div>
  <div v-else>
    <div style="display: none">
      {{ isEdited }}
    </div>
    <div class="tw-container tw-flex tw-flex-col tw-gap-[12px]">
      <TwSelectRelations
        v-model="form.builderId"
        :disabled="formProcessing || !!defaultFormFields.builderId"
        :multi="false"
        label="Застройщик"
        list-label="Найти застройщика"
        relation-name="developer"
        :dense="true"
        :persistent-label="true"
      />
      <TwSelect
        v-model="form.type"
        label="Тип предложения"
        placeholder="Выбрать"
        :options="dictionary.type"
        :error-text="$v.form.type.$invalid ? '*Обязательное поле':''"
        :dense="true"
        :persistent-label="true"
      />
      <TwSelectRelations
        v-model="form.complexesId"
        :disabled="formProcessing"
        :multi="true"
        label="Для ЖК"
        list-label="Найти ЖК"
        relation-name="complex"
        :dense="true"
        :default-filters="complexFilters"
      />
      <TwTextarea
        v-model="form.crmDescription"
        rows="3"
        resize="y"
        placeholder="Внутреннее(для CRM) описание для риелтора"
        :disabled="formProcessing"
        :dense="true"
        :show-label="true"
      />

      <TwInputText
        v-model="form.siteName"
        type="text"
        placeholder="Название (для сайта)"
        :error-text="$v.form.siteName.$invalid ? '*Обязательное поле':''"
        :disabled="formProcessing"
        :show-label="true"
        :dense="true"
        :persistent-label="true"
      />
      <TwTextarea
        v-model="form.siteShortDescription"
        rows="3"
        resize="y"
        placeholder="Краткое описание (для сайта)"
        :disabled="formProcessing"
        :dense="true"
        :show-label="true"
      />
      <TwTextarea
        v-model="form.siteDescription"
        rows="3"
        resize="y"
        placeholder="Полное описание (для сайта)"
        :disabled="formProcessing"
        :dense="true"
        :show-label="true"
      />
      <hr>
      <strong>Настройка публикации на сайте и в подборе CRM:</strong>
      <TwInputText
        v-model="form.dateTo"
        type="date"
        class="tw-flex-1"
        placeholder="Действует до"
        :disabled="formProcessing"
        :dense="true"
        :show-label="true"
        :persistent-label="true"
      />
      <TwInputSwitch
        v-model="form.publish"
        input-class-checked="checked:tw-bg-an-flat-green"
        :disabled="formProcessing"
        class="tw-z-0"
      >
        <template #label>
          <span class="tw-relative tw-z-0 tw-text-an-flat-grey-4 tw-font-manrope tw-font-semibold tw-text-[14px]">
            {{ form.publish ? 'Активна' : 'Не активна' }}
          </span>
        </template>
      </TwInputSwitch>
    </div>
    <TwCardSimpleFormSaveBlock
      class="tw-mt-[15px]"
      :loading="formProcessing"
      :cancel-confirm="isEdited"
      :is-sticky="true"
      :disable-success="!isEdited || formProcessing"
      :disable-cancel="formProcessing || (formType === 'create' && !isEdited)"
      :visible="true"
      @success="handleSubmit"
      @cancel="handleCancel"
    >
      <template #info>
        <!-- Результат публикации в человеко понятном формате -->
        <div
          v-if="$Global.DateFormat.hasDateExpiration(form.dateTo) || !form.publish"
          class="tw-text-an-flat-red-main tw-py-[6px]"
        >
          <span class="tw-font-medium">
            Внимание! Данное предложение не опубликовано!
          </span>
          <div
            v-if="$Global.DateFormat.hasDateExpiration(form.dateTo)"
            class="tw-text-an-flat-red-main"
          >
            Истёк срок действия
          </div>
          <div
            v-if="!form.publish"
            class="tw-text-an-flat-red-main"
          >
            Активность отключена
          </div>
        </div>
        <div
          v-else
          class="tw-text-an-flat-green-btn-bg tw-text-center tw-py-[6px]"
        >
          <span class="tw-font-medium">
            Опубликовано!
            <br>
            Данное предложение увидят посетители сайта и риелторы в подборе!
          </span>
        </div>
      </template>
    </TwCardSimpleFormSaveBlock>
  </div>
</template>

<script>
import builderStockAPI from '@/Api/ObjectStorage/Builder/stock'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import { RouteKeys } from '@/router/types'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import { required } from 'vuelidate/lib/validators'
import TwSelectRelations from '@/components/tw-ui/ui-kit/input/select-relations/TwSelectRelations.vue'
import TwInputSwitch from '@/components/tw-ui/ui-kit/input/TwInputSwitch.vue'
import TwInputText from '@/components/tw-ui/ui-kit/input/TwInputText.vue'
import TwTextarea from '@/components/tw-ui/ui-kit/input/TwTextarea.vue'
import TwSelect from '@/components/tw-ui/ui-kit/input/TwSelect.vue'
import TwCardSimpleFormSaveBlock from '@/components/tw-ui/forms/TwCardSimpleFormSaveBlock.vue'

export default {
  name: 'RealtyBuilderStockForm',
  components: {
    TwCardSimpleFormSaveBlock,
    TwSelect,
    TwTextarea,
    TwInputText,
    TwInputSwitch,
    TwSelectRelations,
    TwCircleLoader
  },
  mixins: [FormCreateEditMixin],
  props: {
    disableRedirect: {
      type: Boolean,
      default: false
    },
    defaultFormFields: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      API: builderStockAPI,
      currentDate: new Date().toISOString().slice(0, 10),
      descTextLengthRules: [
        v => (v || '').length <= 150 || 'Максимальная длинна описания 150 символов'
      ],
      form: {
        publish: false,
        siteName: null,
        type: 'action',
        complexesId: [],
        builderId: null,
        dateTo: null,
        crmDescription: null,
        siteDescription: null,
        siteShortDescription: null
      }
    }
  },
  validations () {
    return {
      form: {
        siteName: {
          required
        },
        type: {
          required
        }
      }
    }
  },
  computed: {
    complexFilters () {
      return {
        builderId: this.form?.builderId ? [this.form.builderId] : []
      }
    }
  },
  methods: {
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          const adaperMap = {
            // from(show): to(send)
            complexes: 'complexesId',
            builder: 'builderId'
          }
          for (const key in this.preset) {
            if (key === 'builder') {
              this.$set(this.form, adaperMap[key], (this.preset[key] && this.preset[key].id) || this.defaultFormFields[adaperMap[key]])
            } else if (key === 'complexes' && Array.isArray(this.preset[key])) {
              this.$set(this.form, adaperMap[key], this.preset[key].map(complex => complex.id))
            } else if (key === 'type' && this.preset[key]) {
              this.$set(this.form, key, this.preset[key]?.value || null)
            } else {
              this.$set(this.form, key, this.preset[key])
            }
          }
          if (this.defaultFormFields?.builderId && !this.form.builderId) {
            this.$set(this.form, 'builderId', this.defaultFormFields.builderId)
          }
        }
        resolve()
      })
    },
    handleSubmit () {
      console.warn('handleSubmit', this.form, this.$v.form.$invalid)
      this.$v.$reset()
      this.$v.form.$touch()
      if (!this.$v.form.$invalid) {
        if (this.formProcessing === true) {
          return
        }

        this.formProcessing = true
        const request = this.formType === 'create'
          ? this.API.store(this.form)
          : this.API.update(this.targetId, this.form)

        request.then(({ data }) => {
          if (this.formType === 'create') {
            this.$emit('create', data.data)
            // если текущий путь равен пути создания то редирект в просмотр
            if (this.$route.name === `${RouteKeys.lead}-create`) {
              this.$router.replace({
                name: `${RouteKeys.lead}-show`,
                params: { id: data.data.id }
              })
            }
          } else {
            this.$emit('update', data.data)
          }
        }).finally(() => {
          this.formProcessing = false
        })
      }
    }
  }
}
</script>
