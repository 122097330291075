export default class YandexMetrika {
  isDev: boolean
  metrikaId: any
  options: any
  ReachEventId = {
    SupportTaskCreate: 'support-task-create'
  }

  constructor (router: any) {
    // console.warn('vue router', router)
    this.isDev = process.env.NODE_ENV !== 'production'
    this.metrikaId = process.env?.VUE_APP_YM_ID || null
    this.options = {
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true,
      defer: true // отключить автоматическую отправку данных о просмотрах
    }
    console.warn('Yandex Metrika Init', this)
    if (!this.isDev) {
      window.ym(this.metrikaId, 'init', this.options)
    } else {
      console.log('Yandex Metrika disable for development!')
    }

    router.afterEach((to: any, from: any) => {
      // Только если изменился путь - иначе отправляет отчет на каждое изменения адреса и параметров
      if (to.path !== from.path) {
        this.hit()
      }
    })
  }

  // Отправка данных о просмотре. Обычно используется на страницах, реализованных с использованием AJAX или и Flash.
  // https://yandex.ru/support/metrica/objects/hit.html
  public hit (otherParams = {}) {
    const path = location?.pathname || ''
    const params = {
      path,
      query: location?.search || '',
      hash: location?.hash || '',
      title: document?.title || '',
      referer: document?.referrer || '',
      params: {
        source: 'page',
        version: 1,
        ...otherParams
      }
      // TODO: pass title: <new page title>
      // This will need special handling because router.afterEach is called *before* DOM is updated.
    }

    // не отправлять в DEV режиме
    if (this.isDev) {
      console.log('DEV - YandexMetrika hit: ', path, params)
      return
    }

    try {
      window.ym(this.metrikaId, 'hit', path, params)
    } catch (e) {
      console.error('YandexMetrika hit error:', e)
    }
  }

  // Передача информации о достижении
  // https://yandex.ru/support/metrica/objects/reachgoal.html
  public reach (targetEventId = '', params = {}) {
    // не отправлять в DEV режиме
    if (this.isDev) {
      console.log('YandexMetrika reach: ', targetEventId, params)
      return
    }

    if (!targetEventId) {
      console.error('YandexMetrika reach: ERROR targetEventId', targetEventId, params)
      return
    }

    try {
      window.ym(this.metrikaId, 'reachGoal', targetEventId, params)
    } catch (e) {
      console.error('YandexMetrika reachGoal error:', e)
    }
  }
}
