<template>
  <TwPanelContent class="tw-mt-[20px]">
    <template v-if="Array.isArray(list) && list.length">
      <div class="tw-flex-1">
        <TwPanelContentBlock class="tw-mb-[6px]">
          <template #title>
            Действие с отказом
          </template>
          <div v-if="latestBounceItemId && data && (!data.status || (data.status && data.status.value === 'processing'))">
            <div class="tw-mb-[12px]">
              <TwPanelContentBlockFieldView>
                <template #title>
                  Дата отказа
                </template>
                <div v-if="data.createdAt">
                  {{ $Global.DateFormat.DateTimeShortMonthLong(data.createdAt) }}
                </div>
              </TwPanelContentBlockFieldView>
            </div>
            <div class="tw-mb-[12px]">
              <TwPanelContentBlockFieldView>
                <template #title>
                  Причина отказа
                </template>
                <div class="tw-font-medium">
                  <div
                    v-if="data.reasonRefusal"
                    v-html="$Global.nl2br(data.reasonRefusal)"
                  />
                  <div v-else>
                    Не заполнено
                  </div>
                </div>
              </TwPanelContentBlockFieldView>
            </div>
            <div>
              <div class="tw-flex tw-flex-col tw-gap-[15px]">
                <TwSelect
                  v-model="form.status"
                  label="Результат"
                  placeholder="Выбрать"
                  :dense="true"
                  :disable-error-border="true"
                  :persistent-label="true"
                  :options="dictionary.status"
                  :error-text="statusError"
                  :disabled="formProcessing"
                  @input="$v.form.$touch()"
                />
                <div
                  v-if="form.status === 'processing'"
                  class="tw-text-an-flat-orange-warning tw-font-medium"
                >
                  * Пока решения от клиента нет или до него не дозвонились
                </div>

                <TwSelectRelations
                  v-if="form.status && form.status === 'returned'"
                  v-model="form.newResponsibleUserId"
                  label="Ответственный"
                  placeholder="Выбрать"
                  list-label="Выбор сотрудника"
                  relation-name="user"
                  :disable-error-border="true"
                  :persistent-label="true"
                  :multi="false"
                  :dense="true"
                  :error-text="form.newResponsibleUserId ? '':'Обязательное поле'"
                  :disabled="formProcessing"
                />

                <TwTextarea
                  v-model="form.comment"
                  rows="5"
                  :persistent-label="true"
                  label="Комментарий"
                  maxlength="250"
                  show-length
                  resize="y"
                  :disabled="formProcessing"
                  :error-text="commentError"
                  :show-label="true"
                />
              </div>
              <TwCardSimpleFormSaveBlock
                class="tw-mt-[15px]"
                :loading="formProcessing"
                :cancel-hide="true"
                :is-sticky="true"
                :disable-success="formProcessing"
                :disable-cancel="formProcessing"
                :visible="true"
                @success="submitUpdate"
              />
            </div>
          </div>
          <div
            v-else
            class="tw-text-center tw-text-an-flat-gray-4"
          >
            Отказ обработан
          </div>
        </TwPanelContentBlock>
        <TwPanelContentBlock class="tw-mb-[6px]">
          <template #title>
            Данные в заявке
          </template>
          <div v-if="dealInfo && Array.isArray(dealInfo) && dealInfo.length">
            <div class="tw-flex tw-flex-col tw-gap-[12px]">
              <TwPanelContentBlockFieldView
                v-for="(item, index) in dealInfo"
                :key="index"
              >
                <template #title>
                  <div>
                    {{ item.name }}
                  </div>
                </template>
                <div>
                  {{ item.value }}
                </div>
              </TwPanelContentBlockFieldView>
            </div>
          </div>
          <div v-else>
            <div>Данных нет</div>
          </div>
        </TwPanelContentBlock>
        <TwPanelContentBlock class="tw-mb-[6px]">
          <template #title>
            Статистика по заявке
          </template>
          <div v-if="dealChronologyStat.stat && Array.isArray(dealChronologyStat.stat) && dealChronologyStat.stat.length">
            <div class="tw-mb-[12px] tw-flex tw-flex-col tw-gap-[12px]">
              <TwPanelContentBlockFieldView
                v-for="(item, index) in dealChronologyStat.stat"
                :key="index"
              >
                <template #title>
                  <div>
                    {{ item.attachEntityName }}
                  </div>
                </template>
                <div>
                  {{ item.count }}
                </div>
              </TwPanelContentBlockFieldView>
            </div>
          </div>
          <div v-else>
            <div>Данных по статистике нет</div>
          </div>
        </TwPanelContentBlock>
      </div>
      <div class="tw-flex-1 tw-max-w-[500px]">
        <TwPanelContentBlock
          class="tw-mb-[6px]"
          :content-classes="''"
        >
          <template #title>
            История отказов по заявке
          </template>
          <template #default>
            <div
              v-if="Array.isArray(bounceHistoryList) && bounceHistoryList.length"
              class="tw-text-main-sm tw-flex tw-flex-col tw-items-start tw-gap-[10px] tw-w-full"
            >
              <template v-for="(item, index) in bounceHistoryList">
                <div
                  v-if="item.status"
                  :key="index"
                  class="tw-w-full tw-px-[14px] tw-py-[15px] tw-border-2 tw-rounded-[14px] tw-border-white"
                >
                  <div class="tw-flex tw-justify-between tw-mb-[15px]">
                    <div
                      v-if="item.status"
                      class="tw-font-medium"
                    >
                      {{ item.status && item.status.label }}
                    </div>
                    <div class="tw-text-right tw-text-main-xs">
                      <div class="tw-text-an-flat-gray-4">
                        {{ $Global.DateFormat.DateTimeShortMonthLong(item.createdAt) }}
                      </div>
                      <div>
                        <ViewPanelLink
                          v-if="item.checkUserId"
                          :id="item.checkUserId"
                          component="employee-show"
                        >
                          <EmployeeName :id="item.checkUserId" />
                        </ViewPanelLink>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="item.reasonRefusal"
                    class="tw-mb-[15px]"
                  >
                    <div class="tw-text-main-sm2 tw-text-an-flat-gray-4">
                      Причина отказа:
                    </div>
                    <div v-html="$Global.nl2br(item.reasonRefusal)" />
                  </div>
                  <div class="tw-mb-[15px]">
                    <div class="tw-text-main-sm2 tw-text-an-flat-gray-4">
                      Ответственный:
                    </div>
                    <ViewPanelLink
                      v-if="item.dealNewResponsibleUserId"
                      :id="item.dealNewResponsibleUserId"
                      component="employee-show"
                    >
                      <EmployeeName :id="item.dealNewResponsibleUserId" />
                    </ViewPanelLink>
                    <ViewPanelLink
                      v-else-if="item.dealResponsibleUserId"
                      :id="item.dealResponsibleUserId"
                      component="employee-show"
                    >
                      <EmployeeName :id="item.dealResponsibleUserId" />
                    </ViewPanelLink>
                  </div>
                  <div class="tw-mb-[15px]">
                    <div class="tw-text-main-sm2 tw-text-an-flat-gray-4">
                      Комментарий:
                    </div>
                    <ReadMoreExpander
                      :text="item.comment"
                      :less-length="250"
                    />
                  </div>
                </div>
              </template>
            </div>
            <div
              v-else
              class="tw-text-an-flat-disabled-text tw-text-center tw-p-[5px]"
            >
              Обработанные отказы отсутствуют
            </div>
          </template>
        </TwPanelContentBlock>
      </div>
    </template>
    <div
      v-else
      class="tw-w-full tw-text-center tw-text-an-flat-disabled-text"
    >
      По данной заявке отказы отсутствуют
    </div>
  </TwPanelContent>
</template>

<script>
import { defineComponent } from 'vue'
import API from '@/Api/Crm/DealBounceHandling'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'
import TwPanelContent from '@/components/tw-ui/panels/TwPanelContent.vue'
import TwSelect from '@/components/tw-ui/ui-kit/input/TwSelect.vue'
import TwSelectRelations from '@/components/tw-ui/ui-kit/input/select-relations/TwSelectRelations.vue'
import TwTextarea from '@/components/tw-ui/ui-kit/input/TwTextarea.vue'
import { required } from 'vuelidate/lib/validators'
import TwCardSimpleFormSaveBlock from '@/components/tw-ui/forms/TwCardSimpleFormSaveBlock.vue'
import EmployeeName from '@/components/other/employee/EmployeeName.vue'
import ReadMoreExpander from '@/components/other/text/ReadMoreExpander.vue'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink.vue'

export default defineComponent({
  name: 'DealBounceHandlingTab',
  components: { ViewPanelLink, ReadMoreExpander, EmployeeName, TwCardSimpleFormSaveBlock, TwTextarea, TwSelectRelations, TwSelect, TwPanelContent, TwPanelContentBlockFieldView, TwPanelContentBlock },
  props: {
    dealData: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      isEdit: false,
      loading: false,
      formProcessing: false,
      data: null,
      dictionary: null,
      list: [],
      form: {
        status: null,
        comment: '',
        newResponsibleUserId: null
      },
      dealChronologyStat: {},
      dealInfo: [],
      errorSubmit: null
    }
  },
  validations () {
    return {
      form: {
        status: {
          required
        },
        comment: {
          required
        },
        newResponsibleUserId: {
          required
        }
      }
    }
  },
  computed: {
    statusError () {
      const errors = []
      this.$v?.form?.$touch()
      if (!this.$v.form.status.$dirty) return errors
      if (!this.$v.form.status.required) {
        errors.push('Поле обязательное')
      }
      if (Array.isArray(this.errorSubmit?.status) && this.errorSubmit?.status.length) {
        return this.errorSubmit.status
      }
      return errors
    },
    commentError () {
      const errors = []
      this.$v?.form?.$touch()
      if (!this.$v.form.comment.$dirty) return errors
      if (!this.$v.form.comment.required) {
        errors.push('Поле обязательное')
      }
      if (Array.isArray(this.errorSubmit?.comment) && this.errorSubmit?.comment.length) {
        return this.errorSubmit.comment
      }
      return errors
    },
    latestBounceItemId () {
      return Array.isArray(this.list) && this.list.length ? this.list[0]?.id : null
    },
    bounceHistoryList () {
      return Array.isArray(this.list) ? this.list.filter(i => i.status?.value) : []
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    submitUpdate () {
      this.errorSubmit = null
      this.formProcessing = true
      API.update(this.latestBounceItemId, this.form)
        .then(({ data }) => {
          console.warn(this.$options.name, 'submitUpdate', data)
          this.isEdit = false
          this.$emit('refresh')
          this.$nextTick(() => {
            this.getList()
          })
        })
        .catch((error) => {
          console.error(this.$options.name, 'submitUpdate', error)
          this.errorSubmit = error?.response?.data || 'Ошибка!'
        })
        .finally(() => {
          this.formProcessing = false
        })
    },
    fetchLatest () {
      if (!this.latestBounceItemId) {
        console.warn('Нет отказов требующих обработки!')
        return
      }
      this.loading = true
      console.warn(this.$options.name, 'fetchLatest', this.latestBounceItemId)
      API.show(this.latestBounceItemId)
        .then(({ data }) => {
          console.warn(this.$options.name, 'fetchLatest', data)
          this.data = data.data || null
          this.dictionary = data.dictionary || null
          // Заполняю форму
          this.form = {
            status: this.data.status?.value || null,
            comment: this.data.comment || '',
            newResponsibleUserId: this.data.dealResponsibleUserId || null
          }
        })
        .catch((error) => {
          console.error(this.$options.name, 'fetchLatest', error)
          this.data = null
        })
        .finally(() => {
          this.loading = false
        })
    },
    getList () {
      this.loading = true
      API.getListFromDeal({ dealId: this.dealData.id })
        .then(({ data }) => {
          console.warn(this.$options.name, 'getList', data)
          this.dealChronologyStat = data.dealChronologyStat || {}
          this.dealInfo = data.dealInfo || []
          this.list = data.data || []
          this.$nextTick(() => {
            if (this.list.length) {
              this.fetchLatest()
            }
          })
        })
        .catch((error) => {
          console.error(this.$options.name, 'getList', error)
          this.list = []
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
})
</script>

<style scoped>

</style>
