import { AxiosResponse } from 'axios'
import HTTP from '@/Api/http'
const path = '/person'

export default {
  listInit (): Promise<AxiosResponse> {
    const $config: any = {}
    return HTTP.get(`${path}/list-init`, $config)
  },
  getListNew (param?: object, otherConfig?: object): Promise<AxiosResponse> {
    const $config = {
      params: param,
      ...(otherConfig || {})
    }
    return HTTP.get(`${path}/list`, $config)
  },
  getListSearch (param?: object, otherConfig?: object): Promise<AxiosResponse> {
    const $config = {
      params: param,
      ...(otherConfig || {})
    }
    return HTTP.get(`${path}/search`, $config)
  },
  getList (param?: object, otherConfig?: object): Promise<AxiosResponse> {
    const $config = {
      params: param,
      ...(otherConfig || {})
    }

    return HTTP.get(`${path}`, $config)
  },
  create (): Promise<AxiosResponse> {
    const $config = {}

    return HTTP.get(`${path}/create`, $config)
  },
  store (data: object): Promise<AxiosResponse> {
    return HTTP.post(`${path}`, data)
  },
  edit (id: number): Promise<AxiosResponse> {
    const $config = {}

    return HTTP.get(`${path}/${id}`, $config)
  },
  update (id: number, data: object): Promise<AxiosResponse> {
    return HTTP.put(`${path}/${id}`, data)
  },
  show (id: number | string): Promise<AxiosResponse> {
    const $config = {}
    return HTTP.get(`${path}/${id}`, $config)
  },
  showShort (id: number | string): Promise<AxiosResponse> {
    const $config = {}
    return HTTP.get(`${path}/${id}/short-info`, $config)
  },
  getLeadDeal (id: number | string): Promise<AxiosResponse> {
    const $config = {}
    return HTTP.get(`${path}/${id}/lead-deal`, $config)
  },
  delete (id: number): Promise<AxiosResponse> {
    const $config = {}

    return HTTP.delete(`${path}/${id}`, $config)
  }
}
