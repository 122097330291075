<template>
  <div>
    <TwSelectRelations
      v-model="form.complexId"
      class="tw-mt-[18px]"
      :disabled="formProcessing"
      label="Выберите ЖК"
      list-label="Выбрать ЖК"
      relation-name="complex"
      :error-text="$v.form.complexId.$invalid ? 'Заполните поле':''"
      :default-filters="complexFilters"
    />
    <div class="tw-mt-[18px]">
      <TwCheckBox
        v-model="form.active"
        :disabled="formProcessing"
        label="Активно"
      />
    </div>
    <div class="tw-mt-[18px]">
      <TwCheckBox
        v-model="form.blockReservation"
        :disabled="formProcessing"
        label="Заблокировать бронирование"
      />
    </div>
    <div>
      <TwTextarea
        v-model="form.description"
        rows="5"
        class="tw-mt-[12px]"
        placeholder="Описание"
        :disabled="formProcessing"
        :show-label="true"
      />
    </div>
    <div class="">
      <div class="tw-font-medium tw-mt-[18px]">
        Параметры для блокировки
      </div>
      <div class="tw-mt-[26px]">
        <TwSelect
          v-model="form.filter.roomCount"
          label="По комнатности"
          :multi="true"
          :disabled="formProcessing"
          :options="dictionary.roomCount"
        />
      </div>
    </div>
    <div>
      <TwCardBlockFormActions
        :title-submit="formType === 'create' ? 'Создать' : 'Сохранить'"
        :handle-submit="handleSubmit"
        :handle-cancel="handleCancel"
        :disable-submit="$v.form.$invalid || formProcessing"
        :disable-cancel="formProcessing"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { required } from 'vuelidate/lib/validators'
import API from '@/Api/Rtd/RequestToDeveloper/Settings/ReservationObjectBlock'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import TwSelectRelations from '@/components/tw-ui/ui-kit/input/select-relations/TwSelectRelations.vue'
import TwTextarea from '@/components/tw-ui/ui-kit/input/TwTextarea.vue'
import TwSelect from '@/components/tw-ui/ui-kit/input/TwSelect.vue'
import TwCardBlockFormActions from '@/components/core/ViewPanel/Card/TwCardBlockFormActions.vue'
import TwCheckBox from '@/components/tw-ui/ui-kit/input/TwCheckBox.vue'

export default defineComponent({
  name: 'BuilderDeveloperReservationObjectBlockForm',
  components: { TwCheckBox, TwCardBlockFormActions, TwSelect, TwTextarea, TwSelectRelations },
  mixins: [FormCreateEditMixin],
  props: {
    companyId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      API: API,
      form: {
        filter: {
          roomCount: []
        }
      }
    }
  },
  validations () {
    return {
      form: {
        complexId: {
          required
        }
      }
    }
  },
  computed: {
    complexFilters () {
      return {
        builderId: this.companyId ? [this.companyId] : []
      }
    }
  },
  methods: {
    handleSubmit () {
      this.$v.$reset()
      this.$v.form.$touch()
      if (this.formProcessing === true) {
        return
      }
      if (!this.$v.form.$invalid) {
        this.formProcessing = true
        this.form.companyId = this.companyId
        const request = this.formType === 'create'
          ? this.API.store(this.form)
          : this.API.update(this.targetId, this.form)

        request.then(({ data }) => {
          if (this.formType === 'create') {
            this.$emit('create', data.data)
          } else {
            this.$emit('update', data.data)
          }
        }).finally(() => {
          this.formProcessing = false
        })
      }
    },
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          for (const key in this.preset) {
            if (key === 'filter') {
              this.$set(this.form, key, this.preset[key] || {
                roomCount: []
              })
            } else {
              this.$set(this.form, key, this.preset[key])
            }
          }
        }
        resolve()
      })
    }
  }
})
</script>

<style scoped>

</style>
