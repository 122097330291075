import files from './files'
import siteDeveloper from './site-developer'
import importDocuments from './import-documents'
import { RouteKeys } from '@/router/types'
import dictPermissionRules from '@/Permissions/dictRules'

export default {
  path: 'nb-imports',
  name: 'nb-imports',
  redirect: { name: `${RouteKeys.builderFiles}-files` },
  meta: { name: 'Загрузка объектов', icon: 'loading-objects', ruleNamespace: dictPermissionRules.AppServicePermissionRulesOBJECTSNewBuildingsBuilderFileReadBuilderNBFileRule },
  component: () => import('@/views/Dictionary/Index.vue'),
  children: [
    files,
    siteDeveloper,
    importDocuments
  ]
}
