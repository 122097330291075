<template>
  <div
    :class="alignFlexClass"
    class="tw-w-full tw-flex"
  >
    <div class="tw-flex tw-gap-[6px] tw-items-center">
      <svg
        v-if="value"
        class="tw-m-auto"
        :width="size"
        :height="size"
        :viewBox="`0 0 22 22`"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11 1.75C5.89137 1.75 1.75 5.89137 1.75 11C1.75 16.1086 5.89137 20.25 11 20.25C16.1086 20.25 20.25 16.1086 20.25 11C20.25 5.89137 16.1086 1.75 11 1.75ZM0.25 11C0.25 5.06294 5.06294 0.25 11 0.25C16.9371 0.25 21.75 5.06294 21.75 11C21.75 16.9371 16.9371 21.75 11 21.75C5.06294 21.75 0.25 16.9371 0.25 11Z"
          :fill="trueColor"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.0303 7.96967C15.3232 8.26256 15.3232 8.73744 15.0303 9.0303L10.0303 14.0303C9.7374 14.3232 9.2626 14.3232 8.96967 14.0303L6.96967 12.0303C6.67678 11.7374 6.67678 11.2626 6.96967 10.9697C7.26256 10.6768 7.73744 10.6768 8.03033 10.9697L9.5 12.4393L13.9697 7.96967C14.2626 7.67678 14.7374 7.67678 15.0303 7.96967Z"
          :fill="trueColor"
        />
      </svg>
      <svg
        v-else
        class="tw-m-auto"
        :width="size"
        :height="size"
        :viewBox="`0 0 22 22`"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11 22C4.93436 22 0 17.0656 0 11C0 4.93436 4.93436 0 11 0C17.0656 0 22 4.93436 22 11C22 17.0656 17.0656 22 11 22ZM11 1.375C5.69252 1.375 1.375 5.69252 1.375 11C1.375 16.3075 5.69252 20.625 11 20.625C16.3075 20.625 20.625 16.3075 20.625 11C20.625 5.69252 16.3075 1.375 11 1.375Z"
          :fill="falseColor"
        />
        <path
          d="M7.59737 15.0901C7.4213 15.0901 7.24522 15.0233 7.11145 14.8885C6.8429 14.6199 6.8429 14.1845 7.11145 13.916L13.9176 7.10962C14.1864 6.84106 14.6217 6.84106 14.8903 7.10962C15.1589 7.37817 15.1589 7.81357 14.8903 8.08229L8.08395 14.8885C7.94833 15.0233 7.77243 15.0901 7.59737 15.0901Z"
          :fill="falseColor"
        />
        <path
          d="M14.4025 15.0902C14.2266 15.0902 14.0506 15.0233 13.9168 14.8886L7.1106 8.08323C6.84188 7.81467 6.84188 7.37928 7.1106 7.11072C7.37915 6.842 7.81455 6.842 8.0831 7.11072L14.8893 13.9169C15.158 14.1855 15.158 14.6209 14.8893 14.8894C14.7547 15.0233 14.5786 15.0902 14.4025 15.0902Z"
          :fill="falseColor"
        />
      </svg>
      <div :style="{ color: value ? trueColor : falseColor}">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BooleanViewIcon',
  props: {
    value: {
      type: [Boolean, Number, String],
      default: null
    },
    small: {
      type: Boolean,
      default: true
    },
    trueColor: {
      type: String,
      default: '#22C17B'
    },
    falseColor: {
      type: String,
      default: '#E63114'
    },
    alignFlexClass: {
      type: String,
      default: 'tw-justify-center'
    }
  },
  computed: {
    size () {
      return this.small ? '18px' : '22px'
    }
  }
}
</script>

<style scoped>

</style>
