<template>
  <div class="tw-font-manrope">
    <div
      v-if="loading"
      class="tw-h-full tw-w-full tw-flex tw-justify-center tw-items-center tw-py-5"
    >
      <TwCircleLoader />
    </div>
    <div
      v-else-if="error && error !== 404"
      class="tw-text-an-flat-red-main tw-text-center"
    >
      Код ошибки: {{ error || 0 }}
      <div>
        <code>
          {{ errorData }}
        </code>
      </div>
    </div>
    <div
      v-else-if="id && data"
      class="tw-flex tw-gap-[14px] tw-flex-col"
    >
      <TwPanelContentBlockFieldView v-if="data.objectAddress">
        <template #title>
          Адрес объекта
        </template>
        <div v-if="data.objectAddress">
          {{ data.objectAddress }}
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView>
        <template #title>
          Программа ипотеки
        </template>
        <div v-if="data.programCategory">
          {{ data.programCategory.label || data.programCategory }}
          <!--    TODO Сопоставление для ссылки не  верное закрыто пока порядок не наведут      -->
          <!--          <ViewPanelLink label="Программа" component="mortgage-program-show" :id="data.programCategory.value">-->
          <!--          </ViewPanelLink>-->
        </div>
      </TwPanelContentBlockFieldView>
      <hr>
      <TwPanelContentBlockFieldView>
        <template #title>
          Банк
        </template>
        <div v-if="data.bank">
          <ViewPanelLink
            :id="data.bank.id"
            component="company-show"
          >
            {{ data.bank.name }}
          </ViewPanelLink>
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView>
        <template #title>
          Сумма кредита
        </template>
        <div>
          {{ (data.creditSum || 0).toLocaleString('ru') }} ₽
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView>
        <template #title>
          Дата выдачи
        </template>
        <div v-if="data.dateExtradition && typeof data.dateExtradition === 'string'">
          {{ $Global.DateFormat.DateShort(data.dateExtradition) }}
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView>
        <template #title>
          Процентов в год
        </template>
        <div v-if="data.percentAtYear">
          {{ data.percentAtYear }}%
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView>
        <template #title>
          Срок кредита
        </template>
        <div v-if="data.years">
          {{ data.years }} лет
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView>
        <template #title>
          Вознаграждение Банк ({{ data.rewardBankPercent }}%)
        </template>
        <div>
          {{ (data.rewardBank || 0).toLocaleString('ru') }} ₽
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView>
        <template #title>
          Страховая компания
        </template>
        <div v-if="data.insuranceCompany">
          <ViewPanelLink
            :id="data.insuranceCompany.id"
            component="company-show"
          >
            {{ data.insuranceCompany.name }}
          </ViewPanelLink>
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView>
        <template #title>
          Сумма страховки
        </template>
        <div>
          {{ (data.insuranceSum || 0).toLocaleString('ru') }} ₽
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView>
        <template #title>
          Вознаграждение Страховая ({{ data.insuranceKvPercent }}%)
        </template>
        <div>
          {{ (data.insuranceKv || 0).toLocaleString('ru') }} ₽
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView>
        <template #title>
          Типы страховок
        </template>
        <div>
          <div>{{ data.insuranceLife ? 'Страхование жизни' : '' }}</div>
          <div>{{ data.insuranceProperty ? 'Страхование имущества' : '' }}</div>
          <div>{{ data.insuranceTitle ? 'Страхование титула' : '' }}</div>
        </div>
      </TwPanelContentBlockFieldView>
    </div>
    <div
      v-else
      class="tw-text-an-flat-disabled-text tw-text-center"
    >
      Нет данных
    </div>
  </div>
</template>

<script>
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'
import ShowObjMixin from '@/mixins/ShowObjMixin'
import API from '@/Api/Crm/Deal/Mortgage/Result'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'

export default {
  name: 'MortgageResultShow',
  components: { TwCircleLoader, TwPanelContentBlockFieldView, ViewPanelLink },
  mixins: [ShowObjMixin],
  data () {
    return {
      API
    }
  }
}
</script>
