<template>
  <TwPanelContentBlock
    class="tw-mb-[6px] tw-bg-white"
    style-hr="tw-bg-an-flat-hr-border-grey"
    content-classes="''"
    no-data-text="В разработке..."
  >
    <template #title>
      <div>
        Публикация на сайте
      </div>
    </template>
    <template #action>
      <TwPanelContentBlockActionButton
        v-if="allowEdit"
        type="edit"
        @click="isEdit = !isEdit"
      >
        {{ isEdit ? 'отменить':'изменить' }}
      </TwPanelContentBlockActionButton>
    </template>
    <div class="tw-flex tw-gap-[12px] tw-flex-col">
      <div
        v-if="loading"
        class="tw-w-full tw-text-center tw-py-[35px]"
      >
        <TwCircleLoader />
      </div>
      <div
        v-else
        class="tw-grid tw-gap-[30px] tw-grid-cols-[repeat(auto-fill,minmax(300px,1fr))]"
      >
        <EmployeeSiteForm
          v-if="isEdit"
          :id="userSiteId || 0"
          ref="EditForm"
          :user-id="userId"
          @refresh="fetchInfo"
          @cancel="isEdit = false"
        />
        <EmployeeSiteShow
          v-else
          :info="info"
        />
      </div>
    </div>
  </TwPanelContentBlock>
</template>

<script>
import API from '@/Api/Auth/User/site'
import EmployeeSiteForm from '@/views/Company/Employee/site/EmployeeSiteForm'
import EmployeeSiteShow from '@/views/Company/Employee/site/EmployeeSiteShow'
import dictPermissionRules from '@/Permissions/dictRules'
import { mapGetters } from 'vuex'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import TwPanelContentBlockActionButton from '@/components/tw-ui/panels/TwPanelContentBlockActionButton.vue'

export default {
  name: 'EmployeeSiteBlock',
  components: {
    TwPanelContentBlockActionButton,
    TwCircleLoader,
    TwPanelContentBlock,
    EmployeeSiteShow,
    EmployeeSiteForm
  },
  props: {
    userData: {
      required: true
    }
  },
  data () {
    return {
      info: null,
      loading: false,
      isEdit: false
    }
  },
  methods: {
    onMethod () {
      console.warn('TODO method')
    },
    fetchInfo () {
      this.isEdit = false
      if (!this.userSiteId) {
        this.$emit('refresh')
        return
      }

      this.loading = true
      API.show(this.userSiteId).then(({ data }) => {
        this.info = data?.data || null
      }).catch((error) => {
        console.log(error)
      }).finally(() => {
        this.loading = false
      })
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'profile/isAdmin',
      allowNamespacesDict: 'profile/allowNamespacesDict'
    }),
    userSiteId () {
      return this.userData?.site?.id || null
    },
    userId () {
      return this.userData?.id || null
    },
    allowEdit () {
      return this.isAdmin || !!this.allowNamespacesDict[dictPermissionRules.AppServicePermissionRulesCRMUsersUpdateUserSiteRule]
    }
  },
  watch: {
    userSiteId () {
      this.fetchInfo()
    }
  },
  mounted () {
    this.fetchInfo()
  }
}
</script>

<style scoped>

</style>
