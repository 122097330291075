import { RouteKeys } from '@/router/types'

export default {
  path: `${RouteKeys.seoPageResale}`,
  name: RouteKeys.seoPageResale,
  redirect: { name: `${RouteKeys.seoPageResale}-list` },
  meta: { name: 'Страницы вторичной недвижимости', icon: 'admin-catalog-seo-page-resale' },
  component: () => import('@/views/Site/Catalog/Index.vue'),
  children: [
    {
      path: 'list',
      name: `${RouteKeys.seoPageResale}-list`,
      meta: { name: 'Объекты вторички', icon: 'admin-catalog-seo-page-resale', hideInMenu: true },
      component: () => import('@/views/Site/Catalog/SeoPageResale/List.vue')
    },
    {
      path: ':id/edit',
      name: `${RouteKeys.seoPageResale}-edit`,
      meta: { name: 'Объекты вторички', icon: 'admin-catalog-seo-page-resale', hideInMenu: true },
      component: () => import('@/views/Site/Catalog/SeoPageResale/Edit.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    },
    {
      path: 'create',
      name: `${RouteKeys.seoPageResale}-create`,
      meta: { name: 'Объекты вторички', icon: 'admin-catalog-seo-page-resale', hideInMenu: true },
      component: () => import('@/views/Site/Catalog/SeoPageResale/Edit.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    }
  ]
}
