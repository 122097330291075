<template>
  <div class="tw-font-manrope tw-mt-3">
    <div class="tw-grid tw-grid-cols-[450px_auto] tw-gap-4">
      <!--О КЛИЕНТЕ-->
      <TwPanelContentBlock class="tw-mb-[6px]">
        <template #title>
          <div>
            Клиент
          </div>
        </template>
        <template
          v-if="dealData.person"
          #action
        >
          <ViewPanelLink
            :id="dealData.person.id"
            class="tw-text-main-xs"
            label="Клиент"
            component="person-show"
          >
            подробнее
          </ViewPanelLink>
        </template>
        <div>
          <PersonMainShow
            :info="dealData.person"
            :person-types="{}"
          />
        </div>
      </TwPanelContentBlock>

      <!--АНКЕТА-чек лист-->
      <TwPanelContentBlock class="tw-mb-[6px]">
        <template #title>
          <div>
            Анкета (чек лист)
          </div>
        </template>
        <template #action>
          <button
            class="tw-text-main-xs enabled:hover:tw-text-an-flat-dark-blue-link"
            @click.prevent="isEdit = !isEdit"
          >
            {{ isEdit ? 'отменить' : 'изменить' }}
          </button>
        </template>
        <div>
          <DealHouseDevelopQuestionnaireForm
            v-if="isEdit"
            :id="questionnaireHasExist ? dealId : 0"
            :preset-values="questionnaireHasExist ? null : presetValues"
            :deal-id="dealId"
            @refresh="isEdit = false"
            @cancel="isEdit = false"
          />
          <DealHouseDevelopQuestionnaireShow
            v-else
            :deal-id="dealId"
            @changeQuestionnaireExist="questionnaireHasExist = $event"
          />
        </div>
      </TwPanelContentBlock>
    </div>
  </div>
</template>

<script>
import PersonMainShow from '@/views/Person/main/PersonMainShow.vue'
import DealHouseDevelopQuestionnaireForm from '@/views/Deal/TypeViews/houseDevelop/Client/DealHouseDevelopQuestionnaireForm.vue'
import DealHouseDevelopQuestionnaireShow from '@/views/Deal/TypeViews/houseDevelop/Client/DealHouseDevelopQuestionnaireShow.vue'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink.vue'

export default {
  name: 'DealHouseDevelopClientTab',
  components: { ViewPanelLink, TwPanelContentBlock, DealHouseDevelopQuestionnaireShow, DealHouseDevelopQuestionnaireForm, PersonMainShow },
  props: {
    dealData: {
      required: true
    }
  },
  data () {
    return {
      isEdit: false,
      questionnaireHasExist: false
    }
  },
  computed: {
    dealId () {
      return this.dealData?.id || null
    },
    presetValues () {
      const fullName = `${this.dealData?.person?.surname || ''} ${this.dealData?.person?.name || ''} ${this.dealData?.person?.fatherName || ''}`
      const phone = this.dealData?.person?.phones?.[0]?.phone || null
      return {
        fullName,
        phone
      }
    }
  }
}
</script>

<style scoped>

</style>
