<template>
  <TwDialog
    v-model="show"
    :disable-close="false"
    :is-close-btn="true"
    :max-width="dialogMaxWidth"
    @close="closeModal"
  >
    <template #header>
      <div>
        <div
          class="tw-text-h5 tw-font-semibold"
          v-text="titleText"
        />
      </div>
    </template>
    <template #body>
      <div>
        <!-- Интерфейсы выбора статуса -->
        <template v-if="show && !dealingStatus && !failingStatus && !successStatus">
          <!-- Интерфейс определения результата Позитив // Негатив -->
          <div v-if="!resultType">
            <div v-if="type === 'Lead'">
              <div class="tw-flex tw-gap-[10px]">
                <div class="tw-flex-1 tw-flex tw-flex-col tw-gap-[6px]">
                  <template v-if="Array.isArray(successList) && !successList.length">
                    Нет подходящих статусов
                  </template>
                  <template v-else>
                    <TwPanelActionButton
                      v-for="(status, index) in successList"
                      :key="index"
                      color="green"
                      size="md"
                      class="tw-w-full"
                      @click.prevent="selectFinalStatus(status)"
                    >
                      {{ status.code === 'deal' ? 'Создать заявку' : status.name }}
                    </TwPanelActionButton>
                  </template>
                </div>
                <div class="tw-flex-1 tw-max-w-[30%]">
                  <TwPanelActionButton
                    color="red"
                    size="md"
                    class="tw-w-full"
                    @click.prevent="resultType = 'bad'"
                  >
                    Забраковать
                  </TwPanelActionButton>
                </div>
              </div>
            </div>
            <div v-else-if="type === 'Deal'">
              <div class="tw-flex tw-gap-[10px]">
                <div class="tw-flex-1 tw-flex tw-flex-col tw-gap-[6px]">
                  <template v-if="Array.isArray(successList) && !successList.length">
                    Нет подходящих статусов
                  </template>
                  <template v-else>
                    <TwPanelActionButton
                      v-for="(status, index) in successList"
                      :key="index"
                      color="green"
                      size="md"
                      class="tw-w-full"
                      @click.prevent="selectFinalStatus(status)"
                    >
                      {{ status.name || 'Сделка заключена' }}
                    </TwPanelActionButton>
                  </template>
                </div>
                <div class="tw-flex-1 tw-max-w-[30%]">
                  <TwPanelActionButton
                    color="red"
                    size="md"
                    class="tw-w-full"
                    @click.prevent="resultType = 'bad'"
                  >
                    Сделка проиграна
                  </TwPanelActionButton>
                </div>
              </div>
            </div>
            <div v-else>
              Неопределён шаблон типа: type: {{ type }}
            </div>
          </div>

          <!-- Интерфейс выбора Негатив результата -->
          <div v-if="resultType === 'bad'">
            <div>
              <template v-if="Array.isArray(failList) && !failList.length">
                Нет подходящих статусов
              </template>
              <template v-else>
                <div class="tw-flex tw-flex-col tw-items-center tw-gap-[6px]">
                  <TwPanelActionButton
                    v-for="(status, index) in failList"
                    :key="index"
                    color="none"
                    size="md"
                    class="tw-w-full tw-max-w-[300px]"
                    :style="{
                      backgroundColor: status.color || 'lightgrey',
                      color: $Global.textColorFromBgColorHex(status.color) || 'black'
                    }"
                    @click.prevent="selectFinalStatus(status)"
                  >
                    {{ status.name }}
                  </TwPanelActionButton>
                  <TwPanelActionButton
                    color="none"
                    size="md"
                    class="tw-w-full tw-max-w-[300px]"
                    @click.prevent="resultType = ''"
                  >
                    назад
                  </TwPanelActionButton>
                </div>
              </template>
            </div>
          </div>
        </template>
        <!--
            Интерфейсы зависящие от выбранного типа и статуса
         -->
        <!-- Пост-Интерфейс создания сделки из лида -->
        <CreateFromLead
          v-else-if="type === 'Lead' && dealingStatus"
          :lead-id="leadId"
          :lead="lead"
          @toBack="closeModal"
          @dealCreated="dealCreated"
        />
        <!-- Пост-Интерфейс причины негативного статуса сделки -->
        <DealFailStatusForm
          v-else-if="type === 'Deal' && failingStatus"
          :status-info="failingStatus"
          @apply="failingStatusApply"
          @cancel="failingStatus = null"
        />
        <!-- Пост-Интерфейс успешного статуса сделки -->
        <DealSuccessStatusForm
          v-else-if="type === 'Deal' && successStatus"
          :status-info="successStatus"
          :deal-id="dealId"
          @apply="successStatusApply"
          @cancel="successStatus = null"
        />
        <div v-else>
          Отсутствет шаблон! {{ type }} / dealingStatus: {{ !!dealingStatus }} / failingStatus: {{ !!failingStatus }}
        </div>
      </div>
    </template>
  </TwDialog>
</template>

<script>
import TwDialog from '@/components/tw-ui/modal/TwDialog.vue'
import CreateFromLead from '@/components/status/CreateFromLead'
import DealFailStatusForm from '@/components/status/DealFailStatusForm'
import DealSuccessStatusForm from '@/components/status/DealSuccessStatusForm'
import TwPanelActionButton from '../tw-ui/panels/TwPanelActionButton.vue'

export default {
  name: 'FinalStatusSelectModal',
  components: { DealSuccessStatusForm, DealFailStatusForm, CreateFromLead, TwDialog, TwPanelActionButton },
  props: {
    leadId: {
      default: null
    },
    dealId: {
      default: null
    },
    title: {
      type: String,
      default: 'Выберите результат, с которым будет завершена обработка'
    },
    type: {
      type: String,
      default: null // 'Deal' | 'Lead'
    },
    fullscreen: {
      type: Boolean,
      default: false
    },
    lead: {
      type: Object,
      default: () => {
        return {}
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      default: '600px' // undefined
    },
    successList: {
      default: () => []
    },
    failList: {
      default: () => []
    }
  },
  data () {
    return {
      resultType: '', // bad || good
      show: false,
      dealingStatus: null,
      successStatus: null,
      failingStatus: null
    }
  },
  computed: {
    titleText () {
      if (this.type === 'Lead') {
        if (this.dealingStatus) return 'Создание заявки'
      } else if (this.type === 'Deal') {
        if (this.failingStatus) return 'Причины негатива'
        if (this.successStatus) return 'Параметры завершения'
      }
      return this.title
    },
    dialogMaxWidth () {
      if (this.type === 'Lead') {
        if (this.dealingStatus) return '500px'
      } else if (this.type === 'Deal') {
        if (this.failingStatus) return '400px'
        if (this.successStatus) return '400px'
      }
      return this.maxWidth
    }
  },
  methods: {
    dealCreated () {
      this.$emit('dealCreated', { id: this.dealingStatus.id })
      this.closeModal()
    },
    failingStatusApply (formData) {
      // console.log('Негатив - failingStatusApply', this.failingStatus)
      this.$emit('selectFinalStatus', {
        id: this.failingStatus.id,
        name: this.failingStatus.name,
        ...formData
      })
      this.closeModal()
    },
    successStatusApply (formData) {
      // console.log('Позитив - successStatusApply', this.successStatus)
      this.$emit('selectFinalStatus', {
        id: this.successStatus.id,
        name: this.successStatus.name,
        ...formData
      })
      this.successStatus = null
      this.closeModal()
    },
    selectFinalStatus (status) {
      // console.log('selectFinalStatus', status)
      if (this.type === 'Lead' && status.code === 'deal') { // Перевод лида в заявку
        this.dealingStatus = status
      } else if (this.type === 'Deal' && status?.stage?.value === 'fail') { // Негативный результат сделки
        this.failingStatus = status
      } else if (this.type === 'Deal' && status?.code === 'success') { // Успешный результат сделки
        this.successStatus = status
      } else {
        this.$emit('selectFinalStatus', {
          id: status.id,
          name: status.name
        })
      }
    },
    closeModal () {
      this.show = false
      this.failingStatus = null
      this.successStatus = null
      this.resultType = ''
      this.dealingStatus = null
    },
    showModal (presetStatus = null) {
      console.warn(this.successList, this.failList)
      if (presetStatus) {
        this.selectFinalStatus(presetStatus)
      }
      this.show = true
    }
  }
}
</script>
