import { RouteKeys } from '@/router/types'
import program from './program'
import requirement from './requirement'
import complexParams from './complex-params'
import userComments from './user-comments'
import dictPermissionRules from '@/Permissions/dictRules'

export default {
  path: RouteKeys.mortgage,
  name: RouteKeys.mortgage,
  meta: { name: 'Ипотека', icon: 'mortgage', hideIsNotChildren: true },
  redirect: { name: `${RouteKeys.mortgage}-${RouteKeys.variant}-matrix` },
  component: () => import('@/views/Mortgage/Index.vue'),
  children: [
    program,
    {
      path: 'program-rates-editor',
      name: 'program-rates-editor',
      meta: { name: 'Редактор ставок (базовых)', icon: 'bid-editor-(basic)', ruleNamespace: dictPermissionRules.AppServicePermissionRulesCRMMortgageProgramsUpdateProgramRule, hText: 'Редактор ставок (базовых)' },
      component: () => import('@/views/Mortgage/ProgramRatesEditor/ProgramRatesEditorBlock.vue')
    },
    requirement,
    complexParams,
    userComments
  ]
}
