import { TBaseTableHeaderItem, tableItemStyleOne } from '@/components/tw-ui/table/TableTypes'

const options: TBaseTableHeaderItem[] = [
  {
    ...tableItemStyleOne,
    text: 'Файл',
    dataKey: 'genPlan',
    colStyle: 'width: auto;'
  },
  {
    ...tableItemStyleOne,
    text: 'Название',
    dataKey: 'name',
    colStyle: 'width: auto;'
  },
  {
    ...tableItemStyleOne,
    text: 'Кол-во комнат',
    dataKey: 'roomCount',
    align: 'center'
  },
  {
    ...tableItemStyleOne,
    text: 'Общая площадь',
    dataKey: 'totalArea',
    align: 'right'
  },
  {
    ...tableItemStyleOne,
    text: 'Жилая площадь',
    dataKey: 'residentialArea',
    align: 'right'
  },
  {
    ...tableItemStyleOne,
    text: 'Коммерч.',
    dataKey: 'commercial',
    align: 'center'
  },
  {
    ...tableItemStyleOne,
    text: 'Проект ИЖС',
    dataKey: 'project'
  }
]

export default options
