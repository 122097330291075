<template>
  <div
    v-if="formProcessing && !preset"
    class="tw-p-2 tw-flex"
  >
    <TwCircleLoader class="tw-m-auto" />
  </div>
  <div
    v-else-if="preset"
    class="tw-font-manrope tw-flex tw-flex-col tw-gap-[16px]"
  >
    <TwFlexRow>
      <TwFlexCell class="tw-flex tw-justify-end">
        <TwCheckBox
          :value="form.free"
          :disabled="formProcessing || disableFreeChange"
          label="Бесплатная услуга"
          @input="onInputFree"
        />
      </TwFlexCell>
    </TwFlexRow>

    <!-- Бесплатная услуга   -->
    <TwFlexCol v-if="form.free">
      <TwFlexCell>
        <TwSelect
          ref="freeCondition"
          v-model="form.freeCondition"
          label="Причина предоставления бесплатной услуги"
          placeholder="Выбрать"
          :has-one-option-preselect="true"
          :dense="true"
          :persistent-label="true"
          :error-text="$v.form.freeCondition.$invalid ? 'Обязательное поле':''"
          :options="freeConditionsList"
          :disabled="formProcessing"
          @input="onChangeFreeCondition"
          @focus="$event.target.select()"
          @blur="$v.form.freeCondition.$touch()"
        />
      </TwFlexCell>
    </TwFlexCol>
    <!-- Платная услуга   -->
    <!-- TODO удалили - на беке не используется -->

    <!-- Блок с выбором договора   -->
    <TwFlexCol v-if="requireContract">
      <TwFlexCell>
        <TwSelectRelations
          v-model="form.contractId"
          prefetch-request
          :default-filters="contractDealTypeFilters"
          :dense="true"
          :persistent-label="true"
          :check-disabled="contractAllowSelectUnsigned"
          label="Номер договора"
          list-label="Найти договор"
          placeholder="Выбрать договор"
          :error-text="form.contractId ? '':'Обязательное поле'"
          :disabled="formProcessing || (!!preset.contractId && !!form.contractId)"
          relation-name="contract"
        />
      </TwFlexCell>
    </TwFlexCol>

    <!-- Доп параметры бесплатной услуги   -->
    <TwFlexCol v-if="form.free">
      <TwFlexCell v-if="currentRelCondition && currentRelCondition.type">
        <!-- Выбор ЖК -->
        <template v-if="currentRelCondition.type === 'complex'">
          <TwSelectRelations
            v-model="form.relationComplexId"
            relation-name="complex"
            label="Выбрать ЖК"
            :default-filters="selectComplexFilters"
            list-label="Поиск ЖК"
            placeholder="Выбрать"
            :persistent-label="true"
            :dense="true"
            :multi="false"
            :disabled="formProcessing"
            :error-text="$v.form.relationComplexId.$invalid ? 'Обязательное поле' : ''"
          />
        </template>
        <!-- Выбор связанного договора -->
        <template v-else-if="currentRelCondition.type === 'contract'">
          <TwSelectRelations
            ref="relationContractId"
            v-model="form.relationContractId"
            :check-disabled="contractAllowSelectSigned"
            prefetch-request
            :disabled="formProcessing"
            :default-filters="contractFromConditionFilters"
            return-object
            :dense="true"
            :persistent-label="true"
            label="Номер связанного договора"
            list-label="Найти договор"
            placeholder="Выбрать договор"
            relation-name="contract"
            :error-text="$v.form.relationContractId.$invalid ? 'Обязательное поле' : ''"
            @input="$v.form.relationContractId.$touch()"
          />
        </template>
      </TwFlexCell>
    </TwFlexCol>
    <hr>
    <DealRelationCreateFormScheme
      v-if="form.form"
      :value="form.form"
      :schema-fields="formScheme && formScheme.fields"
      @input-field="schemeFormChange"
    />
    <hr>
    <TwTextarea
      v-model="form.comment"
      rows="5"
      :persistent-label="true"
      label="Комментарий"
      maxlength="500"
      show-length
      resize="y"
      :show-label="true"
    />
    <TwCardSimpleFormSaveBlock
      class="tw-mt-[15px]"
      :loading="formProcessing"
      :cancel-confirm="true"
      :is-sticky="true"
      bg-color="transparent"
      :disable-success="formProcessing"
      :disable-cancel="formProcessing"
      :visible="true"
      @success="handleSubmit"
      @cancel="handleCancel"
    >
      <template #info>
        <div
          v-if="serverErrorMessage"
          class="tw-text-an-flat-red-main tw-text-center tw-p-[8px] tw-mb-[5px] tw-bg-white"
        >
          {{ serverErrorMessage }}
        </div>
      </template>
    </TwCardSimpleFormSaveBlock>
  </div>
</template>

<script lang="js">
import API from '@/Api/Crm/Deal/RelatedServices'
import TwCheckBox from '@/components/tw-ui/ui-kit/input/TwCheckBox.vue'
import TwSelect from '@/components/tw-ui/ui-kit/input/TwSelect.vue'
import TwCardSimpleFormSaveBlock from '@/components/tw-ui/forms/TwCardSimpleFormSaveBlock.vue'
import TwSelectRelations from '@/components/tw-ui/ui-kit/input/select-relations/TwSelectRelations.vue'
import TwFlexCol from '@/components/tw-ui/ui-kit/flex-block/TwFlexCol.vue'
import TwFlexCell from '@/components/tw-ui/ui-kit/flex-block/TwFlexCell.vue'
import TwFlexRow from '@/components/tw-ui/ui-kit/flex-block/TwFlexRow.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import TwTextarea from '@/components/tw-ui/ui-kit/input/TwTextarea.vue'
import DealRelationCreateFormScheme from './DealRelationCreateFormScheme.vue'

export default {
  name: 'DealRelationCreateForm',
  components: {
    DealRelationCreateFormScheme,
    TwTextarea,
    TwCircleLoader,
    TwFlexRow,
    TwFlexCell,
    TwFlexCol,
    TwSelectRelations,
    TwCardSimpleFormSaveBlock,
    TwSelect,
    TwCheckBox
  },
  props: {
    dealId: {
      type: Number,
      required: true
    },
    serviceCode: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      serverErrorMessage: null,
      scheme: null,
      preset: null,
      dictionary: null,
      formScheme: {},
      relationObjectId: this.dealId,
      currentDate: new Date().toISOString().slice(0, 10),
      API: API,
      form: {
        free: null,
        freeCondition: null,
        relationComplexId: null,
        relationContractId: null,
        comment: null
      },
      formProcessing: false
    }
  },
  validations () {
    return {
      form: {
        freeCondition: {
          required: function (v) {
            if (this.form.free) {
              return !!v
            }
            return true
          }
        },
        relationContractId: {
          required: function (v) {
            if (this.form.free && this.currentRelCondition.type === 'contract') {
              return !!v
            }
            return true
          }
        },
        relationComplexId: {
          required: function (v) {
            if (this.form.free && this.currentRelCondition.type === 'complex') {
              return !!v
            }
            return true
          }
        },
        contractId: {
          required: function (v) {
            if (this.requireContract) {
              return !!v
            }
            return true
          }
        }
      }
    }
  },
  methods: {
    schemeFormChange (eventData) {
      this.$set(this.form.form, eventData.fieldKey, eventData.newValue)
    },
    handleCancel () {
      this.$emit('cancel')
    },
    fetchInit () {
      if (this.dealId && this.serviceCode) {
        this.formProcessing = true
        API.show(this.dealId, this.serviceCode).then(({ data }) => {
          this.preset = data?.data || null
          this.formScheme = data?.formScheme || {}
          this.dictionary = data?.dictionary || null
          this.scheme = data?.scheme || null
          this.$nextTick(() => {
            this.presetData()
          })
        }).catch(() => {
          this.preset = null
          this.formScheme = {}
          this.dictionary = null
          this.scheme = null
        }).finally(() => {
          this.formProcessing = false
        })
      } else {
        console.warn('WARN: Не был получен код для запроса /related-service')
      }
    },
    onInputFree (eventValue) {
      this.form.free = eventValue
      // Сброс полей
      this.form.contractId = null
      this.form.relationContractId = null
      this.form.freeCondition = null

      if (this.form.free) {
        // Если изначально были заданы значения - возвращаю их на в поля ввода
        if (this.preset?.freeCondition) {
          this.form.freeCondition = this.preset?.freeCondition?.value
        } else {
          this.form.freeCondition = null
        }
        if (this.preset?.relationContract) {
          this.form.relationContractId = this.preset.relationContract.id || null
        } else {
          this.form.relationContractId = null
        }
      }
    },
    onChangeFreeCondition () {
      this.form.relationContractId = null
      this.form.relationComplexId = null
    },
    // Разрешить только НЕ подписанные договора
    contractAllowSelectUnsigned (item, isAlert = false) {
      if (item.signed) {
        if (isAlert) {
          alert('Договор уже подписан в другой заявке! Выберите не подписанный договор')
        }
        return true
      }
      return false
    },
    // Разрешить только подписанные договора
    contractAllowSelectSigned (item, isAlert = false) {
      if (!item.signed) {
        if (isAlert) {
          alert('Договор не подписан! Выберите подписанный договор')
        }
        return true
      }
      return false
    },
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          const adaperMap = {
            // from(show): to(send)
            deal: 'dealId',
            contract: 'contractId'
          }
          for (const key in this.preset) {
            if (adaperMap[key] !== undefined) {
              if (adaperMap[key].indexOf('Id') !== -1 && (this.preset[key] && this.preset[key].id)) {
                this.$set(this.form, adaperMap[key], this.preset[key].id)
              }
            } else {
              if (key === 'freeCondition') {
                this.$set(this.form, key, this.preset[key] ? this.preset[key].value : null)
              } else if (key === 'relationContract' && this.preset[key]) {
                this.$set(this.form, 'relationContractId', this.preset[key]?.id || null)
              } else if (key === 'relationComplex' && this.preset[key]) {
                this.$set(this.form, 'relationComplexId', this.preset[key]?.id || null)
              } else {
                this.$set(this.form, key, this.preset[key])
              }
            }
          }
        }
        resolve()
      })
    },
    handleResetCustom () {
      this.handleReset()
    },
    handleSubmit () {
      this.$v.$reset()
      this.$v.form.$touch()
      console.warn(this.$options.name, ' handleSubmit ', this.$v.form, this.$v.form.$invalid)
      if (!this.$v.form.$invalid) {
        if (this.formProcessing === true) {
          return
        }

        this.formProcessing = true
        this.serverErrorMessage = null

        this.API.store(this.dealId, this.serviceCode, this.form)
          .then(({ data }) => {
            // console.warn(this.$options.name, ' CREATE ', data)
            this.$emit('created', data)
          })
          .catch((error) => {
            this.serverErrorMessage = error.serverErrorMessage || null
          })
          .finally(() => {
            this.formProcessing = false
          })
      }
    }
  },
  computed: {
    currentConditions () {
      if (this.form?.free) {
        return this.form.freeCondition && Array.isArray(this.scheme?.freeConditions) ? this.scheme.freeConditions.find(item => item.code === this.form.freeCondition) : {}
      }
      return this.scheme?.paid || {}
    },
    requireContract () {
      return this.currentConditions?.requireContractOnRequest || false
    },
    currentRelCondition () {
      return this.currentConditions ? this.currentConditions.relCondition : null
    },
    freeConditionsList () {
      return Array.isArray(this.scheme?.freeConditions) && this.scheme.freeConditions.length ? this.scheme.freeConditions.map(condition => {
        return {
          label: condition.name,
          value: condition.code
        }
      }) : []
    },
    disableFreeChange () {
      return !this.freeConditionsList.length || !this.scheme.paid
    },
    selectDealFilters () {
      return {
        type: this.currentRelCondition?.dealType || null
      }
    },
    selectComplexFilters () {
      return {
        isExclusive: true
      }
    },
    contractFromConditionFilters () {
      if (this.currentRelCondition.type && this.currentRelCondition.dealType) {
        return { type: this.currentRelCondition.dealType }
      }
      return null
    },
    contractDealTypeFilters () {
      return { type: this.scheme?.dealType || undefined }
    }
  },
  mounted () {
    this.fetchInit()
  }
}
</script>
