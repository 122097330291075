<template>
  <TwDialog
    :value="visible"
    :disable-close="false"
    :is-close-btn="false"
    max-width="350px"
  >
    <template #header>
      <div>
        <div class="tw-text-h5 tw-font-semibold">
          {{ stateData.title }}
        </div>
      </div>
    </template>
    <template #body>
      <div
        v-if="loading"
        class="tw-min-h-[40px] tw-h-full tw-w-full tw-flex tw-justify-center tw-items-center"
      >
        <TwCircleLoader
          width="30px"
          height="30px"
        />
      </div>
      <div :class="loading ? 'tw-invisible':''">
        {{ stateData.description }}
        <div v-if="stateData.form">
          <div
            v-if="stateData.form.statusSuccess !== undefined"
            class="tw-my-[12px]"
          >
            <TwCheckBox
              v-model="stateData.form.statusSuccess"
              :disabled="loading"
              label="Установить всем скопированным объектам статус <Продана>"
            />
          </div>
        </div>
      </div>
    </template>

    <template #actions>
      <div class="tw-mt-[15px] tw-flex tw-gap-[10px]">
        <button
          ref="successBtn"
          :disabled="loading"
          title="Подтвердить действие"
          class="tw-grow tw-px-[15px] tw-py-[7px] tw-bg-white tw-rounded-sm tw-text-an-flat-red-main disabled:tw-opacity-50 enabled:hover:tw-bg-an-flat-red-main enabled:hover:tw-text-white"
        >
          {{ stateData.successText }}
        </button>
        <button
          ref="cancelBtn"
          :disabled="loading"
          title="Отменить действие"
          class="tw-w-[100px] tw-px-[15px] tw-py-[7px] tw-bg-an-flat-gray-4 tw-rounded-sm tw-text-white disabled:tw-opacity-50"
        >
          {{ stateData.cancelText }}
        </button>
      </div>
    </template>
  </TwDialog>
</template>

<script>
import TwDialog from '@/components/tw-ui/modal/TwDialog.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import TwCheckBox from '@/components/tw-ui/ui-kit/input/TwCheckBox.vue'

export default {
  name: 'NbEditorActionsConfirmDialog',
  components: { TwCheckBox, TwCircleLoader, TwDialog },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    inChess: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      stateData: {
        title: '-',
        description: '-',
        successText: '-',
        cancelText: '-',
        form: {}
      },
      visible: false,
      selectedType: null // для локального выбора типа и последующего создания объекта
    }
  },
  methods: {
    close () {
      this.visible = false
    },
    confirm (options = {}) {
      console.warn(this.$options.name, 'confirm', options)
      this.stateData = {
        ...options
      }
      return new Promise((resolve) => {
        const onCancel = (event) => {
          event.preventDefault()
          this.$refs.cancelBtn.removeEventListener('click', onCancel)
          this.$refs.successBtn.removeEventListener('click', onSuccess)
          this.close()
          resolve({
            success: false,
            form: this.stateData?.form
          })
        }
        const onSuccess = (event) => {
          event.preventDefault()
          this.$refs.cancelBtn.removeEventListener('click', onCancel)
          this.$refs.successBtn.removeEventListener('click', onSuccess)
          resolve({
            success: true,
            form: this.stateData?.form
          })
        }
        this.visible = true
        this.$nextTick(() => {
          this.$refs.cancelBtn.addEventListener('click', onCancel)
          this.$refs.successBtn.addEventListener('click', onSuccess)
        })
      })
      // .finally(() => {
      //   this.visible = false
      // })
    }
  }
}
</script>
