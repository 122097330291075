import { Module } from 'vuex'
import { RootState } from '@/store/types'
import { AFiltersState, ApiSchemaFilter } from './types'
import { get, set } from 'local-storage'
import siteAdminAPI from '@/Api/Site/Admin/Filters/index'
import { cloneDeep } from 'lodash'
import Vue from 'vue'
import store from '@/store'

const namespaced = true

const schemaApiList: ApiSchemaFilter = [
  {
    api: siteAdminAPI,
    logoutRequired: false,
    code: 'siteAdmin'
  }
]

const dealTypeLocalStorageKey = 'crm/Deal/Type/Selected'

export const aFilter: Module<AFiltersState, RootState> = {
  namespaced,
  state: {
    fetchStatus: false,
    originalSchema: {}, // схема по умолчанию по типам
    currentSchema: {}, // схема по текущих используемых фильтров по типам
    savedUserFields: [] // Поля фильтров которые сохранил пользователь // TODO сохран. фильтров отложены
  },
  actions: {
    fetchFilterSchema ({ commit, dispatch }) {
      commit('SET_FETCH_STATUS', true)
      const requests = schemaApiList.map(item => item.api.config())
      Promise.allSettled(requests).then(values => {
        values.forEach((item, index) => {
          // При удачном завершении промиса
          if (item.status === 'fulfilled') {
            const data = item?.value?.data || []
            const schemaData: any = {}
            for (const key in data) {
              const schemaKey = `${schemaApiList[index].code}/${key}`
              schemaData[schemaKey] = data[key]
              dispatch('initCurrentFilters', schemaKey)
            }
            // console.warn('fetchFilterSchema newSchemaData ', schemaData)
            // TODO костыль для замены фильтра типа сделки для новостроек
            if (schemaApiList[index].code === 'crm') {
              const storageValue = get(dealTypeLocalStorageKey)
              schemaData['crm/Deal'].find((filterField: any) => {
                if (filterField.key === 'Deal.type') {
                  if (storageValue && Array.isArray(filterField.options) && filterField.options.length) {
                    const targetDealType = filterField.options.find((i: any) => {
                      return i.value === storageValue
                    })
                    if (targetDealType) {
                      filterField.defaultValue = { [targetDealType.value]: targetDealType.label }
                    }
                  }
                  return true
                }
                return false
              })
            }
            commit('SET_ORIGINAL_SCHEMA', schemaData)
          } else {
            // console.warn('fetchFilterSchema ', item.reason.response.status, schemaApiList[index], store)
            // Если есть обязательный логаут
            if (schemaApiList[index].logoutRequired && item?.reason?.response?.status === 401) {
              store.dispatch('profile/resetAuth')
            }
          }
        })
      }).finally(() => {
        commit('SET_FETCH_STATUS', false)
      })
    },
    // TODO сохран. фильтров отложены
    saveUserFields ({ commit }, payload) {
      // console.warn('saveUserFields', payload)
      commit('SET_SAVE_FIELDS', payload)
    },
    changeCurrentFields ({ commit }, data) {
      // console.warn('changeCurrentFields', data)
      if (!data.schemaKey || !data.fields) console.error('ERROR: changeCurrentFields [schemaKey] && [fields] - required!')
      commit('SET_CURRENT_FILTER_BY_KEY', data)
    },
    // копирование и выборка из оригинальных фильтров, только тех которые должны быть показаны с списке фильтров
    initCurrentFilters ({ commit, state }, schemaKey) {
      // console.warn('initCurrentFilters [schemaKey]]', schemaKey)
      return new Promise((resolve: any) => {
        // console.warn('initCurrentFilters', schemaKey, state.originalSchema[schemaKey])
        if (!schemaKey) console.error('ERROR: initCurrentFilters [schemaKey]] - required!')
        // получаю поля фильтра по ключу TODO - тут в будущем встроится выбор схемы сохраненных фильтров
        const targetSchemaFields = cloneDeep(state.originalSchema[schemaKey])
        const currentFilterFields: any = []
        if (targetSchemaFields) {
          // console.warn('СХЕМА initCurrentFilters [schemaKey]]', schemaKey, targetSchemaFields)
          // инициализирую фильтры по умолчанию
          targetSchemaFields.forEach((item: any) => {
            // console.log('ПОЛЕ initCurrentFilters', item.key, item, ' defaultValue: ', item.defaultValue)
            // Только поля помеченные для использования
            if (item?.useDefault) {
              // console.log('AFilter initCurrentFilters[0]', item.key, item.useDefault, item.defaultValue, item)
              if (item.defaultValue) {
                // console.log('AFilter initCurrentFilters[1]', item.key, item.type, item.defaultValue)
                // для разных типов разная инициализация
                if (item.type.indexOf('enum') !== -1) {
                  // console.log('AFilter initCurrentFilters[2]', item.key, item.type, item.defaultValue)
                  if (Array.isArray(item.defaultValue)) {
                    // console.log('AFilter initCurrentFilters[3]', item.key, item.type, item.defaultValue)
                    const [firstValue = null] = item.defaultValue // тут беру первый элемент массива
                    if (firstValue) {
                      // console.log('AFilter initCurrentFilters[4]', item.key, item.type, item.defaultValue)
                      // console.error('firstValue', firstValue)
                      currentFilterFields.push({
                        ...item,
                        inputValue: firstValue,
                        inputText: item.options.find((i: any) => i.value === firstValue)?.label
                      })
                    } else {
                      currentFilterFields.push({
                        ...item
                      })
                    }
                  } else {
                    // console.log('AFilter initCurrentFilters[2.1]', item.key, item.type, item.defaultValue)
                    const [firstValue = null] = Object.entries(item.defaultValue) // а тут создаю массив из объекта
                    if (Array.isArray(firstValue) && firstValue.length) {
                      const [inputValue, inputText] = firstValue
                      currentFilterFields.push({
                        ...item,
                        inputValue,
                        inputText
                      })
                    }
                  }
                } else {
                  // console.log('AFilter initCurrentFilters[2.2]', item.key, item.type, item.defaultValue)
                  currentFilterFields.push({
                    ...item,
                    inputValue: item.defaultValue,
                    inputText: item.label
                  })
                }
              } else {
                // console.log('AFilter initCurrentFilters[END], получен фильтр по умолчанию!', item.type, item.defaultValue)
                // console.warn('AFilter initCurrentFilters, нет значения по умолчанию!', item)
                currentFilterFields.push({
                  ...item
                })
              }
            }
          })
        }
        // console.warn('RESULT initCurrentFilters [schemaKey]]', schemaKey, currentFilterFields)
        commit('SET_CURRENT_FILTER_BY_KEY', { schemaKey, fields: currentFilterFields })
        resolve()
      })
    }
  },
  mutations: {
    SET_FETCH_STATUS (state, status) {
      state.fetchStatus = !!status
    },
    SET_ORIGINAL_SCHEMA (state, schemaData) {
      if (!state.originalSchema) {
        Vue.set(state, 'originalSchema', {})
      }
      for (const key in schemaData) {
        Vue.set(state.originalSchema, key, schemaData[key])
      }
    },
    // TODO сохран. фильтров отложены
    SET_SAVE_FIELDS (state, payloadData) {
      state.savedUserFields.push(payloadData)
    },
    SET_CURRENT_FILTER_BY_KEY (state, payloadData) {
      // console.warn('SET_CURRENT_FILTER_BY_KEY', payloadData)
      const cloneFields = cloneDeep(payloadData.fields)
      Vue.set(state.currentSchema, payloadData.schemaKey, cloneFields)
    },
    SET_CURRENT_FILTER_VALUE_BY_KEY (state, payloadData) {
      // console.warn('SET_CURRENT_FILTER_VALUE_BY_KEY', payloadData)
      const indexField = state.currentSchema[payloadData.schemaKey].findIndex((field: any) => field.key === payloadData.fieldKey)
      // console.warn('SET_CURRENT_FILTER_VALUE_BY_KEY', indexField)
      if (indexField !== -1) {
        Vue.set(state.currentSchema[payloadData.schemaKey][indexField], 'inputValue', payloadData.inputValue)
        if (payloadData?.inputText) {
          Vue.set(state.currentSchema[payloadData.schemaKey][indexField], 'inputText', payloadData.inputText)
        }
      }
      // записать тип сделки по умолчанию
      if (payloadData.schemaKey === 'crm/Deal' && payloadData.fieldKey === 'Deal.type' && payloadData.inputValue) {
        set(dealTypeLocalStorageKey, payloadData.inputValue)
      }
    },
    // TODO UNUSE - очистка состояния текущих фильтров - использую initCurrentFilters
    CLEAR_CURRENT_FILTERS_BY_KEY (state, schemaKey) {
      // console.warn('CLEAR_CURRENT_FILTERS_BY_KEY', schemaKey)
      for (const index in state.currentSchema[schemaKey]) {
        Vue.set(state.currentSchema[schemaKey][index], 'inputValue', undefined)
      }
    },
    // очистка состояния конкретного поля текущего фильтра
    CLEAR_CURRENT_FILTER_FIELD_BY_KEY (state, payloadData) {
      // console.warn('CLEAR_CURRENT_FILTER_FIELD_BY_KEY', payloadData.schemaKey, payloadData.fieldKey)
      const indexField = state.currentSchema[payloadData.schemaKey].findIndex((field: any) => field.key === payloadData.fieldKey)
      Vue.set(state.currentSchema[payloadData.schemaKey][indexField], 'inputValue', undefined)
    }
  },
  getters: {
    filterFetchStatus: (state) => {
      return !!state?.fetchStatus
    },
    getFilterSchemaByKey: (state) => (key: string) => {
      return state?.originalSchema[key] || null
    },
    getCurrentFilterSchemaByKey: (state) => (key: string) => {
      return state?.currentSchema[key] || null
    },
    getUserFieldsByKey: (state) => (key: string) => {
      return (Array.isArray(state?.savedUserFields) && state?.savedUserFields.filter(item => item.schemaKey === key)) || []
    }
  }
}
