<template>
  <div
    :class="fromPanel ? 'tw-px-[26px] tw-py-[18px]':''"
    class="tw-font-manrope tw-text-main-sm2 tw-max-w-[100%] tw-bg-white tw-min-h-screen"
  >
    <template v-if="isCardContentVisible">
      <TwPanelTitle v-if="fromPanel">
        <template #left>
          <div
            v-if="data"
            class="tw-text-h4 tw-font-bold"
          >
            <span>{{ data.name }}</span>
            <span
              v-if="data && data.type"
              class="tw-ml-[4px] tw-font-medium tw-text-an-flat-disabled-text"
            >
              ({{ data.type.label }})
            </span>
            <span class="tw-ml-[4px] tw-text-an-flat-disabled-text tw-font-medium"># {{ data.id }}</span>
          </div>
          <div
            v-else-if="isNew"
            class="tw-text-h4 tw-font-bold tw-text-an-flat-disabled-text"
          >
            Новая компания
          </div>
          <div v-else>
            ~
          </div>
        </template>
      </TwPanelTitle>
      <TwPanelTabsFull
        v-model="currentTab"
        :items="tabList"
      >
        <template #content.main>
          <TwPanelContent class="tw-mt-[12px] tw-flex tw-font-manrope tw-text-main-sm2">
            <div class="tw-flex-1">
              <TwPanelContentBlock class="tw-mb-[6px]">
                <template #title>
                  О компании
                </template>
                <template #action>
                  <TwPanelContentBlockActionButton
                    type="edit"
                    :disabled="isNew"
                    @click="editMain = !editMain"
                  >
                    {{ editMain ? 'отменить':'изменить' }}
                  </TwPanelContentBlockActionButton>
                </template>
                <div>
                  <CompanyMainForm
                    v-if="editMain"
                    :id="data ? data.id : 0"
                    ref="EditMain"
                    :preset-type="presetType"
                    @create="onCreated"
                    @update="onUpdated"
                    @cancel="editMain = false"
                  />
                  <CompanyMainShow
                    v-else
                    :dictionary="dictionary"
                    :info="data"
                    @refresh="refresh"
                  />
                </div>
              </TwPanelContentBlock>

              <!-- Черный список -->
              <TwPanelContentBlock
                v-if="data && data.id"
                class="tw-my-[6px]"
              >
                <template #title>
                  <div class="tw-flex tw-items-center tw-gap-[7px]">
                    Черный список
                    <TwPopoverShort>
                      <template #trigger>
                        <svg
                          class="tw-block"
                          width="16"
                          height="16"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M2 9.54167C2 5.37652 5.37652 2 9.54167 2C13.7068 2 17.0833 5.37652 17.0833 9.54167C17.0833 13.7068 13.7068 17.0833 9.54167 17.0833C5.37652 17.0833 2 13.7068 2 9.54167ZM9.54167 0C4.27195 0 0 4.27195 0 9.54167C0 14.8114 4.27195 19.0833 9.54167 19.0833C14.8114 19.0833 19.0833 14.8114 19.0833 9.54167C19.0833 4.27195 14.8114 0 9.54167 0ZM8.62587 6.21542C8.94399 6.02846 9.31803 5.96012 9.68172 6.0225C10.0454 6.08488 10.3753 6.27396 10.6129 6.55626C10.8505 6.83855 10.9806 7.19583 10.9801 7.56483L10.9801 7.56632C10.9801 7.88962 10.7243 8.26933 10.1441 8.65615C9.88443 8.82927 9.61763 8.96338 9.41181 9.05485C9.31032 9.09996 9.22714 9.13316 9.17167 9.15429C9.14402 9.16483 9.1235 9.17227 9.11133 9.1766L9.09956 9.18073L9.09999 9.18059C8.5769 9.35576 8.29438 9.92155 8.46887 10.445C8.64352 10.969 9.20984 11.2522 9.73379 11.0775L9.41756 10.1288C9.73379 11.0775 9.73407 11.0774 9.73437 11.0773L9.73502 11.0771L9.73649 11.0766L9.74015 11.0754L9.75027 11.0719L9.78135 11.061C9.80671 11.052 9.84124 11.0394 9.88366 11.0233C9.96833 10.991 10.0853 10.9441 10.2241 10.8825C10.4987 10.7604 10.8726 10.5742 11.2535 10.3202C11.9544 9.853 12.9796 8.95175 12.9801 7.56724L12.9801 7.56781L11.9801 7.56632H12.9801V7.56724C12.9812 6.72613 12.6847 5.91174 12.143 5.26825C11.6012 4.62463 10.849 4.19352 10.0198 4.05129C9.19063 3.90906 8.33784 4.06487 7.61251 4.49116C6.88718 4.91744 6.33612 5.58667 6.05694 6.38031C5.87367 6.9013 6.14744 7.47222 6.66843 7.65549C7.18942 7.83876 7.76033 7.56499 7.94361 7.044C8.06606 6.69591 8.30775 6.40239 8.62587 6.21542ZM9.4859 12.5455C8.93361 12.5455 8.4859 12.9932 8.4859 13.5455C8.4859 14.0978 8.93361 14.5455 9.4859 14.5455H9.49444C10.0467 14.5455 10.4944 14.0978 10.4944 13.5455C10.4944 12.9932 10.0467 12.5455 9.49444 12.5455H9.4859Z"
                            fill="#A2ABB5"
                          />
                        </svg>
                      </template>
                      <template #content>
                        <div class="tw-normal-case tw-max-w-[250px]">
                          Добавить в черный список, если компания повела себя не порядочно. С обязательным описанием возникшей ситуации
                        </div>
                      </template>
                    </TwPopoverShort>
                  </div>
                </template>
                <template #action>
                  <TwPanelContentBlockActionButton
                    :disabled="isNew || loading"
                    type="edit"
                    @click="editBlackList = !editBlackList"
                  >
                    {{ editBlackList ? 'отменить':'изменить' }}
                  </TwPanelContentBlockActionButton>
                </template>
                <div>
                  <CompanyBlackListForm
                    v-if="editBlackList"
                    :id="data && data.blackList ? data.blackList.id : 0"
                    dense-fields
                    :company-id="data.id"
                    @create="refresh"
                    @update="refresh"
                    @refresh="refresh"
                    @cancel="editBlackList = false"
                  />
                  <CompanyBlackListShow
                    v-else
                    :dictionary="dictionary"
                    :info="data.blackList"
                  />
                </div>
              </TwPanelContentBlock>
            </div>
            <div class="tw-flex-1">
              <TwPanelContentBlock
                v-if="!isNew && data && data.id"
                class="tw-mb-[6px]"
              >
                <template #title>
                  Филиалы
                </template>
                <div>
                  <CompanyFilials :company-id="data.id" />
                </div>
              </TwPanelContentBlock>
            </div>
          </TwPanelContent>
        </template>
        <template #content.persons>
          <CompanyPersons
            :company-id="data.id"
            from-panel
          />
        </template>
        <template #content.builderSettings>
          <CompanyBuilderSettingsTab :company-id="data.id" />
        </template>
        <template #content.builderInfoSite>
          <CompanyBuilderInfoSite :company-id="data.id" />
        </template>
        <template #content.builderStock>
          <RealtyBuilderStockTab :id="data.id" />
        </template>
        <template #content.builderProjects>
          <TwPanelContent class="tw-mt-[12px] tw-flex tw-flex-wrap tw-font-manrope tw-text-main-sm2">
            <div class="tw-flex-1 tw-min-w-[400px]">
              <BuilderProjectsBlockList :company-id="data.id" />
            </div>
          </TwPanelContent>
        </template>
        <template #content.builderPlans>
          <RealtyPlansBlockList
            :builder-id="data.id"
            :is-allow-edit="true"
          />
        </template>
        <template #content.builderObjects>
          <BuilderObjectsBlockList :company-id="data.id" />
        </template>
      </TwPanelTabsFull>
    </template>
    <!--
      Блок заполняющий пространство при отсутствуии контента
      для прижатия блока сохранения к нижней части страницы
    -->
    <div class="tw-flex tw-justify-center tw-items-center">
      <TwCircleLoader
        v-if="loading && !isCardContentVisible"
        width="50px"
        height="50px"
      />
      <div v-else-if="error">
        <PermissionDenied :text="error === 403 ? 'Недостаточно прав для просмотра!' : 'Ошибка соединения - Обратитесь к администратору!'" />
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/Api/Crm/Company'
import CardMixin from '@/mixins/CardMixin'
import CompanyMainShow from '@/views/Dictionary/General/Company/main/CompanyMainShow.vue'
import CompanyMainForm from '@/views/Dictionary/General/Company/main/CompanyMainForm.vue'
import CompanyPersons from '@/views/Dictionary/General/Company/main/CompanyPersons.vue'
import CompanyFilials from '@/views/Dictionary/General/Company/main/CompanyFilials.vue'
import CompanyBuilderSettingsTab from '@/views/Dictionary/General/Company/developer/SettingsTab/CompanyBuilderSettingsTab.vue'
import CompanyBuilderInfoSite from '@/views/Dictionary/General/Company/developer/SiteInfo/CompanyBuilderInfoSite.vue'
import RealtyBuilderStockTab from '@/views/Dictionary/General/Company/developer/stock/RealtyBuilderStockTab.vue'
import TwPanelTitle from '@/components/tw-ui/panels/TwPanelTitle.vue'
import TwPanelTabsFull from '@/components/tw-ui/panels/TwPanelTabsFull.vue'
import { mapGetters } from 'vuex'
import PermissionDenied from '@/views/PermissionDenied.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import TwPanelContentBlockActionButton from '@/components/tw-ui/panels/TwPanelContentBlockActionButton.vue'
import TwPanelContent from '@/components/tw-ui/panels/TwPanelContent.vue'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import BuilderProjectsBlockList from '@/views/Newbuildings/Editor/BuilderProjects/BuilderProjectsBlockList.vue'
import CompanyBlackListForm from '@/views/Dictionary/General/Company/black-list/CompanyBlackListForm.vue'
import CompanyBlackListShow from '@/views/Dictionary/General/Company/black-list/CompanyBlackListShow.vue'
import TwPopoverShort from '@/components/tw-ui/popover/TwPopoverShort.vue'
import RealtyPlansBlockList from '@/views/Newbuildings/Editor/Plans/RealtyPlansBlockList.vue'
import BuilderObjectsBlockList from '@/views/Dictionary/General/Company/developer/ObjectsList/BuilderObjectsBlockList.vue'

export default {
  name: 'CompanyCard',
  components: {
    BuilderObjectsBlockList,
    BuilderProjectsBlockList,
    RealtyPlansBlockList,
    CompanyBlackListShow,
    CompanyBlackListForm,
    TwPopoverShort,
    TwPanelContentBlock,
    TwPanelContent,
    TwPanelContentBlockActionButton,
    TwCircleLoader,
    PermissionDenied,
    TwPanelTabsFull,
    TwPanelTitle,
    RealtyBuilderStockTab,
    CompanyBuilderInfoSite,
    CompanyBuilderSettingsTab,
    CompanyFilials,
    CompanyPersons,
    CompanyMainForm,
    CompanyMainShow
  },
  props: {
    presetType: {
      type: String,
      default: null
    }
  },
  mixins: [CardMixin],
  data () {
    return {
      editBlackList: false,
      currentTab: 'main',
      API: API
    }
  },
  methods: {
    refresh () {
      this.editBlackList = false
      // console.warn('refresh', this.$options.name, this.id, this.data, this.isNew)
      if (this.isNew) {
        this.editMain = true
      } else {
        this.fetch()
      }
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'profile/isAdmin',
      allowNamespacesDict: 'profile/allowNamespacesDict'
    }),
    tabList () {
      return [
        {
          label: 'Общие',
          key: 'main'
        },
        {
          label: 'Сотрудники',
          key: 'persons',
          disabled: this.isNew
        },
        {
          label: 'Настройки',
          key: 'builderSettings',
          disabled: this.isNew,
          hidden: this.data?.type?.value !== 'developer'
        },
        {
          label: 'На сайте',
          key: 'builderInfoSite',
          disabled: this.isNew,
          hidden: this.data?.type?.value !== 'developer'
        },
        {
          label: 'Предложения(Акции)',
          key: 'builderStock',
          disabled: this.isNew,
          hidden: this.data?.type?.value !== 'developer'
        },
        {
          label: 'Проекты',
          key: 'builderProjects',
          disabled: this.isNew,
          hidden: this.data?.type?.value !== 'developer'
        },
        {
          label: 'Планировки',
          key: 'builderPlans',
          disabled: this.isNew,
          hidden: this.data?.type?.value !== 'developer'
        },
        {
          label: 'Объекты',
          key: 'builderObjects',
          disabled: this.isNew,
          hidden: this.data?.type?.value !== 'developer'
        }
      ]
    }
  }
}
</script>
