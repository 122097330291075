<template>
  <v-sheet
    tabindex="0"
    title="Нажмите на поле или перетяните файл для загрузки!"
    :color="disabled || loading ? 'grey lighten-5':'indigo lighten-5'"
    :width="width"
    style="cursor:pointer;"
    :height="height"
    class="pa-2"
    @click="dragZoneClick"
    @dragenter="dragHandler"
    @dragleave="dragHandler"
    @dragover="dragHandler"
    @drop="dragHandler"
  >
    <input
      ref="upload"
      type="file"
      :value="null"
      :accept="accept"
      :disabled="disabled || loading"
      :multiple="multiple"
      style="display:none"
      @change="inputChange"
    >
    <div
      style="height: 100%;"
      class="d-flex align-center justify-center flex-column"
    >
      <template v-if="!loading">
        <div class="text-center">
          <v-icon
            v-if="!dragover"
            :color="disabled || loading ? 'grey darken-2':'indigo darken-2'"
            size="30"
          >
            fa-cloud-upload
          </v-icon>
          <v-icon
            v-if="dragover"
            :color="disabled || loading ? 'grey darken-2':'indigo darken-2'"
            size="50"
          >
            fa-plus
          </v-icon>
        </div>
        <div class="text-center">
          <span
            style="font-size: small"
            :class="disabled || loading ? 'grey--text lighten-1':'indigo--text lighten-1'"
          >
            <span v-if="!dragover">
              Нажмите на поле или перетяните файл для загрузки!
            </span>
          </span>
        </div>
      </template>
      <template v-else>
        <v-progress-circular
          :size="40"
          :width="4"
          color="secondary"
          indeterminate
        />
      </template>
    </div>
    <slot />
  </v-sheet>
</template>

<script>
export default {
  name: 'FileDrop',
  props: {
    accept: {
      type: String,
      default: 'image/png, image/jpeg, image/bmp'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '200px'
    },
    multiple: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      formUpload: false,
      dragover: false
    }
  },
  methods: {
    dragZoneClick () {
      // console.warn('++', e)
      if (this.disabled) return
      this.$refs.upload.click()
    },
    inputChange (e) {
      this.filesSelected(e.target.files)
    },
    dragHandler (e) {
      // console.warn('dragHandler', e)
      e.preventDefault()

      if (this.disabled) return

      if (e && e.type) {
        switch (e.type) {
          case 'dragenter':
            this.dragover = true
            break
          case 'dragleave':
            this.dragover = false
            break
          case 'dragover':
            this.dragover = true
            break
          case 'drop':
            if (e.dataTransfer) {
              this.filesSelected(e.dataTransfer.files)
            }
            break
        }
      }
    },
    filesSelected (files) {
      console.log('filesSelected ', files)
      this.$emit('filesSelected', files)
      this.dragover = false
    }
  }
}
</script>
