<template>
  <div
    class="tw-w-full tw-flex tw-items-start tw-gap-[10px] tw-px-[14px] tw-py-[15px] tw-rounded-[14px]"
    :class="typeClasses"
  >
    <div v-if="hasIcon">
      <slot name="icon">
        <svg
          v-if="type === 'neutral'"
          width="40"
          height="41"
          viewBox="0 0 40 41"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20 0.135864C9 0.135864 0 9.13586 0 20.1359C0 31.1359 9 40.1359 20 40.1359C31 40.1359 40 31.1359 40 20.1359C40 9.13586 31 0.135864 20 0.135864Z"
            fill="#C7D6E8"
          />
          <g clip-path="url(#clip0_716_58159)">
            <path
              d="M8.58169 25.0867C8.55089 25.2615 8.59076 25.4415 8.69263 25.587C8.79446 25.7325 8.95 25.8316 9.12491 25.8624L12.0971 26.3865L10.2248 15.7681L8.58169 25.0867Z"
              fill="white"
            />
            <path
              d="M12.1509 8.7183C11.9756 8.68647 11.7947 8.72576 11.6484 8.82754C11.5022 8.92937 11.4025 9.08531 11.3716 9.26085L10.6614 13.2885L24.1736 10.9059L12.1509 8.7183Z"
              fill="white"
            />
            <path
              d="M31.4192 27.9886L28.6284 12.1613C28.5642 11.7971 28.217 11.5541 27.8526 11.6182L12.0254 14.4089C11.6612 14.4731 11.418 14.8204 11.4822 15.1846L14.273 31.0119C14.3038 31.1868 14.4029 31.3423 14.5484 31.4442C14.6617 31.5236 14.796 31.5653 14.9325 31.5653C14.9712 31.5653 15.0101 31.5619 15.0487 31.5551L30.876 28.7644C31.0509 28.7336 31.2064 28.6344 31.3083 28.489C31.4101 28.3435 31.45 28.1635 31.4192 27.9886ZM15.0503 19.9953C14.9861 19.6311 15.2293 19.2838 15.5935 19.2196L26.1451 17.359C26.5096 17.2949 26.8567 17.538 26.9209 17.9022C26.9851 18.2664 26.7418 18.6137 26.3776 18.6779L15.8261 20.5385C15.7868 20.5454 15.7476 20.5488 15.709 20.5488C15.3901 20.5488 15.1076 20.3202 15.0503 19.9953ZM23.7907 24.5739L16.7564 25.8143C16.717 25.8212 16.6779 25.8245 16.6393 25.8245C16.3204 25.8245 16.0379 25.596 15.9806 25.2711C15.9164 24.9069 16.1596 24.5595 16.5238 24.4953L23.5581 23.255C23.9226 23.1907 24.2697 23.434 24.3339 23.7982C24.3981 24.1624 24.1549 24.5097 23.7907 24.5739ZM26.8427 21.3158L16.2912 23.1764C16.2518 23.1833 16.2127 23.1866 16.1741 23.1866C15.8552 23.1866 15.5727 22.9581 15.5154 22.6332C15.4512 22.269 15.6944 21.9216 16.0586 21.8574L26.6102 19.9969C26.9747 19.9327 27.3217 20.1759 27.386 20.5401C27.4501 20.9043 27.2069 21.2516 26.8427 21.3158Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_716_58159">
              <rect
                width="22.8571"
                height="22.8571"
                fill="white"
                transform="translate(8.57141 8.70728)"
              />
            </clipPath>
          </defs>
        </svg>
        <svg
          v-if="type === 'info'"
          width="40"
          height="41"
          viewBox="0 0 40 41"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20 0.135864C9 0.135864 0 9.13586 0 20.1359C0 31.1359 9 40.1359 20 40.1359C31 40.1359 40 31.1359 40 20.1359C40 9.13586 31 0.135864 20 0.135864Z"
            fill="#C7D6E8"
          />
          <g clip-path="url(#clip0_716_58159)">
            <path
              d="M8.58169 25.0867C8.55089 25.2615 8.59076 25.4415 8.69263 25.587C8.79446 25.7325 8.95 25.8316 9.12491 25.8624L12.0971 26.3865L10.2248 15.7681L8.58169 25.0867Z"
              fill="white"
            />
            <path
              d="M12.1509 8.7183C11.9756 8.68647 11.7947 8.72576 11.6484 8.82754C11.5022 8.92937 11.4025 9.08531 11.3716 9.26085L10.6614 13.2885L24.1736 10.9059L12.1509 8.7183Z"
              fill="white"
            />
            <path
              d="M31.4192 27.9886L28.6284 12.1613C28.5642 11.7971 28.217 11.5541 27.8526 11.6182L12.0254 14.4089C11.6612 14.4731 11.418 14.8204 11.4822 15.1846L14.273 31.0119C14.3038 31.1868 14.4029 31.3423 14.5484 31.4442C14.6617 31.5236 14.796 31.5653 14.9325 31.5653C14.9712 31.5653 15.0101 31.5619 15.0487 31.5551L30.876 28.7644C31.0509 28.7336 31.2064 28.6344 31.3083 28.489C31.4101 28.3435 31.45 28.1635 31.4192 27.9886ZM15.0503 19.9953C14.9861 19.6311 15.2293 19.2838 15.5935 19.2196L26.1451 17.359C26.5096 17.2949 26.8567 17.538 26.9209 17.9022C26.9851 18.2664 26.7418 18.6137 26.3776 18.6779L15.8261 20.5385C15.7868 20.5454 15.7476 20.5488 15.709 20.5488C15.3901 20.5488 15.1076 20.3202 15.0503 19.9953ZM23.7907 24.5739L16.7564 25.8143C16.717 25.8212 16.6779 25.8245 16.6393 25.8245C16.3204 25.8245 16.0379 25.596 15.9806 25.2711C15.9164 24.9069 16.1596 24.5595 16.5238 24.4953L23.5581 23.255C23.9226 23.1907 24.2697 23.434 24.3339 23.7982C24.3981 24.1624 24.1549 24.5097 23.7907 24.5739ZM26.8427 21.3158L16.2912 23.1764C16.2518 23.1833 16.2127 23.1866 16.1741 23.1866C15.8552 23.1866 15.5727 22.9581 15.5154 22.6332C15.4512 22.269 15.6944 21.9216 16.0586 21.8574L26.6102 19.9969C26.9747 19.9327 27.3217 20.1759 27.386 20.5401C27.4501 20.9043 27.2069 21.2516 26.8427 21.3158Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_716_58159">
              <rect
                width="22.8571"
                height="22.8571"
                fill="white"
                transform="translate(8.57141 8.70728)"
              />
            </clipPath>
          </defs>
        </svg>
        <svg
          v-if="type === 'success'"
          width="40"
          height="41"
          viewBox="0 0 40 41"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.5122 0C8.78049 0 0 8.78049 0 19.5122C0 30.2439 8.78049 39.0244 19.5122 39.0244C30.2439 39.0244 39.0244 30.2439 39.0244 19.5122C39.0244 8.78049 30.2439 0 19.5122 0Z"
            fill="#98E09F"
          />
          <path
            d="M19.9766 8C13.3598 8 7.97656 13.3832 7.97656 20C7.97656 26.6168 13.3598 32 19.9766 32C26.5934 32 31.9766 26.6168 31.9766 20C31.9766 13.3832 26.5934 8 19.9766 8ZM19.9766 29.8182C14.5627 29.8182 10.1584 25.4137 10.1584 20C10.1584 14.5863 14.5627 10.1818 19.9766 10.1818C25.3904 10.1818 29.7947 14.5863 29.7947 20C29.7947 25.4137 25.3903 29.8182 19.9766 29.8182Z"
            fill="white"
          />
          <path
            d="M24.2437 16.2801L18.4237 21.6908L15.7563 19.2109C15.3546 18.8375 14.7031 18.8374 14.3013 19.2109C13.8996 19.5844 13.8996 20.19 14.3013 20.5635L17.6962 23.7198C17.8891 23.8992 18.1508 24 18.4236 24H18.4237C18.6965 24 18.9582 23.8992 19.1512 23.7199L25.6987 17.6329C26.1004 17.2593 26.1004 16.6537 25.6987 16.2802C25.2969 15.9066 24.6455 15.9066 24.2437 16.2801Z"
            fill="white"
          />
        </svg>
        <svg
          v-if="type === 'warning'"
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_575_1490)">
            <path
              d="M20 0C9 0 0 9 0 20C0 31 9 40 20 40C31 40 40 31 40 20C40 9 31 0 20 0Z"
              fill="#F7C546"
            />
            <path
              d="M20 8C13.373 8 8 13.373 8 20C8 26.6278 13.373 32 20 32C26.6278 32 32 26.6285 32 20C32 13.373 26.6277 8 20 8ZM20 29.75C14.615 29.75 10.25 25.385 10.25 20C10.25 14.615 14.615 10.25 20 10.25C25.385 10.25 29.75 14.615 29.75 20C29.75 25.385 25.385 29.75 20 29.75ZM20.0008 13.2627C19.1413 13.2627 18.491 13.7113 18.491 14.4357V21.0807C18.491 21.806 19.1412 22.253 20.0008 22.253C20.8393 22.253 21.5105 21.7872 21.5105 21.0807V14.4357C21.5105 13.7285 20.8393 13.2627 20.0008 13.2627ZM20.0008 23.75C19.175 23.75 18.503 24.422 18.503 25.2485C18.503 26.0735 19.175 26.7455 20.0008 26.7455C20.8265 26.7455 21.4978 26.0735 21.4978 25.2485C21.4977 24.422 20.8265 23.75 20.0008 23.75Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_575_1490">
              <rect
                width="40"
                height="40"
                fill="white"
              />
            </clipPath>
          </defs>
        </svg>
        <svg
          v-if="type === 'error'"
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20 0C9 0 0 9 0 20C0 31 9 40 20 40C31 40 40 31 40 20C40 9 31 0 20 0Z"
            fill="#E63114"
            fill-opacity="0.8"
          />
          <path
            d="M20 8C13.3676 8 8 13.367 8 20C8 26.6325 13.367 32 20 32C26.6324 32 32 26.633 32 20C32 13.3675 26.633 8 20 8ZM20 30.125C14.4039 30.125 9.875 25.5965 9.875 20C9.875 14.4038 14.4035 9.875 20 9.875C25.5961 9.875 30.125 14.4035 30.125 20C30.125 25.5962 25.5965 30.125 20 30.125Z"
            fill="white"
          />
          <path
            d="M24.1069 22.7798L21.3271 20L24.1069 17.2202C24.473 16.8541 24.473 16.2606 24.107 15.8944C23.7408 15.5283 23.1472 15.5283 22.7811 15.8944L20.0013 18.6742L17.2215 15.8944C16.8554 15.5283 16.2618 15.5283 15.8957 15.8944C15.5296 16.2606 15.5296 16.8541 15.8957 17.2202L18.6755 20L15.8957 22.7798C15.5296 23.1459 15.5295 23.7395 15.8957 24.1056C16.2619 24.4718 16.8555 24.4717 17.2215 24.1056L20.0013 21.3258L22.7811 24.1056C23.1471 24.4717 23.7408 24.4717 24.107 24.1056C24.4731 23.7395 24.473 23.1459 24.1069 22.7798Z"
            fill="white"
          />
        </svg>
      </slot>
    </div>
    <div class="tw-flex-1 tw-flex tw-h-full tw-text-[13px] tw-flex-col">
      <div class="tw-font-bold">
        <slot name="title" />
      </div>
      <div class="tw-flex-1" />
      <div class="tw-block tw-text-[13px] tw-text-[#4B5763]">
        <slot name="text" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TwPanelInfoBlock',
  props: {
    type: {
      type: String,
      default: 'info' // info | warning | error | success | neutral
    },
    hasIcon: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    typeClasses () {
      if (this.type === 'success') {
        return 'tw-bg-[#DDF8E9]'
      }
      if (this.type === 'warning') {
        return 'tw-bg-[#FEECBE]'
      }
      if (this.type === 'error') {
        return 'tw-bg-[#FFCEC6]'
      }
      if (this.type === 'neutral') {
        return 'tw-bg-an-flat-gray-15'
      }
      return 'tw-bg-an-flat-blue-block-panel'
    }
  }
}
</script>
