<template>
  <v-form ref="form">
    <div>
      <div class="error--text">
        <div
          v-for="(error, index) in errors"
          :key="index"
        >
          <v-alert
            text
            dense
            prominent
            type="error"
          >
            {{ error }}
          </v-alert>
        </div>
      </div>

      <v-row
        v-if="application.applicationType === 'COBORROWER'"
        justify="start"
        align="start"
        dense
        class="my-2"
      >
        <v-col
          class=""
          cols="12"
          sm="6"
          md="5"
        >
          <v-checkbox
            v-model="form.mandatoryCoBorrower"
            class="mt-0"
            color="primary"
            label="Основной созаемщик"
          />
        </v-col>
      </v-row>

      <h3>Личные данные</h3>
      <v-row
        justify="center"
        align="start"
        dense
        class="my-2"
      >
        <v-col
          class=""
          cols="12"
          sm="6"
          md="5"
        >
          <v-text-field
            v-model="form.fullName"
            outlined
            role="presentation"
            dense
            class="ma-0 pa-0"
            label="ФИО"
            type="text"
            :disabled="loading"
          />

          <v-select
            v-model="form.education.type"
            label="Образование"
            attach=""
            item-text="label"
            item-value="value"
            :items="dict.education"
            :disabled="loading"
            dense
            outlined
          />

          <DatesArrayInput
            v-model="form.childrenBirthdays"
            label="Даты рождения детей"
          />
        </v-col>
        <v-col
          class=""
          cols="12"
          sm="6"
          md="3"
        >
          <v-select
            v-model="form.gender"
            label="Пол"
            attach=""
            item-text="label"
            item-value="value"
            :items="dict.gender"
            :disabled="loading"
            dense
            outlined
          />
          <v-select
            v-model="form.marriageStatus"
            label="Семейное положение"
            attach=""
            item-text="label"
            item-value="value"
            :items="dict.marriageStatus"
            :disabled="loading"
            dense
            outlined
          />
          <v-checkbox
            v-model="form.marriageContract"
            class="mt-0"
            color="primary"
            label="Наличие брачного контракта"
          />
        </v-col>
        <v-col
          class=""
          cols="12"
          sm="6"
          md="4"
        >
          <NativeDateInputField
            v-model="form.birthday"
            type="date"
            :disabled="loading"
            outlined
            dense
            clearable
            label="Дата рождения"
            autocomplete="off"
            required
          />

          <v-select
            v-model="form.citizenship"
            label="Гражданство"
            attach=""
            item-text="label"
            item-value="value"
            :items="dict.citizenship"
            :disabled="loading"
            dense
            outlined
          />
          <v-checkbox
            v-model="form.hasDisabledChild"
            class="mt-0"
            color="primary"
            label="Наличие ребенка инвалида в семье"
          />
        </v-col>
        <v-col
          class=""
          cols="6"
        >
          <v-text-field
            v-model="form.contactMail"
            dense
            outlined
            placeholder="ivanov@mail.ru"
            label="Email"
            type="email"
            role="presentation"
            autocomplete="off"
            :disabled="loading"
          />
        </v-col>
        <v-col
          class=""
          cols="6"
        >
          <v-text-field
            v-model="form.contactPhoneMobile"
            dense
            outlined
            placeholder="900 000 00 00"
            label="Телефон"
            type="phone"
            role="presentation"
            autocomplete="off"
            :disabled="loading"
            prefix="+7"
          />
        </v-col>
      </v-row>

      <h3 class="mt-5">
        Паспорт
      </h3>
      <v-row
        justify="center"
        align="start"
        dense
        class="my-2"
      >
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="form.passport.series"
            v-input-allow-regex="'[0-9]'"
            outlined
            dense
            type="text"
            label="Серия"
            placeholder="----"
            autocomplete="off"
            persistent-hint
            maxlength="4"
            :disabled="loading"
            required
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="form.passport.number"
            v-input-allow-regex="'[0-9]'"
            outlined
            dense
            type="text"
            label="Номер"
            placeholder="------"
            autocomplete="off"
            maxlength="6"
            :disabled="loading"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <NativeDateInputField
            v-model="form.passport.issueDate"
            type="date"
            :disabled="loading"
            outlined
            dense
            clearable
            label="Дата выдачи"
            autocomplete="off"
            required
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="form.passport.subdivisionCode"
            v-input-allow-regex="'[0-9]'"
            outlined
            dense
            type="text"
            label="Код подразделения"
            placeholder="------"
            autocomplete="off"
            maxlength="6"
            :disabled="loading"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-textarea
            v-model="form.passport.issuer"
            dense
            outlined
            label="Орган выдачи паспорта"
            rows="3"
            required
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-textarea
            v-model="form.passport.birthPlace"
            dense
            outlined
            label="Место рождения"
            rows="3"
            required
          />
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="form.inn"
            v-input-allow-regex="'[0-9]'"
            outlined
            dense
            type="text"
            label="ИНН"
            placeholder="10 или 12 цифр"
            autocomplete="off"
            maxlength="12"
            :rules="innRules"
            :disabled="loading"
          />
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="form.snils"
            v-input-allow-regex="'[0-9]'"
            outlined
            dense
            type="text"
            label="СНИЛС"
            placeholder="11 цифр"
            autocomplete="off"
            maxlength="11"
            :rules="snilsRules"
            :disabled="loading"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
        />
        <v-col
          class=""
          cols="12"
        >
          <div class="mb-3 d-flex">
            <v-checkbox
              v-model="isPreviousPassport"
              hide-details
              color="primary"
              label="Ранее менял ФИО"
              @change="changePreviousPassport"
            />
          </div>
          <div
            v-if="form.previousPassport"
            style="background-color: #f1f1f1"
          >
            <v-subheader>Данные о предыдущем паспорте</v-subheader>
            <v-row class="ma-3">
              <v-col>
                <v-text-field
                  v-model="form.previousPassport.series"
                  v-input-allow-regex="'[0-9]'"
                  outlined
                  dense
                  type="text"
                  label="Серия"
                  placeholder="----"
                  autocomplete="off"
                  persistent-hint
                  maxlength="4"
                  :rules="passportRules.seriesRules"
                  :disabled="loading"
                  required
                />
              </v-col>
              <v-col>
                <v-text-field
                  v-model="form.previousPassport.number"
                  v-input-allow-regex="'[0-9]'"
                  outlined
                  dense
                  type="text"
                  label="Номер"
                  placeholder="------"
                  autocomplete="off"
                  maxlength="6"
                  :rules="passportRules.numberRules"
                  required
                  :disabled="loading"
                />
              </v-col>
              <v-col>
                <NativeDateInputField
                  v-model="form.previousPassport.issueDate"
                  type="date"
                  :disabled="loading"
                  outlined
                  dense
                  clearable
                  label="Дата выдачи"
                  autocomplete="off"
                  required
                />
              </v-col>
            </v-row>
          </div>
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <v-card color="lightgrey">
            <v-card-subtitle class="mb-3">
              Адрес регистрации:
            </v-card-subtitle>
            <v-card-text>
              <DomClickDealAddressForm
                v-model="form.registrationAddress"
                :disabled="loading"
              />
              <v-checkbox
                v-model="form.isTempRegistration"
                class="mt-0"
                color="primary"
                label="Временная регистрация"
              />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <!--     TODO HIDE -- Можно игнорить, оно оставлено для обратной совместимости, основное поле с адресом registrationAdress <Telegram> @Dimassik31     -->
          <!--          <v-card color="lightgrey">-->
          <!--            <v-card-subtitle class="mb-3 d-flex">-->
          <!--              Адрес проживания:-->
          <!--              <v-spacer/>-->
          <!--              <v-btn x-small text @click="copyActualAdress">Скопировать из адреса регистрации</v-btn>-->
          <!--            </v-card-subtitle>-->
          <!--            <v-card-text>-->
          <!--              <DomClickDealAddressForm v-model="form.actualAddress" :disabled="loading"/>-->
          <!--            </v-card-text>-->
          <!--          </v-card>-->
        </v-col>
      </v-row>

      <h3 class="mt-5">
        Место работы
      </h3>
      <v-row
        justify="center"
        align="start"
        dense
        class="my-2"
      >
        <v-col
          class=""
          cols="12"
          sm="6"
          md="4"
        >
          <v-text-field
            v-model="form.job.jobOrganization"
            outlined
            dense
            type="text"
            label="Название организации"
            placeholder="Название организации, в которой работает клиент"
            autocomplete="off"
            :disabled="loading"
          />
          <v-text-field
            v-model="form.job.jobPosition"
            outlined
            dense
            type="text"
            label="Должность"
            placeholder="Должность"
            autocomplete="off"
            :disabled="loading"
          />
        </v-col>
        <v-col
          class=""
          cols="12"
          sm="6"
          md="4"
        >
          <NativeDateInputField
            v-model="form.job.employmentDate"
            type="date"
            :disabled="loading"
            outlined
            dense
            clearable
            label="Дата устройства на последнее место работы"
            autocomplete="off"
            required
          />
          <v-text-field
            v-model="form.job.jobOrganizationInn"
            v-input-allow-regex="'[0-9]'"
            outlined
            dense
            type="text"
            label="ИНН организации"
            placeholder="10 или 12 цифр"
            autocomplete="off"
            maxlength="12"
            :disabled="loading"
          />
        </v-col>
        <v-col
          class=""
          cols="12"
          sm="6"
          md="4"
        >
          <v-select
            v-model="form.job.jobSeniority"
            label="Общий трудовой стаж за 5 лет"
            attach=""
            item-text="label"
            item-value="value"
            :items="dict.jobSeniority"
            :disabled="loading"
            dense
            outlined
          />

          <v-text-field
            v-model="form.job.jobPhoneNumber"
            dense
            outlined
            placeholder="900 000 00 00"
            label="Телефон работодателя"
            type="phone"
            role="presentation"
            autocomplete="off"
            :disabled="loading"
            prefix="+7"
          />
        </v-col>
      </v-row>

      <h3 class="mt-5">
        Доход
      </h3>
      <v-row
        justify="center"
        align="start"
        dense
        class="my-2"
      >
        <v-col
          v-if="application.applicationType === 'COBORROWER'"
          cols="12"
        >
          <v-checkbox
            v-model="form.considerCoBorrowerIncome"
            class="mt-0"
            color="primary"
            label="Рассматривать доход созаемщика"
          />
        </v-col>
        <v-col
          class=""
          cols="12"
          sm="6"
          md="4"
        >
          <v-select
            v-model="form.jobIncomeTypeId"
            label="Способ подтверждения дохода"
            attach=""
            item-text="label"
            item-value="value"
            :items="dict.jobIncomeType"
            :disabled="loading"
            dense
            outlined
          />
          <v-checkbox
            v-if="form.jobIncomeTypeId === 'PENSION'"
            v-model="form.isPensionViaSber"
            class="mt-0"
            color="primary"
            label="Пенсионер с доходом на карту Сбера"
          />
          <v-select
            v-model="form.incomeVerificationDocument"
            label="Документ, подтверждающий занятость"
            attach=""
            item-text="label"
            clearable
            item-value="value"
            :items="dict.incomeVerificationDocument"
            :disabled="loading"
            dense
            outlined
          />
        </v-col>
        <v-col
          class=""
          cols="12"
          sm="6"
          md="4"
        >
          <InputPriceSpaced
            v-model="form.proofIncome"
            label="Подтвержденный доход"
            role="presentation"
            outlined
            dense
            required
            :disabled="loading"
          />
        </v-col>
        <v-col
          class=""
          cols="12"
          sm="6"
          md="4"
        >
          <InputPriceSpaced
            v-model="form.additionalIncome"
            label="Дополнительный доход"
            role="presentation"
            outlined
            dense
            :disabled="loading"
          />
          <div class="d-flex">
            <v-checkbox
              v-if="form.additionalIncome"
              v-model="form.canConfirmAdditionalIncome"
              hide-details
              color="primary"
              label="Подтверждение дополнительного дохода"
            />
          </div>
        </v-col>
      </v-row>

      <template v-if="targetProduct === 'REFINANCING'">
        <h3 class="mt-5">
          Платежи по кредитам(Только для рефинансирования)
        </h3>
        <v-row
          justify="center"
          align="start"
          dense
          class="my-2"
        >
          <v-col
            class=""
            cols="12"
            sm="6"
            md="4"
          >
            <InputPriceSpaced
              v-model="form.refinancedMonthlyPayment"
              label="Текущий ежемесячный платёж"
              role="presentation"
              outlined
              dense
              required
              :disabled="loading"
              :require-rules="requiredRules"
            />
          </v-col>
        </v-row>
      </template>
      <!--СОХРАНЕНИЕ ПОЛЕЙ АНКЕТЫ-->
      <v-row>
        <v-col>
          <small
            v-if="isEdited"
            class="error--text"
          >* Сохраните изменения перед загрузкой документов</small>
        </v-col>
        <v-col class="text-right">
          <v-btn
            :disabled="!isEdited"
            :loading="loading"
            color="success"
            @click="applicationUpdateSend"
          >
            Сохранить изменения
          </v-btn>
        </v-col>
      </v-row>

      <!--ДОКУМЕНТЫ-->
      <DomClickDealApplicationDocBlock
        :application-id="application.id"
        :documents="application.documents"
        :deal-id="dealId"
        :loading-parent="loading"
        :additional-data="additionalData"
        :refresh-documents-process="refreshDocumentsProcess"
        @refresh-application-documents="$emit('refresh-application-documents')"
      />

      <div class="mt-15">
        <h3>СМС подтверждение</h3>
        <div
          v-if="application.acceptance.status === 'REQUESTED'"
          class="mt-2"
        >
          <v-chip
            small
            class="ma-2"
            :color="dict.acceptanceStatuses[application.acceptance.status].color"
          >
            {{ dict.acceptanceStatuses[application.acceptance.status].text }}
          </v-chip>
          {{ $Global.DateFormat.DateTimeShort(application.acceptance.lastRequestMoment) }}
          <v-text-field
            v-model="objAcceptance.code"
            class="mt-5"
            style="width: 300px"
            outlined
            dense
            type="text"
            label="Код подтверждения"
            placeholder=""
            autocomplete="off"
            :error-messages="objAcceptance.error"
            :rules="requiredRules"
            :disabled="loading"
          >
            <template #append>
              <v-btn
                color="secondary"
                small
                text
                :disabled="loading"
                @click="sendAcceptanceCode"
              >
                Отправить
              </v-btn>
            </template>
          </v-text-field>
          <!-- TODO Получение кода по АПИ - только для тестового стенда -->
          <!--          <div class="d-flex">-->
          <!--            <div class="pa-2" style="border: 1px dotted red">-->
          <!--              <div class="error&#45;&#45;text mb-5">-->
          <!--                <small>-->
          <!--                  Только для тестирования-->
          <!--                </small>-->
          <!--              </div>-->
          <!--              <v-btn color="warning"-->
          <!--                     small-->
          <!--                     :disabled="loading"-->
          <!--                     @click="debugGetAcceptanceCode">-->
          <!--                Получить коды для подписи-->
          <!--              </v-btn>-->
          <!--              <pre>-->
          <!--              {{debugAcceptanceCode}}-->
          <!--            </pre>-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
        <div
          v-if="application.acceptance.status === 'APPROVED'"
          class="mt-2"
        >
          <v-chip
            small
            class="ma-2"
            :color="dict.acceptanceStatuses[application.acceptance.status].color"
          >
            {{ dict.acceptanceStatuses[application.acceptance.status].text }}
          </v-chip>
          {{ $Global.DateFormat.DateTimeShort(application.acceptance.signMoment) }}
        </div>
        <div
          v-if="application.acceptance.status === 'NOT_SENT'"
          class="mt-2"
        >
          <v-chip
            small
            :color="dict.acceptanceStatuses[application.acceptance.status].color"
            class="ma-2"
          >
            {{ dict.acceptanceStatuses[application.acceptance.status].text }}
          </v-chip>
        </div>
      </div>
      <!--   Ошибки сохранения анкеты   -->
      <v-dialog
        :value="!!errorSave"
        scrollable
        max-width="500"
        persistent
      >
        <v-card>
          <v-card-title class="title primary--text">
            Ошибка
          </v-card-title>
          <v-divider />
          <v-card-text class="pt-2 text-center">
            <div class="error--text text-h6">
              Не удалось сохранить анкету
            </div>
            <div>
              {{ errorSave }}
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              @click="errorSave = null"
            >
              ок
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-form>
</template>

<script>
import { VuetifyInputValidatorRules } from '@/plugins/helpers/ValidationUtils'
import {
  marriageStatus,
  education,
  previousPassportDefault,
  jobSeniority,
  jobIncomeType,
  incomeVerificationDocument,
  gender,
  citizenship,
  acceptanceStatuses
} from '../../DomClickDict'
import NativeDateInputField from '@/components/input/NativeDateInputField'
import isEqual from 'lodash/isEqual'
import dealAPI from '@/Api/Crm/Mortgage/Docmclick/Deals'
import InputPriceSpaced from '@/components/input/price/InputPriceSpaced'
import DomClickDealAddressForm
  from '@/views/MortgageProgramWorksheet/DomClickWorksheet/deal/application/DomClickDealAddressForm'
import DatesArrayInput from '@/_dev/DatesArrayInput'
import DomClickDealApplicationDocBlock
  from '@/views/MortgageProgramWorksheet/DomClickWorksheet/deal/application/DomClickDealApplicationDocBlock'

export default {
  name: 'DomClickDealApplicationForm',
  components: { DomClickDealApplicationDocBlock, DatesArrayInput, DomClickDealAddressForm, InputPriceSpaced, NativeDateInputField },
  props: {
    dealId: {
      type: Number,
      required: true
    },
    targetProduct: {
      type: String,
      required: true
    },
    additionalData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    refreshDocumentsProcess: {
      type: Boolean,
      default: false
    },
    application: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      errorSave: null,
      isPreviousPassport: false, // Если ранее менял паспотр
      objAcceptance: {
        code: '',
        error: null
      },
      originalForm: {},
      form: {
        fullName: null, // ФИО
        birthday: null, // Дата рождения
        gender: null, // Пол
        marriageStatus: 'MARRIED', // Семейное положение
        marriageContract: false, // Наличие брачного контракта
        contactPhoneMobile: null, // Контактный телефон
        contactMail: null, // Контактный email
        education: { // Данные об образовании
          type: null,
          course: null // если не оконченное
        },
        mandatoryCoBorrower: true, // Флаг основного созаемщика
        considerCoBorrowerIncome: false, // Рассматривать доход созаемщика
        hasDisabledChild: false, // Наличие ребенка инвалида в семье
        job: { // Данные о месте работы
          employmentDate: null, // Дата устройства на последнее место работы в формате yyyy-MM-dd
          jobOrganization: null, // Название организации, в которой работает клиент
          jobPosition: null, // Должность
          jobOrganizationInn: null, // ИНН организации, в которой работает клиент
          jobSeniority: null, // Общий трудовой стаж за 5 лет
          jobPhoneNumber: null // Номер телефона работодателя
        },
        jobIncomeTypeId: null, // Способ подтверждения дохода
        incomeVerificationDocument: null, // Тип документа, подтверждающего доход
        additionalIncome: 0, // Дополнительный доход
        canConfirmAdditionalIncome: false, // Подтверждение дополнительного дохода
        proofIncome: 0, // Подтвержденный доход
        passport: { // Данные паспорта
          series: null,
          number: null,
          birthPlace: null,
          issuer: null,
          issueDate: null,
          subdivisionCode: null
        },
        previousPassport: null, // Данные СТАРОГО паспорта
        registrationAddress: { // Данные об адресе регистрации
          postalCode: '',
          country: '',
          regionType: '',
          region: '',
          areaType: '',
          area: '',
          cityType: '',
          city: '',
          settlementType: '',
          settlement: '',
          streetType: '',
          street: '',
          houseType: '',
          house: '',
          blockType: '',
          blockTypeFull: '',
          block: '',
          flatType: '',
          flat: ''
        },
        actualAddress: { // Данные об адресе проживания
          postalCode: '',
          country: '',
          regionType: '',
          region: '',
          areaType: '',
          area: '',
          cityType: '',
          city: '',
          settlementType: '',
          settlement: '',
          streetType: '',
          street: '',
          houseType: '',
          house: '',
          blockType: '',
          blockTypeFull: '',
          block: '',
          flatType: '',
          flat: ''
        },
        citizenship: 'RU', // Гражданство
        snils: null, // Номер СНИЛС
        inn: null, // Номер ИНН
        childrenBirthdays: [], // Даты рождения детей
        refinancedMonthlyPayment: 0, // Текущий ежемесячный платёж(для рефинансирования)
        isPensionViaSber: false,
        isTempRegistration: false
      },
      requiredRules: [
        (v) => !!v || 'Важное поле'
      ],
      passportRules: VuetifyInputValidatorRules.docPassport,
      innRules: VuetifyInputValidatorRules.innRules,
      snilsRules: VuetifyInputValidatorRules.snilsRules,
      dict: {
        acceptanceStatuses,
        citizenship,
        incomeVerificationDocument,
        jobSeniority,
        jobIncomeType,
        marriageStatus,
        gender,
        education
      },
      errors: [],
      loading: false,
      debugAcceptanceCode: []
    }
  },
  computed: {
    isEdited () {
      return !isEqual(this.form, this.originalForm)
    }
  },
  mounted () {
    this.initForm()
  },
  methods: {
    // TODO HIDE -- Можно игнорить, оно оставлено для обратной совместимости, основное поле с адресом registrationAdress <Telegram> @Dimassik31
    // copyActualAdress () {
    //   this.form.actualAddress = JSON.parse(JSON.stringify(this.form.registrationAddress))
    // },
    debugGetAcceptanceCode () {
      this.loading = true
      this.debugAcceptanceCode = []
      dealAPI.getDebugAcceptanceCode(this.form.contactPhoneMobile)
        .then(({ data }) => {
          console.warn('debugGetAcceptanceCode RESULT: ', data)
          this.debugAcceptanceCode = data?.data || []
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    sendAcceptanceCode () {
      this.loading = true
      this.objAcceptance.error = ''
      dealAPI.applicationAcceptance(this.dealId, this.application.id, { code: this.objAcceptance.code })
        .then(({ data }) => {
          console.warn('sendAcceptanceCode RESULT: ', data)
          this.$emit('update')
        })
        .catch((error) => {
          console.error(error)
          this.objAcceptance.error = error.response?.data[0]?.message || 'Неверный код подтверждения'
        })
        .finally(() => {
          this.loading = false
        })
    },
    changePreviousPassport (value) {
      if (value) {
        const { previousPassport } = this.application
        this.$set(this.form, 'previousPassport', previousPassport || previousPassportDefault)
      } else {
        this.$set(this.form, 'previousPassport', null)
      }
    },
    initForm () {
      console.warn('initForm', this.application)
      if (this.application) {
        for (const fieldKey in this.form) {
          // Условия для данных
          if (this.application[fieldKey] !== null) {
            this.$set(this.form, fieldKey, this.application[fieldKey])
          }
          // Условия для интерфейсов
          if (fieldKey === 'previousPassport') {
            if (this.form[fieldKey]) {
              this.isPreviousPassport = true
            } else {
              this.isPreviousPassport = false
              this.$set(this.form, fieldKey, null)
            }
          }
        }
      }
      this.originalForm = JSON.parse(JSON.stringify(this.form))
    },
    applicationUpdateSend () {
      console.warn('applicationUpdateSend')
      this.$refs.form.validate()
      this.loading = true
      this.errorSave = null
      dealAPI.applicationUpdate(this.dealId, this.application.id, this.form)
        .then(({ data }) => {
          console.warn('applicationUpdateSend RESULT: ', data)
          if (Array.isArray(data?.errors) && data?.errors.length) {
            this.errorSave = data?.errors.map((err) => err)?.join('; ')
          } else {
            this.$emit('update')
          }
        })
        .catch((error) => {
          console.error('applicationUpdateSend', error)
          this.errorSave = error?.message
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped>

</style>
