<template>
  <TwPanelContentBlock class="tw-mb-[6px]">
    <template #title>
      Запрос услуги по заявке
    </template>

    <div class="tw-flex tw-flex-col tw-gap-[6px]">
      <div v-if="info">
        <div v-if="info.service">
          Тип заявки: <b>{{ info.service.label }}</b>
        </div>
        <div>
          Бесплатно: <b>{{ info.free ? 'Да':'Нет' }}</b>
        </div>
        <div v-if="info.contract">
          Договор:
          <ViewPanelLink
            v-if="info.contract.value"
            :id="info.contract.value"
            component="deal-contract-show"
          >
            {{ info.contract.label }}
          </ViewPanelLink>
        </div>
        <br>
        <div v-if="info.freeCondition">
          Причина бесп. услуги: <b>{{ info.freeCondition.label }}</b>
        </div>
        <div v-if="info.relatedContract">
          Договор основание:
          <ViewPanelLink
            v-if="info.relatedContract.value"
            :id="info.relatedContract.value"
            component="deal-contract-show"
          >
            {{ info.relatedContract.label }}
          </ViewPanelLink>
        </div>
        <div v-if="info.relatedDeal">
          Заявка основание:
          <ViewPanelLink
            v-if="info.relatedDeal.value"
            :id="info.relatedDeal.value"
            component="deal-show"
          >
            #{{ info.relatedDeal.label }}
          </ViewPanelLink>
        </div>
        <div v-if="info.relatedComplex">
          ЖК:
          <ViewPanelLink
            v-if="info.relatedComplex.value"
            :id="info.relatedComplex.value"
            component="realty-complex-show"
          >
            {{ info.relatedComplex.label }}
          </ViewPanelLink>
        </div>
        <div v-if="info.comment">
          Комментарий: <b v-html="$Global.nl2br(info.comment || '')" />
        </div>
        <br>
        <template v-if="info.form && Array.isArray(info.form)">
          <div
            v-for="(value, index) in info.form"
            :key="index"
          >
            {{ value }}
          </div>
        </template>
      </div>
    </div>
  </TwPanelContentBlock>
</template>

<script>
import { defineComponent } from 'vue'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink.vue'

export default defineComponent({
  name: 'DealRelationsServiceRequestBlock',
  components: { TwPanelContentBlock, ViewPanelLink },
  props: {
    info: {
      type: Object,
      default: null
    }
  }
})
</script>

<style scoped>

</style>
