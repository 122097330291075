<template>
  <div class="tw-grid tw-grid-cols-[minmax(250px,_300px),_1fr] 2xl:tw-gap-x-[50px] tw-gap-x-[30px] tw-gap-y-[10px] tw-flex-wrap">
    <div>
      <div class="tw-sticky tw-top-0">
        <h1 class="tw-text-h1 tw-mb-[15px]">
          Фиксация клиента
        </h1>
        <div class="tw-text-[18px] tw-font-medium tw-my-[5px]">
          <span>Текущий статус: </span>
          <div class="tw-text-an-flat-gray-4">
            <template v-if="isNew">
              Новая фиксация
            </template>
            <template v-else-if="showInitData?.draft">
              Черновик
            </template>
            <div
              v-else-if="showInitData?.status"
              class="tw-w-full"
            >
              <div
                class="tw-mt-[10px]"
                :style="{ color: showInitData.status?.color }"
              >
                <b>{{ showInitData.status?.label }}</b>
              </div>
              <div
                v-if="showInitData.statusChangeDate"
                class="tw-mt-[5px] tw-text-main-sm2"
              >
                <div>{{ showInitData.statusChangeDate }}</div>
              </div>
              <div
                v-if="showInitData.statusComment"
                class="tw-mt-[5px] tw-border-l-4 tw-p-[5px] tw-text-main-sm2"
                :style="{ borderColor: showInitData.status?.color }"
              >
                <i>{{ showInitData.statusComment }}</i>
              </div>
            </div>
            <div
              v-if="showInitData && showInitData.cancelRequest"
              class="tw-mt-[20px]"
            >
              <span class="tw-text-an-flat-black-main">Запрос на отмену:</span>
              <div class="tw-mt-[5px] tw-border-l-4 tw-border-an-flat-red-main tw-p-[5px] tw-text-main-sm2">
                <i>{{ showInitData.cancelRequest.comment }}</i><br>
                <i>от {{ showInitData.cancelRequest.createdAt }}</i>
              </div>
            </div>
          </div>
        </div>
        <div class="tw-text-main-md tw-bg-an-flat-panel-modal-bg tw-rounded-2xl tw-px-[25px] tw-py-[18px] tw-my-[26px]">
          <div class="tw-mb-[24px]">
            <div class="tw-text-an-flat-gray-4 tw-mb-[5px]">
              Номер фиксации:
            </div>
            <div class="tw-font-semibold">
              <template v-if="isNew">
                ~
              </template>
              <template v-else-if="showInitData?.id">
                {{ showInitData.id }}
              </template>
            </div>
          </div>
          <div class="tw-mb-[24px]">
            <div class="tw-text-an-flat-gray-4 tw-mb-[5px]">
              Дата создания:
            </div>
            <div class="tw-font-semibold">
              <template v-if="isNew">
                ~
              </template>
              <template v-else-if="showInitData?.createdAt">
                {{ $Global.DateFormat.DateTimeShort(showInitData.createdAt) }}
              </template>
              <template v-else>
                Неизвестно
              </template>
            </div>
          </div>

          <div v-if="showInitData && showInitData.status && showInitData.status.value === 'confirmed' && (showInitData.confirmedBefore || showInitData.confirmedPrice)">
            <div v-if="showInitData.confirmedBefore">
              <div class="tw-text-an-flat-gray-4 tw-mb-[5px]">
                Подтверждено до:
              </div>
              <div class="">
                <strong>{{ $Global.DateFormat.DateTimeShort(showInitData.confirmedBefore) }}</strong>
              </div>
            </div>
            <div v-if="showInitData.confirmedPrice">
              <div class="tw-text-an-flat-gray-4 tw-mb-[5px]">
                Потвержденная цена:
              </div>
              <div class="">
                <strong>{{ (showInitData.confirmedPrice || 0).toLocaleString('ru') }}</strong> руб.
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="$v.form.$invalid"
          class="tw-text-an-flat-red-main tw-p-[5px] tw-mt-[5px] tw-text-center"
        >
          * Заполните обязательные поля формы фиксации
        </div>
        <div class="tw-py-[24px] tw-flex tw-flex-wrap tw-gap-2">
          <button
            v-if="isNew || form.draft"
            :disabled="$v.form.$invalid || loading"
            class="tw-w-full tw-rounded-md tw-bg-white tw-border tw-border-solid tw-border-an-flat-green-btn-bg tw-text-an-flat-green-btn-bg tw-py-[18px] tw-font-bold tw-text-[14px] tw-flex tw-gap-[12px] tw-justify-center tw-items-center enabled:hover:tw-drop-shadow-lg disabled:tw-opacity-50"
            @click.stop="onSubmit(false)"
          >
            Сохранить черновик
          </button>
          <button
            v-if="isNew || form.draft"
            :disabled="$v.form.$invalid || loading"
            class="tw-w-full tw-rounded-md tw-bg-an-flat-green-btn-bg tw-py-[18px] tw-font-bold tw-text-[14px] tw-text-white tw-flex tw-gap-[12px] tw-justify-center tw-items-center enabled:hover:tw-drop-shadow-lg disabled:tw-opacity-50"
            @click.stop="onSubmit(true)"
          >
            <svg
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.5625 6.48828L10.0625 0.988281C9.8125 0.738281 9.4375 0.738281 9.1875 0.988281L7.1875 2.98828C6.875 3.30078 7 3.67578 7.1875 3.86328L7.625 4.30078L5.75 6.17578C4.8125 5.98828 2.25 5.55078 0.875 6.92578C0.625 7.17578 0.625 7.55078 0.875 7.80078L4.4375 11.3633L0.5 15.3008C0.25 15.5508 0.25 15.9258 0.5 16.1758C0.75 16.4258 1.1875 16.3633 1.375 16.1758L5.3125 12.2383L8.875 15.8008C9.25 16.1133 9.625 15.9883 9.75 15.8008C11.125 14.4258 10.6875 11.8633 10.5 10.9258L12.375 9.05078L12.8125 9.48828C13.0625 9.73828 13.4375 9.73828 13.6875 9.48828L15.6875 7.48828C15.8125 7.11328 15.8125 6.73828 15.5625 6.48828ZM13.125 8.05078L12.6875 7.61328C12.4375 7.36328 12.0625 7.36328 11.8125 7.61328L9.25 10.2383C9.0625 10.4258 9 10.6133 9.0625 10.8633C9.25 11.5508 9.5625 13.2383 9.0625 14.3633L2.1875 7.48828C3.25 6.98828 5 7.30078 5.6875 7.48828C5.875 7.55078 6.125 7.48828 6.3125 7.30078L8.875 4.73828C9.25 4.36328 9.0625 4.05078 8.875 3.86328L8.4375 3.42578L9.625 2.30078L14.25 6.92578L13.125 8.05078Z"
                fill="white"
              />
            </svg>
            Сохранить и отправить
          </button>
          <RequestToDeveloperCancel
            v-if="form.id && uiParams && uiParams.cancelButton && uiParams.cancelButton.enabled"
            :id="form.id"
            @success="$emit('refresh')"
          />
        </div>
      </div>
    </div>
    <div class="tw-overflow-auto">
      <div class=" tw-bg-an-flat-panel-modal-bg tw-rounded-2xl lg:tw-p-[44px] tw-p-[22px]">
        <div class="tw-text-h4 tw-mb-[26px]">
          Зафиксированные данные
        </div>
        <div class="tw-bg-white tw-px-[24px] tw-py-[28px] tw-rounded-md tw-my-[20px]">
          <div class="tw-font-semibold tw-mb-[15px]">
            Данные клиентов
          </div>
          <div
            v-for="person in personsInfoArray"
            :key="person.id"
            class="tw-flex tw-flex-wrap tw-gap-[16px]"
          >
            <div class="tw-w-full tw-text-right tw-text-main-xs">
              <ViewPanelLink
                v-if="form.draft && person.id"
                :id="person.id"
                label="Клиент"
                component="person-show"
                :callback="createInit"
              >
                Изменить данные клиента
              </ViewPanelLink>
            </div>
            <div class="tw-flex tw-flex-wrap tw-gap-[16px] tw-w-full">
              <div class="tw-flex-1">
                <TwInputText
                  class="tw-font-semibold"
                  :error-text="person.surname ? '':'Некорректное значение'"
                  :disabled="true"
                  type="text"
                  placeholder="Фамилия"
                  :value="person.surname"
                  :show-label="true"
                />
              </div>
              <div class="tw-flex-1">
                <TwInputText
                  class="tw-font-semibold"
                  :error-text="person.name ? '':'Некорректное значение'"
                  :disabled="true"
                  type="text"
                  placeholder="Имя"
                  :value="person.name"
                  :show-label="true"
                />
              </div>
            </div>
            <div class="tw-flex tw-flex-wrap tw-gap-[16px] tw-w-full">
              <div class="tw-flex-1">
                <TwInputText
                  class="tw-font-semibold"
                  :error-text="person.fatherName ? '':'Некорректное значение'"
                  :disabled="true"
                  type="text"
                  placeholder="Отчество"
                  :value="person.fatherName"
                  :show-label="true"
                />
              </div>
              <div class="tw-flex-1">
                <TwInputPhone
                  class="tw-font-semibold"
                  :error-text="person.phone ? '':'Некорректное значение'"
                  :disabled="true"
                  placeholder="Номер телефона"
                  :value="person.phone || 'Остутствует'"
                  :show-label="true"
                />
              </div>
            </div>
          </div>
          <div class="tw-font-semibold tw-mt-[26px] tw-mb-[10px]">
            Данные бронируемого объекта
          </div>
          <div class="tw-flex tw-flex-wrap tw-gap-[16px]">
            <div class="tw-flex-1">
              <TwSelectRelations
                v-model="form.companyId"
                :disabled="disabledInput"
                :multi="false"
                label="Застройщик"
                list-label="Найти застройщика"
                relation-name="developer"
                :error-text="$v.form.companyId.$invalid && 'Обязательное поле'"
                @input="formInfo"
              />
            </div>
            <div
              v-if="form.companyId"
              class="tw-flex-1"
            >
              <TwSelectRelations
                v-model="form.complexId"
                :disabled="disabledInput"
                :multi="false"
                label="Жилой комплекс"
                list-label="Найти ЖК"
                relation-name="complex"
                :error-text="$v.form.complexId.$invalid && 'Обязательное поле'"
                :default-filters="complexFilters"
                @input="formInfo"
              />
            </div>
            <div
              v-if="formInfoResult && formInfoResult.data && formInfoResult.data.description"
              class="tw-w-full"
            >
              <TwPanelInfoBlock>
                <template #title>
                  Примечание для риелтора
                </template>
                <template #text>
                  {{ formInfoResult.data.description }}
                </template>
              </TwPanelInfoBlock>
            </div>
            <div
              v-if="formInfoResult && formInfoResult.data && formInfoResult.data.days"
              class="tw-w-full"
            >
              <div class="tw-flex tw-items-start tw-gap-[10px] tw-px-[14px] tw-py-[15px] tw-border-an-flat-blue-block-panel tw-border-4 tw-rounded-[14px] tw-bg-white">
                <div>
                  <svg
                    width="40"
                    height="41"
                    viewBox="0 0 40 41"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20 0.135864C9 0.135864 0 9.13586 0 20.1359C0 31.1359 9 40.1359 20 40.1359C31 40.1359 40 31.1359 40 20.1359C40 9.13586 31 0.135864 20 0.135864Z"
                      fill="#C7D6E8"
                    />
                    <g clip-path="url(#clip0_716_58159)">
                      <path
                        d="M8.58169 25.0867C8.55089 25.2615 8.59076 25.4415 8.69263 25.587C8.79446 25.7325 8.95 25.8316 9.12491 25.8624L12.0971 26.3865L10.2248 15.7681L8.58169 25.0867Z"
                        fill="white"
                      />
                      <path
                        d="M12.1509 8.7183C11.9756 8.68647 11.7947 8.72576 11.6484 8.82754C11.5022 8.92937 11.4025 9.08531 11.3716 9.26085L10.6614 13.2885L24.1736 10.9059L12.1509 8.7183Z"
                        fill="white"
                      />
                      <path
                        d="M31.4192 27.9886L28.6284 12.1613C28.5642 11.7971 28.217 11.5541 27.8526 11.6182L12.0254 14.4089C11.6612 14.4731 11.418 14.8204 11.4822 15.1846L14.273 31.0119C14.3038 31.1868 14.4029 31.3423 14.5484 31.4442C14.6617 31.5236 14.796 31.5653 14.9325 31.5653C14.9712 31.5653 15.0101 31.5619 15.0487 31.5551L30.876 28.7644C31.0509 28.7336 31.2064 28.6344 31.3083 28.489C31.4101 28.3435 31.45 28.1635 31.4192 27.9886ZM15.0503 19.9953C14.9861 19.6311 15.2293 19.2838 15.5935 19.2196L26.1451 17.359C26.5096 17.2949 26.8567 17.538 26.9209 17.9022C26.9851 18.2664 26.7418 18.6137 26.3776 18.6779L15.8261 20.5385C15.7868 20.5454 15.7476 20.5488 15.709 20.5488C15.3901 20.5488 15.1076 20.3202 15.0503 19.9953ZM23.7907 24.5739L16.7564 25.8143C16.717 25.8212 16.6779 25.8245 16.6393 25.8245C16.3204 25.8245 16.0379 25.596 15.9806 25.2711C15.9164 24.9069 16.1596 24.5595 16.5238 24.4953L23.5581 23.255C23.9226 23.1907 24.2697 23.434 24.3339 23.7982C24.3981 24.1624 24.1549 24.5097 23.7907 24.5739ZM26.8427 21.3158L16.2912 23.1764C16.2518 23.1833 16.2127 23.1866 16.1741 23.1866C15.8552 23.1866 15.5727 22.9581 15.5154 22.6332C15.4512 22.269 15.6944 21.9216 16.0586 21.8574L26.6102 19.9969C26.9747 19.9327 27.3217 20.1759 27.386 20.5401C27.4501 20.9043 27.2069 21.2516 26.8427 21.3158Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_716_58159">
                        <rect
                          width="22.8571"
                          height="22.8571"
                          fill="white"
                          transform="translate(8.57141 8.70728)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div class="flex tw-text-[13px]">
                  <b class="tw-block">Срок фиксации</b>
                  <span class="tw-block tw-text-[13px] tw-text-[#4B5763]">{{ formInfoResult.data.days }}дн.</span>
                </div>
              </div>
            </div>
            <div class="tw-w-full">
              <TwTextarea
                v-model="form.comment"
                :show-label="true"
                :disabled="disabledInput"
                label="Комментарий"
                placeholder="Комментарий"
                rows="6"
              />
            </div>
          </div>
          <div
            v-if="form.draft"
            class="tw-grow tw-text-an-flat-red-main tw-text-center tw-mt-[10px]"
          >
            Пожалуйста перепроверьте корректность всех данных клиента
          </div>
        </div>
        <template v-if="formInfoResult && formInfoResult.data && formInfoResult.data.needPetition">
          <div
            v-if="formInfoResult.dictionary && formInfoResult.dictionary.petitionTemplate"
            class="tw-my-[15px] tw-text-an-flat-gray-4 tw-flex tw-flex-wrap tw-gap-[10px]"
          >
            Скачайте шаблон ходатайства, заполните его и прикрепите к запросу:
            <template v-for="petition in formInfoResult.dictionary.petitionTemplate">
              <template v-if="Array.isArray(petition.files) && petition.files.length">
                <a
                  v-for="file in petition.files"
                  :key="petition.id+ '_' + file.id"
                  :href="file.url"
                  target="_blank"
                  class="tw-underline tw-text-an-flat-dark-blue-link"
                >{{ file.name }}</a>
              </template>
            </template>
          </div>
          <TwUploadFilesList
            v-model="form.petitionFileId"
            label="Загрузить ходатайство"
            :api="fileApi"
            :is-public="true"
            :multiple="false"
            :disabled="disabledInput"
          />
          <div
            v-if="$v.form.petitionFileId.$invalid"
            class="tw-mt-[5px] tw-text-an-flat-red-main tw-p-[5px]"
          >
            {{ $v.form.petitionFileId.$invalid ? 'Обязательное поле - прикрепите ходатайство':'' }}
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/Api/Rtd/RequestToDeveloper/Request'
import fileApi from '@/Api/Rtd/File'
import TwInputText from '@/components/tw-ui/ui-kit/input/TwInputText.vue'
import TwTextarea from '@/components/tw-ui/ui-kit/input/TwTextarea.vue'
import personAPI from '@/Api/Crm/Person'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink.vue'
import TwUploadFilesList from '@/components/tw-ui/ui-kit/files/TwUploadFilesList.vue'
import TwSelectRelations from '@/components/tw-ui/ui-kit/input/select-relations/TwSelectRelations.vue'
import { required } from 'vuelidate/lib/validators'
import RequestToDeveloperCancel from '@/views/Newbuildings/RequestToDeveloper/RequestToDeveloperCancel.vue'
import TwPanelInfoBlock from '@/components/tw-ui/panels/TwPanelInfoBlock.vue'
import TwInputPhone from '@/components/tw-ui/ui-kit/custom/TwInputPhone.vue'

export default {
  name: 'RequestToDeveloperFixationUI',
  components: { TwInputPhone, TwPanelInfoBlock, RequestToDeveloperCancel, TwSelectRelations, TwUploadFilesList, ViewPanelLink, TwTextarea, TwInputText },
  props: {
    createInitData: {
      type: Object,
      default: null
    },
    showInitData: {
      type: Object,
      default: null
    },
    isNew: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      fileApi,
      loading: false,

      personsInfoArray: [],
      form: {
        id: null,
        dealId: null,
        companyId: this.createInitData?.companyId || null,
        complexId: this.createInitData?.complex || null,
        comment: '',
        personId: [],
        petitionFileId: [],
        draft: false
      },
      formInfoResult: null
    }
  },
  validations () {
    return {
      form: {
        companyId: {
          required
        },
        complexId: {
          required
        },
        petitionFileId: {
          ...(this.formInfoResult?.data?.needPetition) && {
            required
          }
        }
      }
    }
  },
  computed: {
    disabledInput () {
      return this.loading || !this.form.draft
    },
    complexFilters () {
      return {
        builderId: this.form?.companyId ? [this.form.companyId] : []
      }
    },
    uiParams () {
      return this.showInitData?.ui || null
    }
  },
  watch: {
    'form.personId' (newValue) {
      this.buildViewPersonData(newValue)
    }
  },
  mounted () {
    if (this.isNew) {
      this.createInit()
    } else {
      this.showInit()
    }
  },
  methods: {
    onSubmit (saveAndSend = false) {
      console.warn('saveDraft', this.form, this.$v.form.$invalid)
      this.$v.$reset()
      this.$v.form.$touch()
      if (!this.$v.form.$invalid) {
        this.loading = true
        if (this.isNew) {
          API.draftStore({
            ...this.form,
            saveAndSend
          }, 'fixation').then(({ data: result }) => {
            console.warn('SEND SUCCESS', result)
            this.$emit('created', result.data)
            if (saveAndSend) {
              this.$emit('success-send')
            }
          }).catch((error) => {
            console.error('SEND ERROR', error)
          }).finally(() => {
            this.loading = false
            this.$emit('refresh')
          })
        } else {
          API.update(this.form.id, {
            ...this.form,
            saveAndSend
          }, 'fixation').then(({ data: result }) => {
            console.warn('SEND SUCCESS', result)
            this.$emit('updated', result.data)
            if (saveAndSend) {
              this.$emit('success-send')
            }
          }).catch((error) => {
            console.error('SEND ERROR', error)
          }).finally(() => {
            this.loading = false
            this.$emit('refresh')
          })
        }
      }
    },
    formInfo () {
      console.warn('formInfo', this.form, this.formInfoResult)
      this.formInfoResult = null
      if (this.form?.companyId && this.form?.complexId) {
        this.loading = true
        API.formInfo({ companyId: this.form.companyId, complexId: this.form.complexId }, 'fixation').then(({ data: result }) => {
          console.warn('formInfo SUCCESS', result)
          this.formInfoResult = result || null
        }).catch((error) => {
          // console.log(error.response?.data?.complexId)
          console.error('formInfo ERROR', error)
          if (error.response?.data?.complexId) {
            this.form.complexId = null
          }
        }).finally(() => {
          this.loading = false
        })
      }
    },
    createInit () {
      console.warn('createInit', this.createInitData)
      this.loading = true
      API.create({ dealId: this.createInitData?.dealId }, this.createInitData?.requestType).then(({ data: result }) => {
        console.warn(result)
        this.initFormData(result?.data)
      }).catch((error) => {
        console.error(error)
      }).finally(() => {
        this.loading = false
      })
    },
    showInit () {
      console.warn('showInit', this.showInitData)
      this.initFormData(this.showInitData)
    },
    initFormData (presetObject) {
      console.warn('initFormData', presetObject)
      return new Promise((resolve) => {
        if (presetObject) {
          for (const key in this.form) {
            if (key === 'personId') {
              this.$set(this.form, 'personId', presetObject?.person?.map(p => p.id))
            } else if (presetObject[key]) {
              this.$set(this.form, key, presetObject[key])
            }
          }
        }
        this.formInfo()
        resolve()
      })
    },
    async buildViewPersonData (personIds) {
      console.warn('buildViewPersonData', personIds)
      this.personsInfoArray = []
      // список клиентов генерится по разному для черновика и отправленной фиксации
      // Так как в отправленной нужно показать данные клиента на момент фиксации
      if (this.form?.draft) {
        console.warn('buildViewPersonData ЧЕРНОВИК', personIds)
        if (Array.isArray(personIds) && personIds.length) {
          for (const personId of personIds) {
            console.error(personId)
            const personInfo = await personAPI.show(personId).then(({ data }) => data?.data ? { id: data?.data?.id, name: data?.data?.name, fatherName: data?.data?.fatherName, surname: data?.data?.surname, phone: data?.data?.phones?.[0]?.phone } : null)
            console.error('personInfo', personInfo)
            this.personsInfoArray.push(personInfo)
          }
        } else {
          console.error('Отсутствует список клиентов', personIds)
        }
      } else {
        console.warn('buildViewPersonData ОТПРАВЛЕННАЯ ФИКСАЦИЯ', this.showInitData)
        for (const person of this.showInitData.person) {
          // console.error(person)
          console.error('personInfo', person)
          this.personsInfoArray.push(person)
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
