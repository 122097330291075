import { render, staticRenderFns } from "./DeveloperCommissionPopoverInfo.vue?vue&type=template&id=70c7a404&scoped=true"
import script from "./DeveloperCommissionPopoverInfo.vue?vue&type=script&lang=js"
export * from "./DeveloperCommissionPopoverInfo.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70c7a404",
  null
  
)

export default component.exports