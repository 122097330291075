import { GetterTree } from 'vuex'
import { RootState } from '@/store/types'
import { FiltersState } from '@/store/filters/types'

export const getters: GetterTree<FiltersState, RootState> = {
  getFiltersByKey: (state) => (key: string) => {
    return state[key] || {}
  },
  getCurrentParams: (state) => {
    return state.current
  }
}

export default null
