import { AxiosResponse } from 'axios'
import HTTP, { downloadFile } from '@/Api/http'

const path = '/file'

export default {
  // Загрузка файлов - по умолчанию на приватный диск
  upload (data: object): Promise<AxiosResponse> {
    const $config: any = {
      headers: {
        'Content-Type': 'multipart/form-data;charset=utf-8'
      }
    }
    return HTTP.post(`${path}`, data, $config)
  },
  // Загрузка файлов - на публичный диск
  uploadPublic (data: object): Promise<AxiosResponse> {
    const $config: any = {
      headers: {
        'Content-Type': 'multipart/form-data;charset=utf-8'
      }
    }
    return HTTP.post(`${path}/public`, data, $config)
  },
  show (id: number): Promise<AxiosResponse> {
    const $config = {}
    return HTTP.get(`${path}/${id}`, $config)
  },
  download (url: string, fileName: string) {
    downloadFile(url, fileName)
  }
}
