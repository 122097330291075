<template>
  <TwDialog
    :value="open"
    :is-close-btn="dialogStage === 'error'"
    max-width="600px"
    @input="open = $event"
  >
    <template #header>
      <div class="tw-text-h4 tw-font-semibold">
        Назначить ответственным сотрудника
      </div>
    </template>
    <template #body>
      <div
        v-if="dialogStage === 'form'"
        class="tw-min-h-[400px] tw-flex tw-flex-col tw-gap-[15px]"
      >
        <TwSelectRelations
          v-if="(dealType === 'buy_nb' || dealType === 'sale_nb')"
          v-model="form.complexId"
          :disabled="formProcessing"
          :dense="true"
          :persistent-label="true"
          label="Жилой комплекс"
          list-label="Выбрать ЖК"
          placeholder="Выбрать ЖК"
          relation-name="complex"
        />

        <TwSelect
          v-model="form.userId"
          class="tw-mt-[15px]"
          label="Ответственный"
          placeholder="Выбрать"
          :dense="true"
          :disable-error-border="true"
          :persistent-label="true"
          :options="usersList"
          :error-text="$v.form.userId.$invalid ? 'Обязательное поле':''"
          :disabled="formProcessing"
        />
        <div class="tw-flex-1" />
        <div
          v-if="error"
          class="tw-text-center tw-text-an-flat-red-main"
        >
          {{ error }}
        </div>
        <div
          v-if="$v.form.$invalid"
          class="tw-text-center tw-text-an-flat-red-main"
        >
          Выберите ответственного
        </div>
        <TwCardSimpleFormSaveBlock
          class="tw-mt-[15px]"
          :loading="formProcessing"
          success-title="Назначить"
          bg-color="transparent"
          :is-sticky="false"
          :disable-success="formProcessing || $v.form.$invalid"
          :disable-cancel="formProcessing"
          :visible="true"
          @success="handleSubmit"
          @cancel="open = false"
        />
      </div>
      <div v-else-if="dialogStage === 'success'">
        <div class="tw-text-center">
          <div class="tw-m-auto tw-bg-an-flat-green-btn-bg tw-w-[120px] tw-h-[120px] tw-flex tw-justify-center tw-items-center tw-rounded-full">
            <svg
              width="52"
              height="41"
              viewBox="0 0 52 41"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 19.5833L18.6056 33L47 5"
                stroke="white"
                stroke-width="10"
                stroke-linecap="round"
              />
            </svg>
          </div>
          <div class="tw-my-[20px] tw-font-semibold tw-text-[20px] tw-leading-normal">
            <span class="tw-text-an-flat-disabled-text">Заявка #{{ form.dealId }}</span><br>
            Ответственный успешно переназначен!
          </div>
          <button
            class="tw-min-w-[250px] tw-m-auto tw-rounded-md tw-bg-an-flat-green-btn-bg tw-py-[18px] tw-font-bold tw-text-[14px] tw-text-white tw-flex tw-gap-[12px] tw-justify-center tw-items-center enabled:hover:tw-drop-shadow-lg disabled:tw-opacity-50"
            @click.prevent="$emit('success')"
          >
            Закрыть
          </button>
        </div>
      </div>
      <div
        v-else-if="dialogStage === 'error'"
        class="tw-text-an-flat-red-main"
      >
        <h3>Произошла ошибка:</h3>
        <pre>
          {{ error }}
        </pre>
      </div>
      <div
        v-else
        class="tw-text-an-flat-red-main"
      >
        dialogStage - не описан
      </div>
    </template>
  </TwDialog>
</template>

<script>
import API from '@/Api/Crm/Company/DealDistributionResponsibleUser'
import TwDialog from '@/components/tw-ui/modal/TwDialog.vue'
import TwSelect from '@/components/tw-ui/ui-kit/input/TwSelect.vue'
import { required } from 'vuelidate/lib/validators'
import TwCardSimpleFormSaveBlock from '@/components/tw-ui/forms/TwCardSimpleFormSaveBlock.vue'
import TwSelectRelations from '@/components/tw-ui/ui-kit/input/select-relations/TwSelectRelations.vue'

const dialogStages = {
  form: 'form',
  success: 'success',
  error: 'error'
}

export default {
  name: 'DealDistributionSetUserModal',
  components: {
    TwSelectRelations,
    TwCardSimpleFormSaveBlock,
    TwSelect,
    TwDialog
  },
  props: {
    dealType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      dialogStage: dialogStages.form,
      error: null,
      open: false,
      formProcessing: false,
      usersList: [],
      form: {
        complexId: null,
        userId: null,
        dealId: null
      }
    }
  },
  validations () {
    return {
      form: {
        userId: {
          required
        }
      }
    }
  },
  methods: {
    onOpenModal (dealId) {
      console.warn('onOpenModal', dealId)
      this.dialogStage = dialogStages.form
      this.open = true
      this.$set(this.form, 'dealId', dealId)
    },
    handleSubmit () {
      console.warn('handleSubmit', this.form, this.$v.form.$invalid)
      this.$v.$reset()
      this.$v.form.$touch()
      if (!this.$v.form.$invalid) {
        if (this.formProcessing === true) {
          return
        }

        this.error = null
        this.formProcessing = true
        API.distribute(this.form).then(() => {
          this.dialogStage = dialogStages.success
        }).catch((error) => {
          this.error = error?.response?.data
          this.dialogStage = dialogStages.error
        }).finally(() => {
          this.formProcessing = false
        })
      }
    },
    fetchUserList () {
      console.warn('fetchUserList', this.form, this.$v.form.$invalid)
      this.$v.$reset()
      this.$v.form.$touch()
      if (this.formProcessing === true) {
        return
      }

      this.error = null
      this.formProcessing = true
      API.forDistributeList(this.form.dealId, { complexId: this.form.complexId || undefined }).then(({ data: result }) => {
        console.warn('fetchUserList', result.data)
        this.usersList = result.data?.map(item => {
          return {
            ...item,
            label: `${item.user ? item.user.label : '~'} - ${item.distributedAt ? this.$Global.DateFormat.DateTimeShort(item.distributedAt, true) : 'не назначалось'}`,
            value: item.user.value
          }
        })
      }).catch((error) => {
        console.warn('fetchUserList', error)
        this.error = error?.response?.data
      }).finally(() => {
        this.formProcessing = false
      })
    }
  },
  watch: {
    'form.complexId' () {
      this.form.userId = null
      this.fetchUserList()
    },
    open (newValue) {
      if (newValue) {
        this.fetchUserList()
      } else {
        this.usersList = []
        this.form.userId = null
        this.form.complexId = null
      }
    }
  }
}
</script>
