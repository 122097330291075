import { AxiosResponse } from 'axios'
import HTTP from '@/Api/http'

const path = '/deal/buy-nb/company-period-result'

export default {
  listInit (): Promise<AxiosResponse> {
    const $config: any = {}
    return HTTP.get(`${path}/list-init`, $config)
  },
  getList (param?: object, otherConfig?: object): Promise<AxiosResponse> {
    const $config = {
      params: param,
      ...(otherConfig || {})
    }
    return HTTP.get(`${path}`, $config)
  },
  show (id: number): Promise<AxiosResponse> {
    const $config = {}
    return HTTP.get(`${path}/${id}`, $config)
  },
  storeBlock (id: number, data: any): Promise<AxiosResponse> {
    const $config = {}
    return HTTP.post(`${path}/${id}/block`, data, $config)
  }
}
