<template>
  <div class="json-pretty">
    <pre v-html="syntaxHighlight" />
  </div>
</template>

<script>
export default {
  name: 'JsonPretty',
  props: {
    json: {
      type: [Object, Array],
      default: () => {
        return {}
      }
    }
  },
  computed: {
    syntaxHighlight () {
      let json = ''
      if (typeof this.json !== 'string') {
        json = JSON.stringify(this.json, undefined, 2)
      }
      json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;')
      return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\\-]?\d+)?)/g, function (match) {
        let cls = 'number'
        if (/^"/.test(match)) {
          if (/:$/.test(match)) {
            cls = 'key'
          } else {
            cls = 'string'
          }
        } else if (/true|false/.test(match)) {
          cls = 'boolean'
        } else if (/null/.test(match)) {
          cls = 'null'
        }
        return '<span class="' + cls + '">' + match + '</span>'
      })
    }
  }
}
</script>

<style>
.json-pretty pre {
  font-family: monospace;
  outline: 1px solid #ccc;
  padding: 5px;
  margin: 5px;
  color: black;
}

.json-pretty .string {
  color: darkgreen;
}

.json-pretty .number {
  color: darkorange;
}

.json-pretty .boolean {
  color: darkblue;
}

.json-pretty .null {
  color: magenta;
}

.json-pretty .key {
  color: darkred;
}
</style>
