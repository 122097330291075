import { RouteKeys } from '@/router/types'
import dictPermissionRules from '@/Permissions/dictRules'

export default {
  path: RouteKeys.office,
  name: RouteKeys.office,
  redirect: { name: `${RouteKeys.office}-list` },
  meta: { name: 'Офисы', icon: 'block-company-office', ruleNamespace: dictPermissionRules.AppServicePermissionRulesAuthStructureOfficeCreateOfficeRule },
  component: () => import('@/views/Company/Office/Index.vue'),
  children: [
    {
      path: 'list',
      name: `${RouteKeys.office}-list`,
      meta: { name: 'Список офисов', icon: 'block-company-office', hideInMenu: true },
      component: () => import('@/views/Company/Office/List.vue')
    },
    {
      path: ':id/edit',
      name: `${RouteKeys.office}-edit`,
      meta: { name: 'Редактирование офиса', icon: 'block-company-office', hideInMenu: true },
      component: () => import('@/views/Company/Office/Edit.vue'),
      props: (route:any) => ({ id: route.params.id })
    },
    {
      path: 'create',
      name: `${RouteKeys.office}-create`,
      meta: { name: 'Добавление офиса', icon: 'block-company-office', hideInMenu: true },
      component: () => import('@/views/Company/Office/Edit.vue'),
      props: (route:any) => ({ id: route.params.id })
    }
  ]
}
