<template>
  <div :style="dotBlockStyle" />
</template>

<script>
export default {
  name: 'StatusColorDot',
  props: {
    color: {
      type: String,
      default: ''
    },
    size: {
      type: Number,
      default: 10
    },
    radius: {
      type: String,
      default: ''
    }
  },
  computed: {
    dotBlockStyle () {
      return {
        backgroundColor: this.color || 'lightgray',
        width: this.size ? this.size + 'px' : '10px',
        height: this.size ? this.size + 'px' : '10px',
        borderRadius: this.radius || '50%'
      }
    }
  }
}
</script>

<style scoped>

</style>
