<template>
  <span v-if="user">
    {{ userPositions }}
  </span>
  <span v-else>
    Загрузка
  </span>
</template>

<script>
export default {
  name: 'EmployeePosition',
  props: {
    id: {
      type: [Number, String, Array],
      required: true
    }
  },
  computed: {
    user () {
      return this.$store.getters['users/getUserById'](this.id)
    },
    userPositions () {
      if (this.user && this.user?.userStructure?.length) {
        return this.user.userStructure?.map(us => {
          return us?.position?.name || ''
        }).join(', ')
      }
      return ''
    }
  }
}
</script>
