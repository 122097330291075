import { RouteKeys } from '@/router/types'
import dictPermissionRules from '@/Permissions/dictRules'

export default {
  path: RouteKeys.changeLog,
  name: RouteKeys.changeLog,
  redirect: { name: `${RouteKeys.changeLog}-list` },
  meta: { name: 'Управление логом изменений', icon: 'change-log-creating', plannedKey: RouteKeys.changeLog, ruleNamespace: dictPermissionRules.AppServicePermissionRulesDOCChangeLogManagementChangeLogRule },
  component: () => import('@/views/ChangeLog/Index.vue'),
  children: [
    {
      path: 'list',
      name: `${RouteKeys.changeLog}-list`,
      meta: { name: 'Список changeLog', icon: 'crm-news', hideInMenu: true, isAdmin: true },
      component: () => import('@/views/ChangeLog/List.vue')
    },
    {
      path: ':id',
      name: `${RouteKeys.changeLog}-view`,
      meta: { name: '', hideInMenu: true, isAdmin: true },
      component: () => import('@/views/ChangeLog/Page/ChangeLogPageView.vue'),
      props: (route:any) => ({ id: Number(route.params.id), edit: route.params.edit || !Number(route.params.id) })
    }
  ]
}
