<template>
  <div
    v-if="Array.isArray(plannedList) && plannedList.length"
    class="py-2"
  >
    <template v-if="viewAll">
      <div
        v-for="(item, index) in plannedList"
        :key="index"
      >
        <template v-if="item.attachEntity && item.attachEntity.value === 'notification'">
          <div v-if="item.attachEntityData">
            <div
              v-if="item.attachEntityData.notificationAt"
              class="font-weight-bold"
              :class="item.expired ? 'error--text' : ''"
            >
              {{ $Global.DateFormat.DateTimeShort(item.attachEntityData.notificationAt) }}
            </div>
            <span v-if="item.attachEntityData.type === 'meet'">
              Встреча: <i v-if="item.attachEntityData">{{ item.attachEntityData.title }}</i>
            </span>
            <span v-else-if="item.attachEntityData.type === 'outgoing_call'">
              Позвонить: <i v-if="item.attachEntityData">{{ item.attachEntityData.title }}</i>
            </span>
            <!-- PP telegram:- Для ... нужно убрать. Лишнее-->
            <small
              v-if="item.attachEntityData.userId"
              class="d-flex"
            >
              <span class="mr-2">
                для
              </span>
              <ViewPanelLink
                :id="item.attachEntityData.userId"
                label="Пользователь"
                component="employee-show"
              >
                <EmployeeName :id="item.attachEntityData.userId" />
              </ViewPanelLink>
            </small>
          </div>
          <div v-else>
            Нет данных
          </div>
        </template>
        <template v-else>
          Отсутствует шаблон - {{ item.attachEntity }}
        </template>
      </div>
    </template>
    <template v-else>
      <div v-if="viewedItem">
        <template v-if="viewedItem.attachEntity && viewedItem.attachEntity.value === 'notification'">
          <div v-if="viewedItem.attachEntityData">
            <div
              v-if="viewedItem.attachEntityData.notificationAt"
              class="font-weight-bold"
              :class="viewedItem.expired ? 'error--text' : ''"
            >
              {{ $Global.DateFormat.DateTimeShort(viewedItem.attachEntityData.notificationAt) }}
            </div>
            <span v-if="viewedItem.attachEntityData.type === 'meet'">
              Встреча: <i v-if="viewedItem.attachEntityData">{{ viewedItem.attachEntityData.title }}</i>
            </span>
            <span v-else-if="viewedItem.attachEntityData.type === 'outgoing_call'">
              Позвонить: <i v-if="viewedItem.attachEntityData">{{ viewedItem.attachEntityData.title }}</i>
            </span>
            <!-- PP telegram:- Для ... нужно убрать. Лишнее-->
            <!--        <small v-if="viewedItem.attachEntityData.userId" class="d-flex">-->
            <!--          <span class="mr-2">-->
            <!--            для-->
            <!--          </span>-->
            <!--          <ViewPanelLink label="Пользователь" component="employee-show" :id="viewedItem.attachEntityData.userId">-->
            <!--            <EmployeeName :id="viewedItem.attachEntityData.userId"/>-->
            <!--          </ViewPanelLink>-->
            <!--        </small>-->
          </div>
          <div v-else>
            Нет данных
          </div>
        </template>
        <template v-else>
          Отсутствует шаблон - {{ viewedItem.attachEntity }}
        </template>
      </div>
    </template>
  </div>
  <div
    v-else
    class="text-muted"
  >
    Дела отсутствуют
  </div>
</template>

<script>
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink'
import EmployeeName from '@/components/other/employee/EmployeeName'
export default {
  name: 'UserPlannedList',
  components: { EmployeeName, ViewPanelLink },
  props: {
    viewAll: {
      type: Boolean,
      default: false
    },
    plannedList: {
      default: () => []
    }
  },
  computed: {
    viewedItem () {
      const isExpired = this.plannedList.some(item => item.expired)
      if (isExpired) return this.plannedList.find(item => item.expired)
      if (this.plannedList.length) {
        const list = this.plannedList
        // сортирую по дате чтобы получить ближайшее запланированное
        list.sort((a, b) => {
          if (a?.attachEntityData?.notificationAt > b?.attachEntityData?.notificationAt) {
            return 1
          }
          if (a?.attachEntityData?.notificationAt < b?.attachEntityData?.notificationAt) {
            return -1
          }
          return 0
        })
        return list[0]
      }
      return null
    }
  }
}
</script>
