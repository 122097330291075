<template>
  <div>
    <div
      v-if="loading"
      class="tw-h-full tw-w-full tw-flex tw-justify-center tw-items-center tw-py-5"
    >
      <TwCircleLoader
        height="50px"
        width="50px"
      />
    </div>
    <div v-else>
      <div
        :class="fromPanel ? 'tw-px-[26px] tw-py-[18px]':''"
        class="tw-font-manrope tw-text-main-sm2 tw-max-w-[100%] tw-bg-white tw-min-h-screen"
      >
        <TwPanelTitle v-if="fromPanel">
          <template #left>
            <div class="tw-text-h4 tw-font-bold">
              <div v-if="data">
                <span>
                  Планировка {{ data.name }}
                </span>
              </div>
              <div
                v-else-if="isNew"
                class="tw-text-h4 tw-text-an-flat-disabled-text"
              >
                Новая планировка
              </div>
              <div v-else>
                ~
              </div>
            </div>
          </template>
          <template #right>
            <TwPanelActionButton
              v-if="!isNew && !copyData && data"
              :disabled="isNew"
              class="tw-mr-2"
              color="gray"
              @click.stop="createCopy"
            >
              Создать копию
            </TwPanelActionButton>
            <TwPanelActionButton
              v-if="allowEditDelete && !isNew"
              :disabled="isNew"
              title="Удаление"
              color="red"
              @click.prevent="deleteCardItem"
            >
              Удалить
            </TwPanelActionButton>
          </template>
        </TwPanelTitle>
        <TwPanelTabsFull
          v-model="currentTab"
          :items="tabList"
        >
          <template #content.main>
            <TwPanelContent>
              <!-- LEFT -->
              <div class="tw-flex-1">
                <TwPanelContentBlock class="tw-mb-[6px]">
                  <template #title>
                    <div>
                      О планировке
                    </div>
                  </template>
                  <template #action>
                    <TwPanelContentBlockActionButton
                      :disabled="isNew"
                      type="edit"
                      @click="editMain = !editMain"
                    >
                      {{ editMain ? 'отменить' : 'изменить' }}
                    </TwPanelContentBlockActionButton>
                  </template>
                  <div class="tw-font-medium">
                    <RealtyComplexPlanMainForm
                      v-if="editMain"
                      :id="data ? data.id : 0"
                      ref="EditMain"
                      :copy-data="copyData"
                      :default-form-fields="defaultFormFields"
                      @create="onCreated"
                      @update="onUpdated"
                      @cancel="onCancel"
                    />
                    <RealtyComplexPlanMainShow
                      v-else
                      :dictionary="dictionary"
                      :info="data"
                      @refresh="refresh"
                    />
                  </div>
                </TwPanelContentBlock>
                <TwPanelContentBlock
                  v-if="data && !editMain"
                  class="tw-mb-[6px]"
                >
                  <template #title>
                    <div>
                      Список комнат
                    </div>
                  </template>
                  <div v-if="data.rooms">
                    <table class="tw-w-full">
                      <thead>
                        <tr class="tw-text-main-xs tw-text-an-flat-gray-4">
                          <th class="tw-p-[7px] tw-text-center tw-border-collapse tw-border-b tw-border-an-flat-disabled-text">
                            Тип
                          </th>
                          <th class="tw-p-[7px] tw-text-right tw-border-collapse tw-border-b tw-border-an-flat-disabled-text">
                            Площадь, м²
                          </th>
                          <th class="tw-p-[7px] tw-text-center tw-border-collapse tw-border-b tw-border-an-flat-disabled-text">
                            Отопливаемая
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <template v-if="Array.isArray(data.rooms) && data.rooms.length">
                          <tr
                            v-for="(roomInfo, index) in data.rooms"
                            :key="index"
                          >
                            <td class="tw-min-w-[200px] tw-p-2 tw-border-collapse tw-border-b tw-border-an-flat-disabled-text">
                              {{ roomInfo.type && roomInfo.type.label }}
                            </td>
                            <td class="tw-p-2 tw-w-[120px] tw-text-right tw-border-b tw-border-an-flat-disabled-text">
                              {{ roomInfo.area }}
                            </td>
                            <td class="tw-p-2 tw-text-center tw-border-b tw-border-an-flat-disabled-text">
                              {{ roomInfo.heated ? 'Да':'Нет' }}
                            </td>
                          </tr>
                        </template>
                        <tr v-else>
                          <td
                            colspan="99"
                            class="tw-pt-[30px] tw-pb-[15px] tw-text-an-flat-disabled-text tw-text-center"
                          >
                            Комнаты не добавлены
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </TwPanelContentBlock>
              </div>
              <!-- RIGHT -->
              <div
                v-if="data && !editMain"
                class="tw-flex-1 tw-min-w-[400px]"
              >
                <TwPanelContentBlock
                  class="tw-mb-[6px]"
                  :content-classes="''"
                >
                  <template #title>
                    <div>
                      Планы объекта
                    </div>
                  </template>
                  <div
                    v-if="Array.isArray(data.genPlanFiles) && data.genPlanFiles.length"
                    class="tw-flex tw-flex-col tw-items-center"
                  >
                    <div
                      v-for="(file, fIndex) in data.genPlanFiles"
                      :key="file.id"
                      class="tw-p-[6px] tw-bg-white tw-rounded-sm tw-mb-[6px] tw-cursor-pointer"
                    >
                      <img
                        alt="Планировка"
                        :src="getFileResizeByKey(file, 'default')"
                        @click="viewImage(fIndex)"
                      >
                    </div>
                  </div>
                  <div
                    v-else
                    class="tw-text-center tw-p-3 tw-text-an-flat-disabled-text"
                  >
                    Нет изображений с планами
                  </div>
                </TwPanelContentBlock>
              </div>
            </TwPanelContent>
          </template>
        </TwPanelTabsFull>
      </div>
    </div>
  </div>
</template>

<script>
import { API } from '@/Api/ObjectStorage'
import CardMixin from '@/mixins/CardMixin'
import RealtyComplexPlanMainForm from '@/views/Newbuildings/Editor/Plans/main/RealtyComplexPlanMainForm.vue'
import RealtyComplexPlanMainShow from '@/views/Newbuildings/Editor/Plans/main/RealtyComplexPlanMainShow.vue'
import TwPanelTabsFull from '@/components/tw-ui/panels/TwPanelTabsFull.vue'
import TwPanelContent from '@/components/tw-ui/panels/TwPanelContent.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import TwPanelTitle from '@/components/tw-ui/panels/TwPanelTitle.vue'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwPanelContentBlockActionButton from '@/components/tw-ui/panels/TwPanelContentBlockActionButton.vue'
import { mapGetters } from 'vuex'
import dictPermissionRules from '@/Permissions/dictRules'
import TwPanelActionButton from '@/components/tw-ui/panels/TwPanelActionButton.vue'
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import { getFileResizeByKey } from '@/plugins/helpers/fileHelpers'

export default {
  name: 'RealtyPlanCard',
  components: {
    TwPanelActionButton,
    TwPanelContentBlockActionButton,
    TwPanelContentBlock,
    TwPanelTitle,
    TwCircleLoader,
    TwPanelContent,
    TwPanelTabsFull,
    RealtyComplexPlanMainShow,
    RealtyComplexPlanMainForm
  },
  mixins: [CardMixin, ViewPanelShowMixin],
  props: {
    complexId: {
      type: [Number, String],
      default: null
    },
    builderId: {
      type: [Number, String],
      default: null
    }
  },
  data () {
    return {
      currentTab: 'main',
      API: API.Editor.Plan
    }
  },
  methods: {
    getFileResizeByKey,
    createCopy () {
      const copyData = { ...JSON.parse(JSON.stringify(this.data || {})), ...{} }
      copyData.id = null
      copyData.name += '(Копия)'
      copyData.genPlanFilesId = this.data.genPlanFiles.map(f => f.id) || []
      copyData.rooms?.forEach((item) => {
        delete item.id
      })
      delete copyData.genPlanFiles
      // console.log('createCopy copyData', copyData)
      this.showRealtyPlanCard({ id: 0, copyData }, () => {
        this.onCopied()
      })
    },
    viewImage (startIndex) {
      this.$store.dispatch('imageViewer/showImages', {
        files: this.data.genPlanFiles.map(f => {
          return {
            path: getFileResizeByKey(f, 'big')
          }
        }) || [],
        startIndex
      })
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'profile/isAdmin',
      allowNamespacesDict: 'profile/allowNamespacesDict'
    }),
    allowEditDelete () {
      return this.isAdmin || !!this.allowNamespacesDict[dictPermissionRules.AppServicePermissionRulesOBJECTSNewBuildingsRealtyEditRealtyRule]
    },
    defaultFormFields () {
      return {
        complexId: this.complexId,
        builderId: this.builderId
      }
    },
    tabList () {
      return [
        {
          label: 'Общая информация',
          key: 'main'
        }
      ]
    }
  }
}
</script>
