<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-width="200"
    offset-y
  >
    <template #activator="{ attrs, on }">
      <v-btn
        block
        outlined
        :rounded="false"
        small
        color="success"
        v-bind="attrs"
        v-on="on"
      >
        Создать
      </v-btn>
    </template>
    <v-card>
      <v-card-text class="font-weight-medium">
        Новый контакт
      </v-card-text>
      <v-card-text class="py-0">
        <v-form ref="form">
          <v-text-field
            v-model="form.name"
            class="mt-0 pt-0"
            type="text"
            label="Имя"
            :rules="nameRules"
          />
          <v-text-field
            v-model="form.email"
            type="email"
            label="Email"
            :rules="emailRequireRules"
          />
        </v-form>
      </v-card-text>
      <v-card-title>
        <v-btn
          small
          outlined
          @click="clear"
        >
          Отмена
        </v-btn>
        <v-spacer />
        <v-btn
          small
          color="primary"
          @click="success"
        >
          Добавить
        </v-btn>
      </v-card-title>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'AddNewEmailContact',
  props: {
    inputValue: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      menu: false,
      form: {
        email: '',
        name: ''
      },
      nameRules: [
        v => !!v || 'Поле обязательное'
      ],
      emailRequireRules: [
        (v) => !!v || 'Поле обязательное',
        (v) => /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Неправильный E-mail'
      ]
    }
  },
  watch: {
    inputValue (newValue) {
      this.form.email = newValue
    }
  },
  methods: {
    success () {
      if (this.$refs.form.validate()) {
        this.$emit('add', this.form)
        this.$nextTick(this.clear)
      }
    },
    clear () {
      this.form = {
        email: '',
        name: ''
      }
      this.menu = false
    }
  }
}
</script>
