import { SERVICES } from '@/Api/Services'
import Call from './Call'
import Filters from './Filters'
import Platform from './Platform'

export const AuthAxiosDefaultConfig: any = {
  serviceKey: SERVICES.TELEPHONY.key
}

export const API: any = {
  Call,
  Platform,
  Filters
}
