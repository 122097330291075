import { RouteKeys } from '@/router/types'
import dictPermissionRules from '@/Permissions/dictRules'

export default {
  path: RouteKeys.crmNews,
  name: RouteKeys.crmNews,
  redirect: { name: `${RouteKeys.crmNews}-list` },
  meta: { name: 'Новости управление', icon: 'crm-news', plannedKey: RouteKeys.crmNews, ruleNamespace: dictPermissionRules.AppServicePermissionRulesCRMAnFlatNewsEditNewsRule },
  component: () => import('@/views/News/Index.vue'),
  children: [
    {
      path: 'list',
      name: `${RouteKeys.crmNews}-list`,
      meta: { name: 'Новости управление', icon: 'crm-news', hideInMenu: true },
      component: () => import('@/views/News/List.vue')
    },
    {
      path: ':id',
      name: `${RouteKeys.crmNews}-view`,
      meta: { name: '', hideInMenu: true },
      component: () => import('@/views/News/Page/NewsPageView.vue'),
      props: (route:any) => ({ id: Number(route.params.id), edit: route.params.edit || !Number(route.params.id) })
    }
  ]
}
