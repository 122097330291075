import { AxiosResponse } from 'axios'
import HTTP from '@/Api/http'

const path = '/integration'

export default {
  listByType (type: string, params: any = {}): Promise<AxiosResponse> {
    const $config = {
      params
    }
    return HTTP.get(`${path}/${type}`, $config)
  },
  runProcess (id: number, type: string, data: object): Promise<AxiosResponse> {
    return HTTP.post(`${path}/${type}/${id}`, data)
  }
}
