<template>
  <div
    v-cloak
    v-if="info"
    class="tw-flex tw-gap-[12px] tw-flex-col"
  >
    <TwPanelContentBlockFieldView
      no-data-text="Не указано"
      :horizontal="true"
    >
      <template #title>
        Фамилия
      </template>
      <div v-if="info.surname">
        {{ info.surname }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView
      no-data-text="Не указано"
      :horizontal="true"
    >
      <template #title>
        Имя
      </template>
      <div v-if="info.name">
        {{ info.name }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView
      no-data-text="Не указано"
      :horizontal="true"
    >
      <template #title>
        Отчество
      </template>
      <div v-if="info.fatherName">
        {{ info.fatherName }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView
      no-data-text="Не указано"
      :horizontal="true"
    >
      <template #title>
        Пол
      </template>
      <div v-if="info.gender">
        {{ info.gender && info.gender.label }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView
      no-data-text="Не указано"
      :horizontal="true"
    >
      <template #title>
        Дата рождения
      </template>
      <div v-if="info.birthDate">
        {{ $Global.DateFormat.DateFull(info.birthDate) }}
        <span class="tw-text-an-flat-gray-4">
          ({{ $Global.calculateAge(info.birthDate) }}
          {{ $Global.textPlural($Global.calculateAge(info.birthDate), 'year') }})
        </span>
      </div>
    </TwPanelContentBlockFieldView>
    <template v-if="!personTypes.isChildren">
      <TwPanelContentBlockFieldView
        no-data-text="Не указаны"
        :horizontal="true"
      >
        <template #title>
          Телефоны для связи
        </template>
        <div v-if="Array.isArray(info.phones) && info.phones.length">
          <div>
            <div
              v-for="(item, p_index) in info.phones"
              :key="p_index+'-p'"
              class="tw-flex tw-gap-1"
            >
              <SipCallLink
                v-if="item.phone"
                :phone="item.phone"
              />
              <small
                v-if="item.main"
                class="tw-text-an-flat-green"
              >(осн.)</small>
            </div>
          </div>
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView
        no-data-text="Не указаны"
        :horizontal="true"
      >
        <template #title>
          Доп. контакты
        </template>
        <div v-if="Array.isArray(info.additionalContacts) && info.additionalContacts.length">
          <div>
            <div
              v-for="(info, ac_index) in info.additionalContacts"
              :key="ac_index+'-ac'"
              class="tw-flex tw-gap-1"
            >
              <span class="tw-text-an-flat-disabled-text">{{ info.type && (info.type.label || info.type) }}:</span>
              <span class="tw-text-an-flat-black-main">{{ info.contact }}</span>
            </div>
          </div>
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView no-data-text="Не указано">
        <template #title>
          Сотрудник компании
        </template>
        <div v-if="info.company">
          <ViewPanelLink
            :id="info.company.id"
            label="Компания"
            component="company-show"
          >
            {{ info.company.name }}
          </ViewPanelLink>
        </div>
      </TwPanelContentBlockFieldView>
    </template>
  </div>
</template>

<script>
import SipCallLink from '@/components/telephony/SipCallLink'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'

export default {
  name: 'PersonMainShow',
  components: { TwPanelContentBlockFieldView, ViewPanelLink, SipCallLink },
  props: {
    personTypes: {},
    info: {}
  }
}
</script>
