<template>
  <TwPanelContentBlock class="tw-mb-[6px]">
    <template #title>
      <div>
        Двухфакторная авторизация
      </div>
    </template>
    <template #action>
      <button
        :disabled="checkStatusProcess"
        title="Обновить статус"
        class="tw-text-main-xs enabled:hover:tw-text-an-flat-dark-blue-link"
        @click.prevent="checkStatus"
      >
        Обновить
      </button>
    </template>
    <div>
      <!--  Изменить статус может сам пользователь или администратор  -->
      <div class="tw-my-[16px]">
        <TwInputSwitch
          v-model="twoFactorStatus"
          :disabled="changeProcess || checkStatusProcess"
          @input="onChangeStatus"
        >
          <template #label>
            <span class="tw-relative tw-flex tw-items-center tw-gap-[6px] tw-z-10 tw-text-an-flat-grey-4 tw-font-manrope tw-font-semibold tw-text-[14px]">
              {{ changeProcess ? 'Подождите' : twoFactorStatus ? 'Подключена' : 'Отключена' }}
              <TwCircleLoader
                v-if="changeProcess"
                width="20px"
                height="20px"
              />
            </span>
          </template>
        </TwInputSwitch>
      </div>
      <!--  Функционал доступный только владельцу аккаунта  -->
      <template v-if="isMyProfile">
        <div v-if="qrCode && twoFactorStatus && !twoFactorStatusVerify">
          <img
            width="200"
            height="200"
            :src="qrCode"
          >
          <div class="tw-my-[16px]">
            <TwInputText
              v-model="verifyCode"
              type="text"
              label="Код подтверждения"
              placeholder="Введите подтверждения"
              :disabled="changeProcess || checkStatusProcess"
              :show-label="true"
              :dense="true"
              :error-text="verifyCode ? '' : 'Обязательное поле'"
              :persistent-label="true"
            >
              <template #append>
                <button
                  class="tw-px-[16px] tw-py-[4px] tw-rounded-sm tw-bg-an-flat-blue-btn tw-text-main-xs tw-font-bold tw-text-white disabled:tw-bg-an-flat-btn-disabled-bg disabled:tw-text-an-flat-disabled-input-text disabled:tw-shadow-none"
                  :disabled="changeProcess || checkStatusProcess || !verifyCode"
                  @click.prevent="verify"
                >
                  Подтвердить
                </button>
              </template>
            </TwInputText>
          </div>
        </div>
        <div v-if="Array.isArray(phrases) && phrases.length">
          <div class="tw-flex tw-items-center">
            <ClickBoard
              :disabled="false"
              :value="phrases.join(', ')"
            />
            <span class="tw-font-bold">Резервные коды</span>
          </div>
          <div
            v-for="(key, index) in phrases"
            :key="index"
            class="tw-ml-[12px]"
          >
            {{ index + 1 }}. <strong>{{ key }}</strong>
          </div>
        </div>
        <div
          v-else-if="twoFactorStatus && twoFactorStatusVerify"
          class="tw-my-[16px]"
        >
          <button
            :disabled="changeProcess || checkStatusProcess"
            class="tw-px-[16px] tw-py-[6px] tw-rounded-sm tw-bg-an-flat-blue-btn tw-text-main-xs tw-font-bold tw-text-white disabled:tw-bg-an-flat-btn-disabled-bg disabled:tw-text-an-flat-disabled-input-text disabled:tw-shadow-none"
            @click.prevent="generate"
          >
            Сгенерировать резервные коды
          </button>
        </div>
      </template>

      <div class="tw-mt-[16px]">
        <div class="text-uppercase font-weight-bold">
          Инструкция:
        </div>
        <div>
          1. скачайте приложение <b>FreeOTP</b> на телефон
          (<a
            :href="appLink.android"
            target="_blank"
            title="Скачать приложение"
          >Android</a>,
          <a
            :href="appLink.ios"
            target="_blank"
            title="Скачать приложение"
          >IOS</a>)
        </div>
        <div>
          2. включите 2х факторную авторизацию
        </div>
        <div>
          <div>
            3. отсканируйте QR код в приложении FreeOTP
          </div>
          <img
            class="tw-mt-[12px]"
            alt="optQrScan"
            width="300"
            :src="optQrScan"
          >
        </div>
      </div>
    </div>
  </TwPanelContentBlock>
</template>

<script>
import { twoFactorVerifyAppLink } from '@/plugins/helpers/GlobalConfigVars'
import API from '@/Api/Auth/TwoFactor'
import ClickBoard from '@/components/button/ClickBoard'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwInputSwitch from '@/components/tw-ui/ui-kit/input/TwInputSwitch.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import TwInputText from '@/components/tw-ui/ui-kit/input/TwInputText.vue'

export default {
  name: 'TwoFactorBlock',
  components: { TwInputText, TwCircleLoader, TwInputSwitch, TwPanelContentBlock, ClickBoard },
  props: {
    userId: {
      type: Number,
      required: true
    },
    isMyProfile: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      qrCode: '',
      changeProcess: false,
      twoFactorStatus: false,
      twoFactorStatusVerify: false,
      verifyCode: '',
      phrases: [], // резервные коды
      checkStatusProcess: false
    }
  },
  computed: {
    appLink () {
      return twoFactorVerifyAppLink
    },
    optQrScan () {
      return require('@/assets/images/two-factor/enable-otp.png')
    }
  },
  mounted () {
    this.checkStatus()
  },
  methods: {
    checkStatus () {
      if (this.checkStatusProcess) return
      this.checkStatusProcess = true
      API.status(this.userId)
        .then(({ data }) => {
          // console.warn(this.$options.name, 'getStatus: ', !!data?.enabled, data)
          this.twoFactorStatus = data?.enabled
          this.twoFactorStatusVerify = data?.verified
          if (this.twoFactorStatus && !this.twoFactorStatusVerify && this.isMyProfile && !this.qrCode) {
            this.changeRequest()
          }
        })
        .catch((error) => {
          console.error(this.$options.name, error)
        })
        .finally(() => {
          this.checkStatusProcess = false
        })
    },
    changeRequest () {
      return new Promise((resolve) => {
        if (this.changeProcess) return new Error('Ошибка - процесс загрузки активен')
        this.changeProcess = true
        this.qrCode = ''
        this.verifyCode = ''
        this.phrases = []
        API.change(this.userId, {
          enable: this.twoFactorStatus,
          days: 3
        })
          .then(({ data }) => {
            // console.warn(this.$options.name, 'CHANGE: ', data)
            this.twoFactorStatus = data?.enabled
            this.twoFactorStatusVerify = data?.verified
            this.qrCode = (data && data['qr-code']) || null
          })
          .catch((error) => {
            console.error(this.$options.name, error)
          })
          .finally(() => {
            this.changeProcess = false
            resolve()
          })
      })
    },
    async onChangeStatus () {
      await this.changeRequest()
      this.checkStatus()
    },
    verify () {
      API.verify({ code: this.verifyCode }, this.verifyToken)
        .then(({ data }) => {
          // console.warn(this.$options.name, data)
          this.twoFactorStatus = true
          this.twoFactorStatusVerify = true
          this.phrases = data.phrases
          this.verifyCode = ''
        })
        .catch((error) => {
          console.error(this.$options.name, error)
          this.verifyCode = ''
        })
    },
    generate () {
      API.generate()
        .then(({ data }) => {
          // console.warn(this.$options.name, data)
          this.phrases = data || []
        })
        .catch((error) => {
          console.error(this.$options.name, error)
          this.phrases = []
        })
    }
  }
}
</script>

<style scoped>

</style>
