import { RouteKeys } from '@/router/types'
import dictPermissionRules from '@/Permissions/dictRules'

export default {
  path: RouteKeys.newbuildings,
  name: `${RouteKeys.accounting}-${RouteKeys.newbuildings}`,
  redirect: { name: `${RouteKeys.newbuildings}-act-list` },
  meta: { name: 'Новостройки', icon: 'accounting' },
  component: () => import('@/views/Reports/Index.vue'),
  children: [
    {
      path: 'result-list',
      name: `${RouteKeys.newbuildings}-result-list`,
      meta: { name: 'Результаты', icon: 'report', hideInMenu: false, ruleNamespace: dictPermissionRules.AppServicePermissionRulesACCOUNTINGBuyNbConfirmCommissionRule },
      component: () => import('@/views/Accounting/Result/List.vue')
    },
    {
      path: 'act-list',
      name: `${RouteKeys.newbuildings}-act-list`,
      meta: { name: 'Акты', icon: 'report', hideInMenu: false, ruleNamespace: dictPermissionRules.AppServicePermissionRulesACCOUNTINGActManagementRule },
      component: () => import('@/views/Accounting/Act/List.vue')
    }
  ]
}
