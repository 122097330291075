import lodashDebounce from 'lodash/debounce'
import { mapGetters, mapMutations } from 'vuex'

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    ...mapMutations({
      setFilterFieldsByKey: 'filters/SET_FILTER_BY_KEY'
    }),
    changeField () {
      this.debounceFilters()
    },
    debounceFilters: lodashDebounce(function () {
      console.warn(this.$options.name, this.type || this.currentParams.type, this.fields)
      this.setFilterFieldsByKey({ type: this.type || this.currentParams.type, fields: this.fields })
      console.warn('debounceFilters ', this.currentFilters)
    }, 500)
  },
  computed: {
    ...mapGetters({
      currentParams: 'filters/getCurrentParams'
    })
  }
}
