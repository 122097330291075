import { TypesRealtyObjects } from '@/views/Newbuildings/Editor/typesDict'
import NbEditorActionsConfirmDialog from '@/views/Newbuildings/Editor/OtherComponents/Actions/NbEditorActionsConfirmDialog.vue'

export default {
  components: { NbEditorActionsConfirmDialog },
  data () {
    return {
      actionProcess: false
    }
  },
  methods: {
    // Действие при нажатии на контестное меню - Изменить
    openObjectEditPanel (payload) {
      console.warn('openObjectEditPanel: ', payload)
      this.$emit('showObject', {
        id: payload.id,
        type: payload.objectType?.value || null
      })
    },
    // Действие при нажатии на контестное меню - Копировать
    async copyObject (payload) {
      console.warn('onCopyObject: ', payload)
      const objectType = payload?.objectType?.value || payload?.type?.value // TODO свойства разные в ответах разных роутов
      if (objectType) {
        const copyConfirmState = {
          title: 'Подтвердите копирование',
          description: 'При КОПИРОВАНИИ, продублируется данный объект и все связанные с ним элементы.',
          successText: 'Подтвердить',
          cancelText: 'Отмена',
          form: {}
        }
        // поля формы по умолчанию
        copyConfirmState.form = TypesRealtyObjects[objectType]?.copyForm ? { ...TypesRealtyObjects[objectType]?.copyForm } : {}
        const result = await this.$refs.confirmDialog?.confirm(copyConfirmState) || {}
        if (result.success) {
          console.warn('onCopyObject ', TypesRealtyObjects[objectType].API)
          if (TypesRealtyObjects[objectType]?.API?.copy) {
            this.actionProcess = true
            TypesRealtyObjects[objectType].API.copy(payload.id, result.form).then((result) => {
              console.warn('onCopyObject', result)
              this.$store.commit('notify/ADD', {
                title: 'Копирование запущено - завершиться в ближайщее время!',
                type: 'success',
                timeout: 3000,
                visible: true
              })
              this.onCopied()
            }).catch((error) => {
              console.error(error)
              this.$store.commit('notify/ADD', {
                title: 'Ошибка копирования',
                desc: '',
                type: 'error',
                timeout: 30000,
                visible: true
              })
            }).finally(() => {
              this.actionProcess = false
              this.$refs.confirmDialog?.close()
            })
          } else {
            console.error('Нет АПИ для совершения действия')
          }
        } else {
          console.error('Копирование отменено')
        }
      } else {
        console.error('Не удалось определить тип объекта для совершения действия')
      }
    },
    // Что то делаем после успешного копирования
    onCopied () {
      console.warn('Копирование прошло успешно, что нужно делать дальше?')
      // пропс в родительском компоненте, означающий что действие происходит на шахматке, а не в панели конкретного элемента
      if (!this.inChess) {
        this.$emit('callback')
      }
      this.$emit('refresh')
    },
    // Действие при нажатии на контестное меню - Удалить
    async deleteObject (payload) {
      console.warn('onCopyObject: ', payload)
      const objectType = payload?.objectType?.value || payload?.type?.value // TODO свойства разные в ответах разных роутов
      if (objectType) {
        const deleteConfirmState = {
          title: 'Подтвердите удаление',
          description: 'При УДАЛЕНИИ, будет удален объект и ВСЕ связанные с ним элементы.',
          successText: 'Подтвердить',
          cancelText: 'Отмена',
          form: {}
        }
        const result = await this.$refs.confirmDialog?.confirm(deleteConfirmState) || {}
        if (result.success) {
          console.warn('onDeleteObject ', TypesRealtyObjects[objectType].API)
          if (TypesRealtyObjects[objectType]?.API?.delete) {
            this.actionProcess = true
            TypesRealtyObjects[objectType].API.delete(payload.id).then((result) => {
              console.warn('onDeleteObject', result)
              this.$store.commit('notify/ADD', {
                title: 'Объект успешно удалён',
                desc: '',
                type: 'success',
                timeout: 3000,
                visible: true
              })
              this.onDeleted()
            }).catch((error) => {
              console.error(error)
              this.$store.commit('notify/ADD', {
                title: 'Ошибка удаления объекта',
                type: 'error',
                timeout: 3000,
                visible: true
              })
            }).finally(() => {
              this.actionProcess = false
              this.$refs.confirmDialog?.close()
            })
          } else {
            console.error('Нет АПИ для совершения действия!')
          }
        } else {
          console.error('Удаление отменено')
        }
      } else {
        console.error('Не удалось определить тип объекта для совершения действия')
      }
    },
    // Что то делаем после успешного удаления
    onDeleted () {
      console.warn('Удаление прошло успешно, что нужно делать дальше?')
      if (!this.inChess) {
        this.$emit('callback')
        this.$emit('close-me')
      }
      this.$emit('refresh')
    }
  }
}
