<template>
  <div
    v-if="!permissionDenied"
    class="page-wrapper px-0 tw-mb-[35px]"
    :style="{ position: 'relative', maxWidth: 'none' }"
  >
    <div
      v-if="$slots.header"
      :class="[headerBgClass]"
      class="tw-flex tw-gap-[12px] tw-items-center tw-justify-between tw-rounded-[16px] tw-z-[12] tw-px-[15px] tw-py-[14px]"
    >
      <TwPopoverShort v-if="showRefreshButton">
        <template #trigger>
          <button
            class="tw-group tw-bg-white tw-py-[11px] tw-px-[10px] tw-rounded-full enabled:hover:tw-bg-an-flat-blue-btn31 enabled:hover:tw-shadow-[0_4px_11.5px_rgba(104,156,200,0.8)] enabled:active:tw-shadow-none enabled:active:tw-bg-white"
            @click.prevent="refreshParent"
          >
            <svg
              class="group-hover:tw-rotate-90"
              width="20"
              height="17"
              viewBox="0 0 20 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                class="group-hover:tw-fill-white group-active:tw-fill-an-flat-blue-btn31"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.27391 4.60426C4.03776 4.95733 3.56006 5.05503 3.20699 4.82041C2.85314 4.58579 2.75622 4.10887 2.99083 3.75426C5.10929 0.558872 9.1216 -0.792667 12.7524 0.467333C15.7062 1.49272 17.787 4.03426 18.2878 7.00502L18.6224 6.57041C18.8808 6.23349 19.3639 6.17041 19.7008 6.42964C20.0378 6.68887 20.1001 7.17118 19.8408 7.5081L18.287 9.52579C18.1362 9.72272 17.9078 9.82579 17.677 9.82579C17.5662 9.82579 17.4578 9.79195 17.3539 9.74349C17.2208 9.69502 17.1016 9.62349 17.0147 9.51656L15.1908 8.11272C14.8539 7.85349 14.7916 7.37118 15.0508 7.03426C15.3093 6.69733 15.7916 6.63502 16.1293 6.89349L16.7878 7.40041C16.4216 4.91426 14.7062 2.77349 12.247 1.91964C9.28622 0.89041 6.00391 1.99349 4.27391 4.60426ZM1.37843 10.255C1.22766 10.4519 0.999196 10.555 0.768427 10.555C0.604581 10.555 0.439196 10.5035 0.299966 10.3958C-0.0369574 10.1365 -0.0992652 9.65424 0.159966 9.31732L1.71381 7.29963C1.93073 7.01655 2.30073 6.9404 2.61612 7.0727C2.76227 7.11809 2.89304 7.19347 2.98612 7.30809L4.80997 8.71193C5.14689 8.97116 5.2092 9.45347 4.94997 9.7904C4.6915 10.1265 4.2092 10.1889 3.8715 9.93117L3.21304 9.42424C3.5792 11.9104 5.29458 14.0512 7.75381 14.905C10.7177 15.9358 13.9969 14.8312 15.7269 12.2204C15.963 11.8665 16.4384 11.7681 16.7938 12.0042C17.1477 12.2389 17.2446 12.7165 17.01 13.0704C15.4215 15.465 12.7684 16.825 10.0115 16.825C9.09073 16.825 8.15766 16.6735 7.24843 16.3581C4.29458 15.3327 2.21381 12.7912 1.71304 9.8204L1.37843 10.255Z"
                fill="#60656A"
              />
            </svg>
          </button>
        </template>
        <template #content>
          Обновить список
        </template>
      </TwPopoverShort>
      <div class="tw-flex tw-gap-[12px] tw-flex-1 tw-items-center tw-justify-between tw-flex-wrap-reverse">
        <slot name="header" />
      </div>
    </div>
    <div class="tw-bg-transparent">
      <slot name="subHeader" />
    </div>
    <div
      class=""
      :class="pageContentClass"
    >
      <slot name="default" />
    </div>
    <v-toolbar
      v-if="$slots.footer"
      bottom
      :max-height="headerMaxHeight"
      color="transparent"
      elevation="0"
      short
      dense
    >
      <slot name="footer" />
    </v-toolbar>
  </div>
  <PermissionDenied v-else />
</template>

<script>
import PermissionDenied from '@/views/PermissionDenied'
import TwPopoverShort from '@/components/tw-ui/popover/TwPopoverShort.vue'
export default {
  name: 'PageViewDefaultWrapper',
  components: { TwPopoverShort, PermissionDenied },
  props: {
    headerMaxHeight: {
      type: String,
      default: '48px'
    },
    headerHeight: {
      type: String,
      default: '48px'
    },
    headerClass: {
      type: String,
      default: ''
    },
    headerBgClass: {
      type: String,
      default: 'tw-bg-gradient-to-r tw-from-[#A2C5EF] tw-from-10% tw-via-[#C0DAF8] tw-via-40% tw-to-[#D2EEF9] tw-to-70%'
    },
    pageContentClass: {
      type: String,
      default: 'page-content tw-mt-3'
    },
    showRefreshButton: {
      type: Boolean,
      default: false
    },
    permissionDenied: {
      type: Boolean,
      default: false
    },
    relative: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    refreshParent () {
      if (this.$parent?.refresh) {
        this.$parent?.refresh()
      } else {
        this.$emit('refresh')
      }
    }
  }
}
</script>
