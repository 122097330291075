<template>
  <div>
    <TwSelectRelations
      v-model="form.complexIds"
      class="tw-mt-[15px]"
      :disabled="formProcessing"
      :multi="true"
      label="Все ЖК"
      list-label="Выбрать ЖК"
      relation-name="complex"
      :default-filters="complexFilters"
    />

    <div class="tw-mt-[18px]">
      <TwCheckBox
        v-model="form.active"
        :disabled="formProcessing"
        label="Активно"
      />
    </div>

    <TwInputText
      v-model.number="form.days"
      class="tw-mt-[12px]"
      type="number"
      placeholder="Срок бронирования"
      :disabled="formProcessing"
      :show-label="true"
      :error-text="$v.form.days.$invalid ? 'Заполните поле':''"
    >
      <template #append>
        дней
      </template>
    </TwInputText>
    <div class="tw-mt-[18px]">
      <TwCheckBox
        v-model="form.paid"
        :disabled="formProcessing"
        label="Платно"
        @input="changePaid"
      />
    </div>
    <TwSelect
      v-if="form.paid"
      v-model="form.priceType"
      label="Вариант стоимости"
      class="tw-mt-[12px]"
      placeholder="Выбрать"
      :options="dictionary.priceType"
      :persistent-label="true"
      :error-text="$v.form.priceType.$invalid ? 'Заполните поле':''"
      @input="form.price = null"
    />

    <TwInputText
      v-if="form.priceType"
      v-model.number="form.price"
      class="tw-mt-[12px]"
      type="text"
      placeholder="Стоимость"
      :disabled="formProcessing"
      :show-label="true"
    >
      <template #append>
        {{ form.priceType === 'percent' ? '%' : 'руб.' }}
      </template>
    </TwInputText>

    <div class="tw-mt-[18px]">
      <TwCheckBox
        v-model="form.needPetition"
        :disabled="formProcessing"
        label="Требуется ходатайство"
      />
    </div>

    <div class="tw-mt-[18px]">
      <TwCheckBox
        v-model="form.needClientDocument"
        :disabled="formProcessing"
        label="Требуются документы клиента"
      />
    </div>

    <TwTextarea
      v-model="form.description"
      rows="5"
      class="tw-mt-[12px]"
      placeholder="Описание для риелтора"
      :disabled="formProcessing"
      :show-label="true"
    />

    <TwSelect
      v-model="form.communicationMethod"
      label="Способ связи с застройщиком"
      class="tw-mt-[12px]"
      placeholder="Выбрать"
      :options="dictionary.communicationMethod"
      :persistent-label="true"
      :error-text="$v.form.communicationMethod.$invalid ? 'Заполните поле':''"
    />

    <TwInputText
      v-model.number="form.communicationName"
      class="tw-mt-[12px]"
      type="text"
      placeholder="Имя"
      :disabled="formProcessing"
      :show-label="true"
    />

    <TwInputText
      v-model="form.communicationContact"
      class="tw-mt-[12px]"
      type="text"
      placeholder="Контакт застройщика"
      :disabled="formProcessing"
      :show-label="true"
      :error-text="$v.form.communicationContact.$invalid ? 'Заполните поле':''"
    />

    <TwTextarea
      v-model="form.communicationInfo"
      rows="5"
      class="tw-mt-[12px]"
      placeholder="Доп. инфо по связи с застройщиком"
      :disabled="formProcessing"
      :show-label="true"
      :error-text="$v.form.communicationInfo.$invalid ? 'Заполните поле':''"
    />
    <div>
      <TwCardBlockFormActions
        :title-submit="formType === 'create' ? 'Создать' : 'Сохранить'"
        :handle-submit="handleSubmit"
        :handle-cancel="handleCancel"
        :disable-submit="!isEdited || formProcessing"
        :disable-cancel="formProcessing"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { required } from 'vuelidate/lib/validators'
import SettingsReservationType from '@/Api/Rtd/RequestToDeveloper/Settings/ReservationType'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import TwInputText from '@/components/tw-ui/ui-kit/input/TwInputText.vue'
import TwTextarea from '@/components/tw-ui/ui-kit/input/TwTextarea.vue'
import TwCheckBox from '@/components/tw-ui/ui-kit/input/TwCheckBox.vue'
import TwCardBlockFormActions from '@/components/core/ViewPanel/Card/TwCardBlockFormActions.vue'
import TwSelect from '@/components/tw-ui/ui-kit/input/TwSelect.vue'
import TwSelectRelations from '@/components/tw-ui/ui-kit/input/select-relations/TwSelectRelations.vue'

export default defineComponent({
  name: 'BuilderReservationTypesForm',
  components: { TwSelectRelations, TwSelect, TwCardBlockFormActions, TwCheckBox, TwTextarea, TwInputText },
  mixins: [FormCreateEditMixin],
  props: {
    companyId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      API: SettingsReservationType,
      form: {}
    }
  },
  validations () {
    return {
      form: {
        days: {
          required
        },
        priceType: {
          ...(this.form.paid) && {
            required
          }
        },
        communicationMethod: {
          required
        },
        communicationInfo: {
          ...(['personal_account', 'other'].includes(this.form.communicationMethod)) && {
            required
          }
        },
        communicationContact: {
          ...(['phone', 'email', 'sms', 'messenger'].includes(this.form.communicationMethod)) && {
            required
          }
        }
      }
    }
  },
  computed: {
    complexFilters () {
      return {
        builderId: this.companyId ? [this.companyId] : []
      }
    }
  },
  methods: {
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          for (const key in this.preset) {
            if (key === 'communicationMethod') {
              this.$set(this.form, 'communicationMethod', this.preset[key]?.value || null)
            } else {
              this.$set(this.form, key, this.preset[key])
            }
          }
        }
        resolve()
      })
    },
    handleSubmit () {
      this.$v.$reset()
      this.$v.form.$touch()
      if (this.formProcessing === true) {
        return
      }
      if (!this.$v.form.$invalid) {
        this.formProcessing = true
        this.form.companyId = this.companyId
        const request = this.formType === 'create'
          ? this.API.store(this.form)
          : this.API.update(this.targetId, this.form)

        request.then(({ data }) => {
          if (this.formType === 'create') {
            this.$emit('create', data.data)
          } else {
            this.$emit('update', data.data)
          }
        }).finally(() => {
          this.formProcessing = false
        })
      }
    },
    changePaid (val) {
      if (!val) {
        this.form.price = 0
      }
    }
  }
})
</script>

<style scoped>

</style>
