import { AxiosResponse } from 'axios'
import HTTP from '@/Api/http'
import { AuthAxiosDefaultConfig } from '@/Api/Accounting'

const path = (id) => `/accounting-buy-nb/act/${id}`

export default {
  ready (id: number, data: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.post(`${path(id)}/ready`, data, $config)
  },
  received (id: number, data: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.post(`${path(id)}/received`, data, $config)
  },
  payed (id: number, data: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.post(`${path(id)}/payed`, data, $config)
  },
  makeCalcWithUsers (id: number, data: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.post(`${path(id)}/make-calc-with-users`, data, $config)
  }
}
