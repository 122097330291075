<template>
  <v-card v-if="!loadingParent">
    <v-card-title>
      Офис выдачи кредита
      <v-btn
        text
        x-small
        title="Изменить"
        :disabled="loading"
        @click="isEditOffice = !isEditOffice"
      >
        {{ isEditOffice ? 'Отмена' : 'Изменить' }}
      </v-btn>
    </v-card-title>
    <v-card-text class="pb-3">
      <div
        v-if="isEditOffice"
        class="d-flex"
      >
        <v-autocomplete
          v-if="Array.isArray(officesData)"
          v-model="newDealOfficeId"
          attach=""
          hide-details
          :items="officesData"
          :disabled="loading"
          item-value="id"
          item-text="address"
          outlined
          dense
          label="Офис выдачи кредита"
        />
        <v-btn
          :disabled="dealOffice && (newDealOfficeId === dealOffice.id)"
          :loading="loading"
          text
          class="ml-2"
          @click="updateOfficeId"
        >
          обновить офис
        </v-btn>
      </div>
      <div
        v-else
        class="body-1"
      >
        <span class="text--disabled">Адрес офиса:</span> {{ dealOffice && dealOffice.address || 'Не выбран' }}
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import dealAPI from '@/Api/Crm/Mortgage/Docmclick/Deals'

export default {
  name: 'DomClickOfficeSelectBlock',
  props: {
    dealId: {
      type: Number,
      required: true
    },
    loadingParent: {
      type: Boolean,
      default: false
    },
    dealOffice: {
      type: [Object, null],
      default: null
    }
  },
  data () {
    return {
      isEditOffice: false,
      loading: false,
      newDealOfficeId: null,
      officesData: []
    }
  },
  mounted () {
    this.offices()
    this.newDealOfficeId = this.dealOffice?.id || null
  },
  methods: {
    updateOfficeId () {
      this.$emit('updateOfficeId', this.newDealOfficeId)
      this.isEditOffice = false
    },
    offices () {
      this.loading = true
      dealAPI.offices(this.dealId)
        .then(({ data }) => {
          console.warn('offices RESULT: ', data)
          this.$set(this, 'officesData', data?.data)
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped>

</style>
