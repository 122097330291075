import { RouteKeys } from '@/router/types'
export default {
  path: `${RouteKeys.seoPageMortgage}`,
  name: RouteKeys.seoPageMortgage,
  redirect: { name: `${RouteKeys.seoPageMortgage}-list` },
  meta: { name: 'SEO страницы Ипотека', icon: 'list' },
  component: () => import('@/views/Site/Admin/Index.vue'),
  children: [
    {
      path: 'list',
      name: `${RouteKeys.seoPageMortgage}-list`,
      meta: { name: 'Список SEO страницы Ипотека', icon: 'home', hideInMenu: true },
      component: () => import('@/views/Site/Admin/Mortgage/SeoPage/List.vue')
    },
    {
      path: ':id/edit',
      name: `${RouteKeys.seoPageMortgage}-edit`,
      meta: { name: 'Редактирование SEO страницы Ипотека', icon: 'home', hideInMenu: true },
      component: () => import('@/views/Site/Admin/Mortgage/SeoPage/Edit.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    },
    {
      path: 'create',
      name: `${RouteKeys.seoPageMortgage}-create`,
      meta: { name: 'Добавление SEO страницы Ипотека', icon: 'home', hideInMenu: true },
      component: () => import('@/views/Site/Admin/Mortgage/SeoPage/Edit.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    }
  ]
}
