import Vue from 'vue'
import API from '@/Api/Messenger'
import { scrollToBottomMSG, scrollToElement, setObjectChatRoom } from '@/views/Messenger/helpers'
const namespaced = true
export const chat = {
  namespaced,
  state: {
    chat: {
      params: {
        after: 0,
        before: 0,
        limit: 40
      },
      loading: {
        msg: false,
        next: false,
        prev: false,
        answer: false
      },
      info: {
        selectedUserId: null,
        chatID: null,
        status: 'undefined',
        count: 0,
        name: null,
        type: null,
        owner: null
      },
      list: {
        chatRoom: [],
        chatUser: [],
        replyMessageObj: []
      }
    }
  },
  mutations: {
    SET_CHAT_INFO (state:any, payload:any) {
      const { type, setChatInfo, unviewedList } = payload
      switch (type) {
        case 'SET_INFO_USER':
          Object.assign(state.chat.info, setChatInfo)
          break
        case 'SET_INFO_CHAT_ID':
          Vue.set(state.chat.info, 'chatID', setChatInfo)
          break
        case 'SET_LIST_CHATROOM':
          state.chat.list.chatRoom = [...state.chat.list.chatRoom, setChatInfo]
          break
        case 'SET_FAKE_MSG_CHATROOM':
          state.chat.list.chatRoom.forEach((item:any) => {
            if (item.chatID === state.chat.info.chatID) {
              item.message = [...item.message, setChatInfo]
            }
          })
          break
        case 'SET_MSGS_CHATROOM':
          state.chat.list.chatRoom.forEach((item:any) => {
            if (item.chatID === state.chat.info.chatID) {
              item.message.forEach((msg: any) => {
                if (msg.id === setChatInfo.tmpId) {
                  delete msg.status
                  Object.assign(msg, setChatInfo.data)
                }
              })
            }
          })
          break
        case 'SET_LOADING':
          Vue.set(state.chat.loading, setChatInfo.key, setChatInfo.params)
          break
        case 'SET_PARAMS':
          Vue.set(state.chat.params, setChatInfo.key, setChatInfo.params)
          break
        case 'SET_MSG_CHATROOM':
          state.chat.list.chatRoom.forEach((item:any) => {
            if (item.chatID === state.chat.info.chatID) {
              item.message = setChatInfo
              item.message.reverse()
            }
          })
          break
        case 'SET_CHAT':
          setChatInfo.forEach((user:any) => {
            user.count = 0
            unviewedList.forEach((unviewed:any) => {
              if (user.id === unviewed.chatId) {
                user.count = unviewed.count
              }
            })
          })
          state.chat.list.chatUser = setChatInfo
          break
        case 'SET_FAKE_USER':
          state.chat.list.chatUser.unshift(setChatInfo)
          break
        case 'SET_NEW_USER_CHAT':
          state.chat.list.chatUser.forEach((item:any) => {
            if (item.id === setChatInfo.tmpId) {
              delete item.status
              Object.assign(item, setChatInfo.data)
            }
          })
          break
      }
    },
    SET_MSG_CHAT_ROOM (state:any, payload:any) {
      const { type, msg } = payload
      switch (type) {
        case 'SET_AFTER_MSG':
          state.chat.list.chatRoom.forEach((item:any) => {
            if (item.chatID === state.chat.info.chatID) {
              item.message = [...item.message, ...msg.reverse()]
            }
          })
          break
        case 'SET_BEFORE_MSG':
          state.chat.list.chatRoom.forEach((item:any) => {
            if (item.chatID === state.chat.info.chatID) {
              item.message = [...msg.reverse(), ...item.message]
            }
          })
          break
        case 'SET_LAST_MSG':
          state.chat.list.chatRoom.forEach((item:any) => {
            if (item.chatID === state.chat.info.chatID) {
              item.endChat = msg
            }
          })
          break
        case 'RECORD_TEMPORY_MSG_ID':
          state.chat.list.chatRoom.forEach((item:any) => {
            if (item.chatID === state.chat.info.chatID) {
              item.previewID = msg
            }
          })
          break
      }
    },
    SET_CHAT_UNVIEWED (state:any, payload:any) {
      const { type, data, chatID } = payload
      switch (type) {
        case 'SET_UNVIEWED_COUNT':
          state.chat.list.chatRoom.forEach((item:any) => {
            if (item.chatID === chatID) {
              item.count = data
            }
          })
          state.chat.list.chatUser.forEach((item:any) => {
            if (item.id === chatID) {
              item.count = data
            }
          })
          break
        case 'CLEAR_UNVIEWED':
          state.chat.list.chatRoom.forEach((item:any) => {
            if (item.chatID === chatID) {
              item.count = 0
            }
          })
          state.chat.list.chatUser.forEach((item:any) => {
            if (item.id === chatID) {
              item.count = 0
            }
          })
          break
      }
    },
    SET_REPLY_MSG_OBJ (state:any, payload:any) {
      const { type, data } = payload
      switch (type) {
        case 'ADD':
          if (state.chat.list.replyMessageObj.length > 0) {
            let status:any = false
            state.chat.list.replyMessageObj.forEach((item:any) => {
              if (state.chat.info.chatID === item.chatID) {
                Object.assign(item.objMsg, data.objMsg)
                status = true
              }
            })
            if (!status) state.chat.list.replyMessageObj.push(data)
          } else {
            state.chat.list.replyMessageObj.push(data)
          }
          break
        case 'CLEAR':
          state.chat.list.replyMessageObj.forEach((item:any, index:any) => {
            if (data.chatID === item.chatID) {
              state.chat.list.replyMessageObj.splice(index, 1)
            }
          })
      }
    }
  },
  actions: {
    // ПОЛУЧАЕМ ВСЕ СООБЩЕНИЯ ЧАТА
    getChatMessages ({ state, commit }:any, id:any) {
      commit('SET_CHAT_INFO', { type: 'SET_LOADING', setChatInfo: { key: 'msg', params: true } })
      if (id) commit('SET_CHAT_INFO', { type: 'SET_INFO_CHAT_ID', setChatInfo: id })
      API.Chat.readChatList(state.chat.info.chatID, state.chat.params)
        .then(({ data }) => {
          commit('SET_CHAT_INFO', { type: 'SET_MSG_CHATROOM', setChatInfo: data.data })
          commit('SET_CHAT_INFO', { type: 'SET_LOADING', setChatInfo: { key: 'msg', params: false } })
        })
        .catch((error) => {
          console.error('error API.Chat.readlist.chat', error)
        })
        .finally(() => {
          switch (true) {
            case (state.chat.info.count > 0):
              scrollToElement({ block: 'start', behavior: 'auto', msgID: 'unviewed', load: true })
              break
            default:
              scrollToBottomMSG({ block: 'start', behavior: 'auto' })
              break
          }
        })
    },
    // СОЗДАЕМ НОВЫЙ ЧАТ
    createNewChat ({ commit, dispatch }:any, payload:any) {
      const { groupChat, message } = payload
      API.Chat.store(groupChat)
        .then(({ data }) => {
          commit('SET_CHAT_INFO', {
            type: 'SET_INFO_USER',
            setChatInfo: {
              status: 'undefined',
              chatID: data.data.id,
              selectedUserId: 'undefined',
              name: groupChat.name,
              type: groupChat.type
            }
          })
          commit('SET_CHAT_INFO', { type: 'SET_NEW_USER_CHAT', setChatInfo: data })
          const objectChatRoom = setObjectChatRoom(data.data.id)
          commit('SET_CHAT_INFO', { type: 'SET_LIST_CHATROOM', setChatInfo: objectChatRoom })
          commit('SET_CHAT_INFO', { type: 'SET_FAKE_MSG_CHATROOM', setChatInfo: message })
          dispatch('sendChatMessage', message)
        })
        .catch((error) => { console.log('error', error) })
    },
    // ОТПРАВЛЯЕМ СООБЩЕНИЯ В ЧАТ
    sendChatMessage ({ state, commit }:any, payload:any) {
      scrollToBottomMSG({ block: 'end', behavior: 'auto' })
      API.Chat.sendChatList(state.chat.info.chatID, payload)
        .then(({ data }) => {
          commit('SET_CHAT_INFO', { type: 'SET_MSGS_CHATROOM', setChatInfo: data })
        })
        .catch((error) => {
          console.log('error', error)
        })
    }
  },
  getters: {
    getInfoChat: (state: any) => {
      return state.chat || {}
    }
  }
}
