<template>
  <div
    class="tw-relative tw-text-editor-wrapper tw-h-full"
    :class="{'tw-pt-[18px]' : label }"
  >
    <div
      v-if="label"
      class="tw-absolute tw-text-an-flat-dark-blue-link tw-text-main-sm2 tw-top-0 tw-pl-[8px] tw-cursor-default"
    >
      {{ label }}
    </div>
    <div class="tiptap-editor-wrapper">
      <editor-menu-bar
        v-if="editable"
        v-slot="{ commands, isActive, getMarkAttrs }"
        class="editor-menu"
        :editor="editor"
      >
        <div>
          <div class="tw-flex tw-flex-wrap tw-gap-[10px] tw-items-center">
            <v-btn-toggle>
              <v-btn
                icon
                small
                @click="commands.undo"
              >
                <icon name="undo" />
              </v-btn>
              <v-btn
                icon
                small
                @click="commands.redo"
              >
                <icon name="redo" />
              </v-btn>
            </v-btn-toggle>
            <v-btn-toggle>
              <v-btn
                icon
                small
                :variant="isActive.bold() ? 'secondary':'outline-secondary'"
                :active="isActive.bold()"
                @click="commands.bold"
              >
                <icon name="bold" />
              </v-btn>
              <v-btn
                icon
                small
                :variant="isActive.italic() ? 'secondary':'outline-secondary'"
                :active="isActive.italic()"
                @click="commands.italic"
              >
                <icon name="italic" />
              </v-btn>
              <v-btn
                icon
                small
                :variant="isActive.strike() ? 'secondary':'outline-secondary'"
                :active="isActive.strike()"
                @click="commands.strike"
              >
                <icon name="strike" />
              </v-btn>
              <v-btn
                icon
                small
                :variant="isActive.underline() ? 'secondary':'outline-secondary'"
                :active="isActive.underline()"
                @click="commands.underline"
              >
                <icon name="underline" />
              </v-btn>
            </v-btn-toggle>
            <v-btn-toggle>
              <v-btn
                icon
                small
                :variant="isActive.bullet_list() ? 'secondary':'outline-secondary'"
                :active="isActive.bullet_list()"
                @click="commands.bullet_list"
              >
                <icon name="ul" />
              </v-btn>
              <v-btn
                icon
                small
                :variant="isActive.ordered_list() ? 'secondary':'outline-secondary'"
                :active="isActive.ordered_list()"
                @click="commands.ordered_list"
              >
                <icon name="ol" />
              </v-btn>
            </v-btn-toggle>
            <v-btn-toggle>
              <v-btn
                icon
                small
                :variant="isActive.code_block() ? 'secondary':'outline-secondary'"
                :active="isActive.code_block()"
                @click="commands.code_block"
              >
                <icon name="code" />
              </v-btn>
              <v-btn
                icon
                small
                :variant="isActive.blockquote() ? 'secondary':'outline-secondary'"
                :active="isActive.blockquote()"
                @click="commands.blockquote"
              >
                <icon name="quote" />
              </v-btn>
              <v-btn
                icon
                small
                :color="isActive.link() ? 'secondary':'outline-secondary'"
                :active="isActive.link()"
                @click="showLinkMenu(getMarkAttrs('link'))"
              >
                <v-icon small>
                  fa-link
                </v-icon>
              </v-btn>
            </v-btn-toggle>
            <v-btn-toggle>
              <v-btn
                icon
                small
                :color="isActive.table() ? 'secondary':'outline-secondary'"
                @click="commands.createTable({rowsCount: 3, colsCount: 3, withHeaderRow: false })"
              >
                <v-icon small>
                  fa-table
                </v-icon>
              </v-btn>
              <template v-if="isActive.table()">
                <v-btn
                  icon
                  small
                  color="secondary"
                  @click="commands.deleteTable"
                >
                  <icon name="delete_table" />
                </v-btn>
                <v-btn
                  icon
                  small
                  color="secondary"
                  @click="commands.addColumnBefore"
                >
                  <icon name="add_col_before" />
                </v-btn>
                <v-btn
                  icon
                  small
                  color="secondary"
                  @click="commands.addColumnAfter"
                >
                  <icon name="add_col_after" />
                </v-btn>
                <v-btn
                  icon
                  small
                  color="secondary"
                  @click="commands.deleteColumn"
                >
                  <icon name="delete_col" />
                </v-btn>
                <v-btn
                  icon
                  small
                  color="secondary"
                  @click="commands.addRowBefore"
                >
                  <icon name="add_row_before" />
                </v-btn>
                <v-btn
                  icon
                  small
                  color="secondary"
                  @click="commands.addRowAfter"
                >
                  <icon name="add_row_after" />
                </v-btn>
                <v-btn
                  icon
                  small
                  color="secondary"
                  @click="commands.deleteRow"
                >
                  <icon name="delete_row" />
                </v-btn>
                <v-btn
                  icon
                  small
                  color="secondary"
                  @click="commands.toggleCellMerge"
                >
                  <icon name="combine_cells" />
                </v-btn>
              </template>
            </v-btn-toggle>
            <v-spacer />
            <v-btn-toggle>
              <v-btn
                small
                light
                :color="view === 'editor' ? 'primary':''"
                @click="view = 'editor'"
              >
                <strong>Редактор</strong>
              </v-btn>
              <v-btn
                small
                light
                title="Исходный код"
                :color="view === 'source' ? 'primary':''"
                @click="view = 'source'"
              >
                <v-icon>fa-code</v-icon>
              </v-btn>
            </v-btn-toggle>
          </div>
          <div
            class="mt-2"
            style="max-width: 300px"
          >
            <v-text-field
              v-if="linkMenuIsActive"
              v-model="linkUrl"
              dense
              hide-details
              placeholder="https://"
              outlined
              clearable
              label="Ссылка"
              type="text"
              @keydown.enter="setLinkUrl(commands.link, linkUrl)"
              @keydown.esc="hideLinkMenu"
            >
              <template #append>
                <div class="d-flex">
                  <v-btn
                    x-small
                    icon
                    class="mr-3"
                    color="success"
                    @click="setLinkUrl(commands.link, linkUrl)"
                  >
                    <v-icon>fa-check</v-icon>
                  </v-btn>
                  <v-btn
                    x-small
                    icon
                    color="error"
                    @click="setLinkUrl(commands.link, null)"
                  >
                    <v-icon>fa-times</v-icon>
                  </v-btn>
                </div>
              </template>
            </v-text-field>
          </div>
        </div>
      </editor-menu-bar>
      <div
        v-if="view === 'editor'"
        style="height: 100%; overflow: auto;"
        class="editor-body user-help-table-styles"
      >
        <editor-content
          :style="{color: textColor}"
          class="editor__content p-1"
          :editor="editor"
        />
      </div>
      <TwTextarea
        v-else
        v-model="currentContent"
        class="tw-text-main-sm"
        resize="y"
        dense
        input-class-base="tw-border-none tw-outline-none tw-text-an-flat-black-main tw-w-full tw-min-w-[280px] tw-pt-[15px] tw-pb-[17px] tw-bg-white tw-block placeholder:tw-text-an-flat-not-active-input enabled:hover:tw-drop-shadow-input"
        rows="16"
      />
    </div>
  </div>
</template>

<script>

import Icon from '@/components/tw-ui/ui-kit/input/text-editor-icons/TextEditorIcons'
import { Editor, EditorMenuBar, EditorContent } from 'tiptap'
import {
  History,
  Bold, Italic, Strike, Underline,
  BulletList, OrderedList, ListItem,
  Code, CodeBlock, Link, Blockquote,
  Table, TableHeader, TableCell, TableRow,
  Heading
} from 'tiptap-extensions'
import TwTextarea from '@/components/tw-ui/ui-kit/input/TwTextarea.vue'

// Набор иконок для текстового редактора TipTap  https://github.com/scrumpy/tiptap
const iconsData = `<svg xmlns="http://www.w3.org/2000/svg" style="position: absolute; width: 0; height: 0; display: none;">
      <symbol viewBox="0 0 24 24" id="icon--add_col_after">
        <path
          d="M5 14a5 5 0 110 10 5 5 0 010-10zm2.5 5.938a.937.937 0 100-1.875H6.25a.312.312 0 01-.313-.313V16.5a.937.937 0 10-1.875 0v1.25c0 .173-.14.313-.312.313H2.5a.937.937 0 100 1.875h1.25c.173 0 .313.14.313.312v1.25a.937.937 0 101.875 0v-1.25c0-.173.14-.313.312-.313H7.5zM16 19a3 3 0 006 0V5a3 3 0 00-6 0v14zm-2 0V5a5 5 0 0110 0v14a5 5 0 01-10 0z"></path>
      </symbol>
      <symbol viewBox="0 0 24 24" id="icon--add_col_before">
        <path
          d="M19 14a5 5 0 110 10 5 5 0 010-10zm2.5 5.938a.937.937 0 100-1.875h-1.25a.312.312 0 01-.313-.313V16.5a.937.937 0 10-1.875 0v1.25c0 .173-.14.313-.312.313H16.5a.937.937 0 100 1.875h1.25c.173 0 .313.14.313.312v1.25a.937.937 0 101.875 0v-1.25c0-.173.14-.313.312-.313h1.25zM2 19a3 3 0 006 0V5a3 3 0 10-6 0v14zm-2 0V5a5 5 0 1110 0v14a5 5 0 01-10 0z"></path>
      </symbol>
      <symbol viewBox="0 0 24 24" id="icon--add_row_after">
        <path
          d="M19 0a5 5 0 110 10 5 5 0 010-10zm2.5 5.938a.937.937 0 100-1.875h-1.25a.312.312 0 01-.313-.313V2.5a.937.937 0 10-1.875 0v1.25c0 .173-.14.313-.312.313H16.5a.937.937 0 100 1.875h1.25c.173 0 .313.14.313.312V7.5a.937.937 0 101.875 0V6.25c0-.173.14-.313.312-.313h1.25zM5 16a3 3 0 000 6h14a3 3 0 000-6H5zm0-2h14a5 5 0 010 10H5a5 5 0 010-10z"></path>
      </symbol>
      <symbol viewBox="0 0 24 24" id="icon--add_row_before">
        <path
          d="M19 14a5 5 0 110 10 5 5 0 010-10zm2.5 5.938a.937.937 0 100-1.875h-1.25a.312.312 0 01-.313-.313V16.5a.937.937 0 10-1.875 0v1.25c0 .173-.14.313-.312.313H16.5a.937.937 0 100 1.875h1.25c.173 0 .313.14.313.312v1.25a.937.937 0 101.875 0v-1.25c0-.173.14-.313.312-.313h1.25zM5 2a3 3 0 100 6h14a3 3 0 000-6H5zm0-2h14a5 5 0 010 10H5A5 5 0 115 0z"></path>
      </symbol>
      <symbol viewBox="0 0 24 24" id="icon--bold">
        <path
          d="M17.194 10.962A6.271 6.271 0 0012.844.248H4.3a1.25 1.25 0 000 2.5h1.013a.25.25 0 01.25.25V21a.25.25 0 01-.25.25H4.3a1.25 1.25 0 100 2.5h9.963a6.742 6.742 0 002.93-12.786zm-4.35-8.214a3.762 3.762 0 010 7.523H8.313a.25.25 0 01-.25-.25V3a.25.25 0 01.25-.25zm1.42 18.5H8.313a.25.25 0 01-.25-.25v-7.977a.25.25 0 01.25-.25h5.951a4.239 4.239 0 010 8.477z"></path>
      </symbol>
      <symbol viewBox="0 0 24 24" id="icon--checklist">
        <path
          d="M21 0H3a3 3 0 00-3 3v18a3 3 0 003 3h18a3 3 0 003-3V3a3 3 0 00-3-3zm1 21a1 1 0 01-1 1H3a1 1 0 01-1-1V3a1 1 0 011-1h18a1 1 0 011 1z"></path>
        <path
          d="M11.249 4.5a1.251 1.251 0 00-1.75.25L7.365 7.6l-.482-.481a1.25 1.25 0 00-1.767 1.764l1.5 1.5a1.262 1.262 0 001.884-.134l3-4a1.25 1.25 0 00-.251-1.749zm0 9a1.251 1.251 0 00-1.75.25L7.365 16.6l-.482-.481a1.25 1.25 0 10-1.767 1.768l1.5 1.5a1.265 1.265 0 001.884-.138l3-4a1.25 1.25 0 00-.251-1.749zM18.5 7.749H14a1.25 1.25 0 000 2.5h4.5a1.25 1.25 0 000-2.5zm0 8H14a1.25 1.25 0 000 2.5h4.5a1.25 1.25 0 100-2.5z"></path>
      </symbol>
      <symbol viewBox="0 0 24 24" id="icon--code">
        <path
          d="M9.147 21.552a1.244 1.244 0 01-.895-.378L.84 13.561a2.257 2.257 0 010-3.125l7.412-7.613a1.25 1.25 0 011.791 1.744l-6.9 7.083a.5.5 0 000 .7l6.9 7.082a1.25 1.25 0 01-.9 2.122zm5.707 0a1.25 1.25 0 01-.9-2.122l6.9-7.083a.5.5 0 000-.7l-6.9-7.082a1.25 1.25 0 011.791-1.744l7.411 7.612a2.257 2.257 0 010 3.125l-7.412 7.614a1.244 1.244 0 01-.89.38zm6.514-9.373z"></path>
      </symbol>
      <symbol viewBox="0 0 24 24" id="icon--combine_cells">
        <path
          d="M2 19a3 3 0 003 3h14a3 3 0 003-3V5a3 3 0 00-3-3H5a3 3 0 00-3 3v14zm-2 0V5a5 5 0 015-5h14a5 5 0 015 5v14a5 5 0 01-5 5H5a5 5 0 01-5-5zm12-9a1 1 0 011 1v2a1 1 0 01-2 0v-2a1 1 0 011-1zm0 6a1 1 0 011 1v3a1 1 0 01-2 0v-3a1 1 0 011-1zm0-13a1 1 0 011 1v3a1 1 0 01-2 0V4a1 1 0 011-1z"></path>
      </symbol>
      <symbol viewBox="0 0 24 24" id="icon--delete_col">
        <path
          d="M12.641 21.931a7.01 7.01 0 001.146 1.74A5 5 0 017 19V5a5 5 0 1110 0v7.29a6.972 6.972 0 00-2 .965V5a3 3 0 00-6 0v14a3 3 0 003.641 2.931zM19 14a5 5 0 110 10 5 5 0 010-10zm-2.5 5.938h5a.937.937 0 100-1.875h-5a.937.937 0 100 1.875z"></path>
      </symbol>
      <symbol viewBox="0 0 24 24" id="icon--delete_row">
        <path
          d="M13.255 15a6.972 6.972 0 00-.965 2H5A5 5 0 015 7h14a5 5 0 014.671 6.787 7.01 7.01 0 00-1.74-1.146A3 3 0 0019 9H5a3 3 0 000 6h8.255zM19 14a5 5 0 110 10 5 5 0 010-10zm-2.5 5.938h5a.937.937 0 100-1.875h-5a.937.937 0 100 1.875z"></path>
      </symbol>
      <symbol viewBox="0 0 24 24" id="icon--delete_table">
        <path
          d="M19 14a5 5 0 110 10 5 5 0 010-10zm-2.5 5.938h5a.937.937 0 100-1.875h-5a.937.937 0 100 1.875zM12.29 17H9v5h3.674c.356.75.841 1.426 1.427 2H5a5 5 0 01-5-5V5a5 5 0 015-5h14a5 5 0 015 5v2.823a.843.843 0 010 .354V14.1a7.018 7.018 0 00-2-1.427V9h-5v3.29a6.972 6.972 0 00-2 .965V9H9v6h4.255a6.972 6.972 0 00-.965 2zM17 7h5V5a3 3 0 00-3-3h-2v5zm-2 0V2H9v5h6zM7 2H5a3 3 0 00-3 3v2h5V2zM2 9v6h5V9H2zm0 8v2a3 3 0 003 3h2v-5H2z"></path>
      </symbol>
      <symbol viewBox="0 0 24 24" id="icon--github">
        <path
          d="M11.999.5C5.649.5.5 5.648.5 12c0 5.082 3.294 9.392 7.865 10.914.574.103.756-.236.756-.541 0-.274.006-1.037 0-1.997-3.198.694-3.861-1.515-3.861-1.515-.523-1.329-1.275-1.682-1.275-1.682-1.045-.714.077-.699.077-.699 1.153.08 1.762 1.184 1.762 1.184 1.026 1.758 2.691 1.25 3.346.956.106-.742.402-1.251.731-1.536-2.554-.292-5.238-1.277-5.238-5.686 0-1.255.448-2.281 1.184-3.086-.118-.289-.514-1.46.112-3.043 0 0 .967-.309 3.162 1.18a11.011 11.011 0 012.88-.388c.976.005 1.96.132 2.88.388 2.195-1.488 3.159-1.18 3.159-1.18.627 1.583.232 2.754.114 3.043.736.805 1.183 1.831 1.183 3.086 0 4.42-2.689 5.391-5.251 5.674.412.357.787 1.047.787 2.12v3.184c0 .308.186.647.77.536C20.209 21.389 23.5 17.08 23.5 12 23.5 5.648 18.352.5 11.999.5z"></path>
      </symbol>
      <symbol viewBox="0 0 24 24" id="icon--hr">
        <path d="M5 13a1 1 0 010-2h14a1 1 0 010 2H5z"></path>
      </symbol>
      <symbol viewBox="0 0 24 24" id="icon--image">
        <circle cx="9.75" cy="6.247" r="2.25"></circle>
        <path
          d="M16.916 8.71A1.027 1.027 0 0016 8.158a1.007 1.007 0 00-.892.586l-1.558 3.434a.249.249 0 01-.422.053l-.82-1.024a1 1 0 00-.813-.376 1.007 1.007 0 00-.787.426L7.59 15.71a.5.5 0 00.41.79h12a.5.5 0 00.425-.237.5.5 0 00.022-.486z"></path>
        <path
          d="M22 0H5.5a2 2 0 00-2 2v16.5a2 2 0 002 2H22a2 2 0 002-2V2a2 2 0 00-2-2zm-.145 18.354a.5.5 0 01-.354.146H6a.5.5 0 01-.5-.5V2.5A.5.5 0 016 2h15.5a.5.5 0 01.5.5V18a.5.5 0 01-.145.351z"></path>
        <path d="M19.5 22h-17a.5.5 0 01-.5-.5v-17a1 1 0 00-2 0V22a2 2 0 002 2h17.5a1 1 0 000-2z"></path>
      </symbol>
      <symbol viewBox="0 0 24 24" id="icon--italic">
        <path
          d="M22.5.248h-7.637a1.25 1.25 0 000 2.5h1.086a.25.25 0 01.211.384L4.78 21.017a.5.5 0 01-.422.231H1.5a1.25 1.25 0 000 2.5h7.637a1.25 1.25 0 000-2.5H8.051a.25.25 0 01-.211-.384L19.22 2.98a.5.5 0 01.422-.232H22.5a1.25 1.25 0 000-2.5z"></path>
      </symbol>
      <symbol viewBox="0 0 24 24" id="icon--link">
        <path
          d="M12.406 14.905a1 1 0 00-.543 1.307 1 1 0 01-.217 1.09l-2.828 2.829a2 2 0 01-2.828 0L3.868 18.01a2 2 0 010-2.829L6.7 12.353a1.013 1.013 0 011.091-.217 1 1 0 00.763-1.849 3.034 3.034 0 00-3.268.652l-2.832 2.828a4.006 4.006 0 000 5.657l2.122 2.121a4 4 0 005.656 0l2.829-2.828a3.008 3.008 0 00.651-3.27 1 1 0 00-1.306-.542z"></path>
        <path
          d="M7.757 16.241a1.011 1.011 0 001.414 0l7.779-7.778a1 1 0 00-1.414-1.414l-7.779 7.778a1 1 0 000 1.414z"></path>
        <path
          d="M21.546 4.574l-2.121-2.121a4.006 4.006 0 00-5.657 0l-2.829 2.828a3.006 3.006 0 00-.651 3.269 1 1 0 101.849-.764 1 1 0 01.217-1.086l2.828-2.828a2 2 0 012.829 0l2.121 2.121a2 2 0 010 2.829L17.3 11.645a1.015 1.015 0 01-1.091.217 1 1 0 00-.765 1.849 3.026 3.026 0 003.27-.651l2.828-2.828a4.007 4.007 0 00.004-5.658z"></path>
      </symbol>
      <symbol viewBox="0 0 24 24" id="icon--mention">
        <path
          d="M12 .5A11.634 11.634 0 00.262 12 11.634 11.634 0 0012 23.5a11.836 11.836 0 006.624-2 1.25 1.25 0 10-1.393-2.076A9.34 9.34 0 0112 21a9.132 9.132 0 01-9.238-9A9.132 9.132 0 0112 3a9.132 9.132 0 019.238 9v.891a1.943 1.943 0 01-3.884 0V12A5.355 5.355 0 1012 17.261a5.376 5.376 0 003.861-1.634 4.438 4.438 0 007.877-2.736V12A11.634 11.634 0 0012 .5zm0 14.261A2.763 2.763 0 1114.854 12 2.812 2.812 0 0112 14.761z"></path>
      </symbol>
      <symbol viewBox="0 0 24 24" id="icon--ol">
        <path
          d="M7.75 4.5h15a1 1 0 000-2h-15a1 1 0 000 2zm15 6.5h-15a1 1 0 100 2h15a1 1 0 000-2zm0 8.5h-15a1 1 0 000 2h15a1 1 0 000-2zM2.212 17.248a2 2 0 00-1.933 1.484.75.75 0 101.45.386.5.5 0 11.483.63.75.75 0 100 1.5.5.5 0 11-.482.635.75.75 0 10-1.445.4 2 2 0 103.589-1.648.251.251 0 010-.278 2 2 0 00-1.662-3.111zm2.038-6.5a2 2 0 00-4 0 .75.75 0 001.5 0 .5.5 0 011 0 1.031 1.031 0 01-.227.645L.414 14.029A.75.75 0 001 15.248h2.5a.75.75 0 000-1.5h-.419a.249.249 0 01-.195-.406L3.7 12.33a2.544 2.544 0 00.55-1.582zM4 5.248h-.25A.25.25 0 013.5 5V1.623A1.377 1.377 0 002.125.248H1.5a.75.75 0 000 1.5h.25A.25.25 0 012 2v3a.25.25 0 01-.25.25H1.5a.75.75 0 000 1.5H4a.75.75 0 000-1.5z"></path>
      </symbol>
      <symbol viewBox="0 0 24 24" id="icon--paragraph">
        <path
          d="M22.5.248H7.228a6.977 6.977 0 100 13.954h2.318a.25.25 0 01.25.25V22.5a1.25 1.25 0 002.5 0V3a.25.25 0 01.25-.25h3.682a.25.25 0 01.25.25v19.5a1.25 1.25 0 002.5 0V3a.249.249 0 01.25-.25H22.5a1.25 1.25 0 000-2.5zM9.8 11.452a.25.25 0 01-.25.25H7.228a4.477 4.477 0 110-8.954h2.318A.25.25 0 019.8 3z"></path>
      </symbol>
      <symbol viewBox="0 0 24 24" id="icon--quote">
        <path
          d="M18.559 3.932a4.942 4.942 0 100 9.883 4.609 4.609 0 001.115-.141.25.25 0 01.276.368 6.83 6.83 0 01-5.878 3.523 1.25 1.25 0 000 2.5 9.71 9.71 0 009.428-9.95V8.873a4.947 4.947 0 00-4.941-4.941zm-12.323 0a4.942 4.942 0 000 9.883 4.6 4.6 0 001.115-.141.25.25 0 01.277.368 6.83 6.83 0 01-5.878 3.523 1.25 1.25 0 000 2.5 9.711 9.711 0 009.428-9.95V8.873a4.947 4.947 0 00-4.942-4.941z"></path>
      </symbol>
      <symbol viewBox="0 0 24 24" id="icon--redo">
        <path
          d="M22.608.161a.5.5 0 00-.545.108L19.472 2.86a.25.25 0 01-.292.045 12.537 12.537 0 00-12.966.865A12.259 12.259 0 006.1 23.632a1.25 1.25 0 001.476-2.018 9.759 9.759 0 01.091-15.809 10 10 0 019.466-1.1.25.25 0 01.084.409l-1.85 1.85a.5.5 0 00.354.853h6.7a.5.5 0 00.5-.5V.623a.5.5 0 00-.313-.462z"></path>
      </symbol>
      <symbol viewBox="0 0 24 24" id="icon--remove">
        <path
          d="M20.485 3.511A12.01 12.01 0 1024 12a12.009 12.009 0 00-3.515-8.489zm-1.767 15.21A9.51 9.51 0 1121.5 12a9.508 9.508 0 01-2.782 6.721z"></path>
        <path
          d="M16.987 7.01a1.275 1.275 0 00-1.8 0l-3.177 3.177L8.829 7.01a1.277 1.277 0 00-1.805 1.806l3.176 3.177-3.176 3.178a1.277 1.277 0 001.805 1.806l3.176-3.177 3.177 3.178a1.277 1.277 0 001.8-1.806l-3.176-3.178 3.176-3.177a1.278 1.278 0 00.005-1.807z"></path>
      </symbol>
      <symbol viewBox="0 0 24 24" id="icon--strike">
        <path
          d="M23.75 12.952A1.25 1.25 0 0022.5 11.7h-8.936a.492.492 0 01-.282-.09c-.722-.513-1.482-.981-2.218-1.432-2.8-1.715-4.5-2.9-4.5-4.863 0-2.235 2.207-2.569 3.523-2.569a4.54 4.54 0 013.081.764 2.662 2.662 0 01.447 1.99v.3a1.25 1.25 0 102.5 0v-.268a4.887 4.887 0 00-1.165-3.777C13.949.741 12.359.248 10.091.248c-3.658 0-6.023 1.989-6.023 5.069 0 2.773 1.892 4.512 4 5.927a.25.25 0 01-.139.458H1.5a1.25 1.25 0 000 2.5h10.977a.251.251 0 01.159.058 4.339 4.339 0 011.932 3.466c0 3.268-3.426 3.522-4.477 3.522-1.814 0-3.139-.405-3.834-1.173a3.394 3.394 0 01-.65-2.7 1.25 1.25 0 00-2.488-.246A5.76 5.76 0 004.4 21.753c1.2 1.324 3.114 2 5.688 2 4.174 0 6.977-2.42 6.977-6.022a6.059 6.059 0 00-.849-3.147.25.25 0 01.216-.377H22.5a1.25 1.25 0 001.25-1.255z"></path>
      </symbol>
      <symbol viewBox="0 0 24 24" id="icon--table">
        <path fill-rule="evenodd"
              d="M17 17v5h2a3 3 0 003-3v-2h-5zm-2 0H9v5h6v-5zm2-2h5V9h-5v6zm-2 0V9H9v6h6zm2-8h5V5a3 3 0 00-3-3h-2v5zm-2 0V2H9v5h6zm9 9.177V19a5 5 0 01-5 5H5a5 5 0 01-5-5V5a5 5 0 015-5h14a5 5 0 015 5v2.823a.843.843 0 010 .354v7.646a.843.843 0 010 .354zM7 2H5a3 3 0 00-3 3v2h5V2zM2 9v6h5V9H2zm0 8v2a3 3 0 003 3h2v-5H2z"></path>
      </symbol>
      <symbol viewBox="0 0 24 24" id="icon--ul">
        <circle cx="2.5" cy="3.998" r="2.5"></circle>
        <path d="M8.5 5H23a1 1 0 000-2H8.5a1 1 0 000 2z"></path>
        <circle cx="2.5" cy="11.998" r="2.5"></circle>
        <path d="M23 11H8.5a1 1 0 000 2H23a1 1 0 000-2z"></path>
        <circle cx="2.5" cy="19.998" r="2.5"></circle>
        <path d="M23 19H8.5a1 1 0 000 2H23a1 1 0 000-2z"></path>
      </symbol>
      <symbol viewBox="0 0 24 24" id="icon--underline">
        <path
          d="M22.5 21.248h-21a1.25 1.25 0 000 2.5h21a1.25 1.25 0 000-2.5zM1.978 2.748h1.363a.25.25 0 01.25.25v8.523a8.409 8.409 0 0016.818 0V3a.25.25 0 01.25-.25h1.363a1.25 1.25 0 000-2.5H16.3a1.25 1.25 0 000 2.5h1.363a.25.25 0 01.25.25v8.523a5.909 5.909 0 01-11.818 0V3a.25.25 0 01.25-.25H7.7a1.25 1.25 0 100-2.5H1.978a1.25 1.25 0 000 2.5z"></path>
      </symbol>
      <symbol viewBox="0 0 24 24" id="icon--undo">
        <path
          d="M17.786 3.77a12.542 12.542 0 00-12.965-.865.249.249 0 01-.292-.045L1.937.269A.507.507 0 001.392.16a.5.5 0 00-.308.462v6.7a.5.5 0 00.5.5h6.7a.5.5 0 00.354-.854L6.783 5.115a.253.253 0 01-.068-.228.249.249 0 01.152-.181 10 10 0 019.466 1.1 9.759 9.759 0 01.094 15.809 1.25 1.25 0 001.473 2.016 12.122 12.122 0 005.013-9.961 12.125 12.125 0 00-5.127-9.9z"></path>
      </symbol>
    </svg>`

export default {
  name: 'TwTextEditorWrapper',
  components: {
    TwTextarea,
    EditorMenuBar,
    EditorContent,
    Icon
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    textColor: {
      default: 'black'
    },
    editableVal: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'Ввести комментарий'
    }
  },
  data () {
    return {
      editable: true,

      linkUrl: null,
      linkMenuIsActive: false,

      view: 'editor',
      currentContent: '',

      editor: new Editor({
        onInit: () => {
          // editor is initialized
          console.warn('Инициализация текстового редактора')
        },
        onUpdate: ({ getHTML }) => {
          // get new content on update
          const newContent = getHTML()
          this.currentContent = newContent
          // console.warn('Обноление текстового редактора: ',newContent);
          this.$emit('input', newContent)
        },
        extensions: [
          new History(),

          new Bold(),
          new Italic(),
          new Strike(),
          new Underline(),

          new Code(),
          new CodeBlock(),
          new Link(),
          new Blockquote(),

          new BulletList(),
          new OrderedList(),
          new ListItem(),

          new Table({
            resizable: true
          }),
          new TableHeader(),
          new TableCell(),
          new TableRow(),

          new Heading({
            levels: [1, 2, 3, 4, 5, 6]
          })
        ],
        autofocus: true,
        content: '<p></p>'
      }),
      inited: false
    }
  },
  watch: {
    view (val) {
      // console.warn('VIEW CHANGE', val, this.currentContent)
      if (val === 'editor') {
        this.editor.setContent(this.currentContent)
        this.$nextTick(() => {
          this.$emit('input', this.currentContent)
        })
      }
    },
    currentContent () {
      // console.log('currentContent ', this.currentContent)
      if (this.view === 'source') {
        this.editor.setContent(this.currentContent)
        this.$nextTick(() => {
          this.$emit('input', this.currentContent)
        })
      }
    },
    editableVal (val) {
      this.setEditableStatus(val)
    },
    value: {
      handler () {
        this.initContent()
      },
      immediate: true
    }
  },
  beforeCreate () {
    // Проверяю наличие блока с иконками, если нет то создаю
    if (!document.getElementById('icons-for-tip-tap')) {
      const iconsBlock = document.createElement('div')
      iconsBlock.id = 'icons-for-tip-tap'
      iconsBlock.innerHTML = iconsData
      document.getElementById('app').appendChild(iconsBlock)
    }
  },
  mounted () {
    this.setEditableStatus(this.editableVal)
  },
  beforeDestroy () {
    // Always destroy your editor instance when it's no longer needed
    this.editor.destroy()
  },
  methods: {
    setEditableStatus ($status) {
      this.editable = $status
      this.editor.setOptions({
        editable: $status
      })
    },
    showLinkMenu (attrs) {
      this.linkUrl = attrs.href
      this.linkMenuIsActive = !this.linkMenuIsActive
      this.$nextTick(() => {
        if (this.$refs && this.$refs.linkInput) {
          this.$refs.linkInput.focus()
        }
      })
    },
    hideLinkMenu () {
      this.linkUrl = null
      this.linkMenuIsActive = false
    },
    setLinkUrl (command, url) {
      command({ href: url })
      this.hideLinkMenu()
    },
    initContent () {
      if (this.value && !this.inited) {
        this.editor.setContent(this.value)
        this.currentContent = this.value
        this.inited = true
      }
    }
  }
}
</script>

<style lang="less">
@import (less) "@/assets/less/tip-tap-editor.less";
.tw-text-editor-wrapper {
  .tiptap-editor-wrapper {
    height: 100%;
    border: 1px solid #E5E7EB;
    border-radius: 6px;
    & > .editor-body {
      border-top: 1px solid #E5E7EB;
    }
    .editor__content {
      height: 100%;
      & > .ProseMirror {
        //min-height: 50%;
      }
    }
  }
}
</style>
