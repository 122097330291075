<template>
  <div>
    <TwPopoverShort v-if="!emailStatusWorker">
      <template #trigger>
        <button
          class="tw-group tw-p-2 tw-cursor-pointer tw-relative"
          @click="showEmployeeProfile"
        >
          <div class="tw-absolute tw-left-[-6px] tw-top-[-7px]">
            <span class="tw-animate-ping tw-absolute tw-left-0 tw-top-0 tw-inline-flex tw-h-full tw-w-full tw-rounded-full tw-bg-an-flat-red-main tw-opacity-75" />
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2007_2185)">
                <circle
                  cx="10"
                  cy="10"
                  r="10"
                  fill="#E63114"
                />
                <path
                  d="M8.86826 5.82681C8.86826 5.28345 9.35599 4.94705 10.0006 4.94705C10.6295 4.94705 11.1329 5.29635 11.1329 5.82681V10.8105C11.1329 11.3404 10.6295 11.6897 10.0006 11.6897C9.35593 11.6897 8.86826 11.3545 8.86826 10.8105V5.82681Z"
                  fill="white"
                />
                <path
                  d="M8.87725 13.9364C8.87725 13.3165 9.38128 12.8125 10.0006 12.8125C10.6199 12.8125 11.1233 13.3165 11.1234 13.9364C11.1234 14.5551 10.6199 15.0591 10.0006 15.0591C9.38128 15.0591 8.87725 14.5551 8.87725 13.9364Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_2007_2185">
                  <rect
                    width="20"
                    height="20"
                    fill="white"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
          <svg
            width="19"
            height="15"
            viewBox="0 0 19 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              class="group-hover:tw-fill-an-flat-red-main tw-fill-an-flat-grey-3"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0 2.67857C0 1.19924 1.24366 0 2.77778 0H15.7407C17.2749 0 18.5185 1.19924 18.5185 2.67857V11.6071C18.5185 13.0865 17.2749 14.2857 15.7407 14.2857H2.77778C1.24366 14.2857 0 13.0865 0 11.6071V2.67857ZM2.77778 1.78571C2.26641 1.78571 1.85185 2.18546 1.85185 2.67857V11.6071C1.85185 12.1003 2.26641 12.5 2.77778 12.5H15.7407C16.2521 12.5 16.6667 12.1003 16.6667 11.6071V2.67857C16.6667 2.18546 16.2521 1.78571 15.7407 1.78571H2.77778Z"
              fill="#99A1A7"
            />
            <path
              class="group-hover:tw-fill-an-flat-red-main tw-fill-an-flat-grey-3"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.155607 2.18284C0.439264 1.77255 1.01414 1.66167 1.43963 1.93521L9.25936 6.96221L17.0791 1.93521C17.5045 1.66167 18.0794 1.77255 18.3632 2.18284C18.6468 2.59313 18.5318 3.14748 18.1063 3.42101L9.77297 8.77819C9.46195 8.9781 9.05676 8.9781 8.74574 8.77819L0.412412 3.42101C-0.0130783 3.14748 -0.128051 2.59313 0.155607 2.18284V2.18284Z"
              fill="#99A1A7"
            />
          </svg>
        </button>
      </template>
      <template #content>
        <div class="tw-flex tw-flex-col tw-gap-[3px] tw-justify-center">
          <span>{{ emailStatus.label }}</span>
          <span>Перейдите в профиль и настройте почту!</span>
        </div>
      </template>
    </TwPopoverShort>
    <button
      v-else
      class="tw-group tw-p-2 tw-cursor-pointer"
      @click.stop="handlerClick"
    >
      <svg
        width="19"
        height="15"
        viewBox="0 0 19 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          class="group-hover:tw-fill-an-flat-red-main tw-fill-an-flat-grey-3"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 2.67857C0 1.19924 1.24366 0 2.77778 0H15.7407C17.2749 0 18.5185 1.19924 18.5185 2.67857V11.6071C18.5185 13.0865 17.2749 14.2857 15.7407 14.2857H2.77778C1.24366 14.2857 0 13.0865 0 11.6071V2.67857ZM2.77778 1.78571C2.26641 1.78571 1.85185 2.18546 1.85185 2.67857V11.6071C1.85185 12.1003 2.26641 12.5 2.77778 12.5H15.7407C16.2521 12.5 16.6667 12.1003 16.6667 11.6071V2.67857C16.6667 2.18546 16.2521 1.78571 15.7407 1.78571H2.77778Z"
          fill="#99A1A7"
        />
        <path
          class="group-hover:tw-fill-an-flat-red-main tw-fill-an-flat-grey-3"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.155607 2.18284C0.439264 1.77255 1.01414 1.66167 1.43963 1.93521L9.25936 6.96221L17.0791 1.93521C17.5045 1.66167 18.0794 1.77255 18.3632 2.18284C18.6468 2.59313 18.5318 3.14748 18.1063 3.42101L9.77297 8.77819C9.46195 8.9781 9.05676 8.9781 8.74574 8.77819L0.412412 3.42101C-0.0130783 3.14748 -0.128051 2.59313 0.155607 2.18284V2.18284Z"
          fill="#99A1A7"
        />
      </svg>
    </button>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import TwPopoverShort from '@/components/tw-ui/popover/TwPopoverShort.vue'
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'

export default {
  name: 'EmailIcon',
  components: { TwPopoverShort },
  mixins: [ViewPanelShowMixin],
  methods: {
    ...mapActions({
      emailConfigs: 'profile/fetchEmailConfigs'
    }),
    handlerClick () {
      this.$emit('click')
    },
    showEmployeeProfile () {
      const id = this.currentActiveUserForSocket?.id
      console.warn('this.showEmployeeCard(this.currentActiveUserForSocket.id)', this.currentActiveUserForSocket.id)
      console.warn(this.$options.name, ' showEmployeeCard ', id)
      this.showPanel({
        component: 'employee-show',
        props: { id, presetTab: 'settings' },
        label: 'Пользователь'
      })
    }
  },
  computed: {
    ...mapGetters({
      currentActiveUserForSocket: 'profile/currentActiveUser',
      emailStatus: 'profile/getEmailStatus'
    }),
    emailStatusWorker () {
      let status = null
      if (this.emailStatus?.value === 'error') {
        status = false
      } else {
        status = true
      }
      return status
    }
  },
  watch: {
    currentActiveUserForSocket () {
      this.emailConfigs()
    }
  }
}
</script>
