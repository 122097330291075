<template>
  <div class="tw-font-manrope tw-text-main-sm2 tw-max-w-[100%] tw-px-[26px] tw-py-[18px] tw-bg-white tw-min-h-screen">
    <template v-if="isCardContentVisible">
      <!--   ПРЕДУПРЕЖДЕНИЯ   -->
      <div>
        <TwPanelInfoBlock
          v-for="(danger, index) in dangersList"
          :key="index"
          :type="danger.type"
          class="tw-mb-[6px]"
        >
          <template #title>
            {{ danger.title }}
          </template>
          <template #text>
            <div v-html="$Global.nl2br(danger.description)" />
          </template>
        </TwPanelInfoBlock>
      </div>
      <!--   ЗАГОЛОВОК   -->
      <TwPanelTitle v-if="!isIframe">
        <template #left>
          <div class="tw-text-h4 tw-font-bold">
            <div
              v-if="isNew"
              class="title text-muted"
            >
              Новое обращение
            </div>
            <div v-else-if="data">
              <span>
                Обращение
              </span>
              <span class="tw-text-an-flat-disabled-text tw-font-medium"> # {{ data.id }}</span>
            </div>
            <div v-else>
              ~
            </div>
          </div>
        </template>
        <template #right>
          <template v-if="isAdmin">
            <TwPanelActionButton
              color="gray"
              @click="onOpenIframe"
            >
              iFrame страница
            </TwPanelActionButton>
          </template>
          <!-- TODO debug permissions -->
          <MenuEntityActions
            v-if="permissions"
            :permissions="permissions"
            @delete="deleteCardItem"
          />
        </template>
      </TwPanelTitle>

      <TwPanelContent>
        <div class="tw-flex-1 tw-min-w-[400px] xl:tw-max-w-[500px]">
          <TwPanelContentBlock class="tw-mb-[6px]">
            <template #title>
              <div>
                Об обращении
              </div>
            </template>
            <template
              v-if="isAdmin"
              #action
            >
              <TwPanelContentBlockActionButton
                type="edit"
                :disabled="isNew || loading"
                @click="editMain = !editMain"
              >
                {{ editMain ? 'отменить':'изменить' }}
              </TwPanelContentBlockActionButton>
            </template>
            <div>
              <SupportMainForm
                v-if="editMain"
                :id="data ? data.id : 0"
                ref="EditMain"
                :default-form-fields="defaultFormFields"
                @create="onCreated"
                @update="onUpdated"
                @cancel="editMain = false"
              />
              <SupportMainShow
                v-else
                :dictionary="dictionary"
                :info="data"
                @refresh="refresh"
              />
            </div>
          </TwPanelContentBlock>
        </div>
        <div class="tw-flex-1 tw-min-w-[400px]">
          <SupportCommentBlock
            v-if="data && data.id"
            :task-id="data.id"
            :info="data"
            @update="updateComments"
          />
        </div>
      </TwPanelContent>
    </template>
    <!--
      Блок заполняющий пространство при отсутствуии контента
      для прижатия блока сохранения к нижней части страницы
    -->
    <div class="tw-flex tw-justify-center tw-items-center">
      <TwCircleLoader
        v-if="loading && !isCardContentVisible"
        width="50px"
        height="50px"
      />
      <div v-else-if="error">
        <PermissionDenied :text="error === 403 ? 'Недостаточно прав для просмотра!' : 'Ошибка соединения - Обратитесь к администратору!'" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import API from '@/Api/Support/Task'
import CardMixin from '@/mixins/CardMixin'
import DangersMixin from '@/mixins/DangersMixin'
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import MenuEntityActions from '@/components/core/ViewPanel/Card/entity-actions/menuEntityActions'
import PermissionDenied from '@/views/PermissionDenied'
import SupportMainForm from '@/views/Support/task/main/SupportMainForm'
import SupportMainShow from '@/views/Support/task/main/SupportMainShow'
import SupportCommentBlock from '@/views/Support/comment/SupportCommentBlock'
import TwPanelInfoBlock from '@/components/tw-ui/panels/TwPanelInfoBlock.vue'
import TwPanelTitle from '@/components/tw-ui/panels/TwPanelTitle.vue'
import TwPanelActionButton from '@/components/tw-ui/panels/TwPanelActionButton.vue'
import TwPanelContent from '@/components/tw-ui/panels/TwPanelContent.vue'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwPanelContentBlockActionButton from '@/components/tw-ui/panels/TwPanelContentBlockActionButton.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'

export default {
  name: 'SupportTaskCard',
  components: {
    TwCircleLoader,
    TwPanelContentBlockActionButton,
    TwPanelContentBlock,
    TwPanelContent,
    TwPanelActionButton,
    TwPanelTitle,
    TwPanelInfoBlock,
    SupportCommentBlock,
    SupportMainShow,
    SupportMainForm,
    MenuEntityActions,
    PermissionDenied
  },
  mixins: [CardMixin, ViewPanelShowMixin, DangersMixin],
  props: {
    presetData: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      isIframe: false,
      API: API
    }
  },
  methods: {
    ...mapActions({
      checkToken: 'profile/checkToken'
    }),
    ...mapMutations({
      setToken: 'profile/setToken'
    }),
    postFetch (data) {
      if (data.hasNew) {
        this.$emit('callback')
      }
    },
    updateComments () {
      this.refresh()
    },
    onOpenIframe () {
      if (this.data?.id) {
        const routeData = this.$router.resolve({
          name: 'iframe-support-by-id',
          params: { id: this.data?.id }
        })
        window.open(routeData.href, '_blank')
      } else {
        const routeData = this.$router.resolve({
          name: 'iframe-support'
        })
        window.open(routeData.href, '_blank')
      }
    }
  },
  computed: {
    ...mapGetters({
      isAuth: 'profile/isAuth',
      isAdmin: 'profile/isAdmin'
    }),
    defaultFormFields () {
      return {
        description: this.presetData
      }
    }
  },
  watch: {
    loading (newValue) {
      if (!newValue && this.$refs?.chronology?.fetch) {
        this.$refs.chronology.fetch()
      }
    }
  },
  mounted () {
    if (this.$route?.name?.includes('iframe-')) {
      this.isIframe = true
      // TODO ДОБАВИТЬ СТИЛИ ДЛЯ ПРОКРУТКИ для Iframe
      document.querySelector('body').style.overflow = 'auto'
      document.querySelector('body').style.height = '100%'
      document.querySelector('html').style.overflow = 'auto'
      document.querySelector('html').style.height = '100%'
      if (!this.isAuth) {
        const token = this.$route?.query?.token
        console.warn(this.$options.name, this.id, this.$route.query)
        if (token) {
          this.setToken({ token })
          this.$nextTick(() => {
            this.checkToken()
          })
        }
      }
    }
    if (this.isNew) {
      this.$nextTick(() => {
        this.fetchDangers()
      })
    }
  }
}
</script>
