import { AxiosResponse } from 'axios'
import HTTP from '@/Api/http'
import { AuthAxiosDefaultConfig } from '@/Api/Mortgage/index'

const path = '/mortgage/program/rate'

export default {
  // TODO Сервис на сайте со списком отсутствует. Просить у бэка
  getList (param?: object): Promise<AxiosResponse> {
    const $config = {
      ...AuthAxiosDefaultConfig,
      params: param
    }
    return HTTP.get(`${path}`, $config)
  },

  create (): Promise<AxiosResponse> {
    const $config = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.get(`${path}/create`, $config)
  },

  store (data: object): Promise<AxiosResponse> {
    const $config = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.post(`${path}`, data, $config)
  },

  edit (id: number): Promise<AxiosResponse> {
    const $config = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.get(`${path}/${id}`, $config)
  },

  update (id: number, data: object): Promise<AxiosResponse> {
    const $config = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.put(`${path}/${id}`, data, $config)
  },

  show (id: number): Promise<AxiosResponse> {
    const $config = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.get(`${path}/${id}`, $config)
  },

  delete (id: number): Promise<AxiosResponse> {
    const $config = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.delete(`${path}/${id}`, $config)
  },

  // Список банков \ программ \ ставок - для интерфейса массового редактирования ставок
  bankProgramRateList (): Promise<AxiosResponse> {
    const $config = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.get(`${path}/bankProgramRate`, $config)
  },

  // Урезанное изменение ставки
  updateSimple (id: number, data: object): Promise<AxiosResponse> {
    const $config = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.put(`${path}/${id}/simple`, data, $config)
  }
}
