<template>
  <div
    v-if="block && Array.isArray(block.fields) && block.fields.length"
    class="tw-flex tw-flex-wrap tw-gap-[32px]"
  >
    <div
      v-for="(columnFields, colIndex) in shareFieldsToCols"
      :key="colIndex"
      class="tw-min-w-[300px] tw-flex-1 tw-flex tw-flex-col tw-gap-[16px]"
    >
      <div
        v-for="field in columnFields"
        :key="field.key"
      >
        <TwFormGeneratorField
          :field="field"
          :value="formData[field.key]"
          :disabled="disabled"
          @input="$emit('input', $event)"
        />
      </div>
    </div>
  </div>
  <div
    v-else
    class="tw-text-center tw-text-an-flat-disabled-text tw-p-[12px]"
  >
    Отсутствуют поля
  </div>
</template>

<script>
import TwFormGeneratorField from '@/views/Company/UserSurveys/FormGenerator/TwFormGeneratorField.vue'

export default {
  name: 'TwFormGeneratorBlock',
  components: { TwFormGeneratorField },
  props: {
    block: {
      type: Object,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    shareFieldsToCols () {
      const countFields = this.block?.fields?.length || 0
      const countCols = this.block.cols || 1
      const countColFields = Math.ceil(countFields / countCols)
      const fields = (this.block?.fields || []).map((field, index) => {
        return {
          ...field,
          fieldNum: index + 1
        }
      }) || []
      // console.warn('----------', countFields, countCols, countColFields, fields)
      const result = []
      for (let col = 1; col <= countCols; col++) {
        result.push(fields.splice(0, countColFields))
      }
      // console.log('++++++++++++', result)
      return result
    }
  }
}
</script>
