<template>
  <div>
    <div class="tw-flex tw-flex-col tw-gap-[12px]">
      <div class="tw-mt-[12px] tw-flex tw-justify-between">
        <div class="tw-text-main-md tw-font-medium tw-text-an-flat-gray-4">
          Контакты:
        </div>
        <TwPanelContentBlockActionButton
          :disabled="!allowDepartmentList.filter(d =>!d.disabled).length"
          @click.prevent="addNewContact"
        >
          ДОБАВИТЬ НОВЫЙ КОНТАКТ
        </TwPanelContentBlockActionButton>
      </div>
      <div
        v-if="Array.isArray(value) && value.length"
        class=""
      >
        <div
          v-for="(item, index) in value"
          :key="index+'-row-tw'"
          class="tw-border tw-border-solid tw-rounded-sm tw-p-[10px] tw-mt-[12px] tw-flex tw-flex-col tw-gap-[12px]"
        >
          <div class="tw-flex tw-gap-[6px]">
            <TwSelect
              v-model="item.department"
              class="tw-flex-1"
              label="Отдел"
              placeholder="Не выбран"
              :dense="true"
              :disable-error-border="false"
              :persistent-label="true"
              :options="allowDepartmentList"
              :clearable="true"
              :error-text="!item.department ? 'Заполните поле' : ''"
              :disabled="false"
            />
            <button
              title="Удалить контакт"
              class="tw-ml-[6px] tw-mt-[21px] tw-text-an-flat-red-light hover:tw-text-an-flat-red-main"
              @click.prevent="removeContact(index)"
            >
              <svg
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4.67361 1.45139C3.67259 0.450377 2.13979 0.360212 1.25 1.25C0.360203 2.1398 0.450368 3.6726 1.45138 4.67362L5.27777 8.50001L1.45139 12.3264C0.450375 13.3274 0.36021 14.8602 1.25 15.75C2.13979 16.6398 3.6726 16.5496 4.67361 15.5486L8.5 11.7222L12.3264 15.5486C13.3274 16.5496 14.8602 16.6398 15.75 15.75C16.6398 14.8602 16.5496 13.3274 15.5486 12.3264L11.7222 8.50001L15.5486 4.67361C16.5496 3.67259 16.6398 2.13979 15.75 1.24999C14.8602 0.360203 13.3274 0.450368 12.3264 1.45138L8.5 5.27778L4.67361 1.45139Z"
                  fill="currentColor"
                />
              </svg>
            </button>
          </div>
          <div class="tw-flex tw-gap-[6px] tw-flex-wrap">
            <TwInputPhone
              v-model="item.phone"
              class="tw-flex-1 !tw-min-w-[200px]"
              :disabled="false"
              :disable-error-border="false"
              :show-label="true"
              :persistent-label="true"
              :dense="true"
              :error-text="!item.email && !item.phone ? 'Заполните поле' : ''"
            />
            <TwInputText
              v-model="item.email"
              class="tw-flex-1 !tw-min-w-[200px]"
              type="email"
              label="Email"
              placeholder="company@mail.ru"
              :disabled="false"
              :disable-error-border="false"
              :show-label="true"
              :persistent-label="true"
              :dense="true"
              :error-text="!item.email && !item.phone ? 'Заполните поле' : ''"
            />
          </div>
        </div>
      </div>
      <div
        v-else
        class="tw-text-an-flat-disabled-text tw-p-3 tw-text-center"
      >
        Не добавлены
      </div>
    </div>
  </div>
</template>

<script>
import companyAPI from '@/Api/Crm/Company'
import TwPanelContentBlockActionButton from '@/components/tw-ui/panels/TwPanelContentBlockActionButton.vue'
import TwSelect from '@/components/tw-ui/ui-kit/input/TwSelect.vue'
import TwInputPhone from '@/components/tw-ui/ui-kit/custom/TwInputPhone.vue'
import TwInputText from '@/components/tw-ui/ui-kit/input/TwInputText.vue'

export default {
  name: 'InputCompanyContacts',
  components: { TwInputText, TwInputPhone, TwSelect, TwPanelContentBlockActionButton },
  props: {
    value: {
      default: () => []
    },
    errorMessages: {
      default: () => []
    }
  },
  data () {
    return {
      loading: false,
      getDefaultContactItem: null,
      departmentList: []
    }
  },
  computed: {
    allowDepartmentList () {
      return this.departmentList.map(department => {
        const index = this.value.findIndex(contact => {
          return contact?.department === department?.value
        })
        department.disabled = index !== -1
        return department
      })
    }
  },
  mounted () {
    this.loading = true
    companyAPI.companyContactsCreate().then(({ data }) => {
      this.initData(data)
    }).catch().finally(() => {
      this.loading = false
    })
  },
  methods: {
    initData (initialData) {
      console.warn(initialData)
      const { data, dictionary } = initialData
      this.departmentList = dictionary?.departments || []
      const contactItem = {}
      for (const key in data) {
        if (key === 'department') {
          contactItem[key] = null // data[key]?.value || null
        } else {
          contactItem[key] = data[key]
        }
      }
      this.getDefaultContactItem = contactItem
      console.warn(this.getDefaultContactItem)
    },
    // changeContact (index, val) {
    //   console.log('changeContact: ', index, val)
    //   // const value = this.value
    //   // value[index].phone = val
    //   // this.onChange(value)
    // },
    // changeType (index, val) {
    //   console.log('changeType: ', index, val)
    //   // const value = this.value
    //   // value[index].phone = val
    //   // this.onChange(value)
    // },
    addNewContact () {
      let value = this.value
      if (!Array.isArray(value)) {
        value = []
      }
      value.push({ ...{}, ...this.getDefaultContactItem })
      this.onChange(value)
    },
    removeContact (index) {
      const value = this.value
      console.log('removeContact', index, value)
      value.splice(index, 1)
      this.onChange(value)
    },
    onChange (value) {
      this.$emit('input', value)
    }
  }
}
</script>
