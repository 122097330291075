<template>
  <div class="tw-font-manrope">
    <div
      v-if="loading"
      class="tw-p-[24px] tw-flex"
    >
      <TwCircleLoader class="tw-m-auto" />
    </div>
    <div
      v-else-if="Array.isArray(data) && data.length"
      class="tw-flex tw-flex-col tw-gap-[8px]"
    >
      <div
        v-for="item in data"
        :key="item.id"
        class="tw-group tw-bg-white tw-rounded-sm tw-px-[14px] tw-py-[10px] tw-flex tw-gap-[6px] tw-items-center"
      >
        <div class="tw-flex-1">
          {{ item.bank.label || item.bank.name }}
          <br>
          <span class="tw-text-an-flat-gray-4">Сумма:</span>
          {{ (item.sum || 0).toLocaleString('ru') }} руб.
          <span class="tw-ml-[12px] tw-text-an-flat-gray-4">Ставка:</span>
          {{ item.percent }}%
          <span class="tw-ml-[12px] tw-text-an-flat-gray-4">Срок:</span>
          {{ item.term }} лет
        </div>
        <div
          v-if="allowEdit"
          class="tw-invisible group-hover:tw-visible"
        >
          <TwPanelContentBlockActionButton
            :disabled="disabled || loading"
            type="edit"
            @click="onEdit(item)"
          >
            Изменить
          </TwPanelContentBlockActionButton>
          <br>
          <TwPanelContentBlockActionButton
            :disabled="disabled || loading"
            type="delete"
            @click="onDelete(item)"
          >
            Удалить
          </TwPanelContentBlockActionButton>
        </div>
      </div>
    </div>
    <div
      v-else-if="customList"
      class="tw-text-an-flat-gray-4 tw-text-center"
    >
      Одобрения не указаны в заявке по ипотеке
    </div>
    <div
      v-else
      class="tw-text-an-flat-red-main tw-text-center"
    >
      Внимание!
      <br>
      Одобрение банка обязательно для успешного завершения сделки
    </div>
  </div>
</template>

<script>
import DataListMixin from '@/mixins/DataListMixin'
import API from '@/Api/Crm/Deal/Mortgage/ApprovalBanksInfo/index'
import TwPanelContentBlockActionButton from '@/components/tw-ui/panels/TwPanelContentBlockActionButton.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'

export default {
  name: 'MortgageApprovedBanksList',
  components: { TwCircleLoader, TwPanelContentBlockActionButton },
  mixins: [DataListMixin],
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    dealId: {
      type: [String, Number],
      required: false
    },
    customList: {
      type: Array,
      default: null
    }
  },
  data () {
    return {
      API,
      defaultFilters: {
        deal: this.dealId
      }
    }
  },
  computed: {
    allowEdit () {
      return !this.customList
    },
    isCustomListShow () {
      return Array.isArray(this.customList)
    }
  },
  mounted () {
    if (this.isCustomListShow) {
      this.data = this.customList
    } else {
      this.fetch()
    }
  },
  methods: {
    onEdit (item) {
      this.$emit('on-edit-item', item.id)
    },
    onDelete (item) {
      this.$emit('on-delete-item', item.id)
    }
  }
}
</script>
