<template>
  <div
    v-show="loading"
    class="a-circle-loader"
    :style="{ width: `${size}px`, height: `${size}px` }"
  >
    <div
      class="clip"
      :style="clipStyle"
      style="background: transparent; border-style: solid; border-radius: 100%;"
    />
    <div :style="'color:'+color">
      <slot name="text" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ACircleLoader',
  props: {
    loading: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: '#6495ed'
    },
    size: {
      type: Number,
      default: 30
    },
    borderWidth: {
      type: Number,
      default: 2
    }
  },
  computed: {
    clipStyle () {
      return {
        width: this.size + 'px',
        height: this.size + 'px',
        borderWidth: this.borderWidth + 'px',
        borderColor: this.color + ' ' + this.color + ' transparent'
      }
    }
  }
}
</script>

<style>
.a-circle-loader {
  text-align: center;
}
.a-circle-loader > .clip {
  display: inline-block;
  animation: clipDelay 750ms 0s infinite linear;
  animation-fill-mode: both;
}
@keyframes clipDelay {
  0% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(180deg) scale(0.8);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
}
</style>
