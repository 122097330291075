import { render, staticRenderFns } from "./BuilderHousingConsumerCooperativeInfo.vue?vue&type=template&id=54bdba89&scoped=true"
import script from "./BuilderHousingConsumerCooperativeInfo.vue?vue&type=script&lang=js"
export * from "./BuilderHousingConsumerCooperativeInfo.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54bdba89",
  null
  
)

export default component.exports