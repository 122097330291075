<template>
  <div class="tw-font-manrope tw-flex tw-flex-col tw-gap-[16px]">
    <div style="display: none">
      {{ isEdited }}
    </div>

    <TwSelectRelations
      v-model="form.companyId"
      :multi="false"
      :dense="true"
      label="Застройщик"
      list-label="Найти застройщика"
      relation-name="developer"
      :persistent-label="true"
      placeholder="Не выбран"
      :disabled="formProcessing"
      class="tw-mt-[25px]"
    />

    <TwTextarea
      v-model="form.fileLink"
      rows="4"
      resize="y"
      placeholder="Вставьте ссылку"
      label="Ссылка на файл"
      :disabled="formProcessing"
      :show-label="true"
      :dense="false"
      :persistent-label="true"
      :error-text="$v.form.fileLink.$invalid ? 'Заполните поле':''"
    />

    <TwSelect
      v-model="form.parser"
      label="Тип парсера"
      placeholder="Выберите парсер"
      :multi="false"
      :dense="true"
      :persistent-label="true"
      :options="dictionary.parser"
      :disabled="formProcessing"
      :error-text="$v.form.parser.$invalid ? 'Заполните поле':''"
    />

    <TwTextarea
      v-model="form.comment"
      rows="5"
      placeholder="Коммент"
      :disabled="formProcessing"
      :show-label="true"
      :persistent-label="true"
      class="tw-mt-[15px]"
    />

    <TwCheckBox
      v-model="form.active"
      :disabled="formProcessing"
      label="Активность"
    />

    <TwCardSimpleFormSaveBlock
      class="tw-mt-[15px]"
      :loading="formProcessing"
      :cancel-confirm="isEdited"
      :is-sticky="true"
      :disable-success="!isEdited || formProcessing"
      :disable-cancel="formProcessing"
      :visible="true"
      @success="handleSubmit"
      @cancel="handleCancel"
    />
  </div>
</template>

<script>
import { API } from '@/Api/BuilderImporter'
import { required } from 'vuelidate/lib/validators'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import { RouteKeys } from '@/router/types'
import TwTextarea from '@/components/tw-ui/ui-kit/input/TwTextarea.vue'
import TwSelect from '@/components/tw-ui/ui-kit/input/TwSelect.vue'
import TwSelectRelations from '@/components/tw-ui/ui-kit/input/select-relations/TwSelectRelations.vue'
import TwCheckBox from '@/components/tw-ui/ui-kit/input/TwCheckBox.vue'
import TwCardSimpleFormSaveBlock from '@/components/tw-ui/forms/TwCardSimpleFormSaveBlock.vue'

export default {
  name: 'ImportBuilderFileMainForm',
  components: {
    TwCardSimpleFormSaveBlock,
    TwCheckBox,
    TwSelectRelations,
    TwSelect,
    TwTextarea
  },
  mixins: [FormCreateEditMixin],
  props: {
    disableRedirect: {
      type: Boolean,
      default: false
    },
    defaultFormFields: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      API: API.ImportBuilderFile,
      form: {
        active: null,
        companyId: null,
        fileLink: null,
        parser: null
      }
    }
  },
  validations: {
    form: {
      fileLink: {
        required
      },
      parser: {
        required
      }
    }
  },
  methods: {
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          const adaperMap = {
            // from(show): to(send)
            company: 'companyId'
          }
          for (const key in this.preset) {
            if (adaperMap[key] !== undefined) {
              if (adaperMap[key].indexOf('Id') !== -1) {
                this.$set(this.form, adaperMap[key], (this.preset[key] && this.preset[key].id) || this.defaultFormFields[adaperMap[key]])
              }
            } else {
              this.$set(this.form, key, this.preset[key])
            }
          }
          // Заполнение застройщика заданными через параметры
          if (this.formType === 'create' && this.defaultFormFields?.companyId) {
            this.form.companyId = this.preset?.companyId || this.defaultFormFields?.companyId
          }
        }
        resolve()
      })
    },
    handleSubmit () {
      this.$v.$reset()
      this.$v.$touch()
      // console.log('handleSubmit ', this.$v)
      // console.log('$invalid ', this.$v.form.$invalid)
      if (!this.$v.$invalid) {
        if (this.formProcessing === true) {
          return
        }

        this.formProcessing = true
        const request = this.formType === 'create'
          ? this.API.store(this.form)
          : this.API.update(this.targetId, this.form)

        request.then(({ data }) => {
          if (this.formType === 'create') {
            this.$emit('create', data.data)
            // если текущий путь равен пути создания то редирект в просмотр
            if (this.$route.name === `${RouteKeys.lead}-create`) {
              this.$router.replace({
                name: `${RouteKeys.lead}-show`,
                params: { id: data.data.id }
              })
            }
          } else {
            this.$emit('update', data.data)
          }
        }).finally(() => {
          this.formProcessing = false
        })
      }
    }
  }
}
</script>
