<template>
  <div class="tw-p-[24px]">
    <div v-if="isReopenForm">
      <TwTextarea
        v-model="reopenFormData.comment"
        rows="5"
        resize="y"
        autocomplete="off"
        persistent-label
        placeholder="Комментарий"
        :disabled="formProcessing"
        :show-label="true"
        :error-text="reopenFormData.comment ? '':'Обязательное поле'"
      />
      <div class="tw-flex tw-gap-[18px] tw-justify-center tw-my-[12px]">
        <button
          :disabled="formProcessing"
          class="tw-uppercase tw-bg-an-flat-disabled-input-text tw-text-white tw-px-[18px] tw-py-[7px] tw-rounded-sm tw-border tw-border-solid enabled:hover:tw-text-an-flat-black-main enabled:hover:tw-bg-an-flat-bg-main disabled:tw-opacity-50"
          @click="isReopenForm = false"
        >
          Отмена
        </button>
        <button
          :disabled="formProcessing || !reopenFormData.comment"
          class="tw-uppercase tw-bg-an-flat-green tw-text-white tw-px-[18px] tw-py-[7px] tw-rounded-sm tw-border tw-border-solid enabled:hover:tw-text-an-flat-black-main enabled:hover:tw-bg-an-flat-bg-main disabled:tw-opacity-50"
          @click="sendReopen"
        >
          Отправить
        </button>
      </div>
    </div>
    <div
      v-else
      class="tw-flex tw-gap-[18px] tw-justify-center"
    >
      <button
        :disabled="formProcessing"
        class="tw-uppercase tw-bg-an-flat-black-table tw-text-white tw-px-[18px] tw-py-[7px] tw-rounded-sm tw-border tw-border-solid enabled:hover:tw-text-an-flat-black-main enabled:hover:tw-bg-an-flat-bg-main disabled:tw-opacity-50"
        @click="onSelectAction('reopen')"
      >
        Открыть задачу повторно
      </button>
      <button
        :disabled="formProcessing"
        class="tw-uppercase tw-bg-an-flat-green tw-text-white tw-px-[18px] tw-py-[7px] tw-rounded-sm tw-border tw-border-solid enabled:hover:tw-text-an-flat-black-main enabled:hover:tw-bg-an-flat-bg-main disabled:tw-opacity-50"
        @click="onSelectAction('confirm')"
      >
        Подтвердить выполнение
      </button>
    </div>
  </div>
</template>

<script>
import API from '@/Api/Support/Task'
import TwTextarea from '@/components/tw-ui/ui-kit/input/TwTextarea.vue'

export default {
  name: 'SupportTaskStatusForm',
  components: { TwTextarea },
  props: {
    taskId: {
      type: [Number, String],
      required: true
    },
    info: {
      type: Object,
      default: () => {
        return {}
      }
    },
    dictionary: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      API: API,
      reopenFormData: {
        comment: ''
      },
      formProcessing: false,
      requireRules: [
        v => !!v || 'Поле обязательное'
      ],
      isReopenForm: false
    }
  },
  methods: {
    onSelectAction (type) {
      console.warn({ type })
      if (type === 'confirm') {
        this.sendConfirm()
      } else if (type === 'reopen') {
        this.isReopenForm = true
      } else {
        console.error(this.$options.name, 'onSelectAction - BAD TYPE ', { type })
      }
    },
    sendConfirm () {
      if (this.formProcessing === true) return
      this.formProcessing = true
      this.API.confirm(this.taskId, {}).then(() => {
        // console.warn('sendConfirm', data)
        this.$emit('update')
        this.refresh()
      }).catch((error) => {
        console.error(this.$options.name, error)
      }).finally(() => {
        this.formProcessing = false
      })
    },
    sendReopen () {
      if (this.reopenFormData.comment) {
        // console.warn('sendReopen')
        if (this.formProcessing === true) return
        this.formProcessing = true
        this.API.reopen(this.taskId, this.reopenFormData).then(() => {
          // console.warn('sendReopen', data)
          this.reopenFormData.comment = ''
          this.isReopenForm = false
          this.$emit('update')
          this.refresh()
        }).catch((error) => {
          console.error(this.$options.name, error)
        }).finally(() => {
          this.formProcessing = false
        })
      }
    }
  }
}
</script>
