import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import routes from '@/router/routes'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes: routes,
  scrollBehavior (to, from, savedPosition) {
    return savedPosition || { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  // console.warn(to, from, store)
  next()
})

router.afterEach((to, from) => {
  // console.warn('--->', from, to)
  document.title = to.meta?.name ? `${to.meta.name} - REALTY.CRM` : 'REALTY.CRM'
  store.commit('setTitle', to.meta?.name)

  // при смене маршрута отменим показ ошибки на странице - !если она была
  if (store.getters.getPageErrorStatus) {
    store.commit('SET_ERROR_PAGE', false)
  }

  store.commit('setPrevPage', {
    path: from.fullPath,
    name: from.name,
    props: from.params,
    query: from.query
  })

  const breadcrumbs = Array.of()
  to.matched.forEach((item:any) => {
    breadcrumbs.push({
      props: item.props,
      text: item.meta && item.meta.name ? item.meta.name : item.name,
      disabled: to.name === item.meta.name || to.name === item.name || (item.redirect?.name && to.name === item.redirect.name),
      href: item.path,
      isRedirect: !!item.redirect,
      to: { name: item.name }
    })
  })
  store.commit('SET_BREADCRUMBS', breadcrumbs)

  // Если переход с главной то считаю это инициализацией фильтров
  if (from.path && from.path === '/' && to.path !== '/') {
    // Проверяю есть ли у пользователя право на просмотр Режима управления
    if (store.getters['profile/isManagement'] || store.getters['profile/isAdmin']) {
      // сверяю целевой путь с доступными в Режиму управления
      if (Array.isArray(store.getters['mainMenu/menu'].management) && store.getters['mainMenu/menu'].management.length) {
        for (const item of store.getters['mainMenu/menu'].management) {
          // если схожий путь есть переключаю меня в режим управления
          if (to.path.includes(item.path)) {
            store.commit('mainMenu/SET_MANAGEMENT_MENU_STATUS', true)
            break
          }
        }
      }
    } else {
      store.commit('mainMenu/SET_MANAGEMENT_MENU_STATUS', false)
    }
  }
})

export default router
