<template>
  <PageViewDefaultWrapper
    ref="wrapper"
    header-class="mb-2"
    :permission-denied="permissionDenied"
    relative
  >
    <template>
      <div
        v-if="loading"
        class="tw-p-2 tw-flex"
      >
        <TwCircleLoader class="tw-m-auto" />
      </div>
      <div v-else>
        <div class="tw-mb-[18px]">
          <TwInputSwitch
            :value="onlyActiveItems"
            :disabled="loading"
            @input="changeActiveFilter"
          >
            <template #label>
              <span class="tw-relative tw-z-10 tw-text-an-flat-grey-4 tw-font-manrope tw-font-semibold tw-text-[14px]">
                Показать только активные предложения
              </span>
            </template>
          </TwInputSwitch>
        </div>
        <table v-if="Array.isArray(data) && data.length">
          <tr
            v-for="item in data"
            :key="item.id"
            :class="item.id === selectedId ? 'tw-cursor-default tw-bg-an-flat-green/10':'tw-cursor-pointer hover:tw-bg-an-flat-green/20'"
            @click.stop="onClickToItem(item)"
          >
            <td class="tw-p-[9px] tw-rounded-tl-sm tw-rounded-bl-sm">
              <div
                v-if="item.type"
                class="tw-text-an-flat-dark-blue-link tw-font-semibold"
              >
                {{ item.type.label }}
              </div>
              {{ item.crmDescription | textSubString(100) }}
            </td>
            <td class="tw-p-[9px] tw-rounded-tr-sm tw-rounded-br-sm tw-text-right tw-whitespace-nowrap">
              <div
                v-if="$Global.DateFormat.hasDateExpiration(item.dateTo) || !item.publish"
                class="tw-text-an-flat-red-main"
              >
                <span class="tw-font-medium">
                  Не опубликовано
                </span>
                <div
                  v-if="$Global.DateFormat.hasDateExpiration(item.dateTo)"
                  class="tw-text-an-flat-red-main"
                >
                  <small>
                    Истёк срок действия
                  </small>
                </div>
                <div
                  v-if="!item.publish"
                  class="tw-text-an-flat-gray-4"
                >
                  <small>
                    Активность отключена
                  </small>
                </div>
              </div>
              <div
                v-else
                class="tw-text-an-flat-green-btn-bg"
              >
                Опубликовано
              </div>
              <div
                v-if="item.dateTo"
                class=""
              >
                <small>Действует до</small> <b>{{ (new Date(item.dateTo)).toLocaleDateString() }}</b>
              </div>
            </td>
          </tr>
        </table>
        <div
          v-else
          class="tw-text-center tw-text-an-flat-disabled-text"
        >
          Предложения отсутствуют
        </div>
      </div>
    </template>
    <template #footer>
      <TwPagination
        v-if="pageCount > 1"
        :disabled="loading"
        :value="pagination.current_page"
        :length="pagination.last_page"
        @change="changePagination"
      />
    </template>
  </PageViewDefaultWrapper>
</template>

<script>
import { API } from '@/Api/ObjectStorage'
import PageViewDefaultWrapper from '@/components/core/ViewDefault/PageViewDefaultWrapper'
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import { mapGetters } from 'vuex'
import TwPagination from '@/components/tw-ui/ui-kit/table/TwPagination.vue'
import TwInputSwitch from '@/components/tw-ui/ui-kit/input/TwInputSwitch.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'

export default {
  name: 'BuilderStockList',
  components: { TwInputSwitch, TwPagination, PageViewDefaultWrapper, TwCircleLoader },
  mixins: [ViewPanelShowMixin],
  props: {
    builderId: {
      type: [Number, String, null],
      default: null
    },
    selectedId: {
      type: [Number, String, null],
      default: null
    }
  },
  data () {
    return {
      onlyActiveItems: false,
      API: API.BuilderStock,
      defaultFilters: {
        builderId: [this.builderId],
        publish: undefined
      },
      loading: false,
      permissionDenied: false,

      data: [],
      pagination: {},
      loadingText: 'Загрузка... Пожалуйста подождите',
      noDataText: 'Нет данных',
      noResultsText: 'Не найдено',
      itemsPerPage: 25
    }
  },
  methods: {
    changeActiveFilter (eventData) {
      if (eventData) {
        this.onlyActiveItems = true
        this.defaultFilters.publish = true
      } else {
        this.onlyActiveItems = false
        this.defaultFilters.publish = undefined
      }
      this.$nextTick(() => {
        this.fetch()
      })
    },
    onClickToItem (item) {
      console.warn('onClickToItem', item)
      this.$emit('selectItem', item)
    },
    changePagination (targetPage) {
      this.fetch({
        page: targetPage
      })
    },
    fetch (params = {}) {
      if (this.loading) {
        return
      }

      if (!this.API) {
        throw Error('not set API')
      }

      this.permissionDenied = false
      this.loading = true
      // eslint-disable-next-line no-param-reassign
      params = { ...params, ...this.defaultFilters }

      this.API.getList(params)
        .then((response) => {
          this.data = response.data.data
          if (response.data.meta) {
            this.pagination = response.data.meta
            this.itemsPerPage = response.data.meta.per_page || 25
          }
        })
        .catch((error) => {
          // обработка отсутствия прав на просмотр
          if (error?.response?.status === 403) {
            this.permissionDenied = true
          }
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  computed: {
    ...mapGetters({
      getFiltersByKey: 'filters/getFiltersByKey',
      isAdmin: 'profile/isAdmin'
    }),
    pageCount () {
      return Math.ceil(this.pagination.total / this.itemsPerPage)
    }
  },
  mounted () {
    this.fetch()
  }
}
</script>
