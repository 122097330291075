/* eslint-disable no-console */

import { register } from 'register-service-worker'

// РЕгистрировать воркер по условию
if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
          'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered (registration) {
      console.log('Service worker has been registered.', caches, registration)
      document.dispatchEvent(
        new CustomEvent('swRegistered', { detail: registration })
      )
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound (registration) {
      console.log('updatefound - найдены обновления для приложения', registration, caches)
    },
    updated (registration) {
      console.log('updated - обновлено', registration, caches)
      document.dispatchEvent(
        new CustomEvent('swUpdated', { detail: registration })
      )
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.log('error - кеш: ', caches)
      console.error('Error during service worker registration:', error)
    }
  })
}
