<template>
  <PageViewDefaultWrapper
    ref="wrapper"
    header-class="mb-2"
    :permission-denied="permissionDenied"
    relative
  >
    <TwTableBase
      schema-key="RealtyComplexBuildingList"
      class="tw-text-main-sm"
      tbody-row-classes="tw-cursor-pointer"
      :items="data"
      :headers="headers"
      :table-fixed="false"
      :loading="loading"
      :pagination="pagination"
      :table-columns-management="true"
      @headersUpdate="headers = $event"
      @row-click="clickToObject"
      @changePagination="changePagination"
    >
      <template #item.type="{ item }">
        <div
          v-if="item.type"
          class="tw-font-medium tw-flex tw-items-center tw-gap-[10px]"
        >
          <div
            v-if="Array.isArray(item.childTypes) && item.childTypes.length"
            class="tw-flex"
          >
            <div
              v-for="child in item.childTypes"
              :key="child.value"
              class="tw-flex tw-items-center tw-gap-[10px]"
            >
              <NbTypeIcon :type="child.value" />
              {{ child.label }}
            </div>
          </div>
          <div
            v-else
            class="tw-flex tw-items-center tw-gap-[10px]"
          >
            <NbTypeIcon :type="item.type.value" />
            {{ item.type.label }}
          </div>
        </div>
        <div v-else>
          -
        </div>
      </template>
      <template #item.constructionMaterial="{ item }">
        {{ item.constructionMaterial || '~' }}
      </template>
      <template #item.dateComplete="{ item }">
        <div v-if="item.dateComplete">
          {{ $Global.DateFormat.DateShort(item.dateComplete, true) }}
        </div>
        <div v-else>
          -
        </div>
      </template>
      <template #item.completed="{ item }">
        <div
          v-if="item.completed"
          class="tw-text-an-flat-green-btn-bg-active tw-font-bold"
        >
          Сдан
        </div>
        <div v-else>
          -
        </div>
      </template>
    </TwTableBase>
  </PageViewDefaultWrapper>
</template>

<script>
import { API } from '@/Api/ObjectStorage'
import PageViewDefaultWrapper from '@/components/core/ViewDefault/PageViewDefaultWrapper'
import { mapGetters, mapActions } from 'vuex'
import TwTableBase from '@/components/tw-ui/table/TwTableBase.vue'
import NbTypeIcon from '@/views/Newbuildings/Editor/NbTypeIcon.vue'

const headers = [
  {
    text: 'Тип объекта',
    dataKey: 'type',
    sortable: false,
    colStyle: 'width: auto',
    align: 'left',
    thClass: 'tw-text-main-sm2 tw-text-left tw-font-semibold tw-whitespace-nowrap tw-px-[12px] tw-py-[8px] tw-bg-an-flat-gray-9',
    tdClass: ' tw-border tw-border-an-flat-gray-7 tw-p-[12px]'
  },
  {
    text: 'Номер объекта',
    dataKey: 'addressNumber',
    sortable: false,
    colStyle: 'width: 50px',
    thClass: 'tw-text-main-sm2 tw-text-left tw-font-semibold tw-whitespace-nowrap tw-px-[12px] tw-py-[8px] tw-bg-an-flat-gray-9',
    tdClass: 'tw-border tw-border-an-flat-gray-7 tw-p-[12px]'
  },
  {
    text: 'Материал',
    dataKey: 'constructionMaterial',
    sortable: false,
    colStyle: 'width: auto',
    thClass: 'tw-text-main-sm2 tw-text-left tw-font-semibold tw-whitespace-nowrap tw-px-[12px] tw-py-[8px] tw-bg-an-flat-gray-9',
    tdClass: 'tw-border tw-border-an-flat-gray-7 tw-p-[12px] tw-whitespace-nowrap'
  },
  {
    text: 'Сдан',
    dataKey: 'completed',
    sortable: false,
    align: 'center',
    colStyle: 'width: 50px;',
    thClass: 'tw-text-main-sm2 tw-text-left tw-font-semibold tw-whitespace-nowrap tw-px-[12px] tw-py-[8px] tw-bg-an-flat-gray-9',
    tdClass: 'tw-border tw-border-an-flat-gray-7 tw-p-[12px] tw-whitespace-nowrap'
  },
  {
    text: 'Дата сдачи',
    dataKey: 'dateComplete',
    sortable: false,
    colStyle: 'width: 180px;',
    thClass: 'tw-text-main-sm2 tw-text-left tw-font-semibold tw-whitespace-nowrap tw-px-[12px] tw-py-[8px] tw-bg-an-flat-gray-9',
    tdClass: 'tw-text-center tw-border tw-border-an-flat-gray-7 tw-p-[12px] tw-whitespace-nowrap'
  }
]

export default {
  name: 'RealtyComplexBuildingList',
  components: { NbTypeIcon, TwTableBase, PageViewDefaultWrapper },
  props: {
    complexId: {
      type: [Number, String, null],
      default: null
    },
    complexType: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      API: API.RealtyNewBuildings,
      loading: false,
      permissionDenied: false,
      headers,

      data: [],
      pagination: {},
      loadingText: 'Загрузка... Пожалуйста подождите',
      noDataText: 'Нет данных',
      noResultsText: 'Не найдено',
      itemsPerPage: 25
    }
  },
  methods: {
    ...mapActions({
      showPanel: 'viewPanels/showPanel'
    }),
    clickToObject (item) {
      this.showPanel({
        label: '',
        component: 'nb-editor-object',
        props: {
          id: item.id,
          type: item.type.value,
          complexId: this.complexId,
          presetTabEdit: true
        },
        callback: () => {
          console.warn(this.$options.name, '- showPanel')
          this.refresh()
        }
      })
    },
    changePagination (eventData) {
      this.fetch({
        ...eventData
      })
    },
    // обновление списка объектов из локальных параметров компонента, с сохранением пагинации
    refresh () {
      this.fetch({
        page: this.pagination?.current_page || 1
      })
    },
    fetch (params = {}) {
      if (this.loading) {
        return
      }

      if (!this.API) {
        throw Error('not set API')
      }

      this.permissionDenied = false
      this.loading = true
      params.limit = 999

      this.API.objects(this.complexId, params)
        .then((response) => {
          this.data = response.data.data
          if (response.data.meta) {
            this.pagination = response.data.meta
            this.itemsPerPage = response.data.meta.per_page || 25
          }
          // SENDING HOUSE DATA TO THE EDITOR
          this.$emit('updateComplexList', response.data.data)
        })
        .catch((error) => {
          // обработка отсутствия прав на просмотр
          if (error?.response?.status === 403) {
            this.permissionDenied = true
          }
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'profile/isAdmin'
    })
  },
  mounted () {
    this.fetch()
  }
}
</script>
