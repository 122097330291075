<template>
  <TwPanelContentBlock>
    <template #title>
      <div>
        Документ-основание
      </div>
    </template>
    <template #action>
      <TwPanelContentBlockActionButton
        v-if="editBlock && !!info && !!info.contract"
        type="edit"
        :disabled="disabled"
        @click="setUnsignedContract(info.contract)"
      >
        Обнулить договор
      </TwPanelContentBlockActionButton>
      <TwPanelContentBlockActionButton
        :disabled="disabled"
        :type="editBlock ? 'edit':'add'"
        @click="editBlock = !editBlock"
      >
        {{ editBlock ? 'отменить': 'изменить' }}
      </TwPanelContentBlockActionButton>
    </template>
    <div
      v-if="loading"
      class="tw-p-[20px] tw-flex"
    >
      <TwCircleLoader class="tw-m-auto" />
    </div>
    <div v-else>
      <template v-if="editBlock">
        <div>
          <DealDocumentForm
            :id="editSignRelationId"
            dense-fields
            :deal-id="dealId"
            :person-id="personId"
            @create="refresh"
            @update="refresh"
            @cancel="refresh"
          />
        </div>
      </template>
      <!-- SHOW -->
      <DealDocumentShow
        v-else-if="info && !editBlock"
        :info="info"
        :dictionary="dictionary"
      />
      <!-- NONE -->
      <template v-else>
        <div class="text-muted text-center">
          Нет информации
        </div>
      </template>
    </div>
  </TwPanelContentBlock>
</template>

<script>
import dealContractAPI from '@/Api/Crm/Deal/Contract'
import dealDocumentAPI from '@/Api/Crm/Deal/DealDocument'
import DealDocumentForm from '@/views/Deal/TypeViews/mortgage/dealDocument/DealDocumentForm.vue'
import DealDocumentShow from '@/views/Deal/TypeViews/mortgage/dealDocument/DealDocumentShow.vue'
import { mapGetters } from 'vuex'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwPanelContentBlockActionButton from '@/components/tw-ui/panels/TwPanelContentBlockActionButton.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'

export default {
  name: 'DealDocumentBlock',
  components: {
    TwCircleLoader,
    TwPanelContentBlockActionButton,
    TwPanelContentBlock,
    DealDocumentShow,
    DealDocumentForm
  },
  props: {
    permissions: {
      type: Object,
      default: () => {
        return {}
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    dealId: {
      type: [String, Number],
      required: true
    },
    personId: {
      type: [String, Number],
      required: true
    },
    dealType: {
      type: [String, Object],
      required: true
    },
    dealSubTypeValue: {
      default: null
    }
  },
  data () {
    return {
      loading: false,
      editBlock: false,
      editSignRelationId: 0, // Синтетический ИД 0 или 1 - ЕСТЬ или НЕТ - запрос получения данных делается по dealID
      info: null, // данные для просмотра
      dictionary: null // словари
    }
  },
  methods: {
    setUnsignedContract (contract) {
      // подтверждение перед обнулением
      if (window.confirm(`Подтвердите обнуление информации по договору: ${contract?.contractNum || ''}`)) {
        this.editBlock = false
        dealContractAPI.setUnsigned(contract.id).finally(() => {
          this.$emit('refresh')
        })
      }
    },
    refresh () {
      this.editBlock = false
      this.initContractData()
      this.$emit('refresh')
    },
    initContractData () {
      if (this.loading) {
        return
      }
      this.loading = true
      dealDocumentAPI.show(this.dealId)
        .then(({ data }) => {
          console.warn(this.$options.name, data)
          this.editSignRelationId = data?.data ? 1 : 0
          this.info = data?.data || null
          this.dictionary = data?.dictionary || null
        })
        .catch((error) => {
          console.error(this.$options.name, error)
          this.editSignRelationId = 0
          this.info = null
          this.dictionary = null
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'profile/isAdmin'
    })
  },
  mounted () {
    this.initContractData()
  }
}
</script>
