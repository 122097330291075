<template>
  <div>
    <button
      :disabled="!isDropDown"
      class="tw-flex tw-justify-between tw-w-[100%]"
      @click="active = !active"
    >
      <b class="tw-block tw-mb-[10px]">{{ title }}</b>
      <div v-if="isDropDown">
        <span
          v-show="active"
          class="tw-block tw-rotate-180"
        >
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 10.2979L9 7.79785L6 10.2979"
              stroke="#545961"
              stroke-width="2"
              stroke-linecap="round"
            />
          </svg>
        </span>
        <span
          v-show="!active"
          class="tw-block tw-rotate-0"
        >
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 10.2979L9 7.79785L6 10.2979"
              stroke="#545961"
              stroke-width="2"
              stroke-linecap="round"
            />
          </svg>
        </span>
      </div>
    </button>
    <div
      v-show="active"
      :class="[isDropDown ? 'tw-pt-[22px] tw-border-t tw-border-[#ECECED] tw-mt-[10px]' : '']"
    >
      <template v-if="Array.isArray(files) && files.length">
        <template v-if="viewType === 'grid'">
          <ul
            v-if="Array.isArray(imageFiles) && imageFiles.length"
            class="tw-flex tw-flex-wrap tw-mb-[20px]"
          >
            <li
              v-for="(file, index) in imageFiles"
              :key="index"
              class="tw-mb-[10px]"
            >
              <div class="tw-mr-[11px] ">
                <img
                  class="tw-block tw-h-[90px] tw-w-[86px] tw-mb-[8px] tw-bg-an-flat-bg-main tw-cursor-zoom-in"
                  :src="getFileResizeByKey(file.fileServer, 'default')"
                  :alt="file.fileServer.name"
                  @click="viewImages(imageFiles, index)"
                  @error="imageLoadError(index)"
                >
                <div class="tw-flex tw-items-center tw-justify-between">
                  <span class="tw-block tw-text-an-flat-not-active-input tw-truncate">{{ $Global.formatBytes(file.fileServer.size) }}</span>
                  <div
                    v-if="hasDownload"
                    class="tw-ml-auto"
                  >
                    <button
                      class="tw-p-[8px] tw-rounded-sm"
                      @click.stop="download(file.fileServer)"
                    >
                      <svg
                        width="13"
                        height="13"
                        viewBox="0 0 13 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_769_149)">
                          <path
                            d="M10.2083 13H2.91666C2.13764 13 1.40521 12.6995 0.854287 12.1538C0.30339 11.6082 0 10.8827 0 10.1111V9.38884C0 8.98995 0.326442 8.66659 0.729171 8.66659C1.1319 8.66659 1.45834 8.98995 1.45834 9.38884V10.1111C1.45834 10.4969 1.61005 10.8596 1.88545 11.1324C2.16092 11.4053 2.52712 11.5555 2.91666 11.5555H10.2083C10.5978 11.5555 10.964 11.4053 11.2395 11.1324C11.5149 10.8596 11.6667 10.4969 11.6667 10.1111V9.38884C11.6667 8.98995 11.9931 8.66659 12.3958 8.66659C12.7985 8.66659 13.125 8.98995 13.125 9.38884V10.1111C13.125 10.8827 12.8216 11.6081 12.2707 12.1538C11.7198 12.6995 10.9873 13 10.2083 13ZM6.56249 10.1111C6.46162 10.1111 6.36559 10.0908 6.27823 10.0541C6.19672 10.02 6.12012 9.97047 6.05311 9.90565C6.05308 9.90563 6.05308 9.90561 6.05306 9.90561C6.05257 9.90515 6.05209 9.90467 6.05161 9.90419C6.05148 9.90409 6.05133 9.90392 6.0512 9.90379C6.05079 9.90344 6.05043 9.90306 6.05005 9.90268C6.0498 9.90243 6.04957 9.90222 6.04931 9.90195C6.04906 9.90169 6.04873 9.90137 6.0485 9.90117C6.04799 9.90066 6.04743 9.90011 6.04692 9.8996L3.13024 7.01067C2.84549 6.72864 2.84549 6.27134 3.13024 5.98928C3.41498 5.70725 3.8767 5.70722 4.16145 5.98928L5.83334 7.64525V0.722226C5.83332 0.323333 6.15976 0 6.56249 0C6.96522 0 7.29168 0.323333 7.29168 0.722226V7.64523L8.96355 5.98928C9.24827 5.70725 9.71004 5.70725 9.99476 5.98928C10.2795 6.27131 10.2795 6.72864 9.99476 7.01067L7.07808 9.89955C7.07757 9.90005 7.07701 9.90061 7.0765 9.90111C7.07622 9.90137 7.07591 9.90169 7.07569 9.9019C7.07543 9.90217 7.0752 9.90238 7.07495 9.90263C7.07459 9.90303 7.07418 9.90339 7.07383 9.90374C7.0737 9.90386 7.07352 9.90404 7.07339 9.90414C7.07294 9.90462 7.07245 9.9051 7.07197 9.90555C7.07194 9.90555 7.07194 9.90558 7.07192 9.90561C7.06389 9.91335 7.05577 9.92087 7.04746 9.92818C6.98641 9.98212 6.9184 10.0242 6.84649 10.0542C6.84624 10.0543 6.84604 10.0544 6.84578 10.0545C6.8455 10.0546 6.84527 10.0548 6.84499 10.0548C6.75808 10.0911 6.66267 10.1111 6.56249 10.1111Z"
                            fill="#34363B"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_769_149">
                            <rect
                              width="13.125"
                              height="13"
                              fill="white"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <ul
            v-if="Array.isArray(otherFiles) && otherFiles.length"
            class="tw-flex tw-flex-wrap tw-gap-[12px]"
          >
            <li
              v-for="(file, index) in otherFiles"
              :key="index"
              class="tw-flex tw-gap-[8px] tw-items-center"
            >
              <div>
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_93_22328)">
                    <path
                      d="M10.3125 4.98535C9.70941 4.98535 9.21875 4.4947 9.21875 3.8916V0.297852H3.625C2.67728 0.297852 1.90625 1.06888 1.90625 2.0166V14.5791C1.90625 15.5268 2.67728 16.2979 3.625 16.2979H12.375C13.3227 16.2979 14.0938 15.5268 14.0938 14.5791V4.98535H10.3125ZM4.46875 11.5479H6.74125C7.00012 11.5479 7.21 11.7577 7.21 12.0166C7.21 12.2755 7.00012 12.4854 6.74125 12.4854H4.46875C4.20988 12.4854 4 12.2755 4 12.0166C4 11.7577 4.20988 11.5479 4.46875 11.5479ZM4 9.5166C4 9.25773 4.20988 9.04785 4.46875 9.04785H11.3438C11.6026 9.04785 11.8125 9.25773 11.8125 9.5166C11.8125 9.77548 11.6026 9.98535 11.3438 9.98535H4.46875C4.20988 9.98535 4 9.77548 4 9.5166ZM11.3438 6.54785C11.6026 6.54785 11.8125 6.75773 11.8125 7.0166C11.8125 7.27548 11.6026 7.48535 11.3438 7.48535H4.46875C4.20988 7.48535 4 7.27548 4 7.0166C4 6.75773 4.20988 6.54785 4.46875 6.54785H11.3438Z"
                      fill="#C4C7CF"
                    />
                    <path
                      d="M10.1562 3.89153C10.1562 3.97769 10.2263 4.04778 10.3125 4.04778H13.8848C13.7991 3.88947 13.6888 3.74406 13.5562 3.61866L10.543 0.767969C10.4261 0.657344 10.2958 0.565062 10.1563 0.492188V3.89153H10.1562Z"
                      fill="#C4C7CF"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_93_22328">
                      <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="translate(0 0.297852)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div class="tw-block tw-text-main-sm2 tw-whitespace-nowrap">
                {{ file.fileServer.name }}
              </div>
              <div class="tw-block tw-text-an-flat-not-active-input tw-leading-[22px]">
                <div class="tw-flex tw-items-center tw-justify-between">
                  <span class="tw-block tw-text-an-flat-not-active-input tw-truncate">{{ $Global.formatBytes(file.fileServer.size) }}</span>
                  <div
                    v-if="hasDownload"
                    class="tw-ml-auto"
                  >
                    <button
                      class="tw-p-[8px] tw-rounded-sm"
                      @click.stop="download(file.fileServer)"
                    >
                      <svg
                        width="13"
                        height="13"
                        viewBox="0 0 13 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_769_149)">
                          <path
                            d="M10.2083 13H2.91666C2.13764 13 1.40521 12.6995 0.854287 12.1538C0.30339 11.6082 0 10.8827 0 10.1111V9.38884C0 8.98995 0.326442 8.66659 0.729171 8.66659C1.1319 8.66659 1.45834 8.98995 1.45834 9.38884V10.1111C1.45834 10.4969 1.61005 10.8596 1.88545 11.1324C2.16092 11.4053 2.52712 11.5555 2.91666 11.5555H10.2083C10.5978 11.5555 10.964 11.4053 11.2395 11.1324C11.5149 10.8596 11.6667 10.4969 11.6667 10.1111V9.38884C11.6667 8.98995 11.9931 8.66659 12.3958 8.66659C12.7985 8.66659 13.125 8.98995 13.125 9.38884V10.1111C13.125 10.8827 12.8216 11.6081 12.2707 12.1538C11.7198 12.6995 10.9873 13 10.2083 13ZM6.56249 10.1111C6.46162 10.1111 6.36559 10.0908 6.27823 10.0541C6.19672 10.02 6.12012 9.97047 6.05311 9.90565C6.05308 9.90563 6.05308 9.90561 6.05306 9.90561C6.05257 9.90515 6.05209 9.90467 6.05161 9.90419C6.05148 9.90409 6.05133 9.90392 6.0512 9.90379C6.05079 9.90344 6.05043 9.90306 6.05005 9.90268C6.0498 9.90243 6.04957 9.90222 6.04931 9.90195C6.04906 9.90169 6.04873 9.90137 6.0485 9.90117C6.04799 9.90066 6.04743 9.90011 6.04692 9.8996L3.13024 7.01067C2.84549 6.72864 2.84549 6.27134 3.13024 5.98928C3.41498 5.70725 3.8767 5.70722 4.16145 5.98928L5.83334 7.64525V0.722226C5.83332 0.323333 6.15976 0 6.56249 0C6.96522 0 7.29168 0.323333 7.29168 0.722226V7.64523L8.96355 5.98928C9.24827 5.70725 9.71004 5.70725 9.99476 5.98928C10.2795 6.27131 10.2795 6.72864 9.99476 7.01067L7.07808 9.89955C7.07757 9.90005 7.07701 9.90061 7.0765 9.90111C7.07622 9.90137 7.07591 9.90169 7.07569 9.9019C7.07543 9.90217 7.0752 9.90238 7.07495 9.90263C7.07459 9.90303 7.07418 9.90339 7.07383 9.90374C7.0737 9.90386 7.07352 9.90404 7.07339 9.90414C7.07294 9.90462 7.07245 9.9051 7.07197 9.90555C7.07194 9.90555 7.07194 9.90558 7.07192 9.90561C7.06389 9.91335 7.05577 9.92087 7.04746 9.92818C6.98641 9.98212 6.9184 10.0242 6.84649 10.0542C6.84624 10.0543 6.84604 10.0544 6.84578 10.0545C6.8455 10.0546 6.84527 10.0548 6.84499 10.0548C6.75808 10.0911 6.66267 10.1111 6.56249 10.1111Z"
                            fill="#34363B"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_769_149">
                            <rect
                              width="13.125"
                              height="13"
                              fill="white"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </template>
        <template v-if="viewType === 'list'">
          <ul class="tw-flex tw-flex-col tw-gap-[4px]">
            <li
              v-for="(file, index) in files"
              :key="index"
              class="tw-bg-white tw-rounded-sm tw-p-[10px] tw-flex tw-gap-[10px] tw-items-center"
              @click.passive="file.fileServer.mime.includes('image') ? viewImages(imageFiles, index) : ''"
            >
              <div>
                <img
                  v-if="file.fileServer.mime.includes('image')"
                  class="tw-rounded-xs tw-block tw-h-[17px] tw-min-w-[17px] tw-w-[17px] tw-bg-an-flat-bg-main tw-cursor-zoom-in"
                  :src="getFileResizeByKey(file.fileServer, 'default')"
                  :alt="file.fileServer.name"
                  @error="imageLoadError(index)"
                >
                <svg
                  v-else
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_93_22328)">
                    <path
                      d="M10.3125 4.98535C9.70941 4.98535 9.21875 4.4947 9.21875 3.8916V0.297852H3.625C2.67728 0.297852 1.90625 1.06888 1.90625 2.0166V14.5791C1.90625 15.5268 2.67728 16.2979 3.625 16.2979H12.375C13.3227 16.2979 14.0938 15.5268 14.0938 14.5791V4.98535H10.3125ZM4.46875 11.5479H6.74125C7.00012 11.5479 7.21 11.7577 7.21 12.0166C7.21 12.2755 7.00012 12.4854 6.74125 12.4854H4.46875C4.20988 12.4854 4 12.2755 4 12.0166C4 11.7577 4.20988 11.5479 4.46875 11.5479ZM4 9.5166C4 9.25773 4.20988 9.04785 4.46875 9.04785H11.3438C11.6026 9.04785 11.8125 9.25773 11.8125 9.5166C11.8125 9.77548 11.6026 9.98535 11.3438 9.98535H4.46875C4.20988 9.98535 4 9.77548 4 9.5166ZM11.3438 6.54785C11.6026 6.54785 11.8125 6.75773 11.8125 7.0166C11.8125 7.27548 11.6026 7.48535 11.3438 7.48535H4.46875C4.20988 7.48535 4 7.27548 4 7.0166C4 6.75773 4.20988 6.54785 4.46875 6.54785H11.3438Z"
                      fill="#C4C7CF"
                    />
                    <path
                      d="M10.1562 3.89153C10.1562 3.97769 10.2263 4.04778 10.3125 4.04778H13.8848C13.7991 3.88947 13.6888 3.74406 13.5562 3.61866L10.543 0.767969C10.4261 0.657344 10.2958 0.565062 10.1563 0.492188V3.89153H10.1562Z"
                      fill="#C4C7CF"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_93_22328">
                      <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="translate(0 0.297852)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div class="tw-truncate">
                {{ file.fileServer.name }}
              </div>
              <div class="tw-whitespace-nowrap tw-text-an-flat-disabled-text">
                {{ $Global.formatBytes(file.fileServer.size) }}
              </div>
              <div
                v-if="hasDownload"
                class="tw-ml-auto"
              >
                <button
                  class="tw-p-[8px] tw-bg-an-flat-bg-main tw-rounded-sm"
                  @click.stop="download(file.fileServer)"
                >
                  <svg
                    width="13"
                    height="13"
                    viewBox="0 0 13 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_769_149)">
                      <path
                        d="M10.2083 13H2.91666C2.13764 13 1.40521 12.6995 0.854287 12.1538C0.30339 11.6082 0 10.8827 0 10.1111V9.38884C0 8.98995 0.326442 8.66659 0.729171 8.66659C1.1319 8.66659 1.45834 8.98995 1.45834 9.38884V10.1111C1.45834 10.4969 1.61005 10.8596 1.88545 11.1324C2.16092 11.4053 2.52712 11.5555 2.91666 11.5555H10.2083C10.5978 11.5555 10.964 11.4053 11.2395 11.1324C11.5149 10.8596 11.6667 10.4969 11.6667 10.1111V9.38884C11.6667 8.98995 11.9931 8.66659 12.3958 8.66659C12.7985 8.66659 13.125 8.98995 13.125 9.38884V10.1111C13.125 10.8827 12.8216 11.6081 12.2707 12.1538C11.7198 12.6995 10.9873 13 10.2083 13ZM6.56249 10.1111C6.46162 10.1111 6.36559 10.0908 6.27823 10.0541C6.19672 10.02 6.12012 9.97047 6.05311 9.90565C6.05308 9.90563 6.05308 9.90561 6.05306 9.90561C6.05257 9.90515 6.05209 9.90467 6.05161 9.90419C6.05148 9.90409 6.05133 9.90392 6.0512 9.90379C6.05079 9.90344 6.05043 9.90306 6.05005 9.90268C6.0498 9.90243 6.04957 9.90222 6.04931 9.90195C6.04906 9.90169 6.04873 9.90137 6.0485 9.90117C6.04799 9.90066 6.04743 9.90011 6.04692 9.8996L3.13024 7.01067C2.84549 6.72864 2.84549 6.27134 3.13024 5.98928C3.41498 5.70725 3.8767 5.70722 4.16145 5.98928L5.83334 7.64525V0.722226C5.83332 0.323333 6.15976 0 6.56249 0C6.96522 0 7.29168 0.323333 7.29168 0.722226V7.64523L8.96355 5.98928C9.24827 5.70725 9.71004 5.70725 9.99476 5.98928C10.2795 6.27131 10.2795 6.72864 9.99476 7.01067L7.07808 9.89955C7.07757 9.90005 7.07701 9.90061 7.0765 9.90111C7.07622 9.90137 7.07591 9.90169 7.07569 9.9019C7.07543 9.90217 7.0752 9.90238 7.07495 9.90263C7.07459 9.90303 7.07418 9.90339 7.07383 9.90374C7.0737 9.90386 7.07352 9.90404 7.07339 9.90414C7.07294 9.90462 7.07245 9.9051 7.07197 9.90555C7.07194 9.90555 7.07194 9.90558 7.07192 9.90561C7.06389 9.91335 7.05577 9.92087 7.04746 9.92818C6.98641 9.98212 6.9184 10.0242 6.84649 10.0542C6.84624 10.0543 6.84604 10.0544 6.84578 10.0545C6.8455 10.0546 6.84527 10.0548 6.84499 10.0548C6.75808 10.0911 6.66267 10.1111 6.56249 10.1111Z"
                        fill="#34363B"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_769_149">
                        <rect
                          width="13.125"
                          height="13"
                          fill="white"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
              </div>
            </li>
          </ul>
        </template>
        <template v-if="viewType === 'list-wrapped'">
          <ul class="tw-flex tw-flex-wrap tw-gap-[4px]">
            <li
              v-for="(file, index) in files"
              :key="index"
              class="tw-min-w-[240px] tw-bg-white tw-rounded-sm tw-p-[10px] tw-flex tw-gap-[10px] tw-items-center"
              @click.passive="file.fileServer.mime.includes('image') ? viewImages(imageFiles, index) : ''"
            >
              <div>
                <img
                  v-if="file.fileServer.mime.includes('image')"
                  class="tw-rounded-xs tw-block tw-h-[17px] tw-min-w-[17px] tw-w-[17px] tw-bg-an-flat-bg-main tw-cursor-zoom-in"
                  :src="getFileResizeByKey(file.fileServer, 'default')"
                  :alt="file.fileServer.name"
                  @error="imageLoadError(index)"
                >
                <svg
                  v-else
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_93_22328)">
                    <path
                      d="M10.3125 4.98535C9.70941 4.98535 9.21875 4.4947 9.21875 3.8916V0.297852H3.625C2.67728 0.297852 1.90625 1.06888 1.90625 2.0166V14.5791C1.90625 15.5268 2.67728 16.2979 3.625 16.2979H12.375C13.3227 16.2979 14.0938 15.5268 14.0938 14.5791V4.98535H10.3125ZM4.46875 11.5479H6.74125C7.00012 11.5479 7.21 11.7577 7.21 12.0166C7.21 12.2755 7.00012 12.4854 6.74125 12.4854H4.46875C4.20988 12.4854 4 12.2755 4 12.0166C4 11.7577 4.20988 11.5479 4.46875 11.5479ZM4 9.5166C4 9.25773 4.20988 9.04785 4.46875 9.04785H11.3438C11.6026 9.04785 11.8125 9.25773 11.8125 9.5166C11.8125 9.77548 11.6026 9.98535 11.3438 9.98535H4.46875C4.20988 9.98535 4 9.77548 4 9.5166ZM11.3438 6.54785C11.6026 6.54785 11.8125 6.75773 11.8125 7.0166C11.8125 7.27548 11.6026 7.48535 11.3438 7.48535H4.46875C4.20988 7.48535 4 7.27548 4 7.0166C4 6.75773 4.20988 6.54785 4.46875 6.54785H11.3438Z"
                      fill="#C4C7CF"
                    />
                    <path
                      d="M10.1562 3.89153C10.1562 3.97769 10.2263 4.04778 10.3125 4.04778H13.8848C13.7991 3.88947 13.6888 3.74406 13.5562 3.61866L10.543 0.767969C10.4261 0.657344 10.2958 0.565062 10.1563 0.492188V3.89153H10.1562Z"
                      fill="#C4C7CF"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_93_22328">
                      <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="translate(0 0.297852)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div class="tw-truncate">
                {{ file.fileServer.name }}
              </div>
              <div class="tw-whitespace-nowrap tw-text-an-flat-disabled-text">
                {{ $Global.formatBytes(file.fileServer.size) }}
              </div>
              <div
                v-if="hasDownload"
                class="tw-ml-auto"
              >
                <button
                  class="tw-p-[8px] tw-bg-an-flat-bg-main tw-rounded-sm"
                  @click.stop="download(file.fileServer)"
                >
                  <svg
                    width="13"
                    height="13"
                    viewBox="0 0 13 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_769_149)">
                      <path
                        d="M10.2083 13H2.91666C2.13764 13 1.40521 12.6995 0.854287 12.1538C0.30339 11.6082 0 10.8827 0 10.1111V9.38884C0 8.98995 0.326442 8.66659 0.729171 8.66659C1.1319 8.66659 1.45834 8.98995 1.45834 9.38884V10.1111C1.45834 10.4969 1.61005 10.8596 1.88545 11.1324C2.16092 11.4053 2.52712 11.5555 2.91666 11.5555H10.2083C10.5978 11.5555 10.964 11.4053 11.2395 11.1324C11.5149 10.8596 11.6667 10.4969 11.6667 10.1111V9.38884C11.6667 8.98995 11.9931 8.66659 12.3958 8.66659C12.7985 8.66659 13.125 8.98995 13.125 9.38884V10.1111C13.125 10.8827 12.8216 11.6081 12.2707 12.1538C11.7198 12.6995 10.9873 13 10.2083 13ZM6.56249 10.1111C6.46162 10.1111 6.36559 10.0908 6.27823 10.0541C6.19672 10.02 6.12012 9.97047 6.05311 9.90565C6.05308 9.90563 6.05308 9.90561 6.05306 9.90561C6.05257 9.90515 6.05209 9.90467 6.05161 9.90419C6.05148 9.90409 6.05133 9.90392 6.0512 9.90379C6.05079 9.90344 6.05043 9.90306 6.05005 9.90268C6.0498 9.90243 6.04957 9.90222 6.04931 9.90195C6.04906 9.90169 6.04873 9.90137 6.0485 9.90117C6.04799 9.90066 6.04743 9.90011 6.04692 9.8996L3.13024 7.01067C2.84549 6.72864 2.84549 6.27134 3.13024 5.98928C3.41498 5.70725 3.8767 5.70722 4.16145 5.98928L5.83334 7.64525V0.722226C5.83332 0.323333 6.15976 0 6.56249 0C6.96522 0 7.29168 0.323333 7.29168 0.722226V7.64523L8.96355 5.98928C9.24827 5.70725 9.71004 5.70725 9.99476 5.98928C10.2795 6.27131 10.2795 6.72864 9.99476 7.01067L7.07808 9.89955C7.07757 9.90005 7.07701 9.90061 7.0765 9.90111C7.07622 9.90137 7.07591 9.90169 7.07569 9.9019C7.07543 9.90217 7.0752 9.90238 7.07495 9.90263C7.07459 9.90303 7.07418 9.90339 7.07383 9.90374C7.0737 9.90386 7.07352 9.90404 7.07339 9.90414C7.07294 9.90462 7.07245 9.9051 7.07197 9.90555C7.07194 9.90555 7.07194 9.90558 7.07192 9.90561C7.06389 9.91335 7.05577 9.92087 7.04746 9.92818C6.98641 9.98212 6.9184 10.0242 6.84649 10.0542C6.84624 10.0543 6.84604 10.0544 6.84578 10.0545C6.8455 10.0546 6.84527 10.0548 6.84499 10.0548C6.75808 10.0911 6.66267 10.1111 6.56249 10.1111Z"
                        fill="#34363B"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_769_149">
                        <rect
                          width="13.125"
                          height="13"
                          fill="white"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
              </div>
            </li>
          </ul>
        </template>
      </template>
      <div
        v-else
        class="tw-text-center tw-text-an-flat-disabled-text"
      >
        {{ noDataText }}
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/Api/Crm/File'
import FileUploadMixin from '@/mixins/FileUploadMixin'
import { getFileResizeByKey } from '@/plugins/helpers/fileHelpers'

export default {
  name: 'TwFilesListViewByIds',
  mixins: [FileUploadMixin],
  props: {
    fileIds: {
      type: Array,
      default: () => []
    },
    viewType: {
      type: String,
      default: 'grid'
    },
    noDataText: {
      type: String,
      default: 'Отсутствуют'
    },
    title: {
      type: String,
      default: null
    },
    hasDownload: {
      type: Boolean,
      default: true
    },
    isDropDown: {
      type: Boolean,
      default: true
    },
    api: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      API: this.api || API,
      active: true,
      files: []
    }
  },
  computed: {
    imageFiles () {
      return this.files?.filter((file) => file?.fileServer?.mime?.includes('image'))
    },
    otherFiles () {
      return this.files?.filter((file) => !file?.fileServer?.mime?.includes('image'))
    }
  },
  watch: {
    fileIds: {
      handler () {
        this.initFiles()
      },
      immediate: true
    }
  },
  methods: {
    download (file) {
      this.API.download(file.url, file.name)
    },
    showFileInfo (fileId) {
      if (this.files.some(i => i.id === fileId)) {
        return
      }
      this.API.show(fileId)
        .then(({ data }) => {
          if (data?.data) {
            this.files.push({
              id: fileId,
              error: false,
              loading: false,
              fileServer: data.data
            })
          }
        })
    },
    initFiles () {
      for (const file of this.fileIds) {
        this.showFileInfo(file)
      }
    },
    getFileResizeByKey: getFileResizeByKey
  }
}
</script>

<style scoped>

</style>
