<template>
  <TwPanelContentBlock
    class="tw-flex-1 tw-mb-[6px]"
    :content-classes="'tw-bg-white'"
  >
    <template #title>
      <div />
    </template>
    <template #action>
      <TwPanelContentBlockActionButton
        :type="isFinished ? 'edit':'add'"
        :disabled="loading"
        @click.stop="$emit('changeEdit')"
      >
        {{ isFinished ? 'Изменить':'Заполнить анкету' }}
      </TwPanelContentBlockActionButton>
    </template>
    <div
      v-if="loading"
      class="tw-p-3 tw-flex tw-justify-center"
    >
      <TwCircleLoader class="tw-p-3" />
    </div>
    <div v-else>
      <div class="tw-grid tw-grid-cols-[40px_auto_400px] tw-border-b tw-border-solid tw-font-semibold">
        <div class="tw-border-r tw-border-solid tw-p-[10px] tw-text-center">
          №
        </div>
        <div class="tw-border-r tw-border-solid tw-p-[10px]">
          Вопрос
        </div>
        <div class="tw-p-[10px]">
          Ответ
        </div>
      </div>
      <div class="tw-grid tw-grid-cols-[40px_auto_400px] tw-border-b tw-border-solid">
        <div class="tw-border-r tw-border-solid tw-p-[10px] tw-text-center">
          -
        </div>
        <div class="tw-border-r tw-border-solid tw-p-[10px]">
          Статус анкеты
        </div>
        <div class="tw-p-[10px]">
          <TwBooleanIcon :value="isFinished">
            <template #right>
              {{ isFinished ? 'Заполнена':'Не заполнена' }}
            </template>
          </TwBooleanIcon>
        </div>
      </div>
      <template v-if="isFinished">
        <div
          v-for="(field, index) in flatListFormSchema"
          :key="index"
          class="tw-grid tw-grid-cols-[40px_auto_400px] tw-border-b tw-border-solid"
        >
          <div class="tw-border-r tw-border-solid tw-p-[10px] tw-text-center">
            {{ index + 1 }}
          </div>
          <div class="tw-border-r tw-border-solid tw-p-[10px]">
            {{ field.label }}
          </div>
          <div
            class="tw-p-[10px] tw-break-words"
            :class="formData[field.key] ? '' : 'tw-bg-an-flat-red-main/10'"
          >
            <span
              v-if="!formData[field.key]"
              class="tw-text-an-flat-red-main"
            >
              Не заполнено
            </span>
            <template v-else-if="field.options">
              {{ getFieldValuesFromOptions(formData[field.key], field) }}
            </template>
            <template v-else>
              {{ formData[field.key] }}
            </template>
          </div>
        </div>
      </template>
    </div>
  </TwPanelContentBlock>
</template>

<script>
import API from '@/Api/Review/RealtorSurvey/Anketa'
import TwPanelContentBlockActionButton from '@/components/tw-ui/panels/TwPanelContentBlockActionButton.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwBooleanIcon from '@/components/tw-ui/ui-kit/icons/TwBooleanIcon.vue'

export default {
  name: 'SurveyAnketaShow',
  components: {
    TwBooleanIcon,
    TwPanelContentBlock,
    TwCircleLoader,
    TwPanelContentBlockActionButton
  },
  props: {
    surveyId: {
      type: Number,
      required: true
    },
    isFinished: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      formData: null,
      formSchema: null
    }
  },
  computed: {
    flatListFormSchema () {
      function recursiveFields (obj = {}, level = 1) {
        let list = []
        const blocks = obj?.blocks || null
        const fields = obj?.fields || null

        if (Array.isArray(blocks) && blocks.length) {
          for (const block of blocks) {
            // console.warn('B', level, block)
            list = [...list, ...recursiveFields(block, level + 1)]
          }
        } else if (Array.isArray(fields) && fields.length) {
          for (const field of fields) {
            // console.warn('F', level, field)
            list.push(field)
          }
        }
        return list
      }
      // console.warn('+++++++++++==', recursiveFields(this.formSchema, 1))
      return recursiveFields(this.formSchema, 1)
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    getFieldValuesFromOptions (value, field = {}) {
      if (Array.isArray(field?.options) && field.options.length) {
        return field.options?.find(o => o.value === value)?.label || value || null
      } else {
        console.error('Опции отсутствуют', field, value)
        return value || null
      }
    },
    fetch () {
      this.loading = true
      API.show(this.surveyId)
        .then(({ data: result }) => {
          // console.warn(result)
          this.formData = result?.data || null
          this.formSchema = result?.formSchema || null
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
