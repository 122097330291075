<template>
  <PageViewDefaultWrapper
    ref="wrapper"
    header-class="mb-2"
    :permission-denied="permissionDenied"
    relative
  >
    <template #header>
      <!--      TODO HEADER 1-->
    </template>
    <template>
      <div class="px-3 pt-5 pb-4 d-flex justify-space-between">
        <div
          class="d-flex align-center"
          style="gap: 15px"
        >
          <div class="body-1 font-weight-medium">
            Подходящие программы с субсид. ставками
            <span
              class="ml-2 cursor-default"
              title="Количество подходящих программ"
            >{{ (Array.isArray(filteredDataList) && filteredDataList.length) || 0 }}</span>
          </div>
        </div>
        <div
          class="d-flex align-center flex-wrap"
          title="Быстрый фильтр по банку"
        >
          <v-autocomplete
            v-model="selectedCompany"
            style="max-width: 300px"
            hide-details
            attach=""
            outlined
            dense
            clearable
            :items="uniqCompanyList"
            item-text="name"
            item-value="id"
            label="Выбор банка"
          />
        </div>
      </div>
      <TwTableBase
        schema-key="SubsidizedProgramsListView"
        :loading-error-text="loadingErrorText"
        tbody-classes="tw-text-xs"
        :headers="columns"
        :loading="loading"
        :fixed-header-margin="0"
        :items="filteredDataList"
        :no-data-text="noDataText"
        :table-columns-management="false"
        @headersUpdate="columns = $event"
        @row-click="doubleRowClick"
        @changePagination="changePagination"
      >
        <template #tbody="{ items }">
          <tbody>
            <template v-if="Array.isArray(items) && items.length">
              <tr
                v-for="(item, index) in items"
                :key="index"
                class="tw-text-sm2 tw-border-y tw-border-solid odd:tw-bg-white even:tw-bg-slate-100"
              >
                <td
                  class="tw-p-0 tw-pl-[6px]"
                  style="width: auto"
                >
                  <!-- БАНК -->
                  <ViewPanelLink
                    v-if="item.program.company"
                    :id="item.program.company.id"
                    style="color: #2c384d"
                    label="Компания"
                    component="company-show"
                  >
                    {{ item.program.company.name }}
                  </ViewPanelLink>
                  <!-- ПРОГРАММА -->
                  <span class="text--disabled">
                    - <ViewPanelLink
                      :id="item.program.id"
                      label="Программа"
                      component="mortgage-program-show"
                    >
                      {{ item.program.name }}
                    </ViewPanelLink>
                    <!-- Категории программы -->
                    <div
                      v-if="item.program && Array.isArray(item.program.categories)"
                      style="line-height: normal;font-size: 12px"
                    >
                      <small
                        v-for="(category, index) in item.program.categories"
                        :key="index"
                        class="text-muted"
                      >
                        {{ category.name }}<template v-if="index < item.program.categories.length - 1">,</template>
                      </small>
                    </div>
                  </span>
                  <!-- ПРЕДУПРЕЖДЕНИЯ -->
                  <small
                    v-if="item.warning"
                    class="red--text darken-4"
                  >
                    {{ item.warning }}
                  </small>
                </td>
                <!-- otherRates -->
                <td
                  class="tw-whitespace-nowrap tw-text-right tw-p-0 tw-align-top"
                  style="width: 140px"
                >
                  <div class="tw-my-[3px]">
                    Базовая
                  </div>
                  <template v-if="Array.isArray(item.otherRates) && item.otherRates.length">
                    <v-divider />
                    <div
                      v-for="(object, index) in item.otherRates"
                      :key="index"
                      class="tw-my-[3px]"
                      title="Субсидированная ставка"
                    >
                      {{ object.programRate.termMax || 0 }}
                      {{ $Global.textPlural(object.programRate.termMax || 0, 'month') }}
                      <v-divider v-if="index < item.otherRates.length - 1" />
                    </div>
                  </template>
                </td>
                <!-- rate -->
                <td
                  class="tw-whitespace-nowrap tw-text-right tw-p-0 tw-align-top"
                  style="width: 60px"
                >
                  <div class="tw-my-[3px]">
                    {{ item.baseRate.programRate.rate || '~' }}<Percent class="tw-text-xs" />
                  </div>
                  <template v-if="Array.isArray(item.otherRates) && item.otherRates.length">
                    <v-divider />
                    <div
                      v-for="(object, index) in item.otherRates"
                      :key="index"
                      class="tw-my-[3px]"
                    >
                      {{ object.programRate.rate }}
                      <Percent class="tw-text-xs" />
                      <v-divider v-if="index < item.otherRates.length - 1" />
                    </div>
                  </template>
                </td>
                <!-- pay -->
                <td
                  class="tw-whitespace-nowrap tw-text-right tw-p-0 tw-align-top"
                  style="width: 130px"
                >
                  <div class="tw-my-[3px]">
                    {{ item.baseRate.pay ? item.baseRate.pay.toLocaleString() : 0 }}
                    <Rouble class="tw-text-xs" />
                  </div>
                  <template v-if="Array.isArray(item.otherRates) && item.otherRates.length">
                    <v-divider />
                    <div
                      v-for="(object, index) in item.otherRates"
                      :key="index"
                      class="tw-my-[3px]"
                    >
                      <span class="ml-3">
                        {{ object.pay ? object.pay.toLocaleString() : 0 }}<Rouble class="tw-text-xs" />
                      </span>
                      <v-divider v-if="index < item.otherRates.length - 1" />
                    </div>
                  </template>
                </td>
                <!-- economyFromBase -->
                <td
                  class="tw-whitespace-nowrap tw-text-right tw-p-0 tw-pr-[6px] tw-align-top"
                  style="width: 120px"
                >
                  <div class="tw-my-[3px]">
                    {{ item.baseRate.economyFromBase ? item.baseRate.economyFromBase.toLocaleString() : 0 }}
                    <Rouble class="tw-text-xs" />
                  </div>
                  <template v-if="Array.isArray(item.otherRates) && item.otherRates.length">
                    <v-divider />
                    <div
                      v-for="(object, index) in item.otherRates"
                      :key="index"
                      class="tw-my-[3px]"
                    >
                      {{ object.economyFromBase ? object.economyFromBase.toLocaleString() : 0 }}
                      <Rouble class="tw-text-xs" />
                      <v-divider v-if="index < item.otherRates.length - 1" />
                    </div>
                  </template>
                </td>
              </tr>
            </template>
          </tbody>
        </template>
      </TwTableBase>
    </template>
  </PageViewDefaultWrapper>
</template>

<script>
import API from '@/Api/Mortgage/Program'
import tableOptions from './tableOptions'
import Rouble from '@/components/symbol/Rouble'
import Percent from '@/components/symbol/Percent'
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink'
import PageViewDefaultWrapper from '@/components/core/ViewDefault/PageViewDefaultWrapper'
import TwTableBase from '@/components/tw-ui/table/TwTableBase.vue'
import DataListMixin from '@/mixins/DataListMixin'

export default {
  name: 'SubsidizedProgramsListView',
  components: {
    TwTableBase,
    PageViewDefaultWrapper,
    ViewPanelLink,
    Percent,
    Rouble
  },
  mixins: [DataListMixin, ViewPanelShowMixin],
  props: {
    complexId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      noDataText: 'Нет подходящих программ',
      noResultsText: 'Нет подходящих программ',
      selectedCompany: null,
      API,
      columns: tableOptions,
      itemsPerPage: 999,
      permissionDenied: false
    }
  },
  computed: {
    headers () {
      return tableOptions
    },
    uniqCompanyList () {
      const companies = []
      // console.log('uniqCompanyList', this.data)
      if (Array.isArray(this.data)) {
        for (const { program } of this.data) {
          // console.log(program.name, program)
          if (program?.company?.id && companies.indexOf(c => c.id !== program.company.id) === -1) {
            companies.push(program.company)
          }
        }
        // console.log('uniqCompanyList ', { companies })
      }
      return companies
    },
    filteredDataList () {
      return this.data.filter(item => this.selectedCompany ? item?.program?.company?.id === this.selectedCompany : true)
    }
  },
  mounted () {
    this.refresh()
  },
  methods: {
    row_classes (item) {
      return item.warning ? 'red lighten-4 ' : ''
    },
    refresh () {
      this.selectedCompany = null
      this.fetch()
    },
    fetch () {
      this.loading = true
      API.complexRates(this.complexId)
        .then(({ data }) => {
          console.warn(this.$options.name, data)
          this.data = data?.data || []
        })
        .catch((error) => {
          console.error(this.$options.name, error)
          // обработка отсутствия прав на просмотр
          if (error?.response?.status === 403) {
            this.permissionDenied = true
          }
        })
        .finally(() => {
          console.warn(this.$options.name)
          this.loading = false
        })
    }
  }
}
</script>
<style lang="less">
.complex-subsidized-programs-table-container {
  table {
    tr {
      th:first-child, td:first-child {
        padding-left: 10px !important;
      }
      th:last-child, td:last-child {
        padding-right: 10px !important;
      }
      &:nth-child(even) {
        background-color: whitesmoke;
      }
    }
  }
}
</style>
