<template>
  <TwPanelContentBlock class="tw-mb-[6px]">
    <template #title>
      <div>Преимущества</div>
    </template>
    <template #action>
      <button
        :disabled="loading"
        class="tw-text-main-xs enabled:hover:tw-text-an-flat-dark-blue-link"
        @click.prevent="isEdit = !isEdit"
      >
        {{ isEdit ? 'отменить' : 'изменить' }}
      </button>
    </template>
    <div
      v-if="loading"
      class="tw-flex tw-w-full tw-justify-center"
    >
      <TwCircleLoader />
    </div>
    <div
      v-else
      class="tw-flex tw-gap-[12px] tw-flex-col"
    >
      <template v-if="isEdit">
        <div class="tw-flex tw-w-full tw-justify-end">
          <button
            :disabled="loading"
            class="tw-text-main-xs tw-text-white tw-bg-an-flat-dark-blue-link tw-px-[7px] tw-py-[3px] tw-rounded-sm"
            @click.prevent="addAdvantage"
          >
            Добавить преимущество
          </button>
        </div>
        <div
          ref="inputsBlock"
          class="tw-flex tw-flex-col tw-gap-[15px]"
        >
          <template v-if="!redraw">
            <template v-for="(item, index) in form.advantages">
              <TwInputText
                :key="index"
                v-model="form.advantages[index]"
                :disabled="formProcessing"
                :max-length="100"
                dense
                placeholder="Описание преимущества"
              >
                <template #prepend>
                  <div class="tw-flex tw-gap-[6px] tw-whitespace-nowrap tw-items-center">
                    <button class="draggable-handler tw-font-semibold tw-text-an-flat-red-main tw-p-[5px]">
                      <svg
                        class=""
                        width="9"
                        height="15"
                        viewBox="0 0 9 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M9 9.375L4.5 15L-1.90735e-06 9.375H3.72058L3.72058 5.625H-1.90735e-06L4.5 2.5034e-06L9 5.625H5.27942L5.27942 9.375L9 9.375Z"
                          fill="#60656A"
                        />
                      </svg>
                    </button>
                    № {{ index + 1 }}
                  </div>
                </template>
                <template #append>
                  <button
                    class="tw-font-semibold tw-text-an-flat-red-main tw-p-[5px]"
                    @click="removeAdvantage(index)"
                  >
                    x
                  </button>
                </template>
              </TwInputText>
            </template>
          </template>
        </div>
        <TwCardBlockFormActions
          title-submit="Сохранить"
          :handle-submit="handleSubmit"
          :handle-cancel="handleCancel"
          :disable-submit="formProcessing"
          :disable-cancel="formProcessing"
        />
      </template>
      <template v-else>
        <div>
          <div
            v-if="data && Array.isArray(data.advantages) && data.advantages.length"
            class="tw-flex tw-flex-col tw-gap-[15px]"
          >
            <div
              v-for="(itemText, index) in data.advantages"
              :key="index"
            >
              <span class="tw-text-an-flat-disabled-text tw-mr-[6px]">№ {{ index + 1 }}</span>
              {{ itemText || '~' }}
            </div>
          </div>
          <div
            v-else
            class="tw-text-an-flat-disabled-text tw-text-center"
          >
            Не добавлены
          </div>
        </div>
      </template>
    </div>
  </TwPanelContentBlock>
</template>

<script>
import Sortable from 'sortablejs'
import { API } from '@/Api/ObjectStorage'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import TwInputText from '@/components/tw-ui/ui-kit/input/TwInputText.vue'
import TwCardBlockFormActions from '@/components/core/ViewPanel/Card/TwCardBlockFormActions.vue'

export default {
  name: 'RealtyComplexAdvantages',
  components: { TwCardBlockFormActions, TwInputText, TwCircleLoader, TwPanelContentBlock },
  props: {
    id: {
      type: String,
      required: true
    },
    isAllowEdit: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      sortableInstance: null,
      API: API.ComplexAdvantages,
      loading: null,
      formProcessing: false,
      data: null,
      form: {},
      isEdit: false,
      redraw: false
    }
  },
  watch: {
    isEdit (newValue) {
      if (newValue) {
        this.form = {
          complexId: this.id,
          advantages: (Array.isArray(this.data?.advantages) && this.data?.advantages.length && this.data?.advantages) || ['']
        }
        this.$nextTick(() => {
          this.initSortable()
        })
      } else {
        this.sortableInstance?.destroy()
      }
    }
  },
  mounted () {
    this.refresh()
  },
  methods: {
    removeAdvantage (index) {
      this.form.advantages.splice(index, 1)
    },
    addAdvantage () {
      this.form.advantages.push('')
    },
    refresh () {
      this.isEdit = false
      this.loading = true
      this.API.getAdvantagesByComplexId(this.id).then(({ data: result }) => {
        console.warn('getAdvantagesByComplexId: ', result.data)
        this.data = result?.data || null
      }).catch((error) => {
        console.warn('error getAdvantagesByComplexId: ', error)
      }).finally(() => {
        this.loading = false
      })
    },
    handleSubmit () {
      this.formProcessing = true
      this.API.update(this.form).then(() => {
        this.refresh()
      }).catch((error) => {
        console.warn('error', error)
      }).finally(() => {
        this.formProcessing = false
      })
    },
    handleCancel () {
      this.isEdit = false
    },
    initSortable () {
      this.sortableInstance = Sortable.create(this.$refs.inputsBlock, {
        handle: '.draggable-handler',
        // chosenClass: 'dragged-item-active', // Класс перемещаемого элемента
        sort: true,
        animation: 200,
        onEnd: ({ newIndex, oldIndex }) => {
          this.redraw = true
          const copyItems = [...this.form.advantages]
          const selected = copyItems.splice(oldIndex, 1)[0]
          copyItems.splice(newIndex, 0, selected)
          this.$set(this.form, 'advantages', copyItems)
          setTimeout(() => {
            this.redraw = false
          }, 10)
        }
      })
    }
  }
}
</script>
