import { RouteKeys } from '@/router/types'
import dictPermissionRules from '@/Permissions/dictRules'

export default {
  path: `/${RouteKeys.person}`,
  name: RouteKeys.person,
  redirect: { name: `${RouteKeys.person}-list` },
  meta: { name: 'Контакты', icon: 'person', plannedKey: 'person', ruleNamespace: dictPermissionRules.AppServicePermissionRulesCRMPersonContactsReadContactRule },
  component: () => import('@/views/Person/Index.vue'),
  children: [
    {
      path: 'list',
      name: `${RouteKeys.person}-list`,
      meta: { name: 'Список контактов', icon: 'person', hideInMenu: true },
      component: () => import('@/views/Person/List.vue')
    },
    {
      path: ':id/edit',
      name: `${RouteKeys.person}-edit`,
      meta: { name: 'Редактирование контакта', icon: 'person', hideInMenu: true },
      component: () => import('@/views/Person/PersonCard.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    },
    {
      path: 'create',
      name: `${RouteKeys.person}-create`,
      meta: { name: 'Добавление контакта', icon: 'person', hideInMenu: true },
      component: () => import('@/views/Person/PersonCard.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    },
    {
      path: ':id',
      name: `${RouteKeys.person}-show`,
      meta: { name: 'Просмотр контакта', icon: 'home', hideInMenu: true },
      component: () => import('@/views/Person/PersonCard.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    }
  ]
}
