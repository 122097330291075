<template>
  <TwPanelContent>
    <PageViewDefaultWrapper
      header-class="my-3"
      :permission-denied="permissionDenied"
      show-refresh-button
      @refresh="() => fetch()"
    >
      <template #header>
        <div class="tw-grow">
          <TwFilterBlock
            :schema-key="filterSchemaKey"
            @search="fetch"
          />
        </div>
      </template>
      <template>
        <TwTableBase
          :schema-key="filterSchemaKey"
          :loading-error-text="loadingErrorText"
          tbody-classes="tw-text-xs"
          tbody-row-classes="tw-cursor-pointer"
          :items="data"
          :headers="columns"
          :loading="loading"
          :pagination="pagination"
          :fixed-header-margin="0"
          :table-columns-management="true"
          @headersUpdate="changeHeaders"
          @changePagination="changePagination"
        >
          <template #item.actions="{ item }">
            <div class="tw-flex tw-flex-wrap tw-gap-[12px]">
              <TwPanelActionButton
                v-if="item.isFinished"
                class="tw-max-w-[200px]"
                @click.stop="clickToItem(item, false)"
              >
                Посмотреть
              </TwPanelActionButton>
              <TwPanelActionButton
                class="tw-max-w-[200px]"
                @click.stop="clickToItem(item, true)"
              >
                {{ item.isFinished ? 'Изменить':'Заполнить' }}
              </TwPanelActionButton>
            </div>
          </template>
        </TwTableBase>
        <SurveyDealViewModal
          ref="viewModal"
          @refresh="refresh"
        />
      </template>
    </PageViewDefaultWrapper>
  </TwPanelContent>
</template>

<script>
import API from '@/Api/Review/RealtorSurvey/Deal'
import TwPanelContent from '@/components/tw-ui/panels/TwPanelContent.vue'
import TwTableBase from '@/components/tw-ui/table/TwTableBase.vue'
import PageViewDefaultWrapper from '@/components/core/ViewDefault/PageViewDefaultWrapper.vue'
import DataListMixin from '@/mixins/DataListMixin'
import tableOptions from './tableOptions'
import { ServicesSchema } from '@/store/twfilter/servicesSchema'
import SurveyDealViewModal from '@/views/Company/UserSurveys/Deal/SurveyDealViewModal.vue'
import TwFilterBlock from '@/components/filters/twfilters/TwFilterBlock.vue'
import TwPanelActionButton from '@/components/tw-ui/panels/TwPanelActionButton.vue'

export default {
  name: 'SurveyDealsList',
  components: {
    TwPanelActionButton,
    TwFilterBlock,
    SurveyDealViewModal,
    PageViewDefaultWrapper,
    TwTableBase,
    TwPanelContent
  },
  mixins: [DataListMixin],
  props: {
    info: {
      required: true
    }
  },
  data () {
    return {
      filterSchemaKey: ServicesSchema.SurveyDealsList.key,
      API,
      columns: tableOptions,
      defaultFilters: { surveyId: this.info.id }
    }
  },
  computed: {
    surveyId () {
      return this.info?.id || null
    }
  },
  methods: {
    changeHeaders (eventData) {
      this.columns = eventData
    },
    clickToItem (itemInfo, isEdit = false) {
      if (itemInfo?.dealId?.value && this.$refs.viewModal) {
        this.$refs.viewModal.onOpenModal(itemInfo.dealId.value, isEdit)
      } else {
        console.error('BAD DEAL ID')
      }
    }
  }
}
</script>

<style scoped>

</style>
