<template>
  <v-form ref="form">
    <div style="display: none">
      {{ isEdited }}
    </div>
    <div class="tw-flex tw-flex-col">
      <div>
        <v-text-field
          v-model="form.name"
          label="Название"
          autocomplete="off"
          :rules="nameRules"
          required
        />
      </div>
      <div>
        <div>
          <div class="ma-0 pa-0 grey--text">
            Активность:
          </div>
          <div class="d-flex">
            <v-checkbox
              v-model="form.active"
              class="mt-0"
              color="success"
              :label="form.active ? 'Да':'Нет'"
            />
          </div>
        </div>
      </div>
      <div>
        <div>
          <div class="text-muted">
            Банк:
          </div>
          <CompanyAsyncSelect
            v-model="form.companyId"
            chips
            clearable
            :rules="requireRules"
            placeholder="Выбрать Банк"
            :filters="{ 'Company.type': 'bank' }"
          />
        </div>
      </div>
      <div>
        <div>
          <div class="ma-0 pa-0 grey--text">
            Возможность оплатить КВ за счет ПВ (<span class="red--text">Только Вторичка</span>):
          </div>
          <div class="d-flex">
            <v-checkbox
              v-model="form.commissionRewardFirstPay"
              class="mt-0"
              color="success"
              :label="form.commissionRewardFirstPay ? 'Да':'Нет'"
            />
          </div>
        </div>
        <div class="my-3">
          <div class="ma-0 pa-0 grey--text">
            Использование маткапитала на первоначальный взнос:
          </div>
          <v-checkbox
            v-model="form.matCapitalFirstPay"
            class="mt-0"
            color="success"
            :label="form.matCapitalFirstPay ? 'Да':'Нет'"
          />
        </div>
      </div>
    </div>
    <TwPanelContentBlockFieldView
      :horizontal="false"
      class="tw-mb-[22px]"
    >
      <template #title>
        Категории программы:
      </template>
      <template>
        <div class="tw-pt-[12px]">
          <ProgramCategorySelect
            v-model="form.categoriesId"
            :required="true"
            @selectFirst="selectFirstCategory"
          />
        </div>
      </template>
    </TwPanelContentBlockFieldView>
    <v-row justify="space-between">
      <v-col
        cols="12"
        md="6"
      >
        <InputPriceSpaced
          v-model="form.sumMin"
          label="Размер кредита от, руб."
          required
          :rules="requireRules"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <InputPriceSpaced
          v-model="form.sumMax"
          label="Размер кредита до, руб."
          required
          :rules="requireRules"
        />
      </v-col>
    </v-row>

    <v-row justify="space-between">
      <v-col
        cols="12"
        md="6"
      >
        <v-text-field
          v-model.number="form.termMin"
          type="number"
          label="Срок кредита от, мес."
          autocomplete="off"
          :rules="requireRules"
          required
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-text-field
          v-model.number="form.termMax"
          type="number"
          label="Срок кредита до, мес."
          autocomplete="off"
          :rules="requireRules"
          required
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <div>
          <div class="ma-0 pa-0 grey--text">
            Субсидированные ставки:
          </div>
          <v-checkbox
            v-model="form.subsidized"
            class="mt-0"
            color="success"
            :label="form.subsidized ? 'Включены':'Выключены'"
          />
        </div>
      </v-col>
    </v-row>

    <!-- СТАВКИ - только базовые. субсидированные добавляются через матрицу в требованиях банка -->
    <div class="mb-10">
      <v-card flat>
        <v-card-text>
          <div class="pa-2">
            <div class="d-flex justify-space-between font-weight-bold">
              <v-btn
                x-small
                color="primary"
                @click="addRate"
              >
                <v-icon
                  small
                  left
                >
                  fa-plus-square
                </v-icon>
                Добавить базовую ставку
              </v-btn>
              <div>
                <!-- Базовые ставки:-->
              </div>
            </div>
          </div>
          <v-divider />
          <div>
            <div
              v-if="Array.isArray(form.rates) && form.rates.filter(r => !Array.isArray(r.complexesId)).length"
              class="d-flex flex-wrap"
              style="gap: 15px"
            >
              <template v-for="(obj, index) of form.rates">
                <div
                  v-if="!Array.isArray(obj.complexesId)"
                  :key="index"
                  style="position:relative; min-width: 400px; max-width: 50%; flex: 1"
                >
                  <v-card
                    class="mt-3"
                    outlined
                    style="position:relative;"
                    :title="obj.id ? '':'Новая ставка'"
                    :color="obj.id ? '':'orange lighten-5'"
                  >
                    <v-card-actions
                      style="position:absolute; right: 0; top: -10px"
                    >
                      <div v-if="obj.id">
                        <div
                          class="text--disabled font-weight-light"
                          style="font-size: 12px;"
                        >
                          #{{ obj.id }}
                        </div>
                      </div>
                      <v-spacer />
                      <div
                        class="pa-0"
                        title="Удалить ставку?"
                        @click="removeRate(index)"
                      >
                        <v-btn
                          small
                          icon
                        >
                          <v-icon
                            small
                            color="error"
                          >
                            fa-times
                          </v-icon>
                        </v-btn>
                      </div>
                    </v-card-actions>
                    <MortgageProgramRateForm v-model="form.rates[index]" />
                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        v-if="form.rates[index].id"
                        x-small
                        text
                        @click="copyRate(form.rates[index])"
                      >
                        <v-icon
                          x-small
                          left
                        >
                          fa-copy
                        </v-icon>
                        Создать копию
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </div>
              </template>
            </div>
            <template v-else>
              <div class="pa-5 text--disabled text-center">
                Нет добавленых ставок
              </div>
            </template>
          </div>
        </v-card-text>
      </v-card>
    </div>

    <TwCardSimpleFormSaveBlock
      class="tw-mt-[15px]"
      :loading="formProcessing"
      bg-color="transparent"
      :cancel-confirm="true"
      :is-sticky="false"
      :visible="true"
      @success="handleSubmit"
      @cancel="handleCancel"
    />
  </v-form>
</template>

<script>
import API from '@/Api/Mortgage/Program/index'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import CompanyAsyncSelect from '@/components/select/company/CompanyAsyncSelect'
import ProgramCategorySelect from '@/components/select/program-category/ProgramCategorySelect'
import InputPriceSpaced from '@/components/input/price/InputPriceSpaced'
import MortgageProgramRateForm from '@/views/Mortgage/Program/main/MortgageProgramRateForm'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'
import TwCardSimpleFormSaveBlock from '@/components/tw-ui/forms/TwCardSimpleFormSaveBlock.vue'

export default {
  name: 'MortgageProgramMainForm',
  components: {
    TwPanelContentBlockFieldView,
    MortgageProgramRateForm,
    InputPriceSpaced,
    ProgramCategorySelect,
    CompanyAsyncSelect,
    TwCardSimpleFormSaveBlock
  },
  mixins: [FormCreateEditMixin],
  props: {
    disableRedirect: {
      type: Boolean,
      default: false
    },
    defaultFormFields: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      API: API,
      form: {
        id: null,
        name: '',
        companyId: '',
        categoriesId: [],
        active: false,
        commissionRewardFirstPay: null,
        matCapitalFirstPay: false,
        sumMin: null,
        sumMax: null,
        termMin: null,
        termMax: null,
        rates: []
      },
      defaultRateObj: () => {
        return {
          rate: null,
          discountFlat: 0,
          discountElectronRegistration: 0,
          firstSumMin: null,
          firstSumMax: null
        }
      }
    }
  },
  created () {
    this.addRate()
  },
  methods: {
    handleSubmit () {
      console.warn(this.form)
      if (this.$refs.form.validate() && (Array.isArray(this.form.categoriesId) && this.form.categoriesId.length)) {
        if (this.formProcessing === true) {
          return
        }

        this.formProcessing = true
        const request = this.formType === 'create'
          ? API.store(this.form)
          : API.update(this.targetId, this.form)

        request.then(({ data }) => {
          if (this.formType === 'create') {
            this.$emit('create', data.data)
          } else {
            this.$emit('update', data.data)
          }
        }).finally(() => {
          this.formProcessing = false
        })
      }
    },
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          const adaperMap = {
            // from(show): to(send)
            company: 'companyId',
            categories: 'categoriesId'
          }
          for (const key in this.preset) {
            if (adaperMap[key] !== undefined) {
              if (adaperMap[key].indexOf('Id') !== -1 && (this.preset[key] && this.preset[key].id)) {
                this.$set(this.form, adaperMap[key], this.preset[key].id)
              } else if (Array.isArray(this.preset[key])) {
                this.$set(this.form, adaperMap[key], this.preset[key].map(item => item.id))
              }
            } else {
              this.$set(this.form, key, this.preset[key])
            }
          }
        }
        resolve()
      })
    },
    copyRate (item) {
      const copyItem = { ...item, ...{} }
      copyItem.id = null
      this.form.rates.unshift(copyItem)
    },
    removeRate (index) {
      this.form.rates.splice(index, 1)
    },
    addRate () {
      if (!Array.isArray(this.form.rates)) {
        this.form.rates = []
      }
      this.form.rates.unshift(this.defaultRateObj())
    },
    selectFirstCategory (data) {
      console.log('selectFirstCategory', data)
      if (!this.form.name) {
        this.form.name = data?.name || ''
      }
    }
  }
}
</script>
