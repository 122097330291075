<template>
  <button
    class="tw-py-[6px] tw-px-[14px] tw-ml-auto tw-text-an-flat-blue-btn tw-text-main-sm tw-font-medium hover:tw-underline"
    @click="request"
  >
    Написать письмо
  </button>
</template>

<script>
import { defineComponent } from 'vue'
import RequestToDeveloperAPI from '@/Api/Rtd/RequestToDeveloper/Management'
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'

export default defineComponent({
  name: 'DeveloperRequestEmailWrite',
  mixins: [ViewPanelShowMixin],
  props: {
    requestId: {
      type: Number,
      default: 0
    }
  },
  methods: {
    request () {
      if (!this.requestId) return
      RequestToDeveloperAPI.createEmail(this.requestId, {})
        .then(({ data: result }) => {
          this.openEmail(result.data?.id)
        })
        .catch((error) => {
          console.error(error)
        })
    },
    openEmail (id) {
      if (id) {
        this.showEmailCard({ id }, () => { /**/ }, true)
      } else {
        alert('None email ID')
      }
    }
  }
})
</script>

<style scoped>

</style>
