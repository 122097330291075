<template>
  <div :class="baseClasses">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'TwFlexCol',
  props: {
    baseClasses: {
      type: String,
      default: 'tw-flex tw-flex-col tw-gap-[14px]'
    }
  }
}
</script>

<style scoped>

</style>
