<template>
  <div
    class="footer-block"
    style="width: 100%"
  >
    <v-card
      v-if="pagination"
      class="my-1 d-flex align-center justify-center"
      flat
    >
      <div
        v-if="pagination.total"
        class="caption px-2 text-muted d-none d-md-block"
      >
        {{ pagination.total }} {{ $Global.textPlural(pagination.total, 'element') }}
      </div>
    </v-card>
    <div
      v-if="pagination && pageCount > 1"
      class="d-flex align-center justify-center"
    >
      <v-pagination
        :disabled="disabled"
        :value="pagination.current_page"
        :length="pageCount"
        :total-visible="totalVisible"
        @input="change"
      />

      <div
        v-if="showPerPageLimit"
        title="В разработке..."
        class="d-flex align-center"
      >
        <div class="mx-3 white--text d-none d-md-block">
          На странице
        </div>
        <v-select
          disabled
          style="max-width: 80px;"
          hide-details
          dense
          solo
          :value="pagination.per_page"
          :items="[10, 25, 50, 100]"
          label="На странице"
          @input="changePerPage"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DefaultListPagination',
  props: {
    pagination: {
      default: null
    },
    pageCount: {
      type: Number,
      default: 1
    },
    totalVisible: {
      type: Number,
      default: 7
    },
    showPerPageLimit: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    change (targetPageNumber) {
      this.$emit('change', targetPageNumber)
      this.$nextTick(this.scrollToTop)
    },
    changePerPage (number) {
      console.warn('changePerPage', number)
      this.$emit('change-per-page', number)
    },
    scrollToTop () {
      const block = document.getElementById('scrolling-content') || document.documentElement // целевой блок
      // console.warn('scrollToTop', block)
      const isVerticalScroll = block?.scrollHeight > block?.clientHeight // вертикальный скролл
      const isHorizontalScroll = block?.scrollWidth > block?.clientWidth // горизонтальный скролл
      console.log({ isVerticalScroll, isHorizontalScroll })
      if (isVerticalScroll || isHorizontalScroll) {
        block.scrollTo({
          top: 0,
          left: 0
          // behavior: 'smooth' // плавная прокрутка дергается - отключил
        })
      }
    }
  }
}
</script>
<style scoped>
.footer-block {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: space-around;
  align-items: stretch;
}
</style>
