<template>
  <div>
    <TwFlexCol>
      <TwFlexRow>
        <TwFlexCell>
          <TwInputText
            v-model="form.email"
            class="tw-flex-1"
            autocomplete="on"
            type="email"
            label="Email"
            placeholder="name@mail.ru"
            :disabled="formProcessing"
            :disable-error-border="false"
            :show-label="true"
            :persistent-label="true"
            :dense="true"
            :error-text="emailErrors"
          />
        </TwFlexCell>
        <TwFlexCell>
          <TwInputText
            ref="password"
            v-model="form.password"
            type="password"
            autocomplete="on"
            label="Пароль от почты"
            placeholder="Пароль от почты"
            :disabled="formProcessing"
            :disable-error-border="false"
            :show-label="true"
            :persistent-label="true"
            :dense="true"
            :error-text="passwordErrors"
          />
        </TwFlexCell>
      </TwFlexRow>
      <TwFlexRow>
        <TwFlexCell>
          <TwInputRadio
            v-model="form.protocolIncoming"
            :disabled="formProcessing"
            :dense="true"
            :show-label="true"
            label="Протокол входящей почты"
            :options="dictionary.protocol"
            :error-text="form.protocolIncoming ? '' : 'Обязательное поле'"
          />
        </TwFlexCell>
        <TwFlexCell>
          <TwInputRadio
            v-model="form.protectConnect"
            :disabled="formProcessing"
            :dense="true"
            :show-label="true"
            label="Протокол защищеного соединения"
            :options="dictionary.protect"
            :error-text="form.protectConnect ? '' : 'Обязательное поле'"
          />
        </TwFlexCell>
      </TwFlexRow>
      <TwFlexRow>
        <TwFlexCell>
          <TwInputText
            v-model="form.serverOutgoing"
            class="tw-flex-1"
            autocomplete="off"
            type="text"
            label="Сервер исходящей почты(smtp)"
            placeholder="smtp.mail.ru"
            :disabled="formProcessing"
            :disable-error-border="false"
            :show-label="true"
            :persistent-label="true"
            :dense="true"
          />
          <TwInputText
            v-model.number="form.portOutgoingServer"
            class="tw-flex-1"
            autocomplete="off"
            type="number"
            label="Порт сервера исходящей почты(smtp)"
            placeholder="465"
            :disabled="formProcessing"
            :disable-error-border="false"
            :show-label="true"
            :persistent-label="true"
            :dense="true"
          />
        </TwFlexCell>
        <TwFlexCell>
          <TwInputText
            v-model="form.serverIncoming"
            class="tw-flex-1"
            autocomplete="off"
            type="text"
            :label="`Сервер входящей почты(${form.protocolIncoming})`"
            placeholder="imap.mail.ru"
            :disabled="formProcessing"
            :disable-error-border="false"
            :show-label="true"
            :persistent-label="true"
            :dense="true"
          />
          <TwInputText
            v-model.number="form.portIncomingServer"
            class="tw-flex-1"
            autocomplete="off"
            type="number"
            :label="`Порт сервера входящей почты(${form.protocolIncoming})`"
            placeholder="993"
            :disabled="formProcessing"
            :disable-error-border="false"
            :show-label="true"
            :persistent-label="true"
            :dense="true"
          />
        </TwFlexCell>
      </TwFlexRow>
      <TwFlexRow>
        <TwFlexCell>
          <TwInputRadio
            v-model="form.useDefault"
            :disabled="formProcessing"
            :dense="true"
            :show-label="true"
            label="Использовать по умолчанию"
            :options="boolSelectList"
            :error-text="form.useDefault === null ? 'Выберите значение':''"
          />
        </TwFlexCell>
        <TwFlexCell />
      </TwFlexRow>
    </TwFlexCol>
    <EditFormFooter
      class="tw-mt-[20px]"
      :disabled="!isEdited || formProcessing || preset.system"
      :in-modal="true"
      @submit="handleSubmit"
      @cancel="$emit('cancel')"
    />
  </div>
</template>

<script>
import API from '@/Api/Email/Config'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import EditFormFooter from '@/components/form/EditFormFooter.vue'
import TwFlexCol from '@/components/tw-ui/ui-kit/flex-block/TwFlexCol.vue'
import TwFlexRow from '@/components/tw-ui/ui-kit/flex-block/TwFlexRow.vue'
import TwFlexCell from '@/components/tw-ui/ui-kit/flex-block/TwFlexCell.vue'
import TwInputText from '@/components/tw-ui/ui-kit/input/TwInputText.vue'
import TwInputRadio from '@/components/tw-ui/ui-kit/input/TwInputRadio.vue'

export default {
  name: 'EmailConfigForm',
  components: { EditFormFooter, TwFlexCol, TwFlexCell, TwFlexRow, TwInputText, TwInputRadio },
  mixins: [FormCreateEditMixin],
  props: {
    userId: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      API: API,
      form: {
        login: null,
        email: null,
        password: null,
        serverOutgoing: null,
        serverIncoming: null,
        portOutgoingServer: 0,
        portIncomingServer: 0,
        protocolIncoming: 'pop3',
        protectConnect: 'ssl',
        useDefault: true,
        userId: null
      },
      boolSelectList: [
        {
          label: 'Да',
          value: true
        },
        {
          label: 'Нет',
          value: false
        }
      ]
    }
  },
  computed: {
    emailErrors () {
      if (!this.form.email) {
        return 'Поле обязательное'
      } else if (!/^\S+@\S+\.\S+$/.test(this.form.email)) {
        return 'Неправильный формат E-mail'
      }
      return null
    },
    passwordErrors () {
      if (!this.form.password) {
        return 'Поле обязательное'
      } else if (this.form.password.length <= 6) {
        return 'Минимум 6 символов'
      }
      return null
    },
    targetId () {
      return this.id || null
    }
  },
  methods: {
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          for (const key in this.preset) {
            if (key === 'userId') {
              this.$set(this.form, key, this.userId || this.preset[key])
            } else {
              this.$set(this.form, key, this.preset[key])
            }
          }
        }
        resolve()
      })
    },
    handleSubmit () {
      if (this.formProcessing === true) {
        return
      }
      const formData = this.form
      // TODO Логин равен почте - сделано для уменьшения полей в форме
      formData.login = formData.email

      this.formProcessing = true
      const request = this.formType === 'create'
        ? API.store(formData)
        : API.update(this.targetId, formData)

      request.then(({ data }) => {
        if (this.formType === 'create') {
          this.$emit('create', data.data)
        } else {
          this.$emit('update', data.data)
          this.$nextTick(() => {
            this.fetchData()
          })
        }
      }).finally(() => {
        this.formProcessing = false
      })
    }
  }
}
</script>

<style scoped>

</style>
