<template>
  <div class="tw-bg-[url('@/assets/images/ui/background-crm.jpeg')] tw-block tw-absolute tw-z-0 tw-w-full tw-h-full tw-bg-cover">
    <div class="tw-absolute tw-z-20 tw-bg-opacity-50 tw-bg-slate-500 tw-top-0 tw-bottom-0 tw-right-0 tw-left-0 tw-backdrop-blur-sm">
      <div class="tw-max-w-[533px] tw-rounded-[10px] tw-px-[50px] tw-pt-[57px] tw-pb-[50px] tw-bg-an-flat-grey-11 tw-relative tw-w-11/12 md:tw-w-8/12 tw-top-1/3 tw-left-1/2 tw-transform -tw-translate-x-1/2 -tw-translate-y-1/3">
        <button
          v-if="showClose"
          :disabled="disabled"
          class="tw-absolute tw-top-6 tw-right-6 tw-transition tw-ease-in tw-delay-100 hover:tw-rotate-[90deg] disabled:tw-opacity-50"
          @click.stop="onClose"
        >
          <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1L15.0741 15.0741M1 15.0741L15.0741 1"
              stroke="#5C6775"
              stroke-width="2"
              stroke-linecap="round"
            />
          </svg>
        </button>
        <div>
          <h1 class="tw-font-semibold tw-text-[24px] tw-mb-[25px] tw-text-start">
            <slot name="title" />
          </h1>
          <slot name="content" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TwUnAuthWrapper',
  props: {
    showClose: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClose () {
      this.$emit('close', true)
    }
  }
}
</script>

<style scoped>

</style>
