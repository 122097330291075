<template>
  <div class="item-wrapper mt-5">
    <div
      :style="{backgroundColor: listColors[variant].bg}"
      class="item-icon"
    >
      <svg
        width="14"
        height="14"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.02615 12.8463L7.09096 13.7815C6.0978 13.7812 2.96597 13.7812 2.81216 13.7812V15.1875L7.67262 15.1886L9.02051 13.8407C10.3782 13.7684 11.6445 13.2055 12.6132 12.2367L17.9968 6.85315L15.0137 3.87003L9.63016 9.25358C8.66139 10.2223 8.09847 11.4886 8.02615 12.8463ZM15.0137 5.85875L16.0081 6.85315L11.6189 11.2423C11.0311 11.8301 10.2961 12.2174 9.49713 12.3697C9.64946 11.5707 10.0367 10.8358 10.6245 10.248L15.0137 5.85875Z"
          fill="white"
        />
        <path
          d="M2.81216 5.34375H8.43716V6.75H2.81216V5.34375Z"
          fill="white"
        />
        <path
          d="M2.81216 2.53125H11.2497V3.9375H2.81216V2.53125Z"
          fill="white"
        />
        <path
          d="M2.81216 8.15625H5.62466V9.5625H2.81216V8.15625Z"
          fill="white"
        />
        <path
          d="M2.81216 10.9688H5.62466V12.375H2.81216V10.9688Z"
          fill="white"
        />
        <path
          d="M14.0625 14.4844V12.7765L13.608 13.2311C13.3117 13.5273 12.9931 13.793 12.6562 14.0273V14.4844C12.6562 15.2751 12.9187 16.0058 13.361 16.5938H3.51562C2.35252 16.5938 1.40625 15.6475 1.40625 14.4844V1.40625H12.6562V4.23907L14.0625 2.83282V0H0V14.4844C0 16.4229 1.57711 18 3.51562 18H16.1719V16.5938C15.0088 16.5938 14.0625 15.6475 14.0625 14.4844Z"
          fill="white"
        />
      </svg>
    </div>
    <div
      v-if="item"
      class="card item-hovered-visible-block"
    >
      <div
        v-if="item"
        class="card-wrapper"
        style="width: 100%"
      >
        <div
          class="card-left"
          style="flex: auto"
        >
          <div class="card-header">
            <div class="card-header-info">
              <p class="card-title">
                <span>{{ item.attachEntity && item.attachEntity.label }}:</span>
              </p>
              <p class="card-time">
                {{ $Global.DateFormat.DateTimeShort(item.date) }}
              </p>
            </div>
          </div>
          <div class="card-notification-info tw-cursor-default">
            <div class="card-notification-text">
              <div class="tw-flex tw-flex-col tw-gap-[6px]">
                <div v-if="item.attachEntityData">
                  <div v-if="item.attachEntityData.service">
                    Тип заявки: <b>{{ item.attachEntityData.service.label }}</b>
                  </div>
                  <div>
                    Бесплатно: <b>{{ item.attachEntityData.free ? 'Да':'Нет' }}</b>
                  </div>
                  <div v-if="item.attachEntityData.contract">
                    Договор:
                    <ViewPanelLink
                      v-if="item.attachEntityData.contract.value"
                      :id="item.attachEntityData.contract.value"
                      component="deal-contract-show"
                    >
                      {{ item.attachEntityData.contract.label }}
                    </ViewPanelLink>
                  </div>
                  <div v-if="item.attachEntityData.freeCondition">
                    Причина бесп. услуги: <b>{{ item.attachEntityData.freeCondition.label }}</b>
                  </div>
                  <div v-if="item.attachEntityData.relatedContract">
                    Договор основание:
                    <ViewPanelLink
                      v-if="item.attachEntityData.relatedContract.value"
                      :id="item.attachEntityData.relatedContract.value"
                      component="deal-contract-show"
                    >
                      {{ item.attachEntityData.relatedContract.label }}
                    </ViewPanelLink>
                  </div>
                  <div v-if="item.attachEntityData.relatedDeal">
                    Заявка основание:
                    <ViewPanelLink
                      v-if="item.attachEntityData.relatedDeal.value"
                      :id="item.attachEntityData.relatedDeal.value"
                      component="deal-show"
                    >
                      #{{ item.attachEntityData.relatedDeal.label }}
                    </ViewPanelLink>
                  </div>
                  <div v-if="item.attachEntityData.relatedComplex">
                    ЖК:
                    <ViewPanelLink
                      v-if="item.attachEntityData.relatedComplex.value"
                      :id="item.attachEntityData.relatedComplex.value"
                      component="realty-complex-show"
                    >
                      {{ item.attachEntityData.relatedComplex.label }}
                    </ViewPanelLink>
                  </div>
                  <br>
                  <div v-if="item.attachEntityData.comment">
                    Комментарий: <b v-html="$Global.nl2br(item.attachEntityData.comment || '')" />
                  </div>
                  <br>
                  <template v-if="item.attachEntityData.form && Array.isArray(item.attachEntityData.form)">
                    <div
                      v-for="(value, index) in item.attachEntityData.form"
                      :key="index"
                    >
                      {{ value }}
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <EmployeeLinkBlock
              v-if="entityData.creatorId"
              :user-id="entityData.creatorId"
              title="Инициатор"
            />
          </div>
        </div>
        <div class="card-right">
          <ChronologyPinned
            v-if="!(variant === 'other' && item.pin)"
            :id="item.id"
            :pin-status="item.pin"
            @refresh="$emit('refresh')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { listColors } from '@/components/core/Chronology/ListConfig'
import ChronologyPinned from '@/components/core/Chronology/ChronologyPinned'
import EmployeeLinkBlock from '@/components/other/employee/EmployeeLinkBlock'
import ViewPanelLink from '../../ViewPanel/ViewPanelLink.vue'

export default {
  name: 'ChronologyDealRelatedServiceRequest',
  components: { EmployeeLinkBlock, ChronologyPinned, ViewPanelLink },
  props: {
    item: {
      type: Object,
      required: true
    },
    variant: {
      type: String,
      required: true // other, plan, pin
    }
  },
  computed: {
    listColors () {
      return listColors
    },
    entityData () {
      return this.item?.attachEntityData || null
    },
    oldValue () {
      return this.isPhoneNum(this.entityData?.oldValue) ? this.$Global.formatPhone(this.entityData.oldValue) : this.entityData.oldValue
    },
    value () {
      return this.isPhoneNum(this.entityData?.value) ? this.$Global.formatPhone(this.entityData.value) : this.entityData.value
    }
  },
  methods: {
    isPhoneNum (value) {
      return (/^\d+$/).test(value)
    }
  }
}
</script>

<style scoped>

</style>
