import { AxiosResponse } from 'axios'
import { AuthAxiosDefaultConfig } from '@/Api/Logger'
import HTTP from '@/Api/http'

const path = '/log'

interface LogFields {
  section?: string;
  channel?: string;
  message?: string;
  level?: number;
  context?: any;
}

export default {
  sendLog (data: LogFields): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.post(path, data, $config)
  }
}
