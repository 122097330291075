<template>
  <div
    class="tw-flex tw-flex-wrap tw-gap-[6px] tw-items-center"
    style="display: flex; place-items: center"
  >
    <TwSelect
      key="month"
      :options="monthOptions"
      placeholder="Выбор"
      :show-label="false"
      :dense="true"
      :persistent-label="false"
      :value="valueCalc[1]"
      @input="onInput($event, 1)"
    />
    <TwSelect
      key="year"
      :options="yearOptions"
      placeholder="Выбор"
      :show-label="false"
      :dense="true"
      :persistent-label="false"
      :value="valueCalc[0]"
      @input="onInput($event, 0)"
    />
  </div>
</template>

<script>
import TwSelect from '@/components/tw-ui/ui-kit/input/TwSelect.vue'

export default {
  name: 'ADateMonthSelect',
  components: { TwSelect },
  props: {
    value: {
      type: [Object, null],
      default: () => null
    }
  },
  computed: {
    valueCalc () {
      return [this.value?.value[0] || null, this.value?.value[1] || null]
    },
    yearOptions () {
      const years = []
      const currentYear = new Date().getFullYear()
      let startYear = currentYear - 5
      while (startYear < currentYear) {
        startYear++
        years.push({
          value: startYear.toString(),
          label: startYear
        })
      }
      return years.reverse()
    },
    monthOptions () {
      const list = new Array(12).fill(null)
      console.warn({ list })
      return list.map(function (mon, index) {
        const monthNum = index + 1
        return {
          value: monthNum < 10 ? `0${monthNum.toString()}` : monthNum.toString(),
          label: new Date(2000, index).toLocaleString('ru', { month: 'long' })
        }
      })
    }
  },
  methods: {
    onInput (value, index) {
      const newValue = this.value?.value
      newValue[index] = value
      this.$emit('input', newValue)
    }
  }
}
</script>
