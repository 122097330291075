import { AxiosResponse } from 'axios'
import HTTP from '../../../http'
import { AuthAxiosDefaultConfig } from '@/Api/Auth'

const path = '/reset_password'

export default {
  sendEmail (data: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.post(`${path}`, data, $config)
  },
  setNewPassword (data: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.put(`${path}`, data, $config)
  }
}
