<template>
  <APopoverWrapper
    ref="popover"
    :hide-close="true"
    :hide-arrow="true"
    :z-index="1"
    :offset="[0, 5]"
    content-classes="tw-border tw-border-solid tw-border-an-flat-dark-blue-link tw-text-an-flat-dark-blue-link tw-bg-an-flat-light-blue tw-py-[8px] tw-px-[10px] tw-text-[11px] tw-rounded-md"
    @onOpen="onOpen"
  >
    <template #trigger>
      <div class="tw-text-an-flat-dark-blue-link tw-flex tw-items-center tw-cursor-pointer hover:tw-underline">
        Комиссия
        <svg
          class="tw-ml-[5px]"
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.5 15C11.6421 15 15 11.6421 15 7.5C15 3.35786 11.6421 0 7.5 0C3.35786 0 0 3.35786 0 7.5C0 11.6421 3.35786 15 7.5 15ZM6.73289 8.50136C6.68506 8.70481 6.66115 8.91621 6.66115 9.13556H8.06843C8.06843 8.95118 8.07763 8.80813 8.09603 8.7064C8.11442 8.6015 8.17513 8.47434 8.27815 8.32493C8.33701 8.2391 8.4106 8.15804 8.4989 8.08174C8.59088 8.00227 8.69021 7.92121 8.79691 7.83856C8.90361 7.7559 9.01582 7.66054 9.13355 7.55245C9.38374 7.32357 9.58977 7.07402 9.75166 6.80381C9.91722 6.53361 10 6.23479 10 5.90736C10 5.68801 9.95585 5.47184 9.86755 5.25885C9.78293 5.04587 9.65784 4.85831 9.49227 4.69619C9.25313 4.44823 8.95327 4.27021 8.59272 4.16213C8.23584 4.05404 7.85504 4 7.45033 4C7.18543 4 6.92053 4.03338 6.65563 4.10014C6.39441 4.16689 6.15894 4.26703 5.94923 4.40055C5.68065 4.56267 5.46358 4.78043 5.29801 5.05381C5.13613 5.32402 5.03679 5.61807 5 5.93597H6.40177C6.39809 5.80245 6.422 5.68006 6.47351 5.5688C6.52502 5.45754 6.60596 5.36376 6.71634 5.28747C6.81199 5.21753 6.92789 5.16667 7.06402 5.13488C7.20383 5.09991 7.34915 5.08243 7.5 5.08243C7.68396 5.08243 7.85688 5.10786 8.01876 5.15872C8.18065 5.2064 8.3131 5.28906 8.41611 5.40668C8.48234 5.47343 8.52833 5.55291 8.55408 5.6451C8.57984 5.73728 8.59272 5.83583 8.59272 5.94074C8.59272 6.10286 8.5688 6.25545 8.52097 6.3985C8.47314 6.53837 8.3738 6.67984 8.22296 6.82289C8.039 6.99137 7.8716 7.12648 7.72075 7.2282C7.5699 7.32675 7.43377 7.42689 7.31236 7.52861C7.19095 7.62716 7.08057 7.76067 6.98124 7.92916C6.86718 8.104 6.7844 8.29473 6.73289 8.50136ZM6.67219 9.73638V11H8.06843V9.73638H6.67219Z"
            fill="#2D82D0"
          />
        </svg>
      </div>
    </template>
    <template #content>
      <div
        class=""
        style="max-width: 250px;"
      >
        <div
          v-if="loading"
          class="tw-py-[5px] tw-flex tw-justify-center tw-items-center"
        >
          <TwCircleLoader
            border-width="2px"
            width="20px"
            height="20px"
          />
        </div>
        <div v-else-if="viewInfo">
          <div
            v-if="viewInfo.commission"
            class=""
          >
            Сумма комиссии: <strong>{{ (viewInfo.commission || 0).toLocaleString('ru') }} ₽</strong>
          </div>
          <div
            v-if="viewInfo.description"
            v-html="$Global.nl2br(viewInfo.description)"
          />
        </div>
        <div
          v-else
          class="text-center"
        >
          Нет данных
        </div>
      </div>
    </template>
  </APopoverWrapper>
</template>

<script>
import APopoverWrapper from '@/components/filters/afilter/fields/apopover/APopoverWrapper.vue'
import API from '@/Api/Rtd/Company/DeveloperCommission'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'

export default {
  name: 'DeveloperCommissionPopoverInfo',
  components: { TwCircleLoader, APopoverWrapper },
  props: {
    objectInfo: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      result: null
    }
  },
  computed: {
    viewInfo () {
      return this.result || null
    }
  },
  methods: {
    onOpen () {
      this.result = null
      this.loading = true

      API.calculateByRealtyObjectId(this.objectInfo.id)
        .then(({ data: result }) => {
          console.warn(this.$options.name, result)
          this.result = result?.data || null
        })
        .catch((error) => {
          console.error(this.$options.name, error)
          this.result = 'error'
        })
        .finally(() => {
          this.loading = false
          this.$refs?.popover?.onUpdate()
        })
    }
  }
}
</script>

<style scoped>

</style>
