<template>
  <div
    class="tw-select-none tw-relative"
    :class="itemWrapperClass"
    :title="item.name"
  >
    <component
      :is="linkUrl ? 'a' : 'div'"
      :href="linkUrl || ''"
      class="tw-group tw-flex tw-gap-0 tw-font-manrope tw-font-semibold"
      :class="[fontSize, iconMarginClass, itemHeight, itemActiveClass]"
      @click.prevent="clickToMenuItem"
    >
      <div
        class="tw-relative tw-w-[42px] tw-flex tw-justify-center tw-items-center"
        :title="item.routeName"
      >
        <TwIconMenu
          :icon-name="item.icon || item.routeName"
          :classes="iconActiveClass"
          :classes-stroke="iconActiveClassStroke"
        />
        <div
          v-if="item.plannedKey && userPlanned[item.plannedKey]"
          class="tw-w-[20px] tw-h-[16px] tw-absolute tw-top-[10px] tw-right-0 tw-bg-an-flat-red-main tw-text-white tw-text-main-xs tw-font-semibold tw-rounded-full tw-text-center"
        >
          {{ userPlanned[item.plannedKey] }}
        </div>
      </div>
      <div
        v-if="!isMiniLeftPanel"
        class="tw-flex-1 tw-flex tw-justify-start tw-items-center tw-min-w-0"
        :data-item-path="item.path"
      >
        <div class="tw-flex-1 tw-flex tw-truncate">
          <span class="tw-truncate">
            {{ item.name }}
          </span>
          <span
            v-if="isMultiOneLevelItem"
            class="tw-ml-1 tw-rounded-full tw-w-[22px] tw-h-[22px] tw-cursor-pointer tw-flex tw-justify-center tw-items-center"
            :class="isOpenChildren ? 'tw-bg-an-flat-red-main' : 'tw-bg-an-flat-grey-11'"
            @click.stop="isOpenChildren = !isOpenChildren"
          >
            <svg
              v-if="isOpenChildren"
              width="8"
              height="8"
              viewBox="0 0 8 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.1718 1.1718C1.56232 0.781274 2.19549 0.781274 2.58601 1.1718L6.82865 5.41444C7.21918 5.80496 7.21918 6.43813 6.82865 6.82865C6.43813 7.21918 5.80496 7.21918 5.41444 6.82865L1.1718 2.58601C0.781275 2.19549 0.781275 1.56232 1.1718 1.1718Z"
                fill="white"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.8282 1.1718C7.21873 1.56232 7.21873 2.19549 6.8282 2.58601L2.58556 6.82865C2.19504 7.21918 1.56187 7.21918 1.17135 6.82865C0.780823 6.43813 0.780823 5.80496 1.17135 5.41444L5.41399 1.1718C5.80451 0.781275 6.43768 0.781275 6.8282 1.1718Z"
                fill="white"
              />
            </svg>
            <svg
              v-else
              width="8"
              height="8"
              viewBox="0 0 8 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4 0.000488238C4.55228 0.000488262 5 0.448204 5 1.00049L5 7.00049C5 7.55277 4.55228 8.00049 4 8.00049C3.44771 8.00049 3 7.55277 3 7.00049L3 1.00049C3 0.448203 3.44772 0.000488213 4 0.000488238Z"
                fill="#515960"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8 4.00049C8 4.55277 7.55228 5.00049 7 5.00049L1 5.00049C0.447715 5.00049 3.91405e-08 4.55277 8.74228e-08 4.00049C1.35705e-07 3.4482 0.447715 3.00049 1 3.00049L7 3.00049C7.55228 3.00049 8 3.4482 8 4.00049Z"
                fill="#515960"
              />
            </svg>
          </span>
        </div>
        <slot name="badge">
          <!--    Бадж если нужен      -->
        </slot>
      </div>
    </component>
    <div
      v-if="isOpenChildren && isChildren"
      ref="children"
      class="tw-overflow-auto"
    >
      <TwSidebarMainMenuItem
        v-for="(menuItem, index) in item.children"
        :key="index"
        :item="menuItem"
        :index="index"
        :level="level + 1"
        :is-mini-left-panel="isMiniLeftPanel"
      />
    </div>
  </div>
</template>

<script>
import TwIconMenu from '@/components/tw-ui/menu/TwIconMenu.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'TwSidebarMainMenuItem',
  components: { TwIconMenu },
  props: {
    item: {
      type: Object,
      required: true
    },
    level: {
      type: Number,
      default: 0
    },
    index: {
      type: Number,
      required: true
    },
    navFrom: {
      type: String,
      default: 'routeName',
      validator (value) {
        // The value must match one of these strings
        return ['routeName', 'path'].includes(value)
      }
    },
    isMiniLeftPanel: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      isOpenChildren: false
    }
  },
  methods: {
    clickToMenuItem () {
      if (this.isChildren) {
        this.isOpenChildren = !this.isOpenChildren
        if (this.isOpenChildren) {
          this.$nextTick(() => {
            const lastChildItem = this.$refs.children?.lastChild || null
            // console.warn('children', lastChildItem)
            if (lastChildItem) {
              const observer = new IntersectionObserver((entries) => {
                // console.warn('children entries', entries[0].isIntersecting)
                if (entries[0].isIntersecting) {
                  // ничего не делаю
                } else {
                  // не видно - значит нужно прокрутить в область видимости
                  lastChildItem.scrollIntoView({ block: 'end', behavior: 'smooth' })
                }
                observer.unobserve(lastChildItem)
              })
              observer.observe(lastChildItem)
            }
          })
        }
      } else {
        this.$router.push({
          ...(this.navFrom === 'routeName') ? {
            name: this.item?.routeName
          } : {
            path: this.item?.path
          }
        }).catch((error) => {
          console.log('Ошибка перехода: ' + error)
        })
      }
    }
  },
  computed: {
    ...mapGetters({
      userPlanned: 'profile/userPlanned'
    }),
    linkUrl () {
      // ссылку формирую только на конечные пункты меню - тоесть для тех у кого нет вложенности
      const routerLinkObject = this.isChildren ? null : this.$router.resolve({
        ...(this.navFrom === 'routeName') ? {
          name: this.item?.routeName
        } : {
          path: this.item?.path
        }
      })
      return routerLinkObject?.href || null
    },
    isMatched () {
      return this.$route.matched?.some(({ name }) => {
        return name === this.item.routeName
      })
    },
    isChildren () {
      return Array.isArray(this.item.children) && this.item.children.length
    },
    isMultiOneLevelItem () {
      return this.isChildren && this.level === 1
    },
    fontSize () {
      if (this.level === 0) {
        return 'tw-text-[16px]'
      }
      return 'tw-text-[14px]'
    },
    itemHeight () {
      if (this.level === 0) {
        return 'tw-h-[52px]'
      }
      return 'tw-h-[42px]'
    },
    iconMarginClass () {
      if (!this.isMiniLeftPanel && this.level === 1) {
        return 'tw-ml-[20px]'
      } else if (!this.isMiniLeftPanel && this.level === 2) {
        return 'tw-ml-[40px]'
      }
      return 'tw-ml-0'
    },
    itemActiveClass () {
      return this.isMatched ? '!tw-cursor-default !tw-text-black' : 'tw-cursor-pointer !tw-text-gray-500 hover:!tw-text-black'
    },
    iconActiveClass () {
      return this.isMatched ? 'tw-fill-an-flat-red-main' : 'group-hover:tw-fill-an-flat-red-main tw-fill-an-flat-grey-3'
    },
    iconActiveClassStroke () {
      return this.isMatched ? 'tw-stroke-an-flat-red-main' : 'group-hover:tw-stroke-an-flat-red-main tw-stroke-an-flat-grey-3'
    },
    itemWrapperClass () {
      return Array.isArray(this.item.children) && this.item.children.length && this.level === 1 && this.isOpenChildren ? 'tw-bg-an-flat-red-1 tw-rounded-[10px]' : this.level === 2 && this.isMatched ? 'tw-bg-an-flat-red-2 tw-text-an-flat-red-main last:tw-rounded-b-[10px]' : 'tw-text-black'
    },
    isOpened: {
      get () {
        return this.isMatched || this.isOpenChildren
      },
      set (value) {
        this.isOpenChildren = value
      }
    }
  },
  watch: {
    isMatched: {
      handler (newValue) {
        this.isOpenChildren = !!newValue
      },
      immediate: true
    }
  }
}
</script>

<style scoped>

</style>
