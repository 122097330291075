<template>
  <div>
    <div
      v-if="loading"
      class="tw-w-full tw-p-[6px]"
    >
      <TwCircleLoader />
    </div>
    <div
      v-else-if="viewInfo"
      class="tw-cursor-pointer"
      @click.stop="onClickToBlock"
    >
      <div
        class="tw-mt-[10px] tw-relative tw-rounded-md tw-px-[14px] tw-py-[10px] tw-flex tw-gap-[8px]"
        :class="requiredView ? 'tw-bg-an-flat-red-main/10':'tw-bg-an-flat-blue-bg-block'"
      >
        <svg
          width="30"
          height="37"
          viewBox="0 0 30 37"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 3.09937C6.75 3.09937 0 9.84937 0 18.0994C0 26.3494 6.75 33.0994 15 33.0994C23.25 33.0994 30 26.3494 30 18.0994C30 9.84937 23.25 3.09937 15 3.09937ZM15 7.59937C17.55 7.59937 19.5 9.54937 19.5 12.0994C19.5 14.6494 17.55 16.5994 15 16.5994C12.45 16.5994 10.5 14.6494 10.5 12.0994C10.5 9.54937 12.45 7.59937 15 7.59937ZM15 28.8994C11.25 28.8994 7.95 26.9494 6 24.0994C6 21.0994 12 19.4494 15 19.4494C18 19.4494 24 21.0994 24 24.0994C22.05 26.9494 18.75 28.8994 15 28.8994Z"
            fill="#C7D6E8"
          />
        </svg>
        <APopoverWrapper
          ref="popoverWrapper"
          no-close-click-outside
          :disabled="false"
          :z-index="9999"
          @onClose="$emit('need-view', false)"
        >
          <template #trigger>
            <!-- ОБЩАЯ ИНФОРМАЦИЯ -->
            <div>
              <a class="tw-flex tw-underline hover:tw-no-underline">
                <template v-if="nameInLabel && viewInfo.person">
                  {{ $Global.peopleName(viewInfo.person, 'fi') }} найдено:
                </template>
                <template v-else>
                  У клиента найдено:
                </template>
                <div
                  v-if="Array.isArray(viewInfo.leads)"
                  class="mx-1"
                >{{ viewInfo.leads.length }} {{ $Global.textPlural(viewInfo.leads.length, 'lead') }},</div>
                <div
                  v-if="Array.isArray(viewInfo.deals)"
                  class="mx-1"
                >{{ viewInfo.deals.length }} {{ $Global.textPlural(viewInfo.deals.length, 'deal') }}</div>
              </a>
              <div
                v-if="requiredView"
                class="error--text caption"
              >
                * Обязательно к ознакомлению
              </div>
            </div>
          </template>
          <template #content>
            <div
              class="custom-component-table caption"
              style="width: 100%; min-width: 300px; max-width: 800px; max-height: 300px; background-color: white; display: flex; flex-direction: column; overflow: auto"
            >
              <!-- СПИСОК ЛИДОВ -->
              <label class="font-weight-bold">Лиды:</label>
              <table
                v-if="viewInfo.leads.length"
                class="mb-3 ml-3"
              >
                <tr>
                  <th />
                  <th>№</th>
                  <th>Статус</th>
                  <th>Ответственный</th>
                  <th>Дата создания</th>
                </tr>
                <tr
                  v-for="(lead, index) in viewInfo.leads"
                  :key="index"
                  class="my-2"
                  @click.stop="showLeadCard({ id: lead.id })"
                >
                  <td
                    style="width: 1px"
                    :style="{ backgroundColor: lead.status && lead.status.color}"
                  />
                  <td class="text-center">
                    <ViewPanelLink
                      :id="lead.id"
                      label="Лид"
                      component="lead-show"
                    >
                      #{{ lead.id }}
                    </ViewPanelLink>
                  </td>
                  <td class="text-center">
                    {{ lead.status && lead.status.name }}
                  </td>
                  <td class="text-center">
                    <ViewPanelLink
                      v-if="lead.responsibleUser && typeof lead.responsibleUser.id === 'number'"
                      :id="lead.responsibleUser.id"
                      label="Пользователь"
                      component="employee-show"
                      :disabled="!$store.getters['users/getUserById'](lead.responsibleUser.id)"
                    >
                      <span v-if="lead.responsibleUser.fio">{{ lead.responsibleUser.fio }}</span>
                      <span v-else-if="$store.getters['users/getUserById'](lead.responsibleUser.id)">
                        {{ $Global.peopleName($store.getters['users/getUserById'](lead.responsibleUser.id), 'fi') }}
                      </span>
                      <span v-else>
                        Загрузка
                      </span>
                    </ViewPanelLink>
                    <span v-else>
                      -
                    </span>
                  </td>
                  <td class="text-center">
                    {{ $Global.DateFormat.DateTimeShort(lead.createdAt) }}
                  </td>
                </tr>
              </table>
              <div v-else>
                Отсутствуют
              </div>
              <!-- СПИСОК ЗАЯВОК -->
              <label class="font-weight-bold">Заявки:</label>
              <table
                v-if="viewInfo.deals.length"
                class="mb-3 ml-3"
              >
                <tr>
                  <th />
                  <th>№ / Название</th>
                  <th>Тип</th>
                  <th>Статус</th>
                  <th>Ответственный</th>
                  <th>Дата создания</th>
                </tr>
                <tr
                  v-for="(deal, index) in viewInfo.deals"
                  :key="index"
                  @click.stop="showDealCard(deal.id)"
                >
                  <td
                    style="width: 1px"
                    :style="{ backgroundColor: deal.status && deal.status.color}"
                  />
                  <td style="min-width: 200px">
                    <ViewPanelLink
                      :id="deal.id"
                      label="Заявка"
                      component="deal-show"
                    >
                      #{{ deal.id }}
                      {{ deal.name }}
                    </ViewPanelLink>
                  </td>
                  <td class="text-center">
                    {{ deal.type && deal.type.label }}
                    <div
                      v-if="deal.subtype"
                      class="text-muted"
                    >
                      {{ deal.subtype.name }}
                    </div>
                  </td>
                  <td class="text-center">
                    {{ deal.status && deal.status.name }}
                  </td>
                  <td
                    class="text-center"
                    style="min-width: 120px"
                  >
                    <ViewPanelLink
                      v-if="deal.responsibleUser && typeof deal.responsibleUser.id === 'number'"
                      :id="deal.responsibleUser.id"
                      label="Пользователь"
                      component="employee-show"
                      :disabled="!$store.getters['users/getUserById'](deal.responsibleUser.id)"
                    >
                      <span v-if="deal.responsibleUser.fio">{{ deal.responsibleUser.fio }}</span>
                      <span v-else-if="$store.getters['users/getUserById'](deal.responsibleUser.id)">
                        {{ $Global.peopleName($store.getters['users/getUserById'](deal.responsibleUser.id), 'fi') }}
                      </span>
                      <span v-else>
                        Загрузка
                      </span>
                    </ViewPanelLink>
                    <span v-else>
                      -
                    </span>
                  </td>
                  <td
                    class="text-center"
                    style="min-width: 120px"
                  >
                    {{ $Global.DateFormat.DateTimeShort(deal.createdAt) }}
                  </td>
                </tr>
              </table>
              <div v-else>
                Отсутствуют
              </div>
            </div>
          </template>
        </APopoverWrapper>
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/Api/Crm/Person/index'
import APopoverWrapper from '@/components/filters/afilter/fields/apopover/APopoverWrapper'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink'
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'

export default {
  name: 'PersonLeadDealInfo',
  components: { TwCircleLoader, ViewPanelLink, APopoverWrapper },
  mixins: [ViewPanelShowMixin],
  props: {
    requiredView: {
      type: Boolean,
      default: false
    },
    nameInLabel: {
      type: Boolean,
      default: false
    },
    personId: {
      type: [Number, String],
      default: 0
    }
  },
  data () {
    return {
      loading: null,
      info: null
    }
  },
  computed: {
    viewInfo () {
      return this.info && (this.info?.leads?.length || this.info?.deals?.length) ? this.info : null
    }
  },
  watch: {
    personId: {
      handler (newValue, oldValue) {
        if (!newValue) {
          this.$emit('need-view', false)
        }
        if (newValue !== oldValue) {
          this.fetchInfo()
        }
      },
      immediate: true
    }
  },
  methods: {
    onClickToBlock () {
      this.$refs.popoverWrapper?.onOpen()
    },
    fetchInfo () {
      console.warn(`${this.$options.name} - fetchInfo: `, this.personId)
      if (this.loading || !this.personId) return

      this.loading = true

      API.getLeadDeal(this.personId)
        .then(({ data }) => {
          console.warn(data)
          this.$set(this, 'info', data.data || null)
          if (this.info && (this.info?.leads?.length || this.info?.deals?.length)) {
            this.$emit('need-view', true)
          } else {
            this.$emit('need-view', false)
          }
        })
        .catch((error) => {
          console.error({ error })
          this.$emit('need-view', false)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped lang="less">
.custom-component-table {
  table, th, td {
    border: 1px solid lightgrey;
    border-collapse: collapse;
  }
  td {
    margin: 2px 5px;
    padding: 5px 3px;
    vertical-align: center;
  }
}
</style>
