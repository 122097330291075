<template>
  <TwUnAuthWrapper
    :show-close="!token"
    @close="$router.back()"
  >
    <template #title>
      <div v-if="token">
        Обновление пароля
      </div>
      <div v-else>
        Восстановление пароля
        <div
          v-if="!result"
          class="tw-text-[12px] tw-font-light tw-text-an-flat-grey-4"
        >
          Введите <strong>email</strong>, на него мы отправим ссылку для восстановления пароля
        </div>
      </div>
    </template>
    <template #content>
      <!-- Форма Нового пароля -->
      <div v-if="token">
        <form
          class="tw-flex tw-flex-col tw-max-w-full"
          @submit.prevent="onSubmitNewPassword"
        >
          <!--     Скрытое поля с мейлом нужно для того чтобы привязать новый пароль к мылу при сохранении в браузере     -->
          <TwUnAuthInputText
            :value="email"
            class="tw-hidden"
            label-text="E-mail"
            autocomplete="email"
            type="email"
            placeholder="Email"
          />
          <TwUnAuthInputText
            v-model="password"
            :required="true"
            label-text="Новый пароль"
            placeholder="Новый пароль"
            autocomplete="new-password"
            type="password"
            :error-text="error || (($v.password && !$v.password.required) ? 'Обязательное поле' : '')"
            :disabled="formProcessing"
            @input="$v.$touch"
          />
          <TwUnAuthInputText
            v-model="password_confirmation"
            :required="true"
            label-text="Повторите новый пароль"
            placeholder="Повторите новый пароль"
            autocomplete="new-password"
            type="password"
            class="tw-mb-[30px]"
            :error-text="error || (($v.password_confirmation && !$v.password_confirmation.samePass) ? 'Пароли должны совпадать' : '')"
            :disabled="formProcessing"
            @input="$v.$touch"
          />
          <button
            :disabled="formProcessing"
            type="submit"
            class="tw-transition tw-ease-in tw-delay-100 tw-bg-an-flat-blue-dark-2 tw-text-white tw-p-[15px] tw-rounded-[10px] tw-text-center tw-w-full tw-font-bold tw-text-[14px] hover:tw-bg-an-flat-blue-dark-1 hover:tw-text-white disabled:tw-opacity-50"
          >
            {{ formProcessing ? 'Подождите' : 'Назначить новый пароль' }}
          </button>
        </form>
      </div>
      <!-- Форма EMAIL -->
      <div v-else>
        <form
          v-if="!result"
          ref="formEmail"
          class="tw-flex tw-flex-col tw-max-w-full"
          @submit.prevent="onSubmitEmail"
        >
          <TwUnAuthInputText
            ref="email"
            v-model="email"
            label-text="E-mail"
            type="email"
            autocomplete="email"
            placeholder="Email"
            :required="true"
            :error-text="error || (($v.email && !$v.email.required) ? 'Обязательное поле' : '')"
            :disabled="formProcessing"
            @input="$v.$touch"
          />
          <button
            :disabled="formProcessing"
            type="submit"
            class="tw-transition tw-ease-in tw-delay-100 tw-bg-an-flat-blue-dark-2 tw-text-white tw-p-[15px] tw-rounded-[10px] tw-text-center tw-w-full tw-font-bold tw-text-[14px] hover:tw-bg-an-flat-blue-dark-1 hover:tw-text-white disabled:tw-opacity-50"
          >
            {{ formProcessing ? 'Подождите' : 'Восстановить пароль' }}
          </button>
        </form>
        <div
          v-else
          class="tw-text-center tw-text-green-600"
        >
          <div>
            Письмо отправлено, проверьте почтовый ящик <strong>{{ email }}</strong>
          </div>
          <div class="tw-text-[12px] tw-font-light tw-text-an-flat-grey-4">
            Перейдите по ссылке из письма чтобы назначить новый пароль
          </div>
        </div>
      </div>
    </template>
  </TwUnAuthWrapper>
</template>

<script>
import TwUnAuthWrapper from '@/components/tw-ui/un-auth-ui/TwUnAuthWrapper.vue'
import resetPassword from '@/Api/Auth/User/ResetPassword'
import TwUnAuthInputText from '@/components/tw-ui/un-auth-ui/TwUnAuthInputText.vue'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'TwResetView',
  components: { TwUnAuthInputText, TwUnAuthWrapper },
  data () {
    return {
      email: '',
      password: '',
      password_confirmation: '',

      formProcessing: false,
      token: '',

      result: false,
      error: null
    }
  },
  validations () {
    if (this.token) {
      return {
        password: {
          required
        },
        password_confirmation: {
          samePass: function () {
            return this.password === this.password_confirmation
          }
        }
      }
    }
    return {
      email: {
        required
      }
    }
  },
  created () {
    // при наличии токена в параметрах - меняю интерфейс на ввод нового пароля
    if (this.$route?.query?.token) {
      // console.log(this.$route.query.token)
      this.token = this.$route.query.token
    }
    // при наличии email в параметрах - подставляю email
    if (this.$route?.query?.email) {
      // console.log(this.$route.query.email)
      this.email = this.$route.query.email
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.$refs?.email?.focus()
    })
  },
  methods: {
    resetResults () {
      this.result = false
      this.error = null
    },
    onSubmitNewPassword () {
      this.$v.$reset()
      this.$v.$touch()
      this.error = null
      console.warn('onSubmitNewPassword', this.$v, this.$v.$invalid)
      if (!this.$v.$invalid) {
        this.resetResults()
        this.formProcessing = true
        resetPassword.setNewPassword({
          token: this.token,
          password: this.password,
          password_confirmation: this.password_confirmation
        })
          .then(({ data }) => {
            console.warn(this.$options.name, data)
            this.result = data.result
            this.error = data.error
            if (!data.error && data.result) {
              this.$router.push('/')
            }
          })
          .catch((error) => {
            console.error(this.$options.name, error.toString())
            this.error = error.toString()
          })
          .finally(() => {
            this.formProcessing = false
          })
      }
    },
    onSubmitEmail () {
      console.warn('onSubmitEmail')
      this.$v.$reset()
      this.$v.$touch()
      this.error = null
      if (!this.$v.$invalid) {
        this.resetResults()
        this.formProcessing = true
        resetPassword.sendEmail({
          email: this.email
        })
          .then(({ data }) => {
            console.warn(this.$options.name, data)
            this.result = data.result
            this.error = data.error
          })
          .catch((error) => {
            console.error(this.$options.name, error.toString())
            this.error = error.toString()
          })
          .finally(() => {
            this.formProcessing = false
          })
      }
    }
  }
}
</script>
