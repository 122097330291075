import { AxiosResponse } from 'axios'
import HTTP from '@/Api/http'
const path = '/person/black_list'

export default {
  create (): Promise<AxiosResponse> {
    const $config = {}
    return HTTP.get(`${path}/create`, $config)
  },
  store (data: object): Promise<AxiosResponse> {
    return HTTP.post(`${path}`, data)
  },
  edit (id: number): Promise<AxiosResponse> {
    const $config = {}
    return HTTP.get(`${path}/${id}`, $config)
  },
  update (id: number, data: object): Promise<AxiosResponse> {
    return HTTP.put(`${path}/${id}`, data)
  },
  show (id: number): Promise<AxiosResponse> {
    const $config = {}
    return HTTP.get(`${path}/${id}`, $config)
  },
  delete (id: number): Promise<AxiosResponse> {
    const $config = {}
    return HTTP.delete(`${path}/${id}`, $config)
  }
}
