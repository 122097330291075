import { TBaseTableHeaderItem, tableItemStyleOne } from '@/components/tw-ui/table/TableTypes'

const options: TBaseTableHeaderItem[] = [
  {
    ...tableItemStyleOne,
    text: '',
    dataKey: 'menu',
    colStyle: 'width: 50px;'
  },
  {
    ...tableItemStyleOne,
    text: 'Дата',
    dataKey: 'date',
    colStyle: 'width: 130px;',
    align: 'center'
  },
  {
    ...tableItemStyleOne,
    text: 'Файлы',
    dataKey: 'files',
    colStyle: 'width: auto;'
  }
]

export default options
