const listColors = {
  other: {
    bg: '#2fc6f5'
  },
  plan: {
    bg: '#9ece00',
    bgBody: '#fffeef'
  },
  pin: {
    bl: '#bfe059',
    bg: '#2fc6f5',
    bgBody: '#f7fbe9'
  }
}

export {
  listColors
}
