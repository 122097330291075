<template>
  <viewer
    ref="viewer"
    :images="images"
    :options="options"
    :rebuild="true"
    :static="false"
    class="viewer"
    @inited="inited"
  >
    <img
      v-for="(src, index) in images"
      :key="src+'-'+index"
      :src="src"
      class="image"
    >
  </viewer>
</template>

<script>
import 'viewerjs/dist/viewer.css'
import { component as Viewer } from 'v-viewer'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'ImageViewer',
  components: { Viewer },
  methods: {
    ...mapMutations({
      clearImages: 'imageViewer/CLEAR_IMAGES'
    }),
    inited (viewer) {
      // FIX - viewer.js bug - добавляется отступ справа
      // поэтому переопределю методы открытия и закрытия
      viewer.open = () => {
        document.body.classList.toggle('viewer-open')
      }
      viewer.close = () => {
        document.body.classList.toggle('viewer-open')
      }

      this.$viewer = viewer
      this.$viewer.view(this.startViewIndex)
    }
  },
  computed: {
    ...mapGetters({
      startViewIndex: 'imageViewer/getStartIndex',
      images: 'imageViewer/getImages'
    }),
    options () {
      return {
        // view: (e) => {
        // if (this.$viewer) {
        //   this.$viewer.img_link = this.filesInfo
        //   this.$viewer.img_index = e.detail.index
        // }
        // },
        hidden: () => {
          // очищаю хранилище с изображениями после скрытия интерфейса
          this.clearImages()
        },
        inline: false,
        button: true,
        navbar: this.images.length > 1,
        // title: (e) => {
        //   console.log('title', e, this.$viewer)
        //   return 'Заголовок фото'
        // if (this.$viewer.img_link) {
        //   return this.$viewer.img_link[this.$viewer.img_index] ? this.$viewer.img_link[this.$viewer.img_index].fileName : 'FileName Not Found'
        // } else {
        //   return '' // без названия
        // }
        // },
        toolbar: {
          zoomIn: true,
          zoomOut: true,
          oneToOne: true,
          reset: true,
          prev: this.images.length > 1,
          play: {
            show: this.images.length > 1,
            size: 'large'
          },
          next: this.images.length > 1,
          rotateLeft: true,
          rotateRight: true,
          flipHorizontal: true,
          flipVertical: true,

          download: () => {
            // console.warn(this.$options.name, 'DOWNLOAD')
            const a = document.createElement('a')
            a.setAttribute('target', '_blank')
            a.setAttribute('title', 'Скачать')
            a.download = this.$viewer.image.src
            a.href = this.$viewer.image.src
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
          }
        },
        tooltip: true,
        movable: false,
        zoomable: true,
        rotatable: true,
        scalable: true,
        transition: true,
        fullscreen: true,
        keyboard: true
        // url: 'data-source'
      }
    }
  },
  created () {
    // отслеживаю выполнения экшена добавления изображений
    const unsubscribe = this.$store.subscribeAction((action) => {
      if (action.type === 'imageViewer/showImages') {
        // console.log(state)
        // console.log(action.type)
        // console.log(action.payload)
        // после выполнения экшена открываю показ изображений
        this.$viewer.show()
      }
    })

    this.$on('hook:beforeDestroy', () => {
      // для остановки отслеживания нужно вызвать unsubscribe
      unsubscribe()
    })
  }
}
</script>

<style>
.image {
  display: none;
}
.viewer-download::before {
  background-position: 2px 2px;
  content: 'Download';
}
.viewer-download::before {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath d='M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z' fill='white'/%3e%3cpath d='M0 0h24v24H0z' fill='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  color: transparent;
  display: block;
  font-size: 0;
  height: 18px;
  line-height: 0;
  width: 16px;
}
</style>
