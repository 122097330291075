<template>
  <div>
    <v-progress-circular
      v-if="loading"
      size="50"
      color="primary"
      indeterminate
    />
    <v-simple-table dense>
      <template #default>
        <tbody>
          <tr
            v-for="email in list"
            :key="email.id"
          >
            <!--          <td></td>-->
            <td class="caption">
              {{ email.emailFolder.type && email.emailFolder.type.label }}
            </td>
            <td class="caption">
              <div
                v-for="(contact, index) in email.contacts"
                :key="contact.id+'_'+index"
                class="my-1"
              >
                <div
                  v-if="contact.person"
                  :key="contact.id"
                >
                  {{ $Global.peopleName(contact.person, 'fi') }}
                </div>
                <div
                  v-else
                  :key="contact.id"
                  class="d-flex align-center"
                >
                  <PersonNameLetters
                    class="mr-1"
                    :width="30"
                    :height="30"
                    :text="contact.name || contact.email || 'none'"
                  />
                  {{ contact.name }}
                  <span v-text="`<${contact.email}>`" />
                </div>
              </div>
            </td>
            <td class="caption">
              <div
                class="panel-view-link"
                @click="show(email)"
              >
                {{ email.theme }}
              </div>
            </td>
            <td class="caption text-right">
              {{ $Global.DateFormat.DateTimeShort(email.dateMailBox || email.updatedAt) }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import API from '@/Api/Email/Mail'
import PersonNameLetters from '@/components/other/person/PersonNameLetters'
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'

export default {
  name: 'EmailItemChildrens',
  components: { PersonNameLetters },
  mixins: [ViewPanelShowMixin],
  props: {
    id: {
      required: true
    }
  },
  data () {
    return {
      loading: false,
      list: []
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    show (item) {
      console.log('show', item)
      this.showEmailCard(
        {
          id: item.id
        },
        () => {
          console.warn(this.$options.name, 'CALLBACK showEmailCard')
          // this.fetch()
        })
    },
    getList () {
      if (this.loading) return
      this.loading = true
      API.children(this.id)
        .then(({ data }) => {
          console.warn('getList ', data.data)
          this.list = data.data || []
        })
        .catch((error) => {
          console.error('getList ', error)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
