<template>
  <div
    v-if="loading"
    class="tw-text-center"
  >
    <TwCircleLoader />
  </div>
  <div
    v-else
    class="tw-mt-3 tw-max-w-[1400px]"
  >
    <TwPanelContentBlock class="tw-mb-[6px]">
      <template #title>
        <div>Информация по результату сделки</div>
      </template>
      <template #action>
        <TwPanelContentBlockActionButton
          :disabled="loading"
          :type="isEdit ? 'delete' : info ? 'edit':'add'"
          @click="isEdit = !isEdit"
        >
          {{ isEdit ? 'отменить' : info ? 'изменить' : 'создать' }}
        </TwPanelContentBlockActionButton>
      </template>
      <div>
        <DealInsuranceResultForm
          v-if="isEdit"
          :id="info && info.id ? dealId : 0"
          :deal-id="dealId"
          @refresh="refresh"
          @create="refresh"
          @update="refresh"
          @cancel="isEdit = false"
        />
        <DealInsuranceResultShow
          v-else
          :deal-id="dealId"
          :info="info"
          :dictionary="dictionary"
        />
      </div>
    </TwPanelContentBlock>
  </div>
</template>

<script>
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
// TODO add and change API
import API from '@/Api/Crm/Deal/Insurance/Result'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import DealInsuranceResultForm from '@/views/Deal/TypeViews/insurance/Result/DealInsuranceResultForm.vue'
import DealInsuranceResultShow from '@/views/Deal/TypeViews/insurance/Result/DealInsuranceResultShow.vue'
import TwPanelContentBlockActionButton from '@/components/tw-ui/panels/TwPanelContentBlockActionButton.vue'

export default {
  name: 'DealInsuranceResultBlock',
  components: {
    TwPanelContentBlockActionButton,
    DealInsuranceResultShow,
    DealInsuranceResultForm,
    TwCircleLoader,
    TwPanelContentBlock
  },
  props: {
    dealData: {
      required: true
    }
  },
  data () {
    return {
      isEdit: false,
      loading: false,
      info: null,
      dictionary: null
    }
  },
  computed: {
    dealId () {
      return this.dealData?.id || null
    }
  },
  mounted () {
    this.refresh()
  },
  methods: {
    refresh () {
      this.isEdit = false
      this.fetchInfo()
    },
    fetchInfo () {
      this.loading = true
      API.show(this.dealId)
        .then(({ data: result }) => {
          console.warn('fetchInfo', result)
          this.$set(this, 'info', result?.data?.id ? result?.data : null)
          this.$set(this, 'dictionary', result?.dictionary || null)
        })
        .catch((error) => {
          console.error('fetchInfo', error)
          this.$set(this, 'info', null)
          this.$set(this, 'dictionary', null)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
