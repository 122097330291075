import HTTP from '@/Api/http'
import { AuthAxiosDefaultConfig } from '@/Api/Telephony'

const path = 'platform'

export default {
  call (phone: number): Promise<any> {
    const $config = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.get(`${path}/call/${phone}`, $config)
  }
}
