<template>
  <TwFlexCol v-if="info">
    <TwFlexCell>
      <TwPanelContentBlockFieldView
        no-data-text="Не указано"
        :horizontal="true"
      >
        <template #title>
          Семейное положение
        </template>
        <div v-if="info.status">
          {{ info.status && info.status.label }}
        </div>
      </TwPanelContentBlockFieldView>
    </TwFlexCell>
    <TwFlexCell v-if="info.status && info.status.value === 'marriage'">
      <TwPanelContentBlockFieldView
        no-data-text="Не указано"
        :horizontal="true"
      >
        <template #title>
          Наличие брачного контракта
        </template>
        <div>
          {{ info.familyContract ? 'Есть':'Нет' }}
        </div>
      </TwPanelContentBlockFieldView>
    </TwFlexCell>
  </TwFlexCol>
  <div
    v-else
    class="text-muted text-center"
  >
    Нет данных
  </div>
</template>

<script>
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'
import TwFlexCell from '@/components/tw-ui/ui-kit/flex-block/TwFlexCell.vue'
import TwFlexCol from '@/components/tw-ui/ui-kit/flex-block/TwFlexCol.vue'
export default {
  name: 'PersonFamilyShow',
  components: { TwFlexCol, TwFlexCell, TwPanelContentBlockFieldView },
  props: {
    info: {},
    dictionary: {}
  }
}
</script>
