import { RouteKeys } from '@/router/types'

export default {
  path: `/${RouteKeys.support}`,
  name: RouteKeys.support,
  redirect: { name: `${RouteKeys.support}-list` },
  meta: { name: 'Тех. поддержка', icon: 'question-circle', hideInMenu: true },
  component: () => import('@/views/Support/Index.vue'),
  children: [
    {
      path: 'list',
      name: `${RouteKeys.support}-list`,
      meta: { name: 'Список обращений', icon: 'home', hideInMenu: true },
      component: () => import('@/views/Support/task/List.vue'),
      props: true
    },
    {
      path: ':id/edit',
      name: `${RouteKeys.support}-edit`,
      meta: { name: 'Редактирование обращения', icon: 'home', hideInMenu: true },
      component: () => import('@/views/Support/task/SupportTaskCard.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    },
    {
      path: 'create',
      name: `${RouteKeys.support}-create`,
      meta: { name: 'Добавление обращения', icon: 'home', hideInMenu: true },
      component: () => import('@/views/Support/task/SupportTaskCard.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    },
    {
      path: ':id',
      name: `${RouteKeys.support}-show`,
      meta: { name: 'Просмотр обращения', icon: 'home', hideInMenu: true },
      component: () => import('@/views/Support/task/SupportTaskCard.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    }
  ]
}
