const sampleColors = [
  { // red
    fillColor: 'rgba(247,70,74,0.2)',
    strokeColor: 'rgba(247,70,74,1)',
    pointColor: 'rgba(247,70,74,1)',
    pointStrokeColor: '#fff',
    pointHighlightFill: '#fff',
    pointHighlightStroke: 'rgba(247,70,74,0.8)'
  },
  { // green
    fillColor: 'rgba(70,191,189,0.2)',
    strokeColor: 'rgba(70,191,189,1)',
    pointColor: 'rgba(70,191,189,1)',
    pointStrokeColor: '#fff',
    pointHighlightFill: '#fff',
    pointHighlightStroke: 'rgba(70,191,189,0.8)'
  },
  { // blue
    fillColor: 'rgba(151,187,205,0.2)',
    strokeColor: 'rgba(151,187,205,1)',
    pointColor: 'rgba(151,187,205,1)',
    pointStrokeColor: '#fff',
    pointHighlightFill: '#fff',
    pointHighlightStroke: 'rgba(151,187,205,0.8)'
  },
  { // yellow
    fillColor: 'rgba(253,180,92,0.2)',
    strokeColor: 'rgba(253,180,92,1)',
    pointColor: 'rgba(253,180,92,1)',
    pointStrokeColor: '#fff',
    pointHighlightFill: '#fff',
    pointHighlightStroke: 'rgba(253,180,92,0.8)'
  },
  { // light grey
    fillColor: 'rgba(220,220,220,0.2)',
    strokeColor: 'rgba(220,220,220,1)',
    pointColor: 'rgba(220,220,220,1)',
    pointStrokeColor: '#fff',
    pointHighlightFill: '#fff',
    pointHighlightStroke: 'rgba(220,220,220,0.8)'
  },
  { // grey
    fillColor: 'rgba(148,159,177,0.2)',
    strokeColor: 'rgba(148,159,177,1)',
    pointColor: 'rgba(148,159,177,1)',
    pointStrokeColor: '#fff',
    pointHighlightFill: '#fff',
    pointHighlightStroke: 'rgba(148,159,177,0.8)'
  },
  { // dark grey
    fillColor: 'rgba(77,83,96,0.2)',
    strokeColor: 'rgba(77,83,96,1)',
    pointColor: 'rgba(77,83,96,1)',
    pointStrokeColor: '#fff',
    pointHighlightFill: '#fff',
    pointHighlightStroke: 'rgba(77,83,96,1)'
  }
]

const newsBuildingsColors = [
  '#F44336',
  '#9C27B0',
  '#3F51B5',
  '#2196F3',
  '#00BCD4',
  '#009688',
  '#8BC34A',
  '#CDDC39',
  '#FFC107',
  '#FF5722',
  '#607D8B'
]

export {
  sampleColors,
  newsBuildingsColors
}
