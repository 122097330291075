<template>
  <div>
    <TwTextarea
      v-model="form.termDesc"
      rows="5"
      class="tw-mt-[12px]"
      placeholder="Срок"
      :disabled="formProcessing"
      :error-text="$v.form.termDesc.$invalid ? 'Уменьшите описание':''"
      :show-label="true"
    />
    <TwTextarea
      v-model="form.initialPaymentAmountDesc"
      rows="5"
      class="tw-mt-[12px]"
      placeholder="Первоначальный взнос"
      :disabled="formProcessing"
      :error-text="$v.form.initialPaymentAmountDesc.$invalid ? 'Уменьшите описание':''"
      :show-label="true"
    />
    <TwTextarea
      v-model="form.expensesDesc"
      rows="5"
      class="tw-mt-[12px]"
      placeholder="Расходы"
      :disabled="formProcessing"
      :error-text="$v.form.expensesDesc.$invalid ? 'Уменьшите описание':''"
      :show-label="true"
    />
    <div class="tw-mt-[18px]">
      <TwCheckBox
        v-model="form.active"
        :disabled="formProcessing"
        label="Активно"
      />
    </div>
    <div>
      <TwCardBlockFormActions
        :title-submit="formType === 'create' ? 'Создать' : 'Изменить'"
        :handle-submit="handleSubmit"
        :handle-cancel="handleCancel"
        :disable-submit="formProcessing || $v.form.$invalid"
        :disable-cancel="formProcessing"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import DeveloperHousingConsumerCooperativeAPI from '@/Api/Rtd/Company/DeveloperHousingConsumerCooperative'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import TwTextarea from '@/components/tw-ui/ui-kit/input/TwTextarea.vue'
import TwCheckBox from '@/components/tw-ui/ui-kit/input/TwCheckBox.vue'
import TwCardBlockFormActions from '@/components/core/ViewPanel/Card/TwCardBlockFormActions.vue'
import { maxLength } from 'vuelidate/lib/validators'

export default defineComponent({
  name: 'BuilderHousingConsumerCooperativeInfoForm',
  components: { TwCardBlockFormActions, TwCheckBox, TwTextarea },
  mixins: [FormCreateEditMixin],
  props: {
    companyId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      API: DeveloperHousingConsumerCooperativeAPI,
      form: {}
    }
  },
  validations () {
    return {
      form: {
        termDesc: {
          maxLength: maxLength(255)
        },
        initialPaymentAmountDesc: {
          maxLength: maxLength(255)
        },
        expensesDesc: {
          maxLength: maxLength(255)
        }
      }
    }
  },
  methods: {
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          for (const key in this.preset) {
            this.$set(this.form, key, this.preset[key])
          }
        }
        resolve()
      })
    },
    handleSubmit () {
      this.$v.$reset()
      this.$v.form.$touch()
      if (this.formProcessing === true) {
        return
      }
      if (!this.$v.form.$invalid) {
        this.formProcessing = true
        this.form.companyId = this.companyId
        const request = this.formType === 'create'
          ? this.API.store(this.form)
          : this.API.update(this.targetId, this.form)

        request.then(({ data }) => {
          if (this.formType === 'create') {
            this.$emit('create', data.data)
          } else {
            this.$emit('update', data.data)
          }
        }).finally(() => {
          this.formProcessing = false
        })
      }
    }
  }
})
</script>

<style scoped>

</style>
