import dictPermissionRules from '@/Permissions/dictRules'
export const routeKey = 'complex-photo'

export default {
  path: `${routeKey}`,
  name: routeKey,
  redirect: { name: `${routeKey}-list` },
  meta: { name: 'Фото для рекламы ЖК', icon: 'advertising-complex-platform-id', ruleNamespace: dictPermissionRules.AppServicePermissionRulesOBJECTSAdvertingUploadAdsSettingsRule },
  component: () => import('@/views/ObjectStorage/Index.vue'),
  children: [
    {
      path: 'list',
      name: `${routeKey}-list`,
      meta: { name: 'Фото для рекламы ЖК', icon: 'advertising-complex-platform-id', hideInMenu: true },
      component: () => import('@/views/ObjectStorage/Ads/ComplexPhoto/List.vue')
    },
    {
      path: ':id/edit',
      name: `${routeKey}-edit`,
      meta: { name: 'Редактирование фото ЖК', icon: 'advertising-complex-platform-id', hideInMenu: true },
      component: () => import('@/views/ObjectStorage/Ads/ComplexPhoto/Edit.vue'),
      props: (route:any) => ({ id: route.params.id })
    },
    {
      path: 'create',
      name: `${routeKey}-create`,
      meta: { name: 'Добавление фото ЖК', icon: 'advertising-complex-platform-id', hideInMenu: true },
      component: () => import('@/views/ObjectStorage/Ads/ComplexPhoto/Edit.vue'),
      props: (route:any) => ({ id: route.params.id })
    }
  ]
}
