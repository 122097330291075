<template>
  <div style="position:relative;">
    <APopoverWrapper
      ref="popoverWrapper"
      @onOpen="() => onSearch()"
    >
      <template #trigger>
        <div
          class="employee-select-input-block d-flex justify-start align-center"
          :style="{ borderColor: Array.isArray(errorMessages) && errorMessages.length ? 'red' : '' }"
          tabindex="0"
        >
          <div
            v-if="fullValues && !simple && Array.isArray(fullValues) && fullValues.length"
            class="employee-select-container"
          >
            <div
              v-for="(item, index) in fullValues"
              :key="index"
              class="employee-select-badge"
            >
              <div class="employee-select-badge-text">
                {{ item && item.name }}
              </div>
              <div
                class="cursor-pointer"
                style="position:absolute; right: 5px;"
                @click.stop="removeItem(item, index)"
              >
                <v-icon
                  class="ma-auto"
                  small
                >
                  fa-times
                </v-icon>
              </div>
            </div>
          </div>
          <div
            v-else-if="fullValues && simple"
            class="employee-select-container"
          >
            <div class="employee-select-badge">
              <div class="employee-select-badge-text">
                {{ fullValues.name }}
              </div>
              <div
                class="cursor-pointer"
                style="position:absolute; right: 5px;"
                @click.stop="clear"
              >
                <v-icon
                  class="ma-auto"
                  small
                >
                  fa-times
                </v-icon>
              </div>
            </div>
          </div>
          <div
            v-else
            class="text-muted"
          >
            <div>
              {{ noSelectText }}
            </div>
          </div>
        </div>
        <div
          v-if="Array.isArray(errorMessages) && errorMessages.length"
          class="error__labels"
        >
          <label
            v-for="(error, index) in errorMessages"
            :key="index"
            class="error--text"
          >
            <small>{{ error }}</small>
          </label>
        </div>
      </template>
      <template #content>
        <div style="width: 500px; height: 300px; background-color: white; display: flex; flex-direction: column">
          <div class="employee-select-popover my-2">
            <input
              v-model="search"
              type="text"
              placeholder="Поиск"
              @input="onSearch"
              @keypress.enter.stop="onSearch"
            >
          </div>
          <div
            v-if="searchProccess"
            class="text-center pa-2"
          >
            <v-progress-circular
              size="30"
              color="primary"
              indeterminate
            />
          </div>
          <div
            v-else-if="Array.isArray(list) && list.length"
            style="overflow: auto; display: flex; flex-wrap: wrap"
          >
            <div
              v-for="(item, index) in list"
              :key="index"
              class="employee-select-list-item"
              @click="onSelect(item)"
            >
              <div>
                <small class="text-muted font-weight-regular">
                  #{{ item.id }}
                </small>
                <div :class="item.active ? '':'text-muted'">
                  {{ item.name }}<small
                    v-if="!item.active"
                    class="ml-2"
                  >(Неактивн.)</small>
                </div>
              </div>
            </div>
          </div>
          <div
            v-else
            class="text-center text-muted font-weight-regular"
          >
            Не найдено
          </div>
        </div>
      </template>
    </APopoverWrapper>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import APopoverWrapper from '@/components/filters/afilter/fields/apopover/APopoverWrapper'
import lodashDebounce from 'lodash/debounce'

export default {
  name: 'CompanySearchSelect',
  components: { APopoverWrapper },
  props: {
    simple: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Array, Object, Number, String],
      default: null
    },
    defaultFilters: {
      type: Object,
      default: () => {
        return {}
      }
    },
    errorMessages: {
      type: Array,
      default: () => []
    },
    noSelectText: {
      type: String,
      default: 'Не выбрано'
    },
    zIndex: {
      type: [String, Number, null],
      default: 99
    }
  },
  data () {
    return {
      search: '',
      searchProccess: false,
      relativeItems: []
    }
  },
  methods: {
    ...mapActions({
      searchCompany: 'tmpCache/fetchCompanyList'
    }),
    onSearch: lodashDebounce(function (event) {
      console.log('onSearch', { event })
      this.searchProccess = true
      // event.target.value
      this.searchCompany({ name: event?.target?.value || this.search, ...this.defaultFilters })
        .then((list) => {
          console.log('onSearch data', list)
          this.relativeItems = list
        })
        .catch((error) => {
          console.error('onSearch error', error)
        }).finally(() => {
          this.searchProccess = false
        })
    }, 500),
    onSelect (item) {
      let newValue = []
      if (this.simple) {
        newValue = this.value || null
        if (newValue !== item.id) {
          newValue = item.id
          this.$emit('input', newValue)
          this.$emit('inputFullData', this.getItemById(newValue))
          // Одиночный выбор закрывает поповер
          if (this.$refs.popoverWrapper) {
            this.$refs.popoverWrapper.onClose()
          }
        }
      } else {
        newValue = [...[], ...(this.value || [])]
        const targetIndex = newValue.findIndex((v) => v === item.id)
        if (targetIndex === -1) {
          newValue.push(item.id)
          this.$emit('input', newValue)
          this.$emit('inputFullData', newValue.map(v => this.getItemById(v)))
        }
      }
    },
    removeItem (item) {
      console.warn(this.$options.name, 'removeItem', item)
      const newValue = [...[], ...this.value]
      const targetIndex = newValue.findIndex((v) => v === item.id)
      newValue.splice(targetIndex, 1)
      this.$emit('input', newValue.length ? newValue : null)
      this.$emit('inputFullData', newValue.length ? newValue.map(v => this.getItemById(v)) : null)
    },
    clear () {
      console.warn(this.$options.name, 'clear')
      this.$emit('input', null)
      this.$emit('inputFullData', null)
    }
  },
  computed: {
    ...mapGetters({
      cachedItems: 'tmpCache/cachedCompanyList',
      getItemById: 'tmpCache/getCompanyById'
    }),
    fullValues () {
      if (!this.simple && Array.isArray(this.value) && this.value.length) {
        return this.value.map(v => this.getItemById(v))
      }
      if (this.simple && this.value) {
        return this.getItemById(this.value)
      }
      return null
    },
    list () {
      // Если есть заданные фильтры игнорю кеш и показываю только актуальные данные cachedItems
      if (!Object.keys(this.defaultFilters).length) {
        return this.search || (Array.isArray(this.relativeItems) && this.relativeItems.length) ? this.relativeItems : this.cachedItems
      } else {
        return this.relativeItems
      }
    }
  }
}
</script>

<style lang="less">
.employee-select-input-block {
  font: 13px "Helvetica Neue",Arial,Helvetica,sans-serif;
  width: 100%;
  border: 1px solid rgba(83, 92, 105, .2);
  border-radius: 2px;
  padding: 0 5px;
  background: #fff;
  min-height: 38px;
  outline: none;
  &:focus{
    border: 1px solid #bcedfc;
  }
  .employee-select-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 3px;
    height: 100%;
    .employee-select-badge {
      margin: 3px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px 22px 5px 7px;
      background: #bcedfc;
      //margin-left: 5px;
      border-radius: 2px;
      &-text {
        padding: 0;
        max-width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 13px;
      }
    }
  }
}

.employee-select-list-item {
  font: 13px "Helvetica Neue",Arial,Helvetica,sans-serif;
  width: 48%;
  display: flex;
  cursor: pointer;
  padding: 7px 10px;
  border-radius: 3px;
  //margin: 5px;
  &:hover {
    background-color: #eaeaea;
  }
}

.employee-select-popover {
  font: 13px "Helvetica Neue",Arial,Helvetica,sans-serif;
  width: 100%;
  border: 1px solid rgba(83, 92, 105, .2);
  border-radius: 2px;
  padding: 0 5px;
  background: #fff;

  input {
    width: 100%;
    height: 36px;
    outline: none;
  }
}
</style>
