<template>
  <div>
    <div class="mt-15 d-flex align-center justify-space-between">
      <div class="d-flex">
        <h3>Документы</h3>
        <v-btn
          :color="isShowCreateDoc ? 'secondary' : 'primary'"
          x-small
          text
          :disabled="deleteDocumentProcess || refreshDocumentsProcess || loadingParent"
          @click="isShowCreateDoc = !isShowCreateDoc"
        >
          {{ isShowCreateDoc ? 'Скрыть форму нового документа' : 'Добавить новый документ' }}
        </v-btn>
      </div>
      <div>
        <v-btn
          x-small
          text
          :disabled="deleteDocumentProcess || refreshDocumentsProcess || loadingParent"
          @click="$emit('refresh-application-documents')"
        >
          Обновить список документов
        </v-btn>
      </div>
    </div>
    <div
      v-if="calcDocumentsClassificationStatuses['IN_PROGRESS'] || calcDocumentsClassificationStatuses['ERROR']"
      class="mt-3"
    >
      <span class="font-weight-medium">Распознавание:</span>
      <span
        v-if="calcDocumentsClassificationStatuses['IN_PROGRESS']"
        class="mr-3"
        :style="{ color: dict.classificationStatus['IN_PROGRESS'].color }"
      >
        В процессе: {{ calcDocumentsClassificationStatuses['IN_PROGRESS'] }}
      </span>
      <span
        v-if="calcDocumentsClassificationStatuses['ERROR']"
        :style="{ color: dict.classificationStatus['ERROR'].color }"
      >
        Ошибка: {{ calcDocumentsClassificationStatuses['ERROR'] }}
      </span>
    </div>
    <v-row
      justify="center"
      align="start"
      dense
      class="my-2"
    >
      <v-col
        class=""
        cols="6"
      >
        <DomClickDealApplicationDocForm
          v-if="isShowCreateDoc"
          :deal-id="dealId"
          :application-id="applicationId"
          :person-ids="allPersonIds"
          @hide="isShowCreateDoc = false"
          @refresh="$emit('refresh-application-documents')"
        />
      </v-col>
      <v-col
        class=""
        cols="12"
        style="position:relative;"
      >
        <v-overlay
          absolute
          :value="deleteDocumentProcess || refreshDocumentsProcess"
        >
          <div class="text-center py-3">
            <v-progress-circular
              indeterminate
              size="64"
            />
            <div class="mt-2">
              Документы обновляются... Подождите
            </div>
          </div>
        </v-overlay>
        <v-card
          v-if="documents && Array.isArray(documents) && documents.length"
          tile
        >
          <v-list-item
            v-for="doc in documents"
            :key="doc.id"
            dense
            two-line
          >
            <v-list-item-avatar
              rounded="0"
              size="50"
            >
              <v-img
                v-if="getImgPreview(doc)"
                style="border: 1px solid white"
                :src="getImgPreview(doc)"
                @click="imageShow(doc)"
              />
              <v-icon
                v-else
                color="grey darken-3"
              >
                fa-file-text-o
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="d-flex">
                {{ getDocTitleFromDict(doc.type) }}
                <v-spacer />
                <div v-if="doc.classificationStatus && dict.classificationStatus[doc.classificationStatus]">
                  <span class="text--disabled font-weight-regular">Распознавание: </span>
                  <span :style="{ color: dict.classificationStatus[doc.classificationStatus].color }">
                    {{ dict.classificationStatus[doc.classificationStatus].text }}
                  </span>
                </div>
              </v-list-item-title>
              <div class="mt-2 d-flex font-weight-regular text--disabled">
                <small v-if="doc && doc.crmFile">
                  {{ doc.crmFile.name }}
                </small>
                <v-spacer />
                <div>
                  <v-btn
                    x-small
                    text
                    :disabled="loadingParent || refreshDocumentsProcess"
                    :color="doc.type ? 'grey' : 'red'"
                    @click="openChangeType(doc)"
                  >
                    Изменить тип документа
                  </v-btn>
                </div>
              </div>
              <v-list-item-subtitle
                v-if="Array.isArray(doc.documentDefects) && doc.documentDefects.length"
                class="text-wrap error--text"
              >
                <small
                  v-for="(msg, msgIndex) in doc.documentDefects"
                  :key="msgIndex"
                >
                  {{ msg }}<template v-if="msgIndex < doc.documentDefects.length - 1">, </template>
                </small>
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn
                icon
                small
                :disabled="loadingParent || refreshDocumentsProcess"
                title="Удалить документ"
                @click="deleteDocument(doc)"
              >
                <v-icon
                  small
                  color="red lighten-1"
                >
                  fa-trash
                </v-icon>
              </v-btn>
              <v-btn
                v-if="doc && doc.crmFile"
                :disabled="loadingParent || refreshDocumentsProcess"
                icon
                small
                title="Скачать файл"
                @click="downloadFileDocument(doc)"
              >
                <v-icon
                  small
                  color="primary"
                >
                  fa-download
                </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-card>
        <div
          v-else
          class="text-center text--disabled text-h6 my-5"
        >
          Нет загруженных документов
        </div>
      </v-col>
    </v-row>

    <v-dialog
      v-model="isChangeTypeModal"
      max-width="500px"
    >
      <v-card v-if="editableDoc">
        <v-card-title class="title primary white--text">
          Изменение типа документа
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-3 pb-2">
          <v-form ref="docType">
            <v-select
              v-model="form.type"
              label="Тип документа"
              style="max-width: 500px"
              item-text="label"
              item-value="value"
              :items="dict.docTypes"
              :disabled="updateDocumentProcess"
              dense
              :rules="requiredRules"
              outlined
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />

          <v-btn
            color="grey darken-1"
            text
            :disabled="updateDocumentProcess"
            @click="clearChangeType"
          >
            отмена
          </v-btn>

          <v-btn
            color="primary darken-1"
            text
            :disabled="editableDoc.type === form.type"
            :loading="updateDocumentProcess"
            @click="updateDocument"
          >
            сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DomClickDealApplicationDocForm
  from '@/views/MortgageProgramWorksheet/DomClickWorksheet/deal/application/DomClickDealApplicationDocForm'
import { classificationStatus, docTypes } from '@/views/MortgageProgramWorksheet/DomClickWorksheet/DomClickDict'
import { getFileResizeByKey, getImageResizeByKey } from '@/plugins/helpers/fileHelpers'
import fileAPI from '@/Api/Crm/File'
import dealAPI from '@/Api/Crm/Mortgage/Docmclick/Deals'

export default {
  name: 'DomClickDealApplicationDocBlock',
  components: { DomClickDealApplicationDocForm },
  props: {
    documents: {
      type: Array,
      default: () => []
    },
    dealId: {
      type: Number,
      required: true
    },
    refreshDocumentsProcess: {
      type: Boolean
    },
    loadingParent: {
      type: Boolean
    },
    applicationId: {
      type: Number,
      required: true
    },
    additionalData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      requiredRules: [
        (v) => !!v || 'Обязательное поле'
      ],
      isChangeTypeModal: false,
      updateDocumentProcess: false,
      deleteDocumentProcess: false,
      isShowCreateDoc: false,
      editableDoc: null,
      form: {
        type: null
      },
      dict: {
        docTypes,
        classificationStatus
      }
    }
  },
  computed: {
    calcDocumentsClassificationStatuses () {
      if (this.documents && Array.isArray(this.documents) && this.documents.length) {
        return this.documents.reduce((accumulate, doc) => {
          if (accumulate[doc.classificationStatus] === undefined) {
            accumulate[doc.classificationStatus] = 0
          }
          accumulate[doc.classificationStatus] += 1
          return accumulate
        }, {})
      }
      return {}
    },
    allPersonIds () {
      return [
        ...(this.additionalData.otherPersonsId || []),
        ...(this.additionalData.personId ? [this.additionalData.personId] : [])
      ]
    }
  },
  methods: {
    getImgPreview (document) {
      return getImageResizeByKey(document.crmFile)
    },
    imageShow (document) {
      // console.warn('+++', this.filesImages)
      const f = document?.crmFile
      if (f?.mime && f.mime.indexOf('image') !== -1) {
        this.$store.dispatch('imageViewer/showImages', {
          files: [
            {
              path: f.mime.indexOf('image') !== -1 ? getFileResizeByKey(f, 'full') : '/img/file-not-image.png',
              preview: f.mime.indexOf('image') !== -1 ? getFileResizeByKey(f, 'default') : '/img/file-not-image.png',
              name: f.name,
              size: f.size
            }
          ],
          startIndex: 0
        })
      } else {
        console.error('Файл не является изображением', document?.crmFile)
      }
    },
    getDocTitleFromDict (value) {
      return docTypes.find(doc => doc.value === value)?.label || 'Неизвестный документ'
    },
    downloadFileDocument (document) {
      console.warn('downloadFileDocument', document?.crmFile)
      const { url = '', name = '' } = document?.crmFile
      if (url && name) {
        fileAPI.download(url, name)
      } else {
        console.error('downloadFileDocument - отсутствует ссылка или название файла')
      }
    },
    deleteDocument (document) {
      console.warn('deleteDocument', document)
      if (window.confirm('Удалить документ?')) {
        this.deleteDocumentProcess = true
        dealAPI.applicationDocDelete(this.dealId, this.applicationId, document.id)
          .then(({ data }) => {
            console.warn('deleteDocument RESULT: ', data)
            this.$emit('refresh-application-documents')
          })
          .catch((error) => {
            console.error(error)
          })
          .finally(() => {
            this.deleteDocumentProcess = false
          })
      }
    },
    openChangeType (doc) {
      this.$set(this, 'editableDoc', doc)
      this.$set(this.form, 'type', doc.type)
      this.$nextTick(() => {
        this.isChangeTypeModal = true
      })
    },
    clearChangeType () {
      this.$set(this, 'editableDoc', null)
      this.$set(this.form, 'type', null)
      this.$nextTick(() => {
        this.isChangeTypeModal = false
      })
    },
    updateDocument () {
      console.warn('updateDocument', this.editableDoc.type)
      this.updateDocumentProcess = true
      dealAPI.applicationDocUpdate(this.dealId, this.applicationId, this.editableDoc.id, this.form)
        .then(({ data }) => {
          console.warn('updateDocument RESULT: ', data)
          this.$emit('refresh-application-documents')
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.updateDocumentProcess = false
          this.clearChangeType()
        })
    }
  }
}
</script>

<style scoped>

</style>
