import { RouteKeys } from '@/router/types'
import dictPermissionRules from '@/Permissions/dictRules'

export default {
  path: RouteKeys.builderFiles,
  name: RouteKeys.builderFiles,
  redirect: { name: `${RouteKeys.builderFiles}-files` },
  meta: { name: 'Автоимпорт файлов застройщиков', icon: 'auto-import-developer-files', ruleNamespace: dictPermissionRules.AppServicePermissionRulesOBJECTSNewBuildingsBuilderFileReadBuilderNBFileRule },
  component: () => import('@/views/ObjectStorage/Index.vue'),
  children: [
    {
      path: 'files',
      name: `${RouteKeys.builderFiles}-files`,
      meta: { name: 'Файлы застройщиков', icon: 'auto-import-developer-files', hideInMenu: true },
      component: () => import('@/views/BuilderImporter/ImportBuilderFile/List.vue')
    },
    {
      path: 'check-file',
      name: `${RouteKeys.builderFiles}-check`,
      meta: { name: 'Проверка файла застройщика', icon: 'auto-import-developer-files', hideInMenu: true },
      component: () => import('@/views/BuilderImporter/ImportBuilderFile/CheckBuilderFile.vue')
    }
  ]
}
