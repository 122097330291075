import office from './office'
import department from './department'
import position from './position'
import employee from './employee'
import contract from './contract'
import structure from './structure'
import positionStructure from './position-structure'
import departmentPosition from './department-position'
import userSurveys from './user-surveys'
import { RouteKeys } from '@/router/types'
import dictPermissionRules from '@/Permissions/dictRules'

export default {
  path: RouteKeys.company,
  name: RouteKeys.company,
  redirect: { name: `${RouteKeys.employee}-list` },
  meta: { name: 'Компания', icon: 'users' },
  component: () => import('@/views/Dictionary/Index.vue'),
  children: [
    employee,
    contract,
    structure,
    position,
    department,
    office,
    departmentPosition,
    positionStructure,
    {
      path: RouteKeys.dealDistributionResponsibleUser,
      name: RouteKeys.dealDistributionResponsibleUser,
      meta: { name: 'Список распределения', icon: 'block-company-dictPermissionRules', ruleNamespace: dictPermissionRules.AppServicePermissionRulesCRMDealsDealDistributeEditUserList },
      component: () => import('@/views/Company/DealDistributionResponsibleUser/List.vue')
    },
    {
      path: RouteKeys.dealDistributionChangesComplexes,
      name: RouteKeys.dealDistributionChangesComplexes,
      meta: { name: 'Список замены ЖК', icon: 'block-company-dictPermissionRules', isAdmin: true }, // TODO добавить правило для валидации доступа
      component: () => import('@/views/Company/DealDistributionChangesComplexes/List.vue')
    },
    userSurveys
  ]
}
