import { AxiosResponse } from 'axios'
import HTTP from '@/Api/http'

const path = '/deal/contract'

export default {
  listInit (): Promise<AxiosResponse> {
    const $config: any = {}
    return HTTP.get(`${path}/list-init`, $config)
  },
  getListNew (param?: object, otherConfig?: object): Promise<AxiosResponse> {
    const $config = {
      params: param,
      ...(otherConfig || {})
    }
    return HTTP.get(`${path}`, $config)
  },
  getList (param?: any): Promise<AxiosResponse> {
    const $config = {
      params: param
    }
    return HTTP.get(`${path}`, $config)
  },
  show (id: number | string): Promise<AxiosResponse> {
    const $config = {}
    return HTTP.get(`${path}/${id}`, $config)
  },
  generate (data: any): Promise<AxiosResponse> {
    return HTTP.post(`${path}/generate`, data)
  },
  create (): Promise<AxiosResponse> {
    const $config = {}
    return HTTP.get(`${path}/create`, $config)
  },
  toOffice (id: number, data: { status: string, comment: string }): Promise<AxiosResponse> {
    return HTTP.put(`${path}/${id}/toOffice`, data)
  },
  archive (id: number, data: { status: string, comment: string }): Promise<AxiosResponse> {
    return HTTP.put(`${path}/${id}/archive`, data)
  },
  // Обнуление подписанного договора
  setUnsigned (id: number): Promise<AxiosResponse> {
    const $config = {}
    return HTTP.get(`${path}/${id}/setUnsigned`, $config)
  }
}
