<template>
  <v-row dense>
    <!--    TODO DEBUG-->
    <!--    <v-col cols="12">-->
    <!--      {{dFields}}-->
    <!--    </v-col>-->
    <!--    <v-col cols="12">-->
    <!--      {{fields}}-->
    <!--    </v-col>-->
    <v-col
      cols="12"
      xl="6"
    >
      <v-text-field
        v-model="fields.number"
        type="text"
        label="Номер"
        autocomplete="off"
        :rules="numberRules"
        required
      />
    </v-col>
    <v-col
      cols="12"
      lg="6"
    >
      <NativeDateInputField
        v-model="fields.dateIssued"
        type="date"
        clearable
        label="Дата выдачи"
        autocomplete="off"
        required
      />
    </v-col>
    <v-col
      cols="12"
      lg="6"
    >
      <NativeDateInputField
        v-model="fields.dateExpire"
        type="date"
        clearable
        label="Срок действия"
        autocomplete="off"
        required
      />
    </v-col>
    <v-col cols="12">
      <v-textarea
        v-model="fields.whoIssued"
        rows="3"
        dense
        type="text"
        label="Кем выдан"
        autocomplete="off"
        required
      />
    </v-col>
    <v-col
      cols="12"
      xl="6"
    >
      <v-textarea
        v-model="fields.placeBirth"
        rows="3"
        dense
        type="text"
        label="Место рождения"
        autocomplete="off"
        required
      />
    </v-col>
    <v-col
      cols="12"
      xl="6"
    >
      <v-textarea
        v-model="fields.placeRegistration"
        hide-details
        rows="3"
        dense
        type="text"
        label="Место регистрации"
        autocomplete="off"
        required
      />
    </v-col>
  </v-row>
</template>

<script>
// import AAddressInput from '@/components/ainput/AAddressInput'
import NativeDateInputField from '@/components/input/NativeDateInputField'
export default {
  name: 'ForeignPassportType',
  components: { NativeDateInputField },
  // components: { },
  // components: { AAddressInput },
  props: {
    // набор значений fields из формы
    formType: {},
    value: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      detectedFields: {}, // поля распознанные сервером computer-vision
      numberRules: [
        v => !!(typeof v === 'string' && v.length > 4 && v.length < 10) || 'Неверный формат'
      ]
    }
  },
  computed: {
    fields: {
      get () {
        return this.value
      }
      // set (values) {
      //   console.warn('SET fields ', values)
      // }
    },
    dFields () {
      // сопоставление полей
      const fieldMap = {
        number: 'number',
        issue_date: 'dateIssued',
        issue_expire: 'dateExpire',
        issued_by: 'whoIssued',
        birth_place: 'placeBirth'
      }
      const fieldValues = this.detectedFields?.document?.values
      if (Array.isArray(fieldValues)) {
        const fields = {}
        for (const yaField of fieldValues) {
          console.log('yaField ', yaField.code)
          // сопоставляю поля
          if (fieldMap[yaField.code]) {
            if (yaField.code === 'issue_date') {
              if (yaField.value) {
                console.warn({ ccccccc: yaField.value, co: yaField.code })
                const [day, month, year] = yaField.value.split('.')
                console.warn({ day, month, year })
                fields[fieldMap[yaField.code]] = {
                  // ...yaField,
                  value: `${year}-${month}-${day}`
                }
              }
            } else {
              fields[fieldMap[yaField.code]] = yaField
            }
          }
        }
        return fields
      }
      return {}
    }
  },
  methods: {
    setDetectedFields (fields) {
      console.warn('setDetectedFields', fields)
      this.$set(this, 'detectedFields', fields)
      // this.detectedFields = fields
    }
  }
}
</script>

<style scoped>

</style>
