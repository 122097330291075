<template>
  <div
    :class="[sizeClasses, disabled ? 'tw-pointer-events-none tw-opacity-70':'']"
    class="tw-relative tw-text-left tw-text-an-flat-black-main tw-select-none tw-w-auto tw-font-manrope tw-font-medium tw-leading-none tw-block"
  >
    <div
      v-if="isRequired || label || persistentLabel || errorValues.length"
      class="tw-h-[21px] tw-w-full tw-text-right tw-text-[12px] tw-leading-[21px] tw-relative tw-text-an-flat-red-main"
      :class="labelError"
    >
      <span
        v-for="(err, index) in errorValues"
        :key="index"
      >
        {{ err }}<span v-if="errorValues.length - 1 > index">,</span>
      </span>
    </div>
    <div
      ref="trigger"
      role="button"
      tabindex="0"
      :class="[sizeClasses, errorValues.length ? classesError : '', disabled ? 'tw-pointer-events-none tw-bg-an-flat-disabled-input-bg tw-text-an-flat-disabled-input-text':'tw-bg-white']"
      class="tw-group tw-relative tw-z-0 tw-flex tw-gap-[10px] tw-items-center tw-justify-between tw-px-[20px] tw-py-[7px] tw-border tw-border-solid tw-rounded-sm hover:tw-drop-shadow-input"
      @click.stop="show"
    >
      <div class="tw-truncate">
        <label
          v-if="label || persistentLabel"
          :class="[persistentLabel || isValue ? '-tw-translate-x-[15px] tw-text-an-flat-dark-blue-link ' + (dense ? '-tw-translate-y-[41px]':'-tw-translate-y-[46px]'):'']"
          class="tw-truncate tw-absolute tw-top-1/2 -tw-translate-y-1/2 tw-transition-all tw-duration-200 tw-ease-out motion-reduce:tw-transition-none"
        >
          {{ label }}
          <span
            v-if="isRequired"
            class="tw-text-an-flat-red-main tw-text-main-sm"
          >
            <slot name="requiredSymbol">*</slot>
          </span>
        </label>
        <div
          v-if="Array.isArray(selectedItemLabels)"
          class="tw-flex tw-gap-[7px]"
        >
          <template v-if="selectedItemLabels.length">
            <template v-for="(sItem, sIndex) in selectedItemLabels">
              <div
                v-if="sIndex < 2"
                :key="sItem.value"
                :title="sItem.label"
                class="tw-relative tw-cursor-default tw-leading-none tw-flex tw-gap-1 tw-truncate tw-text-an-flat-dark-blue-link tw-bg-an-flat-dark-blue-link tw-bg-opacity-10 tw-rounded-xs tw-px-[7px] tw-py-[5px]"
              >
                <div class="tw-truncate tw-pr-[12px]">
                  {{ sItem.label }}
                </div>
                <button
                  title="Удалить"
                  class="tw-absolute tw-right-[3px] tw-rounded-full"
                  @click.stop="removeItem(sItem)"
                >
                  <svg
                    width="14"
                    height="13"
                    viewBox="0 0 14 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7 0.375C3.62262 0.375 0.875 3.12262 0.875 6.5C0.875 9.87738 3.62262 12.625 7 12.625C10.3774 12.625 13.125 9.87738 13.125 6.5C13.125 3.12262 10.3774 0.375 7 0.375ZM9.49683 8.37817C9.66772 8.54907 9.66772 8.82593 9.49683 8.99683C9.41138 9.08228 9.29944 9.125 9.1875 9.125C9.07556 9.125 8.96362 9.08228 8.87817 8.99683L7 7.11865L5.12183 8.99683C5.03638 9.08228 4.92444 9.125 4.8125 9.125C4.70056 9.125 4.58862 9.08228 4.50317 8.99683C4.33228 8.82593 4.33228 8.54907 4.50317 8.37817L6.38135 6.5L4.50317 4.62183C4.33228 4.45093 4.33228 4.17407 4.50317 4.00317C4.67407 3.83228 4.95093 3.83228 5.12183 4.00317L7 5.88135L8.87817 4.00317C9.04907 3.83228 9.32593 3.83228 9.49683 4.00317C9.66772 4.17407 9.66772 4.45093 9.49683 4.62183L7.61865 6.5L9.49683 8.37817Z"
                      fill="#2D82D0"
                    />
                  </svg>
                </button>
              </div>
            </template>
            <div
              v-if="selectedItemLabels.length - 2 > 0"
              class="tw-font-semibold tw-leading-none tw-text-an-flat-dark-blue-link tw-bg-an-flat-dark-blue-link tw-bg-opacity-10 tw-rounded-full tw-px-[7px] tw-py-[5px]"
            >
              +{{ selectedItemLabels.length - 2 }}
            </div>
          </template>
          <div
            v-else
            class="tw-truncate tw-pr-[12px]"
          >
            {{ placeholder }}
          </div>
        </div>
        <div
          v-else
          :class="selectedItemLabels ? 'tw-font-medium' : 'tw-font-normal tw-text-an-flat-disabled-text'"
          class="tw-truncate"
        >
          {{ selectedItemLabels || placeholder }}
        </div>
      </div>
      <div>
        <svg
          :class="visibleList ? '':'tw-rotate-180'"
          class="tw-transition-all tw-duration-200"
          width="9"
          height="5"
          viewBox="0 0 9 5"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            :class="visibleList ? 'tw-stroke-an-flat-red-main': disabled ? 'tw-stroke-an-flat-disabled-input-text' : 'tw-stroke-an-flat-black-main'"
            d="M7.25 4L4.25 1.5L1.25 4"
            stroke="#1B1918"
            stroke-width="2"
            stroke-linecap="round"
          />
        </svg>
      </div>
      <div
        ref="prepend"
        class="tw-absolute tw-top-1/2 tw-left-0 tw-transform -tw-translate-x-0 -tw-translate-y-1/2 tw-ml-[15px]"
      >
        <slot name="prepend" />
      </div>
      <div
        ref="append"
        class="tw-absolute tw-top-1/2 tw-right-0 tw-transform -tw-translate-x-0 -tw-translate-y-1/2 tw-mr-[15px]"
      >
        <slot name="append" />
      </div>
      <div
        v-if="cleanable && isValue"
        class="tw-absolute tw-top-1/2 tw-right-[15px] tw-transform -tw-translate-x-0 -tw-translate-y-1/2 tw-mr-[15px] tw-hidden group-hover:tw-block"
      >
        <button
          class="tw-p-[5px]"
          @click.stop="onClearValue"
        >
          <svg
            width="15"
            height="15"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10 5C10 7.76142 7.76143 10 5 10C2.23857 10 0 7.76142 0 5C0 2.23858 2.23857 0 5 0C7.76143 0 10 2.23858 10 5ZM3.71445 3.71445C3.89197 3.53694 4.17976 3.53694 4.35727 3.71445L5 4.35719L5.64273 3.71445C5.82024 3.53694 6.10805 3.53694 6.28555 3.71445C6.46307 3.89196 6.46307 4.17976 6.28555 4.35728L5.64282 5.00001L6.28575 5.64293C6.46326 5.82044 6.46326 6.10824 6.28575 6.28575C6.10823 6.46326 5.82043 6.46326 5.64293 6.28575L5 5.64283L4.35709 6.28574C4.17957 6.46326 3.89177 6.46326 3.71426 6.28574C3.53674 6.10824 3.53674 5.82043 3.71426 5.64292L4.35718 5.00001L3.71445 4.35728C3.53694 4.17977 3.53694 3.89197 3.71445 3.71445Z"
              fill="#E63114"
            />
          </svg>
        </button>
      </div>
    </div>
    <div
      v-if="visibleList"
      ref="tooltip"
      class="tw-absolute tw-z-[1000] tw-w-full tw-bg-white tw-border tw-border-solid tw-rounded-sm tw-py-[9px] tw-shadow"
    >
      <ul
        v-click-outside="clickOutSide"
        role="listbox"
        :style="{ maxHeight: selectPlaceMaxHeight, padding: 0 }"
        class="tw-overflow-auto"
      >
        <slot name="listTop">
        <!--     Слот сверху над списком - нужен например чтобы вставить поисковую стору для локального поиска по списку опций     -->
        </slot>
        <template v-if="!options.length">
          <li class="tw-min-h-[42px] tw-pl-[18px] tw-pr-[18px] tw-flex tw-justify-center tw-items-center tw-text-an-flat-disabled-text">
            {{ noDataText }}
          </li>
        </template>
        <template>
          <slot
            name="options"
            v-bind="{ options, value, clickToItem, getIsCheckedItem }"
          >
            <li
              v-for="optionItem in options"
              :key="optionItem.value"
              role="option"
              :class="getIsCheckedItem(optionItem) ? 'tw-text-an-flat-red-main tw-bg-an-flat-red-lighten hover:tw-bg-an-flat-red-lighten':''"
              class="tw-leading-normal tw-cursor-pointer tw-min-h-[42px] tw-pl-[18px] tw-pr-[12px] tw-flex tw-gap-[6px] tw-items-center hover:tw-bg-an-flat-bg-main"
              @click.prevent="clickToItem(optionItem)"
            >
              <TwCheckBox
                :multi="multi"
                :radio="!multi"
                :value="getIsCheckedItem(optionItem)"
              />
              <span>
                {{ optionItem.label }}
              </span>
            </li>
          </slot>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
import { createPopper } from '@popperjs/core'
import TwCheckBox from '@/components/tw-ui/ui-kit/input/TwCheckBox.vue'
export default {
  name: 'TwSelect',
  components: { TwCheckBox },
  props: {
    value: {
      type: [Array, String, Number, Boolean],
      default: null
    },
    options: {
      type: Array,
      default: () => []
    },
    // сжатые поля
    dense: {
      type: Boolean,
      default: false
    },
    // КНопка очистки поля
    cleanable: {
      type: Boolean,
      default: false
    },
    multi: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    noDataText: {
      type: String,
      default: 'Нет данных'
    },
    persistentLabel: {
      type: Boolean,
      default: false
    },
    selectPlaceMaxHeight: {
      type: String,
      default: '200px'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    disableErrorBorder: {
      type: Boolean,
      default: false
    },
    // Возаращать объекты значений
    returnObject: {
      type: Boolean,
      default: false
    },
    labelErrorClass: {
      type: String,
      default: ''
    },
    errorText: {
      type: [String, Boolean, Array],
      default: null
    },
    // Если в списке опций один элемент - выбрать его автоматически
    hasOneOptionPreselect: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      popperInstance: null,
      visibleList: false
    }
  },
  methods: {
    show () {
      this.visibleList = true
      this.$nextTick(() => {
        this.popperInstance = createPopper(this.$refs.trigger, this.$refs.tooltip, {
          // strategy: 'fixed',
          placement: 'bottom',
          rootBoundary: 'document',
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 5]
              }
            }
          ]
        })
      })
      // this.popperInstance.update()
    },
    hide () {
      this.visibleList = false
      this.popperInstance?.destroy()
      this.popperInstance = null
    },
    clickOutSide () {
      this.hide()
    },
    onClearValue () {
      if (this.multi) {
        this.onInputValue([])
      } else {
        this.onInputValue(null)
      }
    },
    getIsCheckedItem (option) {
      if (this.multi) {
        return this.value?.includes(option.value)
      }
      return option.value === this.value
    },
    removeItem (item) {
      this.clickToItem(item)
    },
    clickToItem (option) {
      let newModelValue = []
      if (this.multi) {
        newModelValue = [...[], ...this.value]
        const selectedIndex = this.value?.indexOf(option.value)
        if (selectedIndex > -1) {
          newModelValue.splice(selectedIndex, 1)
        } else {
          newModelValue.push(option.value)
        }
      } else {
        newModelValue = option.value === this.value ? null : option.value
        if (newModelValue !== null) {
          this.hide()
        }
      }
      this.onInputValue(newModelValue)
    },
    onInputValue (newValue) {
      this.$emit('input', newValue)
      if (this.returnObject) {
        if (this.multi) {
          this.$emit('input-object', newValue?.map(val => {
            return this.options.find(o => o.value === val)
          }))
        } else {
          this.$emit('input-object', this.options.find(o => o.value === newValue))
        }
      }
    }
  },
  computed: {
    isRequired () {
      // определение обязательности поля
      // - если тестовая ошибка имеет дефолтное значение
      // и если текст лейбла показан над полем ввода
      return this.errorText !== null && this.errorText !== '' && (this.persistentLabel || this.isValue)
    },
    errorValues () {
      if (Array.isArray(this.errorText)) {
        return this.errorText
      } else if (this.errorText) {
        return [this.errorText]
      }
      return []
    },
    sizeClasses () {
      if (this.dense) {
        return 'tw-min-h-[40px] tw-text-main-xs tw-min-w-[160px]'
      } else {
        return 'tw-min-h-[52px] tw-text-main-sm2 tw-min-w-[180px]'
      }
    },
    classesError () {
      if (this.disableErrorBorder) {
        return ''
      }
      return 'tw-text-an-flat-red-main tw-border-an-flat-red-main'
    },
    labelError () {
      if (this.classesError) {
        if (this.labelErrorClass) {
          return `${this.classesError} ${this.labelErrorClass}`
        } else {
          return `${this.classesError}`
        }
      }
      return ''
    },
    selectedItemLabels () {
      if (this.multi) {
        return this.value?.map(val => {
          return {
            ...(this.options.find(o => o.value === val) || {})
          }
        })
      }
      return this.options.find(o => o.value === this.value)?.label
    },
    isValue () {
      if (this.multi) {
        return Array.isArray(this.value) && this.value.length
      }
      return this.value !== null
    }
  },
  created () {
    if (this.multi && !Array.isArray(this.value)) {
      this.onInputValue([])
    } else if (!this.multi && Array.isArray(this.value)) {
      this.onInputValue(null)
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (this.hasOneOptionPreselect && Array.isArray(this.options) && this.options.length === 1) {
        this.clickToItem(this.options[0])
      }
    })
  }
}
</script>
