import { RouteKeys } from '@/router/types'
import dictPermissionRules from '@/Permissions/dictRules'

export default {
  path: RouteKeys.systemTagMessage,
  name: RouteKeys.systemTagMessage,
  redirect: { name: `${RouteKeys.systemTagMessage}-list` },
  meta: {
    name: 'Информ. сообщения',
    icon: 'list-of-comments',
    ruleNamespace: [
      dictPermissionRules.AppServicePermissionRulesCRMMortgageProgramsReadProgramRule,
      dictPermissionRules.AppServicePermissionRulesOBJECTSNewBuildingsRealtyEditRealtyNbRule
    ]
  },
  component: () => import('@/views/Dictionary/Index.vue'),
  children: [
    {
      path: 'list',
      name: `${RouteKeys.systemTagMessage}-list`,
      meta: { name: 'Список информ. сообщений', icon: 'list-of-comments', hideInMenu: true },
      component: () => import('@/views/Dictionary/SystemTagMessage/List.vue')
    },
    {
      path: ':id/edit',
      name: `${RouteKeys.systemTagMessage}-edit`,
      meta: { name: 'Редактирование информ. сообщения', icon: 'list-of-comments', hideInMenu: true },
      component: () => import('@/views/Dictionary/SystemTagMessage/Edit.vue'),
      props: (route:any) => ({ id: route.params.id })
    },
    {
      path: 'create',
      name: `${RouteKeys.systemTagMessage}-create`,
      meta: { name: 'Добавление информ. сообщения', icon: 'list-of-comments', hideInMenu: true },
      component: () => import('@/views/Dictionary/SystemTagMessage/Edit.vue'),
      props: (route:any) => ({ id: route.params.id })
    },
    {
      path: ':id',
      name: `${RouteKeys.systemTagMessage}-show`,
      meta: { name: 'Просмотр информ. сообщения', icon: 'list-of-comments', hideInMenu: true },
      component: () => import('@/views/Dictionary/SystemTagMessage/Edit.vue'),
      props: (route:any) => ({ id: route.params.id })
    }
  ]
}
