<template>
  <div>
    <div style="display: none">
      {{ isEdited }}
    </div>
    <div :class="wrapperClasses">
      <div
        v-if="isInsuranceObjectLabelNone"
        class="tw-min-w-[300px]"
      >
        <TwInputText
          v-model="form.insuranceObjectAddress"
          type="text"
          disable-error-border
          placeholder="Адрес объекта страхования"
          :disabled="formProcessing"
          :show-label="true"
          :persistent-label="true"
          :error-text="$v.form.insuranceObjectAddress.$invalid ? 'Заполните поле':''"
        />
      </div>
      <div
        v-else
        class="tw-min-w-[300px]"
      >
        <TwSelect
          v-model="form.insuranceObjectId"
          label="Объект страхования"
          placeholder="Выбрать"
          :options="dictionary.insuranceObject || []"
          :disabled="true || formProcessing"
          :persistent-label="true"
        />
      </div>
      <div class="tw-min-w-[300px]">
        <TwInputText
          type="text"
          disable-error-border
          placeholder="Оставшаяся сумма по ипотеке"
          max-length="13"
          :value="$Global.inputFormatPrice.getStringSpaced(form.remainingAmountMortgage)"
          :disabled="formProcessing"
          :show-label="true"
          @input="form.remainingAmountMortgage = $Global.inputFormatPrice.getNumber($event)"
        >
          <template #append>
            <Rouble />
          </template>
        </TwInputText>
      </div>
      <div class="tw-min-w-[300px]">
        <TwSelectRelations
          v-model="form.companyId"
          disable-error-border
          :multi="false"
          :dense="false"
          label="Страховая компания"
          list-label="Найти компанию"
          relation-name="insurance"
          :persistent-label="true"
          placeholder="Не выбрана"
          :disabled="formProcessing"
          :error-text="$v.form.companyId.$invalid ? 'Заполните поле':''"
        />
      </div>
      <div class="tw-min-w-[300px]">
        <TwInputText
          v-model="form.payDate"
          type="date"
          disable-error-border
          placeholder="Дата пролонгации"
          :min="currentDate"
          :disabled="formProcessing"
          :show-label="true"
          :persistent-label="true"
          :error-text="$v.form.payDate.$invalid ? 'Заполните поле':''"
        />
      </div>
      <div class="tw-min-w-[300px]">
        <TwInputText
          type="text"
          disable-error-border
          placeholder="Сумма страхования имущества"
          max-length="13"
          :value="$Global.inputFormatPrice.getStringSpaced(form.insuranceObjectSum)"
          :disabled="formProcessing"
          :show-label="true"
          :error-text="$v.form.insuranceObjectSum.$invalid ? 'Заполните поле':''"
          @input="form.insuranceObjectSum = $Global.inputFormatPrice.getNumber($event)"
        >
          <template #append>
            <Rouble />
          </template>
        </TwInputText>
      </div>
      <div class="tw-min-w-[300px]">
        <TwInputText
          type="text"
          disable-error-border
          placeholder="Сумма страхования жизни"
          max-length="13"
          :value="$Global.inputFormatPrice.getStringSpaced(form.insuranceLifeSum)"
          :disabled="formProcessing"
          :show-label="true"
          :error-text="$v.form.insuranceLifeSum.$invalid ? 'Заполните поле':''"
          @input="form.insuranceLifeSum = $Global.inputFormatPrice.getNumber($event)"
        >
          <template #append>
            <Rouble />
          </template>
        </TwInputText>
      </div>
      <div class="tw-min-w-[300px]">
        <TwInputText
          type="text"
          disable-error-border
          placeholder="Сумма страхования титула"
          max-length="13"
          :value="$Global.inputFormatPrice.getStringSpaced(form.insuranceTitleSum)"
          :disabled="formProcessing"
          :show-label="true"
          :error-text="$v.form.insuranceTitleSum.$invalid ? 'Заполните поле':''"
          @input="form.insuranceTitleSum = $Global.inputFormatPrice.getNumber($event)"
        >
          <template #append>
            <Rouble />
          </template>
        </TwInputText>
      </div>
      <div class="tw-min-w-[300px]">
        <TwInputText
          type="text"
          disable-error-border
          placeholder="Вознаграждение агентству"
          max-length="13"
          :value="$Global.inputFormatPrice.getStringSpaced(form.commission)"
          :disabled="formProcessing"
          :show-label="true"
          :error-text="$v.form.commission.$invalid ? 'Заполните поле':''"
          @input="form.commission = $Global.inputFormatPrice.getNumber($event)"
        >
          <template #append>
            <Rouble />
          </template>
        </TwInputText>
      </div>
    </div>
    <div
      v-if="serverErrors"
      class="tw-text-an-flat-red-main tw-text-main-xs tw-text-center"
    >
      <div
        v-for="(msg, index) in serverErrors"
        :key="index"
        class="tw-my-[15px]"
      >
        {{ msg }}
      </div>
    </div>
    <TwCardSimpleFormSaveBlock
      class="tw-mt-[15px]"
      :loading="formProcessing"
      bg-color="transparent"
      :is-sticky="false"
      :visible="true"
      @success="handleSubmit"
      @cancel="handleCancel"
    />
  </div>
</template>

<script>
import API from '@/Api/Crm/Deal/Insurance/Result'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import { required, minValue } from 'vuelidate/lib/validators'
import TwCardSimpleFormSaveBlock from '@/components/tw-ui/forms/TwCardSimpleFormSaveBlock.vue'
import { GridWrapperForPanelShowAndForm } from '@/utils/tw-classes'
import TwSelect from '@/components/tw-ui/ui-kit/input/TwSelect.vue'
import TwInputText from '@/components/tw-ui/ui-kit/input/TwInputText.vue'
import Rouble from '@/components/symbol/Rouble.vue'
import TwSelectRelations from '@/components/tw-ui/ui-kit/input/select-relations/TwSelectRelations.vue'

export default {
  name: 'DealInsuranceResultForm',
  components: { TwSelectRelations, Rouble, TwInputText, TwSelect, TwCardSimpleFormSaveBlock },
  mixins: [FormCreateEditMixin],
  props: {
    dealId: {
      type: Number,
      required: true
    },
    denseFields: {
      type: Boolean,
      default: false
    },
    disableRedirect: {
      type: Boolean,
      default: false
    },
    defaultFormFields: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      wrapperClasses: GridWrapperForPanelShowAndForm.threeCols,
      currentDate: new Date().toISOString().slice(0, 10),
      API: API,
      form: {},
      serverErrors: []
    }
  },
  validations () {
    return {
      form: {
        companyId: {
          required
        },
        insuranceObjectAddress: {
          ...(this.isInsuranceObjectLabelNone) && {
            required
          }
        },
        payDate: {
          required
        },
        insuranceObjectSum: {
          required,
          minValue: minValue(1)
        },
        insuranceLifeSum: {
          required,
          minValue: minValue(1)
        },
        insuranceTitleSum: {
          required,
          minValue: minValue(1)
        },
        commission: {
          required,
          minValue: minValue(1)
        }
      }
    }
  },
  computed: {
    isInsuranceObjectLabelNone () {
      return (this.dictionary.insuranceObject || []).some((obj) => {
        return !!(obj.value === this.form.insuranceObjectId && !obj.label)
      })
    }
  },
  created () {
    this.createQueryParams = {
      dealId: this.dealId
    }
  },
  methods: {
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          const adaperMap = {
            // from(show): to(send)
            deal: 'dealId'
          }
          for (const key in this.preset) {
            if (adaperMap[key] !== undefined) {
              if (adaperMap[key].indexOf('Id') !== -1) {
                console.warn('presetData ', this.preset[key] && this.preset[key].id, this.defaultFormFields[adaperMap[key]])
                this.$set(this.form, adaperMap[key], (this.preset[key] && this.preset[key].id) || this.defaultFormFields[adaperMap[key]])
              }
            } else {
              this.$set(this.form, key, this.preset[key])
            }
          }
        }
        resolve()
      })
    },
    handleResetCustom () {
      this.handleReset()
    },
    handleSubmit () {
      // console.warn(this.$options.name, ' handleSubmit ', this.formType, this.targetId)
      this.$v.$reset()
      this.$v.form.$touch()
      console.warn(this.$options.name, ' handleSubmit ', this.$v.form, this.$v.form.$invalid)
      if (!this.$v.form.$invalid) {
        if (this.formProcessing === true) {
          return
        }

        this.formProcessing = true
        this.serverErrors = []
        if (this.formType === 'create') {
          this.API.store(this.dealId, this.form)
            .then(({ data }) => {
              console.warn(this.$options.name, ' CREATE ', data)
              // this.fetchData(data.data.id)
              this.$emit('create', data.data)
            })
            .catch((error) => {
              if (error?.response?.data) {
                console.error(this.$options.name, error.response.data)
                for (const key in error.response.data) {
                  if (!this.serverErrors) this.serverErrors = []
                  this.serverErrors.push(error.response.data[key][0])
                }
              }
            })
            .finally(() => {
              this.formProcessing = false
            })
        } else if (this.formType === 'edit' && this.targetId) {
          this.API.update(this.targetId, this.form)
            .then(({ data }) => {
              console.warn(this.$options.name, ' UPDATE ', data.data)
              this.$emit('update', data.data)
            })
            .catch((error) => {
              if (error?.response?.data) {
                console.error(this.$options.name, error.response.data)
                for (const key in error.response.data) {
                  const [refKey, index = 0] = key.split('.')
                  console.log(key, refKey, index)
                  if (!this.serverErrors) this.serverErrors = []
                  this.serverErrors.push(error.response.data[key][0])
                }
              }
            })
            .finally(() => {
              this.formProcessing = false
            })
        }
      }
    }
  }
}
</script>
