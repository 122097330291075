<template>
  <div>
    <textarea
      ref="textarea"
      v-model="form.comment"
      :disabled="formProcessing"
      :class="{focus: active}"
      rows="6"
      class="tw-w-full tw-border tw-border-solid tw-px-[15px] tw-pt-[15px] tw-pb-[25px] tw-rounded-sm tw-outline-0 tw-resize-y"
      placeholder="Комментарий"
      v-html="form.comment"
    />
    <div class="tw-mt-[15px]">
      <div class="tw-text-an-flat-blue-btn-active tw-text-sm2 tw-mb-[5px] tw-mt-[10px]">
        Прикрепленные файлы:
      </div>
      <TwUploadFilesList
        v-model="form.filesId"
        label="Прикрепленные файлы"
        :no-data-text="false"
        :is-public="false"
        :has-inline="false"
        :multiple="true"
      />
    </div>
    <TwCardSimpleFormSaveBlock
      class="tw-mt-[15px]"
      bg-color="transparent"
      :loading="formProcessing"
      :cancel-confirm="false"
      :is-sticky="false"
      :disable-success="formProcessing || (!form.comment && !form.filesId.length)"
      :disable-cancel="formProcessing"
      :visible="true"
      @success="handleSubmit"
      @cancel="closeComment"
    />
  </div>
</template>

<script>

import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import API from '@/Api/Crm/Message/Comment'
import TwUploadFilesList from '@/components/tw-ui/ui-kit/files/TwUploadFilesList.vue'
import TwCardSimpleFormSaveBlock from '@/components/tw-ui/forms/TwCardSimpleFormSaveBlock.vue'

export default {
  name: 'FormCommentsSend',
  components: { TwCardSimpleFormSaveBlock, TwUploadFilesList },
  mixins: [FormCreateEditMixin],
  props: {
    id: {
      type: [String, Number, null],
      default: null
    },
    entity: {
      type: String,
      required: true // person | lead | deal
    },
    active: {
      type: Boolean,
      default: false
    },
    entityId: {
      type: [String, Number, null],
      default: null
    }
  },
  data: () => ({
    API,
    form: {
      comment: '',
      filesId: [],
      pin: false
    }
  }),
  mounted () {
    setTimeout(() => {
      if (this.$refs.textarea) this.$refs.textarea.focus()
    }, 400)
  },
  methods: {
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          for (const key in this.preset) {
            if (key === 'files' && Array.isArray(this.preset[key])) {
              this.$set(this.form, 'filesId', this.preset[key].map(f => f.id) || [])
            } else if (key === 'comment') {
              this.$set(this.form, 'comment', this.preset[key])
            }
          }
        }
        resolve()
      })
    },
    handleSubmit () {
      const commentData = {
        comment: this.form.comment,
        pin: this.form.pin,
        filesId: this.form.filesId,
        entity: this.entity,
        entityId: this.entityId.toString()
      }
      if ((!commentData.comment && !commentData.filesId.length) || this.formProcessing) return

      this.formProcessing = true
      if (this.id) {
        API.update(this.id, commentData).then(() => {
          this.$emit('success')
        }).catch((error) => {
          console.error(error)
        }).finally(() => {
          this.formProcessing = false
        })
      } else {
        API.store(commentData).then(() => {
          this.form.comment = ''
          this.$emit('success')
          this.$emit('refresh')
        }).catch((error) => {
          console.error(error)
        }).finally(() => {
          this.formProcessing = false
        })
      }
    },
    closeComment () {
      this.$emit('cancel')
    }
  }
}
</script>

<style>

.feed-add-post-form-but-wrap {
  background-color: #fff;
  border-bottom: 1px solid #f0f1f2;
  position: relative;
  display: flex;
  justify-content: space-between;
}
.send-comment-wrapper {
  background-color: white;
}
.entry-buttons{
  padding: 7px;
  border-top: 1px solid #f0f1f2;
}
.btn-left {
  margin-right: 10px;
}
.crm-entity-stream-content-new-comment-textarea, .crm-entity-stream-content-new-comment-textarea-shadow {
  display: block;
  padding: 10px 14px 10px 16px;
  min-height: 21px;
  width: 100%;
  outline: 0;
  border: 0;
  color: #000;
  font: 13px/18px "Helvetica Neue",Helvetica,Arial,sans-serif;
  resize: none;
  transition: 400ms all ease;
}
.focus {
  padding-bottom: 120px;
  padding-left: 28px;
  padding-top: 15px;
}
.focusnone{
  display: none;
}
</style>
