<template>
  <div>
    <TwPanelContentBlock :content-classes="''">
      <template #title>
        <div>
          Одобренные банки
        </div>
      </template>
      <template #action>
        <TwPanelContentBlockActionButton
          :disabled="disabled || loading"
          type="add"
          @click="onCreateItem"
        >
          добавить
        </TwPanelContentBlockActionButton>
      </template>
      <div>
        <MortgageApprovedBanksList
          ref="list"
          :deal-id="dealId"
          :disabled="disabled || loading"
          @on-edit-item="onEditItem"
          @on-delete-item="onDeleteItem"
        />
      </div>
    </TwPanelContentBlock>

    <!--    Форма создания \ редактирования     -->
    <TwDialog
      v-model="editDialog"
      max-width="450px"
      @close="targetEditId = 0"
    >
      <template #header>
        <div class="tw-text-h5">
          {{ targetEditId ? 'Изменить одобрение' : 'Добавить одобрение' }}
        </div>
      </template>
      <template #body>
        <div class="tw-mt-[20px]">
          <MortgageApprovedBankForm
            v-if="editDialog"
            :id="targetEditId || 0"
            :deal-id="dealId"
            @create="refreshList"
            @update="refreshList"
            @cancel="editDialog = false"
          />
        </div>
      </template>
    </TwDialog>
  </div>
</template>

<script>
import API from '@/Api/Crm/Deal/Mortgage/ApprovalBanksInfo/index'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwPanelContentBlockActionButton from '@/components/tw-ui/panels/TwPanelContentBlockActionButton.vue'
import MortgageApprovedBanksList from '@/views/Deal/TypeViews/mortgage/approvedBanks/MortgageApprovedBanksList.vue'
import TwDialog from '@/components/tw-ui/modal/TwDialog.vue'
import MortgageApprovedBankForm from '@/views/Deal/TypeViews/mortgage/approvedBanks/MortgageApprovedBankForm.vue'

export default {
  name: 'MortgageApprovedBanksBlock',
  components: { MortgageApprovedBankForm, MortgageApprovedBanksList, TwPanelContentBlockActionButton, TwPanelContentBlock, TwDialog },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    dealId: {
      type: [String, Number],
      required: true
    }
  },
  data () {
    return {
      loading: false,
      editDialog: false,
      targetEditId: 0
    }
  },
  methods: {
    refreshList () {
      this.editDialog = false
      this.$refs.list.fetch()
    },
    refresh () {
      this.editDialog = false
      this.$emit('refresh')
    },
    onEditItem (id) {
      this.targetEditId = id || 0
      this.$nextTick(() => {
        this.editDialog = true
      })
    },
    onDeleteItem (id) {
      if (confirm('Удалить запись об одобрении банком?')) {
        if (this.loading) return
        this.loading = true
        API.delete(id)
          .then(() => {
            this.refreshList()
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    onCreateItem () {
      this.targetEditId = 0
      this.$nextTick(() => {
        this.editDialog = true
      })
    }
  }
}
</script>
