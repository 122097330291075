import { getFileResizeByKey } from '@/plugins/helpers/fileHelpers'

export default {
  props: {
    files: {
      type: Array,
      default: () => []
    },
    hideTitle: {
      type: Boolean,
      default: false
    },
    imageSize: {
      type: [Number, String],
      default: 90
    }
  },
  data () {
    return {
      API: null
    }
  },
  methods: {
    getFileResizeByKey: getFileResizeByKey,
    download (file) {
      this.API.download(file.url, file.name)
    },
    imageShow (index) {
      // console.warn('+++', this.filesImages)
      this.$store.dispatch('imageViewer/showImages', {
        files: this.filesImages,
        startIndex: index
      })
    }
  },
  computed: {
    filesImages () {
      return this.files
        .filter(f => f.mime.indexOf('image') !== -1)
        .map(f => {
          return {
            path: f.mime.indexOf('image') !== -1 ? getFileResizeByKey(f, 'full') : '/img/file-not-image.png',
            preview: f.mime.indexOf('image') !== -1 ? getFileResizeByKey(f, 'default') : '/img/file-not-image.png',
            name: f.name,
            size: f.size
          }
        })
    },
    filesForDownloads () {
      return this.files.filter(f => f.mime.indexOf('image') === -1)
    }
  }
}
