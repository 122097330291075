/*
* Переиспользуемый код валидаций проекта
* */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import validateInn from '@/plugins/helpers/validateInn'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import validateSnils from '@/plugins/helpers/validateSnils'

export const VuetifyInputValidatorRules = {
  requireRules: [
    (v: any) => !!v || 'Поле обязательное'
  ],
  requireArrayRules: [
    (v: any) => !!(Array.isArray(v) && v.length) || 'Поле обязательное'
  ],
  requireBooleanRules: [
    (v: any) => typeof v === 'boolean' || 'Поле обязательное'
  ],
  percentRules: [
    (v: any) => (v >= 0 && v <= 100) || '% должен быть от 0 до 100'
  ],
  nameRules: [
    (v: any) => !!v || 'Поле обязательное'
  ],
  genderRules: [
    (v: any) => !!v || 'Поле обязательное'
  ],
  emailRules: [
    (v: any) => /^\S+@\S+\.\S+$/.test(v) || 'Неправильный E-mail'
  ],
  emailRequireRules: [
    (v: any) => !!v || 'Поле обязательное',
    (v: any) => /^\S+@\S+\.\S+$/.test(v) || 'Неправильный E-mail'
    // (v) => /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Неправильный E-mail' // todo old validation regex
  ],
  phoneRules: [
    (v: any) => (!!v && v.toString().replace(/[^\d]/g, '') && v.replace(/[^\d]/g, '').length >= 11) || 'Неверный формат номера телефона'
  ],
  passwordRules: [
    (v: any) => !!v || 'Поле обязательное'
  ],
  docPassport: {
    numberRules: [
      (v: any) => !!(typeof v === 'string' && /^([0-9]){6}$/.test(v)) || 'Неверный формат'
    ],
    codeRules: [
      (v: any) => !!(typeof v === 'string' && /^([0-9-]){7}$/.test(v)) || 'Неверный формат'
    ],
    seriesRules: [
      (v: any) => !!(typeof v === 'string' && /^([0-9]){4}$/.test(v)) || 'Неверный формат'
    ]
  },
  innRules: [
    (v: any) => {
      const errors: any = {}
      validateInn(v, errors)
      if (v) {
        // console.warn(errors)
        return errors?.message ? errors.message : true
      } else {
        return true
      }
    }
  ],
  snilsRules: [
    (v: any) => {
      const errors: any = {}
      validateSnils(v, errors)
      if (v) {
        // console.warn(errors)
        return errors?.message ? errors.message : true
      } else {
        return true
      }
    }
  ]
}
