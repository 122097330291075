<template>
  <!--
   Данный компонент обертка создан для того чтобы пофиксить видимость заполнения input type=date
   в браузере safari, он заполняет поле текущей датой.
   Но vuetify перезаписывает стилистику поля ввода и текст в сафари кажется введённым а не серым.
   Здесь любое false значение делает цвет текста поля неавктивным
   -->
  <v-text-field
    v-bind="$props"
    style="color: #4CAF50"
    :class="[value ? '' : 'gray-is-value-false']"
    v-on="$listeners"
  />
</template>

<script>
/**
 * Полностью компирует функционал  <v-text-field/>
 */
export default {
  name: 'NativeDateInputField',
  props: {
    value: {},
    label: {
      type: String,
      default: ''
    },
    prefix: {
      type: String,
      default: ''
    },
    suffix: {
      type: String,
      default: ''
    },
    errorMessages: {},
    autocomplete: {
      type: String,
      default: 'off'
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'date'
    }
  }
}
</script>
<style lang="less">
/* меняет цвет текста поля ввода предустановленной даты на сафари и плейсхолдер в хроме */
.gray-is-value-false {
  input{
    color: #cbcbcb !important;
  }
}
</style>
