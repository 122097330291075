<template>
  <v-form ref="form">
    <div>
      <div class="error--text">
        <div
          v-for="(error, index) in calculateErrors"
          :key="index"
        >
          <v-alert
            text
            dense
            prominent
            type="error"
          >
            {{ error }}
          </v-alert>
        </div>
      </div>
      <v-row
        justify="center"
        align="start"
        dense
        class="my-2"
      >
        <v-col
          class=""
          cols="6"
        >
          <div class="my-3">
            <v-select
              v-model="calculateForm.product"
              label="Цель кредита"
              item-text="title"
              item-value="product"
              :items="productsList"
              :disabled="disableUi || !productsList.length"
              dense
              attach=""
              color="primary"
              outlined
              @input="changeFormField"
              @change="calculateForm.discounts = []; calculateForm.subproduct = null"
            />
            <v-select
              v-model="calculateForm.subproduct"
              label="Программа"
              item-text="title"
              item-value="subproduct"
              :items="subProductsList"
              :disabled="disableUi || !subProductsList.length"
              dense
              hide-details
              attach=""
              color="primary"
              outlined
              @input="changeFormField"
              @change="calculateForm.discounts = []"
            />
          </div>
          <div class="my-3">
            <combined-price-input
              v-model.number="calculateForm.estateCost"
              :disabled="disableUi"
              track-color="blue accent-1"
              track-point-color="blue accent-3"
              track-fill-color="blue accent-4"
              :min="1"
              label="Стоимость недвижимости"
              @input="changeFormField"
            />
          </div>
          <div class="my-3">
            <combined-price-input
              v-model.number="calculateForm.deposit"
              track-color="blue accent-1"
              track-point-color="blue accent-3"
              track-fill-color="blue accent-4"
              :max="calculateForm.estateCost"
              :min="1"
              :disabled="disableUi"
              percent-show
              label="Первоначальный взнос"
              @input="changeFormField"
            />
          </div>
          <div class="my-3">
            <combined-price-input
              :value="calculateForm.term / 12"
              track-color="blue accent-1"
              track-point-color="blue accent-3"
              track-fill-color="blue accent-4"
              :max="30"
              :min="1"
              :disabled="disableUi"
              bottom-label-text="лет"
              label="Срок кредита"
              @input="calculateForm.term = $event * 12; changeFormField()"
            >
              <template #currentLabel>
                {{ $Global.textPlural(calculateForm.term, 'year') }}
              </template>
            </combined-price-input>
          </div>
        </v-col>
        <v-col
          class="d-flex flex-column"
          cols="6"
        >
          <div class="my-3">
            <v-select
              v-model="calculateForm.borrowerCategory"
              label="Категория заёмщика"
              item-text="label"
              item-value="value"
              :items="borrowerCategory"
              :disabled="disableUi"
              dense
              attach=""
              outlined
              @input="changeFormField"
            />
            <v-select
              v-model="calculateForm.discounts"
              label="Скидки и акции"
              item-text="title"
              item-value="discount"
              :items="subProductDiscountsList"
              :disabled="disableUi"
              small-chips
              dense
              multiple
              hide-details
              attach=""
              color="primary"
              outlined
              @input="changeFormField"
            />
          </div>
          <DomClickCalculationShow
            :style="{ filter: disableUi ? 'blur(2px)' : 'none' }"
            :info="calculateInfo"
          />
        </v-col>
        <v-col cols="12">
          <!-- Ошибки создания заявки -->
          <div class="error--text">
            <div
              v-for="(error, index) in createDealErrors"
              :key="index"
            >
              <v-alert
                text
                dense
                prominent
                type="error"
              >
                {{ error }}
              </v-alert>
            </div>
          </div>

          <div class="my-3 d-flex justify-space-between">
            <!--            <v-btn color="primary"-->
            <!--                   outlined-->
            <!--                   class="my-3"-->
            <!--                   @click="onChangeCalculateForm"-->
            <!--                   :disabled="disableUi || (!id)">-->
            <!--              Обновить расчет-->
            <!--            </v-btn>-->
            <v-spacer />
            <v-btn
              color="success"
              class="my-3"
              :loading="loading"
              :disabled="disableUi || (!id)"
              @click="createDeal"
            >
              {{ dealId ? 'Открыть заявку':'Создать заявку' }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-form>
</template>

<script>
import { VuetifyInputValidatorRules } from '@/plugins/helpers/ValidationUtils'
import domClickCalculateAPI from '@/Api/Crm/Mortgage/Docmclick/Calculations'
import { loanPurpose, marriageStatus, jobIncomeType, programsType, discounts, borrowerCategory } from './DomClickDict'
import CombinedPriceInput from '@/components/input/price/CombinedPriceInput'
import lodashDebounce from 'lodash/debounce'
import DomClickCalculationShow from '@/views/MortgageProgramWorksheet/DomClickWorksheet/DomClickCalculationShow'
import API from '@/Api/Crm/Mortgage/Docmclick/Deals'

export default {
  name: 'DomClickProductCalculate',
  components: { DomClickCalculationShow, CombinedPriceInput },
  props: {
    id: { // идентификатор расчёта
      type: Number,
      default: 0
    },
    dealId: { // идентификатор заявкеи домклик
      type: Number,
      default: 0
    },
    dealProgramId: {
      type: Number,
      default: 0
    },
    calculateInfo: {
      type: Object,
      require: true
    },
    targetProgram: {},
    productsList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      requireRules: VuetifyInputValidatorRules.requireRules,
      loading: false,
      loadingCalculate: false,
      loanPurpose,
      marriageStatus,
      jobIncomeType,
      discounts,
      borrowerCategory,
      calculateErrors: [],
      calculateForm: {
        borrowerCategory: 'cannotConfirmIncome',
        region: 0,
        discounts: [],
        product: 'SECONDARY',
        subproduct: '',
        estateCost: 0,
        deposit: 0,
        term: 0
      },
      createDealErrors: []
    }
  },
  methods: {
    programTypeInfo (val) {
      return programsType.find(t => t.value === val)
    },
    discountsInfo (val) {
      return discounts.find(t => t.value === val)
    },
    onErrorCalculate (errorData) {
      console.warn('onErrorCalculate', errorData)
      this.calculateErrors = errorData
    },
    // Формирование расчёта при изменениях
    onChangeCalculateForm () {
      console.log('onChangeCalculateForm', this.calculateForm, this.dealProgramId, this.id)
      this.loadingCalculate = true
      this.calculateErrors = []
      if (this.id) {
        console.warn('onChangeCalculateForm UPDATE')
        domClickCalculateAPI.update(this.id, this.calculateForm).then(({ data }) => {
          console.warn('onChangeCalculateForm UPDATE data', data)
          if (data?.errors) {
            const errors = Array.isArray(data?.errors) && data?.errors.length ? data?.errors : [data?.message || 'Ошибка обновления расчёта']
            this.onErrorCalculate(errors)
          } else {
            this.$emit('calculateUpdated', data?.data?.id)
          }
        }).catch(({ response }) => {
          console.error('onChangeCalculateForm UPDATE error', response)
        }).finally(() => {
          this.loadingCalculate = false
        })
      } else if (this.dealProgramId) {
        console.warn('onChangeCalculateForm STORE')
        domClickCalculateAPI.store(this.dealProgramId, this.calculateForm).then(({ data }) => {
          if (data?.errors) {
            const errors = Array.isArray(data?.errors) && data?.errors.length ? data?.errors : [data?.message || 'Ошибка создания расчёта']
            this.onErrorCalculate(errors)
          } else {
            this.$emit('calculateUpdated', data?.data?.id)
          }
        }).catch(({ response }) => {
          console.error('onChangeCalculateForm STORE', response)
        }).finally(() => {
          this.loadingCalculate = false
        })
      } else {
        console.error('Нет данных для отправки', this.dealProgramId, this.id)
        this.loadingCalculate = false
      }
    },
    debounceCalculateForm: lodashDebounce(function () {
      this.onChangeCalculateForm()
    }, 500),
    changeFormField () {
      this.debounceCalculateForm()
    },
    formCompare (isInit) {
      // console.warn('formCompare 0', this.$options.name, this.targetProgram, this.calculateInfo)
      let formCompareFromProgram = {}
      if (this.targetProgram) {
        const { discounts = [], product, subproduct, deposit, term = 120, realtyCost: estateCost } = this.targetProgram
        formCompareFromProgram = {
          discounts,
          product,
          subproduct,
          deposit,
          term,
          estateCost
        }
        // console.warn('formCompare 1', this.$options.name, formCompareFromProgram, this.calculateForm)
      }
      // если инциализация то приоритет имеют данные программы, иначе в приоритете данные тукущего расчёта
      this.calculateForm = {
        ...this.calculateForm,
        ...(isInit) ? {
          ...(this.calculateInfo || {}),
          ...formCompareFromProgram
        } : {
          ...formCompareFromProgram,
          ...(this.calculateInfo || {})
        }
      }
      // console.warn('formCompare 2', this.$options.name, this.calculateForm)
      if (isInit) {
        this.changeFormField()
      }
    },
    createDeal () {
      console.warn('createDeal', this.dealProgramId, this.calcId, this.id, this.dealId)
      if (this.dealId) {
        this.$emit('goto-deal')
      } else {
        this.createDealErrors = []
        this.loading = true
        API.store(this.dealProgramId, { calcId: this.id }).then(({ data }) => {
          console.warn('createDeal', data)
          if (data?.data) {
            this.$nextTick(() => {
              this.$emit('goto-deal', data)
            })
          } else if (data.errors) {
            this.createDealErrors = Array.isArray(data.errors) && data.errors.length ? data.errors : [data?.message || 'Ошибка создания анкеты']
            this.loading = false
          }
        }).catch((error) => {
          console.error('createDeal', error)
          this.loading = false
        })
      }
    }
  },
  computed: {
    disableUi () {
      return this.loading || this.loadingCalculate
    },
    subProductsList () {
      return [
        // ...[{ title: 'Не выбран', product: null }],
        ...this.productsList?.find(p => p.product === this.calculateForm.product)?.subproducts || []
      ]
    },
    subProductDiscountsList () {
      return [
        ...this.subProductsList?.find(p => p.subproduct === this.calculateForm.subproduct)?.discounts || []
      ]
    }
  },
  watch: {
    targetProgram: {
      handler () {
        this.formCompare(true)
      },
      deep: true,
      immediate: false
    },
    calculateInfo: {
      handler () {
        this.formCompare()
      },
      deep: true,
      immediate: false
    }
  },
  mounted () {
    this.formCompare(true)
  }
}
</script>

<style scoped>
.v-list-item__content .v-list-item__title {
  white-space: inherit;
}
</style>
