import { RouteKeys } from '@/router/types'

export default {
  path: `${RouteKeys['program-category']}`,
  name: RouteKeys['program-category'],
  redirect: { name: `${RouteKeys['program-category']}-list` },
  meta: { name: 'Категории программ кредитования', icon: 'books-mortgage-category' },
  component: () => import('@/views/Dictionary/Mortgage/ProgramCategory/Index.vue'),
  children: [
    {
      path: 'list',
      name: `${RouteKeys['program-category']}-list`,
      meta: { name: 'Список категорий программ кредитования', icon: 'books-mortgage-category', hideInMenu: true },
      component: () => import('@/views/Dictionary/Mortgage/ProgramCategory/List.vue')
    },
    {
      path: ':id/edit',
      name: `${RouteKeys['program-category']}-edit`,
      meta: { name: 'Редактирование категории', icon: 'books-mortgage-category', hideInMenu: true },
      component: () => import('@/views/Dictionary/Mortgage/ProgramCategory/Edit.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    },
    {
      path: 'create',
      name: `${RouteKeys['program-category']}-create`,
      meta: { name: 'Добавление категории', icon: 'books-mortgage-category', hideInMenu: true },
      component: () => import('@/views/Dictionary/Mortgage/ProgramCategory/Edit.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    },
    {
      path: ':id',
      name: `${RouteKeys['program-category']}-show`,
      meta: { name: 'Просмотр категории', icon: 'books-mortgage-category', hideInMenu: true },
      component: () => import('@/views/Dictionary/Mortgage/ProgramCategory/Edit.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    }
  ]
}
