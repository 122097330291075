<template>
  <div
    v-if="subAuth"
    class="tw-h-[60px] tw-min-w-[280px] tw-bg-an-flat-red-main tw-rounded-[8px] tw-text-white tw-text-[12px] tw-font-bold tw-pl-[13px] tw-pr-[4px]"
  >
    <div
      v-if="subAuthUserInfo"
      class="tw-h-full tw-flex tw-items-center tw-gap-[10px]"
    >
      <div class="tw-h-10 tw-w-10 tw-rounded-full tw-bg-gray-50 tw-border-solid tw-border-2 tw-border-white tw-overflow-hidden">
        <img
          v-if="avatar"
          :src="avatar"
          alt="Авартар суб авторизации"
          class="tw-min-h-10 tw-min-w-10"
        >
        <div
          v-else
          class="tw-flex tw-items-center tw-justify-center tw-w-full tw-h-full"
        >
          <svg
            class="tw-m-auto"
            width="15"
            height="17"
            viewBox="0 0 15 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.4"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.5 1.5C5.84596 1.5 4.58775 2.98526 4.8597 4.61679L5.0822 5.95179C5.08219 5.95179 5.0822 5.9518 5.0822 5.95179C5.2792 7.13371 6.30181 8 7.5 8C8.6982 8 9.7208 7.13371 9.9178 5.9518L10.1403 4.61679C10.4123 2.98526 9.15404 1.5 7.5 1.5ZM3.8733 4.7812C3.49976 2.54011 5.22804 0.5 7.5 0.5C9.77196 0.5 11.5002 2.54012 11.1267 4.78121L10.9042 6.1162C10.6268 7.78028 9.18705 9 7.5 9C5.81295 9 4.37317 7.78029 4.0958 6.11621L3.8733 4.7812C3.8733 4.7812 3.8733 4.7812 3.8733 4.7812ZM5.47087 9.58183C5.56375 9.84189 5.42822 10.128 5.16817 10.2209L2.24774 11.2639C2.24773 11.2639 2.24774 11.2639 2.24774 11.2639C1.94925 11.3705 1.75 11.6532 1.75 11.9702V13.8901C1.75 14.0422 1.75619 14.1572 1.777 14.2497C1.79643 14.336 1.82267 14.375 1.84552 14.3971C2.04523 14.59 2.50875 14.8715 3.42397 15.1021C4.33023 15.3305 5.64346 15.5 7.5 15.5C9.35654 15.5 10.6698 15.3305 11.576 15.1021C12.4912 14.8715 12.9548 14.59 13.1545 14.3971C13.1773 14.375 13.2036 14.336 13.223 14.2497C13.2438 14.1572 13.25 14.0422 13.25 13.8901V11.9702C13.25 11.6532 13.0508 11.3705 12.7523 11.2639C12.7523 11.2639 12.7523 11.2639 12.7523 11.2639L9.83183 10.2209C9.57178 10.128 9.43625 9.84189 9.52913 9.58183C9.622 9.32178 9.90811 9.18625 10.1682 9.27913L13.0886 10.3221C13.7851 10.5709 14.25 11.2306 14.25 11.9702V13.8901C14.25 14.1831 14.2465 14.7325 13.8493 15.1163C13.473 15.4797 12.82 15.8199 11.8204 16.0718C10.8119 16.326 9.41215 16.5 7.5 16.5C5.58785 16.5 4.18811 16.326 3.17959 16.0718C2.18003 15.8199 1.52696 15.4797 1.15073 15.1163C0.753455 14.7325 0.75 14.1831 0.75 13.8901V11.9702C0.75 11.2306 1.21488 10.5709 1.91139 10.3221L4.83183 9.27913C5.09189 9.18625 5.378 9.32178 5.47087 9.58183Z"
              fill="black"
            />
          </svg>
        </div>
      </div>
      <div class="tw-flex-1">
        <div>
          Просмотр CRM от лица:
        </div>
        <div
          class="tw-opacity-70"
          title="Профиль пользователя"
        >
          <ViewPanelLink
            :id="subAuthUserInfo.id"
            label="Пользователь"
            component="employee-show"
            no-link-style
          >
            {{ $Global.peopleName(subAuthUserInfo, 'if') }}
          </ViewPanelLink>
        </div>
      </div>
      <div
        class="tw-group tw-p-[10px] tw-rounded-full tw-cursor-pointer tw-transition-all tw-duration-300 hover:tw-rotate-180"
        title="Выйти из суб аккаунта"
        @click.stop="subAuthLogOut"
      >
        <svg
          class=""
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M17.25 13.4167V15.3333C17.25 17.4512 15.5336 19.1667 13.4167 19.1667H3.83333C1.71541 19.1667 -1.90735e-06 17.4512 -1.90735e-06 15.3333C-1.90735e-06 12.143 -1.90735e-06 7.02458 -1.90735e-06 3.83333C-1.90735e-06 1.71637 1.71541 0 3.83333 0C6.57321 0 10.6758 0 13.4167 0C15.5336 0 17.25 1.71637 17.25 3.83333V5.75C17.25 6.279 16.8207 6.70833 16.2917 6.70833C15.7627 6.70833 15.3333 6.279 15.3333 5.75C15.3333 5.75 15.3333 4.85204 15.3333 3.83333C15.3333 2.77533 14.4747 1.91667 13.4167 1.91667H3.83333C2.77437 1.91667 1.91666 2.77533 1.91666 3.83333V15.3333C1.91666 16.3923 2.77437 17.25 3.83333 17.25C6.57321 17.25 10.6758 17.25 13.4167 17.25C14.4747 17.25 15.3333 16.3923 15.3333 15.3333C15.3333 14.3156 15.3333 13.4167 15.3333 13.4167C15.3333 12.8886 15.7627 12.4583 16.2917 12.4583C16.8207 12.4583 17.25 12.8886 17.25 13.4167ZM9.98008 8.625L11.2192 7.38683C11.593 7.01212 11.593 6.4055 11.2192 6.03079C10.8445 5.65704 10.2379 5.65704 9.86412 6.03079L6.98912 8.90579C6.61441 9.2805 6.61441 9.88712 6.98912 10.2618L9.86412 13.1368C10.2379 13.5106 10.8445 13.5106 11.2192 13.1368C11.593 12.7621 11.593 12.1555 11.2192 11.7808L9.98008 10.5417H18.2083C18.7373 10.5417 19.1667 10.1123 19.1667 9.58333C19.1667 9.05529 18.7373 8.625 18.2083 8.625H9.98008Z"
            fill="white"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import { employeeAvatarListGetLink } from '@/views/Company/Employee/employeeHelpers'
import { mapGetters, mapActions } from 'vuex'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink'
export default {
  name: 'TwAdminSubAuth',
  components: { ViewPanelLink },
  methods: {
    ...mapActions({
      subAuthLogOut: 'profile/subAuthLogOut'
    })
  },
  computed: {
    ...mapGetters({
      subAuth: 'profile/subAuth',
      subAuthUserInfo: 'profile/subAuthUserInfo',
      deploymentType: 'getDeploymentType'
    }),
    avatar () {
      return this.subAuthUserInfo?.file ? employeeAvatarListGetLink(this.subAuthUserInfo.file) : null
    }
  }
}
</script>

<style scoped>

</style>
