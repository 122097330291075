<template>
  <TwPanelContentBlock
    class="tw-mb-[6px] tw-bg-white"
    style-hr="tw-bg-an-flat-hr-border-grey"
    content-classes="''"
  >
    <template #title>
      <div>
        Обо мне
      </div>
    </template>
    <template #action>
      <TwPanelContentBlockActionButton
        v-if="allowEdit"
        type="edit"
        @click="isEdit = !isEdit"
      >
        {{ isEdit ? 'отменить':'изменить' }}
      </TwPanelContentBlockActionButton>
    </template>
    <div class="tw-flex tw-flex-col tw-gap-[12px] tw-font-medium">
      <div
        v-if="loading"
        class="tw-w-full tw-text-center tw-py-[35px]"
      >
        <TwCircleLoader />
      </div>
      <div v-else>
        <EmployeeAboutForm
          v-if="isEdit"
          :id="userAboutId || 0"
          ref="EditForm"
          :user-id="userId"
          @refresh="fetchInfo"
          @cancel="isEdit = false"
        />
        <EmployeeAboutShow
          v-else
          :info="info"
        />
      </div>
    </div>
  </TwPanelContentBlock>
</template>

<script>
import API from '@/Api/Auth/User/about'
import EmployeeAboutForm from '@/views/Company/Employee/about/EmployeeAboutForm'
import EmployeeAboutShow from '@/views/Company/Employee/about/EmployeeAboutShow'
import dictPermissionRules from '@/Permissions/dictRules'
import { mapGetters } from 'vuex'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import TwPanelContentBlockActionButton from '@/components/tw-ui/panels/TwPanelContentBlockActionButton.vue'

export default {
  name: 'EmployeeAboutBlock',
  components: { TwPanelContentBlockActionButton, TwCircleLoader, TwPanelContentBlock, EmployeeAboutShow, EmployeeAboutForm },
  props: {
    userData: {
      required: true
    }
  },
  data () {
    return {
      info: null,
      loading: false,
      isEdit: false
    }
  },
  methods: {
    fetchInfo () {
      this.isEdit = false
      if (!this.userAboutId) {
        this.$emit('refresh')
        return
      }

      this.loading = true
      API.show(this.userAboutId).then(({ data }) => {
        this.info = data?.data || null
      }).catch((error) => {
        console.log(error)
      }).finally(() => {
        this.loading = false
        this.isEdit = false
      })
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'profile/isAdmin',
      allowNamespacesDict: 'profile/allowNamespacesDict'
    }),
    userAboutId () {
      return this.userData?.about?.id || null
    },
    userId () {
      return this.userData?.id || null
    },
    allowEdit () {
      return this.isAdmin || !!this.allowNamespacesDict[dictPermissionRules.AppServicePermissionRulesCRMUsersUpdateUserAboutRule]
    }
  },
  watch: {
    userAboutId () {
      this.fetchInfo()
    }
  },
  mounted () {
    this.fetchInfo()
  }
}
</script>

<style scoped>

</style>
