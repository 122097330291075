<template>
  <div class="tw-font-manrope tw-text-main-sm2 tw-max-w-[100%] tw-px-[26px] tw-py-[18px] tw-bg-white tw-min-h-screen">
    <template v-if="isCardContentVisible">
      <!--   ЗАГОЛОВОК   -->
      <TwPanelTitle>
        <template #left>
          <div class="tw-text-h4 tw-font-bold">
            <div
              v-if="isNew"
              class="title text-muted"
            >
              Новый Акт
            </div>
            <div v-else-if="data">
              <span>Акт</span>
              <span class="tw-text-an-flat-disabled-text tw-font-medium"> # {{ data.id }}</span>
            </div>
            <div v-else>
              ~
            </div>
          </div>
        </template>
        <template #right>
          <MenuEntityActions
            v-if="!isNew && data && !data.formed"
            :permissions="{ entity: { delete: true } }"
            @delete="deleteCardItem"
          />
        </template>
      </TwPanelTitle>

      <!--   ВКЛАДКИ   -->
      <TwPanelTabsFull
        v-model="currentTab"
        :items="tabList"
      >
        <template #content.main>
          <TwPanelContent>
            <TwPanelContentBlock class="tw-mb-[6px] tw-flex-1">
              <template #title>
                <div>
                  О акте
                </div>
              </template>
              <template
                v-if="!isNew"
                #action
              >
                <TwPanelContentBlockActionButton
                  :disabled="isNew || loading"
                  type="edit"
                  @click="editMain = !editMain"
                >
                  {{ editMain ? 'отменить':'изменить' }}
                </TwPanelContentBlockActionButton>
              </template>
              <div>
                <template v-if="editMain">
                  <TwAccountingUprocessedCompanySelect
                    :value="(data && data.companyId) || selectedCompanyId"
                    :disabled="loading || !!(data && data.companyId)"
                    :dense="true"
                    @input="selectedCompanyId = $event"
                  />
                  <ActMainForm
                    v-if="(data && data.companyId) || selectedCompanyId"
                    :id="data ? data.id : 0"
                    ref="EditMain"
                    :company-id="(data && data.companyId) || selectedCompanyId"
                    @create="onCreated"
                    @update="onUpdated"
                    @cancel="onCancelAndClose"
                  />
                </template>
                <ActMainShow
                  v-else
                  :dictionary="dictionary"
                  :info="data"
                  @refresh="refresh"
                />
              </div>
            </TwPanelContentBlock>
            <div class="tw-flex-1">
              <TwPanelContentBlock
                v-if="!editMain && data"
                class="tw-mb-[6px]"
              >
                <template #title>
                  <div>
                    Действия
                  </div>
                </template>
                <div class="tw-flex tw-gap-[10px] tw-flex-wrap">
                  <TwPanelActionButton
                    v-if="!isNew"
                    color="gray"
                    :disabled="actionProcess"
                    @click.prevent="onAction(actActions.ready)"
                  >
                    Завершить редактирование
                  </TwPanelActionButton>
                  <TwPanelActionButton
                    v-if="!isNew"
                    color="gray"
                    :disabled="actionProcess"
                    @click.prevent="onAction(actActions.received)"
                  >
                    Подтвердить получение акта
                  </TwPanelActionButton>
                  <TwPanelActionButton
                    v-if="!isNew"
                    color="gray"
                    :disabled="actionProcess"
                    @click.prevent="onAction(actActions.payed)"
                  >
                    Подтвердить оплату
                  </TwPanelActionButton>
                </div>
              </TwPanelContentBlock>
              <TwPanelContentBlock
                v-if="!editMain && data"
                class="tw-mb-[6px]"
              >
                <template #title>
                  <div>
                    Список
                  </div>
                </template>
                <div class="tw-flex tw-flex-col tw-gap-[8px]">
                  <div
                    v-for="record in data.records"
                    :key="record.id"
                    class="tw-cursor-default tw-p-[8px] tw-flex tw-gap-[16px] tw-items-center odd:tw-bg-an-flat-bg-main"
                  >
                    <div class="tw-flex-1 tw-font-semibold">
                      {{ record.name }}
                    </div>
                    <div class="tw-font-medium tw-text-main-lg">
                      {{ (record.amount || 0).toLocaleString('ru') }} <span class="tw-text-main-sm">руб.</span>
                    </div>
                  </div>
                </div>
              </TwPanelContentBlock>
            </div>
          </TwPanelContent>
        </template>
      </TwPanelTabsFull>
    </template>
    <!--
      Блок заполняющий пространство при отсутствуии контента
      для прижатия блока сохранения к нижней части страницы
    -->
    <div class="tw-flex tw-justify-center tw-items-center">
      <TwCircleLoader
        v-if="loading && !isCardContentVisible"
        width="50px"
        height="50px"
      />
      <div v-else-if="error">
        <PermissionDenied :text="error === 403 ? 'Недостаточно прав для просмотра!' : 'Ошибка соединения - Обратитесь к администратору!'" />
      </div>
    </div>
    <div v-if="isAdmin && !isNew">
      <TwSpoiler
        title-classes="tw-mb-[6px] tw-px-[15px] tw-py-[8px] tw-font-medium tw-bg-an-flat-gray-light tw-text-main-sm tw-w-full"
        content-classes="tw-w-full"
        title-variant="block"
      >
        <template #title>
          DEBUG isAdmin
        </template>
        <div class="">
          DATA:
          <JsonPretty :json="data" />
          OTHER:
          <JsonPretty :json="otherData" />
        </div>
      </TwSpoiler>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import API from '@/Api/Accounting/BuyNb/Act'
import AccountingBuyNbActActionAPI from '@/Api/Accounting/BuyNb/Act/Action'
import CardMixin from '@/mixins/CardMixin'
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import MenuEntityActions from '@/components/core/ViewPanel/Card/entity-actions/menuEntityActions'
import PermissionDenied from '@/views/PermissionDenied'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import TwPanelTitle from '@/components/tw-ui/panels/TwPanelTitle.vue'
import TwPanelContent from '@/components/tw-ui/panels/TwPanelContent.vue'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwPanelTabsFull from '@/components/tw-ui/panels/TwPanelTabsFull.vue'
import TwPanelActionButton from '@/components/tw-ui/panels/TwPanelActionButton.vue'
import TwPanelContentBlockActionButton from '@/components/tw-ui/panels/TwPanelContentBlockActionButton.vue'
import ActMainForm from './main/ActMainForm.vue'
import ActMainShow from './main/ActMainShow.vue'
import JsonPretty from '@/components/development/JsonPretty.vue'
import TwSpoiler from '@/components/tw-ui/other/TwSpoiler.vue'
import TwAccountingUprocessedCompanySelect from '@/components/filters/twfilters/fields/custom-inputs/TwAccountingUprocessedCompanySelect.vue'

export default {
  components: {
    TwAccountingUprocessedCompanySelect,
    JsonPretty,
    TwSpoiler,
    ActMainForm,
    ActMainShow,
    TwPanelContentBlockActionButton,
    TwPanelActionButton,
    TwPanelTabsFull,
    TwPanelContentBlock,
    TwPanelContent,
    TwPanelTitle,
    TwCircleLoader,
    PermissionDenied,
    MenuEntityActions
  },
  mixins: [CardMixin, ViewPanelShowMixin],
  props: {},
  data () {
    return {
      API: API,
      currentTab: 'main',
      selectedCompanyId: null,
      serverErrorMessage: null,
      actionProcess: false,
      actActions: {
        ready: {
          method: AccountingBuyNbActActionAPI.ready
        },
        received: {
          method: AccountingBuyNbActActionAPI.received
        },
        payed: {
          method: AccountingBuyNbActActionAPI.payed
        }
      }
    }
  },
  methods: {
    onAction (action) {
      this.serverErrorMessage = null
      this.actionProcess = true
      action.method(this.data.id, {}).then(() => {
        this.refresh()
      }).catch((error) => {
        this.serverErrorMessage = error.serverErrorMessage
      }).finally(() => {
        this.actionProcess = false
      })
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'profile/isAdmin'
    }),
    tabList () {
      return [
        {
          label: 'Общая информация',
          key: 'main'
        }
      ]
    }
  }
}
</script>
