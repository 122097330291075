<template>
  <div class="tw-font-manrope tw-min-h-screen tw-flex tw-gap-2 tw-flex-col tw-flex-auto tw-flex-shrink-0 tw-bg-white tw-text-gray-800 tw-scrollbar">
    <div
      class="tw-fixed tw-z-[2] tw-top-0 tw-left-0 tw-flex tw-flex-col tw-bg-white tw-h-full tw-transition-all"
      :class="[sidebarSizeClass, sidebarMiniHoveredClass]"
    >
      <div class="tw-flex tw-items-center tw-justify-start">
        <div
          class="tw-flex tw-items-center tw-gap-4 tw-px-3 tw-py-4 tw-flex-1 tw-cursor-pointer"
          :class="topUiHeightClass"
          title="На главную"
          @click.stop="goToHome"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="52"
            height="43"
            viewBox="0 0 44 43"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.69322 43H33.5339C38.8654 43 43.2272 38.6611 43.2272 33.3578V9.64221C43.2272 4.33886 38.8654 0 33.5339 0H9.69322C4.36178 0 0 4.33886 0 9.64221V33.3578C0 38.6611 4.36178 43 9.69322 43Z"
              fill="#E63114"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M11.689 17.9147H20.0801V25.0841H11.689V17.9147ZM10.0983 28.2485H20.0801V31.6931H23.2618V28.2485H33.2437C34.119 28.2485 34.8341 27.5373 34.8341 26.6666V16.3317C34.8341 15.4617 34.1188 14.75 33.2437 14.75H10.098C9.22246 14.75 8.50781 15.4608 8.50781 16.3317V26.6666C8.50781 27.5373 9.22291 28.2485 10.0983 28.2485ZM23.2618 17.9147H31.6526V25.0841H23.2618V17.9147Z"
              fill="white"
            />
          </svg>
          <svg
            v-show="!localIsMiniLeftPanel"
            xmlns="http://www.w3.org/2000/svg"
            width="121"
            height="21"
            viewBox="0 0 121 21"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M64.9337 0.09375V3.8145H87.3247V6.38779H73.4001V9.66922H87.3247V12.2433H64.9337V15.9635H89.1962C90.2249 15.9635 91.065 15.1279 91.065 14.104V1.95332C91.065 0.929563 90.2249 0.09375 89.1962 0.09375H64.9337ZM14.3399 20.0133H18.0803V15.9635H29.8159C30.8452 15.9635 31.6857 15.1275 31.6857 14.104V1.95332C31.6857 0.930279 30.8447 0.09375 29.8159 0.09375H2.60374C1.57463 0.09375 0.734375 0.929563 0.734375 1.95332V14.104C0.734375 15.1275 1.57499 15.9635 2.6042 15.9635H14.3399V20.0133ZM18.0803 3.8145H27.9453V12.2433H18.0803V3.8145ZM14.3399 12.2433H4.47437V3.8145H14.3399V12.2433ZM120.938 0.09375H94.8054V3.8145H106.001V15.9635H109.742V3.8145H120.938V0.09375ZM57.0819 15.9635H60.8358V0.09375L41.1654 0.0959892C40.3352 0.0963475 39.6326 0.608675 39.3828 1.39647L34.7638 15.9632H38.6856L42.5374 3.81415H57.0819V15.9635Z"
              fill="#1B1918"
            />
          </svg>
        </div>
        <button
          v-show="!isHover"
          :class="[miniLeftPanelButtonClass]"
          :title="localIsMiniLeftPanel ? 'Развернуть меню' : 'Скрыть меню'"
          class="tw-shadow-[-3px_7px_21.6px_0_rgba(185,191,208,0.35)] tw-transition-all tw-absolute tw-flex tw-items-center tw-justify-center tw-bg-white tw-h-[40px] tw-w-[40px] tw-my-[10px] tw-rounded-[8px] tw-border tw-group focus:tw-outline-0"
          @click.prevent="changePanel"
        >
          <svg
            class="tw-transition-all"
            :class="{'tw-rotate-90' : !localIsMiniLeftPanel}"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="15"
            viewBox="0 0 24 15"
            fill="none"
          >
            <path
              class="group-hover:tw-stroke-an-flat-red-main tw-transition-color tw-duration-150"
              d="M22.375 1.91406H1"
              stroke="#9E9E9E"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              class="group-hover:tw-stroke-an-flat-red-main tw-transition-color tw-duration-150"
              d="M22.375 7.91406H1"
              stroke="#9E9E9E"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              class="group-hover:tw-stroke-an-flat-red-main tw-transition-color tw-duration-150"
              d="M22.375 13.9141H1"
              stroke="#9E9E9E"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
      <div
        class="tw-flex-grow tw-overflow-y-auto"
        @mouseover="mouseOver"
        @mouseleave="mouseLeave"
      >
        <slot
          name="sidebar"
          :isMiniLeftPanel="localIsMiniLeftPanel"
        >
          Sidebar Slot
        </slot>
      </div>
    </div>
    <div
      class="tw-z-auto tw-flex tw-flex-col tw-h-full"
      :class="contentLeftMargin"
    >
      <div
        class="tw-z-[1] tw-top-0"
        :class="$route.meta.toolbarNotSticky ? 'tw-relative':'tw-sticky'"
      >
        <slot
          name="toolbar"
          :topUiHeightClass="topUiHeightClass"
        >
          Toolbar Slot
        </slot>
      </div>
      <slot
        name="content"
        :topUiHeightClass="topUiHeightClass"
      >
        Content Slot
      </slot>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import lodashDebounce from 'lodash/debounce'

export default {
  name: 'TwMainLayout',
  data () {
    return {
      topUiHeightClass: 'tw-min-h-[80px]',
      isHover: false
    }
  },
  methods: {
    ...mapMutations({
      SET_MINIFY_LEFT_PANEL: 'SET_MINIFY_LEFT_PANEL'
    }),
    goToHome () {
      console.warn('goToHome', this.$router, this.$route)
      this.$router.push('/').catch(() => {
        window.location.reload()
      })
    },
    mouseOver () {
      // console.warn('sidebar mouseOver')
      if (this.isMiniLeftPanel) this.changeHover(true)
    },
    mouseLeave () {
      // console.warn('sidebar mouseLeave')
      if (this.isMiniLeftPanel) this.changeHover(false)
    },
    changeHover: lodashDebounce(function (value) {
      // console.log('changeHover', value)
      this.isHover = value
    }, 400),
    changePanel () {
      // console.warn('sidebar changePanel')
      this.SET_MINIFY_LEFT_PANEL(!this.isMiniLeftPanel)
    }
  },
  computed: {
    ...mapGetters({
      user: 'profile/getUser',
      apiUrl: 'getApiUrl'
    }),
    ...mapState({
      isMiniLeftPanel: 'isMiniLeftPanel'
    }),
    localIsMiniLeftPanel () {
      return !this.isHover && this.isMiniLeftPanel
    },
    miniLeftPanelButtonClass () {
      // box-shadow: -3px 7px 21.6px 0px rgba(185, 191, 208, 0.35);
      // -shadow-[-3px_7px_21.6px_0_rgba(185,191,208,0.35)]
      return this.localIsMiniLeftPanel ? '-tw-right-[30px]' : 'tw-right-0'
    },
    sidebarMiniHoveredClass () {
      return this.isHover && this.isMiniLeftPanel ? 'tw-drop-shadow-lg' : ''
    },
    contentLeftMargin () {
      return this.localIsMiniLeftPanel ? 'tw-ml-20' : this.isHover ? 'tw-ml-20' : 'tw-ml-80'
    },
    sidebarSizeClass () {
      return this.localIsMiniLeftPanel ? 'tw-w-20 tw-shadow-sm' : 'tw-w-80'
    },
    isMiniLeftPanel: {
      get () {
        return this.$store.state.isMiniLeftPanel
      },
      set (val) {
        this.SET_MINIFY_LEFT_PANEL(val)
      }
    }
  }
}
</script>
