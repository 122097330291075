<template>
  <div>
    <div style="display: none">
      {{ isEdited }}
    </div>
    <TwFlexCol>
      <TwFlexCell>
        <TwSelectRelations
          v-model="form.builderId"
          :multi="false"
          :dense="true"
          label="Застройщик"
          list-label="Найти застройщика"
          relation-name="developer"
          :persistent-label="true"
          placeholder="Не выбран"
          :error-text="$v.form.builderId.$invalid ? 'Заполните поле':''"
          :disabled="formProcessing || !!defaultFormFields.builderId"
          @input="form.planId = null"
        />
      </TwFlexCell>
      <TwFlexCell>
        <TwInputText
          type="text"
          placeholder="Стоимость"
          max-length="13"
          :value="$Global.inputFormatPrice.getStringSpaced(form.price)"
          :disabled="formProcessing"
          :show-label="true"
          :dense="true"
          persistent-label
          :error-text="$v.form.price.$invalid ? 'Заполните поле':''"
          @input="form.price = $Global.inputFormatPrice.getNumber($event)"
        >
          <template #append>
            руб.
          </template>
        </TwInputText>
      </TwFlexCell>
      <TwFlexCell>
        <TwTextarea
          v-model="form.description"
          rows="5"
          label="Описание"
          placeholder="Текстовое описание проекта"
          maxlength="1000"
          show-length
          resize="y"
          :auto-resize="true"
          persistent-label
          :disabled="formProcessing"
          :show-label="true"
        />
      </TwFlexCell>
      <TwFlexCell>
        <TwSelectRelations
          v-model="form.planId"
          class="tw-mt-[12px]"
          :disabled="formProcessing"
          label="Планировка"
          placeholder="Выберите планировку"
          list-label="Найти планировку"
          relation-name="plan"
          :default-filters="plansFilters"
          :show-label="true"
          :dense="true"
          :persistent-label="true"
        />
      </TwFlexCell>
      <TwFlexCell>
        <div>
          <div class="tw-mb-[12px] tw-font-semibold">
            Прикрепление изображений проекта:
          </div>
          <TwUploadFilesList
            v-model="form.galleriesId"
            :api="osFileAPI"
            label="Загрузить изображения"
            no-data-text="Нет прикрепленнных изображений"
            :has-inline="false"
            file-type="builderProjectGallery"
            :is-public="false"
            :multiple="true"
            :disabled="formProcessing"
          />
        </div>
      </TwFlexCell>
    </TwFlexCol>

    <TwCardSimpleFormSaveBlock
      :loading="formProcessing"
      bg-color="transparent"
      :is-sticky="false"
      :visible="true"
      :cancel-confirm="formType === 'edit' && isEdited"
      @success="handleSubmit"
      @cancel="handleCancel"
    />
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import API from '@/Api/ObjectStorage/Builder/project'
import osFileAPI from '@/Api/ObjectStorage/File'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import TwCardSimpleFormSaveBlock from '@/components/tw-ui/forms/TwCardSimpleFormSaveBlock.vue'
import TwTextarea from '@/components/tw-ui/ui-kit/input/TwTextarea.vue'
import TwSelectRelations from '@/components/tw-ui/ui-kit/input/select-relations/TwSelectRelations.vue'
import TwInputText from '@/components/tw-ui/ui-kit/input/TwInputText.vue'
import TwUploadFilesList from '@/components/tw-ui/ui-kit/files/TwUploadFilesList.vue'
import TwFlexCell from '@/components/tw-ui/ui-kit/flex-block/TwFlexCell.vue'
import TwFlexCol from '@/components/tw-ui/ui-kit/flex-block/TwFlexCol.vue'

export default {
  name: 'BuilderProjectForm',
  components: { TwFlexCol, TwFlexCell, TwCardSimpleFormSaveBlock, TwUploadFilesList, TwInputText, TwTextarea, TwSelectRelations },
  mixins: [FormCreateEditMixin],
  props: {
    defaultFormFields: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      osFileAPI,
      API,
      form: {
        id: null,
        price: null,
        description: null,
        galleriesId: []
      }
    }
  },
  validations: {
    form: {
      price: {
        required
      },
      description: {
        required
      },
      builderId: {
        required
      }
    }
  },
  methods: {
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          const adaperMap = {
            // from(show): to(send)
            plan: 'planId',
            builder: 'builderId',
            galleries: 'galleriesId'
          }
          for (const key in this.preset) {
            if (adaperMap[key] !== undefined) {
              if (key === 'galleries') {
                this.$set(this.form, adaperMap[key], Array.isArray(this.preset[key]) && this.preset[key].length ? this.preset[key].map(f => f.id) : [])
              } else if (adaperMap[key].indexOf('Id') !== -1) {
                this.$set(this.form, adaperMap[key], (this.preset[key] && this.preset[key].id) || this.defaultFormFields[adaperMap[key]])
              }
            } else {
              this.$set(this.form, key, this.preset[key])
            }
          }
        }
        resolve()
      })
    },
    handleResetCustom () {
      this.handleReset()
    },
    handleSubmit () {
      // console.warn(this.$options.name, ' handleSubmit ', this.formType, this.targetId)
      this.$v.$reset()
      this.$v.form.$touch()
      console.warn(this.$options.name, ' handleSubmit ', this.$v.form, this.$v.form.$invalid)
      if (!this.$v.form.$invalid) {
        if (this.formProcessing === true) {
          return
        }

        this.formProcessing = true
        this.serverErrors = null
        if (this.formType === 'create') {
          this.API.store(this.form)
            .then(({ data }) => {
              console.warn(this.$options.name, ' CREATE ', data)
              // this.fetchData(data.data.id)
              this.$emit('create', data.data)
            })
            .catch((error) => {
              if (error?.response?.data) {
                console.error(this.$options.name, error.response.data)
                for (const key in error.response.data) {
                  const [refKey, index] = key.split('.')
                  // console.log(key, refKey, index, field)
                  if (!this.serverErrors) this.serverErrors = {}
                  if (!this.serverErrors[refKey]) this.serverErrors[refKey] = []
                  this.serverErrors[refKey][index] = error.response.data[key][0]
                }
              }
            })
            .finally(() => {
              this.formProcessing = false
            })
        } else if (this.formType === 'edit' && this.targetId) {
          this.API.update(this.targetId, this.form)
            .then(({ data }) => {
              console.warn(this.$options.name, ' UPDATE ', data.data)
              this.$emit('update', data.data)
            })
            .catch((error) => {
              if (error?.response?.data) {
                console.error(this.$options.name, error.response.data)
                for (const key in error.response.data) {
                  const [refKey, index] = key.split('.')
                  // console.log(key, refKey, index, field)
                  if (!this.serverErrors) this.serverErrors = {}
                  if (!this.serverErrors[refKey]) this.serverErrors[refKey] = []
                  this.serverErrors[refKey][index] = error.response.data[key][0]
                }
              }
            })
            .finally(() => {
              this.formProcessing = false
            })
        }
      }
    }
  },
  computed: {
    plansFilters () {
      return {
        builderId: this.defaultFormFields?.builderId ? [this.defaultFormFields?.builderId] : this.form.builderId ? [this.form.builderId] : []
      }
    }
  }
}
</script>
