<template>
  <div class="tw-font-manrope">
    <div class="tw-relative tw-ml-[14px] tw-border-l-2 tw-border-l-solid tw-border-l-white">
      <div class="tw-absolute tw-top-[16px] tw-w-[30px] tw-border-t-2 tw-border-t-solid tw-border-t-white" />
      <div class="tw-absolute -tw-left-[18px] tw-w-[34px] tw-h-[34px] tw-bg-white tw-flex tw-justify-center tw-items-center tw-rounded-full">
        <svg
          width="34"
          height="34"
          viewBox="0 0 34 34"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="17"
            cy="17"
            r="17"
            fill="#E8ECF2"
          />
          <path
            d="M17.5 8.57324C12.6786 8.57324 8.75 12.5018 8.75 17.3232C8.75 18.6625 9.01786 20.0018 9.64286 21.1626V24.4661C9.64286 24.8232 9.91071 25.1804 10.3571 25.1804H13.6607C14.8214 25.8054 16.1607 26.0732 17.5 26.0732C22.3214 26.0732 26.25 22.1447 26.25 17.3232C26.25 12.5018 22.3214 8.57324 17.5 8.57324ZM14.8214 18.2161C14.375 18.2161 13.9286 17.7697 13.9286 17.3232C13.9286 16.8768 14.375 16.4304 14.8214 16.4304C15.2679 16.4304 15.7143 16.8768 15.7143 17.3232C15.7143 17.7697 15.2679 18.2161 14.8214 18.2161ZM17.5 18.2161C17.0536 18.2161 16.6072 17.7697 16.6072 17.3232C16.6072 16.8768 17.0536 16.4304 17.5 16.4304C17.9464 16.4304 18.3929 16.8768 18.3929 17.3232C18.3929 17.7697 17.9464 18.2161 17.5 18.2161ZM20.1786 18.2161C19.7321 18.2161 19.2857 17.7697 19.2857 17.3232C19.2857 16.8768 19.7321 16.4304 20.1786 16.4304C20.625 16.4304 21.0714 16.8768 21.0714 17.3232C21.0714 17.7697 20.625 18.2161 20.1786 18.2161Z"
            fill="#7A8797"
          />
        </svg>
      </div>
      <div class="tw-ml-[30px]">
        <div class="">
          <ChronologyTopActionsBlock
            :entity-id="entityId"
            :entity="entity"
            :person-id="personId"
            :entity-info="entityInfo"
            @refresh="fetch"
          />
        </div>
        <ChronologyList
          :entity="entity"
          :entity-id="entityId"
          :list="items.pinned"
          :loading="loading"
          variant="pin"
          @refresh="fetch"
        />
        <div class="tw-mt-[22px]">
          <div class="tw-absolute -tw-left-[18px] tw-w-[34px] tw-h-[34px] tw-bg-white tw-flex tw-justify-center tw-items-center tw-rounded-full">
            <svg
              width="34"
              height="34"
              viewBox="0 0 34 34"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="17"
                cy="17"
                r="17"
                fill="#E8ECF2"
              />
              <g clip-path="url(#clip0_308_17577)">
                <path
                  d="M18.2086 10.1716C17.034 9.50137 15.642 9.13281 14.2042 9.13281C10.2901 9.13281 7 11.8307 7 15.2852C7 16.4963 7.40668 17.6574 8.17919 18.6601L7.10005 21.7946C6.98603 22.1257 7.2541 22.4629 7.62817 22.4629C7.71369 22.4629 7.79977 22.4446 7.8792 22.4072L11.1611 20.8634C11.2939 20.9164 11.4285 20.9656 11.5644 21.0113C10.8046 19.9141 10.3989 18.6546 10.3989 17.3359C10.3989 13.4088 13.9706 10.3897 18.2086 10.1716Z"
                  fill="#7A8797"
                />
                <path
                  d="M24.736 20.7109C25.5085 19.7081 25.9152 18.5471 25.9152 17.3359C25.9152 13.8802 22.6237 11.1836 18.711 11.1836C14.7969 11.1836 11.5068 13.8815 11.5068 17.3359C11.5068 20.7916 14.7983 23.4883 18.711 23.4883C19.7609 23.4883 20.8069 23.2903 21.7539 22.9141L25.036 24.458C25.2351 24.5517 25.4757 24.5255 25.646 24.3917C25.8163 24.2578 25.883 24.0424 25.8152 23.8454L24.736 20.7109ZM16.4574 17.8486C16.1513 17.8486 15.9032 17.6191 15.9032 17.3359C15.9032 17.0528 16.1513 16.8232 16.4574 16.8232C16.7634 16.8232 17.0116 17.0528 17.0116 17.3359C17.0116 17.6191 16.7634 17.8486 16.4574 17.8486ZM18.6741 17.8486C18.368 17.8486 18.1199 17.6191 18.1199 17.3359C18.1199 17.0528 18.368 16.8232 18.6741 16.8232C18.9801 16.8232 19.2282 17.0528 19.2282 17.3359C19.2282 17.6191 18.9801 17.8486 18.6741 17.8486ZM20.8907 17.8486C20.5847 17.8486 20.3366 17.6191 20.3366 17.3359C20.3366 17.0528 20.5847 16.8232 20.8907 16.8232C21.1968 16.8232 21.4449 17.0528 21.4449 17.3359C21.4449 17.6191 21.1968 17.8486 20.8907 17.8486Z"
                  fill="#7A8797"
                />
              </g>
              <defs>
                <clipPath id="clip0_308_17577">
                  <rect
                    width="18.9156"
                    height="17.5"
                    fill="white"
                    transform="translate(7 8.07324)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div class="tw-text-an-flat-disabled-text tw-p-[8px] tw-border tw-border-dashed tw-rounded-lg tw-cursor-default">
            Обсуждение. В разработке...
          </div>
        </div>
        <ChronologyList
          :entity="entity"
          :entity-id="entityId"
          :loading="loading"
          :list="items.planned"
          variant="plan"
          @refresh="fetch"
        />
        <ChronologyList
          :entity="entity"
          :entity-id="entityId"
          :loading="loading"
          variant="other"
          :list="items.other"
          @refresh="fetch"
        />
      </div>
      <div class="tw-absolute -tw-left-[5px] tw-bottom-0 tw-w-[8px] tw-h-[8px] tw-bg-white tw-rounded-full" />
    </div>
  </div>
</template>

<script>
import API from '@/Api/Crm/Chronology'
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import ChronologyTopActionsBlock from '@/components/core/Chronology/tw-dev/ChronologyTopActionsBlock.vue'
import ChronologyList from '@/components/core/Chronology/ChronologyList.vue'

export default {
  name: 'TwChronologyRoot',
  components: { ChronologyList, ChronologyTopActionsBlock },
  filters: {
    dataFormat: function (value) {
      if (!value) return ''
      if (value && new Date(value)) {
        const dateObj = new Date(value)
        const options = { month: 'long', day: 'numeric' }
        return new Intl.DateTimeFormat('ru', options).format(dateObj)
      }
    }
  },
  mixins: [ViewPanelShowMixin],
  props: {
    personId: {},
    entityId: {},
    entity: {},
    entityInfo: {}
  },
  data () {
    return {
      items: {
        pinned: [],
        other: [],
        planned: []
      },
      commentEdit: false,
      loading: false
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      if (this.loading) {
        return
      }
      this.loading = true
      API.getList({
        entity: this.entity.toString(),
        entityId: this.entityId.toString()
      }).then(({ data }) => {
        this.items = data || {} // planned other pinned
        this.$set(this.items, 'planned', data?.planned || [])
        this.$set(this.items, 'other', data?.other || [])
        this.$set(this.items, 'pinned', data?.pinned || [])
      }).catch((error) => {
        console.error(this.$options.name, error)
      }).finally(() => {
        this.loading = false
      })
    }
  }
}

</script>

<style lang="less">
.v-application a {
  color: black
}
.v-application p {
  margin-bottom: 0!important;
}
.entity-stream > p {
  background-color: #9ece00;
  padding: 2px 15px;
  min-height: 18px;
  border-radius: 11px;
  color: #fff;
  font: bold 12px/18px "Helvetica Neue",Helvetica,Arial,sans-serif;
  position: relative;
  top: 50%;
}
.date-stream > div > span {
  padding: 2px 15px;
  min-height: 18px;
  border-radius: 11px;
  color: #fff;
  font: bold 12px/18px "Helvetica Neue",Helvetica,Arial,sans-serif;
  position: relative;
  top: 50%;
  text-transform: lowercase;
}
.date-stream > p {
  text-transform: lowercase;
  background-color: #dde5e8;
  padding: 2px 15px;
  min-height: 18px;
  border-radius: 11px;
  color: #6e7273;
  font: bold 12px/18px "Helvetica Neue",Helvetica,Arial,sans-serif;
  position: relative;
  top: 50%;
}
.entry-wrapper {
  background-color: #eef2f4;
  position: relative;
}

.crm-entity-stream-section-new {
  border-left: 1px solid #cfd6da;
  padding-left: 28px;
}

element.style {
  display: block;
}

.item-icon {
  width: 31px;
  height: 31px;
  border-radius: 50%;
  position: absolute;
  left: -15px;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}
.item-icon:before {
  content: '';
  width: 13px;
  height: 2px;
  background-color: white;
  position: absolute;
  right: -13px;
  top: 50%;
  z-index: 1;
  transform: translate(0, -50%);
}
p,h1,h2,h3,h4,h5,h6 {
  margin: 0;
}
.loading {
  display: flex;
  justify-content: center;
  padding: 25px 0;
}
.entity-stream {
  display: flex;
  justify-content: center;
  align-items: center;
}
.entity-stream:before {
  content: '';
  width: 100%;
  height: 2px;
  background-color: white;
  position: absolute;
  left: 0px;
}

.date-stream {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.date-stream:before {
  content: '';
  width: 100%;
  height: 2px;
  background-color: white;
  position: absolute;
  left: 0px;
}

.item-circle-total {
  width: 18px;
  height: 16px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -7px;
  right: -7px;
  color: white;
  box-sizing: border-box;
  border: 2px solid #ebf1f4;
  border-radius: 8px;
  background-color: #f54819;
  font: bold 9px/12px "Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
}
.v-icon.v-icon {
  font-size: 17px;
}
.todo-none {
  background-color: #fffeef;
  padding: 3px 7px 3px 17px;
  min-height: 0;
  color: #a8adb4;
  font: 13px/25px "Helvetica Neue",Helvetica,Arial,sans-serif;
}

.todo-none_events {
  background-color: #dde5e8;
  padding: 3px 7px 3px 17px;
  min-height: 0;
  color: #a8adb4;
  font: 13px/25px "Helvetica Neue",Helvetica,Arial,sans-serif;
}
/*Start style card default*/
.card {
  position: relative;
  background-color: #ffffff;
  padding: 15px 15px 10px 15px;
  border-left: 2px solid #2fc6f6;
  width: 100%;
  border-radius: 10px;
}
.card-wrapper {
  overflow: hidden;
  display: flex;
}
.card-left {
  width: calc(100% - 50px)
}
.card-header {
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
}
.card-header-info {
  display: flex;
  align-items: baseline;
}
.card-title {
  font: bold 12px/21px "Helvetica Neue",Helvetica,Arial,sans-serif;
  margin-right: 8px;
}
.card-text p {
  color: #535c69;
}

.card-text {
  max-height: 70px;
  min-height: 65px;
  font: 400 15px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
  transition: 250ms all ease;
  position: relative;
}
// TODO remove - Вроде ненужно???
//.card-text:before {
//  content: '';
//  height: 35px;
//  width: 100%;
//  bottom: 0;
//  position: absolute;
//  background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #F7FBE8 90%)
//}

.card-text-one {
  max-height: 70px;
  min-height: 40px;
  font: 400 15px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
  transition: 250ms all ease;
  position: relative;
}
.card-text-one-blur-place:before {
  content: '';
  height: 20px;
  width: 100%;
  bottom: 0;
  position: absolute;
  background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff 70%)
}
.card-text-one-blur-place-pin:before {
  content: '';
  height: 20px;
  width: 100%;
  bottom: 0;
  position: absolute;
  background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #f7fbe9 70%)
}
.card-time {
  color: #a8adb4;
  font: 400 10px/12px "Helvetica Neue",Helvetica,Arial,sans-serif;
}
.card-buttons-show {
  text-align: center;
}
.card-buttons-show > p {
  margin: 0;
  color: #a79698;
  font: 400 13px/20px "Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
  text-decoration: none;
  cursor: pointer;
}
.card-buttons-show > p:hover {
  color: #000;
  font: 400 13px/20px "Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
  text-decoration: underline;
}
.card-right {
  min-width: 50px;
  display: flex;
  /* justify-content: flex-end; */
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}
.card-img {
  width: 20px;
  height: 20px;
  background-color: red;
  border-radius: 50%;
}
.card-expand {
  display: flex;
  justify-content: center;
}
.card-expand p {
  color: #858c95;
  font: 400 13px/20px "Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
  cursor: pointer;
}

//EMAIL style

.card-email-readed {
  display: flex;
  align-items: center;
}
.card-email-send-read {
  color:  #FF9800;
  padding: 1px 8px 0;
  border-radius: 10px;
  font-weight: bold;
  font-size: 8px;
  text-transform: uppercase;
  margin-left: 10px;
}
.card-email-to-info {
  font: bold 10px/21px "Helvetica Neue", Helvetica, Arial, sans-seri;
  text-transform: uppercase;
}
.item-theme {
  color: #337ab7;
  text-decoration: none;
}
.card-email-readed {
  font: bold 11px/21px "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.card-email-send-got {
  color: #43A047;
  padding: 1px 8px 0 0;
  border-radius: 10px;
  font-weight: bold;
  font-size: 8px;
  text-transform: uppercase;
}
.card-email-send{
  color:  #039BE5;
  padding: 1px 8px 0 0;
  border-radius: 10px;
  font-weight: bold;
  font-size: 8px;
  text-transform: uppercase;
}
.card-email-info-person {
  display: flex;
  padding-bottom: 10px;
}
.card-email-down {
  border-top: 1px solid #f2f2f4;
  padding-top: 10px;
}

// notification style
.stage-bgDoneFalse {
  background-color: #fffeef;
}
.stage-bgDoneTrue {
  background-color: #fff;
}
.card-notification-status-done {
  background-color:  #4CAF50;
  color: #E8F5E9;
  margin-right: 10px;
  padding: 1px 8px 0;
  border-radius: 10px;
  font-weight: bold;
  font-size: 9px;
  text-transform: uppercase;
}
.card-notification-towhom {
  display: flex;
  align-items: center;
  font: bold 12px/21px "Helvetica Neue",Helvetica,Arial,sans-serif;
}
.card-notification-towhom p {
  margin-right: 5px;
}
.notification-done {
  position: relative;
  padding: 10px 15px 10px 15px;
  width: 100%;
  border-radius: 10px;
  display: flex;
}
.notification {
  position: relative;
  padding: 10px 15px 10px 15px;
  width: 100%;
  border-radius: 10px;
  display: flex;
}
.card-notification-name {
  font-weight: 500;
}
.card-notification-info p {
  font: 400 12px/18px "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 15px;
  margin-bottom: 3px
}
.card-notification-text {
  padding: 3px 3px 10px 3px;
}
.disabled {
  display: none;
}
.active {
  max-height: 541px;
  min-height: 55px;
  transition: 250ms all ease;
}
.active::before {
  content: none;
}
.v-icon.v-icon{
  margin-right: 0;
  padding-bottom: 0;
}
.icon {
  z-index: 99;
}
.icon:hover {
  transform: rotate(45deg);
  cursor: pointer;
}
.card-edit-button {
  color: #a7adb4;
  margin-right: 10px;
  padding: 1px 8px 0;
  border-radius: 15px;
  font-weight: bold;
  font-size: 9px;
  text-transform: uppercase;
  cursor: pointer;
}
.card-title-email {
  cursor: pointer;
}
.date-today {
  background-color: #2fc6f5;
}
</style>
