<template>
  <span
    :title="socketStatus.text"
    aria-hidden="true"
  >
    <span
      :class="socketStatus.class"
      aria-hidden="true"
      class="tw-absolute tw-right-0 tw-h-[14px] tw-w-[14px] tw-border tw-border-white tw-border-[3px] tw-rounded-full"
    />
    <span
      v-if="!socketStatus.result"
      :class="socketStatus.class"
      aria-hidden="true"
      class="tw-animate-ping tw-absolute tw-right-0 tw-h-3 tw-w-3 tw-border tw-border-white tw-border-opacity-5 tw-rounded-full"
    />
  </span>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'TwSocketsConnectionIndicator',
  computed: {
    ...mapGetters({
      isSocketConnected: 'sockets/isSocketConnected',
      isSocketAuthStatus: 'sockets/isSocketAuthStatus'
    }),
    socketStatus () {
      return {
        class: this.isSocketConnected ? this.isSocketAuthStatus ? 'tw-bg-green-400' : 'tw-bg-orange-400' : 'tw-bg-red-400',
        text: this.isSocketConnected ? this.isSocketAuthStatus ? 'Сокет соединение активно' : 'Сокет соединение не авторизовано' : 'Ошибка соединения с сокет сервером',
        result: this.isSocketConnected ? !!this.isSocketAuthStatus : false
      }
    }
  }
}
</script>

<style scoped>

</style>
