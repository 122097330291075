<template>
  <TwPanelContentBlock>
    <template #title>
      <div>
        Скачивание документов
      </div>
    </template>
    <PageViewDefaultWrapper
      v-if="Array.isArray(data) && data.length"
      ref="wrapper"
      header-class="mb-2"
      :permission-denied="permissionDenied"
      relative
    >
      <template>
        <TwTableBase
          schema-key="TwTableEmailBlockEmailList"
          tbody-classes="tw-text-xs tw-cursor-default"
          :headers="columns"
          :loading="loading"
          :fixed-header-margin="0"
          :items="data"
          @headersUpdate="columns = $event"
          @changePagination="changePagination"
        >
          <template #item.menu="{ item }">
            <button
              title="Скачать"
              class="tw-text-an-flat-blue-btn hover:tw-underline"
              @click="download(item)"
            >
              Скачать
            </button>
          </template>
        </TwTableBase>
      </template>
    </PageViewDefaultWrapper>
    <div
      v-else
      class="tw-text-center tw-text-an-flat-disabled-text"
    >
      Документы отсутствуют
    </div>
  </TwPanelContentBlock>
</template>

<script>
import { tableItemStyleOneGen } from '@/components/tw-ui/table/TableTypes'
import DataListMixin from '@/mixins/DataListMixin'
import API from '@/Api/Crm/Dictionary/General/Document'
import PageViewDefaultWrapper from '@/components/core/ViewDefault/PageViewDefaultWrapper.vue'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwTableBase from '@/components/tw-ui/table/TwTableBase.vue'

export default {
  name: 'DocumentDownloadList',
  components: { TwPanelContentBlock, PageViewDefaultWrapper, TwTableBase },
  mixins: [DataListMixin],
  props: {
    dealId: {
      type: Number,
      default: 0
    },
    dealProgramId: {
      type: Number,
      default: 0
    },
    section: {
      type: String,
      default: ''
    },
    subsection: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      defaultFilters: {
        active: true
      },
      API,
      columns: [
        {
          ...tableItemStyleOneGen(),
          dataKey: 'name',
          text: 'Название',
          colStyle: 'width: auto;'
        },
        {
          ...tableItemStyleOneGen(),
          dataKey: 'menu',
          text: '',
          colStyle: 'width: 70px;'
        }
      ]
    }
  },
  mounted () {
    this.fetch({
      section: this.section,
      subsection: this.subsection
    })
  },
  methods: {
    download (item) {
      const url = `${this.$store.getters.getApiUrl}dictionary/document/${item.id}/download?id=${item.id}&dealId=${this.dealId}&dealProgramId=${this.dealProgramId}`
      // console.log('DOC download from ID:', item, url)
      this.API.download(url)
    }
  }
}
</script>

<style scoped>

</style>
