<template>
  <TwSelect
    :value="value"
    :return-object="true"
    placeholder="Выбрать"
    :label="label"
    :multi="multi"
    :dense="true"
    :error-text="errorText"
    :cleanable="cleanable"
    :persistent-label="true"
    :options="listOptions"
    @input="$emit('input', $event)"
    @input-object="$emit('inputFullData', $event)"
  >
    <template #append>
      <slot name="append" />
    </template>
  </TwSelect>
</template>

<script>
import API from '@/Api/Crm/Deal/DealDocument'
import TwSelect from '@/components/tw-ui/ui-kit/input/TwSelect.vue'

export default {
  name: 'TwDealsSelect',
  components: { TwSelect },
  props: {
    label: {
      type: String,
      default: null
    },
    value: {
      type: [Array, Number],
      default: null
    },
    dealId: {
      required: true,
      type: Number
    },
    defaultFilters: {
      type: Object,
      default: null
    },
    errorText: {
      default: null
    },
    multi: {
      type: Boolean,
      default: false
    },
    cleanable: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      list: []
    }
  },
  computed: {
    listOptions () {
      return this.list.map(item => {
        return {
          ...item,
          label: `#${item.id} ${item.name} - ${item.status ? item.status.name : ''}`,
          value: item.id
        }
      })
    }
  },
  watch: {
    defaultFilters: {
      handler () {
        this.fetchPersonDeals()
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    fetchPersonDeals () {
      console.warn('fetchPersonDeals', this.defaultFilters)
      API.searchDealList(this.dealId, this.defaultFilters || {})
        .then((response) => {
          this.list = response?.data?.data || []
        })
        .catch(() => {
          this.list = []
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
