<template>
  <TwPanelContent class="tw-mt-[12px] tw-flex tw-font-manrope tw-text-main-sm2">
    <div class="tw-flex-1">
      <TwPanelContentBlock class="tw-mb-[6px]">
        <template #title>
          Информация о застройщике на сайте
        </template>
        <template #action>
          <TwPanelContentBlockActionButton
            v-if="info"
            type="edit"
            class="tw-text-main-xs enabled:hover:tw-text-an-flat-dark-blue-link"
            @click.prevent="isEdit = !isEdit"
          >
            {{ isEdit ? 'отменить':'изменить' }}
          </TwPanelContentBlockActionButton>
        </template>
        <div
          v-if="loading"
          class="tw-p-[24px] tw-flex tw-text-center"
        >
          <TwCircleLoader class="tw-m-auto" />
        </div>
        <div v-else>
          <RealtyBuilderMainForm
            v-if="isEdit"
            :id="info ? info.id : 0"
            @create="refresh"
            @update="refresh"
            @cancel="isEdit = false"
          />
          <RealtyBuilderMainShow
            v-else
            :info="info"
            @refresh="refresh"
          />
        </div>
      </TwPanelContentBlock>
    </div>
    <div class="tw-flex-1" />
  </TwPanelContent>
</template>

<script>
import { API } from '@/Api/ObjectStorage'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import RealtyBuilderMainForm from '@/views/Dictionary/General/Company/developer/SiteInfo/RealtyBuilderMainForm.vue'
import RealtyBuilderMainShow from '@/views/Dictionary/General/Company/developer/SiteInfo/RealtyBuilderMainShow.vue'
import TwPanelContent from '@/components/tw-ui/panels/TwPanelContent.vue'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwPanelContentBlockActionButton from '@/components/tw-ui/panels/TwPanelContentBlockActionButton.vue'

export default {
  name: 'CompanyBuilderInfoSite',
  components: {
    TwPanelContent,
    TwPanelContentBlock,
    TwPanelContentBlockActionButton,
    RealtyBuilderMainShow,
    RealtyBuilderMainForm,
    TwCircleLoader
  },
  props: {
    companyId: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      info: null,
      loading: false,

      isEdit: false
    }
  },
  methods: {
    onEditItem () {
      this.isEdit = true
    },
    fetch () {
      this.isEdit = false
      this.loading = true
      API.Builder.show(this.companyId)
        .then(({ data: result }) => {
          console.warn(result)
          this.info = result?.data || null
        })
        .catch((error) => {
          console.error(this.$options.name, error)
          this.info = null
        })
        .finally(() => {
          this.loading = false
        })
    },
    refresh () {
      this.fetch()
    }
  },
  mounted () {
    this.refresh()
  }
}
</script>
