import { AxiosResponse } from 'axios'
import HTTP from '@/Api/http'
import { AuthAxiosDefaultConfig } from '@/Api/ObjectStorage'

const path = '/realty/newbuildings'

export default {
  chess (complexId: any, params?: any): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig,
      params
    }
    return HTTP.get(`${path}/${complexId}/chess`, $config)
  },
  objects (complexId: any, params?: any): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig,
      params
    }
    return HTTP.get(`${path}/${complexId}/objects`, $config)
  }
}
