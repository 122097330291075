import { AxiosResponse } from 'axios'
import HTTP, { buildInitialHttp } from '@/Api/http'
import { AuthAxiosDefaultConfig } from '@/Api/Rtd'

export const path = '/request-to-developer/management/request'

export default {
  listInit (): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.get(`${path}/list-init`, $config)
  },
  getList (param?: object): Promise<AxiosResponse> {
    const $config = {
      ...AuthAxiosDefaultConfig,
      params: param
    }
    return HTTP.get(`${path}`, $config)
  },
  show (id: number): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.get(`${path}/${id}`, $config)
  },
  downloadFiles (id: number) {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    const link = document.createElement('a')
    link.href = `${buildInitialHttp($config).defaults.baseURL}${path}/${id}/download-files`
    link.target = '_blank'
    document.body.appendChild(link)
    link.click()
    // удаляю ссылку и чищу память
    document.body.removeChild(link)
  },
  setStatus (id: number, data: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.post(`${path}/${id}/status`, data, $config)
  },
  getHistory (id: number): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.get(`${path}/${id}/history`, $config)
  },
  createEmail (requestId: number, data: object) {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.post(`${path}/${requestId}/create-email`, data, $config)
  }
}
