<template>
  <div :class="wrapperClass">
    <label
      v-if="labelText"
      :class="labelClass"
      :for="uid"
    >{{ labelText }}</label>
    <div class="tw-relative">
      <input
        :id="uid"
        ref="input"
        v-model.trim="localValue"
        :placeholder="placeholder"
        :disabled="disabled"
        :readonly="readonly"
        :required="required"
        :type="localType"
        :class="[inputClassBase, inputClassFocused, inputClassDisabled, errorText ? inputClassError : '']"
        :autocomplete="autocomplete"
      >
      <div
        v-if="type === 'password'"
        class="tw-w-[35px] tw-absolute tw-top-1/2 tw-right-[10px] tw-transform -tw-translate-x-0 -tw-translate-y-1/2"
      >
        <button
          :title="showPassword ? 'Скрыть пароль':'Показать пароль'"
          :disabled="disabled"
          type="button"
          class="tw-p-[10px] tw-absolute tw-top-1/2 tw-left-1/2 tw-transform -tw-translate-x-1/2 -tw-translate-y-1/2 disabled:tw-opacity-50"
          @click.stop="showPassword = !showPassword"
        >
          <svg
            v-if="showPassword"
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.5 8.24121C9.48773 8.24121 5.84919 10.4364 3.16431 14.0019C2.94523 14.294 2.94523 14.7021 3.16431 14.9942C5.84919 18.564 9.48773 20.7592 13.5 20.7592C17.5123 20.7592 21.1508 18.564 23.8357 14.9985C24.0548 14.7064 24.0548 14.2983 23.8357 14.0062C21.1508 10.4364 17.5123 8.24121 13.5 8.24121ZM13.7878 18.9077C11.1244 19.0752 8.92498 16.8801 9.09251 14.2124C9.22998 12.0129 11.0127 10.2302 13.2122 10.0927C15.8756 9.92516 18.075 12.1203 17.9075 14.788C17.7657 16.9832 15.983 18.7659 13.7878 18.9077ZM13.6546 16.8715C12.2199 16.9617 11.0342 15.7803 11.1287 14.3455C11.2017 13.1599 12.164 12.2019 13.3496 12.1246C14.7844 12.0344 15.9701 13.2157 15.8756 14.6505C15.7983 15.8405 14.836 16.7984 13.6546 16.8715Z"
              fill="#4A5664"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M27.0607 0.93934C27.6464 1.52513 27.6464 2.47487 27.0607 3.06066L3.06066 27.0607C2.47487 27.6464 1.52513 27.6464 0.93934 27.0607C0.353553 26.4749 0.353553 25.5251 0.93934 24.9393L24.9393 0.93934C25.5251 0.353553 26.4749 0.353553 27.0607 0.93934Z"
              fill="#7A828C"
            />
          </svg>
          <svg
            v-else
            width="21"
            height="13"
            viewBox="0 0 21 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.5 0.240967C6.48773 0.240967 2.84919 2.43612 0.164314 6.00163C-0.0547714 6.29374 -0.0547714 6.70184 0.164314 6.99396C2.84919 10.5638 6.48773 12.7589 10.5 12.7589C14.5123 12.7589 18.1508 10.5638 20.8357 6.99825C21.0548 6.70614 21.0548 6.29804 20.8357 6.00593C18.1508 2.43612 14.5123 0.240967 10.5 0.240967ZM10.7878 10.9074C8.12442 11.075 5.92498 8.87981 6.09251 6.21212C6.22998 4.01268 8.01273 2.22992 10.2122 2.09245C12.8756 1.92492 15.075 4.12007 14.9075 6.78776C14.7657 8.98291 12.983 10.7657 10.7878 10.9074ZM10.6546 8.87122C9.21985 8.96143 8.03421 7.78009 8.12872 6.34529C8.20175 5.15965 9.16401 4.20169 10.3496 4.12437C11.7844 4.03415 12.9701 5.2155 12.8756 6.6503C12.7983 7.84023 11.836 8.79819 10.6546 8.87122Z"
              fill="#93A1B0"
            />
          </svg>
        </button>
      </div>
    </div>
    <label
      v-if="errorText"
      :class="labelErrorClass"
      :for="uid"
    >* {{ errorText }}</label>
  </div>
</template>

<script>
export default {
  name: 'TwUnAuthInputText',
  props: {
    value: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    autocomplete: {
      type: String,
      default: 'off'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    labelText: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    wrapperClass: {
      type: String,
      default: 'tw-relative tw-font-manrope tw-text-an-flat-blue-dark-2 tw-block tw-mb-[18px]'
    },
    errorText: {
      type: String,
      default: ''
    },
    labelClass: {
      type: String,
      default: 'tw-block tw-text-[14px] cursor-pointer tw-select-none tw-mb-[9px] tw-font-medium tw-text-an-flat-grey-22'
    },
    labelErrorClass: {
      type: String,
      default: 'tw-block tw-text-[12px] tw-my-[6px] tw-px-[24px] tw-text-an-flat-red-main'
    },
    inputClassBase: {
      type: String,
      default: 'tw-rounded-[10px] tw-w-full tw-mh-[54px] tw-px-[24px] tw-pt-[15px] tw-pb-[17px] tw-bg-white tw-block tw-w-full tw-bg-transparent placeholder:tw-text-an-flat-blue-dark-2'
    },
    inputClassFocused: {
      type: String,
      default: 'focus:tw-outline-an-flat-blue-light focus:placeholder:tw-text-opacity-40'
    },
    inputClassDisabled: {
      type: String,
      default: 'disabled:tw-text-opacity-40'
    },
    inputClassError: {
      type: String,
      default: 'tw-border tw-border-solid tw-border-an-flat-red-main placeholder:tw-text-an-flat-red-main'
    }
  },
  data () {
    return {
      showPassword: false
    }
  },
  computed: {
    localType () {
      if (this.type === 'password' && this.showPassword) {
        return 'text'
      }
      return this.type
    },
    localValue: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    },
    uid () {
      return this._uid
    }
  },
  methods: {
    focus () {
      setTimeout(() => {
        this.$refs.input?.focus()
      }, 0)
    }
  }
}
</script>

<style scoped>

</style>
