<template>
  <div>
    <div class="tw-mb-[12px] tw-flex tw-items-center tw-justify-between">
      <div class=" tw-text-[20px] tw-text-h6">
        <slot name="header" />
      </div>
      <div>
        <TwPopoverShort>
          <template #trigger>
            <button
              class="tw-group tw-bg-white tw-py-[11px] tw-px-[10px] tw-rounded-full enabled:hover:tw-bg-an-flat-blue-btn"
              @click="refreshParent"
            >
              <svg
                class="group-hover:tw-rotate-90"
                width="20"
                height="17"
                viewBox="0 0 20 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  class="group-hover:tw-fill-white"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4.27391 4.60426C4.03776 4.95733 3.56006 5.05503 3.20699 4.82041C2.85314 4.58579 2.75622 4.10887 2.99083 3.75426C5.10929 0.558872 9.1216 -0.792667 12.7524 0.467333C15.7062 1.49272 17.787 4.03426 18.2878 7.00502L18.6224 6.57041C18.8808 6.23349 19.3639 6.17041 19.7008 6.42964C20.0378 6.68887 20.1001 7.17118 19.8408 7.5081L18.287 9.52579C18.1362 9.72272 17.9078 9.82579 17.677 9.82579C17.5662 9.82579 17.4578 9.79195 17.3539 9.74349C17.2208 9.69502 17.1016 9.62349 17.0147 9.51656L15.1908 8.11272C14.8539 7.85349 14.7916 7.37118 15.0508 7.03426C15.3093 6.69733 15.7916 6.63502 16.1293 6.89349L16.7878 7.40041C16.4216 4.91426 14.7062 2.77349 12.247 1.91964C9.28622 0.89041 6.00391 1.99349 4.27391 4.60426ZM1.37843 10.255C1.22766 10.4519 0.999196 10.555 0.768427 10.555C0.604581 10.555 0.439196 10.5035 0.299966 10.3958C-0.0369574 10.1365 -0.0992652 9.65424 0.159966 9.31732L1.71381 7.29963C1.93073 7.01655 2.30073 6.9404 2.61612 7.0727C2.76227 7.11809 2.89304 7.19347 2.98612 7.30809L4.80997 8.71193C5.14689 8.97116 5.2092 9.45347 4.94997 9.7904C4.6915 10.1265 4.2092 10.1889 3.8715 9.93117L3.21304 9.42424C3.5792 11.9104 5.29458 14.0512 7.75381 14.905C10.7177 15.9358 13.9969 14.8312 15.7269 12.2204C15.963 11.8665 16.4384 11.7681 16.7938 12.0042C17.1477 12.2389 17.2446 12.7165 17.01 13.0704C15.4215 15.465 12.7684 16.825 10.0115 16.825C9.09073 16.825 8.15766 16.6735 7.24843 16.3581C4.29458 15.3327 2.21381 12.7912 1.71304 9.8204L1.37843 10.255Z"
                  fill="#60656A"
                />
              </svg>
            </button>
          </template>
          <template #content>
            Обновить список
          </template>
        </TwPopoverShort>
      </div>
    </div>
    <div>
      <TwTableBase
        schema-key="TwTableEmailBlockEmailList"
        tbody-classes="tw-text-xs"
        :headers="columns"
        :loading="loading"
        :fixed-header-margin="80"
        :items="data"
        @headersUpdate="columns = $event"
        @row-click="show"
        @changePagination="changePagination"
      >
        <template #item.emailFolder.createdAt="{ item }">
          <div v-if="item.createdAt">
            {{ $Global.DateFormat.DateTimeShort(item.createdAt) }}
          </div>
        </template>
        <template #item.emailConfigContact="{ item }">
          <div class=" tw-flex tw-gap-[24px] text-left text-no-wrap caption">
            <div>
              <div class="text--disabled">
                <small class="tw-text-[11px] tw-text-an-flat-gray-4">
                  Имя:
                </small>
              </div>
              {{ item.emailConfigContact.name }}
            </div>
            <div>
              <div class="text--disabled">
                <small class="tw-text-[11px]  tw-text-an-flat-gray-4">
                  Почта:
                </small>
              </div>
              {{ item.emailConfigContact.email }}
            </div>
          </div>
        </template>
        <template #item.theme="{ item }">
          <div v-if="item.theme">
            {{ item.theme }}
          </div>
        </template>
        <template #item.contacts="{ item }">
          <div class="text-left text-no-wrap caption">
            <div v-if="item.contacts[0].name">
              <div class="text--disabled">
                <small class="tw-text-[11px] tw-text-an-flat-gray-4">
                  Имя:
                </small>
              </div>
              {{ item.contacts[0].name }}
            </div>
            <div v-else-if="item.contacts[0].email">
              <div class="text--disabled">
                <small class="tw-text-[11px] tw-text-an-flat-gray-4">
                  Почта:
                </small>
              </div>
              {{ item.contacts[0].email }}
            </div>
            <div v-else>
              Нет данных
            </div>
          </div>
        </template>
        <template #item.status.label="{ item }">
          <div
            v-if="item.status && item.status.label"
            class="tw-w-fit tw-px-[10px] tw-py-[4px] tw-rounded-[6px] tw-font-semibold tw-text-[12px]"
            :class="{
              'tw-bg-an-flat-bg-main': item.status.value === 'draft',
              'tw-text-an-flat-orange-text  tw-bg-an-flat-orange-lighten': item.status.value === 'sent_queue',
              'tw-text-an-flat-red-main  tw-bg-an-flat-red-lighten3': item.status.value === 'delete',
              'tw-text-an-flat-green  tw-bg-an-flat-green-bg-block': item.status.value === 'send'
            }"
          >
            {{ item.status.label }}
          </div>
        </template>
      </TwTableBase>
    </div>
  </div>
</template>

<script>
import TwTableBase from '@/components/tw-ui/table/TwTableBase.vue'
import API from '@/Api/Email/Mail'
import tableOptions from '@/components/tw-ui/list/TwTableEmailBlock/tableOptions'
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import TwPopoverShort from '@/components/tw-ui/popover/TwPopoverShort.vue'

export default {
  name: 'TwTableEmailBlock',
  components: { TwTableBase, TwPopoverShort },
  mixins: [ViewPanelShowMixin],
  props: {
    entityId: {
      type: Number,
      required: true
    },
    entity: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      data: [],
      columns: tableOptions,
      dataTable: null
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      console.log('my fetch todod', this.$props)
      this.loading = true
      API.getListByEntity({ entityId: this.entityId, entity: this.entity })
        .then(info => {
          this.data = info.data.data
        })
        .catch(error => {
          console.error('Error fetch TwTableEmailBlock: ', error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    changePagination (event) {
      console.error('changePagination: ', 'Need pagination write to developer', event)
    },
    show (item) {
      console.log('show', item)
      this.showEmailCard(
        {
          id: item.id
        },
        () => {
          console.warn('CALLBACK showEmailCard')
          this.refresh()
        })
    },
    refreshParent () {
      this.fetch()
      if (this.$parent?.refresh) {
        console.log(this.$parent?.refresh)
        this.$parent?.refresh()
      } else {
        console.error('У родительского компонента отсутствует метод refresh()')
      }
    }
  }
}
</script>

<style scoped>

</style>
