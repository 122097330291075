import HTTP from '@/Api/http'

export default {
  data () {
    return {
      dangersList: []
    }
  },
  methods: {
    fetchDangers () {
      this.dangersList = []
      HTTP.get(`${location.origin}/dangers.json?timestamp=${new Date().getTime()}`)
        .then((result) => {
          console.warn('fetchDangers', result.data)
          this.dangersList = Array.isArray(result.data) ? result.data : []
        })
        .catch((error) => {
          console.error('fetchDangers', this.$options.name, error)
        })
    }
  }
}
