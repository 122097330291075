import { Module } from 'vuex'
import { RootState } from '@/store/types'
import { ProfileState } from './types'
import { get, remove, set } from 'local-storage'
import AuthAPI from '@/Api/Auth/Profile'
import emailConfigsAPI from '@/Api/Email/Config'
import chronologyAPI from '@/Api/Crm/Chronology/index'
import router from '../../router'
import dictPermissionRules from '@/Permissions/dictRules'

const namespaced = true
const baseFetchPlannedTimeout = 60000
let checkPlannedTimer: any = null

export const profile: Module<ProfileState, RootState> = {
  namespaced,
  state: {
    subAuth: null, // Объект для хранения данных суб пользователя под которым админ может просматривать систему
    user: undefined,
    authData: undefined,
    emailConfigs: [],
    emailStatus: {},
    userPlannedInfo: {
      lead: 0,
      deal: 0,
      person: 0
    }
  },
  actions: {
    // Получаю список конфигураций EMAIL
    fetchEmailConfigs ({ state, commit }, payload = {}): any {
      if (!payload?.userId) {
        payload.userId = state.subAuth?.info?.id || state.user?.id
      }
      // Приналичии суб авторизации под другим пользователем получаю конфигурацию почты для суб пользователя
      return emailConfigsAPI.getList(payload)
        .then(({ data }) => {
          // console.warn('fetchEmailConfigs', data)
          commit('SET_EMAIL_CONFIGS', data.data || [])
          commit('SET_EMAIL_STATUS', data.ui || {})
          return data
        })
        .catch((error) => {
          console.error('fetchEmailConfigs', error)
          return null
        })
    },
    // инициализация значений из localstorage
    initAuth ({ commit }): any {
      const authData: any = get('token')
      if (authData) {
        commit('setToken', authData)
      }
      const user = get('user')
      if (user) {
        commit('setUser', user)
      }
      // Получение суб авторизации для админа авторизованного под другим юзером
      const subAuthData: any = get('subAuth')
      if (subAuthData) {
        commit('SET_SUB_USER_DATA', {
          token: subAuthData.token,
          info: subAuthData.info
        })
      }
      return { isToken: !!authData?.token }
    },
    // проверка валидности токена
    checkToken ({ state, commit, dispatch }): any {
      if (state?.authData?.token) {
        AuthAPI.getProfile()
          .then((responseProfile) => {
            if (responseProfile.data && responseProfile.data.data) {
              commit('setUser', responseProfile.data.data)
              dispatch('currentPlannedForUser')
            } else {
              dispatch('resetAuth')
            }
          })
      }
    },
    // данный метод вынесен из мутаций в экшены ибо не мутации синхронны и не работала чистка localstorage
    resetAuth ({ state, commit }) {
      console.warn('Выход пользователя и сброс локальных данных', state.authData)
      if (state.authData) {
        AuthAPI.logout().finally(() => {
          remove('subAuth')
          remove('token')
          commit('CLEAR_AUTH')
        })
      }
    },
    // Запуск слушателя списка событий хронологии
    currentPlannedForUser ({ commit }): any {
      // Если таймер есть то очищаю его
      if (checkPlannedTimer) {
        clearTimeout(checkPlannedTimer)
        checkPlannedTimer = null
      }
      const fetchCurrentPlannedForUser = () => {
        chronologyAPI.currentPlannedForUser()
          .then(({ data }) => {
            // console.warn('currentPlannedForUser ', data)
            commit('SET_USER_PLANNED', data)
            checkPlannedTimer = setTimeout(fetchCurrentPlannedForUser, baseFetchPlannedTimeout)
          })
          .catch((error) => {
            console.error('currentPlannedForUser ', error)
            checkPlannedTimer = setTimeout(fetchCurrentPlannedForUser, baseFetchPlannedTimeout * 5)
          })
      }
      fetchCurrentPlannedForUser()
    },
    // Запрос на авторизацию под другим пользователем
    subAuthLogIn ({ commit }, payload): any {
      return AuthAPI.subLogin(payload.id)
        .then(({ data }) => {
          const subAuthData = {
            token: data.token,
            info: payload.info
          }
          commit('SET_SUB_USER_DATA', subAuthData)
          set('subAuth', subAuthData)
          commit('viewPanels/REMOVE_ALL_PANEL', {}, { root: true })
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          router.push('/').catch(() => {})
        })
        .catch(() => {
          //
        })
    },
    // Выход из под другого пользователя
    subAuthLogOut ({ commit }): any {
      commit('SET_SUB_USER_DATA', null)
      remove('subAuth')
      commit('viewPanels/REMOVE_ALL_PANEL', {}, { root: true })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      router.push('/').catch(() => {})
    }
  },
  mutations: {
    SET_SUB_USER_DATA (state, payload: any) {
      if (payload?.token) {
        state.subAuth = {
          token: payload.token,
          info: payload.info
        }
      } else {
        state.subAuth = null
      }
    },
    CLEAR_AUTH (state) {
      state.subAuth = null
      state.authData = undefined
      state.user = undefined
      state.emailConfigs = undefined
    },
    setUser (state, user) {
      if (user) {
        state.user = {
          id: user.id,
          name: user.name,
          surname: user.surname,
          email: user.email,
          emailConfigs: user.emailConfigs,
          phone: user.phone,
          rulesCalc: user.rulesCalc || [],
          userStructure: user.userStructure || [],
          file: user.file || null
        }
        set('user', state.user)
      } else {
        remove('user')
        state.user = undefined
      }
    },
    setToken (state, authData: any) {
      if (authData.token !== '') {
        state.authData = authData
        set('token', authData)
      } else {
        remove('token')
        state.authData = undefined
      }
    },
    SET_EMAIL_STATUS (state, ui: any) {
      state.emailStatus = ui?.email || {}
    },
    SET_EMAIL_CONFIGS (state, configList: any) {
      state.emailConfigs = configList || []
    },
    SET_USER_PLANNED (state, data: any) {
      state.userPlannedInfo = {
        lead: data.lead || 0,
        deal: data.deal || 0,
        person: data.person || 0
      }
    }
  },
  getters: {
    isAuth (state): boolean {
      return !!state.authData && typeof state.authData.token === 'string'
    },
    subAuth (state): boolean {
      return !!state.subAuth && typeof state.subAuth.token === 'string'
    },
    getSubAuthToken (state): boolean {
      return !!state?.subAuth?.token && state.subAuth.token
    },
    subAuthUserInfo (state): boolean {
      return !!state.subAuth && !!state.subAuth.info && state.subAuth.info
    },
    currentActiveUser (state): any {
      const userTypeKey = state.subAuth?.info?.id ? 'subAuth' : 'user'
      const result = { id: 0, token: '' }
      if (userTypeKey === 'subAuth') {
        result.id = state.subAuth?.info?.id || null
        result.token = state.subAuth?.token || null
      } else if (userTypeKey === 'user') {
        result.id = state.user?.id || null
        result.token = state.authData?.token || null
      }
      return result
    },
    // Статус пользователя для доступа к панели администрирования
    isManagement (state): boolean {
      const adminRuleNamespace = dictPermissionRules.AppServicePermissionRulesShowManagementPanel
      // Суб авторизация имеет приоритет
      const userTypeKey = state.subAuth?.info?.id ? 'subAuth' : 'user'
      if (userTypeKey === 'subAuth') {
        return !!(state.subAuth && Array.isArray(state.subAuth?.info?.rulesCalc) && state.subAuth?.info?.rulesCalc.some((item: any) => {
          return item?.rule?.namespace === adminRuleNamespace && item?.ruleValue === 100
        }))
      } else if (userTypeKey === 'user') {
        return !!(state.user && Array.isArray(state.user?.rulesCalc) && state.user.rulesCalc.some((item: any) => {
          return item?.rule?.namespace === adminRuleNamespace && item?.ruleValue === 100
        }))
      } else {
        return false
      }
    },
    isAdmin (state): boolean {
      const adminRuleNamespace = dictPermissionRules.AppServicePermissionRulesRootRule
      // Суб авторизация имеет приоритет
      const userTypeKey = state.subAuth?.info?.id ? 'subAuth' : 'user'
      if (userTypeKey === 'subAuth') {
        return !!(state.subAuth && Array.isArray(state.subAuth?.info?.rulesCalc) && state.subAuth?.info?.rulesCalc.some((item: any) => {
          return item?.rule?.namespace === adminRuleNamespace && item?.ruleValue === 100
        }))
      } else if (userTypeKey === 'user') {
        return !!(state.user && Array.isArray(state.user?.rulesCalc) && state.user.rulesCalc.some((item: any) => {
          return item?.rule?.namespace === adminRuleNamespace && item?.ruleValue === 100
        }))
      } else {
        return false
      }
    },
    // Генерируемый словарь со всеми ключами и значениями прав активного клиента
    // Использовать для сверки прав внутри интерфейсов
    allowNamespacesDict (state): any {
      const userTypeKey = state.subAuth?.info?.id ? 'subAuth' : 'user'
      // получаю все правила с полными правами
      if (userTypeKey === 'subAuth') {
        return state?.subAuth?.info?.rulesCalc?.filter((item: any) => {
          return item?.rule?.namespace && item?.ruleValue >= 25
        }).reduce((accumulate: any, current: any) => {
          accumulate[current?.rule?.namespace] = current.ruleValue
          return accumulate
        }, {})
      } else if (userTypeKey === 'user') {
        return state?.user?.rulesCalc?.filter((item: any) => {
          return item?.rule?.namespace && item?.ruleValue >= 25
        }).reduce((accumulate: any, current: any) => {
          accumulate[current?.rule?.namespace] = current.ruleValue
          return accumulate
        }, {})
      }
      return {}
    },
    // Информация о основном авторизованном пользователе
    getUser (state): any {
      return state.user || get('user')
    },
    // Информация о пользователе авторизованном дополнительно
    getSubAuthUser (state): any {
      return state.subAuth?.info || null
    },
    getToken (state): any {
      const authData = state.authData || get('token')
      return authData && authData.token ? authData.token : null
    },
    fullName (state): string {
      const { user } = state
      const name = (user && user.name) || ''
      const surname = (user && user.surname) || ''
      return `${name} ${surname}`
    },
    emails (state): Array<any> {
      return [state?.user?.email]
    },
    getEmailStatus (state): any {
      return state?.emailStatus
    },
    UserEmailConfigs (state): Array<any> {
      // console.warn('++', state?.user)
      return state?.emailConfigs || []
    },
    userPlanned (state): Object {
      return state?.userPlannedInfo || {}
    },
    // Генерация текстового значения структуры пользователя
    userStructureInfoText (state): Object {
      const userInfo: any = state.subAuth?.info?.id ? state.subAuth.info : state.user
      if (Array.isArray(userInfo?.userStructure) && userInfo?.userStructure.length) {
        let result = ''
        for (const structureIndex in userInfo.userStructure) {
          const { structure, position } = userInfo.userStructure[structureIndex]
          // Структура
          if (structure) {
            // Формат структуры
            result += structure?.entity?.label ? `${structure.entity.label} ` : ''
            const structureFormat = structure?.entity?.value || null
            // Название структуры
            if (structure.name) {
              result += structure.name ? `${structure.name} - ` : ' '
            } else if (structure[structureFormat]) {
              result += structure[structureFormat]?.name ? `${structure[structureFormat].name} - ` : ''
            }
          }
          // Должность
          if (position) {
            result += position.name || ''
          }
          // Разделитель
          if (Number(structureIndex) < userInfo.userStructure.length - 1) {
            result += ', '
          }
        }
        return result
      }
      return 'Не указано'
    }
  }
}
