<template>
  <div :class="type === 'messages'">
    <template v-if="list[index-1] && list[index-1].date && list[index] && list[index].date">
      <span
        v-if="!$Global.DateFormat.equalYearMonthDay(list[index-1].date, list[index].date)"
        :class="classElement"
        :style="{backgroundColor: $Global.DateFormat.isRecentDate(item.date) ? '#2fc6f5' : '#dde5e8', color: $Global.DateFormat.isRecentDate(item.date) ? '#fff' : '#6e7273'}"
      >
        {{ $Global.DateFormat.DateShort(item.date) }}
      </span>
    </template>
    <template v-else>
      <span
        :class="classElement"
        :style="{ marginBottom: '16px', backgroundColor: $Global.DateFormat.isRecentDate(item.date) ? '#2fc6f5' : '#dde5e8', color: $Global.DateFormat.isRecentDate(item.date) ? '#fff' : '#6e7273'}"
      >
        {{ $Global.DateFormat.DateShort(list[index].date) }}
      </span>
    </template>
  </div>
</template>

<script>
export default {
  name: 'LayoutDate',
  props: {
    list: {
      type: [Array, Object],
      default: () => ([])
    },
    item: {
      type: [Array, Object],
      default: () => ({})
    },
    classElement: {
      type: String,
      default: ''
    },
    index: {
      type: [Number, String]
    },
    type: {
      type: String
    }
  }
}
</script>
<style lang="less">
  .layout-date {
    margin: 0 0 1rem 0;
  }
</style>
