import { RouteKeys } from '@/router/types'

export default {
  path: RouteKeys.position,
  name: RouteKeys.position,
  redirect: { name: `${RouteKeys.position}-list` },
  meta: { name: 'Должности', icon: 'block-company-position', isAdmin: true },
  component: () => import('@/views/Company/Position/Index.vue'),
  children: [
    {
      path: 'list',
      name: `${RouteKeys.position}-list`,
      meta: { name: 'Список должностей', icon: 'block-company-position', hideInMenu: true },
      component: () => import('@/views/Company/Position/List.vue')
    },
    {
      path: ':id/edit',
      name: `${RouteKeys.position}-edit`,
      meta: { name: 'Редактирование должности', icon: 'block-company-position', hideInMenu: true },
      component: () => import('@/views/Company/Position/Edit.vue'),
      props: (route:any) => ({ id: route.params.id })
    },
    {
      path: 'create',
      name: `${RouteKeys.position}-create`,
      meta: { name: 'Добавление должности', icon: 'block-company-position', hideInMenu: true },
      component: () => import('@/views/Company/Position/Edit.vue'),
      props: (route:any) => ({ id: route.params.id })
    }
  ]

}
