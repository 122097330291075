<template>
  <TwPanelTabsFull
    v-model="currentTab"
    :items="tabList"
  >
    <template #content.main>
      <DealGeneralTab
        :deal="regroupDealData.mainDeal"
        :deal-state="dealState"
        :dictionary="dictionary"
        :permissions="permissions"
        :is-new="isNew"
        :edit-main="editMain"
        :loading="loading"
        @changeEdit="editMain = $event"
        @refresh="refreshDeal"
        @create="refreshDeal"
        @update="refreshDeal"
      />
    </template>
    <template #content.myCompanyDocs>
      <TwPanelContent>
        <div class="tw-flex-1">
          <DealDocumentBlock
            class="tw-mb-[6px]"
            :permissions="permissions"
            :disabled="loading || (isSuccessDeal && !isAdmin)"
            :deal-id="regroupDealData && regroupDealData.mainDeal && regroupDealData.mainDeal.id"
            :deal-type="regroupDealData && regroupDealData.mainDeal && regroupDealData.mainDeal.type"
            :person-id="commonDealPersonId"
            :deal-sub-type-value="dealSubTypeValue"
            @refresh="refreshDeal"
          />
        </div>
        <div class="tw-flex-1" />
      </TwPanelContent>
    </template>
    <template #content.files>
      <DealFilesTab
        :deal="regroupDealData.mainDeal"
        :dictionary="dictionary"
        :permissions="permissions"
        :is-new="isNew"
        :loading="loading"
      />
    </template>
  </TwPanelTabsFull>
</template>

<script>
import DealGeneralTab from '@/views/Deal/TypeViews/DealGeneralTab'
import { mapGetters } from 'vuex'
import DealFilesTab from '@/views/Deal/TypeViews/DealFilesTab.vue'
import TwPanelTabsFull from '@/components/tw-ui/panels/TwPanelTabsFull.vue'
import DealDocumentBlock from '@/views/Deal/TypeViews/mortgage/dealDocument/DealDocumentBlock.vue'
import TwPanelContent from '@/components/tw-ui/panels/TwPanelContent.vue'

export default {
  name: 'DealAnOther',
  components: {
    TwPanelContent,
    DealDocumentBlock,
    TwPanelTabsFull,
    DealFilesTab,
    DealGeneralTab
  },
  props: {
    dictionary: {
      type: Object,
      default: () => {
        return {}
      }
    },
    dealState: {
      default: null
    },
    permissions: {
      type: Object,
      default: () => {
        return {}
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    isNew: {
      type: Boolean,
      default: false
    },
    dealData: {
      required: true
    }
  },
  data () {
    return {
      updateProcessing: false,
      currentTab: 'main',
      editMain: false
    }
  },
  methods: {
    refreshDeal (v) {
      this.editMain = false
      this.$emit('refresh', v)
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'profile/isAdmin'
    }),
    tabList () {
      return [
        {
          label: 'Общие',
          key: 'main'
        },
        {
          label: 'Документы компании',
          key: 'myCompanyDocs',
          disabled: this.isNew || this.loading || this.updateProcessing
        },
        {
          label: 'Файлы',
          key: 'files',
          disabled: this.isNew || this.loading || this.updateProcessing
        }
      ]
    },
    isSuccessDeal () {
      return (this?.regroupDealData?.mainDeal?.status?.code === 'success')
    },
    dealSubTypeValue () {
      return this.regroupDealData && this.regroupDealData?.mainDeal && this.regroupDealData?.mainDeal?.subtype ? this.regroupDealData?.mainDeal?.subtype?.value : null
    },
    commonDealPersonId () {
      return this.regroupDealData?.mainDeal?.person?.id || null
    },
    regroupDealData () {
      const { programs = [], dealApprovalInfo, ...variantFilters } = this.typedDealData || {} // исключаю лишнее оставляю только фильтры
      // console.log(this.$options.name, programs)
      // добавляю к фильтрам значения из основной Заявки - TODO клиентов добавлять не нужно??? Ибо тут не создание а обновление
      Object.assign(variantFilters || {}, {
        personId: this.dealData?.person?.id,
        otherPersons: Array.isArray(this.dealData?.otherPersons) && this.dealData?.otherPersons.length ? this.dealData?.otherPersons.map(c => c.id) : []
      })
      return {
        mainDeal: this.dealData,
        mortgageDeal: this.typedDealData,
        dealApprovalInfo,
        variantFilters,
        programs
      }
    }
  },
  watch: {
    // при переходах между вкладками отключаю редактирование
    currentTab () {
      this.editMain = false
    }
  }
}
</script>
