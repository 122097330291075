import { AxiosResponse } from 'axios'
import HTTP from '@/Api/http'
import { AuthAxiosDefaultConfig } from '@/Api/ObjectStorage'

const path = '/realty/editor/new-building/parking'

export default {
  create (params = {}): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig,
      params
    }
    return HTTP.get(`${path}/create`, $config)
  },
  show (id: string): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.get(`${path}/${id}`, $config)
  },
  store (data: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.post(`${path}`, data, $config)
  },
  update (id: string, data: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.put(`${path}/${id}`, data, $config)
  },
  copy (id: string, params = {}): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig,
      params
    }
    return HTTP.post(`${path}/${id}/copy`, {}, $config)
  },
  delete (id: string): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.delete(`${path}/${id}`, $config)
  }
}
