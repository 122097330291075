<template>
  <PageViewDefaultWrapper
    header-class="mb-2"
    :permission-denied="permissionDenied"
  >
    <template #header>
      <v-btn
        :disabled="isUserEmailBoxesNone || isUserEmailFoldersNone"
        class="mr-1"
        outlined
        color="primary"
        @click="create"
      >
        Написать
      </v-btn>
      <v-spacer />
      <Filters
        :disabled="isUserEmailBoxesNone || isUserEmailFoldersNone"
        :from-panel="fromPanel"
        type="emails"
        :show-filter-panel="false"
        @change="fetch"
      />
      <EmailBoxAndFolderSelector
        :value-folder="defaultFilters.folderId"
        :disabled="isUserEmailBoxesNone"
        @changeFolder="fetch"
        @inputFolder="defaultFilters.folderId = $event"
      />
    </template>
    <template>
      <template v-if="isUserEmailBoxesNone || isUserEmailFoldersNone">
        <v-card-text class="text-center">
          <div v-if="isUserEmailBoxesNone">
            Не удалось получить конфигурации почтовых ящиков пользователя
            <v-btn
              class="ma-2"
              small
              outlined
              @click="showProfile"
            >
              Открыть профиль
            </v-btn>
          </div>
          <div v-if="isUserEmailFoldersNone">
            Пожалуйста подождите<br>
            Почта собирается<br>
            <small>
              (Email Folders None)
            </small><br>
            <v-btn
              class="ma-2"
              small
              outlined
              @click="$store.dispatch('profile/fetchEmailConfigs')"
            >
              Обновить
            </v-btn>
          </div>
        </v-card-text>
      </template>
      <v-data-table
        v-else-if="!isUserEmailBoxesNone || isUserEmailFoldersNone"
        item-key="id"
        fixed-header
        height="100%"
        :loading="loading"
        :loading-text="loadingText"
        :no-data-text="noDataText"
        :no-results-text="noResultsText"
        :headers="tableOptionsComputed"
        :items="data"
        :items-per-page="itemsPerPage"
        hide-default-footer
      >
        <template #item="{ item }">
          <tr :class="item.emailFolder.type.value === 'inbox' ? [!!item.read ? 'email-table-read-true':'email-table-read-false']:[]">
            <td class="text-no-wrap">
              <ListMenu
                v-if="listItemActions.length"
                offset-y
                :action-list="listItemActions"
                :target="item"
              />
            </td>
            <td class="caption">
              <div
                v-for="(contact, index) in item.contacts"
                :key="contact.id+'_'+index"
                class="my-1"
              >
                <div
                  v-if="contact.person"
                  :key="contact.id"
                  class="panel-view-link"
                  @click="showPersonCard({ id: contact.person.id })"
                >
                  {{ $Global.peopleName(contact.person, 'fi') }}
                </div>
                <div
                  v-else
                  :key="contact.id"
                  class="d-flex align-center"
                >
                  <PersonNameLetters
                    class="mr-1"
                    :width="30"
                    :height="30"
                    :text="contact.name || contact.email || 'none'"
                  />
                  {{ contact.name }}
                  <span v-text="`<${contact.email}>`" />
                </div>
              </div>
            </td>
            <td class="caption">
              <div
                class="panel-view-link"
                @click="show(item)"
              >
                {{ item.theme }}
              </div>
            </td>
            <td class="caption text-no-wrap">
              <div class="d-flex flex-column align-end">
                <div class="text-right">
                  {{ $Global.DateFormat.DateTimeShort(item.dateMailBox || item.updatedAt) }}
                  <div v-if="item.status && item.status.value === 'send'">
                    <v-icon
                      :color="item.read ? 'green' : 'secondary'"
                      x-small
                    >
                      {{ item.read ? 'fa-check' : 'fa-clock-o' }}
                    </v-icon>
                    <small>
                      {{ item.read ? 'Прочитано' : 'Не прочитано' }}
                    </small>
                    <div v-if="item.read && item.dateRead">
                      <small>{{ $Global.DateFormat.DateTimeShort(item.dateRead) }}</small>
                    </div>
                  </div>
                </div>

                <div
                  v-if="item.childrenCount"
                  class="py-2"
                >
                  <v-btn
                    color="grey"
                    outlined
                    x-small
                    @click="toggleExpanded(item.id)"
                  >
                    {{ item.childrenCount }}
                    <v-icon
                      right
                      size="18"
                    >
                      fa-angle-down
                      <template v-if="expandedItems.includes(item.id)">
                        fa-flip-vertical
                      </template>
                    </v-icon>
                  </v-btn>
                </div>
              </div>
            </td>
          </tr>
          <tr v-if="item.childrenCount && expandedItems.includes(item.id)">
            <td
              class="px-0"
              colspan="99"
            >
              <EmailItemChildrens :id="item.id" />
            </td>
          </tr>
        </template>
      </v-data-table>
    </template>
    <template #footer>
      <DefaultListPagination
        class="text-center pt-1"
        :disabled="loading"
        :pagination="pagination"
        :page-count="pageCount"
        @change="changePagination"
      />
    </template>
  </PageViewDefaultWrapper>
</template>

<script>
import { mapGetters } from 'vuex'
import DataListMixin from '@/mixins/DataListMixin'
import API from '@/Api/Email/Mail'
import tableOptions from './tableOptions'
import Filters from '@/components/filters/Filters'
import ListMenu from '@/components/popup/menu/ListMenu'
import DefaultListPagination from '@/components/list/pagination/DefaultListPagination'
import PageViewDefaultWrapper from '@/components/core/ViewDefault/PageViewDefaultWrapper'
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import EmailBoxAndFolderSelector from '@/components/select/email/EmailBoxAndFolderSelector'
import PersonNameLetters from '@/components/other/person/PersonNameLetters'
import EmailItemChildrens from '@/views/Email/EmailItemChildrens'

export default {
  name: 'UserEmailList',
  components: {
    EmailItemChildrens,
    PersonNameLetters,
    EmailBoxAndFolderSelector,
    PageViewDefaultWrapper,
    DefaultListPagination,
    ListMenu,
    Filters
  },
  mixins: [DataListMixin, ViewPanelShowMixin],
  data () {
    return {
      API,
      noResultsText: 'Нет писем',
      defaultFilters: {
        folderId: 0,
        sortDirection: 'desc', // asc || desc
        sortField: 'createdAt'
      },
      expandedItems: []
    }
  },
  methods: {
    toggleExpanded (id) {
      console.warn('toggleExpanded', id, this.expandedItems)
      const expItemIndex = this.expandedItems.indexOf(id)
      if (expItemIndex !== -1) {
        this.expandedItems.splice(expItemIndex, 1)
      } else {
        this.expandedItems.push(id)
      }
    },
    create () {
      this.showEmailCard({})
    },
    show (item) {
      console.log('show', item)
      this.showEmailCard(
        {
          id: item.id
        },
        () => {
          console.warn('CALLBACK showEmailCard')
          this.refresh()
        })
    },
    showProfile () {
      this.showMyProfilePanel({}, () => {
        //
      })
    }
  },
  computed: {
    ...mapGetters({
      UserEmailConfigs: 'profile/UserEmailConfigs'
    }),
    tableOptionsComputed () {
      // const replaceContactsHeaderIndex = tableOptions.findIndex(item => item.value === 'contacts')
      return tableOptions
    },
    isUserEmailBoxesNone () {
      return !this.UserEmailConfigs || !Array.isArray(this.UserEmailConfigs) || !this.UserEmailConfigs.length
    },
    isUserEmailFoldersNone () {
      return !this.defaultFilters.folderId
    }
  },
  watch: {
    isUserEmailBoxesNone: {
      handler (newValue) {
        // console.error('isUserEmailBoxesNone ', newValue, oldValue)
        if (!newValue) {
          // console.error('isUserEmailBoxesNone ', this.defaultFilters.folderId)
          if (!this.isUserEmailFoldersNone) {
            this.fetch()
          }
        }
      },
      immediate: true
    }
  }
}
</script>
