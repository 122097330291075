<template>
  <div class="tw-pt-[17px] tw-px-[22px] tw-pb-[22px]">
    <b class="tw-block tw-text-an-flat-black-main">
      Параметры подбора условий:
    </b>
    <form
      ref="form"
      class="tw-mb-[28px]"
    >
      <div class="tw-grid tw-grid-cols-1 xl:tw-grid-cols-2 3xl:tw-grid-cols-4 tw-gap-[20px] tw-mb-[28px]">
        <div class="">
          <CombinedPriceInput
            v-model.number="form.term"
            :disabled="disabled"
            :max="30"
            :min="1"
            :symbol-input="'лет'"
            bottom-label-text="лет"
            label="Срок кредита"
          >
            <template #currentLabel>
              {{ $Global.textPlural(form.term, 'year') }}
            </template>
          </CombinedPriceInput>
        </div>
        <div class="">
          <CombinedPriceInput
            v-model.number="form.sum"
            :disabled="disabled"
            :min="1"
            :symbol-input="'₽'"
            label="Стоимость недвижимости"
          />
        </div>
        <div class="">
          <CombinedPriceInput
            v-model.number="form.firstSum"
            :max="form.sum"
            :min="0"
            :percent-show="true"
            :disabled="!form.sum || disabled"
            label="Первоначальный взнос"
          />
        </div>
        <div class="tw-p-[25px] tw-rounded-md tw-text-center tw-text-white tw-bg-gradient-to-r tw-from-[#B2D1F8] tw-from-10% tw-via-[#79C1F7] tw-via-35% tw-to-[#E6F4FC] tw-to-75%">
          <div>
            <b class="tw-text-[12px] tw-text-center tw-text-uppercase">Сумма кредита</b>
            <div class="tw-text-[24px] tw-font-semibold">
              {{ creditSum.toLocaleString() }} <small>руб.</small>
            </div>
          </div>
        </div>
      </div>
      <div class="tw-bg-an-flat-gray-15 tw-px-[18px] tw-pt-[15px] tw-pb-[21px] tw-rounded-md tw-w-full tw-flex tw-flex-col tw-mb-[28px]">
        <span class="tw-text-[14px] tw-font-bold tw-mb-[14px]">
          Дополнительно:
        </span>
        <div class="tw-w-full tw-flex tw-flex-row tw-gap-[40px] tw-mb-[6px] 2xl:tw-mb-[16px]">
          <div class="tw-w-1/2 tw-flex tw-flex-col tw-justify-between tw-gap-[30px]">
            <div class="tw-flex tw-flex-col 3xl:tw-flex-row tw-gap-[10px]">
              <div class="tw-flex tw-flex-col 3xl:tw-w-1/2 3xl:tw-flex-row tw-gap-[10px]">
                <div class="3xl:tw-w-full">
                  <TwInputText
                    v-model.number="form.age"
                    type="number"
                    label="Возраст"
                    placeholder="Возраст"
                    :persistent-label="true"
                    :show-label="true"
                    :max="100"
                    :min="1"
                    :dense="true"
                  >
                    <template #append>
                      лет
                    </template>
                  </TwInputText>
                </div>
                <div class="3xl:tw-w-full">
                  <TwInputText
                    v-model.number="form.experienceLast"
                    type="number"
                    label="Стаж на тек. месте"
                    placeholder="Стаж на тек. месте"
                    :persistent-label="true"
                    :show-label="true"
                    :max="100"
                    :min="1"
                    :dense="true"
                  >
                    <template #append>
                      мес.
                    </template>
                  </TwInputText>
                </div>
              </div>
              <div class="3xl:tw-w-1/2">
                <TwInputText
                  v-model="form.region"
                  class="tw-w-full"
                  type="text"
                  label="Регион приобретения недвижимости"
                  placeholder="Регион приобретения недвижимости"
                  :persistent-label="true"
                  :show-label="true"
                  :dense="true"
                />
              </div>
            </div>
          </div>
          <ul class="tw-w-1/2 tw-grid tw-grid-cols-1 3xl:tw-grid-cols-2 4xl:tw-grid-cols-3 tw-gap-[12px] tw-h-fit">
            <li>
              <TwCheckBox
                v-model="form.badCreditHistory"
                label="Плохая кредитная история"
                :disabled="disabled"
                background-and-border-color="tw-bg-an-flat-dark-blue-link tw-border-an-flat-dark-blue-link"
              />
            </li>
            <li>
              <TwCheckBox
                v-model="form.noNationalRF"
                label="Нет гражданства РФ"
                :disabled="disabled"
                background-and-border-color="tw-bg-an-flat-dark-blue-link tw-border-an-flat-dark-blue-link"
              />
            </li>
            <li>
              <TwCheckBox
                v-model="form.noMilitaryId"
                label="Мужчина до 27 лет без военного билета"
                :disabled="disabled"
                background-and-border-color="tw-bg-an-flat-dark-blue-link tw-border-an-flat-dark-blue-link"
              />
            </li>
            <li>
              <TwCheckBox
                v-model="form.rono"
                label="РОНО"
                :disabled="disabled"
                background-and-border-color="tw-bg-an-flat-dark-blue-link tw-border-an-flat-dark-blue-link"
              />
            </li>
            <li>
              <TwCheckBox
                v-model="form.matCapitalFirstPay"
                label="Мат капитал в качестве п/в"
                :disabled="disabled"
                background-and-border-color="tw-bg-an-flat-dark-blue-link tw-border-an-flat-dark-blue-link"
              />
            </li>
            <li>
              <TwCheckBox
                v-model="form.decreaseToCreditSum"
                label="Занижение до суммы кредита"
                :disabled="disabled"
                background-and-border-color="tw-bg-an-flat-dark-blue-link tw-border-an-flat-dark-blue-link"
              />
            </li>
            <li>
              <TwCheckBox
                v-model="form.decreaseLowerCreditSum"
                label="Занижение ниже суммы кредита"
                :disabled="disabled"
                background-and-border-color="tw-bg-an-flat-dark-blue-link tw-border-an-flat-dark-blue-link"
              />
            </li>
            <li>
              <TwCheckBox
                v-model="form.redemptionBankPledge"
                label="Выкуп залога другого банка"
                :disabled="disabled"
                background-and-border-color="tw-bg-an-flat-dark-blue-link tw-border-an-flat-dark-blue-link"
              />
            </li>
            <li>
              <TwCheckBox
                v-model="form.transactionBetweenRelatives"
                label="Сделки между родственниками"
                :disabled="disabled"
                background-and-border-color="tw-bg-an-flat-dark-blue-link tw-border-an-flat-dark-blue-link"
              />
            </li>
            <li class="2xl:tw-col-span-2 4xl:tw-col-span-1">
              <TwCheckBox
                v-model="form.withDifferentiated"
                label="Только банки с возможностью выбрать дифференцированный платёж"
                :disabled="disabled"
                background-and-border-color="tw-bg-an-flat-dark-blue-link tw-border-an-flat-dark-blue-link"
              />
            </li>
          </ul>
        </div>
        <div class="">
          <TwCheckBox
            :class="{ 'tw-mb-[24px]' : isSubsidized }"
            label="Расчёт субсидированной ставки"
            :disabled="disabled"
            :value="isSubsidized"
            background-and-border-color="tw-bg-an-flat-dark-blue-link tw-border-an-flat-dark-blue-link"
            @input="subsidizedChange"
          />
          <div
            v-if="isSubsidized"
            class="tw-grid tw-grid-cols-1 xl:tw-grid-cols-2 2xl:tw-grid-cols-3 3xl:tw-grid-cols-4 tw-gap-[22px]"
          >
            <div>
              <TwSelectButton
                :dense="true"
                label="Тип недвижимости:"
                :value="form.subsidizedDealType"
                :options="realEstateSelect"
                @input="form.subsidizedDealType = $event"
              />
            </div>
            <template v-if="form.subsidizedDealType === 'buy'">
              <!--        ВТОРИЧКА          -->
              <div>
                <TwSelectButton
                  :dense="true"
                  label="Возможность оплатить КВ за счет ПВ:"
                  :value="form.commissionRewardFirstPay"
                  :options="contributionSelect"
                  @input="addCommissionRewardFirstPay($event)"
                />
              </div>
              <div>
                <CommentByTagView
                  :api="mortgageUserCommentsAPI"
                  tag="subsidizedBuy"
                />
              </div>
            </template>
            <template v-else-if="form.subsidizedDealType === 'buy_nb'">
              <!--        НОВОСТРОЙКИ          -->
              <div>
                <TwSelectButton
                  :dense="true"
                  :multi="true"
                  label="Компенсация от застройщика банку:"
                  :value="form.commissionPayType"
                  :options="compensationSelect"
                  @input="addCommissionPayType($event)"
                />
              </div>
              <div class="">
                <VariantComplexSelect
                  v-model="form.complexId"
                  classes-component="tw-bg-white"
                  label="Жилой комплекс"
                  placeholder="Не выбран"
                  clearable
                  :commission-pay-type="form.commissionPayType"
                />
              </div>
              <div>
                <CommentByTagView
                  :api="mortgageUserCommentsAPI"
                  tag="subsidizedBuyNb"
                />
              </div>
            </template>
          </div>
        </div>
      </div>
      <div>
        <b class="tw-block tw-mb-[12px] tw-text-[18px]">
          Категории
          <span class="tw-text-an-flat-red-main">*</span>
        </b>
        <div class="">
          <ProgramCategorySelect
            v-model="form.categoriesId"
            class=""
            :only-subsidized="form.subsidizedDealType === 'buy_nb'"
            :warning-values="form"
            @copy="showCopyPasteModal"
          />
        </div>
      </div>
      <div>
        <div v-if="form.subsidizedDealType === 'buy_nb'">
          <small class="tw-text-an-flat-grey-3">
            <span class="tw-text-an-flat-red-main">*</span> - отображаются только программы поддерживающие субсидирование
          </small>
        </div>
      </div>
    </form>
    <VariantParamsCopyPasteModal
      ref="copyPasteModal"
      :params="form"
      :from-iframe="fromIframe"
      @updateVariantParams="updateFormFromCopyPaste"
    />
  </div>
</template>

<script>
import lodashDebounce from 'lodash/debounce'
import CombinedPriceInput from '@/components/input/price/CombinedPriceInput'
import ProgramCategorySelect from '@/components/select/program-category/ProgramCategorySelect'
import VariantParamsCopyPasteModal from '@/views/Mortgage/Variant/Matrix/VariantParamsCopyPasteModal'
import CommentByTagView from '@/views/Mortgage/UserComments/CommentByTagView'
import mortgageUserCommentsAPI from '@/Api/Mortgage/UserComments'
import VariantComplexSelect from '@/views/Mortgage/Variant/programs/VariantComplexSelect'
import TwInputText from '@/components/tw-ui/ui-kit/input/TwInputText.vue'
import TwCheckBox from '@/components/tw-ui/ui-kit/input/TwCheckBox.vue'
import TwSelectButton from '@/components/tw-ui/ui-kit/button/TwSelectButton.vue'
import API from '@/Api/Crm/Deal/Mortgage'
export default {
  name: 'VariantFilterMatrix',
  components: {
    TwSelectButton,
    TwCheckBox,
    TwInputText,
    VariantComplexSelect,
    CommentByTagView,
    VariantParamsCopyPasteModal,
    ProgramCategorySelect,
    CombinedPriceInput
  },
  props: {
    initData: {},
    fromIframe: {
      type: Boolean,
      default: false
    },
    dealId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      API: API,
      disabled: false,
      isSubsidized: false,
      init: false,
      realEstateSelect: [
        {
          label: 'Вторичка',
          value: 'buy'
        },
        {
          label: 'Новостройки',
          value: 'buy_nb'
        }
      ],
      contributionSelect: [
        {
          label: 'Не важно',
          value: null
        },
        {
          label: 'Есть',
          value: true
        },
        {
          label: 'Нет',
          value: false
        }
      ],
      compensationSelect: [
        {
          label: 'Не оплачивает',
          value: 'no'
        },
        {
          label: 'Полностью',
          value: 'full'
        },
        {
          label: 'Частично',
          value: 'part'
        }
      ],
      form: {
        region: 'Республика Татарстан, г.Казань',
        age: null,
        experienceLast: null,
        badCreditHistory: null,
        withDifferentiated: null,
        noMilitaryId: null,
        noNationalRF: null,
        subsidizedDealType: null, // Для определения типа матриц null, buy, buy_nb

        commissionRewardFirstPay: null, // Возможность оплаты КВ за счёт средств ПВ(Только для вторички)
        decreaseToCreditSum: null, // Занижение до суммы кредита
        decreaseLowerCreditSum: null, // Занижение ниже суммы кредита
        redemptionBankPledge: null, // Выкуп залога другого банка
        transactionBetweenRelatives: null, // Сделки между родственниками

        commissionPayType: [], // Компенсация от застройщика банку не используется в расчётах, добавлено в форму для применения импорта \ экспорта
        complexId: null, // ID комплекса для субсид ставок
        categoriesId: [],
        sum: 5000000,
        term: 30,
        firstSum: 1500000,
        rono: false,
        matCapitalFirstPay: false
      }
    }
  },
  methods: {
    addCommissionRewardFirstPay (event) {
      this.form.commissionRewardFirstPay = event
      this.updateDealMortgage(this.dealId)
    },
    addCommissionPayType (event) {
      this.form.commissionPayType = event
      this.updateDealMortgage(this.dealId)
    },
    initial () {
      this.init = true
      if (this.initData) {
        console.warn('initData', this.initData, this.form)
        for (const fieldKey in this.form) {
          if (fieldKey === 'categoriesId' && Array.isArray(this.initData?.categories)) {
            this.form[fieldKey] = this.initData.categories.map(cat => cat.id)
          } else if (fieldKey === 'gender') {
            this.form[fieldKey] = this.initData?.gender?.value || null
          } else if (fieldKey === 'subsidizedDealType') {
            this.form[fieldKey] = this.initData?.subsidizedDealType?.value || null
          } else {
            this.form[fieldKey] = this.initData[fieldKey] !== undefined ? this.initData[fieldKey] : this.form[fieldKey]
          }
        }
        // Определяю состояние флага субсидированных ставок
        this.isSubsidized = !!this.form?.subsidizedDealType
      } else {
        this.search()
      }
    },
    subsidizedChange (value) {
      this.isSubsidized = value
      if (!value) {
        // если галочку сняли - сбрасываю значения выбранного комплекса и типа сделки
        this.form.complexId = null
        this.form.subsidizedDealType = null
      } else {
        // Если галочку поставили - выбираю вторичку по умолчанию
        this.form.subsidizedDealType = 'buy'
      }
    },
    subsidizedDealTypeChange (value) {
      // сбрасываю Возможность оплаты КВ за счёт средств ПВ
      this.form.commissionRewardFirstPay = null
      // если выбрали вторичку - сбрасываю выбранный комплекс
      if (value === 'buy') {
        this.form.complexId = null
        this.form.commissionPayType = null
      } else {
        // если новостройку то ???
      }
    },
    search () {
      this.debounceFilters()
    },
    updateFormFromCopyPaste (paramsObj = {}) {
      this.$set(this, 'form', {
        ...this.form,
        ...paramsObj
      })
      // Определяю состояние флага субсидированных ставок
      this.isSubsidized = !!this.form?.subsidizedDealType
      this.search()
    },
    debounceFilters: lodashDebounce(function () {
      // первые изменения завершают инициализацию
      if (this.init) {
        this.init = false
      }
      this.updateDealMortgage(this.dealId)
      this.$emit('change', this.form)
    }, 500),
    showCopyPasteModal (type) {
      this.$refs.copyPasteModal.showModal(type)
    },
    postDealMortgage (dealId) {
      if (this.dealId && this.dealId > 0) {
        this.disabled = true
        API.store(dealId, this.form)
          .catch((error) => {
            console.error(this.$options.name, 'Error postDealMortgage: ', error)
          })
          .finally(() => {
            this.disabled = false
          })
      }
    },
    updateDealMortgage (dealId) {
      if (this.dealId && this.dealId > 0) {
        this.disabled = true
        API.update(dealId, this.form)
          .catch((error) => {
            console.error(this.$options.name, 'Error updateDealMortgage: ', error)
          })
          .finally(() => {
            this.disabled = false
          })
      }
    },
    refreshTypedDeal () {
      if (this.dealId && this.dealId > 0) {
        this.disabled = true
        API.show(this.dealId)
          .then(({ data }) => {
            if (data?.data) {
              this.form = data?.data
              if (this.form.subsidizedDealType && this.form.subsidizedDealType.value) {
                this.form.subsidizedDealType = this.form.subsidizedDealType.value
              }
            }
            this.typedDealData = data?.data || null
          })
          .catch((error) => {
            console.error(this.$options.name, 'Error refreshTypedDeal: ', error)
            this.typedDealData = null
            this.postDealMortgage(this.dealId)
          })
          .finally(() => {
            this.disabled = false
            this.updateProcessing = false
            // refresh main deal
            this.$emit('refresh')
          })
      }
    }
  },
  computed: {
    mortgageUserCommentsAPI () {
      return mortgageUserCommentsAPI
    },
    creditSum () {
      const sum = this.form?.sum || 0
      const firstSum = this.form?.firstSum || 0
      return sum < firstSum ? 0 : sum - firstSum
    }
  },
  watch: {
    form: {
      handler (newVal) {
        if (newVal.firstSum > newVal.sum) {
          this.form.firstSum = newVal.sum || 0
        }
        if (!newVal.age) {
          this.form.age = null
        }
        if (!newVal.experienceLast) {
          this.form.experienceLast = null
        }
        this.search()
      },
      deep: true,
      immediate: true
    }
  },
  mounted () {
    this.initial()
    this.refreshTypedDeal()
  }
}
</script>
