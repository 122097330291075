<template>
  <div class="tw-font-manrope tw-mt-3">
    <div class="tw-grid tw-grid-cols-[350px_auto] tw-gap-4">
      <!--О КЛИЕНТЕ-->
      <TwPanelContentBlock class="tw-mb-[6px]">
        <template #title>
          <div>
            Клиент
          </div>
        </template>
        <template
          v-if="dealData.person"
          #action
        >
          <ViewPanelLink
            :id="dealData.person.id"
            class="tw-text-main-xs"
            label="Клиент"
            component="person-show"
          >
            подробнее
          </ViewPanelLink>
        </template>
        <div>
          <PersonMainShow
            :info="dealData.person"
            :person-types="{}"
          />
        </div>
      </TwPanelContentBlock>

      <!--АНКЕТА-чек лист-->
      <TwPanelContentBlock class="tw-mb-[6px]">
        <template #title>
          <div>
            Анкета (чек лист)
          </div>
        </template>
        <template #action>
          <button
            v-if="!isEdit"
            class="tw-text-main-xs enabled:hover:tw-text-an-flat-dark-blue-link"
            @click.prevent="isEdit = true"
          >
            изменить
          </button>
        </template>
        <div>
          <DealBuyNbQuestionnaireForm
            v-if="isEdit"
            :id="editableId ? dealId : 0"
            :preset-values="editableId ? null : presetValues"
            :deal-id="dealId"
            @refresh="isEdit = false"
            @cancel="isEdit = false"
          />
          <DealBuyNbQuestionnaireShow
            v-else
            :deal-id="dealId"
            @changeEditableId="editableId = $event"
          />
        </div>
      </TwPanelContentBlock>
    </div>
  </div>
</template>

<script>
import PersonMainShow from '@/views/Person/main/PersonMainShow.vue'
import DealBuyNbQuestionnaireForm from '@/views/Deal/TypeViews/buyNb/Client/DealBuyNbQuestionnaireForm.vue'
import DealBuyNbQuestionnaireShow from '@/views/Deal/TypeViews/buyNb/Client/DealBuyNbQuestionnaireShow.vue'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink.vue'

export default {
  name: 'DealBuyNbClientTab',
  components: { ViewPanelLink, TwPanelContentBlock, DealBuyNbQuestionnaireShow, DealBuyNbQuestionnaireForm, PersonMainShow },
  props: {
    dealData: {
      required: true
    }
  },
  data () {
    return {
      isEdit: false,
      editableId: 0
    }
  },
  computed: {
    dealId () {
      return this.dealData?.id || null
    },
    presetValues () {
      const fullName = `${this.dealData?.person?.surname || ''} ${this.dealData?.person?.name || ''} ${this.dealData?.person?.fatherName || ''}`
      const phone = this.dealData?.person?.phones?.[0]?.phone || null
      return {
        fullName,
        phone
      }
    }
  }
}
</script>

<style scoped>

</style>
