<template>
  <div
    v-cloak
    v-if="info"
  >
    <div
      v-if="!info.active || !info.company.active"
      class="tw-bg-an-flat-orange-warning tw-rounded-sm tw-text-white tw-px-[18px] tw-py-[12px] tw-text-[18px]"
    >
      <div
        v-if="!info.active"
        class="tw-font-medium"
      >
        <b class="tw-text-[26px] tw-mr-[8px]">!</b>Программа неактивна!
      </div>
      <div
        v-if="!info.company.active"
        class="tw-font-medium"
      >
        <b class="tw-text-[26px] tw-mr-[8px]">!</b>Банк программы не активен!
      </div>
    </div>
    <div>
      <div class="tw-flex tw-flex-col tw-gap-[22px]">
        <TwPanelContentBlockFieldView>
          <template #title>
            Наименование
          </template>
          <div>
            {{ info.name }}
          </div>
        </TwPanelContentBlockFieldView>
        <TwPanelContentBlockFieldView v-if="viewObjects.main">
          <template #title>
            Условия
          </template>
          <div>
            <div>
              <div
                v-for="(item, key) of viewObjects.main"
                :key="key"
                class="my-2"
              >
                <template v-if="key !== 'id' && key !== 'name' && key !== 'commissionRewardFirstPay'">
                  <small class="text-muted">{{ key | fieldNameTranslate('main') }}</small>
                  <div>
                    <template v-if="key === 'active' || key === 'matCapitalFirstPay'">
                      {{ item ? 'Да' : 'Нет' }}
                    </template>
                    <template v-else-if="key === 'subsidized'">
                      {{ item ? 'Включены' : 'Выключены' }}
                    </template>

                    <template v-else-if="key.indexOf('sum') !== -1">
                      {{ item && item.toLocaleString() }} руб.
                    </template>
                    <template v-else-if="key.indexOf('rate') !== -1 || key.indexOf('firstSum') !== -1">
                      {{ item && item.toLocaleString() }}%
                    </template>
                    <template v-else-if="key.indexOf('term') !== -1">
                      {{ item }} {{ $Global.textPlural(item || 0, 'month') }}
                    </template>
                    <template v-else-if="key === 'categories'">
                      <div v-if="Array.isArray(item) && item.length">
                        <template v-for="(catInfo, catIndex) of item">
                          <div
                            :key="catIndex"
                            class="pa-1 text-no-wrap"
                          >
                            <div
                              :class="catInfo.active ? '':'text-muted'"
                              class="d-flex align-baseline"
                            >
                              <small>
                                {{ catInfo.name }}
                              </small>
                              <small
                                v-if="catInfo.type"
                                class="mx-1 text--disabled"
                              >({{ catInfo.type.label }})</small>
                              <small
                                v-if="!catInfo.active"
                                class="error--text"
                              >(неактивная)</small>
                            </div>
                          </div>
                        </template>
                      </div>
                      <div v-else>
                        Не выбраны
                      </div>
                    </template>
                    <template v-else>
                      {{ item }}
                    </template>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </TwPanelContentBlockFieldView>
        <TwPanelContentBlockFieldView
          v-if="info.name"
          :horizontal="false"
        >
          <template #title>
            Банк
          </template>
          <template>
            <div
              v-if="info.company"
              :class="info.company.active ? '':'red--text'"
            >
              <ViewPanelLink
                :id="info.company.id"
                label="Компания"
                component="company-show"
              >
                {{ info.company.name }}
              </ViewPanelLink>
              <span v-if="!info.company.active">
                (неактивен)
              </span>
            </div>
            <div v-else>
              Не указан
            </div>
          </template>
        </TwPanelContentBlockFieldView>
        <TwPanelContentBlockFieldView
          v-if="info.name"
          :horizontal="false"
        >
          <template #title>
            <div>
              Возможность оплатить КВ за счет ПВ (<span class="tw-text-an-flat-red-main">Только Вторичка</span>)
            </div>
          </template>
          <template>
            {{ info.commissionRewardFirstPay ? 'Да' : 'Нет' }}
          </template>
        </TwPanelContentBlockFieldView>
        <TwPanelContentBlockFieldView :horizontal="false">
          <template #title>
            Ставки*
          </template>
          <template>
            <div>
              <template v-if="Array.isArray(viewObjects.rates) && viewObjects.rates.length">
                <div>
                  <div>
                    <small>* Только базовые ставки - субсидированные добавляются через матрицу в требованиях банка</small>
                  </div>
                  <div class="tw-pt-[20px]">
                    <div
                      v-if="viewObjects.rates.some(r => !r.complexesId)"
                      class="tw-px-[6px] tw-py-[6px] tw-flex tw-flex-wrap tw-gap-[10px] tw-max-w-[300px] tw-border tw-border-solid tw-rounded-sm"
                    >
                      <template v-for="(rate, index) of viewObjects.rates">
                        <template v-if="!rate.complexesId">
                          <div
                            :key="index"
                            class="tw-w-1/3 tw-relative tw-flex-1"
                          >
                            <div class="tw-relative tw-flex tw-flex-col tw-justify-start">
                              <div class="tw-text-an-flat-not-active-input tw-w-light tw-text-[10px] tw-text-[10px] tw-absolute tw-right-[5px] tw-top-[3px]">
                                #{{ rate.id }}
                              </div>
                              <div class="">
                                <MortgageProgramRateShow :item="rate" />
                              </div>
                            </div>
                          </div>
                        </template>
                      </template>
                    </div>
                    <div
                      v-else
                      class="tw-text-an-flat-not-active-input"
                    >
                      Не добавлены
                    </div>
                  </div>
                </div>
              </template>
              <div
                v-else
                class="tw-mx-2 tw-px-[3]"
              >
                не добавлены
              </div>
            </div>
          </template>
        </TwPanelContentBlockFieldView>
      </div>
    </div>
  </div>
</template>

<script>
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink'
import MortgageProgramRateShow from '@/views/Mortgage/Program/main/MortgageProgramRateShow'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'

const fieldDict = {
  main: {
    // name: 'Название программы',
    // id: 'Внутренний идентификатор',
    matCapitalFirstPay: 'Маткапитал первоначальным взносом',
    sumMin: 'Мин. сумма кредита',
    sumMax: 'Макс. сумма кредита',
    termMin: 'Мин. срок кредита',
    termMax: 'Макс. срок кредита',
    categories: 'Категории',
    subsidized: 'Субсидированные ставки',
    active: 'Активная'
  },
  company: {
    name: 'Название',
    id: 'Внутренний идентификатор',
    type: 'Тип компании',
    active: 'Активный'
  }
}

export default {
  name: 'MortgageProgramMainShow',
  components: {
    TwPanelContentBlockFieldView,
    MortgageProgramRateShow,
    ViewPanelLink
  },
  filters: {
    fieldNameTranslate: function (fieldName, dictType) {
      // console.warn('fieldNameTranslate ', fieldName, dictType)
      if (!dictType || !fieldName) return 'Error'
      return fieldDict[dictType][fieldName] || 'Название отсутствует в словаре'
    }
  },
  props: {
    info: {},
    dictionary: {}
  },
  data () {
    return {
    }
  },
  computed: {
    viewObjects () {
      const { rates, company, ...main } = this.info
      return {
        rates,
        company,
        main
      }
    }
  }
}
</script>
