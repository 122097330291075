import { RouteKeys } from '@/router/types'
import platformAccount from './platform-account'

export default {
  path: RouteKeys.messenger,
  name: `${RouteKeys.messenger}-dictionary`,
  redirect: { name: `${RouteKeys.messengerPlatformAccount}` },
  meta: { name: 'Messenger', icon: 'books-messenger', isAdmin: true },
  component: () => import('@/views/Dictionary/Index.vue'),
  children: [
    platformAccount
  ]
}
