<template>
  <div v-if="!formProcessing">
    <div style="display: none">
      {{ isEdited }}
    </div>
    <div>
      <TwTextarea
        ref="text"
        v-model="form.text"
        rows="5"
        resize="y"
        class="tw-mt-[24px]"
        autocomplete="off"
        persistent-label
        placeholder="Комментарий"
        :disabled="formProcessing"
        :error-text="Array.isArray(textErrors) && textErrors.length ? textErrors[0] : '' "
        :show-label="true"
        @input="$v.form.text.$touch()"
        @blur="$v.form.text.$touch()"
      />
    </div>
    <div
      ref="fileId"
      class="tw-my-[24px]"
    >
      <div class="tw-font-bold tw-mb-[10px]">
        Прикреплённые файлы:
      </div>
      <TwUploadFilesList
        v-model="form.fileId"
        label="Загрузить файлы"
        height="120px"
        :is-public="true"
        :api="fileAPI"
      />
    </div>

    <TwCardSimpleFormSaveBlock
      bg-color="transparent"
      :loading="formProcessing"
      :cancel-confirm="isEdited"
      :is-sticky="false"
      :visible="true"
      @success="handleSubmit"
      @cancel="handleCancel"
    />
  </div>
  <div
    v-else
    class="tw-py-3 tw-text-center tw-m-auto"
  >
    <TwCircleLoader />
  </div>
</template>

<script>
import API from '@/Api/Support/Task/Comment'
import fileAPI from '@/Api/Support/File'
import { required } from 'vuelidate/lib/validators'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import { RouteKeys } from '@/router/types'
import TwTextarea from '@/components/tw-ui/ui-kit/input/TwTextarea.vue'
import TwUploadFilesList from '@/components/tw-ui/ui-kit/files/TwUploadFilesList.vue'
import TwCardSimpleFormSaveBlock from '@/components/tw-ui/forms/TwCardSimpleFormSaveBlock.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'

export default {
  name: 'TaskCommentMainForm',
  components: {
    TwCircleLoader,
    TwCardSimpleFormSaveBlock,
    TwUploadFilesList,
    TwTextarea
  },
  mixins: [FormCreateEditMixin],
  props: {
    taskId: {
      type: Number,
      required: true
    },
    disableRedirect: {
      type: Boolean,
      default: false
    },
    defaultFormFields: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      fileAPI: fileAPI,
      API: API,
      form: {
        taskId: this.taskId,
        fileId: [],
        text: ''
      }
    }
  },
  // Модель для валидации, которую Vuelidate превратит в computed-поле $v
  validations: {
    form: {
      text: {
        required
      }
    }
  },
  computed: {
    textErrors () {
      const errors = []
      if (!this.$v.form.text.$dirty) return errors
      if (!this.$v.form.text.required) {
        errors.push('Обязательное поле')
        this.$Global.scrollToElem(this.$refs.text.$el)
      }
      return errors
    }
  },
  methods: {
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          const adaperMap = {
            // from(show): to(send)
            files: 'fileId'
          }
          for (const key in this.preset) {
            if (adaperMap[key] !== undefined) {
              if (key === 'files' && Array.isArray(this.preset[key])) {
                this.$set(this.form, adaperMap[key], this.preset[key].map(file => file.id))
              }
            } else {
              this.$set(this.form, key, this.preset[key])
            }
          }
        }
        resolve()
      })
    },
    handleSubmit () {
      this.$v.$reset()
      this.$v.$touch()
      // console.log('handleSubmit ', this.$v)
      // console.log('$invalid ', this.$v.form.$invalid)
      if (!this.$v.$invalid) {
        if (this.formProcessing === true) {
          return
        }

        this.formProcessing = true
        const formData = this.form
        formData.taskId = this.taskId

        const request = this.formType === 'create'
          ? API.store(formData)
          : API.update(this.targetId, formData)

        request.then(({ data }) => {
          if (this.formType === 'create') {
            this.$emit('create', data.data)
            // если текущий путь равен пути создания то редирект в просмотр
            if (this.$route.name === `${RouteKeys.lead}-create`) {
              this.$router.replace({
                name: `${RouteKeys.lead}-show`,
                params: { id: data.data.id }
              })
            }
          } else {
            this.$emit('update', data.data)
          }
        }).finally(() => {
          this.formProcessing = false
        })
      }
    }
  }
}
</script>
