import Swiper, { Navigation, Pagination } from 'swiper'
import 'swiper/swiper-bundle.css'

// configure Swiper to use modules
Swiper.use([Navigation, Pagination])

export default {
  props: {
    name: {
      type: String,
      required: true // имя должно быть уникальным в рамках одной страницы
    },
    breakpoints: {
      type: Object,
      default: () => {
        return {}
      }
    },
    direction: {
      type: String,
      default: 'horizontal'
    },
    spaceBetween: {
      type: Number,
      default: 30
    },
    slidesPerView: {
      type: [Number, String],
      default: 3
    },
    slidesPerColumn: {
      type: Number,
      default: 1
    },
    loop: {
      type: Boolean,
      default: false
    },
    centeredSlides: {
      type: Boolean,
      default: false
    },
    freeMode: {
      type: Boolean,
      default: false
    },
    showNavigation: {
      type: Boolean,
      default: false
    },
    showPagination: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      swiper: null
    }
  },
  methods: {
    update () {
      this.$nextTick(() => {
        this.swiper.update()
        this.swiper.updateProgress()
        this.swiper.updateSize()
        this.swiper.updateSlides()
      })
    },
    getCurrentSlide () {
      // console.warn(this.$options.name, 'getCurrentSlide', this.swiper)
      return this.swiper.activeIndex
    },
    slideTo (index, speed = 200, callbacks) {
      // console.warn(this.$options.name, 'slideTo', index)
      this.swiper.slideTo(index, speed, callbacks)
      this.update()
    },
    nextSlide () {
      // console.warn(this.$options.name, 'nextSlide', this.swiper)
      this.swiper.slideNext(200)
    },
    prevSlide () {
      // console.warn(this.$options.name, 'nextSlide', this.swiper)
      this.swiper.slidePrev(200)
    },
    initSwiper () {
      this.swiper = new Swiper('.' + this.uniqName, {
        slidesPerView: this.slidesPerView,
        slidesPerColumn: this.slidesPerColumn,
        spaceBetween: this.spaceBetween,
        loop: this.loop,
        direction: this.direction,
        centeredSlides: this.centeredSlides,
        resizeObserver: true,
        freeMode: this.freeMode,
        breakpoints: this.breakpoints,
        pagination: this.showPagination ? {
          el: '.swiper-pagination',
          clickable: true
        } : false,
        navigation: this.showNavigation ? {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        } : false
        // breakpoints: {
        //   // when window width is >= 320px
        //   320: {
        //     slidesPerView: 2,
        //     spaceBetween: 20
        //   },
        //   // when window width is >= 480px
        //   480: {
        //     slidesPerView: 3,
        //     spaceBetween: 30
        //   },
        //   // when window width is >= 640px
        //   640: {
        //     slidesPerView: 4,
        //     spaceBetween: 40
        //   }
        // }
      })
      this.swiper.on('slideChange', this.slideOnChange)
      this.$on('hook:beforeDestroy', () => {
        this.swiper.off('slideChange', this.slideOnChange)
      })
    },
    slideOnChange () {
      // console.warn(this.$options.name, 'slideOnChange', this.swiper)
      this.activeIndex = this.swiper?.activeIndex || 0
      this.$emit('slideChange', {
        activeIndex: this.swiper.activeIndex
      })
    }
  },
  computed: {
    uniqName () {
      return 'swiper_uniq_' + this.name + '_' + this._uid
    }
  },
  mounted () {
    if (!this.swiper) { this.$nextTick(this.initSwiper) }
  }
}
