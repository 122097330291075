<template>
  <component
    :is="tag"
    :title="title"
    class="tw-cursor-help tw-relative tw-leading-[8px] tw-inline-block tw-w-[15px] tw-h-[15px] tw-mr-[4px] tw-p-[2px] tw-bg-an-flat-black-main tw-text-white tw-rounded-[3px] tw-text-[8px] tw-font-bold"
  >
    <span class="tw-absolute tw-top-1/2 tw-left-1/2 tw-transform -tw-translate-x-1/2 -tw-translate-y-1/2 tw-overflow-hidden">
      ЧС
    </span>
  </component>
</template>

<script lang="js">
export default {
  name: 'TwBlackListIcon',
  props: {
    tag: {
      type: String,
      default: 'div'
    },
    title: {
      type: String,
      default: 'В чёрном списке'
    }
  }
}
</script>
