<template>
  <input
    :value="value"
    autocomplete="off"
    type="text"
    style="padding-right: 20px"
    v-on="$listeners"
  >
</template>

<script>
export default {
  name: 'StringInputField',
  props: {
    value: {}
  }
}
</script>
