<template>
  <v-card
    flat
    tile
    class="my-2 card-tab-block"
  >
    <v-card-subtitle class="d-flex">
      Изменение цен ЖК: {{ complex.name }}
      <v-spacer />
      <NativeDateInputField
        v-model="graphFilters.dateFrom"
        class="mr-3 tw-max-w-[250px]"
        dense
        type="month"
        hide-details
        outlined
        prefix="от"
        :disabled="loading"
      />
      <NativeDateInputField
        v-model="graphFilters.dateTo"
        class="tw-max-w-[250px]"
        dense
        type="month"
        hide-details
        outlined
        prefix="до"
        :disabled="loading"
      />
      <v-btn
        small
        text
        color="primary"
        :loading="loading"
        @click="changePeriod"
      >
        обновить
      </v-btn>
    </v-card-subtitle>
    <v-divider />
    <v-card-text>
      <LinearChart
        :chart-data="chartData"
        :loading="loading"
        :height="height"
        :options="chartOptions"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import LinearChart from '@/components/Graph/Linear'
import { API } from '@/Api/ObjectStorage'
import NativeDateInputField from '@/components/input/NativeDateInputField'
import { newsBuildingsColors } from '@/components/Graph/graphColors'

export default {
  name: 'AnalyzeSimpleComplexGraph',
  components: { NativeDateInputField, LinearChart },
  props: {
    complex: {
      type: Object,
      required: true
    },
    height: {
      type: Number,
      default: 200
    }
  },
  data () {
    return {
      graphFilters: {
        dateFrom: '',
        dateTo: ''
      },
      loading: false,
      chartData: {
        labels: [],
        datasets: []
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: false
        },
        layout: {
          padding: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0
          }
        },
        legend: {
          display: true,
          labels: {
            usePointStyle: true,
            fontSize: 10,
            padding: 15
          },
          position: 'bottom'
        },
        scales: {
          xAxes: [{
            offset: false,
            ticks: {
              display: true
            }
          }],
          yAxes: [{
            offset: false,
            ticks: {
              display: true
            }
          }]
        },
        tooltips: {
          mode: 'label',
          callbacks: {
            label: function (tooltipItem) {
              const price = tooltipItem.yLabel
              return new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(price)
            }
          }
        }
      }
    }
  },
  mounted () {
    const currDate = new Date()
    const fromDate = new Date().setMonth(currDate.getMonth() - 12)
    this.graphFilters = {
      dateFrom: new Date(fromDate).toISOString().substr(0, 7),
      dateTo: currDate.toISOString().substr(0, 7)
    }
    this.changePeriod()
  },
  methods: {
    changePeriod () {
      this.$nextTick(() => {
        this.fetchData()
      })
    },
    fetchData () {
      if (this.loading) return
      this.loading = true
      const graphFilters = { ...this.graphFilters, ...{} }
      API.DealAnalyze.priceChangeForComplex(this.complex.id, graphFilters)
        .then(({ data }) => {
          const { graph = [], labels = [] } = data?.data || {}
          // Данные графика
          const newChartData = {
            labels: graph.map((item) => {
              return item.month || '~'
            }),
            datasets: labels.map((label, labelIndex) => {
              return {
                label: label,
                lineTension: 0,
                fill: false,
                pointBackgroundColor: newsBuildingsColors[labelIndex],
                pointBorderColor: newsBuildingsColors[labelIndex],
                borderColor: newsBuildingsColors[labelIndex],
                data: graph?.map((item) => {
                  return item.price[labelIndex]
                }) || [],
                spanGaps: true // value: null - показать обрыв графика
              }
            })
          }
          this.$set(this, 'chartData', newChartData)
        })
        .catch((error) => {
          console.error('error', error)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped>

</style>
