import dictPermissionRules from '@/Permissions/dictRules'
export const routeKey = 'landing-admin'

export default {
  path: `${routeKey}`,
  name: routeKey,
  redirect: { name: `${routeKey}-list` },
  meta: { name: 'Лендинги ЖК', icon: 'advertising-complex-platform-id', ruleNamespace: dictPermissionRules.AppServicePermissionRulesLANDINGLandingAdminRule },
  component: () => import('@/views/ObjectStorage/Index.vue'),
  children: [
    {
      path: 'list',
      name: `${routeKey}-list`,
      meta: { name: 'Список лендингов ЖК', icon: 'advertising-complex-platform-id', hideInMenu: true },
      component: () => import('@/views/Ads/Landing/AdminLanding/List.vue')
    },
    {
      path: ':id/edit',
      name: `${routeKey}-edit`,
      meta: { name: 'Редактирование лендингов ЖК', icon: 'advertising-complex-platform-id', hideInMenu: true },
      component: () => import('@/views/Ads/Landing/AdminLanding/Edit.vue'),
      props: (route:any) => ({ id: route.params.id })
    },
    {
      path: 'create',
      name: `${routeKey}-create`,
      meta: { name: 'Добавление лендинга ЖК', icon: 'advertising-complex-platform-id', hideInMenu: true },
      component: () => import('@/views/Ads/Landing/AdminLanding/Edit.vue'),
      props: (route: any) => ({ id: route.params.id })
    }
  ]
}
