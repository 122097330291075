<template>
  <component
    :is="tag"
    v-bind="$props"
    class="tw-font-manrope tw-uppercase tw-font-bold tw-rounded-sm tw-flex tw-gap-[6px] tw-justify-center tw-items-center"
    :class="[textSizeClasses, colorClasses]"
    v-on="$listeners"
  >
    <slot name="icon" />
    <slot />
  </component>
</template>

<script>
export default {
  name: 'TwPanelActionButton',
  props: {
    tag: {
      type: String,
      default: 'button'
    },
    size: {
      type: String,
      default: 'xs'
    },
    color: {
      type: String,
      default: 'gray' // gray | blue | green | red | white
    },
    title: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    textSizeClasses () {
      if (this.size === 'xs') {
        return 'tw-text-main-xs tw-px-[12px] tw-py-[4px]'
      } else if (this.size === 'sm') {
        return 'tw-text-main-sm2 tw-px-[12px] tw-py-[6px]'
      } else if (this.size === 'md') {
        return 'tw-text-main-sm tw-px-[12px] tw-py-[8px]'
      } else if (this.size === 'lg') {
        return 'tw-text-main-md tw-px-[16px] tw-py-[10px]'
      }
      return 'tw-text-main-sm tw-px-[10px] tw-py-[4px]'
    },
    colorClasses () {
      if (this.color === 'blue') {
        return 'tw-text-white tw-bg-an-flat-dark-blue-link tw-rounded-sm tw-whitespace-nowrap tw-border tw-border-solid tw-border-an-flat-dark-blue-link enabled:hover:tw-bg-an-flat-dark-blue-link-active enabled:hover:tw-shadow disabled:tw-opacity-50'
      } else if (this.color === 'none') {
        return 'tw-rounded-sm tw-whitespace-nowrap enabled:hover:tw-shadow disabled:tw-opacity-50'
      } else if (this.color === 'green') {
        return 'tw-text-white tw-bg-an-flat-green-btn-bg tw-rounded-sm tw-whitespace-nowrap tw-border tw-border-solid tw-border-an-flat-green-btn-bg enabled:hover:tw-bg-an-flat-green enabled:hover:tw-shadow disabled:tw-opacity-50'
      } else if (this.color === 'red') {
        return 'tw-text-white tw-bg-an-flat-red-main tw-rounded-sm tw-whitespace-nowrap tw-border tw-border-solid tw-border-an-flat-red-main enabled:hover:tw-bg-an-flat-red-main/80 enabled:hover:tw-shadow disabled:tw-opacity-50'
      } else if (this.color === 'white') {
        return 'tw-text-an-flat-black-main tw-bg-white tw-rounded-sm tw-whitespace-nowrap tw-border tw-border-solid tw-border-[#D6DCE3] enabled:hover:tw-bg-[#D6DCE3] enabled:hover:tw-shadow disabled:tw-opacity-50 disabled:tw-border-[#D6DCE3]/50'
      }
      return 'tw-whitespace-nowrap tw-border tw-border-solid tw-border-an-flat-gray-3 enabled:hover:tw-bg-an-flat-bg-main enabled:hover:tw-shadow disabled:tw-opacity-50'
    }
  }
}
</script>
