<template>
  <div
    v-cloak
    v-if="info"
    class="tw-flex tw-gap-[12px] tw-flex-col"
  >
    <TwPanelContentBlockFieldView>
      <template #title>
        Компания
      </template>
      <div v-if="info.companyId">
        <ViewPanelLink
          :id="info.companyId"
          label="Компания"
          component="company-show"
        >
          <TwCompanyName :id="info.companyId" />
        </ViewPanelLink>
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Общая сумма
      </template>
      <div>
        {{ (info.amount).toLocaleString('ru') }} руб.
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Сформирован
      </template>
      <div>
        {{ info.formed ? 'Да':'Нет' }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        payed
      </template>
      <div>
        {{ info.payed ? 'Да':'Нет' }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        received
      </template>
      <div>
        {{ info.received ? 'Да':'Нет' }}
      </div>
    </TwPanelContentBlockFieldView>
  </div>
</template>

<script>
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink.vue'
import TwCompanyName from '@/components/tw-ui/other/TwCompanyName.vue'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'

export default {
  name: 'ActMainShow',
  components: { TwPanelContentBlockFieldView, ViewPanelLink, TwCompanyName },
  props: {
    info: {},
    dictionary: {}
  }
}
</script>
