<template>
  <v-sheet
    tabindex="0"
    :width="width"
    style="position: relative"
    class="pa-1 d-flex flex-column"
    @click.stop="dragZoneClick"
  >
    <v-sheet
      :title="title"
      :height="height"
      @dragenter="dragHandler"
      @dragleave="dragHandler"
      @dragover="dragHandler"
      @drop="dragHandler"
    >
      <input
        ref="upload"
        type="file"
        :value="null"
        :accept="accept"
        :disabled="disabled || processUpload"
        :multiple="multiple"
        style="display:none"
        @change="inputChange"
      >
      <div
        style="height: 100%;"
        class="d-flex align-center justify-center flex-column"
      >
        <template v-if="!processUpload">
          <template v-if="fileInfo && fileInfo.mime && fileInfo.mime.indexOf('image') !== -1">
            <div
              v-if="imageViewError"
              class="text-center"
            >
              <v-icon
                size="50"
                color="error"
              >
                fa-picture-o
              </v-icon>
              <div class="error--text body-2">
                <small>
                  Ошибка загрузки файла!
                </small>
              </div>
            </div>
            <v-img
              v-else
              :contain="isContain"
              :src="getFileResizeByKey(fileInfo, 'default')"
              aspect-ratio="1"
              width="100%"
              :height="height"
              class="cursor-pointer"
              @load="imageSuccessLoad"
              @error="imageErrorLoad"
              @click.stop="viewImage"
            />
          </template>
          <template v-else-if="fileInfo && fileInfo.mime && fileInfo.mime.indexOf('image') === -1">
            <v-icon
              color="success"
              size="40"
            >
              fa-file-text
            </v-icon>
            <div style="font-size: small">
              {{ fileInfo.name }}
            </div>
          </template>
          <div
            v-else
            class="text-center cursor-pointer"
            style="align-self: center; justify-self: flex-start"
          >
            <v-icon
              v-if="!dragover"
              :color="disabled || processUpload ? 'grey darken-2':'indigo darken-2'"
              size="40"
            >
              fa-cloud-upload
            </v-icon>
            <v-icon
              v-if="dragover"
              :color="disabled || processUpload ? 'grey darken-2':'indigo darken-2'"
              size="40"
            >
              fa-plus
            </v-icon>
          </div>
        </template>
        <template v-else>
          <v-progress-circular
            :size="40"
            :width="3"
            color="indigo"
            indeterminate
          />
        </template>
      </div>
    </v-sheet>
    <div class="text-left">
      <div
        class="mx-2"
        style="font-size: small"
      >
        <small>
          {{ label }}
        </small>
      </div>
    </div>
    <div
      class="d-flex justify-space-between"
      style="width: 100%;position: absolute; top: 0; right: 0"
    >
      <v-btn
        v-if="fileInfo"
        fab
        elevation="0"
        x-small
        color="white"
        class="ma-2"
        @click.stop="download"
      >
        <v-icon
          small
          color="primary"
        >
          fa-download
        </v-icon>
      </v-btn>
      <v-btn
        v-if="fileInfo"
        fab
        elevation="0"
        x-small
        color="white"
        class="ma-2"
        @click.stop="clear"
      >
        <v-icon
          small
          color="error"
        >
          fa-trash
        </v-icon>
      </v-btn>
    </div>
  </v-sheet>
</template>

<script>
import { getFileResizeByKey } from '@/plugins/helpers/fileHelpers'
import fileAPI from '@/Api/Crm/File'
import docAPI from '@/Api/Crm/Dictionary/General/Document'
import visionAPI from '@/Api/Vision/Image/index'

export default {
  name: 'SimpleFileUploadAndView',
  props: {
    value: {
      default: null
    },
    title: {
      type: String,
      default: 'Загрузка файла'
    },
    label: {
      type: String,
      default: 'Название документа'
    },
    fileType: {
      type: [String, null],
      default: null
    },
    width: {
      type: String,
      default: '100'
    },
    height: {
      type: String,
      default: '130'
    },
    accept: {
      type: String,
      default: 'image/png, image/jpeg, image/bmp, application/pdf, image/svg+xml'
    },
    multiple: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isContain: {
      type: Boolean,
      default: false
    },
    isPublic: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      fileInfo: null,
      dragover: false,
      processUpload: false,
      imageViewError: false
    }
  },
  watch: {
    value: {
      handler (newValue, OldValue) {
        if (!OldValue && newValue) {
          console.warn('Value INIT change:  ', newValue, OldValue)
          this.fetchValueInfo(newValue)
        }
      },
      immediate: true
    }
  },
  methods: {
    getFileResizeByKey: getFileResizeByKey,
    detectImage (file) {
      visionAPI.detectImageFields('passport', 1, file)
        .then(({ data }) => {
          console.warn('detectImage', data)
          this.$emit('detectedPassportFields', data || {})
        })
        .catch((error) => {
          console.error('detectImage', error)
        })
    },
    imageSuccessLoad () {
      this.imageViewError = false
    },
    imageErrorLoad () {
      this.imageViewError = true
    },
    download () {
      docAPI.download(this.fileInfo.url, this.fileInfo.name)
    },
    clear () {
      this.fileInfo = null
      this.$emit('input', 0)
    },
    dragZoneClick () {
      // console.warn('++', e)
      this.$refs.upload.click()
    },
    inputChange (e) {
      // console.warn('++', this.inputSelected, e)
      this.filesSelected(e.target.files)
    },
    dragHandler (e) {
      // console.warn('dragHandler', e)
      e.preventDefault()

      if (this.disabled) return

      if (e && e.type) {
        switch (e.type) {
          case 'dragenter':
            this.dragover = true
            break
          case 'dragleave':
            this.dragover = false
            break
          case 'dragover':
            this.dragover = true
            break
          case 'drop':
            if (e.dataTransfer) {
              this.filesSelected(e.dataTransfer.files)
            }
            break
        }
      }
    },
    filesSelected (files) {
      console.log('filesSelected ', files, files.length)
      this.dragover = false
      if (files && files.length) {
        this.upload(files[0])
      }
    },
    upload (file) {
      this.processUpload = true
      console.log(this.$options.name, 'upload', file)
      // если это первая траница паспорта - нужно отправить на распознание
      if (this.fileType === 'passport_1') {
        this.detectImage(file)
      }
      const formData = new FormData()
      formData.append('file', file)
      if (this.fileType) formData.append('type', this.fileType)
      if (this.isPublic) {
        return fileAPI.uploadPublic(formData)
          .then(({ data }) => {
            console.warn(this.$options.name, data)
            this.fileInfo = data.data
            console.log('link: ', data.data)
            this.$emit('input', data.data.id)
          })
          .catch((error) => {
            console.warn(this.$options.name, error)
          })
          .finally(() => {
            this.processUpload = false
          })
      } else {
        return fileAPI.upload(formData)
          .then(({ data }) => {
            console.warn(this.$options.name, data)
            this.fileInfo = data.data
            console.log('link: ', data.data)
            this.$emit('input', data.data.id)
          })
          .catch((error) => {
            console.warn(this.$options.name, error)
          })
          .finally(() => {
            this.processUpload = false
          })
      }
    },
    viewImage () {
      this.$store.dispatch('imageViewer/showImages', {
        files: [
          {
            path: this.fileInfo.url
          }
        ],
        startIndex: 0
      })
    },
    fetchValueInfo (id) {
      this.processUpload = true
      fileAPI.show(id)
        .then(({ data: { data } }) => {
          // console.warn(data)
          this.fileInfo = data
        })
        .catch((error) => {
          console.error({ error })
        })
        .finally(() => {
          this.processUpload = false
        })
    }
  }
}
</script>
