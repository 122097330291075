<template>
  <v-form ref="form">
    <v-select
      v-model="productsForm.loanPurpose"
      label="Цель кредита"
      class="pt-5"
      item-text="label"
      item-value="value"
      :items="loanPurpose"
      :disabled="loadingPrograms"
      :rules="requireRules"
      outlined
    />
    <div v-show="productsForm.loanPurpose">
      <v-row
        justify="center"
        dense
        class="my-2"
      >
        <v-col
          class=""
          cols="12"
          md="4"
          sm="6"
        >
          <combined-price-input
            v-model.number="productsForm.loanConditions.realtyCost"
            :disabled="loadingPrograms"
            track-color="blue accent-1"
            track-point-color="blue accent-3"
            track-fill-color="blue accent-4"
            :min="1"
            label="Стоимость недвижимости"
          />
        </v-col>
        <v-col
          class=""
          cols="12"
          md="4"
          sm="6"
        >
          <combined-price-input
            v-model.number="productsForm.loanConditions.deposit"
            track-color="blue accent-1"
            track-point-color="blue accent-3"
            track-fill-color="blue accent-4"
            :max="productsForm.loanConditions.realtyCost"
            :min="0"
            :disabled="!productsForm.loanConditions.realtyCost || loadingPrograms"
            percent-show
            label="Первоначальный взнос"
          />
        </v-col>
        <v-col
          class=""
          cols="12"
          md="4"
          sm="6"
        >
          <combined-price-input
            :disabled="loadingPrograms"
            :value="productsForm.loanConditions.term / 12"
            track-color="blue accent-1"
            track-point-color="blue accent-3"
            track-fill-color="blue accent-4"
            :max="30"
            :min="1"
            bottom-label-text="мес."
            label="Срок кредита"
            @input="productsForm.loanConditions.term = $event * 12"
          >
            <template #currentLabel>
              {{ $Global.textPlural(productsForm.loanConditions.term, 'year') }}
            </template>
          </combined-price-input>
        </v-col>
      </v-row>
      <v-row
        justify="center"
        dense
        class="my-2"
      >
        <v-col
          class=""
          cols="12"
          md="4"
          sm="6"
        >
          <v-text-field
            v-model="productsForm.borrowerData.birthday"
            class="mr-2"
            :disabled="loadingPrograms"
            type="date"
            dense
            outlined
            :rules="requireRules"
            label="Дата дата рождения"
          />
        </v-col>
        <v-col
          class=""
          cols="12"
          md="4"
          sm="6"
        >
          <v-select
            v-model="productsForm.borrowerData.marriageStatus"
            label="Семейное положение"
            item-text="label"
            item-value="value"
            :items="marriageStatus"
            :disabled="loadingPrograms"
            :rules="requireRules"
            dense
            outlined
          />
        </v-col>
        <v-col
          class=""
          cols="12"
          md="4"
          sm="6"
        >
          <v-select
            v-model="productsForm.borrowerData.jobIncomeType"
            label="Подтверждение дохода"
            item-text="label"
            item-value="value"
            :items="jobIncomeType"
            :rules="requireRules"
            :disabled="loadingPrograms"
            dense
            attach=""
            outlined
          />
        </v-col>
      </v-row>
      <v-row
        justify="center"
        dense
        class="my-2"
      >
        <v-col
          class=""
          cols="12"
        >
          <v-list color="transparent">
            <!--            <v-subheader>Программы:</v-subheader>-->
            <ACircleLoader
              v-if="loadingPrograms"
              class="mx-auto py-2"
              :size="50"
              :border-width="3"
            />
            <template v-else-if="programsList.length">
              <v-list-item
                v-for="(program, pIndex) in programsList"
                :key="pIndex"
                class="text-wrap"
                :disabled="loadingPrograms || program.type !== 'CALCULATED'"
                :title="programTypeInfo(program.type) && programTypeInfo(program.type)"
              >
                <v-list-item-avatar
                  v-if="programTypeInfo(program.type)"
                  :title="programTypeInfo(program.type).text"
                >
                  <v-icon
                    v-if="program.type === 'CALCULATED'"
                    class="blue lighten-1"
                    color="white"
                    dark
                  >
                    fa-check
                  </v-icon>
                  <v-icon
                    v-else
                    class="red lighten-1"
                    color="white"
                    dark
                  >
                    fa-times
                  </v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>
                    {{ program.name }}
                    <span
                      v-if="program.rate"
                      class="success--text"
                    >
                      от {{ program.rate }}%
                    </span>
                  </v-list-item-title>

                  <v-list-item-subtitle v-if="program.type !== 'CALCULATED' && programTypeInfo(program.type)">
                    <small class="error--text">
                      {{ programTypeInfo(program.type).text }}
                    </small>
                  </v-list-item-subtitle>

                  <v-list-item-subtitle>
                    <span
                      v-for="discount in program.discounts"
                      :key="discount"
                    >
                      <v-chip x-small>
                        {{ discountsInfo(discount).label }}
                      </v-chip>
                    </span>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action
                  v-if="programTypeInfo(program.type)"
                  :title="programTypeInfo(program.type).text"
                >
                  <v-btn
                    small
                    :disabled="program.type !== 'CALCULATED'"
                    color="primary"
                    @click="changeSelectedProgram(program)"
                  >
                    Расчитать
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </template>
            <v-list-item
              v-else
              disabled
              dense
            >
              <v-list-item-content class="justify-center">
                <p class="text-center text-h6">
                  Нет подходящих программ
                </p>
                <p class="text-center">
                  Измените параметры подбора
                </p>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
      <div class="error--text">
        <div
          v-for="(error, index) in selectErrors"
          :key="index"
        >
          <v-alert
            text
            dense
            prominent
            type="error"
          >
            {{ error }}
          </v-alert>
        </div>
      </div>
    </div>
  </v-form>
</template>

<script>
import { VuetifyInputValidatorRules } from '@/plugins/helpers/ValidationUtils'
import domClickProductAPI from '@/Api/Crm/Mortgage/Docmclick/Products'
import { loanPurpose, marriageStatus, jobIncomeType, programsType, discounts, borrowerCategory, comparisonMarriageStatus } from './DomClickDict'
import CombinedPriceInput from '@/components/input/price/CombinedPriceInput'
import lodashDebounce from 'lodash/debounce'
import ACircleLoader from '@/components/filters/afilter/fields/aicons/ACircleLoader'

import initAPI from '@/Api/Crm/Mortgage/Docmclick'

export default {
  name: 'DomClickProductsSelect',
  components: { ACircleLoader, CombinedPriceInput },
  props: {
    dealProgramId: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      requireRules: VuetifyInputValidatorRules.requireRules,
      loadingPrograms: false,
      productsForm: {
        loanPurpose: 'REALTY',
        loanConditions: {
          realtyCost: 5000000,
          deposit: 1500000,
          term: 120
        },
        borrowerData: {
          birthday: null,
          jobIncomeType: null,
          marriageStatus: null
        }
      },
      selectErrors: [],
      programsList: [],
      loanPurpose,
      marriageStatus,
      jobIncomeType,
      discounts,
      borrowerCategory
    }
  },
  methods: {
    programTypeInfo (val) {
      return programsType.find(t => t.value === val)
    },
    discountsInfo (val) {
      return discounts.find(t => t.value === val)
    },
    // Получение списка программм - продуктов
    onChangeProductForm () {
      console.log('onChangeProductForm')
      if (this.$refs?.form?.validate()) {
        if (this.loadingPrograms) return
        this.updateProgramsList([])
        this.loadingPrograms = true
        this.selectErrors = []
        domClickProductAPI.select(this.productsForm).then(({ data }) => {
          if (data.errors) {
            this.selectErrors = Array.isArray(data?.errors) && data?.errors.length ? data?.errors : [data?.message || 'Ошибка подбора программ']
          } else {
            this.updateProgramsList(data?.data?.programs || [])
          }
        }).catch((error) => {
          console.error(error)
        }).finally(() => {
          this.loadingPrograms = false
        })
      }
    },
    // Запись списка программм - продуктов
    updateProgramsList (data) {
      this.$set(this, 'programsList', data)
    },
    changeSelectedProgram (programData) {
      console.warn('changeSelectedProgram', programData)
      this.$emit('changeSelectedProgram', {
        ...programData,
        term: this.productsForm.loanConditions?.term
      })
    },
    debounceProductForm: lodashDebounce(function () {
      this.onChangeProductForm()
    }, 500)
  },
  watch: {
    productsForm: {
      handler () {
        this.debounceProductForm()
      },
      deep: true,
      immediate: true
    }
  },
  mounted () {
    // Инициализация данных основоного замщика для заполнения полей формы
    initAPI.create(this.dealProgramId).then(({ data }) => {
      console.warn('initAPI select', data)
      // mortgageDeal: {
      //   firstSum: 800000
      //   sum: 4000000
      //   term: 10
      // }
      // loanConditions: {
      //   realtyCost: 5000000,
      //     deposit: 1500000,
      //     term: 120
      // },
      this.productsForm.borrowerData.birthday = data?.data?.birthDate || null
      this.productsForm.borrowerData.marriageStatus = comparisonMarriageStatus(data?.data?.familyStatus?.value || null)

      this.productsForm.loanConditions = {
        ...(data?.data?.sum) && {
          realtyCost: data?.data?.sum
        },
        ...(data?.data?.firstSum) && {
          deposit: data?.data?.firstSum
        },
        ...(data?.data?.term) && {
          term: data?.data?.term * 12
        }
      }
    }).catch((error) => {
      console.error(error)
    })
  }
}
</script>

<style scoped>
.v-list-item__content .v-list-item__title {
  white-space: inherit;
}
</style>
