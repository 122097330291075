<template>
  <div class="tw-font-manrope">
    <TwCircleLoader v-if="loading" />
    <div
      v-else-if="data"
      class="tw-flex tw-gap-[30px] tw-items-center tw-bg-white tw-rounded-2xl tw-px-[13px] tw-py-[16px] tw-mb-[42px]"
    >
      <div class="tw-border-2 tw-border-solid tw-border-an-flat-bg-main tw-h-[120px] tw-w-[120px] tw-rounded-2xl">
        <img
          v-if="data.plan && data.plan.genPlanFile"
          style="object-fit: contain;width: 100%;height: 100%;"
          :src="getFileResizeByKey(data.plan.genPlanFile, 'default')"
          alt="img"
        >
      </div>
      <div class="tw-grow tw-mr-[20px]">
        <div class="tw-flex tw-justify-between tw-mb-[18px]">
          <div>
            <div class="tw-text-main-sm tw-text-an-flat-disabled-text">
              Жилой комплекс
            </div>
            <ViewPanelLink
              v-if="data.complexId"
              :id="data.complexId"
              label="ЖК"
              class="tw-font-semibold tw-text-main-md"
              component="realty-complex-show-page"
            >
              <RealtyComplexName :id="data.complexId" />
            </ViewPanelLink>
          </div>
          <div class="tw-text-h4">
            {{ (dealInfo.price || 0).toLocaleString('ru') }}  ₽
          </div>
        </div>
        <div class="tw-whitespace-nowrap tw-text-main-sm tw-flex tw-flex-wrap tw-gap-x-[15px] tw-gap-y-[10px]">
          <div class="tw-flex-1">
            <span class="tw-text-an-flat-disabled-text">Комнат:</span> <strong v-if="data.plan">{{ data.plan.studio ? 'Студия':data.plan.roomCount || '~' }}</strong><strong v-else>~</strong>
          </div>
          <div class="tw-flex-1">
            <span class="tw-text-an-flat-disabled-text">Номер дома:</span> <strong>{{ data.buildingNum || `~` }}</strong>
          </div>
          <div class="tw-flex-1">
            <span class="tw-text-an-flat-disabled-text">Номер квартиры:</span> <strong>{{ data.apNum || `~` }}</strong>
          </div>
        </div>
        <div class="tw-mt-[6px] tw-text-main-sm tw-flex tw-flex-wrap tw-gap-x-[15px] tw-gap-y-[10px]">
          <div class="tw-flex-1">
            <span class="tw-text-an-flat-disabled-text">Площадь,м²:</span> <strong>{{ (data.plan && data.plan.totalArea) || `~` }}</strong>
          </div>
          <div class="tw-flex-1">
            <span class="tw-text-an-flat-disabled-text">Этаж:</span> <strong>{{ data.floorNum || `~` }}{{ data.floorCount ? `/${data.floorCount}` : '' }}</strong>
          </div>
          <div class="tw-flex-1">
            <span class="tw-text-an-flat-disabled-text">Срок сдачи:</span> <strong class="">{{ data.completed ? 'Сдан' : data.completeDate || `~` }}</strong>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="tw-text-center tw-p-[30px]"
    >
      Нет данных
    </div>
  </div>
</template>

<script>
import { API } from '@/Api/ObjectStorage'
import CardMixin from '@/mixins/CardMixin'
import { getFileResizeByKey } from '@/plugins/helpers/fileHelpers'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import RealtyComplexName from '@/components/other/complex/RealtyComplexName.vue'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink.vue'

export default {
  name: 'RealtyObjectShortInfoBlock',
  components: { ViewPanelLink, RealtyComplexName, TwCircleLoader },
  mixins: [CardMixin],
  props: {
    id: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      API: API.NbSelection,
      showMethodKey: 'showObject'
    }
  },
  computed: {
    dealInfo () {
      return this.data?.deal || {}
    }
  },
  methods: {
    getFileResizeByKey
  }
}
</script>

<style scoped>

</style>
