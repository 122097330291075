<template>
  <div>
    <button
      class="tw-w-full tw-rounded-md tw-border tw-border-solid tw-border-an-flat-red-main tw-bg-white tw-px-[26px] tw-py-[18px] tw-font-bold tw-text-[14px] tw-text-an-flat-red-main tw-flex tw-gap-[12px] tw-justify-center tw-items-center enabled:hover:tw-drop-shadow-lg disabled:tw-opacity-50"
      @click.stop="visible = true"
    >
      Отменить запрос
    </button>
    <TwDialog
      :value="visible"
      max-width="600px"
      @input="visible = $event"
    >
      <template #header>
        <div>
          <div class="tw-text-h4 tw-font-semibold">
            Подтверждение
          </div>
        </div>
      </template>
      <template #body>
        <div>
          <TwTextarea
            v-model="form.comment"
            rows="5"
            class="tw-mt-[12px]"
            placeholder="Причина отмены"
            :error-text="!form.comment ? '* Обязательно для заполнения':''"
            :show-label="true"
          />
          <div class="tw-mt-[15px] tw-flex tw-gap-[10px]">
            <button
              :disabled="!form.comment"
              class="tw-grow tw-px-[15px] tw-py-[7px] tw-bg-an-flat-green-btn-bg tw-rounded-sm tw-text-white disabled:tw-opacity-50"
              @click.prevent="onCancel"
            >
              Изменить статус
            </button>
            <button
              class="tw-w-[100px] tw-px-[15px] tw-py-[7px] tw-bg-an-flat-gray-4 tw-rounded-sm tw-text-white enabled:hover:tw-bg-an-flat-red-main"
              @click.prevent="visible = false"
            >
              Отмена
            </button>
          </div>
        </div>
      </template>
    </TwDialog>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import TwDialog from '@/components/tw-ui/modal/TwDialog.vue'
import TwTextarea from '@/components/tw-ui/ui-kit/input/TwTextarea.vue'
import API from '@/Api/Rtd/RequestToDeveloper/Request'

export default defineComponent({
  name: 'RequestToDeveloperCancel',
  components: { TwTextarea, TwDialog },
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      visible: false,
      form: { comment: '' }
    }
  },
  methods: {
    onCancel () {
      API.cancel(this.id, this.form).then(() => {
        this.visible = false
        this.$emit('success')
      })
    }
  }
})
</script>

<style scoped>

</style>
