import { RouteKeys } from '@/router/types'
import dictPermissionRules from '@/Permissions/dictRules'

export default {
  path: RouteKeys.companyPeriodResultManagement,
  name: RouteKeys.companyPeriodResultManagement,
  redirect: { name: `${RouteKeys.companyPeriodResultManagement}-list` },
  meta: { name: 'Закрытие периода', icon: 'closing-the-period', ruleNamespace: dictPermissionRules.AppServicePermissionRulesCRMDealsNbDealNbCompanyPeriodResultManagementRule },
  component: () => import('@/views/ObjectStorage/Index.vue'),
  children: [
    {
      path: 'list',
      name: `${RouteKeys.companyPeriodResultManagement}-list`,
      meta: { name: 'Закрытие периода', icon: 'closing-the-period', hideInMenu: true },
      component: () => import('@/views/Newbuildings/CompanyPeriodResultManagement/List.vue')
    }
  ]
}
