import { RouteKeys } from '@/router/types'

export default {
  path: `/${RouteKeys.knowledgeBase}`,
  name: RouteKeys.knowledgeBase,
  redirect: { name: `${RouteKeys.knowledgeBase}-list` },
  meta: { name: 'База знаний', icon: 'knowledge-base' },
  component: () => import('@/views/KnowledgeBase/index.vue'),
  children: [
    {
      path: 'list',
      name: `${RouteKeys.knowledgeBase}-list`,
      meta: { name: 'База знаний', icon: 'knowledge-base', hideInMenu: true },
      component: () => import('@/views/KnowledgeBase/ListSections.vue'),
      props: true
    },
    {
      path: 'create',
      name: `${RouteKeys.knowledgeBase}-create`,
      meta: { name: 'Создание раздела', icon: 'knowledge-base', hideInMenu: true },
      component: () => import('@/views/KnowledgeBase/Section/EditSection.vue'),
      props: true
    },
    {
      path: ':id/edit',
      name: `${RouteKeys.knowledgeBase}-edit`,
      meta: { name: 'Редактирование раздела', icon: 'knowledge-base', hideInMenu: true },
      component: () => import('@/views/KnowledgeBase/Section/EditSection.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    },
    {
      path: ':id/creating-article',
      name: `${RouteKeys.knowledgeBase}-creating-article`,
      meta: { name: 'База знаний. Создание статьи', icon: 'knowledge-base', hideInMenu: true },
      component: () => import('@/views/KnowledgeBase/Article/EditArticle.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    },
    {
      path: ':id/update-article',
      name: `${RouteKeys.knowledgeBase}-update-article`,
      meta: { name: 'База знаний. Редактирование статьи', icon: 'knowledge-base', hideInMenu: true },
      component: () => import('@/views/KnowledgeBase/Article/EditArticle.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    },
    {
      path: ':sectionId/show/:articleId',
      name: `${RouteKeys.knowledgeBase}-show`,
      meta: { name: 'База знаний', icon: 'knowledge-base', hideInMenu: true },
      component: () => import('@/views/KnowledgeBase/ShowInfo/ShowInfoSection.vue'),
      props: (route:any) => ({ sectionId: Number(route.params.sectionId), articleId: Number(route.params.articleId) })
    }
  ]
}
