<template>
  <div
    v-cloak
    v-if="info"
    class="tw-flex tw-gap-[12px] tw-flex-col"
  >
    <TwPanelContentBlockFieldView
      v-if="info.name"
      :horizontal="hasHorizontal"
    >
      <template #title>
        Название
      </template>
      <div>
        {{ info.name }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView
      v-if="info.createdAt"
      :horizontal="hasHorizontal"
    >
      <template #title>
        Дата создания
      </template>
      <div>
        {{ $Global.DateFormat.DateTimeShortMonthLong(info.createdAt) }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView
      v-if="info.status"
      :horizontal="hasHorizontal"
    >
      <template #title>
        Статус
      </template>
      <div class="tw-flex tw-gap-[6px] tw-items-center">
        <div
          class="tw-w-[12px] tw-h-[12px] tw-rounded-full"
          :style="{ backgroundColor: info.status.color }"
        />
        {{ info.status.name }}
      </div>
    </TwPanelContentBlockFieldView>
    <LeadPersonShow :info="info.person" />
    <TwPanelContentBlockFieldView
      v-if="info.person"
      :horizontal="hasHorizontal"
    >
      <template #title>
        Доп. контакты
      </template>
      <div>
        <div v-if="!Array.isArray(info.person.additionalContacts) || (Array.isArray(info.person.additionalContacts) && !info.person.additionalContacts.length)">
          отсутствуют
        </div>
        <template v-else>
          <div
            v-for="(info, ac_index) in info.person.additionalContacts"
            :key="ac_index+'-ac'"
            class="tw-flex"
          >
            <span class="tw-text-an-flat-disabled-text tw-mr-1">{{ info.type && (info.type.label || info.type) }}: </span>
            <span>{{ info.contact }}</span>
          </div>
        </template>
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView
      v-if="info.responsibleUserId"
      :horizontal="hasHorizontal"
    >
      <template #title>
        Ответственный
      </template>
      <div>
        <TwPanelContentUserInfo
          :user-id="info.responsibleUserId"
          :warn-is-not-work-phone="true"
        />
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView
      v-if="info.comment"
      :horizontal="hasHorizontal"
    >
      <template #title>
        Комментарий
      </template>
      <div v-if="info.comment">
        <ReadMoreExpander
          :is-nl-trigger="true"
          :text="info.comment"
          :is-clean-html="false"
          :less-length="150"
        />
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView
      v-if="info.source"
      :horizontal="hasHorizontal"
    >
      <template #title>
        Источник
      </template>
      <div>
        {{ info.source.name }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView
      v-if="info.source"
      :horizontal="hasHorizontal"
    >
      <template #title>
        UTM метки
      </template>
      <div>
        <div v-if="info.utm">
          <div
            v-for="(item, key) of info.utm"
            :key="key"
          >
            <span class="tw-text-an-flat-disabled-text">
              {{ key }}:
            </span>
            <span>
              {{ item }}
            </span>
          </div>
        </div>
        <div v-else>
          Отсутствуют
        </div>
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView
      v-if="info.creatorId"
      :horizontal="hasHorizontal"
    >
      <template #title>
        Создал
      </template>
      <div>
        <ViewPanelLink
          :id="info.creatorId"
          label="Пользователь"
          component="employee-show"
        >
          <EmployeeName :id="info.creatorId" />
        </ViewPanelLink>
      </div>
    </TwPanelContentBlockFieldView>
  </div>
</template>

<script>
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink'
import EmployeeName from '@/components/other/employee/EmployeeName'
import LeadPersonShow from '@/views/Lead/main/LeadPersonShow'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'
import ReadMoreExpander from '@/components/other/text/ReadMoreExpander.vue'
import TwPanelContentUserInfo from '@/components/tw-ui/panels/TwPanelContentUserInfo.vue'

export default {
  name: 'LeadMainShow',
  components: { TwPanelContentUserInfo, ReadMoreExpander, TwPanelContentBlockFieldView, LeadPersonShow, EmployeeName, ViewPanelLink },
  props: {
    info: {},
    dictionary: {}
  },
  data () {
    return {
      hasHorizontal: true
    }
  }
}
</script>
