<template>
  <TwPanelContent class="tw-mt-[20px]">
    <div class="tw-flex-1">
      <TwPanelContentBlock class="tw-mb-[6px]">
        <template #title>
          Информация
        </template>
        <div class="tw-flex tw-gap-[12px] tw-flex-col">
          <TwPanelContentBlockFieldView>
            <template #title>
              LABEL
            </template>
            <div class="tw-font-semibold">
              TODO
            </div>
          </TwPanelContentBlockFieldView>
          <TwPanelContentBlockFieldView>
            <template #title>
              LABEL 2
            </template>
            <div class="tw-font-semibold">
              TODO 2
            </div>
          </TwPanelContentBlockFieldView>
        </div>
      </TwPanelContentBlock>
      <TwPanelContentBlock class="tw-mb-[6px]">
        <template #title>
          Доп. информация
        </template>
        <template #default>
          <div class="tw-flex tw-gap-[12px] tw-flex-col">
            <TwPanelContentBlockFieldView>
              <template #title>
                LABEL 3
              </template>
              <div class="tw-font-semibold">
                TODO 4
              </div>
            </TwPanelContentBlockFieldView>
            <TwPanelContentBlockFieldView>
              <template #title>
                LABEL 5
              </template>
              <div class="tw-font-semibold">
                TODO 6
              </div>
            </TwPanelContentBlockFieldView>
          </div>
        </template>
      </TwPanelContentBlock>
    </div>
    <div class="tw-flex-1 tw-text-an-flat-red-main">
      <TwPanelContentBlock
        class="tw-mb-[6px]"
        :content-classes="''"
      >
        <template #title>
          Доп. информация 2
        </template>
        <template #default>
          <div class="tw-flex tw-gap-[12px] tw-flex-col">
            <TwPanelContentBlockFieldView>
              <template #title>
                LABEL 3
              </template>
              <div class="tw-font-semibold">
                TODO 4
              </div>
            </TwPanelContentBlockFieldView>
            <TwPanelContentBlockFieldView>
              <template #title>
                LABEL 5
              </template>
              <div class="tw-font-semibold">
                TODO 6
              </div>
            </TwPanelContentBlockFieldView>
          </div>
        </template>
      </TwPanelContentBlock>
    </div>
    <div class="tw-w-full">
      <TwPanelContentBlock class="tw-w-full tw-mb-[6px]">
        <template #title>
          DEBUG
        </template>
        <div>
          <pre>
              {{ dealData }}
            </pre>
        </div>
      </TwPanelContentBlock>
    </div>
  </TwPanelContent>
</template>

<script>
import { defineComponent } from 'vue'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'
import TwPanelContent from '@/components/tw-ui/panels/TwPanelContent.vue'

export default defineComponent({
  name: 'CustomExampleTab',
  components: { TwPanelContent, TwPanelContentBlockFieldView, TwPanelContentBlock },
  props: {
    dealData: {
      type: Object,
      default: null
    }
  }
})
</script>

<style scoped>

</style>
