<template>
  <div class="tw-w-full tw-h-full tw-font-manrope tw-font-medium tw-text-[24px] tw-text-center tw-p-[10vh]">
    Страница отсутствует или находится в разработке
    <div class="tw-p-5 tw-font-normal tw-text-[14px] tw-text-center tw-text-an-flat-grey-4">
      Напишите в техническую поддержку если считатаете что это ошибка
    </div>
    <div class="tw-text-center">
      <button
        :disabled="false"
        type="button"
        class="tw-bg-an-flat-blue-light tw-text-an-flat-blue-dark-2 tw-p-[15px] tw-rounded-[10px] tw-text-center tw-max-w-[300px] tw-w-full tw-font-bold tw-text-[14px] hover:tw-bg-an-flat-blue-dark-2 hover:tw-text-white disabled:tw-opacity-50"
        @click.stop="$router.push('/support')"
      >
        Техническая поддержка
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound'
}
</script>
