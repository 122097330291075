<template>
  <div>
    <div
      v-if="processing"
      class="tw-flex tw-p-[20px]"
    >
      <TwCircleLoader class="tw-m-auto" />
    </div>
    <div v-else>
      <div class="tw-mt-[10px] tw-flex tw-justify-end">
        <TwPanelActionButton
          color="blue"
          :disabled="processing"
          @click.prevent="fetchDisplayData"
        >
          Обновить
        </TwPanelActionButton>
      </div>
      <div v-if="Array.isArray(data) && data.length">
        <div
          v-for="(item, index) in data"
          :key="index"
          class="tw-mt-[20px]"
        >
          <!-- Building Header -->
          <div class="tw-text-h5 tw-flex tw-flex-wrap tw-gap-[10px] tw-mb-[10px]">
            <div v-if="item.complex">
              <span class="tw-text-an-flat-gray-4">ЖК:</span> {{ item.complex }}
            </div>
            <div v-if="item.building">
              <span class="tw-text-an-flat-gray-4">Строение:</span> {{ item.building }}
            </div>
            <div v-if="item.apartments">
              <span class="tw-text-an-flat-gray-4">Кол-во квартир:</span> {{ Array.isArray(item.apartments) ? item.apartments.length : 0 }}
            </div>
          </div>
          <TwTableSimple
            :headers="headers"
            :items="item.apartments"
            body-max-height="500px"
          >
            <template #item.price="{ item }">
              {{ (item.price || 0).toLocaleString('ru') }}
            </template>
          </TwTableSimple>
        </div>
      </div>
      <div
        v-else
        class="tw-text-center tw-text-an-flat-disabled-text tw-p-[30px]"
      >
        Нет данных
      </div>
      <div
        v-if="errorData"
        class="tw-text-an-flat-red-main tw-p-[10px]"
      >
        {{ errorData }}
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { API } from '@/Api/BuilderImporter'
import TwPanelActionButton from '@/components/tw-ui/panels/TwPanelActionButton.vue'
import TwTableSimple from '@/components/tw-ui/table/TwTableSimple.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'

export default {
  name: 'BuilderFileLoaderDisplayBlock',
  components: { TwCircleLoader, TwPanelActionButton, TwTableSimple },
  props: {
    info: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      statusDict: {
        sale: 'В продаже',
        sold: 'Продано',
        reserved: 'Зарезервировано'
      },
      processing: false,
      data: [],
      errorData: null,
      headers: [
        {
          dataKey: 'number',
          text: 'Номер',
          align: 'right',
          sortable: true,
          maxWidth: '120px'
        },
        {
          dataKey: 'status',
          text: 'Статус',
          align: 'center',
          sortable: true
        },
        {
          dataKey: 'price',
          text: 'Цена, руб.',
          align: 'right',
          sortable: true
        },
        {
          dataKey: 'roomCount',
          text: 'Кол-во комнат',
          align: 'left',
          sortable: true
        },
        {
          dataKey: 'floor',
          text: 'Этаж',
          align: 'left',
          sortable: true
        },
        {
          dataKey: 'area',
          text: 'Площадь, кв.м.',
          align: 'left',
          sortable: true
        }
      ]
    }
  },
  computed: {
    builderFileId () {
      return this?.info?.id || null
    }
  },
  mounted () {
    this.fetchDisplayData()
  },
  methods: {
    fetchDisplayData () {
      this.processing = true
      this.errorData = null
      API.ImportBuilderFileLoader.display({
        fileId: this.builderFileId
      }).then(({ data }) => {
        this.preparationData(data?.data)
      }).catch((error) => {
        this.$set(this, 'data', [])
        this.errorData = error?.response?.data || null
      }).finally(() => {
        this.processing = false
      })
    },
    preparationData (data) {
      for (const index in data) {
        const newList = data[index]?.apartments?.map((item) => {
          item.number = isNaN(Number(item.num)) ? item.num : Number(item.num)
          return item
        })
        data[index].apartments = newList
      }
      this.$set(this, 'data', data || [])
    }
  }
}
</script>

<style scoped>

</style>
