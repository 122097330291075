<template>
  <div>
    <template v-if="item">
      <div
        v-for="(key, index) in viewFields"
        :key="index"
      >
        <small class="text-muted">{{ key | fieldNameTranslate('rates') }}</small>
        <div>
          <template v-if="key.indexOf('sum') !== -1">
            {{ item[key] && item[key].toLocaleString() }} руб.
          </template>
          <template v-else-if="key.indexOf('rate') !== -1 ||key.indexOf('discount') !== -1 || key.indexOf('firstSum') !== -1">
            {{ (item[key] && item[key].toLocaleString()) || 0 }}%
          </template>
          <template v-else-if="key.indexOf('term') !== -1">
            {{ item[key] }} {{ $Global.textPlural(item[key] || 0, 'month') }}
          </template>
          <template v-else-if="typeof item[key] === 'boolean'">
            {{ item[key] ? 'Да' : 'Нет' }}
          </template>
          <template v-else-if="key === 'complexesId'">
            <template v-if="Array.isArray(item[key])">
              <div
                v-for="(complexId, index) in item[key]"
                :key="index"
              >
                <ViewPanelLink
                  :id="complexId"
                  label="ЖК"
                  component="realty-complex-show-page"
                >
                  <small>
                    <RealtyComplexName :id="complexId" />
                  </small>
                </ViewPanelLink>
              </div>
            </template>
          </template>
          <template v-else>
            {{ item[key] }}
          </template>
        </div>
      </div>
    </template>
    <template v-else>
      Нет информации о ставке
    </template>
  </div>
</template>

<script>
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink'
import RealtyComplexName from '@/components/other/complex/RealtyComplexName'

const subsidizedFields = ['rate', 'firstSumMin', 'firstSumMax', 'complexesId', 'termMax', 'subsidized']
const baseFields = ['rate', 'discountFlat', 'discountElectronRegistration', 'firstSumMin', 'firstSumMax', 'subsidized']

const fieldDict = {
  rates: {
    id: 'ID',
    rate: 'Ставка Банк',
    discountElectronRegistration: 'Дисконт эл. регистрации',
    discountFlat: 'Дисконт Флэт',
    firstSumMin: 'Первоначальный взнос от',
    firstSumMax: 'Первоначальный взнос до',
    complexesId: 'Жилые комплексы',
    termMax: 'Срок действия ставки',
    subsidized: 'Субсидированная ставка'
  }
}
export default {
  name: 'MortgageProgramRateShow',
  components: { RealtyComplexName, ViewPanelLink },
  filters: {
    fieldNameTranslate: function (fieldName, dictType) {
      // console.warn('fieldNameTranslate ', fieldName, dictType)
      if (!dictType || !fieldName) return 'Error'
      return fieldDict[dictType][fieldName] || fieldName || 'Название отсутствует в словаре'
    }
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    viewFields () {
      return this.item.subsidized ? subsidizedFields : baseFields
    }
  }
}
</script>

<style scoped>

</style>
