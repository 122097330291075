<template>
  <div>
    <!-- Список файлов -->
    <v-list
      v-if="Array.isArray(files) && files.length"
      dense
      outlined
      class="pa-0 mb-2"
      :style="{maxHeight: fileListMaxHeight, overflowY: 'auto' }"
    >
      <template v-for="(file, index) in files">
        <v-list-item
          :key="index"
        >
          <v-list-item-avatar tile>
            <v-progress-circular
              v-if="file.loading || !file.fileServer"
              :size="20"
              :width="2"
              color="primary"
              indeterminate
            />
            <v-icon
              v-else-if="file.error"
              size="30"
              color="error"
            >
              fa-exclamation-triangle
            </v-icon>
            <template v-else-if="file.fileServer.mime && file.fileServer.mime.indexOf('image') !== -1">
              <v-img
                :src="getFileResizeByKey(file.fileServer, 'default')"
                @click="viewImages(files, index)"
                @error="imageLoadError(index)"
              >
                <template #placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-icon>fa-file-text</v-icon>
                  </v-row>
                </template>
              </v-img>
            </template>
            <v-icon v-else>
              fa-file-text
            </v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>
              {{ (file.fileInput && file.fileInput.name) || (file.fileServer && file.fileServer.name) || 'Нет названия' }}
              <small
                v-if="file.fileServer"
                class="ml-2 text-muted"
              >
                {{ $Global.formatBytes(file.fileServer.size) }}
              </small>
            </v-list-item-title>
            <v-list-item-subtitle
              v-if="file.error"
              class="error--text"
            >
              Ошибка получения файла!
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action-text>
            <v-btn
              v-if="file.fileServer && file.fileServer.name"
              icon
              @click="download(file.fileServer)"
            >
              <v-icon
                small
                color="primary"
              >
                fa-download
              </v-icon>
            </v-btn>
            <v-btn
              icon
              @click="removeFile(index)"
            >
              <v-icon
                small
                color="error"
              >
                fa-trash
              </v-icon>
            </v-btn>
          </v-list-item-action-text>
        </v-list-item>
      </template>
    </v-list>
    <div
      v-else-if="noDataText"
      class="mb-5 text-muted text-center"
    >
      {{ noDataText }}
    </div>
    <!-- Область загрузки -->
    <FileDrop
      :height="height"
      :disabled="uploadProgress || disabled"
      :loading="uploadProgress"
      multiple
      accept="*"
      @filesSelected="filesSelected"
    />
  </div>
</template>

<script>
import { getFileResizeByKey } from '@/plugins/helpers/fileHelpers'
import API from '@/Api/Email/File'
import FileUploadMixin from '@/mixins/FileUploadMixin'

export default {
  name: 'UploadEmailFileList',
  mixins: [FileUploadMixin],
  data () {
    return {
      API: this.api || API
    }
  },
  methods: {
    getFileResizeByKey: getFileResizeByKey
  }
}
</script>
