<template>
  <div
    v-cloak
    v-if="info"
    class="py-2 body-2"
  >
    <v-card
      v-if="info"
      flat
      tile
      class="my-2"
    >
      <v-card-subtitle class="d-flex font-weight-medium">
        <EmailFromToShow
          :email-folder="info.emailFolder"
          :email-config-contact="info.emailConfigContact"
          :contacts="info.contacts"
        />
        <v-spacer />
        <div class="text-right">
          <div>
            {{ $Global.DateFormat.DateTimeShort(info.dateMailBox || info.updatedAt) }}
          </div>
          <div
            v-if="calcFilesSize"
            class="text-capitalize"
          >
            Сообщение: {{ $Global.formatBytes(calcFilesSize) }}
          </div>
        </div>
      </v-card-subtitle>
      <v-divider />
      <v-card-text>
        <div
          class="email-body"
          v-html="info.body"
        />
        <v-divider class="my-3" />
        <div class="tw-mb-[15px]">
          <EmailFilesAttached :files="info.files" />
        </div>
        <div>
          <span>Файлы клиентов</span>
          <ShowClientsFileList
            :file-client="[]"
            :value="info.crmFileId"
            no-data-text="Нет файлов"
            height="100px"
          />
        </div>
      </v-card-text>
    </v-card>
    <v-card
      v-if="(info.status && info.status.value === 'got') && (info.emailFolder.type && info.emailFolder.type.value === 'inbox')"
      flat
      tile
      class="my-2"
    >
      <v-card-text
        v-if="!fastReplyAll"
        class="panel-view-link"
        @click="fastReplyAll = true"
      >
        Написать быстрый ответ всем участникам переписки
      </v-card-text>
      <EmailMainReply
        v-if="fastReplyAll"
        :reply-id="info.id"
        :reply-from="info"
        @hide="fastReplyAll = false"
      />
    </v-card>
  </div>
</template>

<script>
import EmailFromToShow from '@/views/Email/main/EmailFromToShow'
import EmailMainReply from '@/views/Email/main/EmailMainReply'
import EmailFilesAttached from '@/views/Email/main/EmailFilesAttached'
import ShowClientsFileList from '@/views/Email/file/ShowClientsFileList.vue'

export default {
  name: 'EmailMainShow',
  components: { ShowClientsFileList, EmailFilesAttached, EmailMainReply, EmailFromToShow },
  props: {
    info: {},
    dictionary: {}
  },
  data () {
    return {
      fastReplyAll: false
    }
  },
  computed: {
    calcFilesSize () {
      return Array.isArray(this.info?.files) && this.info.files.length ? this.info.files.reduce(function (accumulate, item) {
        return accumulate + item.size || 0
      }, 0) : 0
    }
  }
}
</script>
