interface IService {
  [key: string]: {
    key: string;
    apiUrl: string | null; // /service-status не запрашивается
    host: string;
    useReports?: boolean;
    useWidgets: boolean;
  }
}

// определим сервисы из env
export const SERVICES: IService = Object.freeze({
  BACKEND: {
    key: 'BACKEND',
    apiUrl: process.env.VUE_APP_BACKEND_HOST ? `${process.env.VUE_APP_BACKEND_HOST}/api/v1` : null,
    host: process.env.VUE_APP_BACKEND_HOST ? process.env.VUE_APP_BACKEND_HOST : null,
    useReports: true,
    useWidgets: true
  },
  AUTH: {
    key: 'AUTH',
    apiUrl: process.env.VUE_APP_AUTH_HOST ? `${process.env.VUE_APP_AUTH_HOST}/api/v1` : null,
    host: process.env.VUE_APP_AUTH_HOST ? process.env.VUE_APP_AUTH_HOST : null,
    useReports: true,
    useWidgets: true
  },
  ROI: {
    key: 'ROI',
    apiUrl: process.env.VUE_APP_ROI_HOST ? `${process.env.VUE_APP_ROI_HOST}/api/v1` : null,
    host: process.env.VUE_APP_ROI_HOST ? process.env.VUE_APP_ROI_HOST : null,
    useReports: true,
    useWidgets: false
  },
  TELEPHONY: {
    key: 'TELEPHONY',
    apiUrl: process.env.VUE_APP_TELEPHONY_HOST ? `${process.env.VUE_APP_TELEPHONY_HOST}/api/v1` : null,
    host: process.env.VUE_APP_TELEPHONY_HOST ? process.env.VUE_APP_TELEPHONY_HOST : null,
    useReports: true,
    useWidgets: true
  },
  VISION: {
    key: 'VISION',
    apiUrl: process.env.VUE_APP_COMPUTER_VISION_HOST ? `${process.env.VUE_APP_COMPUTER_VISION_HOST}/api/v1` : null,
    host: process.env.VUE_APP_COMPUTER_VISION_HOST ? process.env.VUE_APP_COMPUTER_VISION_HOST : null,
    useWidgets: false
  },
  LOGGER: {
    key: 'LOGGER',
    apiUrl: process.env.VUE_APP_LOGGER_HOST ? `${process.env.VUE_APP_LOGGER_HOST}/api/v1` : null,
    host: process.env.VUE_APP_LOGGER_HOST ? process.env.VUE_APP_LOGGER_HOST : null,
    useWidgets: false
  },
  SITE: {
    key: 'SITE',
    apiUrl: process.env.VUE_APP_SITE_HOST ? `${process.env.VUE_APP_SITE_HOST}/api/v2` : null,
    host: process.env.VUE_APP_SITE_HOST ? process.env.VUE_APP_SITE_HOST : null,
    useWidgets: false
  },
  SUPPORT: {
    key: 'SUPPORT',
    apiUrl: process.env.VUE_APP_SUPPORT_HOST ? `${process.env.VUE_APP_SUPPORT_HOST}/api/v1` : null,
    host: process.env.VUE_APP_SUPPORT_HOST ? process.env.VUE_APP_SUPPORT_HOST : null,
    useWidgets: true
  },
  REALTY_MINER: {
    key: 'REALTY_MINER',
    apiUrl: process.env.VUE_APP_REALTY_MINER_HOST ? `${process.env.VUE_APP_REALTY_MINER_HOST}/api/v1` : null,
    host: process.env.VUE_APP_REALTY_MINER_HOST ? process.env.VUE_APP_REALTY_MINER_HOST : null,
    useReports: true,
    useWidgets: false
  },
  OS: {
    key: 'OS',
    apiUrl: process.env.VUE_APP_OS_HOST ? `${process.env.VUE_APP_OS_HOST}/api/v1` : null,
    host: process.env.VUE_APP_OS_HOST ? process.env.VUE_APP_OS_HOST : null,
    useReports: true,
    useWidgets: false
  },
  MESSENGER: {
    key: 'MESSENGER',
    apiUrl: process.env.VUE_APP_MESSENGER_HOST ? `${process.env.VUE_APP_MESSENGER_HOST}/api/v1` : null,
    host: process.env.VUE_APP_MESSENGER_HOST ? process.env.VUE_APP_MESSENGER_HOST : null,
    useReports: true,
    useWidgets: true
  },
  PASSPORT: {
    key: 'PASSPORT',
    apiUrl: process.env.VUE_APP_PASSPORT_HOST ? `${process.env.VUE_APP_PASSPORT_HOST}/api/v1` : null,
    host: process.env.VUE_APP_PASSPORT_HOST ? process.env.VUE_APP_PASSPORT_HOST : null,
    useReports: true,
    useWidgets: false
  },
  MODEM_HUB: {
    key: 'MODEM_HUB',
    apiUrl: process.env.VUE_APP_MODEM_HUB_HOST ? `${process.env.VUE_APP_MODEM_HUB_HOST}/api/v1` : null,
    host: process.env.VUE_APP_MODEM_HUB_HOST ? process.env.VUE_APP_MODEM_HUB_HOST : null,
    useWidgets: false
  },
  SEARCH: {
    key: 'SEARCH',
    apiUrl: process.env.VUE_APP_SEARCH_HOST ? `${process.env.VUE_APP_SEARCH_HOST}/api/v1` : null,
    host: process.env.VUE_APP_SEARCH_HOST ? process.env.VUE_APP_SEARCH_HOST : null,
    useWidgets: false
  },
  DOC: {
    key: 'DOC',
    apiUrl: process.env.VUE_APP_DOC_HOST ? `${process.env.VUE_APP_DOC_HOST}/api/v1` : null,
    host: process.env.VUE_APP_DOC_HOST ? process.env.VUE_APP_DOC_HOST : null,
    useWidgets: false
  },
  ADS: {
    key: 'ADS',
    apiUrl: process.env.VUE_APP_ADS_HOST ? `${process.env.VUE_APP_ADS_HOST}/api/v1` : null,
    host: process.env.VUE_APP_ADS_HOST ? process.env.VUE_APP_ADS_HOST : null,
    useReports: true,
    useWidgets: false
  },
  BX_PROXY_RABBIT: {
    key: 'BX_PROXY_RABBIT',
    apiUrl: process.env.VUE_APP_BX_PROXY_RABBIT_HOST ? `${process.env.VUE_APP_BX_PROXY_RABBIT_HOST}/api/v1` : null,
    host: process.env.VUE_APP_BX_PROXY_RABBIT_HOST ? process.env.VUE_APP_BX_PROXY_RABBIT_HOST : null,
    useReports: true,
    useWidgets: false
  },
  BUILDER_IMPORTER: {
    key: 'BUILDER_IMPORTER',
    apiUrl: process.env.VUE_APP_BUILDER_IMPORTER_HOST ? `${process.env.VUE_APP_BUILDER_IMPORTER_HOST}/api/v1` : null,
    host: process.env.VUE_APP_BUILDER_IMPORTER_HOST ? process.env.VUE_APP_BUILDER_IMPORTER_HOST : null,
    useReports: true,
    useWidgets: true
  },
  REVIEW: {
    key: 'REVIEW',
    apiUrl: process.env.VUE_APP_REVIEW_HOST ? `${process.env.VUE_APP_REVIEW_HOST}/api/v1` : null,
    host: process.env.VUE_APP_REVIEW_HOST ? process.env.VUE_APP_REVIEW_HOST : null,
    useReports: true,
    useWidgets: true
  },
  EMAIL: {
    key: 'EMAIL',
    apiUrl: process.env.VUE_APP_EMAIL_HOST ? `${process.env.VUE_APP_EMAIL_HOST}/api/v1` : null,
    host: process.env.VUE_APP_EMAIL_HOST ? process.env.VUE_APP_EMAIL_HOST : null,
    useReports: true,
    useWidgets: true
  },
  FIAS: {
    key: 'FIAS',
    apiUrl: process.env.VUE_APP_FIAS_HOST ? `${process.env.VUE_APP_FIAS_HOST}/api/v1` : null,
    host: process.env.VUE_APP_FIAS_HOST ? process.env.VUE_APP_FIAS_HOST : null,
    useReports: true,
    useWidgets: false
  },
  ACCOUNTING: {
    key: 'ACCOUNTING',
    apiUrl: process.env.VUE_APP_ACCOUNTING_HOST ? `${process.env.VUE_APP_ACCOUNTING_HOST}/api/v1` : null,
    host: process.env.VUE_APP_ACCOUNTING_HOST ? process.env.VUE_APP_ACCOUNTING_HOST : null,
    useReports: true,
    useWidgets: true
  },
  MORTGAGE: {
    key: 'MORTGAGE',
    apiUrl: process.env.VUE_APP_MORTGAGE_HOST ? `${process.env.VUE_APP_MORTGAGE_HOST}/api/v1` : null,
    host: process.env.VUE_APP_MORTGAGE_HOST ? process.env.VUE_APP_MORTGAGE_HOST : null,
    useReports: true,
    useWidgets: false
  },
  KANT_LANDING: {
    key: 'KANT_LANDING',
    apiUrl: 'https://landing.kant.one/api/v1',
    host: 'https://landing.kant.one',
    useReports: true,
    useWidgets: false
  },
  KANT_INTEGRATION: {
    key: 'KANT_INTEGRATION',
    apiUrl: 'https://integration.kant.one/api/v1',
    host: 'https://integration.kant.one',
    useReports: true,
    useWidgets: false
  },
  KANT_RTD: {
    key: 'KANT_RTD',
    apiUrl: process.env.VUE_APP_KANT_RTD_HOST ? `${process.env.VUE_APP_KANT_RTD_HOST}/api/v1` : null,
    host: process.env.VUE_APP_KANT_RTD_HOST ? process.env.VUE_APP_KANT_RTD_HOST : null,
    useReports: true,
    useWidgets: true
  },
  DOC_CHECK: {
    key: 'DOC_CHECK',
    apiUrl: process.env.VUE_APP_DOC_CHECK_HOST ? `${process.env.VUE_APP_DOC_CHECK_HOST}/api/v1` : null,
    host: process.env.VUE_APP_DOC_CHECK_HOST ? process.env.VUE_APP_DOC_CHECK_HOST : null,
    useReports: true,
    useWidgets: true
  },
  COURSE: {
    key: 'COURSE',
    apiUrl: process.env.VUE_APP_COURSE_HOST ? `${process.env.VUE_APP_COURSE_HOST}/api/v1` : null,
    host: process.env.VUE_APP_COURSE_HOST ? process.env.VUE_APP_COURSE_HOST : null,
    useReports: true,
    useWidgets: true
  },
  ANALYTICS: {
    key: 'ANALYTICS',
    apiUrl: process.env.VUE_APP_ANALYTICS_HOST ? `${process.env.VUE_APP_ANALYTICS_HOST}/api/v1` : null,
    host: process.env.VUE_APP_ANALYTICS_HOST ? process.env.VUE_APP_ANALYTICS_HOST : null,
    useReports: false,
    useWidgets: false
  },
  BITRIX: {
    key: 'BITRIX',
    apiUrl: null,
    host: process.env.VUE_APP_BITRIX_HOST ? process.env.VUE_APP_BITRIX_HOST : null,
    useReports: false,
    useWidgets: false
  }
})
