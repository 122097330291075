<template>
  <div class="tw-relative">
    <div class="tw-relative tw-border tw-border-solid tw-rounded-sm tw-overflow-auto">
      <div
        id="map"
        class="map"
        :style="{ height: mapHeight }"
      />
      <div id="plot" />
    </div>
    <div class="tw-absolute tw-top-[12px] tw-left-[12px]">
      <PkkSearchByKadNum @changeCenter="onChangeCenterFromKadNum" />
    </div>
    <div class="tw-flex tw-gap-[12px] tw-mt-[12px]">
      <TwPanelActionButton
        :disabled="actionsDisabled.startDraw"
        color="blue"
        @click.stop="onStartDrawing"
      >
        Задать полигон
      </TwPanelActionButton>
      <TwPanelActionButton
        :disabled="actionsDisabled.endDraw"
        color="blue"
        @click.stop="onStopDrawing"
      >
        Завершить редактирование полигона
      </TwPanelActionButton>
      <TwPanelActionButton
        :disabled="actionsDisabled.clear"
        color="red"
        @click.stop="onClear"
      >
        Удалить полигон
      </TwPanelActionButton>
      <div class="tw-flex-1" />
      <TwPanelActionButton
        color="green"
        @click.stop="onApply"
      >
        Применить полигон
      </TwPanelActionButton>
    </div>
  </div>
</template>

<script>
import Map from 'ol/Map'
import View from 'ol/View'
import { transform } from 'ol/proj'
import Feature from 'ol/Feature'
import { Polygon, Point } from 'ol/geom'
import { createEmpty as createEmptyBounds, extend as extendBounds } from 'ol/extent'
import {
  mapConfig,
  buildSubLayer,
  buildBaseOSM,
  coordsAdapter,
  buildPkkLayer,
  drawingPolygon,
  iconLayer
} from './PkkUtils'
import TwPanelActionButton from '@/components/tw-ui/panels/TwPanelActionButton.vue'
import PkkSearchByKadNum from '@/views/Newbuildings/Editor/OtherComponents/Map/pkkSearchByKadNum.vue'

export default {
  name: 'PkkMarkingMapHelper',
  components: { PkkSearchByKadNum, TwPanelActionButton },
  props: {
    // текущее редактируемое здание - координаты и базовая информация
    value: {
      type: Array,
      default: () => {
        return []
      }
    },
    // Список иных полигонов - не для редактирования
    subPolygons: {
      type: Array,
      default: () => {
        return []
      }
    },
    zoom: {
      type: Number,
      default: mapConfig.defaultMapZoom
    },
    center: {
      type: Array,
      default: () => {
        return mapConfig.defaultCenter
      }
    },
    mapHeight: {
      type: String,
      default: '450px'
    }
  },
  data () {
    return {
      actionsDisabled: {
        startDraw: false,
        endDraw: true,
        clear: true
      },
      drawInteraction: null,
      drawingSource: null,
      drawingLayer: null,
      iconSource: null,
      iconLayer: null,
      mapInstance: null,
      currentDrawingPolygonPoints: []
    }
  },
  watch: {
    center (newCoords) {
      console.warn('center', newCoords)
      this.setCenter(newCoords)
    }
  },
  mounted () {
    // Слой для карты
    const baseOSM = buildBaseOSM()
    // Слой для реестра
    const pkkLayer = buildPkkLayer()
    // Слой для рисования
    this.drawingSource = drawingPolygon.buildSource()
    this.drawingLayer = drawingPolygon.buildLayer(this.drawingSource)
    this.drawInteraction = drawingPolygon.buildInteraction(this.drawingSource)
    // Слой для доп контента - в текущем варианте - иконка местоположения
    this.iconSource = iconLayer.buildSource()
    this.iconLayer = iconLayer.buildLayer(this.iconSource)

    this.mapInstance = null
    this.mapInstance = new Map({
      target: 'map',
      controls: [],
      layers: [
        // добавляются ниже
      ],
      view: new View({
        center: transform(coordsAdapter.YaToOsm(this.center || mapConfig.defaultCenter), 'EPSG:4326', 'EPSG:3857'), // [49.13419396693736, 55.79170889147585] [55.78969, 49.13699], // proj.fromLonLat([36.6549, 50.5938]),
        zoom: mapConfig.defaultMapZoom,
        maxZoom: 20
        // minZoom: 15
      })
    })
    this.mapInstance.addLayer(baseOSM)
    this.mapInstance.addLayer(pkkLayer)
    this.mapInstance.addLayer(this.drawingLayer)
    this.mapInstance.addLayer(this.iconLayer)
    if (Array.isArray(this.subPolygons) && this.subPolygons.length) {
      const subLayer = buildSubLayer(this.subPolygons)
      this.mapInstance.addLayer(subLayer)
    }
    this.onInitDraw()
  },
  beforeDestroy () {
    this.mapInstance = null
  },
  methods: {
    onChangeCenterFromKadNum (eventData) {
      // Сыылка для получения выделенного объекта на карте - сложно - можно сделать как будет время
      // https://pkk.rosreestr.ru/arcgis/rest/services/PKK6/CadastreSelected/MapServer/export?bbox=5478329.796928899%2C7526371.8905847585%2C5478661.223106129%2C7526664.501083572&bboxSR=102100&imageSR=102100&size=1110%2C980&dpi=96&format=png32&transparent=true&layers=show%3A6%2C7%2C8%2C9&layerDefs={"6"%3A"ID %3D '16%3A50%3A140401%3A751'"%2C"7"%3A"ID %3D '16%3A50%3A140401%3A751'"%2C"8"%3A"ID %3D '16%3A50%3A140401%3A751'"%2C"9"%3A"ID %3D '16%3A50%3A140401%3A751'"}&f=image
      console.warn('eventData', eventData)
      this.iconSource.clear()
      // устанавливаю иконку по найденным координатам
      const iconFeature = new Feature(new Point(eventData))
      this.iconSource.addFeature(iconFeature)
      this.mapInstance.getView().setCenter(eventData)
    },
    onApply () {
      console.warn('onApply', this.currentDrawingPolygonPoints)
      if (Array.isArray(this.currentDrawingPolygonPoints) && this.currentDrawingPolygonPoints.length) {
        const adaptedCoords = this.currentDrawingPolygonPoints.map((coords) => {
          return coordsAdapter.OsmToYa(transform(coords, 'EPSG:3857', 'EPSG:4326'))
        })
        console.warn('----', adaptedCoords)
        this.$emit('input', adaptedCoords)
      } else {
      //   sad
        this.$emit('input', [])
      }
    },
    // инит входящих координат редактируемого полигона
    onPresetPolygon () {
      // this.drawingSource.clear()
      if (Array.isArray(this.value) && this.value.length) {
        this.currentDrawingPolygonPoints = this.value.map((coords) => {
          return transform(coordsAdapter.YaToOsm(coords), 'EPSG:4326', 'EPSG:3857')
        })
        const feature = new Feature({
          geometry: new Polygon([this.currentDrawingPolygonPoints])
        })
        this.drawingSource.addFeature(feature)
      }

      this.actionsDisabled = {
        startDraw: !!this.currentDrawingPolygonPoints.length,
        endDraw: true,
        clear: !this.currentDrawingPolygonPoints.length
      }

      this.$nextTick(() => {
        this.fitAllVectorsToMap()
      })
    },
    onInitDraw () {
      // TODO DRAWING
      // https://openlayers.org/en/latest/apidoc/module-ol_interaction_Draw-Draw.html
      this.drawInteraction.setActive(false)
      // TODO инит координат
      this.mapInstance.addInteraction(this.drawInteraction)
      this.drawInteraction.on('drawend', (event) => {
        const drawingCoords = event.feature.getGeometry().getCoordinates()
        console.warn('DRAW ON drawend: ', drawingCoords)
        this.currentDrawingPolygonPoints = Array.isArray(drawingCoords[0]) && drawingCoords[0].length > 3 ? drawingCoords[0] : []
        this.onStopDrawing()
      })
      this.drawInteraction.on('drawabort', (event) => {
        console.warn('DRAW ON drawabort: ', event)
        const drawingCoords = event.feature.getGeometry().getCoordinates()
        console.warn('DRAW ON drawabort: ', drawingCoords)
        this.onStopDrawing()
      })
      this.drawInteraction.on('drawstart', (event) => {
        console.warn('DRAW ON drawstart: ', event)
      })
      this.onPresetPolygon()
    },
    onStartDrawing () {
      console.warn('onStartDrawing', this.drawInteraction)
      this.drawInteraction.setActive(true)
      this.actionsDisabled = {
        startDraw: true,
        endDraw: false,
        clear: true
      }
    },
    onStopDrawing () {
      console.warn('onStopDrawing', this.drawInteraction, this.mapInstance, this.currentDrawingPolygonPoints)
      this.drawInteraction.finishDrawing()
      this.drawInteraction.setActive(false)
      this.actionsDisabled = {
        startDraw: !!this.currentDrawingPolygonPoints.length,
        endDraw: true,
        clear: !this.currentDrawingPolygonPoints.length
      }
      if (!this.currentDrawingPolygonPoints.length) {
        this.onClear()
      }
    },
    onClear () {
      console.warn('onClear')
      this.drawingSource.clear()
      this.currentDrawingPolygonPoints = []

      this.actionsDisabled = {
        startDraw: !!this.currentDrawingPolygonPoints.length,
        endDraw: true,
        clear: !this.currentDrawingPolygonPoints.length
      }
    },
    setCenter (centeredCoords) {
      console.warn(this.mapInstance.getView().getZoom())
      this.mapInstance.getView().setCenter(transform(coordsAdapter.YaToOsm(centeredCoords), 'EPSG:4326', 'EPSG:3857'))
    },
    fitAllVectorsToMap () {
      // заготовка пустого объекта бокса
      const bounds = createEmptyBounds()
      // выборка слоёв
      this.mapInstance.getLayers().forEach(function (layer) {
        // только для словев поддреживающих метод определения бокса(Vector)
        if (layer?.getSource().getExtent) {
          // Расширение пустышки координатами каждого слоя
          extendBounds(bounds, layer.getSource().getExtent())
        }
      })
      // проверяю на валидность границ бокса
      if (!bounds.some(i => i === Infinity)) {
        // Заполняю карту объектами со своев
        this.mapInstance.getView().fit(bounds, {
          callback: () => {
            console.warn('Map fit getExtent')
          },
          padding: [20, 20, 20, 20],
          size: this.mapInstance.getSize()
        })
      }
    }
  }
}
</script>

<style>
#plot {
  pointer-events: none;
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.bar {
  pointer-events: auto;
  fill: #AFAFB9;
}

.bar.selected {
  fill: green;
}

.tip {
  position: absolute;
  background: black;
  color: white;
  padding: 6px;
  font-size: 12px;
  border-radius: 4px;
  margin-bottom: 10px;
  display: none;
  opacity: 0;
}
</style>
