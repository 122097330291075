<template>
  <div>
    <div
      v-if="loading"
      class="tw-text-center"
    >
      <TwCircleLoader />
    </div>
    <div v-else-if="info">
      <div class="tw-grid xl:tw-grid-cols-2 md:tw-grid-cols-1 tw-gap-y-[12px] tw-gap-x-[26px] tw-w-full">
        <TwPanelContentBlockFieldView
          class="tw-min-w-[250px]"
          no-data-text="Не указано"
        >
          <template #title>
            Бюджет
          </template>
          <div v-if="info.budget">
            {{ (Number(info.budget) || 0).toLocaleString('ru') }} руб.
          </div>
        </TwPanelContentBlockFieldView>

        <TwPanelContentBlockFieldView
          class="tw-min-w-[250px]"
          no-data-text="Не указано"
        >
          <template #title>
            Форма оплаты
          </template>
          <div v-if="info.payType">
            {{ info.payType.label || info.payType }}
          </div>
        </TwPanelContentBlockFieldView>

        <TwPanelContentBlockFieldView
          class="tw-min-w-[250px]"
          no-data-text="Не указано"
        >
          <template #title>
            Наличные, сумма
          </template>
          <div v-if="info.paySum">
            {{ (Number(info.paySum) || 0).toLocaleString('ru') }} руб.
          </div>
        </TwPanelContentBlockFieldView>

        <TwPanelContentBlockFieldView>
          <template #title>
            Банк
          </template>
          <div v-if="info.bankId">
            <ViewPanelLink
              :id="info.bankId"
              label="Компания"
              component="company-show"
            >
              <TwCompanyName :id="info.bankId" />
            </ViewPanelLink>
          </div>
        </TwPanelContentBlockFieldView>

        <TwPanelContentBlockFieldView
          v-if="(info.payType.value === 'mortgage' || info.payType.value === 'mortgageNotApproved')"
          no-data-text="Не указано"
        >
          <template #title>
            Программа ипотеки
          </template>
          <div v-if="resultMortgageProgram && resultMortgageProgram.value && resultMortgageProgram.label">
            {{ resultMortgageProgram.label }}
          </div>
        </TwPanelContentBlockFieldView>

        <TwPanelContentBlockFieldView
          class="tw-min-w-[250px]"
          no-data-text="Не указано"
        >
          <template #title>
            Цель строительства
          </template>
          <div v-if="info.purpose">
            {{ info.purpose.label || info.purpose }}
          </div>
        </TwPanelContentBlockFieldView>

        <TwPanelContentBlockFieldView
          class="tw-min-w-[250px]"
          no-data-text="Не указано"
        >
          <template #title>
            Наличие участка
          </template>
          <div v-if="typeof info.hasLand === 'boolean'">
            {{ info.hasLand ? 'Есть' : 'Нет' }}
          </div>
        </TwPanelContentBlockFieldView>

        <TwPanelContentBlockFieldView
          class="tw-min-w-[250px]"
          no-data-text="Не указано"
        >
          <template #title>
            Кол-во этажей
          </template>
          <div v-if="info.floors">
            {{ info.floors.label || info.floors }}
          </div>
        </TwPanelContentBlockFieldView>

        <TwPanelContentBlockFieldView
          class="tw-min-w-[250px]"
          no-data-text="Не указано"
        >
          <template #title>
            Кол-во комнат
          </template>
          <div v-if="info.rooms">
            {{ info.rooms }}
          </div>
        </TwPanelContentBlockFieldView>

        <TwPanelContentBlockFieldView
          class="tw-min-w-[250px]"
          no-data-text="Не указано"
        >
          <template #title>
            Площадь дома
          </template>
          <div v-if="info.area">
            {{ info.area.label || info.area }} м²
          </div>
        </TwPanelContentBlockFieldView>

        <TwPanelContentBlockFieldView
          class="tw-min-w-[250px]"
          no-data-text="Не указано"
        >
          <template #title>
            Площадь участка
          </template>
          <div v-if="typeof info.landAreaFrom === 'number' && info.landAreaTo">
            от {{ info.landAreaFrom }} до {{ info.landAreaTo }} сот.
          </div>
        </TwPanelContentBlockFieldView>

        <TwPanelContentBlockFieldView
          class="tw-min-w-[250px]"
          no-data-text="Не указано"
        >
          <template #title>
            Кол-во санузлов
          </template>
          <div v-if="info.bathroomCount">
            {{ info.bathroomCount.label }}
          </div>
        </TwPanelContentBlockFieldView>

        <TwPanelContentBlockFieldView
          class="tw-min-w-[250px]"
          no-data-text="Не указано"
        >
          <template #title>
            Дополнительно
          </template>
          <div v-if="additionalArray && additionalArray.length">
            <div
              v-for="(item, index) in additionalArray"
              :key="index"
            >
              <div v-if="item.label">
                {{ item.label }}
              </div>
            </div>
          </div>
          <div v-else>
            Не удалось вывести данные
          </div>
        </TwPanelContentBlockFieldView>

        <TwPanelContentBlockFieldView
          class="tw-min-w-[250px]"
          no-data-text="Не указано"
        >
          <template #title>
            Крыша
          </template>
          <div v-if="info.roof">
            {{ info.roof.label }}
          </div>
        </TwPanelContentBlockFieldView>

        <TwPanelContentBlockFieldView
          v-if="info.hasLand"
          class="tw-min-w-[250px]"
          no-data-text="Не указано"
        >
          <template #title>
            Наличие ГПЗУ
          </template>
          <div v-if="typeof info.hasGpzu === 'boolean'">
            {{ info.hasGpzu ? 'Есть' : 'Нет' }}
          </div>
        </TwPanelContentBlockFieldView>

        <TwPanelContentBlockFieldView
          v-if="!info.hasLand"
          class="tw-min-w-[250px]"
          no-data-text="Не указано"
        >
          <template #title>
            Стоимость участка
          </template>
          <div v-if="info.landPaySum">
            до {{ (Number(info.landPaySum) || 0).toLocaleString('ru') }} руб.
          </div>
        </TwPanelContentBlockFieldView>

        <TwPanelContentBlockFieldView
          v-if="!info.hasLand"
          class="tw-min-w-[250px]"
          no-data-text="Не указано"
        >
          <template #title>
            Требования к участку
          </template>
          <div v-if="info.requirementsToLand">
            {{ info.requirementsToLand }}
          </div>
        </TwPanelContentBlockFieldView>
      </div>

      <TwPanelContentBlockFieldView
        class="tw-mt-[26px]"
        no-data-text="Не указана"
      >
        <template #title>
          Точка на карте
        </template>
        <div v-if="info.mapPoint">
          <YandexMapRealtyObject
            class="tw-border tw-border-solid"
            draw-mode="POINT"
            :view-only="true"
            is-preset-default-coords
            :coords="$Global.geoPointArrayToObject(info.mapPoint)"
            style="width: 100%; height: 300px;"
          />
        </div>
      </TwPanelContentBlockFieldView>
    </div>
    <div
      v-else
      class="tw-py-[24px] tw-text-an-flat-red-main tw-text-center"
    >
      {{ errorText }}
    </div>
  </div>
</template>

<script>
import QuestionnaireAPI from '@/Api/Crm/Deal/HouseDevelop/Questionnaire'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'
import YandexMapRealtyObject from '@/components/Map/YandexMapRealtyObject.vue'
import TwCompanyName from '@/components/tw-ui/other/TwCompanyName.vue'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink.vue'

export default {
  name: 'DealHouseDevelopQuestionnaireShow',
  components: {
    ViewPanelLink,
    TwCompanyName,
    YandexMapRealtyObject,
    TwPanelContentBlockFieldView,
    TwCircleLoader
  },
  props: {
    dealId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      errorText: 'Ошибка',
      loading: false,
      info: null,
      dict: null
    }
  },
  computed: {
    additionalArray () {
      if (this.info && this.info.additionalBuildings && this.dict && this.dict.additionalBuildings) {
        return this.dict.additionalBuildings.filter((item) => {
          return this.info.additionalBuildings.includes(item.value)
        })
      }
      return []
    },
    resultMortgageProgram () {
      return this.dict.mortgageProgram.find(item => Number(item.value) === Number(this.info.mortgageProgramId)) || null
      // if (this.info.mortgageProgramId) {
      //   const program = this.dict.mortgageProgram.find(item => Number(item.value) === Number(this.info.mortgageProgramId)) || null
      //   return program ? program : null
      // } else {
      //   return ''
      // }
    }
  },
  mounted () {
    this.fetchInfo()
  },
  methods: {
    fetchInfo () {
      this.loading = true
      QuestionnaireAPI.show(this.dealId)
        .then(({ data: result }) => {
          console.warn('fetchInfo', result)
          this.$set(this, 'info', result?.data?.id ? result?.data : null)
          this.$set(this, 'dict', result?.dictionary || null)
          this.$emit('changeQuestionnaireExist', !!result?.data?.id)
        })
        .catch((error) => {
          console.error('fetchInfo', error.response?.status, error)
          this.$set(this, 'info', null)
          this.$set(this, 'dict', null)
          if (error.response?.status === 404) {
            this.errorText = 'Не заполнена'
            this.$emit('changeQuestionnaireExist', false)
          } else {
            this.errorText = 'Ошибка' + error?.message
          }
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped>

</style>
