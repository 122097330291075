const defaultFetchConfig = {
  headers: {
    'Content-Type': 'application/json'
  },
  referrerPolicy: 'strict-origin-when-cross-origin',
  body: null,
  method: 'GET',
  mode: 'cors'
}
export default function (url = '', config = {}) {
  // eslint-disable-next-line no-param-reassign
  config = {
    ...defaultFetchConfig,
    ...config
  }
  return fetch(url, config)
}
