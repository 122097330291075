import { AxiosResponse } from 'axios'
import HTTP from '@/Api/http'
import { AuthAxiosDefaultConfig } from '@/Api/Review'

const path = '/deal-review-data'

export default {
  store (reviewId: number, data: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.post(`${path}/${reviewId}`, data, $config)
  },
  update (reviewDataId: number, data: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.put(`${path}/${reviewDataId}`, data, $config)
  }
}
