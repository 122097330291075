<template>
  <div class="item-wrapper mt-5">
    <div
      :style="{backgroundColor: listColors[variant].bg}"
      class="item-icon"
    >
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.4461 9.12627C12.0732 8.56801 12.5122 7.83863 12.7054 7.0337C12.8987 6.22878 12.8372 5.38588 12.5291 4.61549C12.221 3.8451 11.6806 3.18317 10.9787 2.71645C10.2769 2.24973 9.44632 2 8.59591 2C7.7455 2 6.91493 2.24973 6.21307 2.71645C5.51121 3.18317 4.9708 3.8451 4.66269 4.61549C4.35457 5.38588 4.29311 6.22878 4.48638 7.0337C4.67965 7.83863 5.11862 8.56801 5.74575 9.12627C4.34491 9.6786 3.14523 10.6242 2.29992 11.8424C1.45461 13.0605 1.00204 14.4959 1 15.9652C1 16.1828 1.08892 16.3914 1.2472 16.5453C1.40548 16.6991 1.62015 16.7855 1.84399 16.7855H15.3478C15.5717 16.7855 15.7863 16.6991 15.9446 16.5453C16.1029 16.3914 16.1918 16.1828 16.1918 15.9652C16.1898 14.4959 15.7372 13.0605 14.8919 11.8424C14.0466 10.6242 12.8469 9.6786 11.4461 9.12627V9.12627ZM6.06394 6.12146C6.06394 5.63473 6.21244 5.15894 6.49065 4.75424C6.76887 4.34954 7.16431 4.03411 7.62696 3.84785C8.08962 3.66159 8.59872 3.61285 9.08987 3.70781C9.58102 3.80277 10.0322 4.03715 10.3863 4.38132C10.7404 4.72548 10.9815 5.16398 11.0792 5.64136C11.1769 6.11873 11.1268 6.61354 10.9351 7.06322C10.7435 7.5129 10.419 7.89725 10.0026 8.16766C9.58621 8.43807 9.09668 8.5824 8.59591 8.5824C7.92439 8.5824 7.28037 8.32312 6.80553 7.86161C6.3307 7.40009 6.06394 6.77414 6.06394 6.12146ZM2.7479 15.1449C2.94985 13.7773 3.65232 12.5264 4.72648 11.6215C5.80063 10.7166 7.17444 10.2185 8.59591 10.2185C10.0174 10.2185 11.3912 10.7166 12.4653 11.6215C13.5395 12.5264 14.242 13.7773 14.4439 15.1449H2.7479Z"
          fill="white"
        />
      </svg>
    </div>
    <div
      v-if="item"
      class="card item-hovered-visible-block"
    >
      <div
        v-if="item"
        class="card-wrapper"
        style="width: 100%"
      >
        <div
          class="card-left"
          style="flex: auto"
        >
          <div class="card-header">
            <div class="card-header-info">
              <p class="card-title">
                <span>{{ item.attachEntity && item.attachEntity.label }}:</span>
              </p>
              <p class="card-time">
                {{ $Global.DateFormat.DateTimeShort(item.date) }}
              </p>
            </div>
          </div>
          <div class="card-notification-info">
            <div class="card-notification-text">
              <div class="tw-flex tw-items-center tw-gap-[6px] tw-flex-wrap">
                <ViewPanelLink
                  :id="entityData.oldUserId"
                  label="Сотрудник"
                  component="employee-show"
                >
                  <EmployeeName
                    :id="entityData.oldUserId"
                    is-avatar
                  />
                </ViewPanelLink>

                <svg
                  width="20"
                  height="10"
                  viewBox="0 0 17 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12 0.113251L17 3L12 5.88675V4H0V2H12V0.113251Z"
                    fill="grey"
                  />
                </svg>
                <ViewPanelLink
                  :id="entityData.userId"
                  label="Сотрудник"
                  component="employee-show"
                >
                  <EmployeeName
                    :id="entityData.userId"
                    is-avatar
                  />
                </ViewPanelLink>
              </div>
            </div>
            <EmployeeLinkBlock
              :user-id="entityData.creatorId"
              title="Инициатор"
            />
          </div>
        </div>
        <div class="card-right">
          <ChronologyPinned
            v-if="!(variant === 'other' && item.pin)"
            :id="item.id"
            :pin-status="item.pin"
            @refresh="$emit('refresh')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { listColors } from '@/components/core/Chronology/ListConfig'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink'
import EmployeeName from '@/components/other/employee/EmployeeName'
import ChronologyPinned from '@/components/core/Chronology/ChronologyPinned'
import EmployeeLinkBlock from '@/components/other/employee/EmployeeLinkBlock'
export default {
  name: 'ChronologyChangeRespUserView',
  components: { EmployeeLinkBlock, ChronologyPinned, EmployeeName, ViewPanelLink },
  props: {
    item: {
      type: Object,
      required: true
    },
    variant: {
      type: String,
      required: true // other, plan, pin
    }
  },
  computed: {
    listColors () {
      return listColors
    },
    entityData () {
      return this.item?.attachEntityData || null
    },
    toStatus () {
      return this.entityData?.status?.name || null
    },
    fromStatus () {
      return this.entityData?.oldStatus?.name || null
    }
  }
}
</script>

<style scoped>

</style>
