<template>
  <div
    v-if="user"
    style="display: inline-flex; justify-content: center; align-items: center"
  >
    <template v-if="user.id">
      <SipCallLink
        v-if="user.workPhone"
        :phone="user.workPhone"
      />
      <div v-else>
        -
      </div>
    </template>
    <span
      v-else-if="!user.hasError"
      class="tw-text-an-flat-disabled-text"
    >
      Загрузка
    </span>
    <span
      v-else
      class="tw-text-an-flat-disabled-text"
      @click.stop="onClickError"
    >
      Ошибка
    </span>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import SipCallLink from '@/components/telephony/SipCallLink.vue'

export default {
  name: 'EmployeePhone',
  components: { SipCallLink },
  props: {
    id: {
      type: [Number, String, Array],
      required: true
    }
  },
  methods: {
    ...mapActions({
      fetchUsersById: 'users/fetchUsersById'
    }),
    onClickError () {
      this.fetchUsersById(this.id)
    }
  },
  computed: {
    user () {
      return this.$store.getters['users/getUserById'](this.id)
    }
  }
}
</script>
