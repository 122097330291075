import HTTP from '@/Api/http'
import { AxiosResponse } from 'axios'
import { AuthAxiosDefaultConfig } from '@/Api/Telephony'

const path = 'call'

export default {
  listInit (): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.get(`${path}/list-init`, $config)
  },
  getList (param?: object, otherConfig?: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig,
      params: param,
      ...(otherConfig || {}),
      timeout: 60000
    }
    return HTTP.get(`${path}/list`, $config)
  },
  show (id: number | string): Promise<any> {
    const $config = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.get(`${path}/${id}`, $config)
  }
}
