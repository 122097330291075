<template>
  <div class="tw-font-manrope tw-flex tw-flex-col tw-gap-[18px]">
    <div style="display: none">
      {{ isEdited }}
    </div>

    <TwPanelContentBlockFieldView>
      <template #title>
        Застройщик
      </template>
      <template #default>
        <div v-if="preset.company && preset.company.name && preset.company.id">
          <ViewPanelLink
            :id="Number(preset.company.id)"
            label="Застройщик"
            component="company-show"
          >
            {{ preset.company.name }}
          </ViewPanelLink>
        </div>
        <div v-else>
          Не указано
        </div>
      </template>
    </TwPanelContentBlockFieldView>

    <TwPanelContentBlockFieldView>
      <template #title>
        Парсер
      </template>
      <template #default>
        <div v-if="form.parser">
          {{ form.parser }}
        </div>
        <div v-else>
          Не указано
        </div>
      </template>
    </TwPanelContentBlockFieldView>

    <TwTextarea
      v-model="form.comment"
      rows="5"
      placeholder="Комментарий"
      label="Комментарий"
      :disabled="formProcessing"
      :show-label="true"
      :persistent-label="true"
    />

    <TwCheckBox
      v-model="form.active"
      :disabled="formProcessing"
      label="Активность"
    />

    <TwCardSimpleFormSaveBlock
      class="tw-mt-[15px]"
      :loading="formProcessing"
      :cancel-confirm="isEdited"
      :is-sticky="true"
      :disable-success="!isEdited || formProcessing"
      :disable-cancel="formProcessing"
      :visible="true"
      @success="handleSubmit"
      @cancel="handleCancel"
    />
  </div>
</template>

<script>
import { API } from '@/Api/BuilderImporter'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import { RouteKeys } from '@/router/types'
import TwCheckBox from '@/components/tw-ui/ui-kit/input/TwCheckBox.vue'
import TwCardSimpleFormSaveBlock from '@/components/tw-ui/forms/TwCardSimpleFormSaveBlock.vue'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink.vue'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'
import TwTextarea from '@/components/tw-ui/ui-kit/input/TwTextarea.vue'

export default {
  name: 'ImportBuilderSiteMainForm',
  components: {
    TwTextarea,
    TwPanelContentBlockFieldView,
    ViewPanelLink,
    TwCardSimpleFormSaveBlock,
    TwCheckBox
  },
  mixins: [FormCreateEditMixin],
  props: {
    disableRedirect: {
      type: Boolean,
      default: false
    },
    defaultFormFields: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      API: API.importBuilderSite,
      form: {
        active: null,
        companyId: null,
        fileLink: null,
        parser: null,
        comment: '',
        company: {}
      }
    }
  },
  methods: {
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          const adaperMap = {
            // from(show): to(send)
            company: 'companyId'
          }
          for (const key in this.preset) {
            if (adaperMap[key] !== undefined) {
              if (adaperMap[key].indexOf('Id') !== -1) {
                this.$set(this.form, adaperMap[key], (this.preset[key] && this.preset[key].id) || this.defaultFormFields[adaperMap[key]])
              }
            } else {
              this.$set(this.form, key, this.preset[key])
            }
          }
          // Заполнение застройщика заданными через параметры
          if (this.formType === 'create' && this.defaultFormFields?.companyId) {
            this.form.companyId = this.preset?.companyId || this.defaultFormFields?.companyId
          }
        }
        resolve()
      })
    },
    handleSubmit () {
      // this.$v.$reset()
      // this.$v.$touch()
      // console.log('handleSubmit ', this.$v)
      // console.log('$invalid ', this.$v.form.$invalid)
      if (this.formProcessing === true) {
        return
      }

      this.formProcessing = true
      const request = this.formType === 'create'
        ? this.API.store(this.form)
        : this.API.update(this.targetId, this.form)

      request.then(({ data }) => {
        if (this.formType === 'create') {
          this.$emit('create', data.data)
          // если текущий путь равен пути создания то редирект в просмотр
          if (this.$route.name === `${RouteKeys.lead}-create`) {
            this.$router.replace({
              name: `${RouteKeys.lead}-show`,
              params: { id: data.data.id }
            })
          }
        } else {
          this.$emit('update', data.data)
        }
      }).finally(() => {
        this.formProcessing = false
      })
    }
  }
}
</script>
