export default {
  path: '/iframe',
  name: 'iframe',
  redirect: { name: 'iframe-support' },
  meta: { name: 'Iframe', icon: 'question-circle', hideInMenu: true },
  component: () => import('@/views/Support/Index.vue'),
  children: [
    {
      path: 'iframe-support',
      name: 'iframe-support',
      meta: { name: 'Обращение в тех. поддержку', icon: 'home', hideInMenu: false },
      component: () => import('@/views/Support/task/SupportTaskCard.vue')
    },
    {
      path: 'iframe-support/:id',
      name: 'iframe-support-by-id',
      meta: { name: 'Обращение в тех. поддержку', icon: 'home', hideInMenu: false },
      component: () => import('@/views/Support/task/SupportTaskCard.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    },
    {
      path: 'iframe-realty-miner',
      name: 'iframe-realty-miner',
      meta: { name: 'Оценка квартир', icon: 'tools-realty-miner', hideInMenu: false },
      component: () => import('@/views/RealtyMiner/RealityMinerCard.vue')
    },
    {
      path: 'iframe-mortgage-variant',
      name: 'iframe-mortgage-variant',
      meta: { name: 'Подбор ипотеки', icon: 'mortgage-variant-matrix', hideInMenu: false },
      component: () => import('@/views/Mortgage/Variant/IframeMortgageVariant.vue')
    },
    {
      path: 'iframe-complex-subsidized-programs',
      name: 'iframe-complex-subsidized-programs',
      meta: { name: 'Список субсид. программ комплекса', icon: 'mortgage-variant-matrix', hideInMenu: false },
      component: () => import('@/views/Newbuildings/Complex/subsidizedPrograms/IframeComplexSubsidizedPrograms.vue'),
      props: (route:any) => ({ complexId: route?.query?.complexId })
    }
  ]
}
