<template>
  <div
    v-show="visible"
    class="view-panel-wrapper"
  >
    <template v-for="(panel, index) in panels">
      <div
        :key="index+'-'+panel.label"
        :style="{ zIndex: index+1 }"
      >
        <transition
          :name="panel.viewType"
          appear
          type="in-out"
        >
          <div
            v-if="panel.viewType === 'panel'"
            class="view-panel-wrapper-item"
            :style="{ width: panel.fixedWidth ? panel.fixedWidth : `${(85 - (index * 3))}%` }"
          >
            <div
              v-if="panel.label || index === panels.length - 1"
              class="view-panel-wrapper-item--label tw-uppercase tw-cursor-pointer tw-flex tw-items-center tw-justify-center tw-bg-an-flat-black-table hover:tw-bg-an-flat-black-1 tw-h-[48px] tw-min-w-[48px] tw-text-white tw-group"
              :style="{ top: `${55 * (panels.length - index)}px` }"
              title="Закрыть панель"
              @click.stop="panelLabelClicked(({panel, index}))"
            >
              <button
                v-if="index === panels.length - 1"
                :class="panel.label ? 'tw-py-[5px] tw-pl-[5px]':''"
              >
                <svg
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M3.48528 0.656851C2.70423 -0.124197 1.4379 -0.124197 0.656854 0.656851C-0.124194 1.4379 -0.124195 2.70423 0.656854 3.48528L3.48528 6.3137L0.656853 9.14213C-0.124195 9.92318 -0.124195 11.1895 0.656853 11.9706C1.4379 12.7516 2.70423 12.7516 3.48528 11.9706L6.31371 9.14213L9.14214 11.9706C9.92318 12.7516 11.1895 12.7516 11.9706 11.9706C12.7516 11.1895 12.7516 9.92318 11.9706 9.14213L9.14213 6.31371L11.9706 3.48528C12.7516 2.70423 12.7516 1.4379 11.9706 0.656851C11.1895 -0.124197 9.92318 -0.124197 9.14214 0.656851L6.31371 3.48528L3.48528 0.656851Z"
                    fill="white"
                  />
                </svg>
              </button>
              <div
                v-if="panel.label"
                class="tw-mx-[10px] tw-leading-none"
              >
                <span>{{ panel.label }}</span>
              </div>
            </div>
            <ViewPanel
              ref="viewPanel"
              :info="panel"
              :style="{ zIndex: index+10 }"
              @click="contentClicked({})"
              @close-me="closeCommand({panel, index})"
              @close-all="closeAllPanels"
              @refresh-all="refreshAllPanels"
            />
          </div>
          <div
            v-else-if="panel.viewType === 'modal'"
            :style="{ width: panel.fixedWidth ? panel.fixedWidth : `${(85 - (index * 3))}%` }"
            class="tw-fixed tw-z-10 tw-top-1/2 tw-left-1/2 tw-transform -tw-translate-x-1/2 -tw-translate-y-1/2 tw-overflow-hidden tw-bg-an-flat-panel-modal-bg tw-rounded-md"
          >
            <ViewPanel
              ref="viewPanel"
              class="tw-min-w-[300px] tw-w-max tw-max-w-[90vw] tw-max-h-[90vh] tw-min-h-[250px]"
              :info="panel"
              style="overflow: auto"
              :style="{ zIndex: index+10 }"
              @click="contentClicked({})"
              @close-me="closeCommand({panel, index})"
              @close-all="closeAllPanels"
              @refresh-all="refreshAllPanels"
            />
          </div>
          <div
            v-else-if="panel.viewType === 'full'"
            class="tw-fixed tw-z-10 tw-min-w-full tw-min-h-screen tw-overflow-hidden tw-bg-an-flat-panel-modal-bg"
            @click.stop="panelLabelClicked(({panel, index}))"
          >
            <ViewPanel
              ref="viewPanel"
              :info="panel"
              style="overflow: auto"
              :style="{ zIndex: index+10 }"
              @click="contentClicked({})"
              @close-me="closeCommand({panel, index})"
              @close-all="closeAllPanels"
              @refresh-all="refreshAllPanels"
            />
          </div>
        </transition>
        <div
          v-if="index === panels.length - 1"
          :style="{backgroundColor: 'rgba(55, 58, 62, 0.80)'}"
          class="view-panel-wrapper--overlay"
          @click="overlayClicked({panel, index})"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import ViewPanel from '@/components/core/ViewPanel/ViewPanel'
import qs from 'qs'

export default {
  name: 'ViewPanelWrapper',
  components: { ViewPanel },
  data () {
    return {
      visible: false
    }
  },
  methods: {
    ...mapActions({
      showPanel: 'viewPanels/showPanel'
    }),
    ...mapMutations({
      remove: 'viewPanels/REMOVE_PANEL',
      removeAll: 'viewPanels/REMOVE_ALL_PANEL'
    }),
    overlayClicked (data) {
      // console.warn('overlayClicked', data)
      this.closeCommand(data)
    },
    panelLabelClicked (data) {
      // console.warn('panelLabelClicked', data)
      this.closeCommand(data)
    },
    closeCommand (data) {
      // console.warn('closeCommand', data)
      if (data?.panel?.isCloseConfirm) {
        console.error('isCloseConfirm', data)
        if (!window.confirm('Хотите закрыть вкладку? Подтвердите действие!')) {
          // если не подтвердили отменяем закрытие
          return
        }
        // иначе продолжаем закрытие
      }
      // TODO сомнительная полезность
      // авторефреш нижестоящей панели
      // if (data.index) {
      //   this.$refs.viewPanel[data.index - 1].refreshPanel()
      // }
      this.$nextTick(() => {
        this.remove(data.index)
      })
    },
    contentClicked () {
      // console.warn('contentClicked', data)
    },
    closeAllPanels () {
      // console.warn('closeAllPanels')
      this.removeAll()
    },
    refreshAllPanels () {
      // console.warn('refreshAllPanels', data)
      // console.error(this.$refs.viewPanel)
      for (const index in this.$refs.viewPanel) {
        this.$refs.viewPanel[index].refreshPanel()
      }
    },
    setHash (params) {
      let panelHash = ''
      if (params) {
        const strHashParams = qs.stringify(params, {
          arrayFormat: 'brackets'
        })
        panelHash = `#${strHashParams}`
      }
      window.location.hash = panelHash
    }
  },
  computed: {
    ...mapGetters({
      isAuth: 'profile/isAuth',
      panels: 'viewPanels/getPanels'
    })
  },
  watch: {
    panels: {
      handler (newValue) {
        if (Array.isArray(newValue) && !newValue.length) {
          this.setHash(null)
          setTimeout(() => {
            this.visible = false
          }, 500)
          document.documentElement.style.overflow = 'auto'
        } else {
          this.visible = true
          const { props, component, label, viewType } = newValue[newValue.length - 1]
          this.setHash({ props, component, label, viewType })
          document.documentElement.style.overflow = 'hidden'
        }
      },
      deep: true
      // immediate: true
    }
  },
  mounted () {
    // console.warn(this.$options.name, this.panels)
    // отслеживанием первое срабатывание изменения hash
    this.unwatchIsLiveProp = this.$watch(() => this.$route.hash,
      (newValue) => {
        // панелей нет а хеш есть! Нужно открыть панель
        if (newValue && !this.panels.length) {
          const hashData = qs.parse(this.$route.hash.replace('#', ''))
          // console.error('!!!!!!!!!!!!!!!!!!!!!!', hashData, this.isAuth)
          // Тут небольшой парсер для простых типов передаваемых свойств
          if (hashData?.props && Object.keys(hashData.props).length) {
            for (const propKey in hashData.props) {
              const propValue = hashData.props[propKey]
              if (!isNaN(propValue)) {
                // Для чисел
                hashData.props[propKey] = Number(propValue)
              } else if (propValue === 'true' || propValue === 'false') {
                // Для булевых
                hashData.props[propKey] = propValue === 'true'
              }
            }
          }
          this.showPanel(hashData)
          this.visible = true
          this.unwatchIsLiveProp()
        }
      },
      {
        immediate: true
      })
  }
}
</script>

<style scoped lang="less">
@import "../../../assets/less/__constants";
.view-panel-wrapper {
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  > div {
    width: 100%;
    height: 100%;
    position: absolute;
  }

  &-item{
    overflow: auto;
    height: 100vh;

    z-index: 2;
    position: fixed;
    top: 0;
    right: 0;
    background-color: #eef2f4;

    &--label{
      font-size: 13px;
      font-weight: 500;
      max-width: 215px;
      top: 21px;
      position: fixed;
      padding-right: 7px;
      padding-left: 5px;
      white-space: nowrap;
      overflow: hidden;
      transition: top .3s;
      box-shadow: inset -6px 0 8px -10px rgba(0,0,0,0.95);
      z-index: 1;
      transform: translateX(-100%);
    }
  }

  &--overlay {
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.4);
  }

  .panel-enter-active, .panel-leave-active {
    transition: all 0.2s;
    .view-panel-wrapper--overlay {
      opacity: 0;
    }
  }
  .panel-leave-to /* .list-leave-active до версии 2.1.8 */ {
    opacity: 0;
    transform: translateX(100%);
  }
  .panel-leave-to .view-panel-wrapper--overlay{
    opacity: 0;
  }
  .panel-enter  /* .panel-leave-active до версии 2.1.8 */ {
    opacity: 0;
    transform: translateX(100%);
  }

  .modal-enter-active, .modal-leave-active {
    transition: all 0.2s;
    .view-panel-wrapper--overlay {
      opacity: 0;
    }
  }
  .modal-leave-to /* .list-leave-active до версии 2.1.8 */ {
    opacity: 0;
    transform: translateY(100%);
  }
  .modal-leave-to .view-panel-wrapper--overlay{
    opacity: 0;
  }
  .modal-enter  /* .modal-leave-active до версии 2.1.8 */ {
    opacity: 0;
    transform: translateY(100%);
  }
}
</style>
