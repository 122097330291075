<template>
  <div class="tw-flex tw-flex-wrap tw-gap-[12px] tw-justify-between tw-items-center tw-mb-[12px] tw-text-an-flat-black-main tw-leading-[27px]">
    <div class="tw-flex tw-gap-[10px] tw-items-center tw-justify-start">
      <button
        v-if="hasBackwardBtn"
        class="tw-group tw-flex tw-gap-[4px] tw-items-center tw-justify-center hover:tw-text-an-flat-dark-blue-link-active"
        @click.prevent="onClickBackward"
      >
        <svg
          width="5"
          height="8"
          viewBox="0 0 5 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            class="group-hover:tw-fill-an-flat-dark-blue-link-active"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.44187 7.76823C4.86615 7.41467 4.92347 6.7841 4.56991 6.35983L2.60339 4.00001L4.56991 1.64019C4.92347 1.21592 4.86615 0.585353 4.44187 0.231788C4.01759 -0.121775 3.38703 -0.0644515 3.03346 0.359825L-2.28882e-05 4.00001L3.03346 7.64019C3.38703 8.06447 4.01759 8.12179 4.44187 7.76823Z"
            fill="#343942"
          />
        </svg>
        {{ backwardBtnText }}
      </button>
      <slot name="left" />
    </div>
    <div class="tw-flex-1 tw-flex tw-gap-[6px] tw-items-center tw-justify-end">
      <slot name="right" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TwPanelTitle',
  props: {
    hasBackwardBtn: {
      type: Boolean,
      default: false
    },
    backwardBtnText: {
      type: String,
      default: 'Назад'
    }
  },
  methods: {
    hasHistory () { return window.history.length > 2 },
    onClickBackward () {
      if (this.hasHistory()) {
        this.$router.go(-1)
      } else {
        // надежно переместиться выше по иерархии нет возможности изза раздела /managment/, тоесть у человека просто не будет прав на менждмент и выдаст ошибку
        // поэтому если нет истории кидаю на главную
        this.$router.push('/')
      }
    }
  }
}
</script>
