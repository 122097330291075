import { TableColumns } from '@/views/tableOptionType'

const options: TableColumns = [
  {
    dataKey: 'company',
    text: 'Банк - Программа',
    value: 'name',
    width: 'auto',
    sortable: false,
    colStyle: 'width: auto;',
    thClass: 'tw-text-main-sm2 tw-text-left tw-font-semibold tw-whitespace-nowrap tw-px-[12px] tw-py-[8px] tw-bg-an-flat-gray-9',
    tdClass: 'tw-text-center tw-border-y tw-border-an-flat-gray-7 tw-p-[12px] tw-whitespace-nowrap'
  },
  {
    dataKey: 'timeout',
    text: 'Срок действия ставки',
    value: 'rate',
    width: '170px',
    align: 'end',
    sortable: false,
    colStyle: 'width: 20px;',
    thClass: 'tw-text-main-sm2 tw-text-left tw-font-semibold tw-whitespace-nowrap tw-px-[12px] tw-py-[8px] tw-bg-an-flat-gray-9',
    tdClass: 'tw-text-center tw-border-y tw-border-an-flat-gray-7 tw-p-[12px] tw-whitespace-nowrap'
  },
  {
    dataKey: 'rate',
    text: 'Ставка банка',
    value: 'rate',
    width: '10%',
    align: 'end',
    sortable: false,
    colStyle: 'width: 20px;',
    thClass: 'tw-text-main-sm2 tw-text-left tw-font-semibold tw-whitespace-nowrap tw-px-[12px] tw-py-[8px] tw-bg-an-flat-gray-9',
    tdClass: 'tw-text-center tw-border-y tw-border-an-flat-gray-7 tw-p-[12px] tw-whitespace-nowrap'
  },
  {
    dataKey: 'pay',
    text: 'Платеж',
    value: 'pay',
    width: '10%',
    align: 'end',
    sortable: false,
    colStyle: 'width: 20px;',
    thClass: 'tw-text-main-sm2 tw-text-left tw-font-semibold tw-whitespace-nowrap tw-px-[12px] tw-py-[8px] tw-bg-an-flat-gray-9',
    tdClass: 'tw-text-center tw-border-y tw-border-an-flat-gray-7 tw-p-[12px] tw-whitespace-nowrap'
  },
  {
    dataKey: 'economyFromBase',
    text: 'Экономия',
    value: 'economyFromBase',
    width: '10%',
    align: 'end',
    sortable: false,
    colStyle: 'width: 20px;',
    thClass: 'tw-text-main-sm2 tw-text-left tw-font-semibold tw-whitespace-nowrap tw-px-[12px] tw-py-[8px] tw-bg-an-flat-gray-9',
    tdClass: 'tw-text-center tw-border-y tw-border-an-flat-gray-7 tw-p-[12px] tw-whitespace-nowrap'
  }
]

export default options
