import { TableColumns } from '@/views/tableOptionType'

const options: TableColumns = [
  {
    text: '',
    value: 'menu',
    width: '50px',
    sortable: false
  },
  {
    text: 'Контакты',
    value: 'contacts',
    width: '30%',
    sortable: false
  },
  {
    text: 'Тема',
    value: 'theme',
    width: 'auto',
    sortable: false
  },
  {
    text: 'Дата',
    value: 'dateMailBox',
    width: '120px',
    align: 'end',
    sortable: false
  }
]

export default options
