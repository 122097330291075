<template>
  <div class="tw-font-manrope tw-mt-3">
    <!-- Текущие параметры подбора -->
    <DealByNbSelectionFiltersShow
      v-if="dealData.id"
      :deal-id="dealData.id"
    />

    <!-- Запросы застройщика -->
    <DealBuyNbRequestToDeveloperList
      v-if="dealData.id"
      :deal-id="dealData.id"
    />

    <!--Список избранных объектов-->
    <TwPanelContentBlock
      class="tw-mb-[6px]"
      :content-classes="''"
    >
      <template #title>
        <div class="tw-w-full tw-grid tw-grid-cols-3 tw-gap-[20px] tw-items-center">
          <div>
            Избранное
          </div>
        </div>
      </template>
      <div>
        <div v-if="dealData.id">
          <NewBuildingSelectionFavoriteBlock :deal-id="dealData.id" />
        </div>
      </div>
    </TwPanelContentBlock>
  </div>
</template>

<script>
import DealBuyNbRequestToDeveloperList from '@/views/Deal/TypeViews/buyNb/Booking/DealBuyNbRequestToDeveloperList.vue'
import DealByNbSelectionFiltersShow from '@/views/Deal/TypeViews/buyNb/Selection/DealByNbSelectionFiltersShow.vue'
import NewBuildingSelectionFavoriteBlock
  from '@/views/Newbuildings/Selection/NbComplex/NewBuildingSelectionFavorite/NewBuildingSelectionFavoriteBlock.vue'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'

export default {
  name: 'DealByNbSelectionTab',
  components: {
    TwPanelContentBlock,
    NewBuildingSelectionFavoriteBlock,
    DealByNbSelectionFiltersShow,
    DealBuyNbRequestToDeveloperList
  },
  props: {
    dealData: {
      required: true
    }
  },
  computed: {
    dealId () {
      return this.dealData?.id || null
    }
  }
}
</script>

<style scoped>

</style>
