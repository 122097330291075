import { RouteKeys } from '@/router/types'
import dictPermissionRules from '@/Permissions/dictRules'

export default {
  path: RouteKeys.builderImportDocument,
  name: RouteKeys.builderImportDocument,
  redirect: { name: `${RouteKeys.builderImportDocument}-files` },
  meta: { name: 'Ручной импорт файлов', icon: 'manual-file-import', ruleNamespace: dictPermissionRules.AppServicePermissionRulesOBJECTSNewBuildingsBuilderFileReadBuilderNBFileRule },
  component: () => import('@/views/ObjectStorage/Index.vue'),
  children: [
    {
      path: 'files',
      name: `${RouteKeys.builderImportDocument}-files`,
      meta: { name: 'Импорт файлов', icon: 'manual-file-import', hideInMenu: true },
      component: () => import('@/views/BuilderImporter/ImportDocumentFile/List.vue')
    }
  ]
}
