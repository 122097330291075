export const fieldsDict: any = {
  main: {
    id: 'Внутренний идентификатор',
    experience: 'Общий трудовой стаж не менее, месяцы',
    experienceLast: 'Трудовой стаж на последнем месте работы не менее, месяцы',
    ageMin: 'Минимальный возраст заемщика',
    ageMax: 'Максимальный возраст заемщика',
    nationally: 'Гражданство',
    goodHistory: 'Хорошая кредитная история заемщика',
    militaryId: 'Военный билет для мужчин до 27 лет',
    rono: 'РОНО',
    earlyPayment: 'Досрочное погашение без санкций',
    paymentType: 'Платежи',
    decreaseToCreditSum: 'Занижение до суммы кредита',
    decreaseLowerCreditSum: 'Занижение ниже суммы кредита',
    redemptionBankPledge: 'Выкуп залога другого банка',
    transactionBetweenRelatives: 'Сделки между родственниками'
  },
  remark: {
    // decreaseToCreditSum: {
    //   true: 'да',
    //   false: 'нет'
    // },
    // decreaseLowerCreditSum: {
    //   true: 'да',
    //   false: 'нет'
    // },
    // redemptionBankPledge: {
    //   true: 'да',
    //   false: 'нет'
    // },
    // transactionBetweenRelatives: {
    //   true: 'да',
    //   false: 'нет'
    // },
    rono: {
      true: 'банк допускает выделение долей детям',
      false: 'выделение долей детям невозможно'
    },
    nationally: {
      true: 'наличие гражданства РФ обязательно',
      false: 'кредит может быть выдан в том числе иностранным гражданам'
    },
    goodHistory: {
      true: 'одобрение могут получить только клиенты с хорошей кредитной историей',
      false: 'одобрение могут получить в том числе клиенты с плохой кредитной историей'
    },
    militaryId: {
      true: 'если мужчине не исполнилось 27 лет, для получения кредита обязательно наличие военного билета',
      false: 'для получения кредита мужчинам до 27 лет наличие военного билета не обязательно'
    }
  }
}

export const fieldNameTranslateFilter = function (fieldName:string, dictType:string) {
  // console.warn('fieldNameTranslate ', fieldName, dictType)
  if (!dictType || !fieldName) return 'Error'
  return fieldsDict[dictType][fieldName] || null
}
