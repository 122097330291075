<template>
  <div class="tw-font-manrope">
    <TwPanelContentBlock>
      <template #title>
        <div>План объектов ЖК</div>
      </template>
      <template #action>
        <TwPanelContentBlockActionButton
          :disabled="loading"
          type="edit"
          @click="editing = !editing"
        >
          {{ editing ? 'отменить' : 'изменить' }}
        </TwPanelContentBlockActionButton>
      </template>
      <div>
        <div>
          <RealtyComplexUploadHighlightPlan
            :complex-id="complexId"
            :editing="editing"
            :buildings-list="buildingsList"
            @fileServerImage="fileData"
            @updateComplexImage="complexImage = $event"
            @removeOldFile="removeImageComplex"
          />
        </div>
        <div v-if="complexImage && complexImage.fileId">
          <RealtyComplexHighlightForm
            :editing="editing"
            :buildings-list="buildingsList"
            :complex-image="complexImage"
            @updated="editing = false"
          />
        </div>
      </div>
    </TwPanelContentBlock>
  </div>
</template>

<script>
import RealtyComplexUploadHighlightPlan
  from '@/views/Newbuildings/Complex/highlight-buildings-plan/RealtyComplexUploadHighlightPlan.vue'
import RealtyComplexHighlightForm
  from '@/views/Newbuildings/Complex/highlight-buildings-plan/RealtyComplexHighlightForm.vue'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import { API } from '@/Api/ObjectStorage'
import TwPanelContentBlockActionButton from '@/components/tw-ui/panels/TwPanelContentBlockActionButton.vue'

export default {
  name: 'RealtyComplexRenderPlan',
  components: {
    TwPanelContentBlockActionButton,
    TwPanelContentBlock,
    RealtyComplexHighlightForm,
    RealtyComplexUploadHighlightPlan
  },
  props: {
    complexId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      buildingsList: [],
      API: API.RealtyNewBuildings,
      loading: false,

      editing: false,
      complexImage: {
        fileId: null
      },
      buildingId: '',
      fileServerData: null
    }
  },
  mounted () {
    this.fetchBuildings()
  },
  methods: {
    fileData (event) {
      if (event && this.complexImage) {
        this.fileServerData = event
        this.complexImage.fileId = event.id
      }
    },
    removeImageComplex () {
      this.complexImage.fileId = null
    },
    fetchBuildings () {
      console.warn('API fetchBuildings')
      if (this.loading) {
        return
      }

      if (!this.API) {
        throw Error('not set API')
      }

      this.permissionDenied = false
      this.loading = true

      this.API.objects(this.complexId)
        .then((response) => {
          console.warn('API fetchBuildings response', response)
          this.buildingsList = response?.data?.data || []
        })
        .catch((error) => {
          console.error('Error API fetchBuildings: ', error)
          // обработка отсутствия прав на просмотр
          if (error?.response?.status === 403) {
            this.permissionDenied = true
          }
          this.buildingsList = []
        })
        .finally(() => {
          console.warn('API fetchBuildings Finally')
          this.loading = false
        })
    }
  }
}
</script>
