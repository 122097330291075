<template>
  <div>
    <div class="tw-flex tw-flex-col tw-gap-[12px]">
      <div class="tw-mt-[12px] tw-flex tw-justify-between">
        <div class="tw-text-main-md tw-font-medium tw-text-an-flat-gray-4">
          Доп. контакты:
        </div>
        <TwPanelContentBlockActionButton
          size="md"
          @click.prevent="addNewContact"
        >
          <span class="tw-font-medium">Добавить новый контакт</span>
        </TwPanelContentBlockActionButton>
      </div>
      <div>
        <template v-if="Array.isArray(value) && value.length">
          <div
            v-for="(item, index) in value"
            :key="index+'-row'"
            class="tw-flex tw-gap-[8px] tw-flex-nowrap tw-items-center"
          >
            <div style="max-width: 180px">
              <TwSelect
                v-model="item.type"
                placeholder="Тип"
                label="Тип"
                :multi="false"
                :dense="true"
                :cleanable="true"
                :persistent-label="true"
                :options="items"
                :error-text="errorMessages[index] && errorMessages[index].type ? errorMessages[index].type : null"
              />
            </div>
            <div class="tw-flex-1">
              <TwInputText
                v-model="item.contact"
                type="text"
                autocomplete="off"
                label="Значение"
                placeholder="Значение"
                :error-text="errorMessages[index] && errorMessages[index].contact ? errorMessages[index].contact : null"
                :show-label="true"
                :dense="true"
                :persistent-label="true"
              />
            </div>
            <button
              title="Удалить поле"
              class="tw-ml-[6px] tw-mt-[21px] tw-text-an-flat-red-light hover:tw-text-an-flat-red-main"
              @click.prevent="removeContact(index)"
            >
              <svg
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4.67361 1.45139C3.67259 0.450377 2.13979 0.360212 1.25 1.25C0.360203 2.1398 0.450368 3.6726 1.45138 4.67362L5.27777 8.50001L1.45139 12.3264C0.450375 13.3274 0.36021 14.8602 1.25 15.75C2.13979 16.6398 3.6726 16.5496 4.67361 15.5486L8.5 11.7222L12.3264 15.5486C13.3274 16.5496 14.8602 16.6398 15.75 15.75C16.6398 14.8602 16.5496 13.3274 15.5486 12.3264L11.7222 8.50001L15.5486 4.67361C16.5496 3.67259 16.6398 2.13979 15.75 1.24999C14.8602 0.360203 13.3274 0.450368 12.3264 1.45138L8.5 5.27778L4.67361 1.45139Z"
                  fill="currentColor"
                />
              </svg>
            </button>
          </div>
        </template>
        <div
          v-else
          class="tw-p-2 tw-text-center tw-text-an-flat-disabled-text"
        >
          Отсутствуют
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TwPanelContentBlockActionButton from '../tw-ui/panels/TwPanelContentBlockActionButton.vue'
import TwInputText from '../tw-ui/ui-kit/input/TwInputText.vue'
import TwSelect from '../tw-ui/ui-kit/input/TwSelect.vue'

export default {
  name: 'InputAdditionalContacts',
  components: {
    TwInputText,
    TwSelect,
    TwPanelContentBlockActionButton
  },
  props: {
    value: {
      default: () => []
    },
    rules: {
      default: () => []
    },
    items: {
      default: () => [
        {
          label: 'Тест 1',
          value: 'test1'
        }, {
          label: 'Тест 2',
          value: 'test2'
        }
      ]
    },
    errorMessages: {
      default: () => []
    }
  },
  data () {
    return {}
  },
  methods: {
    changeContact (index, val) {
      console.log('changeContact: ', index, val)
      // const value = this.value
      // value[index].phone = val
      // this.onChange(value)
    },
    changeType (index, val) {
      console.log('changeType: ', index, val)
      // const value = this.value
      // value[index].phone = val
      // this.onChange(value)
    },
    addNewContact () {
      let value = this.value
      if (!Array.isArray(value)) {
        value = []
      }
      value.push(
        {
          id: null,
          type: null,
          contact: ''
        }
      )
      this.onChange(value)
    },
    removeContact (index) {
      const value = this.value
      console.log('removeContact', index, value)
      value.splice(index, 1)
      this.onChange(value)
    },
    onChange (value) {
      this.$emit('input', value)
    }
  }
}
</script>
