export default [
  {
    value: '93b3df57-4c89-44df-ac42-96f05e9cd3b9',
    label: 'Казань'
  },
  {
    value: '1e90ec38-0f85-442b-ac1b-ca687fa91d88',
    label: 'Зеленодольск'
  }
]
