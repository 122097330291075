<template>
  <div
    v-if="disableUi"
    class="person-documents-expansion text-center my-5"
  >
    <v-progress-circular
      size="50"
      color="primary"
      indeterminate
    />
  </div>
  <div
    v-else
    class="mt-5"
  >
    <div class="mb-5 d-flex justify-space-between align-baseline">
      <div />
      <v-menu
        v-if="Array.isArray(additionalData.otherPersonsId) && additionalData.otherPersonsId.length"
        offset-y
      >
        <template #activator="{ on, attrs }">
          <v-btn
            small
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
          >
            <v-icon
              small
              left
            >
              fa-plus
            </v-icon>
            Добавить созаемщика
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(pId, index) in additionalData.otherPersonsId"
            :key="index"
            :disabled="applications.some(app => app.personId === pId)"
            @click.prevent="applicationCreate(pId)"
          >
            <v-list-item-title>
              {{ $Global.peopleName($store.getters['tmpCache/getPersonById'](pId), 'fio') }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <v-row v-if="!applications.length">
      <v-col
        cols="12"
        class="text-muted"
      >
        Нет добавленых анкет
      </v-col>
    </v-row>
    <v-expansion-panels
      v-else
      v-model="opened"
      tile
      :readonly="false"
      :multiple="false"
    >
      <v-expansion-panel
        v-for="(application, index) in applications"
        :key="index"
      >
        <v-expansion-panel-header
          :class="opened === index ? '' : 'white--text'"
          :color="opened === index ? 'blue-grey lighten-2' : 'blue-grey darken-2'"
        >
          <div class="font-weight-medium">
            <v-icon
              v-if="applicationValidationErrors[application.id]"
              title="Анкета содержит замечания"
              color="error"
              class="mr-3"
            >
              fa-exclamation-circle
            </v-icon>
            <v-icon
              v-else
              color="success"
              title="Анкета запонена корректно"
              class="mr-3"
            >
              fa-check-square
            </v-icon>
            {{ application.fullName }}
            <small
              v-if="application.applicationType"
              class="text-right white--text font-weight-regular"
            >
              ({{ applicationType[application.applicationType] }})
            </small>
          </div>
          <div class="text-right mr-3">
            <v-chip
              v-if="application.acceptance"
              small
              title="Статус СМС подтвержения/подписи"
              :color="acceptanceStatuses[application.acceptance.status].color"
              class="ma-2"
            >
              {{ acceptanceStatuses[application.acceptance.status].text }}
            </v-chip>
          </div>
          <template #actions>
            <v-btn
              icon
              small
            >
              <v-icon>
                fa-angle-down
              </v-icon>
            </v-btn>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content
          v-if="opened === index"
          class="pa-0"
        >
          <div
            style=""
            class="my-4 d-flex justify-space-between"
          >
            <v-btn
              text
              disabled
              small
            />
            <v-btn
              v-if="application.applicationType !== 'BORROWER'"
              text
              outlined
              color="error"
              small
              @click.prevent="applicationDelete(application.id)"
            >
              Удалить
            </v-btn>
          </div>
          <div>
            <DomClickDealApplicationForm
              :deal-id="dealId"
              :application="application"
              :refresh-documents-process="refreshDocumentsProcess"
              :target-product="calculateInfo.product"
              :additional-data="additionalData"
              @update="$emit('refresh')"
              @refresh-application-documents="$emit('refresh-application-documents')"
            />
          </div>
        </v-expansion-panel-content>
        <div v-if="applicationValidationErrors[application.id]">
          <div class="ma-2 text--disabled">
            Замечания к анкете:
          </div>
          <div
            v-for="(err, errIndex) in applicationValidationErrors[application.id]"
            :key="errIndex"
            class="ma-2 error--text"
          >
            {{ err.message }}
          </div>
        </div>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import dealAPI from '@/Api/Crm/Mortgage/Docmclick/Deals'
import { applicationType, acceptanceStatuses } from '../DomClickDict'
import DomClickDealApplicationForm
  from '@/views/MortgageProgramWorksheet/DomClickWorksheet/deal/application/DomClickDealApplicationForm'

export default {
  name: 'DomClickDealApps',
  components: { DomClickDealApplicationForm },
  props: {
    dealId: {
      type: Number,
      required: true
    },
    dealOffice: {
      type: [Object, null],
      default: null
    },
    calculateInfo: {
      type: Object,
      required: true
    },
    additionalData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    refreshDocumentsProcess: {
      type: Boolean,
      default: false
    },
    validateData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    applications: {
      type: Array,
      required: true
    },
    loadingParent: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      acceptanceStatuses,
      applicationType,
      loading: false,
      isEditOffice: false,
      opened: -1
    }
  },
  computed: {
    applicationValidationErrors () {
      const result = {}
      if (Array.isArray(this.validateData?.applicationValidationErrors)) {
        for (const item of this.validateData?.applicationValidationErrors) {
          result[item.id] = Array.isArray(item?.validationErrors) && item?.validationErrors.length ? item?.validationErrors : undefined
        }
      }
      return result
    },
    disableUi () {
      return this.loadingParent || this.loading
    }
  },
  methods: {
    applicationCreate (personId) {
      console.warn('applicationCreate')
      this.loading = true
      dealAPI.applicationCreate(this.dealId, { personId })
        .then(({ data }) => {
          console.warn('applicationCreate RESULT: ', data)
          this.$emit('refresh')
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    applicationDelete (applicationId) {
      console.warn('applicationDelete')
      if (window.confirm('Удалить анкету?')) {
        this.loading = true
        dealAPI.applicationDelete(this.dealId, applicationId)
          .then(({ data }) => {
            console.warn('applicationDelete RESULT: ', data)
            this.$emit('refresh')
          })
          .catch((error) => {
            console.error(error)
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        console.warn('Отмена удаления - applicationDelete')
      }
    }
  }
}
</script>

<style scoped>

</style>
