<template>
  <div class="tw-font-manrope">
    <div
      v-if="loading"
      class="tw-h-full tw-w-full tw-flex tw-justify-center tw-items-center tw-py-5"
    >
      <TwCircleLoader />
    </div>
    <div
      v-else-if="error && error !== 404"
      class="tw-text-an-flat-red-main tw-text-center"
    >
      Код ошибки: {{ error || 0 }}
      <div>
        <code>
          {{ errorData }}
        </code>
      </div>
    </div>
    <div
      v-else-if="id && data"
      class="tw-flex tw-gap-[14px] tw-flex-col"
    >
      <TwPanelContentBlockFieldView>
        <template #title>
          Связанная сделка
        </template>
        <div v-if="data.deal">
          <ViewPanelLink
            :id="data.deal.id"
            label="Заявка"
            component="deal-show"
          >
            {{ data.deal.name }}
          </ViewPanelLink>
          <span
            title="Статус связанной сделки"
            class="tw-ml-[6px] tw-px-[10px] tw-py-[2px] tw-text-main-sm2 tw-font-medium tw-rounded-sm"
            :class="data.deal.status.code === 'success' ? 'tw-text-an-flat-green-btn-bg-active tw-bg-an-flat-green2/30':'tw-text-an-flat-orange-warning tw-bg-an-flat-orange-lighten'"
          >
            {{ data.deal.status.name }}
          </span>
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView>
        <template #title>
          Программа ипотеки
        </template>
        <div v-if="data.programCategory">
          <ViewPanelLink
            :id="data.programCategory.value"
            label="Программа"
            component="mortgage-program-show"
          >
            {{ data.programCategory.label }}
          </ViewPanelLink>
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView>
        <template #title>
          Страховая компания
        </template>
        <div v-if="data.insuranceCompany">
          <ViewPanelLink
            :id="data.insuranceCompany.id"
            component="company-show"
          >
            {{ data.insuranceCompany.name }}
          </ViewPanelLink>
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView>
        <template #title>
          Банк
        </template>
        <div v-if="data.bank">
          <ViewPanelLink
            :id="data.bank.id"
            component="company-show"
          >
            {{ data.bank.name }}
          </ViewPanelLink>
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView>
        <template #title>
          Сумма кредита
        </template>
        <div>
          {{ (data.creditSum || 0).toLocaleString('ru') }} ₽
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView>
        <template #title>
          Процентов в год
        </template>
        <div v-if="data.percentAtYear">
          {{ data.percentAtYear }}%
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView>
        <template #title>
          Срок кредита
        </template>
        <div v-if="data.years">
          {{ data.years }} лет
        </div>
      </TwPanelContentBlockFieldView>
    </div>
    <div
      v-else
      class="tw-text-an-flat-disabled-text tw-text-center"
    >
      Нет данных
    </div>
  </div>
</template>

<script>
import API from '@/Api/Crm/Deal/BuyNb/ResultMortgage/index'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'
import ShowObjMixin from '@/mixins/ShowObjMixin'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink.vue'

export default {
  name: 'BuyNbResultMortgageShow',
  components: { ViewPanelLink, TwCircleLoader, TwPanelContentBlockFieldView },
  mixins: [ShowObjMixin],
  data () {
    return {
      API
    }
  }
}
</script>
