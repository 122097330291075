<template>
  <div
    v-cloak
    v-if="info"
    class="tw-flex tw-gap-[12px] tw-flex-col"
  >
    <TwPanelContentBlockFieldView>
      <template #title>
        Фамилия
      </template>
      <div v-if="info.surname">
        {{ info.surname }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Имя
      </template>
      <div v-if="info.name">
        {{ info.name }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Отчество
      </template>
      <div v-if="info.fatherName">
        {{ info.fatherName }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Рабочий телефон
      </template>
      <div v-if="info.workPhone">
        {{ info.workPhone | formatPhone }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        SIP телефон
      </template>
      <div v-if="info.sipPhone">
        {{ info.sipPhone }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Контактный Email
      </template>
      <div v-if="info.email">
        {{ info.email }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Подразделения / Должность
      </template>
      <div v-if="Array.isArray(info.userStructure) && info.userStructure.length">
        <div
          v-for="(us, index) in info.userStructure"
          :key="index"
          class="tw-my-[6px]"
        >
          {{ us.position && us.position.name }} - <StructureItemName
            :item="us.structure"
            tag="span"
            class="tw-text-an-flat-gray-3"
          />
        </div>
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView v-if="info.bxOffice">
      <template #title>
        Место работы (Битрикс)
      </template>
      <div>
        {{ info.bxOffice }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Дата рождения / Возраст
      </template>
      <div v-if="info.birthday">
        {{ new Date(info.birthday).toLocaleDateString() }} / {{ $Global.calculateAge(info.birthday) }}{{ $Global.textPlural($Global.calculateAge(info.birthday), 'year') }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Пол
      </template>
      <div v-if="info.gender">
        {{ info.gender && info.gender.label }}
      </div>
    </TwPanelContentBlockFieldView>
  </div>
</template>

<script>
import StructureItemName from '@/components/select/structure/StructureItemName'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'
export default {
  name: 'EmployeeMainShow',
  components: { TwPanelContentBlockFieldView, StructureItemName },
  props: {
    info: {},
    dictionary: {}
  }
}
</script>
