import { TBaseTableHeaderItem, tableItemStyleOneGen } from '@/components/tw-ui/table/TableTypes'

const options: TBaseTableHeaderItem[] = [
  {
    ...tableItemStyleOneGen(),
    text: 'Дата загрузки',
    dataKey: 'createdAt',
    colStyle: 'width: 120px;'
  },
  {
    ...tableItemStyleOneGen(false),
    text: 'Результат',
    dataKey: 'result',
    colStyle: 'width: 120px;'
  },
  {
    ...tableItemStyleOneGen(),
    text: 'Загружено квартир',
    dataKey: 'apartmentsCount',
    colStyle: 'width: 120px;'
  },
  {
    ...tableItemStyleOneGen(false),
    text: 'Ошибка',
    dataKey: 'error',
    colStyle: 'width: auto;'
  }
]

export default options
