<template>
  <div class="tw-my-[15px]">
    <div>
      <div
        class="tw-my-[15px]"
        :class="wrapperClasses"
      >
        <div>
          <TwInputText
            v-model="form.fullName"
            type="text"
            label="ФИО"
            placeholder="ФИО"
            :error-text="$v.form.fullName.$invalid ? 'Обязательное поле':''"
            :disabled="formProcessing"
            :disable-error-border="true"
            :show-label="true"
            :persistent-label="true"
          />
        </div>
        <div>
          <TwInputPhone
            v-model="form.phone"
            :disabled="formProcessing"
            :disable-error-border="true"
            :show-label="true"
            :persistent-label="true"
            placeholder="Номер телефона"
            label="Номер телефона"
            :error-text="$v.form.phone.$invalid ? 'Обязательное поле':''"
          />
        </div>
        <div>
          <TwSelect
            v-model="form.objectType"
            label="Тип объекта"
            placeholder="Выбрать"
            :options="dictionary.objectType"
            :disabled="formProcessing"
            :error-text="$v.form.objectType.$invalid ? 'Обязательное поле':''"
            :disable-error-border="true"
            :persistent-label="true"
            @input="onChangeObjectType"
          />
        </div>
        <div v-if="isVisibleFromType.complexIds">
          <TwSelectRelations
            v-model="form.complexIds"
            :disabled="formProcessing"
            :multi="true"
            label="Выбрать ЖК"
            list-label="Поиск ЖК"
            relation-name="complex"
          />
        </div>
        <div v-if="isVisibleFromType.goal">
          <TwSelect
            v-model="form.goal"
            label="Цель покупки"
            placeholder="Выбрать"
            :options="dictionary.goal"
            :error-text="$v.form.goal.$invalid ? 'Обязательное поле':''"
            :disable-error-border="true"
            :persistent-label="true"
          />
        </div>
        <div v-if="isVisibleFromType.roomsCount">
          <TwSelectButton
            v-model="form.roomsCount"
            label="Количество комнат"
            :multi="true"
            :error-text="$v.form.roomsCount.$invalid ? 'Обязательное поле':''"
            :disabled="formProcessing"
            :disable-error-border="true"
            :options="dictionary.roomsCount"
          />
        </div>
        <div v-if="isVisibleFromType.commonAreaMin">
          <TwInputText
            v-model="form.commonAreaMin"
            type="text"
            max-length="5"
            sub-type="float"
            :label="hasNeedHouse ? 'Площадь дома' : 'Общая площадь'"
            append-slot-bg-class="tw-bg-an-flat-gray-17"
            prepend-slot-bg-class="tw-bg-an-flat-gray-17"
            :disabled="formProcessing"
            :disable-error-border="true"
            :show-label="true"
            :has-false-return-null="true"
            :persistent-label="true"
          >
            <template #prepend>
              от
            </template>
            <template #append>
              м²
            </template>
          </TwInputText>
        </div>
        <div v-if="isVisibleFromType.landAreaMin">
          <TwInputText
            v-model="form.landAreaMin"
            type="text"
            max-length="5"
            sub-type="float"
            label="Площадь З/У"
            append-slot-bg-class="tw-bg-an-flat-gray-17"
            prepend-slot-bg-class="tw-bg-an-flat-gray-17"
            :disabled="formProcessing"
            :disable-error-border="true"
            :show-label="true"
            :error-text="$v.form.landAreaMin.$invalid ? 'Обязательное поле':''"
            :has-false-return-null="true"
            :persistent-label="true"
          >
            <template #prepend>
              от
            </template>
            <template #append>
              сот.
            </template>
          </TwInputText>
        </div>
        <div>
          <TwInputText
            type="text"
            label="Бюджет на покупку, макс."
            max-length="13"
            sub-type="int"
            append-slot-bg-class="tw-bg-an-flat-gray-17"
            prepend-slot-bg-class="tw-bg-an-flat-gray-17"
            :value="$Global.inputFormatPrice.getStringSpaced(form.budgetMax)"
            :disabled="formProcessing"
            :show-label="true"
            :error-text="$v.form.budgetMax.$invalid ? 'Обязательное поле':''"
            :persistent-label="true"
            :disable-error-border="true"
            :has-false-return-null="true"
            @input="form.budgetMax = $Global.inputFormatPrice.getNumber($event)"
          >
            <template #prepend>
              до
            </template>
            <template #append>
              руб.
            </template>
          </TwInputText>
        </div>
        <div
          v-if="isVisibleFromType.paymentMethod"
          class="tw-flex tw-flex-wrap tw-gap-[10px] tw-items-center"
        >
          <div class="tw-flex-1">
            <TwSelect
              v-model="form.paymentMethod"
              label="Способ оплаты"
              placeholder="Выбрать"
              :options="dictionary.paymentMethod"
              :error-text="$v.form.paymentMethod.$invalid ? '*Обязательное поле':''"
              :disabled="formProcessing"
              :persistent-label="true"
              :disable-error-border="true"
              @input="onChangePaymentMethod"
            />
          </div>
          <div class="tw-mt-[15px]">
            <TwInputSwitch
              v-if="isVisibleFromType.initialPayment"
              v-model="form.initialPayment"
            >
              <template #label>
                <span class="tw-relative tw-z-10 tw-text-an-flat-grey-4 tw-font-manrope tw-font-semibold tw-text-[14px]">
                  <b>ПВ</b> <span :class="form.initialPayment ? 'tw-text-an-flat-red-main' : 'tw-text-an-flat-grey-4'">{{ form.initialPayment ? 'Есть' : 'Нет' }}</span>
                </span>
              </template>
            </TwInputSwitch>
          </div>
        </div>
        <div v-if="isVisibleFromType.subsidy">
          <TwSelect
            v-model="form.subsidy"
            label="Особые условия покупки"
            placeholder="Выбрать"
            :options="dictionary.subsidy"
            :disabled="formProcessing"
            :disable-error-border="true"
            :persistent-label="true"
          />
        </div>
      </div>
      <TwSpoiler
        v-show="form.objectType !== 'parking_space'"
        class="tw-min-w-full tw-flex-1"
        title-classes="tw-text-right"
        content-classes="tw-py-[15px]"
        title-variant="link"
      >
        <template #title="{ open }">
          {{ open ? 'Скрыть' : 'Указать' }} дополнительные параметры подбора
        </template>
        <div :class="wrapperClasses">
          <div v-if="isVisibleFromType.budgetMin">
            <TwInputText
              type="text"
              label="Бюджет на покупку, мин."
              max-length="13"
              sub-type="int"
              append-slot-bg-class="tw-bg-an-flat-gray-17"
              prepend-slot-bg-class="tw-bg-an-flat-gray-17"
              :value="$Global.inputFormatPrice.getStringSpaced(form.budgetMin)"
              :disabled="formProcessing"
              :show-label="true"
              :persistent-label="true"
              :has-false-return-null="true"
              @input="form.budgetMin = $Global.inputFormatPrice.getNumber($event)"
            >
              <template #prepend>
                от
              </template>
              <template #append>
                руб.
              </template>
            </TwInputText>
          </div>
          <div v-if="isVisibleFromType.kitchenAreaMin">
            <TwInputText
              v-model="form.kitchenAreaMin"
              type="text"
              max-length="5"
              sub-type="float"
              label="Площадь кухни"
              append-slot-bg-class="tw-bg-an-flat-gray-17"
              prepend-slot-bg-class="tw-bg-an-flat-gray-17"
              :disabled="formProcessing"
              :has-false-return-null="true"
              :show-label="true"
              :persistent-label="true"
            >
              <template #prepend>
                от
              </template>
              <template #append>
                м²
              </template>
            </TwInputText>
          </div>
          <div v-if="isVisibleFromType.readyQuarter">
            <TwSelect
              v-model="form.readyQuarter"
              label="Срок сдачи"
              placeholder="Выбрать"
              :options="dictionary.readyQuarter || []"
              :disabled="formProcessing"
              :multi="true"
              :persistent-label="true"
            />
          </div>
          <div v-if="isVisibleFromType.decoration">
            <TwSelect
              v-model="form.decoration"
              label="Отделка"
              placeholder="Выбрать"
              :options="dictionary.decoration"
              :disabled="formProcessing"
              :multi="true"
              :persistent-label="true"
            />
          </div>
          <div v-if="isVisibleFromType.wcType">
            <TwSelect
              v-model="form.wcType"
              label="Тип с/у"
              placeholder="Выбрать"
              :options="dictionary.wcType"
              :disabled="formProcessing"
              :persistent-label="true"
            />
          </div>
          <div v-if="isVisibleFromType.contractType">
            <TwSelect
              v-model="form.contractType"
              label="Тип договора"
              placeholder="Выбрать"
              :options="dictionary.contractType"
              :disabled="formProcessing"
              :persistent-label="true"
            />
          </div>
          <div v-if="isVisibleFromType.sidesWindow">
            <TwSelect
              v-model="form.sidesWindow"
              label="Направление окон"
              placeholder="Выбрать"
              :options="dictionary.sidesWindow"
              :multi="true"
              :disabled="formProcessing"
              :persistent-label="true"
            />
          </div>
          <div v-if="isVisibleFromType.balconyType">
            <TwSelect
              v-model="form.balconyType"
              label="Балкон"
              placeholder="Выбрать"
              :options="dictionary.balconyType"
              :disabled="formProcessing"
              :persistent-label="true"
            />
          </div>
        </div>
      </TwSpoiler>
    </div>

    <TwCardSimpleFormSaveBlock
      class="tw-mt-[15px]"
      :loading="formProcessing"
      :cancel-confirm="true"
      :is-sticky="true"
      :disable-success="formProcessing || $v.form.$invalid"
      :disable-cancel="formProcessing"
      :visible="true"
      @success="handleSubmit"
      @cancel="handleCancel"
    />
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import API from '@/Api/Crm/Deal/BuyNb/Questionnaire'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import TwInputText from '@/components/tw-ui/ui-kit/input/TwInputText.vue'
import TwSelect from '@/components/tw-ui/ui-kit/input/TwSelect.vue'
import TwInputSwitch from '@/components/tw-ui/ui-kit/input/TwInputSwitch.vue'
import TwSelectButton from '@/components/tw-ui/ui-kit/button/TwSelectButton.vue'
import TwCardSimpleFormSaveBlock from '@/components/tw-ui/forms/TwCardSimpleFormSaveBlock.vue'
import TwSpoiler from '@/components/tw-ui/other/TwSpoiler.vue'
import { GridWrapperForPanelShowAndForm } from '@/utils/tw-classes'
import TwSelectRelations from '@/components/tw-ui/ui-kit/input/select-relations/TwSelectRelations.vue'
import VisibleFieldsMixin from './VisibleFieldsMixin'
import TwInputPhone from '@/components/tw-ui/ui-kit/custom/TwInputPhone.vue'

export default {
  name: 'DealBuyNbQuestionnaireForm',
  components: {
    TwInputPhone,
    TwSelectRelations,
    TwSpoiler,
    TwCardSimpleFormSaveBlock,
    TwSelectButton,
    TwInputSwitch,
    TwSelect,
    TwInputText
  },
  mixins: [FormCreateEditMixin, VisibleFieldsMixin],
  props: {
    dealId: {
      type: Number,
      required: true
    },
    presetValues: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      relatedObjectId: this.dealId,
      wrapperClasses: GridWrapperForPanelShowAndForm.threeCols,
      API: API,
      form: {
        id: null,
        deal: null,
        fullName: null,
        phone: null,
        goal: null,
        budgetMin: null,
        budgetMax: null,
        objectType: null,
        complexIds: [],
        decoration: [],
        commonAreaMin: null,
        kitchenAreaMin: null,
        landAreaMin: null,
        wcType: null,
        contractType: null,
        sidesWindow: [],
        roomsCount: [],
        balconyType: null,
        paymentMethod: null,
        subsidy: null,
        readyQuarter: [],
        initialPayment: null
      },
      serverErrors: null
    }
  },
  validations () {
    return {
      form: {
        fullName: {
          required
        },
        phone: {
          required
        },
        roomsCount: {
          ...(this.form.objectType === 'apartment') && {
            required
          }
        },
        landAreaMin: {
          ...(this.isVisibleFromType.landAreaMin) && {
            required
          }
        },
        budgetMax: {
          required
        },
        paymentMethod: {
          required
        },
        goal: {
          required
        },
        objectType: {
          required
        }
      }
    }
  },
  methods: {
    onChangeObjectType (value) {
      console.warn('onChangeObjectType: ', value)
      // обнуляю поле
      if (value !== 'apartment' && value !== 'house') {
        this.form.roomsCount = []
      }
    },
    onChangePaymentMethod (value) {
      console.warn('onChangePaymentMethod: ', value)
      // если не ипотка
      if (value !== 'mortgage') {
        this.form.initialPayment = null
      } else {
        this.form.initialPayment = true
      }
    },
    handleSubmit () {
      console.warn('handleSubmit', this.form, this.$v.form.$invalid)
      this.$v.$reset()
      this.$v.form.$touch()
      if (!this.$v.form.$invalid) {
        this.formProcessing = true

        const request = this.formType === 'create'
          ? API.store(this.dealId, { ...this.form })
          : API.update(this.dealId, { ...this.form })

        request.then(({ data: result }) => {
          console.warn('SEND SUCCESS', result)
          this.$emit('refresh')
        }).catch((error) => {
          console.error('SEND ERROR', error)
        }).finally(() => {
          this.formProcessing = false
        })
      }
    },
    presetData () {
      console.warn(this.preset, this.presetValues)
      return new Promise((resolve) => {
        if (this.preset) {
          for (const key in this.preset) {
            if (this.presetValues?.[key]) {
              this.$set(this.form, key, this.presetValues[key])
            } else {
              this.$set(this.form, key, this.preset[key])
            }
          }
        }
        resolve()
      })
    }
  }
}
</script>
