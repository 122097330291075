<template>
  <div
    v-resize="updateHeight"
    :class="[wrapperClass, sizeClasses]"
  >
    <div>
      <div
        v-if="showLabel || errorText"
        class="tw-flex tw-justify-between tw-items-center tw-gap-[8px] tw-h-[21px]"
      >
        <label
          v-if="showLabel"
          :for="uid"
          :style="{ ...{ color: persistentLabel || focusState || localValue ? '' : 'transparent' } }"
          :class="persistentLabel || localValue || focusState ? 'tw-text-an-flat-dark-blue-link ' : ''"
          class="tw-px-[5px] tw-pointer-events-none tw-font-medium tw-truncate tw-text-main-sm2"
        >
          {{ label || placeholder }}
          <span
            v-if="isRequired"
            class="tw-text-an-flat-red-main tw-text-main-sm"
          >
            <slot name="requiredSymbol">*</slot>
          </span>
        </label>
        <label
          v-if="isRequired || label || persistentLabel || errorValues.length"
          :class="labelErrorClass"
          :for="uid"
        >
          <div
            :class="labelErrorClass"
            class="tw-truncate"
          >
            <span
              v-for="(err, index) in errorValues"
              :key="index"
              :title="err"
            >
              {{ err }}<span v-if="errorValues.length - 1 > index">,</span>
            </span>
          </div>
        </label>
      </div>
      <textarea
        :id="uid"
        ref="input"
        v-model="localValue"
        :maxlength="maxlength"
        :placeholder="placeholder"
        :rows="rows"
        :disabled="disabled"
        :readonly="readonly"
        :required="required"
        :autocomplete="autocomplete"
        :style="inputStyle"
        :class="[inputClassBase, inputClassFocused, inputClassDisabled, errorText ? inputClassError : '', resizeClass]"
        @focusin.passive="onFocus"
        @focusout.passive="onFocus"
      />
      <div
        v-if="showLength"
        class="tw-absolute tw-right-0 tw-bottom-0 tw-px-[6px] tw-py-[2px] tw-m-[4px]"
      >
        {{ value ? value.length : 0 }}/{{ maxlength || '-' }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TwTextarea',
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    maxlength: {
      type: [String, Number],
      default: null
    },
    showLength: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text'
    },
    // сжатые поля
    dense: {
      type: Boolean,
      default: false
    },
    autocomplete: {
      type: String,
      default: 'presentation' // off presentation
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    persistentLabel: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    rows: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    showLabel: {
      type: Boolean,
      default: false
    },
    wrapperClass: {
      type: String,
      default: 'tw-relative tw-font-manrope tw-text-main-sm tw-block'
    },
    resize: {
      type: String,
      default: 'none' // all | none | | x | y
    },
    autoResize: {
      type: Boolean,
      default: true
    },
    maxHeight: {
      type: String,
      default: '300px'
    },
    errorText: {
      type: [String, Array, Boolean],
      default: null
    },
    labelErrorClass: {
      type: String,
      default: 'tw-text-[12px] tw-text-an-flat-red-main'
    },
    inputClassBase: {
      type: String,
      default: 'tw-peer tw-border tw-border-solid tw-outline-1 tw-text-an-flat-black-main tw-rounded-sm tw-w-full tw-min-w-[180px] tw-pt-[15px] tw-pb-[17px] tw-bg-white tw-block placeholder:tw-text-an-flat-not-active-input enabled:hover:tw-drop-shadow-input'
    },
    inputClassFocused: {
      type: String,
      default: 'focus:tw-outline focus:tw-outline-an-flat-blue-light focus:placeholder:tw-text-opacity-40'
    },
    inputClassDisabled: {
      type: String,
      default: 'disabled:tw-bg-an-flat-disabled-input-bg disabled:tw-text-an-flat-disabled-input-text'
    },
    inputClassError: {
      type: String,
      default: 'tw-border-an-flat-red-main placeholder:tw-text-an-flat-red-main'
    }
  },
  data () {
    return {
      myInputModel: this.value,
      focusState: false,
      inputStyle: {}
    }
  },
  computed: {
    isRequired () {
      // определение обязательности поля
      // - если тестовая ошибка имеет дефолтное значение
      // и если текст лейбла показан над полем ввода
      return this.errorText !== '' && this.errorText !== null && (this.persistentLabel || this.localValue || this.focusState)
    },
    errorValues () {
      if (Array.isArray(this.errorText)) {
        return this.errorText
      } else if (this.errorText) {
        return [this.errorText]
      }
      return []
    },
    resizeClass () {
      // all | none | | x | y
      switch (this.resize) {
        case 'x':
          return 'tw-resize-x'
        case 'y':
          return 'tw-resize-y'
        case 'all':
          return 'tw-resize'
        default:
          return 'tw-resize-none'
      }
    },
    sizeClasses () {
      if (this.dense) {
        return 'tw-min-w-[180px] tw-text-main-xs'
      } else {
        return 'tw-min-w-[250px] tw-text-main-sm2'
      }
    },
    localValue: {
      get () {
        this.updateHeight()
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    },
    uid () {
      return `${this.$options.name}_${this._uid}`
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.updateHeight()
    })
  },
  methods: {
    onFocus (event) {
      console.warn(event.type)
      this.focusState = event.type === 'focusin'
    },
    focus () {
      setTimeout(() => {
        this.$refs.input?.focus()
      }, 0)
    },
    updateHeight () {
      const leftPadding = 23 + (this.$refs.prepend?.offsetWidth || 0)
      const rightPadding = 23 + (this.$refs.append?.offsetWidth || 0)
      this.inputStyle = {
        ...this.inputStyle,
        paddingLeft: `${leftPadding}px`,
        paddingRight: `${rightPadding}px`
      }
      if (this.autoResize && this.$refs.input) {
        if (this.$refs.input.scrollHeight > this.$refs.input.offsetHeight) {
          this.inputStyle = {
            ...this.inputStyle,
            height: (this.$refs.input.scrollHeight + 5) + 'px',
            maxHeight: this.maxHeight
          }
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
