<template>
  <div class="tw-font-manrope tw-max-w-[100%] tw-flex tw-gap-[16px] tw-pt-[6px]">
    <template v-if="isAllowPermissions">
      <div class="tw-flex-1">
        <BuilderDeveloperTerms :company-id="companyId" />
        <BuilderDeveloperCommission :company-id="companyId" />
        <BuilderDeveloperTradeIn :company-id="companyId" />
        <BuilderDeveloperReservationObjectBlock :company-id="companyId" />
      </div>
      <div class="tw-flex-1">
        <BuilderPetitionTemplates :company-id="companyId" />
        <BuilderFixationInfo :company-id="companyId" />
        <BuilderReservationTypes :company-id="companyId" />
        <BuilderHousingConsumerCooperativeInfo :company-id="companyId" />
      </div>
    </template>
    <template v-else>
      <div class="tw-flex-1 tw-pt-[50px] tw-text-main-lg tw-text-center tw-text-an-flat-disabled-text">
        Недостаточно прав доступа
      </div>
    </template>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import BuilderReservationTypes from '@/views/Dictionary/General/Company/developer/SettingsTab/BuilderReservationTypes/BuilderReservationTypes.vue'
import BuilderFixationInfo
  from '@/views/Dictionary/General/Company/developer/SettingsTab/BuilderFixationInfo/BuilderFixationInfo.vue'
import BuilderPetitionTemplates
  from '@/views/Dictionary/General/Company/developer/SettingsTab/BuilderPetitionTemplate/BuilderPetitionTemplates.vue'
import { mapGetters } from 'vuex'
import dictPermissionRules from '@/Permissions/dictRules'
import BuilderDeveloperTerms
  from '@/views/Dictionary/General/Company/developer/SettingsTab/BuilderDeveloperTerms/BuilderDeveloperTerms.vue'
import BuilderDeveloperCommission
  from '@/views/Dictionary/General/Company/developer/SettingsTab/BuilderDeveloperCommission/BuilderDeveloperCommission.vue'
import BuilderDeveloperTradeIn
  from '@/views/Dictionary/General/Company/developer/SettingsTab/BuilderDeveloperTradeIn/BuilderDeveloperTradeIn.vue'
import BuilderHousingConsumerCooperativeInfo
  from '@/views/Dictionary/General/Company/developer/SettingsTab/BuilderHousingConsumerCooperativeInfo/BuilderHousingConsumerCooperativeInfo.vue'
import BuilderDeveloperReservationObjectBlock
  from '@/views/Dictionary/General/Company/developer/SettingsTab/BuilderDeveloperReservationObjectBlock/BuilderDeveloperReservationObjectBlock.vue'

export default defineComponent({
  name: 'CompanyBuilderSettingsTab',
  components: { BuilderDeveloperReservationObjectBlock, BuilderHousingConsumerCooperativeInfo, BuilderDeveloperTradeIn, BuilderDeveloperCommission, BuilderDeveloperTerms, BuilderPetitionTemplates, BuilderFixationInfo, BuilderReservationTypes },
  props: {
    companyId: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'profile/isAdmin',
      allowNamespacesDict: 'profile/allowNamespacesDict'
    }),
    isAllowPermissions () {
      return this.isAdmin || !!this.allowNamespacesDict[dictPermissionRules.AppServicePermissionRulesOBJECTSNewBuildingsBuilderRequestBuilderRequestSettingsRule]
    }
  }
})
</script>

<style scoped>

</style>
