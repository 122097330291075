import { defineAsyncComponent } from 'vue'
import { API } from '@/Api/ObjectStorage'

export const TypesRealtyObjects = {
  land: {
    label: 'Участок',
    type: 'land',
    component: defineAsyncComponent(() => {
      return import('@/views/Newbuildings/Editor/LandHouse/NbEditorLandHouseCard.vue')
    }),
    API: API.Editor.Land
  },
  house: {
    label: 'Дом',
    type: 'house',
    component: defineAsyncComponent(() => {
      return import('@/views/Newbuildings/Editor/LandHouse/NbEditorLandHouseCard.vue')
    }),
    API: API.Editor.Land
  },
  building: {
    label: 'Многоквартирный дом',
    type: 'building',
    component: defineAsyncComponent(() => {
      return import('@/views/Newbuildings/Editor/Building/NbEditorBuildingCard.vue')
    }),
    API: API.Editor.Building,
    copyForm: {
      statusSuccess: false
    }
  },
  parking: {
    label: 'Паркинг (здание)',
    type: 'parking',
    component: defineAsyncComponent(() => {
      return import('@/views/Newbuildings/Editor/Parking/NbEditorParkingCard.vue')
    }),
    API: API.Editor.Parking,
    copyForm: {
      statusSuccess: false
    }
  },
  parking_space: {
    label: 'Парковочное место',
    type: 'parking_space',
    component: defineAsyncComponent(() => {
      return import('@/views/Newbuildings/Editor/ParkingSpace/NbEditorParkingSpaceCard.vue')
    }),
    API: API.Editor.ParkingSpace
  },
  entrance: {
    label: 'Вход',
    type: 'entrance',
    component: defineAsyncComponent(() => {
      return import('@/views/Newbuildings/Editor/Entrance/NbEditorEntranceCard.vue')
    }),
    API: API.Editor.Entrance,
    copyForm: {
      statusSuccess: false
    }
  },
  floor: {
    label: 'Этаж',
    type: 'floor',
    component: defineAsyncComponent(() => {
      return import('@/views/Newbuildings/Editor/Floor/NbEditorFloorCard.vue')
    }),
    API: API.Editor.Floor,
    copyForm: {
      statusSuccess: false
    }
  },
  apartment: {
    label: 'Квартира',
    type: 'apartment',
    component: defineAsyncComponent(() => {
      return import('@/views/Newbuildings/Editor/Apartment/NbEditorApartmentCard.vue')
    }),
    API: API.Editor.Apartment
  },
  commercialRoom: {
    label: 'Коммерция (помещение)',
    type: 'commercialRoom',
    component: defineAsyncComponent(() => {
      return import('@/views/Newbuildings/Editor/CommercialRoom/NbEditorCommercialRoomCard.vue')
    }),
    API: API.Editor.CommercialRoom
  },
  mall: {
    label: 'Торговый центр',
    type: 'mall',
    component: defineAsyncComponent(() => {
      return import('@/views/Newbuildings/Editor/NbEditorTypeNoneView.vue')
    }),
    API: null
  },
  business_center: {
    label: 'Бизнес центр',
    type: 'business_center',
    component: defineAsyncComponent(() => {
      return import('@/views/Newbuildings/Editor/NbEditorTypeNoneView.vue')
    }),
    API: null
  },
  room: {
    label: 'Комната в квартире',
    type: 'room',
    component: defineAsyncComponent(() => {
      return import('@/views/Newbuildings/Editor/NbEditorTypeNoneView.vue')
    }),
    API: null
  },
  room_communal: {
    label: 'Комната в коммуналке',
    type: 'room_communal',
    component: defineAsyncComponent(() => {
      return import('@/views/Newbuildings/Editor/NbEditorTypeNoneView.vue')
    }),
    API: null
  },
  room_dormitory: {
    label: 'Комната в общежитии',
    type: 'room_dormitory',
    component: defineAsyncComponent(() => {
      return import('@/views/Newbuildings/Editor/NbEditorTypeNoneView.vue')
    }),
    API: null
  },
  garage: {
    label: 'Гараж',
    type: 'garage',
    component: defineAsyncComponent(() => {
      return import('@/views/Newbuildings/Editor/NbEditorTypeNoneView.vue')
    }),
    API: null
  },
  townhouse: {
    label: 'Таунхаус',
    type: 'townhouse',
    component: defineAsyncComponent(() => {
      return import('@/views/Newbuildings/Editor/NbEditorTypeNoneView.vue')
    }),
    API: null
  },
  twinHouse: {
    label: 'Твин хаус',
    type: 'twinHouse',
    component: defineAsyncComponent(() => {
      return import('@/views/Newbuildings/Editor/NbEditorTypeNoneView.vue')
    }),
    API: null
  },
  dacha: {
    label: 'Дача',
    type: 'dacha',
    component: defineAsyncComponent(() => {
      return import('@/views/Newbuildings/Editor/NbEditorTypeNoneView.vue')
    }),
    API: null
  },
  bathhouse: {
    label: 'Баня',
    type: 'bathhouse',
    component: defineAsyncComponent(() => {
      return import('@/views/Newbuildings/Editor/NbEditorTypeNoneView.vue')
    }),
    API: null
  },
  summer_house: {
    label: 'Летний домик',
    type: 'summer_house',
    component: defineAsyncComponent(() => {
      return import('@/views/Newbuildings/Editor/NbEditorTypeNoneView.vue')
    }),
    API: null
  },
  guesthouse: {
    label: 'Гостевой дом',
    type: 'guesthouse',
    component: defineAsyncComponent(() => {
      return import('@/views/Newbuildings/Editor/NbEditorTypeNoneView.vue')
    }),
    API: null
  },
  swimming_pool: {
    label: 'Плавательный бассейн',
    type: 'swimming_pool',
    component: defineAsyncComponent(() => {
      return import('@/views/Newbuildings/Editor/NbEditorTypeNoneView.vue')
    }),
    API: null
  },
  commercialLand: {
    label: 'Коммерция (участок)',
    type: 'commercialLand',
    component: defineAsyncComponent(() => {
      return import('@/views/Newbuildings/Editor/NbEditorTypeNoneView.vue')
    }),
    API: null
  },
  commercialBuilding: {
    label: 'Коммерция (строение)',
    type: 'commercialBuilding',
    component: defineAsyncComponent(() => {
      return import('@/views/Newbuildings/Editor/NbEditorTypeNoneView.vue')
    }),
    API: null
  }
}

// Общий список типов для селектов
export const RealtyObjectTypeOptions = Object.values(TypesRealtyObjects).map((item) => {
  return {
    label: item.label,
    value: item.type
  }
})

// Отфильтрованный список типов, для получения информации только по необходимым типам
export const RealtyObjectTypeOptionsFilter = (targetTypes: Array<any>) => {
  return RealtyObjectTypeOptions.filter((type) => {
    return targetTypes.includes(type.value)
  })
}

export const RealtyComplexTypes = {
  residential_complex: {
    label: 'Жилой комплекс',
    value: 'residential_complex'
  },
  cottage_village: {
    label: 'Коттеджный посёлок',
    value: 'cottage_village'
  }
}

export const RealtyComplexTypesOptions = [
  {
    label: 'Не выбрано',
    value: null
  },
  ...Object.values(RealtyComplexTypes)
]
