import { RouteKeys } from '@/router/types'
import dictPermissionRules from '@/Permissions/dictRules'
import dealCommission from './dealCommission'
import nb from './nb'

export default {
  path: RouteKeys.accounting,
  name: RouteKeys.accounting,
  meta: { name: 'Бухгалтерия', icon: 'accounting' },
  redirect: { name: `${RouteKeys['deal-commission']}-list` },
  component: () => import('@/views/Site/Admin/Index.vue'),
  children: [
    dealCommission,
    {
      path: 'user-calc-list',
      name: `${RouteKeys.accounting}-user-calc-list`,
      meta: { name: 'Начисления', icon: 'report', hideInMenu: false, ruleNamespace: dictPermissionRules.AppServicePermissionRulesACCOUNTINGUserCalculationManagementRule },
      component: () => import('@/views/Accounting/UserCalc/List.vue')
    },
    nb
  ]
}
