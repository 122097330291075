<template>
  <div>
    <TwPanelContentBlock class="tw-mb-[6px]">
      <template #title>
        Комиссии застройщика
      </template>
      <template #action>
        <TwPanelContentBlockActionButton
          type="add"
          @click="onEditItem(0)"
        >
          добавить
        </TwPanelContentBlockActionButton>
      </template>
      <div>
        <div
          v-if="loading"
          class="tw-flex tw-text-center"
        >
          <TwCircleLoader class="tw-m-auto" />
        </div>
        <div
          v-else
          class=""
        >
          <table class="tw-table tw-table-auto tw-w-full tw-text-left">
            <thead class="tw-border-b">
              <tr>
                <th class="tw-p-[7px]" />
                <th class="tw-p-[7px]">
                  ЖК/Тип
                </th>
                <th class="tw-p-[7px]">
                  Комиссия
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-if="list && list.length">
                <tr
                  v-for="item in list"
                  :key="item.id"
                  class="tw-border-b odd:tw-bg-an-flat-bg-main even:tw-bg-white"
                >
                  <td class="tw-p-[7px]">
                    <button
                      class="tw-group"
                      title="Изменить"
                      @click.prevent="onEditItem(item.id)"
                    >
                      <svg
                        width="19"
                        height="19"
                        viewBox="0 0 19 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          class="group-enabled:group-hover:tw-fill-an-flat-red-main"
                          d="M10.3184 11.3375H7.66211V8.68755L15.3621 0.987549L18.0121 3.63755L10.3184 11.3375ZM8.91211 10.0875H9.79961L16.2434 3.63755L15.3621 2.7563L8.91211 9.20005V10.0875Z"
                          fill="#A2ABB5"
                        />
                        <path
                          class="group-enabled:group-hover:tw-fill-an-flat-red-main"
                          d="M16.8504 18.475H0.525391V2.15002H9.00039V3.40002H1.77539V17.225H15.6004V10H16.8504V18.475Z"
                          fill="#A2ABB5"
                        />
                        <path
                          class="group-enabled:group-hover:tw-fill-an-flat-red-main"
                          d="M3.63867 14.375H9.88867V15.625H3.63867V14.375Z"
                          fill="#A2ABB5"
                        />
                      </svg>
                    </button>
                  </td>
                  <td class="tw-p-[7px] tw-whitespace-nowrap">
                    <div>
                      <div
                        v-if="item.objectType"
                        class="tw-text-main-xs tw-text-an-flat-grey-4"
                      >
                        {{ item.objectType.label }}
                      </div>
                      <ViewPanelLink
                        v-if="item.complexId"
                        :id="item.complexId"
                        label="ЖК"
                        component="realty-complex-show-page"
                      >
                        <RealtyComplexName :id="item.complexId" />
                      </ViewPanelLink>
                      <div v-else>
                        Все ЖК
                      </div>
                    </div>
                  </td>
                  <td class="tw-p-2">
                    <div
                      v-if="item.type"
                      class="tw-text-right tw-text-main-xs tw-text-an-flat-grey-4"
                    >
                      {{ item.type.label || '~' }}
                    </div>
                    <div class="tw-text-right tw-text-main-sm2">
                      <template v-if="item.type && item.type.value === 'deal_percent'">
                        <b>{{ item.commission }}</b> %
                      </template>
                      <template v-else>
                        <b>{{ (item.commission || 0).toLocaleString('ru') }}</b> руб.
                      </template>
                    </div>
                  </td>
                </tr>
              </template>
              <tr v-else>
                <td
                  colspan="99"
                  class="tw-border-b tw-text-center tw-p-[7px] tw-text-an-flat-disabled-text"
                >
                  Нет данных
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </TwPanelContentBlock>
    <TwDialog
      :value="editDialog"
      max-width="600px"
      @input="editDialog = $event"
    >
      <template #header>
        <div class="tw-flex tw-justify-between">
          <div class="tw-text-h4">
            {{ editedId ? 'Изменить':'Добавить' }} комиссию
          </div>
          <button
            v-if="editedId"
            :disabled="loading"
            class="tw-w-[100px] tw-px-[15px] tw-py-[7px] tw-border tw-border-solid tw-border-an-flat-red-main tw-text-an-flat-red-main tw-rounded-sm enabled:hover:tw-text-white enabled:hover:tw-bg-an-flat-red-main"
            @click.prevent="onDeleteItem"
          >
            Удалить
          </button>
        </div>
      </template>
      <template #body>
        <div>
          <BuilderDeveloperCommissionForm
            :id="editedId"
            :company-id="companyId"
            @cancel="editDialog = false"
            @create="refresh"
            @update="refresh"
          />
        </div>
      </template>
    </TwDialog>
  </div>
</template>

<script>
import API from '@/Api/Rtd/Company/DeveloperCommission'
import TwDialog from '@/components/tw-ui/modal/TwDialog.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import RealtyComplexName from '@/components/other/complex/RealtyComplexName.vue'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink.vue'
import BuilderDeveloperCommissionForm
  from '@/views/Dictionary/General/Company/developer/SettingsTab/BuilderDeveloperCommission/BuilderDeveloperCommissionForm.vue'
import TwPanelContentBlockActionButton from '@/components/tw-ui/panels/TwPanelContentBlockActionButton.vue'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'

export default {
  name: 'BuilderDeveloperCommission',
  components: {
    TwPanelContentBlock,
    TwPanelContentBlockActionButton,
    BuilderDeveloperCommissionForm,
    ViewPanelLink,
    RealtyComplexName,
    TwCircleLoader,
    TwDialog
  },
  props: {
    companyId: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      list: [],
      loading: false,

      editDialog: false,
      editedId: 0
    }
  },
  mounted () {
    this.refresh()
  },
  methods: {
    onEditItem (itemId) {
      this.editDialog = true
      this.editedId = itemId
    },
    fetch () {
      this.editDialog = false
      this.editedId = 0
      this.loading = true
      API.getList({ companyId: this.companyId })
        .then(({ data: result }) => {
          this.list = result?.data || []
        })
        .catch((error) => {
          console.error(this.$options.name, error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    onDeleteItem () {
      if (confirm('Подтвердите удаление')) {
        API.delete(this.editedId)
          .finally(() => {
            this.fetch()
          })
      }
    },
    refresh () {
      this.fetch()
    }
  }
}
</script>
