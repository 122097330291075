/* Параметры socket.io
* https://socket.io/docs/v4/client-options/
*  */
export const IoOptions = {
  reconnection: true,
  reconnectionDelay: 6000,
  reconnectionDelayMax: 10000,
  reconnectionAttempts: 10
}

/* Схема событий сокетов */
// События Получния данных
const IN = {
  message: 'message',
  imChatCreated: 'im.chat.created',
  imMessageCreated: 'im.message.created',

  notificationСreated: 'notification.created',

  leadСreated: 'lead.created',
  leadStatusChanged: 'lead.status.changed',
  leadResponsibleСhanged: 'lead.responsible.changed',

  konturCheckReady: 'kontur.check.ready',
  konturCheckSingleSent: 'kontur.check-single.sent',
  konturCheckSingleReady: 'kontur.check-single.ready',
  konturCheckAnaliseReady: 'kontur.check.analise.ready',

  auth: 'auth' // Ответ на [user](проверки токена) - 1 || 0
}

// События Отправки данных
const OUT = {
  messageTest: 'message.test',
  userAuth: 'user.auth' // Запрос проверки токена пользователя { id: [userId], token: [stringToken] }
}

export const SOCKET_EVENT_MAP = {
  IN, // Входящее событие
  OUT // Исходящее событие
}
