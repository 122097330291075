<template>
  <details
    class="tw-select-none tw-font-manrope tw-text-main-sm"
    :style="open && hasShadow ? shadowStyle : ''"
    :class="calcClasses.details"
    :open="open"
    @toggle="onToggle"
  >
    <summary
      :class="[titleClasses, calcClasses.summary]"
      :style="!open && hasShadow ? shadowStyle : ''"
    >
      <slot
        name="title"
        v-bind="{ open }"
      >
        Title Slot Empty
      </slot>
      <svg
        v-if="titleVariant === 'block'"
        :style="{transform: `rotate(${open ? '180' : '0'}deg)`}"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path
          d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"
          fill="currentColor"
        />
        <path
          d="M0 0h24v24H0z"
          fill="none"
        />
      </svg>
    </summary>
    <div
      class="tw-text-left"
      :class="contentClasses"
    >
      <slot v-bind="{ open }" />
    </div>
  </details>
</template>

<script>
export default {
  name: 'TwSpoiler',
  props: {
    titleVariant: {
      type: String,
      default: 'link' // link || block
    },
    contentClasses: {
      type: String,
      default: ''
    },
    titleClasses: {
      type: String,
      default: ''
    },
    hasShadow: {
      type: Boolean,
      default: false
    },
    shadowStyle: {
      type: String,
      default: 'box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);'
    },
    arrowToRight: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      open: false
    }
  },
  computed: {
    calcClasses () {
      const result = {
        summary: [],
        details: []
      }
      if (this.titleVariant === 'block') {
        // todo детально описан вариант ссылки - блок нужно стилизовать
      } else if (this.titleVariant === 'link') {
        result.details.push('tw-text-right') // ссылка вправо
        result.summary.push('before:tw-hidden') // скрыть стрелочку
        result.summary.push('hover:tw-text-an-flat-dark-blue-link-active hover:tw-underline') // стилизовать под ссылку
        if (this.open) {
          result.summary.push('tw-text-an-flat-dark-blue-link tw-underline')
        } else {
          result.summary.push('tw-text-an-flat-gray-4')
        }
      }
      if (this.arrowToRight) {
        result.summary.push('before:tw-right-[0.3em]') // стрелка справа
      } else {
        result.summary.push('before:-tw-left-[0.3em]') // стрелка слева
      }
      return {
        summary: result.summary.join(' '),
        details: result.details.join(' ')
      }
    }
  },
  methods: {
    onToggle (event) {
      this.open = !!event?.target?.open
    }
  }
}
</script>

<style scoped>
  details{
    display:block;
    overflow:initial;
  }
  summary::-webkit-details-marker{display:none;}
  summary::-moz-list-bullet{list-style-type:none;}
  summary::marker{display:none;}
  summary {
    display:flex;
    justify-content: space-between;
    align-items: center;
    gap: 6px;
    cursor: pointer;
    position: relative;
  }
  summary:before{display:none;}
</style>
