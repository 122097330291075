<template>
  <div>
    <div
      v-if="loading"
      class="tw-h-full tw-w-full tw-flex tw-justify-center tw-items-center tw-py-5"
    >
      <TwCircleLoader
        height="50px"
        width="50px"
      />
    </div>
    <div v-else>
      <div
        :class="fromPanel ? 'tw-px-[26px] tw-py-[18px]':''"
        class="tw-font-manrope tw-text-main-sm2 tw-max-w-[100%] tw-bg-white tw-min-h-screen"
      >
        <TwPanelTitle v-if="fromPanel">
          <template #left>
            <div class="tw-text-h4 tw-font-bold">
              <div>
                Заголовок панели
                <span class="tw-text-an-flat-disabled-text tw-font-medium"> # id</span>
              </div>
            </div>
          </template>
          <template #right>
            <TwPanelActionButton
              :disabled="editMain"
              class="tw-mr-2"
              color="green"
              @click.stop="onTodo"
            >
              Создать новый
            </TwPanelActionButton>
            <TwPanelActionButton
              :disabled="editMain"
              class="tw-mr-2"
              color="gray"
              @click.stop="onTodo"
            >
              Копировать
            </TwPanelActionButton>
            <TwPanelActionButton
              :disabled="editMain"
              title="Удаление"
              color="red"
              @click.prevent="onTodo"
            >
              Удалить
            </TwPanelActionButton>
          </template>
        </TwPanelTitle>
        <TwPanelContent>
          <TwPanelInfoBlock
            type="error"
            class="tw-flex-1 tw-mb-[6px]"
          >
            <template #title>
              Важно
            </template>
            <template #text>
              Пример информирования о чем либо глобальном
            </template>
          </TwPanelInfoBlock>
        </TwPanelContent>
        <TwPanelTabsFull
          v-model="currentTab"
          :items="tabList"
          :disabled="editMain"
        >
          <template #content.main>
            <TwPanelContent>
              <!-- LEFT -->
              <div class="tw-flex-1">
                <TwPanelContentBlock class="tw-mb-[6px]">
                  <template #title>
                    <div>
                      О объекте
                    </div>
                  </template>
                  <template #action>
                    <TwPanelContentBlockActionButton
                      type="edit"
                      @click="editMain = !editMain"
                    >
                      {{ editMain ? 'отменить' : 'изменить' }}
                    </TwPanelContentBlockActionButton>
                  </template>
                  <div class="tw-min-h-[350px] tw-flex tw-flex-col tw-gap-[12px] tw-font-medium">
                    <div
                      v-if="editMain"
                      class="tw-text-an-flat-red-main"
                    >
                      Компонент формы
                    </div>
                    <div
                      v-else
                      class="tw-text-an-flat-green-btn-bg"
                    >
                      Компонент просмотра инфо
                    </div>
                  </div>
                </TwPanelContentBlock>
                <TwPanelContentBlock
                  class="tw-mb-[6px]"
                  no-data-text="no data text example prop"
                >
                  <template #title>
                    <div>
                      Инфо 1
                    </div>
                  </template>
                </TwPanelContentBlock>
              </div>
              <!-- RIGHT -->
              <div class="tw-flex-1">
                <TwPanelContentBlock class="tw-mb-[6px]">
                  <template #title>
                    <div>
                      Пример с полями просмотра
                    </div>
                  </template>
                  <div class="tw-flex tw-gap-[12px] tw-flex-col">
                    <TwPanelContentBlockFieldView>
                      <template #title>
                        Жилой комплекс
                      </template>
                      <div>
                        Пример 1
                      </div>
                    </TwPanelContentBlockFieldView>
                    <TwPanelContentBlockFieldView>
                      <template #title>
                        Название планировки
                      </template>
                      <div>
                        Пример 2
                      </div>
                    </TwPanelContentBlockFieldView>
                    <TwPanelContentBlockFieldView>
                      <template #title>
                        Студия
                      </template>
                      <div v-if="false">
                        Пример 3
                      </div>
                    </TwPanelContentBlockFieldView>
                    <TwPanelContentBlockFieldView :horizontal="false">
                      <template #title>
                        Принудительное вертикальное расположение
                      </template>
                      <div>
                        Пример 4 Пример 4 Пример 4 Пример 4 Пример 4 Пример 4   Пример 4 Пример 4 Пример 4 Пример 4 Пример 4 Пример 4
                      </div>
                    </TwPanelContentBlockFieldView>
                  </div>
                </TwPanelContentBlock>
                <TwPanelInfoBlock
                  type="warning"
                  class="tw-mb-[6px]"
                >
                  <template #title>
                    Важно
                  </template>
                  <template #text>
                    Пример информирования о чем либо
                  </template>
                </TwPanelInfoBlock>
                <TwPanelContentBlock
                  class="tw-mb-[6px]"
                  :content-classes="''"
                >
                  <template #title>
                    <div>
                      Еще какойто блок без фона контента
                    </div>
                  </template>
                  <div class="tw-min-h-[350px]">
                    TODO
                  </div>
                </TwPanelContentBlock>
              </div>
            </TwPanelContent>
          </template>
          <template #content.other>
            <div class="tw-flex-1">
              <TwPanelInfoBlock
                type="info"
                class="tw-mb-[6px]"
              >
                <template #title>
                  Важно
                </template>
                <template #text>
                  Пример информирования о чем либо
                </template>
              </TwPanelInfoBlock>
              <TwPanelContentBlock
                class="tw-mb-[6px]"
                :content-classes="''"
              >
                <template #title>
                  <div>
                    Контент на полную ширину
                  </div>
                </template>
                <div>
                  TODO
                </div>
              </TwPanelContentBlock>
              <TwPanelContentBlock class="tw-mb-[6px]">
                <template #title>
                  <div>
                    Контент на полную ширину
                  </div>
                </template>
                <div>
                  TODO
                </div>
              </TwPanelContentBlock>
            </div>
          </template>
        </TwPanelTabsFull>
      </div>
    </div>
  </div>
</template>

<script>
import CardMixin from '@/mixins/CardMixin'
import TwPanelTabsFull from '@/components/tw-ui/panels/TwPanelTabsFull.vue'
import TwPanelContent from '@/components/tw-ui/panels/TwPanelContent.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import TwPanelTitle from '@/components/tw-ui/panels/TwPanelTitle.vue'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwPanelContentBlockActionButton from '@/components/tw-ui/panels/TwPanelContentBlockActionButton.vue'
import TwPanelActionButton from '@/components/tw-ui/panels/TwPanelActionButton.vue'
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import TwPanelInfoBlock from '@/components/tw-ui/panels/TwPanelInfoBlock.vue'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'

export default {
  name: 'SamplePanelCard',
  components: {
    TwPanelContentBlockFieldView,
    TwPanelInfoBlock,
    TwPanelActionButton,
    TwPanelContentBlockActionButton,
    TwPanelContentBlock,
    TwPanelTitle,
    TwCircleLoader,
    TwPanelContent,
    TwPanelTabsFull
  },
  mixins: [CardMixin, ViewPanelShowMixin],
  props: {
    complexId: {
      type: [Number, String],
      default: null
    },
    builderId: {
      type: [Number, String],
      default: null
    }
  },
  data () {
    return {
      currentTab: 'main',
      API: { // TODO Это реализация ФЕЙКОВОГО апи. Тут нужно подскавлять импотр необходимого сервиса
        show () {
          return new Promise(resolve => {
            setTimeout(() => {
              resolve({ data: {} })
            }, 2500)
          })
        }
      }
    }
  },
  computed: {
    tabList () {
      return [
        {
          label: 'Общая информация',
          key: 'main'
        },
        {
          label: 'Ещё одна вкладка',
          key: 'other'
        }
      ]
    }
  },
  methods: {
    onTodo () {
      console.warn('TODOD')
    }
  }
}
</script>
