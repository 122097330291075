<template>
  <div
    v-if="loading && !data"
    class="tw-h-full tw-w-full tw-flex tw-justify-center tw-items-center tw-py-5"
  >
    <TwCircleLoader
      height="50px"
      width="50px"
    />
  </div>
  <div v-else>
    <div
      :class="fromPanel ? 'tw-px-[26px] tw-py-[18px]':''"
      class="tw-font-manrope tw-text-main-sm2 tw-max-w-[100%] tw-bg-white tw-min-h-screen"
    >
      <TwPanelTitle v-if="fromPanel">
        <template #left>
          <div class="tw-text-h4 tw-font-bold">
            <div
              v-if="!isNew && data"
              class="tw-flex tw-flex-row tw-gap-1"
            >
              <ClickBoard :value="`Анкета по программе ${data?.program?.name} # ${data.id}`" />
              <span>Анкета по программе</span>
              <span>{{ data?.program?.name }}</span>
              <span class="tw-text-an-flat-disabled-text tw-font-medium"> # {{ data.id }}</span>
            </div>
            <div v-else>
              Написать письмо
            </div>
          </div>
        </template>
      </TwPanelTitle>
      <div class="tw-grid tw-grid-cols-2 tw-gap-2 tw-mb-4">
        <div class="tw-col-span-2 lg:tw-col-span-1 tw-flex tw-flex-col tw-gap-2">
          <TwPanelContentBlock>
            <template #title>
              <div>О анкете</div>
            </template>
            <template #action>
              <button
                class=""
                :disabled="isNew || loading"
                @click="editMain = !editMain"
              >
                {{ editMain ? 'отменить':'изменить' }}
              </button>
            </template>
            <template #default>
              <MortgageProgramWorksheetMainShow
                v-if="!isNew && !editMain"
                :dictionary="dictionary"
                :info="data"
                :additional-data="additionalData"
                @refresh="refresh"
              />
              <MortgageProgramWorksheetMainForm
                v-else
                v-bind="$props"
                :id="data ? data.id : 0"
                :dictionary="dictionary"
                @cancel="editMain = false"
                @create="onCreated"
                @update="onUpdated"
              />
            </template>
          </TwPanelContentBlock>
          <!-- Сгенерированные документы - Анкеты -->
          <TwPanelContentBlock>
            <template #title>
              <div>
                Шаблоны документов
              </div>
            </template>
            <template #default>
              <div
                v-if="data?.program"
                class="tw-text-center tw-flex tw-items-center tw-flex-wrap tw-gap-4"
              >
                <DocumentListModal
                  v-if="data?.program?.company"
                  section="mortgage"
                  subsection="bank"
                  :deal-program-id="data.id"
                  :entity-id="data?.program?.company?.id"
                  :title="`Документы банка`"
                  :deal="data.deal"
                  :deal-id="dealId"
                >
                  <button
                    class="tw-my-2"
                    :class="buttonClass"
                  >
                    Анкета и другие документы банка
                  </button>
                </DocumentListModal>
                <DocumentListModal
                  section="mortgage"
                  subsection="program"
                  :title="`Документы программы - ${data?.program?.name}`"
                  :entity-id="data.id"
                  :deal="data.deal"
                  :deal-id="dealId"
                >
                  <button
                    type="button"
                    class="tw-mb-2"
                  >
                    Документы программы
                  </button>
                </DocumentListModal>
              </div>
              <div v-else>
                Нет данных
              </div>
            </template>
          </TwPanelContentBlock>
        </div>
        <div class="tw-col-span-2 lg:tw-col-span-1 tw-flex tw-flex-col tw-gap-2">
          <!-- Интерфейс прикрепления документов клиента-персоны -->
          <TwPanelContentBlock>
            <template #title>
              <div>Отправка анкеты в банк</div>
            </template>
            <template #default>
              <div v-if="data">
                <ol class="tw-flex tw-flex-col tw-gap-4">
                  <li>
                    Создайте черновик письма (кнопка ниже)
                  </li>
                  <li>
                    Откройте черновик
                  </li>
                  <li>
                    Нажмите изменить
                  </li>
                  <li>
                    Отредактируйте / прикрепите файлы
                  </li>
                  <li>
                    Нажмите сохранить черновик или можно сразу отправить
                  </li>
                </ol>
              </div>
            </template>
          </TwPanelContentBlock>
          <DealProgram2BankBlock
            v-if="data"
            :deal-program="data"
            :additional-data="additionalData"
            :person-ids="personIds"
          />
        </div>
      </div>
      <!-- Таблица отправленных Email писем в банк -->
      <MortgageProgramWorksheetMainEmails
        v-if="data"
        :info="data"
        :worksheet-id="data.id"
        :loading="loading"
        @refresh="refresh"
      />
    </div>
  </div>
</template>

<script>
import API from '@/Api/Crm/Deal/Mortgage/Program'
import CardMixin from '@/mixins/CardMixin'
import MortgageProgramWorksheetMainForm from '@/views/MortgageProgramWorksheet/main/MortgageProgramWorksheetMainForm'
import MortgageProgramWorksheetMainShow from '@/views/MortgageProgramWorksheet/main/MortgageProgramWorksheetMainShow'
import DocumentListModal from '@/views/Dictionary/General/Document/DocumentDownloadListModal'
import MortgageProgramWorksheetMainEmails from '@/views/MortgageProgramWorksheet/main/MortgageProgramWorksheetMainEmails'
import ClickBoard from '@/components/button/ClickBoard'
import DealProgram2BankBlock from '@/views/MortgageProgramWorksheet/dealProgram2Bank/DealProgram2BankBlock'
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import TwPanelTitle from '@/components/tw-ui/panels/TwPanelTitle.vue'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import { button } from '@/utils/tw-classes'

export default {
  name: 'MortgageProgramWorksheetCard',
  components: {
    TwPanelContentBlock,
    TwPanelTitle,
    TwCircleLoader,
    DealProgram2BankBlock,
    ClickBoard,
    MortgageProgramWorksheetMainEmails,
    DocumentListModal,
    MortgageProgramWorksheetMainShow,
    MortgageProgramWorksheetMainForm
  },
  mixins: [CardMixin, ViewPanelShowMixin],
  props: {
    dealId: {
      type: [Number, String, null],
      default: null
    }
  },
  data () {
    return {
      buttonClass: button.pageListHeaderCreate,
      API: API,
      editMain: false,
      editSend: false,
      sendFormEdited: false
    }
  },
  computed: {
    personIds () {
      const list = []
      if (this.data?.deal) {
        if (this.data?.deal?.person?.id) {
          list.push(this.data?.deal?.person?.id)
        }
        if (Array.isArray(this.data?.deal?.otherPersons) && this.data?.deal?.otherPersons.length) {
          this.data?.deal?.otherPersons.forEach(person => {
            list.push(person.id)
          })
        }
      }
      return list
    }
  }
}
</script>
