import { AxiosResponse } from 'axios'
import HTTP from '@/Api/http'
import { AuthAxiosDefaultConfig } from '@/Api/ObjectStorage'

const path = '/deal/analyze'

export default {
  priceChangeForAllComplexes (param?: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig,
      params: param
    }
    return HTTP.get(`${path}/priceChangeForAllComplexes`, $config)
  },
  priceChangeForComplex (complexId: string, param?: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig,
      params: param
    }
    return HTTP.get(`${path}/priceChangeForComplex/${complexId}`, $config)
  },
  priceChangeForBuilding (buildingId: string, param?: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig,
      params: param
    }
    return HTTP.get(`${path}/priceChangeForBuilding/${buildingId}`, $config)
  }
}
