<template>
  <div class="tw-font-manrope tw-flex tw-flex-col tw-gap-[16px]">
    <div style="display: none">
      {{ isEdited }}
    </div>

    <TwSelect
      v-model="form.education"
      label="Образование"
      placeholder="Выбрать образование"
      :persistent-label="true"
      :options="educationOptions"
    />

    <div class="tw-flex tw-gap-[10px] tw-flex-wrap">
      <TwInputDate
        v-model="form.experienceSince"
        class="tw-flex-1"
        label="Дата начала работы в компании"
        placeholder="Дата начала работы в компании"
        :disabled="formProcessing"
      />
      <TwInputText
        v-model.number="form.position"
        class="tw-flex-1"
        type="text"
        autocomplete="off"
        label="Должность"
        placeholder="Должность"
        cleanable
        :disabled="formProcessing"
        :show-label="true"
      />
    </div>

    <TwTextEditorWrapper
      v-model="form.achievements"
      label="Достижения"
      :disabled="formProcessing"
    />
    <div class="tw-flex tw-gap-[10px] tw-flex-wrap">
      <TwInputSwitch
        v-model="form.best"
        :disabled="formProcessing"
        class="tw-flex-1"
      >
        <template #label>
          <span class="tw-relative tw-text-an-flat-grey-4 tw-font-manrope tw-font-semibold tw-text-[14px]">
            Лучший сотрудник:
            <span :class="form.best ? 'tw-text-an-flat-red-main' : 'tw-text-an-flat-grey-4'">
              {{ form.best ? 'Да' : 'Нет' }}
            </span>
          </span>
        </template>
      </TwInputSwitch>
      <TwSelectButton
        ref="gender"
        v-model="form.hasAuto"
        class="tw-flex-1"
        label="Наличие автомобиля"
        :disabled="formProcessing"
        :options="hasAutoOptions"
      />
    </div>

    <TwCardSimpleFormSaveBlock
      class="tw-mt-[15px]"
      :loading="formProcessing"
      :cancel-confirm="isEdited"
      :is-sticky="true"
      :disable-success="!isEdited || formProcessing"
      :disable-cancel="formProcessing || (formType === 'create' && !isEdited)"
      :visible="true"
      @success="handleSubmit"
      @cancel="handleCancel"
    />
  </div>
</template>

<script>
import API from '@/Api/Auth/User/about'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import TwTextEditorWrapper from '@/components/tw-ui/ui-kit/input/TwTextEditorWrapper.vue'
import TwSelect from '@/components/tw-ui/ui-kit/input/TwSelect.vue'
import TwInputText from '@/components/tw-ui/ui-kit/input/TwInputText.vue'
import TwSelectButton from '@/components/tw-ui/ui-kit/button/TwSelectButton.vue'
import TwInputSwitch from '@/components/tw-ui/ui-kit/input/TwInputSwitch.vue'
import TwCardSimpleFormSaveBlock from '@/components/tw-ui/forms/TwCardSimpleFormSaveBlock.vue'
import TwInputDate from '@/components/tw-ui/ui-kit/input/TwInputDate.vue'

export default {
  name: 'EmployeeAboutForm',
  components: {
    TwInputDate,
    TwCardSimpleFormSaveBlock,
    TwInputSwitch,
    TwSelectButton,
    TwInputText,
    TwSelect,
    TwTextEditorWrapper
  },
  mixins: [FormCreateEditMixin],
  props: {
    userId: {
      type: [Number, String],
      required: true
    }
  },
  data () {
    return {
      API: API,
      currentYear: new Date().getFullYear(),
      form: {
        id: null,
        achievements: '',
        best: null,
        createdAt: null,
        education: null,
        experienceSince: null,
        hasAuto: null,
        userId: null // TODO пробросить с родителя
      },
      hasAutoOptions: [
        {
          label: 'Не указано',
          value: null
        },
        {
          label: 'Да',
          value: true
        },
        {
          label: 'Нет',
          value: false
        }
      ]
    }
  },
  computed: {
    educationOptions () {
      return [
        {
          label: 'Основное общее образование(9 классов)',
          value: 'Основное общее образование(9 классов)'
        },
        {
          label: 'Среднее общее образование(11 классов)',
          value: 'Среднее общее образование(11 классов)'
        },
        {
          label: 'Среднее профессиональное образование',
          value: 'Среднее профессиональное образование'
        },
        {
          label: 'Высшее образование - бакалавриат',
          value: 'Высшее образование - бакалавриат'
        },
        {
          label: 'Высшее образование - специалитет, магистратура',
          value: 'Высшее образование - специалитет, магистратура'
        },
        {
          label: 'Высшее образование - подготовка кадров высшей квалификации',
          value: 'Высшее образование - подготовка кадров высшей квалификации'
        }
      ]
    }
  },
  methods: {
    handleResetCustom () {
      this.handleReset()
    },
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          for (const key in this.preset) {
            this.$set(this.form, key, this.preset[key])
          }
        }

        this.form.userId = this.userId
        resolve()
      })
    },
    handleSubmit () {
      if (this.formProcessing) {
        return
      }

      this.formProcessing = true
      console.log('this.form', this.form)
      const request = this.formType === 'create'
        ? this.API.store(this.form)
        : this.API.update(this.targetId, this.form)

      request.then(({ data }) => {
        this.$emit('refresh', data.data)
      }).catch((error) => {
        console.log(error)
      }).finally(() => {
        this.formProcessing = false
      })
    }
  }
}
</script>
