<template>
  <div>
    <div
      id="fullscreen"
      class="tw-relative fullscreen"
    >
      <div
        :id="editorUid"
        class="editor tw-bg-an-flat-bg-main"
      />
      <div
        v-if="allowPlanMarker && fileId"
        class="sidepanel tw-absolute tw-left-0 tw-bottom-0 tw-mb-[13px] tw-ml-[20px]"
      >
        <div
          class="tw-flex tw-gap-[12px] tw-mb-[13px] tw-px-[12px] tw-py-[10px] tw-rounded-md tw-bg-white"
        >
          <TwPanelActionButton
            :disabled="actionsState.draw"
            color="white"
            size="sm"
            @click.stop="hasPolygonDrawed ? onClear() : onStartDrawing()"
          >
            <template slot#icon>
              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.6667 1.66667H10V0H11.6667V1.66667ZM13.3333 5H15V3.33333H13.3333V5ZM13.3333 8.33333H15V6.66667H13.3333V8.33333ZM6.66667 15H8.33333V13.3333H6.66667V15ZM3.33333 1.66667H5V0H3.33333V1.66667ZM0 11.6667H1.66667V10H0V11.6667ZM1.66667 15V13.3333H0C0 14.25 0.75 15 1.66667 15ZM13.3333 0V1.66667H15C15 0.75 14.25 0 13.3333 0ZM6.66667 1.66667H8.33333V0H6.66667V1.66667ZM0 5H1.66667V3.33333H0V5ZM3.33333 15H5V13.3333H3.33333V15ZM0 8.33333H1.66667V6.66667H0V8.33333ZM0 1.66667H1.66667V0C0.75 0 0 0.75 0 1.66667ZM13.0917 11.9083L14.2917 10.7083C14.5583 10.4417 14.3667 10 14 10H10.8333C10.375 10 10 10.375 10 10.8333V13.9917C10 14.3667 10.45 14.55 10.7083 14.2833L11.9083 13.0833L13.575 14.75C13.9 15.075 14.425 15.075 14.75 14.75C15.075 14.425 15.075 13.9 14.75 13.575L13.0917 11.9083Z"
                  fill="currentColor"
                />
              </svg>
            </template>
            {{ hasPolygonDrawed ? 'Сбросить выделение' : 'Выделить планировку' }}
          </TwPanelActionButton>
          <TwPanelActionButton
            v-if="actionsState.draw"
            color="red"
            size="sm"
            @click.stop="onStopDrawing"
          >
            отмена
          </TwPanelActionButton>
          <TwPanelActionButton
            :disabled="actionsState.draw || !hasPolygonDrawed"
            :color="actionsState.modify ? 'blue':'white'"
            size="sm"
            @click.stop="onModifyPolygon"
          >
            {{ actionsState.modify ? 'Завершить редактирование' : 'Редактировать' }}
          </TwPanelActionButton>
        </div>
      </div>
      <div
        v-if="!fileId"
        class="tw-absolute tw-top-1/2 tw-left-1/2 tw-transform -tw-translate-x-1/2 -tw-translate-y-1/2 tw-flex tw-flex-col tw-justify-center tw-items-center"
      >
        <svg
          width="62"
          height="59"
          viewBox="0 0 62 59"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_117_6174)">
            <g clip-path="url(#clip1_117_6174)">
              <g clip-path="url(#clip2_117_6174)">
                <path
                  d="M57.5403 32.5171C57.7643 31.5561 57.8931 30.5601 57.8931 29.5345C57.8931 22.0936 51.6842 16.0607 44.0241 16.0607C42.5076 16.0607 41.0515 16.3055 39.6864 16.7425C36.3287 11.8058 30.569 8.54688 24.022 8.54688C13.6597 8.54688 5.25871 16.7075 5.25871 26.7747C5.25871 28.3707 5.4717 29.9186 5.86776 31.3943C3.01964 33.3003 1.15039 36.4869 1.15039 40.1003C1.15039 45.9363 6.01972 50.6673 12.0278 50.6673H49.9767C55.9854 50.6673 60.8541 45.9363 60.8541 40.1003C60.8541 37.1224 59.5823 34.4374 57.5403 32.5171ZM41.3103 35.8697L31.3049 45.5889C31.2238 45.6678 31.1164 45.7105 31.0022 45.7105C30.8875 45.7105 30.7807 45.6678 30.7002 45.5889L20.6948 35.8697C20.6143 35.7914 20.5691 35.6865 20.5691 35.5762C20.5691 35.466 20.6137 35.3616 20.6948 35.2828L23.5167 32.542C23.6778 32.3843 23.9598 32.3843 24.1215 32.542L28.4263 36.7234V18.7623C28.4263 18.5335 28.6185 18.3467 28.8541 18.3467H33.1516C33.3878 18.3467 33.5794 18.5329 33.5794 18.7623V36.7228L37.8836 32.5414C38.046 32.3837 38.3279 32.3837 38.489 32.5414L41.3103 35.2822C41.3914 35.361 41.4366 35.4654 41.4366 35.5756C41.4366 35.6859 41.3902 35.7914 41.3103 35.8697Z"
                  fill="#D6DCE3"
                />
              </g>
            </g>
          </g>
          <defs>
            <clipPath id="clip0_117_6174">
              <rect
                width="62"
                height="58"
                fill="white"
                transform="translate(0 0.0170898)"
              />
            </clipPath>
            <clipPath id="clip1_117_6174">
              <rect
                width="62"
                height="58"
                fill="white"
                transform="translate(0 0.356934)"
              />
            </clipPath>
            <clipPath id="clip2_117_6174">
              <rect
                width="59.7037"
                height="58"
                fill="white"
                transform="translate(1.15039 0.605957)"
              />
            </clipPath>
          </defs>
        </svg>
        <span class="tw-text-[#7A8797]">
          Вам необходимо загрузить фотографию плана этажа
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import Map from 'ol/Map'
import View from 'ol/View'
import { Projection, addCoordinateTransforms, transform } from 'ol/proj'
import { doubleClick } from 'ol/events/condition'
// for drawing
import Draw from 'ol/interaction/Draw'
import Modify from 'ol/interaction/Modify'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import { drawingStyles, CompasRotateControl, FullScreenControl } from './PolygonEditorUtils.js'
import TwPanelActionButton from '@/components/tw-ui/panels/TwPanelActionButton.vue'

import { Polygon } from 'ol/geom'
import Feature from 'ol/Feature'

import ImageLayer from 'ol/layer/Image'
import Static from 'ol/source/ImageStatic'
import { getCenter } from 'ol/extent'
import { radToDeg, degreeNormalize360, roundByKoef } from './rotateHelpers.js'

export default {
  name: 'ImageCompasAndPolygonsEditor',
  components: { TwPanelActionButton },
  props: {
    fileId: {
      type: Number,
      default: null
    },
    fileApi: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    allowRotateEdit: { // Можно устанавливать направление сторон света
      type: Boolean,
      default: false
    },
    allowPlanMarker: { // Можно рисовать полигон
      type: Boolean,
      default: false
    },
    angle: { // угол поворота компаса
      type: Number,
      default: null
    },
    polygon: { // Полигон выделенный на изображении
      type: Array,
      default: () => []
    },
    otherPolygons: { // Полигон выделенный на изображении
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      projection: null,
      projectionWebCoords: null,
      extent: [0, 0, 0, 0],
      actionsState: {
        draw: false,
        modify: false
      },
      otherPolygonsSource: new VectorSource({ wrapX: false }),
      otherPolygonsLayer: null,
      drawingSource: new VectorSource({ wrapX: false }),
      drawingLayer: null,
      modifyInteraction: null,
      drawInteraction: null,
      editorInstance: null,
      view: null,
      targetImageLayer: null,
      compasRotator: null,
      fullScreen: null,
      currentRotateAngle: 0
    }
  },
  methods: {
    getFileInfoById () {
      if (!this.fileApi?.show) {
        throw Error('Неопределён API для получения информации о файле')
      }
      if (this.editorInstance) {
        this.editorClear()
        this.compasAngleChange(null)
      }
      if (!this.fileId) {
        console.warn('Отсутствует файл')
        return
      }
      this.fileApi.show(this.fileId)
        .then(async ({ data }) => {
          console.warn('getFileInfoById', data.data)
          await this.editorChangeImage(data.data?.url)
        })
        .catch((error) => {
          console.error(error, this.$options.name, 'ImageCompasAndPolygonsEditor')
        })
    },
    editorClear () {
      if (this.editorInstance) {
        this.drawingSource.clear()
        this.otherPolygonsSource.clear()
        this.editorInstance.removeLayer(this.otherPolygonsLayer)
        this.editorInstance.removeLayer(this.drawingLayer)
        this.editorInstance.removeLayer(this.targetImageLayer)
        this.editorInstance.removeControl(this.compasRotator)
        this.editorInstance.removeControl(this.fullScreen)
        this.editorInstance.setView(null)
      }
    },
    async editorChangeImage (imageUrl) {
      // Инит изображения
      const img = await this.addImageProcess(imageUrl)
      this.extent = [0, -img.height, img.width, 0]
      this.projection = new Projection({
        code: 'BASE_LB',
        units: 'pixels',
        extent: this.extent
      })
      this.projectionWebCoords = new Projection({
        code: 'WEB_LT',
        units: 'pixels',
        extent: this.extent
      })
      addCoordinateTransforms(
        this.projection,
        this.projectionWebCoords,
        function (coordinate) {
          return [coordinate[0], -coordinate[1]]
        },
        function (coordinate) {
          return [coordinate[0], -coordinate[1]]
        })
      // Слой для изображения планировки
      this.targetImageLayer = new ImageLayer({
        source: new Static({
          attributions: '© KANT.one',
          url: imageUrl, // 'https://imgs.xkcd.com/comics/online_communities.png',
          projection: this.projection,
          imageExtent: this.extent
        })
      })

      this.fullScreen = new FullScreenControl()
      this.fullScreen.on('enterfullscreen', () => {
        this.$nextTick(() => {
          this.compasRotator.handleFitExtent()
        })
      })
      this.fullScreen.on('leavefullscreen', () => {
        this.$nextTick(() => {
          this.compasRotator.handleFitExtent()
        })
      })
      this.compasRotator = new CompasRotateControl({ extent: this.extent, editable: this.allowRotateEdit })
      this.compasRotator.angleChangeHandler = this.compasAngleChange
      this.compasRotator.updateAngle(this.angle || 0)

      this.view = new View({
        projection: this.projection,
        center: getCenter(this.extent),
        zoom: 2,
        maxZoom: 8,
        padding: [50, 50, 50, 50]
      })

      this.editorInstance.addControl(this.fullScreen)
      this.editorInstance.addControl(this.compasRotator)
      this.editorInstance.addLayer(this.targetImageLayer)

      this.drawingLayer = new VectorLayer({
        source: this.drawingSource,
        style: drawingStyles.edit
      })
      this.editorInstance.addLayer(this.drawingLayer)

      this.otherPolygonsLayer = new VectorLayer({
        source: this.otherPolygonsSource,
        style: drawingStyles.showOther
      })
      this.editorInstance.addLayer(this.otherPolygonsLayer)

      this.editorInstance.setView(this.view)

      this.editorInstance.getView().on('change:rotation', () => {
        // console.warn('ROTATION ', event, this.editorInstance.getView().getRotation())
        this.currentRotateAngle = degreeNormalize360(radToDeg(this.editorInstance.getView().getRotation()))
      })

      this.editorInstance.getView().fit(this.extent)

      // TODO click handler
      // this.editorInstance.getViewport().addEventListener('click', (e) => {
      //   this.editorInstance.forEachFeatureAtPixel(this.editorInstance.getEventPixel(e), (feature, layer) => {
      //     console.warn('Клик по полигону - действие в разработке', feature, feature.getId(), layer)
      //   })
      // })

      this.onInitDraw()
    },
    compasAngleChange (value) {
      this.$emit('angle-change', roundByKoef(value) || null)
    },
    // OUTPUT
    targetPolygonCoordsChange (value = []) {
      const transformed = value.map((point) => {
        return transform(point, this.projection, this.projectionWebCoords)
      })
      this.$emit('polygon-change', transformed || [])
    },
    // INPUT
    onPresetPolygon () {
      if (Array.isArray(this.polygon) && this.polygon.length) {
        const transformed = this.polygon.map((point) => {
          return transform(point, this.projectionWebCoords, this.projection)
        })
        const feature = new Feature({
          type: 'Polygon',
          geometry: new Polygon([transformed])
        })
        feature.setId('mainPolygon')
        this.drawingSource.addFeature(feature)
      }
      if (Array.isArray(this.otherPolygons) && this.otherPolygons.length) {
        // console.log('otherPolygons1: ', this.otherPolygons)
        for (const item of this.otherPolygons) {
          const transformed = item.coordinates.map((point) => {
            return transform(point, this.projectionWebCoords, this.projection)
          })
          // console.log('otherPolygons2: ', transformed)
          const feature = new Feature({
            type: 'Polygon',
            geometry: new Polygon([transformed])
          })
          feature.setId(item.id)
          this.otherPolygonsSource.addFeature(feature)
        }
      }
    },
    addImageProcess (src) {
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.onload = () => resolve(img)
        img.onerror = reject
        img.src = src
      })
    },
    onInitDraw () {
      // TODO DRAWING
      // https://openlayers.org/en/latest/apidoc/module-ol_interaction_Draw-Draw.html
      // Рисование полигона
      this.drawInteraction = new Draw({
        source: this.drawingSource,
        type: 'Polygon',
        style: drawingStyles.edit
      })
      this.drawInteraction.setActive(false)
      this.editorInstance.addInteraction(this.drawInteraction)
      this.drawInteraction.on('drawend', (event) => {
        const drawingCoords = event.feature.getGeometry().getCoordinates()
        this.targetPolygonCoordsChange(drawingCoords[0] || [])
        this.onStopDrawing()
      })
      this.drawInteraction.on('drawabort', () => {
        // console.warn('DRAW ON drawabort: ', event)
        this.onStopDrawing()
      })
      this.drawInteraction.on('drawstart', () => {
        // console.warn('DRAW ON drawstart: ', event)
      })
      // Изменение полигона
      this.modifyInteraction = new Modify({
        source: this.drawingSource,
        style: drawingStyles.edit,
        deleteCondition: (event) => {
          return doubleClick(event)
        }
      })
      this.modifyInteraction.setActive(false)
      this.editorInstance.addInteraction(this.modifyInteraction)
      this.modifyInteraction.on('modifystart', () => {
        // console.warn('ON modifystart: ', event)
      })
      this.modifyInteraction.on('modifyend', (event) => {
        // console.warn('ON modifyend: ', event)
        const feature = event.features.getArray()?.[0]
        // console.warn('ON modifyend feature:', feature)
        if (feature) {
          this.targetPolygonCoordsChange(feature.getGeometry().getCoordinates()[0])
        }
      })
      this.$nextTick(() => {
        this.onPresetPolygon()
      })
    },
    onModifyPolygon () {
      // console.warn('onModifyPolygon:')
      this.actionsState.modify = !this.actionsState.modify
      this.modifyInteraction.setActive(this.actionsState.modify)
      this.drawingLayer.setStyle(this.actionsState.modify ? drawingStyles.show : drawingStyles.edit)
    },
    onStartDrawing () {
      // console.warn('onStartDrawing', this.drawInteraction)
      this.drawInteraction.setActive(true)
      this.actionsState = {
        draw: true,
        modify: false
      }
    },
    onStopDrawing () {
      // console.warn('onStopDrawing', this.drawInteraction, this.editorInstance)
      this.drawInteraction.setActive(false)
      this.actionsState = {
        draw: false,
        modify: false
      }
      this.drawInteraction.finishDrawing()
      this.$nextTick(() => {
        if (this.hasPolygonDrawed) {
          this.onModifyPolygon()
        }
      })
    },
    onClear () {
      console.warn('onClear')
      this.drawingSource.clear()
      this.targetPolygonCoordsChange([])

      this.actionsState = {
        draw: false,
        modify: false
      }
    }
  },
  computed: {
    hasPolygonDrawed () {
      return !!(Array.isArray(this.polygon) && this.polygon.length)
    },
    editorUid () {
      return `editor-${this._uid}`
    }
  },
  watch: {
    fileId: {
      handler () {
        this.getFileInfoById()
      },
      immediate: true
    },
    angle (value) {
      this.compasRotator?.updateAngle(value || 0)
    }
  },
  mounted () {
    this.$el.addEventListener('contextmenu', (event) => {
      event.preventDefault()
    })
    this.editorInstance = new Map({
      target: this.editorUid,
      controls: [],
      layers: [],
      view: null
    })
  },
  beforeDestroy () {
    this.editorClear()
    this.onClear()
    this.editorInstance.dispose()
  }
}
</script>

<style lang="scss">
.ol-full-screen {
  text-align: end;
  button {
    @apply tw-font-bold tw-text-[16px] tw-w-[41px] tw-h-[41px] tw-ml-auto tw-mt-[20px] tw-mr-[20px] tw-p-[12px] tw-text-[#1B1918] tw-bg-white tw-rounded-[10px] hover:tw-bg-[#7A8797] hover:tw-text-white
  }
}
.editor {
  height: 100%;
}
.ol-overlaycontainer-stopevent {
  button {
    pointer-events: auto !important;
  }
}
.fullscreen:-webkit-full-screen {
  height: 100%;
  margin: 0;
}

.fullscreen:fullscreen {
  height: 100%;
}

.fullscreen {
  width: 100%;
  height: 500px;
}
</style>
