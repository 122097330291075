<template>
  <div
    ref="form"
    class="tw-font-manrope"
  >
    <div style="display: none">
      {{ isEdited }}
    </div>
    <div class="tw-my-[12px]">
      <TwInputText
        v-model="form.slug"
        type="text"
        placeholder="Введите алиас"
        label="Алиас для странице сайта"
        :disabled="formProcessing"
        :show-label="true"
        :dense="true"
        :persistent-label="true"
      />
    </div>
    <div class="tw-my-[12px]">
      <TwInputSwitch
        v-model="form.publish"
        :disabled="formProcessing"
      >
        <template #label>
          <span class="tw-relative tw-z-10 tw-text-an-flat-grey-4 tw-font-manrope tw-text-[14px]">
            Опубликован на сайте <span
              class="tw-font-semibold"
              :class="form.publish ? 'tw-text-an-flat-red-main' : 'tw-text-an-flat-grey-4'"
            >{{ form.publish ? 'Да' : 'Нет' }}</span>
          </span>
        </template>
      </TwInputSwitch>
    </div>
    <TwCardSimpleFormSaveBlock
      class="tw-mt-[15px]"
      :loading="formProcessing"
      :cancel-confirm="isEdited"
      :is-sticky="false"
      :disable-success="!isEdited || formProcessing"
      :disable-cancel="formProcessing || (formType === 'create' && !isEdited)"
      :visible="true"
      @success="handleSubmit"
      @cancel="handleCancel"
    />
  </div>
</template>

<script>
import API from '@/Api/Auth/User/site'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import TwInputText from '@/components/tw-ui/ui-kit/input/TwInputText.vue'
import TwInputSwitch from '@/components/tw-ui/ui-kit/input/TwInputSwitch.vue'
import TwCardSimpleFormSaveBlock from '@/components/tw-ui/forms/TwCardSimpleFormSaveBlock.vue'

export default {
  name: 'EmployeeSiteForm',
  components: {
    TwCardSimpleFormSaveBlock,
    TwInputSwitch,
    TwInputText
  },
  mixins: [FormCreateEditMixin],
  props: {
    userId: {
      type: [Number, String],
      required: true
    }
  },
  data () {
    return {
      API: API,
      currentDate: new Date().toISOString().slice(0, 10),
      form: {
        id: null,
        slug: '',
        publish: false
      }
    }
  },
  mounted () {
    this.fetchData(this.targetId, { userId: this.userId })
  },
  methods: {
    handleResetCustom () {
      this.handleReset()
    },
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          for (const key in this.preset) {
            this.$set(this.form, key, this.preset[key])
          }
        }

        this.form.userId = this.userId
        resolve()
      })
    },
    handleSubmit () {
      if (this.formProcessing) {
        return
      }

      this.formProcessing = true

      const request = this.formType === 'create'
        ? this.API.store(this.form)
        : this.API.update(this.targetId, this.form)

      request.then(({ data }) => {
        this.$emit('refresh', data.data)
      }).catch((error) => {
        console.log(error)
      }).finally(() => {
        this.formProcessing = false
      })
    }
  }
}
</script>
