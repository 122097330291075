import { IOptions } from '@/store/intro-teach/types'

const options: IOptions = {
  nextLabel: 'Далее',
  prevLabel: 'Назад',
  doneLabel: 'Завершить',
  showBullets: false,
  tooltipClass: 'intro-custom-tooltip',
  steps: [
    {
      title: 'О контакте',
      intro: 'Основные данные контакта'
    },
    {
      title: 'Изменения контакта',
      intro: 'Нажмите изменить и внесите необходимые правки'
    },
    {
      title: 'Черный список',
      intro: 'Информация о внесении клиента в черный список компании'
    },
    {
      title: 'Хронология событий',
      intro: 'События происходившие с данным контактом, а также комментарии и создание дел по данному контакту'
    }
    // {
    //   title: 'Ипотека',
    //   intro: 'Клиентская информация необходимая для оформления ипотеки'
    // }
  ]
}

export default options
