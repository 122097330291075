<template>
  <div v-if="domClickDeal">
    <!-- ИНФО О ЗАЙМЕ -->
    <DomClickDealInfo
      :dom-click-deal="domClickDeal"
      :products-list="productsList"
      :calculate-info="calculateInfo"
      :loading="loading"
      @tabChange="$emit('tabChange', $event)"
    />

    <!-- АНКЕТЫ -->
    <DomClickDealApps
      v-if="domClickDeal && domClickDeal.applications && Array.isArray(domClickDeal.applications)"
      :calculate-info="calculateInfo"
      :validate-data="validateData"
      :deal-id="domClickDeal.id"
      :deal-office="domClickDeal.office"
      :refresh-documents-process="refreshDocumentsProcess"
      :additional-data="additionalData"
      :loading-parent="loading"
      :applications="domClickDeal.applications"
      @refresh="$emit('refresh')"
      @refresh-application-documents="refreshApplicationDocuments"
    />

    <!-- ВЫБОР ОФИСА ВЫДАЧИ -->
    <DomClickOfficeSelectBlock
      class="mt-4"
      :deal-id="domClickDeal.id"
      :loading-parent="loading"
      :deal-office="domClickDeal.office"
      @updateOfficeId="updateOfficeId"
    />

    <!-- ОШИБКИ ВАЛИДАЦИИ ПО ЗАЯВКЕ -->
    <div
      v-if="validateData && Array.isArray(validateData.validationErrors) && validateData.validationErrors.length"
      class="mt-3 error--text"
    >
      <div
        v-for="(error, index) in validateData.validationErrors"
        :key="index"
      >
        <v-alert
          text
          dense
          prominent
          type="error"
        >
          {{ error }}
        </v-alert>
      </div>
    </div>

    <!-- Причины возврата на доработку -->
    <div
      v-if="domClickDeal && domClickDeal.returnReason"
      class="mt-3 error--text"
    >
      <v-alert
        text
        dense
        prominent
        outlined
        type="warning"
        v-html="$Global.nl2br(domClickDeal.returnReason)"
      />
    </div>
    <v-card
      v-if="!loading && domClickDeal && domClickDeal.id"
      class="mt-3"
    >
      <v-card-title>
        Ссылка в домклик
      </v-card-title>
      <v-card-text class="pb-3">
        <a
          :href="domClickDealLink"
          target="_blank"
        >
          {{ domClickDealLink }}
        </a>
      </v-card-text>
    </v-card>

    <!-- ОПРАВКА В БАНК, СМС ПОДТВЕРЖДЕНИЕ И ЗАПРОС ВАЛИДАЦИИ -->
    <div
      v-if="!loading && isViewDealActions"
      class="mt-3 d-flex justify-space-between"
    >
      <div :title="isNeedAcceptances ? '' : 'Устраните замечания к анкетам для отправки СМС подтверждения'">
        <v-btn
          v-if="isValidationErrors"
          small
          color="primary"
          outlined
          :loading="validateProcess"
          @click="validate"
        >
          Проверка валидности заявки
        </v-btn>
        <v-btn
          :disabled="!isNeedAcceptances"
          small
          color="secondary"
          text
          :loading="loading"
          @click="acceptances"
        >
          Запрос СМС подтверждений клиентов
        </v-btn>
      </div>
      <v-btn
        v-if="domClickDeal.status !== 'LOAN_APPROVED'"
        small
        color="success"
        :loading="loading"
        :disabled="isValidationErrors"
        @click="send"
      >
        Отправить в банк
      </v-btn>
    </div>
    <div class="mt-5 error--text">
      <div
        v-for="(error, index) in anyErrors"
        :key="index"
      >
        <v-alert
          text
          dense
          prominent
          type="error"
        >
          {{ error }}
        </v-alert>
      </div>
    </div>
  </div>
</template>

<script>
import { isEndStatus } from '@/views/MortgageProgramWorksheet/DomClickWorksheet/DomClickDict'
import DomClickDealInfo from '@/views/MortgageProgramWorksheet/DomClickWorksheet/deal/DomClickDealInfo'
import DomClickDealApps from '@/views/MortgageProgramWorksheet/DomClickWorksheet/deal/DomClickDealApps'
import API from '@/Api/Crm/Mortgage/Docmclick/Deals'
import DomClickOfficeSelectBlock
  from '@/views/MortgageProgramWorksheet/DomClickWorksheet/deal/DomClickOfficeSelectBlock'

export default {
  name: 'DomClickDeal',
  components: { DomClickOfficeSelectBlock, DomClickDealApps, DomClickDealInfo },
  props: {
    calculateInfo: {
      type: Object,
      require: true
    },
    additionalData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    domClickDeal: {
      type: Object,
      require: true
    },
    productsList: {
      type: Array,
      default: () => []
    },
    refreshDocumentsProcess: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      anyErrors: [],
      loading: false,
      validateProcess: false,
      validateData: null
    }
  },
  computed: {
    domClickDealLink () {
      return `https://ipoteka.domclick.ru/deal/${this.domClickDeal.id}`
    },
    isValidationErrors () {
      return !!(Array.isArray(this.validateData?.validationErrors) && this.validateData.validationErrors.length)
    },
    isViewDealActions () {
      return !isEndStatus(this.domClickDeal?.status)
    },
    // показать кнопку запроса СМС подтверждений от клиентов
    isNeedAcceptances () {
      // если в анкетах есть замечания то не показываю запрос подтверждения
      if (Array.isArray(this.validateData?.applicationValidationErrors) && this.validateData.applicationValidationErrors.length && this.validateData.applicationValidationErrors.some(item => !!item.validationErrors.length)) {
        return false
      }
      // когда все ошибки внутри анкет устанили - проверяю наличие ошибки требующей подписи
      return !!(Array.isArray(this.validateData?.validationErrors) && this.validateData?.validationErrors.length === 1 && this.validateData.validationErrors.some(item => item.name === 'DEAL_IS_NOT_SIGNED'))
    }
  },
  mounted () {
    console.warn('MOUNTED', this.$options.name)
    this.validate()
  },
  methods: {
    refreshApplicationDocuments () {
      this.validate()
      this.$emit('refresh-application-documents')
    },
    acceptances () {
      this.loading = true
      API.acceptances(this.domClickDeal.id)
        .then(({ data }) => {
          console.warn('acceptances RESULT: ', data)
          if (data.errors) {
            this.anyErrors = Array.isArray(data.errors) && data.errors.length ? data.errors : [data?.message || 'Ошибка отправки кодов подтверждения']
          } else {
            this.$emit('refresh')
          }
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    updateOfficeId (eventData) {
      this.loading = true
      this.anyErrors = []
      API.update(this.domClickDeal.id, { officeId: eventData || 0 })
        .then(({ data }) => {
          console.warn('validate RESULT: ', data)
          if (data.errors) {
            this.anyErrors = Array.isArray(data.errors) && data.errors.length ? data.errors : [data?.message || 'Ошибка обновления офиса']
          } else {
            this.$emit('refresh')
          }
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    validate () {
      this.anyErrors = []
      this.validateProcess = true
      API.validate(this.domClickDeal.id)
        .then(({ data }) => {
          console.warn('validate RESULT: ', data)
          if (data.errors) {
            this.anyErrors = Array.isArray(data.errors) && data.errors.length ? data.errors : [data?.message || 'Ошибка валидации анкеты']
          } else {
            this.$set(this, 'validateData', data?.data)
          }
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.validateProcess = false
        })
    },
    send () {
      this.loading = true
      this.anyErrors = []
      API.send(this.domClickDeal.id)
        .then(({ data }) => {
          console.warn('send RESULT: ', data)
          if (data.errors) {
            this.anyErrors = Array.isArray(data.errors) && data.errors.length ? data.errors : [data?.message || 'Ошибка отправки анкеты']
          }
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped>
.v-list-item__content .v-list-item__title {
  white-space: inherit;
}
</style>
