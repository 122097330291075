const isToday = (targetDate: Date) => {
  // console.log('___________isToday', targetDate)
  if (!targetDate) {
    console.error('Date Time: isToday: BAD targetDate - ', targetDate)
    return false
  }
  return new Date().toDateString() === new Date(targetDate).toDateString()
}
const isYesterday = (targetDate: Date) => {
  // console.log('___________isYesterday', targetDate)
  if (!targetDate) {
    console.error('Date Time: isYesterday: BAD targetDate - ', targetDate)
    return false
  }
  const yesterdayDate = new Date().setDate(new Date().getDate() - 1)
  return new Date(yesterdayDate).toDateString() === new Date(targetDate).toDateString()
}
// Сафари не может партить дату в формате dd.mm.yyyy hh:mm нужно заменять пробел на T
const dateStringReplaceT = (stringDate: string) => {
  // console.log('=========dateStringReplaceT', stringDate)
  return stringDate?.replace(/ /g, 'T') || ''
}
// Переводит строковую или иную дату в объект даты JS
const anyDateToString = (date: any) => {
  // console.log('=========anyDateToString', date)
  let dateObj: any = ''
  if (typeof date === 'string') {
    dateObj = new Date(dateStringReplaceT(date))
  } else {
    dateObj = new Date(date)
  }
  return dateObj
}

export const DateFormat: any = {
  // Проверка даты на сегодня или вчера
  isRecentDate (stingDate: string): boolean {
    // console.log('------------------isRecentDate', stingDate)
    if (!stingDate) return false
    const dateObj = anyDateToString(stingDate)
    return isToday(dateObj) || isYesterday(dateObj)
  },
  equalYearMonthDay (preDate: Date, currDate: Date): boolean {
    // console.log('___________-equalYearMonthDay', preDate, currDate)
    if (!preDate || !currDate) {
      return false
    }
    const preDateObj = new Date(preDate).toISOString().slice(0, 10)
    const currDateObj = new Date(currDate).toISOString().slice(0, 10)
    return preDateObj === currDateObj
  },
  DateFull (date:string|Date): string {
    // console.log('___________------------------DateFull', date)
    if (date) {
      const dateObj = anyDateToString(date)
      const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' }
      return new Intl.DateTimeFormat('ru', options).format(dateObj)
    }
    return ''
  },
  DateShort (date:string|Date, notTodayYesterday = false): string {
    // console.log('___________------------------DateShort', date)
    if (date) {
      const dateObj = anyDateToString(date)
      const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'numeric', day: 'numeric' }
      if (!notTodayYesterday && isToday(dateObj)) {
        return 'Сегодня'
      } else if (!notTodayYesterday && isYesterday(dateObj)) {
        return 'Вчера'
      } else {
        return new Intl.DateTimeFormat('ru', options).format(dateObj)
      }
    }
    return ''
  },
  DateShortMonthLong (date:string|Date, notTodayYesterday = false): string {
    // console.log('___________------------------DateShort', date)
    if (date) {
      const dateObj = anyDateToString(date)
      const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' }
      if (!notTodayYesterday && isToday(dateObj)) {
        return 'Сегодня'
      } else if (!notTodayYesterday && isYesterday(dateObj)) {
        return 'Вчера'
      } else {
        return new Intl.DateTimeFormat('ru', options).format(dateObj)
      }
    }
    return ''
  },
  DateTimeShort (date:string|Date, notTodayYesterday = false): string {
    // console.log('___________------------------DateTimeShort', date)
    if (date) {
      const dateObj = anyDateToString(date)
      let options: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric' }
      let append = ''
      if (!notTodayYesterday && isToday(dateObj)) {
        append = 'Сегодня '
      } else if (!notTodayYesterday && isYesterday(dateObj)) {
        append = 'Вчера '
      } else {
        options = { year: '2-digit', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' }
      }
      return append + new Intl.DateTimeFormat('ru', options).format(dateObj)
    }
    return ''
  },
  DateTimeShortMonthLong (date:string|Date): string {
    // console.log('___________------------------DateTimeShortMonthLong', date)
    if (date) {
      const dateObj = anyDateToString(date)
      let options = {}
      let append = ''
      if (isToday(dateObj)) {
        options = { hour: 'numeric', minute: 'numeric' }
        append = 'Сегодня '
      } else if (isYesterday(dateObj)) {
        options = { hour: 'numeric', minute: 'numeric' }
        append = 'Вчера '
      } else {
        options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }
      }
      return append + new Intl.DateTimeFormat('ru', options).format(dateObj)
    }
    return ''
  },
  TimeOnlyHhMm (date:string|Date): string {
    // console.log('___________------------------TimeOnlyHhMm', date)
    if (date) {
      const dateObj = anyDateToString(date)
      return dateObj.toLocaleTimeString()?.slice(0, -3)
    }
    return ''
  },
  yyyyMmDdHhM (stingDate?:string): string {
    // console.log('------------------yyyyMmDdHhM', stingDate)
    const dateObj = stingDate ? anyDateToString(stingDate) : new Date()
    const yyyy = dateObj.getFullYear()
    const mm = dateObj.getMonth() + 1 // getMonth() is zero-based
    const dd = dateObj.getDate()
    const hours = dateObj.getHours()
    const minutes = dateObj.getMinutes()

    return [yyyy + '-',
      (mm > 9 ? '' : '0') + mm + '-',
      (dd > 9 ? '' : '0') + dd + ' ',
      (hours > 9 ? '' : '0') + hours + ':',
      (minutes > 9 ? '' : '0') + minutes
    ].join('')
  },
  // Проверяем истёк ли срок
  hasDateExpiration (date:string|Date): boolean {
    const currentDate = new Date()
    const targetDate = new Date(date)
    return currentDate.getTime() > targetDate.getTime()
  },
  datesDiffObj (predDate: any, nextDate: any): object {
    // console.log('+++++++++++++datesDiffObj', predDate, nextDate)
    let diffInMilliSeconds = Math.abs(predDate - nextDate) / 1000

    // TODO need add months, years

    // calculate days
    const days = Math.floor(diffInMilliSeconds / 86400)
    diffInMilliSeconds -= days * 86400
    // console.log('calculated days', days)

    // calculate hours
    const hours = Math.floor(diffInMilliSeconds / 3600) % 24
    diffInMilliSeconds -= hours * 3600
    // console.log('calculated hours', hours)

    // calculate minutes
    const minutes = Math.floor(diffInMilliSeconds / 60) % 60
    diffInMilliSeconds -= minutes * 60
    // console.log('minutes', minutes)

    return {
      days,
      hours,
      minutes
    }
  },
  GetQuarter (d: any): string {
    const date = typeof d === 'string' ? new Date(d) : d || new Date()
    let q = Math.floor(date.getMonth() / 3) + 1
    q -= q > 4 ? 4 : 0
    const y = date.getFullYear() + (q === 1 ? 1 : 0)
    return `${q} кв. ${y}`
  },
  dateAddDays (inputDate: any, daysCount = 0): any {
    const result = new Date(inputDate)
    result.setDate(result.getDate() + daysCount)
    return result
  },
  dateForInput (inputDate: any): any {
    return new Date(inputDate).toISOString().slice(0, 10)
  }
}
