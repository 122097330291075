<template>
  <div
    :id="mapUid"
    v-resize="onResize"
  />
</template>

<script lang="js">
import YandexMapInfrastructureModule from '@/utils/modules/YandexMapInfrastructureModule'

const mapConfig = {
  mapZoom: 17,
  mapControls: ['zoomControl'],
  polygonStyles: {
    fillColor: 'rgba(0, 0, 255, 0.1)',
    strokeColor: 'rgba(0, 0, 200, 1)',
    strokeWidth: 1
  }
}

let ymaps = null

export default {
  name: 'YandexMapDesignationObjects',
  props: {
    polyCoords: {
      type: Array,
      default: () => {
        return []
      }
    },
    coords: {
      type: Array,
      default: () => {
        return []
      }
    },
    mapZoom: {
      type: Number,
      default: mapConfig.mapZoom
    },
    scroll: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      map: null,
      collectionPolygons: null,
      collectionMarkers: null,
      // targetEditPolygon: null,
      coordsConfig: {
        latitudeInit: this.coords[0],
        longitudeInit: this.coords[1]
      }
    }
  },
  computed: {
    mapUid () {
      return `map-${this._uid}`
    }
  },
  watch: {
    coords () {
      this.initValues(true)
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.onInit()
    })
  },
  methods: {
    createPolygon (coords = []) {
      const polygon = new window.ymaps.Polygon([
        // Указываем координаты вершин многоугольника.
        // Координаты вершин внешнего контура.
        coords
        // [
        //   [52.75, 36.52],
        //   [52.75, 36.68],
        //   [52.65, 36.60]
        // ]
        // Координаты вершин внутреннего контура.
        // [
        //   [55.75, 37.52],
        //   [55.75, 37.68],
        //   [55.65, 37.60]
        // ]
      ], {
        // Описываем свойства геообъекта.
        // Содержимое балуна.
        // hintContent: 'Многоугольник'
      }, {
        // Задаем опции геообъекта.
        // Цвет заливки.
        fillColor: mapConfig.polygonStyles.fillColor,
        // Ширина обводки.
        strokeWidth: mapConfig.polygonStyles.strokeWidth,
        strokeColor: mapConfig.polygonStyles.strokeColor
      })
      return polygon
    },
    onResize () {
      this.map?.container?.fitToViewport()
    },
    async onInit () {
      // Проверяю наличие карт
      if (!window?.ymaps) return console.error('window.ymaps - NONE', !!window?.ymaps)
      // Помещаю карты локально для упрощения доступа
      ymaps = window.ymaps
      ymaps.ready().then(() => {
        this.map = new ymaps.Map(this.mapUid, {
          center: [this.coordsConfig.latitudeInit, this.coordsConfig.longitudeInit],
          zoom: this.mapZoom || mapConfig.mapZoom,
          controls: mapConfig.mapControls,
          cursor: 'help'
        }, {
          suppressMapOpenBlock: true, // Нужно ли скрывать предложение открыть текущую карту в Яндекс.Картах
          yandexMapDisablePoiInteractivity: true // Отключает интерактивность элементов подложки карты (кликабельность больниц, магазинов и тп.)
        })
        // console.log('ymaps.ready: ', this.map)

        // Назначаю курсор
        this.map.cursors.push('pointer')

        // Включение-выключение скролла
        if (!this.scroll) {
          this.map.behaviors.disable('scrollZoom')
        }

        // Создаю коллекции для управления элементами
        this.collectionPolygons = new ymaps.GeoObjectCollection({})
        this.map.geoObjects.add(this.collectionPolygons)

        this.collectionMarkers = new ymaps.GeoObjectCollection({})
        this.map.geoObjects.add(this.collectionMarkers)

        this.initValues()
      })
    },
    initValues (isUpdate = false) {
      // подключаю модуль выбора инфраструктуры - срабатывать он будет при смещении карты
      this.infraModule = new YandexMapInfrastructureModule(this.map)
      const [lat = null, lon = null] = this.map?.getCenter()
      this.infraModule?.update({
        lat,
        lon,
        radius: 2000
      })
      // Метка главного объекта
      if (this.coordsConfig?.latitudeInit && this.coordsConfig.longitudeInit) {
        this.collectionMarkers?.removeAll()
        const coords = Object.values(this.coords)
        const mainObjectMarker = new window.ymaps.Placemark(coords, {
          // iconCaption: 'невероятно интересный текст'
        }, {
          draggable: false,
          preset: 'islands#redHomeIcon'
        })
        this.collectionMarkers.add(mainObjectMarker)
      }

      // Полигон
      if (!isUpdate) {
        this.collectionPolygons?.removeAll()
        if (Array.isArray(this.polyCoords) && this.polyCoords.length) {
          // формирую координаты
          console.warn('collectionPolygons ', this.polyCoords)
          // const polygon = this.createPolygon(polyCoords)
          // Добавляем полигон на карту
          // this.collectionPolygons.add(polygon)
          // TODO мульти полигоны - не реализовано на беке
          for (const item of this.polyCoords) {
            const polygon = this.createPolygon(item.mapPolygon)
            // Добавляем полигон на карту
            this.collectionPolygons.add(polygon)
          }
          console.warn('collectionPolygons22222 ', this.collectionPolygons)
        }
      }

      // Центровка и вписывание в карту
      this.mapToCenterAndZoomFromObjects()
    }
  },
  // КАРТА центровка и маштабирование потносительно имеющихся объектов
  mapToCenterAndZoomFromObjects () {
    const bounds = this.map.geoObjects.getBounds()
    this.map.setBounds(bounds, {
      checkZoomRange: true,
      duration: 300,
      preciseZoom: true,
      zoomMargin: 5,
      callback: (err) => {
        if (err) {
          console.error('callback', err)
        }
      }
    })
  }
}
</script>

<style scoped>

</style>
