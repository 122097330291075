<template>
  <div :class="wrapperClass">
    <input
      :id="uid"
      v-model="localValue"
      :disabled="disabled"
      :class="[inputClassBase, inputClassChecked, inputClassFocused, inputClassDisabled]"
      class="checked:tw-min-w-[42px] checked:after:tw-absolute checked:after:tw-z-[2] checked:after:tw-my-[2px] checked:after:tw-ml-[22px] checked:after:tw-h-[18px] checked:after:tw-w-[18px] checked:after:tw-rounded-full checked:after:tw-border-none checked:after:tw-shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:tw-transition-[background-color_0.2s,transform_0.2s] checked:after:tw-content-[''] hover:tw-cursor-pointer"
      type="checkbox"
      role="switch"
    >
    <label
      v-if="labelText || $slots.label"
      :class="[labelClass, inputClassDisabled]"
      :disabled="disabled"
      role="switch"
      :for="uid"
    >
      <slot name="label">
        {{ labelText }}
      </slot>
    </label>
  </div>
</template>

<script>
export default {
  name: 'TwInputSwitch',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    labelText: {
      type: String,
      default: ''
    },
    wrapperClass: {
      type: String,
      default: 'tw-font-manrope tw-flex tw-items-center tw-justify-start'
    },
    labelClass: {
      type: String,
      default: 'inline-block tw-font-semibold tw-text-[14px] cursor-pointer tw-select-none'
    },
    inputClassBase: {
      type: String,
      default: 'tw-mr-2 tw-h-[22px] tw-min-w-[42px] tw-appearance-none tw-rounded-full tw-bg-an-flat-grey-21 tw-outline-none before:tw-pointer-events-none before:tw-absolute before:tw-h-[18px] before:tw-w-[18px] before:tw-rounded-full before:tw-bg-transparent before:tw-content-[\'\'] after:tw-absolute :after:tw-bg-white after:tw-z-[2] after:tw-my-[2px] after:tw-ml-[2px] after:tw-h-[18px] after:tw-w-[18px] after:tw-rounded-full after:tw-border-none after:tw-bg-white after:tw-shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:tw-transition-[background-color_0.2s,transform_0.2s] after:tw-content-[\'\'] '
    },
    inputClassChecked: {
      type: String,
      default: 'checked:tw-bg-an-flat-red-main'
    },
    inputClassFocused: {
      type: String,
      default: ''
    },
    inputClassDisabled: {
      type: String,
      default: 'disabled:tw-opacity-[30%] disabled:tw-cursor-default'
    }
  },
  computed: {
    localValue: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    },
    uid () {
      return this._uid
    }
  }
}
</script>

<style scoped>

</style>
