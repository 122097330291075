<template>
  <div class="tw-font-manrope tw-text-main-sm tw-text-an-flat-black-main tw-pb-[32px] tw-pr-[44px] tw-pt-[40px] tw-pl-[60px] tw-min-h-full tw-bg-white">
    <!--    {{isNew}}-->
    <!--    <pre>-->
    <!--    {{$props}}-->
    <!--    </pre>-->
    <!--    <pre>-->
    <!--    {{data}}-->
    <!--    </pre>-->
    <!--    {{requestTypeView}}-->
    <template v-if="!loading">
      <RequestToDeveloperFixationUI
        v-if="requestTypeView === 'fixation'"
        :show-init-data="developerRequestInfo"
        :is-new="isNew"
        :create-init-data="createInitData"
        @created="onCreated"
        @updated="onUpdated"
        @success-send="successDialog = true"
        @refresh="refresh"
      />
      <RequestToDeveloperReservationUI
        v-else-if="requestTypeView === 'reservation'"
        :show-init-data="developerRequestInfo"
        :show-init-dict="dictionary"
        :is-new="isNew"
        :create-init-data="createInitData"
        @created="onCreated"
        @updated="onUpdated"
        @success-send="successDialog = true"
        @refresh="refresh"
      />
      <div v-else>
        requestType - неопределён! [ {{ requestTypeView }} ]
      </div>
    </template>
    <TwDialog
      :value="successDialog"
      max-width="400px"
      @input="successDialog = $event"
    >
      <template #body>
        <div class="tw-text-center">
          <div class="tw-m-auto tw-bg-an-flat-green-btn-bg tw-w-[120px] tw-h-[120px] tw-flex tw-justify-center tw-items-center tw-rounded-full">
            <svg
              width="52"
              height="41"
              viewBox="0 0 52 41"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 19.5833L18.6056 33L47 5"
                stroke="white"
                stroke-width="10"
                stroke-linecap="round"
              />
            </svg>
          </div>
          <div class="tw-my-[20px] tw-font-semibold tw-text-[20px] tw-leading-normal">
            Запрос на {{ requestTypeView === 'fixation' ? 'фиксацию' : 'бронирование' }} успешно отправлен
          </div>
          <button
            class="tw-min-w-[250px] tw-m-auto tw-rounded-md tw-bg-an-flat-green-btn-bg tw-py-[18px] tw-font-bold tw-text-[14px] tw-text-white tw-flex tw-gap-[12px] tw-justify-center tw-items-center enabled:hover:tw-drop-shadow-lg disabled:tw-opacity-50"
            @click.prevent="successDialog = false"
          >
            Продолжить
          </button>
        </div>
      </template>
    </TwDialog>
  </div>
</template>

<script>
import RequestToDeveloperFixationUI from '@/views/Newbuildings/RequestToDeveloper/RequestToDeveloperFixationUI.vue'
import API from '@/Api/Rtd/RequestToDeveloper/Request'
import CardMixin from '@/mixins/CardMixin'
import TwDialog from '@/components/tw-ui/modal/TwDialog.vue'
import RequestToDeveloperReservationUI from '@/views/Newbuildings/RequestToDeveloper/RequestToDeveloperReservationUI.vue'

export default {
  name: 'RequestToDeveloperPanel',
  components: { RequestToDeveloperReservationUI, TwDialog, RequestToDeveloperFixationUI },
  // При открытии панели миксин инициализирует объект панели и определяется тип - фикса или бронь - на основе этого происходит определение дальнейшей отрисовки компонентов
  mixins: [CardMixin],
  props: {
    createInitData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      API,
      successDialog: false
    }
  },
  computed: {
    requestTypeView () {
      // Если данные получены миксином - смотрим тип - иначе проверяем тип который указан в пропсах для создания
      return this.data?.type?.value || this.createInitData?.requestType
    },
    developerRequestInfo () {
      return {
        ...this.data,
        ...this.otherData,
        ui: this.ui
      }
    }
  }
}
</script>

<style scoped>

</style>
