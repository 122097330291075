import { AxiosResponse } from 'axios'
import { AuthAxiosDefaultConfig } from '@/Api/Vision'
import HTTP from '@/Api/http'

const path = '/image'

export default {
  detectImageFields (type: string, page: number, image: BinaryType): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig,
      headers: {
        'Content-Type': 'multipart/form-data;charset=utf-8'
      }
    }
    const formData = new FormData()
    formData.append('image', image)
    return HTTP.post(`${path}/${type || 'passport'}/${page || 1}`, formData, $config)
  }
}
