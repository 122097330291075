<template>
  <div
    class="tw-relative"
    :class="[disabled ? 'tw-pointer-events-none tw-opacity-70':'']"
  >
    <div
      v-if="label"
      class="tw-flex tw-justify-between tw-gap-[8px] tw-h-[21px]"
    >
      <label
        v-if="label"
        :for="uid"
        :class="'tw-text-an-flat-dark-blue-link'"
        class="tw-pointer-events-none tw-font-medium tw-truncate tw-text-main-sm2 tw-left-[5px] "
      >
        {{ label }}
        <span
          v-if="isRequired"
          class="tw-text-an-flat-red-main tw-text-main-sm"
        >
          <slot name="requiredSymbol">*</slot>
        </span>
      </label>
      <label
        v-if="isShowErrorLabel"
        class="tw-text-[12px] tw-text-an-flat-red-main"
        :for="uid"
      >
        {{ errorText }}
      </label>
    </div>
    <div
      :class="[sizeClasses, disabled ? 'tw-bg-an-flat-disabled-input-bg tw-cursor-default' : 'tw-bg-white hover:tw-shadow-[10px_8px_15px_-15px_rgba(0,0,0,0.3)]', isShowErrorLabel && !disableErrorBorder ? 'tw-outline-an-flat-red-main':'tw-outline-transparent']"
      class="tw-relative tw-font-manrope tw-w-full tw-flex tw-gap-[5px] tw-text-main-sm2 tw-p-[7px] tw-rounded-sm tw-outline tw-outline-1 tw-outline-an-flat-gray-0 tw-border tw-border-solid"
    >
      <button
        v-for="(item, index) in options"
        :key="index"
        type="button"
        :class="[checkedItemValue(item) ? btnColorClasses : '', dense ? 'tw-py-[5px]' : 'tw-py-[10px]']"
        :disabled="disabled || item?.disabled"
        class="tw-grow tw-min-w-fit tw-h-full tw-px-[7px] tw-font-medium tw-size-[13px] tw-leading-none tw-text-an-flat-black-main tw-rounded-sm tw-cursor-pointer tw-border-an-flat-gray-border/30 tw-border-[1px] tw-border-solid
          enabled:hover:tw-bg-an-flat-grey-1 disabled:tw-text-an-flat-disabled-text disabled:tw-cursor-default"
        @click.stop="onClickButtonValue(item)"
      >
        {{ item.label }} {{ item.suffix }}
      </button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TwSelectButton',
  props: {
    value: {
      type: [Array, String, Number, Boolean],
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    // сжатые поля
    dense: {
      type: Boolean,
      default: false
    },
    multi: {
      type: Boolean,
      default: false
    },
    disableUnselectLastItem: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    disableErrorBorder: {
      type: Boolean,
      default: false
    },
    errorText: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: 'red',
      validator: function (value) {
        return value === 'red' || value === 'green' || value === 'blue'
      }
    }
  },
  computed: {
    sizeClasses () {
      if (this.dense) {
        return ' tw-min-h-[40px] tw-min-w-[180px] tw-text-main-xs '
      } else {
        return ' tw-min-h-[52px] tw-min-w-[250px] tw-text-main-sm2 '
      }
    },
    btnColorClasses () {
      if (this.color === 'red') {
        return 'tw-bg-an-flat-red-lighten tw-text-an-flat-red-main enabled:hover:tw-bg-an-flat-red-lighten'
      } else if (this.color === 'green') {
        return 'tw-text-an-flat-green-btn-bg-active tw-bg-an-flat-green-btn-bg-active/20 enabled:hover:tw-bg-an-flat-green-btn-bg-active/20'
      } else if (this.color === 'blue') {
        return 'tw-text-an-flat-blue-btn-active tw-bg-an-flat-blue-btn-active/20 enabled:hover:tw-bg-an-flat-blue-btn-active/20'
      }
      return 'tw-bg-an-flat-red-lighten tw-text-an-flat-red-main enabled:hover:tw-bg-an-flat-red-lighten'
    },
    isRequired () {
      // определение обязательности поля
      // - если тестовая ошибка имеет дефолтное значение
      // и если текст лейбла показан над полем ввода
      return this.errorText !== null && !!this.label
    },
    isShowErrorLabel () {
      if (this.multi) {
        return this.errorText !== null && !this.value.length && !this.disabled
      }
      return this.errorText !== null && this.value === null && !this.disabled
    },
    uid () {
      return `${this.$options.name}_${this._uid}`
    }
  },
  created () {
    if (this.multi && !Array.isArray(this.value)) {
      console.error('Bad default value: ', this.$options.name, { multi: this.multi }, this.value)
      this.$emit('input', [])
    } else if (!this.multi && Array.isArray(this.value)) {
      console.error('Bad default value: ', this.$options.name, { multi: this.multi }, this.value)
      this.$emit('input', null)
    }
  },
  methods: {
    onClickButtonValue (item) {
      let newValue = this.multi ? [] : null
      if (!this.multi) {
        newValue = this.value
        // Изменение значений, при multi = false
        // disableUnselectLastItem - флаг запрещающий обнулять выбор
        newValue = newValue === item.value ? this.disableUnselectLastItem ? item.value : null : item.value
        this.$emit('input-object', this.options?.find(i => i.value === newValue))
      } else {
        newValue = [...this.value]
        // Если мы находим схожее значение, то мы его удаляем из нашего массива
        if (newValue.includes(item.value)) {
          newValue = newValue.filter(element => element !== item.value)
          // Если значения нет в массиве, то добавляем значение в конец
        } else {
          newValue.push(item.value)
        }
        this.$emit('input-object', this.options.filter(i => newValue.includes(i.value)))
      }
      // Отдаём данные родителю
      this.$emit('input', newValue)
    },
    // Функция для проверки значений
    checkedItemValue (item) {
      if (this.multi) {
        return Array.isArray(this.value) ? this.value?.some(i => i === item.value) : false
      }
      return item.value === this.value
    }
  }
}
</script>

<style scoped>
</style>
