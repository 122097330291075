import SeoPage from './page-meta'
import SeoPageResale from './seo-page-resale'

export const routeKey = 'catalog'

export default {
  path: '/' + routeKey,
  name: routeKey,
  meta: { name: 'Каталог', icon: 'admin-catalog' },
  redirect: { name: 'page-meta' },
  component: () => import('@/views/Site/Admin/Index.vue'),
  children: [
    SeoPage,
    SeoPageResale
  ]
}
