<template>
  <div
    v-cloak
    v-if="info"
    class="tw-flex tw-gap-[12px] tw-flex-col"
  >
    <TwPanelContentBlockFieldView>
      <template #title>
        Застройщик
      </template>
      <div v-if="info.company">
        <ViewPanelLink
          :id="info.company.id"
          label="Застройщик"
          component="company-show"
        >
          {{ info.company.name }}
        </ViewPanelLink>
      </div>
    </TwPanelContentBlockFieldView>

    <TwPanelContentBlockFieldView>
      <template #title>
        Парсер
      </template>
      <div v-if="info.parser">
        {{ info.parser }}
      </div>
    </TwPanelContentBlockFieldView>

    <TwPanelContentBlockFieldView>
      <template #title>
        Ссылка на файл
      </template>
      <div v-if="info.fileLink">
        <a
          :href="info.fileLink"
          target="_blank"
          class="tw-break-words"
        >{{ info.fileLink }}</a>
      </div>
    </TwPanelContentBlockFieldView>

    <TwPanelContentBlockFieldView>
      <template #title>
        Коммент
      </template>
      <div
        v-if="info.comment"
        v-html="$Global.nl2br(info.comment)"
      />
    </TwPanelContentBlockFieldView>

    <TwPanelContentBlockFieldView>
      <template #title>
        Активный
      </template>
      <div>
        {{ info.active ? 'Да' : 'Нет' }}
      </div>
    </TwPanelContentBlockFieldView>
  </div>
</template>

<script>
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'

export default {
  name: 'ImportBuilderFileMainShow',
  components: { TwPanelContentBlockFieldView, ViewPanelLink },
  mixins: [ViewPanelShowMixin],
  props: {
    info: {},
    dictionary: {}
  }
}
</script>
