<template>
  <div class="card-container">
    <div
      v-if="!loading || data"
      class="px-3"
    >
      <div
        v-if="data"
        class="pa-2 d-flex align-center"
      >
        <div
          v-if="!isNew"
          class="font-weight-bold d-flex align-center"
        >
          <ClickBoard :value="`Email: ${data.theme}`" />
          {{ data.theme }}
        </div>
        <div
          v-else
          class="title text-muted"
        >
          Написать письмо
        </div>
        <v-spacer />
        <v-checkbox
          v-if="isNew"
          class="ma-0"
          dense
          color="red"
          on-icon="fa-fire"
          hide-details
          label="Важное"
        />
        <div v-else>
          <span
            v-if="data.emailFolder.type"
            class="font-weight-bold text-muted mr-2"
          >
            {{ data.emailFolder.type.label }}
          </span>
        </div>
      </div>
      <div
        v-if="data"
        class="pa-2 d-flex align-center"
      >
        <small class="text-muted mr-3">{{ data.status && data.status.label }}</small>
        <v-spacer />
        <v-btn
          v-if="!mainFormEdited && isAllowEditEmail"
          x-small
          outlined
          :color="editMain ? 'primary':'secondary'"
          :disabled="isNew"
          @click="editMain = !editMain"
        >
          {{ editMain ? 'отменить':'изменить' }}
        </v-btn>
      </div>
      <v-divider />
      <EmailMainShow
        v-if="!isNew && !editMain"
        :dictionary="dictionary"
        :info="data"
        @refresh="refresh"
      />
      <EmailMainForm
        v-else
        v-bind="$props"
        :id="data ? data.id : 0"
        :to-person="toPerson"
        :for-deal="forDeal"
        :custom-person-ids="[]"
        @create="onCreated"
        @update="onUpdated"
        @cancel="onCancel"
      />
    </div>
    <!--
      Блок заполняющий пространство при отсутствуии контента
      для прижатия блока сохранения к нижней части страницы
    -->
    <div class="filler">
      <v-progress-circular
        v-if="loading"
        size="50"
        color="primary"
        indeterminate
      />
    </div>
  </div>
</template>

<script>
import emailAPI from '@/Api/Email/Mail'
import CardMixin from '@/mixins/CardMixin'
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import EmailMainForm from '@/views/Email/main/EmailMainForm'
import EmailMainShow from '@/views/Email/main/EmailMainShow'
import ClickBoard from '@/components/button/ClickBoard'

export default {
  name: 'EmailCard',
  components: { ClickBoard, EmailMainShow, EmailMainForm },
  mixins: [CardMixin, ViewPanelShowMixin],
  props: {
    // инфа о прикрепляемой сделке
    forDeal: {
      default: null
    },
    toPerson: {
      default: null
    }
  },
  data () {
    return {
      API: emailAPI,
      editMain: false,
      mainFormEdited: false
    }
  },
  computed: {
    isAllowEditEmail () {
      // Письмо должно быть в папке черновики и не должно быть ошибок
      return this.data?.emailFolder?.type?.value === 'draft' && this.data?.status?.value.indexOf('error') === -1
    }
  },
  methods: {
    postFetch (data) {
      if (!data.read && data?.emailFolder?.type?.value === 'inbox') {
        this.read()
      }
    }
  }
}
</script>
