<template>
  <TwDialog
    v-model="isShow"
    :disable-close="false"
    :is-close-btn="false"
    :max-width="maxWidth"
  >
    <template #header>
      <div>
        <div
          class="tw-text-h5 tw-font-semibold"
          v-text="title"
        />
      </div>
    </template>
    <template #body>
      <div v-text="desc" />
    </template>

    <template #actions>
      <div class="tw-mt-[15px] tw-flex tw-gap-[10px]">
        <button
          title="Подтвердить действие"
          class="tw-grow tw-px-[15px] tw-py-[7px] tw-font-semibold tw-bg-white tw-rounded-sm tw-text-an-flat-green disabled:tw-opacity-50 enabled:hover:tw-bg-an-flat-green enabled:hover:tw-text-white"
          @click.prevent="onApply"
        >
          {{ textApply }}
        </button>
        <button
          title="Отменить действие"
          class="tw-w-[100px] tw-px-[15px] tw-py-[7px] tw-bg-an-flat-gray-4 tw-rounded-sm tw-text-white disabled:tw-opacity-50"
          @click.prevent="onCancel"
        >
          {{ textCancel }}
        </button>
      </div>
    </template>
  </TwDialog>
</template>

<script>
import TwDialog from '@/components/tw-ui/modal/TwDialog.vue'

export default {
  name: 'ChangeStatusConfirm',
  components: { TwDialog },
  props: {
    title: {
      type: String,
      default: 'title'
    },
    desc: {
      type: String,
      default: 'desc'
    },
    textApply: {
      type: String,
      default: 'Принять'
    },
    textCancel: {
      type: String,
      default: 'Отмена'
    },
    maxWidth: {
      type: [String, Number],
      default: '290px'
    }
  },
  data () {
    return {
      isShow: false,
      callback: null
    }
  },
  methods: {
    confirm (callback) {
      if (callback) {
        this.$set(this, 'callback', callback)
      }
      this.onShow()
    },
    onShow () {
      this.isShow = true
    },
    onHide () {
      this.isShow = false
      this.$set(this, 'callback', null)
    },
    onToggle () {
      this.isShow = !this.isShow
    },
    onApply () {
      if (this.callback && typeof this.callback === 'function') {
        this.callback(true)
      }
      this.onHide()
    },
    onCancel () {
      if (this.callback && typeof this.callback === 'function') {
        this.callback(false)
      }
      this.onHide()
    }
  }
}
</script>

<style scoped>

</style>
