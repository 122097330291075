import { AxiosResponse } from 'axios'
import HTTP from '@/Api/http'
import { AuthAxiosDefaultConfig } from '@/Api/ObjectStorage'

const path = '/complex/invest-appeal'

export default {
  // TODO DEL
  // getList (param?: object): Promise<AxiosResponse> {
  //   const $config: any = {
  //     ...AuthAxiosDefaultConfig,
  //     params: param
  //   }
  //   return HTTP.get(`${path}`, $config)
  // },
  create (): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.get(`${path}/create`, $config)
  },
  store (data: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.post(`${path}`, data, $config)
  },
  update (id: number, data: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.put(`${path}/${id}`, data, $config)
  },
  show (complexId: string): Promise<AxiosResponse> {
    return this.showByComplexId(complexId)
  },
  delete (id: number): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.delete(`${path}/${id}`, $config)
  },
  showByComplexId (complexId: string): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.get(`/complex/${complexId}/invest-appeal`, $config)
  }
}
