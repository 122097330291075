<template>
  <div
    class="tw-w-full tw-flex tw-flex-col tw-items-center tw-text-center tw-justify-center tw-py-[7px] tw-bottom-0"
    :style="{backgroundColor: bgColor, position: isSticky ? 'sticky' : 'relative'}"
  >
    <!-- Слот для доп инфы -->
    <slot name="info" />
    <div v-if="visible">
      <div class="tw-w-full tw-flex tw-justify-center tw-gap-[14px]">
        <button
          :disabled="loading || disableSuccess"
          :class="dense ? ' tw-px-[20px] tw-py-[5px] tw-rounded-[4px] tw-text-[12px]':'tw-px-[33px] tw-py-[10px] tw-rounded-[10px] tw-text-[14px]'"
          class="tw-max-w-[252px] tw-w-full tw-bg-an-flat-green-btn-bg
          tw-text-white tw-text-center tw-font-semibold tw-transition-all
          enabled:hover:tw-drop-shadow-lg enabled:hover:tw-bg-an-flat-green disabled:tw-opacity-50"
          @click.prevent="preSuccess"
        >
          {{ successTitle }}
        </button>
        <button
          v-if="!cancelHide"
          :disabled="loading || disableCancel"
          :class="dense ? ' tw-px-[20px] tw-py-[5px] tw-rounded-[4px] tw-text-[12px]':'tw-px-[33px] tw-py-[10px] tw-rounded-[10px] tw-text-[14px]'"
          class="tw-max-w-[252px] tw-w-full tw-bg-an-flat-gray-4
          tw-text-white tw-text-center tw-font-semibold
          enabled:hover:tw-drop-shadow-lg disabled:tw-opacity-50"
          @click.prevent="preCancel"
        >
          {{ cancelTitle }}
        </button>
      </div>

      <!--  Подтверждение публикации  -->
      <TwDialog
        v-model="confirmDialog"
        max-width="400px"
      >
        <template #header>
          <div>
            <div class="tw-text-h4 tw-font-semibold">
              Подтверждение
            </div>
          </div>
        </template>
        <template #body>
          <div v-if="confirmType === 'cancel'">
            <div class="tw-font-medium tw-text-an-flat-red-main">
              Уверены, что хотите отменить изменения?
            </div>
            <div class="tw-mt-[15px] tw-flex tw-gap-[10px]">
              <button
                class="tw-grow tw-px-[15px] tw-py-[7px] tw-bg-white tw-rounded-sm tw-text-an-flat-red-main disabled:tw-opacity-50 enabled:hover:tw-bg-an-flat-red-main enabled:hover:tw-text-white"
                @click.prevent="onCancel"
              >
                Да
              </button>
              <button
                class="tw-w-[100px] tw-px-[15px] tw-py-[7px] tw-bg-an-flat-gray-4 tw-rounded-sm tw-text-white"
                @click.prevent="confirmDialog = false"
              >
                Нет
              </button>
            </div>
          </div>
          <div v-if="confirmType === 'success'">
            <div class="tw-font-medium tw-text-an-flat-red-main">
              Подтвердить отправку изменений
            </div>
            <div class="tw-mt-[15px] tw-flex tw-gap-[10px]">
              <button
                class="tw-grow tw-px-[15px] tw-py-[7px] tw-bg-white tw-rounded-sm tw-text-an-flat-green-btn-bg disabled:tw-opacity-50 enabled:hover:tw-bg-an-flat-green-btn-bg enabled:hover:tw-text-white"
                @click.prevent="onSuccess"
              >
                Да
              </button>
              <button
                class="tw-w-[100px] tw-px-[15px] tw-py-[7px] tw-bg-an-flat-gray-4 tw-rounded-sm tw-text-white"
                @click.prevent="confirmDialog = false"
              >
                Нет
              </button>
            </div>
          </div>
        </template>
      </TwDialog>
    </div>
  </div>
</template>

<script>
import TwDialog from '@/components/tw-ui/modal/TwDialog.vue'

export default {
  name: 'TwCardSimpleFormSaveBlock',
  components: { TwDialog },
  props: {
    dense: {
      type: Boolean,
      default: false
    },
    visible: {
      type: Boolean,
      default: false
    },
    disableCancel: {
      type: Boolean,
      default: false
    },
    disableSuccess: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    cancelConfirm: {
      type: Boolean,
      default: false
    },
    successConfirm: {
      type: Boolean,
      default: false
    },
    successTitle: {
      type: String,
      default: 'Сохранить'
    },
    isSticky: {
      type: Boolean,
      default: true
    },
    bgColor: {
      type: String,
      default: 'transparent'
    },
    cancelTitle: {
      type: String,
      default: 'Отменить'
    },
    cancelHide: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      confirmDialog: false,
      confirmType: ''
    }
  },
  methods: {
    preSuccess () {
      if (this.successConfirm) {
        this.confirmDialog = true
        this.confirmType = 'success'
      } else {
        this.onSuccess()
      }
    },
    preCancel () {
      if (this.cancelConfirm) {
        this.confirmDialog = true
        this.confirmType = 'cancel'
      } else {
        this.onCancel()
      }
    },
    onSuccess () {
      this.$emit('success')
      this.confirmDialog = false
      this.confirmType = ''
    },
    onCancel () {
      this.$emit('cancel')
      this.confirmDialog = false
      this.confirmType = ''
    }
  }
}
</script>

<style scoped>
/* Блок сохранения в карточках объектов */

/* Анимация для блока сохранения */
.slide-save-block-enter-active, .slide-save-block-leave-active {
  transition: transform 0.5s;
}

.slide-save-block-leave-to {
  transform: translateY(100%);
}

.slide-save-block-enter {
  transform: translateY(100%);
}
</style>
