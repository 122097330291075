<template>
  <TwSelect
    :value="value"
    :return-object="true"
    placeholder="Выберите статус сделки"
    :label="label"
    :multi="true"
    :dense="true"
    :cleanable="true"
    :persistent-label="true"
    :options="list"
    @input="$emit('input', $event)"
    @input-object="$emit('inputFullData', $event)"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import TwSelect from '@/components/tw-ui/ui-kit/input/TwSelect.vue'
import { ServicesSchema } from '@/store/twfilter/servicesSchema'

export default {
  name: 'TwDealStatusSelect',
  components: { TwSelect },
  props: {
    label: {
      type: String,
      default: null
    },
    value: {
      type: Array,
      default: null
    },
    filterForType: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      getCurrentFiltersSchema: 'twFilter/getCurrentFilterSchemaByKey',
      cachedDealStatusList: 'tmpCache/cachedDealStatusList',
      getDealStatusById: 'tmpCache/getDealStatusById'
    }),
    // схема фильтров для заявок
    dealSchemaFields () {
      return this.getCurrentFiltersSchema(ServicesSchema.DealList.key) || []
    },
    // поле типа заявки
    schemaFieldType () {
      return this.dealSchemaFields.find(f => f.key === 'type') || null
    },
    dealType () {
      return this.schemaFieldType?.inputValue || (Object.keys(this.schemaFieldType?.defaultValue || {})[0])
    },
    list () {
      if (Array.isArray(this.cachedDealStatusList) && this.cachedDealStatusList.length) {
        const statusList = this.filterForType && this.dealType ? this.cachedDealStatusList.filter(status => status.type.value === this.dealType) : this.cachedDealStatusList
        return statusList.map((item) => {
          return {
            label: this.filterForType && this.dealType ? item.name : `${item.name} (${item.type?.label})` || '~',
            value: item.id
          }
        })
      } else {
        return []
      }
    }
  }
}
</script>
