<template>
  <component
    :is="tag"
    v-bind="$props"
    class="tw-font-manrope tw-rounded-sm"
    :class="[textSizeClasses, colorClasses]"
    v-on="$listeners"
  >
    <slot />
  </component>
</template>

<script>
export default {
  name: 'TwPanelContentBlockActionButton',
  props: {
    tag: {
      type: String,
      default: 'button'
    },
    size: {
      type: String,
      default: 'xs'
    },
    type: {
      type: String,
      default: 'add' // add | edit | delete
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    textSizeClasses () {
      if (this.size === 'xs') {
        return 'tw-text-main-xs tw-px-[10px] tw-py-[4px]'
      } else if (this.size === 'sm') {
        return 'tw-text-main-sm2 tw-px-[10px] tw-py-[4px]'
      } else if (this.size === 'md') {
        return 'tw-text-main-sm2 tw-px-[10px] tw-py-[4px]'
      }
      return 'tw-text-main-sm tw-px-[10px] tw-py-[4px]'
    },
    colorClasses () {
      if (this.type === 'add') {
        return 'tw-text-an-flat-black-main enabled:tw-text-an-flat-green-btn-bg enabled:tw-bg-an-flat-green-btn-bg/5 disabled:tw-text-an-flat-disabled-text'
      } else if (this.type === 'edit') {
        return 'tw-text-an-flat-black-main enabled:hover:tw-text-an-flat-dark-blue-link enabled:hover:tw-bg-an-flat-dark-blue-link/5 disabled:tw-text-an-flat-disabled-text'
      } else if (this.type === 'delete') {
        return 'tw-text-an-flat-black-main enabled:hover:tw-text-an-flat-red-main enabled:hover:tw-bg-an-flat-red-main/5 disabled:tw-text-an-flat-disabled-text'
      }
      return 'tw-text-an-flat-black-main enabled:hover:tw-text-an-flat-dark-blue-link enabled:hover:tw-bg-an-flat-dark-blue-link/5 disabled:tw-text-an-flat-disabled-text'
    }
  }
}
</script>
