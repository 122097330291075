import { RouteKeys } from '@/router/types'

export default {
  path: RouteKeys['position-structure'],
  name: RouteKeys['position-structure'],
  redirect: { name: `${RouteKeys['position-structure']}-list` },
  meta: { name: 'Права Должность-Структура', icon: 'block-company-positionStructure', isAdmin: true },
  component: () => import('@/views/Company/PermissionStructureRules/Index.vue'),
  children: [
    {
      path: 'list',
      name: `${RouteKeys['position-structure']}-list`,
      meta: { name: 'Список структурных прав', icon: 'block-company-positionStructure', hideInMenu: true },
      component: () => import('@/views/Company/PositionStructure/List.vue')
    },
    {
      path: ':positionId/:structureId',
      name: `${RouteKeys['position-structure']}-update`,
      meta: { name: 'Права для связки Должность-Структура', icon: 'block-company-positionStructure', hideInMenu: true },
      component: () => import('@/views/Company/PositionStructure/Edit.vue'),
      props: (route:any) => ({
        structureId: route.params?.structureId || 'none',
        positionId: route.params?.positionId || 'none'
      })
    }
  ]
}
