<template>
  <div
    v-cloak
    v-if="info"
    class="tw-flex tw-gap-[12px] tw-flex-col"
  >
    <TwPanelContentBlockFieldView>
      <template #title>
        Тип
      </template>
      <div v-if="info.type && info.type.label">
        {{ info.type.label }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView v-if="info.type && info.type.value === 'developer'">
      <template #title>
        Подтипы
      </template>
      <div v-if="Array.isArray(info.subtype) && info.subtype.length">
        {{ info.subtype.map(st => st.label).join(', ') }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Название
      </template>
      <div v-if="info.name">
        {{ info.name }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Логотип
      </template>
      <div v-if="info.image && info.image.mime.indexOf('image') !== -1">
        <img
          :src="getFileResizeByKey(info.image, 'default')"
          class="tw-h-[90px] tw-max-w-full"
          alt="Логотип компании"
        >
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Процент вознаграждения
      </template>
      <div v-if="info.reward">
        {{ info.reward }}%
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Основная компания
      </template>
      <ViewPanelLink
        v-if="info.parent"
        :id="info.parent.id"
        label="Компания"
        component="company-show"
      >
        {{ info.parent.name }}
      </ViewPanelLink>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Город
      </template>
      <div v-if="city">
        {{ city.label }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Активность
      </template>
      <div>
        {{ info.active ? 'Да':'Нет' }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Контакты
      </template>
      <div
        v-if="Array.isArray(info.contacts) && info.contacts.length"
        class="tw-text-main-sm2"
      >
        <div
          v-for="(contact, index) in info.contacts"
          :key="index"
        >
          <div>
            <strong>Отдел {{ contact.department && contact.department.label }}</strong>
            <div
              v-if="contact.email"
              class="tw-ml-[5px]"
            >
              <span class="tw-text-an-flat-disabled-text">Email</span>
              {{ contact.email }}
            </div>
            <div
              v-if="contact.phone"
              class="tw-ml-[5px]"
            >
              <span class="tw-text-an-flat-disabled-text">Тел.</span>
              {{ contact.phone | formatPhone }}
            </div>
          </div>
        </div>
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Дата создания
      </template>
      <div v-if="info.createdAt">
        {{ $Global.DateFormat.DateTimeShortMonthLong(info.createdAt) }}
      </div>
    </TwPanelContentBlockFieldView>
  </div>
</template>

<script>
import citySampleList from './../citySampleList'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink'
import { getFileResizeByKey } from '@/plugins/helpers/fileHelpers'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'

export default {
  name: 'CompanyMainShow',
  components: { TwPanelContentBlockFieldView, ViewPanelLink },
  props: {
    info: {},
    dictionary: {}
  },
  computed: {
    city () {
      return this.info?.cityUuid ? citySampleList.find(item => item.value === this.info.cityUuid) : null
    }
  },
  methods: {
    getFileResizeByKey: getFileResizeByKey
  }
}
</script>
