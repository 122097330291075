<template>
  <PageViewDefaultWrapper :page-content-class="''">
    <template #default>
      <div class="tw-px-[14px]">
        <TwPanelContentBlock class="tw-mb-[10px]">
          <template #default>
            <VariantFilterMatrix @change="changeVariantFilters" />
          </template>
        </TwPanelContentBlock>
        <div>
          <!-- ФИЛЬТРЫ ПОДБОРА ПРОГРАММ -->

          <!-- ИНФОРМАЦИЯ О ЛИДЕ -->
          <!--  TODO CRM-3271 Заблокировали, до выяснения потребностей отдела Ипотеки, сейчас только Admin  -->
          <LeadInfoBlock
            v-if="isAdmin"
            id="mortgage-lead"
            class="tw-mb-[40px]"
            :lead-id="leadId"
            @leadCreate="leadCreate"
          />
          <!-- СОЗДАНИЕ ЗАЯВКИ -->
          <div
            v-if="leadId"
            id="mortgage-deal-create"
          >
            <div v-if="Array.isArray(selectedPrograms) && selectedPrograms.length">
              <MortgageDealCreate
                :mortgage-deal-id="mortgageDealId"
                :lead-id="leadId"
                :filters="variantFilters"
                :programs="selectedPrograms"
                :current-step="currentStep"
                @success="dealCreate"
              />
            </div>
            <div
              v-else
              class="tw-text-center tw-p-[15px] tw-text-an-flat-disabled-text"
            >
              Выберите подходящие программы чтобы создать сделку по ипотеке
            </div>
          </div>
          <!-- СПИСОК ПОДОБРАННЫХ ПРОГРАММ -->
          <div class="">
            <SubsidizedProgramsSelectorMatrix
              ref="programSelector"
              v-model="selectedPrograms"
              :filters="variantFilters"
              :lead-id="leadId"
              :show-select="!!leadId"
              @input="changePrograms"
            />
          </div>
        </div>
      </div>
    </template>
    <template #footer />
  </PageViewDefaultWrapper>
</template>

<script>
import VariantFilterMatrix from '@/views/Mortgage/Variant/Matrix/VariantFilterMatrix'
import LeadInfoBlock from '@/views/Mortgage/Variant/lead/LeadInfoBlock'
import MortgageDealCreate from '@/views/Mortgage/Deal/MortgageDealCreate'
import PageViewDefaultWrapper from '@/components/core/ViewDefault/PageViewDefaultWrapper'
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import SubsidizedProgramsSelectorMatrix from '@/views/Mortgage/Variant/Matrix/SubsidizedProgramsSelectorMatrix'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'VariantSelectionMatrix',
  components: {
    TwPanelContentBlock,
    SubsidizedProgramsSelectorMatrix,
    PageViewDefaultWrapper,
    MortgageDealCreate,
    LeadInfoBlock,
    VariantFilterMatrix
  },
  mixins: [ViewPanelShowMixin],
  props: {
    initialValues: {
      type: Object,
      default: null
    },
    workToClose: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      currentStep: 0,
      scrollerOptions: {
        duration: 300,
        offset: 30,
        easing: 'easeInOutCubic'
      },
      isDealCreated: false, // создана ли заявка
      variantFilters: {},
      selectedPrograms: []
    }
  },
  methods: {
    changeVariantFilters (newVariantFilters) {
      // this.$set(this, 'variantFilters', newVariantFilters)
      this.$set(this, 'variantFilters', newVariantFilters)
      this.$nextTick(() => {
        if (this.$refs.programSelector) {
          this.$refs.programSelector.refresh(newVariantFilters)
        }
      })
    },
    dealCreate (data) {
      this.isDealCreated = true
      if (this.workToClose) {
        this.$emit('close-me')
      }
      this.$nextTick(() => {
        this.showDealCard(data.data.id)
      })
    },
    leadCreate (data) {
      console.warn('leadCreate ', data)
      if (data && data.id) {
        this.leadId = data.id
        this.stepChange(2)
      } else {
        alert('Не удалось определить ID Лида!')
      }
    },
    changePrograms () {
      if (Array.isArray(this.selectedPrograms) && this.selectedPrograms.length) {
        // делаю шаг к этапу
        this.stepChange(3)
      } else {
        // делаю шаг к этапу
        this.stepChange(this.leadId ? 2 : 1)
      }
    },
    stepChange (to) {
      this.currentStep = to
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'profile/isAdmin'
    }),
    leadId: {
      get () {
        const { leadId } = this.$route.query
        return parseInt(leadId) || null
      },
      set (val) {
        if (!val || val !== this.leadId) {
          const newQuery = { ...this.$route.query, ...{ leadId: val } }
          console.warn('newQuery leadId', newQuery)
          this.$router.replace({ query: newQuery })
        }
      }
    },
    mortgageDealId () {
      const { mortgageDealId } = this.$route.query
      return parseInt(mortgageDealId) || null
    }
  },
  watch: {
    leadId (newValue) {
      if (newValue) {
        this.stepChange(2)
      } else {
        this.stepChange(1)
      }
    }
  },
  mounted () {
    // console.warn(this.$options.name, ' mounted ', this.initialValues)
    if (this.initialValues) {
      // применяем значения если они были переданы
      if (this.initialValues.leadId) {
        this.leadId = this.initialValues.leadId
      }
    }
    if (this.leadId) {
      this.stepChange(2)
    }
  },
  beforeDestroy () {
    if (this.$route.query && Object.keys(this.$route.query).length) {
      this.$router.replace({ query: {} })
    }
  },
  // предупреждение перед закрытием подбора если заполнены поля
  beforeRouteLeave (to, from, next) {
    if (this.leadId && !this.isDealCreated) {
      const isExit = confirm('Сделка не создана! Закрыть?')
      next(isExit)
    } else {
      next()
    }
  }
}
</script>
