import { AxiosResponse } from 'axios'
import HTTP from '@/Api/http'

const path = '/deal/insurance'

export default {
  getHistory (dealId: number, otherConfig?: object): Promise<AxiosResponse> {
    const $config = {
      ...(otherConfig || {})
    }
    return HTTP.get(`${path}/history/${dealId}`, $config)
  },
  getMortgage (dealId: number, otherConfig?: object): Promise<AxiosResponse> {
    const $config = {
      ...(otherConfig || {})
    }
    return HTTP.get(`${path}/mortgage/${dealId}`, $config)
  },
  createFromOtherInsurance (data: object): Promise<AxiosResponse> {
    const $config = {}
    return HTTP.post(`${path}/deal/from-object-insurance`, data, $config)
  }
}
