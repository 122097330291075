import { render, staticRenderFns } from "./UserSocialMediaBlock.vue?vue&type=template&id=d2b0db74&scoped=true"
import script from "./UserSocialMediaBlock.vue?vue&type=script&lang=js"
export * from "./UserSocialMediaBlock.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d2b0db74",
  null
  
)

export default component.exports