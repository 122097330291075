<template>
  <span :class="classes">
    %
  </span>
</template>

<script>
export default {
  name: 'Percent',
  props: {
    classes: {
      default: 'grey--text lighten-1 tw-text-xs'
    }
  }
}
</script>
