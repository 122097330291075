<template>
  <form
    ref="form"
    class="tw-px-[23px] tw-pt-[19px] tw-pb-[23px] tw-bg-an-flat-bg-main tw-rounded-md"
  >
    <div class="tw-mb-[6px] tw-text-main-sm tw-font-bold">
      Создать сделку по ипотеке:
    </div>
    <div class="tw-flex tw-flex-row tw-gap-[16px]">
      <div class="tw-flex tw-flex-wrap tw-gap-[16px] tw-w-full">
        <div class="tw-flex-1 tw-min-w-[200px]">
          <TwSelect
            v-model="form.subtype"
            label="Вид услуги:"
            placeholder="Вид услуги:"
            :persistent-label="true"
            :dense="true"
            :options="subTypeMortgageOptions"
            :error-text="form.subtype ? '' : 'Обязательное поле'"
            :disabled="formProcessing"
          />
        </div>
        <div class="tw-flex-1 tw-min-w-[250px]">
          <TwSelectRelations
            v-model="form.responsibleUserId"
            class="tw-w-full"
            :multi="false"
            label="Основной ответственный:"
            list-label="Найти ответственного"
            relation-name="user"
            persistent-label
            :dense="true"
            :error-text="form.responsibleUserId ? '' : 'Обязательное поле'"
            :default-filters="userDefaultFilters"
          />
        </div>
        <div class="tw-flex-1 tw-min-w-[250px]">
          <TwSelectRelations
            v-model="form.otherResponsibleUsersId"
            class="tw-w-full"
            :multi="true"
            label="Другие ответственные:"
            list-label="Найти ответственного"
            relation-name="user"
            :dense="true"
            :default-filters="userDefaultFilters"
          />
        </div>
        <div class="tw-flex-1 tw-min-w-[250px]">
          <button
            class="tw-mt-[21px] tw-w-full tw-p-[11px] tw-rounded-sm tw-text-white tw-uppercase tw-font-semibold tw-text-[12px] tw-bg-an-flat-blue-btn"
            :class="{ 'tw-opacity-20 tw-cursor-default': formProcessing || (!form.subtype || !form.responsibleUserId),}"
            type="button"
            :disabled="formProcessing"
            @click="handleSubmit"
          >
            <template v-if="formProcessing">
              Подождите
            </template>
            <template v-else>
              {{ mortgageDealId ? 'Сохранить изменения' : 'Создать заявку' }}
            </template>
          </button>
        </div>
      </div>
    </div>
    <div class="tw-text-main-xs tw-text-an-flat-red-main tw-text-center tw-p-3">
      {{ errorText }}
    </div>
  </form>
</template>

<script>
import API from '@/Api/Crm/Deal/Mortgage'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import TwSelect from '@/components/tw-ui/ui-kit/input/TwSelect.vue'
import TwSelectRelations from '@/components/tw-ui/ui-kit/input/select-relations/TwSelectRelations.vue'
export default {
  name: 'MortgageDealCreate',
  components: {
    TwSelectRelations,
    TwSelect
  },
  mixins: [FormCreateEditMixin],
  props: {
    currentStep: {
      type: Number,
      default: 0
    },
    leadId: {
      type: Number || null,
      default: null
    },
    mortgageDealId: {
      type: Number || null,
      default: null
    },
    filters: {
      required: true
    },
    programs: {
      required: true
    }
  },
  data () {
    return {
      API: API,
      form: {
        subtype: null,
        responsibleUserId: null,
        otherResponsibleUsersId: []
      },
      userDefaultFilters: {
        active: true
      },
      errorText: null
    }
  },
  computed: {
    submitData () {
      const { firstSum, matCapitalFirstPay, rono, sum, term, ...personsInfo } = this.filters
      return {
        type: 'mortgage',
        leadId: this.leadId,
        ...this.form,
        ...personsInfo,
        mortgageDeal: {
          firstSum,
          matCapitalFirstPay,
          rono,
          sum,
          term,
          programs: this.programs.map(p => {
            return {
              programId: p,
              approve: null,
              comment: ''
            }
          })
        }
      }
    },
    subTypeDict () {
      return this.dictionary?.subtype || {}
    },
    subTypeMortgageOptions () {
      return Array.isArray(this.subTypeDict.mortgage) ? this.subTypeDict.mortgage : []
    }
  },
  methods: {
    presetData () {
      // clean mixin method
      return new Promise((resolve) => resolve)
    },
    handleSubmit () {
      if (this.form.subtype && this.form.responsibleUserId) {
        if (this.formProcessing === true) {
          return
        }

        this.formProcessing = true
        const request = this.mortgageDealId
          ? API.update(this.mortgageDealId, this.submitData)
          : API.store(this.submitData)

        request.then(({ data }) => {
          console.warn(this.$options.name, 'handleSubmit', data)
          this.$emit('success', data)
        }).catch((error) => {
          this.errorText = error?.response?.data || 'Произошла ошибка!'
        }).finally(() => {
          this.formProcessing = false
        })
      } else {
        console.error('Не заполнены обязательные поля!', this.form)
      }
    }
  }
}
</script>
