const getUuid = (a = ''): string => (
  a
    /* eslint-disable no-bitwise */
    ? ((Number(a) ^ Math.random() * 16) >> Number(a) / 4).toString(16)
    : (`${1e7}-${1e3}-${4e3}-${8e3}-${1e11}`).replace(/[018]/g, getUuid)
)

const scrollToBottomMSG = (objScroll: { block: any, behavior: any }) => {
  const { block, behavior } = objScroll
  const bottomChat = document.querySelector('.bottomChat') as HTMLElement
  if (bottomChat !== null) {
    bottomChat.scrollIntoView({ block: block, behavior: behavior })
  }
}

const scrollToElement = (objScroll: { block: any, behavior: any, msgID: any, load: boolean }) => {
  const { block, behavior, msgID, load } = objScroll
  const getIDMsg = document.getElementById(`${msgID}`) as HTMLElement
  if (getIDMsg !== null) {
    getIDMsg.scrollIntoView({ block: block, behavior: behavior })
    if (load) {
      getIDMsg.classList.add('animate')
      setTimeout(() => {
        getIDMsg.classList.remove('animate')
      }, 900)
    }
  } else {
    return getIDMsg
  }
}

const changeVisibleIconItem = (id: string) => {
  const layoutMessenger = document.getElementById(id) as HTMLElement
  const iconLayoutMessenger = layoutMessenger.querySelector('.chat-right-body-dots') as HTMLElement
  iconLayoutMessenger.style.opacity = '1'
}
const changeHiddenIconItem = (id: string) => {
  const layoutMessenger = document.getElementById(id) as HTMLElement
  const iconLayoutMessenger = layoutMessenger.querySelector('.chat-right-body-dots') as HTMLElement
  iconLayoutMessenger.style.opacity = '0'
}

const resizeWindowHeight = (num: any) => {
  const resultHeight:any = document?.documentElement?.clientHeight - num
  return resultHeight || 720
}

const watchText = (formValue:string, event: string) => {
  let textError:any
  if (!formValue && /\s/.test(event)) {
    textError = 'Поле должно содержать символы'
  } else if (formValue.length < 1) {
    textError = 'Поле не должно быть пустым'
  } else {
    textError = ''
  }
  return textError
}

const hideErrorText = (input:any, className: string, text:string) => {
  const element = document.querySelector(`.${className}`) as HTMLElement
  if (element === null && input !== 'undefined') return
  if (input?.isFocused) {
    if (element?.textContent?.includes(text)) {
      element.style.opacity = '1'
    }
  } else {
    if (element?.textContent?.includes(text)) {
      element.style.opacity = '0'
    }
  }
}

const closeActiveModal = (className: string, classNameActive: string) => {
  const chatWrapper = document.querySelector(`.${className}`) as HTMLElement
  chatWrapper.classList.remove(`${classNameActive}`)
}

const newParamsUser = (paramsUser: any) => {
  const { chatId, selectedUserId, type, owner, name } = paramsUser
  const newParamsUser: object = {
    chatID: chatId || null,
    count: 0,
    name: name || null,
    owner: owner || {},
    selectedUserId: selectedUserId || null,
    status: undefined,
    type: type || null // ?? null or 0
  }
  return newParamsUser
}

const setObjectChatRoom = (chatId: number, count?: number) => {
  const objectChatRoom: object = {
    chatID: chatId,
    count: count,
    previewID: 0,
    message: [],
    endChat: false
  }
  return objectChatRoom
}

const createFakeChat = (userID: number, status: string, name: string) => {
  const fakeID = getUuid()
  const dataListChat:any = {
    id: fakeID,
    tmpId: fakeID,
    userIds: [userID],
    status: status,
    name: name,
    type: 'personal',
    owner: {},
    users: [{ innerId: userID }]
  }
  return dataListChat
}

const createFakeMessage = (tmpId: number, text: string, files: any, answerForId: number, answerForText: string, status: string, userExtId: any) => {
  const fakeMsg:any = {
    date: new Date(),
    id: tmpId,
    text: text,
    files: files,
    answerForId: answerForId,
    answerForText: answerForText,
    status: status,
    user: { innerId: userExtId }
  }
  return fakeMsg
}
export {
  getUuid,
  scrollToBottomMSG,
  scrollToElement,
  changeVisibleIconItem,
  changeHiddenIconItem,
  resizeWindowHeight,
  watchText,
  hideErrorText,
  closeActiveModal,
  newParamsUser,
  setObjectChatRoom,
  createFakeChat,
  createFakeMessage
}
