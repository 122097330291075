import { RouteKeys } from '@/router/types'
import dev from './dev'
import tools from './tools'
import dictionary from './dictionary'
import company from './company'
import reports from './reports'
import news from './news'
import site from './site/admin'
import newbuildings from './newbuildings'
import mortgage from './mortgage'
import review from '@/router/routes/management/review'
import ads from '@/router/routes/management/ads'
import accounting from '@/router/routes/management/accounting'
import dictPermissionRules from '@/Permissions/dictRules'
import changeLog from '@/router/routes/management/changeLog'

export default {
  path: `/${RouteKeys.management}`,
  name: RouteKeys.management,
  meta: { name: 'Панель управления', hideInMenu: true },
  component: () => import('@/views/Management/Index.vue'),
  children: [
    dev,
    {
      meta: { name: 'Сервисы - статус', icon: 'services-statuses', isAdmin: true },
      name: 'Сервисы - статус',
      path: '/service-status',
      component: () => import(/* webpackChunkName: "ServiceStatus", webpackPrefetch: true */ '@/views/ServiceStatus/ServiceStatusPage.vue')
    },
    {
      path: '/docs',
      props: (route:any) => ({ pathKey: route?.query?.pathKey || '' }),
      name: `${RouteKeys.project}-docs`,
      meta: { name: 'Документация', icon: 'documentation', ruleNamespace: dictPermissionRules.AppServicePermissionRulesDOCReadDocumentationRule },
      component: () => import('@/views/Project/Docs/DocsPreviewPage.vue')
    },
    newbuildings,
    mortgage,
    tools,
    dictionary,
    company,
    accounting,
    reports,
    review,
    news,
    changeLog,
    ads,
    site
  ]
}
