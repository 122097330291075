import { SERVICES } from '@/Api/Services'
import ComplexParams from '@/Api/Mortgage/ComplexParams'
import DiscountMatrix from '@/Api/Mortgage/DiscountMatrix'
import ProgramCategory from '@/Api/Mortgage/ProgramCategory'
import Program from '@/Api/Mortgage/Program'
import ProgramRate from '@/Api/Mortgage/ProgramRate'
import Search from '@/Api/Mortgage/Search'
import Requirement from '@/Api/Mortgage/Requirement'
import UserComments from '@/Api/Mortgage/UserComments'
import SeoPage from '@/Api/Mortgage/SeoPage'

export const AuthAxiosDefaultConfig: any = {
  serviceKey: SERVICES.MORTGAGE.key
}

export const API: any = {
  ComplexParams,
  DiscountMatrix,
  ProgramCategory,
  Program,
  ProgramRate,
  Search,
  Requirement,
  UserComments,
  SeoPage
}
