<template>
  <div class="tw-mt-[15px] tw-flex tw-gap-[10px]">
    <button
      v-if="handleSubmit"
      :disabled="disableAll || disableSubmit"
      class="tw-grow tw-px-[15px] tw-py-[7px] tw-bg-an-flat-green-btn-bg tw-rounded-sm tw-text-white disabled:tw-opacity-50"
      @click.prevent="handleSubmit"
    >
      {{ titleSubmit }}
    </button>
    <button
      v-if="handleCancel"
      :disabled="disableAll || disableCancel"
      class="tw-w-[100px] tw-px-[15px] tw-py-[7px] tw-bg-an-flat-gray-4 tw-rounded-sm tw-text-white enabled:hover:tw-bg-an-flat-red-main"
      @click.prevent="preCancel"
    >
      {{ titleCancel }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'TwCardBlockFormActions',
  props: {
    handleSubmit: {
      type: Function,
      default: null
    },
    handleCancel: {
      type: Function,
      default: null
    },
    disableAll: {
      type: Boolean,
      default: false
    },
    disableSubmit: {
      type: Boolean,
      default: false
    },
    disableCancel: {
      type: Boolean,
      default: false
    },
    titleSubmit: {
      type: String,
      default: 'Отправить'
    },
    titleCancel: {
      type: String,
      default: 'Отменить'
    },
    useCancelAlert: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    preCancel () {
      if (this.useCancelAlert && confirm('Подтвердите отмену')) {
        this.handleCancel()
      } else {
        this.handleCancel()
      }
    }
  }
}
</script>
