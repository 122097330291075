import { AxiosResponse } from 'axios'
import HTTP from '@/Api/http'

const path = '/deal/distribution/responsible-user'

export default {
  listInit (): Promise<AxiosResponse> {
    const $config: any = {}
    return HTTP.get(`${path}/list-init`, $config)
  },
  getList (param?: object): Promise<AxiosResponse> {
    const $config = {
      params: param
    }
    return HTTP.get(`${path}`, $config)
  },
  create (): Promise<AxiosResponse> {
    const $config = {}
    return HTTP.get(`${path}/create`, $config)
  },
  store (data: object): Promise<AxiosResponse> {
    return HTTP.post(`${path}`, data)
  },
  edit (id: number): Promise<AxiosResponse> {
    const $config = {}
    return HTTP.get(`${path}/${id}`, $config)
  },
  update (id: number, data: object): Promise<AxiosResponse> {
    return HTTP.put(`${path}/${id}`, data)
  },
  show (id: number): Promise<AxiosResponse> {
    const $config = {}
    return HTTP.get(`${path}/${id}`, $config)
  },
  delete (id: number): Promise<AxiosResponse> {
    const $config = {}
    return HTTP.delete(`${path}/${id}`, $config)
  },

  forDistributeList (dealId: number, param: object): Promise<AxiosResponse> {
    const $config = {
      params: param
    }
    return HTTP.get(`${path}/for-distribute/${dealId}`, $config)
  },
  distribute (data: object): Promise<AxiosResponse> {
    const $config = {}
    return HTTP.post(`${path}/distribute`, data, $config)
  }
}
