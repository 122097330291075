<template>
  <div
    :style="{ width: width, height: height, borderWidth: borderWidth }"
    :class="[borderColorClass, styleBorder]"
    class="tw-inline-block tw-h-8 tw-w-8 tw-animate-spin tw-rounded-full tw-border-4 tw-border-solid tw-border-r-transparent tw-align-[-0.125em] motion-reduce:tw-animate-[spin_1.5s_linear_infinite]"
    role="status"
  >
    <span
      class="!tw-absolute !tw--m-px !tw-h-px !tw-w-px !tw-overflow-hidden !tw-whitespace-nowrap !tw-border-0 !tw-p-0 ![clip:rect(0,0,0,0)]"
    >Loading...</span>
  </div>
</template>

<script>
export default {
  name: 'TwCircleLoader',
  props: {
    width: {
      type: String,
      default: '30px'
    },
    height: {
      type: String,
      default: '30px'
    },
    borderWidth: {
      type: String,
      default: '4px'
    },
    borderStyle: {
      type: String,
      default: 'dashed'
    },
    borderColorClass: {
      type: String,
      default: 'tw-border-an-flat-loader-default'
    }
  },
  computed: {
    styleBorder () {
      return this.borderStyle === 'solid' ? 'tw-border-solid' : 'tw-border-dashed'
    }
  }
}
</script>

<style scoped>

</style>
