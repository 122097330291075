import { RouteKeys } from '@/router/types'

export default {
  path: `${RouteKeys['lead-source']}`,
  name: RouteKeys['lead-source'],
  redirect: { name: `${RouteKeys['lead-source']}-list` },
  meta: { name: 'Лид - Источники', icon: 'lead-source' },
  component: () => import('@/views/Dictionary/Lead/Source/Index.vue'),
  children: [
    {
      path: 'list',
      name: `${RouteKeys['lead-source']}-list`,
      meta: { name: 'Список источников для Лидов', icon: 'lead-source', hideInMenu: true },
      component: () => import('@/views/Dictionary/Lead/Source/List.vue')
    },
    {
      path: ':id/edit',
      name: `${RouteKeys['lead-source']}-edit`,
      meta: { name: 'Редактирование источник Лид', icon: 'lead-source', hideInMenu: true },
      component: () => import('@/views/Dictionary/Lead/Source/Edit.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    },
    {
      path: 'create',
      name: `${RouteKeys['lead-source']}-create`,
      meta: { name: 'Добавление источник Лид', icon: 'lead-source', hideInMenu: true },
      component: () => import('@/views/Dictionary/Lead/Source/Edit.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    },
    {
      path: ':id',
      name: `${RouteKeys['lead-source']}-show`,
      meta: { name: 'Просмотр источника Лида', icon: 'lead-source', hideInMenu: true },
      component: () => import('@/views/Dictionary/Lead/Source/Edit.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    }
  ]
}
