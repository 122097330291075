import { RouteKeys } from '@/router/types'
import dictPermissionRules from '@/Permissions/dictRules'

export default {
  path: RouteKeys.employee,
  name: RouteKeys.employee,
  redirect: { name: `${RouteKeys.employee}-list` },
  meta: { name: 'Сотрудники', icon: 'block-company-employee', ruleNamespace: dictPermissionRules.AppServicePermissionRulesCRMUsersReadUserRule },
  component: () => import('@/views/Company/Employee/Index.vue'),
  children: [
    {
      path: 'list',
      name: `${RouteKeys.employee}-list`,
      meta: { name: 'Список сотрудников', icon: 'block-company-employee', hideInMenu: true },
      component: () => import('@/views/Company/Employee/List.vue')
    },
    {
      path: ':id/edit',
      name: `${RouteKeys.employee}-edit`,
      meta: { name: 'Редактирование сотрудника', icon: 'block-company-employee', hideInMenu: true },
      component: () => import('@/views/Company/Employee/EmployeeCard.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    },
    {
      path: 'create',
      name: `${RouteKeys.employee}-create`,
      meta: { name: 'Добавление сотрудника', icon: 'block-company-employee', hideInMenu: true },
      component: () => import('@/views/Company/Employee/EmployeeCard.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    },
    {
      path: ':id',
      name: `${RouteKeys.employee}-show`,
      meta: { name: 'Просмотр сотрудника', icon: 'block-company-employee', hideInMenu: true },
      component: () => import('@/views/Company/Employee/EmployeeCard.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    }
  ]
}
