import { AxiosResponse } from 'axios'
import HTTP from '@/Api/http'

const path = function (dealId:number): string { return `/deal/${dealId}/deal-document` }

export default {
  // Подписание договоров
  create (params = {}, dealId: number): Promise<AxiosResponse> {
    const $config = {
      params
    }
    return HTTP.get(`${path(dealId)}/create`, $config)
  },
  show (dealId: number): Promise<AxiosResponse> {
    const $config = {}
    return HTTP.get(`${path(dealId)}`, $config)
  },
  store (dealId: any, data: any): Promise<AxiosResponse> {
    return HTTP.post(`${path(dealId)}`, data)
  },
  update (dealId: any, data: any): Promise<AxiosResponse> {
    return HTTP.post(`${path(dealId)}`, data)
  },
  searchDealList (dealId: number, param?: object, otherConfig?: object): Promise<AxiosResponse> {
    const $config = {
      params: param,
      ...(otherConfig || {})
    }
    return HTTP.get(`${path(dealId)}/interface/search-deal`, $config)
  }
}
