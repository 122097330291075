<template>
  <div>
    <TwPanelContentBlock class="tw-mb-[6px]">
      <template #title>
        Комментарии
      </template>
      <template #action>
        <TwPanelContentBlockActionButton
          v-if="!isCreateForm && !isSuccessTask && !isClosedTask"
          type="add"
          :disabled="isUpdatingItem"
          @click="isCreateForm = !isCreateForm"
        >
          Добавить комментарий
        </TwPanelContentBlockActionButton>
      </template>
      <div>
        <template v-if="isCreateForm">
          <TaskCommentMainForm
            :task-id="taskId"
            @cancel="isCreateForm = false"
            @create="refresh"
          />
          <hr class="tw-mt-[24px]">
        </template>
        <div>
          <SupportTaskStatusForm
            v-if="isSuccessTask && !isClosedTask"
            v-bind="$props"
            @update="refresh"
          />
          <div
            v-if="loading"
            class="tw-flex"
          >
            <TwCircleLoader class="tw-m-auto" />
          </div>
          <div v-if="Array.isArray(items) && items.length">
            <TaskCommentItem
              v-for="(item, index) in items"
              :key="item.id"
              :task-id="taskId"
              :item="item"
              :is-not-editable="isSuccessTask || isClosedTask"
              :is-updating-item="isUpdatingItem || isCreateForm"
              :divider="index < items.length - 1"
              @update="isUpdatingItem = $event"
              @refresh="refresh"
            />
          </div>
          <div
            v-else-if="!isCreateForm"
            class="tw-text-center tw-py-2"
          >
            Отсутствуют
          </div>
        </div>
      </div>
    </TwPanelContentBlock>
  </div>
</template>

<script>
import DataListMixin from '@/mixins/DataListMixin'
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import API from '@/Api/Support/Task/Comment'
import TaskCommentItem from '@/views/Support/comment/TaskCommentItem'
import TaskCommentMainForm from '@/views/Support/comment/main/TaskCommentMainForm'
import SupportTaskStatusForm from '@/views/Support/task/main/SupportTaskStatusForm'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwPanelContentBlockActionButton from '@/components/tw-ui/panels/TwPanelContentBlockActionButton.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'

export default {
  name: 'SupportCommentBlock',
  components: {
    TwCircleLoader,
    TwPanelContentBlockActionButton,
    TwPanelContentBlock,
    SupportTaskStatusForm,
    TaskCommentMainForm,
    TaskCommentItem
  },
  mixins: [DataListMixin, ViewPanelShowMixin],
  props: {
    taskId: {
      type: [Number, String],
      required: true
    },
    info: {
      type: Object,
      default: () => {
        return {}
      }
    },
    dictionary: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      API,
      isCreateForm: false,
      isUpdatingItem: false
    }
  },
  computed: {
    items () {
      return (Array.isArray(this.data) && [...this.data, ...[]]) || []
    },
    isSuccessTask () {
      return this?.info?.status === 10
    },
    isClosedTask () {
      return this?.info?.status === 20
    }
  },
  mounted () {
    this.isCreateForm = false
    this.isUpdatingItem = false
    this.fetch({ taskId: this.taskId })
  },
  methods: {
    refresh () {
      this.isCreateForm = false
      this.isUpdatingItem = false
      this.fetch({ taskId: this.taskId })
      this.$nextTick(() => {
        this.$emit('update')
      })
    }
  }
}
</script>

<style scoped>

</style>
