import { AxiosResponse } from 'axios'
import HTTP from '@/Api/http'
import { AuthAxiosDefaultConfig } from '@/Api/Auth'

const path = '/user'

export default {
  create (param?: object, userId?: number): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig,
      params: param
    }
    return HTTP.get(`${path}/${userId}/social-media/create`, $config)
  },
  store (userId: number, data: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.post(`${path}/${userId}/social-media`, data, $config)
  },
  // edit (userId: number): Promise<AxiosResponse> {
  //   const $config: any = {
  //     ...AuthAxiosDefaultConfig
  //   }
  //   return HTTP.get(`${path}/${userId}`, $config)
  // },
  update (userId: number, data: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.put(`${path}/${userId}/social-media`, data, $config)
  },
  show (userId: number | string): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.get(`${path}/${userId}/social-media`, $config)
  }
}
