<template>
  <div
    class="tw-flex tw-flex-wrap tw-gap-[6px] tw-items-center"
  >
    <TwSelect
      key="quarter"
      :options="quarterOptions"
      placeholder="Выбор"
      :show-label="false"
      :dense="true"
      :persistent-label="false"
      :value="valueCalc[1]"
      @input="onInput($event, 1)"
    />
    <TwSelect
      key="year"
      :options="yearOptions"
      placeholder="Выбор"
      :show-label="false"
      :dense="true"
      :persistent-label="false"
      :value="valueCalc[0]"
      @input="onInput($event, 0)"
    />
  </div>
</template>

<script>
import TwSelect from '@/components/tw-ui/ui-kit/input/TwSelect.vue'

export default {
  name: 'ADateQuarterSelect',
  components: { TwSelect },
  props: {
    value: {
      type: [Object, null],
      default: () => null
    }
  },
  computed: {
    valueCalc () {
      return [this.value?.value[0] || null, this.value?.value[1] || null]
    },
    yearOptions () {
      const years = []
      const currentYear = new Date().getFullYear()
      let startYear = currentYear - 5
      while (startYear < currentYear) {
        startYear++
        years.push({
          value: startYear.toString(),
          label: startYear
        })
      }
      return years.reverse()
    },
    quarterOptions () {
      return [
        {
          label: 'I',
          value: 'quarter_1'
        },
        {
          label: 'II',
          value: 'quarter_2'
        },
        {
          label: 'III',
          value: 'quarter_3'
        },
        {
          label: 'VI',
          value: 'quarter_4'
        }
      ]
    }
  },
  methods: {
    onInput (value, index) {
      const newValue = this.value?.value
      newValue[index] = value
      this.$emit('input', newValue)
    }
  }
}
</script>
