<template>
  <div class="ProgSelectorCellPaysInlineMatrix">
    <template v-if="matrixItem.payMatrixPeriod">
      <label>Платеж льготного периода</label>
      <div class="flex-item-three-cols">
        <div>
          <span title="Платёж Банк">
            {{ Math.round(matrixItem.payMatrixPeriod.pay).toLocaleString() }}<Rouble />
          </span>
          <span
            v-if="matrixItem.payMatrixPeriod.rateNegative"
            :title="negativeRateText"
            class="negative-rate-label cursor-help"
          >*</span>
        </div>
        <div
          v-if="matrixItem.payMatrixPeriod.payFlat && matrixItem.payMatrixPeriod.payFlat !== matrixItem.payMatrixPeriod.pay"
          class="center"
        >
          <span title="Платёж Флэт">
            {{ Math.round(matrixItem.payMatrixPeriod.payFlat).toLocaleString() }}<Rouble />
          </span>
          <span
            v-if="matrixItem.payMatrixPeriod.rateFlatNegative"
            :title="negativeRateText"
            class="negative-rate-label cursor-help"
          >*</span>
        </div>
        <div
          v-else
          class="center"
        >
          -
        </div>
        <div v-if="matrixItem.payMatrixPeriod.payElectronRegistration && matrixItem.payMatrixPeriod.payElectronRegistration !== matrixItem.payMatrixPeriod.payFlat">
          <span title="Платёж Эл.рег.">
            {{ Math.round(matrixItem.payMatrixPeriod.payElectronRegistration).toLocaleString() }}<Rouble />
          </span>
          <span
            v-if="matrixItem.payMatrixPeriod.rateElectronRegistrationNegative"
            :title="negativeRateText"
            class="negative-rate-label cursor-help"
          >*</span>
        </div>
        <div v-else>
          -
        </div>
      </div>
    </template>
    <template v-if="matrixItem.payAfterMatrixPeriod">
      <label>Платеж остального периода</label>
      <div class="flex-item-three-cols">
        <div title="Платёж Банк">
          {{ Math.round(matrixItem.payAfterMatrixPeriod.pay).toLocaleString() }}<Rouble />
        </div>
        <div
          v-if="matrixItem.payAfterMatrixPeriod.payFlat && matrixItem.payAfterMatrixPeriod.payFlat !== matrixItem.payAfterMatrixPeriod.pay"
          title="Платёж Флэт"
          class="center"
        >
          {{ Math.round(matrixItem.payAfterMatrixPeriod.payFlat).toLocaleString() }}<Rouble />
        </div>
        <div
          v-else
          class="center"
        >
          -
        </div>
        <div
          v-if="matrixItem.payAfterMatrixPeriod.payElectronRegistration && matrixItem.payAfterMatrixPeriod.payElectronRegistration !== matrixItem.payAfterMatrixPeriod.payFlat"
          title="Платёж Эл.рег."
        >
          {{ Math.round(matrixItem.payAfterMatrixPeriod.payElectronRegistration).toLocaleString() }}<Rouble />
        </div>
        <div v-else>
          -
        </div>
      </div>
    </template>
    <template v-if="matrixItem.overPay">
      <label>
        Сумма уплаченных процентов по кредиту
        <br>за весь срок кредитования
      </label>
      <div class="flex-item-three-cols">
        <div title="Переплата Банк">
          {{ Math.round(matrixItem.overPay.sum).toLocaleString() }}<Rouble />
        </div>
        <div
          v-if="matrixItem.overPay.sumFlat && matrixItem.overPay.sumFlat !== matrixItem.overPay.sum"
          title="Переплата Флэт"
          class="center"
        >
          {{ Math.round(matrixItem.overPay.sumFlat).toLocaleString() }}<Rouble />
        </div>
        <div
          v-else
          class="center"
        >
          -
        </div>
        <div
          v-if="matrixItem.overPay.sumElectronRegistration && matrixItem.overPay.sumElectronRegistration !== matrixItem.overPay.sumFlat"
          title="Переплата Эл.рег."
        >
          {{ Math.round(matrixItem.overPay.sumElectronRegistration).toLocaleString() }}<Rouble />
        </div>
        <div v-else>
          -
        </div>
      </div>
    </template>
    <template v-if="matrixItem.economy">
      <label>Экономия</label>
      <div class="flex-item-three-cols">
        <div title="Платёж Банк">
          <div
            v-if="matrixItem.economy.economy"
            title="Экономия с Банком"
            class="text-center"
            :class="matrixItem.economy.economy > 0 ? 'success--text' : 'error--text'"
          >
            <span>{{ Math.round(matrixItem.economy.economy).toLocaleString() }}<Rouble /></span>
          </div>
        </div>
        <div
          v-if="matrixItem.economy.economyFlat && matrixItem.economy.economyFlat !== matrixItem.economy.economy"
          title="Платёж Флэт"
          class="center"
        >
          <div
            v-if="matrixItem.economy.economyFlat"
            title="Экономия с Флэт"
            class="text-center"
            :class="matrixItem.economy.economyFlat > 0 ? 'success--text' : 'error--text'"
          >
            <span>{{ Math.round(matrixItem.economy.economyFlat).toLocaleString() }}<Rouble /></span>
          </div>
        </div>
        <div
          v-else
          class="center"
        >
          -
        </div>
        <div
          v-if="matrixItem.economy.economyElectronRegistration && matrixItem.economy.economyElectronRegistration !== matrixItem.economy.economyFlat"
          title="Платёж Эл.рег."
        >
          <div
            v-if="matrixItem.economy.economyElectronRegistration"
            title="Экономия с Эл.рег."
            class="text-center"
            :class="matrixItem.economy.economyFlat > 0 ? 'success--text' : 'error--text'"
          >
            <span>{{ Math.round(matrixItem.economy.economyElectronRegistration).toLocaleString() }}<Rouble /></span>
          </div>
        </div>
        <div v-else>
          -
        </div>
      </div>
    </template>
    <div v-if="matrixItem.commissionRewardMatrixCalculateSum">
      <label style="border: none">КВ = <strong
        title="Сумма КВ до вычета денег застройщика"
        style="font-size: 14px"
      >{{ (matrixItem.commissionRewardMatrixCalculateSum || 0).toLocaleString() }} руб.</strong></label>
    </div>
    <template v-if="matrixItem.economyWithRewardSum">
      <label>Экономия за минусом {{ economyWithRewardSumTextFromDealType }}</label>
      <div class="flex-item-three-cols">
        <div title="Платёж Банк">
          <div
            v-if="matrixItem.economyWithRewardSum.economy"
            :title="`Экономия за минусом ${economyWithRewardSumTextFromDealType}, с Банком`"
            class="text-center"
            :class="matrixItem.economyWithRewardSum.economy > 0 ? 'success--text' : 'error--text'"
          >
            <span>{{ Math.round(matrixItem.economyWithRewardSum.economy).toLocaleString() }}<Rouble /></span>
          </div>
        </div>
        <div
          v-if="matrixItem.economyWithRewardSum.economyFlat && matrixItem.economyWithRewardSum.economyFlat !== matrixItem.economyWithRewardSum.economy"
          title="Платёж Флэт"
          class="center"
        >
          <div
            v-if="matrixItem.economyWithRewardSum.economyFlat"
            :title="`Экономия за минусом ${economyWithRewardSumTextFromDealType}, с Флэт`"
            class="text-center"
            :class="matrixItem.economyWithRewardSum.economyFlat > 0 ? 'success--text' : 'error--text'"
          >
            <span>{{ Math.round(matrixItem.economyWithRewardSum.economyFlat).toLocaleString() }}<Rouble /></span>
          </div>
        </div>
        <div
          v-else
          class="center"
        >
          -
        </div>
        <div
          v-if="matrixItem.economyWithRewardSum.economyElectronRegistration && matrixItem.economyWithRewardSum.economyElectronRegistration !== matrixItem.economyWithRewardSum.economyFlat"
          title="Платёж Эл.рег."
        >
          <div
            v-if="matrixItem.economyWithRewardSum.economyElectronRegistration"
            :title="`Экономия за минусом ${economyWithRewardSumTextFromDealType}, с Эл.рег.`"
            class="text-center"
            :class="matrixItem.economyWithRewardSum.economyFlat > 0 ? 'success--text' : 'error--text'"
          >
            <span>{{ Math.round(matrixItem.economyWithRewardSum.economyElectronRegistration).toLocaleString() }}<Rouble /></span>
          </div>
        </div>
        <div v-else>
          -
        </div>
      </div>
    </template>
    <div
      v-if="matrixItem.newCreditParams"
      class="warning white--text pa-1 mb-2"
    >
      <template v-if="matrixItem.newCreditParams.increaseSum">
        <div
          v-if="matrixItem.commissionRewardOptimizeSum || matrixItem.commissionRewardOptimizePercent"
          class="caption ma-2 text-wrap"
        >
          Важно! Застройщик по этому ЖК оплачивает КВ в размере:
          <span class="font-weight-bold">
            <template v-if="matrixItem.commissionRewardOptimizeSum">
              {{ Math.round((matrixItem.commissionRewardOptimizeSum || 0)).toLocaleString() }}<Rouble classes="white--text" />
            </template>
            <template v-if="matrixItem.commissionRewardOptimizePercent">
              {{ matrixItem.commissionRewardOptimizePercent }} %
            </template>
          </span>
        </div>
        <div class="d-flex justify-space-between mx-3">
          <div>
            <v-icon
              class="mb-1"
              small
              title="КВ минус сумма оплаченная застройщиком"
            >
              fa-question-circle
            </v-icon>
            Стоимость недвижимости увелич. на:
          </div>
          <div class="ml-2 font-weight-bold">
            {{ Math.round((matrixItem.newCreditParams.increaseSum || 0)).toLocaleString() }}<Rouble classes="white--text" />
          </div>
        </div>
        <div class="d-flex justify-space-between mx-3">
          <div>Новая стоимость недвижимости:</div>
          <div class="ml-2 font-weight-bold">
            {{ Math.round((matrixItem.newCreditParams.realtyPrice || 0)).toLocaleString() }}<Rouble classes="white--text" />
          </div>
        </div>
        <div class="d-flex justify-space-between mx-3">
          <div>Новая сумма кредита:</div>
          <div class="ml-2 font-weight-bold">
            {{ Math.round((matrixItem.newCreditParams.creditSum || 0)).toLocaleString() }}<Rouble classes="white--text" />
          </div>
        </div>
        <template>
          <div
            v-if="matrixItem.newCreditParams.warningCreditSumMaxDiff"
            class="mt-2 font-weight-bold text-wrap caption"
          >
            Сумма кредита больше возможной на {{ Math.round((matrixItem.newCreditParams.warningCreditSumMaxDiff || 0)).toLocaleString() }}<Rouble classes="white--text" /><br>
            Увеличьте ПВ
          </div>
        </template>
      </template>
      <template v-else>
        <div class="font-weight-bold">
          Удорожания нет
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Rouble from '@/components/symbol/Rouble'
export default {
  name: 'ProgSelectorCellPaysInlineMatrix',
  components: { Rouble },
  props: {
    matrixItem: {
      type: Object,
      required: true
    },
    subsidizedDealType: {
      type: [String, null],
      default: ''
    }
  },
  data () {
    return {
      negativeRateText: 'Попробуйте выбрать дисконт меньше'
    }
  },
  computed: {
    economyWithRewardSumTextFromDealType () {
      return this.subsidizedDealType === 'buy_nb' ? 'удорожания' : 'КВ'
    }
  }
}
</script>
<style scoped lang="less">
.ProgSelectorCellPaysInlineMatrix {
  cursor: default;
  display: block;
  text-align: center;
  label {
    border-bottom: 1px solid lightgrey;
    font-size: 12px;
    text-align: left;
  }
  .flex-item-three-cols {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .negative-rate-label {
    color: orangered;
  }
}
</style>
