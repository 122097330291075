<template>
  <div style="position:relative;">
    <APopoverWrapper
      ref="popoverWrapper"
      :disabled="disabled"
      :z-index="zIndex"
      @onOpen="() => onSearch()"
    >
      <template #trigger>
        <div
          class="select-input-block d-flex justify-start align-center"
          style="flex-wrap: wrap"
          :style="{ borderColor: Array.isArray(errorMessages) && errorMessages.length ? 'red' : '' }"
          tabindex="0"
        >
          <div
            v-if="isSelected && !simple"
            class="select-container"
          >
            <div
              v-for="(item, index) in fullValues"
              :key="index"
              class="select-badge"
              :style="{ backgroundColor: disabled ? 'lightgray' : '' }"
            >
              <div class="select-badge-text">
                {{ item.name }}
              </div>
              <div
                v-if="!disabled"
                class="cursor-pointer"
                style="position:absolute; right: 5px;"
                @click.stop="removeItem(item, index)"
              >
                <v-icon
                  class="ma-auto"
                  small
                >
                  fa-times
                </v-icon>
              </div>
            </div>
          </div>
          <div
            v-else-if="isSelected && simple"
            class="select-container"
          >
            <div
              class="select-badge"
              :style="{ backgroundColor: disabled ? 'lightgray' : '' }"
            >
              <div class="select-badge-text">
                {{ $Global.peopleName(fullValues, 'fi') }}
              </div>
              <div
                v-if="fullValues && !disabled"
                class="cursor-pointer"
                style="position:absolute; right: 5px;"
                @click.stop="clear"
              >
                <v-icon
                  class="ma-auto"
                  small
                >
                  fa-times-circle
                </v-icon>
              </div>
            </div>
          </div>
          <div
            class="text-muted"
            style="flex: 1"
          >
            <input
              v-model="searchStr"
              :placeholder="isSelected ? 'Поиск...':noSelectText"
              :disabled="disabled"
              style="width: 100%;height: 36px;outline: none; min-width: 90px; padding: 0 6px"
              type="text"
              @input="onSearch"
              @keypress.enter.stop="onSearch"
            >
          </div>
        </div>
        <div
          v-if="Array.isArray(errorMessages) && errorMessages.length"
          class="error__labels"
        >
          <label
            v-for="(error, index) in errorMessages"
            :key="index"
            class="error--text"
          >
            <small>{{ error }}</small>
          </label>
        </div>
      </template>
      <template #content>
        <div style="width: 100%; min-width: 300px; max-width: 500px; height: 300px; background-color: white; display: flex; flex-direction: column">
          <div
            v-if="searchProccess"
            class="text-center pa-2"
          >
            <v-progress-circular
              size="30"
              color="primary"
              indeterminate
            />
          </div>
          <div
            v-else-if="Array.isArray(list) && list.length"
            style="overflow: auto; display: grid;grid-template-columns: auto auto; gap: 5px"
          >
            <div
              v-for="(item, index) in list"
              :key="index"
              style="min-width: 180px;"
              :style="{ backgroundColor: item.id === value || Array.isArray(value) && value.includes(item.id) ? 'rgba(144,238,144,0.55)':'' }"
              class="select-list-item"
              @click="onSelect(item)"
            >
              <div
                v-if="item"
                class="d-flex"
              >
                <v-img
                  v-if="item.avatar"
                  class="mr-2"
                  style="border-radius: 50%"
                  width="40px"
                  height="40px"
                  :src="item.avatar"
                />
                <div
                  v-else
                  class="mr-2 d-flex justify-center align-center"
                  style="min-width: 40px; height: 40px;border-radius: 50%; background-color: darkgray"
                >
                  <v-icon
                    size="24px"
                    dark
                  >
                    fa-building-o
                  </v-icon>
                </div>
                <div>
                  <div class="">
                    {{ item.name }}
                  </div>
                  <small class="text-muted font-weight-regular">
                    {{ item.builder && item.builder.name }}
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div
            v-else
            class="text-center text-muted font-weight-regular"
          >
            Не найдено
          </div>
        </div>
      </template>
    </APopoverWrapper>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import APopoverWrapper from '@/components/filters/afilter/fields/apopover/APopoverWrapper'
import lodashDebounce from 'lodash/debounce'

export default {
  name: 'BuilderSearchSelect',
  components: { APopoverWrapper },
  props: {
    simple: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Array, Object, Number, String],
      default: null
    },
    defaultFilters: {
      type: Object,
      default: () => {
        return {}
      }
    },
    errorMessages: {
      type: Array,
      default: () => []
    },
    noSelectText: {
      type: String,
      default: 'Не выбрано'
    },
    zIndex: {
      type: [String, Number, null],
      default: 99
    }
  },
  data () {
    return {
      searchStr: '',
      searchProccess: false,
      relativeItems: []
    }
  },
  methods: {
    ...mapActions({
      fetchList: 'tmpCache/realty/fetchBuilderList'
    }),
    onSearch: lodashDebounce(function (event) {
      console.log('onSearch', { event })
      this.searchProccess = true
      const text = (event?.target?.value || this.searchStr)
      this.fetchList(text)
        .then((list) => {
          console.log('onSearch data', list)
          this.relativeItems = list
        })
        .catch((error) => {
          console.error('onSearch error', error)
        }).finally(() => {
          this.searchProccess = false
          this.$refs.popoverWrapper.open = true
        })
    }, 500),
    onSelect (item) {
      let newValue = []
      if (this.simple) {
        newValue = this.value || null
        if (newValue !== item.id) {
          newValue = item.id
          this.$emit('input', newValue)
          this.$emit('inputFullData', this.getById(newValue))
          // Одиночный выбор закрывает поповер
          if (this.$refs.popoverWrapper) {
            this.$refs.popoverWrapper.onClose()
          }
        } else {
          this.removeItem(item)
        }
      } else {
        newValue = [...[], ...(this.value || [])]
        const targetIndex = newValue.findIndex((v) => v === item.id)
        if (targetIndex === -1) {
          newValue.push(item.id)
          this.$emit('input', newValue)
          this.$emit('inputFullData', newValue.map(v => this.getById(v)))
        } else {
          this.removeItem(item)
        }
      }
      this.$nextTick(() => {
        this.searchStr = ''
      })
    },
    removeItem (item) {
      console.warn(this.$options.name, 'removeItem', item)
      const newValue = [...[], ...this.value]
      const targetIndex = newValue.findIndex((v) => v === item.id)
      newValue.splice(targetIndex, 1)
      this.$emit('input', newValue.length ? newValue : null)
      this.$emit('inputFullData', newValue.length ? newValue.map(v => this.getById(v)) : null)
    },
    clear () {
      console.warn(this.$options.name, 'clear')
      this.$emit('input', null)
      this.$emit('inputFullData', null)
    }
  },
  computed: {
    ...mapGetters({
      cachedList: 'tmpCache/realty/cachedBuilderList',
      getById: 'tmpCache/realty/getBuilderById'
    }),
    fullValues () {
      if (!this.simple && Array.isArray(this.value) && this.value.length) {
        return this.value.map(v => this.getById(v))
      }
      if (this.simple && this.value) {
        return this.getById(this.value)
      }
      return null
    },
    isSelected () {
      // Определяю есть ли выбранный элемент
      if (this.fullValues && !this.simple && Array.isArray(this.fullValues) && this.fullValues.length) { // При множественном выборе
        return true
      } else if (this.simple && this.fullValues) { // При еденичном выборе
        return true
      }
      return false
    },
    list () {
      return this.searchStr || (Array.isArray(this.relativeItems) && this.relativeItems.length) ? this.relativeItems : this.cachedList
    }
  }
}
</script>

<style lang="less">
.select-input-block {
  font: 13px "Helvetica Neue",Arial,Helvetica,sans-serif;
  width: 100%;
  border: 1px solid rgba(83, 92, 105, .2);
  border-radius: 2px;
  padding: 0 5px;
  min-height: 38px;
  outline: none;
  &:focus{
    border: 1px solid #bcedfc;
  }
  .select-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 3px;
    height: 100%;
    .select-badge {
      margin: 3px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px 22px 5px 7px;
      background: #bcedfc;
      //margin-left: 5px;
      border-radius: 2px;
      &-text {
        padding: 0;
        max-width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 13px;
      }
    }
  }
}

.select-list-item {
  font: 13px "Helvetica Neue",Arial,Helvetica,sans-serif;
  //width: 48%;
  //display: flex;
  cursor: pointer;
  padding: 7px 10px;
  border-radius: 3px;
  //margin: 5px;
  &:hover {
    background-color: #eaeaea;
  }
}
</style>
