<template>
  <div
    class="tw-flex tw-bg-white tw-pr-4 tw-pl-16 tw-justify-end tw-items-center"
    :class="topUiHeightClass"
  >
    <div class="tw-flex-1 tw-truncate">
      <span
        v-if="headerText"
        class="lg:tw-text-lg xl:tw-text-2xl tw-font-bold"
        v-text="headerText"
      />
    </div>
    <TwAdminSubAuth />
    <div class="tw-flex tw-gap-8 tw-ml-[20px]">
      <div class="tw-flex tw-items-center tw-gap-4">
        <!--        TODO other icons-->
        <!--        <div class="tw-p-2 tw-opacity-50" title="В разработке...">-->
        <!--          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">-->
        <!--            <path class="group-hover:tw-fill-an-flat-red-main tw-fill-an-flat-grey-3"-->
        <!--                  d="M15.1965 13.9035L12.2237 10.9307C13.1246 9.75122 13.6188 8.32061 13.619 6.811C13.619 4.99176 12.9104 3.28129 11.6238 1.99491C10.3375 0.708537 8.62721 0 6.80775 0C4.98852 0 3.27805 0.708537 1.99167 1.99491C-0.66389 4.6507 -0.66389 8.97174 1.99167 11.6271C3.27805 12.9137 4.98852 13.6222 6.80775 13.6222C8.31736 13.622 9.74798 13.1279 10.9274 12.2269L13.9003 15.1997C14.0791 15.3788 14.3139 15.4683 14.5484 15.4683C14.7829 15.4683 15.0177 15.3788 15.1965 15.1997C15.5546 14.8419 15.5546 14.2614 15.1965 13.9035ZM3.28789 10.3309C1.34714 8.3901 1.34736 5.23212 3.28789 3.29114C4.22806 2.3512 5.47818 1.83333 6.80775 1.83333C8.13754 1.83333 9.38744 2.3512 10.3276 3.29114C11.2678 4.2313 11.7856 5.48143 11.7856 6.811C11.7856 8.14079 11.2678 9.39069 10.3276 10.3309C9.38744 11.271 8.13754 11.7889 6.80775 11.7889C5.47818 11.7889 4.22806 11.271 3.28789 10.3309Z" fill="#99A1A7"/>-->
        <!--          </svg>-->
        <!--        </div>-->
        <!--        <div class="tw-p-2 tw-opacity-50" title="В разработке...">-->
        <!--          <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">-->
        <!--            <path class="group-hover:tw-fill-an-flat-red-main tw-fill-an-flat-grey-3"-->
        <!--                  fill-rule="evenodd" clip-rule="evenodd" d="M3.86099 1.61091C4.89244 0.579463 6.29139 0 7.75008 0C9.20877 0 10.6077 0.579462 11.6392 1.61091C12.6706 2.64236 13.2501 4.04131 13.2501 5.5C13.2501 7.97766 13.7796 9.49713 14.253 10.3649C14.491 10.8013 14.7204 11.0832 14.8745 11.2458C14.9518 11.3275 15.0109 11.3798 15.0436 11.4071C15.0575 11.4187 15.0667 11.4258 15.0705 11.4286C15.4261 11.6755 15.583 12.1241 15.4572 12.5398C15.3295 12.9615 14.9407 13.25 14.5001 13.25H1.00008C0.559411 13.25 0.170684 12.9615 0.0429857 12.5398C-0.0828737 12.1241 0.0740739 11.6755 0.429665 11.4286C0.433432 11.4258 0.442612 11.4187 0.456573 11.4071C0.489281 11.3798 0.548368 11.3275 0.625688 11.2458C0.779803 11.0832 1.00914 10.8013 1.24718 10.3649C1.72051 9.49713 2.25008 7.97766 2.25008 5.5C2.25008 4.04131 2.82954 2.64236 3.86099 1.61091ZM0.443848 11.419C0.443933 11.4189 0.444018 11.4189 0.444103 11.4188C0.444099 11.4188 0.444095 11.4188 0.44409 11.4188L0.443848 11.419ZM3.04207 11.25H12.4581C11.8219 10.0532 11.2501 8.2168 11.2501 5.5C11.2501 4.57174 10.8813 3.6815 10.225 3.02513C9.56857 2.36875 8.67834 2 7.75008 2C6.82182 2 5.93158 2.36875 5.2752 3.02513C4.61883 3.6815 4.25008 4.57174 4.25008 5.5C4.25008 8.2168 3.67826 10.0532 3.04207 11.25Z"/>-->
        <!--            <path class="group-hover:tw-fill-an-flat-red-main tw-fill-an-flat-grey-3"-->
        <!--                  fill-rule="evenodd" clip-rule="evenodd" d="M5.95143 14.385C6.42916 14.1079 7.04108 14.2705 7.3182 14.7482C7.36215 14.824 7.42524 14.8869 7.50115 14.9306C7.57705 14.9743 7.66311 14.9973 7.7507 14.9973C7.8383 14.9973 7.92436 14.9743 8.00026 14.9306C8.07616 14.8869 8.13925 14.824 8.1832 14.7482C8.46032 14.2705 9.07225 14.1079 9.54997 14.385C10.0277 14.6621 10.1903 15.274 9.9132 15.7518C9.69344 16.1306 9.37801 16.4451 8.99848 16.6637C8.61896 16.8823 8.18867 16.9973 7.7507 16.9973C7.31273 16.9973 6.88244 16.8823 6.50292 16.6637C6.1234 16.4451 5.80797 16.1306 5.5882 15.7518C5.31108 15.274 5.4737 14.6621 5.95143 14.385Z" fill="#99A1A7"/>-->
        <!--          </svg>-->
        <!--        </div>-->
        <!--        <div class="tw-p-2 tw-opacity-50" title="В разработке...">-->
        <!--          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">-->
        <!--            <path class="group-hover:tw-fill-an-flat-red-main tw-fill-an-flat-grey-3"-->
        <!--                  fill-rule="evenodd" clip-rule="evenodd" d="M9.54167 2C5.37652 2 2 5.37652 2 9.54167C2 13.7068 5.37652 17.0833 9.54167 17.0833C13.7068 17.0833 17.0833 13.7068 17.0833 9.54167C17.0833 5.37652 13.7068 2 9.54167 2ZM0 9.54167C0 4.27195 4.27195 0 9.54167 0C14.8114 0 19.0833 4.27195 19.0833 9.54167C19.0833 14.8114 14.8114 19.0833 9.54167 19.0833C4.27195 19.0833 0 14.8114 0 9.54167Z" fill="#A2ABB5"/>-->
        <!--            <path class="group-hover:tw-fill-an-flat-red-main tw-fill-an-flat-grey-3"-->
        <!--                  fill-rule="evenodd" clip-rule="evenodd" d="M9.68172 6.0225C9.31803 5.96012 8.94399 6.02846 8.62587 6.21542C8.30775 6.40239 8.06606 6.69591 7.94361 7.044C7.76033 7.56499 7.18942 7.83876 6.66843 7.65549C6.14744 7.47222 5.87367 6.9013 6.05694 6.38031C6.33612 5.58667 6.88718 4.91744 7.61251 4.49116C8.33784 4.06487 9.19063 3.90906 10.0198 4.05129C10.849 4.19352 11.6012 4.62463 12.143 5.26825C12.6847 5.91174 12.9812 6.72613 12.9801 7.56724C12.9796 8.95175 11.9544 9.853 11.2535 10.3202C10.8726 10.5742 10.4987 10.7604 10.2241 10.8825C10.0853 10.9441 9.96833 10.991 9.88366 11.0233C9.84124 11.0394 9.80671 11.052 9.78135 11.061L9.75027 11.0719L9.74015 11.0754L9.73649 11.0766L9.73502 11.0771L9.73437 11.0773C9.73407 11.0774 9.73379 11.0775 9.41756 10.1288L9.73379 11.0775C9.20984 11.2522 8.64352 10.969 8.46888 10.445C8.29435 9.92147 8.577 9.35557 9.10024 9.1805C9.10039 9.18045 9.10054 9.1804 9.10069 9.18035L9.10033 9.18047L9.10024 9.1805L9.09956 9.18073L9.09868 9.18103C9.09839 9.18113 9.09869 9.18103 9.09955 9.18073L9.11133 9.1766C9.1235 9.17227 9.14402 9.16483 9.17167 9.15429C9.22714 9.13316 9.31032 9.09996 9.41181 9.05485C9.61763 8.96338 9.88443 8.82927 10.1441 8.65615C10.7243 8.26933 10.9801 7.88962 10.9801 7.56632L10.9801 7.56483C10.9806 7.19583 10.8505 6.83855 10.6129 6.55626C10.3753 6.27396 10.0454 6.08488 9.68172 6.0225ZM12.9801 7.56724C12.9801 7.56743 12.9801 7.56762 12.9801 7.56781L11.9801 7.56632H12.9801C12.9801 7.56663 12.9801 7.56694 12.9801 7.56724ZM8.48589 13.5455C8.48589 12.9932 8.93361 12.5455 9.48589 12.5455H9.49444C10.0467 12.5455 10.4944 12.9932 10.4944 13.5455C10.4944 14.0978 10.0467 14.5455 9.49444 14.5455H9.48589C8.93361 14.5455 8.48589 14.0978 8.48589 13.5455Z" fill="#A2ABB5"/>-->
        <!--          </svg>-->
        <!--        </div>-->
        <!--  Иконка Емаил   -->
        <EmailIcon @click="showMyEmailList" />
        <!--  Быстрое создание   -->
        <div>
          <TwPopoverShort :open-by-hover="false">
            <template #trigger>
              <button
                :disabled="false"
                title="Интерфейсы быстрого создания"
                class="tw-px-[10px] tw-py-[5px] tw-text-an-flat-grey-3 tw-flex tw-gap-[6px] tw-items-center tw-font-semibold tw-text-main-xs tw-border tw-border-solid tw-rounded-sm
                          enabled:hover:tw-text-an-flat-green enabled:hover:tw-border-an-flat-green enabled:hover:tw-bg-an-flat-green/10"
              >
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11 4.99998H7.00002V1.00002C7.00002 0.448015 6.55301 0 6 0C5.44699 0 4.99998 0.448015 4.99998 1.00002V4.99998H1.00002C0.447007 4.99998 0 5.448 0 6C0 6.552 0.447007 7.00002 1.00002 7.00002H4.99998V11C4.99998 11.552 5.44699 12 6 12C6.55301 12 7.00002 11.552 7.00002 11V7.00002H11C11.553 7.00002 12 6.552 12 6C12 5.448 11.553 4.99998 11 4.99998Z"
                    fill="currentColor"
                  />
                </svg>
                Создать
              </button>
            </template>
            <template #content>
              <div class="tw-max-w-[200px]">
                <ul>
                  <li
                    class="tw-block tw-px-[10px] tw-py-[6px] tw-cursor-pointer hover:tw-underline"
                    @click.prevent="showDealCard({ id: 0 })"
                  >
                    Создать заявку
                  </li>
                </ul>
              </div>
            </template>
          </TwPopoverShort>
        </div>
        <div
          class="tw-p-2 tw-cursor-pointer tw-flex tw-gap-[4px] tw-items-center"
          @click.prevent="showUpdatesUI"
        >
          <TwPopoverShort>
            <template #trigger>
              <div class="tw-text-main-xs tw-flex tw-gap-[4px] tw-items-center">
                <svg
                  v-if="updatesInfo.exist"
                  class="tw-animate-pulse"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    class="tw-fill-an-flat-red-main"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10 2.09607C5.63478 2.09607 2.09607 5.63478 2.09607 10C2.09607 14.3652 5.63478 17.9039 10 17.9039C14.3652 17.9039 17.9039 14.3652 17.9039 10C17.9039 5.63478 14.3652 2.09607 10 2.09607ZM0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10ZM10.0506 11.9229C9.47907 11.9229 9 12.3964 9 12.9613C9 13.5263 9.47907 13.9998 10.0506 13.9998C10.6011 13.9998 11.1012 13.5263 11.076 12.9863C11.1012 12.3923 10.6263 11.9229 10.0506 11.9229ZM9 6.09675C9 5.58374 9.30435 5.16079 9.79376 5.02764C10.2832 4.91799 10.7726 5.14121 10.9823 5.57982C11.0523 5.73646 11.0769 5.89311 11.0769 6.06934C11.0646 6.31409 11.0471 6.55884 11.0296 6.8036C11.0122 7.04836 10.9947 7.29313 10.9823 7.5379C10.9597 7.91577 10.9361 8.29365 10.9124 8.67152C10.8888 9.04946 10.8651 9.4274 10.8425 9.80534C10.8178 10.0521 10.8178 10.2753 10.8178 10.5181C10.7973 10.9214 10.4683 11.2308 10.0488 11.2308C9.62925 11.2308 9.30435 10.941 9.27967 10.5416C9.24486 9.95474 9.21006 9.37373 9.17525 8.79274C9.14014 8.20672 9.10503 7.62072 9.06992 7.02879C9.05759 6.8741 9.04627 6.71844 9.03496 6.56277C9.02365 6.4071 9.01234 6.25143 9 6.09675Z"
                    fill="#A2ABB5"
                  />
                </svg>
                <div
                  class="tw-flex tw-flex-row tw-items-center tw-gap-[6px] tw-px-[10px] tw-py-[6px] tw-rounded-full"
                  :class="updatesInfo.exist ? 'tw-text-an-flat-red-main tw-bg-an-flat-red-lighten4':'tw-text-an-flat-green-btn-bg tw-bg-an-flat-green-bg-block2'"
                >
                  <div v-if="updatesInfo.exist">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M10.8334 13.3333C10.8334 13.7936 10.4603 14.1667 10.0001 14.1667C9.53983 14.1667 9.16675 13.7936 9.16675 13.3333C9.16675 12.8731 9.53983 12.5 10.0001 12.5C10.4603 12.5 10.8334 12.8731 10.8334 13.3333Z"
                        fill="#E63114"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M10.0001 5.83334C10.4603 5.83334 10.8334 6.20644 10.8334 6.66667V10.8333C10.8334 11.2936 10.4603 11.6667 10.0001 11.6667C9.53983 11.6667 9.16675 11.2936 9.16675 10.8333V6.66667C9.16675 6.20644 9.53983 5.83334 10.0001 5.83334Z"
                        fill="#E63114"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M10.0001 3.33333C6.31818 3.33333 3.33342 6.3181 3.33342 10C3.33342 13.6819 6.31818 16.6667 10.0001 16.6667C13.682 16.6667 16.6668 13.6819 16.6668 10C16.6668 6.3181 13.682 3.33333 10.0001 3.33333ZM1.66675 10C1.66675 5.39763 5.39771 1.66667 10.0001 1.66667C14.6024 1.66667 18.3334 5.39763 18.3334 10C18.3334 14.6023 14.6024 18.3333 10.0001 18.3333C5.39771 18.3333 1.66675 14.6023 1.66675 10Z"
                        fill="#E63114"
                      />
                    </svg>
                  </div>
                  <div v-else>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M10.0001 3.33333C6.31818 3.33333 3.33342 6.3181 3.33342 10C3.33342 13.6819 6.31818 16.6667 10.0001 16.6667C13.682 16.6667 16.6668 13.6819 16.6668 10C16.6668 6.3181 13.682 3.33333 10.0001 3.33333ZM1.66675 10C1.66675 5.39762 5.39771 1.66666 10.0001 1.66666C14.6024 1.66666 18.3334 5.39762 18.3334 10C18.3334 14.6023 14.6024 18.3333 10.0001 18.3333C5.39771 18.3333 1.66675 14.6023 1.66675 10Z"
                        fill="#22C17B"
                      />
                      <path
                        d="M7 10L9 12L13 8"
                        stroke="#22C17B"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                    </svg>
                  </div>
                  v.{{ currentVersion }}
                </div>
              </div>
            </template>
            <template #content>
              <div v-if="updatesInfo.exist">
                Устаревшая версия CRM, требуется обновление
              </div>
              <div v-else>
                У вас актуальная версия CRM
              </div>
            </template>
          </TwPopoverShort>
        </div>
      </div>
      <div
        v-if="user"
        class="tw-relative tw-flex tw-items-center tw-gap-x-4 tw-text-[14px]"
      >
        <div class="tw-relative">
          <TwSocketsConnectionIndicator />
          <div
            v-if="isViewWithClient"
            title="Активирован просмотр с клиентом. Часть полей скрыта!"
            aria-hidden="true"
            class="tw-absolute tw-top-[19px] -tw-left-[13px]"
          >
            <svg
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g filter="url(#filter0_d_5_931)">
                <path
                  d="M28.5 16C28.5 21.799 23.799 26.5 18 26.5C12.201 26.5 7.5 21.799 7.5 16C7.5 10.201 12.201 5.5 18 5.5C23.799 5.5 28.5 10.201 28.5 16Z"
                  fill="#E63114"
                  stroke="white"
                />
              </g>
              <g clip-path="url(#clip0_5_931)">
                <path
                  d="M18 12.4234C15.7073 12.4234 13.6281 13.6778 12.0939 15.7153C11.9687 15.8822 11.9687 16.1154 12.0939 16.2823C13.6281 18.3222 15.7073 19.5766 18 19.5766C20.2927 19.5766 22.3719 18.3222 23.9061 16.2847C24.0313 16.1178 24.0313 15.8846 23.9061 15.7177C22.3719 13.6778 20.2927 12.4234 18 12.4234ZM18.1645 18.5186C16.6425 18.6143 15.3857 17.3599 15.4814 15.8355C15.56 14.5787 16.5787 13.56 17.8355 13.4814C19.3575 13.3857 20.6143 14.6401 20.5186 16.1645C20.4376 17.4188 19.4188 18.4376 18.1645 18.5186ZM18.0884 17.355C17.2685 17.4066 16.591 16.7315 16.645 15.9116C16.6867 15.2341 17.2366 14.6867 17.9141 14.6425C18.734 14.591 19.4115 15.266 19.3575 16.0859C19.3133 16.7659 18.7634 17.3133 18.0884 17.355Z"
                  fill="white"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d_5_931"
                  x="0.4"
                  y="0.4"
                  width="35.2"
                  height="35.2"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood
                    flood-opacity="0"
                    result="BackgroundImageFix"
                  />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="2" />
                  <feGaussianBlur stdDeviation="3.3" />
                  <feComposite
                    in2="hardAlpha"
                    operator="out"
                  />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.901961 0 0 0 0 0.192157 0 0 0 0 0.0784314 0 0 0 0.76 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_5_931"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_5_931"
                    result="shape"
                  />
                </filter>
                <clipPath id="clip0_5_931">
                  <rect
                    width="12"
                    height="12"
                    fill="white"
                    transform="translate(12 10)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
          <img
            v-if="userAvatar"
            :src="userAvatar"
            alt="Авартар пользователя"
            class="tw-h-10 tw-w-10 tw-rounded-full tw-bg-gray-50 tw-border-solid tw-border tw-border-an-flat-grey-2"
          >
          <div
            v-else
            class="tw-h-10 tw-w-10 tw-rounded-full tw-flex tw-items-center tw-justify-center tw-bg-gray-50"
          >
            <svg
              width="38"
              height="37"
              viewBox="0 0 38 37"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 37C29.2173 37 37.5 28.7173 37.5 18.5C37.5 8.28273 29.2173 0 19 0C8.78273 0 0.5 8.28273 0.5 18.5C0.5 28.7173 8.78273 37 19 37Z"
                fill="#F5F7F9"
              />
              <path
                d="M16.3576 19.8218L13.2703 20.9244C12.7443 21.1122 12.3933 21.6104 12.3933 22.1688V24.1985C12.3933 24.5311 12.4164 24.8834 12.6556 25.1146C13.2645 25.7027 15.0164 26.4289 19.0005 26.4289C22.9845 26.4289 24.7364 25.7027 25.3453 25.1146C25.5845 24.8834 25.6076 24.5311 25.6076 24.1985V22.1688C25.6076 21.6104 25.2566 21.1122 24.7306 20.9244L21.6433 19.8218M19.0005 18.5003C17.4754 18.5003 16.1739 17.3977 15.9231 15.8934L15.6879 14.4821C15.3467 12.4352 16.9253 10.5718 19.0005 10.5718C21.0756 10.5718 22.6542 12.4352 22.313 14.4821L22.0778 15.8934C21.8271 17.3977 20.5255 18.5003 19.0005 18.5003Z"
                stroke="#99A1A7"
                stroke-width="1.5"
                stroke-linecap="round"
              />
            </svg>
          </div>
        </div>
        <div class="tw-text-sm tw-leading-5">
          <div class="tw-font-manrope tw-text-an-flat-grey-31 tw-font-medium">
            {{ $Global.peopleName(user, 'if') }}
          </div>
          <div class="tw-font-manrope tw-text-an-flat-grey-5 tw-font-semibold">
            {{ user.email }}
          </div>
        </div>
        <div
          :class="isShowUserMenu ? 'tw-rotate-180' : ''"
          class="tw-py-2 tw-px-3 tw-cursor-pointer tw-group"
          @click.prevent="isShowUserMenu = !isShowUserMenu"
        >
          <svg
            width="8"
            height="5"
            viewBox="0 0 8 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              class="group-hover:tw-fill-an-flat-red-main tw-fill-an-flat-grey-3"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.231777 0.359814C0.585341 -0.064463 1.21591 -0.121787 1.64018 0.231777L4 2.19829L6.35981 0.231777C6.78409 -0.121787 7.41466 -0.064463 7.76822 0.359814C8.12178 0.784091 8.06446 1.41466 7.64018 1.76822L4 4.80171L0.359814 1.76822C-0.064463 1.41466 -0.121787 0.784091 0.231777 0.359814Z"
              fill="#343942"
            />
          </svg>
        </div>
        <!-- User dropdown menu -->
        <transition
          enter-active-class="tw-transition tw-ease-out tw-duration-100"
          enter-from-class="tw-transform tw-opacity-0 tw-scale-95"
          enter-to-class="tw-transform tw-opacity-100 tw-scale-100"
          leave-active-class="tw-transition tw-ease-in tw-duration-75"
          leave-from-class="tw-transform tw-opacity-100 tw-scale-100"
          leave-to-class="tw-transform tw-opacity-0 tw-scale-95"
        >
          <div
            v-if="isShowUserMenu"
            v-click-outside="onClickOutside"
            class="tw-overflow-auto tw-select-none tw-absolute tw-font-[14px] tw-right-0 tw-top-0 tw-z-10 tw-mt-12 tw-w-[274px] tw-origin-top-right tw-rounded-[20px] tw-bg-white tw-pt-[15px] tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="user-menu-button"
            tabindex="-1"
          >
            <router-link
              role="menuitem"
              tag="span"
              tabindex="-1"
              class="tw-text-gray-500 hover:tw-text-an-flat-red-main tw-block tw-px-[22px] tw-py-2 tw-cursor-pointer"
              to="/profile"
            >
              Профиль пользователя
            </router-link>
            <span
              role="menuitem"
              tabindex="-1"
              class="tw-text-gray-500 hover:tw-text-an-flat-red-main tw-block tw-px-[22px] tw-pt-2 tw-pb-4 tw-cursor-pointer"
              @click.stop="resetAuth"
            >
              Выход из CRM
            </span>
            <div
              class="tw-px-[22px] tw-py-[15px] tw-rounded-[12px] tw-font-medium"
              :class="isViewWithClient ? 'tw-bg-an-flat-red-main tw-bg-opacity-15' : 'tw-bg-an-flat-grey-11'"
            >
              <div title="Скрыть контент который нежелательно показывать клиентам(Проценты, комиссии и тд.)">
                <div class="tw-mb-[10px]">
                  Отображение для клиента <span :class="isViewWithClient ? 'tw-text-an-flat-red-main' : 'tw-text-an-flat-grey-4'">{{ isViewWithClient ? 'вкл.' : 'выкл.' }}</span>
                </div>
                <div
                  title="В разработке..."
                  class="tw-flex tw-items-center tw-justify-between"
                >
                  <TwInputSwitch v-model="isViewWithClient" />
                  <svg
                    v-if="isViewWithClient"
                    width="46"
                    height="46"
                    viewBox="0 0 46 46"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g filter="url(#filter0_d_61_9115)">
                      <path
                        d="M38.5 21C38.5 29.5604 31.5604 36.5 23 36.5C14.4396 36.5 7.5 29.5604 7.5 21C7.5 12.4396 14.4396 5.5 23 5.5C31.5604 5.5 38.5 12.4396 38.5 21Z"
                        fill="#E63114"
                        stroke="white"
                      />
                    </g>
                    <g clip-path="url(#clip0_61_9115)">
                      <path
                        d="M23 15.7979C19.6651 15.7979 16.6409 17.6224 14.4093 20.5859C14.2272 20.8287 14.2272 21.1679 14.4093 21.4107C16.6409 24.3778 19.6651 26.2024 23 26.2024C26.3349 26.2024 29.3591 24.3778 31.5907 21.4143C31.7728 21.1715 31.7728 20.8323 31.5907 20.5895C29.3591 17.6224 26.3349 15.7979 23 15.7979ZM23.2392 24.6635C21.0255 24.8027 19.1974 22.9782 19.3366 20.7609C19.4509 18.9328 20.9326 17.451 22.7608 17.3368C24.9745 17.1975 26.8026 19.022 26.6633 21.2393C26.5455 23.0639 25.0637 24.5457 23.2392 24.6635ZM23.1285 22.9711C21.936 23.046 20.9505 22.0641 21.029 20.8716C21.0897 19.8861 21.8895 19.0899 22.875 19.0256C24.0676 18.9506 25.053 19.9325 24.9745 21.1251C24.9102 22.1141 24.1104 22.9104 23.1285 22.9711Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <filter
                        id="filter0_d_61_9115"
                        x="0.4"
                        y="0.4"
                        width="45.2"
                        height="45.2"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                      >
                        <feFlood
                          flood-opacity="0"
                          result="BackgroundImageFix"
                        />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset dy="2" />
                        <feGaussianBlur stdDeviation="3.3" />
                        <feComposite
                          in2="hardAlpha"
                          operator="out"
                        />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0.901961 0 0 0 0 0.192157 0 0 0 0 0.0784314 0 0 0 0.76 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow_61_9115"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_dropShadow_61_9115"
                          result="shape"
                        />
                      </filter>
                      <clipPath id="clip0_61_9115">
                        <rect
                          width="17.4545"
                          height="17.4545"
                          fill="white"
                          transform="translate(14.2727 12.2729)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  <svg
                    v-else
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="15.998"
                      cy="15.998"
                      r="15.998"
                      fill="white"
                    />
                    <path
                      d="M22.215 22.3924L8.78398 9.60134L8.78111 9.59821C8.7084 9.52953 8.62219 9.47515 8.52741 9.43819C8.43263 9.40124 8.33114 9.38242 8.22872 9.38282C8.12631 9.38322 8.02498 9.40282 7.93052 9.44052C7.83606 9.47821 7.75032 9.53326 7.6782 9.60251C7.60608 9.67176 7.54899 9.75386 7.51018 9.84413C7.47138 9.9344 7.45162 10.0311 7.45204 10.1286C7.45288 10.3256 7.53586 10.5142 7.68271 10.6529L8.85863 11.7728C7.43256 12.703 6.20507 13.8828 5.24146 15.2494C5.08411 15.4706 5 15.7318 5 15.999C5 16.2662 5.08411 16.5273 5.24146 16.7486C7.70117 20.2158 11.6005 22.247 15.4999 22.2217C16.7629 22.226 18.0176 22.0258 19.2102 21.6295L21.1137 23.4424C21.2604 23.5775 21.4568 23.6525 21.6609 23.6512C21.865 23.6499 22.0604 23.5723 22.2051 23.4353C22.3498 23.2982 22.4323 23.1126 22.4349 22.9182C22.4374 22.7238 22.3598 22.5363 22.2187 22.3959L22.215 22.3924ZM15.4999 20.2056C14.7611 20.2054 14.0342 20.0288 13.3855 19.692C12.7369 19.3552 12.1872 18.869 11.7867 18.2777C11.3862 17.6865 11.1477 17.0091 11.093 16.3074C11.0383 15.6058 11.169 14.9022 11.4734 14.2611L12.7018 15.431C12.6028 15.8751 12.622 16.3357 12.7574 16.7709C12.8929 17.2061 13.1404 17.6022 13.4774 17.9232C13.8144 18.2442 14.2303 18.4799 14.6873 18.6089C15.1443 18.7379 15.6279 18.7561 16.0942 18.6619L17.3226 19.8318C16.7501 20.079 16.1285 20.2065 15.4999 20.2056Z"
                      fill="#373E4A"
                    />
                    <path
                      d="M25.7584 15.2487C23.2987 11.7795 19.3993 9.74828 15.4999 9.77367C14.2369 9.76932 12.9822 9.96957 11.7897 10.3659L13.6764 12.1627C14.4951 11.8099 15.4073 11.703 16.2914 11.8564C17.1754 12.0098 17.9894 12.4162 18.6249 13.0214C19.2603 13.6265 19.687 14.4018 19.8481 15.2438C20.0091 16.0858 19.897 16.9545 19.5265 17.7342L22.1408 20.2241C23.5671 19.294 24.7948 18.1142 25.7584 16.7475C25.9156 16.5263 25.9997 16.2652 25.9997 15.9981C25.9997 15.7309 25.9156 15.4699 25.7584 15.2487Z"
                      fill="#373E4A"
                    />
                    <path
                      d="M15.4999 13.2734C15.3001 13.2735 15.1009 13.2934 14.9055 13.3328L18.2983 16.5637C18.3868 16.1667 18.381 15.756 18.2815 15.3614C18.1819 14.9668 17.9911 14.5984 17.7229 14.2831C17.4547 13.9677 17.1159 13.7134 16.7313 13.5388C16.3467 13.3641 15.926 13.2734 15.4999 13.2734Z"
                      fill="#373E4A"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { employeeAvatarListGetLink } from '@/views/Company/Employee/employeeHelpers'
import TwSocketsConnectionIndicator from '@/components/sockets/TwSocketsConnectionIndicator.vue'
import TwInputSwitch from '@/components/tw-ui/ui-kit/input/TwInputSwitch.vue'
import TwAdminSubAuth from '@/components/tw-ui/toolbar/TwAdminSubAuth.vue'
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import TwPopoverShort from '@/components/tw-ui/popover/TwPopoverShort.vue'
import EmailIcon from '@/components/icon/Email/EmailIcon.vue'

export default {
  name: 'TwTopToolbar',
  components: { EmailIcon, TwPopoverShort, TwAdminSubAuth, TwInputSwitch, TwSocketsConnectionIndicator },
  mixins: [ViewPanelShowMixin],
  props: {
    topUiHeightClass: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      isShowUserMenu: false
    }
  },
  methods: {
    ...mapMutations({
      setUpdates: 'setUpdates'
    }),
    ...mapActions({
      resetAuth: 'profile/resetAuth'
    }),
    onClickOutside () {
      console.warn('onClickOutside')
      this.isShowUserMenu = false
    },
    showUpdatesUI () {
      if (this.updatesInfo.exist) {
        this.setUpdates({
          exist: true,
          uiVisible: true
        })
      }
    }
  },
  computed: {
    ...mapState({
      currentVersion: 'version',
      updatesInfo: 'updates'
    }),
    ...mapGetters({
      isAdmin: 'profile/isAdmin',
      deploymentType: 'getDeploymentType',
      user: 'profile/getUser'
    }),
    userAvatar () {
      return this.user?.file ? employeeAvatarListGetLink(this.user.file) : null
    },
    headerText () {
      return this.$route?.meta?.hText || this.$store.getters.getPageTitle || this.$route?.meta?.name || ''
    },
    isViewWithClient: {
      get () {
        return this.$store.state.isViewWithClient
      },
      set (val) {
        this.$store.commit('SET_VIEW_WITH_CLIENT', val)
      }
    }
  },
  watch: {
    '$route.path' () {
      this.isShowUserMenu = false
    }
  }
}
</script>

<style scoped>

</style>
