<template>
  <div class="body-2">
    <v-form ref="form">
      <v-card
        v-if="defaultEmail"
        tile
        outlined
      >
        <v-card-title>
          Быстрый ответ
        </v-card-title>
        <v-card-text>
          <v-row align="center">
            <v-col>
              Получатели:
              <i
                v-for="(contact, c_index) of replyFrom.contacts"
                :key="contact.id"
              >
                <span v-text="`${contact.name} <${contact.email}>`" />
                <span v-if="c_index < replyFrom.contacts.length - 1">, </span>
              </i>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text class="pt-0">
          <QuillEmailEditor v-model="form.body" />
        </v-card-text>
        <v-card-text class="pt-0">
          <div class="mb-1">
            Прикреплённые файлы:
          </div>
          <UploadEmailFileList
            v-model="form.filesId"
            :no-data-text="false"
            height="100px"
          />
        </v-card-text>
        <div style="position: sticky;bottom: 0;">
          <v-divider />
          <v-card-actions class="white">
            <v-spacer />
            <v-btn
              color="primary"
              :disabled="!form.body"
              @click="handleSubmit"
            >
              Отправить ответ
            </v-btn>
            <v-btn
              outlined
              @click="handleCancel"
            >
              Отменить
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import emailAPI from '@/Api/Email/Mail'
import QuillEmailEditor from '@/components/text-editor/QuillEmailEditor'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import UploadEmailFileList from '@/views/Email/file/UploadEmailFileList.vue'

export default {
  name: 'EmailMainReply',
  components: { UploadEmailFileList, QuillEmailEditor },
  mixins: [FormCreateEditMixin],
  props: {
    replyId: {
      type: String,
      required: true
    },
    replyFrom: {
      type: Object
    }
  },
  data () {
    return {
      API: emailAPI,
      form: {
        folderId: '',
        theme: '',
        body: '',
        contacts: [],
        filesId: []
      }
    }
  },
  methods: {
    handleCancel () {
      this.$emit('hide')
    },
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          const adaperMap = {
            // from(show): to(send)
            files: 'filesId',
            contacts: 'contacts',
            body: 'body',
            theme: 'theme'
          }
          for (const key in this.preset) {
            if (adaperMap[key] !== undefined) {
              if (key === 'files') {
                this.$set(this.form, adaperMap[key], this.preset[key] || [])
              } else if (key === 'contacts') {
                this.$set(this.form,
                  adaperMap[key],
                  Array.isArray(this.preset[key]) && this.preset[key].length ? this.preset[key].map(c => {
                    return {
                      id: c.id,
                      name: c.name,
                      email: c.email
                    }
                  }) : [])
              } else {
                this.$set(this.form, adaperMap[key], this.preset[key])
              }
            }
          }
        }
        resolve()
      })
    },
    handleSubmit () {
      if (this.form.body.trim()) {
        if (this.formProcessing === true) {
          return
        }

        this.formProcessing = true

        this.API.reply(this.replyId)
          .then((data) => {
            console.warn(this.$options.name, data)
            this.handleCancel()
          })
          .catch((error) => {
            console.error(this.$options.name, error)
          })
          .finally(() => {
            this.formProcessing = false
          })
      }
    },
    initProps () {
      if (Array.isArray(this.toPerson?.additionalContacts)) {
        const emails = this.toPerson.additionalContacts.filter(contact => contact.type === 'email')
        if (emails.length) {
          this.form.to.push(emails[0].id)
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      currentUserName: 'profile/fullName',
      emails: 'profile/emails'
    }),
    defaultEmail () {
      if (this.emails && this.emails.length) {
        return this.emails[0]
      }
      return null
    }
  },
  mounted () {
    this.initProps()
  }
}
</script>
