<template>
  <div class="tw-flex tw-w-full tw-flex-col tw-relative">
    <span
      v-show="errorValue && errorVisible || errorVisible && errorText"
      class="tw-absolute tw-top-0 tw-right-0 tw-text-an-flat-red-main tw-text-main-xs"
    >{{ errorText }}</span>
    <span
      v-show="(required === true && result === null || required === true && result === '') && !disabled && !errorValue"
      class="tw-absolute tw-top-0 tw-right-0 tw-text-an-flat-red-main tw-text-main-xs"
    >
      Заполните поле
    </span>
    <div
      :class="[sizeClasses, errorValue && errorVisible || errorVisible && errorText ? inputClassError : '']"
      class="tw-group tw-flex tw-relative tw-mt-[21px] tw-flex-row-reverse tw-font-an-flat-manrope tw-overflow-visible tw-font-medium tw-border-[1px] tw-border-an-flat-gray-0
        tw-rounded-sm"
    >
      <div
        v-if="stringInputValue && result && !disabled"
        class="tw-absolute tw-top-1/2 tw-right-[25px] tw-transform -tw-translate-x-0 -tw-translate-y-1/2 tw-hidden group-hover:tw-block"
      >
        <button
          title="Сброс"
          class="tw-p-[5px]"
          @click.stop="clearInput"
        >
          <svg
            width="15"
            height="15"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10 5C10 7.76142 7.76143 10 5 10C2.23857 10 0 7.76142 0 5C0 2.23858 2.23857 0 5 0C7.76143 0 10 2.23858 10 5ZM3.71445 3.71445C3.89197 3.53694 4.17976 3.53694 4.35727 3.71445L5 4.35719L5.64273 3.71445C5.82024 3.53694 6.10805 3.53694 6.28555 3.71445C6.46307 3.89196 6.46307 4.17976 6.28555 4.35728L5.64282 5.00001L6.28575 5.64293C6.46326 5.82044 6.46326 6.10824 6.28575 6.28575C6.10823 6.46326 5.82043 6.46326 5.64293 6.28575L5 5.64283L4.35709 6.28574C4.17957 6.46326 3.89177 6.46326 3.71426 6.28574C3.53674 6.10824 3.53674 5.82043 3.71426 5.64292L4.35718 5.00001L3.71445 4.35728C3.53694 4.17977 3.53694 3.89197 3.71445 3.71445Z"
              fill="#E63114"
            />
          </svg>
        </button>
      </div>
      <label
        v-if="!errorValue && !((required === true && result === null || required === true && result === '') && !disabled && !errorValue) && label"
        :for="id"
        class="tw-block tw-absolute tw-top-[-24px] tw-left-[4px] tw-w-[100%] tw-text-main-sm tw-text-an-flat-dark-blue-link"
      >{{ label }}</label>
      <input
        :id="id"
        :value="stringInputValue"
        :min="min"
        :max="max"
        :disabled="disabled"
        :required="required"
        :type="type"
        class="tw-block tw-w-full tw-bg-white tw-min-h-[52px] tw-pr-[12px] tw-pl-[8px] tw-text-an-flat-header focus:tw-outline-none tw-rounded-r-[4px]
            tw-peer disabled:tw-bg-an-flat-bg-main disabled:tw-text-an-flat-gray-1"
        @input="takeValue($event)"
      >
      <label
        :for="id"
        class="tw-flex tw-items-center tw-min-w-[38px] tw-px-[12px] tw-bg-an-flat-gray-17 tw-text-an-flat-active-input tw-text-center tw-rounded-l-[4px]
            group-hover/hover:tw-bg-an-flat-red-lighten group-hover/hover:tw-text-an-flat-red-main
            peer-focus:tw-text-an-flat-red-main
            peer-disabled:tw-text-an-flat-gray-1 peer-disabled:tw-bg-an-flat-disabled-input-bg"
      >
        {{ suffix }}
      </label>
    </div>
  </div>
</template>

<script>
import lodashDebounce from 'lodash/debounce'

export default {
  name: 'TwInputDate',
  props: {
    value: {
      default: null
    },
    type: {
      type: String,
      default: 'date'
    },
    min: {
      type: String,
      default: null
    },
    max: {
      type: String,
      default: null
    },
    errorText: {
      type: String,
      default: 'Введите корректную дату'
    },
    errorVisible: {
      type: Boolean,
      default: false
    },
    suffix: {
      type: String,
      default: 'Дата'
    },
    dense: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      stringInputValue: '',
      result: '',
      errorValue: false
    }
  },
  methods: {
    takeValue (event) {
      this.onChange(event.target.value)
    },
    onChange: lodashDebounce(function (event) {
      const parseEvent = Date.parse(event)
      this.result = event

      if (parseEvent < Date.parse(this.min) || parseEvent > Date.parse(this.max)) {
        this.errorValue = true
        this.result = null
      } else {
        this.errorValue = false
      }

      this.result ? this.$emit('input', this.result) : this.$emit('input', null)
      this.result ? this.$emit('event-input', this.result) : this.$emit('event-input', null)
      this.stringInputValue = event
    }, 500),
    clearInput () {
      if (this.result && this.stringInputValue) {
        this.onChange('')
      } else {
        console.error('Error Date clearInput(result, stringInputValue) ', this.result, this.stringInputValue)
      }
    }
  },
  computed: {
    inputClassError () {
      if (this.disabled) {
        return ''
      }
      return 'tw-border-an-flat-red-main'
    },
    sizeClasses () {
      if (this.dense) {
        return 'tw-min-h-[40px] tw-text-main-xs'
      } else {
        return 'tw-min-h-[52px] tw-text-main-sm2'
      }
    }
  },
  watch: {
    value: function (val, oldVal) {
      if (val !== oldVal) {
        this.result = val
        this.stringInputValue = val
      }
    }
  },
  created () {
    if (this.value !== null) {
      this.result = this.value
      this.stringInputValue = this.value
    }
  }
}
</script>
