import { AxiosResponse } from 'axios'
import HTTP from '@/Api/http'

const dealPath = '/deal/interface'
const leadPath = '/lead/interface'

export default {
  dealInterfaceStatus (param?: object): Promise<AxiosResponse> {
    const $config = {
      params: param
    }
    return HTTP.get(`${dealPath}/status`, $config)
  },
  dealInterfaceStatusByType (param?: { dealType: null }): Promise<AxiosResponse> {
    const $config = {
      params: param
    }
    return HTTP.get(`${dealPath}/status-by-type`, $config)
  },
  leadInterfaceStatus (param?: object): Promise<AxiosResponse> {
    const $config = {
      params: param
    }
    return HTTP.get(`${leadPath}/status`, $config)
  },
  leadInterfaceSource (param?: object): Promise<AxiosResponse> {
    const $config = {
      params: param
    }
    return HTTP.get(`${leadPath}/source`, $config)
  }
}
