<template>
  <v-form ref="form">
    <div style="display: none">
      {{ isEdited }}
    </div>
    <v-row
      dense
      justify="space-between"
    >
      <v-col cols="12">
        <CompanyAsyncSelect
          v-model="form.companyId"
          chips
          clearable
          :rules="requireRules"
          placeholder="Выбрать Банк"
          :filters="{ 'Company.type': 'bank' }"
        />
      </v-col>
    </v-row>
    <v-row
      dense
      justify="space-between"
    >
      <v-col
        cols="12"
        md="6"
      >
        <v-text-field
          v-model.number="form.experience"
          class="mt-0 pt-0"
          type="number"
          label="Общий трудовой стаж не менее, мес."
          :rules="requireRules"
          required
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-text-field
          v-model.number="form.experienceLast"
          class="mt-0 pt-0"
          type="number"
          label="Трудовой стаж на последнем месте работы, мес."
          :rules="requireRules"
          required
        />
      </v-col>
    </v-row>
    <v-row
      dense
      justify="space-between"
    >
      <v-col
        cols="12"
        md="6"
      >
        <v-text-field
          v-model.number="form.ageMin"
          class="mt-0 pt-0"
          type="number"
          label="Минимальный возраст заемщика, лет"
          :rules="requireRules"
          required
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-text-field
          v-model.number="form.ageMax"
          class="mt-0 pt-0"
          type="number"
          label="Максимальный возраст заемщика (вкл) на момент погашения кредита, лет"
          :rules="requireRules"
          required
        />
      </v-col>
    </v-row>
    <v-row
      dense
      justify="space-between"
    >
      <v-col
        cols="12"
        md="6"
      >
        <InputRadioGroup
          v-model="form.goodHistory"
          fixed-color="primary"
          label="Хорошая кредитная история:"
          :options="goodHistoryOptions"
          :classes="['mt-0']"
          :rules="requireBooleanRules"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <InputRadioGroup
          v-model="form.militaryId"
          fixed-color="primary"
          label="Военный билет для мужчин до 27лет:"
          :classes="['mt-0']"
          :options="militaryIdOptions"
          :rules="requireBooleanRules"
        />
      </v-col>
    </v-row>
    <v-row
      dense
      justify="space-between"
    >
      <v-col
        cols="12"
        md="6"
      >
        <InputRadioGroup
          v-model="form.nationally"
          fixed-color="primary"
          label="Гражданство:"
          :options="nationallyOptions"
          :classes="['mt-0']"
          :rules="requireBooleanRules"
        />
      </v-col>

      <v-col
        cols="12"
        md="6"
      >
        <InputRadioGroup
          v-model="form.rono"
          fixed-color="primary"
          label="РОНО:"
          :classes="['mt-0']"
          :options="ronoOptions"
          :rules="requireBooleanRules"
        />
      </v-col>
    </v-row>
    <v-row
      dense
      justify="space-between"
    >
      <v-col
        cols="12"
        md="6"
      >
        <InputRadioGroup
          v-model="form.paymentType"
          fixed-color="primary"
          label="Платежи:"
          :classes="['mt-0']"
          :options="dictionary.paymentType"
          :rules="requireRules"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <!---->
      </v-col>
    </v-row>
    <v-row
      dense
      justify="space-between"
    >
      <v-col
        cols="12"
        md="6"
      >
        <InputRadioGroup
          v-model="form.decreaseToCreditSum"
          fixed-color="primary"
          :options="decreaseToCreditSumOptions"
          label="Занижение до суммы кредита:"
          :classes="['mt-0']"
          :rules="requireBooleanRules"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <InputRadioGroup
          v-model="form.decreaseLowerCreditSum"
          fixed-color="primary"
          label="Занижение ниже суммы кредита:"
          :classes="['mt-0']"
          :options="decreaseLowerCreditSumOptions"
          :rules="requireBooleanRules"
        />
      </v-col>
    </v-row>
    <v-row
      dense
      justify="space-between"
    >
      <v-col
        cols="12"
        md="6"
      >
        <InputRadioGroup
          v-model="form.redemptionBankPledge"
          fixed-color="primary"
          :options="redemptionBankPledgeOptions"
          label="Выкуп залога другого банка:"
          :classes="['mt-0']"
          :rules="requireBooleanRules"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <InputRadioGroup
          v-model="form.transactionBetweenRelatives"
          fixed-color="primary"
          label="Сделки между родственниками:"
          :classes="['mt-0']"
          :options="transactionBetweenRelativesOptions"
          :rules="requireBooleanRules"
        />
      </v-col>
    </v-row>
    <TwCardSimpleFormSaveBlock
      class="tw-mt-[15px]"
      :loading="formProcessing"
      bg-color="transparent"
      :cancel-confirm="true"
      :is-sticky="false"
      :visible="true"
      @success="handleSubmit"
      @cancel="handleCancel"
    />
  </v-form>
</template>

<script>
import API from '@/Api/Mortgage/Requirement'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import { RouteKeys } from '@/router/types'
import CompanyAsyncSelect from '@/components/select/company/CompanyAsyncSelect'
import InputRadioGroup from '@/components/radio/InputRadioGroup'
import TwCardSimpleFormSaveBlock from '@/components/tw-ui/forms/TwCardSimpleFormSaveBlock.vue'

export default {
  name: 'RequirementMainForm',
  components: { TwCardSimpleFormSaveBlock, InputRadioGroup, CompanyAsyncSelect },
  mixins: [FormCreateEditMixin],
  props: {
    disableRedirect: {
      type: Boolean,
      default: false
    },
    defaultFormFields: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      API: API,
      form: {
        companyId: null,
        experience: null,
        experienceLast: null,
        ageMin: null,
        ageMax: null,
        nationally: null,
        goodHistory: null,
        militaryId: null,
        rono: null,

        decreaseToCreditSum: null, // Занижение до суммы кредита
        decreaseLowerCreditSum: null, // Занижение ниже суммы кредита
        redemptionBankPledge: null, // Выкуп залога другого банка
        transactionBetweenRelatives: null, // Сделки между родственниками

        paymentType: null
      },
      goodHistoryOptions: [
        {
          label: 'Да, одобрение могут получить только клиенты с хорошей кредитной историей',
          value: true
        },
        {
          label: 'Нет, одобрение могут получить в том числе клиенты с плохой кредитной историей',
          value: false
        }
      ],
      militaryIdOptions: [
        {
          label: 'Да, если мужчине не исполнилось 27 лет, для получения кредита обязательно наличие военного билета',
          value: true
        },
        {
          label: 'Нет, для получения кредита мужчинам до 27 лет наличие военного билета не обязательно',
          value: false
        }
      ],
      nationallyOptions: [
        {
          label: 'Да, наличие гражданства РФ обязательно',
          value: true
        },
        {
          label: 'Нет, кредит может быть выдан в том числе иностранным гражданам',
          value: false
        }
      ],
      ronoOptions: [
        {
          label: 'Да, банк допускает выделение долей детям',
          value: true
        },
        {
          label: 'Нет, выделение долей детям невозможно',
          value: false
        }
      ],
      decreaseToCreditSumOptions: [
        {
          label: 'Да',
          value: true
        },
        {
          label: 'Нет',
          value: false
        }
      ],
      decreaseLowerCreditSumOptions: [
        {
          label: 'Да',
          value: true
        },
        {
          label: 'Нет',
          value: false
        }
      ],
      redemptionBankPledgeOptions: [
        {
          label: 'Да',
          value: true
        },
        {
          label: 'Нет',
          value: false
        }
      ],
      transactionBetweenRelativesOptions: [
        {
          label: 'Да',
          value: true
        },
        {
          label: 'Нет',
          value: false
        }
      ]
    }
  },
  methods: {
    handleSubmit () {
      if (this.$refs.form.validate()) {
        if (this.formProcessing === true) {
          return
        }

        this.formProcessing = true
        const request = this.formType === 'create'
          ? API.store(this.form)
          : API.update(this.targetId, this.form)

        request.then(({ data }) => {
          if (this.formType === 'create') {
            this.$emit('create', data.data)
            // если текущий путь равен пути создания то редирект в просмотр
            if (this.$route.name === `${RouteKeys.company}-create`) {
              this.$router.replace({
                name: `${RouteKeys.company}-show`,
                params: { id: data.data.id }
              })
            }
          } else {
            this.$emit('update', data.data)
          }
        }).finally(() => {
          this.formProcessing = false
        })
      }
    },
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          const adaperMap = {
            // from(show): to(send)
            company: 'companyId'
          }
          for (const key in this.preset) {
            if (adaperMap[key] !== undefined) {
              if (adaperMap[key].indexOf('Id') !== -1 && (this.preset[key] && this.preset[key].id)) {
                this.$set(this.form, adaperMap[key], this.preset[key].id)
              }
            } else {
              if (key === 'paymentType' && typeof this.preset[key] === 'object') {
                this.$set(this.form, key, this.preset[key].value)
              } else {
                this.$set(this.form, key, this.preset[key])
              }
            }
          }
        }
        resolve()
      })
    }
  }
}
</script>
