<template>
  <TwPanelContentBlock
    no-data-text="Отсутствует"
    class="tw-mb-[6px]"
  >
    <template #title>
      Рассрочка платежей
    </template>
    <template #action>
      <TwPanelContentBlockActionButton
        type="add"
        @click.stop="isEdit = !isEdit"
      >
        {{ isEdit ? 'отменить': info ? 'изменить' : 'добавить' }}
      </TwPanelContentBlockActionButton>
    </template>
    <template #default>
      <div
        v-if="loading"
        class="tw-flex tw-justify-center tw-items-center"
      >
        <TwCircleLoader
          width="30px"
          height="30px"
        />
      </div>
      <div
        class="tw-flex tw-gap-[12px] tw-flex-col"
      >
        <div
          v-if="isEdit"
          class="tw-flex tw-gap-[16px] tw-flex-col"
        >
          <TwCheckBox
            v-model="form.finished"
            class="tw-mt-[16px]"
            background-and-border-color="tw-bg-an-flat-dark-blue-link tw-border-an-flat-dark-blue-link"
            label="Рассрочка выплачена полностью"
            :disabled="formProcessing"
            :dense="false"
          />
          <TwTextarea
            v-model="form.comment"
            rows="5"
            class="tw-mt-[15px]"
            label="Комментарий"
            placeholder="Комментарий"
            :error-text="$v.form.comment.$invalid ? 'Обязательное поле' : ''"
            :disabled="formProcessing"
            :persistent-label="true"
            :show-label="true"
          />
          <!--    Вывод ошибок      -->
          <div class="tw-text-center tw-text-an-flat-red-main">
            <div v-if="$v.form.$invalid">
              Заполните все поля формы
            </div>
            <div v-if="serverErrorMessage">
              {{ serverErrorMessage }}
            </div>
          </div>
          <div>
            <TwCardBlockFormActions
              title-submit="Сохранить"
              :use-cancel-alert="false"
              :disable-submit="$v.form.$invalid"
              :handle-submit="handleSubmit"
              :handle-cancel="handleCancel"
              :disable-cancel="formProcessing"
            />
          </div>
        </div>
        <div
          v-else-if="info"
          class="tw-flex tw-gap-[12px] tw-flex-col"
        >
          <div class="tw-flex tw-gap-[12px] tw-flex-col">
            <TwPanelContentBlockFieldView>
              <template #title>
                Рассрочка выплачена полностью
              </template>
              <TwBooleanIcon
                :value="info.finished"
                size="18"
              >
                <template #right>
                  {{ info.finished ? 'Да':'Нет' }}
                </template>
              </TwBooleanIcon>
            </TwPanelContentBlockFieldView>
            <TwPanelContentBlockFieldView no-data-text="Отсутствует">
              <template #title>
                Комментарий
              </template>
              <div
                v-if="info.comment"
                class="tw-font-medium"
              >
                <ReadMoreExpander
                  :is-nl-trigger="false"
                  :text="info.comment"
                  :less-length="250"
                />
              </div>
            </TwPanelContentBlockFieldView>

            <TwPanelContentBlockFieldView>
              <template #title>
                Создал
              </template>
              <div v-if="info.creatorId">
                <ViewPanelLink
                  :id="info.creatorId"
                  label="Пользователь"
                  component="employee-show"
                >
                  <EmployeeName :id="info.creatorId" />
                </ViewPanelLink>
              </div>
            </TwPanelContentBlockFieldView>
            <TwPanelContentBlockFieldView>
              <template #title>
                Изменил
              </template>
              <div v-if="info.updateUserId">
                <ViewPanelLink
                  :id="info.updateUserId"
                  label="Пользователь"
                  component="employee-show"
                >
                  <EmployeeName :id="info.updateUserId" />
                </ViewPanelLink>
              </div>
            </TwPanelContentBlockFieldView>
          </div>
        </div>
        <div
          v-else
          class="tw-text-an-flat-disabled-text tw-p-[10px] tw-text-center"
        >
          Не добавлена
        </div>
      </div>
    </template>
  </TwPanelContentBlock>
</template>

<script>
import API from '@/Api/Crm/Deal/BuyNb/ResultManagement'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'
import TwCardBlockFormActions from '@/components/core/ViewPanel/Card/TwCardBlockFormActions.vue'
import TwTextarea from '@/components/tw-ui/ui-kit/input/TwTextarea.vue'
import ReadMoreExpander from '@/components/other/text/ReadMoreExpander.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import TwPanelContentBlockActionButton from '@/components/tw-ui/panels/TwPanelContentBlockActionButton.vue'
import TwBooleanIcon from '@/components/tw-ui/ui-kit/icons/TwBooleanIcon.vue'
import TwCheckBox from '@/components/tw-ui/ui-kit/input/TwCheckBox.vue'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink.vue'
import EmployeeName from '@/components/other/employee/EmployeeName.vue'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'DealNbResultManagementPartialPaymentBlock',
  components: {
    EmployeeName,
    ViewPanelLink,
    TwCheckBox,
    TwBooleanIcon,
    TwPanelContentBlockActionButton,
    TwCircleLoader,
    ReadMoreExpander,
    TwTextarea,
    TwCardBlockFormActions,
    TwPanelContentBlockFieldView,
    TwPanelContentBlock
  },
  mixins: [ViewPanelShowMixin],
  props: {
    id: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      isEdit: false,
      formProcessing: false,
      loading: false,
      form: {
        finished: false,
        comment: null
      },
      serverErrorMessage: null,
      info: null
    }
  },
  validations () {
    return {
      form: {
        comment: {
          required
        }
      }
    }
  },
  watch: {
    isEdit (newValue) {
      if (newValue) {
        this.form.finished = this.info?.finished || false
        this.form.comment = this.info?.comment || null
      }
    }
  },
  methods: {
    fetchInfo () {
      this.serverErrorMessage = null
      this.loading = true
      API.showPartialPayment(this.id)
        .then(({ data: result }) => {
          this.info = result?.data || null
        })
        .catch((error) => {
          console.error('handleSubmit', error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleSubmit () {
      if (!this.$v.form.$invalid) {
        this.formProcessing = true
        this.serverErrorMessage = null
        if (this.info?.id) {
          API.updatePartialPayment(this.id, this.form)
            .then(() => {
              this.fetchInfo()
              this.isEdit = false
            })
            .catch((error) => {
              this.serverErrorMessage = error?.serverErrorMessage
            })
            .finally(() => {
              this.formProcessing = false
            })
        } else {
          API.storePartialPayment(this.id, this.form)
            .then(() => {
              this.fetchInfo()
              this.isEdit = false
            })
            .catch((error) => {
              console.warn('error', error)
              this.serverErrorMessage = error?.serverErrorMessage
            })
            .finally(() => {
              this.formProcessing = false
            })
        }
      }
    },
    handleCancel () {
      this.isEdit = false
    }
  },
  mounted () {
    this.fetchInfo()
  }
}
</script>
