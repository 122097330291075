<template>
  <div class="tw-flex tw-gap-[9px] tw-text-main-sm">
    <button
      :disabled="predDisable"
      title="Предыдущая страница"
      class="tw-relative tw-group tw-w-[42px] tw-h-[42px] tw-bg-white tw-rounded-full tw-border tw-border-solid tw-border-an-flat-grey-9 disabled:tw-bg-transparent disabled:tw-border-an-flat-btn-disabled-bg hover:tw-border-an-flat-red-main hover:tw-bg-an-flat-red-main"
      @click.stop="pred"
    >
      <svg
        class="tw-absolute tw-top-1/2 tw-right-1/2 tw-transform tw-translate-x-1/2 -tw-translate-y-1/2"
        width="5"
        height="8"
        viewBox="0 0 5 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          class="group-disabled:tw-fill-an-flat-btn-disabled-bg group-hover:tw-fill-white"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.44187 7.76823C4.86615 7.41467 4.92347 6.7841 4.56991 6.35983L2.60339 4.00001L4.56991 1.64019C4.92347 1.21592 4.86615 0.585353 4.44187 0.231788C4.01759 -0.121775 3.38703 -0.0644515 3.03346 0.359825L-2.28882e-05 4.00001L3.03346 7.64019C3.38703 8.06447 4.01759 8.1218 4.44187 7.76823Z"
          fill="#343942"
        />
      </svg>
    </button>
    <div class="tw-flex tw-gap-[9px]">
      <template v-for="(item, iIndex) in items">
        <button
          v-if="typeof item === 'number'"
          :key="item + '_' + iIndex"
          :disabled="disabled || value === item"
          :class="[
            value === item ? 'tw-border-an-flat-red-main' : 'tw-border-an-flat-grey-9 hover:tw-border-an-flat-btn-disabled-bg hover:tw-bg-an-flat-btn-disabled-bg disabled:tw-bg-an-flat-btn-disabled-bg',
            disabled ? 'tw-opacity-50' : ''
          ]"
          class="tw-min-w-[42px] tw-h-[42px] tw-px-[14px] tw-bg-white tw-rounded tw-border tw-border-solid"
          @click.stop="change(item)"
        >
          {{ item }}
        </button>
        <div
          v-else
          :key="item + '_' + iIndex"
          class="tw-w-[42px] tw-h-[42px] tw-flex tw-items-end tw-justify-center tw-tracking-widest tw-cursor-default"
        >
          {{ item }}
        </div>
      </template>
    </div>
    <button
      :disabled="nextDisable"
      title="Следующая страница"
      class="tw-relative tw-group tw-w-[42px] tw-h-[42px] tw-bg-white tw-rounded-full tw-border tw-border-solid tw-border-an-flat-grey-9 disabled:tw-bg-transparent disabled:tw-border-an-flat-btn-disabled-bg hover:tw-border-an-flat-red-main hover:tw-bg-an-flat-red-main"
      @click.stop="next"
    >
      <svg
        class="tw-absolute tw-top-1/2 tw-right-1/2 tw-transform tw-translate-x-1/2 -tw-translate-y-1/2"
        width="5"
        height="8"
        viewBox="0 0 5 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          class="group-disabled:tw-fill-an-flat-btn-disabled-bg group-hover:tw-fill-white"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.359818 7.76823C-0.0644591 7.41467 -0.121783 6.7841 0.231781 6.35983L2.19829 4.00001L0.231781 1.64019C-0.121783 1.21592 -0.0644591 0.585353 0.359818 0.231788C0.784095 -0.121775 1.41466 -0.0644515 1.76822 0.359825L4.80171 4.00001L1.76822 7.64019C1.41466 8.06447 0.784095 8.1218 0.359818 7.76823Z"
          fill="#343942"
        />
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  name: 'TwPagination',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: Number,
      default: 1
    },
    length: {
      type: Number,
      default: 10
    },
    totalVisible: {
      type: Number,
      default: 7
    }
  },
  computed: {
    nextDisable () {
      return this.value + 1 > this.length || this.disabled
    },
    predDisable () {
      return this.value - 1 <= 0 || this.disabled
    },
    items () {
      const totalVisible = parseInt(this.totalVisible, 10)

      if (totalVisible === 0 || isNaN(this.length) || this.length > Number.MAX_SAFE_INTEGER) {
        return []
      }

      const maxLength = Math.min(
        Math.max(0, totalVisible) || this.length,
        Math.max(0, this.maxButtons) || this.length,
        this.length
      )

      if (this.length <= maxLength) {
        return this.range(1, this.length)
      }

      const even = maxLength % 2 === 0 ? 1 : 0
      const left = Math.floor(maxLength / 2)
      const right = this.length - left + 1 + even

      if (this.value > left && this.value < right) {
        const firstItem = 1
        const lastItem = this.length
        const start = this.value - left + 2
        const end = this.value + left - 2 - even
        const secondItem = start - 1 === firstItem + 1 ? 2 : '...'
        const beforeLastItem = end + 1 === lastItem - 1 ? end + 1 : '...'

        return [1, secondItem, ...this.range(start, end), beforeLastItem, this.length]
      } else if (this.value === left) {
        const end = this.value + left - 1 - even
        return [...this.range(1, end), '...', this.length]
      } else if (this.value === right) {
        const start = this.value - left + 1
        return [1, '...', ...this.range(start, this.length)]
      } else {
        return [
          ...this.range(1, left),
          '...',
          ...this.range(right, this.length)
        ]
      }
    }
  },
  methods: {
    next () {
      this.change(this.value + 1)
    },
    pred () {
      this.change(this.value - 1)
    },
    change (pageNum) {
      console.log(this.$options.name, 'change', pageNum)
      this.$emit('change', pageNum || 1)
    },
    range (from, to) {
      const range = []
      // eslint-disable-next-line no-param-reassign
      from = from > 0 ? from : 1
      for (let i = from; i <= to; i++) {
        range.push(i)
      }
      return range
    }
  }
}
</script>

<style scoped>

</style>
