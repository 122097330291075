export enum RouteKeys {
    accounting = 'accounting',
    link = 'link',
    messenger = 'messenger',
    messengerPlatformAccount = 'messenger-platform-account',
    contract = 'contract',
    company = 'company',
    companies = 'companies',
    document = 'document',
    employee = 'user',
    userSurveys = 'user-surveys',
    variant = 'variant',
    deal = 'deal',
    mortgage = 'mortgage',
    newbuildings = 'newbuildings',
    person = 'person',
    lead = 'lead',
    requirement = 'requirement',
    program = 'program',
    'program-category' = 'program-category',
    'lead-source' = 'lead-source',
    'lead-status' = 'lead-status',
    'deal-status' = 'deal-status',
    'deal-commission' = 'deal-commission',
    structure = 'structure',
    'permission-structure' = 'permission-structure',
    'position-structure' = 'position-structure',
    office = 'office',
    position = 'position',
    department = 'department',
    reports = 'reports',
    support = 'support',
    'realty-objects' = 'realty-objects',
    objects = 'objects',
    builder = 'builder',
    builderFiles = 'builders',
    builderImportDocument = 'builder-import-document',
    companyPeriodResultManagement = 'company-period-result-management',
    complex = 'complex',
    project = 'project',
    tools = 'tools',
    news = 'news',
    changeLog = 'change-log',
    crmNews = 'crm-news',
    stock = 'stock',
    mortgageComplexParams = 'complex-params',
    mortgageUserComments = 'user-comments',
    systemTagMessage = 'tag-message',
    siteArticles = 'site-articles',
    siteDeveloper = 'site-developer',
    siteServices = 'site-services',
    siteServiceSection = 'site-service-section',
    siteServiceItem = 'site-service-item',
    siteFaq = 'site-faq',
    management = 'management',
    test = 'test',
    seoPage = 'seo-page',
    seoPageResale = 'seo-page-resale',
    pageMeta = 'page-meta',
    adsObjects = 'ads-objects',
    departmentPosition = 'department-position',
    view = 'view',
    building = 'building',
    apartment = 'apartment',
    dealReview = 'deal-review',
    dealResultManagement = 'deal-result-management',
    companyReviews = 'company-reviews',
    requestDistribution = 'request-distribution',
    dealDistributionResponsibleUser = 'deal-distribution-responsible-user',
    dealDistributionChangesComplexes = 'deal-distribution-changes-complexes',
    dev = 'dev',
    seoPageMortgage = 'seo-page-mortgage',
    knowledgeBase = 'knowledgeBase',
    training = 'training',
}

type TDeployEnv = 'prod' | 'local' | 'beta' | 'test' | string
export const DEPLOYMENT_ENV: TDeployEnv = process.env && process.env.VUE_APP_DEPLOYMENT_ENV ? process.env.VUE_APP_DEPLOYMENT_ENV : 'local'
export const DEPLOYMENT_VERSION = process.env && (process.env.VUE_APP_COMMIT_TAG || process.env.VUE_APP_COMMIT_SHORT_SHA || 'dev')
