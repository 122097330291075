<template>
  <TwPanelContentBlock class="tw-flex-1 tw-mb-[6px]">
    <template #title>
      <div>
        Форма проведения опроса
      </div>
    </template>
    <template #action>
      <TwPanelContentBlockActionButton
        type="edit"
        :disabled="formProcessing"
        @click.stop="$emit('changeEdit')"
      >
        Отменить
      </TwPanelContentBlockActionButton>
    </template>
    <div
      v-if="formProcessing"
      class="tw-p-3 tw-flex tw-justify-center"
    >
      <TwCircleLoader class="tw-p-3" />
    </div>
    <TwFormGenerator
      v-else-if="formSchema"
      :disabled="formProcessing"
      :form-data="formData"
      :form-schema="formSchema"
      @input="onInputValue"
    />

    <TwCardSimpleFormSaveBlock
      class="tw-mt-[15px]"
      :loading="formProcessing"
      :cancel-confirm="false"
      :is-sticky="true"
      :disable-success="formProcessing"
      :disable-cancel="formProcessing"
      :visible="true"
      @success="handleSubmit"
      @cancel="$emit('changeEdit')"
    />
  </TwPanelContentBlock>
</template>

<script>
import API from '@/Api/Review/RealtorSurvey/Interview'
import TwPanelContentBlockActionButton from '@/components/tw-ui/panels/TwPanelContentBlockActionButton.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwFormGenerator from '@/views/Company/UserSurveys/FormGenerator/TwFormGenerator.vue'
import TwCardSimpleFormSaveBlock from '@/components/tw-ui/forms/TwCardSimpleFormSaveBlock.vue'

export default {
  name: 'SurveyInterviewForm',
  components: {
    TwCardSimpleFormSaveBlock,
    TwFormGenerator,
    TwPanelContentBlock,
    TwCircleLoader,
    TwPanelContentBlockActionButton
  },
  props: {
    createParams: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      formProcessing: false,
      // isNeedViewPersonLeadAndDeals: false,
      API: API,
      formData: {},
      formSchema: {}
    }
  },
  validations: {
    formData: {}
  },
  mounted () {
    this.init()
  },
  methods: {
    onInputValue (eventData) {
      console.warn('ON INPUT DATA: ', eventData)
      this.formData[eventData.field] = eventData.value
    },
    init () {
      this.formProcessing = true
      API.create(this.createParams?.surveyId).then(({ data }) => {
        console.warn(data)
        this.formData = data?.data || {}
        this.formSchema = data?.formSchema || {}
      }).finally(() => {
        this.formProcessing = false
      })
    },
    handleSubmit () {
      this.$v.$reset()
      this.$v.$touch()
      // console.log('handleSubmit ', this.$v)
      // console.log('$invalid ', this.$v.formData.$invalid)
      if (!this.$v.$invalid) {
        if (this.formProcessing === true) {
          return
        }

        this.formProcessing = true
        API.update(this.createParams?.surveyId, this.formData).then(() => {
          this.$emit('changeEdit')
          this.$emit('updated')
        }).finally(() => {
          this.formProcessing = false
        })
      }
    }
  }
}
</script>
