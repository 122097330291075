import { AxiosResponse } from 'axios'
import { AuthAxiosDefaultConfig } from '@/Api/Auth'
import HTTP from '@/Api/http'

const profilePath = '/profile'
const loginPath = '/login'
const logoutPath = '/logout'

export default {
  reg ($name: string, $email: string, $password: string): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.post('register', {
      name: $name,
      email: $email,
      password: $password
    }, $config)
  },
  logout (): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.post(logoutPath, {}, $config)
  },
  login ($email: string, $password: string): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.post(loginPath, { email: $email, password: $password }, $config)
  },
  subLogin (userId: string | number): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.post(`${loginPath}/${userId}`, {}, $config)
  },
  getProfile (): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.get(profilePath, $config)
  },
  update (data: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.put(profilePath, data, $config)
  },
  create (): Promise<AxiosResponse> {
    return new Promise((resolve: any) => resolve())
  },
  edit (): Promise<AxiosResponse> {
    return new Promise((resolve: any) => resolve())
  }
}
