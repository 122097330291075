<template>
  <div :class="baseClasses">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'TwFlexRow',
  props: {
    baseClasses: {
      type: String,
      default: 'tw-flex tw-flex-wrap tw-gap-[8px]'
    }
  }
}
</script>

<style scoped>

</style>
