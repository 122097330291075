<template>
  <div v-if="formPermissionActionsStatus && formPermissionActionsStatus[formType]">
    <div style="display: none">
      {{ isEdited }}
    </div>
    <div
      class="tw-flex tw-flex-col tw-gap-[12px]"
      style="min-height: 600px"
    >
      <TwSelect
        v-model="form.type"
        label="Выберите тип сделки"
        placeholder="Выберите из списка"
        :options="typeOptions"
        :disabled="formProcessing"
        :dense="true"
        :persistent-label="true"
      />
      <template v-if="subTypeOptions.length">
        <TwSelect
          v-model="form.subtype"
          label="Выберите вид услуги"
          placeholder="Выберите из списка"
          :options="subTypeOptions"
          :disabled="formProcessing"
          :dense="true"
          :persistent-label="true"
        />
      </template>
      <TwSelectRelations
        v-model="form.responsibleUserId"
        :multi="false"
        :dense="true"
        label="Ответственный"
        list-label="Найти сотрудника"
        relation-name="user"
        :persistent-label="true"
        placeholder="Не выбран"
        :disabled="formProcessing"
      />
      <TwSelectRelations
        v-model="form.byRealtorId"
        :multi="false"
        :dense="true"
        label="От риелтора"
        list-label="Найти сотрудника"
        relation-name="user"
        :persistent-label="true"
        placeholder="Не выбран"
        :disabled="formProcessing"
      />
      <div style="flex: 1" />
      <div>
        <TwCardSimpleFormSaveBlock
          class="tw-mt-[15px]"
          :loading="formProcessing"
          :cancel-confirm="false"
          :is-sticky="true"
          :disable-success="(subTypeOptions.length && !form.subtype) || !form.type || formProcessing"
          :disable-cancel="formProcessing"
          :visible="true"
          success-title="Создать заявку"
          cancel-title="Отмена"
          @success="handleSubmit"
          @cancel="$emit('toBack')"
        />
      </div>
    </div>
  </div>
  <div
    v-else-if="formProcessing"
    class="tw-py-[10px] tw-flex"
  >
    <TwCircleLoader class="tw-m-auto" />
  </div>
  <div v-else>
    <PermissionDenied text="Недостаточно прав для данного действия!" />
    <!-- TODO debug permissions -->
    <!--    <strong>-->
    <!--      {{formPermissionActionsStatus}}-->
    <!--    </strong>-->
    <!--    <br/>-->
    <!--    <strong>-->
    <!--      {{formType}}-->
    <!--    </strong>-->
    <!--    <br/>-->
    <!--    {{permissions}}-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import API from '@/Api/Crm/Deal/index'
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import PermissionDenied from '@/views/PermissionDenied.vue'
import TwSelectRelations from '@/components/tw-ui/ui-kit/input/select-relations/TwSelectRelations.vue'
import TwSelect from '@/components/tw-ui/ui-kit/input/TwSelect.vue'
import TwCircleLoader from '../tw-ui/ui-kit/TwCircleLoader.vue'
import TwCardSimpleFormSaveBlock from '../tw-ui/forms/TwCardSimpleFormSaveBlock.vue'

export default {
  name: 'CreateFromLead',
  components: { TwSelect, TwSelectRelations, PermissionDenied, TwCircleLoader, TwCardSimpleFormSaveBlock },
  mixins: [ViewPanelShowMixin, FormCreateEditMixin],
  props: {
    lead: {
      type: Object,
      default: () => {
        return {}
      }
    },
    leadId: {
      required: true
    }
  },
  data () {
    return {
      formProcessing: false,
      API: API,
      form: {
        type: null,
        subtype: null,
        responsibleUserId: null,
        byRealtorId: null,
        leadId: this.leadId
      }
    }
  },
  methods: {
    presetData () {
      if (this?.lead?.creatorId) {
        this.form.byRealtorId = this.lead.creatorId
      }
      if (!this.form.byRealtorId && this.currentUserId) {
        this.form.byRealtorId = this.currentUserId
      }
      if (!this.form.responsibleUserId && this.currentUserId) {
        this.form.responsibleUserId = this.currentUserId
      }
    },
    handleSubmit () {
      this.formProcessing = true
      API.store(this.form)
        .then(({ data }) => {
          console.warn('data', data)
          this.$emit('dealCreated', data.data)
          this.showDealCard(data?.data?.id)
        })
        .finally(() => {
          this.formProcessing = false
        })
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'profile/getUser'
    }),
    currentUserId () {
      return this?.currentUser?.id
    },
    typeOptions () {
      return this.dictionary?.type || []
    },
    subTypeDict () {
      return this.dictionary?.subtype || {}
    },
    subTypeOptions () {
      return this.form?.type && Array.isArray(this.subTypeDict[this.form.type]) ? this.subTypeDict[this.form.type] : []
    }
  }
}
</script>

<style scoped>

</style>
