import { RouteKeys } from '@/router/types'
export default {
  path: `/${RouteKeys.deal}`,
  name: `${RouteKeys.deal}`,
  meta: { name: 'Заявки', icon: 'deal', plannedKey: 'deal' },
  redirect: { name: `${RouteKeys.deal}-list` },
  component: () => import('@/views/Deal/Index.vue'),
  children: [
    {
      path: 'page',
      name: `${RouteKeys.deal}-page`,
      redirect: { name: `${RouteKeys.deal}-list` },
      meta: { name: 'Заявки', icon: 'deal', hideInMenu: true },
      component: () => import('@/views/Deal/Index.vue'),
      props: true,
      children: [
        {
          path: 'list',
          name: `${RouteKeys.deal}-list`,
          meta: { name: 'Список заявок', icon: 'deal', hideInMenu: true, hText: 'Список заявок' },
          component: () => import('@/views/Deal/List.vue'),
          props: true
        },
        {
          path: 'kanban',
          name: `${RouteKeys.deal}-kanban`,
          meta: { name: 'Канбан заявки', icon: 'deal', hideInMenu: true, hText: 'Канбан заявки', toolbarNotSticky: true },
          component: () => import('@/views/Deal/kanban/DealKanbanPage.vue'),
          props: true
        }
      ]
    },
    {
      path: ':id/edit',
      name: `${RouteKeys.deal}-edit`,
      meta: { name: 'Редактирование Заявки', icon: 'deal', hideInMenu: true },
      component: () => import('@/views/Deal/DealCard.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    },
    {
      path: 'create',
      name: `${RouteKeys.deal}-create`,
      meta: { name: 'Добавление Заявки', icon: 'deal', hideInMenu: true },
      component: () => import('@/views/Deal/DealCard.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    },
    {
      path: ':id',
      name: `${RouteKeys.deal}-show`,
      meta: { name: 'Просмотр заявки', icon: 'deal', hideInMenu: true },
      component: () => import('@/views/Deal/DealCard.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    }
  ]
}
