<template>
  <div class="tw-bg-an-flat-gray-15 tw-rounded-md tw-flex tw-flex-col">
    <template v-if="$slots.title">
      <div class="tw-flex tw-justify-between tw-items-center tw-pt-[10px] tw-pb-[5px] tw-pl-[18px] tw-pr-[18px]">
        <div class="tw-flex-1 tw-text-an-flat-not-active-input tw-font-bold tw-uppercase tw-leading-[24px]">
          <slot name="title" />
        </div>
        <div class="tw-font-medium tw-flex tw-items-center tw-gap-[8px]">
          <slot name="action" />
        </div>
      </div>
      <hr
        class="tw-border"
        :class="styleHr"
      >
    </template>
    <div
      class="tw-grow"
      :class="noContentPadding ? '' : 'tw-p-[14px]'"
    >
      <div
        v-if="$slots.default"
        :class="contentClasses"
      >
        <slot name="default" />
      </div>
      <div
        v-else
        class="tw-text-an-flat-disabled-text tw-text-center"
      >
        {{ noDataText }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TwPanelContentBlock',
  props: {
    noDataText: {
      type: String,
      default: 'Не указано'
    },
    noContentPadding: {
      type: Boolean,
      default: false
    },
    contentClasses: {
      type: String,
      default: 'tw-bg-white tw-rounded-md tw-p-[18px] tw-h-full'
    },
    styleHr: {
      type: String,
      default: 'tw-border-white'
    }
  }
}
</script>
