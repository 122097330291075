export type TBaseTableHeaderItem = {
  dataKey: string | number
  text: string
  colStyle: string
  align?: 'left' | 'center' | 'right'
  thClass: string
  tdClass: string
  sortable?: boolean
  isDivider?: boolean
  hideFromClient?: boolean
  visible?: boolean
  value?: string
}

export type TableTypes = Array<TBaseTableHeaderItem>

// TODO - изменять в крайнем случае(повлияет на всё где подключено) - для кастомизации создать ...Two...Tree...
export const tableItemStyleOne: any = {
  thClass: 'tw-text-main-sm2 tw-text-left tw-font-semibold tw-whitespace-nowrap tw-px-[12px] tw-py-[8px] tw-bg-an-flat-gray-9',
  tdClass: 'tw-border tw-border-an-flat-gray-7 tw-p-[12px] tw-whitespace-nowrap'
}
export const tableItemStyleTwo: any = {
  thClass: 'tw-px-[22px] tw-pt-[11px] tw-pb-[10px] tw-border-x tw-border-t tw-bg-an-flat-bg-main tw-text-left tw-text-an-flat-black-table tw-text-table-header tw-whitespace-nowrap',
  tdClass: 'tw-px-[16px] tw-py-[12px] tw-border tw-border-opacity tw-text-table-body tw-text-center tw-text-an-flat-black-table tw-whitespace-nowrap'
}
// вариант с генеративными классами стилей
// TODO аргументы метода можно дополнять
export function tableItemStyleOneGen (nowrapTd = true, nowrapTh = true):any {
  return {
    thClass: `
     tw-text-main-sm2
     tw-text-left
     tw-font-semibold
     tw-whitespace-nowrap
     ${nowrapTh ? 'tw-whitespace-nowrap' : ''}
     tw-px-[12px]
     tw-py-[8px]
     tw-bg-an-flat-gray-9
    `,
    tdClass: `
     tw-border
     tw-border-an-flat-gray-7
     tw-p-[12px]
     ${nowrapTd ? 'tw-whitespace-nowrap' : ''}`
  }
}
export const tableItemStyleTwoGen: any = (nowrapTd = true, nowrapTh = true) => {
  return {
    thClass: `
     tw-px-[22px]
     tw-pt-[11px]
     tw-pb-[10px]
     tw-border-x
     tw-border-t
     tw-bg-an-flat-bg-main
     tw-text-an-flat-black-table
     tw-text-table-header
     ${nowrapTh ? 'tw-whitespace-nowrap' : ''}
    `,
    tdClass: `
     tw-px-[16px]
     tw-py-[12px]
     tw-border
     tw-border-opacity
     tw-text-table-body
     tw-text-an-flat-black-table
     ${nowrapTd ? 'tw-whitespace-nowrap' : ''}`
  }
}
