<template>
  <div
    :class="fromPanel ? 'tw-px-[26px] tw-py-[18px]':''"
    class="tw-font-manrope tw-text-main-sm2 tw-max-w-[100%] tw-bg-white tw-min-h-screen"
  >
    <template v-if="isCardContentVisible">
      <TwPanelTitle v-if="fromPanel">
        <template #left>
          <div class="tw-text-h4 tw-font-bold">
            <div
              v-if="data"
              class="tw-flex tw-items-center tw-gap-[7px]"
            >
              <span v-if="data && data.confirmed">
                <TwPopoverShort>
                  <template #trigger>
                    <svg
                      width="18"
                      height="19"
                      viewBox="0 0 18 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="9"
                        cy="9.51421"
                        r="8"
                        fill="#22C17B"
                        stroke="#22C17B"
                        stroke-width="2"
                      />
                      <path
                        d="M6 10.1334L7.90909 12L12 8.00005"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                    </svg>
                  </template>
                  <template #content>{{ confirmedTextTrue }}</template>
                </TwPopoverShort>
              </span>
              <span>{{ $Global.peopleName(data, 'fi') }}</span>
              <span class="tw-text-an-flat-disabled-text tw-font-medium"> # {{ data.id }}</span>
            </div>
            <div
              v-else-if="isNew"
              class="title text-muted"
            >
              Новый контакт
            </div>
            <div v-else>
              ~
            </div>
          </div>
        </template>
        <template #right>
          <div class="tw-flex tw-gap-[8px] tw-font-manrope tw-font-bold tw-text-main-xs ml-3" />
          <TwPanelActionButton
            v-if="!isNew && data"
            title="Создать лид на основе данного контакта"
            color="green"
            @click.prevent="createLead"
          >
            Создать лид
          </TwPanelActionButton>
          <TwPanelActionButton
            v-if="!isNew && data && !loading && !editMain && currentTab === 'main'"
            title="Создать лид на основе данного контакта"
            @click.prevent="mainIntro"
          >
            <svg
              class="tw-block tw-mr-[4px]"
              width="16"
              height="16"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2 9.54167C2 5.37652 5.37652 2 9.54167 2C13.7068 2 17.0833 5.37652 17.0833 9.54167C17.0833 13.7068 13.7068 17.0833 9.54167 17.0833C5.37652 17.0833 2 13.7068 2 9.54167ZM9.54167 0C4.27195 0 0 4.27195 0 9.54167C0 14.8114 4.27195 19.0833 9.54167 19.0833C14.8114 19.0833 19.0833 14.8114 19.0833 9.54167C19.0833 4.27195 14.8114 0 9.54167 0ZM8.62587 6.21542C8.94399 6.02846 9.31803 5.96012 9.68172 6.0225C10.0454 6.08488 10.3753 6.27396 10.6129 6.55626C10.8505 6.83855 10.9806 7.19583 10.9801 7.56483L10.9801 7.56632C10.9801 7.88962 10.7243 8.26933 10.1441 8.65615C9.88443 8.82927 9.61763 8.96338 9.41181 9.05485C9.31032 9.09996 9.22714 9.13316 9.17167 9.15429C9.14402 9.16483 9.1235 9.17227 9.11133 9.1766L9.09956 9.18073L9.09999 9.18059C8.5769 9.35576 8.29438 9.92155 8.46887 10.445C8.64352 10.969 9.20984 11.2522 9.73379 11.0775L9.41756 10.1288C9.73379 11.0775 9.73407 11.0774 9.73437 11.0773L9.73502 11.0771L9.73649 11.0766L9.74015 11.0754L9.75027 11.0719L9.78135 11.061C9.80671 11.052 9.84124 11.0394 9.88366 11.0233C9.96833 10.991 10.0853 10.9441 10.2241 10.8825C10.4987 10.7604 10.8726 10.5742 11.2535 10.3202C11.9544 9.853 12.9796 8.95175 12.9801 7.56724L12.9801 7.56781L11.9801 7.56632H12.9801V7.56724C12.9812 6.72613 12.6847 5.91174 12.143 5.26825C11.6012 4.62463 10.849 4.19352 10.0198 4.05129C9.19063 3.90906 8.33784 4.06487 7.61251 4.49116C6.88718 4.91744 6.33612 5.58667 6.05694 6.38031C5.87367 6.9013 6.14744 7.47222 6.66843 7.65549C7.18942 7.83876 7.76033 7.56499 7.94361 7.044C8.06606 6.69591 8.30775 6.40239 8.62587 6.21542ZM9.4859 12.5455C8.93361 12.5455 8.4859 12.9932 8.4859 13.5455C8.4859 14.0978 8.93361 14.5455 9.4859 14.5455H9.49444C10.0467 14.5455 10.4944 14.0978 10.4944 13.5455C10.4944 12.9932 10.0467 12.5455 9.49444 12.5455H9.4859Z"
                fill="#A2ABB5"
              />
            </svg>
            <span class="tw-block">
              Помощь
            </span>
          </TwPanelActionButton>
          <!-- TODO debug permissions -->
          <MenuEntityActions
            v-if="permissions"
            class="ml-3"
            :permissions="permissions"
            @delete="deleteCardItem"
          />
        </template>
      </TwPanelTitle>
      <TwPanelTabsFull
        v-model="currentTab"
        :items="tabList"
      >
        <template #content.main>
          <TwPanelContent>
            <!-- LEFT -->
            <div class="tw-flex-1">
              <!-- Основное -->
              <TwPanelContentBlock
                ref="introMain1"
                class="tw-mb-[6px]"
              >
                <template #title>
                  <div>
                    О {{ personTypes.isChildren ? 'ребёнке' : 'контакте' }}
                  </div>
                </template>
                <template #action>
                  <TwPanelContentBlockActionButton
                    ref="introMain2"
                    :disabled="isNew || loading"
                    type="edit"
                    @click="editMain = !editMain"
                  >
                    {{ editMain ? 'отменить':'изменить' }}
                  </TwPanelContentBlockActionButton>
                </template>
                <div>
                  <div
                    v-if="data && data.badPassport"
                    class="tw-mb-[12px] tw-font-semibold tw-p-2 tw-text-white tw-bg-an-flat-red-main tw-text-center"
                  >
                    <template v-if="data.badPassport === 'terrorist'">
                      Паспорт найден в базе распространителей оружия или террористов
                    </template>
                    <template v-if="data.badPassport === 'blocked'">
                      Паспорт найден в базе недействительных
                    </template>
                  </div>
                  <PersonMainForm
                    v-if="editMain"
                    :id="data ? data.id : 0"
                    ref="EditMain"
                    :person-types="personTypes"
                    :person-object-id="personObjectId"
                    :searched-phone="searchedPhone"
                    @create="onCreated"
                    @update="onUpdated"
                    @cancel="onCancelAndClose"
                  />
                  <PersonShowMain
                    v-else
                    :person-types="personTypes"
                    :dictionary="dictionary"
                    :info="data"
                  />
                </div>
              </TwPanelContentBlock>
              <!-- Черный список -->
              <TwPanelContentBlock
                v-if="data && !personTypes.isChildren"
                ref="introMain3"
                class="tw-my-[6px]"
              >
                <template #title>
                  <div class="tw-flex tw-items-center tw-gap-[7px]">
                    Черный список
                    <TwPopoverShort>
                      <template #trigger>
                        <svg
                          class="tw-block"
                          width="16"
                          height="16"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M2 9.54167C2 5.37652 5.37652 2 9.54167 2C13.7068 2 17.0833 5.37652 17.0833 9.54167C17.0833 13.7068 13.7068 17.0833 9.54167 17.0833C5.37652 17.0833 2 13.7068 2 9.54167ZM9.54167 0C4.27195 0 0 4.27195 0 9.54167C0 14.8114 4.27195 19.0833 9.54167 19.0833C14.8114 19.0833 19.0833 14.8114 19.0833 9.54167C19.0833 4.27195 14.8114 0 9.54167 0ZM8.62587 6.21542C8.94399 6.02846 9.31803 5.96012 9.68172 6.0225C10.0454 6.08488 10.3753 6.27396 10.6129 6.55626C10.8505 6.83855 10.9806 7.19583 10.9801 7.56483L10.9801 7.56632C10.9801 7.88962 10.7243 8.26933 10.1441 8.65615C9.88443 8.82927 9.61763 8.96338 9.41181 9.05485C9.31032 9.09996 9.22714 9.13316 9.17167 9.15429C9.14402 9.16483 9.1235 9.17227 9.11133 9.1766L9.09956 9.18073L9.09999 9.18059C8.5769 9.35576 8.29438 9.92155 8.46887 10.445C8.64352 10.969 9.20984 11.2522 9.73379 11.0775L9.41756 10.1288C9.73379 11.0775 9.73407 11.0774 9.73437 11.0773L9.73502 11.0771L9.73649 11.0766L9.74015 11.0754L9.75027 11.0719L9.78135 11.061C9.80671 11.052 9.84124 11.0394 9.88366 11.0233C9.96833 10.991 10.0853 10.9441 10.2241 10.8825C10.4987 10.7604 10.8726 10.5742 11.2535 10.3202C11.9544 9.853 12.9796 8.95175 12.9801 7.56724L12.9801 7.56781L11.9801 7.56632H12.9801V7.56724C12.9812 6.72613 12.6847 5.91174 12.143 5.26825C11.6012 4.62463 10.849 4.19352 10.0198 4.05129C9.19063 3.90906 8.33784 4.06487 7.61251 4.49116C6.88718 4.91744 6.33612 5.58667 6.05694 6.38031C5.87367 6.9013 6.14744 7.47222 6.66843 7.65549C7.18942 7.83876 7.76033 7.56499 7.94361 7.044C8.06606 6.69591 8.30775 6.40239 8.62587 6.21542ZM9.4859 12.5455C8.93361 12.5455 8.4859 12.9932 8.4859 13.5455C8.4859 14.0978 8.93361 14.5455 9.4859 14.5455H9.49444C10.0467 14.5455 10.4944 14.0978 10.4944 13.5455C10.4944 12.9932 10.0467 12.5455 9.49444 12.5455H9.4859Z"
                            fill="#A2ABB5"
                          />
                        </svg>
                      </template>
                      <template #content>
                        <div class="tw-normal-case tw-max-w-[250px]">
                          Добавить в черный список, если клиент повел себя не порядочно. С обязательным описанием возникшей ситуации
                        </div>
                      </template>
                    </TwPopoverShort>
                  </div>
                </template>
                <template #action>
                  <TwPanelContentBlockActionButton
                    :disabled="isNew || loading"
                    type="edit"
                    @click="editBlackList = !editBlackList"
                  >
                    {{ editBlackList ? 'отменить':'изменить' }}
                  </TwPanelContentBlockActionButton>
                </template>
                <div>
                  <PersonBlackListForm
                    v-if="editBlackList"
                    :id="data && data.blackList ? data.blackList.id : 0"
                    ref="blackList"
                    dense-fields
                    :person-id="data.id"
                    @create="refresh"
                    @update="refresh"
                    @refresh="refresh"
                    @cancel="editBlackList = false"
                  />
                  <PersonBlackListShow
                    v-else
                    :dictionary="dictionary"
                    :info="data.blackList"
                  />
                </div>
              </TwPanelContentBlock>
            </div>
            <!-- RIGHT -->
            <div class="tw-flex-1">
              <TwPanelContentBlock
                v-if="data"
                ref="introMain4"
                :content-classes="''"
                class="tw-mb-[6px]"
              >
                <TwChronologyRoot
                  v-if="!isNew && data"
                  ref="chronology"
                  :entity-id="data.id"
                  :person-id="data.id"
                  entity="person"
                  :entity-info="null"
                />
              </TwPanelContentBlock>
            </div>
          </TwPanelContent>
        </template>
        <template #content.docs>
          <PersonDocumentsTab
            v-if="data"
            :person-id="data.id"
          />
        </template>
        <template #content.otherInfo>
          <PersonHipotecTab
            v-if="data"
            v-bind="$props"
            :id="data.id"
            :show-main-block="false"
          />
        </template>
        <template #content.deals>
          <PersonDealList
            v-if="data"
            :person-id="data.id"
          />
        </template>
        <template #content.insuranceObject>
          <PersonInsuranceObjectList
            v-if="data"
            :person-id="data.id"
          />
        </template>
      </TwPanelTabsFull>
    </template>
    <!--
      Блок заполняющий пространство при отсутствуии контента
      для прижатия блока сохранения к нижней части страницы
    -->
    <div class="tw-flex tw-justify-center tw-items-center">
      <TwCircleLoader
        v-if="loading && !isCardContentVisible"
        width="50px"
        height="50px"
      />
      <div v-else-if="error">
        <PermissionDenied :text="error === 403 ? 'Недостаточно прав для просмотра!' : 'Ошибка соединения - Обратитесь к администратору!'" />
      </div>
    </div>
  </div>
</template>

<script>
import { confirmedTextTrue } from '@/views/Person/personDict'
import MainLesson from './lessons/Main'
import API from '@/Api/Crm/Person'
import PersonMainForm from '@/views/Person/main/PersonMainForm'
import PersonShowMain from '@/views/Person/main/PersonMainShow'
import CardMixin from '@/mixins/CardMixin'
import PersonDocumentsTab from '@/views/Person/documents/PersonDocumentsTab'
import PersonBlackListForm from '@/views/Person/main/PersonBlackListForm'
import PersonBlackListShow from '@/views/Person/main/PersonBlackListShow'
import PersonHipotecTab from '@/views/Person/hipotec/PersonHipotecTab'
import PersonDealList from '@/views/Person/Deals/PersonDealList'
import MenuEntityActions from '@/components/core/ViewPanel/Card/entity-actions/menuEntityActions'
import PermissionDenied from '@/views/PermissionDenied'
import { mapActions } from 'vuex'

import TwPanelTabsFull from '@/components/tw-ui/panels/TwPanelTabsFull.vue'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import TwPanelTitle from '@/components/tw-ui/panels/TwPanelTitle.vue'
import TwPanelContent from '@/components/tw-ui/panels/TwPanelContent.vue'
import TwPopoverShort from '@/components/tw-ui/popover/TwPopoverShort.vue'
import TwPanelActionButton from '@/components/tw-ui/panels/TwPanelActionButton.vue'
import TwPanelContentBlockActionButton from '@/components/tw-ui/panels/TwPanelContentBlockActionButton.vue'
import TwChronologyRoot from '@/components/core/Chronology/tw-dev/TwChronologyRoot.vue'
import PersonInsuranceObjectList from '@/views/Person/InsuranceObject/PersonInsuranceObjectList.vue'

export default {
  name: 'PersonCard',
  components: {
    PersonInsuranceObjectList,
    TwChronologyRoot,
    TwPanelContentBlockActionButton,
    TwPanelActionButton,
    TwPopoverShort,
    TwPanelContent,
    TwPanelTitle,
    TwCircleLoader,
    TwPanelTabsFull,
    TwPanelContentBlock,
    PermissionDenied,
    MenuEntityActions,
    PersonDealList,
    PersonHipotecTab,
    PersonBlackListShow,
    PersonBlackListForm,
    PersonDocumentsTab,
    PersonShowMain,
    PersonMainForm
  },
  mixins: [CardMixin],
  props: {
    type: {
      type: [String, null],
      default: ''
    },
    searchedPhone: {
      type: String,
      default: ''
    },
    personObjectId: {
      type: [Number, null],
      default: null
    }
  },
  data () {
    return {
      API: API,
      currentTab: 'main',
      editBlackList: false, // статус состояния формы информации о черном списке
      confirmedTextTrue
    }
  },
  methods: {
    ...mapActions({
      showPanel: 'viewPanels/showPanel',
      isAdmin: 'profile/isAdmin'
    }),
    createLead () {
      this.showPanel({
        component: 'lead-show',
        props: { id: 0, workToClose: false, personId: this.data.id },
        label: 'Лид',
        callback: this.refresh
      })
    },
    refresh () {
      this.editBlackList = false
      // console.warn('refresh', this.$options.name, this.id, this.data, this.isNew)
      if (this.isNew) {
        this.editMain = true
      } else {
        this.fetch()
      }
    },
    mainIntro () {
      this.$store.dispatch('introTeach/showLesson', {
        options: MainLesson,
        steps: [
          {
            element: this.$refs.introMain1.$el
          },
          {
            element: this.$refs.introMain2.$el
          },
          {
            element: this.$refs.introMain3.$el
          },
          {
            element: this.$refs.introMain4.$el
          }
        ]
      })
    }
  },
  computed: {
    tabList () {
      return [
        {
          label: 'Общие',
          key: 'main'
        },
        {
          label: 'Документы',
          key: 'docs',
          hasBadge: this.data && Array.isArray(this.data.documents) && this.data.documents.length,
          disabled: this.isNew
        },
        {
          label: 'Доп. информация',
          key: 'otherInfo',
          disabled: this.isNew || !!this.personTypes?.isChildren
        },
        {
          label: 'Сделки',
          key: 'deals',
          disabled: this.isNew
        },
        {
          label: 'Страховки',
          key: 'insuranceObject',
          disabled: this.isNew || !this.isAdmin
        }
      ]
    },
    personTypes () {
      return {
        isChildren: this.data?.types?.includes('children') || this.type === 'children'
      }
    }
  },
  watch: {
    loading (newValue) {
      if (!newValue && this.$refs?.chronology?.fetch) {
        this.$refs.chronology.fetch()
      }
    }
  }
}
</script>
