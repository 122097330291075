<template>
  <div
    class="px-2 py-1 d-flex lighten-2 justify-space-between"
    :class="[confidenceQuality ? 'yellow':'red']"
  >
    <div
      v-if="visionValue"
      title="Распознано на изображении"
    >
      <small>
        {{ visionValue }}
      </small>
    </div>
    <div class="text-center">
      <span :title="`Процент качества распознания: ${confidenceQuality ? 'Хороший':'Низкое качество - проверьте ввод'}`">
        {{ confidencePercent }}%
      </span>
      <v-btn
        title="Применить распознанные данные"
        x-small
        text
        @click.stop="$emit('apply')"
      >
        Применить
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DocumentVisionFieldWarning',
  props: {
    visionValue: {
      type: String,
      default: ''
    },
    confidence: {
      type: Number,
      default: 0
    }
  },
  computed: {
    confidencePercent () {
      return (this.confidence && Math.floor(this.confidence * 100)) || 0
    },
    confidenceQuality () {
      return this.confidencePercent > 85 // good\bad
    }
  }
}
</script>

<style scoped>

</style>
