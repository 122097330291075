import { AxiosResponse } from 'axios'
import HTTP from '@/Api/http'

const path = '/deal/buy-nb/selection-filter'

export default {
  create (): Promise<AxiosResponse> {
    const $config = {}
    return HTTP.get(`${path}/create`, $config)
  },
  show (dealId: number): Promise<AxiosResponse> {
    const $config = {}
    return HTTP.get(`${path}/${dealId}`, $config)
  },
  change (dealId: number, data: any): Promise<AxiosResponse> {
    const $config = {}
    return HTTP.post(`${path}/${dealId}`, data, $config)
  }
}
