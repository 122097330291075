import { TBaseTableHeaderItem, tableItemStyleOneGen } from '@/components/tw-ui/table/TableTypes'

const options: TBaseTableHeaderItem[] = [
  {
    ...tableItemStyleOneGen(),
    text: 'Файлы',
    dataKey: 'gallery',
    colStyle: 'width: auto;'
  },
  {
    ...tableItemStyleOneGen(),
    text: 'Стоимость',
    dataKey: 'price',
    align: 'right'
  },
  {
    ...tableItemStyleOneGen(),
    text: 'Площадь',
    dataKey: 'planArea',
    align: 'right'
  },
  {
    ...tableItemStyleOneGen(false),
    text: 'Описание',
    dataKey: 'description',
    colStyle: 'width: auto;'
  },
  {
    ...tableItemStyleOneGen(),
    text: 'Дата изменения',
    dataKey: 'date'
  },
  {
    ...tableItemStyleOneGen(),
    text: 'Создатель',
    dataKey: 'creatorId'
  }
]

export default options
