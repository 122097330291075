<template>
  <div
    v-if="loading && !data"
    class="tw-h-full tw-w-full tw-flex tw-justify-center tw-items-center tw-py-5"
  >
    <TwCircleLoader
      height="50px"
      width="50px"
    />
  </div>
  <UserSurveysCreateMainForm
    v-else-if="isNew"
    @cancel="onCancelAndClose"
    @create="onCreated"
  />
  <div v-else>
    <div
      v-if="data"
      :class="fromPanel ? 'tw-px-[26px] tw-py-[18px]':''"
      class="tw-text-main-sm2 tw-max-w-[100%] tw-bg-white tw-min-h-screen"
    >
      <TwPanelTitle>
        <template #left>
          <div class="tw-flex tw-gap-[10px] tw-items-center">
            <div class="tw-text-h4">
              <span class="tw-font-bold">Карточка сотрудника</span>
              <span class="tw-text-an-flat-disabled-text tw-font-normal"> # {{ data.id }}</span>
            </div>
            <div class="tw-font-normal tw-text-main-sm tw-flex tw-gap-[8px] tw-items-center">
              <TwBooleanIcon :value="data.isFinished">
                <template #right>
                  {{ data.isFinished ? 'Полностью заполнена' : 'Частично заполнена, необходимо заполнить полностью' }}
                </template>
              </TwBooleanIcon>
            </div>
          </div>
        </template>
      </TwPanelTitle>
      <UserSurveysMainShow
        v-if="data"
        :info="data"
      />
      <TwPanelTabsFull
        v-if="data"
        v-model="currentTab"
        :items="tabList"
      >
        <template #content.main>
          <SurveyAnketaBlock
            :info="data"
            @refresh="refresh"
          />
        </template>
        <template #content.interview>
          <SurveyInterviewBlock
            :info="data"
            @refresh="refresh"
          />
        </template>
        <template #content.deals>
          <SurveyDealsList
            :info="data"
            @refresh="refresh"
          />
        </template>
      </TwPanelTabsFull>
    </div>
  </div>
</template>

<script>
import API from '@/Api/Review/RealtorSurvey'
import CardMixin from '@/mixins/CardMixin'
import TwPanelTitle from '@/components/tw-ui/panels/TwPanelTitle.vue'
import TwPanelTabsFull from '@/components/tw-ui/panels/TwPanelTabsFull.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import UserSurveysCreateMainForm from '@/views/Company/UserSurveys/main/UserSurveysCreateMainForm.vue'
import SurveyAnketaBlock from '@/views/Company/UserSurveys/Anketa/SurveyAnketaBlock.vue'
import TwBooleanIcon from '@/components/tw-ui/ui-kit/icons/TwBooleanIcon.vue'
import SurveyInterviewBlock from '@/views/Company/UserSurveys/Interview/SurveyInterviewBlock.vue'
import UserSurveysMainShow from '@/views/Company/UserSurveys/main/UserSurveysMainShow.vue'
import SurveyDealsList from '@/views/Company/UserSurveys/Deal/SurveyDealsList.vue'

export default {
  name: 'UserSurveysCard',
  components: {
    SurveyDealsList,
    UserSurveysMainShow,
    SurveyInterviewBlock,
    TwBooleanIcon,
    SurveyAnketaBlock,
    UserSurveysCreateMainForm,
    TwCircleLoader,
    TwPanelTabsFull,
    TwPanelTitle
  },
  mixins: [CardMixin],
  data () {
    return {
      API: API,
      currentTab: this.presetTab || 'main'
    }
  },
  computed: {
    tabList () {
      return [
        {
          label: 'Анкета',
          key: 'main',
          disabled: this.isNew || this.loading
        },
        {
          label: 'Интервью',
          key: 'interview',
          disabled: this.isNew || this.loading
        },
        {
          label: 'Сделки',
          key: 'deals',
          disabled: this.isNew || this.loading
        }
      ]
    }
  }
}
</script>
