<template>
  <TwPanelTabsFull
    v-model="currentTab"
    :items="tabList"
  >
    <template #content.DealBounceHandling>
      <DealBounceHandlingTab
        :deal-data="regroupDealData.mainDeal"
        @refresh="refreshDeal"
      />
    </template>
    <template #content.main>
      <DealGeneralTab
        :deal="regroupDealData.mainDeal"
        :deal-state="dealState"
        :dictionary="dictionary"
        :permissions="permissions"
        :is-new="isNew"
        :edit-main="editMain"
        :loading="loading"
        @changeEdit="editMain = $event"
        @refresh="refreshDeal"
        @create="refreshDeal"
        @update="refreshDeal"
      />
    </template>
    <template #content.client>
      <DealBuyNbClientTab :deal-data="dealData" />
    </template>
    <template #content.selection>
      <DealByNbSelectionTab :deal-data="dealData" />
    </template>
    <template #content.myCompanyDocs>
      <TwPanelContent>
        <div class="tw-flex-1">
          <DealBuyNbResultBlock
            :deal-data="dealData"
            :deal-state="dealState"
            @refresh="refreshDeal"
          />
        </div>
        <div class="tw-flex-1">
          <BuyNbResultMortgageBlock
            :deal-data="dealData"
            :deal-state="dealState"
            @refresh="refreshDeal"
          />
        </div>
      </TwPanelContent>
    </template>
    <template #content.files>
      <DealFilesTab
        :deal="regroupDealData.mainDeal"
        :dictionary="dictionary"
        :permissions="permissions"
        :is-new="isNew"
        :loading="loading"
      />
    </template>
  </TwPanelTabsFull>
</template>

<script>
import TwPanelTabsFull from '@/components/tw-ui/panels/TwPanelTabsFull.vue'
import DealGeneralTab from '@/views/Deal/TypeViews/DealGeneralTab'
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import DealByNbSelectionTab from '@/views/Deal/TypeViews/buyNb/Selection/DealByNbSelectionTab'
import DealFilesTab from '@/views/Deal/TypeViews/DealFilesTab.vue'
import { mapGetters } from 'vuex'
import DealBuyNbClientTab from '@/views/Deal/TypeViews/buyNb/Client/DealBuyNbClientTab.vue'
import DealBuyNbResultBlock from '@/views/Deal/TypeViews/buyNb/Result/DealBuyNbResultBlock.vue'
import DealBounceHandlingTab from '@/views/Deal/DealBounceHandling/DealBounceHandlingTab.vue'
import dictPermissionRules from '@/Permissions/dictRules'
import TwPanelContent from '@/components/tw-ui/panels/TwPanelContent.vue'
import BuyNbResultMortgageBlock from '@/views/Deal/TypeViews/buyNb/ResultMortgage/BuyNbResultMortgageBlock.vue'

export default {
  name: 'DealBuyNb',
  components: {
    BuyNbResultMortgageBlock,
    TwPanelContent,
    DealBounceHandlingTab,
    DealBuyNbResultBlock,
    DealBuyNbClientTab,
    DealFilesTab,
    DealByNbSelectionTab,
    TwPanelTabsFull,
    DealGeneralTab
  },
  mixins: [ViewPanelShowMixin],
  props: {
    dictionary: {
      type: Object,
      default: () => {
        return {}
      }
    },
    dealState: {
      default: null
    },
    permissions: {
      type: Object,
      default: () => {
        return {}
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    isNew: {
      type: Boolean,
      default: false
    },
    presetTab: {
      type: String,
      default: null
    },
    dealData: {
      required: true
    }
  },
  data () {
    return {
      updateProcessing: false,
      currentTab: this.presetTab || 'main',
      editMain: false
    }
  },
  methods: {
    currentTabChange (tabKey) {
      console.warn('currentTabChange', tabKey)
      this.currentTab = tabKey || 'main'
    },
    refreshDeal (v) {
      this.editMain = false
      this.$emit('refresh', v)
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'profile/isAdmin',
      allowNamespacesDict: 'profile/allowNamespacesDict'
    }),
    allowDealBounceHandling () {
      return this.isAdmin || !!this.allowNamespacesDict[dictPermissionRules.AppServicePermissionRulesCRMDealsDealBounceHandingRule]
    },
    tabList () {
      return [
        {
          label: 'Общие',
          key: 'main'
        },
        {
          label: 'Клиент',
          key: 'client',
          disabled: this.isNew || this.loading || this.updateProcessing
        },
        {
          label: 'Подбор',
          key: 'selection',
          disabled: this.isNew || this.loading || this.updateProcessing
        },
        {
          label: 'Документы компании',
          key: 'myCompanyDocs',
          disabled: this.isNew || this.loading || this.updateProcessing
        },
        {
          label: 'Файлы',
          key: 'files',
          disabled: this.isNew || this.loading || this.updateProcessing
        },
        {
          label: 'Работа с отказом',
          key: 'DealBounceHandling',
          disabled: this.isNew || this.loading || this.updateProcessing || !this.allowDealBounceHandling,
          hidden: !this.allowDealBounceHandling
        }
      ]
    },
    regroupDealData () {
      if (this.dealData && Object.keys(this.dealData).length) {
        const { mortgageDeal = {}, ...mainDeal } = this.dealData // отделяю заявку и заявку ипотека
        const { programs = [], id, ...variantFilters } = mortgageDeal || {} // исключаю лишнее оставляю только фильтры
        console.log(this.$options.name, id, programs)
        // добавляю к фильтрам значения из основной Заявки - TODO клиентов добавлять не нужно??? Ибо тут не создание а обновление
        Object.assign(variantFilters || {}, {
          personId: mainDeal.person.id,
          otherPersons: Array.isArray(mainDeal.otherPersons) && mainDeal.otherPersons.length ? mainDeal.otherPersons.map(c => c.id) : []
        })
        return {
          mainDeal,
          mortgageDeal,
          variantFilters
        }
      }
      return null
    }
  },
  watch: {
    // при переходах между вкладками отключаю редактирование
    currentTab () {
      this.editMain = false
    }
  }
}
</script>
