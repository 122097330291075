import { RouteKeys } from '@/router/types'

export default {
  path: `${RouteKeys.messengerPlatformAccount}`,
  name: RouteKeys.messengerPlatformAccount,
  redirect: { name: `${RouteKeys.messengerPlatformAccount}-list` },
  meta: { name: 'Платформы', icon: 'books-messenger-platform' },
  component: () => import('@/views/Dictionary/Index.vue'),
  children: [
    {
      path: 'list',
      name: `${RouteKeys.messengerPlatformAccount}-list`,
      meta: { name: 'Список платформ', icon: 'books-messenger-platform', hideInMenu: true },
      component: () => import('@/views/Dictionary/Messenger/PlatformAccount/List.vue')
    },
    {
      path: ':id/edit',
      name: `${RouteKeys.messengerPlatformAccount}-edit`,
      meta: { name: 'Редактирование', icon: 'books-messenger-platform', hideInMenu: true },
      component: () => import('@/views/Dictionary/Messenger/PlatformAccount/Edit.vue'),
      props: (route:any) => ({ id: route.params.id })
    },
    {
      path: 'create',
      name: `${RouteKeys.messengerPlatformAccount}-create`,
      meta: { name: 'Добавление', icon: 'books-messenger-platform', hideInMenu: true },
      component: () => import('@/views/Dictionary/Messenger/PlatformAccount/Edit.vue'),
      props: (route:any) => ({ id: route.params.id })
    }
  ]
}
