import { AxiosResponse } from 'axios'
import HTTP from '@/Api/http'

const path = '/mortgage/domclick/products'

export default {
  getList (): Promise<AxiosResponse> {
    return HTTP.get(`${path}`)
  },
  select (data: object): Promise<AxiosResponse> {
    // Получение списка программ при помощи POST запроса
    return HTTP.post(`${path}/select`, data)
  }
}
