<template>
  <div>
    <div class="tw-mb-[20px] tw-flex tw-justify-between">
      <div class="tw-text-main-md tw-font-semibold">
        Подразделения / Должности
      </div>
      <TwPanelActionButton
        color="green"
        @click.prevent="addNewElem"
      >
        Добавить
      </TwPanelActionButton>
    </div>
    <div
      v-if="value.length"
      class="tw-flex tw-flex-col tw-gap-[12px]"
    >
      <div
        v-for="(item, index) in value"
        :key="index+'-row-'+item.id"
      >
        <div class="tw-flex tw-gap-[8px]">
          <div>
            <div class="">
              <TwStructureSelect
                v-model="item.structureId"
                :disabled="false"
                :dense="true"
              />
            </div>
          </div>
          <div>
            <div class="">
              <TwPositionSelect
                v-model="item.positionId"
                :disabled="false"
                :dense="true"
              />
            </div>
          </div>
          <div>
            <TwPanelActionButton
              color="red"
              class="tw-mt-[28px]"
              @click.prevent="removeElem(index)"
            >
              ×
            </TwPanelActionButton>
          </div>
        </div>
      </div>
    </div>
    <template v-else>
      <div class="tw-text-center tw-p-2 tw-text-an-flat-disabled-text">
        Не добавлены
      </div>
    </template>
  </div>
</template>

<script>
import TwPositionSelect from '@/components/filters/twfilters/fields/custom-inputs/TwPositionSelect.vue'
import TwStructureSelect from '@/components/filters/twfilters/fields/custom-inputs/TwStructureSelect.vue'
import TwPanelActionButton from '@/components/tw-ui/panels/TwPanelActionButton.vue'

export default {
  name: 'InputUserStructures',
  components: { TwPositionSelect, TwStructureSelect, TwPanelActionButton },
  props: {
    value: {
      default: () => []
    },
    rules: {
      default: () => []
    },
    errorMessages: {
      default: () => []
    }
  },
  data () {
    return {}
  },
  methods: {
    addNewElem () {
      let value = this.value
      if (!Array.isArray(value)) {
        value = []
      }
      value.push(
        {
          id: null,
          // userId: null,
          structureId: null,
          positionId: null
        }
      )
      this.onChange(value)
    },
    removeElem (index) {
      const value = this.value
      console.log('removeElem', index, value)
      value.splice(index, 1)
      this.onChange(value)
    },
    onChange (value) {
      this.$emit('input', value)
    }
  }
}
</script>
