<template>
  <div class="tw-font-manrope tw-text-main-sm2 tw-max-w-[100%] tw-px-[26px] tw-py-[18px] tw-bg-white tw-min-h-screen">
    <div
      v-if="loading && !data"
      class="tw-py-3 tw-w-full tw-min-h-screen tw-flex tw-justify-center tw-items-center"
    >
      <TwCircleLoader
        width="50px"
        height="50px"
      />
    </div>
    <template v-else-if="data">
      <TwPanelTitle>
        <template #left>
          <div class="tw-text-h4 tw-font-bold">
            Закрытие периода
            <span v-if="data.year && data.month">
              {{ data.month }}/{{ data.year }}
            </span>
            <span
              v-if="isNew"
              class="tw-text-an-flat-disabled-text tw-font-medium"
            >Новое</span>
            <span
              v-else-if="data && data.id"
              class="tw-text-an-flat-disabled-text tw-font-medium"
            ># {{ data.id }}</span>
          </div>
        </template>
        <template #right>
          <TwPanelActionButton
            v-if="data && !data.blocked"
            color="red"
            :title="isAllowBasePermissions ? '' : 'Недостаточно прав для закрытия'"
            :disabled="loading || !isAllowBasePermissions"
            @click.stop="visibleBlockDialog = true"
          >
            Закрыть отчетный период
          </TwPanelActionButton>
          <div
            v-else
            class="tw-text-an-flat-gray-4 tw-uppercase tw-text-h6"
          >
            Отчётный период закрыт
          </div>
        </template>
      </TwPanelTitle>
      <TwPanelTabs>
        <button class="tw-bg-white tw-rounded-sm tw-px-[14px] tw-py-[8px]">
          Общая информация
        </button>
      </TwPanelTabs>
      <TwPanelContent>
        <div class="tw-w-full">
          <TwPanelContentBlock class="tw-mb-[6px]">
            <template #title>
              <div>
                О периоде
              </div>
            </template>
            <template #action>
              <TwPanelContentBlockActionButton
                v-if="isNew"
                :disabled="isNew || loading"
                class="tw-text-main-xs enabled:hover:tw-text-an-flat-dark-blue-link"
                @click="editMain = !editMain"
              >
                {{ editMain ? 'отменить':'изменить' }}
              </TwPanelContentBlockActionButton>
            </template>
            <div>
              <CompanyPeriodResultManagementMainShow
                :dictionary="dictionary"
                :info="data"
                @refresh="refresh"
              />
            </div>
          </TwPanelContentBlock>
        </div>
        <div class="tw-w-full">
          <TwPanelInfoBlock
            v-if="data.blocked && data.amount !== resultsTotalAll"
            type="warning"
            class="tw-mb-[6px]"
          >
            <template #title>
              Внимание!
            </template>
            <template #text>
              <div>
                Зафиксированная сумма(<b>{{ (data.amount || 0).toLocaleString('ru') }}</b>) периода отличается от общей суммы результатов периода(<b>{{ (resultsTotalAll || 0).toLocaleString('ru') }}</b>)
              </div>
            </template>
          </TwPanelInfoBlock>
        </div>
        <div
          v-if="!isNew"
          class="tw-w-full"
        >
          <CompanyPeriodResultManagementResultsBlock
            ref="resultsBlock"
            :info="data"
            :is-allow-base-permissions="isAllowBasePermissions"
            :is-allow-admin-permissions="isAllowAdminPermissions"
            @refresh="refresh"
          />
        </div>
      </TwPanelContent>

      <!--  Диалог - закрытия периода   -->
      <CompanyPeriodResultManagementBlockDialog
        v-if="data && $refs.resultsBlock"
        v-model="visibleBlockDialog"
        :period-id="data.id"
        :results-total-all="$refs.resultsBlock.resultsTotalAll"
        @refresh="refresh"
      />
    </template>
  </div>
</template>

<script>
import API from '@/Api/Crm/Deal/BuyNb/CompanyPeriodResult'
import CardMixin from '@/mixins/CardMixin'
import TwPanelTitle from '@/components/tw-ui/panels/TwPanelTitle.vue'
import TwPanelTabs from '@/components/tw-ui/panels/TwPanelTabs.vue'
import TwPanelContent from '@/components/tw-ui/panels/TwPanelContent.vue'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import { mapGetters } from 'vuex'
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import CompanyPeriodResultManagementMainShow
  from '@/views/Newbuildings/CompanyPeriodResultManagement/main/CompanyPeriodResultManagementMainShow.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import TwPanelActionButton from '@/components/tw-ui/panels/TwPanelActionButton.vue'
import CompanyPeriodResultManagementResultsBlock
  from '@/views/Newbuildings/CompanyPeriodResultManagement/results/CompanyPeriodResultManagementResultsBlock.vue'
import TwPanelContentBlockActionButton from '@/components/tw-ui/panels/TwPanelContentBlockActionButton.vue'
import CompanyPeriodResultManagementBlockDialog
  from '@/views/Newbuildings/CompanyPeriodResultManagement/CompanyPeriodResultManagementBlockDialog.vue'
import dictPermissionRules from '@/Permissions/dictRules'
import TwPanelInfoBlock from '@/components/tw-ui/panels/TwPanelInfoBlock.vue'

export default {
  name: 'CompanyPeriodResultManagementCard',
  components: {
    TwPanelInfoBlock,
    CompanyPeriodResultManagementBlockDialog,
    TwPanelContentBlockActionButton,
    CompanyPeriodResultManagementResultsBlock,
    TwPanelActionButton,
    TwCircleLoader,
    CompanyPeriodResultManagementMainShow,
    TwPanelContentBlock,
    TwPanelContent,
    TwPanelTabs,
    TwPanelTitle
  },
  mixins: [CardMixin, ViewPanelShowMixin],
  data () {
    return {
      API: API || null,
      visibleBlockDialog: false
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'profile/isAdmin',
      allowNamespacesDict: 'profile/allowNamespacesDict'
    }),
    isAllowBasePermissions () {
      return this.isAdmin || !!this.allowNamespacesDict[dictPermissionRules.AppServicePermissionRulesCRMDealsNbDealNbCompanyPeriodResultManagementRule]
    },
    isAllowAdminPermissions () {
      return this.isAdmin || !!this.allowNamespacesDict[dictPermissionRules.AppServicePermissionRulesCRMDealsNbDealNbCompanyPeriodResultAdminRule]
    },
    resultsTotalAll () {
      return Array.isArray(this.data?.results) && this.data.results.length ? this.data.results.reduce((total, item) => {
        return (item?.commission || 0) + total
      }, 0) : 0
    }
  }
}
</script>
