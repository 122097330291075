const defaultImage = function () {
  return require('@/assets/images/no_image.svg')
}

export const getFileResizeByKey = (fileObj: any, resizeKey = 'default') => {
  // console.warn('HELPER::getFileResizeByKey', resizeKey, fileObj)
  if (fileObj?.resize) {
    return fileObj?.resize[resizeKey] || fileObj?.url
  }
  return fileObj?.url ? fileObj?.url : defaultImage()
}

export const getImageResizeByKey = (fileObj: any, isNullable = true, resizeKey = 'default') => {
  // console.warn('HELPER::getFileResizeByKey', resizeKey, fileObj)
  return fileObj?.resize[resizeKey] || (isNullable ? null : defaultImage())
}
