<template>
  <div class="tw-w-full">
    <ImageHighlightEditor
      v-if="planImageInfo"
      :houses="complexImage.houses"
      :image="planImageInfo"
      :buildings-list="buildingsList"
      :complex-id="complexImage.complexId"
      :editing="editing"
      @submitCoordsHouses="submitUpdateHousesCoordinates"
    />
    <TwDialog
      v-model="statusModal"
      max-width="600px"
    >
      <template #header>
        <b class="tw-text-[24px] tw-text-center tw-block tw-px-[16px] tw-pt-[24px] tw-pb-[16px]">Данные успешно сохранены!</b>
      </template>
    </TwDialog>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { API } from '@/Api/ObjectStorage'
import ImageHighlightEditor from '@/views/Newbuildings/Complex/highlight-buildings-plan/ImageHighlightEditor.vue'
import TwDialog from '@/components/tw-ui/modal/TwDialog.vue'

export default defineComponent({
  name: 'RealtyComplexHighlightForm',
  components: { TwDialog, ImageHighlightEditor },
  props: {
    complexImage: {
      type: Object,
      default: null
    },
    editing: {
      type: Boolean,
      default: false
    },
    buildingsList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      fileApi: API.File,
      planImageInfo: null,
      statusModal: false
    }
  },
  mounted () {
    this.getImageInfo()
  },
  methods: {
    getImageInfo () {
      this.fileApi.show(this.complexImage.fileId).then(({ data: result }) => {
        console.warn('getImageInfo', result.data)
        this.planImageInfo = result.data || null
      })
    },
    submitUpdateHousesCoordinates (eventData) {
      console.warn('submitUpdateHousesCoordinates: ', eventData)
      API.ComplexHighlight.updateHousesCoordinates({
        complexId: this.complexImage.complexId,
        fileId: this.complexImage.fileId,
        imageId: this.complexImage.imageId,
        houses: eventData.filter(house => Array.isArray(house.coordinates) && house.coordinates.length)
      }).then(() => {
        this.statusModal = true
      })
    }
  }
})
</script>

<style scoped>

</style>
