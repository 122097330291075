<template>
  <div
    v-if="favorites"
    class="tw-w-full"
  >
    <div class="tw-relative tw-mb-[26px] tw-flex tw-gap-[15px] tw-justify-start tw-items-center">
      <button
        class="tw-z-[1] tw-py-[12px] tw-text-main-sm2 tw-font-bold tw-text-an-flat-green-apartment tw-border-b-[3px] tw-border-solid tw-border-an-flat-green-apartment"
        title="Обновить список избранного"
        @click.prevent="refresh"
      >
        Избранные объекты ({{ Array.isArray(favorites.list) && favorites.list.length }})
      </button>
      <button
        class="tw-z-[1] tw-py-[12px] tw-text-main-sm2 tw-text-an-flat-disabled-text tw-border-b-[3px] tw-border-solid tw-border-transparent"
        disabled
        @click.prevent="refresh"
      >
        Сравнить избранное
      </button>
      <div class="tw-absolute tw-z-[0] tw-w-full tw-bottom-[1px] tw-left-0 tw-border-b tw-border-solid tw-border-an-flat-disabled-text" />
    </div>
    <div
      v-if="favorites.processing"
      class="tw-p-[10px] tw-flex tw-justify-center"
    >
      <TwCircleLoader />
    </div>
    <div
      v-else-if="Array.isArray(favorites.list) && favorites.list.length"
      class="tw-container tw-grid tw-gap-[15px] tw-grid-cols-[repeat(auto-fill,_minmax(250px,_1fr))]"
    >
      <NewBuildingSelectionFavoriteBlockItem
        v-for="favorite in favorites.list"
        :key="favorite.id"
        :deal-id="dealId"
        :item="favorite"
        @un-favorite="onDeleteFavorite"
      />
    </div>
    <div
      v-else-if="favorites.error"
      class="tw-bg-an-flat-red-main tw-text-white"
    >
      {{ favorites.error }}
    </div>
    <div
      v-else
      class="tw-p-[10px] tw-text-center tw-text-an-flat-disabled-text"
    >
      Пусто
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import NewBuildingSelectionFavoriteBlockItem
  from '@/views/Newbuildings/Selection/NbComplex/NewBuildingSelectionFavorite/NewBuildingSelectionFavoriteBlockItem.vue'

export default defineComponent({
  name: 'NewBuildingSelectionFavoriteBlock',
  components: { NewBuildingSelectionFavoriteBlockItem, TwCircleLoader },
  props: {
    dealId: {
      type: Number,
      default: null
    }
  },
  methods: {
    ...mapActions({
      fetchFavoriteList: 'favorite/dealObjects/fetchFavoriteList',
      addFavorite: 'favorite/dealObjects/addFavorite',
      deleteFavorite: 'favorite/dealObjects/deleteFavorite'
    }),
    refresh () {
      this.fetchFavoriteList({ dealId: this.dealId })
    },
    onDeleteFavorite (favoriteId) {
      this.deleteFavorite({
        dealId: this.dealId,
        id: favoriteId
      })
    }
  },
  computed: {
    ...mapGetters({
      getFavoritesByDealId: 'favorite/dealObjects/getFavoritesByDealId'
    }),
    favorites () {
      return this.getFavoritesByDealId(this.dealId)
    }
  }
})
</script>

<style scoped>

</style>
