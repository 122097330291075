import FileDrop from '@/components/input/file/FileDrop'

export default {
  components: { FileDrop },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    fileType: {
      type: [String, null],
      default: null
    },
    fileListMaxHeight: {
      type: String,
      default: '300px'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '150px'
    },
    label: {
      type: String,
      default: 'Загрузить файл'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: true
    },
    isPublic: {
      type: Boolean,
      default: false
    },
    noDataText: {
      type: [String, Boolean],
      default: 'Нет загруженных файлов'
    },
    api: {
      type: Object
    }
  },
  data () {
    return {
      API: null,
      loadingProgress: false,
      uploadProgress: false,
      files: [],
      loadingTypes: false,
      typesObj: null
    }
  },
  methods: {
    initValues: async function () {
      // console.warn('initValues', this.value, this.files)
      if (this.loadingProgress || this.uploadProgress) return
      this.loadingProgress = true
      // this.files = []
      // console.warn('initValues', this.value, this.files)
      const files = []
      if (Array.isArray(this.value)) {
        for (const fileId of this.value) {
          // console.warn('++++', fileId, this.value, this.files)
          const fileData = this.files.find(f => f.fileServer?.id === fileId)
          if (fileData) {
            files.push({
              error: false,
              loading: false,
              fileInput: null,
              fileServer: fileData.fileServer
            })
          } else {
            await this.API.show(fileId)
              .then(({ data }) => {
                // console.warn('initValues', data.data)
                if (data?.data) {
                  files.push({
                    error: false,
                    loading: false,
                    fileInput: null,
                    fileServer: data.data
                  })
                }
              })
              .catch((error) => {
                console.error(error, this.$options.name, 'initValues', fileId)
                files.push({
                  error: true,
                  loading: false,
                  fileInput: null,
                  fileServer: null
                })
              })
          }
        }
      }
      this.files = files
      this.loadingProgress = false
    },
    download (file) {
      this.API.download(file.url, file.name)
    },
    viewImages (files, index) {
      this.$store.dispatch('imageViewer/showImages', {
        files: files.filter(f => f.fileServer.mime.indexOf('image') !== -1).map((f) => {
          return {
            path: f.fileServer.url
          }
        }),
        startIndex: index
      })
    },
    filesSelected (fileList) {
      // console.log('filesSelected', fileList)
      for (const index in Array.from(fileList)) {
        // console.log('fileList', fileList[index])
        this.files.push({
          error: false,
          loading: false,
          fileInput: fileList[index],
          fileServer: null
        })
      }
      this.$nextTick(this.runUploadWork)
    },
    async runUploadWork () {
      // console.warn('runUploadWork', this.files)
      this.uploadProgress = true
      for (const index in this.files) {
        if (!this.files[index].fileServer) {
          await this.upload(this.files[index].fileInput, index)
        }
      }
      this.uploadProgress = false
      this.$nextTick(this.change)
    },
    upload (file, index) {
      this.files[index].loading = true
      // console.log(this.$options.name, 'upload', index, file)
      const formData = new FormData()
      formData.append('file', file)
      // может быть присвоен тип например: floorPlan || passport_1
      if (this.fileType) {
        formData.append('type', this.fileType)
      }
      const targetUploader = this.isPublic && this.API.uploadPublic ? this.API.uploadPublic : this.API.upload
      return targetUploader(formData)
        .then(({ data }) => {
          // console.warn(this.$options.name, data)
          this.files[index].fileServer = data.data
          // console.log('link: ', data.data.url)
        })
        .catch((error) => {
          console.warn(this.$options.name, 'upload ERROR', index, error)
          if (error?.response?.data) {
            if (error.response.data?.file) {
              this.files[index].error = error.response.data?.file
            } else {
              this.files[index].error = error.toString()
            }
          } else {
            this.files[index].error = true
          }
        })
        .finally(() => {
          this.files[index].loading = false
        })
    },
    change () {
      this.$emit('input', this.files.map(f => f?.fileServer?.id || null))
    },
    imageLoadError (index) {
      if (this.files[index]) {
        this.files[index].error = true
      }
    },
    removeFile (index) {
      this.files.splice(index, 1)
      this.$nextTick(this.change)
    }
  },
  computed: {
    typeInfo () {
      return this.fileType && this.typesObj ? this.typesObj[this.fileType] : null
    }
  },
  watch: {
    value: {
      handler () {
        this.initValues()
      },
      immediate: true
    }
  },
  create () {
    // Меняю API если пришел
    if (this.api) {
      this.$set(this, 'API', this.api)
    }
  },
  mounted () {
    // Проверяю есть ли метод получения типов для данного АПИ
    if (this.api.types) {
      this.loadingTypes = true
      this.api.types().then(({ data }) => {
        if (data?.data) {
          this.typesObj = data.data
        }
      }).catch(() => {
        this.typesObj = null
      }).finally(() => {
        this.loadingTypes = false
      })
    }
  }
}
