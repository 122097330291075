import Vue from 'vue'
import { Module } from 'vuex'
import { RootState } from '@/store/types'
import { TmpCacheState } from '../types'
import { API } from '@/Api/Telephony'
import store from '@/store/index'

const namespaced = true

export const telephony: Module<TmpCacheState, RootState> = {
  namespaced,
  state: {
    calls: {}
  },
  actions: {
    fetchCallList: function ({ commit }, search: string) {
      return API.Call.getList({ search, limit: 150 })
        .then(({ data }: any) => {
          // console.log('fetchCallList', data.data)
          commit('SET_CALLS', data.data)
          return data?.data || []
        })
        .catch((error: any) => {
          console.error(error)
        })
    },
    fetchCallById: function ({ commit, state }, id: number | string, isUpdate = false) {
      // Если данные есть и обновление не нужно не делаю запрос
      if (!isUpdate && state.calls[id]) {
        return
      }
      commit('SET_CALLS', { id })
      return API.Call.show(id)
        .then(({ data }: any) => {
          // console.log('fetchCallById', data.data)
          commit('SET_CALLS', data.data)
        })
        .catch((error: any) => {
          console.error(error)
        })
    }
  },
  mutations: {
    SET_CALLS (state, payload) {
      // console.log('SET_CALLS', payload)
      // параметр времени запроса
      const reqTime = new Date().getTime()
      if (Array.isArray(payload)) {
        payload.forEach((item) => {
          item.rt = reqTime
          Vue.set(state.calls, item.id, item)
        })
      } else if (payload.id) {
        payload.rt = reqTime
        Vue.set(state.calls, payload.id, payload)
      } else {
        console.error('Необработанное условие при попытке добавить Calls в tmpCache')
      }
    }
  },
  getters: {
    getCallById: (state) => (id: number) => {
      if (state.calls[id] === undefined) {
        store.dispatch('tmpCache/telephony/fetchCallById', id)
      }
      return state.calls[id] || null
    },
    cachedCallsList: (state) => {
      return Object.values(state.calls) || []
    }
  }
}
