<template>
  <div
    :id="uniqId"
    class="tw-font-manrope tw-text-main-sm tw-relative"
    :style="{ zIndex }"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <transition
      enter-active-class="tw-ease-out tw-duration-300"
      enter-from-class="tw-transform tw-opacity-0"
      enter-to-class="tw-opacity-100"
      leave-active-class="tw-ease-in tw-duration-200"
      leave-from-class="tw-opacity-100"
      leave-to-class="tw-transform tw-opacity-0"
    >
      <div
        v-if="isVisible"
        class="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity"
      />
    </transition>

    <div
      v-show="isVisible"
      class="tw-fixed tw-inset-0 tw-z-10 tw-overflow-y-hidden"
    >
      <div class="tw-flex tw-min-h-full tw-items-end tw-justify-center tw-p-4 tw-text-center sm:tw-items-center sm:tw-p-0">
        <transition
          enter-active-class="tw-ease-out tw-duration-300"
          enter-from-class="tw-transform tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
          enter-to-class="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
          leave-active-class="tw-ease-in tw-duration-200"
          leave-from-class="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
          leave-to-class="tw-transform tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
        >
          <div
            v-if="isVisible"
            :style="{ maxWidth: maxWidth }"
            :class="paddingClass"
            class="tw-relative tw-transform tw-overflow-hidden tw-text-left tw-shadow-xl tw-transition-all tw-bg-an-flat-bg-main tw-rounded-2xl sm:tw-my-8 sm:tw-w-full sm:tw-max-w-[90vw]"
          >
            <button
              v-if="isCloseBtn"
              :disabled="disableClose"
              class="tw-absolute tw-z-20 tw-top-0 tw-right-0 tw-pr-[20px] tw-pt-[20px] tw-group hover:tw-text-an-flat-red-main"
              @click.stop="onClose"
            >
              <TwIconsFunctionalPack
                name="close"
                size="30"
              />
            </button>
            <slot name="content">
              <div class="tw-max-h-[80vh] tw-overflow-y-auto">
                <div class="tw-z-10 tw-sticky tw-top-0 tw-bg-an-flat-bg-main tw-pb-[15px]">
                  <slot name="header" />
                </div>
                <div class="tw-relative">
                  <slot name="body" />
                </div>
                <div class="tw-sticky tw-bottom-0">
                  <slot name="footer" />
                </div>
              </div>
            </slot>
            <div>
              <slot
                name="actions"
                v-bind="{ onClose }"
              />
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import TwIconsFunctionalPack from '../other/TwIconsFunctionalPack.vue'

export default {
  name: 'TwDialog',
  components: { TwIconsFunctionalPack },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    isCloseBtn: {
      type: Boolean,
      default: true
    },
    disableClose: {
      type: Boolean,
      default: false
    },
    zIndex: {
      type: Number,
      default: 10
    },
    maxWidth: {
      type: String
    },
    paddingClass: {
      type: String,
      default: 'tw-px-[46px] tw-py-[26px]'
    }
  },
  data () {
    return {
      // Локальная видимость - для того чтобы можно было использовать компонент без передачи value и вызывать через ref
      isVisible: false,
      tmpDocOverflow: ''
    }
  },
  computed: {
    uniqId () {
      return `${this.$options.name}-${this._uid}`
    }
  },
  watch: {
    value (newValue) {
      if (newValue) {
        this.onOpen()
      } else {
        this.onClose()
      }
    }
  },
  beforeDestroy () {
    this.removeElFromDom()
  },
  mounted () {
    // Нужно для hot-reload режима разработки
    if (this.$el && this.value) {
      document.querySelector('body').appendChild(this.$el)
    }
  },
  methods: {
    onOpen () {
      this.isVisible = true
      this.tmpDocOverflow = document.documentElement?.style?.overflow || ''
      document.documentElement.style.overflow = 'hidden'
      document.querySelector('body').appendChild(this.$el)
      this.$emit('input', true)
      this.$emit('open')
      document.addEventListener('keydown', this.keyDownHandler)
    },
    onClose () {
      this.isVisible = false
      document.documentElement.style.overflow = this.tmpDocOverflow || ''
      this.removeElFromDom()
      this.$emit('input', false)
      this.$emit('close')
      document.removeEventListener('keydown', this.keyDownHandler)
    },
    removeElFromDom () {
      const removeEl = document.getElementById(this.uniqId)
      if (removeEl) {
        document.querySelector('body').removeChild(removeEl)
      }
    },
    keyDownHandler (e) {
      if (e.code === 'Escape') {
        this.$emit('close')
      }
    }
  }
}
</script>

<style scoped>

</style>
