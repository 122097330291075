<template>
  <div
    v-if="formProcessing"
    class="tw-p-[30px] tw-flex"
  >
    <TwCircleLoader class="tw-m-auto" />
  </div>
  <div v-else>
    <div style="display: none">
      {{ isEdited }}
    </div>
    <div class="">
      <div class="tw-flex tw-flex-col tw-gap-[5px]">
        <div>
          <label class="tw-text-an-flat-disabled-text">Код комплекса:</label>
          {{ preset.extComplex || '~' }}
        </div>
        <div>
          <label class="tw-text-an-flat-disabled-text">Код строения:</label>
          {{ preset.extBuilding || '~' }}
        </div>
        <div>
          <label class="tw-text-an-flat-disabled-text">Дата проверки файла:</label>
          {{ preset.inFileDate }}
        </div>
        <div>
          <label class="tw-text-an-flat-disabled-text">Результат проверки:</label>
          {{ preset.inFile ? 'Успех' : 'Ошибка' }}
        </div>
      </div>
      <div class="tw-mt-[20px] tw-flex tw-flex-col tw-gap-[12px]">
        <div>
          <TwSelectRelations
            v-model="form.complexId"
            :disabled="false"
            :dense="true"
            :persistent-label="true"
            :prefetch-request="true"
            placeholder="Не выбран"
            :error-text="form.complexId ? '' : '* Выберите ЖК'"
            label="Выбор ЖК"
            list-label="Выбрать ЖК"
            relation-name="complex"
            :default-filters="complexFilters"
          />
        </div>
        <div class="tw-flex tw-font-medium">
          ID объекта:
          <div class="tw-flex-1" />
          <TwPanelContentBlockActionButton
            :disabled="formProcessing || !form.complexId"
            type="add"
            @click="addBuilding"
          >
            + добавить строение
          </TwPanelContentBlockActionButton>
        </div>
        <div
          v-if="Array.isArray(form.buildingsIds) && form.buildingsIds.length"
          class="tw-flex tw-flex-col tw-gap-[10px]"
        >
          <div
            v-for="(buildingId, buildingIndex) in form.buildingsIds"
            :key="buildingIndex"
            class="tw-rounded-sm tw-border tw-border-solid tw-bg-an-flat-gray-8"
          >
            <div class="tw-flex tw-gap-[6px] tw-items-center tw-justify-center tw-px-[10px] tw-py-[15px]">
              <CustomBuildingSelector
                v-model="form.buildingsIds[buildingIndex]"
                class="tw-grow tw-min-w-[250px]"
                :disabled="!form.complexId"
                :dense="true"
                :error-text="form.complexId ? form.buildingsIds[buildingIndex] ? '' : '* Выберите Дом' : ''"
                :complex-id="form.complexId"
              />
              <TwPanelContentBlockActionButton
                :disabled="formProcessing || !form.complexId"
                type="delete"
                @click.prevent="removeBuilding(buildingIndex)"
              >
                удалить
              </TwPanelContentBlockActionButton>
            </div>
          </div>
        </div>
        <div
          v-else
          class="tw-text-center tw-text-an-flat-disabled-text"
        >
          Не добавлены
        </div>
      </div>
    </div>
    <div
      v-if="serverErrors && Object.keys(serverErrors).length"
      class="tw-text-an-flat-red-main"
    >
      {{ serverErrors }}
    </div>
    <TwCardSimpleFormSaveBlock
      class="tw-mt-[15px]"
      bg-color="transparent"
      :loading="formProcessing"
      :cancel-confirm="isEdited"
      :is-sticky="true"
      :disable-success="!isEdited || formProcessing"
      :disable-cancel="formProcessing"
      :visible="true"
      @success="handleSubmit"
      @cancel="handleCancel"
    />
  </div>
</template>

<script>
import { API } from '@/Api/BuilderImporter'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import TwSelectRelations from '@/components/tw-ui/ui-kit/input/select-relations/TwSelectRelations.vue'
import CustomBuildingSelector from '@/views/BuilderImporter/ImportDocumentFile/CustomBuildingSelector.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import TwPanelContentBlockActionButton from '@/components/tw-ui/panels/TwPanelContentBlockActionButton.vue'
import TwCardSimpleFormSaveBlock from '@/components/tw-ui/forms/TwCardSimpleFormSaveBlock.vue'

export default {
  name: 'BuilderFileComparisionForm',
  components: {
    TwPanelContentBlockActionButton,
    TwCircleLoader,
    CustomBuildingSelector,
    TwSelectRelations,
    TwCardSimpleFormSaveBlock
  },
  mixins: [FormCreateEditMixin],
  props: {
    disableRedirect: {
      type: Boolean,
      default: false
    },
    defaultFormFields: {
      type: Object,
      default: () => {
        return {}
      }
    },
    builderId: {
      type: [Number, String, null],
      default: null
    }
  },
  data () {
    return {
      API: API.ImportBuilderFileComparison,
      form: {
        complexId: null,
        buildingsIds: []
      },
      serverErrors: null
    }
  },
  computed: {
    complexFilters () {
      return {
        builderId: this.builderId ? [this.builderId] : []
      }
    }
  },
  methods: {
    addBuilding () {
      this.form.buildingsIds?.push('')
    },
    removeBuilding (index) {
      this.form.buildingsIds?.splice(index, 1)
    },
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          this.$set(this.form, 'id', this.preset?.id || null)
          this.$set(this.form, 'buildingsIds', this.preset?.buildingsIds || [])
          this.$set(this.form, 'fileId', this.preset?.fileId || null)
          this.$set(this.form, 'complexId', this.preset?.complexId || null)
        }
        resolve()
      })
    },
    handleSubmit () {
      if (this.formProcessing === true) {
        return
      }
      this.formProcessing = true
      this.serverErrors = {}
      const request = this.formType === 'create'
        ? this.API.store(this.form)
        : this.API.update(this.targetId, this.form)

      request.then(({ data }) => {
        if (this.formType === 'create') {
          this.$emit('create', data.data)
        } else {
          this.$emit('update', data.data)
        }
      }).catch((error) => {
        if (error?.response?.data) {
          console.error(this.$options.name, error.response.data)
          for (const key in error.response.data) {
            const [refKey, index] = key.split('.')
            // console.log(key, refKey, index, field)
            if (!this.serverErrors) this.serverErrors = {}
            if (!this.serverErrors[refKey]) this.serverErrors[refKey] = []
            this.serverErrors[refKey][index] = error.response.data[key][0]
          }
        }
      }).finally(() => {
        this.formProcessing = false
      })
    }
  }
}
</script>
