<template>
  <div
    class="tw-px-[16px] tw-pt-[11px] tw-pb-[13px] tw-rounded-md"
    :class="{ 'tw-bg-[#E9F9F2]' : leadId, 'tw-bg-[#FEF5F3]' : !leadId}"
  >
    <div>
      <div class="tw-flex tw-flex-row tw-items-center tw-justify-between">
        <b class="tw-block tw-text-[13px] tw-text-an-flat-black-main">Информация о Лиде:</b>
        <div
          v-if="leadId"
          class="tw-flex tw-flex-row tw-px-[10px] tw-py-[8px] tw-border-2 tw-border-solid tw-border-white tw-rounded-sm tw-text-an-flat-gray-5 tw-text-[12px] tw-font-semibold"
        >
          <span class="tw-flex tw-flex-row tw-mr-[5px]">
            ID лида:
            <ViewPanelLink
              :id="leadId"
              label="Лид"
              component="lead-show"
            >
              {{ leadId }}
            </ViewPanelLink>
          </span>
          <span class="tw-block tw-mr-[3px]">от</span>
          <span
            v-if="info && info.person"
            class="tw-block tw-mr-[16px]"
          >
            <ViewPanelLink
              :id="info.person.id"
              label="Контакт"
              component="person-show"
            >
              {{ $Global.peopleName(info.person, 'fi') }}
            </ViewPanelLink>
          </span>
          <button
            class="tw-flex tw-items-center"
            @click="clearLead"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="9"
              height="8"
              viewBox="0 0 9 8"
              fill="none"
            >
              <g clip-path="url(#clip0_1_50)">
                <path
                  d="M5.11915 4.00779L8.23419 0.892655C8.43692 0.690029 8.43692 0.362409 8.23419 0.159783C8.03157 -0.0428442 7.70395 -0.0428442 7.50132 0.159783L4.38619 3.27492L1.27114 0.159783C1.06842 -0.0428442 0.740898 -0.0428442 0.538272 0.159783C0.33555 0.362409 0.33555 0.690029 0.538272 0.892655L3.65331 4.00779L0.538272 7.12293C0.33555 7.32555 0.33555 7.65317 0.538272 7.8558C0.639253 7.95688 0.772028 8.00765 0.904708 8.00765C1.03739 8.00765 1.17007 7.95688 1.27114 7.8558L4.38619 4.74066L7.50132 7.8558C7.6024 7.95688 7.73508 8.00765 7.86776 8.00765C8.00044 8.00765 8.13312 7.95688 8.23419 7.8558C8.43692 7.65317 8.43692 7.32555 8.23419 7.12293L5.11915 4.00779Z"
                  fill="#E63114"
                />
              </g>
              <defs>
                <clipPath id="clip0_1_50">
                  <rect
                    width="9"
                    height="8"
                    fill="white"
                  />
                </clipPath>
              </defs>
            </svg>
          </button>
        </div>
        <div
          v-else
          class="tw-flex tw-flex-row tw-items-center tw-gap-[16px]"
        >
          <div class="tw-text-an-flat-red-main tw-text-[11px] tw-leading-none">
            Лид отсутствует
          </div>
          <button
            class="tw-transition-all tw-px-[13px] tw-py-[9px] tw-rounded-sm tw-bg-[#DDEBF7] tw-text-an-flat-dark-blue-link tw-font-semibold tw-text-[12px] tw-uppercase hover:tw-opacity-50 active:tw-opacity-70"
            @click="showLeadCard({ id: 0, workToClose: true }, showLeadCardCallback)"
          >
            Добавить Лид
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/Api/Crm/Lead'
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink'
export default {
  name: 'LeadInfoBlock',
  components: { ViewPanelLink },
  mixins: [ViewPanelShowMixin],
  props: {
    leadId: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      loading: false,
      info: null
    }
  },
  watch: {
    leadId: {
      handler (newValue) {
        newValue && this.fetchLeadInfo(newValue)
      },
      immediate: true
    }
  },
  methods: {
    showLeadCardCallback (data) {
      console.warn(this.$options.name, ' showLeadCardCallback ', data)
      console.warn('showLeadCard', data.data)
      this.$emit('leadCreate', data.data)
    },
    clearLead () {
      const newQuery = { ...this.$route.query, ...{ leadId: undefined } }
      console.warn('newQuery leadId', newQuery)
      this.$router.replace({ query: newQuery })
    },
    fetchLeadInfo () {
      this.loading = true
      API.show(this.leadId)
        .then(({ data }) => {
          console.log(data)
          this.info = data.data
        })
        .catch((error) => {
          console.error(error)
          this.info = null
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
