import { AxiosResponse } from 'axios'
import HTTP from '@/Api/http'
const path = '/person/insurance-object'

export default {
  getList (param?: object, otherConfig?: object): Promise<AxiosResponse> {
    const $config = {
      params: param,
      ...(otherConfig || {})
    }
    return HTTP.get(`${path}`, $config)
  },
  showInsuranceObject (id: number | string): Promise<AxiosResponse> {
    const $config = {}
    return HTTP.get(`${path}/${id}`, $config)
  }
}
