<template>
  <div class="tw-font-manrope tw-m-[32px]">
    <div class="tw-flex tw-gap-[10px] tw-items-center tw-justify-between tw-w-full tw-font-semibold tw-bg-an-flat-panel-modal-header-bg tw-rounded-sm tw-p-[5px]">
      <div class="tw-text-main-sm2 tw-font-semibold">
        <button class="tw-bg-white tw-p-[13px] tw-rounded-sm tw-whitespace-nowrap tw-border tw-border-solid tw-border-transparent hover:tw-border-white disabled:tw-opacity-30 disabled:hover:tw-border-transparent">
          {{ title }}
        </button>
      </div>
      <button
        class="tw-group tw-p-2"
        title="Закрыть"
        @click.stop="$emit('close-me')"
      >
        <svg
          width="16"
          height="17"
          viewBox="0 0 16 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            class="group-hover:tw-fill-an-flat-red-main"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1.69806 0.294442C1.3096 -0.0981475 0.679797 -0.0981475 0.291337 0.294442C-0.0971125 0.687023 -0.0971125 1.32353 0.291337 1.71611L6.51434 8.00531L0.291381 14.2945C-0.0970987 14.6871 -0.0971288 15.3236 0.291301 15.7162C0.679731 16.1087 1.30954 16.1087 1.69802 15.7161L7.92097 9.42689L14.144 15.7161C14.5325 16.1087 15.1623 16.1087 15.5507 15.7161C15.9392 15.3235 15.9392 14.687 15.5507 14.2944L9.32768 8.00521L15.5506 1.71606C15.9391 1.32346 15.9392 0.686954 15.5507 0.294394C15.1623 -0.0981661 14.5325 -0.0981264 14.144 0.294484L7.92106 6.58363L1.69806 0.294442Z"
            fill="#848C93"
          />
        </svg>
      </button>
    </div>
    <div class="tw-text-main-md tw-mt-[10px] tw-bg-white tw-rounded-[24px] tw-p-[31px] tw-w-full">
      <div v-html="descHtml" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalInformationShow',
  props: {
    id: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    descHtml: {
      type: String,
      default: null
    }
  }
}
</script>

<style scoped>

</style>
