<template>
  <div v-cloak>
    <div v-if="list && list.length">
      <CardBlockField
        v-for="child in list"
        :key="child.id"
        class="item-hovered-visible-block"
      >
        <template #title>
          <div class="d-flex justify-space-between align-center">
            {{ $Global.peopleName(child, 'fio') }}
            <div class="item-hidden-block">
              <v-btn
                class="font-weight-bold"
                x-small
                text
                color="primary"
                @click="onEditChild(child)"
              >
                Изменить
              </v-btn>
              <v-btn
                class="font-weight-bold"
                x-small
                text
                color="error"
                @click="onRemove(child)"
              >
                Удалить
              </v-btn>
            </div>
          </div>
        </template>
        <template #content>
          {{ $Global.calculateAge(child.birthDate) }}
          {{ $Global.textPlural($Global.calculateAge(child.birthDate), 'year') }}
          ({{ new Date(child.birthDate).toLocaleDateString() }})
        </template>
      </CardBlockField>
    </div>
    <div
      v-else
      class="text-muted text-center"
    >
      Нет данных
    </div>
  </div>
</template>

<script>
import API from '@/Api/Crm/Person/Relations'
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import CardBlockField from '@/components/core/ViewPanel/Card/CardBlockField'

export default {
  name: 'PersonFamilyChildren',
  components: { CardBlockField },
  mixins: [ViewPanelShowMixin],
  props: {
    parentId: {
      type: [String, Number],
      required: true
    },
    list: {
      type: Array,
      default: () => []
    },
    dictionary: {}
  },
  methods: {
    onEditChild (child) {
      this.showPersonChildrenCard({ id: child.id }, () => {
        this.$emit('refresh')
      })
    },
    onRemove (item) {
      const isAllow = confirm('Подтвердите удаление объекта!')
      if (isAllow) {
        const formData = {
          type: 'children',
          personObjectId: this.parentId,
          personSubjectId: item.id
        }
        API.delete(formData).finally(() => {
          this.$emit('refresh')
        })
      }
    }
  }
}
</script>
