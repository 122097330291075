import { AxiosResponse } from 'axios'
import HTTP from '@/Api/http'
import { AuthAxiosDefaultConfig } from '@/Api/ObjectStorage'

const path = '/complex/description'

export default {
  getList (param?: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig,
      params: param
    }
    return HTTP.get(`${path}`, $config)
  },
  create (): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.get(`${path}/create`, $config)
  },
  store (data: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.post(`${path}`, data, $config)
  },
  edit (id: number): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.get(`${path}/${id}`, $config)
  },
  update (id: number, data: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.put(`${path}/${id}`, data, $config)
  },
  show (id: number | string): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.get(`${path}/${id}`, $config)
  },
  delete (id: number): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.delete(`${path}/${id}`, $config)
  }
}
