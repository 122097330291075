import { AxiosResponse } from 'axios'
import HTTP from '@/Api/http'

const AuthAxiosDefaultConfig: any = {
  serviceKey: 'REALTY_MINER'
}

export default {
  getList (param?: any): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig,
      params: param
    }
    return HTTP.get('/infrastructure', $config)
  }
}
