import { AxiosResponse } from 'axios'
import HTTP from '@/Api/http'

const path = '/deal/mortgage/program'

export default {
  update (id: number, data: any): Promise<AxiosResponse> {
    return HTTP.put(`${path}/${id}`, data)
  },
  show (id: number): Promise<AxiosResponse> {
    const $config = {}
    return HTTP.get(`${path}/${id}`, $config)
  },
  edit (): Promise<AxiosResponse> {
    return new Promise((resolve: any) => resolve())
  },
  send2Bank (id: number, data: any): Promise<AxiosResponse> {
    return HTTP.post(`${path}/${id}/send2Bank`, data)
  },
  draftEmail (id: number, data: any): Promise<AxiosResponse> {
    return HTTP.post(`${path}/${id}/create_email`, data)
  },
  add (dealId: number, programId: number): Promise<AxiosResponse> {
    return HTTP.post(`${path}/add`, { dealId: dealId, id: programId })
  },
  list (dealId: number): Promise<AxiosResponse> {
    // TODO QUERY parametr
    return HTTP.get(`${path}/?dealId=${dealId}`)
  }
}
