import { Module } from 'vuex'
import { RootState } from '@/store/types'
import { FiltersState } from '@/store/filters/types'
import { state } from '@/store/filters/state'
import { actions } from '@/store/filters/actions'
import { getters } from '@/store/filters/getters'
import { mutations } from '@/store/filters/mutations'

const namespaced = true

/* eslint-disable import/prefer-default-export */
export const filters: Module<FiltersState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
