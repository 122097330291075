<template>
  <div>
    <div class="tw-w-full tw-font-manrope tw-text-main-sm tw-font-bold tw-text-white">
      <!--   Прелодер скелет   -->
      <div
        v-if="loading && !items.length"
        class="tw-flex tw-gap-[8px] tw-w-full tw-animate-pulse"
      >
        <div class="tw-flex-grow tw-rounded-sm tw-h-[30px] tw-bg-slate-200" />
        <div class="tw-flex-grow tw-rounded-sm tw-h-[30px] tw-bg-slate-200" />
        <div class="tw-flex-grow tw-rounded-sm tw-h-[30px] tw-bg-slate-200" />
        <div class="tw-flex-grow tw-rounded-sm tw-h-[30px] tw-bg-slate-200" />
        <div class="tw-flex-grow tw-rounded-sm tw-h-[30px] tw-bg-slate-200" />
      </div>
      <div
        v-else
        class="tw-flex tw-w-full"
      >
        <button
          v-for="(status, index) in statusList.any"
          :key="index"
          :disabled="disabled"
          :title="status.name"
          :class="highLighting.index === index ? '':'tw-truncate'"
          class="tw-group tw-flex tw-flex-grow tw-h-[30px] tw-bg-transparent"
          @click="status.local ? changeStatus(status) : confirmChangeStatus(status)"
          @mouseleave="initStatus"
          @mouseover="overStatus({status, index})"
        >
          <!--    Начало стрелки     -->
          <span
            class="tw-inline-flex group-first:tw-hidden"
            :style="{ backgroundColor: highLighting.index >= index ? highLighting.color:status.color }"
          >
            <span class="tw-block tw-h-[100%] tw-w-[8px] tw-border-t-[15px] tw-border-b-[15px] tw-border-l-[8px] tw-border-l-[#eef2f4] tw-border-b-transparent tw-border-t-transparent" />
            <span
              class="tw-block tw-h-[100%] tw-w-[8px] tw-border-t-[15px] tw-border-b-[15px] tw-border-l-[8px] tw-border-solid tw-border-t-[#eef2f4] tw-border-b-[#eef2f4] tw-border-transparent"
              :style="{ borderTopColor: highLighting.index >= index ? highLighting.color:baseItemBgColor, borderBottomColor: highLighting.index >= index ? highLighting.color:baseItemBgColor }"
            />
          </span>
          <!--    Текст на стрелке     -->
          <span
            class="tw-block tw-leading-[18px] tw-truncate tw-p-[6px] tw-w-full group-first:tw-rounded-tl-sm group-first:tw-rounded-bl-sm group-last:tw-rounded-tr-sm group-last:tw-rounded-br-sm"
            :style="{ backgroundColor: highLighting.index >= index ? highLighting.color:baseItemBgColor, color: highLighting.index >= index ? $Global.textColorFromBgColorHex(highLighting.color) : 'black' }"
          >
            {{ status.name }}
          </span>
          <!--    Конец стрелки     -->
          <span
            class="tw-inline-flex group-last:tw-hidden"
            :style="{ backgroundColor: highLighting.index >= index ? highLighting.color:status.color }"
          >
            <span
              class="tw-block tw-h-[100%] tw-w-[8px] tw-border-t-[15px] tw-border-b-[15px] tw-border-l-[8px] tw-border-l-[#eef2f4] tw-border-b-transparent tw-border-t-transparent"
              :style="{ borderLeftColor: highLighting.index >= index ? highLighting.color:baseItemBgColor }"
            />
            <span class="tw-block tw-h-[100%] tw-w-[8px] tw-border-t-[15px] tw-border-b-[15px] tw-border-l-[8px] tw-border-solid tw-border-t-[#eef2f4] tw-border-b-[#eef2f4] tw-border-transparent" />
          </span>
        </button>
      </div>
    </div>
    <FinalStatusSelectModal
      ref="finalSelectModal"
      :success-list="statusList.success"
      type="Deal"
      :deal-id="dealId"
      :lead-id="leadId"
      :fail-list="statusList.fail"
      @selectFinalStatus="confirmChangeStatus"
      @dealCreated="changeStatus"
    />
    <ChangeStatusConfirm
      ref="changeStatusConfirm"
      title="Подтверждение"
      :desc="confirmDesc"
      max-width="500px"
    />
  </div>
</template>

<script>
import uiListAPI from '@/Api/Crm/UI/List'
import dealAPI from '@/Api/Crm/Deal/index'
import FinalStatusSelectModal from '@/components/status/finalStatusSelectModal.vue'
import ChangeStatusConfirm from '@/components/status/changeStatusConfirm.vue'
export default {
  name: 'DealStatusProgress',
  components: { ChangeStatusConfirm, FinalStatusSelectModal },
  props: {
    leadId: {},
    dealId: {},
    currentStatus: {},
    disabled: {
      type: Boolean,
      default: false
    },
    dealType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      baseItemBgColor: '#EAF2FA',
      items: [],
      loading: false,
      highLighting: {
        index: 0,
        color: 'lightgrey'
      },
      confirmDesc: ''
    }
  },
  computed: {
    statusList () {
      let any = this.items?.filter(item => item.active && item.stage.value !== 'fail' && item.stage.value !== 'success') || []
      any = [...any, this.finallyStatus]
      const success = this.items?.filter(item => item.active && item.stage.value === 'success') || []
      const fail = this.items?.filter(item => item.active && item.stage.value === 'fail') || []
      return {
        all: [...any, ...success, ...fail],
        // все активные статусы кроме успешных и неуспешных
        any,
        // успешное завершение
        success,
        // неуспешное завершение
        fail
      }
    },
    finallyStatus () {
      if (this.currentStatus.stage.value !== 'fail' && this.currentStatus.stage.value !== 'success') {
        // если статус не окончательный показываем локальный статус
        // при клике на который будет открываться интерфейс выбора финального статуса
        return {
          local: true,
          color: 'lightgreen',
          name: 'Завершение обработки'
        }
      }
      return this.currentStatus
    }
  },
  watch: {
    currentStatus: {
      handler () {
        this.initStatus()
      },
      deep: true,
      immediate: true
    }
  },
  mounted () {
    this.searchFromServer()
  },
  methods: {
    isNotCurrentStatus (targetStatus) {
      // console.warn('isNotCurrentStatus', targetStatus)
      return targetStatus.id && this.currentStatus.id !== targetStatus.id
    },
    confirmChangeStatus (selectedStatus) {
      if (this.isNotCurrentStatus(selectedStatus)) {
        this.confirmDesc = 'Новый статус: ' + selectedStatus?.name
        // console.warn('confirmChangeStatus', selectedStatus)
        this.$refs.changeStatusConfirm.confirm((result) => {
          // console.warn('confirmChangeStatus confirm callback', result, selectedStatus)
          if (result) {
            this.changeStatus(selectedStatus)
          }
        })
      }
    },
    changeStatus (selected) {
      // console.warn('changeStatus', selected)
      if (this.isNotCurrentStatus(selected)) {
        if (this.loading === true) {
          return
        }

        this.loading = true
        const formData = {
          statusId: selected.id,
          contractFinishDate: selected.contractFinishDate || undefined,
          comment: selected.comment || undefined,
          dateSuspense: selected.dateSuspense || undefined
        }
        // console.error('changeStatus', formData)
        const targetItemId = this.leadId || this.dealId
        dealAPI.changeStatus(targetItemId, formData)
          .then(({ data }) => {
            this.$emit('changeStatus', data)
          })
          .catch((error) => {
            this.$emit('changeStatusError', error?.response?.data || {})
          })
          .finally(() => {
            this.loading = false
            if (this.$refs.finalSelectModal) {
              this.$refs.finalSelectModal.closeModal()
            }
          })
      } else if (selected.local) {
        this.$refs.finalSelectModal.showModal()
      }
    },
    overStatus (e) {
      // console.warn('overStatus', e)
      this.highLighting.index = e.index
      this.highLighting.color = e.status.color
    },
    initStatus () {
      // console.warn('initStatus')
      this.highLighting.index = this.statusList.all.findIndex(item => item.id === this.currentStatus.id)
      this.highLighting.color = this.statusList.all.find(item => item.id === this.currentStatus.id)?.color
    },
    searchFromServer () {
      // console.warn(`${this.$options.name} - searchFromServer: `, searchString)
      if (this.loading) return

      this.loading = true
      uiListAPI.dealInterfaceStatusByType({ dealType: this.dealType })
        .then((response) => {
          this.items = response.data.data || []
        })
        .catch((error) => {
          console.error({ error })
        })
        .finally(() => {
          this.loading = false
          this.initStatus()
        })
    }
  }
}
</script>

<style lang="less" scoped>
</style>
