<template>
  <div
    v-if="formProcessing && !form.socialMedia"
    class="tw-font-manrope tw-py-3 tw-flex tw-justify-center"
  >
    <TwCircleLoader />
  </div>
  <div
    v-else
    ref="form"
    class="tw-font-manrope"
  >
    <div style="display: none">
      {{ isEdited }}
    </div>
    <div class="tw-flex tw-gap-[12px] tw-flex-col">
      <div
        v-for="(item, index) in form.socialMedia"
        :key="index"
      >
        <TwInputText
          v-model="form.socialMedia[index].url"
          type="text"
          :label="item.label"
          :placeholder="item.default"
          :disabled="formProcessing"
          :show-label="true"
          :dense="true"
          :persistent-label="true"
          @focus="onFocusInput($event, index)"
        >
          <template #append>
            <a
              v-if="form.socialMedia[index].url"
              :href="form.socialMedia[index].url"
              target="_blank"
            >Проверить</a>
          </template>
        </TwInputText>
      </div>
    </div>
    <TwCardSimpleFormSaveBlock
      class="tw-mt-[15px]"
      :loading="formProcessing"
      :cancel-confirm="isEdited"
      :is-sticky="false"
      :disable-success="!isEdited || formProcessing"
      :disable-cancel="formProcessing || (formType === 'create' && !isEdited)"
      :visible="true"
      @success="handleSubmit"
      @cancel="handleCancel"
    />
  </div>
</template>

<script>
import API from '@/Api/Auth/User/social-media'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import TwCardSimpleFormSaveBlock from '@/components/tw-ui/forms/TwCardSimpleFormSaveBlock.vue'
import TwInputText from '@/components/tw-ui/ui-kit/input/TwInputText.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'

export default {
  name: 'UserSocialMediaForm',
  components: {
    TwCircleLoader,
    TwInputText,
    TwCardSimpleFormSaveBlock
  },
  mixins: [FormCreateEditMixin],
  data () {
    return {
      API: API,
      form: {}
    }
  },
  methods: {
    onFocusInput (hasFocusIn, index) {
      if (hasFocusIn && !this.form.socialMedia[index].url) {
        this.form.socialMedia[index].url = `https://${this.form.socialMedia[index].default}`
      }
    },
    handleResetCustom () {
      this.handleReset()
    },
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          for (const key in this.preset) {
            this.$set(this.form, key, this.preset[key])
          }
        }
        resolve()
      })
    },
    handleSubmit () {
      if (this.formProcessing) {
        return
      }

      this.formProcessing = true
      console.log('this.form', this.form)
      const request = this.formType === 'create'
        ? this.API.store(this.targetId, {
          socialMedia: this.form.socialMedia.map(item => {
            return {
              ...item,
              url: item.url || null
            }
          })
        })
        : this.API.update(this.targetId, {
          socialMedia: this.form.socialMedia.map(item => {
            return {
              ...item,
              url: item.url || null
            }
          })
        })

      request.then(({ data }) => {
        this.$emit('refresh', data.data)
      }).catch((error) => {
        console.log(error)
      }).finally(() => {
        this.formProcessing = false
      })
    }
  }
}
</script>
