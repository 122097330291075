<template>
  <TwPanelContent>
    <div class="tw-flex-1 tw-min-w-[400px]">
      <TwPanelContentBlock class="tw-mb-[6px]">
        <template #title>
          <div>
            Доступные интеграции
          </div>
        </template>
        <template #action>
          <TwPanelContentBlockActionButton
            type="edit"
            :disabled="loading"
            @click.stop="fetch"
          >
            обновить
          </TwPanelContentBlockActionButton>
        </template>
        <div
          v-if="loading"
          class="tw-p-3 tw-flex tw-justify-center"
        >
          <TwCircleLoader class="tw-p-3" />
        </div>
        <div
          v-else-if="Array.isArray(list) && list.length"
          class="tw-flex tw-flex-col tw-gap-[6px]"
        >
          <div
            v-for="item in list"
            :key="item.id"
          >
            <button
              :disabled="item.enabled === false"
              class="tw-h-[44px] tw-whitespace-nowrap tw-border tw-border-solid tw-border-an-flat-gray-1 enabled:hover:tw-bg-an-flat-bg-main enabled:hover:tw-shadow disabled:tw-border-an-flat-gray-1/50 disabled:tw-bg-opacity-50 tw-text-main-sm2 tw-px-[15px] tw-py-[4px] tw-w-full tw-justify-between tw-uppercase tw-font-bold tw-rounded-sm tw-flex tw-items-center"
              @click.stop="integrationOnSelect(item)"
            >
              <div>
                {{ item.name }}
              </div>
              <div>
                <div v-if="item.processResult">
                  <span :class="item.processResult.success ? 'tw-text-an-flat-green-btn-bg-active':'tw-text-an-flat-red-main'">{{ item.processResult.message || '-' }}</span>
                </div>
                <div
                  v-else
                  class="tw-text-an-flat-orange-warning"
                >
                  Не обработано
                </div>
              </div>
            </button>
          </div>
        </div>
        <div
          v-else
          class="tw-p-3 tw-text-center tw-text-an-flat-disabled-text"
        >
          Нет данных
        </div>
      </TwPanelContentBlock>
    </div>
    <div class="tw-flex-1">
      <TwPanelContentBlock
        class="tw-mb-[6px]"
        no-data-text="Выберите интеграцию"
      >
        <template #title>
          <div>
            Выбранная интеграция
          </div>
        </template>
        <div v-if="selectedIntegration">
          <div v-if="selectedIntegration.cls === 'App\\Modules\\Integration\\Service\\Process\\TransferLeadService' && formByCls[selectedIntegration.cls]">
            <TwSelect
              v-if="selectedIntegration.dictionary"
              v-model="formByCls[selectedIntegration.cls].complexId"
              class="tw-mt-[10px]"
              label="Выбрать ЖК"
              placeholder="Выбрать"
              :options="selectedIntegration.dictionary.complexId"
              :disabled="formProcessing"
              :error-text="formByCls[selectedIntegration.cls].complexId ? '':'Выберите ЖК'"

              :persistent-label="true"
            />

            <div class="tw-py-2 tw-text-an-flat-red-main tw-text-center tw-overflow-auto">
              {{ error }}
            </div>

            <TwCardSimpleFormSaveBlock
              class="tw-mt-[15px]"
              :loading="formProcessing"
              :cancel-confirm="false"
              :is-sticky="false"
              :disable-success="formProcessing || !formByCls[selectedIntegration.cls].complexId"
              :disable-cancel="formProcessing"
              :visible="true"
              @success="handleSubmit"
              @cancel="selectedIntegration = null"
            />
          </div>
          <div v-else>
            <div class="tw-text-center tw-text-an-flat-red-main">
              Нет шаблона формы для интеграции
            </div>
            <pre>
              {{ selectedIntegration }}
            </pre>
          </div>
        </div>
      </TwPanelContentBlock>
    </div>
  </TwPanelContent>
</template>

<script>
import API from '@/Api/Crm/Integration'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwPanelContent from '@/components/tw-ui/panels/TwPanelContent.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import TwPanelContentBlockActionButton from '@/components/tw-ui/panels/TwPanelContentBlockActionButton.vue'
import TwCardSimpleFormSaveBlock from '@/components/tw-ui/forms/TwCardSimpleFormSaveBlock.vue'
import TwSelect from '@/components/tw-ui/ui-kit/input/TwSelect.vue'

export default {
  name: 'LeadIntegrationTab',
  components: {
    TwSelect,
    TwCardSimpleFormSaveBlock,
    TwPanelContentBlockActionButton,
    TwCircleLoader,
    TwPanelContent,
    TwPanelContentBlock
  },
  props: {
    info: {
      required: true
    }
  },
  data () {
    return {
      formProcessing: false,
      loading: false,
      list: [],
      selectedIntegration: null,
      form: {},
      formByCls: {
        'App\\Modules\\Integration\\Service\\Process\\TransferLeadService': {
          leadId: this.info.id,
          complexId: null
        }
      },
      error: null
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    handleSubmit () {
      this.formProcessing = true
      this.error = null
      API.runProcess(this.selectedIntegration.id, 'lead', this.formByCls[this.selectedIntegration.cls])
        .then(({ data: result }) => {
          console.warn('Integration submitted', result)
          alert('Интеграция успешно отправлена!')
          this.selectedIntegration = null
          this.fetch()
        })
        .catch((error) => {
          this.error = error?.response?.data || error
        })
        .finally(() => {
          this.formProcessing = false
        })
    },
    integrationOnSelect (eventData) {
      if (this.selectedIntegration?.id === eventData?.id) {
        this.selectedIntegration = null
      } else {
        this.selectedIntegration = eventData
      }
    },
    fetch () {
      this.loading = true
      this.selectedIntegration = null
      API.listByType('lead', { leadId: this.info.id })
        .then(({ data: result }) => {
          console.warn(result)
          this.list = result?.data || []
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped>

</style>
