<template>
  <div
    v-if="loading"
    class="tw-py-3 tw-flex tw-w-full"
  >
    <TwCircleLoader class="tw-m-auto" />
  </div>
  <div v-else>
    <div v-if="info">
      <TwFlexCol>
        <TwFlexCell>
          <TwPanelContentBlockFieldView>
            <template #title>
              Инвест. привлекательность
            </template>
            <div v-if="info.level">
              {{ info.level.label }}
            </div>
          </TwPanelContentBlockFieldView>
        </TwFlexCell>
        <TwFlexCell>
          <TwPanelContentBlockFieldView>
            <template #title>
              Комментарий
            </template>
            <div
              v-if="info.comment"
              v-html="$Global.nl2br(info.comment)"
            />
          </TwPanelContentBlockFieldView>
        </TwFlexCell>
      </TwFlexCol>
    </div>
    <div
      v-else
      class="tw-text-an-flat-disabled-text tw-text-center"
    >
      Не добавлена
    </div>
  </div>
</template>

<script>
import complexInvestAppealAPI from '@/Api/ObjectStorage/Complex/InvestAppeal'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'
import TwFlexCell from '@/components/tw-ui/ui-kit/flex-block/TwFlexCell.vue'
import TwFlexCol from '@/components/tw-ui/ui-kit/flex-block/TwFlexCol.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'

export default {
  name: 'RealtyComplexInvestAppealShow',
  components: {
    TwCircleLoader,
    TwFlexCell,
    TwFlexCol,
    TwPanelContentBlockFieldView
  },
  props: {
    complexId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      info: null
    }
  },
  methods: {
    fetchInfo () {
      this.loading = true
      complexInvestAppealAPI.showByComplexId(this.complexId)
        .then(({ data: result }) => {
          this.info = result?.data
        })
        .finally(() => {
          this.loading = false
          this.$emit('changeItemId', this.info ? this.info.id : null)
        })
    }
  },
  mounted () {
    this.fetchInfo()
  }
}
</script>
