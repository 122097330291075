<template>
  <div v-cloak>
    <div v-if="info.id">
      <CardBlockField>
        <template #title>
          Статус
        </template>
        <template #content>
          {{ info.status && info.status.label }}
        </template>
      </CardBlockField>
      <CardBlockField v-if="Array.isArray(info.errors) && info.errors.length">
        <template #title>
          Ошибки
        </template>
        <template #content>
          <div
            v-for="(error, eIndex) in info.errors"
            :key="eIndex"
            class="caption error--text"
          >
            <small>{{ eIndex + 1 }}. {{ error }}</small>
          </div>
        </template>
      </CardBlockField>
      <CardBlockField v-if="info.updatedAt && info.updatedAt !== info.createdAt">
        <template #title>
          Изменен
        </template>
        <template #content>
          {{ $Global.DateFormat.DateTimeShortMonthLong(info.updatedAt) }}
        </template>
      </CardBlockField>
      <CardBlockField v-if="info.createdAt">
        <template #title>
          Создан
        </template>
        <template #content>
          {{ $Global.DateFormat.DateTimeShortMonthLong(info.createdAt) }}
        </template>
      </CardBlockField>
      <CardBlockField v-if="info.requestId">
        <template #title>
          UID
        </template>
        <template #content>
          {{ info.requestId }}
        </template>
      </CardBlockField>
      <div style="margin: 15px 0;padding: 0 15px;">
        <v-expansion-panels :disabled="!Array.isArray(info.requestFiles) || (Array.isArray(info.requestFiles) && !info.requestFiles.length)">
          <v-expansion-panel>
            <v-expansion-panel-header>
              Прикрепленные файлы <sup>({{ (Array.isArray(info.requestFiles) && info.requestFiles.length) || 0 }})</sup>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <template v-if="Array.isArray(info.requestFiles) && info.requestFiles.length">
                <EmailFilesAttached
                  :files="info.requestFiles"
                  hide-title
                  :image-size="70"
                />
              </template>
              <template v-else>
                Не прикреплены
              </template>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>
    <div
      v-else
      class="text-muted text-center"
    >
      Запрос отсутствует
    </div>
  </div>
</template>

<script>
import CardBlockField from '@/components/core/ViewPanel/Card/CardBlockField'
import EmailFilesAttached from '@/views/Email/main/EmailFilesAttached'

export default {
  name: 'DealProgram2BankShow',
  components: { EmailFilesAttached, CardBlockField },
  props: {
    info: {}
  }
}
</script>
