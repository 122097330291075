<template>
  <span v-if="info">
    <span v-if="info.name">
      {{ info.name }}
    </span>
    <span
      v-else-if="!info.hasError"
      class="tw-text-an-flat-disabled-text"
    >
      Загрузка
    </span>
    <span
      v-else
      class="tw-text-an-flat-disabled-text"
      @click.stop="onClickError"
    >
      Ошибка
    </span>
  </span>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'RealtyComplexName',
  props: {
    id: {
      type: [Number, String],
      required: true
    }
  },
  methods: {
    ...mapActions({
      fetchComplexById: 'tmpCache/realty/fetchComplexById'
    }),
    onClickError () {
      this.fetchComplexById(this.id)
    }
  },
  computed: {
    ...mapGetters({
      complexById: 'tmpCache/realty/getComplexById'
    }),
    info () {
      return this.complexById(this.id)
    }
  }
}
</script>
