<template>
  <TwPanelContentBlock class="tw-mb-[6px]">
    <template #title>
      <div>
        Настройки Email
      </div>
    </template>
    <template #action>
      <button
        :disabled="loadingList || checkProcess"
        class="tw-px-[16px] tw-py-[4px] tw-rounded-sm tw-bg-an-flat-blue-btn tw-text-main-xs tw-text-white disabled:tw-bg-an-flat-btn-disabled-bg disabled:tw-text-an-flat-disabled-input-text disabled:tw-shadow-none"
        @click.prevent="refresh"
      >
        Обновить список
      </button>
      <FormModalWrapper
        ref="createEmailConfigModal"
        :disabled="permissionCreateMail"
        title="Добавление конфигурации Email"
      >
        <template #trigger="{ on }">
          <button
            :disabled="permissionCreateMail"
            class="tw-px-[16px] tw-py-[4px] tw-rounded-sm tw-bg-an-flat-green-btn-bg tw-text-main-xs tw-text-white disabled:tw-bg-an-flat-btn-disabled-bg disabled:tw-text-an-flat-disabled-input-text disabled:tw-shadow-none"
            @click.prevent="on"
          >
            Добавить почту
          </button>
        </template>
        <template #form="{ on }">
          <EmailConfigForm
            :user-id="userId"
            @create="emailConfigChange"
            @cancel="on"
          />
        </template>
      </FormModalWrapper>
    </template>
    <div>
      <div>
        <div>
          <div class="tw-uppercase tw-font-bold">
            Инструкция:
          </div>
          <div>
            1. Войдите в почту <b>mail.ru</b>
          </div>
          <div>
            2. Перейдите в "Пароли внешних приложений"
          </div>
          <div>
            3. Создайте приложение
          </div>
          <div>
            4. Запросят название для приложения. Можно указать "CRM"
          </div>
          <div>
            5. Сгенерится пароль
          </div>
          <div>
            6. Скопировать пароль
          </div>
          <div>
            7. Ввести сгенерированный пароль в настройках почты в CRM
          </div>
        </div>
        <hr class="tw-my-[12px]">
        <table
          v-if="!loadingList && Array.isArray(list) && list.length"
          class="tw-table tw-table-auto tw-w-full tw-text-main-sm"
        >
          <tr
            v-for="(item, index) in list"
            :key="index"
            class="tw-border-b"
          >
            <td class="tw-p-[7px] tw-whitespace-nowrap tw-font-bold">
              {{ item.email }}
            </td>
            <td class="tw-p-[7px] tw-whitespace-nowrap tw-font-medium">
              <div class="tw-flex tw-items-center">
                <span
                  v-if="checkProcess"
                  class="tw-text-an-flat-orange-warning"
                >
                  Проверка подключения...
                </span>
                <div v-else>
                  <div
                    v-if="item.active"
                    class="tw-flex tw-flex-row tw-items-center"
                  >
                    <template v-if="!item.statusLastConnection || item.statusLastConnection.value === 'error'">
                      <div>
                        <span class="tw-mr-2 tw-text-an-flat-red-main tw-bg-an-flat-red-lighten2 tw-pt-[4px] tw-pb-[6px] tw-px-[11px] tw-rounded-full">
                          {{ item.statusLastConnection ? item.statusLastConnection.label : 'Нет последнего подключения' }}
                        </span>
                      </div>
                      <button
                        :disabled="loadingList || checkProcess"
                        class="tw-px-[16px] tw-py-[4px] tw-rounded-sm tw-bg-an-flat-orange-warning tw-text-main-xs tw-text-white disabled:tw-bg-an-flat-btn-disabled-bg disabled:tw-text-an-flat-disabled-input-text disabled:tw-shadow-none"
                        @click.prevent="checkConnection(item.id)"
                      >
                        Проверить статус
                      </button>
                    </template>
                    <div
                      v-else
                      class="tw-flex tw-flex-row tw-items-center"
                    >
                      <span class="tw-mr-[4px] tw-text-an-flat-green-btn-bg tw-bg-[#E9F9F2] tw-pt-[4px] tw-pb-[6px] tw-px-[11px] tw-rounded-full">
                        {{ item.statusLastConnection.label }}
                      </span>
                      <small
                        class="tw-text-an-flat-disabled-text"
                        title="Последний сбор почты"
                      >
                        ({{ item.lastMailCollect ? $Global.DateFormat.DateTimeShort(item.lastMailCollect) : 'Почта собирается...' }})
                      </small>
                    </div>
                  </div>
                  <span
                    v-else
                    class="tw-text-an-flat-disabled-input-text tw-bg-an-flat-bg-main tw-pt-[4px] tw-pb-[6px] tw-px-[11px] tw-rounded-full"
                  >
                    Почта отключена
                  </span>
                </div>
              </div>
            </td>
            <td class="tw-p-[7px] tw-whitespace-nowrap tw-text-right tw-font-medium">
              <FormModalWrapper
                ref="updateEmailConfigModal"
                title="Изменение конфигурации Email"
              >
                <template #trigger="{ on }">
                  <button
                    :disabled="loadingList || checkProcess"
                    class="tw-px-[16px] tw-py-[4px] tw-rounded-sm tw-bg-an-flat-blue-btn tw-text-main-xs tw-text-white disabled:tw-bg-an-flat-btn-disabled-bg disabled:tw-text-an-flat-disabled-input-text disabled:tw-shadow-none"
                    @click.prevent="on"
                  >
                    Изменить параметры
                  </button>
                </template>
                <template #form="{ on }">
                  <EmailConfigForm
                    :id="item.id"
                    :user-id="userId"
                    @update="emailConfigChange"
                    @cancel="on"
                  />
                </template>
              </FormModalWrapper>
            </td>
          </tr>
        </table>
        <div
          v-else
          class="tw-text-center tw-text-an-flat-red-main"
        >
          Нет добавленных почтовых ящиков
        </div>
      </div>
    </div>
  </TwPanelContentBlock>
</template>

<script>
import API from '@/Api/Email/Config'
import EmailConfigForm from '@/views/Company/Employee/settings/UserEmailConfig/EmailConfigForm.vue'
import FormModalWrapper from '@/components/form/FormModalWrapper.vue'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'UserEmailConfigList',
  components: { TwPanelContentBlock, FormModalWrapper, EmailConfigForm },
  props: {
    userId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      checkProcess: false,
      loadingList: false,
      list: []
    }
  },
  methods: {
    ...mapActions({
      fetchConfigFromStore: 'profile/fetchEmailConfigs'
    }),
    checkConnection (id) {
      if (this.checkProcess) return
      this.checkProcess = true
      API.checkConnection(id)
        .then((data) => {
          console.warn('checkConnection: ', data)
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.checkProcess = false
          this.refresh()
        })
    },
    refresh () {
      if (this.loadingList) return
      this.getConfigs()
    },
    emailConfigChange (data) {
      console.log('emailConfigChange', data)
      this.$emit('update')
      this.$emit('refresh')
      this.refresh()
    },
    getConfigs () {
      this.loadingList = true
      this.fetchConfigFromStore({ userId: this.userId }).then((result) => {
        this.list = result.data || []
      })
        .catch((error) => {
          console.error(this.$options.name, error)
        })
        .finally(() => {
          this.loadingList = false
        })
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'profile/isAdmin',
      subUserInfo: 'profile/getSubAuthUser',
      userInfo: 'profile/getUser'
    }),
    isMyProfile () {
      if (this.subUserInfo) {
        return !!(this.subUserInfo && this.subUserInfo?.id === this.userId)
      }
      return !!(this.userInfo && this.userInfo?.id === this.userId)
    },
    showUserSettings () {
      return this.isMyProfile || this.isAdmin
    },
    permissionCreateMail () {
      if (this.showUserSettings) {
        return false
      } else if (this.$route.path.indexOf('profile') !== -1) {
        return false
      } else {
        return true
      }
    }
  },
  mounted () {
    this.refresh()
  }
}
</script>

<style scoped>

</style>
