<template>
  <TwPanelTabsFull
    v-model="currentTab"
    :items="tabList"
  >
    <template #content.main>
      <DealGeneralTab
        :deal="dealData"
        :deal-state="dealState"
        :dictionary="dictionary"
        :permissions="permissions"
        :is-new="isNew"
        :edit-main="editMain"
        :loading="loading"
        @changeEdit="editMain = $event"
        @refresh="refreshDeal"
        @create="refreshDeal"
        @update="refreshDeal"
      />
    </template>
    <template #content.client>
      <DealHouseDevelopClientTab :deal-data="dealData" />
    </template>
    <template #content.dealResult>
      <HouseDevelopCustomExampleTab :deal-data="dealData" />
    </template>
  </TwPanelTabsFull>
</template>

<script>
import DealGeneralTab from '@/views/Deal/TypeViews/DealGeneralTab'
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import { mapGetters } from 'vuex'
import HouseDevelopCustomExampleTab from '@/views/Deal/TypeViews/houseDevelop/CustomExampleTab/HouseDevelopCustomExampleTab.vue'
import DealHouseDevelopClientTab from '@/views/Deal/TypeViews/houseDevelop/Client/DealHouseDevelopClientTab.vue'
import TwPanelTabsFull from '@/components/tw-ui/panels/TwPanelTabsFull.vue'

export default {
  name: 'DealHouseDevelop',
  components: {
    TwPanelTabsFull,
    DealHouseDevelopClientTab,
    HouseDevelopCustomExampleTab,
    DealGeneralTab
  },
  mixins: [ViewPanelShowMixin],
  props: {
    dictionary: {
      type: Object,
      default: () => {
        return {}
      }
    },
    dealState: {
      default: null
    },
    permissions: {
      type: Object,
      default: () => {
        return {}
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    isNew: {
      type: Boolean,
      default: false
    },
    dealData: {
      required: true
    }
  },
  data () {
    return {
      updateProcessing: false,
      currentTab: 'main',
      editMain: false
    }
  },
  methods: {
    currentTabChange (tabKey) {
      console.warn('currentTabChange', tabKey)
      this.currentTab = tabKey || 'main'
    },
    refreshDeal (v) {
      this.editMain = false
      this.$emit('refresh', v)
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'profile/isAdmin'
    }),
    tabList () {
      return [
        {
          label: 'Общие',
          key: 'main'
        },
        {
          label: 'Клиент',
          key: 'client',
          disabled: this.isNew || this.loading || this.updateProcessing
        },
        {
          label: 'Результат',
          key: 'dealResult',
          disabled: true || !this.isAdmin || this.isNew || this.loading || this.updateProcessing
        }
      ]
    }
  },
  watch: {
    // при переходах между вкладками отключаю редактирование
    currentTab () {
      this.editMain = false
    }
  }
}
</script>
