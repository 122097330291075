<template>
  <a
    v-if="link"
    :href="link"
    target="_blank"
    title="Открыть в Bitrix 24"
  >
    <slot>
      {{ link }}
    </slot>
  </a>
</template>

<script>
import { SERVICES } from '@/Api/Services'

export default {
  name: 'LinkToBitrix',
  props: {
    bxId: {
      type: Number,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  computed: {
    link () {
      const dict = {
        lead: `${SERVICES.BITRIX.host}/crm/lead/details/${this.bxId}/`,
        deal: `${SERVICES.BITRIX.host}/crm/deal/details/${this.bxId}/`
      }
      return dict[this.type]
    }
  }
}
</script>

<style scoped>

</style>
