import { AxiosResponse } from 'axios'
import HTTP from '../../../http'
import { AuthAxiosDefaultConfig } from '@/Api/Auth'

const path = '/structure/department'

export default {
  getList (param?: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig,
      params: param
    }
    return HTTP.get(`${path}`, $config)
  },
  show (id: number | string): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.get(`${path}/${id}`, $config)
  }
}
