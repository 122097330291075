import { RouteKeys } from '@/router/types'

export default {
  path: `${RouteKeys.siteServiceSection}`,
  name: RouteKeys.siteServiceSection,
  redirect: { name: `${RouteKeys.siteServiceSection}-list` },
  meta: { name: 'Разделы услуг', icon: 'admin-service-sections', isAdmin: false },
  component: () => import('@/views/Site/Admin/Index.vue'),
  children: [
    {
      path: 'list',
      name: `${RouteKeys.siteServiceSection}-list`,
      meta: { name: 'Список разделов', icon: 'admin-service-sections', hideInMenu: true },
      component: () => import('@/views/Site/Admin/Content/Service/Section/List.vue')
    },
    {
      path: ':id/edit',
      name: `${RouteKeys.siteServiceSection}-edit`,
      meta: { name: 'Редактирование раздела', icon: 'admin-service-sections', hideInMenu: true },
      component: () => import('@/views/Site/Admin/Content/Service/Section/Edit.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    },
    {
      path: 'create',
      name: `${RouteKeys.siteServiceSection}-create`,
      meta: { name: 'Добавление раздела', icon: 'admin-service-sections', hideInMenu: true },
      component: () => import('@/views/Site/Admin/Content/Service/Section/Edit.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    },
    {
      path: ':id',
      name: `${RouteKeys.siteServiceSection}-show`,
      meta: { name: 'Просмотр раздела', icon: 'admin-service-sections', hideInMenu: true },
      component: () => import('@/views/Site/Admin/Content/Service/Section/Show.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    }
  ]
}
