import { AxiosResponse } from 'axios'
import HTTP from '../../../http'
import { AuthAxiosDefaultConfig } from '@/Api/Auth'

const path = '/permission/user'

export default {
  show (userId: number): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.get(`${path}/${userId}`, $config)
  },
  update (data: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.put(path, data, $config)
  }
}
