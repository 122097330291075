<template>
  <CardBlockField>
    <template #title>
      <div
        style="width: 100%"
        class="d-flex align-center"
      >
        Автомобили
        <v-spacer />
        <v-btn
          v-if="editItemId === null && (list.length < 2)"
          class="font-weight-bold"
          x-small
          text
          color="green"
          @click="editItemId = 0"
        >
          Добавить
        </v-btn>
      </div>
    </template>
    <template #content>
      <div>
        <PersonCarForm
          v-if="editItemId === 0"
          :id="0"
          ref="createForm"
          :person-id="personId"
          dense-fields
          @create="onChange"
          @cancel="editItemId = null"
        />
        <div v-if="list && list.length">
          <div
            v-for="(item) in list"
            :key="item.id"
            class="my-2 px-3 sub-white-bordered-block"
          >
            <div v-if="editItemId === item.id">
              <PersonCarForm
                :id="item.id"
                ref="updateForm"
                :person-id="personId"
                dense-fields
                @update="onChange"
                @cancel="editItemId = null"
              />
            </div>
            <div
              v-else
              class="item-show-blocks"
            >
              <div
                v-if="item.address"
                class="my-2"
              >
                <small class="text-muted">Адрес объекта</small>
                <div>
                  {{ item.address }}
                </div>
              </div>

              <div
                v-if="item.model"
                class="my-2"
              >
                <small class="text-muted">Марка, модель</small>
                <div>
                  {{ item.model }}
                </div>
              </div>
              <div
                v-if="item.yearManufacture"
                class="my-2"
              >
                <small class="text-muted">Год выпуска</small>
                <div>
                  {{ item.yearManufacture }} год
                </div>
              </div>
              <div
                v-if="item.price"
                class="my-2"
              >
                <small class="text-muted">Стоимость</small>
                <div>
                  {{ item.price.toLocaleString() }} руб.
                </div>
              </div>
              <div class="my-2 text-right item-show-actions">
                <v-btn
                  class="font-weight-bold"
                  x-small
                  text
                  color="primary"
                  @click="editItemId = item.id"
                >
                  Изменить
                </v-btn>
                <v-btn
                  class="font-weight-bold"
                  x-small
                  text
                  color="error"
                  @click="onRemove(item)"
                >
                  Удалить
                </v-btn>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="!editItemId">
          Отсутствуют
        </div>
      </div>
    </template>
  </CardBlockField>
</template>

<script>
import API from '@/Api/Crm/Person/Car/index'
import CardBlockField from '@/components/core/ViewPanel/Card/CardBlockField'
import PersonCarForm from '@/views/Person/hipotec/assets/PersonCarForm'
export default {
  name: 'PersonCars',
  components: { PersonCarForm, CardBlockField },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    personId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      editItemId: null
    }
  },
  methods: {
    onRemove (item) {
      const isAllow = confirm('Подтвердите удаление объекта!')
      if (isAllow) {
        API.delete(item.id).finally(() => {
          this.$emit('refresh')
        })
      }
    },
    onChange () {
      this.$emit('refresh')
      this.clearEditedId()
    },
    clearEditedId () {
      this.editItemId = null
    }
  }
}
</script>

<style scoped lang="less">
.item-show-actions {
  visibility: hidden;
}
.item-show-blocks:hover {
  .item-show-actions {
    visibility: visible;
  }
}
</style>
