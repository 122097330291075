import { SERVICES } from '@/Api/Services'
import Config from '@/Api/Email/Config'
import Mail from '@/Api/Email/Mail'
import Contacts from '@/Api/Email/Contacts'
import Folders from '@/Api/Email/Folders'

export const AuthAxiosDefaultConfig: any = {
  serviceKey: SERVICES.EMAIL.key
}

export const API: any = {
  Config,
  Mail,
  Contacts,
  Folders
}
