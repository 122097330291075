<template>
  <div :title="title">
    <slot v-bind="{ copyText, clipboardSuccessHandler, clipboardErrorHandler }">
      <button
        v-clipboard="() => value"
        v-clipboard:success="clipboardSuccessHandler"
        v-clipboard:error="clipboardErrorHandler"
        :title="title"
        :disabled="disabled"
        :style="{ color: iconColor }"
        class="tw-flex tw-items-center tw-gap-[6px] disabled:tw-opacity-50"
      >
        <svg
          :width="iconSize"
          :height="iconSize"
          viewBox="0 0 16 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.93359 18H3.21875C1.66789 18 0.40625 16.7384 0.40625 15.1875V5.66016C0.40625 4.1093 1.66789 2.84766 3.21875 2.84766H9.93359C11.4845 2.84766 12.7461 4.1093 12.7461 5.66016V15.1875C12.7461 16.7384 11.4845 18 9.93359 18ZM3.21875 4.25391C2.44339 4.25391 1.8125 4.8848 1.8125 5.66016V15.1875C1.8125 15.9629 2.44339 16.5938 3.21875 16.5938H9.93359C10.709 16.5938 11.3398 15.9629 11.3398 15.1875V5.66016C11.3398 4.8848 10.709 4.25391 9.93359 4.25391H3.21875ZM15.5586 13.4297V2.8125C15.5586 1.26164 14.297 0 12.7461 0H4.94141C4.55304 0 4.23828 0.314758 4.23828 0.703125C4.23828 1.09149 4.55304 1.40625 4.94141 1.40625H12.7461C13.5215 1.40625 14.1523 2.03714 14.1523 2.8125V13.4297C14.1523 13.8181 14.4671 14.1328 14.8555 14.1328C15.2438 14.1328 15.5586 13.8181 15.5586 13.4297Z"
            fill="currentColor"
          />
        </svg>
        <slot name="button-text" />
      </button>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'ClickBoard',
  props: {
    disabled: {
      default: true
    },
    value: { // Значение которое будет скопировано
      default: ''
    },
    titleActive: {
      default: 'Копировать'
    },
    titleDisabled: {
      default: 'Недоступно'
    },
    iconSize: {
      default: '18'
    },
    iconColor: {
      default: '#7A8797'
    },
    showAlert: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    title () {
      return this.disabled ? this.titleDisabled : this.titleActive
    }
  },
  methods: {
    copyText (targetText) {
      return targetText
    },
    clipboardSuccessHandler () {
      if (this.showAlert) {
        this.$store.commit('notify/ADD', {
          title: 'Скопировано',
          type: 'info',
          timeout: 1500,
          visible: true
        })
      }
      this.$emit('success')
    },
    clipboardErrorHandler () {
      if (this.showAlert) {
        this.$store.commit('notify/ADD', {
          title: 'Ошибка копирования',
          type: 'danger',
          timeout: 1500,
          visible: true
        })
      }
      this.$emit('error')
    }
  }
}
</script>

<style scoped>

</style>
