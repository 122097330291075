<template>
  <div v-if="info">
    <label class="tw-text-an-flat-text-gray tw-font-medium tw-text-[11px] tw-mb-[8px]">{{ title }}:</label>
    <blockquote
      v-if="info.comment"
      class="tw-text-an-flat-grey-3 tw-font-medium tw-text-[11px]"
      :class="loading ? 'blur' : ''"
      v-text="info.comment"
    />
  </div>
</template>

<script>
export default {
  name: 'CommentByTagView',
  props: {
    title: {
      type: String,
      default: 'Примечание'
    },
    tag: {
      type: String,
      required: true
    },
    api: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      API: this.api,
      loading: false,
      info: null
    }
  },
  watch: {
    tag: {
      handler () {
        this.fetchBuyTag()
      },
      immediate: true
    }
  },
  methods: {
    fetchBuyTag () {
      if (this.api.showByTag && this.tag) {
        this.loading = true
        this.api.showByTag(this.tag).then(({ data }) => {
          this.info = data?.data || null
        }).catch((error) => {
          console.error(this.$options.name, error)
        }).finally(() => {
          this.loading = false
        })
      } else {
        console.error(this.$options.name, 'Нет тега или не передан целевой АПИ')
      }
    }
  }
}
</script>
