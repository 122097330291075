import { AxiosResponse } from 'axios'
import HTTP from '@/Api/http'

const path = '/mortgage/domclick/calculations'

export default {
  // Просмотр информации о расчёте
  show (id: number | string): Promise<AxiosResponse> {
    const $config = {}
    return HTTP.get(`${path}/${id}`, $config)
  },
  // Создаю в связке с ID программы нашей CRM
  store (dealProgramId: number, data: object): Promise<AxiosResponse> {
    return HTTP.post(`${path}/${dealProgramId}`, data)
  },
  // Изменяю уже на основе выданного после создания ID
  update (id: number, data: object): Promise<AxiosResponse> {
    return HTTP.put(`${path}/${id}`, data)
  }
}
