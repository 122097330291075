import Building from './NewBuilding/Building/index'
import Entrance from './NewBuilding/Entrance/index'
import Floor from './NewBuilding/Floor/index'
import Apartment from './NewBuilding/Apartment/index'
import Land from '@/Api/ObjectStorage/Realty/Editor/NewBuilding/Land/index'
import CommercialRoom from './NewBuilding/CommercialRoom/index'
import Parking from './NewBuilding/Parking/index'
import ParkingSpace from './NewBuilding/ParkingSpace/index'
import Plan from './Plan/index'
import NewBuildingPrice from './NewBuildingPrice/index'

export default {
  Building,
  Entrance,
  Floor,
  Apartment,
  Land,
  CommercialRoom,
  Parking,
  ParkingSpace,
  Plan,
  NewBuildingPrice
}
