import router from '@/router'
import { RouteKeys } from '@/router/types'

export default {
  generate: function (type: any = '', id: any = '') {
    const routeData = router.resolve({
      name: `${RouteKeys.link}-show`,
      params: { type, id: id }
    })
    return `${location.origin}${routeData.href}`
  }
}

export const typesResistance = {
  deal: 'deal',
  lead: 'lead',
  person: 'person',
  user: 'user',
  developer: 'developer',
  complex: 'complex',
  builderImportFeed: 'builder-import-feed',
  builderImportSite: 'builder-import-site',
  builderImportFile: 'builder-import-file',
  requestToDeveloper: 'request-to-developer',
  konturCheck: 'kontur-check',
  news: 'news',
  support: 'support'
}
