import { tableItemStyleOneGen, TBaseTableHeaderItem } from '@/components/tw-ui/table/TableTypes'

const options: TBaseTableHeaderItem[] = [
  {
    ...tableItemStyleOneGen(false),
    text: 'Название',
    dataKey: 'name',
    colStyle: 'width: auto;'
  },
  {
    ...tableItemStyleOneGen(),
    text: 'Кол-во записей',
    dataKey: 'itemsCount',
    colStyle: 'width: 30px;'
  },
  {
    ...tableItemStyleOneGen(),
    text: 'Субс.ставка с эл. рег.',
    dataKey: 'discountFromElectronRegistration',
    colStyle: 'width: 30px;'
  }
]

export default options
