<template>
  <div class="tw-font-manrope">
    <div style="display: none">
      {{ isEdited }}
    </div>
    <div class="tw-flex tw-gap-[14px] tw-flex-col">
      <TwSelectRelations
        v-model="form.bankId"
        :multi="false"
        :dense="true"
        :persistent-label="true"
        placeholder="Выберите банк"
        label="Банк"
        list-label="Найти банк"
        relation-name="bank"
        :disabled="formProcessing"
        :error-text="$v.form.bankId.$invalid ? 'Обязательное поле' : ''"
      />
      <TwInputText
        v-model.number="form.term"
        type="number"
        class="tw-flex-1"
        placeholder="Срок кредита"
        :disabled="formProcessing"
        :show-label="true"
        :dense="true"
        :persistent-label="true"
        :error-text="$v.form.term.$invalid ? 'Обязательно от 1 года до 50 лет' : ''"
        @input="$v.form.term.$touch()"
      >
        <template #append>
          лет
        </template>
      </TwInputText>
      <TwInputText
        class="tw-flex-1"
        type="text"
        placeholder="Сумма кредита"
        max-length="13"
        :value="$Global.inputFormatPrice.getStringSpaced(form.sum)"
        :disabled="formProcessing"
        :show-label="true"
        :dense="true"
        :persistent-label="true"
        :error-text="$v.form.sum.$invalid ? 'Обязательное поле' : ''"
        @input="form.sum = $Global.inputFormatPrice.getNumber($event)"
      >
        <template #append>
          руб.
        </template>
      </TwInputText>

      <TwInputText
        v-model.number="form.percent"
        type="number"
        class="tw-flex-1"
        placeholder="Процентов в год"
        :disabled="formProcessing"
        :show-label="true"
        :dense="true"
        :persistent-label="true"
        :error-text="$v.form.percent.$invalid ? 'Обязательно от 1 до 50%' : ''"
        @input="$v.form.percent.$touch()"
      >
        <template #append>
          %
        </template>
      </TwInputText>
    </div>
    <div
      v-if="serverErrors"
      class="tw-text-an-flat-red-main"
    >
      {{ serverErrors }}
    </div>

    <TwCardSimpleFormSaveBlock
      class="tw-mt-[15px]"
      :loading="formProcessing"
      :cancel-confirm="true"
      :is-sticky="true"
      bg-color="transparent"
      :disable-success="formProcessing"
      :disable-cancel="formProcessing"
      :visible="true"
      @success="handleSubmit"
      @cancel="handleCancel"
    />
  </div>
</template>

<script>
import API from '@/Api/Crm/Deal/Mortgage/ApprovalBanksInfo/index'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import TwCardSimpleFormSaveBlock from '@/components/tw-ui/forms/TwCardSimpleFormSaveBlock.vue'
import TwSelectRelations from '@/components/tw-ui/ui-kit/input/select-relations/TwSelectRelations.vue'
import TwInputText from '@/components/tw-ui/ui-kit/input/TwInputText.vue'
import { required, minValue, maxValue } from 'vuelidate/lib/validators'

export default {
  name: 'MortgageApprovedBankForm',
  components: {
    TwInputText,
    TwSelectRelations,
    TwCardSimpleFormSaveBlock
  },
  mixins: [FormCreateEditMixin],
  props: {
    dealId: {
      type: Number,
      required: true
    },
    denseFields: {
      type: Boolean,
      default: false
    },
    disableRedirect: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      API: API,
      serverErrors: null,
      form: {
        dealId: null,
        bankId: null,
        sum: 0,
        term: 0,
        percent: 0
      }
    }
  },
  validations () {
    return {
      form: {
        dealId: {
          required
        },
        bankId: {
          required
        },
        term: {
          required,
          minValue: minValue(1),
          maxValue: maxValue(50)
        },
        sum: {
          required,
          minValue: minValue(1)
        },
        percent: {
          required,
          minValue: minValue(1),
          maxValue: maxValue(50)
        }
      }
    }
  },
  methods: {
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          const adaperMap = {
            // from(show): to(send)
            bank: 'bankId',
            deal: 'dealId'
          }
          for (const key in this.preset) {
            if (adaperMap[key] !== undefined) {
              if (adaperMap[key]) {
                this.$set(this.form, adaperMap[key], this.preset[key]?.id || null)
              }
            } else {
              this.$set(this.form, key, this.preset[key])
            }
          }
          this.$set(this.form, 'dealId', this.dealId)
        }
        resolve()
      })
    },
    handleResetCustom () {
      this.handleReset()
    },
    handleSubmit () {
      this.$v.$reset()
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        alert('Заполните все поля формы!')
      } else {
        if (this.formProcessing === true) {
          return
        }

        this.formProcessing = true
        this.serverErrors = null
        if (this.formType === 'create') {
          this.API.store(this.dealId, this.form)
            .then(({ data }) => {
              this.$emit('create', data.data)
            })
            .catch((error) => {
              if (error?.response?.data) {
                console.error(this.$options.name, error.response.data)
                this.serverErrors = error.response.data
              }
            })
            .finally(() => {
              this.formProcessing = false
            })
        } else if (this.formType === 'edit' && this.targetId) {
          this.API.update(this.targetId, this.form)
            .then(({ data }) => {
              console.warn(this.$options.name, ' UPDATE ', data.data)
              this.$emit('update', data.data)
            })
            .catch((error) => {
              if (error?.response?.data) {
                console.error(this.$options.name, error.response.data)
                this.serverErrors = error.response.data
              }
            })
            .finally(() => {
              this.formProcessing = false
            })
        }
      }
    }
  }
}
</script>
