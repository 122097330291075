<template>
  <div v-cloak>
    <div v-if="info">
      <CardBlockField>
        <template #title>
          Образование
        </template>
        <template #content>
          {{ (info.education && info.education.label) || 'Не указано' }}
        </template>
      </CardBlockField>
      <CardBlockField>
        <template #title>
          Менял фамилию
        </template>
        <template #content>
          <div
            v-if="!!info.surnameChange"
            class="px-3 sub-white-bordered-block"
          >
            <div class="my-2">
              <small class="text-muted">Старая фамилия</small>
              <div>
                {{ info.surnameChangeOld }}
              </div>
            </div>
            <div class="my-2">
              <small class="text-muted">Дата изменения</small>
              <div>
                {{ $Global.DateFormat.DateShort(info.surnameChangeDate) }}
              </div>
            </div>
            <div class="my-2">
              <small class="text-muted">Причина изменения</small>
              <div>
                {{ info.surnameChangeCause }}
              </div>
            </div>
          </div>
          <div v-else>
            Нет
          </div>
        </template>
      </CardBlockField>
      <CardBlockField>
        <template #title>
          Была ли процедура банкротства
        </template>
        <template #content>
          {{ info.bankruptcy ? 'Да' : 'Нет' }}
        </template>
      </CardBlockField>
      <CardBlockField>
        <template #title>
          Алименты
        </template>
        <template #content>
          {{ info.alimony > 0 ? `${info.alimony.toLocaleString()} руб.` : 'Нет' }}
        </template>
      </CardBlockField>
    </div>
    <div
      v-else
      class="text-muted text-center"
    >
      Нет данных
    </div>
  </div>
</template>

<script>
import CardBlockField from '@/components/core/ViewPanel/Card/CardBlockField'
export default {
  name: 'PersonAdditionalInfoShow',
  components: { CardBlockField },
  props: {
    info: {},
    dictionary: {}
  }
}
</script>
