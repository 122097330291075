<template>
  <div
    class="tw-font-manrope tw-font-semibold tw-text-main-xs tw-relative tw-w-full"
    :class="open ? 'tw-drop-shadow-2xl':''"
    @keyup.stop="keyPressHandler"
  >
    <TwFilterInput
      ref="trigger"
      :schema-key="schemaKey"
      :has-open="open"
      @search="onSearch"
      @closeSettings="onClose"
      @showSettings="onOpen"
    />
    <div
      v-if="open"
      ref="tooltip"
      class="tw-absolute tw-z-[20] tw-w-full tw-bg-white tw-border-t tw-rounded-b-md tw-shadow- tw-px-[12px]"
    >
      <TwFilterSettings
        v-bind="$props"
        @search="onSearch"
      />
    </div>
  </div>
</template>

<script>
import { createPopper } from '@popperjs/core'
import TwFilterSchemaMixin from '@/components/filters/twfilters/TwFilterSchemaMixin'
import TwFilterSettings from '@/components/filters/twfilters/TwFilterSettings.vue'
import TwFilterInput from '@/components/filters/twfilters/TwFilterInput.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'TwFilterBlock',
  components: { TwFilterInput, TwFilterSettings },
  mixins: [TwFilterSchemaMixin],
  props: {
    hasManualInit: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      popperInstance: null,
      open: false
    }
  },
  methods: {
    onUpdate () {
      this.popperInstance.update()
    },
    clickInContent (event) {
      event.stopPropagation()
      console.log('Popper Content click')
    },
    onToggle () {
      // console.warn(this.$options.name, 'onToggle')
      if (this.open) {
        this.onClose()
      } else {
        this.onOpen()
      }
    },
    onOpen () {
      // console.warn(this.$options.name, 'onOpen')
      if (this.disabled) return

      this.open = true

      const trigger = this.$refs.trigger
      const tooltip = this.$refs.tooltip
      this.popperInstance = createPopper(trigger, tooltip, {
        // elementContext: 'reference',
        // boundary: document.body,
        // placement: 'left-bottom',
        // strategy: 'fixed',
        modifiers: [
          {
            name: 'preventOverflow',
            options: {
              rootBoundary: trigger,
              padding: 0
            }
          },
          {
            name: 'offset',
            options: {
              offset: this.offset
            }
          }
          // {
          //   name: 'flip',
          //   options: {
          //     padding: 0
          //     // flipVariations: false,
          //     // altBoundary: true,
          //     // rootBoundary: 'viewport', // document || 'viewport'  by default
          //     // boundary: trigger.$el || this.parentEl || document.body,
          //     // fallbackPlacements: ['top', 'right'] // by default, all the placements are allowed
          //   }
          // }
        ]
      })
      this.$emit('onOpen')
    },
    onClose () {
      // console.warn(this.$options.name, 'onClose')
      this.open = false
      this.$emit('onClose')
      this.popperInstance = null
    },
    onSearch (fields) {
      this.open = false
      this.$emit('search', fields)
    },
    manualInitCurrentFilters () {
      this.initCurrentFilters(this.schemaKey)
      this.$nextTick(() => {
        this.onSearchByActiveFields()
      })
    },
    keyPressHandler (e) {
      if (e.key === 'Escape') {
        this.onClose()
      } else if (e.key === 'Enter') {
        this.onSearchByActiveFields()
      }
    },
    initial () {
      // Инит только для типовых сервисов - остальные инитятся кастомно в ручную
      if (!this.hasManualInit) {
        this.initFiltersBySchemaKey()
      }
    }
  },
  computed: {
    ...mapGetters({
      isAuth: 'profile/isAuth',
      subAuth: 'profile/subAuth'
    })
  },
  watch: {
    // если прошла успешная авторизация основного аккаунта
    isAuth: {
      handler (newValue) {
        if (newValue) {
          this.initial()
        }
      },
      immediate: true
    },
    // если прошла успешная авторизация суб аккаунта
    subAuth: {
      handler (newValue) {
        if (newValue) {
          this.initial()
        }
      },
      immediate: true
    }
  },
  // todo off - add in watchers - TODO DEL
  // mounted () {
  //   this.initial()
  // },
  beforeDestroy () {
    this.popperInstance = null
  }
}
</script>
