<template>
  <PageViewDefaultWrapper
    ref="wrapper"
    header-class="mb-2"
    :permission-denied="permissionDenied"
    relative
  >
    <TwPanelContent>
      <TwPanelContentBlock
        class="tw-w-full tw-mb-[6px]"
        no-data-text="Нет добавленых документов"
      >
        <template #title>
          <div>
            Объекты страхования
          </div>
        </template>
        <div>
          <TwTableBase
            tbody-classes="tw-text-xs"
            tbody-row-classes="tw-cursor-pointer"
            :items="data"
            :headers="headers"
            :table-fixed="false"
            :loading="loading"
            :fixed-header-margin="0"
            :table-columns-management="false"
            :no-data-text="noResultsText"
            :pagination="pagination"
            @row-click="rowClick"
            @changePagination="changePagination"
          >
            <template #item.action="{ item }">
              <TwPanelActionButton
                color="green"
                title="Будет сгенерирована заявка на продление страховки без учета РАНЕЕ СОЗДАННОЙ в этом году"
                @click.stop="createNewInsuranceDeal(item)"
              >
                Создать заявку
              </TwPanelActionButton>
            </template>
            <template #item.fromDealId="{ item }">
              <ViewPanelLink
                v-if="item.fromDealId"
                :id="item.fromDealId"
                label="Заявка"
                component="deal-show"
                :callback="fetch"
              >
                Заявка #{{ item.fromDealId }}
              </ViewPanelLink>
            </template>
            <template #item.paySum="{ item }">
              {{ (item.paySum || 0).toLocaleString('ru') }}
            </template>
            <template #item.active="{ item }">
              <BooleanViewIcon
                :value="!!item.active"
                align-flex-class="tw-justify-start"
                small
              />
            </template>
            <template #item.payDate="{ item }">
              <div v-if="item.payDate">
                {{ $Global.DateFormat.DateShort(item.payDate) }}
              </div>
            </template>
            <template #item.companyId="{ item }">
              <ViewPanelLink
                v-if="item.companyId"
                :id="item.companyId"
                label="Застройщик"
                component="company-show"
              >
                <TwCompanyName :id="item.companyId" />
              </ViewPanelLink>
            </template>
            <template #item.mortgageBefore="{ item }">
              <div v-if="item.mortgageBefore">
                {{ $Global.DateFormat.DateShort(item.mortgageBefore) }}
              </div>
            </template>
            <template #item.dates="{ item }">
              <div
                v-if="item.createdAt && item.createdAt === item.updatedAt"
                title="Дата создания"
              >
                <span class="text-muted">Созд.</span> {{ $Global.DateFormat.DateTimeShort(item.createdAt) }}
              </div>
              <div
                v-else-if="item.updatedAt"
                title="Дата последнего изменения"
              >
                <span class="text-muted">Изм.:</span> {{ $Global.DateFormat.DateTimeShort(item.updatedAt) }}
              </div>
            </template>
          </TwTableBase>
        </div>
      </TwPanelContentBlock>
    </TwPanelContent>
  </PageViewDefaultWrapper>
</template>

<script>
import API from '@/Api/Crm/Person/InsuranceObject'
import InsuranceAPI from '@/Api/Crm/Deal/Insurance/Other'
import PageViewDefaultWrapper from '@/components/core/ViewDefault/PageViewDefaultWrapper'
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import { mapGetters } from 'vuex'
import TwPanelContent from '@/components/tw-ui/panels/TwPanelContent.vue'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwTableBase from '@/components/tw-ui/table/TwTableBase.vue'
import tableOptions from './tableOptions'
import BooleanViewIcon from '@/components/tw-ui/ui-kit/icons/BooleanViewIcon.vue'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink.vue'
import TwCompanyName from '@/components/tw-ui/other/TwCompanyName.vue'
import TwPanelActionButton from '@/components/tw-ui/panels/TwPanelActionButton.vue'

export default {
  name: 'PersonInsuranceObjectList',
  components: {
    TwPanelActionButton,
    TwCompanyName,
    ViewPanelLink,
    BooleanViewIcon,
    TwTableBase,
    TwPanelContentBlock,
    TwPanelContent,
    PageViewDefaultWrapper
  },
  mixins: [ViewPanelShowMixin],
  props: {
    personId: {
      type: [Number, null],
      default: null
    }
  },
  data () {
    return {
      API,
      defaultFilters: {
        personId: this.personId
      },
      loading: false,
      permissionDenied: false,

      data: [],
      pagination: {},
      loadingText: 'Загрузка... Пожалуйста подождите',
      noDataText: 'Нет данных',
      noResultsText: 'Не найдено',
      itemsPerPage: 25,
      headers: tableOptions,

      generateProcess: false
    }
  },
  methods: {
    createNewInsuranceDeal (item) {
      if (confirm('Будет сгенерирована заявка на продление страховки без учета РАНЕЕ СОЗДАННОЙ в этом году. Продолжить?')) {
        this.generateProcess = true
        InsuranceAPI.createFromOtherInsurance({ objectInsuranceId: item.id })
          .then(() => {
            alert('заявка сгенерирована!')
          })
          .catch(() => {
            alert('ошибка генерации заявки')
          })
          .finally(() => {
            this.generateProcess = false
          })
      }
    },
    rowClick () {
      alert('В разработке...')
    },
    changePagination (eventData) {
      console.log('changePagination - eventData: ', eventData)
      this.fetch({
        ...eventData
      })
    },
    fetch (params = {}) {
      if (this.loading) {
        return
      }

      if (!this.API) {
        throw Error('not set API')
      }

      this.permissionDenied = false
      this.loading = true
      // eslint-disable-next-line no-param-reassign
      params = { ...params, ...this.defaultFilters }

      this.API.getList(params)
        .then((response) => {
          this.data = response.data.data
          if (response.data.meta) {
            this.pagination = response.data.meta
          }
        })
        .catch((error) => {
          // обработка отсутствия прав на просмотр
          if (error?.response?.status === 403) {
            this.permissionDenied = true
          }
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  computed: {
    ...mapGetters({
      getFiltersByKey: 'filters/getFiltersByKey'
    })
  },
  mounted () {
    this.fetch()
  }
}
</script>
