<template>
  <div
    v-if="item"
    class="notification item-hovered-visible-block"
    :class="[classObject]"
  >
    <template
      v-if="item.attachEntityData.type === 'outgoing_call'"
      class="card-wrapper"
    >
      <div class="card-left">
        <div class="card-header">
          <div class="card-header-info">
            <p class="card-title">
              Позвонить:
            </p>
            <p class="card-time">
              {{ $Global.DateFormat.DateTimeShort(item.date) }}
            </p>
          </div>
          <button-editor
            v-if="!item.attachEntityData.done"
            @openEdit="openEdit"
          />
        </div>
        <div
          v-if="!editNotification"
          class="card-notification-info"
          :class="{active: showComment}"
        >
          <p class="card-notification-text">
            {{ item.attachEntityData.title }}
          </p>
          <div
            v-if="person"
            class="card-notification-towhom"
          >
            <ViewPanelLink
              :id="person.id"
              class="card-notification-name mr-2"
              label="Контакт"
              component="person-show"
              :callback="() => {}"
            >
              {{ $Global.peopleName(person, 'if') }}
            </ViewPanelLink>
            <template v-if="Array.isArray(person.phones) && person.phones.length">
              <div
                v-for="(info, index) in person.phones"
                :key="index"
                class="d-flex my-0 mx-1 pa-0"
              >
                <div
                  class="d-flex"
                  :class="info.main ? 'font-weight-medium' : ''"
                >
                  <SipCallLink
                    v-if="info.phone"
                    style="font-size: 12px; font-weight: 300"
                    :phone="info.phone"
                  /><template v-if="index < person.phones.length - 1">
                    ,
                  </template>
                </div>
              </div>
            </template>
          </div>
          <div
            v-else
            class="error--text"
          >
            Клиент не указан
          </div>
          <div
            v-if="item.attachEntityData && item.attachEntityData.notificationAt"
            class="card-notification-towhom"
          >
            <p class="my-1">
              Время звонка:
            </p>
            {{ $Global.DateFormat.DateTimeShort(item.attachEntityData.notificationAt) }}
          </div>
          <div
            v-if="item.attachEntityData && item.attachEntityData.remindAt"
            class="text-muted card-notification-towhom"
          >
            <p class="my-1">
              Напоминание:
            </p>
            {{ $Global.DateFormat.DateTimeShort(item.attachEntityData.remindAt) }}
          </div>
        </div>
        <form-notification-send
          v-if="editNotification && !item.attachEntityData.done"
          :id="item.attachEntityData.id"
          :item="item"
          :type="item.attachEntityData.type"
          :entity-id="entityId"
          :entity="entity"
          @cancel="editNotification = false"
          @success="refresh"
        />
      </div>
      <div class="card-right">
        <div
          v-if="!item.attachEntityData.done && !editNotification"
          class="card-notification-checkbox"
        >
          <TwCircleLoader
            v-if="pinningProcess"
            width="15px"
            height="15px"
            border-width="2px"
          />
          <input
            v-else
            type="checkbox"
            title="Отметить как выполненное"
            @click.stop="doneChange(item.attachEntityData)"
          >
        </div>
        <ChronologyPinned
          v-if="!(variant === 'other' && item.pin || !item.attachEntityData.done)"
          :id="item.id"
          :pin-status="item.pin"
          @refresh="refresh"
        />
      </div>
    </template>
    <!--    Встреча -->
    <template
      v-else
      class="card-wrapper"
    >
      <div class="card-left">
        <div class="card-header">
          <div class="card-header-info">
            <p class="card-title">
              Встреча:
            </p>
            <p class="card-time">
              {{ $Global.DateFormat.DateTimeShort(item.date) }}
            </p>
          </div>
          <button-editor
            v-if="!item.attachEntityData.done"
            @openEdit="openEdit"
          />
        </div>
        <div
          v-if="!editNotification || item.attachEntityData.done"
          class="card-notification-info"
          :class="{active: showComment}"
        >
          <p class="card-notification-text">
            {{ item.attachEntityData.title }}
          </p>
          <div
            v-if="person"
            class="card-notification-towhom"
          >
            <ViewPanelLink
              :id="person.id"
              class="card-notification-name mr-2"
              label="Контакт"
              component="person-show"
              :callback="() => {}"
            >
              {{ $Global.peopleName(person, 'if') }}
            </ViewPanelLink>
            <template v-if="Array.isArray(person.phones) && person.phones.length">
              <div
                v-for="(info, index) in person.phones"
                :key="index"
                class="d-flex my-0 mx-1 pa-0"
              >
                <div
                  class="d-flex"
                  :class="info.main ? 'font-weight-medium' : ''"
                >
                  <SipCallLink
                    v-if="info.phone"
                    style="font-size: 12px; font-weight: 300"
                    :phone="info.phone"
                  /><template v-if="index < person.phones.length - 1">
                    ,
                  </template>
                </div>
              </div>
            </template>
          </div>
          <div
            v-else
            class="error--text"
          >
            Клиент не указан
          </div>
          <div
            v-if="item.attachEntityData && item.attachEntityData.notificationAt"
            class="card-notification-towhom"
          >
            <p>Время встречи: </p>
            {{ $Global.DateFormat.DateTimeShort(item.attachEntityData.notificationAt) }}
          </div>
          <div
            v-if="item.attachEntityData && item.attachEntityData.remindAt"
            class="text-muted card-notification-towhom"
          >
            <p>Напоминание: </p>
            {{ $Global.DateFormat.DateTimeShort(item.attachEntityData.remindAt) }}
          </div>
        </div>
        <form-notification-send
          v-if="editNotification && !item.attachEntityData.done"
          :id="item.attachEntityData.id"
          :item="item"
          :type="item.attachEntityData.type"
          :entity-id="entityId"
          :entity="entity"
          @cancel="editNotification = false"
          @success="refresh"
        />
      </div>
      <div class="card-right">
        <div
          v-if="!item.attachEntityData.done && !editNotification"
          class="card-notification-checkbox"
        >
          <TwCircleLoader
            v-if="pinningProcess"
            width="15px"
            height="15px"
            border-width="2px"
          />
          <input
            v-else
            type="checkbox"
            title="Отметить как выполненное"
            @click.stop="doneChange(item.attachEntityData)"
          >
        </div>
        <ChronologyPinned
          v-if="!(variant === 'other' && item.pin || !item.attachEntityData.done)"
          :id="item.id"
          :pin-status="item.pin"
          @refresh="refresh"
        />
      </div>
    </template>
    <!--    Встреча -->
  </div>
  <div
    v-else
    class="error--text"
  >
    Нет данных
  </div>
</template>

<script>
import API from '@/Api/Crm/Message/Notification'
import FormNotificationSend from '@/components/core/Chronology/formNotificationSend'
import ChronologyNotficationDoneMixin from '@/components/core/Chronology/ChronologyNotficationDoneMixin'
import ChronologyPinned from '@/components/core/Chronology/ChronologyPinned'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink'
import SipCallLink from '@/components/telephony/SipCallLink.vue'
import ButtonEditor from '@/components/core/Chronology/buttonEditor'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'

export default {
  name: 'NotificationShow',
  components: { ButtonEditor, SipCallLink, ViewPanelLink, ChronologyPinned, FormNotificationSend, TwCircleLoader },
  mixins: [ChronologyNotficationDoneMixin],
  props: {
    variant: {
      type: String,
      default: '' // other, plan, pin
    },
    item: {
      type: Object,
      required: true
    },
    entity: {
      type: String,
      required: true // person | lead | deal
    },
    entityId: {
      type: [String, Number, null],
      default: null
    }
  },
  data: () => ({
    API,
    done: false,
    editNotification: false,
    showComment: false,
    bgDone: {
      false: 'stage-bgDoneFalse',
      true: 'stage-bgDoneTrue'
    }
  }),
  computed: {
    person () {
      return this.item?.attachEntityData?.person || null
    },
    classObject: function () {
      if (!this.item.attachEntityData.done) {
        return this.bgDone.false
      } else {
        return this.bgDone.true
      }
    }
  },
  methods: {
    openEdit (done) {
      if (!done) {
        this.editNotification = true
      } else {
        this.editNotification = false
      }
    },
    refresh () {
      this.editNotification = false
      this.$emit('refresh')
    }
  }
}
</script>
