<template>
  <div :class="proxyClasses.container">
    <div :class="proxyClasses.search">
      <input
        v-model="query"
        type="text"
        autocomplete="off"
        :name="inputName"
        :class="proxyClasses.input"
        :disabled="disabled"
        :placeholder="placeholder"
        @input="onInputChange"
        @keyup.enter="onKeyPress($event, KeyEvent.Enter)"
        @keyup.esc="onKeyPress($event, KeyEvent.Esc)"
        @keyup.up="onKeyPress($event, KeyEvent.Up)"
        @keyup.down="onKeyPress($event, KeyEvent.Down)"
        @focus="onInputFocus"
        @blur="onInputBlur"
      >
    </div>
    <div
      v-if="inputFocused && suggestionsVisible && !disabled"
      :class="proxyClasses.suggestions"
    >
      <div
        v-if="!query"
        class="tw-text-an-flat-disabled-text tw-text-center tw-py-[10px]"
      >
        Начните вводить адрес
      </div>
      <div
        v-else-if="loading"
        class="tw-text-an-flat-disabled-text tw-text-center tw-py-[10px]"
      >
        Идёт поиск...
      </div>
      <template v-else-if="Array.isArray(suggestionList) && suggestionList.length">
        <div
          v-for="(suggestion, index) in suggestionList"
          :key="`suggestion_${index}`"
          class="dadata-address-input__suggestions-div-item"
          style="padding: 4px 6px; cursor: pointer"
          @mousedown="onSuggestionClick(index)"
        >
          {{ suggestion.value }}
        </div>
      </template>
      <div
        v-else
        class="tw-text-an-flat-disabled-text tw-text-center tw-py-[10px]"
      >
        Адрес не найден
      </div>
    </div>
    <div
      v-if="errorMsg"
      class="error--text"
    >
      <code>{{ errorMsg }}</code>
    </div>
  </div>
</template>

<script>
import { API } from '@/Api/ObjectStorage'
import lodashDebounce from 'lodash/debounce'
import camelCase from 'lodash/camelCase'
import mapKeys from 'lodash/mapKeys'

const KeyEvent = {
  Enter: 'enter',
  Esc: 'esc',
  Up: 'up',
  Down: 'down'
}
const DEFAULT_HIGHLIGHT_OPTIONS = {
  caseSensitive: false,
  splitBySpace: false,
  highlightTag: 'mark',
  highlightClass: 'dadata-address-input__suggestion-item-text_highlight',
  highlightStyle: '',
  wrapperTag: 'span',
  wrapperClass: ''
}
const DEFAULT_CLASSES = {
  container: 'dadata-address-input',
  search: 'dadata-address-input__search',
  input: 'dadata-address-input__input',
  suggestions: 'dadata-address-input__suggestions',
  suggestionItem: 'dadata-address-input__suggestions-item',
  suggestionCurrentItem: 'dadata-address-input__suggestions-item_current'
}

export default {
  name: 'DaDataAddressInput',
  props: {
    // строка по которой будет происходить поиск
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'Поиск...'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    inputName: {
      type: String,
      default: 'dadata-address-input-input'
    },
    classes: {
      type: Object,
      default: () => DEFAULT_CLASSES
    },
    highlightOptions: {
      type: Object,
      default: () => DEFAULT_HIGHLIGHT_OPTIONS
    }
  },
  data () {
    return {
      suggestionList: [],
      errorMsg: '',
      inputFocused: false,
      suggestionsVisible: true,
      suggestionIndex: -1,
      loading: false,
      KeyEvent
    }
  },
  methods: {
    onSuggestionClick (index) {
      if (this.disabled) {
        return
      }
      this.selectSuggestion(index)
      this.resetSuggestions()
    },
    selectSuggestion (index) {
      if (this.disabled) {
        return
      }
      if (this.suggestionList.length >= index - 1) {
        this.query = this.suggestionList[index]?.value || ''
        this.$emit('input-object', this.suggestionList[index] || null)
      }
    },
    resetSuggestions () {
      if (this.disabled) {
        return
      }
      this.suggestionsVisible = false
      this.suggestionIndex = -1
      this.suggestionList = []
    },
    onKeyPress (keyboardEvent, keyEvent) {
      if (this.disabled) {
        return
      }
      keyboardEvent.preventDefault()
      if (keyEvent === KeyEvent.Enter) {
        if (this.withinTheBoundaries) {
          this.selectSuggestion(this.suggestionIndex)
          this.resetSuggestions()
        }
      }
      if (keyEvent === KeyEvent.Esc) {
        this.suggestionsVisible = false
      }
      if (keyEvent === KeyEvent.Up) {
        if (this.limitDown) {
          this.suggestionIndex -= 1
        }
      }
      if (keyEvent === KeyEvent.Down) {
        if (this.limitUp) {
          this.suggestionIndex += 1
        }
      }
    },
    onInputFocus (event) {
      if (this.disabled) {
        return
      }
      this.inputFocused = true
      this.$emit('focus', event)
    },
    onInputBlur () {
      if (this.disabled) {
        return
      }
      this.inputFocused = false
    },
    onInputChange () {
      if (this.disabled) {
        return
      }
      this.suggestionsVisible = true
    },
    fetchWithDebounce: lodashDebounce(async function () {
      // this.suggestionList = await this.devFetchSuggestions() // TODO - прямой запрос к DADATA
      this.suggestionList = await this.fetchSuggestions()
    }, 1000),
    fetchSuggestions () {
      if (this.query) {
        this.errorMsg = ''
        this.loading = true
        return API.DaData.address(this.query)
          .then(({ data }) => {
            // Изменяю названия свойств адресов с snake_case на CamelCase - такой формат у заявки DomClick
            return data?.data?.map((item) => {
              item.data = mapKeys(item.data, (value, key) => camelCase(key))
              // console.log('newObj: ', item)
              return item
            }) || []
          })
          .catch(error => {
            console.log('error', error)
            this.errorMsg = error.message || 'Ошибка на сервере'
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        return []
      }
    }
  },
  computed: {
    query: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    withinTheBoundaries () {
      return this.suggestionIndex >= 0 && this.suggestionIndex < this.suggestionList.length - 1
    },
    limitUp () {
      return this.suggestionIndex < this.suggestionList.length - 1
    },
    limitDown () {
      return this.suggestionIndex >= 0
    },
    proxyClasses () {
      return {
        container: this.classes?.container ?? DEFAULT_CLASSES.container,
        search: this.classes?.search ?? DEFAULT_CLASSES.search,
        input: this.classes?.input ?? DEFAULT_CLASSES.input,
        suggestions: this.classes?.suggestions ?? DEFAULT_CLASSES.suggestions,
        suggestionItem: this.classes?.suggestionItem ?? DEFAULT_CLASSES.suggestionItem,
        suggestionCurrentItem: this.classes?.suggestionCurrentItem ?? DEFAULT_CLASSES.suggestionCurrentItem
      }
    },
    proxyHighlightOptions () {
      return {
        caseSensitive: this.highlightOptions?.caseSensitive ?? DEFAULT_HIGHLIGHT_OPTIONS.caseSensitive,
        splitBySpace: this.highlightOptions?.splitBySpace ?? DEFAULT_HIGHLIGHT_OPTIONS.splitBySpace,
        highlightTag: this.highlightOptions?.highlightTag ?? DEFAULT_HIGHLIGHT_OPTIONS.highlightTag,
        highlightClass: this.highlightOptions?.highlightClass ?? DEFAULT_HIGHLIGHT_OPTIONS.highlightClass,
        highlightStyle: this.highlightOptions?.highlightStyle ?? DEFAULT_HIGHLIGHT_OPTIONS.highlightStyle,
        wrapperTag: this.highlightOptions?.wrapperTag ?? DEFAULT_HIGHLIGHT_OPTIONS.wrapperTag,
        wrapperClass: this.highlightOptions?.wrapperClass ?? DEFAULT_HIGHLIGHT_OPTIONS.wrapperClass
      }
    }
  },
  watch: {
    query: {
      handler: async function () {
        await this.fetchWithDebounce()
      }
    }
  }
}
</script>

<style>
.dadata-address-input {
  width: 100%;
  position: relative;
  border-radius: 6px;
}
.dadata-address-input__input {
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  padding: 0 5px;
  outline: none;
  overflow: hidden;
  border-radius: 6px;
  border: 1px solid #E7E9ED;
  font-size: 12px;
  color: #4B5763;
  transition: 0.3s;
}
.dadata-address-input__input:focus {
  box-shadow: inset 0 1px 1px rgba(45, 130, 208, 0.11),
  0 0 0 3px rgba(157, 155, 152, 0.1);
  border-color: rgba(45, 130, 208, 1);
}
.dadata-address-input__suggestions {
  position: absolute;
  top: 49px;
  z-index: 10;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #E7E9ED;
}
.dadata-address-input__suggestions-item {
  padding: 10px;
  cursor: pointer;
  font-size: 14px;
  color: #4B5763;
  transition: 0.3s;
}
.dadata-address-input__suggestions-div-item:hover {
  background: lightgray;
}
.dadata-address-input__suggestion-item-text_highlight {
  background-color: transparent;
}
.dadata-address-input__suggestions-item-highlight {
  background-color: rgba(45, 130, 208, 0.11);
}
.dadata-address-input__suggestions-item:hover {
  background-color: rgba(45, 130, 208, 0.11);
}
.dadata-address-input__suggestions-item_current {
  background-color: rgba(45, 130, 208, 0.11);
}
</style>
