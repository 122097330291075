<template>
  <TwDialog
    :value="open"
    :disable-close="false"
    :is-close-btn="true"
    max-width="600px"
    @close="cancelAgreeResults"
  >
    <template #header>
      <div class="tw-text-h5 tw-font-semibold">
        Выбор результатов
      </div>
    </template>
    <template #body>
      <div class="tw-select-none">
        <div
          v-if="Array.isArray(results) && results.length"
          class="tw-flex tw-flex-col tw-gap-[6px]"
        >
          <div
            v-for="(result, rInder) in results"
            :key="rInder"
            :class="[selectedResultsTmp.includes(result.id) ? '':'']"
            class="tw-flex tw-bg-slate-100 tw-rounded-sm tw-cursor-pointer hover:tw-bg-slate-300"
            @click.prevent="onClickResult(result.id)"
          >
            <TwCheckBox
              :value="selectedResultsTmp.includes(result.id)"
              background-and-border-color="tw-bg-an-flat-blue-btn tw-border-an-flat-blue-btn"
            />
            <div class="tw-p-[10px]">
              <div class="tw-font-medium">
                {{ result.name }}
              </div>
              <div class="tw-text-main-xs">
                <span class="tw-mr-[10px]">Комиссия: <b>{{ (result.commission || 0).toLocaleString('ru') }}</b> руб.</span>
                <span>Рассрочка: <b>{{ result.partialPayment ? 'Да':'Нет' }}</b></span>
              </div>
            </div>
          </div>

          <div class="tw-bg-an-flat-bg-main tw-p-[6px] tw-sticky tw-bottom-0 tw-my-[12px] tw-flex tw-gap-[20px] tw-w-full tw-justify-center">
            <TwPanelActionButton
              color="gray"
              @click.stop="cancelAgreeResults"
            >
              Отмена
            </TwPanelActionButton>
            <TwPanelActionButton
              :disabled="false"
              color="green"
              @click.prevent="successAgreeResults"
            >
              Добавить в список
            </TwPanelActionButton>
          </div>
        </div>
        <div
          v-else
          class="tw-p-[16px] tw-text-an-flat-disabled-text tw-text-center"
        >
          Нет результатов по данной компании
        </div>
      </div>
    </template>
  </TwDialog>
</template>

<script>
import API from '@/Api/Accounting/BuyNb/Act'
import TwPanelActionButton from '@/components/tw-ui/panels/TwPanelActionButton.vue'
import TwDialog from '@/components/tw-ui/modal/TwDialog.vue'
import TwCheckBox from '@/components/tw-ui/ui-kit/input/TwCheckBox.vue'

export default {
  name: 'SelectResultsWithoutAct',
  components: {
    TwCheckBox,
    TwDialog,
    TwPanelActionButton
  },
  props: {
    open: {
      type: Boolean,
      default: false
    },
    companyId: {
      type: Number,
      default: null
    },
    records: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      results: [],
      selectedResultsTmp: []
    }
  },
  methods: {
    fetchResultsList () {
      API.getListResultsWithoutAct({ companyId: this.companyId }).then(({ data }) => {
        this.results = data?.data || []
      }).catch(() => {
        this.results = []
      })
    },
    onClickResult (id) {
      if (this.selectedResultsTmp.includes(id)) {
        this.selectedResultsTmp.splice(this.selectedResultsTmp.findIndex((rId) => rId === id), 1)
      } else {
        this.selectedResultsTmp.push(id)
      }
    },
    successAgreeResults () {
      this.$emit('change-records', this.selectedResultsTmp, this.results)
    },
    cancelAgreeResults () {
      this.selectedResultsTmp = []
      this.results = []
      this.$emit('cancel')
    }
  },
  watch: {
    open (newVal) {
      if (newVal) {
        this.fetchResultsList()
        this.selectedResultsTmp = this.records.map((item) => {
          return item.resultId
        })
      } else {
        this.selectedResultsTmp = []
      }
    }
  }
}
</script>
