<template>
  <div class="card-container">
    <template v-if="Array.isArray(dealErrors) && dealErrors.length">
      <div class="error--text ma-5">
        <div
          v-for="(error, index) in dealErrors"
          :key="index"
        >
          <v-alert
            text
            dense
            prominent
            type="error"
          >
            {{ error }}
          </v-alert>
        </div>
      </div>
    </template>
    <template v-else>
      <div
        v-if="isCardContentVisible && !loading"
        class="pa-3 mb-5"
      >
        <div
          v-if="!isIframe"
          class="d-flex align-center"
        >
          <div
            v-if="data"
            class="title d-flex align-center"
          >
            Домклик
            <span class="text-muted ml-2">
              # {{ data.id }}
            </span>
          </div>
          <div
            v-else
            class="title text-muted"
          >
            Новая заявка Домклик
          </div>
          <v-spacer />
          <MenuEntityActions
            v-if="permissions && !isEndStatus"
            :permissions="permissions"
            @delete="deleteCardItem"
          />
        </div>
        <div class="py-2 body-2">
          <v-overlay :value="fetchProductsListProcess">
            <div class="text-center">
              <v-progress-circular
                indeterminate
                size="64"
              />
              <div class="mt-2">
                Инициализация параметров домклик...
              </div>
            </div>
          </v-overlay>
          <v-tabs
            v-model="currentTab"
            show-arrows
            background-color="transparent"
          >
            <v-tab
              key="selection"
              :disabled="!!id || loading"
              href="#selection"
            >
              Подбор
            </v-tab>
            <v-tab
              key="calculation"
              :disabled="(!selectedProgram && !calcId) || loading || isEndStatus"
              href="#calculation"
            >
              Расчёт
            </v-tab>
            <v-tab
              key="deal"
              :disabled="!data || loading"
              href="#deal"
            >
              Заявка Домклик
            </v-tab>
            <v-tabs-items
              v-model="currentTab"
              style="background-color: transparent"
            >
              <v-tab-item
                key="selection"
                value="selection"
              >
                <v-layout>
                  <v-col cols="12">
                    <DomClickProductsSelect
                      :deal-program-id="dealProgramId"
                      @changeSelectedProgram="changeSelectedProgram"
                    />
                  </v-col>
                </v-layout>
              </v-tab-item>
              <v-tab-item
                key="calculation"
                value="calculation"
              >
                <v-layout>
                  <v-col cols="12">
                    <DomClickProductCalculate
                      :id="calcId"
                      :deal-id="id"
                      :loading="loading"
                      :products-list="productsList"
                      :calculate-info="calculateInfo"
                      :deal-program-id="dealProgramId"
                      :target-program="selectedProgram"
                      @calculateUpdated="fetchCalculation"
                      @goto-deal="gotoDeal"
                    />
                  </v-col>
                </v-layout>
              </v-tab-item>
              <v-tab-item
                key="deal"
                value="deal"
              >
                <v-layout>
                  <v-col cols="12">
                    <DomClickDeal
                      v-if="data"
                      :products-list="productsList"
                      :additional-data="additionalData"
                      :calculate-info="calculateInfo"
                      :dom-click-deal="data"
                      :refresh-documents-process="refreshDocumentsProcess"
                      @refresh="refresh"
                      @refresh-application-documents="refreshApplicationDocuments"
                      @tabChange="currentTab = $event"
                    />
                  </v-col>
                </v-layout>
              </v-tab-item>
            </v-tabs-items>
          </v-tabs>
          <div class="error--text">
            <div
              v-for="(error, index) in otherErrors"
              :key="index"
            >
              <v-alert
                text
                dense
                prominent
                type="error"
              >
                {{ error }}
              </v-alert>
            </div>
          </div>
        </div>
      </div>
    </template>
    <!--
      Блок заполняющий пространство при отсутствуии контента
      для прижатия блока сохранения к нижней части страницы
    -->
    <div class="filler">
      <v-progress-circular
        v-if="loading"
        size="50"
        color="primary"
        indeterminate
      />
      <div v-else-if="error">
        <PermissionDenied :text="error === 403 ? 'Недостаточно прав для просмотра!' : 'Ошибка соединения - Обратитесь к администратору!'" />
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/Api/Crm/Mortgage/Docmclick/Deals'
import CardMixin from '@/mixins/CardMixin'
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import MenuEntityActions from '@/components/core/ViewPanel/Card/entity-actions/menuEntityActions'
import PermissionDenied from '@/views/PermissionDenied'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import DomClickProductsSelect from '@/views/MortgageProgramWorksheet/DomClickWorksheet/DomClickProductsSelect'
import DomClickDeal from '@/views/MortgageProgramWorksheet/DomClickWorksheet/deal/DomClickDeal'
import DomClickProductCalculate from '@/views/MortgageProgramWorksheet/DomClickWorksheet/DomClickProductCalculate'
import domClickCalculateAPI from '@/Api/Crm/Mortgage/Docmclick/Calculations'
import ProductsAPI from '@/Api/Crm/Mortgage/Docmclick/Products'
import { isEndStatus } from '@/views/MortgageProgramWorksheet/DomClickWorksheet/DomClickDict'

export default {
  name: 'DomClickWorksheetCard',
  components: {
    DomClickProductCalculate,
    DomClickDeal,
    DomClickProductsSelect,
    MenuEntityActions,
    PermissionDenied
  },
  mixins: [CardMixin, ViewPanelShowMixin],
  props: {
    dealProgramId: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      fetchProductsListProcess: false,
      fetchCalculationProcess: false,
      refreshDocumentsProcess: false,
      calculateInfo: {},
      productsList: [],
      otherErrors: [],
      dealErrors: [],
      selectedProgram: null,
      tmpCalcId: null,
      isIframe: false,
      currentTab: 'selection',
      API: API
    }
  },
  methods: {
    ...mapActions({
      checkToken: 'profile/checkToken'
    }),
    ...mapMutations({
      setToken: 'profile/setToken'
    }),
    gotoDeal (event) {
      console.warn('gotoDeal', this.dealProgramId, this.calcId, this.id)
      if (event) {
        this.onCreated(event.data)
        this.currentTab = 'deal'
      } else {
        this.refresh()
        this.currentTab = 'deal'
      }
    },
    changeTab (targetKey) {
      console.warn('changeTab ', targetKey)
      this.currentTab = targetKey
    },
    changeSelectedProgram (programData) {
      this.$set(this, 'selectedProgram', programData)
      this.changeTab('calculation')
    },
    postFetch () {
      this.currentTab = 'deal'
      this.fetchCalculation()
      // TODO локальное разрешение на действие удаления
      if (!this.permissions) {
        this.permissions = {
          entity: {
            delete: true
          }
        }
      }
    },
    // Получить информацию о расчёте по ID
    fetchCalculation (calcId) {
      this.tmpCalcId = calcId
      return new Promise((resolve) => {
        if (this.calcId) {
          this.fetchCalculationProcess = true
          domClickCalculateAPI.show(this.calcId)
            .then(({ data }) => {
              console.warn('fetchCalculation', this.$options.name, data.data)
              this.calculateInfo = data?.data
              resolve(data?.data)
            })
            .catch((error) => {
              console.error(error)
              resolve(null)
            })
            .finally(() => {
              this.fetchCalculationProcess = false
            })
        } else {
          resolve(null)
        }
      })
    },
    // Получить список продуктов
    fetchProductsList () {
      return new Promise((resolve) => {
        if (Array.isArray(this.productsList) && this.productsList.length) {
          return resolve(null)
        }
        this.otherErrors = []
        this.fetchProductsListProcess = true
        return ProductsAPI.getList().then(({ data }) => {
          if (data.errors) {
            this.otherErrors = Array.isArray(data?.errors) && data?.errors.length ? data?.errors : [data?.message || 'Получения списка программ']
          }
          this.productsList = data?.data || []
          // TODO исправление бага домклика - дополнение описания господдержки
          /*
          * В выдаче ручки получе есть разница в параметрах ГП на дом и ГП на строительство.
          * У ГП на строительство дома есть поле субпродукт id, "subproduct": "HOME_BUILD_WITH_STATE_SUPPORT_2020",
          * можно на фронте сделать отличное название для ГП на строительства, по ксловию заполнения поля "subproduct": "HOME_BUILD_WITH_STATE_SUPPORT_2020".
          * */
          this.productsList.forEach(item => {
            if (item.product === 'WITH_STATE_SUPPORT_2020') {
              item.subproducts.forEach((p) => {
                if (p.subproduct === 'HOME_BUILD_WITH_STATE_SUPPORT_2020') {
                  p.title = p.title + ' на строительство дома'
                } else {
                  p.title = p.title + ' на дом'
                }
              })
            }
          })
        }).catch((error) => {
          console.error(error)
          resolve(error)
        })
          .finally(() => {
            this.fetchProductsListProcess = false
          })
      })
    },
    refreshApplicationDocuments () {
      // console.warn('refreshApplicationDocuments')
      this.refreshDocumentsProcess = true
      API.show(this.data.id)
        .then(({ data }) => {
          // console.warn('refreshApplicationDocuments RESULT: ', data)
          const { applications } = data?.data
          if (Array.isArray(applications)) {
            for (const index in applications) {
              // console.warn('refreshApplicationDocuments RESULT: ', applications[index].documents, this.data?.applications[index].documents)
              // this.data?.applications[index].documents = applications[index]?.documents || []
              this.data.applications[index].documents = applications[index].documents
            }
          }
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.refreshDocumentsProcess = false
        })
    },
    fetch (id = this.id || this.data?.id) {
      this.error = false
      this.loading = true
      this.dealErrors = []

      return this.API.show(id)
        .then((result) => {
          const { data, dictionary, permissions, errors, message, ...others } = result.data
          if (errors) {
            this.dealErrors = Array.isArray(errors) && errors.length ? errors : [message || 'Ошибка получения заявки домклик']
          } else {
            this.data = data
            this.dictionary = dictionary
            this.permissions = permissions
            if (others.additionalData && Object.keys(others.additionalData).length) {
              for (const key in others.additionalData) {
                this.$set(this.additionalData, [key], others.additionalData[key])
              }
            }
            if (this.postFetch) {
              this.postFetch(data)
            }
          }
        })
        .catch((error) => {
          // console.error(error)
          // обработка ошибки запроса
          this.dealErrors.push(error.message)
          if (error?.response?.status) {
            this.error = error.response.status
          } else {
            this.error = true
          }
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  computed: {
    ...mapGetters({
      isAuth: 'profile/isAuth',
      isAdmin: 'profile/isAdmin'
    }),
    calcId () {
      return this.tmpCalcId || this.data?.calcId || 0
    },
    isEndStatus () {
      return isEndStatus(this.data?.status)
    }
  },
  mounted () {
    this.fetchProductsList()
    if (this.$route?.name?.includes('iframe-')) {
      this.isIframe = true
      // TODO ДОБАВИТЬ СТИЛИ ДЛЯ ПРОКРУТКИ для Iframe
      document.querySelector('body').style.overflow = 'auto'
      document.querySelector('body').style.height = '100%'
      document.querySelector('html').style.overflow = 'auto'
      document.querySelector('html').style.height = '100%'
      if (!this.isAuth) {
        const token = this.$route?.query?.token
        console.warn(this.$options.name, this.id, this.$route.query)
        if (token) {
          this.setToken({ token })
          this.$nextTick(() => {
            this.checkToken()
          })
        }
      }
    }
  }
}
</script>
