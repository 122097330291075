import { RouteKeys } from '@/router/types'

export default {
  path: RouteKeys.department,
  name: RouteKeys.department,
  redirect: { name: `${RouteKeys.department}-list` },
  meta: { name: 'Отделы', icon: 'block-company-department', isAdmin: true },
  component: () => import('@/views/Company/Department/Index.vue'),
  children: [
    {
      path: 'list',
      name: `${RouteKeys.department}-list`,
      meta: { name: 'Список отделов', icon: 'block-company-department', hideInMenu: true },
      component: () => import('@/views/Company/Department/List.vue')
    }
  ]
}
