<template>
  <div
    style="outline: none; display: contents"
    tabindex="0"
    :title="disabled ? '':''"
  >
    <div
      ref="trigger"
      :style="{ display: popoverTriggerDisplay }"
      @click="onToggle"
    >
      <slot name="trigger">
        clickMe
      </slot>
    </div>
    <div
      v-show="open"
      ref="tooltip"
      :class="contentClasses || 'a-tooltip'"
      :style="{ zIndex }"
      style="font-weight: initial;"
      @click.stop="clickInContent"
    >
      <div
        v-if="open"
        v-click-outside="clickOutSide"
      >
        <div
          v-if="!hideClose"
          style="position:relative;height: 20px"
        >
          <div
            class="tw-cursor-pointer tw-absolute tw-top-0 tw-right-0"
            @click.stop="onClose"
          >
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.28624 0.220687C0.991991 -0.0735622 0.514931 -0.0735622 0.220682 0.220687C-0.0735605 0.514928 -0.0735605 0.991997 0.220682 1.28624L4.93447 6.00003L0.220715 10.7138C-0.0735501 11.008 -0.0735729 11.4851 0.220654 11.7794C0.514881 12.0735 0.991948 12.0735 1.28621 11.7793L5.99996 7.06552L10.7137 11.7793C11.008 12.0736 11.4851 12.0736 11.7793 11.7793C12.0736 11.4851 12.0736 11.008 11.7793 10.7137L7.06551 5.99996L11.7792 1.2862C12.0735 0.991945 12.0736 0.514877 11.7793 0.22065C11.4851 -0.0735761 11.0081 -0.0735464 10.7138 0.220717L6.00003 4.93447L1.28624 0.220687Z"
                fill="#8C94A8"
              />
            </svg>
          </div>
        </div>
        <slot name="content">
          popout info content
        </slot>
      </div>
      <div
        v-if="!hideArrow"
        class="tooltip-arrow"
        data-popper-arrow
      />
    </div>
  </div>
</template>

<script>
import { createPopper } from '@popperjs/core'

export default {
  name: 'APopoverWrapper',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    hideClose: {
      type: Boolean,
      default: false
    },
    hideArrow: {
      type: Boolean,
      default: false
    },
    noCloseClickOutside: {
      type: Boolean,
      default: false
    },
    contentClasses: {
      type: String,
      default: null
    },
    popoverTriggerDisplay: {
      type: String,
      default: 'inline'
    },
    offset: {
      type: Array,
      default: () => [0, 10]
    },
    zIndex: {
      default: 99
    },
    parentEl: {
      default: null
    }
  },
  data () {
    return {
      popperInstance: null,
      open: false
    }
  },
  beforeDestroy () {
    this.popperInstance = null
  },
  methods: {
    onUpdate () {
      this.popperInstance.update()
    },
    stopPropagation (event) {
      event.stopPropagation()
    },
    clickInContent (event) {
      event.stopPropagation()
      console.log('Popper Content click')
    },
    onToggle () {
      // console.warn(this.$options.name, 'onToggle')
      if (this.open) {
        this.onClose()
      } else {
        this.onOpen()
      }
    },
    onOpen () {
      // console.warn(this.$options.name, 'onOpen')
      if (this.disabled) return
      this.open = true
      const trigger = this.$refs.trigger
      const tooltip = this.$refs.tooltip
      this.popperInstance = createPopper(trigger, tooltip, {
        // elementContext: 'reference',
        // boundary: document.body,
        // placement: 'left-bottom',
        strategy: 'fixed',
        modifiers: [
          {
            name: 'preventOverflow',
            options: {
              rootBoundary: this.parentEl || document,
              padding: 5
            }
          },
          {
            name: 'offset',
            options: {
              offset: this.offset
            }
          },
          {
            name: 'flip',
            options: {
              padding: 0
              // flipVariations: false,
              // altBoundary: true,
              // rootBoundary: 'viewport', // document || 'viewport'  by default
              // boundary: trigger.$el || this.parentEl || document.body,
              // fallbackPlacements: ['top', 'right'] // by default, all the placements are allowed
            }
          }
        ]
      })
      this.$emit('onOpen')
    },
    onClose () {
      // console.warn(this.$options.name, 'onClose')
      this.open = false
      this.$emit('onClose')
    },
    clickOutSide () {
      // закрываю - если это не запрещено
      if (!this.noCloseClickOutside) {
        this.onClose()
      }
    }
  }
}
</script>
<style scoped>
.a-tooltip {
  display: inline-block;
  background: #ffffff;
  /*color: #643045;*/
  /*font-weight: bold;*/
  padding: 5px 10px;
  font-size: 13px;
  border-radius: 4px;
  -webkit-box-shadow: 0 0 8px 2px rgba(34, 60, 80, 0.34);
  -moz-box-shadow: 0 0 8px 2px rgba(34, 60, 80, 0.34);
  box-shadow: 0 0 8px 2px rgba(34, 60, 80, 0.34);
}

.tooltip-arrow,
.tooltip-arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
}

.tooltip-arrow {
  visibility: hidden;
}

.tooltip-arrow::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
}

.a-tooltip[data-popper-placement^='top'] > .tooltip-arrow {
  bottom: -4px;
}

.a-tooltip[data-popper-placement^='bottom'] > .tooltip-arrow {
  top: -4px;
}

.a-tooltip[data-popper-placement^='left'] > .tooltip-arrow {
  right: -4px;
}

.a-tooltip[data-popper-placement^='right'] > .tooltip-arrow {
  left: -4px;
}

</style>
