<template>
  <div class="read-more-block">
    <div
      v-html="viewedText"
    />
    <br v-if="isNlTrigger && viewTextVariants.hasMore">
    <button
      v-if="viewTextVariants.hasMore"
      class="tw-transition-all tw-mt-[4px] tw-group tw-text-main-xs2 hover:tw-text-an-flat-dark-blue-link"
      :class="[align === 'left' ? 'tw-mr-auto':align === 'right' ? 'tw-ml-auto' : '']"
      @click.stop="clickShow"
    >
      {{ show ? 'свернуть':'развернуть' }}
      <span
        class="tw-inline-block tw-transition-all tw-px-[2px] tw-pt-[2px]"
        :class="{'tw-rotate-180 tw-pb-[5px]': show}"
      >
        <svg
          width="8"
          height="6"
          viewBox="0 0 8 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            class="tw-transition-all group-hover:tw-stroke-an-flat-dark-blue-link"
            d="M1 1.5L4 4L7 1.5"
            stroke="#9FA6B4"
            stroke-width="2"
            stroke-linecap="round"
          />
        </svg>
      </span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'ReadMoreExpander',
  props: {
    text: {
      type: String,
      default: ''
    },
    lessLength: {
      type: Number,
      default: 50
    },
    isNlTrigger: {
      type: Boolean,
      default: false
    },
    isCleanHtml: {
      type: Boolean,
      default: true
    },
    isUrl2link: {
      type: Boolean,
      default: false
    },
    align: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      show: false
    }
  },
  computed: {
    cleanHtml () {
      if (this.text) {
        if (this.isCleanHtml) {
          const tmp = document.createElement('div')
          tmp.innerHTML = this.text
          return this.$Global.nl2br(tmp.textContent || tmp.innerText || '')
        }
        return this.$Global.nl2br(this.text)
      }
      return ''
    },
    viewTextVariants () {
      let less = this.cleanHtml.slice(0, this.lessLength)
      const hasMore = !!this.cleanHtml.slice(this.lessLength)
      if (hasMore && !this.show) {
        less = less + ' ...'
      }
      return {
        all: this.isUrl2link ? this.$Global.url2link(this.cleanHtml) : this.cleanHtml,
        less,
        hasMore
      }
    },
    viewedText () {
      if (this.viewTextVariants.hasMore) {
        return this.show ? this.viewTextVariants.all : this.viewTextVariants.less
      }
      return this.viewTextVariants.all
    }
  },
  methods: {
    clickShow () {
      this.show = !this.show
      this.$emit('input', this.show)
    }
  }
}
</script>
<style lang="scss">
.read-more-block {
  a {
    @apply hover:tw-underline
  }
  @apply tw-flex tw-flex-col
}
</style>
