import { TableColumns } from '@/views/tableOptionType'

const options: TableColumns = [
  {
    dataKey: 'data',
    text: 'Выбрать',
    value: 'data-table-select',
    align: 'center',
    sortable: false,
    colStyle: 'width: 70px;',
    thClass: 'tw-text-[13px] tw-font-medium tw-text-[#96A3B4] tw-bg-[#F8F9FA] tw-pt-[19px] tw-pb-[16px]',
    tdClass: 'tw-border-y tw-border-an-flat-gray-7 tw-py-[12px] tw-pl-[32px] tw-pr-[12px] tw-text-[13px] tw-text-an-flat-gray-4 tw-overflow-hidden tw-h-[70px]'
  },
  {
    dataKey: 'name',
    text: 'Банк - Программа',
    value: 'name',
    sortable: false,
    colStyle: 'width: 70px;',
    thClass: 'tw-w-full tw-text-[13px] tw-font-medium tw-text-[#96A3B4] tw-bg-[#F8F9FA] tw-pl-[32px] tw-pt-[19px] tw-pb-[16px]',
    tdClass: 'tw-text-left tw-border-y tw-border-an-flat-gray-7 tw-py-[12px] tw-pr-[12px] tw-pl-[32px] tw-text-[13px] tw-text-an-flat-gray-4  tw-overflow-hidden tw-h-[70px]'
  },
  {
    dataKey: 'rate',
    text: 'Ставки Банк/Флэт/Эл.рег.',
    value: 'rate',
    align: 'center',
    sortable: false,
    colStyle: 'width: 70px;',
    thClass: 'tw-text-[13px] tw-font-medium tw-text-[#96A3B4] tw-bg-[#F8F9FA] tw-text-center  tw-pt-[19px] tw-pb-[16px]',
    tdClass: 'tw-min-w-[200px] tw-text-left tw-border-y tw-border-an-flat-gray-7 tw-p-[12px] tw-text-[13px] tw-text-an-flat-black-main tw-overflow-hidden tw-h-[70px]'
  },
  {
    dataKey: 'pay',
    text: 'Платеж Банк/Флэт/Эл.рег',
    value: 'pay',
    align: 'end',
    sortable: false,
    colStyle: 'width: 70px;',
    thClass: 'tw-flex tw-text-[13px] tw-font-medium tw-text-[#96A3B4] tw-bg-[#F8F9FA] tw-pt-[19px] tw-pb-[16px] tw-pl-[32px]',
    tdClass: 'tw-min-w-[300px] tw-border-y tw-border-an-flat-gray-7 tw-py-[12px] tw-pr-[12px] tw-pl-[32px] tw-text-[13px] tw-text-an-flat-gray-4 tw-overflow-hidden tw-h-[70px]'
  }
]

export default options
