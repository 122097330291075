import document from './document'
import companies from './companies'

export default {
  path: 'general',
  name: 'general-dictionary',
  redirect: { name: 'company-list' },
  meta: { name: 'Общие', icon: 'are-common' },
  component: () => import('@/views/Dictionary/Index.vue'),
  children: [
    companies,
    document
  ]
}
