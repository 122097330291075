<template>
  <div class="tw-font-manrope">
    <div class="tw-border tw-border-solid tw-border-an-flat-red-main tw-rounded-2xl tw-p-[24px] tw-bg-an-flat-red-main tw-bg-opacity-10">
      <div class="tw-text-h5">
        Внимание!
      </div>
      <div class="tw-mt-2 tw-text-an-flat-grey-4">
        Данный объект забронирован!
      </div>
      <div class="tw-mt-3 tw-p-3 tw-bg-white tw-rounded tw-flex tw-justify-between">
        <div>
          Забронировано: <strong>{{ (info.reservedBy && info.reservedBy.label) || '~' }}</strong>
        </div>
        <div>
          До:  <strong>{{ info.confirmedBefore || 'Не указано' }}</strong>
        </div>
      </div>
      <template v-if="Array.isArray(info.ourReservations) && info.ourReservations.length">
        <div
          v-for="reservation in info.ourReservations"
          :key="reservation.id"
          class="tw-mt-3 tw-p-3 tw-bg-white tw-rounded tw-flex tw-justify-between tw-items-center"
        >
          <div>
            <div>
              ID: {{ reservation.id }}
            </div>
            <ViewPanelLink
              v-if="reservation.userId"
              :id="reservation.userId"
              label="Пользователь"
              :no-link-style="false"
              component="employee-show"
            >
              <EmployeeName :id="reservation.userId" />
            </ViewPanelLink>
          </div>
          <div v-if="reservation.status">
            Статус: <span :style="{ color: reservation.status.color }">{{ reservation.status.label }}</span>
          </div>
          <div>
            До:  <strong>{{ reservation.confirmedBefore || 'Не указано' }}</strong>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import EmployeeName from '@/components/other/employee/EmployeeName.vue'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink.vue'

export default {
  name: 'RequestToDeveloperReservationStatus',
  components: { ViewPanelLink, EmployeeName },
  props: {
    info: {
      type: Object,
      default: null
    }
  }
}
</script>

<style scoped>

</style>
