<template>
  <div>
    <TwInputText
      v-model.number="form.days"
      class="tw-mt-[12px]"
      type="number"
      placeholder="Срок фиксации"
      :disabled="formProcessing"
      :show-label="true"
      :error-text="$v.form.days.$invalid ? 'Заполните поле':''"
    >
      <template #append>
        дней
      </template>
    </TwInputText>

    <div class="tw-mt-[18px]">
      <TwCheckBox
        v-model="form.needPetition"
        :disabled="formProcessing"
        label="Требуется ходатайство"
      />
    </div>

    <TwTextarea
      v-model="form.description"
      rows="5"
      class="tw-mt-[12px]"
      placeholder="Описание для риелтора"
      :disabled="formProcessing"
      :show-label="true"
    />

    <TwSelect
      v-model="form.communicationMethod"
      label="Способ связи с застройщиком"
      class="tw-mt-[12px]"
      placeholder="Выбрать"
      :options="dictionary.communicationMethod"
      :persistent-label="true"
      :error-text="$v.form.communicationMethod.$invalid ? 'Заполните поле':''"
    />

    <TwInputText
      v-model.number="form.communicationName"
      class="tw-mt-[12px]"
      type="text"
      placeholder="Имя"
      :disabled="formProcessing"
      :show-label="true"
    />

    <TwInputText
      v-model="form.communicationContact"
      class="tw-mt-[12px]"
      type="text"
      placeholder="Контакт застройщика"
      :disabled="formProcessing"
      :show-label="true"
      :error-text="$v.form.communicationContact.$invalid ? 'Заполните поле':''"
    />

    <TwTextarea
      v-model="form.communicationInfo"
      rows="5"
      class="tw-mt-[12px]"
      placeholder="Доп. инфо по связи с застройщиком"
      :disabled="formProcessing"
      :show-label="true"
      :error-text="$v.form.communicationInfo.$invalid ? 'Заполните поле':''"
    />
    <div>
      <TwCardBlockFormActions
        :title-submit="formType === 'create' ? 'Создать' : 'Изменить'"
        :handle-submit="handleSubmit"
        :handle-cancel="handleCancel"
        :disable-submit="formProcessing || $v.form.$invalid"
        :disable-cancel="formProcessing"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import SettingsFixationInfo from '@/Api/Rtd/RequestToDeveloper/Settings/FixationInfo'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import TwTextarea from '@/components/tw-ui/ui-kit/input/TwTextarea.vue'
import TwCheckBox from '@/components/tw-ui/ui-kit/input/TwCheckBox.vue'
import TwCardBlockFormActions from '@/components/core/ViewPanel/Card/TwCardBlockFormActions.vue'
import TwInputText from '@/components/tw-ui/ui-kit/input/TwInputText.vue'
import { required } from 'vuelidate/lib/validators'
import TwSelect from '@/components/tw-ui/ui-kit/input/TwSelect.vue'

export default defineComponent({
  name: 'BuilderFixationInfoForm',
  components: { TwSelect, TwInputText, TwCardBlockFormActions, TwCheckBox, TwTextarea },
  mixins: [FormCreateEditMixin],
  props: {
    companyId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      API: SettingsFixationInfo,
      form: {}
    }
  },
  validations () {
    return {
      form: {
        days: {
          required
        },
        communicationMethod: {
          required
        },
        communicationInfo: {
          ...(['personal_account', 'other'].includes(this.form.communicationMethod)) && {
            required
          }
        },
        communicationContact: {
          ...(['phone', 'email', 'sms', 'messenger'].includes(this.form.communicationMethod)) && {
            required
          }
        }
      }
    }
  },
  methods: {
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          for (const key in this.preset) {
            if (key === 'communicationMethod') {
              this.$set(this.form, 'communicationMethod', this.preset[key]?.value || null)
            } else {
              this.$set(this.form, key, this.preset[key])
            }
          }
        }
        resolve()
      })
    },
    handleSubmit () {
      this.$v.$reset()
      this.$v.form.$touch()
      if (this.formProcessing === true) {
        return
      }
      if (!this.$v.form.$invalid) {
        this.formProcessing = true
        this.form.companyId = this.companyId
        const request = this.formType === 'create'
          ? this.API.store(this.form)
          : this.API.update(this.targetId, this.form)

        request.then(({ data }) => {
          if (this.formType === 'create') {
            this.$emit('create', data.data)
          } else {
            this.$emit('update', data.data)
          }
        }).finally(() => {
          this.formProcessing = false
        })
      }
    }
  }
})
</script>

<style scoped>

</style>
