<template>
  <div
    v-cloak
    v-if="info"
    class="tw-flex tw-gap-[12px] tw-flex-col"
  >
    <TwPanelContentBlockFieldView>
      <template #title>
        Образование
      </template>
      <div v-if="info.education">
        {{ info.education }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Должность
      </template>
      <div v-if="info.position">
        {{ info.position }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Дата начала работы в компании
      </template>
      <div v-if="info.experienceSince">
        {{ new Date(info.experienceSince).toLocaleDateString() }} г.
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Достижения
      </template>
      <div v-if="info.achievements">
        <div
          class="tw-text-main-xs"
          v-html="info.achievements"
        />
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Наличие автомобиля
      </template>
      <div v-if="info.hasAuto !== null">
        {{ info.hasAuto ? 'Да' : 'Нет' }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Лучший сотрудник
      </template>
      <div>
        {{ info.best ? 'Да' : 'Нет' }}
      </div>
    </TwPanelContentBlockFieldView>
  </div>
  <div
    v-else
    class="tw-text-an-flat-disabled-text tw-text-center"
  >
    Не заполнено
  </div>
</template>

<script>
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'
export default {
  name: 'EmployeeAboutShow',
  components: { TwPanelContentBlockFieldView },
  props: {
    info: {
      default: () => {
        return {}
      }
    }
  }
}
</script>
