import { RouteKeys } from '@/router/types'
import complex from './complex'
import builder from './builder'
import news from './news'
import requestDistribution from './request-distribution'
import resultManagement from './result-management'
import companyPeriodResultManagement from './company-period-result-management'
import dictPermissionRules from '@/Permissions/dictRules'
import loadingObjects from './loading-objects'

export default {
  path: RouteKeys.newbuildings,
  name: RouteKeys.newbuildings,
  meta: { name: 'Новостройки', icon: 'newbuildings', hideIsNotChildren: true },
  redirect: { name: `${RouteKeys.newbuildings}-selection` },
  component: () => import('@/views/Newbuildings/Index.vue'),
  children: [
    complex,
    builder,
    news,
    {
      path: 'request-to-developer',
      name: `${RouteKeys.newbuildings}-request-to-developer`,
      meta: { name: 'Фиксации и бронирования', icon: 'fixations-and-bookings', ruleNamespace: dictPermissionRules.AppServicePermissionRulesOBJECTSNewBuildingsBuilderRequestBuilderRequestManagementRule },
      component: () => import('@/views/Newbuildings/Management/Request/List.vue')
    },
    resultManagement,
    companyPeriodResultManagement,
    loadingObjects,
    requestDistribution
  ]
}
