<template>
  <div
    v-if="isManagementMenu && menu"
    key="management"
    class="tw-flex tw-flex-col tw-flex-grow tw-overflow-y-auto tw-scrollbar tw-pl-3 tw-pr-1 tw-py-0"
  >
    <TwSidebarMainMenuItem
      v-for="(menuItem, index) in menu.management"
      :key="index"
      :item="menuItem"
      :index="index"
      :is-mini-left-panel="isMiniLeftPanel"
    />
  </div>
  <div
    v-else-if="!isManagementMenu && menu"
    key="base"
    class="tw-flex tw-flex-col tw-flex-grow tw-overflow-y-auto tw-scrollbar tw-pl-3 tw-pr-1 tw-py-0"
  >
    <TwSidebarMainMenuItem
      v-for="(menuItem, index) in menu.base"
      :key="index"
      :item="menuItem"
      :index="index"
      :is-mini-left-panel="isMiniLeftPanel"
    />
  </div>
  <div
    v-else
    class="tw-text-an-flat-grey-4"
  >
    Подождите...
  </div>
</template>

<script>
import TwSidebarMainMenuItem from '@/components/tw-ui/menu/TwSidebarMainMenuItem.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'TwSidebarMainMenu',
  components: { TwSidebarMainMenuItem },
  props: {
    isMiniLeftPanel: {
      type: Boolean,
      default: false
    },
    isManagementMenu: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      menu: 'mainMenu/menu'
    })
  }
}
</script>

<style scoped>

</style>
