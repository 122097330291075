import { AxiosResponse } from 'axios'
import HTTP from '@/Api/http'

import { AuthAxiosDefaultConfig } from '@/Api/Messenger'

const path = '/im/chat'

export default {
  getList (param?: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig,
      params: param
    }
    return HTTP.get(`${path}`, $config)
  },
  create (): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.get(`${path}/create`, $config)
  },
  store (data: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.post(`${path}`, data, $config)
  },
  edit (id: number): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.get(`${path}/${id}`, $config)
  },
  update (id: number, data: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.put(`${path}/${id}`, data, $config)
  },
  readChatList (chatId: number | string, paramsChat: any): Promise<AxiosResponse> {
    const { after, before, limit } = paramsChat
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.get(`${path}/${chatId}/message?afterId=${after}&beforeId=${before}&limit=${limit}`, $config)
  },
  getUnviewedMsg (): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.get(`${path}/unviewed`, $config)
  },
  sendChatList (chatId: number | string, data: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.post(`${path}/${chatId}/message`, data, $config)
  },
  show (id: number | string): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.get(`${path}/${id}`, $config)
  },
  delete (id: number): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.delete(`${path}/${id}`, $config)
  },
  downloadFile (chatId: number, fileId: number, fileName?: string): Promise<any> {
    return new Promise(() => {
      // console.warn('fetch token ', token)
      return HTTP.get(`${path}/${chatId}/file/${fileId}/download`, {
        ...AuthAxiosDefaultConfig,
        withCredentials: false,
        responseType: 'blob'
      }).then((response) => {
        console.warn('response: ', response)
        const anchor = document.createElement('a')
        document.body.appendChild(anchor)
        const blob = new Blob([response.data], { type: response.data?.type || 'text/bin' })
        // console.warn('blob: ', blob)
        const url = window.URL.createObjectURL(blob)
        console.warn('url: ', url)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName || 'Без названия') // or any other extension
        document.body.appendChild(link)
        link.click()
        window.URL.revokeObjectURL(url)
      }).catch((error) => {
        console.warn('error: ', error)
      })
    })
  }
}
