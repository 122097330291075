import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  props: {
    schemaKey: {
      type: String,
      required: true
    }
  },
  methods: {
    ...mapActions({
      initCurrentFilters: 'twFilter/initCurrentFilters',
      fetchFilterService: 'twFilter/fetchFilterService',
      saveUserFields: 'twFilter/saveUserFields',
      changeCurrentFields: 'twFilter/changeCurrentFields'
    }),
    ...mapMutations({
      setCurrentFieldValue: 'twFilter/SET_CURRENT_FILTER_VALUE_BY_KEY',
      clearCurrentFilterField: 'twFilter/CLEAR_CURRENT_FILTER_FIELD_BY_KEY'
    }),
    // инит схемы фильтров
    async initFiltersBySchemaKey () {
      console.warn('initFiltersBySchemaKey', this.schemaKey)
      if (this.schemaKey) {
        // Если нет фильтров
        if (!Array.isArray(this.currentFilterFields) || !this.currentFilterFields.length) {
          // получение схемы фильтров
          await this.fetchFilterService(this.schemaKey)
          // инит полученного
          await this.initCurrentFilters(this.schemaKey)
        }

        this.$nextTick(() => {
          this.onSearchByActiveFields()
        })
      } else {
        return new Error(`Не указан ключ схемы фильтров: ${this.schemaKey}`)
      }
    },
    onSearchByActiveFields (event) {
      if (event) {
        event.target.blur()
      }
      const fields = {}
      // инициирую активные фильтры
      if (Array.isArray(this.currentFilterFields)) {
        for (const field of this.currentFilterFields.filter(f => f.inputValue !== undefined)) {
          this.$set(fields, field.key, field.inputValue)
        }
      }
      this.$emit('search', fields)
    },
    onClearAll () {
      console.log('onClearAll', this.schemaKey)
      this.initCurrentFilters(this.schemaKey)
      this.$emit('search', {})
    },
    removeField (field) {
      console.log('removeField', field, this.schemaKey)
      const targetIndex = this.currentFilterFields.findIndex(item => item.key === field.key)
      if (targetIndex !== -1) {
        this.currentFilterFields.splice(targetIndex, 1)
      }
    },
    addField (field) {
      console.log('addField', field, this.schemaKey)
      this.currentFilterFields.push(field)
    }
  },
  computed: {
    ...mapGetters({
      getCurrentFilterSchemaByKey: 'twFilter/getCurrentFilterSchemaByKey',
      getFilterSchemaByKey: 'twFilter/getFilterSchemaByKey',
      filterLoadingByKey: 'twFilter/filterLoadingByKey',
      getUserFieldsByKey: 'twFilter/getUserFieldsByKey'
    }),
    // Статус загрузки фильтров по текущему ключу схемы
    filterLoading () {
      return this.filterLoadingByKey(this.schemaKey)
    },
    // Все доступные фильтры
    allFiltersSchema () {
      return this.getFilterSchemaByKey(this.schemaKey)
    },
    // отключение юзабилити - если ошибка
    disabled () {
      return !!this.allFiltersSchema?.error
    },
    // Описание для схемы с сервера
    schemaDescription () {
      return this.allFiltersSchema?.description || null
    },
    // Все сохраненные фильтры по ключу
    allUserFiltersFields () {
      return this.getUserFieldsByKey(this.schemaKey)
    },
    // Поля фильтров которые изменены пользователем
    usageActiveFilterFields () {
      return (Array.isArray(this.currentFilterFields) && this.currentFilterFields.filter(f => {
        if (Array.isArray(f.inputValue)) {
          return !!f.inputValue.length
        }
        return f.key !== 'Deal.type' && f.inputValue !== undefined
      })) || []
    },
    // Поля фильтров которые изменены пользователем
    usageActiveFilterForInputBadges () {
      const result = []
      for (const index in this.usageActiveFilterFields) {
        const field = this.usageActiveFilterFields[index]
        if (Array.isArray(field.inputValue) && !field.inputValue.length) {
          continue
        }
        if (result.length < 3) {
          result.push(field)
        } else {
          result.push({
            key: 'moreLabels',
            label: `и ещё ${this.usageActiveFilterFields.length - 3}`,
            inputText: '\n' + this.usageActiveFilterFields.filter((item, ix) => index <= ix).map((item) => {
              return `${item.label}: ${item.inputText || item.inputValue || ''}`
            }).join('\n')
          })
          break
        }
      }
      return result
    },
    // Поля фильтров которые показаны пользователю
    currentFilterFields () {
      return this.getCurrentFilterSchemaByKey(this.schemaKey) || []
    }
  }
}
