import { RouteKeys } from '@/router/types'
import dictPermissionRules from '@/Permissions/dictRules'

export default {
  path: RouteKeys.complex,
  name: RouteKeys.complex,
  redirect: { name: `${RouteKeys.complex}-list` },
  meta: { name: 'ЖК и КП', icon: 'residential-complexes', ruleNamespace: dictPermissionRules.AppServicePermissionRulesOBJECTSNewBuildingsComplexReadComplexNBRule },
  component: () => import('@/views/ObjectStorage/Index.vue'),
  children: [
    {
      path: 'list',
      name: `${RouteKeys.complex}-list`,
      meta: { name: 'ЖК и КП', icon: 'objects', hideInMenu: true },
      component: () => import('@/views/Newbuildings/Complex/List.vue')
    },
    {
      path: ':id/edit',
      name: `${RouteKeys.complex}-edit`,
      meta: { name: 'Редактирование', icon: 'objects', hideInMenu: true },
      component: () => import('@/views/Newbuildings/Complex/RealtyComplexCard.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    },
    {
      path: 'create',
      name: `${RouteKeys.complex}-create`,
      meta: { name: 'Добавление', icon: 'objects', hideInMenu: true },
      component: () => import('@/views/Newbuildings/Complex/RealtyComplexCard.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    },
    {
      path: ':id',
      name: `${RouteKeys.complex}-show`,
      meta: { name: 'Просмотр', icon: 'objects', hideInMenu: true },
      component: () => import('@/views/Newbuildings/Complex/RealtyComplexCard.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    },
    {
      path: 'view/:id', // для открытия в новой вкладке использовать openRealtorComplexView() из import { openRealtorComplexView } from '@/views/Newbuildings/Selection/Dict'
      name: `${RouteKeys.view}-${RouteKeys.complex}`,
      meta: { name: 'Информация о ЖК', icon: 'objects', hideInMenu: true, hText: 'Информация о ЖК' },
      component: () => import('@/views/Newbuildings/Complex/RealtyComplexPage/RealtyComplexPage.vue'),
      props: (route:any) => {
        console.error(route.params)
        return ({ id: route.params.id })
      }
    }
  ]
}
