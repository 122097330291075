<template>
  <div class="tw-min-w-[450px]">
    <TwInputText
      v-model="kadNum"
      type="text"
      autocomplete="off"
      placeholder="Введите кад. номер. Пример: 16:50:140401:154"
      :cleanable="false"
      :dense="true"
      :disabled="loading"
      :show-label="false"
      @enter="getKadNumInfo"
    >
      <template #append>
        <button
          class="tw-bg-an-flat-blue-btn tw-text-white tw-px-[7px] tw-py-[3px] tw-rounded-sm disabled:tw-opacity-50"
          :disabled="loading || !kadNum"
          @click="getKadNumInfo"
        >
          Найти по кад. номеру
        </button>
      </template>
    </TwInputText>
  </div>
</template>

<script>
import httpFetch from '@/Api/httpFetch'
import TwInputText from '@/components/tw-ui/ui-kit/input/TwInputText.vue'

export default {
  name: 'PkkSearchByKadNum',
  components: { TwInputText },
  props: {
    value: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      controllerAbort: null,
      loading: false,
      kadNum: '', // 16:50:140401:751     16:50:140401:154     16:50:140401:146
      kadNumJsonData: null
    }
  },
  methods: {
    getKadNumInfo () {
      if (this.loading && this.controllerAbort) {
        this.controllerAbort.abort()
      }
      this.controllerAbort = new AbortController()
      this.loading = true
      this.kadNumJsonData = null
      // нужно почстить строку от нулей в начале каждого из чисел
      const kadNumCleaned = (this.kadNum || '').split(':').map(n => Number(n)).join(':')
      httpFetch('https://pkk.rosreestr.ru/api/features/1/' + kadNumCleaned, {
        signal: this.controllerAbort.signal
      }).then((r) => {
        return r.json()
      }).then((jsonData) => {
        console.log(jsonData)
        this.kadNumJsonData = jsonData
        if (jsonData.feature?.center) {
          this.$emit('changeCenter', [
            jsonData.feature.center.x,
            jsonData.feature.center.y
          ])
        } else {
          alert('Не удалось найти учаток с таким номером - проверьте правильность ввода')
        }
      })
        .catch((error) => {
          console.error('Ошибка при запросе', error)
          alert('Ошибка при запросе к Росреестру')
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped>

</style>
