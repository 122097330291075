<template>
  <TwPanelContentBlock
    class="tw-mb-[6px]"
    no-data-text="Отсутствуют"
  >
    <template #title>
      <div>
        Связи
      </div>
    </template>
    <div
      v-if="dealRelation"
      class="tw-flex tw-gap-[14px] tw-flex-col"
    >
      <TwPanelContentBlockFieldView
        v-if="dealRelation.parentDeal"
        no-data-text="Отсутствует"
      >
        <template #title>
          Родительская заявка
        </template>
        <div v-if="dealRelation.parentDeal">
          <TwPanelContentDealInfo :deal="dealRelation.parentDeal" />
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView
        v-if="Array.isArray(dealRelation.childDeals) && dealRelation.childDeals.length"
        no-data-text="Отсутствуют"
      >
        <template #title>
          Связанные заявки
        </template>
        <div class="tw-flex tw-flex-col tw-gap-[8px]">
          <TwPanelContentDealInfo
            v-for="(deal, dIndex) in dealRelation.childDeals"
            :key="dIndex"
            :deal="deal"
          />
        </div>
      </TwPanelContentBlockFieldView>
    </div>
  </TwPanelContentBlock>
</template>

<script>
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwPanelContentDealInfo from '@/components/tw-ui/panels/TwPanelContentDealInfo.vue'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'

export default {
  name: 'DealRelationsList',
  components: { TwPanelContentBlockFieldView, TwPanelContentDealInfo, TwPanelContentBlock },
  props: {
    dealRelation: {
      default: null
    }
  }
}
</script>

<style scoped>

</style>
