<template>
  <div>
    <div class="tw-flex tw-gap-[20px] tw-justify-around">
      <div class="tw-flex-1">
        <div class="tw-text-main-xs">
          Минуты:
        </div>
        <input
          v-model.number="minutes"
          class="tw-text-right tw-w-full tw-py-[8px] tw-px-[12px] tw-border tw-border-solid tw-rounded-sm"
          :class="[minutesError ? 'tw-outline-an-flat-red-main' : ' tw-outline-an-flat-blue-btn31']"
          type="text"
          placeholder="0"
          @focus="$event.target && $event.target.select()"
        >
        <div
          v-if="minutesError"
          class="tw-my-[3px] tw-text-main-xs2 tw-text-an-flat-red-main tw-text-right"
        >
          {{ minutesError }}
        </div>
      </div>
      <div class="tw-flex-1">
        <div class="tw-text-main-xs">
          Часы:
        </div>
        <input
          v-model.number="hours"
          class="tw-text-right tw-w-full tw-py-[8px] tw-px-[12px] tw-border tw-border-solid tw-rounded-sm"
          :class="[hoursError ? 'tw-outline-an-flat-red-main' : ' tw-outline-an-flat-blue-btn31']"
          type="text"
          placeholder="0"
          @focus="$event.target && $event.target.select()"
        >
        <div
          v-if="hoursError"
          class="tw-my-[3px] tw-text-main-xs2 tw-text-an-flat-red-main tw-text-right"
        >
          {{ hoursError }}
        </div>
      </div>
      <div class="tw-flex-1">
        <div class="tw-text-main-xs">
          Дни:
        </div>
        <input
          v-model.number="days"
          class="tw-text-right tw-w-full tw-py-[8px] tw-px-[12px] tw-border tw-border-solid tw-rounded-sm"
          :class="[daysError ? 'tw-outline-an-flat-red-main' : ' tw-outline-an-flat-blue-btn31']"
          type="text"
          placeholder="0"
          @focus="$event.target && $event.target.select()"
        >
        <div
          v-if="daysError"
          class="tw-my-[3px] tw-text-main-xs2 tw-text-an-flat-red-main tw-text-right"
        >
          {{ daysError }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputMinHourDayFrom',
  props: {
    value: {
      type: String,
      default: null
    },
    fromDate: {
      type: String,
      required: true
    },
    isIncrement: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      minutes: 0,
      hours: 0,
      days: 0
    }
  },
  computed: {
    minutesError () {
      return (this.minutes >= 0 && this.minutes <= 59) ? false : 'от 0 до 59 минут'
    },
    hoursError () {
      return (this.hours >= 0 && this.hours <= 23) ? false : 'от 0 до 23 часов'
    },
    daysError () {
      return (this.days >= 0 && this.days <= 7) ? false : 'от 0 до 7 дней'
    },
    calcTime () {
      let result = 0
      result += this.minutes > 0 ? (1000 * 60) * this.minutes : 0
      result += this.hours > 0 ? (1000 * 60 * 60) * this.hours : 0
      result += this.days > 0 ? (1000 * 60 * 60 * 24) * this.days : 0
      return result
    },
    resultDate () {
      const datetime = new Date(this.fromDate).getTime()
      if (this.isIncrement) {
        return new Date((datetime) + this.calcTime).toISOString()
      } else {
        return new Date((datetime) - this.calcTime).toISOString()
      }
    }
  },
  watch: {
    resultDate (newValue) {
      if (!this.minutes && !this.hours && !this.days) {
        this.$emit('input', null)
      } else {
        this.$emit('input', newValue)
      }
    }
  },
  mounted () {
    if (this.value && this.fromDate) {
      const datesDiffObj = this.$Global.DateFormat.datesDiffObj(new Date(this.fromDate), new Date(this.value))
      // console.warn({ datesDiffObj })
      this.days = datesDiffObj.days
      this.hours = datesDiffObj.hours
      this.minutes = datesDiffObj.minutes
    }
  }
}
</script>

<style lang="less">
</style>
