<template>
  <div>
    <button
      class="ml-5 card-edit-button item-hidden-block"
      @click.stop="openEdit"
    >
      Редактировать
    </button>
  </div>
</template>

<script>
export default {
  name: 'ButtonEditor',
  methods: {
    openEdit () {
      this.$emit('openEdit')
    }
  }
}
</script>
