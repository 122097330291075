<template>
  <v-text-field
    v-input-allow-regex="'[0-9.]'"
    type="text"
    :label="label"
    autocomplete="off"
    :value="spacedValue"
    :rules="requireRules"
    :persistent-placeholder="persistentPlaceholder"
    :error-messages="errorMessages"
    v-bind="$props"
    :required="required"
    :disabled="disabled"
    :suffix="suffix"
    @focus="$event.target.select()"
    @input="input"
  />
</template>

<script>
export default {
  name: 'InputPriceSpaced',
  props: {
    value: {
      default: null
    },
    label: {
      type: String,
      default: ''
    },
    suffix: {
      type: String,
      default: ''
    },
    errorMessages: {},
    requireRules: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    persistentPlaceholder: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    spacedValue () {
      // визуальный показ числа разделенного пробелами, для этого привожу его к строковому виду
      // console.warn('spacedValue', typeof this.value, typeof this.value === 'number' ? this.value.toLocaleString() : '0')
      return typeof this.value === 'number' ? this.value.toLocaleString() : null
    }
  },
  methods: {
    input (newVal) {
      // eslint-disable-next-line no-irregular-whitespace,no-param-reassign
      newVal = newVal.replace(/ | /g, '').replace(',', '.').replace(/[^0-9.]+/g, '') // убрать пробелы и заменить запятую на точку
      // console.log('newVal', newVal, !isNaN(parseFloat(newVal)) ? parseFloat(newVal) : 0)
      // отправить родителю если удалось спарсить число или 0
      this.$emit('input', !isNaN(parseFloat(newVal)) ? parseFloat(newVal) : null)
    }
  }
}
</script>
