<template>
  <div class="py-2 body-2">
    <v-card
      v-if="defaultEmailConfig"
      tile
      outlined
    >
      <v-form ref="form">
        <div style="display: none">
          {{ isEdited }}
        </div>
        <v-card-text>
          <span>От кого:</span>
          <span
            class="mx-2 primary--text"
            v-text="`${currentUserName} <${defaultEmailConfig.email}>`"
          />
        </v-card-text>
        <v-divider />
        <v-card-text>
          <v-row align="start">
            <v-col cols="auto">
              Кому:
            </v-col>
            <v-col>
              <EmailsContactsAsyncSelect
                v-model="form.contacts"
                placeholder="Выберите получателей"
                chip-color=""
                :rules="requireArrayRules"
                multiple
              />
            </v-col>
          </v-row>
          <v-row align="start">
            <v-col cols="auto">
              Тема:
            </v-col>
            <v-col>
              <v-text-field
                v-model="form.theme"
                class="ma-0 pa-0"
                :rules="requireRules"
                placeholder="Введите тему сообщения"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text class="pt-0">
          <QuillEmailEditor
            v-model="form.body"
            :rules="requireRules"
            min-height="100px"
            max-height="500px"
          />
          <div
            v-if="preset.signature"
            style="border: 1px solid #cccccc; border-top: none"
            v-html="preset.signature"
          />
        </v-card-text>
        <template v-if="forDeal">
          <v-divider />
          <v-card-text>
            Заявка: <v-chip
              class="ml-2"
              label
              small
            >
              {{ forDeal.type && forDeal.type.label }} #{{ forDeal.id }}
            </v-chip>
          </v-card-text>
          <v-divider />
        </template>
        <v-card-text class="pt-0">
          <div class="my-3 d-flex justify-center">
            <strong>Прикреплённые файлы:</strong>
            <v-spacer />
            <PersonSelectDocumentFiles
              v-if="customPersonIds.length"
              :value="form.crmFileId"
              :target-persons="customPersonIds"
              @input="onChangePersonFileIds"
            />
          </div>
          <div>
            <ShowClientsFileList
              v-model="form.crmFileId"
              :file-client="[]"
              no-data-text="Нет файлов"
              height="100px"
            />
          </div>
          <UploadEmailFileList
            v-model="form.filesId"
            :file-client="[]"
            no-data-text="Нет файлов"
            height="100px"
          />
        </v-card-text>
      </v-form>
      <div style="position: sticky;bottom: 0;">
        <v-divider />
        <v-card-actions class="white">
          <v-btn
            color="primary"
            :disabled="sendingProcess"
            @click="handleSubmit('sent')"
          >
            Сохранить и отправить
          </v-btn>
          <v-btn
            outlined
            :disabled="sendingProcess"
            color="primary"
            @click="handleSubmit('draft')"
          >
            Сохранить черновик
          </v-btn>
          <v-spacer />
          <v-btn
            outlined
            color="secondary"
            :disabled="sendingProcess"
            @click="$emit('cancel')"
          >
            Отменить
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
    <v-card v-else>
      <v-card-text>
        <strong>Отстуствует прикреплённый Email для отправки писем!</strong>
      </v-card-text>
      <v-divider />
      <v-card-text class="text-center">
        Добавьте почтовый ящик в настройках профиля!
        <v-btn
          class="mx-2"
          small
          outlined
          @click="showProfile"
        >
          Открыть профиль
        </v-btn>
      </v-card-text>
    </v-card>

    <v-overlay :value="sendingProcess">
      <div class="text-center">
        <v-progress-circular
          indeterminate
          size="64"
        />
        <div class="mt-2">
          Письмо отправляется!<br>
          Пожалуйста подождите...
        </div>
      </div>
    </v-overlay>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import profileAPI from '@/Api/Auth/Profile/index'
import emailAPI from '@/Api/Email/Mail'
import QuillEmailEditor from '@/components/text-editor/QuillEmailEditor'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import EmailsContactsAsyncSelect from '@/components/select/email/EmailsContactsAsyncSelect'
import UploadEmailFileList from '@/views/Email/file/UploadEmailFileList.vue'
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import PersonSelectDocumentFiles from '@/components/select/person/PersonSelectDocumentFiles'
import ShowClientsFileList from '@/views/Email/file/ShowClientsFileList.vue'

export default {
  name: 'EmailMainForm',
  components: { ShowClientsFileList, PersonSelectDocumentFiles, UploadEmailFileList, EmailsContactsAsyncSelect, QuillEmailEditor },
  mixins: [FormCreateEditMixin, ViewPanelShowMixin],
  props: {
    // инфа о прикрепляемой сделке
    forDeal: {
      default: null
    },
    toPerson: {
      default: null
    }
  },
  data () {
    return {
      API: emailAPI,
      form: {
        folderId: '',
        theme: '',
        body: '',
        contacts: [],
        filesId: [],
        crmFileId: []
      },
      sendingProcess: false
    }
  },
  methods: {
    onChangePersonFileIds (data) {
      console.warn('onChangePersonFileIds:', data)
      // this.form.filesId = data || []
      this.$set(this.form, 'crmFileId', data || [])
    },
    handleSubmit (type) {
      console.warn(this.$options.name, ' handleSubmit ', type, this.form, this.formType, this.targetId)

      if (this.$refs.form.validate()) {
        if (this.sendingProcess) {
          return
        }
        this.sendingProcess = true
        this.form.folderId = type === 'draft' ? this.defaultDraftFolder?.id : this.defaultOutFolder?.id
        if (this.formType === 'create') {
          console.warn(this.$options.name, ' CREATE ', this.form)
          this.API.store(this.form).then(({ data }) => {
            console.warn(this.$options.name, ' CREATE+ ', data)
            this.$emit('create', data.data)
            this.$store.commit('notify/ADD', {
              title: type === 'draft' ? 'Черновик создан' : 'Email отправлен',
              type: 'success',
              timeout: 3000,
              visible: true
            })
          }).finally(() => {
            this.sendingProcess = false
          })
        } else if (this.formType === 'edit' && this.targetId) {
          // если как черновик то редактирую
          if (type === 'draft') {
            this.API.update(this.targetId, this.form).then(({ data }) => {
              console.warn(this.$options.name, ' UPDATE ', data.data)
              this.$emit('update', data.data)
              this.$store.commit('notify/ADD', {
                title: 'Черновик отредактирован',
                type: 'success',
                timeout: 3000,
                visible: true
              })
            })
              .catch((error) => {
                console.error('update draft:', error)
              })
              .finally(() => {
                this.sendingProcess = false
              })
          } else {
            // если как отправка то отправляю
            this.API.send_draft(this.targetId || this.id, this.form)
              .then((data) => {
                this.$emit('update', data.data)
                this.$store.commit('notify/ADD', {
                  title: 'Email отправлен',
                  type: 'success',
                  timeout: 3000,
                  visible: true
                })
              })
              .catch((error) => {
                console.error('send_draft:', error)
              })
              .finally(() => {
                this.sendingProcess = false
              })
          }
        } else {
          alert(`Error handleSubmit: ${this.$options.name}`)
        }
      }
    },
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          const adaperMap = {
            // from(show): to(send)
            emailFolder: 'emailFolderId',
            files: 'filesId',
            crmFileId: 'crmFileId',
            contacts: 'contacts',
            body: 'body',
            theme: 'theme'
          }
          for (const key in this.preset) {
            if (adaperMap[key] !== undefined) {
              if (key === 'files' && Array.isArray(this.preset[key])) {
                this.$set(this.form, adaperMap[key], this.preset[key].map(f => f.id) || [])
              } else if (key === 'contacts') {
                this.$set(this.form,
                  adaperMap[key],
                  Array.isArray(this.preset[key]) && this.preset[key].length ? this.preset[key]
                    .filter(c => {
                      return !!c.email
                    })
                    .map(c => {
                      return {
                        id: c.id,
                        name: c.name,
                        email: c.email
                      }
                    }) : [])
              } else {
                this.$set(this.form, adaperMap[key], this.preset[key])
              }
            }
          }
        }

        this.initProps()
        resolve()
      })
    },
    initProps () {
      if (Array.isArray(this.toPerson?.additionalContacts)) {
        const emails = this.toPerson.additionalContacts.filter(contact => contact.type === 'email')
        if (emails.length) {
          // console.warn('!!!!!!!!!!!emails', emails)
          this.form.contacts.push({
            name: `${this.toPerson.name} ${this.toPerson.surname}`,
            email: emails[0].contact
          })
        }
      }
    },
    showProfile () {
      this.showMyProfilePanel({}, () => {
        profileAPI.getProfile()
          .then((responseProfile) => {
            console.warn(responseProfile.data.data)
            this.$store.commit('profile/setUser', responseProfile.data.data)
            this.$store.dispatch('profile/fetchEmailConfigs')
          })
      })
    }
  },
  computed: {
    ...mapGetters({
      currentUserName: 'profile/fullName',
      UserEmailConfigs: 'profile/UserEmailConfigs',
      emails: 'profile/emails'
    }),
    // список ID клиентов - будет использован для прикрепления файлов клиента к письму
    customPersonIds () {
      return this.forDeal ? [this.forDeal?.person?.id, ...(this.forDeal?.otherPersons || []).map(p => p.id)] : []
    },
    defaultEmailConfig () {
      return Array.isArray(this.UserEmailConfigs) &&
        this.UserEmailConfigs.length &&
        this.UserEmailConfigs.find(item => {
          return item.useDefault
        })
    },
    defaultDraftFolder () {
      return Array.isArray(this.UserEmailConfigs) && this.UserEmailConfigs.length && this.UserEmailConfigs[0]?.emailFolders.find(f => f.type && f.type.value === 'draft')
    },
    defaultOutFolder () {
      return Array.isArray(this.UserEmailConfigs) && this.UserEmailConfigs.length && this.UserEmailConfigs[0]?.emailFolders.find(f => f.type && f.type.value === 'sent')
    }
  },
  mounted () {
    this.form.folderId = this?.defaultOutFolder?.id
  }
}
</script>
