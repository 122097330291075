import { AxiosResponse } from 'axios'
import HTTP from '@/Api/http'
import { AuthAxiosDefaultConfig } from '@/Api/ObjectStorage'

const path = '/dadata'

export default {
  address (address: string): Promise<AxiosResponse> {
    const $config = {
      params: {
        address
      },
      ...AuthAxiosDefaultConfig
    }
    return HTTP.get(`${path}/address`, $config)
  }
}
