import leadStatus from './status'
import leadSource from './source'

export default {
  path: 'lead',
  name: 'lead-dictionary',
  redirect: { name: 'lead-status-list' },
  meta: { name: 'Лиды', icon: 'leads', isAdmin: true },
  component: () => import('@/views/Dictionary/Index.vue'),
  children: [
    leadStatus,
    leadSource
  ]
}
