<template>
  <div
    v-cloak
    v-if="hasItem"
    class="tw-flex tw-gap-[12px] tw-flex-col"
  >
    <template v-for="(item, index) in info.socialMedia">
      <TwPanelContentBlockFieldView
        v-if="item.url"
        :key="index"
        no-data-text="Не указано"
      >
        <template #title>
          {{ item.label }}
        </template>
        <div
          v-if="item.url"
          class="tw-truncate"
        >
          <a
            :href="item.url"
            :title="item.url"
            target="_blank"
          >{{ item.url }}</a>
        </div>
      </TwPanelContentBlockFieldView>
    </template>
  </div>
  <div
    v-else
    class="tw-text-an-flat-disabled-text tw-text-center"
  >
    Не заполнено
  </div>
</template>

<script>
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'

export default {
  name: 'UserSocialMediaShow',
  components: { TwPanelContentBlockFieldView },
  props: {
    info: {
      default: () => {
        return {}
      }
    }
  },
  computed: {
    hasItem () {
      return this.info && this.info?.socialMedia && this.info?.socialMedia.some(i => i.url)
    }
  }
}
</script>
