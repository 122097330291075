import { radCalc, radDiffBetween } from './rotateHelpers'

export default class ElementPointerRotate {
  disableRotate = false
  currentRadian = 0
  element = null
  bb = null
  cx = 0
  cy = 0

  radian = {
    click: 0,
    last: 0,
    current: 0
  }

  pointerState = {
    UP: true,
    DOWN: false,
    MOVE: false
  }

  constructor (options) {
    this.element = options?.element || null
    this.element.addEventListener('pointercancel', this.onUp.bind(this), false)
    this.element.addEventListener('pointerup', this.onUp.bind(this), false)
    this.element.addEventListener('pointerdown', this.onDown.bind(this), false)
    this.element.addEventListener('pointermove', this.onMove.bind(this), false)
    this.element.addEventListener('resize', this.onResize.bind(this), false)
    this.element.addEventListener('click', this.onResize.bind(this), false)
    this.onResize()
  }

  update () {
    // console.warn(this)
    this.element.style.transform = 'rotate(' + this.radian.current + 'rad)'
  }

  onDown (event) {
    // console.warn('DOWN', event, this)
    event.preventDefault()
    this.onResize()
    this.element.setPointerCapture(event.pointerId)
    this.pointerState.UP = false
    this.pointerState.DOWN = true

    this.radian.click = radCalc(event.clientY - this.cy, event.clientX - this.cx)
  }

  onUp (event) {
    // console.warn('UP', event, this, this.disableRotate)
    event.preventDefault()
    this.pointerState.UP = true
    this.pointerState.DOWN = false

    this.radian.last = this.radian.current
    this.currentRadian = this.radian.current
  }

  onMove (event) {
    event.preventDefault()
    if (!this.disableRotate && this.pointerState.DOWN) {
      this.radian.current = this.radian.last + radDiffBetween(radCalc(event.clientY - this.cy, event.clientX - this.cx), this.radian.click)
      requestAnimationFrame(this.update.bind(this))
    }
  }

  onResize () {
    // console.warn('resize')
    this.bb = this.element.getBoundingClientRect()
    this.cx = this.bb.left + this.bb.width / 2
    this.cy = this.bb.top + this.bb.height / 2
  }

  setCurrentRadian (rad) {
    console.warn('setCurrentRadian', rad)
    this.radian.current = rad
    this.radian.last = rad
    this.currentRadian = rad
    requestAnimationFrame(this.update.bind(this))
  }
}
