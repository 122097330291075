<template>
  <TwPanelContent
    v-if="realtorInfo"
    class="tw-min-h-[230px] tw-flex tw-flex-wrap tw-gap-[16px] tw-mb-[12px]"
  >
    <div class="tw-w-[230px] tw-h-[230px] tw-border tw-border-solid tw-rounded-md tw-overflow-auto">
      <img
        v-if="realtorInfo.file"
        class="tw-h-full tw-w-full tw-object-cover"
        alt="Аватар"
        :src="employeeAvatarProfileGetLink(realtorInfo.file)"
      >
      <div
        v-else
        class="tw-h-full tw-w-full tw-flex tw-justify-center tw-items-center tw-text-an-flat-disabled-text"
      >
        Без аватара
      </div>
    </div>
    <div class="tw-flex-1 tw-min-w-[230px] tw-border tw-border-solid tw-rounded-md tw-p-[18px] tw-flex tw-flex-col">
      <div class="tw-text-h5">
        {{ $Global.peopleName(realtorInfo, 'fio') }}
      </div>
      <div class="tw-text-main-sm2 tw-text-an-flat-gray-4">
        <template v-if="Array.isArray(realtorInfo.userStructure) && realtorInfo.userStructure.length">
          <span
            v-for="(us, index) in realtorInfo.userStructure"
            :key="index"
            class=""
          >
            {{ us.position && us.position.name }} <StructureItemName
              prefix="["
              suffix="]"
              :item="us.structure"
              tag="span"
            /><template v-if="index < (realtorInfo.userStructure.length - 1)">,</template>
          </span>
        </template>
      </div>
      <hr class="tw-my-[6px]">
      <div class="tw-flex-1" />
      <div>
        <table class="tw-text-main-sm2">
          <tr>
            <td class="tw-text-an-flat-gray-4">
              Дата рождения:
            </td>
            <td class="tw-p-[5px]">
              <template v-if="realtorInfo.birthday">
                {{ $Global.DateFormat.DateShort(realtorInfo.birthday, true) }}
              </template>
              <template v-else>
                Не указано
              </template>
            </td>
          </tr>
          <tr>
            <td class="tw-text-an-flat-gray-4">
              Почта:
            </td>
            <td class="tw-p-[5px]">
              <template v-if="realtorInfo.email">
                {{ realtorInfo.email }}
              </template>
              <template v-else>
                Не указано
              </template>
            </td>
          </tr>
          <tr>
            <td class="tw-text-an-flat-gray-4">
              Телефон:
            </td>
            <td class="tw-p-[5px]">
              <template v-if="realtorInfo.workPhone">
                <SipCallLink
                  v-if="realtorInfo.workPhone"
                  :phone="realtorInfo.workPhone"
                />
              </template>
              <template v-else>
                Не указано
              </template>
            </td>
          </tr>
        </table>
      </div>
      <div class="tw-flex-1" />
      <hr class="tw-my-[6px]">
      <TwPanelActionButton
        class="tw-mt-[12px] tw-max-w-[200px]"
        @click.stop="onOpenUserCard"
      >
        Открыть профиль
      </TwPanelActionButton>
    </div>
    <div class="tw-w-[300px] tw-border tw-border-solid tw-rounded-md tw-border-an-flat-green tw-bg-an-flat-green/10 tw-flex tw-flex-col tw-gap-[18px] tw-p-[18px]">
      <div class="tw-text-h5">
        Заполненность карточки
      </div>
      <div class="tw-flex-1" />
      <div class="tw-text-main-sm tw-flex tw-gap-[10px] tw-items-center">
        <TwBooleanIcon :value="info.isFinishedForm">
          <template #right>
            Анкета {{ info.isFinishedForm ? 'заполнена':'не заполнена' }}
          </template>
        </TwBooleanIcon>
      </div>
      <div class="tw-text-main-sm tw-flex tw-gap-[10px] tw-items-center">
        <TwBooleanIcon :value="info.isFinishedInterview">
          <template #right>
            Интервью {{ info.isFinishedForm ? 'заполнено':'не заполнено' }}
          </template>
        </TwBooleanIcon>
      </div>
      <div class="tw-text-main-sm tw-flex tw-gap-[10px] tw-items-center">
        <TwBooleanIcon :value="info.isFinishedDeals">
          <template #right>
            Все сделки {{ info.isFinishedDeals ? 'заполнены':'не заполнены' }}
          </template>
        </TwBooleanIcon>
      </div>
    </div>
  </TwPanelContent>
</template>

<script>
import TwPanelActionButton from '@/components/tw-ui/panels/TwPanelActionButton.vue'
import SipCallLink from '@/components/telephony/SipCallLink.vue'
import TwBooleanIcon from '@/components/tw-ui/ui-kit/icons/TwBooleanIcon.vue'
import TwPanelContent from '@/components/tw-ui/panels/TwPanelContent.vue'
import { mapActions } from 'vuex'
import { employeeAvatarProfileGetLink } from '@/views/Company/Employee/employeeHelpers'
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import StructureItemName from '@/components/select/structure/StructureItemName.vue'

export default {
  name: 'UserSurveysMainShow',
  components: {
    StructureItemName,
    TwPanelContent,
    TwBooleanIcon,
    SipCallLink,
    TwPanelActionButton
  },
  mixins: [ViewPanelShowMixin],
  props: {
    info: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      realtorInfo: null
    }
  },
  methods: {
    ...mapActions({
      fetchUsersByIdFull: 'users/fetchUsersByIdFull'
    }),
    employeeAvatarProfileGetLink,
    onOpenUserCard () {
      this.showEmployeeCard({ id: this.info?.realtorId }, () => {
        this.refresh()
      })
    },
    fetchUserInfo () {
      this.fetchUsersByIdFull(this.info?.realtorId).then((result) => {
        this.realtorInfo = result
      }).catch(() => {
        this.realtorInfo = null
      })
    }
  },
  mounted () {
    this.fetchUserInfo()
  }
}
</script>
