import Vue from 'vue'
import { Module } from 'vuex'
import { RootState } from '@/store/types'
import estimationAPI from '@/Api/RealtyMiner/Estimation'
import router from '../../router'
import { API as osApi } from '@/Api/ObjectStorage'

type EstimationResult = {
  count: number;
  price: number;
  pricePerMeter: number;
  apartments: Array<never>;
}
interface MinerState {
  [key: string]: any;
}

const namespaced = true

export const realtyMiner: Module<MinerState, RootState> = {
  namespaced,
  state: {
    address: '',
    // --- Раздельно хранимые фильтры
    locationFilter: {},
    actualFilter: {},
    realtyFilter: {},
    calcOptionsFilter: {},
    // ---
    estimationLoading: false,
    estimation: null,
    dictionary: {},
    isExtendedFilters: false
  },
  actions: {
    // метод инициализации параметров подбора оценки
    fetchFiltersCreate ({ commit, dispatch, state }) {
      const { requestUuid, ...otherQueryParams } = router?.currentRoute?.query || {}
      return estimationAPI.filtersCreate({ requestUuid }).then(({ data } : any) => {
        console.warn('fetchFiltersCreate', data)
        // Инициализация фильтров - разбить дату и раскидать по нужным объектам
        commit('REPLACE_FILTER_VALUES', {
          filterKey: 'calcOptionsFilter',
          values: data.calcOptions
        })
        const { location = {}, excludeId = [], includeNotActualId = [], ...other } = data.data
        if (other.address) {
          commit('REPLACE_FILTER_VALUES', {
            filterKey: 'address',
            values: other.address
          })
        }
        commit('REPLACE_FILTER_VALUES', {
          filterKey: 'locationFilter',
          values: location
        })
        commit('REPLACE_FILTER_VALUES', {
          filterKey: 'realtyFilter',
          values: other
        })
        commit('REPLACE_FILTER_VALUES', {
          filterKey: 'actualFilter',
          values: { excludeId, includeNotActualId }
        })
        commit('SET_DICTIONARY', data.dictionary)
      }).catch((error: any) => {
        console.error('fetchFiltersCreate', error)
      }).finally(() => {
        // После инициализации параметров серверными значениями - наложить параметры из url query
        if (otherQueryParams && Object.keys(otherQueryParams).length) {
          // Парсим параметры
          for (const fieldKey in otherQueryParams) {
            let value: any = otherQueryParams[fieldKey]
            // При парсинге объекта query поле <wallMaterialGroup> с одним элементом считается стрингом - исправляю превращая в массив
            if (fieldKey === 'wallMaterialGroup' && typeof value === 'string') {
              value = [otherQueryParams[fieldKey]]
            }
            if (Array.isArray(otherQueryParams[fieldKey])) {
              value = value.map((v: any) => isNaN(Number(v)) ? v : Number(v))
            } else {
              value = isNaN(Number(value)) ? value : Number(value)
            }
            otherQueryParams[fieldKey] = value
          }
          const { lat, lon, radius, ...generalFilters } = otherQueryParams
          commit('REPLACE_FILTER_VALUES', {
            filterKey: 'realtyFilter',
            values: {
              ...state.realtyFilter,
              ...generalFilters
            }
          })

          commit('REPLACE_FILTER_VALUES', {
            filterKey: 'locationFilter',
            values: {
              ...(!lat && !lon) ? {
                // Если координат нет в query - использую дефолтные
                lat: state.locationFilter.lat,
                lon: state.locationFilter.lon,
                // в этом случае и адрес берется дефолтный - чтобы избежать геокодинга координат яндексом
                address: (!lat && !lon) ? state.state?.address : ''
              } : {
                lat,
                lon
              },
              radius: radius || state.locationFilter.radius || 1000
            }
          })
          dispatch('fetchEstimation')
        }
      })
    },
    async changeLocation ({ commit }, payload) {
      // console.warn('changeLocation', payload)
      // При изменении точки местоположения
      // запрашиваю информацию о доме по адресу новой локации
      if (payload.address) {
        commit('SET_LOADING_ESTIMATION', true)
        const { data: searchResult = null } = await osApi.Fias.searchNew({ address: payload.address }).catch(() => { return {} })
        // результат содержит массив объектов
        if (Array.isArray(searchResult?.data) && searchResult?.data.length) {
          // беру первый объект массива
          const [firstResult] = searchResult.data
          // console.warn('changeLocation === ', firstResult)
          // смотрю есть ли информация о доме
          if (firstResult?.house) {
            const { id, prop } = firstResult.house
            // ЕСЛИ в параметре house.prop есть buildYear, в котором должен быть год постройки дома, но не всегда он там есть
            if (prop?.buildYear) {
              // Заполняю год постройки в форме
              // console.warn('changeLocation houseData BUILD YEAR ++++: ', prop?.buildYear)
              if (prop?.buildYear) {
                commit('SET_FILTER_FIELD_VALUE', { fieldKey: 'buildYear', filterKey: 'realtyFilter', value: prop?.buildYear })
              }
            } else {
              // Если его нет, то отправляем запрос на /reform-zkh/house/{uuid}, где uuid - это house.id из предыдущего запроса, год постройки должен быть в параметре
              const { data: houseInfo = null } = await osApi.ReformZkh.searchHouse(id).catch(() => { return {} })
              // получаю год постройки
              const buildYear = houseInfo?.data?.prop?.buildYear || null
              const floorCountMax = houseInfo?.data?.prop?.floorCountMax || null
              const wallMaterialGroup = houseInfo?.data?.prop?.wallMaterialGroup || null
              // Заполняю год постройки в форме
              // console.warn('changeLocation houseData ++++: ', buildYear)
              if (buildYear) {
                commit('SET_FILTER_FIELD_VALUE', { fieldKey: 'buildYear', filterKey: 'realtyFilter', value: buildYear })
              }
              if (floorCountMax) {
                commit('SET_FILTER_FIELD_VALUE', { fieldKey: 'floorCount', filterKey: 'realtyFilter', value: floorCountMax })
              }
              if (wallMaterialGroup) {
                commit('SET_FILTER_FIELD_VALUE', { fieldKey: 'wallMaterialGroup', filterKey: 'realtyFilter', value: [wallMaterialGroup] })
              }
            }
            // console.warn('changeLocation == ', firstResult.house)
          }
        }
        commit('SET_LOADING_ESTIMATION', false)
      }
      commit('SET_LOCATION', payload)
    },
    fetchEstimation ({ commit, getters, state }) {
      if (state.estimationLoading) return
      // предварительная очистка данных предыдущего запроса
      // commit('SET_ESTIMATION', {})
      commit('SET_LOADING_ESTIMATION', true)
      console.warn('fetchEstimation mergedFilters FILTERS', getters.mergedFilters, state)
      return new Promise((resolve: any) => {
        estimationAPI.analyzeApartment(getters.mergedFilters).then(({ data } : any) => {
          console.warn('fetchEstimation', data.data)
          // Помещаю ID запроса в URL
          const currUuid = router?.currentRoute?.query?.requestUuid || null
          // Проверка дабы избежать дублирования навигации при одинаковом uuid
          if (data?.data?.uuid && currUuid !== data?.data?.uuid) {
            router.replace({
              query: { requestUuid: data?.data?.uuid }
            }).catch()
          }
          // Заполняю данные
          commit('SET_ESTIMATION', data.data || {})
          resolve(data.data)
        }).finally(() => {
          commit('SET_LOADING_ESTIMATION', false)
        })
      })
    },
    setItemExcluded ({ commit }, targetItemId) {
      commit('CHANGE_FIELD_EXCLUDE', targetItemId)
    },
    setItemIncludeNotActual ({ commit }, targetItemId) {
      commit('CHANGE_FIELD_INCLUDE_NOT_ACTUAL', targetItemId)
    }
  },
  mutations: {
    SET_FILTER_FIELD_VALUE (state, { filterKey, fieldKey, value }: any) {
      // console.warn('SET_FILTER_FIELD_VALUE ', { filterKey, fieldKey, value })
      // Создать объект фильтра если отсутствует
      if (state[filterKey] === undefined) {
        // state[filterKey] = {}
        Vue.set(state, filterKey, {})
      }
      // Создать поле если отсутствует
      if (state[filterKey][fieldKey] === undefined) {
        // state[filterKey][fieldKey] = null
        Vue.set(state[filterKey], fieldKey, null)
      }
      // state[filterKey][fieldKey] = value
      Vue.set(state[filterKey], fieldKey, value)
    },
    REPLACE_FILTER_VALUES (state, { filterKey, values }: any) {
      // console.warn('REPLACE_FILTER_VALUES ', { filterKey, values })
      // Создать объект фильтра если отсутствует
      if (state[filterKey] === undefined) {
        // state[filterKey] = {}
        Vue.set(state, filterKey, {})
      }
      // state[filterKey] = values
      Vue.set(state, filterKey, values)
    },
    SET_LOADING_ESTIMATION (state, status: boolean) {
      state.estimationLoading = status
    },
    CHANGE_SHOW_EX_FILTERS (state, status?: boolean) {
      // если значение булевое - применяю, иначе разворачиваю состояние
      state.isExtendedFilters = typeof status === 'boolean' ? status : !state.isExtendedFilters
    },
    SET_ESTIMATION (state, payload: EstimationResult) {
      state.estimation = payload
    },
    SET_DICTIONARY (state, payload) {
      state.dictionary = payload
    },
    CHANGE_FIELD_EXCLUDE (state, id: number) {
      if (!state.actualFilter.excludeId) state.actualFilter.excludeId = []
      const index = state.actualFilter.excludeId.indexOf(id)
      if (index === -1) {
        state.actualFilter.excludeId.push(id)
      } else {
        state.actualFilter.excludeId.splice(index, 1)
      }
    },
    CHANGE_FIELD_INCLUDE_NOT_ACTUAL (state, id: number) {
      console.warn('CHANGE_FIELD_INCLUDE_NOT_ACTUAL', id)
      if (!state.actualFilter.includeNotActualId) state.actualFilter.includeNotActualId = []
      const index = state.actualFilter.includeNotActualId.indexOf(id)
      if (index === -1) {
        state.actualFilter.includeNotActualId.push(id)
      } else {
        state.actualFilter.includeNotActualId.splice(index, 1)
      }
    },
    SET_LOCATION (state, payload: { lat: number, lon: number, address: string }) {
      state.actualFilter.excludeId = []
      state.actualFilter.includeNotActualId = []
      state.locationFilter.lat = parseFloat(payload.lat.toFixed(7))
      state.locationFilter.lon = parseFloat(payload.lon.toFixed(7))
      state.address = payload.address
    }
  },
  getters: {
    isExtendedFilters (state) {
      return !!state.isExtendedFilters
    },
    realtyFilters (state) {
      return state.realtyFilter
    },
    mergedFilters (state) {
      console.warn('mergedFilters', state.address)
      // TODO Танец с бубном, изза не совпадения формата в данных из create и формата который нужен серверу
      const { includeFirstFloors = false, includeLastFloors = false, includeMiddleFloors = false, ...otherRealtyFilter } = state.realtyFilter || {}
      const floorFilters = { includeFirstFloors, includeLastFloors, includeMiddleFloors }
      return {
        ...(state.actualFilter || {}),
        location: {
          ...(state.locationFilter || {})
        },
        floorFilters,
        ...(otherRealtyFilter || {}),
        calcOptions: {
          ...(state.calcOptionsFilter || {})
        },
        address: state.address || ''
      }
    },
    estimationLocation (state) {
      return {
        ...(state.locationFilter || {}),
        ...({ address: state.address })
      }
    },
    estimationDictionary (state) {
      return state.dictionary || {}
    },
    estimationLoading (state) {
      return state.estimationLoading
    },
    // Список объектов
    estimationApartments (state) {
      if (Array.isArray(state.estimation?.apartments)) {
        return state.estimation?.apartments.sort((a: any, b: any) => {
          return (a?.calculated === b?.calculated) ? 0 : a?.calculated ? -1 : 1
        })
      }
      return []
    },
    // Доп информация
    estimationInfo (state) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { apartments, ...info } = state.estimation || {}
      return info || {}
    }
  }
}
