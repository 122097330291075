<template>
  <div
    v-cloak
    v-if="info"
    class="tw-flex tw-gap-[12px] tw-flex-col"
  >
    <TwPanelContentBlockFieldView v-if="info.createdAt">
      <template #title>
        Дата создания
      </template>
      <div>
        {{ $Global.DateFormat.DateTimeShortMonthLong(info.createdAt) }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView v-if="info.status">
      <template #title>
        Статус
      </template>
      <div>
        {{ info.statusName }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView v-if="info.theme || info.subTheme">
      <template #title>
        Тема
      </template>
      <div>
        <div v-if="info.theme || info.subTheme">
          {{ info.theme.name }}
          <span v-if="info.subTheme">
            | {{ info.subTheme.name }}
          </span>
        </div>
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView v-if="info.description">
      <template #title>
        Описание
      </template>
      <div>
        <span v-html="info.description" />
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView v-if="info.files">
      <template #title>
        Прикреплённые файлы
      </template>
      <div>
        <SupportFilesAttached :files="info.files" />
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView v-if="info.files">
      <template #title>
        Создал
      </template>
      <div>
        <ViewPanelLink
          :id="info.userId"
          label="Пользователь"
          component="employee-show"
        >
          <EmployeeName :id="info.userId" />
        </ViewPanelLink>
      </div>
    </TwPanelContentBlockFieldView>
  </div>
</template>

<script>
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink'
import EmployeeName from '@/components/other/employee/EmployeeName'
import SupportFilesAttached from '@/views/Support/files/SupportFilesAttached'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'

export default {
  name: 'SupportMainShow',
  components: { TwPanelContentBlockFieldView, SupportFilesAttached, EmployeeName, ViewPanelLink },
  mixins: [ViewPanelShowMixin],
  props: {
    info: {},
    dictionary: {}
  }
}
</script>
