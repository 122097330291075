export const button = {
  errorBase: 'tw-bg-white tw-text-main-sm tw-border tw-border-solid tw-border-an-flat-red-main tw-text-an-flat-red-main tw-px-[10px] tw-py-[5px] tw-rounded-sm enabled:hover:tw-bg-an-flat-red-main enabled:hover:tw-text-white disabled:tw-opacity-50',
  pageListHeaderCreate: 'tw-h-fit tw-transition-all tw-bg-an-flat-blue-btn hover:tw-bg-an-flat-blue-btn-hover active:tw-bg-an-flat-blue-btn-active' +
    ' tw-text-white tw-uppercase tw-text-[16px] tw-font-medium tw-rounded-sm tw-py-[6px] tw-px-[24px]',
  // TODO написанов торопях, по хорошему нужно нормальный стиль вписать
  pageListHeaderNeutral: 'tw-h-fit tw-transition-all tw-bg-an-flat-bg-block-grey hover:tw-bg-white active:tw-bg-white' +
    ' tw-text-an-flat-blue-dark-1 tw-uppercase tw-text-[16px] tw-font-medium tw-rounded-sm tw-py-[6px] tw-px-[24px]'
}
export const textColors = {
  gray: 'tw-text-gray-500',
  red: 'tw-text-red-500',
  green: 'tw-text-green-500',
  blue: 'tw-text-blue-500',
  cyan: 'tw-text-cyan-500',
  orange: 'tw-text-orange-500',
  yellow: 'tw-text-yellow-500'
}
export const styleFormSeo = {
  fieldWrapper: 'sm:tw-flex sm:tw-flex-col sm:tw-w-full xl:tw-w-auto xl:tw-gap-[15px] 2xl:tw-w-1/2',
  blockInputs: 'tw-w-1/2 tw-flex tw-flex-col tw-gap-[15px] tw-pt-[4px] flex-auto sm:tw-w-full sm:tw-mb-[15px] xl:tw-mb-0 xl:tw-grid xl:tw-grid-cols-2',
  optionsTextarea: 'tw-w-1/2 tw-flex tw-flex-col tw-gap-[15px] tw-pt-[4px] flex-auto sm:tw-w-full xl:tw-grid xl:tw-grid-cols-2',
  restDebug: 'sm:tw-bg-an-flat-green-btn-bg md:tw-bg-an-flat-black-main lg:tw-bg-an-flat-red-main xl:tw-bg-an-flat-nb-commercial 2xl:tw-bg-an-flat-blue-dark-1'
}

export const GridWrapperForPanelShowAndForm = {
  threeCols: 'tw-grid 4xl:tw-grid-cols-3 xl:tw-grid-cols-2 lg:tw-grid-cols-1 tw-gap-y-[12px] tw-gap-x-[20px] tw-w-full'
}

export const cardWrapStyles = {
  greyWrapper: 'tw-px-[10px] tw-py-[11px] tw-rounded-md tw-bg-an-flat-grey-wrapper-block'
}
