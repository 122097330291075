<template>
  <div
    v-show="!hideProccess"
    class="tw-font-manrope"
  >
    <transition-group
      type="animation"
      tag="div"
      class="notify__container"
      :enter-active-class="`${transition}-enter-active`"
      :move-class="`${transition}-move`"
      :leave-active-class="`${transition}-leave-active`"
    >
      <template v-for="item in list">
        <NoficationItem
          :key="item.uid"
          :item="item"
          class="bottom-right [&:nth-child(n+6)]:tw-hidden"
          @hide="onHide"
        />
      </template>
      <button
        v-if="listItemsCount > 5"
        key="hide-all"
        class="btn-close-all"
        @click.stop="onHideAll"
      >
        Скрыть все уведомления
      </button>
    </transition-group>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import NoficationItem from '@/components/tw-ui/notify/NoficationItem.vue'

export default {
  name: 'NoficationGlobalView',
  components: { NoficationItem },
  data: () => ({
    transition: 'notify__fade',
    hideProccess: false
  }),
  methods: {
    ...mapMutations({
      hideAll: 'notify/HIDE_ALL',
      hide: 'notify/HIDE'
    }),
    onHide (item) {
      this.hide(item)
    },
    onHideAll () {
      this.hideProccess = true
      this.hideAll()
      setTimeout(() => {
        this.hideProccess = false
      }, 1500)
    }
  },
  computed: {
    ...mapGetters({
      list: 'notify/allVisibleItems'
    }),
    listItemsCount () {
      return Array.isArray(this.list) ? this.list.length : 0
    }
  }
}
</script>
<style scoped lang="scss">
.notify__container {
  bottom: 1em;
  right: 0;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
  z-index: 9000;
  position: fixed;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  color: #fff;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  pointer-events: none;
}

.btn-close-all {
  box-shadow: 0px 0px 8px 0px #A2ABB547;
  @apply tw-pointer-events-auto tw-py-[8px] tw-mr-[1em] tw-ml-[1em] tw-my-[6px] tw-font-medium tw-text-main-sm tw-border tw-border-solid tw-border-[#EAECF0] tw-rounded-sm tw-bg-white tw-text-an-flat-black-table tw-uppercase hover:tw-bg-[#EAECF0]
}

$trans-cubic-bezier: cubic-bezier(0.215, 0.61, 0.355, 1);
@mixin timing-function {
  animation-timing-function: $trans-cubic-bezier;
}

/* ----------------------------------------------
 * Modified version from Animista
 * Animista is Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

@keyframes fadeOutTop {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-50px);
    opacity: 0;
  }
}

@keyframes fadeOutLeft {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-50px);
    opacity: 0;
  }
}

@keyframes fadeOutBottom {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(50px);
    opacity: 0;
  }
}

@keyframes fadeOutRight {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(50px);
    opacity: 0;
  }
}

@keyframes fadeInLeft {
  0% {
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeInRight {
  0% {
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeInTop {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeInBottom {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.notify__fade-enter-active {
  &.top-left,
  &.bottom-left {
    animation-name: fadeInLeft;
  }
  &.top-right,
  &.bottom-right {
    animation-name: fadeInRight;
  }
  &.top-center {
    animation-name: fadeInTop;
  }
  &.bottom-center {
    animation-name: fadeInBottom;
  }
}

.notify__fade-leave-active {
  &.top-left,
  &.bottom-left {
    animation-name: fadeOutLeft;
  }
  &.top-right,
  &.bottom-right {
    animation-name: fadeOutRight;
  }
  &.top-center {
    animation-name: fadeOutTop;
  }
  &.bottom-center {
    animation-name: fadeOutBottom;
  }
}

.notify__fade-leave-active,
.notify__fade-enter-active {
  animation-duration: 450ms;
  animation-fill-mode: both;
}

.notify__fade-move {
  transition-timing-function: ease-in-out;
  transition-property: all;
  transition-duration: 200ms;
}
</style>
