import { AxiosResponse } from 'axios'
import HTTP from '@/Api/http'

const path = function (dealId:number): string { return `/deal/${dealId}/related-service` }

export default {
  list (dealId: number): Promise<AxiosResponse> {
    const $config = {}
    return HTTP.get(`${path(dealId)}`, $config)
  },
  show (dealId: number, code: string): Promise<AxiosResponse> {
    const $config = {}
    return HTTP.get(`${path(dealId)}/${code}`, $config)
  },
  store (dealId: number, code: string, data: any): Promise<AxiosResponse> {
    return HTTP.post(`${path(dealId)}/${code}`, data)
  }
}
