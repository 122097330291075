import { Module } from 'vuex'
import { dealObjects } from './modules/dealObjects'

const namespaced = true

export const favorite: Module<any, any> = {
  namespaced,
  state: {},
  actions: {},
  mutations: {},
  getters: {},
  modules: {
    dealObjects
  }
}
