export const routeKey = 'video-review'

export default {
  path: `${routeKey}`,
  name: routeKey,
  redirect: { name: `${routeKey}-list` },
  meta: { name: 'Видео отзывы', icon: 'reviews-videoReview' },
  component: () => import('@/views/Site/Admin/Company/VideoReview/Index.vue'),
  children: [
    {
      path: 'list',
      name: `${routeKey}-list`,
      meta: { name: 'Список видео отзывов', icon: 'reviews-videoReview', hideInMenu: true },
      component: () => import('@/views/Site/Admin/Company/VideoReview/List.vue')
    },
    {
      path: ':id/edit',
      name: `${routeKey}-edit`,
      meta: { name: 'Редактирование видео отзыва', icon: 'reviews-videoReview', hideInMenu: true },
      component: () => import('@/views/Site/Admin/Company/VideoReview/Edit.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    },
    {
      path: 'create',
      name: `${routeKey}-create`,
      meta: { name: 'Добавление видео отзыва', icon: 'reviews-videoReview', hideInMenu: true },
      component: () => import('@/views/Site/Admin/Company/VideoReview/Edit.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    },
    {
      path: ':id',
      name: `${routeKey}-show`,
      meta: { name: 'Просмотр видео отзыва', icon: 'reviews-videoReview', hideInMenu: true },
      component: () => import('@/views/Site/Admin/Company/VideoReview/Show.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    }
  ]
}
