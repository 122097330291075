<template>
  <div class="tw-flex tw-flex-row tw-gap-[15px]">
    <div title="Платёж Банк">
      {{ Math.round(payInfo.pay).toLocaleString() }}<Rouble />
      <div
        title="Экономия с Банком"
        class="success--text text-center"
      >
        <small v-if="economyInfo.economyFromBase">{{ Math.round(economyInfo.economyFromBase).toLocaleString() }}<Rouble /></small>
        <small v-else>-</small>
      </div>
      <div
        v-if="overPayInfo.sum"
        title="Переплата с Банком"
        class="secondary--text text-center"
      >
        <small>{{ Math.round(overPayInfo.sum).toLocaleString() }}<Rouble /></small>
      </div>
    </div>
    <div
      v-if="payInfo.payFlat && payInfo.payFlat !== payInfo.pay"
      title="Платёж Флэт"
      class="center"
    >
      {{ Math.round(payInfo.payFlat).toLocaleString() }}<Rouble />
      <div
        title="Экономия с Флэт"
        class="success--text text-center"
      >
        <small v-if="economyInfo.economyFlat">{{ Math.round(economyInfo.economyFlat).toLocaleString() }}<Rouble /></small>
        <small v-else>-</small>
      </div>
      <div
        v-if="overPayInfo.sumFlat"
        title="Переплата с Флэт"
        class="secondary--text text-center"
      >
        <small>{{ Math.round(overPayInfo.sumFlat).toLocaleString() }}<Rouble /></small>
      </div>
    </div>
    <div
      v-else
      class="center"
    >
      -
    </div>

    <div
      v-if="payInfo.payElectronRegistration && payInfo.payElectronRegistration !== payInfo.payFlat"
      title="Платёж Эл.рег."
    >
      {{ Math.round(payInfo.payElectronRegistration).toLocaleString() }}<Rouble />
      <div
        title="Экономия с Эл.рег."
        class="success--text text-center"
      >
        <small v-if="economyInfo.economyElectronRegistration">{{ Math.round(economyInfo.economyElectronRegistration).toLocaleString() }}<Rouble /></small>
        <small v-else>-</small>
      </div>
      <div
        v-if="overPayInfo.sumElectronRegistration"
        title="Переплата с Эл.рег."
        class="secondary--text text-center"
      >
        <small>{{ Math.round(overPayInfo.sumElectronRegistration).toLocaleString() }}<Rouble /></small>
      </div>
    </div>
    <div v-else>
      -
    </div>

    <!--    <template v-if="overPayInfo">-->
    <!--      <label>Переплата</label>-->
    <!--      <div class="flex-item-three-cols">-->
    <!--        <div title="Переплата Банк">-->
    <!--          {{ Math.round(overPayInfo.sum).toLocaleString() }}<Rouble/>-->
    <!--        </div>-->
    <!--        <div v-if="overPayInfo.sumFlat && overPayInfo.sumFlat !== overPayInfo.sum" title="Переплата Флэт" class="center">-->
    <!--          {{ Math.round(overPayInfo.sumFlat).toLocaleString() }}<Rouble/>-->
    <!--        </div>-->
    <!--        <div v-else class="center">-</div>-->
    <!--        <div v-if="overPayInfo.sumElectronRegistration && overPayInfo.sumElectronRegistration !== overPayInfo.sumFlat" title="Переплата Эл.рег.">-->
    <!--          {{ Math.round(overPayInfo.sumElectronRegistration).toLocaleString() }}<Rouble/>-->
    <!--        </div>-->
    <!--        <div v-else>-</div>-->
    <!--      </div>-->
    <!--    </template>-->
  </div>
</template>

<script>
import Rouble from '@/components/symbol/Rouble'
export default {
  name: 'ProgSelectorCellPaysInlineShort',
  components: { Rouble },
  props: ['payInfo', 'economyInfo', 'overPayInfo']
}
</script>
