<template>
  <TwPanelContent>
    <div class="tw-flex-1">
      <TwPanelContentBlock class="tw-mb-[6px]">
        <template #title>
          <div>
            О заявке
          </div>
        </template>
        <template
          v-if="permissions.entity && permissions.entity.update"
          #action
        >
          <TwPanelContentBlockActionButton
            :disabled="isNew || loading"
            type="edit"
            @click="$emit('changeEdit', !editMain)"
          >
            {{ editMain ? 'отменить':'изменить' }}
          </TwPanelContentBlockActionButton>
        </template>
        <div class="tw-flex tw-gap-[12px] tw-flex-col">
          <DealMainForm
            v-if="editMain"
            :id="deal ? deal.id : 0"
            ref="EditMain"
            @create="$emit('create', $event)"
            @update="$emit('update', $event)"
            @cancel="cancelEditedForm"
          />
          <DealMainShow
            v-else
            :dictionary="dictionary"
            :info="deal"
            @refresh="$emit('refresh', $event)"
          />
        </div>
      </TwPanelContentBlock>

      <!-- Список связанных заявок -->
      <DealRelationsList :deal-relation="dealState && dealState.dealRelation" />

      <!-- источник заявки -->
      <!-- Запрос услуги по заявке -->
      <DealRelationsServiceRequestBlock
        v-if="dealState && dealState.dealRelatedServiceRequest"
        :info="dealState.dealRelatedServiceRequest"
      />

      <!-- ЛИД -->
      <TwPanelContentBlock
        v-if="deal.lead"
        class="tw-mb-[6px]"
      >
        <template #title>
          <div>
            Лид
          </div>
        </template>
        <div>
          <DealLeadInfoShow :info="deal.lead" />
        </div>
      </TwPanelContentBlock>
      <TwPanelContentBlock
        v-if="deal.statusFailInfo"
        class="tw-mb-[6px]"
      >
        <template #title>
          <div>
            Причина
          </div>
        </template>
        <div class="text-wrap">
          <strong>{{ deal.statusFailInfo.comment }}</strong>
          <div v-if="deal.statusFailInfo.dateSuspense">
            <span>Приостановлено до
              {{ $Global.DateFormat.DateShort(deal.statusFailInfo.dateSuspense) }}
            </span>
          </div>
        </div>
      </TwPanelContentBlock>
      <TwPanelContentBlock
        v-if="deal && deal.extId"
        class="tw-mb-[6px]"
      >
        <template #title>
          <div>
            Битрикс 24
          </div>
        </template>
        <div>
          <LinkToBitrix
            type="deal"
            :bx-id="deal.extId"
          />
        </div>
      </TwPanelContentBlock>
    </div>
    <div class="tw-flex-1">
      <!-- Хронология -->
      <TwPanelContentBlock
        v-if="deal"
        :content-classes="''"
        class="tw-mb-[6px]"
      >
        <TwChronologyRoot
          v-if="!isNew && deal"
          ref="chronology"
          :person-id="targetPersonId"
          :entity-id="deal.id"
          entity="deal"
          :entity-info="deal"
        />
      </TwPanelContentBlock>
    </div>
  </TwPanelContent>
</template>

<script>
import DealMainForm from '@/views/Deal/main/DealMainForm.vue'
import DealMainShow from '@/views/Deal/main/DealMainShow.vue'
import LinkToBitrix from '@/components/bitrix/LinkToBitrix.vue'
import DealLeadInfoShow from '@/views/Deal/main/DealLeadInfoShow.vue'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwPanelContent from '@/components/tw-ui/panels/TwPanelContent.vue'
import TwPanelContentBlockActionButton from '@/components/tw-ui/panels/TwPanelContentBlockActionButton.vue'
import TwChronologyRoot from '@/components/core/Chronology/tw-dev/TwChronologyRoot.vue'
import DealRelationsList from '@/views/Deal/TypeViews/Relations/DealRelationsList.vue'
import { mapGetters } from 'vuex'
import DealRelationsServiceRequestBlock from './Relations/DealRelationsServiceRequestBlock.vue'

export default {
  name: 'DealGeneralTab',
  components: {
    DealRelationsServiceRequestBlock,
    DealRelationsList,
    TwChronologyRoot,
    TwPanelContentBlockActionButton,
    TwPanelContent,
    TwPanelContentBlock,
    DealLeadInfoShow,
    LinkToBitrix,
    DealMainShow,
    DealMainForm
  },
  props: {
    deal: {
      required: true
    },
    dealState: {
      default: null
    },
    dictionary: {
      type: Object,
      default: () => {
        return {}
      }
    },
    permissions: {
      type: Object,
      default: () => {
        return {}
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    isNew: {
      type: Boolean,
      default: false
    },
    editMain: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    cancelEditedForm () {
      console.log('cancelEditedForm')
      this.$emit('changeEdit', false)
    },
    submitEditedForm () {
      const callback = (data) => {
        console.error('submitEditedForm callback', { data })
        this.$emit('changeEdit', false)
        this.$emit('refresh')
      }
      if (this.editMain && this.$refs.EditMain) {
        this.$refs.EditMain.$once(this.deal && this.deal.id ? 'update' : 'create', callback)
        this.$refs.EditMain.handleSubmit()
      }
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'profile/isAdmin'
    }),
    targetPersonId () {
      return this.deal?.person?.id || null
    }
  },
  watch: {
    loading (newValue) {
      // получить хронологию после получения данных о заявке
      if (!newValue && this.$refs?.chronology?.fetch) {
        this.$refs.chronology.fetch()
      }
    }
  },
  mounted () {
    // получить хронологию при создании компонента
    if (this.$refs?.chronology?.fetch) {
      this.$refs.chronology.fetch()
    }
  },
  created () {
    const unsubscribe = this.$store.subscribeAction({
      // before: (action, state) => {
      //   console.error(`Начато action ${action.type} ${this.editMain}`)
      // },
      after: (action) => {
        // console.error(`Завершено action ${action.type} ${this.$options.name}`)
        if (action.type === 'cards/saveBlockSuccess') {
          this.submitEditedForm()
        }
        if (action.type === 'cards/saveBlockCancel') {
          this.cancelEditedForm()
        }
      },
      error: (action, state, error) => {
        console.error(`error action ${action.type} ${this.editMain}`)
        console.error(error)
      }
    })

    this.$on('hook:beforeDestroy', () => {
      // для остановки отслеживания нужно вызвать unsubscribe
      unsubscribe()
    })
  }
}
</script>

<style scoped>

</style>
