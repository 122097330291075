<template>
  <v-row
    no-gutters
    class="mb-3"
  >
    <v-col cols="12">
      <DaDataAddressInput
        v-model="searchStr"
        class="mb-5"
        @input-object="updateAddressObj"
      />
    </v-col>
    <v-col
      v-for="(name, key) in addressFieldNames"
      :key="key"
      cols="12"
      md="6"
      class="pa-1"
      title="Для изменения полей адреса воспользуйтесь поиском"
    >
      <label
        style=""
        :for="key"
      >
        <div style="font-size: 10px">
          {{ name }}
        </div>
        <input
          v-model="proxyValue[key]"
          :name="key"
          type="text"
          readonly="readonly"
          autocomplete="off"
          :disabled="disabled"
        >
      </label>
    </v-col>
  </v-row>
<!--  postalCode: '', // Индекс-->
<!--  country: '', // Страна-->
<!--  regionType: '', // Тип региона (сокращенный)-->
<!--  region: '', // Регион-->
<!--  areaType: '', // Тип района в регионе (сокращенный)-->
<!--  area: '', // Тип района в регионе-->
<!--  cityType: '', // Тип города (сокращенный)-->
<!--  city: '', // Тип города-->
<!--  settlementType: '', // Тип населенного пункта (сокращенный-->
<!--  settlement: '', // Населенный пункт-->
<!--  streetType: '', // Тип улицы (сокращенный)-->
<!--  street: '', // Улица-->
<!--  houseType: '', // Тип дома (сокращенный)-->
<!--  house: '', // Дом-->
<!--  blockType: '', // Тип корпуса/строения (сокращенный)-->
<!--  blockTypeFull: '', // Тип корпуса/строения-->
<!--  block: '', // Корпус/строение-->
<!--  flatType: '', // Тип квартиры (сокращенный)-->
<!--  flat: '' // Квартира-->
</template>

<script>
import { DaDataAddressDefaultForm, DaDataAddressFieldNames } from '../../DomClickDict'
import DaDataAddressInput from '@/components/input/daData/DaDataAddressInput.vue'

export default {
  name: 'DomClickDealAddressForm',
  components: { DaDataAddressInput },
  props: {
    value: {
      type: Object,
      default: () => DaDataAddressDefaultForm
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      searchStr: ''
    }
  },
  computed: {
    // Объект значения проксируется и напрямую изменяется по средствам мутации объекта
    proxyValue: {
      get () {
        return this.value
      }
    },
    addressFieldNames () {
      return DaDataAddressFieldNames
    }
  },
  methods: {
    updateAddressObj (daDataObj) {
      // console.warn('updateAddressObj ', daDataObj.data)
      for (const key in DaDataAddressDefaultForm) {
        this.proxyValue[key] = daDataObj?.data[key] || ''
      }
    }
  }
}
</script>

<style scoped>
input {
  width: 100%;
  font-size: 12px;
  border: 1px solid lightgray;
  padding: 0 3px 0 3px;
}
input:focus{
  outline: 1px solid gray;
}
</style>
