import infrastructureAPI from '@/Api/RealtyMiner/Infrastructure'
import lodashDebounce from 'lodash/debounce'
import { SERVICES } from '@/Api/Services'

const MAIN_ROOT_URL = `${SERVICES.REALTY_MINER.host}/infra-widget/`
const MAP_ICONS = [
  {
    key: 'Atms',
    icon: 'islands#darkGreenMoneyCircleIcon',
    iconGroup: 'islands#invertedDarkGreenClusterIcons',
    iconList: MAIN_ROOT_URL + 'img/atm.svg'
  },
  {
    key: 'Banks',
    icon: 'islands#greenLeisureCircleIcon',
    iconGroup: 'islands#invertedGreenClusterIcons',
    iconList: MAIN_ROOT_URL + 'img/bank.svg'
  },
  {
    key: 'k_garden',
    icon: 'islands#pinkFamilyCircleIcon',
    iconGroup: 'islands#invertedPinkClusterIcons',
    iconList: MAIN_ROOT_URL + 'img/child.svg'
  },
  {
    key: 'school',
    icon: 'islands#darkOrangeEducationCircleIcon',
    iconGroup: 'islands#invertedDarkOrangeClusterIcons',
    iconList: MAIN_ROOT_URL + 'img/school.svg'
  },
  {
    key: 'pharmacy',
    icon: 'islands#yellowMedicalCircleIcon',
    iconGroup: 'islands#invertedYellowClusterIcons',
    iconList: MAIN_ROOT_URL + 'img/pharmacy.svg'
  },
  {
    key: 'health_care',
    icon: 'islands#redMedicalCircleIcon',
    iconGroup: 'islands#invertedRedClusterIcons',
    iconList: MAIN_ROOT_URL + 'img/pharmacy.svg'
  },
  {
    key: 'supermarket',
    icon: 'islands#oliveFashionCircleIcon',
    iconGroup: 'islands#invertedOliveClusterIcons',
    iconList: MAIN_ROOT_URL + 'img/market.svg'
  },
  {
    key: 'mall', // TODO
    icon: 'islands#redFashionCircleIcon',
    iconGroup: 'islands#invertedRedClusterIcons',
    iconList: MAIN_ROOT_URL + 'img/market.svg'
  },
  {
    key: 'parking',
    icon: 'islands#blueParkingCircleIcon',
    iconGroup: 'islands#invertedBlueClusterIcons',
    iconList: MAIN_ROOT_URL + 'img/parking.svg'
  },
  {
    key: 'car_repair', // TODO
    icon: 'islands#blueParkingCircleIcon',
    iconGroup: 'islands#invertedBlueClusterIcons',
    iconList: MAIN_ROOT_URL + 'img/star.svg'
  },
  {
    key: 'car_wash', // TODO
    icon: 'islands#blueParkingCircleIcon',
    iconGroup: 'islands#invertedBlueClusterIcons',
    iconList: MAIN_ROOT_URL + 'img/star.svg'
  },
  {
    key: 'metro', // TODO
    icon: 'islands#blueParkingCircleIcon',
    iconGroup: 'islands#invertedBlueClusterIcons',
    iconList: MAIN_ROOT_URL + 'img/star.svg'
  }
]

type TFetchParams = {
  lat : number;
  lon : number;
  radius : number;
}

const defaultFetchParams: TFetchParams = {
  lat: 55.78969,
  lon: 49.13699,
  radius: 1000
}
const defaultOptions: any = {
  listBoxExpanded: false,
  preSelectAllItems: true,
  boundschangeListener: false, // активировать слушатель события движения карты - это автоматически будет вызывать update() инфраструктуры
  initialRadius: null
}
/*
* СОЗДАТЬ МОДУЛЬ: const infraModule = new YandexMapInfrastructureModule(this.map) - передаем инстанс Яндекс карты
* ЗАПУСТИТЬ ОБНОВЛЕНИЕ ИНФРАСТРУКТУРЫ: infraModule.update({ lat: 55.78969, lon: 49.13699, radius: 1000 })
* */
export default class YandexMapInfrastructureModule {
  map: any;
  listBox: any;
  infrastructure: any;
  infrastructureManager: any;
  params: TFetchParams;
  listBoxExpanded: boolean;
  preSelectAllItems: boolean;
  constructor (map: any, inputOptions: any = {}) {
    const options = {
      ...defaultOptions,
      ...inputOptions
    }
    this.map = map
    this.params = {
      ...defaultFetchParams,
      ...(options?.initialRadius) ? {
        radius: options.initialRadius
      } : {}
    }
    this.listBoxExpanded = options.listBoxExpanded
    this.preSelectAllItems = options.preSelectAllItems

    // создаем менеджер объектов для инфраструктуры
    this.infrastructureManager = new window.ymaps.ObjectManager({
      clusterize: true,
      gridSize: 32,
      clusterIconLayout: 'default#pieChart',
      zIndex: 100,
      zIndexActive: 100
    })
    this.map.geoObjects.add(this.infrastructureManager)

    if (options?.boundschangeListener) {
      this.map.events.add('boundschange', (e: any) => {
        const [lat = null, lon = null] = e.originalEvent.map?.getCenter()
        this.params = {
          ...this.params,
          ...(lat && lon) && {
            lat,
            lon
          }
        }
        lodashDebounce(() => {
          this.update(this.params)
        }, 500)()
      })
    }
  }

  update (params: TFetchParams = defaultFetchParams) {
    // очищаю устаревшее
    this.infrastructureManager?.removeAll()

    this.params = {
      ...params
    }
    infrastructureAPI.getList({ location: this.params }).then(({ data: result }) => {
      // console.warn(result.data)
      this.buildInfrastructure(result.data)
    }).catch((error) => {
      console.warn(error)
      this.buildInfrastructure([])
    })
  }

  // Формирую список выбора инфраструктуры
  buildInfrastructure (data: Array<any>) {
    // console.warn('buildInfrastructure', data)
    // если есть список удаляю старый список
    if (this.listBox) this.map.controls.remove(this.listBox)
    // выбираю уникальные типы инфраструктур
    const uniqInfrastructureTypeList: any = []
    for (const itemObj of data) {
      const typeIndex = uniqInfrastructureTypeList.findIndex((structureItem: any) => (structureItem.type === itemObj.type))
      if (typeIndex <= -1) {
        uniqInfrastructureTypeList.push({
          typeName: itemObj.typeName,
          type: itemObj.type
        })
      }
    }

    // формирую инфраструктуру
    this.infrastructure = uniqInfrastructureTypeList.map((item, i) => {
      return { label: item.typeName, value: i + 1, type: item.type, data: [], isSpin: false }
    })

    // формирую инфраструктуру
    if (this.infrastructure) {
      // Создаю элементы меню для списка фильтрации по типам
      const listBoxItems = this.infrastructure.map((item: any) => {
        // Иконка для инфраструктуры
        const itemIcon: any = MAP_ICONS.find(icon => {
          return icon.key === item.type
        })

        // Отмечаю выбран ли элемент по умолчанию
        return new window.ymaps.control.ListBoxItem({
          data: {
            label: item.label,
            icon: itemIcon.iconList
          },
          state: {
            selected: this.preSelectAllItems
          },
          options: {
            layout: window.ymaps.templateLayoutFactory.createClass(`
            <div class="tw-p-[12px] tw-w-[250px] tw-flex tw-gap-[6px] tw-items-center tw-cursor-pointer hover:tw-bg-an-flat-yellow-warning">
              {% if state.selected %}
                <div>✅</div>
              {% else %}
                <div>◻️</div>
              {% endif %}
              <img alt="{{ data.label }}" src="{{ data.icon }}">
              <div>{{ data.label }}</div>
            </div>
            `)
          }
        })
      })

      // Создаю список и заполняю элементами
      this.listBox = new window.ymaps.control.ListBox({
        items: listBoxItems,
        data: {
          content: 'Инфраструктура',
          title: 'Инфраструктура'
        },
        state: {
          // Признак, развернут ли список.
          expanded: this.listBoxExpanded,
          filters: listBoxItems.reduce((filters: any, filter: any) => {
            // console.warn('filters', filters, filter, filter.data.get('label'))
            filters[filter.data.get('label')] = filter.isSelected()
            return filters
          }, {})
        }
      })

      // Событие смены состояния элемента списка
      this.listBox.events.add(['select', 'deselect'], (e: any) => {
        const listBoxItem = e.get('target')
        const filters = window.ymaps.util.extend({}, this.listBox.state.get('filters'))
        filters[listBoxItem.data.get('label')] = listBoxItem.isSelected()
        const newfilters: any = {}
        for (const key in filters) {
          const tempkey = key.substring(key.lastIndexOf('>') + 1)
          newfilters[tempkey] = filters[key]
        }
        // console.warn('select', 'deselect', newfilters)
        this.listBox.state.set('filters', newfilters)
      })

      // Метод смены выбранных фильтров
      const getFilterFunction = (categories: any) => {
        // console.warn('getFilterFunction', categories)
        return (obj: any) => {
          const label = obj.properties.clusterCaption
          return categories[label]
        }
      }

      // Отслеживание фильтрации
      const filterMonitor = new window.ymaps.Monitor(this.listBox.state)
      filterMonitor.add('filters', (filters: any) => {
        // console.warn('filterMonitor filters', filters)
        // Применим фильтр.
        this.infrastructureManager.setFilter(getFilterFunction(filters))
      })

      this.appendInfrastructureItems(data)
      this.map.controls.add(this.listBox, { float: 'left' })
    }
  }

  // Дополняю инфраструктуру объектами на основе списка с сервера
  appendInfrastructureItems (objList: Array<any>) {
    // console.warn('appendInfrastructureItems', objList);
    if (this.infrastructure && objList.length !== 0) {
      this.infrastructure.forEach((item: any) => {
        // Отпределяю иконку
        const itemIcon: any = MAP_ICONS.find(icon => {
          return icon.key === item.type
        })
        // Фильтрую объекты по типам
        const data = objList.filter(item => item.type === itemIcon.key)
        // Дополняю объект инфраструктуры
        item.data = data.map((dataItem, i) => {
          return {
            type: 'Feature',
            id: item.value * 1000 + i,
            geometry: {
              type: 'Point',
              coordinates: [dataItem.location.lat, dataItem.location.lon]
            },
            properties: {
              balloonContent: dataItem.name || dataItem.typeName || '',
              clusterCaption: item.label,
              hintContent: dataItem.name || dataItem.typeName || ''
            },
            options: {
              preset: itemIcon.icon,
              iconLayout: 'default#image',
              iconImageHref: itemIcon.iconList,
              iconImageSize: [32, 32],
              zIndex: 200,
              zIndexActive: 200
            }
          }
        })
        item.isSpin = false
        this.infrastructureMergeToCollection()
      })
    }
  }

  // Объеденяю элементы инфраструктуры в коллекции
  infrastructureMergeToCollection () {
    // console.warn('infrastructureMergeToCollection', this.infrastructure)
    let infrastructureAll = {
      type: 'FeatureCollection',
      features: []
    }
    if (this.infrastructure) {
      infrastructureAll = {
        type: 'FeatureCollection',
        features: this.infrastructure.reduce((previous: any, current: any) => {
          return previous.concat(current.data)
        }, [])
      }
    }
    // console.warn('collection', infrastructureAll)
    // Добавляю в коллекию
    this.infrastructureManager.add(infrastructureAll)

    const filters = window.ymaps.util.extend({}, this.listBox.state.get('filters'))
    const newfilters: any = {}
    for (const key in filters) {
      const tempkey = key.substring(key.lastIndexOf('>') + 1)
      newfilters[tempkey] = filters[key]
    }
    // Применяю фильтры
    this.listBox.state.set('filters', newfilters)
  }
}
