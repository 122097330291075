<template>
  <div
    v-if="item"
    class="card item-hovered-visible-block"
  >
    <div class="card-wrapper">
      <div class="card-left">
        <div class="card-header">
          <div class="card-header-info">
            <p class="card-title">
              {{ item.attachEntity.label }}:
            </p>
            <p class="card-time">
              {{ $Global.DateFormat.DateTimeShort(item.date) }}
            </p>
          </div>
          <button-editor @openEdit="openEdit" />
        </div>
        <div
          v-if="!edit"
          ref="commentBody"
          class="card-text"
          :class="[{ active: showComment, 'card-text-one-blur-place': isShowMore, 'card-text-one-blur-place-pin': isShowMore && item.pin && variant === 'pin' }, classComment]"
        >
          <p
            v-if="item.attachEntityData.comment"
            style="word-wrap: break-word;"
            v-html="$Global.nl2br(item.attachEntityData.comment)"
          />
        </div>
        <FormCommentsSend
          v-else
          :id="item.attachEntityData.id"
          :entity-id="entityId"
          :active="edit"
          :entity="entity"
          @cancel="edit = false"
          @success="refresh"
        />
      </div>
      <div class="card-right">
        <ChronologyPinned
          v-if="!(variant === 'other' && item.pin)"
          :id="item.id"
          :pin-status="item.pin"
          @refresh="refresh"
        />
      </div>
    </div>
    <div
      v-show="isShowMore"
      class="card-buttons-show"
      :class="{ disabled: edit }"
    >
      <p
        v-if="showComment"
        @click.prevent="showAllComment"
      >
        {{ toggle.hide }}
      </p>
      <p
        v-else
        @click.prevent="showAllComment"
      >
        {{ toggle.show }}
      </p>
    </div>
    <EmailFilesAttached
      v-if="!edit && Array.isArray(item.attachEntityData.files) && item.attachEntityData.files.length"
      :files="item.attachEntityData.files"
    />
    <EmployeeLinkBlock
      v-if="item && item.attachEntityData"
      :user-id="item.attachEntityData.userId"
    />
  </div>
  <div
    v-else
    class="error--text"
  >
    Нет данных
  </div>
</template>

<script>
import FormCommentsSend from '@/components/core/Chronology/formCommentsSend'
import API from '@/Api/Crm/Message/Comment'
import ChronologyPinned from '@/components/core/Chronology/ChronologyPinned'
import ButtonEditor from '@/components/core/Chronology/buttonEditor'
import EmailFilesAttached from '@/views/Email/main/EmailFilesAttached'
import EmployeeLinkBlock from '@/components/other/employee/EmployeeLinkBlock'
export default {
  name: 'ChronologyCommentView',
  components: { EmployeeLinkBlock, EmailFilesAttached, ChronologyPinned, FormCommentsSend, ButtonEditor },
  filters: {
    dateFormat: function (value) {
      if (!value) return ''
      return new Date(value).toLocaleString()
    }
  },
  props: {
    variant: {
      type: String,
      default: '' // other, plan, pin
    },
    item: {
      type: Object,
      required: true
    },
    entity: {
      type: String,
      required: true // person | lead | deal
    },
    entityId: {
      type: [String, Number, null],
      default: null
    }
  },
  data: () => ({
    API,
    edit: false,
    bgDone: {
      false: 'card-text-one ',
      true: 'card-text'
    },
    form: {
      comment: '',
      pin: false
    },
    showComment: false,
    isShowMore: false,
    toggle: {
      show: 'Развернуть',
      hide: 'Скрыть'
    }
  }),
  computed: {
    classComment: function () {
      if (this.item.pin && this.variant === 'pin') {
        return this.bgDone.true
      } else {
        return this.bgDone.false
      }
    }
  },
  mounted () {
    // Проверить нужно ли показывать кнопки Свернуть\Развернуть для коммента
    this.$nextTick(() => {
      this.checkShowMore()
    })
  },
  methods: {
    openEdit () {
      this.edit = true
    },
    refresh () {
      this.edit = false
      this.$emit('refresh')
    },
    showAllComment () {
      this.showComment = !this.showComment
    },
    checkShowMore () {
      this.isShowMore = false
      if (this.$refs?.commentBody) {
        this.isShowMore = this.$refs.commentBody.scrollHeight > this.$refs.commentBody.clientHeight
      }
    }
  }
}
</script>
