<template>
  <TwPanelContentBlock
    class="tw-mb-[6px] tw-bg-white"
    style-hr="tw-bg-an-flat-hr-border-grey"
    content-classes="''"
  >
    <template #title>
      <div>
        Мои интересы
      </div>
    </template>
    <template #action>
      <TwPanelContentBlockActionButton
        v-if="allowEdit"
        type="edit"
        @click="isEdit = !isEdit"
      >
        {{ isEdit ? 'готово':'изменить' }}
      </TwPanelContentBlockActionButton>
    </template>
    <div class="tw-flex tw-flex-col tw-gap-[12px] tw-font-medium">
      <div
        v-if="loading"
        class="tw-w-full tw-text-center tw-py-[35px]"
      >
        <TwCircleLoader />
      </div>
      <div v-else>
        <div v-if="isEdit">
          <TwUserTagInput :user-id="userId" />
        </div>
        <div
          v-else
          class="tw-w-full tw-flex tw-gap-[12px] tw-flex-wrap"
        >
          <template v-if="Array.isArray(userTagList) && userTagList.length">
            <div
              v-for="(item, index) in userTagList"
              :key="`${item.id}_${index}`"
              class="tw-cursor-default tw-flex tw-items-center tw-justify-center tw-bg-an-flat-gray-light tw-py-[7px] tw-px-[10px] tw-rounded-[50px]"
            >
              <div class="tw-mr-[6px]">
                # {{ item.tag }}
              </div>
              <div
                v-if="Array.isArray(item.users) && item.users.length"
                class="tw-relative"
                :class="{ 'tw-min-w-[40px]': item.users.length >= 3, 'tw-min-w-[30px]': item.users.length === 2 , 'tw-min-w-[15px]': item.users.length === 1 } "
              >
                <template v-for="(userNum, uIndex) in 3">
                  <img
                    v-if="item.users[uIndex]"
                    :key="`${item.id}_${item.users[uIndex].id}`"
                    :alt="userNum + '-' + uIndex"
                    :style="{ left: `${uIndex * 12}px`, zIndex: uIndex }"
                    class="tw-absolute -tw-top-[10px] tw-min-w-[20px] tw-w-[20px] tw-min-h-[20px] tw-h-[20px] tw-rounded-full tw-border-[2px] tw-border-solid tw-border-white"
                    :src="employeeAvatarListGetLink(item.users[uIndex].file)"
                  >
                </template>
              </div>
              <div
                v-if="Array.isArray(item.users) && item.users.length && item.users.length > 3"
                class="tw-ml-[6px] tw-text-an-flat-disabled-text"
              >
                +{{ item.users.length - 3 }}
              </div>
            </div>
          </template>
          <div
            v-else
            class="tw-text-center tw-text-an-flat-disabled-text tw-w-full"
          >
            Интересы не добавлены
          </div>
        </div>
      </div>
    </div>
  </TwPanelContentBlock>
</template>

<script>
import { employeeAvatarListGetLink } from '@/views/Company/Employee/employeeHelpers'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import TwPanelContentBlockActionButton from '@/components/tw-ui/panels/TwPanelContentBlockActionButton.vue'
import TwUserTagInput from '@/components/tw-ui/ui-kit/input/user-tag-input/TwUserTagInput.vue'
import API from '@/Api/Auth/User/tag'

export default {
  name: 'UserTagsBlock',
  components: { TwUserTagInput, TwPanelContentBlockActionButton, TwCircleLoader, TwPanelContentBlock },
  props: {
    userId: {
      required: true
    },
    allowEdit: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      userTagList: [],
      loading: false,
      isEdit: false
    }
  },
  watch: {
    userId () {
      this.fetchTagList()
    },
    isEdit (newVal) {
      if (!newVal) {
        this.fetchTagList()
      }
    }
  },
  mounted () {
    this.fetchTagList()
  },
  methods: {
    employeeAvatarListGetLink,
    fetchTagList () {
      console.warn('fetchTagList')
      this.loading = true
      API.getList({ userId: this.userId, limit: 500 })
        .then(({ data: result }) => {
          this.userTagList = result?.data || []
        })
        .catch((error) => {
          console.error('onSearch error', error)
        }).finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped>

</style>
