export const deploymentTypeColors = {
  local: 'orange',
  beta: 'dodgerblue',
  prod: '#e4300e'
}
export const twDeploymentTypeColors = {
  local: 'tw-text-orange-600',
  beta: 'tw-text-blue-700',
  prod: 'tw-text-an-flat-red-main'
}
