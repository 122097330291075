import { Module } from 'vuex'
import Vue from 'vue'
import DealObjectsAPI from '@/Api/Crm/Deal/Objects/Favorite'
import store from '@/store'

const namespaced = true

export const dealObjects: Module<any, any> = {
  namespaced,
  state: {
    favorites: {}
  },
  actions: {
    fetchFavoriteList: function ({ commit }, payload) {
      if (!payload?.dealId) {
        // запрашиваю тольео если есть сделка
        return
      }
      commit('SET_FAVORITES_BY_DEAL', {
        processing: true,
        dealId: payload?.dealId ? payload.dealId : 'all',
        list: []
      })
      return DealObjectsAPI.getList({
        dealId: payload?.dealId || undefined,
        limit: 999
      })
        .then(({ data: result }) => {
          console.log('fetchFavoriteList', result.data)
          commit('SET_FAVORITES_BY_DEAL', {
            processing: false,
            dealId: payload?.dealId ? payload.dealId : 'all',
            list: result.data || []
          })
        })
        .catch((error) => {
          console.error(error)
          commit('SET_FAVORITES_BY_DEAL', {
            error: error,
            processing: false,
            dealId: payload?.dealId ? payload.dealId : 'all',
            list: []
          })
        })
    },
    addFavorite: function ({ commit }, payload) {
      commit('SET_FAVORITES_BY_DEAL', {
        processing: true,
        dealId: payload?.dealId ? payload.dealId : 'all',
        list: []
      })
      return DealObjectsAPI.store({
        dealId: payload?.dealId,
        objectId: payload?.objectId
      })
        .then(({ data: result }) => {
          console.log('addFavorite', result.data)
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          store.dispatch('favorite/dealObjects/fetchFavoriteList', { dealId: payload?.dealId })
        })
    },
    deleteFavorite: function ({ commit }, payload) {
      commit('SET_FAVORITES_BY_DEAL', {
        processing: true,
        dealId: payload?.dealId ? payload.dealId : 'all',
        list: []
      })
      return DealObjectsAPI.delete(payload.id)
        .then(({ data: result }) => {
          console.log('deleteFavorite', result.data)
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          store.dispatch('favorite/dealObjects/fetchFavoriteList', { dealId: payload?.dealId })
        })
    }
  },
  mutations: {
    SET_FAVORITES_BY_DEAL (state, payload) {
      console.log('SET_FAVORITES_BY_DEAL', payload)
      Vue.set(state.favorites, payload.dealId, payload)
    }
  },
  getters: {
    getFavoritesByDealId: (state) => (dealId: number) => {
      if (state.favorites[dealId] === undefined) {
        store.dispatch('favorite/dealObjects/fetchFavoriteList', { dealId })
      }
      return state.favorites[dealId] || null
    }
  }
}
