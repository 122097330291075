<template>
  <div v-cloak>
    <div v-if="info">
      <CardBlockField>
        <template #title>
          {{ info.company && info.company.type && info.company.type.label }}
        </template>
        <template #content>
          <ViewPanelLink
            :id="info.company.id"
            :label="info.company && info.company.type && info.company.type.label"
            component="company-show"
          >
            {{ info.company && info.company.name }}
          </ViewPanelLink>
        </template>
      </CardBlockField>
      <CardBlockField>
        <template #title>
          Ежемесячный платеж
        </template>
        <template #content>
          {{ info.payMonth.toLocaleString() }} руб.
        </template>
      </CardBlockField>
    </div>
    <div
      v-else
      class="text-muted text-center"
    >
      Нет данных
    </div>
  </div>
</template>

<script>
import CardBlockField from '@/components/core/ViewPanel/Card/CardBlockField'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink'
export default {
  name: 'PersonCreditShow',
  components: { ViewPanelLink, CardBlockField },
  props: {
    info: {},
    dictionary: {}
  }
}
</script>
