<template>
  <div class="tw-mb-[6px] tw-bg-an-flat-gray-15 tw-rounded-sm tw-p-[5px] tw-font-semibold tw-flex tw-gap-[15px]">
    <slot name="default" />
  </div>
</template>

<script>
export default {
  name: 'TwPanelTabs'
}
</script>
