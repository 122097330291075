<template>
  <div class="tw-font-manrope tw-flex tw-text-left">
    <div
      v-if="loading"
      class="tw-p-[30px] tw-m-auto"
    >
      <TwCircleLoader class="tw-m-auto" />
    </div>
    <div
      v-else-if="info"
      class="tw-grow"
    >
      <div class="tw-bg-an-flat-bg-main tw-text-main-sm tw-font-semibold tw-pt-[22px] tw-pb-[16px] tw-px-[31px] tw-rounded-t-[24px]">
        {{ title || 'Нет данных' }}
      </div>
      <hr>
      <table class="tw-w-full">
        <thead>
          <tr class="tw-bg-an-flat-bg-main tw-text-an-flat-gray-4">
            <th class="tw-py-[14px] tw-pl-[31px]">
              Дата
            </th>
            <th class="tw-py-[14px]">
              Действие
            </th>
            <th class="tw-py-[14px] tw-pr-[31px]">
              Пользователь
            </th>
          </tr>
        </thead>
        <tbody class="tw-bg-white">
          <template v-if="Array.isArray(info.history) && info.history.length">
            <tr
              v-for="(changeInfo, index) in info.history"
              :key="index"
            >
              <td class="tw-text-an-flat-gray-4 tw-py-[14px] tw-pl-[31px]">
                {{ $Global.DateFormat.DateTimeShort(changeInfo.createdAt) }}
              </td>
              <td class="tw-py-[14px] tw-font-semibold tw-flex tw-items-center tw-gap-[7px] tw-leading-normal">
                Цена
                <template v-if="changeInfo.previousPrice">
                  {{ (changeInfo.previousPrice || 0).toLocaleString('ru') }} ₽
                  <svg
                    width="16"
                    height="7"
                    viewBox="0 0 16 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.8169 3.01998L13.2441 0.446979C13.1268 0.329766 12.9679 0.263916 12.8021 0.263916C12.6363 0.263916 12.4774 0.329766 12.3602 0.446979C12.2429 0.564192 12.1771 0.723168 12.1771 0.888932C12.1771 1.0547 12.2429 1.21367 12.3602 1.33089L13.9144 2.8851C13.8979 2.88379 13.8813 2.88257 13.8644 2.88257H0.625C0.45924 2.88257 0.300269 2.94842 0.183058 3.06563C0.065848 3.18284 0 3.34181 0 3.50757C0 3.67333 0.065848 3.8323 0.183058 3.94951C0.300269 4.06672 0.45924 4.13257 0.625 4.13257H13.8203L12.2838 5.66907C12.1666 5.78628 12.1007 5.94527 12.1007 6.11105C12.1007 6.27684 12.1665 6.43584 12.2837 6.55309C12.4009 6.67033 12.5599 6.73622 12.7257 6.73624C12.8915 6.73627 13.0505 6.67044 13.1677 6.55323L15.8169 3.90401C15.875 3.84597 15.921 3.77707 15.9524 3.70124C15.9838 3.62541 16 3.54414 16 3.46206C16 3.37998 15.9838 3.2987 15.9524 3.22287C15.921 3.14704 15.875 3.07814 15.8169 3.0201V3.01998Z"
                      fill="black"
                    />
                  </svg>
                </template>
                {{ (changeInfo.price || 0).toLocaleString('ru') }} ₽
                <template v-if="changeInfo.previousPrice && changeInfo.price">
                  <svg
                    v-if="changeInfo.isRise"
                    width="22"
                    height="16"
                    viewBox="0 0 22 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.5217 0.183062L7.94869 2.75594C7.83147 2.87315 7.76562 3.03213 7.76562 3.19789C7.76562 3.36366 7.83147 3.52263 7.94869 3.63984C8.0659 3.75706 8.22488 3.82291 8.39064 3.82291C8.55641 3.82291 8.71538 3.75706 8.83259 3.63984L10.3868 2.08559C10.3855 2.10213 10.3843 2.11872 10.3843 2.13559L10.3843 15.375C10.3843 15.5408 10.4501 15.6997 10.5673 15.8169C10.6846 15.9342 10.8435 16 11.0093 16C11.175 16 11.334 15.9342 11.4512 15.8169C11.5684 15.6997 11.6343 15.5408 11.6343 15.375L11.6343 2.17966L13.1708 3.71616C13.288 3.8334 13.447 3.89929 13.6128 3.89931C13.7785 3.89934 13.9376 3.83351 14.0548 3.7163C14.172 3.59909 14.2379 3.4401 14.238 3.27432C14.238 3.10853 14.1721 2.94953 14.0549 2.83228L11.4057 0.183062C11.3477 0.125023 11.2788 0.0789822 11.2029 0.047571C11.1271 0.0161599 11.0458 -7.77278e-06 10.9638 -7.76919e-06C10.8817 -7.7656e-06 10.8004 0.0161599 10.7246 0.0475711C10.6487 0.0789822 10.5798 0.125023 10.5218 0.183062L10.5217 0.183062Z"
                      fill="#22C17B"
                    />
                    <path
                      d="M3.543 15.611C3.00167 14.981 2.535 14.2693 2.143 13.476C1.751 12.6873 1.45233 11.859 1.247 10.991C1.04167 10.1183 0.946 9.248 0.96 8.38C0.946 7.512 1.04167 6.644 1.247 5.776C1.45233 4.90333 1.751 4.07267 2.143 3.284C2.535 2.49067 3.00167 1.779 3.543 1.149L4.635 1.961C4.075 2.62367 3.62467 3.305 3.284 4.005C2.94333 4.70033 2.69367 5.41433 2.535 6.147C2.37633 6.875 2.297 7.61933 2.297 8.38C2.297 9.136 2.37633 9.88033 2.535 10.613C2.69367 11.3457 2.94333 12.062 3.284 12.762C3.62933 13.462 4.07967 14.141 4.635 14.799L3.543 15.611Z"
                      fill="#22C17B"
                    />
                    <path
                      d="M18.457 1.389C18.9983 2.019 19.465 2.73067 19.857 3.524C20.249 4.31267 20.5477 5.141 20.753 6.009C20.9583 6.88167 21.054 7.752 21.04 8.62C21.054 9.488 20.9583 10.356 20.753 11.224C20.5477 12.0967 20.249 12.9273 19.857 13.716C19.465 14.5093 18.9983 15.221 18.457 15.851L17.365 15.039C17.925 14.3763 18.3753 13.695 18.716 12.995C19.0567 12.2997 19.3063 11.5857 19.465 10.853C19.6237 10.125 19.703 9.38067 19.703 8.62C19.703 7.864 19.6237 7.11967 19.465 6.387C19.3063 5.65433 19.0567 4.938 18.716 4.238C18.3707 3.538 17.9203 2.859 17.365 2.201L18.457 1.389Z"
                      fill="#22C17B"
                    />
                  </svg>
                  <svg
                    v-else
                    width="22"
                    height="16"
                    viewBox="0 0 22 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.4783 15.8169L14.0513 13.2441C14.1685 13.1268 14.2344 12.9679 14.2344 12.8021C14.2344 12.6363 14.1685 12.4774 14.0513 12.3602C13.9341 12.2429 13.7751 12.1771 13.6094 12.1771C13.4436 12.1771 13.2846 12.2429 13.1674 12.3602L11.6132 13.9144C11.6145 13.8979 11.6157 13.8813 11.6157 13.8644L11.6157 0.625C11.6157 0.45924 11.5499 0.300269 11.4327 0.183058C11.3154 0.0658481 11.1565 3.67036e-08 10.9907 3.86802e-08C10.825 4.06569e-08 10.666 0.0658481 10.5488 0.183058C10.4316 0.300269 10.3657 0.45924 10.3657 0.625L10.3657 13.8203L8.82922 12.2838C8.71201 12.1666 8.55302 12.1007 8.38724 12.1007C8.22145 12.1007 8.06245 12.1665 7.9452 12.2837C7.82796 12.4009 7.76207 12.5599 7.76205 12.7257C7.76202 12.8915 7.82785 13.0505 7.94506 13.1677L10.5943 15.8169C10.6523 15.875 10.7212 15.921 10.797 15.9524C10.8729 15.9838 10.9542 16 11.0362 16C11.1183 16 11.1996 15.9838 11.2754 15.9524C11.3512 15.921 11.4202 15.875 11.4782 15.8169L11.4783 15.8169Z"
                      fill="#E63114"
                    />
                    <path
                      d="M3.543 15.611C3.00167 14.981 2.535 14.2693 2.143 13.476C1.751 12.6873 1.45233 11.859 1.247 10.991C1.04167 10.1183 0.946 9.248 0.96 8.38C0.946 7.512 1.04167 6.644 1.247 5.776C1.45233 4.90333 1.751 4.07267 2.143 3.284C2.535 2.49067 3.00167 1.779 3.543 1.149L4.635 1.961C4.075 2.62367 3.62467 3.305 3.284 4.005C2.94333 4.70033 2.69367 5.41433 2.535 6.147C2.37633 6.875 2.297 7.61933 2.297 8.38C2.297 9.136 2.37633 9.88033 2.535 10.613C2.69367 11.3457 2.94333 12.062 3.284 12.762C3.62933 13.462 4.07967 14.141 4.635 14.799L3.543 15.611Z"
                      fill="#E63114"
                    />
                    <path
                      d="M18.457 1.389C18.9983 2.019 19.465 2.73067 19.857 3.524C20.249 4.31267 20.5477 5.141 20.753 6.009C20.9583 6.88167 21.054 7.752 21.04 8.62C21.054 9.488 20.9583 10.356 20.753 11.224C20.5477 12.0967 20.249 12.9273 19.857 13.716C19.465 14.5093 18.9983 15.221 18.457 15.851L17.365 15.039C17.925 14.3763 18.3753 13.695 18.716 12.995C19.0567 12.2997 19.3063 11.5857 19.465 10.853C19.6237 10.125 19.703 9.38067 19.703 8.62C19.703 7.864 19.6237 7.11967 19.465 6.387C19.3063 5.65433 19.0567 4.938 18.716 4.238C18.3707 3.538 17.9203 2.859 17.365 2.201L18.457 1.389Z"
                      fill="#E63114"
                    />
                  </svg>
                </template>
              </td>
              <td class="tw-py-[14px] tw-pr-[31px]">
                <ViewPanelLink
                  v-if="changeInfo.creatorId"
                  :id="changeInfo.creatorId"
                  label="Пользователь"
                  component="employee-show"
                >
                  <EmployeeName :id="changeInfo.creatorId" />
                </ViewPanelLink>
              </td>
            </tr>
          </template>
          <tr v-else>
            <td
              colspan="99"
              class="tw-py-[31px] tw-text-an-flat-gray-4 tw-text-center"
            >
              Нет данных
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      v-else-if="error"
      class="tw-p-[20px] tw-m-auto tw-text-an-flat-red-main"
    >
      <pre>
        {{ error }}
      </pre>
    </div>
    <div
      v-else
      class="tw-p-[20px] tw-m-auto tw-text-an-flat-disabled-text tw-text-main-lg"
    >
      Нет данных
    </div>
  </div>
</template>

<script>
import { API } from '@/Api/ObjectStorage'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import EmployeeName from '@/components/other/employee/EmployeeName.vue'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink.vue'

export default {
  name: 'RealtyApartmentPriceHistory',
  components: { ViewPanelLink, EmployeeName, TwCircleLoader },
  props: {
    id: {
      type: String,
      default: null
    },
    title: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      API: API.NbSelection,
      info: null,
      error: null,
      loading: false
    }
  },
  mounted () {
    this.fetchInfo()
  },
  methods: {
    fetchInfo () {
      if (this.API && this.id) {
        this.loading = true
        this.error = null
        this.API.objectPriceHistory(this.id).then(({ data: result }) => {
          this.info = result?.data || null
        }).catch((error) => {
          console.error(this.$options.name, 'fetchInfo', error)
          this.info = null
          this.error = error
        }).finally(() => {
          this.loading = false
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
