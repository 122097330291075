import { render, staticRenderFns } from "./TwPingPoint.vue?vue&type=template&id=42a1c1e8&scoped=true"
import script from "./TwPingPoint.vue?vue&type=script&lang=js"
export * from "./TwPingPoint.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42a1c1e8",
  null
  
)

export default component.exports