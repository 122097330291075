<template>
  <div class="tw-font-manrope tw-flex tw-flex-col tw-gap-[16px]">
    <div style="display: none">
      {{ isEdited }}
    </div>

    <TwInputText
      ref="surname"
      v-model="form.surname"
      type="text"
      autocomplete="off"
      label="Фамилия"
      placeholder="Фамилия"
      :error-text="serverErrors.surname || formErrors.surname"
      :disabled="formProcessing"
      :show-label="true"
      :persistent-label="true"
      @input="$v.form.$touch()"
      @blur="$v.form.$touch()"
    />

    <TwInputText
      ref="name"
      v-model="form.name"
      type="text"
      autocomplete="off"
      label="Имя"
      placeholder="Имя"
      :error-text="serverErrors.name || formErrors.name"
      :disabled="formProcessing"
      :show-label="true"
      :persistent-label="true"
      @input="$v.form.$touch()"
      @blur="$v.form.$touch()"
    />

    <TwInputText
      ref="fatherName"
      v-model="form.fatherName"
      type="text"
      autocomplete="off"
      label="Отчество"
      placeholder="Отчество"
      :error-text="formErrors.fatherName"
      :disabled="formProcessing"
      :show-label="true"
      :persistent-label="true"
    />

    <div class="tw-flex tw-gap-[10px] tw-flex-wrap">
      <TwSelectButton
        ref="gender"
        v-model="form.gender"
        class="tw-flex-1"
        label="Пол"
        :disabled="formProcessing"
        :options="dictionary.gender"
        :error-text="formErrors.gender"
      />
      <TwInputText
        ref="birthday"
        v-model="form.birthday"
        class="tw-flex-1"
        type="date"
        autocomplete="off"
        label="Дата рождения"
        placeholder="Дата рождения"
        min="1920-01-01"
        cleanable
        :max="new Date().toISOString().slice(0, 10)"
        :error-text="formErrors.birthday"
        :disabled="formProcessing"
        :show-label="true"
        :persistent-label="true"
      />
    </div>

    <div class="tw-flex tw-gap-[10px] tw-flex-wrap">
      <div class="tw-flex-1">
        <TwInputPhone
          ref="workPhone"
          v-model="form.workPhone"
          :disabled="formProcessing"
          :show-label="true"
          :persistent-label="true"
          label="Рабочий телефон"
          @input="$v.form.$touch()"
        />
      </div>
      <div class="tw-flex-1">
        <TwInputText
          ref="sipPhone"
          v-model="form.sipPhone"
          type="text"
          autocomplete="off"
          label="SIP телефон"
          placeholder="SIP телефон"
          :error-text="formErrors.sipPhone"
          :disabled="formProcessing"
          :show-label="true"
          :persistent-label="true"
        />
      </div>
    </div>

    <TwInputText
      ref="email"
      v-model="form.email"
      type="email"
      label="E-mail"
      placeholder="Введите Email"
      :disabled="formProcessing"
      :error-text="formErrors.email"
      :show-label="true"
      :persistent-label="true"
      @input="$v.form.$touch()"
      @blur="$v.form.$touch()"
    />

    <div class="tw-flex tw-gap-[10px] tw-flex-wrap">
      <div class="tw-flex-1">
        <TwInputText
          ref="password"
          v-model="form.password"
          type="password"
          autocomplete="new-password"
          label="Пароль"
          placeholder="Пароль"
          :disabled="formProcessing"
          :show-label="true"
          :persistent-label="true"
          :error-text="formErrors.password"
          @input="$v.form.$touch()"
          @blur="$v.form.$touch()"
        />
      </div>
      <div class="tw-flex-1">
        <TwInputText
          ref="password_confirmation"
          v-model="form.password_confirmation"
          type="password"
          autocomplete="new-password"
          label="Повтор пароля"
          placeholder="Повторите пароль"
          :disabled="formProcessing"
          :show-label="true"
          :persistent-label="true"
          :error-text="formErrors.password_confirmation"
          @input="$v.form.$touch()"
          @blur="$v.form.$touch()"
        />
      </div>
    </div>

    <div>
      <InputUserStructures v-model="form.userStructure" />
    </div>

    <div>
      <TwInputSwitch
        ref="active"
        v-model="form.active"
        @input="$v.form.$touch()"
        @blur="$v.form.$touch()"
      >
        <template #label>
          <span class="tw-relative tw-text-an-flat-grey-4 tw-font-manrope tw-font-semibold tw-text-[14px]">
            Активность
            <span :class="form.active ? 'tw-text-an-flat-red-main' : 'tw-text-an-flat-grey-4'">
              {{ form.active ? 'Да' : 'Нет' }}
            </span>
          </span>
        </template>
      </TwInputSwitch>
    </div>

    <TwCardSimpleFormSaveBlock
      class="tw-mt-[15px]"
      :loading="formProcessing"
      :cancel-confirm="isEdited"
      :is-sticky="true"
      :disable-success="!isEdited || formProcessing"
      :disable-cancel="formProcessing"
      :visible="true"
      @success="handleSubmit"
      @cancel="handleCancel"
    />
  </div>
</template>

<script>
import API from '@/Api/Auth/User/index'
import profileAPI from '@/Api/Auth/Profile/index'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import { required, sameAs } from 'vuelidate/lib/validators'
import InputUserStructures from '@/components/input/userStructure/InputUserStructures.vue'
import TwInputText from '@/components/tw-ui/ui-kit/input/TwInputText.vue'
import TwSelectButton from '@/components/tw-ui/ui-kit/button/TwSelectButton.vue'
import TwCardSimpleFormSaveBlock from '@/components/tw-ui/forms/TwCardSimpleFormSaveBlock.vue'
import TwInputSwitch from '@/components/tw-ui/ui-kit/input/TwInputSwitch.vue'
import TwInputPhone from '@/components/tw-ui/ui-kit/custom/TwInputPhone.vue'

export default {
  name: 'EmployeeMainForm',
  components: {
    TwInputPhone,
    TwInputSwitch,
    TwCardSimpleFormSaveBlock,
    TwSelectButton,
    TwInputText,
    InputUserStructures
  },
  mixins: [FormCreateEditMixin],
  props: {
    isMyProfile: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      API: API,
      form: {
        active: true,
        name: null,
        surname: null,
        fatherName: null,
        workPhone: '',
        sipPhone: '',
        gender: null,
        email: null,
        birthday: null,
        userStructure: [],
        password: '',
        password_confirmation: ''
      },

      serverErrors: {}
    }
  },
  validations: {
    form: {
      name: {
        required,
        serverFailed: function () {
          return !(this.serverErrors?.name)
        }
      },
      surname: {
        required,
        serverFailed: function () {
          return !(this.serverErrors?.surname)
        }
      },
      sipPhone: {
        serverFailed: function () {
          return !(this.serverErrors?.sipPhone)
        }
      },
      workPhone: {
        specFormat: (v) => {
          return !v || (!!v && v.toString().replace(/[^\d]/g, '') && v.replace(/[^\d]/g, '').length >= 11)
        },
        serverFailed: function () {
          return !(this.serverErrors?.workPhone)
        }
      },
      email: {
        required,
        specFormat: (v) => {
          return !v || (!!v && /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(v))
        },
        serverFailed: function () {
          return !(this.serverErrors?.email)
        }
      },
      password: {
        required: function (v) {
          return this.formType === 'create' ? required(v) : true
        },
        sameAs: sameAs(function () {
          return this.form.password_confirmation
        }),
        serverFailed: function () {
          return !(this.serverErrors?.password)
        }
      },
      password_confirmation: {
        required: function (v) {
          return this.formType === 'create' ? required(v) : true
        },
        sameAs: sameAs(function () {
          return this.form.password
        }),
        serverFailed: function () {
          return !(this.serverErrors?.password_confirmation)
        }
      }
    }
  },
  computed: {
    formErrors () {
      const errors = {}
      // Сбор локальных ошибок
      for (const fieldName in this.$v.form.$params) {
        // console.log(fieldName)
        errors[fieldName] = []
        if (!this.$v.form[fieldName].$dirty) return errors[fieldName]
        if (this.$v.form[fieldName].required !== undefined && !this.$v.form[fieldName].required) {
          errors[fieldName].push('Обязательное поле')
          this.$Global.scrollToElem(this.$refs[fieldName].$el)
        }
        if (this.$v.form[fieldName].sameAs !== undefined && !this.$v.form[fieldName].sameAs) {
          errors[fieldName].push('Поля не совпадают')
          this.$Global.scrollToElem(this.$refs[fieldName].$el)
        }
        // console.log(this.$v.form[fieldName].specFormat)
        if (this.$v.form[fieldName].specFormat !== undefined && !this.$v.form[fieldName].specFormat) {
          errors[fieldName].push('Неверный формат')
          this.$Global.scrollToElem(this.$refs[fieldName].$el)
        }
        if (this.$v.form[fieldName].serverFailed !== undefined && !this.$v.form[fieldName].serverFailed) {
          this.$Global.scrollToElem(this.$refs[fieldName].$el)
          errors[fieldName] = [...(this.serverErrors[fieldName] || []), ...(errors[fieldName] || [])]
          // удалю поле из ошибок чтобы при вводе оно не отображалось
          // delete this.serverErrors[fieldName]
        }
      }
      return errors
    }
  },
  methods: {
    handleResetCustom () {
      this.handleReset()
    },
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          for (const key in this.preset) {
            if (key === 'file' && this.preset[key]) {
              this.$set(this.form, 'fileId', this.preset[key]?.id || null)
            } else if (key === 'gender' && this.preset[key]) {
              this.$set(this.form, key, this.preset[key].value)
            } else if (key === 'userStructure' && Array.isArray(this.preset[key])) {
              const prepareUserStructure = this.preset[key].map(item => {
                return {
                  id: item?.id || null,
                  structureId: item?.structure?.id || null,
                  positionId: item?.position?.id || null
                }
              })
              this.$set(this.form, 'userStructure', prepareUserStructure)
            } else if (key === 'workPhone' && this.preset[key]) {
              this.$set(this.form, key, this.preset[key].toString())
            } else if (key !== 'rulesCalc') {
              this.$set(this.form, key, this.preset[key])
            }
          }
        }
        resolve()
      })
    },
    handleSubmit () {
      // console.warn(this.$options.name, ' handleSubmit ', this.formType, this.targetId, Object.keys(this.$v.form.$params))
      console.warn(this.$options.name, ' handleSubmit ', this.$v.form.$invalid, this.$v.form, this.form)
      this.$set(this, 'serverErrors', {})
      this.$v.$reset()
      this.$v.form.$touch()
      if (!this.$v.form.$invalid) {
        if (this.formProcessing === true) {
          return
        }

        this.formProcessing = true

        const formData = this.form

        // TODO Временный костыль - жестко заданный ID франшизы - для создания пользователей
        if (this.formType === 'create') {
          formData.accountId = '3fa0f013-7f52-4976-8737-7b9b4a1469d0'
        }
        if (this.formType === 'edit' && !formData.password) {
          delete formData.password
        }

        let request = null
        // для своего профиля необходимо использовать иной АПИ ибо права доступа...
        if (this.isMyProfile && this.formType === 'edit') {
          request = profileAPI.update(formData)
        } else {
          // в иных случаях используем стандартный АПИ работы с пользователем
          request = this.formType === 'create'
            ? this.API.store(formData)
            : this.API.update(this.targetId, formData)
        }

        request.then(({ data }) => {
          if (this.formType === 'create') {
            // console.log('+++++++', data)
            this.$emit('create', data.data, this.$route.name)
          } else {
            this.$emit('update', data.data)
          }
          this.$emit('refresh', data.data)
        }).catch((error) => {
          if (error?.response?.data) {
            console.error(this.$options.name, error.response.data)
            for (const key in error.response.data) {
              const [refKey, index, field] = key.split('.')
              console.log(key, refKey, index, field)
              if (!this.serverErrors) this.serverErrors = {}
              if (!this.serverErrors[refKey]) this.$set(this.serverErrors, refKey, [])
              console.log(refKey, error.response.data[key][0])
              this.serverErrors[refKey].push(error.response.data[key][0])
            }
          }
        }).finally(() => {
          this.formProcessing = false
        })
      }
    }
  }
}
</script>
