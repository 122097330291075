import { Module } from 'vuex'
import { RootState } from '@/store/types'
import { TPanel, ViewPanelsState } from './types'

const IS_DEV = process.env.NODE_ENV !== 'production'
const namespaced = true

export const viewPanels: Module<ViewPanelsState, RootState> = {
  namespaced,
  state: {
    panels: []
  },
  actions: {
    showPanel ({ commit }, config) {
      // console.log('showPanel', config)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this._vm?.$Global?.YandexMetrika?.hit({ ...(config || {}), ...{ source: 'panel' } }) // Сообщить метрике о открытии панели

      if (!config) {
        return console.error('viewPanels/showPanel - BAD config data', { config })
      }
      commit('SET_PANEL', config)
    }
  },
  mutations: {
    SET_PANEL (state, data: TPanel) {
      // console.log('SET_PANEL', data)
      if (!data.viewType) {
        data.viewType = 'panel'
      }
      if (!data.callback) {
        data.callback = (data: any) => {
          if (IS_DEV) {
            console.warn('WARNING! Callback not found!', 'viewPanels/SET_PANEL', data)
          }
        }
      }
      state.panels.push(data)
    },
    REMOVE_PANEL (state, index = 0) {
      state.panels.splice(index, 1)
    },
    REMOVE_ALL_PANEL (state) {
      state.panels = []
    }
  },
  getters: {
    getPanels (state) {
      return state.panels
    },
    targetPanel (state) {
      return Array.isArray(state.panels) && state.panels.length ? state.panels[state.panels.length - 1] : null
    }
  }
}
