<template>
  <div class="tw-fill-an-flat-black-table">
    <!-- Headers -->
    <div
      class="tw-grid tw-border-b tw-font-semibold tw-border-collapse"
      :style="gridStyles"
    >
      <template v-for="(header, index) of headersList">
        <div
          v-show="header.visible"
          :key="`${header.dataKey}_${index}`"
          :data-col-key="header.dataKey"
          :class="header.thClasses"
          :style="{ textAlign: header.align }"
          class="tw-relative tw-pr-[22px] tw-border-t tw-border-r tw-border-solid first:tw-border-l"
        >
          {{ header.text }}
          <button
            v-if="header.sortable"
            class="tw-flex tw-gap-[4px] tw-flex-col tw-p-[9px] tw-absolute tw-right-0 tw-top-0"
            @click.stop="onChangeSort(header)"
          >
            <svg
              width="8"
              height="6"
              viewBox="0 0 8 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                :class="[sort.sortField === header.dataKey && sort.sortDirection === 'asc' ? 'tw-fill-an-flat-blue-btn':'tw-fill-an-flat-disabled-text']"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.76822 5.1402C7.41465 5.56447 6.78409 5.6218 6.35981 5.26823L3.99999 3.30172L1.64018 5.26823C1.2159 5.6218 0.585337 5.56447 0.231773 5.1402C-0.121791 4.71592 -0.0644668 4.08535 0.35981 3.73179L3.99999 0.698303L7.64018 3.73179C8.06446 4.08535 8.12178 4.71592 7.76822 5.1402Z"
                fill="#343942"
              />
            </svg>
            <svg
              width="8"
              height="5"
              viewBox="0 0 8 5"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                :class="[sort.sortField === header.dataKey && sort.sortDirection === 'desc' ? 'tw-fill-an-flat-blue-btn':'tw-fill-an-flat-disabled-text']"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.231781 0.35981C0.585345 -0.0644668 1.21591 -0.121791 1.64019 0.231773L4 2.19829L6.35982 0.231773C6.78409 -0.121791 7.41466 -0.0644668 7.76822 0.35981C8.12179 0.784087 8.06446 1.41465 7.64019 1.76822L4 4.8017L0.359818 1.76822C-0.0644591 1.41465 -0.121783 0.784087 0.231781 0.35981Z"
                fill="#9FA6B4"
              />
            </svg>
          </button>
        </div>
      </template>
    </div>
    <!-- Rows -->
    <div
      v-if="Array.isArray(itemsList) && itemsList.length"
      :style="{ maxHeight: bodyMaxHeight }"
      class="tw-overflow-auto"
    >
      <div
        v-for="(item, iIndex) of itemsList"
        :key="iIndex"
        :class="tbodyRowClasses"
        :style="gridStyles"
        class="tw-grid"
        @click.passive="$emit('row-click', item)"
      >
        <!-- Cells -->
        <template v-for="(header, hIndex) in headersList">
          <div
            v-show="header.visible"
            :key="`${header.dataKey}_${hIndex}`"
            :data-col-key="header.dataKey"
            :class="[header.tdClasses]"
            :style="{ textAlign: header.align }"
            class="tw-border-solid tw-border-r first:tw-border-l"
          >
            <slot
              :name="`item.${header.dataKey}`"
              v-bind="{ item, header }"
            >
              {{ item[header.dataKey] }}
            </slot>
          </div>
        </template>
      </div>
    </div>
    <div
      v-else
      class="tw-text-center tw-text-an-flat-disabled-text tw-p-[30px]"
    >
      Нет данных
    </div>
    <div
      v-if="lastPage && lastPage > 1"
      class="tw-flex tw-justify-center tw-p-[10px]"
    >
      <TwPagination
        :disabled="loading"
        :value="currentPage"
        :length="lastPage"
        @change="onChangePagination"
      />
    </div>
    <div
      v-if="errorMessage"
      class="tw-text-an-flat-red-main tw-p-[10px]"
    >
      <pre>
        {{ errorMessage }}
      </pre>
    </div>
  </div>
</template>
<script>
import TwPagination from '../ui-kit/table/TwPagination.vue'

export default {
  name: 'TwTableSimple',
  components: {
    TwPagination
  },
  mixins: [],
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      required: true
    },
    headers: {
      type: Array,
      required: true
    },
    itemsPerPage: {
      type: Number,
      default: -1 // -1 По умолчанию - все элементы без пагинации
    },
    errorMessage: {
      type: String,
      default: null
    },
    bodyMaxHeight: {
      type: String,
      default: 'auto' // Ограничение высоты содежимого таблицы - если больше - появится прокрутка
    },
    paddingClasses: {
      type: String,
      default: 'tw-p-[7px]'
    },
    tbodyRowClasses: {
      type: String,
      default: 'tw-border-b odd:tw-bg-an-flat-bg-main even:tw-bg-white hover:tw-bg-an-flat-gray-8'
    }
  },
  data () {
    return {
      currentPage: 1,
      sort: {
        sortField: null,
        sortDirection: null
      }
    }
  },
  computed: {
    gridStyles () {
      return {
        // Собираю инфтрукции по ширине колонок
        gridTemplateColumns: this.headersList.reduce(
          (accumulator, currentValue) => accumulator + (`${currentValue.maxWidth || '1fr'} `),
          ''
        )
      }
    },
    lastPage () {
      return Math.ceil((Array.isArray(this.items) ? this.items.length : 0) / this.itemsPerPage)
    },
    headersList () {
      return this.headers.map((column) => {
        return {
          visible: true,
          sortable: false,
          align: 'left',
          thClasses: this.paddingClasses,
          tdClasses: this.paddingClasses,
          ...column
        }
      })
    },
    itemsList () {
      if (!Array.isArray(this.items)) {
        return []
      }

      const sorter = (a, b) => {
        if (a?.[this.sort.sortField] > b?.[this.sort.sortField]) {
          return this.sort.sortDirection === 'asc' ? 1 : -1
        }
        if (a?.[this.sort.sortField] < b?.[this.sort.sortField]) {
          return this.sort.sortDirection === 'desc' ? 1 : -1
        }
        return 0
      }

      // Если указано кол - во элементов на странице - будет показана пагинация
      if (this.itemsPerPage && this.itemsPerPage > 0) {
        return [...this.items].sort(sorter).slice((this.currentPage - 1) * this.itemsPerPage, this.currentPage * this.itemsPerPage)
      }
      return [...this.items].sort(sorter)
    }
  },
  methods: {
    onChangePagination (eventData) {
      this.currentPage = eventData
    },
    // Сортировка
    onChangeSort (headerData) {
      // console.warn('onChangeSort ', headerData)
      const newSort = {
        sortField: null,
        sortDirection: null
      }
      if (this.sort.sortField !== headerData?.dataKey) {
        newSort.sortDirection = null
      }
      const targetSortDirection = !this.sort?.sortDirection ? 'asc' : this.sort?.sortDirection === 'asc' ? 'desc' : this.sort?.sortDirection === 'desc' ? null : 'asc'

      newSort.sortField = targetSortDirection ? (headerData?.dataKey || null) : null
      newSort.sortDirection = targetSortDirection
      this.sort = {
        ...newSort
      }
    }
  }
}
</script>
