<template>
  <span
    v-if="info"
    :data-person-id="id"
  >
    <span :style="isStyleName">
      {{ name || '~' }}
    </span>
  </span>
  <v-skeleton-loader
    v-else
    type="text"
  />
</template>

<script>
export default {
  name: 'PersonName',
  props: {
    id: {
      type: Number,
      required: true
    },
    isStyleName: {
      type: String,
      default: ''
    },
    format: {
      type: String,
      default: 'fi'
    }
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    name () {
      return this.$Global.peopleName(this.info, this.format)
    },
    info () {
      return this.$store.getters['tmpCache/getPersonById'](this.id)
    }
  }
}
</script>

<style scoped>

</style>
