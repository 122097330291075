import qs from 'qs'

export default {
  props: {
    id: {
      type: [Number, String],
      default: 0
    },
    fromPanel: {
      type: Boolean,
      default: false
    },
    workToClose: {
      type: Boolean,
      default: false
    },
    copyData: {
      type: Object,
      default: null
    },
    // Вкладка которая будет активна при открытии карточки
    presetTab: {
      type: String,
      default: null
    },
    // Предзаданное состояние формы редактивания
    presetTabEdit: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      API: null,
      error: false,
      loading: false,
      data: null,
      dictionary: null, // Словари
      scheme: null, // Схемы данных инструкции для фронта с бека
      permissions: {}, // Разрешения
      otherData: null,
      additionalData: {},
      ui: {}, // инструкции для интерфейсов
      currentTab: this.presetTab || 0,
      editMain: this.presetTabEdit || false,
      mainFormEdited: false,
      showMethodKey: 'show' // show OR showShort OR showObject
    }
  },
  methods: {
    onSubmit () {
      this.$store.dispatch('cards/saveBlockSuccess', {})
      if (this.editMain && this.$refs.EditMain) {
        this.$refs.EditMain.handleSubmit()
      }
    },
    onCreated (data) {
      console.log('onCreated data', data)
      this.editMain = false
      this.mainFormEdited = false
      this.fetch(data.id).finally(() => {
        this.$emit('callback', { data })
        this.$emit('create', { data })

        this.$nextTick(() => {
          if (this.workToClose) {
            this.$emit('close-me')
          } else {
            // если данные были получены и есть ID, а в хэше параметров он отсутствует, - добавляю ID в хеш
            const hashData = qs.parse(this.$route.hash.replace('#', ''))
            // console.warn('+++++++++++++', this.$route, hashData)
            if (!hashData?.props?.id || hashData?.props?.id === '0') {
              hashData.props.id = data.id
              const strHashParams = qs.stringify(hashData, {
                arrayFormat: 'brackets'
              })
              // console.warn('strHashParams: ', strHashParams)
              this.$router.replace({ hash: `#${strHashParams}` })
            }
          }
        })
      })
    },
    onCancel () {
      this.$store.dispatch('cards/saveBlockCancel', {})
      this.editMain = false
      this.mainFormEdited = false
      if (this.isNew) {
        if (this.fromPanel) {
          this.$emit('close-me')
        } else {
          // console.log(this.$route.matched[0])
          if (this.$route.matched[0]) {
            this.$router.push({ name: this.$route.matched[0]?.name })
          } else {
            console.error(this.$options.name, 'onCancel matched redirect Error')
          }
        }
      }
    },
    // TODO used? DELETE
    onCancelAndClose (data) {
      console.log('onCancelAndClose', data)
      this.editMain = false
      this.mainFormEdited = false
      // Если нет ID - значит это карточка не созданного объекта - нужно закрыть
      if (!this?.data?.id) {
        this.$emit('close-me')
      }
    },
    onUpdated (data) {
      console.log('onUpdated data', data)
      this.editMain = false
      this.mainFormEdited = false
      this.fetch(data.id).finally(() => {
        this.$emit('callback', { data })

        this.$nextTick(() => {
          if (this.workToClose) {
            this.$emit('close-me')
          }
        })
      })
    },
    fetch (id = this.id || this.data?.id) {
      this.error = false
      this.loading = true

      return this.API?.[this.showMethodKey](id)
        .then((result) => {
          const { data, dictionary, permissions = {}, scheme, ui, ...others } = result.data
          this.data = data
          this.dictionary = dictionary
          this.scheme = scheme
          this.permissions = permissions
          this.otherData = others
          this.ui = ui || {}
          if (others.additionalData && Object.keys(others.additionalData).length) {
            for (const key in others.additionalData) {
              this.$set(this.additionalData, [key], others.additionalData[key])
            }
          }
          if (this.postFetch) {
            this.postFetch(data)
          }
        })
        .catch((error) => {
          // console.error(error)
          // обработка ошибки запроса
          if (error?.response?.status) {
            this.error = error.response.status
          } else {
            this.error = true
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    // пометка о прочтении - сейчас задействована только в EMAIL
    read (id = this.id) {
      this.loading = true

      this.API.read(id, true)
        .then((result) => {
          console.log(this.$options.name, 'read:', result)
          this.$emit('callback', { data: result.data })
        })
        .catch((error) => {
          console.log(this.$options.name, 'read:', error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    deleteCardItem () {
      // delete
      this.API.delete(this.id || this.data?.id).then(() => {
        this.$emit('callback')
        this.$emit('close-me')
      })
    },
    onDeleted () {
      this.$emit('callback')
      this.$emit('close-me')
    },
    onCopied () {
      this.$emit('callback')
      this.refresh()
    },
    refresh () {
      if (this.isNew) {
        this.editMain = true
      } else {
        this.fetch()
      }
    }
  },
  computed: {
    isNew () {
      // ID из параметров или при создании нового объекта ID из полученных данных
      return !(this.id) && !(this.data?.id)
    },
    isCardContentVisible () {
      // принимаю решение - рисовать контент карточки или нет и будут рисоваться прелодер\ошибка
      // TODO ???? !this.loading &&
      return !this.error && (!this.loading || !!this.data)
    },
    actions () {
      const { entity } = this.permissions || {}
      return {
        read: entity?.read || null,
        create: entity?.create || null,
        update: entity?.update || null,
        delete: entity?.delete || null
      }
    }
  },
  watch: {
    editMain (newValue) {
      if (!newValue) {
        this.mainFormEdited = false
      }
    }
  },
  mounted () {
    // проверка на наличии API
    if (!this.API) {
      alert(`API Not Found: ${this.$options.name}`)
    } else {
      this.refresh()
    }
  }
}
