<template>
  <div>
    <div
      v-if="loading"
      class="tw-flex tw-justify-center tw-w-full tw-p-3"
    >
      <TwCircleLoader />
    </div>
    <div
      v-else-if="Array.isArray(rulesForView) && rulesForView.length"
      class="tw-grid tw-items-start tw-grid-cols-2 tw-gap-x-[10px] tw-gap-y-[8px]"
    >
      <TwSpoiler
        v-for="(group, gIndex) in rulesForView"
        :key="gIndex"
        has-shadow
        title-classes="tw-px-[24px] tw-py-[16px] tw-font-semibold tw-bg-white tw-text-main-sm tw-w-full"
        content-classes="tw-px-[24px] tw-py-[16px] tw-bg-white tw-w-full"
        title-variant="block"
      >
        <template #title>
          <div class="tw-flex tw-gap-[6px]">
            {{ group.name }}
            <TwBadgeLabel
              v-if="group.changedRulesCount"
              :label="`Персональные: ${group.changedRulesCount}`"
              size="xs"
              rounded="sm"
              color="blue"
              title="персонально назначенные правила"
            />
            <TwBadgeLabel
              v-if="group.ruleValueInheritedCount"
              :label="`Структурное: ${group.ruleValueInheritedCount}`"
              size="xs"
              rounded="sm"
              color="grey"
              title="право наследуемое из структуры"
            />
            <TwBadgeLabel
              v-if="group.ruleValueInheritedDepartmentCount"
              :label="`Отдела: ${group.ruleValueInheritedDepartmentCount}`"
              size="xs"
              rounded="sm"
              color="grey"
              title="право наследуемое из отделов"
            />
          </div>
        </template>
        <div class="">
          <div
            v-for="(rule, rIndex) in group.rules"
            :key="rIndex"
            class="mb-3"
            :data-id="rule.id"
            :data-namespace="rule.namespace"
          >
            <div class="font-weight-medium mb-1 tw-flex tw-gap-[6px] tw-flex-wrap tw-justify-between tw-items-center">
              <div>
                {{ rule.name }}:
              </div>
              <div
                v-if="isAdmin"
                class="tw-text-main-xs"
              >
                <ClickBoard
                  title-active="Скопировать namespace правила"
                  :disabled="!rule.namespace"
                  icon-size="15"
                  :value="rule.namespace"
                >
                  <template #button-text>
                    {{ rule.namespace }}
                  </template>
                </ClickBoard>
              </div>
            </div>
            <div class="tw-flex tw-mb-[6px]">
              <TwBadgeLabel
                v-if="rule.ruleValueInherited"
                :label="`Структура: ${getRuleInheritedLabel(rule)}`"
                size="xs"
                rounded="sm"
                color="grey"
                title="право наследуемое из структуры"
              />
              <TwBadgeLabel
                v-if="rule.ruleValueInheritedDepartment"
                :label="`Отдел: ${getRuleInheritedDepartmentLabel(rule)}`"
                size="xs"
                rounded="sm"
                color="grey"
                title="право наследуемое из отделов"
              />
            </div>
            <div
              v-if="formRules[rule.id]"
              class="tw-mb-[6px] tw-text-an-flat-blue-btn"
            >
              <small>* Назначено</small>
            </div>
            <TwSelect
              :value="formRules[rule.id]"
              title="Назначенное право"
              placeholder="Не установлено"
              :multi="false"
              :dense="true"
              :cleanable="true"
              :persistent-label="false"
              :options="rule.values"
              :disabled="loading"
              @input="onChangeRule($event, rule.id)"
            />
            <div
              v-if="rule.name !== rule.description"
              class="tw-ml-[6px]"
            >
              <small>*{{ rule.description }}</small>
            </div>
          </div>
        </div>
      </TwSpoiler>
    </div>
    <div
      v-else
      class="text-center"
    >
      Нет данных
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ClickBoard from '@/components/button/ClickBoard.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import TwSpoiler from '../tw-ui/other/TwSpoiler.vue'
import TwBadgeLabel from '../tw-ui/ui-kit/views/TwBadgeLabel.vue'
import TwSelect from '../tw-ui/ui-kit/input/TwSelect.vue'

export default {
  name: 'StructureFormInput',
  components: { TwCircleLoader, ClickBoard, TwSpoiler, TwBadgeLabel, TwSelect },
  props: {
    formRules: {
      type: Object,
      required: true
    },
    groups: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'profile/isAdmin'
    }),
    rulesForView () {
      // Права для отрисовки
      return (this.groups || []).map((group) => {
        group.changedRulesCount = group?.rules?.filter(rule => !!rule.ruleValue)?.length || 0
        group.ruleValueInheritedCount = group?.rules?.filter(rule => !!rule.ruleValueInherited)?.length || 0
        group.ruleValueInheritedDepartmentCount = group?.rules?.filter(rule => !!rule.ruleValueInheritedDepartment)?.length || 0
        return group
      })
    }
  },
  methods: {
    onChangeRule (eventValue, ruleId) {
      console.warn('StructureFormInput', eventValue, ruleId)
      this.$emit('change-rule', eventValue, ruleId)
    },
    getRuleInheritedLabel (rule) {
      // console.warn('getRuleInheritedLabel', rule)
      return (rule?.values || []).find(item => item.value === rule.ruleValueInherited)?.label || 'Некорректное значение'
    },
    getRuleInheritedDepartmentLabel (rule) {
      // console.warn('getRuleInheritedDepartmentLabel', rule)
      return (rule?.values || []).find(item => item.value === rule.ruleValueInheritedDepartment)?.label || 'Некорректное значение'
    }
  }
}
</script>

<style scoped>

</style>
