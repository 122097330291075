import { MutationTree } from 'vuex'
import { set, remove } from 'local-storage'
import { PrevPage, RootState } from '@/store/types'

// @typescript-eslint/ban-ts-ignore
// TODO import jwtDecode from 'jwt-decode';

export const mutations: MutationTree<RootState> = {
  setTitle (state, title: string) {
    state.pageTitle = title
  },
  setUpdates (state, updates: any) {
    state.updates = {
      exist: updates?.exist || false,
      uiVisible: updates?.uiVisible || false
    }
  },
  setPrevPage (state, prevPage?: PrevPage) {
    state.prevPage = prevPage
    if (state.prevPage) {
      set<PrevPage>('prevPage', state.prevPage)
    } else {
      remove('prevPage')
    }
  },
  CHANGE_OPENED_LEFT_PANEL (state, { routeName, levelNum, targetStatus }) {
    if (targetStatus) {
      if (!levelNum) {
        state.openedLeftPanelItems = [routeName]
      } else {
        state.openedLeftPanelItems[levelNum] = routeName
      }
    } else {
      state.openedLeftPanelItems.splice(levelNum, 2)
    }
    set('openedLeftPanelItems', state.openedLeftPanelItems)
  },
  SET_MINIFY_LEFT_PANEL (state, payload) {
    // console.warn('SET_MINIFY_LEFT_PANEL', !!payload)
    state.isMiniLeftPanel = !!payload
    set('isMiniLeftPanel', !!payload)
  },
  SET_VIEW_WITH_CLIENT (state, payload) {
    console.warn('SET_VIEW_WITH_CLIENT', !!payload)
    state.isViewWithClient = !!payload
    set('isViewWithClient', !!payload)
  },
  SET_LOADING_PAGE (state, status) {
    if (typeof status === 'boolean') {
      state.loadingPage = status
    } else {
      state.loadingPage = !!status
    }
  },
  SET_ERROR_PAGE (state, status) {
    if (typeof status === 'boolean') {
      state.errorPage = status
    } else {
      state.errorPage = !!status
    }
  },
  SET_BREADCRUMBS (state, payload) {
    state.breadcrumbs = payload
  },
  SET_DEPLOYMENT_TYPE (state, type) {
    // VUE_APP_COMMIT_SHORT_SHA: "a5991518"
    // VUE_APP_COMMIT_TAG: ""
    state.deploymentType = type
  },
  SET_DEPLOYMENT_VERSION (state, value) {
    state.version = value
  },
  SET_API_URL (state, url) {
    state.apiUrl = url
  }
}

export default null
