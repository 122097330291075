import Vue from 'vue'
import { Module } from 'vuex'
import { RootState } from '@/store/types'
import { TmpCacheState } from '../types'
import departmentAPI from '@/Api/Auth/Structure/Department'
import StructureAPI from '@/Api/Auth/Structure'
import store from '@/store/index'

const namespaced = true

export const auth: Module<TmpCacheState, RootState> = {
  namespaced,
  state: {
    // TODO WARN Пользователи кешируются тут - src/store/users/index.ts
    department: {},
    structure: {}
  },
  actions: {
    // Отделы
    fetchDepartmentList: function ({ commit }, search: string) {
      return departmentAPI.getList({ search, limit: 150 })
        .then(({ data }: any) => {
          // console.log('fetchDepartmentList', data.data)
          commit('SET_DEPARTMENT', data.data)
          return data?.data || []
        })
        .catch((error: any) => {
          console.error(error)
        })
    },
    fetchDepartmentById: function ({ commit }, id: number | string) {
      commit('SET_DEPARTMENT', { id })
      return departmentAPI.show(id)
        .then(({ data }: any) => {
          // console.log('fetchDepartmentById', data.data)
          commit('SET_DEPARTMENT', data.data)
        })
        .catch((error: any) => {
          console.error(error)
        })
    },
    // Структуры
    fetchStructureList: function ({ commit }, search: string) {
      return StructureAPI.getList({ search, limit: 150 })
        .then(({ data }: any) => {
          // console.log('fetchStructureList', data.data)
          commit('SET_STRUCTURE', data.data)
          return data?.data || []
        })
        .catch((error: any) => {
          console.error(error)
        })
    },
    fetchStructureById: function ({ commit }, id: number) {
      commit('SET_STRUCTURE', { id })
      return StructureAPI.show(id)
        .then(({ data }: any) => {
          // console.log('fetchStructureById', data.data)
          commit('SET_STRUCTURE', data.data)
        })
        .catch((error: any) => {
          console.error(error)
        })
    }
  },
  mutations: {
    SET_DEPARTMENT (state, payload) {
      // console.log('SET_DEPARTMENT', payload)
      // параметр времени запроса
      const reqTime = new Date().getTime()
      if (Array.isArray(payload)) {
        payload.forEach((item) => {
          item.rt = reqTime
          Vue.set(state.department, item.id, item)
        })
      } else if (payload.id) {
        payload.rt = reqTime
        Vue.set(state.department, payload.id, payload)
      } else {
        console.error('Необработанное условие при попытке добавить Department в tmpCache')
      }
    },
    SET_STRUCTURE (state, payload) {
      // console.log('SET_STRUCTURE', payload)
      // параметр времени запроса
      const reqTime = new Date().getTime()
      if (Array.isArray(payload)) {
        payload.forEach((item) => {
          item.rt = reqTime
          Vue.set(state.structure, item.id, item)
        })
      } else if (payload.id) {
        payload.rt = reqTime
        Vue.set(state.structure, payload.id, payload)
      } else {
        console.error('Необработанное условие при попытке добавить Structure в tmpCache')
      }
    }
  },
  getters: {
    getDepartmentById: (state) => (id: number) => {
      if (state.department[id] === undefined) {
        store.dispatch('tmpCache/auth/fetchDepartmentById', id)
      }
      return state.department[id] || null
    },
    cachedDepartmentList: (state) => {
      return Object.values(state.department) || []
    },
    getStructureById: (state) => (id: number) => {
      if (state.structure[id] === undefined) {
        store.dispatch('tmpCache/auth/fetchStructureById', id)
      }
      return state.structure[id] || null
    },
    cachedStructureList: (state) => {
      return Object.values(state.structure) || []
    }
  }
}
