import { AxiosResponse } from 'axios'
import HTTP from '@/Api/http'

const path = '/deal/insurance/result'

export default {
  create (param: any = {}): Promise<AxiosResponse> {
    const $config = {
      params: param
    }
    return HTTP.get(`${path}/create`, $config)
  },
  show (dealId: number): Promise<AxiosResponse> {
    const $config = {}
    return HTTP.get(`${path}/${dealId}`, $config)
  },
  store (dealId: number, data: any): Promise<AxiosResponse> {
    return HTTP.post(`${path}/${dealId}`, data)
  },
  update (dealId: number, data: any): Promise<AxiosResponse> {
    return HTTP.put(`${path}/${dealId}`, data)
  }
}
