<template>
  <div>
    <div
      v-if="loading"
      class="tw-h-full tw-w-full tw-flex tw-justify-center tw-items-center tw-py-5"
    >
      <TwCircleLoader
        height="50px"
        width="50px"
      />
    </div>
    <div v-else>
      <div
        :class="fromPanel ? 'tw-px-[26px] tw-py-[18px]':''"
        class="tw-font-manrope tw-text-main-sm2 tw-max-w-[100%] tw-bg-white tw-min-h-screen"
      >
        <TwPanelTitle v-if="fromPanel">
          <template #left>
            <div
              v-if="data"
              class="tw-text-h4 tw-font-bold"
            >
              <div>
                Ручной импорт файла застройщика
                <span class="tw-text-an-flat-disabled-text tw-font-medium"> # {{ data.id }}</span>
              </div>
            </div>
            <div
              v-else-if="isNew"
              class="tw-text-h4 tw-font-bold"
            >
              Ручной импорт файла застройщика
            </div>
            <div v-else>
              ~
            </div>
          </template>
        </TwPanelTitle>
        <TwPanelTabsFull
          v-model="currentTab"
          :items="tabList"
        >
          <template #content.main>
            <TwPanelContent v-if="data">
              <TwPanelContentBlock class="tw-flex-1 tw-mb-[6px]">
                <template #title>
                  Информация о загруженном файле
                </template>
                <div class="tw-grid tw-gap-[15px] tw-grid-cols-[repeat(auto-fill,_minmax(300px,_1fr))]">
                  <TwPanelContentBlockFieldView>
                    <template #title>
                      ID Импорта
                    </template>
                    <div class="tw-font-semibold">
                      {{ data.id }}
                    </div>
                  </TwPanelContentBlockFieldView>
                  <TwPanelContentBlockFieldView>
                    <template #title>
                      Статус обработки
                    </template>
                    <div
                      v-if="data.processStatus && data.processStatus.value"
                      class="tw-font-semibold"
                      :class="statusColors[data.processStatus.value]"
                    >
                      {{ data.processStatus.label }}
                    </div>
                  </TwPanelContentBlockFieldView>
                  <TwPanelContentBlockFieldView>
                    <template #title>
                      Парсер
                    </template>
                    <div v-if="data.parserTemplate">
                      <span class="tw-font-semibold">
                        {{ data.parserTemplate.label }}
                      </span>
                      <div class="tw-text-main-xs tw-text-an-flat-disabled-text">
                        {{ data.parserTemplate.value }}
                      </div>
                    </div>
                  </TwPanelContentBlockFieldView>
                  <TwPanelContentBlockFieldView>
                    <template #title>
                      Застройщик
                    </template>
                    <div
                      v-if="data.companyId"
                      class="tw-font-semibold"
                    >
                      <ViewPanelLink
                        v-if="data.companyId"
                        :id="data.companyId"
                        class=""
                        label="Застройщик"
                        component="company-show"
                      >
                        <div v-if="$store.getters['tmpCache/getCompanyById'](data.companyId)">
                          {{ $store.getters['tmpCache/getCompanyById'](data.companyId).name }}
                        </div>
                      </ViewPanelLink>
                    </div>
                  </TwPanelContentBlockFieldView>
                  <TwPanelContentBlockFieldView>
                    <template #title>
                      Загрузил
                    </template>
                    <div
                      v-if="data.creatorId"
                      class="tw-font-semibold"
                    >
                      <ViewPanelLink
                        :id="data.creatorId"
                        label="Пользователь"
                        component="employee-show"
                      >
                        <EmployeeName :id="data.creatorId" />
                      </ViewPanelLink>
                    </div>
                  </TwPanelContentBlockFieldView>
                  <TwPanelContentBlockFieldView>
                    <template #title>
                      Загруженный файл
                    </template>
                    <div
                      v-if="fileId"
                      class="tw-font-semibold"
                    >
                      <TwFilesListViewByIds
                        :file-ids="fileId"
                        :api="fileAPI"
                        :is-drop-down="false"
                        view-type="list"
                      />
                    </div>
                  </TwPanelContentBlockFieldView>
                  <TwPanelContentBlockFieldView v-if="data.error">
                    <template #title>
                      Ошибки
                    </template>
                    <div class="tw-text-an-flat-red-main tw-max-w-[300px]">
                      <ReadMoreExpander
                        :is-nl-trigger="false"
                        :text="data.error"
                        :less-length="100"
                      />
                    </div>
                  </TwPanelContentBlockFieldView>
                </div>
              </TwPanelContentBlock>
            </TwPanelContent>
            <TwPanelContent v-if="data">
              <ImportDocumentUploadedBlock
                class="tw-flex-1 tw-mb-[6px]"
                :info="data"
                :ui="ui"
              />
            </TwPanelContent>
          </template>
        </TwPanelTabsFull>
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/Api/BuilderImporter/ImportDocument/File'
import fileAPI from '@/Api/BuilderImporter/File'
import { statusColors } from '@/views/BuilderImporter/ImportDocumentFile/tableOptions'
import CardMixin from '@/mixins/CardMixin'
import TwPanelTitle from '@/components/tw-ui/panels/TwPanelTitle.vue'
import TwPanelTabsFull from '@/components/tw-ui/panels/TwPanelTabsFull.vue'
import TwPanelContent from '@/components/tw-ui/panels/TwPanelContent.vue'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import ReadMoreExpander from '@/components/other/text/ReadMoreExpander.vue'
import EmployeeName from '@/components/other/employee/EmployeeName.vue'
import TwFilesListViewByIds from '@/components/tw-ui/list/TwFilesListViewByIds.vue'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink.vue'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'
import ImportDocumentUploadedBlock
  from '@/views/BuilderImporter/ImportDocumentFile/main/ImportDocumentUploadedBlock.vue'

export default {
  name: 'ImportDocumentFileCard',
  components: {
    ImportDocumentUploadedBlock,
    TwPanelContentBlockFieldView,
    ViewPanelLink,
    TwFilesListViewByIds,
    EmployeeName,
    ReadMoreExpander,
    TwCircleLoader,
    TwPanelContentBlock,
    TwPanelContent,
    TwPanelTabsFull,
    TwPanelTitle
  },
  mixins: [CardMixin],
  props: {
    builderId: {
      default: null
    }
  },
  data () {
    return {
      statusColors,
      currentTab: 'main',
      API,
      fileAPI
    }
  },
  computed: {
    tabList () {
      return [
        {
          label: 'Общая информация',
          key: 'main'
        }
      ]
    },
    fileId () {
      return this.data?.fileId ? [this.data?.fileId] : null
    }
  }
}
</script>
