<template>
  <TwPanelContentBlock :content-classes="''">
    <div v-if="error">
      <TwPanelInfoBlock>
        <template #title>
          Недостаточно прав доступа
        </template>
        <template #text>
          для изменения прав доступа обратитесь к своему руководителю или в тех. поддержку
        </template>
      </TwPanelInfoBlock>
    </div>
    <div v-else>
      <div>
        <StructureFormInput
          :groups="groups"
          :form-rules="form.rules"
          :loading="loading"
          @change-rule="onChangeRule"
        />
      </div>

      <TwCardSimpleFormSaveBlock
        class="tw-mt-[15px]"
        style="z-index: 2"
        :loading="loading"
        :visible="true"
        :cancel-confirm="isEdited"
        :disable-success="!isEdited || loading"
        :disable-cancel="!isEdited || loading"
        @success="saveRules"
        @cancel="handleReset"
      />
    </div>
  </TwPanelContentBlock>
</template>

<script>
import userStructureApi from '@/Api/Auth/Permission/UserStructure/index'
import StructureFormInput from '@/components/input/StructureFormInput.vue'
import isEqual from 'lodash/isEqual'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwCardSimpleFormSaveBlock from '@/components/tw-ui/forms/TwCardSimpleFormSaveBlock.vue'
import TwPanelInfoBlock from '@/components/tw-ui/panels/TwPanelInfoBlock.vue'

export default {
  name: 'UserRulesTab',
  components: { TwPanelInfoBlock, TwCardSimpleFormSaveBlock, TwPanelContentBlock, StructureFormInput },
  props: {
    userId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      error: '',
      groups: [],
      form: {
        rules: {},
        userId: this.userId
      },
      originalForm: {}
    }
  },
  computed: {
    isEdited () {
      return !isEqual(this.form, this.originalForm)
    }
  },
  mounted () {
    this.refresh()
  },
  methods: {
    onChangeRule (eventValue, ruleId) {
      // console.warn('UserRulesTab', eventValue, ruleId)
      this.$set(this.form.rules, ruleId, eventValue)
    },
    // Права для отправки на перезапись
    buildRulesFormData () {
      const result = {}
      if (Array.isArray(this.groups)) {
        for (const group of this.groups) {
          for (const rule of group.rules) {
            result[rule.id] = rule?.ruleValue || null
          }
        }
      }
      return result
    },
    fetch () {
      if (!userStructureApi) return console.error(this.$options.name, 'API NOT FOUND!')
      if (this.loading) return
      this.loading = true
      this.error = null
      return userStructureApi.show(this.userId)
        .then(({ data }) => {
          console.warn(this.$options.name, data.data)
          this.groups = data?.data?.groups || []
        })
        .catch((error) => {
          console.error(this.$options.name, error)
          this.groups = []
          this.error = 'Ошибка доступа'
        })
        .finally(() => {
          this.loading = false
          this.$set(this.form, 'rules', this.buildRulesFormData())
          this.$nextTick(() => {
            this.copyOriginalForm()
          })
        })
    },
    saveRules () {
      if (this.loading === true) {
        return
      }

      this.loading = true
      const rules = Object.keys(this.form.rules).map(ruleKey => {
        return {
          ruleId: ruleKey,
          ruleValue: this.form.rules[ruleKey]
        }
      })
      userStructureApi.update({ userId: this.form.userId, rules }).then(({ data }) => {
        console.warn('saveRules', data)
        this.$nextTick(() => {
          this.$store.commit('notify/ADD', {
            title: 'Права успешно обновлены',
            type: 'success',
            timeout: 3000,
            visible: true
          })
        })
      }).catch(() => {
        this.$nextTick(() => {
          this.$store.commit('notify/ADD', {
            title: 'Ошибка сохранения прав доступа',
            type: 'error',
            timeout: 3000,
            visible: true
          })
        })
      }).finally(() => {
        this.loading = false
        this.$nextTick(() => {
          this.refresh()
        })
      })
    },
    refresh () {
      this.fetch()
    },
    handleReset () {
      this.form = JSON.parse(JSON.stringify(this.originalForm))
    },
    copyOriginalForm () {
      this.originalForm = JSON.parse(JSON.stringify(this.form))
    }
  }
}
</script>
