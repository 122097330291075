<template>
  <div
    v-if="formProcessing"
    class="tw-py-3 tw-flex tw-justify-center"
  >
    <TwCircleLoader />
  </div>
  <div v-else>
    <div style="display: none">
      {{ isEdited }}
    </div>
    <div class="tw-text-main-sm tw-mt-[24px]">
      <div class="tw-font-bold tw-flex tw-items-center tw-justify-between">
        <div>
          Список:
        </div>
        <TwPanelActionButton
          color="blue"
          @click.prevent="resultSelectDialog = true"
        >
          Выбрать записи
        </TwPanelActionButton>
      </div>
      <div class="tw-min-h-[200px]">
        <div
          v-if="Array.isArray(form.records) && form.records.length"
          class="tw-my-[14px] tw-flex tw-flex-col tw-gap-[10px]"
        >
          <div
            v-for="(record, rInder) in form.records"
            :key="rInder"
            class="tw-flex tw-gap-[6px] tw-items-start"
          >
            <TwInputText
              v-model="form.records[rInder].name"
              :disabled="formProcessing"
              type="text"
              class="tw-flex-1"
              placeholder="Название"
              max-length="250"
              :persistent-label="true"
              :show-label="true"
              :dense="true"
            />
            <div>
              <TwInputText
                :disabled="formProcessing"
                type="text"
                placeholder="Сумма"
                max-length="13"
                :persistent-label="true"
                :show-label="true"
                :dense="true"
                :value="$Global.inputFormatPrice.getStringSpaced(form.records[rInder].amount)"
                @input="form.records[rInder].amount = $Global.inputFormatPrice.getNumber($event)"
              >
                <template #append>
                  руб.
                </template>
              </TwInputText>
              <span
                v-if="record.hasPartial"
                class="tw-text-main-xs tw-text-an-flat-orange-warning"
              >* Рассрочка платежей</span>
            </div>
          </div>
        </div>
        <div
          v-else
          class="tw-p-[16px] tw-text-an-flat-disabled-text tw-text-"
        >
          Нет добавленных записей
        </div>
      </div>
      <div class="tw-flex tw-justify-end">
        <div class="tw-mr-[10px]">
          Итого:
        </div> <b>{{ (recordsSum || 0).toLocaleString('ru') }} руб.</b>
      </div>
    </div>
    <div
      v-if="serverErrorMessage"
      class="tw-text-center tw-text-an-flat-red-main tw-mt-[12px]"
    >
      {{ serverErrorMessage }}
    </div>
    <TwCardSimpleFormSaveBlock
      class="tw-mt-[12px]"
      :loading="formProcessing"
      :cancel-confirm="isEdited"
      :is-sticky="false"
      :disable-success="formProcessing"
      :disable-cancel="formProcessing || (formType === 'create' && !isEdited)"
      :visible="true"
      @success="handleSubmit"
      @cancel="handleCancel"
    />
    <SelectResultsWithoutAct
      :open="resultSelectDialog"
      :records="form.records"
      :company-id="companyId"
      @change-records="changeRecords"
      @cancel="resultSelectDialog = false"
    />
  </div>
</template>

<script>
import API from '@/Api/Accounting/BuyNb/Act'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import { RouteKeys } from '@/router/types'
import TwCardSimpleFormSaveBlock from '@/components/tw-ui/forms/TwCardSimpleFormSaveBlock.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import TwPanelActionButton from '@/components/tw-ui/panels/TwPanelActionButton.vue'
import SelectResultsWithoutAct from './SelectResultsWithoutAct.vue'
import TwInputText from '@/components/tw-ui/ui-kit/input/TwInputText.vue'

export default {
  name: 'ActMainForm',
  components: {
    TwInputText,
    SelectResultsWithoutAct,
    TwPanelActionButton,
    TwCircleLoader,
    TwCardSimpleFormSaveBlock
  },
  mixins: [FormCreateEditMixin],
  props: {
    companyId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      resultSelectDialog: false,
      API: API,
      createQueryParams: {
        companyId: this.companyId
      },
      form: {}
    }
  },
  methods: {
    changeRecords (inputArray = [], resultsWithoutAct = []) {
      this.form.records = inputArray.map((id) => {
        const resultInfo = resultsWithoutAct?.find(r => r.id === id)
        return {
          resultId: id,
          name: resultInfo?.name || `Результат ${id}`,
          amount: resultInfo?.partialPayment ? null : resultInfo?.commission || null,
          hasPartial: !!resultInfo?.partialPayment
        }
      })
      this.resultSelectDialog = false
    },
    changeCompanyId () {
      this.createQueryParams.companyId = this.companyId
      this.form.companyId = this.companyId
      this.$nextTick(() => {
        this.fetchData()
      })
    },
    leadDealNeedViewChange (event) {
      this.isNeedViewPersonLeadAndDeals = event
    },
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          for (const key in this.preset) {
            if (key === 'companyId') {
              this.$set(this.form, 'companyId', (this.preset[key] || this.companyId))
            } else {
              this.$set(this.form, key, this.preset[key])
            }
          }
        }
        resolve()
      })
    },
    handleSubmit () {
      if (this.formProcessing === true) {
        return
      }
      this.form.amount = this.recordsSum
      this.formProcessing = true
      const request = this.formType === 'create'
        ? API.store(this.form)
        : API.update(this.targetId, this.form)

      request.then(({ data }) => {
        if (this.formType === 'create') {
          this.$emit('create', data.data)
          // если текущий путь равен пути создания то редирект в просмотр
          if (this.$route.name === `${RouteKeys.lead}-create`) {
            this.$router.replace({
              name: `${RouteKeys.lead}-show`,
              params: { id: data.data.id }
            })
          }
        } else {
          this.$emit('update', data.data)
        }
      }).catch((error) => {
        this.serverErrorMessage = error.serverErrorMessage
      }).finally(() => {
        this.formProcessing = false
      })
    }
  },
  computed: {
    recordsSum () {
      return this.form.records?.reduce((accumulator, currentValue) => accumulator + (currentValue.amount || 0), 0)
    }
  },
  watch: {
    companyId () {
      this.changeCompanyId()
    }
  }
}
</script>
