import { AxiosResponse } from 'axios'
import HTTP from '@/Api/http'
import { AuthAxiosDefaultConfig } from '@/Api/Site'

const path = '/admin/filters'

export default {
  // общая схема конфигурации фильтров
  config (param?: object): Promise<AxiosResponse> {
    const $config = {
      params: param,
      ...AuthAxiosDefaultConfig
    }
    return HTTP.get(`${path}`, $config)
  }
}
