<template>
  <TwFlexCol>
    <template v-for="(field, index) in schemaFields">
      <TwFlexCell
        v-if="checkFieldVisible(field)"
        :key="index"
        :class="field.visibleFor && field.visibleFor.field ? 'tw-ml-[40px]':''"
      >
        <!-- Для блоков вызываю рекурсию -->
        <template v-if="field.type === 'block'">
          <DealRelationCreateFormScheme
            :visible-for="field.visibleFor"
            :block-name="field.name"
            :value="value"
            :schema-fields="field.fields"
            @input-field="changeField($event.fieldKey, $event.newValue)"
          />
        </template>
        <template v-else-if="field.type === 'input' && field.inputType === 'text'">
          <TwInputText
            autocomplete="off"
            type="text"
            :label="field.label"
            :max-length="field.maxLen"
            :placeholder="`Введите ${field.label}`"
            :disabled="false"
            :dense="true"
            :show-label="true"
            :persistent-label="true"
            :value="value[field.key]"
            :error-text="getValidationErrorFromField(field)"
            @input="changeField(field.key, $event)"
          />
        </template>
        <template v-else-if="field.type === 'input' && field.inputType === 'number'">
          <TwInputText
            autocomplete="off"
            type="number"
            :label="field.label"
            :min="field.min"
            :max="field.max"
            :placeholder="`Введите ${field.label}`"
            :disabled="false"
            :dense="true"
            :show-label="true"
            :persistent-label="true"
            :value="value[field.key]"
            :error-text="getValidationErrorFromField(field)"
            @input="changeField(field.key, $event)"
          />
        </template>
        <template v-else-if="field.type === 'input' && field.inputType === 'radiobutton'">
          <TwInputRadio
            :dense="false"
            :show-label="true"
            :label="field.label"
            :value="value[field.key]"
            :options="field.options || []"
            :error-text="getValidationErrorFromField(field)"
            @input="changeField(field.key, $event)"
          />
        </template>
        <template v-else-if="field.type === 'select'">
          <TwSelect
            :label="field.label"
            :placeholder="`Выбрать ${field.label}`"
            :multi="field.multi"
            :dense="true"
            :persistent-label="true"
            :options="field.options || []"
            :disabled="false"
            :value="value[field.key]"
            :error-text="getValidationErrorFromField(field)"
            @input="changeField(field.key, $event)"
          />
        </template>
        <div
          v-else
          :title="JSON.stringify(field)"
          class="tw-text-an-flat-red-main"
        >
          [{{ field.key }}] - поле не описано!
        </div>
      </TwFlexCell>
    </template>
  </TwFlexCol>
</template>

<script lang="js">
import { numeric, required, decimal, minValue, maxValue, maxLength } from 'vuelidate/lib/validators'
import TwInputRadio from '@/components/tw-ui/ui-kit/input/TwInputRadio.vue'
import TwInputText from '@/components/tw-ui/ui-kit/input/TwInputText.vue'
import TwSelect from '@/components/tw-ui/ui-kit/input/TwSelect.vue'
import TwFlexCol from '@/components/tw-ui/ui-kit/flex-block/TwFlexCol.vue'
import TwFlexCell from '@/components/tw-ui/ui-kit/flex-block/TwFlexCell.vue'

export default {
  name: 'DealRelationCreateFormScheme',
  components: {
    TwInputRadio,
    TwSelect,
    TwInputText,
    TwFlexCol,
    TwFlexCell
  },
  props: {
    value: {
      type: Object,
      default: null
    },
    schemaFields: {
      type: Array,
      default: () => {
        return []
      }
    },
    visibleFor: {
      type: Object,
      default: () => {
        return {}
      }
    },
    blockName: {
      type: String,
      default: null
    }
  },
  validations () {
    return {
      value: {
        ...this.generatedValidationSchema
      }
    }
  },
  methods: {
    checkFieldVisible (field) {
      // Если есть поле определяю нужен ли показ
      if (field.visibleFor?.field) {
        const parentFieldValue = this.value?.[field.visibleFor.field]
        // Показать зависимое поле
        if (field.visibleFor.values.includes(parentFieldValue)) {
          return true
        } else {
          // Скрыть зависимые поля + обнулить их значение
          // Для самого поля
          this.changeField(field.key, null)
          // Для вложенных полей блока
          if (field?.fields) {
            field.fields.forEach((f) => {
              this.changeField(f.key, null)
            })
          }
          return false
        }
      }
      // если поле отсутствует - показываю блок по умолчанию
      return true
    },
    changeField (fieldKey, newValue) {
      this.$emit('input-field', {
        fieldKey,
        newValue
      })
    },
    getValidationErrorFromField (field) {
      if (this.$v.value[field.key].$invalid) {
        if (this.$v.value[field.key]?.numeric === false) {
          return 'Только положительные целые числа'
        } else if (this.$v.value[field.key]?.decimal === false) {
          return 'Только десятичные числа'
        } else if (this.$v.value[field.key]?.maxLength === false) {
          return `Макс. длинна ввода: ${field.maxLen} символов`
        } else if (this.$v.value[field.key]?.minValue === false) {
          return `Мин. значение: ${field.min}`
        } else if (this.$v.value[field.key]?.maxValue === false) {
          return `Макс. значение: ${field.max}`
        } else if (this.$v.value[field.key]?.required === false) {
          return 'Обязательное поле'
        } else {
          return 'Неверный формат. Измените ввод'
        }
      }
      return ''
    }
  },
  computed: {
    generatedValidationSchema () {
      return this.schemaFields.filter((field) => {
        return field.type !== 'block'
      }).reduce((obj, curField) => {
        return {
          ...obj,
          [curField.key]: {
            required,
            ...(curField.type === 'input' && curField.inputType === 'number') && {
              numeric
            },
            ...(curField.type === 'input' && curField.inputType === 'float') && {
              decimal
            },
            ...(curField.maxLen) && {
              maxLength: maxLength(curField.maxLen)
            },
            ...(curField.min) && {
              minValue: minValue(curField.min)
            },
            ...(curField.max) && {
              maxValue: maxValue(curField.max)
            }
          }
        }
      }, {})
    }
  }
}
</script>
