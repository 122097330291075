export default {
  computed: {
    hasNeedLand () {
      return (
        this.form.objectType === 'land' ||
        this.form.objectType === 'land_with_contract'
      )
    },
    hasNeedHouse () {
      return (
        this.form.objectType === 'land_with_contract' ||
        this.form.objectType === 'completed_house' ||
        this.form.objectType === 'uncompleted_house'
      )
    },
    isVisibleFromType () {
      return {
        fullName: true,
        phone: true,
        objectType: true,
        goal: this.hasNeedHouse || this.form?.objectType === 'apartment',
        budgetMin: this.form?.objectType !== 'parking_space',
        budgetMax: true,
        decoration: this.hasNeedHouse || this.form?.objectType === 'apartment',
        contractType: this.form?.objectType !== 'parking_space',
        commonAreaMin: this.form?.objectType !== 'parking_space',
        kitchenAreaMin: this.form?.objectType === 'apartment',
        wcType: this.form?.objectType === 'apartment',
        sidesWindow: this.form?.objectType === 'apartment',
        roomsCount: this.form?.objectType === 'apartment',
        balconyType: this.form?.objectType === 'apartment',
        readyQuarter:
          this.form?.objectType === 'apartment' ||
          this.form?.objectType === 'commercial',
        paymentMethod: true,
        subsidy: this.hasNeedHouse || this.form?.objectType === 'apartment',
        initialPayment:
          this.form.paymentMethod === 'mortgage' &&
          (this.hasNeedHouse || this.form?.objectType === 'apartment'),
        complexIds: this.form?.objectType === 'parking_space',
        landAreaMin: this.hasNeedLand || this.hasNeedHouse
      }
    }
  }
}
