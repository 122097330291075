import { AxiosResponse } from 'axios'
import HTTP from '@/Api/http'

const path = '/deal/objects/favorite'

export default {
  getList (param?: any): Promise<AxiosResponse> {
    const $config = {
      params: param
    }
    return HTTP.get(`${path}`, $config)
  },
  create (): Promise<AxiosResponse> {
    const $config = {}
    return HTTP.get(`${path}/create`, $config)
  },
  store (data: object): Promise<AxiosResponse> {
    const $config: any = {}
    return HTTP.post(`${path}`, data, $config)
  },
  delete (id: number | string): Promise<AxiosResponse> {
    const $config: any = {}
    return HTTP.delete(`${path}/${id}`, $config)
  }
}
