<template>
  <div
    v-if="data"
    class="tw-min-w-[400px]"
  >
    <div class="tw-mb-[12px] tw-pt-[33px] tw-pb-[24px] tw-pr-[28px] tw-pl-[28px] tw-bg-an-flat-bg-main tw-text-[14px] tw-rounded-[10px]">
      <b class="tw-block tw-mb-[15px] tw-text-[16px]">Общая информация о запросе</b>
      <ul class="tw-mb-[26px]">
        <li class="tw-flex tw-mb-[9px]">
          <div class="tw-max-w-[122px] tw-w-[100%] tw-mr-[20px]">
            ID Запроса
          </div>
          <span>{{ data?.id || 0 }}</span>
        </li>
        <li class="tw-flex tw-mb-[9px]">
          <div class="tw-max-w-[122px] tw-w-[100%] tw-mr-[20px]">
            ID Заявки
          </div>
          <ViewPanelLink
            v-if="data.dealId"
            :id="data.dealId"
            label="Заявка"
            component="deal-show"
          >
            #{{ data.dealId }}
          </ViewPanelLink>
        </li>
        <li
          v-for="(person, index) in data?.person"
          :key="index"
          class="tw-flex tw-mb-[9px]"
        >
          <div class="tw-max-w-[122px] tw-w-[100%] tw-mr-[20px]">
            Клиент
          </div>
          <ViewPanelLink
            v-if="person"
            :id="person.id"
            label="Клиент"
            component="person-show"
          >
            {{ $Global.peopleName(person, 'fio') }}
          </ViewPanelLink>
        </li>
        <li class="tw-flex">
          <div class="tw-max-w-[122px] tw-w-[100%] tw-mr-[20px]">
            Дата создания
          </div>
          <span>{{ $Global.DateFormat.DateShort(data.createdAt) }}</span>
        </li>
      </ul>
      <div>
        <span class="tw-block tw-mb-[6px]">От кого</span>
        <div class="tw-flex tw-items-center tw-pt-[8px] tw-pb-[8px] tw-pr-[8px] tw-pl-[15px] tw-bg-white tw-rounded-[5px]">
          <div class="tw-mr-[9px] tw-h-[35px] tw-w-[35px] tw-py-[4px]">
            <svg
              class="tw-mx-auto"
              width="22"
              height="28"
              viewBox="0 0 22 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.181 14.1717C16.8473 13.5762 17.3137 12.7982 17.5191 11.9396C17.7244 11.081 17.6591 10.1819 17.3317 9.36017C17.0044 8.53842 16.4302 7.83236 15.6844 7.33452C14.9387 6.83669 14.0562 6.57031 13.1527 6.57031C12.2491 6.57031 11.3666 6.83669 10.6209 7.33452C9.87519 7.83236 9.30101 8.53842 8.97363 9.36017C8.64626 10.1819 8.58097 11.081 8.78631 11.9396C8.99166 12.7982 9.45807 13.5762 10.1244 14.1717C8.63599 14.7608 7.36133 15.7695 6.4632 17.0688C5.56506 18.3682 5.0842 19.8993 5.08203 21.4665C5.08203 21.6986 5.17651 21.9212 5.34468 22.0853C5.51285 22.2494 5.74094 22.3415 5.97877 22.3415H20.3266C20.5644 22.3415 20.7925 22.2494 20.9607 22.0853C21.1289 21.9212 21.2233 21.6986 21.2233 21.4665C21.2212 19.8993 20.7403 18.3682 19.8422 17.0688C18.944 15.7695 17.6694 14.7608 16.181 14.1717ZM10.4625 10.9665C10.4625 10.4474 10.6202 9.93985 10.9158 9.50817C11.2115 9.07649 11.6316 8.74003 12.1232 8.54135C12.6148 8.34267 13.1557 8.29069 13.6775 8.39198C14.1994 8.49326 14.6787 8.74327 15.055 9.11038C15.4312 9.4775 15.6874 9.94523 15.7912 10.4544C15.895 10.9636 15.8417 11.4914 15.6381 11.9711C15.4345 12.4507 15.0897 12.8607 14.6473 13.1491C14.2049 13.4376 13.6848 13.5915 13.1527 13.5915C12.4392 13.5915 11.7549 13.315 11.2504 12.8227C10.7459 12.3304 10.4625 11.6627 10.4625 10.9665ZM6.93918 20.5915C7.15374 19.1328 7.90012 17.7984 9.04141 16.8332C10.1827 15.868 11.6424 15.3367 13.1527 15.3367C14.663 15.3367 16.1227 15.868 17.264 16.8332C18.4052 17.7984 19.1516 19.1328 19.3662 20.5915H6.93918Z"
                fill="#A2ABB5"
              />
              <path
                d="M6.61389 9.24397C6.73382 9.23297 6.85025 9.19851 6.95623 9.14264C7.06221 9.08677 7.15558 9.01064 7.23074 8.91879C7.30591 8.82694 7.36134 8.72126 7.39372 8.60804C7.42611 8.49483 7.43478 8.37641 7.41923 8.25986C7.40368 8.1433 7.36423 8.03099 7.30322 7.92965C7.24221 7.8283 7.1609 7.73998 7.06414 7.66997C6.96738 7.59995 6.85716 7.54968 6.74005 7.52215C6.62294 7.49461 6.50135 7.49039 6.38253 7.50972C5.73842 7.59434 5.13019 7.84885 4.62335 8.24582C4.11652 8.64279 3.73029 9.16718 3.50629 9.76249C3.28229 10.3578 3.229 11.0015 3.35217 11.6241C3.47534 12.2468 3.77029 12.8249 4.20525 13.2961C3.12541 13.7897 2.21235 14.5737 1.57332 15.5561C0.934284 16.5384 0.595813 17.6783 0.597664 18.8418C0.597664 19.0739 0.692141 19.2965 0.860313 19.4606C1.02848 19.6247 1.25657 19.7168 1.4944 19.7168H3.28788C3.52571 19.7168 3.7538 19.6247 3.92197 19.4606C4.09014 19.2965 4.18462 19.0739 4.18462 18.8418C4.18462 18.6098 4.09014 18.3872 3.92197 18.2231C3.7538 18.059 3.52571 17.9668 3.28788 17.9668H2.48082C2.65976 17.1046 3.10163 16.3152 3.74846 15.7023C4.39529 15.0895 5.21688 14.6817 6.10544 14.5325C6.2056 14.513 6.3015 14.4766 6.3888 14.4248C6.4257 14.4293 6.46283 14.4317 6.5 14.4318C6.72691 14.4304 6.9448 14.345 7.10976 14.193C7.27472 14.0409 7.37447 13.8335 7.38892 13.6126C7.40336 13.3916 7.33142 13.1735 7.1876 13.0023C7.04377 12.831 6.83876 12.7193 6.61389 12.6897C6.18887 12.6305 5.80002 12.4236 5.51873 12.1071C5.23744 11.7906 5.08257 11.3858 5.08257 10.9668C5.08257 10.5479 5.23744 10.143 5.51873 9.82657C5.80002 9.51008 6.18887 9.30322 6.61389 9.24397Z"
                fill="#A2ABB5"
              />
            </svg>
          </div>
          <div class="tw-flex tw-flex-col tw-py-[4px]">
            <template v-if="data.userId">
              <ViewPanelLink
                :id="data.userId"
                label="Пользователь"
                component="employee-show"
              >
                <EmployeeName :id="data.userId" />
              </ViewPanelLink>
              <EmployeePosition
                v-if="data.userId"
                :id="data.userId"
                class="tw-block tw-text-an-flat-gray-4"
              />
            </template>
            <template v-else>
              <span class="tw-text-an-flat-disabled-text">
                Не назначен
              </span>
            </template>
          </div>
          <div class="tw-ml-auto">
            <button
              disabled
              class="tw-p-[16px] tw-bg-an-flat-bg-main tw-rounded-[5px] disabled:tw-opacity-50"
            >
              <svg
                width="14"
                height="10"
                viewBox="0 0 14 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.85078 4.3812C6.57578 4.7562 7.42578 4.7562 8.12578 4.3812L12.0508 2.3812L12.9508 1.9312C12.6258 1.2312 11.9008 0.731201 11.0508 0.731201H2.95078C2.12578 0.731201 1.40078 1.2312 1.05078 1.9312L1.95078 2.3812L5.85078 4.3812Z"
                  fill="#34363B"
                />
                <path
                  d="M8.59922 5.28276C8.09922 5.53276 7.54922 5.65776 6.99922 5.65776C6.44922 5.65776 5.89922 5.53276 5.39922 5.28276L1.82422 3.45776L0.824219 2.95776V7.70776C0.824219 8.85776 1.77422 9.80776 2.92422 9.80776H11.0492C12.1992 9.80776 13.1492 8.85776 13.1492 7.70776V2.95776L12.1492 3.45776L8.59922 5.28276Z"
                  fill="#34363B"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="tw-pt-[30px] tw-pb-[28px] tw-pr-[28px] tw-pl-[24px] tw-bg-an-flat-bg-main tw-rounded-[10px] tw-text-[14px]">
      <div class="tw-flex tw-flex-col tw-justify-between tw-min-h-[282px] tw-mb-[15px] tw-pb-[18px] tw-border-b-[2px] tw-border-white ">
        <div>
          <div
            v-if="data.status"
            class="tw-text-main-md tw-font-semibold"
          >
            Текущий статус: <span :style="{ color: data.status.color }">{{ data.status.label }}</span>
          </div>
          <div
            v-if="data.statusChangeDate"
            class="tw-mt-[6px]"
          >
            <span class="tw-text-an-flat-grey-4">Дата изменения статуса:</span> {{ $Global.DateFormat.DateTimeShort(data.statusChangeDate) }}
          </div>
          <div
            v-if="data.statusComment"
            class="tw-mt-[6px]"
          >
            <span class="tw-text-an-flat-grey-4">Комментарий:</span>
            <div>
              <i>{{ data.statusComment || 'Отсутствует' }}</i>
            </div>
          </div>
          <div
            v-if="data.status.value === 'confirmed' && (data.confirmedBefore || data.confirmedPrice)"
            class="tw-mt-[20px]"
          >
            <div v-if="data.confirmedBefore">
              Подтверждено до: <strong>{{ $Global.DateFormat.DateTimeShort(data.confirmedBefore) }}</strong>
            </div>
            <div v-if="data.confirmedPrice">
              Потвержденная цена: <strong>{{ (data.confirmedPrice || 0).toLocaleString('ru') }}</strong> руб.
            </div>
          </div>
        </div>
        <button
          :disabled="false"
          class="tw-w-fit tw-text-an-flat-grey-3 enabled:hover:tw-underline"
          @click.prevent="$emit('showHistory')"
        >
          История изменений
        </button>
      </div>
      <div>
        <div class="tw-mb-[6px]">
          Ответственный
        </div>
        <div class="tw-flex tw-items-center tw-mb-[13px] tw-pt-[8px] tw-pb-[8px] tw-pr-[8px] tw-pl-[15px] tw-bg-white tw-rounded-[5px]">
          <div class="tw-mr-[9px] tw-h-[35px] tw-w-[35px] tw-py-[4px]">
            <svg
              class="tw-mx-auto"
              width="22"
              height="28"
              viewBox="0 0 22 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.181 14.1717C16.8473 13.5762 17.3137 12.7982 17.5191 11.9396C17.7244 11.081 17.6591 10.1819 17.3317 9.36017C17.0044 8.53842 16.4302 7.83236 15.6844 7.33452C14.9387 6.83669 14.0562 6.57031 13.1527 6.57031C12.2491 6.57031 11.3666 6.83669 10.6209 7.33452C9.87519 7.83236 9.30101 8.53842 8.97363 9.36017C8.64626 10.1819 8.58097 11.081 8.78631 11.9396C8.99166 12.7982 9.45807 13.5762 10.1244 14.1717C8.63599 14.7608 7.36133 15.7695 6.4632 17.0688C5.56506 18.3682 5.0842 19.8993 5.08203 21.4665C5.08203 21.6986 5.17651 21.9212 5.34468 22.0853C5.51285 22.2494 5.74094 22.3415 5.97877 22.3415H20.3266C20.5644 22.3415 20.7925 22.2494 20.9607 22.0853C21.1289 21.9212 21.2233 21.6986 21.2233 21.4665C21.2212 19.8993 20.7403 18.3682 19.8422 17.0688C18.944 15.7695 17.6694 14.7608 16.181 14.1717ZM10.4625 10.9665C10.4625 10.4474 10.6202 9.93985 10.9158 9.50817C11.2115 9.07649 11.6316 8.74003 12.1232 8.54135C12.6148 8.34267 13.1557 8.29069 13.6775 8.39198C14.1994 8.49326 14.6787 8.74327 15.055 9.11038C15.4312 9.4775 15.6874 9.94523 15.7912 10.4544C15.895 10.9636 15.8417 11.4914 15.6381 11.9711C15.4345 12.4507 15.0897 12.8607 14.6473 13.1491C14.2049 13.4376 13.6848 13.5915 13.1527 13.5915C12.4392 13.5915 11.7549 13.315 11.2504 12.8227C10.7459 12.3304 10.4625 11.6627 10.4625 10.9665ZM6.93918 20.5915C7.15374 19.1328 7.90012 17.7984 9.04141 16.8332C10.1827 15.868 11.6424 15.3367 13.1527 15.3367C14.663 15.3367 16.1227 15.868 17.264 16.8332C18.4052 17.7984 19.1516 19.1328 19.3662 20.5915H6.93918Z"
                fill="#A2ABB5"
              />
              <path
                d="M6.61389 9.24397C6.73382 9.23297 6.85025 9.19851 6.95623 9.14264C7.06221 9.08677 7.15558 9.01064 7.23074 8.91879C7.30591 8.82694 7.36134 8.72126 7.39372 8.60804C7.42611 8.49483 7.43478 8.37641 7.41923 8.25986C7.40368 8.1433 7.36423 8.03099 7.30322 7.92965C7.24221 7.8283 7.1609 7.73998 7.06414 7.66997C6.96738 7.59995 6.85716 7.54968 6.74005 7.52215C6.62294 7.49461 6.50135 7.49039 6.38253 7.50972C5.73842 7.59434 5.13019 7.84885 4.62335 8.24582C4.11652 8.64279 3.73029 9.16718 3.50629 9.76249C3.28229 10.3578 3.229 11.0015 3.35217 11.6241C3.47534 12.2468 3.77029 12.8249 4.20525 13.2961C3.12541 13.7897 2.21235 14.5737 1.57332 15.5561C0.934284 16.5384 0.595813 17.6783 0.597664 18.8418C0.597664 19.0739 0.692141 19.2965 0.860313 19.4606C1.02848 19.6247 1.25657 19.7168 1.4944 19.7168H3.28788C3.52571 19.7168 3.7538 19.6247 3.92197 19.4606C4.09014 19.2965 4.18462 19.0739 4.18462 18.8418C4.18462 18.6098 4.09014 18.3872 3.92197 18.2231C3.7538 18.059 3.52571 17.9668 3.28788 17.9668H2.48082C2.65976 17.1046 3.10163 16.3152 3.74846 15.7023C4.39529 15.0895 5.21688 14.6817 6.10544 14.5325C6.2056 14.513 6.3015 14.4766 6.3888 14.4248C6.4257 14.4293 6.46283 14.4317 6.5 14.4318C6.72691 14.4304 6.9448 14.345 7.10976 14.193C7.27472 14.0409 7.37447 13.8335 7.38892 13.6126C7.40336 13.3916 7.33142 13.1735 7.1876 13.0023C7.04377 12.831 6.83876 12.7193 6.61389 12.6897C6.18887 12.6305 5.80002 12.4236 5.51873 12.1071C5.23744 11.7906 5.08257 11.3858 5.08257 10.9668C5.08257 10.5479 5.23744 10.143 5.51873 9.82657C5.80002 9.51008 6.18887 9.30322 6.61389 9.24397Z"
                fill="#A2ABB5"
              />
            </svg>
          </div>
          <div class="tw-flex tw-flex-col tw-py-[4px]">
            <template v-if="data.responsibleUserId">
              <ViewPanelLink
                :id="data.responsibleUserId"
                label="Пользователь"
                component="employee-show"
              >
                <EmployeeName :id="data.responsibleUserId" />
              </ViewPanelLink>
              <EmployeePosition
                v-if="data.responsibleUserId"
                :id="data.responsibleUserId"
                class="tw-block tw-text-an-flat-gray-4"
              />
            </template>
            <template v-else>
              <span class="tw-text-an-flat-disabled-text">
                Не назначен
              </span>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    Нет данных
  </div>
</template>

<script>
import EmployeeName from '@/components/other/employee/EmployeeName.vue'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink.vue'
import EmployeePosition from '@/components/other/employee/EmployeePosition.vue'

export default {
  name: 'DeveloperRequestGeneralInfo',
  components: { EmployeePosition, ViewPanelLink, EmployeeName },
  props: {
    data: {
      default: null
    }
  }
}
</script>
