<template>
  <v-row
    v-cloak
    v-if="info"
  >
    <v-col
      cols="12"
      md="6"
    >
      <CardBlockField>
        <template #title>
          Название
        </template>
        <template #content>
          {{ info.name }}
        </template>
      </CardBlockField>
      <CardBlockField v-if="info.createdAt">
        <template #title>
          Дата создания
        </template>
        <template #content>
          {{ $Global.DateFormat.DateTimeShortMonthLong(info.createdAt) }}
        </template>
      </CardBlockField>
    </v-col>

    <v-col
      cols="12"
      md="6"
    >
      <CardBlockField>
        <template #title>
          Банк
        </template>
        <template #content>
          <div
            v-if="info.company"
            :class="info.company.active ? '':'red--text'"
          >
            <ViewPanelLink
              :id="info.company.id"
              label="Компания"
              component="company-show"
            >
              {{ info.company.name }}
            </ViewPanelLink>
            <span v-if="!info.company.active">
              (неактивен)
            </span>
          </div>
          <div
            v-else
            class="text-muted"
          >
            отсутствует
          </div>
        </template>
      </CardBlockField>
      <CardBlockField v-if="info.updatedAt">
        <template #title>
          Дата изменения
        </template>
        <template #content>
          {{ $Global.DateFormat.DateTimeShortMonthLong(info.updatedAt) }}
        </template>
      </CardBlockField>
    </v-col>
  </v-row>
</template>

<script>
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink'
import CardBlockField from '@/components/core/ViewPanel/Card/CardBlockField'

export default {
  name: 'DiscountMatrixMainShow',
  components: { CardBlockField, ViewPanelLink },
  props: {
    info: {},
    dictionary: {}
  }
}
</script>
