import { render, staticRenderFns } from "./CustomBuildingSelector.vue?vue&type=template&id=5f41bced&scoped=true"
import script from "./CustomBuildingSelector.vue?vue&type=script&lang=js"
export * from "./CustomBuildingSelector.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f41bced",
  null
  
)

export default component.exports