<template>
  <div
    v-cloak
    v-if="description"
    class="tw-flex tw-gap-[12px] tw-flex-col"
  >
    <div class="tw-flex tw-gap-[12px] tw-flex-col">
      <TwPanelContentBlockFieldView>
        <template #title>
          Полное описание ЖК
        </template>
        <div
          v-if="description.advantage"
          v-html="description.advantage"
        />
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView>
        <template #title>
          Дом *
        </template>
        <div
          v-if="description.house"
          class="tw-text-an-flat-gray-3"
          v-html="description.house"
        />
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView>
        <template #title>
          Двор *
        </template>
        <div
          v-if="description.yard"
          class="tw-text-an-flat-gray-3"
          v-html="description.yard"
        />
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView>
        <template #title>
          Особенности жилья *
        </template>
        <div
          v-if="description.featuresHousing"
          class="tw-text-an-flat-gray-3"
          v-html="description.featuresHousing"
        />
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView>
        <template #title>
          Безопасность *
        </template>
        <div
          v-if="description.security"
          class="tw-text-an-flat-gray-3"
          v-html="description.security"
        />
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView>
        <template #title>
          Благоустройство *
        </template>
        <div
          v-if="description.improvement"
          class="tw-text-an-flat-gray-3"
          v-html="description.improvement"
        />
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView>
        <template #title>
          Инфрастуктура *
        </template>
        <div
          v-if="description.infrastructure"
          class="tw-text-an-flat-gray-3"
          v-html="description.infrastructure"
        />
      </TwPanelContentBlockFieldView>
      <div class="tw-text-an-flat-orange-warning">
        * отмеченые поля устариели и не используются
      </div>
    </div>
  </div>
  <div
    v-else
    class="tw-text-an-flat-disabled-text tw-text-center"
  >
    Не добавлено
  </div>
</template>

<script>
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'

export default {
  name: 'RealtyComplexDescShow',
  components: { TwPanelContentBlockFieldView },
  props: {
    description: {
      default: null
    },
    dictionary: {}
  }
}
</script>
