<template>
  <TwPanelContentBlock>
    <template #title>
      <div>
        Результат по сделке
      </div>
    </template>
    <!--  Добавить и изменить результат мы можем только в сопровождении  -->
    <template
      v-if="!editBlock && dealSubTypeValue === 'maintenance'"
      #action
    >
      <TwPanelContentBlockActionButton
        :disabled="disabled"
        :type="dealState.resultId ? 'edit':'add'"
        @click="editBlock = !editBlock"
      >
        {{ dealState.resultId ? 'изменить' : 'добавить' }}
      </TwPanelContentBlockActionButton>
    </template>
    <div>
      <!-- EDIT -->
      <MortgageResultForm
        v-if="editBlock"
        :id="dealState.resultId ? dealData.id : 0"
        :deal-id="dealData.id"
        @create="refresh"
        @update="refresh"
        @cancel="editBlock = false"
      />
      <!-- SHOW -->
      <template v-else-if="!editBlock && dealState.resultId">
        <MortgageResultShow :id="dealData.id" />
      </template>
      <!-- NONE -->
      <template v-else>
        <div class="text-muted text-center">
          <div
            v-if="dealSubTypeValue === 'maintenance'"
            class="tw-text-an-flat-red-main"
          >
            Обязательно для успешного завершения сделки
          </div>
          <template v-else>
            Результат будет заполнен автоматически
            <br>
            после завершения сделки по покупке объекта
          </template>
        </div>
      </template>
    </div>
  </TwPanelContentBlock>
</template>

<script>
import MortgageResultForm from '@/views/Deal/TypeViews/mortgage/result/MortgageResultForm'
import MortgageResultShow from '@/views/Deal/TypeViews/mortgage/result/MortgageResultShow'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwPanelContentBlockActionButton from '@/components/tw-ui/panels/TwPanelContentBlockActionButton.vue'

export default {
  name: 'MortgageResultBlock',
  components: { TwPanelContentBlockActionButton, TwPanelContentBlock, MortgageResultShow, MortgageResultForm },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    dealData: {
      required: true
    },
    dealState: {
      default: null
    }
  },
  data () {
    return {
      editBlock: false
    }
  },
  computed: {
    dealSubTypeValue () {
      return this.dealData && this.dealData?.subtype ? this.dealData.subtype?.value : null
    }
  },
  methods: {
    refresh () {
      this.editBlock = false
      this.$emit('refresh')
    }
  }
}
</script>
