<template>
  <div class="tw-font-manrope tw-text-main-sm2 tw-max-w-[100%] tw-px-[26px] tw-py-[18px] tw-bg-white tw-min-h-screen">
    <template v-if="isCardContentVisible">
      <!--   ЗАГОЛОВОК   -->
      <TwPanelTitle>
        <template #left>
          <div class="tw-text-h4 tw-font-bold">
            <div
              v-if="isNew"
              class="title text-muted"
            >
              Новый
            </div>
            <div v-else-if="data">
              <span>{{ data.name }}</span>
              <span class="tw-text-an-flat-disabled-text tw-font-medium"> # {{ data.id }}</span>
            </div>
            <div v-else>
              ~
            </div>
          </div>
        </template>
        <template #right>
          <template v-if="!isNew">
            <TwPanelActionButton
              v-if="!isNew && data && !data.deal"
              color="gray"
            >
              TODO BTN 1
            </TwPanelActionButton>
            <TwPanelActionButton
              v-if="!isNew && data && !data.deal"
              color="blue"
            >
              TODO BTN 1
            </TwPanelActionButton>
          </template>
          <MenuEntityActions
            v-if="permissions"
            :permissions="permissions"
            @delete="deleteCardItem"
          />
        </template>
      </TwPanelTitle>

      <!--   ВКЛАДКИ   -->
      <TwPanelTabsFull
        v-model="currentTab"
        :items="tabList"
      >
        <template #content.main>
          <TwPanelContent>
            <div class="tw-flex-1">
              <TwPanelContentBlock
                v-if="data"
                class="tw-mb-[6px]"
              >
                <template #title>
                  <div>
                    TODO
                  </div>
                </template>
                <TwPanelContentBlockFieldView
                  :horizontal="false"
                >
                  <template #title>
                    TODO label
                  </template>
                  <template #subtitle>
                    sub label
                  </template>
                  <div>
                    TODO VALUE
                  </div>
                </TwPanelContentBlockFieldView>
              </TwPanelContentBlock>
              <TwPanelContentBlock class="tw-mb-[6px]">
                <template #title>
                  <div>
                    TODO 1
                  </div>
                </template>
                <template
                  v-if="permissions && permissions.entity && permissions.entity.update"
                  #action
                >
                  <TwPanelContentBlockActionButton
                    :disabled="isNew || loading"
                    type="edit"
                    @click="editMain = !editMain"
                  >
                    {{ editMain ? 'отменить':'изменить' }}
                  </TwPanelContentBlockActionButton>
                </template>
                <div>
                  <div v-if="editMain">
                    todo EDIT
                  </div>
                  <div v-else>
                    todo SHOW
                  </div>
                </div>
              </TwPanelContentBlock>
              <TwPanelContentBlock
                v-if="data && data.extId"
                class="tw-mb-[6px]"
              >
                <template #title>
                  <div>
                    todo 2
                  </div>
                </template>
                <TwPanelContentBlockFieldView no-data-text="Нет примечания">
                  <template #title>
                    todo 2 label
                  </template>
                  todo 2 value
                </TwPanelContentBlockFieldView>
              </TwPanelContentBlock>
            </div>
            <div class="tw-flex-1">
              <TwPanelContentBlock
                v-if="data"
                class="tw-mb-[6px]"
              >
                TODO RIGHT
              </TwPanelContentBlock>
            </div>
          </TwPanelContent>
        </template>
        <template #content.other>
          <div>
            TODO OTHER TAB
          </div>
        </template>
      </TwPanelTabsFull>
    </template>
    <!--
      Блок заполняющий пространство при отсутствуии контента
      для прижатия блока сохранения к нижней части страницы
    -->
    <div class="tw-flex tw-justify-center tw-items-center">
      <TwCircleLoader
        v-if="loading && !isCardContentVisible"
        width="50px"
        height="50px"
      />
      <div v-else-if="error">
        <PermissionDenied :text="error === 403 ? 'Недостаточно прав для просмотра!' : 'Ошибка соединения - Обратитесь к администратору!'" />
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/Api/Crm/Lead'
import CardMixin from '@/mixins/CardMixin'
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import MenuEntityActions from '@/components/core/ViewPanel/Card/entity-actions/menuEntityActions.vue'
import PermissionDenied from '@/views/PermissionDenied.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import TwPanelTitle from '@/components/tw-ui/panels/TwPanelTitle.vue'
import TwPanelContent from '@/components/tw-ui/panels/TwPanelContent.vue'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'
import TwPanelTabsFull from '@/components/tw-ui/panels/TwPanelTabsFull.vue'
import TwPanelActionButton from '@/components/tw-ui/panels/TwPanelActionButton.vue'
import TwPanelContentBlockActionButton from '@/components/tw-ui/panels/TwPanelContentBlockActionButton.vue'

export default {
  components: {
    TwPanelContentBlockActionButton,
    TwPanelActionButton,
    TwPanelTabsFull,
    TwPanelContentBlockFieldView,
    TwPanelContentBlock,
    TwPanelContent,
    TwPanelTitle,
    TwCircleLoader,
    PermissionDenied,
    MenuEntityActions
  },
  mixins: [CardMixin, ViewPanelShowMixin],
  props: {},
  data () {
    return {
      API: API,
      currentTab: 'main'
    }
  },
  computed: {
    tabList () {
      return [
        {
          label: 'Общая информация',
          key: 'main'
        },
        {
          label: 'Другое',
          key: 'other',
          disabled: this.isNew
        }
      ]
    }
  }
}
</script>
