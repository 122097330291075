import { AxiosResponse } from 'axios'
import HTTP from '@/Api/http'

const path = '/mortgage/domclick'

export default {
  create (dealProgramId: number): Promise<AxiosResponse> {
    return HTTP.get(`${path}/${dealProgramId}/create`)
  }
}
