import { tableItemStyleOneGen, TBaseTableHeaderItem } from '@/components/tw-ui/table/TableTypes'

const options: TBaseTableHeaderItem[] = [
  {
    ...tableItemStyleOneGen(false),
    dataKey: 'id',
    text: 'ID',
    colStyle: 'width: 50px;'
  },
  {
    ...tableItemStyleOneGen(false),
    dataKey: 'dealId',
    text: 'Id сделки',
    colStyle: 'width: auto;'
  },
  {
    ...tableItemStyleOneGen(false),
    text: 'Статус сделки',
    dataKey: 'stage',
    colStyle: 'width: 50px;'
  },
  {
    ...tableItemStyleOneGen(false),
    text: 'Дата сделки',
    dataKey: 'closeDate',
    colStyle: 'width: 150px;'
  },
  {
    ...tableItemStyleOneGen(false),
    text: 'Заполненность',
    dataKey: 'isFinished',
    colStyle: 'width: 50px;'
  },
  {
    ...tableItemStyleOneGen(false),
    text: 'Действия',
    dataKey: 'actions',
    colStyle: 'width: 300px;'
  }
]

export default options
