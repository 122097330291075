import { render, staticRenderFns } from "./TwCardSimpleFormSaveBlock.vue?vue&type=template&id=615ae011&scoped=true"
import script from "./TwCardSimpleFormSaveBlock.vue?vue&type=script&lang=js"
export * from "./TwCardSimpleFormSaveBlock.vue?vue&type=script&lang=js"
import style0 from "./TwCardSimpleFormSaveBlock.vue?vue&type=style&index=0&id=615ae011&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "615ae011",
  null
  
)

export default component.exports