<template>
  <div class="tw-font-manrope tw-mt-3">
    <div class="tw-grid tw-grid-cols-[350px_auto] tw-gap-4">
      <!--О КЛИЕНТЕ-->
      <TwPanelContentBlock class="tw-mb-[6px]">
        <template #title>
          <div>
            Клиент
          </div>
        </template>
        <template
          v-if="dealData.person"
          #action
        >
          <ViewPanelLink
            :id="dealData.person.id"
            class="tw-text-main-xs"
            label="Клиент"
            component="person-show"
          >
            подробнее
          </ViewPanelLink>
        </template>
        <div>
          <PersonMainShow
            :info="dealData.person"
            :person-types="{}"
          />
        </div>
      </TwPanelContentBlock>
      <div>
        <!--Информация по ипотеке-->
        <DealInsuranceMortgageBlock :deal-data="dealData" />
        <!--Информация по истории-->
        <DealInsuranceHistoryBlock :deal-data="dealData" />
      </div>
    </div>
  </div>
</template>

<script>
import PersonMainShow from '@/views/Person/main/PersonMainShow.vue'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink.vue'
import DealInsuranceMortgageBlock
  from '@/views/Deal/TypeViews/insurance/Client/DealInsuranceMortgageBlock.vue'
import DealInsuranceHistoryBlock from '@/views/Deal/TypeViews/insurance/Client/DealInsuranceHistoryBlock.vue'

export default {
  name: 'DealInsuranceClientTab',
  components: { DealInsuranceHistoryBlock, DealInsuranceMortgageBlock, ViewPanelLink, TwPanelContentBlock, PersonMainShow },
  props: {
    dealData: {
      required: true
    }
  },
  data () {
    return {
      isEdit: false,
      editableId: 0
    }
  },
  computed: {
    dealId () {
      return this.dealData?.id || null
    },
    presetValues () {
      const fullName = `${this.dealData?.person?.surname || ''} ${this.dealData?.person?.name || ''} ${this.dealData?.person?.fatherName || ''}`
      const phone = this.dealData?.person?.phones?.[0]?.phone || null
      return {
        fullName,
        phone
      }
    }
  }
}
</script>

<style scoped>

</style>
