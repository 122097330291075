<template>
  <div>
    <div
      v-if="loading && !data"
      class="tw-h-full tw-w-full tw-flex tw-justify-center tw-items-center tw-py-5"
    >
      <TwCircleLoader
        height="50px"
        width="50px"
      />
    </div>
    <div v-else>
      <div
        :class="fromPanel ? 'tw-px-[26px] tw-py-[18px]':''"
        class="tw-font-manrope tw-text-main-sm2 tw-max-w-[100%] tw-bg-white tw-min-h-screen"
      >
        <TwPanelTitle v-if="fromPanel">
          <template #left>
            <div class="tw-text-h4 tw-font-bold">
              <div v-if="data">
                <span>Файл застройщика</span>
                <span class="tw-text-an-flat-disabled-text tw-font-medium"> # {{ data.id }}</span>
              </div>
              <div
                v-else-if="isNew"
                class="title text-muted"
              >
                Новый файл застройщика: квартиры в продаже
              </div>
              <div v-else>
                ~
              </div>
            </div>
          </template>
        </TwPanelTitle>
        <TwPanelTabsFull
          v-model="currentTab"
          :items="tabList"
        >
          <template #content.main>
            <TwPanelContent>
              <!-- LEFT -->
              <div class="tw-flex-1">
                <!-- Основное -->
                <TwPanelContentBlock class="tw-mb-[6px]">
                  <template #title>
                    <div>
                      О файле
                    </div>
                  </template>
                  <template #action>
                    <button
                      :disabled="isNew || !isAllowEditPermissions"
                      class="tw-text-main-xs enabled:hover:tw-text-an-flat-dark-blue-link disabled:tw-text-an-flat-disabled-text"
                      @click="editMain = !editMain"
                    >
                      {{ editMain ? 'отменить' : 'изменить' }}
                    </button>
                  </template>
                  <div class="tw-flex tw-flex-col tw-gap-[12px] tw-font-medium">
                    <ImportBuilderFileMainForm
                      v-if="editMain"
                      :id="data ? data.id : 0"
                      ref="EditMain"
                      :default-form-fields="defaultFormFields"
                      @create="onCreated"
                      @update="onUpdated"
                      @cancel="onCancel"
                    />
                    <ImportBuilderFileMainShow
                      v-else
                      :dictionary="dictionary"
                      :info="data"
                      @refresh="refresh"
                    />
                  </div>
                </TwPanelContentBlock>
              </div>
              <!-- RIGHT -->
              <div class="tw-flex-1 tw-min-w-[500px]">
                <!-- Сопоставление -->
                <BuilderFileComparisonBlock
                  v-if="!isNew && data && data.id"
                  :info="data"
                  :is-allow-edit-permissions="isAllowEditPermissions"
                />
              </div>
            </TwPanelContent>
          </template>
          <template #content.display>
            <TwPanelContent>
              <div class="tw-flex-1">
                <BuilderFileLoaderDisplayBlock
                  v-if="data"
                  :info="data"
                />
              </div>
            </TwPanelContent>
          </template>
          <template #content.log>
            <BuilderFileLogList :id="data.id" />
          </template>
        </TwPanelTabsFull>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import dictPermissionRules from '@/Permissions/dictRules'
import { API } from '@/Api/BuilderImporter'
import CardMixin from '@/mixins/CardMixin'
import ImportBuilderFileMainForm from '@/views/BuilderImporter/ImportBuilderFile/main/ImportBuilderFileMainForm'
import ImportBuilderFileMainShow from '@/views/BuilderImporter/ImportBuilderFile/main/ImportBuilderFileMainShow'
import BuilderFileComparisonBlock from '@/views/BuilderImporter/ImportBuilderFile/comparision/BuilderFileComparisonBlock'
import BuilderFileLoaderDisplayBlock
  from '@/views/BuilderImporter/ImportBuilderFile/comparision/BuilderFileLoaderDisplayBlock'
import BuilderFileLogList from '@/views/BuilderImporter/ImportBuilderFile/log/BuilderFileLogList'
import TwPanelTitle from '@/components/tw-ui/panels/TwPanelTitle.vue'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwPanelTabsFull from '@/components/tw-ui/panels/TwPanelTabsFull.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import TwPanelContent from '@/components/tw-ui/panels/TwPanelContent.vue'

export default {
  name: 'ImportBuilderFileCard',
  components: {
    TwPanelContent,
    TwCircleLoader,
    TwPanelTabsFull,
    TwPanelContentBlock,
    TwPanelTitle,
    BuilderFileLogList,
    BuilderFileLoaderDisplayBlock,
    BuilderFileComparisonBlock,
    ImportBuilderFileMainShow,
    ImportBuilderFileMainForm
  },
  mixins: [CardMixin],
  props: {
    builderId: {
      default: null
    }
  },
  data () {
    return {
      currentTab: 'main',
      API: API.ImportBuilderFile
    }
  },
  computed: {
    ...mapGetters({
      getFiltersByKey: 'filters/getFiltersByKey',
      isAdmin: 'profile/isAdmin',
      allowNamespacesDict: 'profile/allowNamespacesDict'
    }),
    tabList () {
      return [
        {
          label: 'Общие',
          key: 'main'
        },
        {
          label: 'Просмотр',
          key: 'display',
          disabled: this.isNew
        },
        {
          label: 'Лог загрузки',
          key: 'log',
          disabled: this.isNew
        }
      ]
    },
    isAllowEditPermissions () {
      return this.isAdmin || !!this.allowNamespacesDict[dictPermissionRules.AppServicePermissionRulesOBJECTSNewBuildingsBuilderFileManagementBuilderFileRule]
    },
    defaultFormFields () {
      return {
        companyId: this?.builderId || null
      }
    }
  }
}
</script>
