<template>
  <div>
    <div
      :id="mapUid"
      v-resize="onResize"
      :style="{ height: mapHeight, width: '100%' }"
      class="tw-border tw-border-solid tw-rounded-sm tw-overflow-auto"
    />
    <div
      v-if="!viewOnly"
      class="tw-mt-[10px] tw-flex tw-gap-[10px] tw-flex-wrap"
    >
      <TwPanelActionButton
        :disabled="actionsDisabled.startDraw"
        color="blue"
        @click.stop="onStartDrawing"
      >
        {{ Array.isArray(value) && value.length ? 'Изменить' : 'Задать' }} полигон
      </TwPanelActionButton>
      <TwPanelActionButton
        :disabled="actionsDisabled.endDraw"
        color="blue"
        @click.stop="onStopDrawing"
      >
        Завершить редактирование полигона
      </TwPanelActionButton>
      <TwPanelActionButton
        :disabled="actionsDisabled.clear"
        color="red"
        @click.stop="onClear"
      >
        Удалить полигон
      </TwPanelActionButton>
      <div class="tw-flex-1" />
      <TwPanelActionButton
        title="Публичная Кадастровая Карта"
        @click.stop="openPkkHelper"
      >
        ПКК
      </TwPanelActionButton>
    </div>

    <!--  Карта росреестра  -->
    <TwDialog
      v-model="pkkOpened"
      max-width="70vw"
    >
      <template #header>
        <div>
          <div class="tw-text-h4 tw-font-semibold">
            Публичная кадастровая карта
          </div>
        </div>
      </template>
      <template #body>
        <div v-if="localMapCenter && pkkOpened">
          <PkkMarkingMapHelper
            :center="localMapCenter"
            :sub-polygons="buildingsList"
            map-height="60vh"
            :value="value"
            @input="onInputPkk"
          />
        </div>
      </template>
    </TwDialog>
  </div>
</template>

<script>
import TwPanelActionButton from '@/components/tw-ui/panels/TwPanelActionButton.vue'
import PkkMarkingMapHelper from '@/views/Newbuildings/Editor/OtherComponents/Map/PkkMarkingMapHelper.vue'
import TwDialog from '@/components/tw-ui/modal/TwDialog.vue'

const mapConfig = {
  defaultCenter: [55.78969, 49.13699],
  defaultMapZoom: 16,
  mapControls: ['zoomControl', 'fullscreenControl'],
  editablePolygonStyle: {
    fillColor: 'rgba(255, 0, 0, 0.1)',
    strokeColor: 'rgba(255, 0, 0, 1)',
    strokeWidth: 2
  },
  otherPolygonStyle: {
    fillColor: 'rgba(0,64,255,0.2)',
    strokeColor: 'rgb(91,91,91)',
    strokeWidth: 3
  }
}

export default {
  name: 'RealtyComplexBuildingMapMarking',
  components: { TwDialog, PkkMarkingMapHelper, TwPanelActionButton },
  props: {
    // текущее редактируемое здание - координаты и базовая информация
    value: {
      type: Array,
      default: () => {
        return []
      }
    },
    // Список всех зданий комплекса
    buildingsList: {
      type: Array,
      default: () => {
        return []
      }
    },
    // Запрет на редактирование
    disabled: {
      type: Boolean,
      default: false
    },
    // Только просмотр
    viewOnly: {
      type: Boolean,
      default: false
    },
    zoom: {
      type: Number,
      default: mapConfig.defaultMapZoom
    },
    center: {
      type: Array,
      default: () => {
        return mapConfig.defaultCenter
      }
    },
    mapHeight: {
      type: String,
      default: '450px'
    }
  },
  data () {
    return {
      pkkOpened: false,
      map: null,
      drawActive: false,
      editablePolygon: null,
      otherPolygons: null,
      localMapCenter: null
    }
  },
  computed: {
    mapUid () {
      return `map-${this._uid}`
    },
    actionsDisabled () {
      return {
        startDraw: this.drawActive,
        endDraw: !this.drawActive,
        clear: (!this.value?.length && !this.drawActive)
      }
    }
  },
  mounted () {
    this.map = null
    this.$nextTick(() => {
      this.onInit()
    })
  },
  beforeDestroy () {
    this.map = null
  },
  methods: {
    openPkkHelper () {
      this.localMapCenter = this.map?.getCenter() || mapConfig.defaultCenter
      this.$nextTick(() => {
        this.pkkOpened = true
      })
    },
    onInputPkk (eventData) {
      console.warn('onInputPkk ', eventData)
      this.onClear()
      // переинициализирую объект для карты
      this.$nextTick(() => {
        this.$emit('input', eventData)
        this.$nextTick(() => {
          this.editablePolygonInit()
          this.mapToCenterAndZoomFromObjects()
          this.pkkOpened = false
        })
      })
    },
    addPolygon (itemInfo, hasEditable = false) {
      const polygon = new window.ymaps.GeoObject({
        geometry: {
          // Тип геометрии - "Многоугольник".
          type: 'Polygon',
          // Указываем координаты вершин многоугольника.
          coordinates: [itemInfo.mapPolygon]
        },
        // Описываем свойства геообъекта.
        properties: {
          // Содержимое балуна.
          // Описываем свойства геообъекта.
          hintContent: itemInfo.title,
          balloonContent: itemInfo.title
        }
      }, {
        // Задаем опции геообъекта.
        // Цвет заливки.
        fillColor: hasEditable ? mapConfig.editablePolygonStyle.fillColor : mapConfig.otherPolygonStyle.fillColor,
        // Ширина обводки.
        strokeWidth: hasEditable ? mapConfig.editablePolygonStyle.strokeWidth : mapConfig.otherPolygonStyle.strokeWidth,
        strokeColor: hasEditable ? mapConfig.editablePolygonStyle.strokeColor : mapConfig.otherPolygonStyle.strokeColor,
        // The cursor in the mode for adding new vertices.
        editorDrawingCursor: 'crosshair',
        // The maximum number of vertices.
        editorMaxPoints: 99
      })
      polygon.events.add('click', () => {
        this.$emit('polygon-click', itemInfo)
      })
      return polygon
    },
    onResize () {
      this.map?.container?.fitToViewport()
    },
    async onInit () {
      // Проверяю наличие карт
      if (!window?.ymaps) return console.error('window.ymaps - NONE', !!window?.ymaps)
      window.ymaps.ready().then(() => {
        this.map = new window.ymaps.Map(this.mapUid, {
          center: this.center,
          zoom: this.zoom,
          controls: mapConfig.mapControls
        }, {
          suppressMapOpenBlock: true, // Нужно ли скрывать предложение открыть текущую карту в Яндекс.Картах
          yandexMapDisablePoiInteractivity: true // Отключает интерактивность элементов подложки карты (кликабельность больниц, магазинов и тп.)
        })
        this.map.events.add('boundschange', (e) => {
          // при изменении позиции карты перезаписываю локальный центр для передачи другим компонентам
          this.localMapCenter = e.originalEvent.map?.getCenter() || mapConfig.defaultCenter
        })
        // для редактирования показываю поиск
        if (!this.viewOnly) {
          const searchControl = new window.ymaps.control.SearchControl({
            options: {
              noPlacemark: false // отключение popover с информацией после выбора объекта в поиске
            }
          })

          this.map.controls.add(searchControl)
        }

        // Назначаю курсор
        this.map.cursors.push('pointer')

        // инициализирую полигоны
        // основной для редактирвоания
        this.editablePolygonInit()
        this.otherPolygonsInit()

        this.onResize()

        this.$nextTick(() => {
          this.mapToCenterAndZoomFromObjects()
        })
      })
    },
    editablePolygonInit () {
      // console.warn('editablePolygonInit', this.value)
      this.editablePolygon = this.addPolygon({ title: 'Текущий объект', mapPolygon: this.value || [] }, true)
      this.map.geoObjects.add(this.editablePolygon)

      // мониторинг состояния полигона
      const stateMonitor = new window.ymaps.Monitor(this.editablePolygon.editor.state)
      stateMonitor.add(['drawing', 'editing'], (newDrawState) => {
        // монитор управляет состоянием кнопок
        this.drawActive = newDrawState.drawing || newDrawState.editing
      })
    },
    otherPolygonsInit () {
      // console.warn('otherPolygonsInit', this.buildingsList)
      this.buildingsList.forEach((item) => {
        this.map.geoObjects.add(this.addPolygon(item, false))
      })
    },
    onStartDrawing () {
      console.log('onStartDrawing')
      const editablePolygonCoords = this.editablePolygon?.geometry?.getCoordinates()
      if (editablePolygonCoords && Array.isArray(editablePolygonCoords[0]) && editablePolygonCoords[0].length) {
        this.editablePolygon.editor.startEditing()
      } else {
        this.editablePolygon.editor.startDrawing()
      }
    },
    onStopDrawing () {
      console.log('onStopDrawing')
      this.editablePolygon.editor.stopEditing()
      this.editablePolygon.editor.stopDrawing()
      // формирую новые координаты
      const editablePolygonCoords = this.editablePolygon?.geometry?.getCoordinates()
      const newValueData = editablePolygonCoords && Array.isArray(editablePolygonCoords[0]) && editablePolygonCoords[0].length ? editablePolygonCoords[0] : []
      this.$emit('input', newValueData)
    },
    onClear () {
      console.log('onClear')
      // очищаю координаты
      const newValueData = []
      this.$emit('input', newValueData)
      // очищаю карту
      if (this.map?.geoObjects && this.editablePolygon) {
        this.map.geoObjects.remove(this.editablePolygon)
      }
      // переинициализирую объект для карты
      this.$nextTick(() => {
        this.editablePolygonInit()
      })
    },
    // КАРТА центровка и маштабирование потносительно имеющихся объектов
    mapToCenterAndZoomFromObjects () {
      this.localMapCenter = this.map?.getCenter() || mapConfig.defaultCenter
      const bounds = this.map.geoObjects.getBounds()
      if (!bounds) {
        return
      }
      this.map.setBounds(bounds, {
        checkZoomRange: true,
        duration: 300,
        preciseZoom: true,
        zoomMargin: 5,
        callback: (err) => {
          if (err) {
            console.error('callback', err)
          }
        }
      })
      this.$nextTick(() => {
        this.onResize()
      })
    }
  }
}
</script>

<style>
body > ymaps {
  background-color: white;
}
</style>
