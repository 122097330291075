<template>
  <div class="tw-h-full tw-w-full tw-flex tw-justify-center tw-items-center tw-p-5">
    <div class="tw-mb-[250px]">
      <div class="tw-text-h3">
        Внесение сотрудника в реестр опросов
      </div>
      <div class="tw-mt-[30px]">
        <TwSelectRelations
          v-model="form.realtorId"
          :multi="false"
          :dense="true"
          label="Выберите сотрудника"
          list-label="Найти сотрудника"
          relation-name="user"
          :persistent-label="true"
          :error-text="form.realtorId ? '':'Выберите сотрудника'"
          placeholder="Не выбран"
        />
      </div>
      <div class="tw-mt-[30px] tw-text-main-sm tw-flex tw-gap-[8px]">
        <button
          :disabled="formProcessing || !form.realtorId"
          class="tw-px-[20px] tw-py-[8px] tw-rounded-md tw-text-white tw-font-bold tw-bg-an-flat-blue-btn enabled:hover:tw-bg-an-flat-blue-btn-active disabled:tw-opacity-50"
          @click.stop="handleSubmit"
        >
          Внести сотрудника в реестр опросов
        </button>
        <button
          :disabled="formProcessing"
          class="tw-flex-1 tw-px-[20px] tw-py-[8px] tw-rounded-md tw-font-bold tw-bg-an-flat-gray-2 enabled:hover:tw-bg-an-flat-gray-1 disabled:tw-opacity-50"
          @click.stop="$emit('cancel')"
        >
          Отменить
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/Api/Review/RealtorSurvey'
import { required } from 'vuelidate/lib/validators'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import TwSelectRelations from '@/components/tw-ui/ui-kit/input/select-relations/TwSelectRelations.vue'

export default {
  name: 'CreateMainForm',
  components: {
    TwSelectRelations
  },
  mixins: [FormCreateEditMixin],
  props: {
    disableRedirect: {
      type: Boolean,
      default: false
    },
    defaultFormFields: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      isNeedViewPersonLeadAndDeals: false,
      API: API,
      form: {
        realtorId: null
      }
    }
  },
  // Модель для валидации, которую Vuelidate превратит в computed-поле $v
  validations: {
    isNeedViewPersonLeadAndDeals: {
      isViewed: value => value === false
    },
    form: {
      // Название поля должно совпадать с полем в form
      personId: {
        required: (v) => !!(v && !isNaN(parseInt(v)) && parseInt(v) && parseInt(v) > 0)
      },
      responsibleUserId: {
        required
      }
    }
  },
  methods: {
    handleSubmit () {
      if (this.formProcessing === true) {
        return
      }

      this.formProcessing = true
      API.store(this.form).then(({ data }) => {
        this.$emit('create', data.data)
        // if (this.formType === 'create') {
        //   this.$emit('create', data.data)
        //   // если текущий путь равен пути создания то редирект в просмотр
        //   if (this.$route.name === `${RouteKeys.lead}-create`) {
        //     this.$router.replace({
        //       name: `${RouteKeys.lead}-show`,
        //       params: { id: data.data.id }
        //     })
        //   }
        // } else {
        //   this.$emit('update', data.data)
        // }
      }).finally(() => {
        this.formProcessing = false
      })
    }
  }
}
</script>
