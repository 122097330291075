import { AxiosResponse } from 'axios'
import HTTP from '@/Api/http'
const path = '/person/additional_contact'

export default {
  getList (param?: object): Promise<AxiosResponse> {
    const $config = {
      params: param
    }

    return HTTP.get(`${path}`, $config)
  },
  show (id: number): Promise<AxiosResponse> {
    const $config = {}
    return HTTP.get(`${path}/${id}`, $config)
  }
}
