<template>
  <section>
    <h3 class="tw-text-an-flat-black-main tw-font-bold tw-text-[32px] tw-leading-[44px]">
      Среднее изменение цены по дому
    </h3>
    <div class="tw-mt-[32px]">
      <AnalyzeSimpleComplexGraph
        :height="500"
        :complex="complexData"
      />
    </div>
  </section>
</template>

<script>
import AnalyzeSimpleComplexGraph from '@/views/Newbuildings/Graph/AnalyzeSimpleComplexGraph.vue'

export default {
  name: 'PageComplexBlockPriceAnalyze',
  components: { AnalyzeSimpleComplexGraph },
  props: {
    complexData: {
      type: Object,
      default: null
    }
  }
}
</script>

<style scoped>

</style>
