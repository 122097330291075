<template>
  <div
    v-if="dealInfo"
    class="tw-flex tw-flex-col tw-gap-[3px] tw-pt-[8px] tw-pb-[8px] tw-pr-[15px] tw-pl-[15px] tw-bg-an-flat-gray-15/60 tw-rounded-[5px] tw-text-main-sm2"
  >
    <div class="tw-text-main-xs2">
      {{ dealInfo.type ? dealInfo.type.label : 'Неизвестноый тип заявки' }} - {{ dealInfo.subtype ? dealInfo.subtype.label : 'Неизвестный подтип заявки' }}
    </div>
    <div class="tw-flex tw-gap-[6px] tw-items-center tw-mt-[4px]">
      <div
        v-if="dealInfo.status"
        :style="{ backgroundColor: dealInfo.status.color }"
        class="tw-text-main-xs2 tw-px-[8px] tw-py-[2px] tw-rounded-[50px]"
      >
        {{ dealInfo.status && dealInfo.status.name }}
      </div>
      <ViewPanelLink
        :id="dealInfo.id"
        component="deal-show"
      >
        {{ dealInfo.name || 'Заявка без названия' }} #{{ dealInfo.id }}
      </ViewPanelLink>
    </div>
    <div
      v-if="dealInfo.responsibleUserId"
      class="tw-flex tw-flex-col tw-items-end tw-text-main-xs tw-text-an-flat-gray-4"
    >
      <div>
        <ViewPanelLink
          :id="dealInfo.responsibleUserId"
          label="Пользователь"
          component="employee-show"
        >
          <EmployeeName
            :id="dealInfo.responsibleUserId"
            format="fi"
          />
        </ViewPanelLink>
      </div>
      <div class="tw-mt-[2px]">
        Тел.: <EmployeePhone :id="dealInfo.responsibleUserId" />
      </div>
    </div>
  </div>
</template>

<script>
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink'
import EmployeeName from '@/components/other/employee/EmployeeName.vue'
import EmployeePhone from '@/components/other/employee/EmployeePhone.vue'
import crmDealApi from '@/Api/Crm/Deal'

export default {
  name: 'TwPanelContentDealInfo',
  components: { EmployeePhone, EmployeeName, ViewPanelLink },
  props: {
    deal: {
      type: [Number, Object],
      default: null
    }
  },
  data () {
    return {
      pending: false,
      serverError: false,
      serverInfo: null
    }
  },
  computed: {
    dealInfo () {
      if (this.deal) {
        if (typeof this.deal === 'number') {
          // TODO Получить данные о сделке - асинхронно
          return this.serverInfo || null
        } else if (typeof this.deal === 'object') {
          // TODO Отрисовать объект сделки без запросов
          return this.deal || null
        }
      }
      return null
    }
  },
  mounted () {
    if (this.deal) {
      if (typeof this.deal === 'number') {
        // Получить данные о сделке - асинхронно
        this.$nextTick(() => {
          this.fetchInfo()
        })
      } else if (typeof this.deal === 'object') {
        // Отрисовать объект сделки без запросов
        this.serverInfo = this.info
      }
    }
  },
  methods: {
    fetchInfo () {
      this.pending = true
      this.serverError = false
      crmDealApi.show(this.deal)
        .then(({ data: result }) => {
          console.warn('++++', result)
          this.serverInfo = result.data || null
        })
        .catch((error) => {
          console.error(error)
          this.serverError = true
        })
        .finally(() => {
          this.pending = false
        })
    }
  }
}
</script>

<style scoped>

</style>
