import { AxiosResponse } from 'axios'
import HTTP from '@/Api/http'
import { AuthAxiosDefaultConfig } from '@/Api/Rtd'

const path = '/request-to-developer/request'

export default {
  getListForDeal (param?: object, dealId = 0): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig,
      params: {
        ...param,
        dealId
      }
    }
    return HTTP.get(`${path}`, $config)
  },
  create (params: any, requestType: 'fixation' | 'reservation' = 'fixation'): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig,
      params: params
    }
    return HTTP.get(`${path}/${requestType}/create`, $config)
  },
  // Доп информация вызываемая для дополнения формы
  formInfo (param: { companyId: any, complexId: any }, requestType: 'fixation' | 'reservation' = 'fixation'): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig,
      params: param
    }
    return HTTP.get(`${path}/${requestType}/info`, $config)
  },
  show (id: number | string): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.get(`${path}/${id}`, $config)
  },
  cancel (id: number | string, data: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.post(`${path}/${id}/cancel`, data, $config)
  },
  draftStore (data: object, requestType: 'fixation' | 'reservation' = 'fixation'): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.post(`${path}/${requestType}`, data, $config)
  },
  update (id: number, data: object, requestType: 'fixation' | 'reservation' = 'fixation'): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.put(`${path}/${requestType}/${id}`, data, $config)
  }
}
