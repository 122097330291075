<template>
  <div>
    <TwDialog
      max-width="600px"
      :value="value"
      @input="$emit('input', $event)"
    >
      <template #header>
        <div class="tw-text-h4 tw-font-semibold">
          Подтверждение закрытия периода
        </div>
      </template>
      <template #body>
        <div>
          <TwInputText
            disabled
            class="tw-mt-[12px]"
            type="text"
            placeholder="Итоговая комиссия(по списку проверенных заявок)"
            max-length="13"
            :value="$Global.inputFormatPrice.getStringSpaced(form.amount)"
            :persistent-label="true"
            :show-label="true"
            :dense="true"
          >
            <template #append>
              руб.
            </template>
          </TwInputText>
          <TwInputText
            class="tw-mt-[12px]"
            type="text"
            placeholder="Комиссия из Битрикс"
            max-length="13"
            :value="$Global.inputFormatPrice.getStringSpaced(form.amountBx)"
            :persistent-label="true"
            :show-label="true"
            :dense="true"
            @input="form.amountBx = $Global.inputFormatPrice.getNumber($event)"
          >
            <template #append>
              руб.
            </template>
          </TwInputText>
        </div>
        <div class="tw-text-right tw-mt-[15px]">
          Итого: <span class="tw-text-h6">{{ (form.amountBx + form.amount).toLocaleString('ru') }}</span>
        </div>
        <div class="tw-mt-[15px]">
          <TwPanelInfoBlock>
            <template #title>
              Внимание!
            </template>
            <template #text>
              После закрытия периода, к нему нельзя будет прикрепить новые результаты по заявкам
            </template>
          </TwPanelInfoBlock>
        </div>
        <!--    Вывод ошибок      -->
        <div class="tw-p-2 tw-text-center tw-text-an-flat-red-main">
          <div v-if="serverError">
            {{ serverError }}
          </div>
        </div>
        <div class="tw-mt-[15px]">
          <TwPanelActionButton
            class="tw-w-full"
            color="red"
            :disabled="blockProcessing"
            @click.stop="storeBlock"
          >
            Закрыть период
          </TwPanelActionButton>
        </div>
      </template>
    </TwDialog>
  </div>
</template>

<script>
import API from '@/Api/Crm/Deal/BuyNb/CompanyPeriodResult'
import TwPanelActionButton from '@/components/tw-ui/panels/TwPanelActionButton.vue'
import TwDialog from '@/components/tw-ui/modal/TwDialog.vue'
import TwInputText from '@/components/tw-ui/ui-kit/input/TwInputText.vue'
import TwPanelInfoBlock from '@/components/tw-ui/panels/TwPanelInfoBlock.vue'

export default {
  name: 'CompanyPeriodResultManagementBlockDialog',
  components: {
    TwPanelInfoBlock,
    TwInputText,
    TwDialog,
    TwPanelActionButton
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    resultsTotalAll: {
      type: Number,
      default: 0
    },
    periodId: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      API: API || null,
      blockProcessing: false,
      form: {
        amount: this.resultsTotalAll || 0,
        amountBx: 0
      },
      serverError: null
    }
  },
  methods: {
    storeBlock () {
      this.blockProcessing = true
      this.serverError = null
      API.storeBlock(this.periodId, this.form)
        .then(() => {
          // закрыть диалог
          this.$emit('input', false)
          // обновить карточку
          this.$emit('refresh')
        })
        .catch((error) => {
          this.serverError = error?.response?.data
        })
        .finally(() => {
          this.blockProcessing = false
        })
    }
  }
}
</script>
