<template>
  <TwPanelContentBlock
    class="tw-font-manrope tw-mb-[6px] tw-bg-white"
    style-hr="tw-bg-an-flat-hr-border-grey"
    content-classes="''"
  >
    <template #title>
      <div>
        Социальные сети
      </div>
    </template>
    <template #action>
      <div>
        <TwPanelContentBlockActionButton
          :disabled="loading"
          type="edit"
          @click="isEdit = !isEdit"
        >
          {{ isEdit ? 'отменить':'изменить' }}
        </TwPanelContentBlockActionButton>
      </div>
    </template>
    <div>
      <div
        v-if="loading"
        class="tw-w-full tw-flex tw-justify-center tw-items-center tw-py-5"
      >
        <TwCircleLoader />
      </div>
      <div v-else>
        <UserSocialMediaForm
          v-if="isEdit"
          :id="userId || 0"
          ref="EditForm"
          @refresh="fetchInfo"
          @cancel="isEdit = false"
        />
        <UserSocialMediaShow
          v-else
          :info="info"
        />
      </div>
    </div>
  </TwPanelContentBlock>
</template>

<script>
import API from '@/Api/Auth/User/social-media'
import UserSocialMediaForm from '@/views/Company/Employee/social-media/UserSocialMediaForm'
import UserSocialMediaShow from '@/views/Company/Employee/social-media/UserSocialMediaShow'
import { mapGetters } from 'vuex'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import dictPermissionRules from '@/Permissions/dictRules'
import TwPanelContentBlockActionButton from '@/components/tw-ui/panels/TwPanelContentBlockActionButton.vue'

export default {
  name: 'UserSocialMediaBlock',
  components: { TwPanelContentBlockActionButton, TwCircleLoader, TwPanelContentBlock, UserSocialMediaShow, UserSocialMediaForm },
  props: {
    userData: {
      required: true
    }
  },
  data () {
    return {
      info: null,
      loading: false,
      isEdit: false
    }
  },
  methods: {
    fetchInfo () {
      this.isEdit = false
      this.loading = true
      API.show(this.userId).then(({ data }) => {
        this.info = data?.data || null
      }).catch((error) => {
        console.log(error)
      }).finally(() => {
        this.loading = false
        this.isEdit = false
      })
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'profile/isAdmin',
      allowNamespacesDict: 'profile/allowNamespacesDict'
    }),
    userId () {
      return this.userData?.id || null
    },
    allowEdit () {
      return this.isAdmin || !!this.allowNamespacesDict[dictPermissionRules.AppServicePermissionRulesCRMUsersUpdateUserSocialMediaRule]
    }
  },
  mounted () {
    this.fetchInfo()
  }
}
</script>

<style scoped>

</style>
