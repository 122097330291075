<template>
  <div
    v-if="domClickDeal && calculateInfo"
    class="text-right"
  >
    <v-row no-gutters>
      <v-col>
        <CardBlockField>
          <template #title>
            Сумма кредита
          </template>
          <template #content>
            {{ (domClickDeal.requestedSum || 0).toLocaleString() }}<Rouble />
          </template>
        </CardBlockField>
      </v-col>
      <v-col>
        <CardBlockField>
          <template #title>
            Ежемесячный платёж
          </template>
          <template #content>
            {{ (domClickDeal.monthlyPayment || 0).toLocaleString() }}<Rouble />
          </template>
        </CardBlockField>
      </v-col>
      <v-col>
        <CardBlockField>
          <template #title>
            Ставка
          </template>
          <template #content>
            {{ domClickDeal.interestRate }}<Percent />
          </template>
        </CardBlockField>
      </v-col>
      <v-col>
        <CardBlockField>
          <template #title>
            Стоимость недвижимости
          </template>
          <template #content>
            {{ (calculateInfo.estateCost || 0).toLocaleString() }}<Rouble />
          </template>
        </CardBlockField>
      </v-col>
      <v-col>
        <CardBlockField>
          <template #title>
            Необходимый доход
          </template>
          <template #content>
            {{ (calculateInfo.income || 0).toLocaleString() }}<Rouble />
          </template>
        </CardBlockField>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <CardBlockField>
          <template #title>
            Статус
          </template>
          <template #content>
            {{ dealStatuses[domClickDeal.status] || domClickDeal.status }}
          </template>
        </CardBlockField>
      </v-col>
      <v-col>
        <CardBlockField>
          <template #title>
            Программа
          </template>
          <template #content>
            {{ selectedProduct }}
          </template>
        </CardBlockField>
      </v-col>
      <v-col v-if="domClickDeal.loanPeriod">
        <CardBlockField>
          <template #title>
            Срок кредита
          </template>
          <template #content>
            {{ domClickDeal.loanPeriod / 12 }} {{ $Global.textPlural(domClickDeal.loanPeriod / 12, 'year') }}
          </template>
        </CardBlockField>
      </v-col>
      <v-col>
        <CardBlockField>
          <template #title>
            Первоначальный взнос
          </template>
          <template #content>
            {{ (calculateInfo.deposit || 0).toLocaleString() }}<Rouble />
          </template>
        </CardBlockField>
      </v-col>
      <v-col>
        <v-btn
          :disabled="loading || isEndStatus"
          small
          @click="$emit('tabChange', 'calculation')"
        >
          редактировать
        </v-btn>
      </v-col>
    </v-row>
    <!--  <pre>-->
    <!--    {{calculateInfo}}-->
    <!--  </pre>-->
    <!--  <pre>-->
    <!--    {{domClickDeal}}-->
    <!--  </pre>-->
  </div>
</template>

<script>
import CardBlockField from '@/components/core/ViewPanel/Card/CardBlockField'
import { dealStatuses, isEndStatus } from '../DomClickDict'
import Rouble from '@/components/symbol/Rouble'
import Percent from '@/components/symbol/Percent'

export default {
  name: 'DomClickDealInfo',
  components: { Percent, Rouble, CardBlockField },
  props: {
    calculateInfo: {
      type: Object,
      required: true
    },
    domClickDeal: {
      type: Object,
      required: true
    },
    productsList: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isEndStatus () {
      return isEndStatus(this.domClickDeal.status)
    },
    dealStatuses () {
      return dealStatuses
    },
    selectedProduct () {
      return this.productsList?.find(p => p.product === this.calculateInfo?.product)?.title || '~'
    }
  }
}
</script>

<style scoped>
.v-list-item__content .v-list-item__title {
  white-space: inherit;
}
</style>
