<template>
  <div class="tw-font-manrope tw-text-main-sm2 tw-max-w-[100%] tw-px-[26px] tw-py-[18px] tw-bg-white tw-min-h-screen">
    <template v-if="isCardContentVisible">
      <!--   ЗАГОЛОВОК   -->
      <TwPanelTitle>
        <template #left>
          <div class="tw-text-h4 tw-font-bold">
            <div
              v-if="isNew"
              class="title text-muted"
            >
              Новая заявка
            </div>
            <div
              v-else-if="data"
              class="tw-flex tw-flex-wrap tw-gap-[8px]"
            >
              <div class="tw-text-an-flat-disabled-text tw-font-medium tw-text-[18px]">
                #&nbsp;{{ data.id }}
              </div>
              <div>{{ data.name }}</div>
              <div
                v-if="data && data.type"
                class="tw-inline-block tw-text-an-flat-disabled-text tw-font-medium tw-text-[18px]"
              >
                {{ data.type && data.type.label }} <span v-if="dealSubtype">/ {{ dealSubtype.label }}</span>
              </div>
            </div>
            <div v-else>
              ~
            </div>
          </div>
        </template>
        <template #right>
          <template v-if="!isNew && dealStage && (dealStage.value !== 'fail' && dealStage.value !== 'success')">
            <div class="tw-flex tw-gap-[8px] tw-font-manrope tw-font-bold tw-text-main-xs">
              <!-- Заявка на услугу -->
              <DealRelationsAllowServicesBlock
                v-if="data && data.id"
                :deal-id="data.id"
                @refresh="refresh"
              />

              <!-- Переназначение -->
              <TwPanelActionButton
                v-if="data && data.id && allowDistributeResponsibleUser"
                title="Назначить ответственного для сделки"
                color="green"
                @click.prevent="onOpenDistributeModal"
              >
                Назначить
              </TwPanelActionButton>

              <!-- TODO debug permissions -->
              <MenuEntityActions
                v-if="permissions"
                :permissions="permissions"
                @delete="deleteCardItem"
              />
            </div>
          </template>
        </template>
      </TwPanelTitle>
      <!--   СТАТУС   -->
      <TwPanelContent
        v-if="data"
        :wrapper-classes="''"
      >
        <DealStatusProgress
          class="tw-mb-[10px]"
          :current-status="data.status"
          :deal-id="data.id"
          :deal-type="data.type.value"
          :disabled="loading || !actions.update"
          @changeStatus="changeStatus"
          @changeStatusError="changeStatusError"
        />
      </TwPanelContent>
      <!--   ШАБЛОНЫ ЗАЯВОК ПО ТИПАМ     -->
      <template v-if="data">
        <!--    ИПОТЕКА    -->
        <template v-if="data.type.value === 'mortgage'">
          <DealMortgage
            ref="dealUiFromType"
            :deal-data="data"
            :deal-state="otherData.dealState"
            :dictionary="dictionary"
            :loading="loading"
            :permissions="permissions"
            @update="updatedDeal"
            @refresh="refresh"
          />
        </template>
        <!--    НОВОСТРОЙКИ    -->
        <template v-else-if="data.type.value === 'buy_nb'">
          <DealBuyNb
            ref="dealUiFromType"
            :deal-data="data"
            :deal-state="otherData.dealState"
            :dictionary="dictionary"
            :preset-tab="presetTab"
            :loading="loading"
            :permissions="permissions"
            @update="updatedDeal"
            @refresh="refresh"
          />
        </template>
        <!--    ПРОДЛЕНИЕ СТРАХОВКИ    -->
        <template v-else-if="data.type.value === 'insurance'">
          <DealInsurance
            ref="dealUiFromType"
            :deal-data="data"
            :deal-state="otherData.dealState"
            :dictionary="dictionary"
            :preset-tab="presetTab"
            :loading="loading"
            :permissions="permissions"
            @update="updatedDeal"
            @refresh="refresh"
          />
        </template>
        <!--    СТРОИТЕЛЬСТВО    -->
        <template v-else-if="data.type.value === 'house_develop'">
          <DealHouseDevelop
            ref="dealUiFromType"
            :deal-data="data"
            :deal-state="otherData.dealState"
            :dictionary="dictionary"
            :loading="loading"
            :permissions="permissions"
            @update="updatedDeal"
            @refresh="refresh"
          />
        </template>
        <!--    ЗАГЛУШКА    -->
        <template v-else-if="data">
          <DealAnOther
            :deal-data="data"
            :deal-state="otherData.dealState"
            :dictionary="dictionary"
            :loading="loading"
            :permissions="permissions"
            @update="updatedDeal"
            @refresh="refresh"
          />
        </template>
        <!--    ОШИБКА    -->
        <div
          v-else
          class="text-center text-muted text-h5"
        >
          Данный тип заявки находится в разработке...
        </div>
      </template>
      <!--    Новая заявка    -->
      <TwPanelContent v-else-if="isNew">
        <div class="tw-flex-1">
          <TwPanelContentBlock class="tw-mb-[6px]">
            <template #title>
              <div>
                О заявке
              </div>
            </template>
            <div class="tw-flex tw-gap-[12px] tw-flex-col">
              <DealMainForm
                v-if="editMain"
                :id="data ? data.id : 0"
                @create="onCreated"
                @update="onUpdated"
                @cancel="onCancelAndClose"
              />
            </div>
          </TwPanelContentBlock>
        </div>
        <div class="tw-flex-1" />
      </TwPanelContent>
    </template>
    <!--
      Блок заполняющий пространство при отсутствуии контента
      для прижатия блока сохранения к нижней части страницы
    -->
    <div class="tw-flex tw-justify-center tw-items-center">
      <TwCircleLoader
        v-if="loading && !isCardContentVisible"
        width="50px"
        height="50px"
      />
      <div v-else-if="error">
        <PermissionDenied :text="error === 403 ? 'Недостаточно прав для просмотра!' : 'Ошибка соединения - Обратитесь к администратору!'" />
      </div>
    </div>
    <!--  Модалка назначения ответстсвенного  -->
    <DealDistributionSetUserModal
      v-if="data"
      ref="DistributeModal"
      :deal-type="data.type.value"
      @success="dealDistributionSuccess"
    />
    <!--  Модалка уведомления  -->
    <TwDialog
      :value="showDialog"
      max-width="400px"
      @input="showDialog = $event"
      @close="dialogOnClose"
    >
      <template #body>
        <div class="tw-text-center tw-mt-[20px]">
          <div class="tw-m-auto tw-bg-an-flat-red-main tw-w-[90px] tw-h-[90px] tw-flex tw-justify-center tw-items-center tw-rounded-full">
            <svg
              v-if="dialogData.type === 'error'"
              width="40"
              height="40"
              viewBox="0 0 8 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.73292 3.99998L7.84796 0.884843C8.05069 0.682216 8.05069 0.354597 7.84796 0.15197C7.64534 -0.0506567 7.31772 -0.0506567 7.11509 0.15197L3.99996 3.26711L0.884914 0.15197C0.682192 -0.0506567 0.354668 -0.0506567 0.152041 0.15197C-0.0506804 0.354597 -0.0506804 0.682216 0.152041 0.884843L3.26708 3.99998L0.152041 7.11511C-0.0506804 7.31774 -0.0506804 7.64536 0.152041 7.84799C0.253022 7.94906 0.385797 7.99984 0.518478 7.99984C0.651158 7.99984 0.783838 7.94906 0.884914 7.84799L3.99996 4.73285L7.11509 7.84799C7.21617 7.94906 7.34885 7.99984 7.48153 7.99984C7.61421 7.99984 7.74689 7.94906 7.84796 7.84799C8.05069 7.64536 8.05069 7.31774 7.84796 7.11511L4.73292 3.99998Z"
                fill="white"
              />
            </svg>
            <div v-else>
              {{ dialogData.type }} icon - TODO
            </div>
          </div>
          <div
            v-if="dialogData.title"
            class="tw-mt-[20px] tw-font-semibold tw-text-[20px] tw-leading-normal"
          >
            {{ dialogData.title }}
          </div>
          <div class="tw-mt-[20px] tw-mb-[30px] tw-font-medium tw-text-[18px] tw-leading-normal">
            {{ dialogData.description }}
          </div>
          <button
            class="tw-min-w-[250px] tw-m-auto tw-rounded-md tw-bg-an-flat-gray-3 enabled:hover:tw-bg-an-flat-gray-4 tw-py-[12px] tw-font-bold tw-text-main-sm tw-text-white tw-flex tw-gap-[12px] tw-justify-center tw-items-center enabled:hover:tw-drop-shadow-lg disabled:tw-opacity-50"
            @click.prevent="showDialog = false"
          >
            Закрыть
          </button>
        </div>
      </template>
    </TwDialog>
  </div>
</template>

<script>
import API from '@/Api/Crm/Deal'
import DealMortgage from '@/views/Deal/TypeViews/mortgage/DealMortgage'
import CardMixin from '@/mixins/CardMixin'
import DealStatusProgress from '@/components/status/DealStatusProgress'
import MenuEntityActions from '@/components/core/ViewPanel/Card/entity-actions/menuEntityActions'
import PermissionDenied from '@/views/PermissionDenied'
import DealAnOther from '@/views/Deal/TypeViews/another/DealAnOther'
import DealBuyNb from '@/views/Deal/TypeViews/buyNb/DealBuyNb'
import DealInsurance from '@/views/Deal/TypeViews/insurance/DealInsurance.vue'
import TwDialog from '@/components/tw-ui/modal/TwDialog.vue'
import DealHouseDevelop from '@/views/Deal/TypeViews/houseDevelop/DealHouseDevelop.vue'
import { mapGetters } from 'vuex'
import dictPermissionRules from '@/Permissions/dictRules'
import DealDistributionSetUserModal from '@/views/Deal/DealDistributionSetUserModal.vue'
import TwPanelTitle from '@/components/tw-ui/panels/TwPanelTitle.vue'
import TwPanelActionButton from '@/components/tw-ui/panels/TwPanelActionButton.vue'
import TwPanelContent from '@/components/tw-ui/panels/TwPanelContent.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import DealMainForm from '@/views/Deal/main/DealMainForm.vue'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import DealRelationsAllowServicesBlock from './TypeViews/Relations/DealRelationsAllowServicesBlock.vue'

export default {
  name: 'DealCard',
  components: {
    DealRelationsAllowServicesBlock,
    TwPanelContentBlock,
    DealMainForm,
    TwCircleLoader,
    TwPanelContent,
    TwPanelActionButton,
    TwPanelTitle,
    DealDistributionSetUserModal,
    DealHouseDevelop,
    TwDialog,
    DealBuyNb,
    DealInsurance,
    DealAnOther,
    PermissionDenied,
    MenuEntityActions,
    DealStatusProgress,
    DealMortgage
  },
  mixins: [CardMixin],
  data () {
    return {
      API: API,
      showDialog: false,
      dialogData: {
        type: 'warn', // warning, error, success
        title: '',
        description: ''
      }
    }
  },
  methods: {
    onOpenDistributeModal () {
      this.$refs.DistributeModal.onOpenModal(this.data.id)
    },
    changeStatus () {
      this.$emit('callback', {})
      this.refresh()
    },
    dialogOnClose () {
      this.dialogData = {
        type: '', // warning, error, success
        title: '',
        description: ''
      }
    },
    changeStatusError (errorData) {
      // переключить вкладку на ту где нужно заполнить данные
      if (errorData?.status_change_questionnaire && this.$refs?.dealUiFromType?.currentTabChange) {
        this.dialogData = {
          type: 'error', // warning, error, success
          title: '',
          description: errorData?.status_change_questionnaire || ''
        }
        this.$refs?.dealUiFromType?.currentTabChange('client')
        // Показать диалог уведомления
        this.showDialog = true
      }
      // Показать уведомление
      if (errorData?.status_change_disable) {
        this.dialogData = {
          type: 'error', // warning, error, success
          title: '',
          description: errorData?.status_change_disable || ''
        }
        // Показать диалог уведомления
        this.showDialog = true
      }
    },
    dealDistributionSuccess () {
      this.$emit('callback', {})
      this.$emit('close-me')
    },
    updatedDeal () {
      this.$emit('callback', {})
      this.refresh()
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'profile/isAdmin',
      allowNamespacesDict: 'profile/allowNamespacesDict'
    }),
    dealSubtype () {
      return this.data?.subtype || null
    },
    dealStage () {
      return this.data?.status?.stage || null
    },
    // имеется право назначения ответственного
    allowDistributeResponsibleUser () {
      return this.isAdmin || !!this.allowNamespacesDict[dictPermissionRules.AppServicePermissionRulesCRMDealsDealDistributeSetUser]
    }
  }
}
</script>
