<template>
  <PageViewDefaultWrapper
    ref="wrapper"
    header-class="mb-2"
    :permission-denied="permissionDenied"
    relative
  >
    <TwPanelContent class="tw-flex-1">
      <TwPanelContentBlock
        class="tw-flex-1 tw-mb-[6px] tw-overflow-auto"
      >
        <template #title>
          <div>
            Список объектов(ИЖС) застройщика
          </div>
        </template>
        <template #action>
          <TwPanelContentBlockActionButton
            :disabled="loading"
            size="md"
            @click.prevent="addNew"
          >
            Добавить объект
          </TwPanelContentBlockActionButton>
        </template>
        <div>
          <TwTableBase
            :schema-key="filterSchemaKey"
            :loading-error-text="loadingErrorText"
            tbody-classes="tw-text-xs"
            tbody-row-classes="tw-cursor-pointer"
            :items="data"
            :headers="columns"
            :table-fixed="false"
            :loading="loading"
            :fixed-header-margin="0"
            :table-columns-management="true"
            :pagination="pagination"
            @headersUpdate="columns = $event"
            @row-click="clickToItem"
            @sortUpdate="onSortUpdate"
            @changePagination="changePagination"
          >
            <template #item.types="{ item }">
              <div
                v-if="item.type"
                class="tw-font-medium tw-flex tw-items-center tw-gap-[10px]"
              >
                <div
                  v-if="item.subType && item.subType.value"
                  class="tw-flex tw-items-center tw-gap-[10px]"
                >
                  <NbTypeIcon :type="item.subType.value" />
                  {{ item.subType.label }}
                </div>
                <div
                  v-else
                  class="tw-flex tw-items-center tw-gap-[10px]"
                >
                  <NbTypeIcon :type="item.type.value" />
                  {{ item.type.label }}
                </div>
              </div>
              <div v-else>
                -
              </div>
            </template>
          </TwTableBase>
        </div>
      </TwPanelContentBlock>
    </TwPanelContent>
  </PageViewDefaultWrapper>
</template>

<script>
import landHouseAPI from '@/Api/ObjectStorage/Realty/Editor/NewBuilding/Land'
import PageViewDefaultWrapper from '@/components/core/ViewDefault/PageViewDefaultWrapper.vue'
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwPanelContent from '@/components/tw-ui/panels/TwPanelContent.vue'
import TwTableBase from '@/components/tw-ui/table/TwTableBase.vue'
import { ServicesSchema } from '@/store/twfilter/servicesSchema'
import tableOptions from '@/views/Dictionary/General/Company/developer/ObjectsList/tableOptionsForDeveloper.ts'
import DataListMixin from '@/mixins/DataListMixin'
import TwPanelContentBlockActionButton from '@/components/tw-ui/panels/TwPanelContentBlockActionButton.vue'
import { TypesRealtyObjects } from '@/views/Newbuildings/Editor/typesDict'
import { mapActions } from 'vuex'
import NbTypeIcon from '@/views/Newbuildings/Editor/NbTypeIcon.vue'

export default {
  name: 'BuilderObjectsBlockList',
  components: {
    NbTypeIcon,
    TwTableBase,
    TwPanelContent,
    TwPanelContentBlock,
    PageViewDefaultWrapper,
    TwPanelContentBlockActionButton
  },
  mixins: [DataListMixin, ViewPanelShowMixin],
  props: {
    companyId: {
      type: [Number, String, null],
      default: null
    }
  },
  data () {
    return {
      API: landHouseAPI,
      filterSchemaKey: ServicesSchema.OsLandHouseList.key,
      columns: tableOptions,
      defaultFilters: {
        builderId: this.companyId
      }
    }
  },
  methods: {
    ...mapActions({
      initCurrentFilters: 'twFilter/initCurrentFilters',
      fetchFilterService: 'twFilter/fetchFilterService'
    }),
    // инит схемы фильтров
    async initFiltersBySchemaKey () {
      console.warn('initFiltersBySchemaKey', this.filterSchemaKey)
      if (this.filterSchemaKey) {
        // получение схемы фильтров
        await this.fetchFilterService(this.filterSchemaKey)
        // инит полученного
        await this.initCurrentFilters(this.filterSchemaKey)
      } else {
        return new Error(`Не указан ключ схемы фильтров: ${this.filterSchemaKey}`)
      }
      this.fetch()
    },
    clickToItem (eventData) {
      // console.log('clickToItem: ', eventData)
      this.showNbEditorObjectCard({
        id: eventData.id,
        builderId: this.companyId,
        type: TypesRealtyObjects.land.type
      }, () => {
        this.refresh()
      })
    },
    addNew () {
      this.showNbEditorObjectCard({
        id: 0,
        builderId: this.companyId,
        type: TypesRealtyObjects.land.type
      }, () => {
        this.refresh()
      })
    }
  },
  async mounted () {
    await this.initFiltersBySchemaKey()
  }
}
</script>
