import lodashDebounce from 'lodash/debounce'

export default {
  props: {
    // валидация
    errorMessages: {
      type: Array,
      default: () => []
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    // мультиселект
    searchable: {
      type: Boolean,
      default: false
    },
    attach: {
      default: undefined
    },
    defaultMe: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    taggable: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: 'Выбрать'
    },
    // иное
    value: {
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    chips: {
      type: Boolean,
      default: false
    },
    chipColor: {
      type: String,
      default: 'primary'
    },
    clearable: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    classes: {
      type: String,
      default: 'mt-0 pt-0'
    },
    label: {
      type: String,
      default: ''
    },
    rules: {
      type: Array,
      default () {
        return []
      }
    },
    filters: {
      type: Object,
      default () {
        return null
      }
    }
  },
  data: () => ({
    API: {},
    items: [],
    select: null,
    searchQueryKey: 'search',
    loading: false
  }),
  methods: {
    input (event) {
      console.log('INPUT', event)
      if (event && event.id) {
        this.$emit('input', event.id)
        this.$emit('inputFullData', event || null)
      } else if (Array.isArray(event)) {
        this.$emit('input', event.map(v => v.id))
        this.$emit('inputFullData', event || [])
      } else if (event === undefined || event === null) {
        this.$emit('input', null)
        this.$emit('inputFullData', null)
      } else {
        console.warn('/AsyncSelectMixin/input/Необработанное исключение при вводе данных!', { event })
      }
    },
    search: lodashDebounce(function (value) {
      this.searchFromServer(value)
    }, 500),
    searchFromServer (searchString) {
      // console.warn(`${this.$options.name} - searchFromServer: `, searchString)
      if (this.loading || searchString === null) return

      this.loading = true

      this.API.getList({ ...this.filters, ...{ [this.searchQueryKey]: searchString?.trim(), limit: 150 } })
        .then((response) => {
          this.items = response.data.data || []
          if (this.multiple) {
            this.select.forEach(sItem => {
              if (sItem && !this.items.some(i => i.id === sItem.id)) {
                this.items.push(sItem)
              }
            })
          } else {
            if (this.select && !this.items.some(i => i.id === this.select.id)) {
              this.items.push(this.select)
            }
          }
          this.noDataHide = false
          this.$emit('touch')
        })
        .catch((error) => {
          console.error({ error })
        })
        .finally(() => {
          this.loading = false
          if (this.$refs.select) {
            this.$refs.select.updateMenuDimensions()
          }
          // TODO рекурсивный вызов
          if (this.searchStr !== searchString) {
            this.searchFromServer(this.searchStr)
          }
        })
    },
    fetchValueInfo (id, forArray = false) {
      // console.warn(this.$options.name, ' fetchValueInfo ', id)
      // this.loading = true
      this.API.show(id)
        .then(({ data: { data } }) => {
          // console.warn(data)
          if (data) {
            if (!forArray) {
              if (!this.items.some(i => i.id === data.id)) {
                this.items.push(data)
              }
              this.select = data
            } else {
              if (!Array.isArray(this.select)) {
                this.select = []
              }

              if (!this.select.some(i => i.id === data.id)) {
                // this.items.push(data)
                this.select.push(data)
              }
            }
          }
        })
        .catch((error) => {
          console.error({ error })
        })
        .finally(() => {
          // this.loading = false
        })
    }
  },
  watch: {
    searchStr (val) {
      if (this.loading) return
      this.search(val)
    },
    value: {
      handler (newValue, OldValue) {
        console.warn(this.$options.name, 'Value INIT change:  ', newValue, OldValue, (!!newValue && !OldValue))
        if ((!!newValue && !OldValue) || (Array.isArray(newValue) && newValue.length && Array.isArray(OldValue) && !OldValue.length) || (!!newValue && !this.items.length)) {
          if (!Array.isArray(newValue)) {
            console.warn(this.$options.name, 'инициализировать значение: ', newValue, !!newValue)
            this.fetchValueInfo(newValue)
          } else {
            console.warn(this.$options.name, 'инициализировать массив значений: ', newValue, OldValue)
            // инициализация массива значений
            for (const index in newValue) {
              this.fetchValueInfo(newValue[index], true)
            }
          }
        }
      },
      immediate: true
    }
  },
  mounted () {
    this.select = this.multiple ? [] : null
  }
}
