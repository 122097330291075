<template>
  <div class="tw-flex tw-flex-col">
    <TwPanelContentBlock class="tw-mb-[6px]">
      <template #title>
        <div>
          Матрица скидок
        </div>
      </template>
      <div class="tw-min-h-[350px] tw-flex tw-flex-col tw-gap-[12px] tw-font-medium">
        <div>
          <DiscountMatrixMainEditForm
            v-if="companyId || info"
            :id="(info && info.id) || 0"
            :company-id="companyId || (info && info.company.id)"
            :copy-from-id="copyFromId"
            @create="create"
            @update="refresh"
            @cancel="refresh"
          />
          <div
            v-else
            class="error"
          >
            Не удалось определить компанию
          </div>
        </div>
      </div>
    </TwPanelContentBlock>
  </div>
</template>

<script>
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import DiscountMatrixMainEditForm from '@/views/Mortgage/DiscountMatrix/main/DiscountMatrixMainEditForm'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'

export default {
  name: 'DiscountMatrixEditor',
  components: { TwPanelContentBlock, DiscountMatrixMainEditForm },
  mixins: [ViewPanelShowMixin],
  props: {
    info: {},
    dictionary: {},
    companyId: {
      type: [String, Number],
      default: ''
    },
    copyFromId: {
      type: [String, Number],
      default: ''
    }
  },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    refresh () {
      this.$emit('refresh')
    },
    create (data) {
      this.$emit('create', data)
    }
  }
}
</script>

<style scoped>

</style>
