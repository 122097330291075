<template>
  <button
    class="tw-group disabled:tw-opacity-50"
    :disabled="!dealId || isFavoriteProcessing"
    :title="favoriteInfo ? 'Удалить из избранного':'Добавить в избранное'"
    @click.stop="onChangeFavorite"
  >
    <TwCircleLoader
      v-if="isFavoriteProcessing"
      width="20px"
      height="20px"
    />
    <!--    <svg v-else-if="favoriteInfo" :width="iconSize" :height="iconSize" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">-->
    <!--      <path class="group-enabled:group-hover:tw-fill-an-flat-red-main" fill-rule="evenodd" clip-rule="evenodd" d="M11 0C11.4043 0 11.776 0.221815 11.9681 0.577643L15.0435 6.27719L21.1314 7.58755C21.5118 7.66941 21.8207 7.94613 21.9436 8.31526C22.0665 8.68438 21.9854 9.09105 21.7301 9.38464L17.5106 14.2381L18.2122 20.7827C18.2548 21.1792 18.0795 21.5675 17.7541 21.7978C17.4287 22.0281 17.0041 22.0644 16.6444 21.8925L11 19.1965L5.35558 21.8925C4.99585 22.0644 4.57131 22.0281 4.24592 21.7978C3.92054 21.5675 3.74525 21.1792 3.78774 20.7827L4.48934 14.2381L0.269871 9.38464C0.0146057 9.09105 -0.066564 8.68438 0.0564048 8.31526C0.179374 7.94613 0.488187 7.66941 0.868556 7.58755L6.95652 6.27719L10.032 0.577643C10.2239 0.221815 10.5956 0 11 0Z" fill="#98D5B9"/>-->
    <!--    </svg>-->
    <!--    <svg v-else :width="iconSize" :height="iconSize" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">-->
    <!--      <path class="group-enabled:group-hover:tw-fill-[#98D5B9]" fill-rule="evenodd" clip-rule="evenodd" d="M11 0C11.4043 0 11.776 0.221815 11.9681 0.577643L15.0435 6.27719L21.1314 7.58755C21.5118 7.66941 21.8207 7.94613 21.9436 8.31526C22.0665 8.68438 21.9854 9.09105 21.7301 9.38464L17.5106 14.2381L18.2122 20.7827C18.2548 21.1792 18.0795 21.5675 17.7541 21.7978C17.4287 22.0281 17.0041 22.0644 16.6444 21.8925L11 19.1965L5.35558 21.8925C4.99585 22.0644 4.57131 22.0281 4.24592 21.7978C3.92054 21.5675 3.74525 21.1792 3.78774 20.7827L4.48934 14.2381L0.269871 9.38464C0.0146051 9.09105 -0.0665638 8.68438 0.056405 8.31526C0.179374 7.94613 0.488187 7.66941 0.868555 7.58755L6.95652 6.27719L10.032 0.577643C10.2239 0.221815 10.5956 0 11 0ZM11 3.41642L8.65181 7.7682C8.499 8.05142 8.22982 8.2535 7.91522 8.32122L3.15187 9.34647L6.46434 13.1566C6.66503 13.3874 6.76054 13.6915 6.72794 13.9956L6.18521 19.0583L10.5259 16.9849C10.8257 16.8416 11.1742 16.8416 11.4741 16.9849L15.8148 19.0583L15.272 13.9956C15.2395 13.6915 15.335 13.3874 15.5356 13.1566L18.8481 9.34647L14.0848 8.32122C13.7702 8.2535 13.501 8.05142 13.3481 7.7682L11 3.41642Z" fill="#A3ACB5"/>-->
    <!--    </svg>-->
    <div
      v-else
      class="tw-flex tw-items-center"
    >
      <svg
        v-if="favoriteInfo"
        :width="iconSize"
        :height="iconSize"
        viewBox="0 0 10 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          class="group-enabled:group-hover:tw-fill-an-flat-red-main"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.815829 4.79829C0.293387 4.27121 0 3.55882 0 2.81607C0 2.0692 0.296474 1.3527 0.824578 0.824593C1.35268 0.296483 2.06918 0 2.81604 0C3.5629 0 4.27939 0.296483 4.80749 0.824593L5 1.0171L5.19251 0.824593C5.72061 0.296483 6.43658 0 7.18345 0C7.93082 0 8.6468 0.296483 9.1749 0.824593C9.70301 1.3527 10 2.0692 10 2.81607C10 3.55882 9.70661 4.27121 9.18365 4.79829L5.36339 8.84353C5.2692 8.94338 5.13743 9 5 9C4.86257 9 4.7308 8.94338 4.63661 8.84353L0.815829 4.79829Z"
          fill="#E63114"
        />
      </svg>
      <svg
        v-else
        :width="iconSize"
        :height="iconSize"
        viewBox="0 0 11 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          class="group-enabled:group-hover:tw-fill-an-flat-red-main"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.07559 5.65516C0.553152 5.12808 0.259766 4.41569 0.259766 3.67294C0.259766 2.92607 0.55624 2.20958 1.08434 1.68147C1.61245 1.15336 2.32894 0.856873 3.0758 0.856873C3.82266 0.856873 4.53916 1.15336 5.06726 1.68147L5.25976 1.87397L5.45227 1.68147C5.98038 1.15336 6.69635 0.856873 7.44321 0.856873C8.19059 0.856873 8.90657 1.15336 9.43467 1.68147C9.96277 2.20958 10.2598 2.92607 10.2598 3.67294C10.2598 4.41569 9.96638 5.12808 9.44342 5.65516L5.62316 9.7004C5.52897 9.80026 5.39719 9.85687 5.25976 9.85687C5.12233 9.85687 4.99057 9.80026 4.89637 9.7004L1.07559 5.65516ZM5.25976 8.62874L8.71766 4.96748L8.72796 4.95719C9.06819 4.61644 9.25967 4.15473 9.25967 3.67294C9.25967 3.19116 9.06819 2.72945 8.72796 2.3887C8.38721 2.04795 7.92499 1.85647 7.44321 1.85647C6.96195 1.85647 6.49973 2.04795 6.15898 2.3887L5.61338 2.93482C5.41779 3.12991 5.10123 3.12991 4.90615 2.93482L4.36003 2.3887C4.0198 2.04795 3.55758 1.85647 3.0758 1.85647C2.59402 1.85647 2.13232 2.04795 1.79157 2.3887C1.45083 2.72945 1.25987 3.19116 1.25987 3.67294C1.25987 4.15473 1.45083 4.61644 1.79157 4.95719C1.79518 4.96079 1.79826 4.96388 1.80135 4.96748L5.25976 8.62874Z"
          fill="#343A41"
        />
      </svg>
      <div
        v-if="isLabel"
        class="tw-ml-[4px]"
      >
        {{ favoriteInfo ? 'В избранном':'Добавить в избранное' }}
      </div>
    </div>
  </button>
</template>

<script>
import { defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'

export default defineComponent({
  name: 'NewBuildingSelectionFavoriteButton',
  components: { TwCircleLoader },
  props: {
    iconSize: {
      type: Number,
      default: 12
    },
    dealId: {
      type: Number,
      default: null
    },
    objectId: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isLabel: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    ...mapActions({
      fetchFavoriteList: 'favorite/dealObjects/fetchFavoriteList',
      addFavorite: 'favorite/dealObjects/addFavorite',
      deleteFavorite: 'favorite/dealObjects/deleteFavorite'
    }),
    onChangeFavorite () {
      if (this.favoriteInfo) {
        this.deleteFavorite({
          dealId: this.dealId,
          id: this.favoriteInfo.id
        })
      } else {
        this.addFavorite({
          dealId: this.dealId,
          objectId: this.objectId
        })
      }
    }
  },
  computed: {
    ...mapGetters({
      getFavoritesByDealId: 'favorite/dealObjects/getFavoritesByDealId'
    }),
    favoriteInfo () {
      return this.getFavoritesByDealId(this.dealId)?.list?.find(item => item.objectId === this.objectId) || null
    },
    isFavoriteProcessing () {
      return this.getFavoritesByDealId(this.dealId)?.processing
    }
  }
})
</script>

<style scoped>

</style>
