<template>
  <v-form ref="form">
    <div style="display: none">
      {{ isEdited }}
    </div>
    <div class="mt-2 mb-4 d-flex justify-center align-center">
      <strong>Прикреплённые файлы:</strong>
      <v-spacer />
      <PersonSelectDocumentFiles
        v-if="targetPersonIds.length"
        btn-text="Документы клиента"
        :value="form.requestFilesId"
        :disabled="formProcessing"
        :target-persons="targetPersonIds"
        @input="onChangePersonFileIds"
      />
    </div>
    <UploadFileList
      v-model="form.requestFilesId"
      no-data-text="Нет файлов"
      height="100px"
    />

    <TwCardSimpleFormSaveBlock
      class="tw-mt-[15px]"
      :loading="formProcessing"
      bg-color="transparent"
      :cancel-confirm="true"
      :is-sticky="false"
      :visible="true"
      @success="handleSubmit"
      @cancel="handleCancel"
    />
  </v-form>
</template>

<script>
import API from '@/Api/Crm/ExternalApi/dealProgram2Bank/dealProgramApi/index'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import PersonSelectDocumentFiles from '@/components/select/person/PersonSelectDocumentFiles'
import UploadFileList from '@/components/input/file/UploadFileList'
import TwCardSimpleFormSaveBlock from '@/components/tw-ui/forms/TwCardSimpleFormSaveBlock.vue'

export default {
  name: 'DealProgram2BankForm',
  components: { UploadFileList, PersonSelectDocumentFiles, TwCardSimpleFormSaveBlock },
  mixins: [FormCreateEditMixin],
  props: {
    targetPersonIds: {
      type: Array,
      required: true
    },
    dealProgramId: {
      type: [Number, String],
      required: true
    },
    denseFields: {
      type: Boolean,
      default: false
    },
    disableRedirect: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      API: API,
      form: {
        id: null,
        requestFilesId: []
      }
    }
  },
  watch: {
    dealProgramId () {
      this.fetchData()
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    onChangePersonFileIds (data) {
      console.warn('onChangePersonFileIds:', data)
      this.$set(this.form, 'requestFilesId', data || [])
    },
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          const adaperMap = {
            // from(show): to(send)
            dealProgram: 'dealProgramId',
            requestFiles: 'requestFilesId'
          }
          for (const key in this.preset) {
            if (adaperMap[key] !== undefined) {
              if (key === 'requestFiles') {
                this.$set(this.form, adaperMap[key], Array.isArray(this.preset[key]) ? this.preset[key].map(item => item.id) : [])
              } else {
                this.$set(this.form, adaperMap[key], this.preset[key]?.id || null)
              }
            } else {
              this.$set(this.form, key, this.preset[key])
            }
          }
          if (!this.form?.dealProgramId) {
            this.form.dealProgramId = this.dealProgramId
          }
        }
        resolve()
      })
    },
    handleSubmit () {
      console.warn(this.$options.name, ' handleSubmit ', this.formType, this.targetId, this.$refs.form.validate())
      if (this.$refs.form.validate()) {
        if (this.formProcessing === true) {
          return
        }

        this.formProcessing = true
        this.serverErrors = null
        if (this.formType === 'create') {
          this.API.store(this.form)
            .then(({ data }) => {
              console.warn(this.$options.name, ' CREATE ', data)
              // this.fetchData(data.data.id)
              this.$emit('create', data.data)
            })
            .catch((error) => {
              if (error?.response?.data) {
                console.error(this.$options.name, error.response.data)
                for (const key in error.response.data) {
                  const [refKey, index] = key.split('.')
                  // console.log(key, refKey, index, field)
                  if (!this.serverErrors) this.serverErrors = {}
                  if (!this.serverErrors[refKey]) this.serverErrors[refKey] = []
                  this.serverErrors[refKey][index] = error.response.data[key][0]
                }
              }
            })
            .finally(() => {
              this.formProcessing = false
            })
        } else if (this.formType === 'edit' && this.targetId) {
          this.API.update(this.dealProgramId, this.form)
            .then(({ data }) => {
              console.warn(this.$options.name, ' UPDATE ', data.data)
              this.$emit('update', data.data)
            })
            .catch((error) => {
              if (error?.response?.data) {
                console.error(this.$options.name, error.response.data)
                for (const key in error.response.data) {
                  const [refKey, index] = key.split('.')
                  // console.log(key, refKey, index, field)
                  if (!this.serverErrors) this.serverErrors = {}
                  if (!this.serverErrors[refKey]) this.serverErrors[refKey] = []
                  this.serverErrors[refKey][index] = error.response.data[key][0]
                }
              }
            })
            .finally(() => {
              this.formProcessing = false
            })
        }
      }
    },
    fetchData () {
      let targetId = this.dealProgramId
      targetId = targetId && targetId !== '0' ? targetId : null
      if (this.API) {
        if (this.formType === 'edit' && targetId) {
          this.formProcessing = true
          this.API.show(targetId)
            .then((response) => {
              console.error(this.$options.name, response)
              this.permissions = response?.data?.permissions || {}
              this.dictionary = response?.data?.dictionary || {}
              this.preset = response?.data?.data || {}
              this.presetData()?.finally(() => {
                this.copyOriginalForm()
              })
            })
            .finally(() => {
              this.formProcessing = false
            })
        } else if (this.formType === 'create') {
          this.formProcessing = true
          this.API.create(this.dealProgramId)
            .then((response) => {
              console.error(this.$options.name, response)
              this.permissions = response?.data?.permissions || {}
              this.dictionary = response?.data?.dictionary
              this.preset = response?.data?.data
              this.presetData()?.finally(this.copyOriginalForm)
            })
            .finally(() => {
              this.formProcessing = false
            })
        } else {
          console.error('undefined action: ', this.$options.name, this.$route)
        }
      } else {
        alert(`Error variable API: ${this.API}, in ${this.$options.name}`)
      }
    }
  }
}
</script>
