<template>
  <TwPanelContentBlock class="tw-my-[6px]">
    <template #title>
      <div>Инвест. привлекательность</div>
    </template>
    <template #action>
      <TwPanelContentBlockActionButton
        :type="itemId ? 'edit':'add'"
        @click.prevent="isShowForm = !isShowForm"
      >
        {{ isShowForm ? 'отменить' : itemId ? 'изменить' : 'добавить' }}
      </TwPanelContentBlockActionButton>
    </template>
    <template>
      <RealtyComplexInvestAppealForm
        v-if="isShowForm"
        :id="itemId || 0"
        :complex-id="complexId"
        @create="isShowForm = false"
        @update="isShowForm = false"
        @cancel="isShowForm = false"
      />
      <RealtyComplexInvestAppealShow
        v-else
        :complex-id="complexId"
        @changeItemId="itemId = $event"
      />
    </template>
  </TwPanelContentBlock>
</template>

<script>
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwPanelContentBlockActionButton from '@/components/tw-ui/panels/TwPanelContentBlockActionButton.vue'
import RealtyComplexInvestAppealShow from './RealtyComplexInvestAppealShow.vue'
import RealtyComplexInvestAppealForm from './RealtyComplexInvestAppealForm.vue'

export default {
  name: 'RealtyComplexInvestAppeal',
  components: {
    RealtyComplexInvestAppealShow,
    RealtyComplexInvestAppealForm,
    TwPanelContentBlock,
    TwPanelContentBlockActionButton
  },
  props: {
    complexId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      isShowForm: false,
      itemId: null
    }
  }
}
</script>
