<template>
  <div
    :class="wrapperClasses"
    class="tw-text-main-sm2"
  >
    <slot name="default" />
  </div>
</template>

<script>
export default {
  name: 'TwPanelContent',
  props: {
    wrapperClasses: {
      type: [String, Array],
      default: 'tw-flex tw-flex-wrap tw-gap-[8px]'
    }
  }
}
</script>
