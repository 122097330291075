<template>
  <div style="display: flex; flex-direction: column; justify-content: center">
    <TwCircleLoader
      v-if="process"
      width="15px"
      height="15px"
      border-width="2px"
    />
    <span
      v-else-if="!pinStatus"
      class="icon"
      title="Закрепить"
      @click.prevent="change"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="14"
        width="15"
        fill="#a7adb4"
        viewBox="0 0 400 399"
      >
        <path d="M203.288 398.425c-1.59.672-5.954-.97-7.97-2.998-1.418-1.425-2.212-5.82-3.85-21.305-4.05-38.29-4.86-57.147-4.901-114.073l-.031-43.074-40.156-.104c-22.085-.058-41.182-.534-42.438-1.058-5.435-2.269-6.19-10.3-2.099-22.296 2.23-6.536 2.295-6.63 9.703-13.974 8.41-8.336 13.086-10.95 21.583-12.067l4.53-.596 4.856-60.823c2.67-33.452 4.87-62.313 4.886-64.134l.03-3.31-5.075-2.522c-9.74-4.839-16.503-16.035-16.046-26.559.202-4.643.458-5.26 2.966-7.165 2.583-1.96 6.8-2.07 71.759-1.877l69.014.206 2.497 2.511c2.136 2.149 2.495 3.211 2.483 7.365-.032 11.7-8.527 23.329-20.475 28.03-1.01.397-.18 16.11 3.388 64.217 2.598 35.03 4.897 63.865 5.107 64.077.21.212 1.896.593 3.746.847 18.134 2.492 33.164 19.388 33.897 38.107.218 5.57.041 6.189-2.496 8.712l-2.73 2.715-39.017-.101-39.017-.102-.293 49.275c-.32 53.733-1.02 69.072-4.726 103.47-2.409 22.365-2.5 22.806-5.26 25.459-1.545 1.485-3.284 2.9-3.865 3.146z" /></svg></span>
    <span
      v-else-if="pinStatus"
      class="icon"
      title="Открепить"
      @click.prevent="change"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="14"
        width="15"
        fill="#a7adb4"
        viewBox="0 0 400 399"
      >
        <path d="M203.288 398.425c-1.59.672-5.954-.97-7.97-2.998-1.418-1.425-2.212-5.82-3.85-21.305-4.05-38.29-4.86-57.147-4.901-114.073l-.031-43.074-40.156-.104c-22.085-.058-41.182-.534-42.438-1.058-5.435-2.269-6.19-10.3-2.099-22.296 2.23-6.536 2.295-6.63 9.703-13.974 8.41-8.336 13.086-10.95 21.583-12.067l4.53-.596 4.856-60.823c2.67-33.452 4.87-62.313 4.886-64.134l.03-3.31-5.075-2.522c-9.74-4.839-16.503-16.035-16.046-26.559.202-4.643.458-5.26 2.966-7.165 2.583-1.96 6.8-2.07 71.759-1.877l69.014.206 2.497 2.511c2.136 2.149 2.495 3.211 2.483 7.365-.032 11.7-8.527 23.329-20.475 28.03-1.01.397-.18 16.11 3.388 64.217 2.598 35.03 4.897 63.865 5.107 64.077.21.212 1.896.593 3.746.847 18.134 2.492 33.164 19.388 33.897 38.107.218 5.57.041 6.189-2.496 8.712l-2.73 2.715-39.017-.101-39.017-.102-.293 49.275c-.32 53.733-1.02 69.072-4.726 103.47-2.409 22.365-2.5 22.806-5.26 25.459-1.545 1.485-3.284 2.9-3.865 3.146z" /></svg></span>
  </div>
</template>

<script>
import API from '@/Api/Crm/Chronology/index'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'

export default {
  name: 'ChronologyPinned',
  components: { TwCircleLoader },
  props: {
    id: {
      type: Number,
      required: true
    },
    pinStatus: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      process: false
    }
  },
  methods: {
    change () {
      if (this.process) return
      this.process = true
      API.update(this.id, {
        pin: !this.pinStatus
      }).then(({ data }) => {
        console.log(this.$options.name, 'UPDATE', data)
        this.$emit('refresh')
      }).catch((error) => {
        console.error(this.$options.name, 'UPDATE', error)
      }).finally(() => {
        this.process = false
      })
    }
  }
}
</script>
