<template>
  <div v-if="item">
    <div
      v-if="!item.value"
      class="tw-text-an-flat-disabled-text"
    >
      -
    </div>
    <!--  ENUM  -->
    <div v-else-if="info.type === 'enum'">
      {{ item.label || item.value || '-' }}
    </div>
    <!--  ПАНЕЛИ  -->
    <div v-else-if="info.type === 'entity'">
      <ViewPanelLink
        v-if="info.entity === 'person'"
        :id="item.value"
        component="person-show"
      >
        <template v-if="item.label">
          {{ item.label }}
        </template>
        <PersonName
          v-else
          :id="item.value"
          format="fio"
        />
      </ViewPanelLink>
      <ViewPanelLink
        v-else-if="info.entity === 'complex'"
        :id="item.value"
        component="realty-complex-show"
      >
        <template v-if="item.label">
          {{ item.label }}
        </template>
        <RealtyComplexName
          v-else
          :id="item.value"
        />
      </ViewPanelLink>
      <ViewPanelLink
        v-else-if="info.entity === 'user'"
        :id="item.value"
        component="employee-show"
      >
        <template v-if="item.label">
          {{ item.label }}
        </template>
        <EmployeeName
          v-else
          :id="item.value"
        />
      </ViewPanelLink>
      <ViewPanelLink
        v-else-if="info.entity === 'deal'"
        :id="item.value"
        component="deal-show"
      >
        {{ item.label || item.value || '-' }}
      </ViewPanelLink>
      <ViewPanelLink
        v-else-if="info.entity === 'company' || info.entity === 'companyBank' || info.entity === 'companyDeveloper' || info.entity === 'developer'"
        :id="item.value"
        component="company-show"
      >
        <template v-if="item.label">
          {{ item.label }}
        </template>
        <TwCompanyName
          v-else
          :id="item.value"
        />
      </ViewPanelLink>
      <!--  ИСКЛЮЧЕНИЕ  -->
      <div
        v-else
        class="tw-text-an-flat-red-main"
      >
        <code>entity {{ info.entity }} - не описан!</code>
      </div>
    </div>
    <!--  ИСКЛЮЧЕНИЕ  -->
    <div
      v-else
      class="tw-text-an-flat-red-main"
    >
      <code>entity - не описан! Дописать тут '<TwTableBaseDefaultEntityView />'</code>
      <code>{{ info }}</code>
    </div>
  </div>
</template>

<script>

import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink.vue'
import EmployeeName from '@/components/other/employee/EmployeeName.vue'
import PersonName from '@/components/other/person/PersonName.vue'
import TwCompanyName from '@/components/tw-ui/other/TwCompanyName.vue'
import RealtyComplexName from '@/components/other/complex/RealtyComplexName.vue'

export default {
  name: 'TwTableBaseDefaultEntityView',
  components: { RealtyComplexName, TwCompanyName, PersonName, EmployeeName, ViewPanelLink },
  props: {
    item: {
      type: Object,
      default: null
    },
    info: {
      type: Object,
      default: null
    }
  }
}
</script>

<style scoped>

</style>
