import { SERVICES } from '@/Api/Services'

import Task from './Task'
import File from './File'

export const AuthAxiosDefaultConfig: any = {
  serviceKey: SERVICES.SUPPORT.key
}

export const API: any = {
  Task,
  File
}
