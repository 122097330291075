/* eslint-disable no-console */
import axios, { AxiosResponse } from 'axios'
import qs from 'qs'
import store from '@/store'
import LoggerErrorHandler from '@/plugins/helpers/ErrorHandler'
import { SERVICES } from '@/Api/Services'
import { ResponceErrorMessageGenerator } from '@/Api/ResponceErrorMessageGenerator'
console.log('process.env', process.env)
console.log('SERVICES', SERVICES)

const DEPLOYMENT_ENV = process.env && process.env.VUE_APP_DEPLOYMENT_ENV ? process.env.VUE_APP_DEPLOYMENT_ENV : 'local'
const DEPLOYMENT_VERSION = process.env && (process.env.VUE_APP_COMMIT_TAG || process.env.VUE_APP_COMMIT_SHORT_SHA || 'dev')
// VUE_APP_COMMIT_SHORT_SHA: "a5991518"
// VUE_APP_COMMIT_TAG: ""
store.commit('SET_DEPLOYMENT_TYPE', DEPLOYMENT_ENV)
store.commit('SET_DEPLOYMENT_VERSION', DEPLOYMENT_VERSION)
const DEV_SERVER: boolean = (process.env.NODE_ENV !== 'production')
const BACKEND_HOST = process.env.VUE_APP_BACKEND_HOST
const AUTH_HOST = process.env.VUE_APP_AUTH_HOST
const API_URL = `${BACKEND_HOST}/api/v1/`
const AUTH_API_URL = `${AUTH_HOST}/api/v1/`
store.commit('SET_API_URL', API_URL)

if (DEV_SERVER) {
  console.warn('API URL: ', API_URL)
  console.warn('AUTH_API_URL: ', AUTH_API_URL)
}

// Этот метод можно экспортнуть и использовать инстанс аксиоса для генерации ссылки на сервисы buildInitialHttp($config).defaults.baseURL
export function buildInitialHttp (config: any = {}) {
  const createConfig = {
    ...{
      ...{ serviceKey: config?.serviceKey || SERVICES.BACKEND.key }, // Ключ для определения url отправки запроса
      baseURL: SERVICES[config?.serviceKey || SERVICES.BACKEND.key]?.apiUrl,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Commit-Tag': DEPLOYMENT_VERSION || 'none',
        'Content-Type': 'application/json',
        // 'Accept': 'application/json',
        'Cache-Control': 'no-cache'
      },
      validateStatus (status: number) {
        return status < 400 // Reject only if the status code is greater than or equal to 500
      }
    },
    ...config
  }
  return axios.create(createConfig)
}
const HTTP = buildInitialHttp()

HTTP.defaults.paramsSerializer = function (params) {
  // console.warn('paramsSerializer', params)
  // (e.g. https://www.npmjs.com/package/qs, http://api.jquery.com/jquery.param/)
  return qs.stringify(params, {
    arrayFormat: 'brackets'
  })
}

HTTP.defaults.timeout = 35000
HTTP.defaults.withCredentials = true

HTTP.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8'
HTTP.defaults.headers.put['Content-Type'] = 'application/json;charset=utf-8'

/* ОБРАБОТКА ЗАПРОСА */
HTTP.interceptors.request.use(
  (config: any) => {
    /* ХОСТ ДЛЯ ЗАПРОСА API_URL || AUTH_API_URL */
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    config.baseURL = SERVICES[config.serviceKey]?.apiUrl
    if (!config.baseURL) throw new Error('Не удалось определить URL для запроса!')

    /* ФОРМИРОВАНИЕ ЗАПРОСА */
    const token = store.getters['profile/getToken']
    // subToken используется админом от лица какого-то пользователя
    const subToken = store.getters['profile/getSubAuthToken']
    // доп проверка по config.url - чтобы не перезаписывался профиль при reload
    if (subToken && config.url !== '/profile') {
      config.headers.Authorization = `Bearer ${subToken}`
    } else if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }

    /* DEV DEBUG */
    if (DEV_SERVER) {
      // console.log('AXIOS CONFIG ', config)
      // console.log('http set token', token)
      // console.log(config.method?.toUpperCase(), config.url)
      if (config.params && Object.keys(config.params).length) {
        console.log('params: ', config.params)
      }
      if (config.data) {
        console.log('params: ', config.data)
      }
    }

    /* PARAMS PROCESSING */
    if (config.method === 'delete') {
      const formData = new FormData()
      formData.append('_method', 'DELETE')
      config.data = formData
    }
    return config
  },
  (error) => {
    /* ОШИБКА СОЗДАНИЯ ЗАПРОСА */
    return Promise.reject(error)
  })

/* ОБРАБОТКА ОТВЕТА */
HTTP.interceptors.response.use(
  (response: AxiosResponse) => {
    /* УСПЕШНЫЙ ОТВЕТ */
    return response
  },
  (error: any) => {
    /* ОТВЕТ С ОШИБКОЙ */
    error.serverErrorMessage = ResponceErrorMessageGenerator(error)
    // Если прервано\отменено вручную
    if (error.code === 'ERR_CANCELED') {
      return window.Promise.reject('ERR_CANCELED')
    }
    // Если прервано\отменено сервером по таймауту
    if (error.code === 'ECONNABORTED') {
      return window.Promise.reject('ECONNABORTED')
    }
    if (DEV_SERVER) {
      // console.error('http error', error)
      store.commit('notify/ADD', {
        desc: error?.response?.data ? JSON.stringify(error.response.data) : error.toString(),
        title: 'Ошибка HTTP запроса',
        type: 'danger',
        timeout: 7000,
        visible: true
      })
    }

    const { status, data } = error?.response || {}

    if (DEV_SERVER) {
      console.error('http interceptors err response', error.response)
    }

    // eslint-disable-next-line
    let message = error.serverErrorMessage || ''
    switch (status) {
      case 400:
        if (data.error === 'token_not_provided') {
          // store.commit('SET_GUEST');
        }
        message = error.serverErrorMessage || 'Ошибка авторизации, ключ не верный'
        store.commit('notify/ADD', {
          title: message,
          type: 'error',
          timeout: 10000,
          visible: true
        })
        break
      case 401:
        // Двухфакторка - действия при неверном вводе кода подтверждения двухфакторной аутентификации - игнор 401
        if (error?.response?.config?.url?.indexOf('twofactor') !== -1) {
          console.warn(error?.response)
          break
        }
        // Получение конфигурации фильтров - игнор 401
        if (error?.response?.config?.url?.indexOf('filters') !== -1) {
          // console.warn(error?.response)
          break
        }
        // Отчеты - игнор 401
        if (error?.response?.config?.url?.indexOf('report') !== -1) {
          // console.warn(error?.response)
          break
        }
        // Во всех иных случаях выхожу из системы и чищу хранилище
        store.dispatch('profile/resetAuth').then(() => {
          message = 'Ошибка авторизации. Пожалуйста войдите в систему.'
          store.commit('notify/ADD', {
            title: message,
            type: 'danger',
            timeout: 7000,
            visible: true
          })
        })
        break
      case 403:
        store.commit('notify/ADD', {
          title: 'Доступ запрещён!',
          type: 'danger',
          timeout: 7000,
          visible: true
        })
        break
      case 404:
        message = 'API роут не существует'
        break
      case 422:
        store.commit('notify/ADD', {
          title: 'Ошибка валидации',
          desc: message,
          type: 'danger',
          timeout: 15000,
          visible: true
        })
        break
      case 423:
        store.commit('notify/ADD', {
          desc: message,
          type: 'danger',
          timeout: 7000,
          visible: true
        })
        break
      case 424:
        store.commit('notify/ADD', {
          desc: message,
          type: 'danger',
          timeout: 7000,
          visible: true
        })
        break
      case 500:
        message = 'Внутренняя ошибка сервера. Обратитесь к администратору.'
        store.commit('notify/ADD', {
          title: 'Внутренняя ошибка сервера',
          desc: 'Обновите страницу. В случае повторения - обратитесь к администратору.',
          type: 'danger',
          timeout: 30000,
          visible: true
        })
        // логгирование 500 ошибки
        LoggerErrorHandler.sendLog({
          level: 500,
          section: 'crm3/front/axios/request',
          channel: DEV_SERVER ? 'DEV' : 'PROD',
          message: message,
          context: JSON.stringify(error || {})
        })
        break
      default:
        break
    }

    // // console.warn('http error', status, message);

    // отдаем ошибку дальше
    return window.Promise.reject(error)
  })

export default HTTP

// Скачивание по средствам JS - Axios
const downloadFile = (url: string, fileName?: string) => {
  console.log('GLOBAL HTTP downloadFile', url)
  const link = document.createElement('a')
  link.href = url
  link.download = fileName || ''
  link.setAttribute('target', '_blank')
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export {
  downloadFile
}
