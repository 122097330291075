<template>
  <TwPanelContent>
    <SurveyAnketaForm
      v-if="isEdit"
      :create-params="{ surveyId }"
      @changeEdit="isEdit = false"
      @updated="$emit('refresh')"
    />
    <SurveyAnketaShow
      v-else
      :survey-id="surveyId"
      :is-finished="info.isFinishedForm"
      @changeEdit="isEdit = true"
    />
  </TwPanelContent>
</template>

<script>
import TwPanelContent from '@/components/tw-ui/panels/TwPanelContent.vue'
import SurveyAnketaShow from '@/views/Company/UserSurveys/Anketa/SurveyAnketaShow.vue'
import SurveyAnketaForm from '@/views/Company/UserSurveys/Anketa/SurveyAnketaForm.vue'

export default {
  name: 'SurveyAnketaBlock',
  components: {
    SurveyAnketaForm,
    SurveyAnketaShow,
    TwPanelContent
  },
  props: {
    info: {
      required: true
    }
  },
  data () {
    return {
      isEdit: false,
      formProcessing: false,
      loading: false,
      error: null
    }
  },
  computed: {
    surveyId () {
      return this.info?.id || null
    }
  }
}
</script>

<style scoped>

</style>
