<template>
  <TwPanelContentBlock
    class="tw-mb-[6px]"
    :content-classes="''"
  >
    <template #title>
      <div class="tw-w-full tw-grid tw-grid-cols-3 tw-gap-[20px] tw-items-center">
        <div>
          Файлы
        </div>
      </div>
    </template>
    <div>
      <div v-if="deal && deal.files">
        <table>
          <thead>
            <tr>
              <th class="text-left">
                Превью
              </th>
              <th class="text-left">
                Информация
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(file, fIndex) in deal.files"
              :key="file.id"
            >
              <td>
                <img
                  v-if="file.mime.includes('image')"
                  class="cursor-pointer white lighten-2"
                  style="border: 1px solid lightgrey; width: 50px; height: 50px"
                  :width="50"
                  :height="50"
                  :src="getFileResizeByKey(file, 'default')"
                  aspect-ratio="1"
                  @click="imageShow(fIndex)"
                >
                <div v-else>
                  mime not [image]
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        v-else
        class="tw-text-center tw-text-an-flat-disabled-text"
      >
        Нет файлов
      </div>
    </div>
  </TwPanelContentBlock>
</template>

<script>
import { getFileResizeByKey } from '@/plugins/helpers/fileHelpers'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'

export default {
  name: 'DealFilesTab',
  components: { TwPanelContentBlock },
  props: {
    deal: {
      required: true
    },
    dictionary: {
      type: Object,
      default: () => {
        return {}
      }
    },
    permissions: {
      type: Object,
      default: () => {
        return {}
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    isNew: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    filesImages () {
      return this.deal?.files
        .filter(f => f.mime.indexOf('image') !== -1)
        .map(f => {
          return {
            path: f.mime.indexOf('image') !== -1 ? getFileResizeByKey(f, 'full') : '/img/file-not-image.png',
            preview: f.mime.indexOf('image') !== -1 ? getFileResizeByKey(f, 'default') : '/img/file-not-image.png',
            name: f.name,
            size: f.size
          }
        })
    }
  },
  methods: {
    getFileResizeByKey: getFileResizeByKey,
    imageShow (index) {
      this.$store.dispatch('imageViewer/showImages', {
        files: this.filesImages,
        startIndex: index
      })
    }
  }
}
</script>

<style scoped>

</style>
