export function preparePropertiesForForm (propObject: any = {}) {
  const result: any = {}
  // Преобразую свойства для формы
  if (Object.keys(propObject)) {
    for (const fieldKey in propObject) {
      if (fieldKey === 'mapPolygon') {
        result[fieldKey] = Array.isArray(propObject[fieldKey]) ? propObject[fieldKey] : []
      } else if (Array.isArray(propObject[fieldKey])) {
        // для ENUM multi нужно выбрать МАССИВ только значения
        result[fieldKey] = propObject[fieldKey].map((v: any) => v.value) || []
      } else if (propObject[fieldKey]?.value) {
        // для ENUM simple нужно выбрать только значения
        result[fieldKey] = propObject[fieldKey]?.value || null
      } else {
        // для остальных типов оставляю как есть
        result[fieldKey] = propObject[fieldKey]
      }
    }
  }
  return result
}
