import { AxiosResponse } from 'axios'
import HTTP from '@/Api/http'
const path = '/person/relations'

export default {
  add (data: object): Promise<AxiosResponse> {
    const $config = {
      params: data
    }
    return HTTP.put(path, $config)
  },
  delete (data: object): Promise<AxiosResponse> {
    const $config = {
      params: data
    }
    return HTTP.delete(path, $config)
  }
}
