<template>
  <div class="tw-font-manrope tw-text-main-sm2 tw-max-w-[100%] tw-px-[26px] tw-py-[18px] tw-bg-white tw-min-h-screen">
    <div
      v-if="loading && !data"
      class="tw-py-3 tw-w-full tw-min-h-screen tw-flex tw-justify-center tw-items-center"
    >
      <TwCircleLoader
        width="50px"
        height="50px"
      />
    </div>
    <template v-else-if="data || isNew">
      <TwPanelTitle>
        <template #left>
          <div class="tw-text-h4 tw-font-bold">
            Проверка Контур
            <span
              v-if="isNew"
              class="tw-text-an-flat-disabled-text tw-font-medium"
            >Новая</span>
            <span
              v-else-if="data && data.id"
              class="tw-text-an-flat-disabled-text tw-font-medium"
            ># {{ data.id }}</span>
          </div>
        </template>
        <template #right>
          <TwPanelActionButton
            v-if="data && data.ready"
            color="blue"
            :disabled="loading"
            @click.prevent="downloadFullReports"
          >
            Скачать все отчёты
          </TwPanelActionButton>
        </template>
      </TwPanelTitle>
      <TwPanelTabs>
        <button class="tw-bg-white tw-rounded-sm tw-px-[14px] tw-py-[8px]">
          Общая информация
        </button>
      </TwPanelTabs>
      <TwPanelContent>
        <div
          v-if="data"
          class="tw-w-full"
        >
          <TwPanelInfoBlock
            v-if="data.state"
            :type="data.state.code"
          >
            <template #title>
              {{ data.state.label }}
            </template>
            <template #text>
              <div v-if="data.state.desc">
                {{ data.state.desc }}
              </div>
            </template>
          </TwPanelInfoBlock>
        </div>
        <div class="tw-flex-1">
          <TwPanelContentBlock class="tw-mb-[6px]">
            <template #title>
              <div>
                {{ isNew ? 'Информация для проверки':'Введённая информация' }}
              </div>
            </template>
            <div>
              <DocCheckKonturForm
                v-if="editMain"
                :id="data ? data.id : 0"
                :type="type"
                @create="onCreated"
                @update="onUpdated"
                @cancel="onCancelAndClose"
              />
              <DocCheckKonturShow
                v-else
                :dictionary="dictionary"
                :info="data"
                @refresh="refresh"
              />
            </div>
          </TwPanelContentBlock>
          <TwPanelContentBlock
            v-if="analiseInfo"
            class="tw-mb-[6px]"
          >
            <template #title>
              <div>
                Анализ
              </div>
            </template>
            <div
              v-if="analiseInfo"
              class="tw-flex tw-flex-col tw-gap-[12px]"
            >
              <TwPanelContentBlockFieldView no-data-text="Нет данных">
                <template #title>
                  Риски
                </template>
                <div
                  v-if="Array.isArray(analiseInfo.risks) && analiseInfo.risks.length"
                  class="tw-flex tw-flex-col tw-gap-[6px] tw-text-an-flat-red-main"
                >
                  <div
                    v-for="(risk, rIndex) in analiseInfo.risks"
                    :key="rIndex"
                  >
                    <code class="tw-block !tw-px-[10px] !tw-py-[3px]">{{ risk }}</code>
                  </div>
                </div>
                <div
                  v-else
                  class="tw-text-an-flat-disabled-text"
                >
                  Нет рисков
                </div>
              </TwPanelContentBlockFieldView>
              <TwPanelContentBlockFieldView no-data-text="Нет данных">
                <template #title>
                  Особенности
                </template>
                <div
                  v-if="Array.isArray(analiseInfo.features) && analiseInfo.features.length"
                  class="tw-flex tw-flex-col tw-gap-[6px] tw-text-an-flat-orange-warning"
                >
                  <div
                    v-for="(feature, fIndex) in analiseInfo.features"
                    :key="fIndex"
                  >
                    <code class="tw-block !tw-px-[10px] !tw-py-[3px]">{{ feature }}</code>
                  </div>
                </div>
                <div
                  v-else
                  class="tw-text-an-flat-disabled-text"
                >
                  Нет особенностей
                </div>
              </TwPanelContentBlockFieldView>
              <TwPanelContentBlockFieldView no-data-text="Отсутствует">
                <template #title>
                  Отчёт
                </template>
                <TwPanelContentBlockActionButton
                  v-if="analiseInfo.reportId"
                  :disabled="isNew || loading"
                  class="tw-text-main-xs enabled:tw-text-an-flat-dark-blue-link"
                  @click="downloadCheckReport(analiseInfo.reportId)"
                >
                  Скачать
                </TwPanelContentBlockActionButton>
              </TwPanelContentBlockFieldView>
              <TwPanelContentBlockFieldView v-if="analiseInfo.createWarning">
                <template #title>
                  Предупреждение
                </template>
                <div class="tw-flex tw-flex-col tw-gap-[6px] tw-text-an-flat-orange-warning">
                  {{ analiseInfo.createWarning || 'asdasd asd asdasd' }}
                </div>
              </TwPanelContentBlockFieldView>
            </div>
            <div
              v-else
              class="tw-text-center tw-text-an-flat-disabled-text"
            >
              Отсутствует
            </div>
          </TwPanelContentBlock>
        </div>
        <div class="tw-flex-1">
          <template v-if="data && Array.isArray(data.checks) && data.checks.length">
            <TwPanelContentBlock
              v-for="check in data.checks"
              :key="check.id"
              class="tw-mb-[6px]"
            >
              <template #title>
                <div>
                  {{ check.type ? check.type.label : '-' }}
                </div>
              </template>
              <div class="tw-flex tw-gap-[12px] tw-flex-col">
                <TwPanelContentBlockFieldView no-data-text="Нет">
                  <template #title>
                    Отправлено
                  </template>
                  <div v-if="check.sent">
                    {{ check.sentDate ? $Global.DateFormat.DateTimeShort(check.sentDate) : 'Да' }}
                  </div>
                </TwPanelContentBlockFieldView>
                <TwPanelContentBlockFieldView no-data-text="Нет">
                  <template #title>
                    Обработано
                  </template>
                  <div v-if="check.ready">
                    {{ check.readyDate ? $Global.DateFormat.DateTimeShort(check.readyDate) : 'Да' }}
                  </div>
                </TwPanelContentBlockFieldView>
                <TwPanelContentBlockFieldView no-data-text="Нет">
                  <template #title>
                    Совпадения
                  </template>
                  <div
                    v-if="check.matches"
                    class="tw-flex tw-items-center tw-gap-[6px]"
                  >
                    <div class="tw-bg-an-flat-orange-warning tw-w-[10px] tw-h-[10px] tw-rounded-full tw-animate-pulse" />
                    <span>Есть</span>
                    <TwBadgeLabel
                      v-if="check.matchesCount"
                      :label="check.matchesCount"
                      bordered
                      rounded="full"
                      size="xs"
                      color="orange"
                      title="todo title"
                    />
                  </div>
                </TwPanelContentBlockFieldView>
                <TwPanelContentBlockFieldView no-data-text="Отсутствует">
                  <template #title>
                    Скрин проверки
                  </template>
                  <TwPanelContentBlockActionButton
                    v-if="check.screenshotId"
                    :disabled="isNew || loading"
                    class="tw-text-main-xs enabled:hover:tw-text-an-flat-dark-blue-link"
                    @click="downloadCheckReport(check.screenshotId)"
                  >
                    Скачать
                  </TwPanelContentBlockActionButton>
                </TwPanelContentBlockFieldView>
                <TwPanelContentBlockFieldView
                  v-if="check"
                  :horizontal="false"
                  no-data-text="Отсутствует"
                >
                  <template #title>
                    Информация по проверке
                  </template>
                  <div
                    v-if="check.checkInfo"
                    class="tw-break-all"
                  >
                    <ReadMoreExpander
                      align="right"
                      :is-nl-trigger="true"
                      :text="check.checkInfo"
                      :is-clean-html="false"
                      :is-url2link="true"
                      :less-length="250"
                    />
                  </div>
                </TwPanelContentBlockFieldView>
                <TwPanelContentBlockFieldView v-if="check.error">
                  <template #title>
                    Ошибка
                  </template>
                  <div class="tw-text-an-flat-red-main">
                    {{ check.error }}
                  </div>
                </TwPanelContentBlockFieldView>
              </div>
            </TwPanelContentBlock>
          </template>
        </div>
      </TwPanelContent>
      <div v-if="isAdmin">
        <TwSpoiler
          title-classes="tw-mb-[6px] tw-px-[15px] tw-py-[8px] tw-font-medium tw-bg-an-flat-gray-light tw-text-main-sm tw-w-full"
          content-classes="tw-w-full"
          title-variant="block"
        >
          <template #title>
            DEBUG isAdmin
          </template>
          <div class="">
            <JsonPretty :json="data" />
          </div>
        </TwSpoiler>
      </div>
    </template>
  </div>
</template>

<script>
import { SOCKET_EVENT_MAP } from '@/plugins/SocketIoConfig'
import API from '@/Api/DocCheck/Kontur/Check'
import konturDocumentAPI from '@/Api/DocCheck/Kontur/Document'
import { mapGetters } from 'vuex'
import CardMixin from '@/mixins/CardMixin'
import TwPanelTitle from '@/components/tw-ui/panels/TwPanelTitle.vue'
import TwPanelTabs from '@/components/tw-ui/panels/TwPanelTabs.vue'
import TwPanelContent from '@/components/tw-ui/panels/TwPanelContent.vue'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import TwPanelActionButton from '@/components/tw-ui/panels/TwPanelActionButton.vue'
import TwPanelContentBlockActionButton from '@/components/tw-ui/panels/TwPanelContentBlockActionButton.vue'
import TwPanelInfoBlock from '@/components/tw-ui/panels/TwPanelInfoBlock.vue'
import DocCheckKonturForm from './main/DocCheckKonturForm.vue'
import DocCheckKonturShow from './main/DocCheckKonturShow.vue'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'
import TwBadgeLabel from '@/components/tw-ui/ui-kit/views/TwBadgeLabel.vue'
import ReadMoreExpander from '@/components/other/text/ReadMoreExpander.vue'
import JsonPretty from '@/components/development/JsonPretty.vue'
import TwSpoiler from '@/components/tw-ui/other/TwSpoiler.vue'

export default {
  name: 'DocCheckKonturCard',
  components: {
    TwPanelInfoBlock,
    TwPanelContentBlockFieldView,
    TwPanelContentBlockActionButton,
    DocCheckKonturShow,
    DocCheckKonturForm,
    TwPanelActionButton,
    TwCircleLoader,
    TwPanelContentBlock,
    TwPanelContent,
    TwPanelTabs,
    TwBadgeLabel,
    ReadMoreExpander,
    JsonPretty,
    TwSpoiler,
    TwPanelTitle
  },
  mixins: [CardMixin],
  props: {
    type: {
      type: String,
      default: 'person'
    }
  },
  data () {
    return {
      API,
      refreshSocketEvents: [
        SOCKET_EVENT_MAP.IN.konturCheckAnaliseReady,
        SOCKET_EVENT_MAP.IN.konturCheckReady,
        SOCKET_EVENT_MAP.IN.konturCheckSingleReady,
        SOCKET_EVENT_MAP.IN.konturCheckSingleSent
      ]
    }
  },
  methods: {
    downloadFullReports () {
      API.downloadFiles(this.data.id)
    },
    downloadCheckReport (screenshotId) {
      konturDocumentAPI.downloadDocument(screenshotId)
    },
    socketHandler (event) {
      if (this.data?.id === event?.checkGroupId) {
        this.refresh()
      }
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'profile/isAdmin'
    }),
    analiseInfo () {
      return this.data?.analise || null
    }
  },
  mounted () {
    // При открытии вешаю слушателей на события контура
    this.refreshSocketEvents.forEach((eventCode) => {
      this.sockets.subscribe(eventCode, this.socketHandler)
      // При закрытии убиваю слушателей
      this.$on('hook:beforeDestroy', () => {
        this.sockets.unsubscribe(eventCode)
      })
    })
  }
}
</script>
