<template>
  <div
    :id="mapUid"
    v-resize="onResize"
    :style="rootStyles"
  />
</template>

<script lang="js">
const mapConfig = {
  latitudeInit: 55.78969,
  longitudeInit: 49.13699,
  locationRadiusInit: 1000,
  mapZoom: 16,
  mapControls: ['zoomControl'],
  polygonStyles: {
    fillColor: 'rgba(255, 0, 0, 0.1)',
    strokeColor: 'rgba(255, 0, 0, 1)',
    strokeWidth: 3
  }
}

export default {
  name: 'YandexMapRealtyObject',
  props: {
    coords: {
      type: Object,
      default: () => {
        return {
          lat: mapConfig.latitudeInit,
          lon: mapConfig.longitudeInit
        }
      }
    },
    polyCoords: {
      type: Array,
      default: () => {
        return []
      }
    },
    drawMode: { // Режим редактирования - 'POINT' (точка на карте) || 'POLYGON' (Обводка полигоном)
      type: String,
      default: 'POINT'
    },
    viewOnly: { // Только просмотр или редактирвоание
      type: Boolean,
      default: true
    },
    isPresetDefaultCoords: {
      type: Boolean,
      default: true
    },
    mapZoom: {
      type: Number,
      default: mapConfig.mapZoom
    }
  },
  data () {
    return {
      rootStyles: {},
      map: null,
      collectionPolygons: null,
      collectionMarkers: null,
      targetEditPolygon: null
    }
  },
  computed: {
    mapUid () {
      return `map-${this._uid}`
    }
  },
  watch: {
    coords () {
      this.initValues(true)
    },
    polyCoords: {
      handler () {
        this.initValues(true)
      }
    }
  },
  mounted () {
    this.map = null
    this.$nextTick(() => {
      this.onInit()
    })
  },
  beforeDestroy () {
    if (this.map) {
      this.map.destroy()
    }
    this.map = null
  },
  methods: {
    onResize () {
      this.map?.container?.fitToViewport()
    },
    resetCollections () {
      // console.log('resetCollections')
      // Создаю коллекции для управления элементами
      this.collectionPolygons = new window.ymaps.GeoObjectCollection({})
      this.map.geoObjects.add(this.collectionPolygons)

      this.collectionMarkers = new window.ymaps.GeoObjectCollection({})
      this.map.geoObjects.add(this.collectionMarkers)
    },
    async onInit () {
      // Проверяю наличие карт
      if (!window?.ymaps) return console.error('window.ymaps - NONE', !!window?.ymaps)
      window.ymaps.ready().then(() => {
        this.map = new window.ymaps.Map(this.mapUid, {
          center: [mapConfig.latitudeInit, mapConfig.longitudeInit],
          zoom: this.mapZoom || mapConfig.mapZoom,
          controls: mapConfig.mapControls,
          cursor: 'help'
        }, {
          suppressMapOpenBlock: true, // Нужно ли скрывать предложение открыть текущую карту в Яндекс.Картах
          yandexMapDisablePoiInteractivity: true // Отключает интерактивность элементов подложки карты (кликабельность больниц, магазинов и тп.)
        })

        // Назначаю курсор
        this.map.cursors.push('pointer')

        this.resetCollections()

        // Переключение в полный экран
        const fullscreenControl = new window.ymaps.control.FullscreenControl()
        fullscreenControl.events.add(['fullscreenenter', 'fullscreenexit'], (e) => {
          if (e?.originalEvent?.type === 'fullscreenenter') {
            // Фиксирую размер чтобы не ломало верстку
            this.rootStyles = {
              maxWidth: `${this.$el.clientWidth}px`,
              maxHeight: `${this.$el.clientHeight}px`
            }
          }
          if (e?.originalEvent?.type === 'fullscreenexit') {
            this.rootStyles = {}
          }
        })
        this.map.controls.add(fullscreenControl)

        // для редактирования
        if (!this.viewOnly) {
          // Поле поиска на карте
          const searchControl = new window.ymaps.control.SearchControl({
            options: {
              noPlacemark: true // отключение popover с информацией после выбора объекта в поиске
            }
          })
          searchControl.events.add('resultselect', (e) => {
            const targetIndex = e.get('index')
            if (this.drawMode === 'POINT') {
              searchControl.getResult(targetIndex).then((res) => {
                // получаем координаты найденной точки
                const [lat, lon] = res.geometry.getCoordinates()
                const coords = { lat, lon }
                /* Можем ставить метку */
                this.$emit('coords-change', coords)
                this.createMarker([lat, lon])
              })
            }
          })

          this.map.controls.add(searchControl)

          // Рисование точки координат
          if (this.drawMode === 'POINT') {
            // Прослушивание события клика по карте
            this.map.events.add('click', (e) => {
              e.stopPropagation()
              const [lat, lon] = e.get('coords')
              const coords = { lat, lon }
              this.$emit('coords-change', coords)
              this.createMarker([lat, lon])
            })
          } else if (this.drawMode === 'POLYGON' && !this.viewOnly) {
            // Прослушивание события клика по карте
            this.map.events.add('click', (e) => {
              e.stopPropagation()
              // прекращаю редактирование всех полигонов
              this.collectionPolygons.each((ctx) => {
                ctx.editor.stopEditing()
              })
              if (this.targetEditPolygon) {
                // Удаляю информацию о полигоне который редактируется
                this.targetEditPolygon = null
              } else if (!Array.isArray(this.polyCoords) || (Array.isArray(this.polyCoords) && !this.polyCoords.length)) {
                // TODO мульти полигоны - не реализовано на беке - убрать условие выше для мультиполигонов
                // Если полигона нет то нужно начать рисовать
                const polygon = this.createPolygon([e.get('coords')])
                // Отмечаем полигон как текущий редактируемый
                this.targetEditPolygon = polygon
                // Добавляем полигон на карту
                this.collectionPolygons.add(polygon)
                // Начинаем рисовать грани
                polygon.editor.startDrawing()
              }
            })
          }
        }
        this.initValues()
        this.onResize()
      })
    },
    createPolygon (coords = []) {
      const polygon = new window.ymaps.Polygon([
        // Указываем координаты вершин многоугольника.
        // Координаты вершин внешнего контура.
        coords
        // [
        //   [52.75, 36.52],
        //   [52.75, 36.68],
        //   [52.65, 36.60]
        // ]
        // Координаты вершин внутреннего контура.
        // [
        //   [55.75, 37.52],
        //   [55.75, 37.68],
        //   [55.65, 37.60]
        // ]
      ], {
        // Описываем свойства геообъекта.
        // Содержимое балуна.
        // hintContent: 'Многоугольник'
      }, {
        // Задаем опции геообъекта.
        // Цвет заливки.
        fillColor: mapConfig.polygonStyles.fillColor,
        // Ширина обводки.
        strokeWidth: mapConfig.polygonStyles.strokeWidth,
        strokeColor: mapConfig.polygonStyles.strokeColor,
        // The cursor in the mode for adding new vertices.
        editorDrawingCursor: 'crosshair',
        // The maximum number of vertices.
        editorMaxPoints: 999
      })
      // Прослушивание события клика по polygon
      polygon.events.add('click', (e) => {
        e.stopPropagation()
        // прекращаю редакторование полигонов
        this.collectionPolygons.each(function (ctx) {
          ctx.editor.stopEditing()
        })
        if (!this.viewOnly) {
          // выделяю текущий полигон как редактируемый
          this.targetEditPolygon = e.get('target')
          this.targetEditPolygon.editor.startEditing()
        }
      })
      polygon.events.add(['dragend', 'change', 'pixelgeometrychange', 'geometrychange'], (e) => {
        // console.error('=================', e)
        e.stopPropagation()
        // расчёт обноленных координат
        this.getPolygonPoints()
      })
      polygon.editor.events.add(['drawingstop', 'statechange', 'editingstop'], (e) => {
        // console.error('+++++++++++++++', e)
        e.stopPropagation()
        // расчёт обноленных координат
        this.getPolygonPoints()
      })
      return polygon
    },
    createMarker (coords) {
      // console.log('createMarker', coords)
      this.collectionMarkers?.removeAll()
      const mainObjectMarker = new window.ymaps.Placemark(coords, {
        // iconCaption: 'невероятно интересный текст'
      }, {
        draggable: false,
        preset: 'islands#redHomeIcon'
      })
      this.collectionMarkers.add(mainObjectMarker)
      this.mapToCenterAndZoomFromObjects()
    },
    getPolygonPoints () {
      let newPolygonsList = []
      this.collectionPolygons.each((ctx) => {
        const formatCoords = ctx.geometry.getCoordinates().flat()
        newPolygonsList = formatCoords.map(coords => {
          return coords
        })
      })
      this.$emit('poly-coords-change', newPolygonsList)
    },
    initValues (isUpdate = false) {
      // если редактирование и нужно инициировать дефолтное значение
      if (!this.viewOnly && this.isPresetDefaultCoords) {
        // в случае отсутствия заданных координат применяю стандартные и шлю изменения вверх
        if (!this.coords?.lat || !this.coords?.lon) {
          const coords = { lat: mapConfig.latitudeInit, lon: mapConfig.longitudeInit }
          this.$emit('coords-change', coords)
        }
      }
      // Метка главного объекта
      if (this.drawMode === 'POINT' && this.coords?.lat && this.coords?.lon) {
        this.createMarker(Object.values(this.coords))
      }

      // Полигон
      if (this.drawMode === 'POLYGON') {
        // console.warn('initValues isUpdate', isUpdate, this.polyCoords)
        if (!isUpdate) {
          this.collectionPolygons?.removeAll()
          if (Array.isArray(this.polyCoords) && this.polyCoords.length) {
            // формирую координаты
            const polyCoords = this.polyCoords.map(points => Object.values(points))
            const polygon = this.createPolygon(polyCoords)
            // Добавляем полигон на карту
            this.collectionPolygons.add(polygon)
          }
        } else {
          if (this.polyCoords === null || (Array.isArray(this.polyCoords) && !this.polyCoords.length)) {
            this.collectionPolygons?.removeAll()
            this.resetCollections()
          }
        }
      }

      // Центровка и вписывание в карту
      this.mapToCenterAndZoomFromObjects()
    },
    // КАРТА центровка и маштабирование потносительно имеющихся объектов
    mapToCenterAndZoomFromObjects () {
      const bounds = this.map.geoObjects.getBounds()
      if (Array.isArray(this.polyCoords) && this.polyCoords.length) {
        this.map.setBounds(bounds, {
          checkZoomRange: true,
          duration: 300,
          preciseZoom: true,
          zoomMargin: 5,
          callback: (err) => {
            if (err) {
              console.error('callback', err)
            }
          }
        })
      } else {
        // если есть толко точка то setBounds не подходит ибо сильно приближает.
        // Поэтому использую центровку с зумом
        this.map.setCenter(bounds ? bounds[0] : Object.values(this.coords), (this.mapZoom || mapConfig.mapZoom), {
          duration: 300,
          checkZoomRange: true,
          preciseZoom: true,
          zoomMargin: [5, 5]
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
