<template>
  <TwPanelContentBlock
    no-data-text="Отсутствует"
    class="tw-mb-[6px]"
  >
    <template #title>
      Статус подтверждения комиссии
    </template>
    <template #action>
      <TwPanelContentBlockActionButton
        v-if="info && ui && ui.canEdit"
        type="edit"
        @click.stop="isEdit = !isEdit"
      >
        {{ isEdit ? 'отменить':'изменить' }}
      </TwPanelContentBlockActionButton>
    </template>
    <template #default>
      <div
        v-if="loading"
        class="tw-flex tw-justify-center tw-items-center"
      >
        <TwCircleLoader
          width="30px"
          height="30px"
        />
      </div>
      <div
        v-else-if="info"
        class="tw-flex tw-gap-[12px] tw-flex-col"
      >
        <div
          v-if="isEdit"
          class="tw-flex tw-gap-[16px] tw-flex-col"
        >
          <div>
            <TwSelect
              v-if="dictionary"
              v-model="form.checkStatus"
              label="Статус подтверждения куратором"
              placeholder="Не установлен"
              :multi="false"
              :dense="true"
              :persistent-label="true"
              :disabled="formProcessing"
              :error-text="$v.form.checkStatus.$invalid ? 'Установите статус' : ''"
              :options="dictionary.checkStatus"
            />
          </div>
          <div class="tw-flex tw-items-center">
            <TwInputText
              type="text"
              class="tw-flex-1 tw-text-right"
              label="Итоговая комиссия"
              placeholder="Итоговая комиссия"
              max-length="13"
              :value="$Global.inputFormatPrice.getStringSpaced(form.commission)"
              :persistent-label="true"
              :show-label="true"
              :error-text="$v.form.commission.$invalid ? 'Обязательно' : ''"
              :dense="true"
              @input="form.commission = $Global.inputFormatPrice.getNumber($event)"
            >
              <template #append>
                руб.
              </template>
            </TwInputText>
            <TwCheckBox
              v-model="form.commissionConfirmed"
              class="tw-mt-[16px]"
              background-and-border-color="tw-bg-an-flat-dark-blue-link tw-border-an-flat-dark-blue-link"
              label="Подтверждена"
              :disabled="formProcessing"
              :dense="false"
            />
          </div>
          <hr>
          <div class="tw-ml-[5px] tw-font-medium tw-text-main-sm2">
            Отчётный период:
          </div>
          <div class="tw-flex tw-gap-[24px]">
            <TwInputText
              v-model.number="form.month"
              class="tw-flex-1"
              type="number"
              label="Месяц"
              placeholder="Месяц"
              :disabled="formProcessing"
              :error-text="$v.form.month.$invalid ? 'Обязательно' : ''"
              :dense="true"
              :persistent-label="true"
              :show-label="true"
            />
            <TwInputText
              v-model.number="form.year"
              class="tw-flex-1"
              type="number"
              label="Год"
              placeholder="Год"
              :disabled="formProcessing"
              :error-text="$v.form.year.$invalid ? 'Обязательно' : ''"
              :dense="true"
              :persistent-label="true"
              :show-label="true"
            />
          </div>
          <TwTextarea
            v-model="form.description"
            rows="5"
            class="tw-mt-[15px]"
            label="Комментарий"
            placeholder="Комментарий"
            :error-text="$v.form.description.$invalid ? 'Опишите причину' : ''"
            :disabled="formProcessing"
            :persistent-label="true"
            :show-label="true"
          />
          <!--    Вывод ошибок      -->
          <div class="tw-p-2 tw-text-center tw-text-an-flat-red-main">
            <div v-if="$v.form.$invalid">
              Заполните все поля формы
            </div>
            <div v-if="serverError">
              {{ serverError }}
            </div>
          </div>
          <div>
            <TwCardBlockFormActions
              title-submit="Сохранить"
              :use-cancel-alert="false"
              :disable-submit="$v.form.$invalid"
              :handle-submit="handleSubmit"
              :handle-cancel="handleCancel"
              :disable-cancel="formProcessing"
            />
          </div>
        </div>
        <div
          v-else
          class="tw-flex tw-gap-[12px] tw-flex-col"
        >
          <div class="tw-flex tw-gap-[12px] tw-flex-col">
            <TwPanelContentBlockFieldView no-data-text="Не установлен">
              <template #title>
                Статус подтверждения куратором
              </template>
              <div
                v-if="info && info.checkStatus"
                class="tw-font-medium tw-flex"
              >
                <div
                  v-if="!info.checkStatus || (info.checkStatus && !info.checkStatus.value)"
                  class="tw-font-bold tw-rounded-sm tw-px-[10px] tw-py-[4px] tw-block tw-text-an-flat-disabled-text"
                >
                  Нет
                </div>
                <div
                  v-else-if="info.checkStatus.value === 'adjustment'"
                  class="tw-font-bold tw-rounded-sm tw-px-[10px] tw-py-[4px] tw-block tw-text-an-flat-orange-warning tw-bg-an-flat-orange-warning/20"
                >
                  {{ info.checkStatus.label }}
                </div>
                <div
                  v-else-if="info.checkStatus.value === 'confirmed'"
                  class="tw-font-bold tw-rounded-sm tw-px-[10px] tw-py-[4px] tw-block tw-text-an-flat-green-btn-bg tw-bg-an-flat-green-btn-bg/20"
                >
                  {{ info.checkStatus.label }}
                </div>
                <div
                  v-else-if="info.checkStatus.value === 'rejected'"
                  class="tw-font-bold tw-rounded-sm tw-px-[10px] tw-py-[4px] tw-block tw-text-an-flat-red-main tw-bg-an-flat-red-main/20"
                >
                  {{ info.checkStatus.label }}
                </div>
              </div>
            </TwPanelContentBlockFieldView>
            <TwPanelContentBlockFieldView
              v-if="info.checkDate"
              no-data-text="Отсутствует"
            >
              <template #title>
                Дата проверки
              </template>
              <div class="tw-flex tw-justify-between tw-items-center">
                <div>
                  {{ $Global.DateFormat.DateTimeShort(info.checkDate) }}
                </div>
                <ViewPanelLink
                  v-if="info.checkUserId"
                  :id="info.checkUserId"
                  label="Сотрудник"
                  component="employee-show"
                >
                  <EmployeeName :id="info.checkUserId" />
                </ViewPanelLink>
              </div>
            </TwPanelContentBlockFieldView>
            <TwPanelContentBlockFieldView>
              <template #title>
                Итоговая комиссия
              </template>
              <div v-if="info.commission">
                <span class="tw-font-medium">{{ (info.commission || 0).toLocaleString('ru') }}</span>
                руб.
              </div>
            </TwPanelContentBlockFieldView>
            <TwPanelContentBlockFieldView>
              <template #title>
                Комиссия подтверждена
              </template>
              <TwBooleanIcon
                :value="info.commissionConfirmed"
                size="18"
              >
                <template #right>
                  {{ info.commissionConfirmed ? 'Да':'Нет' }}
                </template>
              </TwBooleanIcon>
            </TwPanelContentBlockFieldView>
            <TwPanelContentBlockFieldView
              v-if="info.commissionConfirmed"
              no-data-text="Отсутствует"
            >
              <template #title>
                Дата подтверждения комиссии
              </template>
              <div class="tw-flex tw-justify-between tw-items-center">
                <div v-if="info.commissionConfirmDate">
                  {{ $Global.DateFormat.DateTimeShort(info.commissionConfirmDate) }}
                </div>
                <div v-else>
                  Отсутствует
                </div>
              </div>
            </TwPanelContentBlockFieldView>
            <TwPanelContentBlockFieldView>
              <template #title>
                Отчётный период
              </template>
              <div v-if="info.month && info.year">
                <span class="tw-font-medium">{{ info.month }}/{{ info.year }}</span>
                <span
                  v-if="info.companyPeriodId"
                  class="tw-=text-main-xs tw-cursor-pointer tw-text-an-flat-dark-blue-link hover:tw-text-an-flat-dark-blue-link-active hover:tw-underline"
                  @click.stop="showPeriodCard(info.companyPeriodId)"
                >
                  подробнее
                </span>
              </div>
            </TwPanelContentBlockFieldView>
            <TwPanelContentBlockFieldView no-data-text="Отсутствует">
              <template #title>
                Комментарий
              </template>
              <div
                v-if="info.description"
                class="tw-font-medium"
              >
                <ReadMoreExpander
                  :is-nl-trigger="false"
                  :text="info.description"
                  :less-length="100"
                />
              </div>
            </TwPanelContentBlockFieldView>
          </div>
        </div>
      </div>
    </template>
  </TwPanelContentBlock>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import API from '@/Api/Crm/Deal/BuyNb/ResultManagement'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'
import TwCardBlockFormActions from '@/components/core/ViewPanel/Card/TwCardBlockFormActions.vue'
import TwTextarea from '@/components/tw-ui/ui-kit/input/TwTextarea.vue'
import TwSelect from '@/components/tw-ui/ui-kit/input/TwSelect.vue'
import ReadMoreExpander from '@/components/other/text/ReadMoreExpander.vue'
import TwInputText from '@/components/tw-ui/ui-kit/input/TwInputText.vue'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink.vue'
import EmployeeName from '@/components/other/employee/EmployeeName.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import TwPanelContentBlockActionButton from '@/components/tw-ui/panels/TwPanelContentBlockActionButton.vue'
import TwBooleanIcon from '@/components/tw-ui/ui-kit/icons/TwBooleanIcon.vue'
import TwCheckBox from '@/components/tw-ui/ui-kit/input/TwCheckBox.vue'

export default {
  name: 'DealNbResultManagementStatusBlock',
  components: {
    TwCheckBox,
    TwBooleanIcon,
    TwPanelContentBlockActionButton,
    TwCircleLoader,
    EmployeeName,
    ViewPanelLink,
    TwInputText,
    ReadMoreExpander,
    TwSelect,
    TwTextarea,
    TwCardBlockFormActions,
    TwPanelContentBlockFieldView,
    TwPanelContentBlock
  },
  mixins: [ViewPanelShowMixin],
  props: {
    id: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      isEdit: false,
      formProcessing: false,
      loading: false,
      form: {
        commissionConfirmed: false,
        checkStatus: null,
        commission: null,
        description: null,
        year: null,
        month: null
      },
      serverError: null,
      info: null,
      ui: null,
      dictionary: null
    }
  },
  validations () {
    return {
      form: {
        checkStatus: {
          required
        },
        commission: {
          ...(this.form.checkStatus !== 'adjustment') && { required }
        },
        year: {
          ...(this.form.checkStatus !== 'adjustment') && { required }
        },
        month: {
          ...(this.form.checkStatus !== 'adjustment') && { required }
        },
        description: {
          ...(this.form.checkStatus && (this.form.checkStatus === 'adjustment' || this.form.checkStatus === 'rejected')) && { required }
        }
      }
    }
  },
  watch: {
    isEdit (newValue) {
      if (newValue) {
        this.form.commission = this.info?.commission || null
        this.form.checkStatus = this.info?.checkStatus?.value || null
        this.form.description = this.info?.description || null
        this.form.month = this.info?.month || null
        this.form.year = this.info?.year || new Date().getFullYear()
        this.form.commissionConfirmed = this.info?.commissionConfirmed || false
      }
    }
  },
  mounted () {
    this.fetchStatusInfo()
  },
  methods: {
    showPeriodCard (id) {
      this.showCompanyPeriodResultManagementCard({ id })
    },
    fetchStatusInfo () {
      this.loading = true
      API.showStatus(this.id)
        .then(({ data: result }) => {
          this.info = result?.data || null
          this.ui = result?.ui || null
          this.dictionary = result?.dictionary || null
        })
        .catch((error) => {
          console.error('handleSubmit', error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleSubmit () {
      if (!this.$v.form.$invalid) {
        this.formProcessing = true
        this.serverError = null
        if (this.info?.companyPeriodId) {
          API.updateStatus(this.id, this.form)
            .then(() => {
              this.fetchStatusInfo()
              this.isEdit = false
            })
            .catch((error) => {
              this.serverError = error?.response?.data
            })
            .finally(() => {
              this.formProcessing = false
            })
        } else {
          API.storeStatus(this.id, this.form)
            .then(() => {
              this.fetchStatusInfo()
              this.isEdit = false
            })
            .catch((error) => {
              this.serverError = error?.response?.data
            })
            .finally(() => {
              this.formProcessing = false
            })
        }
      }
    },
    handleCancel () {
      this.isEdit = false
    }
  }
}
</script>
