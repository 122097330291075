<template>
  <v-form ref="form">
    <div style="display: none">
      {{ isEdited }}
    </div>
    <v-text-field
      ref="model"
      v-model="form.model"
      class="py-2"
      label="Молель, марка"
      outlined
      type="text"
      autocomplete="off"
      :dense="denseFields"
      :hide-details="!modelErrors.length"
      :error-messages="modelErrors"
      @focus="$event.target.select()"
    />
    <v-text-field
      ref="square"
      v-model="form.yearManufacture"
      class="pb-2"
      label="Год выпуска"
      require
      outlined
      type="number"
      autocomplete="off"
      suffix="год"
      :dense="denseFields"
      hide-details
    />
    <v-text-field
      ref="price"
      v-model="form.price"
      class="pb-2"
      label="Стоимость"
      require
      outlined
      type="number"
      autocomplete="off"
      suffix="руб."
      :dense="denseFields"
      hide-details
    />
    <div class="mt-2 mb-4 text-right">
      <v-btn
        :disabled="formProcessing"
        class="font-weight-bold mr-2"
        elevation="0"
        x-small
        color="success"
        @click="handleSubmit"
      >
        сохранить
      </v-btn>
      <v-btn
        :disabled="formProcessing"
        class="font-weight-bold"
        elevation="0"
        x-small
        outlined
        color="secondary"
        @click="$emit('cancel')"
      >
        отмена
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import API from '@/Api/Crm/Person/Car/index'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import InputPersonPhones from '@/components/input/phone/InputPersonPhones'
import InputAdditionalContacts from '@/components/input/InputAdditionalContacts'
import CompanyAsyncSelect from '@/components/select/company/CompanyAsyncSelect'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'PersonCarForm',
  // eslint-disable-next-line vue/no-unused-components
  components: { CompanyAsyncSelect, InputAdditionalContacts, InputPersonPhones },
  mixins: [FormCreateEditMixin],
  props: {
    personId: {
      type: Number,
      required: true
    },
    denseFields: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      API: API,
      form: {
        id: null,
        personId: this.personId,
        model: null,
        yearManufacture: null,
        price: null
      }
    }
  },
  computed: {
    modelErrors () {
      const errors = []
      if (!this.$v.form.model.$dirty) return errors
      if (!this.$v.form.model.required) {
        errors.push('Поле обязательное')
        this.$Global.scrollToElem(this.$refs.model.$el)
      }
      return errors
    }
  },
  validations: {
    form: {
      model: {
        required
      }
    }
  },
  mounted () {
    if (this.$refs.model) {
      this.$refs.model.focus()
      this.$Global.scrollToElem(this.$refs.model.$el)
    }
  },
  methods: {
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          const adaperMap = {
            // from(show): to(send)
            person: 'personId'
          }
          for (const key in this.preset) {
            if (adaperMap[key] !== undefined) {
              if (adaperMap[key].indexOf('Id') !== -1 && (this.preset[key] && this.preset[key].id)) {
                this.$set(this.form, adaperMap[key], this.preset[key].id)
              }
            } else {
              this.$set(this.form, key, this.preset[key])
            }
          }
        }
        resolve()
      })
    },
    handleResetCustom () {
      this.handleReset()
    },
    handleSubmit () {
      // console.warn(this.$options.name, ' handleSubmit ', this.formType, this.targetId)
      this.$v.$reset()
      this.$v.form.$touch()
      if (!this.$v.form.$invalid) {
        if (this.formProcessing === true) {
          return
        }

        this.formProcessing = true
        this.serverErrors = null
        if (this.formType === 'create') {
          this.API.store(this.form)
            .then(({ data }) => {
              console.warn(this.$options.name, ' CREATE ', data)
              // this.fetchData(data.data.id)
              this.$emit('create', data.data)
            })
            .catch((error) => {
              if (error?.response?.data) {
                console.error(this.$options.name, error.response.data)
                for (const key in error.response.data) {
                  const [refKey, index] = key.split('.')
                  // console.log(key, refKey, index, field)
                  if (!this.serverErrors) this.serverErrors = {}
                  if (!this.serverErrors[refKey]) this.serverErrors[refKey] = []
                  this.serverErrors[refKey][index] = error.response.data[key][0]
                }
              }
            })
            .finally(() => {
              this.formProcessing = false
            })
        } else if (this.formType === 'edit' && this.targetId) {
          this.API.update(this.targetId, this.form)
            .then(({ data }) => {
              console.warn(this.$options.name, ' UPDATE ', data.data)
              this.$emit('update', data.data)
            })
            .catch((error) => {
              if (error?.response?.data) {
                console.error(this.$options.name, error.response.data)
                for (const key in error.response.data) {
                  const [refKey, index] = key.split('.')
                  // console.log(key, refKey, index, field)
                  if (!this.serverErrors) this.serverErrors = {}
                  if (!this.serverErrors[refKey]) this.serverErrors[refKey] = []
                  this.serverErrors[refKey][index] = error.response.data[key][0]
                }
              }
            })
            .finally(() => {
              this.formProcessing = false
            })
        }
      }
    }
  }
}
</script>
