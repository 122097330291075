import { Module } from 'vuex'
import { RootState } from '@/store/types'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import introJs from 'intro.js'
import 'intro.js/minified/introjs.min.css'

const namespaced = true

export const introTeach: Module<{}, RootState> = {
  namespaced,
  actions: {
    showLesson: function (state, { options = {}, steps = [] }) {
      // console.warn('runLessonFor ', options, steps)
      steps.forEach((step: any, index: any) => {
        options.steps[index] = { ...options.steps[index], ...step }
      })
      introJs().setOptions(options).start()
    }
  }
}
