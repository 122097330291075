<template>
  <div
    v-if="loading"
    class="tw-text-center"
  >
    <TwCircleLoader />
  </div>
  <div v-else>
    <TwPanelContentBlock class="tw-mb-[6px]">
      <template #title>
        <div>Информация по ипотеке</div>
      </template>
      <div
        v-if="info"
        class="tw-flex tw-gap-[12px] tw-flex-col"
      >
        <TwPanelContentBlockFieldView>
          <template #title>
            Банк
          </template>
          <div v-if="info.bankId">
            <ViewPanelLink
              :id="info.bankId"
              label="Компания"
              component="company-show"
            >
              <TwCompanyName :id="info.bankId" />
            </ViewPanelLink>
          </div>
        </TwPanelContentBlockFieldView>
        <TwPanelContentBlockFieldView>
          <template #title>
            Сумма кредита
          </template>
          <div>
            {{ (info.creditSum || 0).toLocaleString('ru') }} ₽
          </div>
        </TwPanelContentBlockFieldView>
        <TwPanelContentBlockFieldView v-if="info.remainingAmountMortgage">
          <template #title>
            Оставшаяся сумма по ипотеке
          </template>
          <div>
            {{ (info.remainingAmountMortgage || 0).toLocaleString('ru') }} ₽
          </div>
        </TwPanelContentBlockFieldView>
        <TwPanelContentBlockFieldView>
          <template #title>
            Срок кредита
          </template>
          <div v-if="info.years">
            {{ info.years }} {{ $Global.textPlural(info.years, 'year') }}
          </div>
        </TwPanelContentBlockFieldView>
        <TwPanelContentBlockFieldView>
          <template #title>
            Процентная ставка
          </template>
          <div v-if="info.percent">
            {{ info.percent }}%
          </div>
        </TwPanelContentBlockFieldView>
        <hr>
        <TwPanelContentBlockFieldView>
          <template #title>
            <strong>
              Страховая компания
            </strong>
          </template>
          <div v-if="info.insuranceCompanyId">
            <ViewPanelLink
              :id="info.insuranceCompanyId"
              label="Компания"
              component="company-show"
            >
              <strong>
                <TwCompanyName :id="info.insuranceCompanyId" />
              </strong>
            </ViewPanelLink>
          </div>
        </TwPanelContentBlockFieldView>
        <TwPanelContentBlockFieldView>
          <template #title>
            <strong>
              Дата выдачи
            </strong>
          </template>
          <div v-if="info.dateExtradition">
            <strong>
              {{ $Global.DateFormat.DateShort(info.dateExtradition) }}
            </strong>
          </div>
        </TwPanelContentBlockFieldView>
        <TwPanelContentBlockFieldView>
          <template #title>
            <strong>
              Сумма страхования
            </strong>
          </template>
          <strong>
            {{ (info.insuranceSum || 0).toLocaleString('ru') }} ₽
          </strong>
        </TwPanelContentBlockFieldView>
      </div>
    </TwPanelContentBlock>
  </div>
</template>

<script>
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
// TODO add and change API
import API from '@/Api/Crm/Deal/Insurance/Other'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'
import TwCompanyName from '@/components/tw-ui/other/TwCompanyName.vue'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink.vue'

export default {
  name: 'DealInsuranceMortgageBlock',
  components: {
    ViewPanelLink,
    TwCompanyName,
    TwPanelContentBlockFieldView,
    TwCircleLoader,
    TwPanelContentBlock
  },
  props: {
    dealData: {
      required: true
    }
  },
  data () {
    return {
      isEdit: false,
      loading: false,
      info: null,
      dictionary: null
    }
  },
  computed: {
    dealId () {
      return this.dealData?.id || null
    }
  },
  mounted () {
    this.refresh()
  },
  methods: {
    refresh () {
      this.isEdit = false
      this.fetchInfo()
    },
    fetchInfo () {
      this.loading = true
      API.getMortgage(this.dealId)
        .then(({ data: result }) => {
          console.warn('fetchInfo', result)
          this.$set(this, 'info', result?.data ? result.data : null)
          this.$set(this, 'dictionary', result?.dictionary || null)
        })
        .catch((error) => {
          console.error('fetchInfo', error)
          this.$set(this, 'info', null)
          this.$set(this, 'dictionary', null)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
