<template>
  <TwDialog
    class="tw-font-manrope tw-text-main-xs"
    :value="value"
    max-width="600px"
    @input="$emit('input', $event)"
  >
    <template #header>
      <div class="tw-text-h4">
        Настройки таблицы
      </div>
    </template>
    <template #body>
      <div class="tw-mt-[30px]">
        <div
          ref="headersEls"
          class="tw-flex tw-gap-[15px] tw-flex-wrap tw-font-semibold"
        >
          <div
            v-for="(header, index) in localHeadersList"
            :key="index + header.dataKey"
            class="tw-border tw-border-dashed tw-px-[7px] tw-py-[4px] tw-flex tw-gap-[5px] tw-flex-nowrap tw-items-center draggable-handler tw-select-none"
          >
            <div>
              <TwCheckBox
                :dense="true"
                :value="localHeadersList[index].visible"
                @input="localHeadersList[index].visible = $event"
              />
            </div>
            <div class="tw-cursor-grabbing tw-flex tw-gap-[10px] tw-flex-nowrap tw-items-center">
              {{ header.isDivider ? '~' : header.text }}
              <svg
                width="5"
                height="11"
                viewBox="0 0 5 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1 2C1.55229 2 2 1.55228 2 1C2 0.447716 1.55229 0 1 0C0.447708 0 0 0.447716 0 1C0 1.55228 0.447708 2 1 2ZM4 2C4.55229 2 5 1.55228 5 1C5 0.447716 4.55229 0 4 0C3.44771 0 3 0.447716 3 1C3 1.55228 3.44771 2 4 2ZM2 4C2 4.55228 1.55229 5 1 5C0.447708 5 0 4.55228 0 4C0 3.44772 0.447708 3 1 3C1.55229 3 2 3.44772 2 4ZM4 5C4.55229 5 5 4.55228 5 4C5 3.44772 4.55229 3 4 3C3.44771 3 3 3.44772 3 4C3 4.55228 3.44771 5 4 5ZM2 7C2 7.55228 1.55229 8 1 8C0.447708 8 0 7.55228 0 7C0 6.44772 0.447708 6 1 6C1.55229 6 2 6.44772 2 7ZM4 8C4.55229 8 5 7.55228 5 7C5 6.44772 4.55229 6 4 6C3.44771 6 3 6.44772 3 7C3 7.55228 3.44771 8 4 8ZM2 10C2 10.5523 1.55229 11 1 11C0.447708 11 0 10.5523 0 10C0 9.44772 0.447708 9 1 9C1.55229 9 2 9.44772 2 10ZM4 11C4.55229 11 5 10.5523 5 10C5 9.44772 4.55229 9 4 9C3.44771 9 3 9.44772 3 10C3 10.5523 3.44771 11 4 11Z"
                  fill="gray"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="tw-mt-[30px] tw-flex tw-gap-[15px]">
          <button
            class="tw-text-center tw-py-[7px] tw-text-an-flat-gray-3 enabled:hover:tw-text-an-flat-blue-btn"
            @click.stop="$emit('reset')"
          >
            Установить по умолчанию
          </button>
          <div class="tw-grow" />
          <button
            class="tw-w-[80px] tw-px-[15px] tw-py-[7px] tw-bg-an-flat-gray-3 tw-rounded-sm tw-text-white enabled:hover:tw-bg-an-flat-blue-btn"
            @click.stop="$emit('input', false)"
          >
            Отмена
          </button>
          <button
            class="tw-w-[120px] tw-px-[15px] tw-py-[7px] tw-bg-an-flat-green-btn-bg tw-rounded-sm tw-text-white disabled:tw-opacity-50"
            @click.stop="onSaveHeaders"
          >
            Сохранить
          </button>
        </div>
      </div>
    </template>
  </TwDialog>
</template>

<script>
import { defineComponent } from 'vue'
import TwDialog from '@/components/tw-ui/modal/TwDialog.vue'
import TwCheckBox from '@/components/tw-ui/ui-kit/input/TwCheckBox.vue'
import Sortable from 'sortablejs'

export default defineComponent({
  name: 'TwTableConfigDialog',
  components: { TwCheckBox, TwDialog },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    headersList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      localHeadersList: []
    }
  },
  watch: {
    value (newValue) {
      if (newValue) {
        this.localHeadersList = this.headersList.map(i => {
          return { ...i, ...{} }
        })
        this.$nextTick(() => {
          this.initializeDragAndDropFunctionality()
        })
      }
    }
  },
  methods: {
    onSaveHeaders () {
      // console.warn('onSaveHeaders', this.localHeadersList)
      this.$emit('changeHeaders', this.localHeadersList)
    },
    initializeDragAndDropFunctionality () {
      const headersListEls = this.$refs.headersEls
      Sortable.create(headersListEls, {
        handle: '.draggable-handler',
        chosenClass: 'tw-bg-an-flat-blue-btn', // Класс перемещаемого элемента
        sort: true,
        animation: 200,
        onEnd: ({ newIndex, oldIndex }) => {
          const copyHeaders = [...this.localHeadersList, ...[]]
          const headerSelected = copyHeaders.splice(oldIndex, 1)[0]
          copyHeaders.splice(newIndex, 0, headerSelected)
          this.$nextTick(() => {
            this.localHeadersList = copyHeaders
          })
        }
      })
    }
  }
})
</script>

<style scoped>

</style>
