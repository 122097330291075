<template>
  <div class="tw-font-manrope">
    <slot name="default" />
    <div class="tw-mb-[6px] tw-bg-an-flat-gray-15 tw-rounded-sm tw-p-[5px] tw-font-semibold tw-flex tw-gap-[15px] tw-overflow-x-auto">
      <slot
        name="buttons"
        v-bind="{ items, onClickButton }"
      >
        <slot
          v-for="(item) in items"
          :name="`button.${item.key}`"
          v-bind="{ item, onClickButton }"
        >
          <button
            v-show="!item.hidden"
            :key="item.key"
            :disabled="item.disabled || disabled"
            class="tw-relative tw-whitespace-nowrap tw-rounded-sm tw-px-[14px] tw-py-[8px] disabled:tw-text-an-flat-disabled-text"
            :class="{ 'tw-bg-white': item.key === value }"
            @click.prevent="onClickButton(item)"
          >
            {{ item.label }}
            <span
              v-if="item.hasBadge"
              class="tw-block tw-absolute tw-top-[6px] tw-right-[3px] tw-w-[10px] tw-h-[10px] tw-bg-an-flat-blue-btn tw-rounded-full"
            />
          </button>
        </slot>
      </slot>
    </div>
    <slot
      name="contents"
      v-bind="{ items }"
    >
      <TwPanelContent
        v-for="(item) in items"
        :key="item.key"
        wrapper-classes="tw-w-full"
      >
        <slot
          v-if="item.key === value"
          :name="`content.${item.key}`"
          v-bind="{ item }"
        >
          <div class="tw-text-center tw-font-semibold tw-text-an-flat-red-main tw-text-lg tw-py-[12px]">
            Слот [{{ `content.${item.key}` }}] - не описан
          </div>
        </slot>
      </TwPanelContent>
    </slot>
  </div>
</template>

<script>
import TwPanelContent from '@/components/tw-ui/panels/TwPanelContent.vue'

export default {
  name: 'TwPanelTabsFull',
  components: { TwPanelContent },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Number, String],
      default: null
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    onClickButton (item) {
      // console.warn(this.$options.name, 'onClickButton', item)
      this.$emit('input', item?.key || null)
    }
  }
}
</script>
<style lang="css" scoped>
/* width */
::-webkit-scrollbar {
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {}

/* Handle */
::-webkit-scrollbar-thumb {}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {}
</style>
