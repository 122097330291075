<template>
  <div
    v-if="info"
    class="section-fields-view"
  >
    <!-- ЗАГОЛОВОК СЕКЦИИ-->
    <div
      class="section-fields-view--header"
      @click="open = !open"
    >
      {{ info.label }}
      <div
        class="icon-size-20"
        :class="open ? 'icon-minus':'icon-plus'"
      />
    </div>
    <div
      v-if="open"
      class="section-fields-view--content"
    >
      <!-- ПОЛЯ СЕКЦИИ-->
      <div class="section-fields-view--content-fields">
        <template v-if="info.fields">
          <div
            v-for="(text, key, index) in info.fields"
            :key="index"
            class="section-fields-view--content-fields-item"
          >
            <div class="section-fields-view--content-fields-item-title">
              {{ text }}
            </div>
            <div class="section-fields-view--content-fields-item-text d-flex align-center">
              <ClickBoard
                :disabled="false"
                :value="`\${${key}}`"
              />
              <div v-text="`\${${key}}`" />
            </div>
          </div>
        </template>
      </div>
      <!-- СУБ СЕКЦИИ-->
      <div
        v-if="info.subsection"
        class="sub-sections"
      >
        <div
          v-for="(section, index) in info.subsection"
          :key="index"
        >
          <SectionFieldsView :info="section" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClickBoard from '@/components/button/ClickBoard'
export default {
  name: 'SectionFieldsView',
  components: { ClickBoard },
  props: {
    info: {
      type: [Object, null],
      required: true
    }
  },
  data () {
    return {
      open: false
    }
  }
}
</script>

<style scoped lang="less">
.icon-size-30 {
  min-width: 30px;
  width: 30px;
  height: 30px;
}
.icon-size-20 {
  min-width: 20px;
  width: 20px;
  height: 20px;
}
.icon-plus {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAM1JREFUeNrsV1EKgCAMDeleeqSO4NH0JHqDjlASC8xINq1m5GBQfvRe23uyDUNDMYV0IZeH0wHWCXwOqV74UQVYBxLuJfCYhIsPFoaWb5iCW3hjRQnTlhlIUogKAjJ6l6UaGiuqZ0NqeNalH2HXQCfQtA1VRtkSRJieXYnx0qKCYLXUASYBsBmyqtSGFmmx3CWkP6sBSl+pekFVgNJXil7QFaD2FauXfhF1ArfNA7FFs1Z7goChWA09of5yKt4JeIbFxDe1mrEvp2yxCjAA0mZjAAxfvGwAAAAASUVORK5CYII=');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.icon-minus {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAH1JREFUeNpiYBhEoACI7wPxfxrj+1C7MCx/D8QOdPCoA9QuFEfcp5PlyI64jyzwfwCiHGwn00AnvFEHjDpg1AGD1gENNKgDGkgNgUYgZqQSbhxNA0PSAfVUTID1RFWNo9XxqANGHTDqgIF0wIMB6Jg8GFRdswHvnA4YAAgwAJqEbR0ulZUcAAAAAElFTkSuQmCC');
  background-repeat: no-repeat;
  background-position: right center;
  background-size: cover;
}
.section-fields-view {
  text-align: left;
  margin-bottom: 10px;
  &--header{
    display: flex;
    gap: 8px;
    justify-content: space-between;
    align-items: center;
    user-select: none;
    cursor: pointer;
    font-weight: bold;
    background-color: #edf0f4;
    border: 1px solid lightgrey;
    padding: 10px 10px;
    border-radius: 6px;
    margin-bottom: 4px;
  }
  &--content{
    border: 1px solid lightgrey;
    background-color: #f7f7f7;
    border-radius: 6px;
    &-fields {
      margin: 10px;
      &-item {
        border-radius: 6px;
        padding: 6px 10px;
        margin: 5px 0;
        background-color: white;
        border: 1px solid lightgrey;
        &-title {
          //padding: 2px 0;
          font-size: small;
          color: grey;
        }
        &-text {
          //padding: 2px 0;
          color: black;
        }
      }
    }
    >.sub-sections {
      margin: 0 10px 0 20px;
    }
  }
}
</style>
