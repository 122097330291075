<template>
  <v-form ref="form">
    <v-container>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <TwSelect
            v-model="form.type"
            class="tw-mb-[12px]"
            label="Тип документа"
            placeholder="Выбрать"
            :options="allowDocTypesDict"
            dense
            :disable-error-border="true"
            :persistent-label="true"
            :disabled="!!(form && form.id) || formProcessing"
            @input="changeDocType"
          >
            <template #options="{ options, clickToItem, getIsCheckedItem }">
              <li
                v-for="optionItem in options"
                :key="optionItem.value"
                role="option"
                :class="getIsCheckedItem(optionItem) ? 'tw-text-an-flat-red-main tw-bg-an-flat-red-lighten hover:tw-bg-an-flat-red-lighten':''"
                class="tw-leading-normal tw-cursor-pointer tw-min-h-[42px] tw-pl-[18px] tw-pr-[6px] tw-flex tw-gap-[15px] tw-items-center enabled:hover:tw-bg-an-flat-bg-main"
                @click.prevent="optionItem.disabled ? '' : clickToItem(optionItem)"
              >
                <TwCheckBox
                  :multi="false"
                  :radio="true"
                  :disabled="optionItem.disabled"
                  :value="getIsCheckedItem(optionItem)"
                />
                <span :class="optionItem.disabled ? 'tw-cursor-default tw-opacity-50' : ''">
                  {{ optionItem.label }} <small v-if="optionItem.disabled">(Уже добавлен)</small>
                </span>
              </li>
            </template>
          </TwSelect>
          <!--ФОРМЫ ДОКУМЕНТОВ ПО ТИПАМ-->
          <PassportType
            v-if="form.type === 'passportType'"
            ref="passportType"
            v-model="form.fields"
            :form-type="formType"
          />
          <ForeignPassportType
            v-else-if="form.type === 'foreignPassportType'"
            ref="foreignPassportType"
            v-model="form.fields"
            :form-type="formType"
          />
          <SnilsType
            v-else-if="form.type === 'snilsType'"
            v-model="form.fields"
            :form-type="formType"
          />
          <InnType
            v-else-if="form.type === 'innType'"
            v-model="form.fields"
            :form-type="formType"
          />
          <div
            v-else
            class="tw-text-center tw-text-an-flat-disabled-text tw-p-3"
          >
            Поля для заполнения отсутствуют
          </div>
        </v-col>
        <v-col
          v-if="form.type"
          cols="12"
          md="6"
        >
          <template v-if="form.type === 'passportType'">
            <v-row>
              <v-col
                v-for="(page, index) in form.filesPages"
                :key="page.value"
                cols="4"
              >
                <v-card outlined>
                  <SimpleFileUploadAndView
                    v-model="form.filesId[index]"
                    :label="page.label"
                    :file-type="page.value"
                    height="200"
                    width="auto"
                    @detectedPassportFields="setFieldsToPassportForm"
                  />
                </v-card>
              </v-col>
            </v-row>
          </template>
          <template v-else-if="form.type === 'foreignPassportType'">
            <v-row>
              <v-col
                v-for="(page, index) in form.filesPages"
                :key="page.value"
                cols="4"
              >
                <v-card outlined>
                  <SimpleFileUploadAndView
                    v-model="form.filesId[index]"
                    :label="page.label"
                    :file-type="page.value"
                    height="200"
                    width="auto"
                    @detectedPassportFields="setFieldsToPassportForm"
                  />
                </v-card>
              </v-col>
            </v-row>
          </template>
          <UploadFileList
            v-else
            v-model="form.filesId"
            height="100px"
          />
        </v-col>
      </v-row>
    </v-container>

    <EditFormFooter
      :disabled="!isEdited || formProcessing || preset.system"
      :show-reset="false"
      :show-cancel-back="false"
      @submit="handleSubmit"
      @reset="handleReset"
    />
  </v-form>
</template>

<script>
import API from '@/Api/Crm/Person/document'
import PassportType from '@/views/Person/documents/document-types/passportType'
import ForeignPassportType from '@/views/Person/documents/document-types/foreignPassportType'
import SnilsType from '@/views/Person/documents/document-types/snilsType'
import InnType from '@/views/Person/documents/document-types/innType'
import UploadFileList from '@/components/input/file/UploadFileList'
import isEqual from 'lodash/isEqual'
import { formFieldsFromTypes } from '@/views/Person/personDict'
import EditFormFooter from '@/components/form/EditFormFooter'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import SimpleFileUploadAndView from '@/components/input/file/SimpleFileUploadAndView'
import TwSelect from '@/components/tw-ui/ui-kit/input/TwSelect.vue'
import TwCheckBox from '@/components/tw-ui/ui-kit/input/TwCheckBox.vue'

export default {
  name: 'PersonDocumentForm',
  components: { TwCheckBox, TwSelect, InnType, SnilsType, SimpleFileUploadAndView, EditFormFooter, UploadFileList, PassportType, ForeignPassportType },
  mixins: [FormCreateEditMixin],
  props: {
    personId: {
      type: Number,
      required: true
    },
    id: {
      type: Number,
      default: 0
    },
    existDocTypes: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      API,
      form: {
        id: null,
        type: null,
        personId: this.personId,
        filesId: [],
        fields: {},
        filesPages: []
      }
    }
  },
  computed: {
    isEdited () {
      return !isEqual(this.form, this.originalForm)
    },
    allowDocTypesDict () {
      return (Array.isArray(this.dictionary?.type) && this.dictionary.type.map(type => {
        type.disabled = this.existDocTypes.includes(type.value)
        return type
      })) || []
    }
  },
  watch: {
    id (newValue) {
      this.fetchData(newValue)
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    setFieldsToPassportForm (fields) {
      console.warn('setFieldsToPassportForm', fields, this.$refs)
      if (this.$refs?.passportType) {
        this.$refs.passportType.setDetectedFields(fields)
      }
    },
    changeDocType (type) {
      console.warn('changeDocType', type)
      if (type) {
        if (formFieldsFromTypes(type)) {
          this.$set(this.form, 'fields', formFieldsFromTypes(type))
        }

        if (this.dictionary[type]) {
          this.$set(this.form, 'filesPages', this.dictionary[type])
        }
      } else {
        console.error('changeDocType BAD TYPE: ', type)
        this.$set(this.form, 'fields', {})
        this.$set(this.form, 'filesPages', {})
      }
    },
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          const adaperMap = {
            // from(show): to(send)
            person: 'personId',
            files: 'filesId'
          }
          for (const key in this.preset) {
            if (adaperMap[key] !== undefined) {
              if (key === 'person' && (this.preset[key] && this.preset[key].id)) {
                this.$set(this.form, adaperMap[key], this.preset[key].id)
              } else if (key === 'files' && (Array.isArray(this.preset[key]) && this.preset[key].length)) {
                if (this.preset?.type?.value && this.dictionary[this.preset.type.value]) {
                  const filePageTypes = this.dictionary[this.preset.type.value]
                  this.$set(this.form, adaperMap[key], filePageTypes.map(page => {
                    return this.preset[key].find(f => f.type?.value === page.value)?.id || null
                  }))
                } else {
                  this.$set(this.form, adaperMap[key], this.preset[key].map(f => f.id))
                }
              }
            } else {
              if (key === 'type') {
                this.$set(this.form, 'filesPages', this.dictionary[this.preset[key].value])
                this.$set(this.form, key, this.preset[key].value)
              } else if (key === 'fields') {
                // если поля отсутствуют в пресете назначим данные по умолчанию
                if (this.preset.fields && typeof this.preset.fields === 'object' && !Array.isArray(this.preset.fields) && Object.keys(this.preset.fields).length) {
                  this.$set(this.form, 'fields', this.preset.fields)
                } else {
                  this.changeDocType(this.preset.type.value)
                }
              } else {
                this.$set(this.form, key, this.preset[key])
              }
            }
          }
        }
        resolve()
      })
    },
    handleSubmit () {
      if (this.$refs.form.validate()) {
        if (this.formProcessing === true) {
          return
        }

        this.formProcessing = true
        this.form.filesId = this.form.filesId.filter(f => !!f)
        const request = this.id
          ? this.API.update(this.id, this.form)
          : this.API.store(this.form)

        request.then(({ data }) => {
          if (this.formType === 'create') {
            this.$emit('create', data.data)
          } else {
            this.$emit('update', data.data)
          }
        }).finally(() => {
          this.formProcessing = false
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
