<template>
  <div>
    <div
      v-if="loading"
      class="tw-h-full tw-w-full tw-flex tw-justify-center tw-items-center tw-py-5"
    >
      <TwCircleLoader
        height="50px"
        width="50px"
      />
    </div>
    <div v-else>
      <div
        :class="fromPanel ? 'tw-px-[26px] tw-py-[18px]':''"
        class="tw-font-manrope tw-text-main-sm2 tw-max-w-[100%] tw-bg-white tw-min-h-screen"
      >
        <TwPanelTitle v-if="fromPanel">
          <template #left>
            <div class="tw-text-h4 tw-font-bold">
              <div v-if="data">
                <span>
                  Проект ИЖС {{ data.name }}
                </span>
              </div>
              <div
                v-else-if="isNew"
                class="tw-text-h4 tw-text-an-flat-disabled-text"
              >
                Новый проект ИЖС
              </div>
              <div v-else>
                ~
              </div>
            </div>
          </template>
          <template #right>
            <TwPanelActionButton
              :disabled="isNew"
              title="Удаление"
              color="red"
              @click.prevent="deleteCardItem"
            >
              Удалить
            </TwPanelActionButton>
          </template>
        </TwPanelTitle>
        <TwPanelTabsFull
          v-model="currentTab"
          :items="tabList"
        >
          <template #content.main>
            <TwPanelContent>
              <!-- LEFT -->
              <div class="tw-flex-1">
                <TwPanelContentBlock class="tw-mb-[6px]">
                  <template #title>
                    <div>
                      О проекте
                    </div>
                  </template>
                  <template #action>
                    <TwPanelContentBlockActionButton
                      :disabled="isNew"
                      type="edit"
                      @click="editMain = !editMain"
                    >
                      {{ editMain ? 'отменить' : 'изменить' }}
                    </TwPanelContentBlockActionButton>
                  </template>
                  <div>
                    <BuilderProjectForm
                      v-if="editMain"
                      :id="data ? data.id : 0"
                      ref="EditMain"
                      :default-form-fields="defaultFormFields"
                      @create="onCreated"
                      @update="onUpdated"
                      @cancel="onCancelAndClose"
                    />
                    <BuilderProjectShow
                      v-else
                      :dictionary="dictionary"
                      :info="data"
                      @refresh="refresh"
                    />
                  </div>
                </TwPanelContentBlock>
              </div>
              <!-- RIGHT -->
              <div class="tw-flex-1 tw-min-w-[400px]">
                <TwPanelContentBlock
                  v-if="data && !editMain"
                  class="tw-mb-[6px]"
                  :content-classes="''"
                >
                  <template #title>
                    <div>
                      Файлы планировки
                    </div>
                  </template>
                  <div
                    v-if="data.plan && Array.isArray(data.plan.genPlanFiles) && data.plan.genPlanFiles.length"
                    class="tw-flex tw-flex-col tw-items-center"
                  >
                    <div
                      v-for="(file, fIndex) in data.plan.genPlanFiles"
                      :key="file.id"
                      class="tw-p-[6px] tw-bg-white tw-rounded-sm tw-mb-[6px] tw-cursor-pointer"
                    >
                      <img
                        alt="Планировка"
                        :src="getFileResizeByKey(file, 'default')"
                        @click.prevent="viewPlanImage(fIndex)"
                      >
                    </div>
                  </div>
                  <div
                    v-else
                    class="tw-text-center tw-p-3 tw-text-an-flat-disabled-text"
                  >
                    Нет изображений с планами
                  </div>
                </TwPanelContentBlock>
                <TwPanelContentBlock
                  v-if="data && !editMain"
                  class="tw-mb-[6px]"
                  :content-classes="''"
                >
                  <template #title>
                    <div>
                      Файлы проекта
                    </div>
                  </template>
                  <div
                    v-if="Array.isArray(data.galleries) && data.galleries.length"
                    class="tw-flex tw-flex-col tw-items-center"
                  >
                    <div
                      v-for="(file, fIndex) in data.galleries"
                      :key="file.id"
                      class="tw-p-[6px] tw-bg-white tw-rounded-sm tw-mb-[6px] tw-cursor-pointer"
                    >
                      <img
                        alt="Планировка"
                        :src="getFileResizeByKey(file, 'default')"
                        @click.prevent="viewProjectImage(fIndex)"
                      >
                    </div>
                  </div>
                  <div
                    v-else
                    class="tw-text-center tw-p-3 tw-text-an-flat-disabled-text"
                  >
                    Нет изображений проекта
                  </div>
                </TwPanelContentBlock>
              </div>
            </TwPanelContent>
          </template>
        </TwPanelTabsFull>
      </div>
    </div>
  </div>
</template>

<script>
import { getFileResizeByKey } from '@/plugins/helpers/fileHelpers'
import BuilderHouseProjectAPI from '@/Api/ObjectStorage/Builder/project'
import CardMixin from '@/mixins/CardMixin'
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import BuilderProjectShow from '@/views/Newbuildings/Editor/BuilderProjects/main/BuilderProjectShow.vue'
import BuilderProjectForm from '@/views/Newbuildings/Editor/BuilderProjects/main/BuilderProjectForm.vue'

import TwPanelContent from '@/components/tw-ui/panels/TwPanelContent.vue'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwPanelContentBlockActionButton from '@/components/tw-ui/panels/TwPanelContentBlockActionButton.vue'
import TwPanelTabsFull from '@/components/tw-ui/panels/TwPanelTabsFull.vue'
import TwPanelTitle from '@/components/tw-ui/panels/TwPanelTitle.vue'
import TwPanelActionButton from '@/components/tw-ui/panels/TwPanelActionButton.vue'

export default {
  name: 'BuilderProjectCard',
  components: {
    TwPanelActionButton,
    TwCircleLoader,
    TwPanelContent,
    TwPanelContentBlock,
    TwPanelContentBlockActionButton,
    BuilderProjectForm,
    BuilderProjectShow,
    TwPanelTabsFull,
    TwPanelTitle
  },
  mixins: [CardMixin, ViewPanelShowMixin],
  props: {
    builderId: {
      type: [Number, String],
      default: null
    }
  },
  data () {
    return {
      API: BuilderHouseProjectAPI,
      currentTab: 'main'
    }
  },
  methods: {
    getFileResizeByKey,
    postFetch () {
      setTimeout(() => {
        // фиксирую высоту окна
        this.minModalHeight = this.$el.clientHeight + 'px'
      }, 500)
    },
    viewProjectImage (startIndex) {
      this.$store.dispatch('imageViewer/showImages', {
        files: this.data.galleries.map(f => {
          return {
            path: getFileResizeByKey(f, 'big')
          }
        }) || [],
        startIndex
      })
    },
    viewPlanImage (startIndex) {
      this.$store.dispatch('imageViewer/showImages', {
        files: this.data.plan?.genPlanFiles?.map(f => {
          return {
            path: getFileResizeByKey(f, 'big')
          }
        }) || [],
        startIndex
      })
    }
  },
  computed: {
    defaultFormFields () {
      return {
        builderId: this.builderId || null
      }
    },
    tabList () {
      return [
        {
          label: 'Общая информация',
          key: 'main'
        }
      ]
    }
  },
  mounted () {
    this.minModalHeight = this.$el.clientHeight + 'px'
  }
}
</script>
