<template>
  <TwPanelContentBlock
    class="tw-mb-[6px]"
    :content-classes="''"
    no-data-text="В разработке..."
  >
    <template #title>
      <div />
    </template>
    <template #action>
      <TwPanelContentBlockActionButton
        type="edit"
        size="md"
        :class="[createBtnStyle, 'tw-font-bold']"
        @click.prevent="createNewDiscountMatrix"
      >
        Создать новую матрицу
      </TwPanelContentBlockActionButton>
    </template>
    <div>
      <TwTableBase
        schema-key="DiscountMatrixInTabRequirement"
        :loading-error-text="loadingErrorText"
        tbody-classes="tw-text-main-sm2"
        :headers="columns"
        :items="data"
        :loading="loading"
        :pagination="pagination"
        @headersUpdate="columns = $event"
        @row-db-click="doubleRowClick($event, { item })"
        @changePagination="changePagination"
      >
        <!--  Название  -->
        <template #item.name="{ item }">
          <ViewPanelLink
            :id="item.id"
            label="Матрица скидок"
            component="discount-matrix-show"
            :callback="fetch"
          >
            {{ item.name }}
          </ViewPanelLink>
        </template>

        <!--  Кол-во записей  -->
        <template #item.itemsCount="{ item }">
          {{ item.itemsCount || '~' }}
        </template>

        <!--   Субс.ставка с эл. рег.   -->
        <template #item.discountFromElectronRegistration="{ item }">
          <BooleanViewIcon
            :value="!!item.discountFromElectronRegistration"
            small
          />
        </template>
      </TwTableBase>
    </div>
  </TwPanelContentBlock>
</template>

<script>
import API from '@/Api/Mortgage/DiscountMatrix/index'
import DataListMixin from '@/mixins/DataListMixin'
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import BooleanViewIcon from '@/components/tw-ui/ui-kit/icons/BooleanViewIcon.vue'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink'
import tableOptions from './tableOptions'
import TwTableBase from '@/components/tw-ui/table/TwTableBase.vue'
import { button } from '@/utils/tw-classes'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwPanelContentBlockActionButton from '@/components/tw-ui/panels/TwPanelContentBlockActionButton.vue'

export default {
  name: 'DiscountMatrixList',
  components: {
    TwPanelContentBlockActionButton,
    TwPanelContentBlock,
    TwTableBase,
    ViewPanelLink,
    BooleanViewIcon
  },
  mixins: [DataListMixin, ViewPanelShowMixin],
  props: {
    companyId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      API,
      columns: tableOptions,
      defaultFilters: {
        companyId: this.companyId,
        limit: 999
      },
      createBtnStyle: button.pageListHeaderCreate
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    createNewDiscountMatrix () {
      this.showDiscountMatrixCard({
        id: 0,
        companyId: this.companyId
      }, () => this.fetch())
    }
  }
}
</script>

<style scoped>

</style>
