import { RouteKeys } from '@/router/types'
import dictPermissionRules from '@/Permissions/dictRules'

export default {
  path: RouteKeys.builder,
  name: RouteKeys.builder,
  redirect: { name: `${RouteKeys.builder}-list` },
  meta: { name: 'Застройщики', icon: 'briefcase', ruleNamespace: dictPermissionRules.AppServicePermissionRulesCRMCompanyDeveloperEditCompanyDeveloperRule },
  component: () => import('@/views/ObjectStorage/Index.vue'),
  children: [
    {
      path: 'list',
      name: `${RouteKeys.builder}-list`,
      meta: { name: 'Застройщики', icon: 'home', hideInMenu: true },
      component: () => import('@/views/Newbuildings/Builder/List.vue')
    }
  ]
}
