import general from './general'
import mortgage from './mortgage'
import lead from './lead'
import deal from './deal'
import messenger from './messenger'
import tagMessage from './tag-message'

export default {
  path: 'dictionary',
  redirect: { name: 'company-list' },
  name: 'dictionary',
  meta: { name: 'Справочники', icon: 'directories' },
  component: () => import('@/views/Dictionary/Index.vue'),
  children: [
    general,
    tagMessage,
    deal,
    lead,
    mortgage,
    messenger
  ]
}
