import { Module } from 'vuex'
import { RootState } from '@/store/types'
import { SocketsState } from '@/store/sockets/types'
import { chat } from './modules/chat'
import { lead } from './modules/lead'
import { notification } from './modules/notification'
import { kontur } from './modules/kontur'
import { SOCKET_EVENT_MAP } from '@/plugins/SocketIoConfig'

const namespaced = true
/*
* Пример отправки события из компонентов VUE
* this.$socket.emit('user', { id: 100, token: 'none' })
* this.$socket.emit('message', 'hello')
*/
export const sockets: Module<SocketsState, RootState> = {
  namespaced,
  state: {
    socketAuthStatus: false,
    isConnected: false
  },
  actions: {
    SOCKET_connect: function ({ commit, state }, arg: any) {
      console.warn('SOCKET_connect', arg, state)
      commit('CHANGE_CONNECTED', true)
    },
    SOCKET_disconnect: function ({ commit }, arg: any) {
      console.warn('SOCKET_disconnect', arg)
      commit('CHANGE_CONNECTED', false)
    },
    // Событие получения статус авторизации пользователя(после проверки токена на socket сервере)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    [`SOCKET_${SOCKET_EVENT_MAP.IN.auth}`]: function ({ commit, state }, socketAuthStatus) {
      // console.warn(SOCKET_EVENT_MAP.IN.auth, socketAuthStatus)
      commit('CHANGE_AUTH_STATUS', !!socketAuthStatus)
    }
  },
  mutations: {
    CHANGE_CONNECTED (state, payload) {
      state.isConnected = payload
    },
    CHANGE_AUTH_STATUS (state, payload) {
      state.socketAuthStatus = !!payload
    }
  },
  getters: {
    isSocketConnected (state) {
      return state.isConnected
    },
    isSocketAuthStatus (state) {
      return state.socketAuthStatus
    }
  },
  modules: {
    kontur,
    notification,
    lead,
    chat
  }
}
