import { Module } from 'vuex'
import { RootState } from '@/store/types'
import { NotifyState, NotifyItem } from '@/store/notify/types'
import { generateUUID } from '@/utils/helper'

const namespaced = true

export const notify: Module<NotifyState, RootState> = {
  namespaced,
  state: {
    list: []
  },
  actions: {},
  mutations: {
    ADD (state, payload: NotifyItem) {
      // console.warn(state, payload)
      payload.uid = generateUUID()
      payload.visible = true
      payload.timestamp = new Date()
      state.list.unshift(payload)
    },
    HIDE (state, item: NotifyItem) {
      const targetIndex = state.list.findIndex(i => i.uid === item.uid)
      state.list[targetIndex].visible = false
    },
    HIDE_ALL (state) {
      let counter = 0
      for (const i in state.list) {
        counter += 1
        setTimeout(() => {
          state.list[i].visible = false
        }, counter * 10)
      }
    }
  },
  getters: {
    allVisibleItems (state) {
      return state.list.filter(item => item.visible)
    }
  }
}
