import { TableTypes } from '@/components/tw-ui/table/TableTypes'

const options: TableTypes = [
  {
    text: '',
    value: 'menu',
    dataKey: 'menu',
    colStyle: 'width: 20px;',
    thClass: 'tw-px-[22px] tw-pt-[11px] tw-pb-[10px] tw-border-x tw-bg-an-flat-bg-main tw-text-left tw-text-an-flat-black-table tw-text-table-header tw-whitespace-nowrap',
    tdClass: 'tw-px-[16px] tw-py-[12px] tw-border-t tw-border-x tw-border-opacity tw-text-table-body tw-text-center tw-text-an-flat-black-table tw-whitespace-nowrap'
  },
  {
    text: 'Название',
    value: 'name',
    dataKey: 'name',
    colStyle: 'width: auto',
    thClass: 'tw-px-[22px] tw-pt-[11px] tw-pb-[10px] tw-border-x tw-bg-an-flat-bg-main tw-text-left tw-text-an-flat-black-table tw-text-table-header tw-whitespace-nowrap',
    tdClass: 'tw-px-[22px] tw-py-[12px] tw-border-t tw-border-x tw-border-opacity tw-text-table-body tw-text-center tw-text-an-flat-black-table tw-whitespace-nowrap'
  },
  {
    text: 'Адрес',
    value: 'address',
    dataKey: 'address',
    colStyle: 'width: auto',
    thClass: 'tw-px-[22px] tw-pt-[11px] tw-pb-[10px] tw-border-x tw-bg-an-flat-bg-main tw-text-left tw-text-an-flat-black-table tw-text-table-header tw-whitespace-nowrap',
    tdClass: 'tw-px-[22px] tw-py-[12px] tw-border-t tw-border-x tw-border-opacity tw-text-table-body tw-text-center tw-text-an-flat-black-table tw-whitespace-nowrap'
  },
  {
    text: 'Телефон',
    value: 'phone',
    dataKey: 'phone',
    colStyle: 'width: auto',
    thClass: 'tw-px-[22px] tw-pt-[11px] tw-pb-[10px] tw-border-x tw-bg-an-flat-bg-main tw-text-left tw-text-an-flat-black-table tw-text-table-header tw-whitespace-nowrap',
    tdClass: 'tw-px-[22px] tw-py-[12px] tw-border-t tw-border-x tw-border-opacity tw-text-table-body tw-text-center tw-text-an-flat-black-table tw-whitespace-nowrap'
  },
  {
    text: 'На сайте',
    value: 'public',
    align: 'left',
    dataKey: 'public',
    colStyle: 'width: 150px',
    thClass: 'tw-px-[22px] tw-pt-[11px] tw-pb-[10px] tw-border-x tw-bg-an-flat-bg-main tw-text-left tw-text-an-flat-black-table tw-text-table-header tw-whitespace-nowrap',
    tdClass: 'tw-px-[22px] tw-py-[12px] tw-border-t tw-border-x tw-border-opacity tw-text-table-body tw-text-center tw-text-an-flat-black-table tw-whitespace-nowrap'
  }
]

export default options
