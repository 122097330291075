<template>
  <TwPanelContentBlock>
    <template #title>
      <div>
        Переписка с сотрудником банка:
      </div>
    </template>
    <template #action>
      <div>
        <span
          v-if="!UserEmailConfigs.length"
          class="ml-2 error--text"
        >
          Почта не настроена
          <v-btn
            class="ma-2"
            small
            outlined
            @click="showProfile"
          >Открыть профиль</v-btn>
        </span>
        <span
          v-else-if="info && !info.contact"
          class="ml-2 error--text"
        >
          Выберите контакт сотрудника банка
        </span>
        <button
          v-else
          class="tw-py-2 tw-px-3 tw-border tw-border-solid tw-rounded-sm tw-border-an-flat-green tw-text-an-flat-green"
          :disabled="(info && !info.contact) || loading || createDraftProcess"
          @click="createDraft"
        >
          {{ createDraftProcess ? 'Создаю черновик письма' : 'Создать черновик письма' }}
        </button>
      </div>
    </template>
    <template #default>
      <v-card-text v-if="info">
        <v-simple-table
          fixed-header
          height="500px"
          style="border: 1px solid lightgray"
        >
          <template #default>
            <thead>
              <tr>
                <th class="text-left">
                  Дата
                </th>
                <th class="text-left">
                  От кого / Кому
                </th>
                <th class="text-left">
                  Тема письма
                </th>
                <th class="text-center">
                  Состояние
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="info && !info.contact">
                <td
                  colspan="99"
                  class="text-muted text-center"
                >
                  Выберите контакт для создания письма банку
                </td>
              </tr>
              <template v-else-if="Array.isArray(emailList) && emailList.length">
                <tr
                  v-for="(email, email_index) in emailList"
                  :key="email.id"
                >
                  <td @click="openEmailCard(email)">
                    <div class="text-no-wrap">
                      <div>
                        <small>{{ $Global.DateFormat.DateTimeShort(email.dateMailBox || email.updatedAt) }}</small>
                      </div>
                      <div v-if="email.status && email.status.value === 'send'">
                        <v-icon
                          :color="email.read ? 'green' : 'secondary'"
                          x-small
                        >
                          {{ email.read ? 'fa-check' : 'fa-clock-o' }}
                        </v-icon>
                        <small>
                          {{ email.read ? 'Прочитано' : 'Ожидание прочтения' }}
                        </small>
                        <div v-if="email.read && email.dateRead">
                          <small>{{ $Global.DateFormat.DateTimeShort(email.dateRead) }}</small>
                        </div>
                      </div>
                    </div>
                  <!--                <small class="caption primary&#45;&#45;text">{{email.status.label}}</small>-->
                  </td>
                  <td @click="openEmailCard(email)">
                    <div v-if="email.contacts">
                      <EmailFromToShow
                        font-size="12px"
                        :email-folder="email.emailFolder"
                        :email-config-contact="email.emailConfigContact"
                        :contacts="email.contacts"
                      />
                    </div>
                  </td>
                  <td @click="openEmailCard(email)">
                    <small>{{ email.theme }}</small>
                  </td>
                  <td class="text-center">
                    <div
                      v-if="email.status"
                      class="d-flex justify-center"
                    >
                      <v-btn
                        v-if="email.status.value === 'draft'"
                        :disabled="sendingDraftProcess.includes(email_index)"
                        x-small
                        :outlined="email.status.value === 'draft'"
                        elevation="0"
                        color="primary"
                        @click.stop="sendDraft(email.id, email_index)"
                      >
                        <template v-if="email.status.value === 'draft'">
                          <v-progress-linear
                            v-if="sendingDraftProcess.includes(email_index)"
                            indeterminate
                            color="primary darken-2"
                          />
                          <span v-else>Отправить</span>
                        </template>
                      </v-btn>
                      <div
                        v-else-if="email.status.value === 'send'"
                        class="d-flex flex-nowrap caption"
                      >
                        <v-icon
                          color="success"
                          class="mr-2"
                          left
                        >
                          fa-share
                        </v-icon>
                        Отправлено
                      </div>
                      <div
                        v-else-if="email.status.value === 'got'"
                        class="d-flex flex-nowrap caption"
                      >
                        <v-icon
                          color="primary"
                          class="mr-2"
                          left
                        >
                          fa-reply
                        </v-icon>
                        Получено
                      </div>
                      <div
                        v-else-if="email.status.value.indexOf('error') !== -1"
                        class="d-flex flex-nowrap caption"
                        :title="email.status.label"
                      >
                        <v-icon
                          color="error"
                          class="mr-2"
                          left
                        >
                          fa-exclamation-triangle
                        </v-icon>
                        Ошибка
                      </div>
                      <div
                        v-else
                        class="d-flex flex-nowrap caption"
                        title="Письмо обрабатывается, пожалуйста подождите"
                      >
                        <ACircleLoader :size="20" />
                        <span class="ml-2">Обработка</span>
                      </div>
                      <v-btn
                        v-if="email.status.value === 'draft'"
                        class="ml-2"
                        :disabled="sendingDraftProcess.includes(email_index)"
                        x-small
                        :outlined="true"
                        elevation="0"
                        color="error"
                        @click.stop="removeEmail(email.id, email_index)"
                      >
                        <v-icon x-small>
                          fa-trash
                        </v-icon>
                      </v-btn>
                    </div>
                  </td>
                </tr>
              </template>
              <tr v-else>
                <td
                  colspan="99"
                  class="text-muted text-center"
                >
                  Нет писем
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      <v-overlay :value="createDraftProcess">
        <div class="text-center">
          <v-progress-circular
            indeterminate
            size="64"
          />
          <div class="mt-2">
            Черновик создаётся!<br>
            Пожалуйста подождите...
          </div>
        </div>
      </v-overlay>
    </template>
  </TwPanelContentBlock>
</template>

<script>
import emailsAPI from '@/Api/Email/Mail'
import API from '@/Api/Crm/Deal/Mortgage/Program'
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import EmailFromToShow from '@/views/Email/main/EmailFromToShow'
import { mapGetters } from 'vuex'
import ACircleLoader from '@/components/filters/afilter/fields/aicons/ACircleLoader'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'

export default {
  name: 'MortgageProgramWorksheetMainEmails',
  components: { TwPanelContentBlock, ACircleLoader, EmailFromToShow },
  mixins: [ViewPanelShowMixin],
  props: {
    info: {
      type: Object
    },
    loading: {
      type: Boolean,
      default: false
    },
    worksheetId: {
      type: [String, Number]
    }
  },
  data () {
    return {
      checkInterval: null,
      loadEmails: false,
      emailList: [],
      createDraftProcess: false,
      sendingDraftProcess: []
    }
  },
  methods: {
    openEmailCard (email) {
      this.showEmailCard({ id: email.id, forDeal: this.info.deal || null }, () => {
        this.refresh()
      })
    },
    showProfile () {
      this.showMyProfilePanel({}, () => {
        this.refresh()
      })
    },
    refresh () {
      this.fetchEmails()
    },
    removeEmail (id, emailIndex) {
      if (this.sendingDraftProcess.includes(emailIndex) || !window.confirm('Удалить черновик?')) return
      this.sendingDraftProcess.push(emailIndex)
      emailsAPI.delete(id)
        .then((data) => {
          console.warn('removeEmail:', data)

          this.$store.commit('notify/ADD', {
            title: 'Черновик удалён',
            type: 'success',
            timeout: 3000,
            visible: true
          })
          this.refresh()
        })
        .catch((error) => {
          console.error('removeEmail:', error)
        })
        .finally(() => {
          this.sendingDraftProcess.splice(emailIndex)
        })
    },
    sendDraft (id, emailIndex) {
      if (this.sendingDraftProcess.includes(emailIndex)) return
      this.sendingDraftProcess.push(emailIndex)
      emailsAPI.send_draft(id)
        .then((data) => {
          console.warn('sendDraft:', data)

          this.$store.commit('notify/ADD', {
            title: 'Черновик отправлен',
            type: 'success',
            timeout: 3000,
            visible: true
          })
          this.refresh()
        })
        .catch((error) => {
          console.error('sendDraft:', error)
        })
        .finally(() => {
          this.sendingDraftProcess.splice(emailIndex)
        })
    },
    createDraft () {
      if (this.createDraftProcess) return
      this.createDraftProcess = true
      API.draftEmail(this.info.id, {
        contactId: this.info.contactId
      })
        .then((data) => {
          console.warn('createDraft:', data)

          this.$store.commit('notify/ADD', {
            title: 'Создан черновик',
            type: 'success',
            timeout: 3000,
            visible: true
          })
          this.refresh()
        })
        .catch((error) => {
          console.error('createDraft:', error)
        })
        .finally(() => {
          this.createDraftProcess = false
        })
    },
    fetchEmails () {
      this.loadEmails = true
      // console.warn('loadEmails')
      emailsAPI.getListByEntity({
        entityId: this.worksheetId,
        entity: 'deal_mortgage',
        sortDirection: 'desc',
        sortField: 'createdAt'
      })
        .then(({ data }) => {
          // console.warn(data)
          this.emailList = data?.data || []
        })
        .catch((error) => {
          console.error(this.$options.name, error)
          this.emailList = []
        })
        .finally(() => {
          this.loadEmails = false
        })
    }
  },
  computed: {
    ...mapGetters({
      UserEmailConfigs: 'profile/UserEmailConfigs'
    })
  },
  created () {
    this.checkInterval = setInterval(this.fetchEmails, 5000)
  },
  mounted () {
    this.refresh()
  },
  beforeDestroy () {
    if (this.checkInterval) {
      console.warn('clearInterval')
      clearInterval(this.checkInterval)
    }
  }
}
</script>
