import { render, staticRenderFns } from "./TwSidebarMainMenuItem.vue?vue&type=template&id=576aea18&scoped=true"
import script from "./TwSidebarMainMenuItem.vue?vue&type=script&lang=js"
export * from "./TwSidebarMainMenuItem.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "576aea18",
  null
  
)

export default component.exports