<template>
  <v-app>
    <!--  Интерфейс оповещения об обновлении приложения  -->
    <TwUpdateAlert />

    <!--  Всплывающие Уведомлнеия  -->
    <NoficationGlobalView />

    <!--  Iframe - показываем без хедера и меню  -->
    <v-main
      v-if="$route.name && $route.name.includes('iframe-')"
      id="iframe-page-view"
      style="min-height: 100vh; overflow: hidden"
    >
      <!--   Контейнер компонента представления iframe   -->
      <v-container
        fluid
        fill-height
        class="pa-0"
        style="min-height: 100vh"
      >
        <div
          id="scrolling-content"
          style=" width: 100%;height: 100%;max-height: 100vh;overflow: auto;"
        >
          <router-view />
        </div>
      </v-container>
      <!--  Панели карточек   -->
      <ViewPanelWrapper style="min-height: 100vh" />
      <!--  Подключение просмотрщика изображений  -->
      <ImageViewer />
    </v-main>

    <!-- Интерфейсы авторизованного пользователя  -->
    <template v-else-if="isAuth">
      <ViewPanelWrapper />
      <TwMainLayout>
        <template #sidebar="{ isMiniLeftPanel }">
          <TwSidebar :is-mini-left-panel="isMiniLeftPanel" />
        </template>
        <template #toolbar="{ topUiHeightClass }">
          <TwTopToolbar :top-ui-height-class="topUiHeightClass" />
        </template>
        <template #content="{ topUiHeightClass }">
          <TwBasicContentWrapper :top-ui-height-class="topUiHeightClass">
            <div v-show="!loading && !error">
              <router-view />
              <!--  Подключение просмотрщика изображений  -->
              <ImageViewer />
            </div>
            <div
              v-if="error && !loading"
              style="place-self: flex-start; width: 100%; height: 100%"
            >
              <ErrorFetch />
            </div>
            <div v-if="loading">
              Загрузка...
            </div>
          </TwBasicContentWrapper>
        </template>
      </TwMainLayout>
      <!--      GLOBAL MODAL MESSENGER-->
      <!--      <MessengerGlobalModal v-if="isRouter"/>-->
    </template>

    <!-- Интерфейсы для не авторизованных  -->
    <template v-else>
      <TwResetView
        v-if="$route.name === 'reset_password'"
        id="reset_password"
      />
      <TwRegistrationView
        v-else-if="$route.name === 'registration'"
        id="registration-page"
      />
      <TwLoginView
        v-else-if="!isAuth"
        id="login-page"
      />
    </template>
  </v-app>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'

import TwUpdateAlert from '@/components/tw-ui/TwUpdateAlert.vue'
import /* webpackChunkName: "TwLoginView", webpackPreload: true */ TwLoginView from '@/components/tw-ui/un-auth-ui/TwLoginView.vue'
import /* webpackChunkName: "TwRegistrationView", webpackPreload: true */ TwRegistrationView from '@/components/tw-ui/un-auth-ui/TwRegistrationView.vue'
import /* webpackChunkName: "TwResetView", webpackPreload: true */ TwResetView from '@/components/tw-ui/un-auth-ui/TwResetView.vue'
import/* webpackChunkName: "TwMainLayout", webpackPreload: true */ TwMainLayout from '@/components/tw-ui/TwMainLayout.vue'
import /* webpackChunkName: "TwSidebar", webpackPreload: true */ TwSidebar from '@/components/tw-ui/TwSidebar.vue'
import /* webpackChunkName: "TwTopToolbar", webpackPreload: true */ TwTopToolbar from '@/components/tw-ui/toolbar/TwTopToolbar.vue'
import /* webpackChunkName: "TwBasicContentWrapper", webpackPreload: true */ TwBasicContentWrapper from '@/components/tw-ui/TwBasicContentWrapper.vue'
import /* webpackChunkName: "ImageViewer", webpackPreload: true */ ImageViewer from '@/components/core/GlobalImageViewer/ImageViewer'

import NoficationGlobalView from '@/components/tw-ui/notify/NoficationGlobalView.vue'
import ErrorFetch from '@/views/ErrorFetch.vue'
import ViewPanelWrapper from '@/components/core/ViewPanel/ViewPanelWrapper.vue'
// import MessengerGlobalModal from '@/views/Messenger/MessengerGlobalModal'
import { SOCKET_EVENT_MAP } from '@/plugins/SocketIoConfig'

export default {
  name: 'App',
  components: {
    NoficationGlobalView,
    TwResetView,
    TwLoginView,
    TwRegistrationView,
    TwUpdateAlert,
    TwBasicContentWrapper,
    TwTopToolbar,
    TwSidebar,
    TwMainLayout,
    ViewPanelWrapper,
    ImageViewer,
    ErrorFetch
  },
  data: () => ({
    breadcrumbs: []
  }),
  methods: {
    ...mapActions({
      fetchFilterSchema: 'aFilter/fetchFilterSchema',
      fetchLeadStatusList: 'tmpCache/fetchLeadStatusList',
      fetchDealStatusList: 'tmpCache/fetchDealStatusList'
    }),
    // Проверка авторизации по токену на socket сервере
    checkUserSocketAuthStatus () {
      // console.warn('+++++ checkUserSocketAuthStatus', this.currentActiveUserForSocket, this.$socket)
      // Только если Socket соединение активно
      if (this.$socket.connected) {
        // После соединения отправляю данные пользователя на проверку авторизации
        this.$socket.emit(SOCKET_EVENT_MAP.OUT.userAuth, {
          id: this.currentActiveUserForSocket?.id || 0,
          token: this.currentActiveUserForSocket?.token || ''
        })
      }
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'profile/isAdmin',
      currentActiveUserForSocket: 'profile/currentActiveUser',
      isSocketConnected: 'sockets/isSocketConnected',
      isAuth: 'profile/isAuth',
      loading: 'getPageLoadingStatus',
      error: 'getPageErrorStatus',
      panels: 'viewPanels/getPanels'
    }),
    // GLOBAL MESSENGER
    isRouter () {
      const statusUrl = this.$route.path !== '/messenger'
      return statusUrl
    },
    // GLOBAL MESSENGER
    scrollContainerStyles () {
      return {
        // backgroundColor: 'white',
        // height: 'calc(100vh - 50px)',
        height: '100%',
        maxHeight: 'calc(100vh - 50px)',
        overflow: Array.isArray(this.panels) && this.panels.length ? 'hidden' : 'auto'
      }
    }
  },
  watch: {
    isAdmin (newValue) {
      Vue.prototype.$isAdmin = !!newValue
    },
    // При изменении данных активного пользователя
    // Или при изменении статуса соединения socket'ов
    currentActiveUserForSocket: {
      handler () {
        this.checkUserSocketAuthStatus()
      },
      immediate: true,
      deep: true
    },
    isSocketConnected () {
      this.checkUserSocketAuthStatus()
    },
    // ---------------------------------------------
    isAuth (newValue) {
      if (newValue) {
        this.fetchFilterSchema()
        // получить и закешировать список статусов лида TODO и зачем ЭТО????
        this.fetchLeadStatusList()
        this.fetchDealStatusList()
      }
    }
  }
}
</script>

<style lang="less">
@import "assets/less/app.less";
@import "~@/assets/scss/_vuetify-customization-variables.scss";
</style>
