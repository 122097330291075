import { AxiosResponse } from 'axios'
import HTTP from '@/Api/http'
import { AuthAxiosDefaultConfig } from '@/Api/ObjectStorage'

const path = '/fias/fiasObject'

export default {
  /*
  * @deprecated сервис удалён - альтенативы нет
  * */
  search (param?: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig,
      params: param
    }
    return HTTP.get(`${path}`, $config)
  },
  /*
  * @deprecated сервис удалён - альтенативы нет
  * */
  searchNew (param?: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig,
      params: param
    }
    return HTTP.get(`${path}/search`, $config)
  },
  /*
  * @deprecated сервис удалён - альтенативы нет
  * */
  objectSearch (param?: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig,
      params: param
    }
    return HTTP.get(`/object${path}`, $config)
  },
  /*
  * @deprecated сервис удалён - альтенативы нет
  * */
  fiasObject (param?: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig,
      params: param
    }
    return HTTP.get(path, $config)
  },
  // краткая информация по фиас объекту - используется для получения инфы по улицам
  /*
  * @deprecated сервис удалён - альтенативы нет
  * */
  fiasObjectShort (id: string, param?: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig,
      params: param
    }
    return HTTP.get(`${path}/${id}/short-info`, $config)
  },
  /*
  * @deprecated сервис удалён - альтенативы нет
  * */
  fiasDistrictShort (id: string, param?: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig,
      params: param
    }
    return HTTP.get(`/fias/district/${id}/short-info`, $config)
  }
}
