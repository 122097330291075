<template>
  <div
    v-cloak
    v-if="info"
    class="tw-flex tw-gap-[12px] tw-flex-col"
  >
    <TwPanelContentBlockFieldView>
      <template #title>
        Тип
      </template>
      <div v-if="info.type">
        {{ info.type.label }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Название
      </template>
      <div v-if="info.name">
        {{ info.name }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Эксклюзивный
      </template>
      <div>
        {{ info.isExclusive ? 'Да' : 'Нет' }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Ссылка на карточку для риелтора
      </template>
      <div v-if="info.id">
        <ViewPanelLink
          v-if="info.id"
          :id="info.id"
          component="realty-complex-show-page"
        >
          Ссылка на карточку для риелтора
        </ViewPanelLink>
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView v-if="!isCottageVillage">
      <template #title>
        Застройщик
      </template>
      <div v-if="info.builder">
        <ViewPanelLink
          :id="info.builder.id"
          label="Застройщик"
          component="realty-builder-show"
        >
          {{ info.builder.name || info.builder.code }}
        </ViewPanelLink>
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView v-if="!!info.link && !isCottageVillage">
      <template #title>
        Ссылка на сайт застройщика
      </template>
      <div
        v-if="info.code"
        class="tw-truncate"
        :title="`${info.link}`"
      >
        <a
          :href="`${info.link}`"
          target="_blank"
          class="other-fake-link"
        >{{ `${info.link}` }}</a>
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Скрытие цены
      </template>
      <div>
        <span class="tw-text-an-flat-disabled-text">ЖК: </span>{{ info.hidePrice ? 'Да' : 'Нет' }}
        <div v-if="info.builder">
          <span class="tw-text-an-flat-disabled-text">Застройщик: </span>{{ info.builder.hidePrice ? 'Да' : 'Нет' }}
        </div>
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView no-data-text="Не загружен">
      <template #title>
        Генеральный план
      </template>
      <div v-if="info.genPlanFile">
        <img
          :src="info.genPlanFile.url"
          alt="Генеральный план"
          @click="viewImage(info.genPlanFile.url)"
        >
      </div>
    </TwPanelContentBlockFieldView>

    <TwPanelContentBlockFieldView v-if="info.active">
      <template #title>
        Ссылка на сайте
      </template>
      <div
        v-if="info.code"
        class="tw-truncate"
        :title="`${siteUrl}/novostroyki/${info.code}`"
      >
        <a
          :href="`${siteUrl}/novostroyki/${info.code}`"
          target="_blank"
          class="other-fake-link"
        >{{ `${siteUrl}/novostroyki/${info.code}` }}</a>
      </div>
    </TwPanelContentBlockFieldView>

    <TwPanelContentBlockFieldView>
      <template #title>
        Учавствует в подборе и показывается на сайте
      </template>
      <div>
        {{ info.active ? 'Да' : 'Нет' }}
      </div>
    </TwPanelContentBlockFieldView>
    <!--  Свойства -->
    <RealtyObjectPropsShow
      :properties="info.properties"
      :scheme="scheme"
    />
  </div>
</template>

<script>
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink'
import RealtyObjectPropsShow from '@/views/Newbuildings/Editor/RealtyObjectPropsShow.vue'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'
import { SERVICES } from '@/Api/Services'

export default {
  name: 'RealtyComplexMainShow',
  components: {
    TwPanelContentBlockFieldView,
    RealtyObjectPropsShow,
    ViewPanelLink
  },
  mixins: [ViewPanelShowMixin],
  props: {
    info: {},
    scheme: {},
    dictionary: {}
  },
  computed: {
    siteUrl () {
      return SERVICES.SITE.host
    },
    isCottageVillage () {
      return this.info?.type?.value === 'cottage_village'
    }
  },
  methods: {
    viewImage (url) {
      this.$store.dispatch('imageViewer/showImages', {
        files: [
          {
            path: url
          }
        ],
        startIndex: 0
      })
    }
  }
}
</script>
