<template>
  <section>
    <h3 class="tw-mb-[32px] tw-text-an-flat-black-main tw-font-bold tw-text-[32px] tw-leading-[44px]">
      Ход строительства
    </h3>
    <div v-if="arrayAgesProgressConstruction">
      <div class="tw-mb-[32px] tw-max-w-[280px]">
        <TwSelect
          v-model="dateProgress"
          label="Выберите дату"
          :options="arrayAgesProgressConstruction"
        />
      </div>
      <div
        v-if="imagesListProgress.length"
        class="tw-flex-1 tw-max-w-[40%]"
      >
        <div class="tw-min-w-[500px]">
          <TwImagesGallery
            name="complex-present-page-cp"
            :images="imagesListProgress"
            show-navigation
            :loop="false"
            :centered-slides="true"
            :slides-per-view="1"
          />
        </div>
      </div>
    </div>
    <div
      v-else
      class="tw-text-an-flat-disabled-text"
    >
      Не добавлен
    </div>
  </section>
</template>

<script>
import { getFileResizeByKey } from '@/plugins/helpers/fileHelpers'
import TwSelect from '@/components/tw-ui/ui-kit/input/TwSelect.vue'
import TwImagesGallery from '@/components/tw-ui/gallery/TwImagesGallery.vue'

export default {
  name: 'PageComplexBlockProgressConstruction',
  props: {
    complexData: {
      type: Object,
      default: null
    }
  },
  components: { TwSelect, TwImagesGallery },
  data () {
    return {
      dateTime: '',
      dateProgress: null
    }
  },
  computed: {
    imagesListProgress () {
      let result = []
      if (this.complexData.constructionProgress) {
        console.log(this.dateProgress === null)
        let objectDate = []
        if (!this.dateProgress && this.arrayAgesProgressConstruction.length) {
          objectDate = this.complexData.constructionProgress.filter(item => item.date === this.arrayAgesProgressConstruction[0].value)
        } else {
          objectDate = this.complexData.constructionProgress.filter(item => item.date === this.dateProgress)
        }
        if (objectDate.length) {
          result = objectDate[0].files.filter(galleryImage => galleryImage.mime.indexOf('image') !== -1).map((image) => {
            return {
              thumb: getFileResizeByKey(image, 'default'),
              original: getFileResizeByKey(image, 'big')
            }
          }) || []
        }
      }
      return result
    },
    arrayAgesProgressConstruction () {
      const arrayObjectDate = this.complexData?.constructionProgress?.map((item) => {
        const isDate = new Date(item.date)
        return {
          label: `${isDate.getDate()}.${(isDate.getMonth() + 1) < 10 ? '0' + (isDate.getMonth() + 1) : (isDate.getMonth() + 1)}.${isDate.getFullYear()}`,
          value: item.date
        }
      })
      return arrayObjectDate
    }
  },
  watch: {
    arrayAgesProgressConstruction: {
      handler (newValue) {
        if (!this.dateProgress && Array.isArray(newValue) && newValue.length) {
          this.dateProgress = newValue[0].value
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>
