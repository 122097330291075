<template>
  <div class="tw-font-manrope">
    <div style="display: none">
      {{ isEdited }}
    </div>
    <TwFlexCol>
      <TwFlexCell>
        <TwSelect
          v-model="form.status"
          label="Выбор статуса"
          placeholder="Выбрать"
          :options="dictionary.status"
          :dense="true"
          :disabled="!form.contactId || formProcessing"
          :persistent-label="true"
        />
      </TwFlexCell>
      <TwFlexCell>
        <TwTextarea
          v-model="form.comment"
          rows="3"
          label="Комментарий"
          placeholder="Введите комментарий"
          maxlength="250"
          show-length
          persistent-label
          :disabled="formProcessing"
          :show-label="true"
        />
      </TwFlexCell>
      <TwFlexCell>
        <PersonSelectAdditionalContact
          v-if="targetCompany"
          v-model="form.contactId"
          required
          class="mt-3"
          :company="targetCompany"
        />
      </TwFlexCell>
    </TwFlexCol>

    <TwCardSimpleFormSaveBlock
      class="tw-mt-[15px]"
      :loading="formProcessing"
      :cancel-confirm="true"
      :is-sticky="true"
      :disable-success="formProcessing"
      :disable-cancel="formProcessing"
      :visible="true"
      @success="handleSubmit"
      @cancel="handleCancel"
    />
  </div>
</template>

<script>
import API from '@/Api/Crm/Deal/Mortgage/Program'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import PersonSelectAdditionalContact from '@/components/select/person/PersonSelectAdditionalContact'
import TwCardSimpleFormSaveBlock from '@/components/tw-ui/forms/TwCardSimpleFormSaveBlock.vue'
import TwFlexCol from '@/components/tw-ui/ui-kit/flex-block/TwFlexCol.vue'
import TwFlexCell from '@/components/tw-ui/ui-kit/flex-block/TwFlexCell.vue'
import TwSelect from '@/components/tw-ui/ui-kit/input/TwSelect.vue'
import TwTextarea from '@/components/tw-ui/ui-kit/input/TwTextarea.vue'

export default {
  name: 'MortgageProgramWorksheetMainForm',
  components: {
    TwTextarea,
    TwSelect,
    TwFlexCell,
    TwFlexCol,
    TwCardSimpleFormSaveBlock,
    PersonSelectAdditionalContact
  },
  mixins: [FormCreateEditMixin],
  props: {
    disableRedirect: {
      type: Boolean,
      default: false
    },
    defaultFormFields: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      API: API,
      form: {
        contactId: null,
        comment: '',
        programId: null,
        status: null,
        filesIds: []
      }
    }
  },
  computed: {
    targetCompany () {
      return this?.preset?.program?.company || null
    }
  },
  methods: {
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          const adaperMap = {
            // from(show): to(send)
            status: 'status',
            comment: 'comment',
            contact: 'contactId',
            program: 'programId',
            files: 'filesIds'
          }
          for (const key in this.preset) {
            if (adaperMap[key] !== undefined) {
              if (!Array.isArray(this.preset[key]) && this.preset[key] && this.preset[key].id) {
                this.$set(this.form, adaperMap[key], this.preset[key].id)
              } else if (Array.isArray(this.preset[key])) {
                this.$set(this.form, adaperMap[key], this.preset[key].length ? this.preset[key].map(i => i.id) : [])
              } else if (key === 'status' && this.preset[key]) {
                this.$set(this.form, key, this.preset[key].value || this.preset[key])
              } else if (key === 'comment') {
                this.$set(this.form, key, this.preset[key] || '')
              } else {
                this.$set(this.form, key, this.preset[key])
              }
            }
          }
        }
        resolve()
      })
    },
    handleSubmit () {
      if (this.formProcessing === true) {
        return
      }

      this.formProcessing = true
      const request = this.formType === 'create'
        ? API.store(this.form)
        : API.update(this.targetId, this.form)

      request.then(({ data }) => {
        if (this.formType === 'create') {
          this.$emit('create', data.data)
        } else {
          this.$emit('update', data.data)
        }
      }).finally(() => {
        this.formProcessing = false
      })
    }
  }
}
</script>
