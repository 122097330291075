<template>
  <div class="tw-bg-transparent tw-py-[40px]">
    <div class="tw-flex tw-items-end tw-justify-between tw-mb-[12px]">
      <div class="tw-flex tw-items-center tw-gap-[15px]">
        <div class="tw-text-an-flat-black-main tw-font-bold tw-text-[18px] tw-leading-normal">
          {{ isMatrixCalc ? 'Подходящие программы с субсид. ставками:':'Подходящие программы:' }}
          <span
            v-if="Array.isArray(data)"
            class="tw-ml-[8px]"
            title="Количество подходящих программ"
          >{{ (Array.isArray(filteredDataList) && filteredDataList.length) || 0 }}</span>
          <div v-if="showSelect && !leadId">
            <small class="tw-font-400">
              Невозможно выбрать программы - отсутствует Лид
            </small>
          </div>
        </div>
        <div
          v-if="filters.complexId"
          class="tw-text-[12px] tw-font-medium tw-text-an-flat-grey-31"
          title="Целевой Жилой Комплекс"
        >
          ЖК:
          <ViewPanelLink
            :id="filters.complexId"
            label="ЖК"
            component="realty-complex-show-page"
          >
            <RealtyComplexName :id="filters.complexId" />
          </ViewPanelLink>
        </div>
        <div
          v-if="firstSumPercent"
          class="tw-text-[12px] tw-font-medium tw-text-an-flat-grey-3"
          title="Процент Первоначального Взноса"
        >
          ПВ:
          {{ firstSumPercent }}%
        </div>
      </div>
      <div
        class="tw-flex tw-items-center tw-flex-wrap"
        title="Быстрый фильтр по банку"
      >
        <v-autocomplete
          v-model="selectedCompany"
          style="max-width: 300px"
          hide-details
          attach=""
          outlined
          dense
          clearable
          :items="uniqCompanyList"
          item-text="name"
          item-value="id"
          label="Выбор банка"
        />
      </div>
    </div>
    <div
      v-if="loading"
      class="tw-flex tw-w-full tw-p-3"
    >
      <TwCircleLoader class="tw-m-auto" />
    </div>
    <div v-else-if="Array.isArray(filteredDataList) && filteredDataList.length">
      <TwTableBase
        schema-key="SubsidizedProgramsSelectorMatrixTable"
        :loading-error-text="loadingErrorText"
        class="tw-border tw-border-solid tw-border-an-flat-gray-7 tw-rounded-md"
        :headers="headers"
        :items="filteredDataList"
        :loading="loading"
      >
        <template #item.data="{ item }">
          <!-- Выбрать -->
          <div
            v-if="headers[0].visible && (!!showSelect || isUpdate)"
            class=""
          >
            <!-- помечать выбранные -->
            <button
              v-if="usagePrograms.includes(item.program.id)"
              :disabled="true"
              title="Программа уже выбрана: анкета - создана"
              class="tw-px-[8px] tw-py-[6px] tw-bg-an-flat-green tw-text-[14px] tw-text-white tw-rounded-md active:tw-opacity-70"
            >
              Анкета создана
            </button>
            <button
              v-else-if="isUpdate"
              :disabled="hasDealClosed || loadingAddNewProgram || loadingDealPrograms || usagePrograms.includes(item.program.id) || disabled"
              class="tw-px-[6px] tw-py-[4px] tw-rounded-md tw-text-white tw-translate-all enabled:hover:tw-opacity-50 enabled:active:tw-opacity-70 disabled:tw-opacity-50"
              :class="{ 'tw-bg-an-flat-green' : usagePrograms.includes(item.program.id), 'tw-bg-an-flat-dark-blue-link' : !usagePrograms.includes(item.program.id)}"
              :title="hasDealClosed ? 'Заявка закрыта!':'Выбрать программу и создать анкету'"
              @click="addNewProgram(item.program.id)"
            >
              {{ loadingAddNewProgram || loadingDealPrograms ? 'Подождите' : 'Выбрать' }}
            </button>
            <TwCheckBox
              v-else-if="!isUpdate"
              background-and-border-color="tw-bg-an-flat-dark-blue-link tw-border-an-flat-dark-blue-link"
              :value="selectedPrograms.includes(item.program.id)"
              @input="changePrograms(item.program.id)"
            />
          </div>
        </template>
        <template #item.name="{ item }">
          <div>
            <div class="">
              <div
                v-if="isMatrixCalc"
                class=""
                style="flex: 1"
              >
                Базовая ставка
              </div>
              <!-- БАНК -->
              <ViewPanelLink
                v-if="item.program.company"
                :id="item.program.company.id"
                label="Компания"
                component="company-show"
              >
                {{ item.program.company.name }}
              </ViewPanelLink>
              <!-- ПРОГРАММА -->
              <span
                v-if="item.program"
                class=""
              >
                - <ViewPanelLink
                  :id="item.program.id"
                  label="Программа"
                  component="mortgage-program-show"
                >
                  {{ item.program.name }}
                </ViewPanelLink>
              </span>
              <!-- ПРЕДУПРЕЖДЕНИЯ -->
              <div>
                <small
                  v-if="item.program && item.program.commissionRewardFirstPay"
                  class="tw-text-an-flat-red-main"
                >
                  Возможно оплатить КВ за счет ПВ
                </small>
              </div>
              <div>
                <small
                  v-if="item.warning"
                  class="tw-text-an-flat-red-main"
                >
                  {{ item.warning }}
                </small>
              </div>
            </div>
            <template v-if="isMatrixCalc">
              <div
                v-if="Array.isArray(discountOptions)"
                class=""
              >
                <div class="tw-flex tw-flex-1">
                  Субсид. ставка
                </div>
                <select
                  v-model="discountRowLocal"
                  class=""
                  :class="discountRowLocal ? '' : 'error-input'"
                  @change="termRowLocal = null"
                >
                  <option
                    v-for="(option, index) in discountOptions"
                    :key="index"
                    :disabled="option.disabled"
                    :value="option.value"
                  >
                    {{ option.label }}
                  </option>
                </select>
                <v-icon
                  v-if="discountRowLocal"
                  x-small
                  class="cursor-pointer pa-2"
                  title="Очистить скидку"
                  @click.prevent="discountRowLocal = null; termRowLocal = null"
                >
                  fa-times
                </v-icon>
                <select
                  v-model="termRowLocal"
                  class="row-selectors"
                  :disabled="!discountRowLocal"
                  :class="termRowLocal ? '' : 'error-input'"
                >
                  <option
                    v-for="(option, index) in termOptions"
                    :key="index"
                    :disabled="option.disabled"
                    :value="option.value"
                  >
                    {{ option.label }}
                  </option>
                </select>
              </div>
              <div
                v-else
                class="tw-text-center tw-text-an-flat-grey-31 tw-flex tw-justify-between tw-items-center"
              >
                <div title="Для появления выбора необходимо добавить матрицу ставок в требованиях банка">
                  Матрица ставок отсутствует
                </div>
              </div>
            </template>
          </div>
        </template>
        <template #item.rate="{ item }">
          <div>
            <div class="tw-flex tw-items-center tw-flex-row">
              <ProgSelectorCellRatesInline
                :rate="item.basePay.rate"
                style="flex: 1;"
                :rate-flat="item.basePay.rateFlat"
                :rate-electron-registration="item.basePay.rateElectronRegistration"
              />
            </div>
            <template v-if="isMatrixCalc">
              <div
                v-if="selectedMatrixItem && selectedMatrixItem.payMatrixPeriod"
                class="tw-flex tw-items-center tw-flex-row"
              >
                <ProgSelectorCellRatesInline
                  :rate="selectedMatrixItem.payMatrixPeriod.rate"
                  style="flex: 1;"
                  :rate-flat="selectedMatrixItem.payMatrixPeriod.rateFlat"
                  :rate-electron-registration="selectedMatrixItem.payMatrixPeriod.rateElectronRegistration"
                />
              </div>
            </template>
          </div>
        </template>
        <template #item.pay="{ item }">
          <div class="tw-flex">
            <div class="two tw-flex tw-items-center tw-flex-row">
              <ProgSelectorCellPaysInlineShort
                style="flex: 1;"
                :pay-info="item.basePay"
                :economy-info="item.basePayEconomy"
                :over-pay-info="item.baseOverPay"
              />
            </div>
            <template v-if="isMatrixCalc">
              <div
                v-if="selectedMatrixItem"
                class="three"
              >
                <ProgSelectorCellPaysInlineMatrix
                  :matrix-item="selectedMatrixItem"
                  :subsidized-deal-type="filters.subsidizedDealType"
                />
              </div>
            </template>
          </div>
        </template>
      </TwTableBase>
    </div>
    <div
      v-else
      class="tw-bg-an-flat-gray-7 tw-py-4 tw-px-5 tw-rounded-md"
    >
      <div
        v-if="filters.subsidizedDealType === 'buy_nb' && !filters.complexId"
        colspan="99"
        class="ma-auto text-center error--text"
      >
        Не выбран комплекс для подбора Субсидированных ставок
      </div>
      <div
        v-else
        class="tw-text-center tw-text-an-flat-disabled-text"
      >
        {{ noDataText }}
      </div>
    </div>
  </div>
</template>

<script>
import DataListMixin from '@/mixins/DataListMixin'
import API from '@/Api/Mortgage/Search'
import APIDealMortgage from '@/Api/Crm/Deal/Mortgage/Program'
import dealAPI from '@/Api/Crm/Deal'
import tableOptions from './tableOptions'
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink'
import TwTableBase from '@/components/tw-ui/table/TwTableBase.vue'
import ProgSelectorCellRatesInline from '@/views/Mortgage/Variant/Matrix/ProgSelectorCellRatesInline.vue'
import ProgSelectorCellPaysInlineShort from '@/views/Mortgage/Variant/Matrix/ProgSelectorCellPaysInlineShort.vue'
import ProgSelectorCellPaysInlineMatrix from '@/views/Mortgage/Variant/Matrix/ProgSelectorCellPaysInlineMatrix.vue'
import { mortgageTermList } from '@/views/Mortgage/dict'
import RealtyComplexName from '@/components/other/complex/RealtyComplexName.vue'
import TwCheckBox from '@/components/tw-ui/ui-kit/input/TwCheckBox.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'

export default {
  name: 'SubsidizedProgramsSelectorMatrix',
  components: {
    TwCircleLoader,
    TwCheckBox,
    RealtyComplexName,
    ProgSelectorCellPaysInlineMatrix,
    ProgSelectorCellPaysInlineShort,
    ProgSelectorCellRatesInline,
    TwTableBase,
    ViewPanelLink
  },
  mixins: [DataListMixin, ViewPanelShowMixin],
  props: {
    // Показать колонку с действиями выбора или прикрепления программы к заявке
    showSelect: {
      type: Boolean,
      default: false
    },
    fromPanel: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    // Если происходит не создание а изменение подбора внутри заявки
    isUpdate: {
      type: Boolean,
      default: false
    },
    dealId: {
      type: Number,
      default: null
    },
    leadId: {
      type: Number,
      default: 0
    },
    filters: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      isScrollToTopEnable: false, // отключаю скролл вврех при запросах в DataListMixin.js
      noDataText: 'Нет подходящих программ',
      noResultsText: 'Нет подходящих программ',
      API,
      APIDealMortgage,
      dealSelectedPrograms: [],
      selectedPrograms: [],
      selectedCompany: null,
      type: 'mortgageVariant',
      mortgageTermList: mortgageTermList,
      discountRowLocal: null,
      termRowLocal: null,
      loadingAddNewProgram: false,
      loadingDealPrograms: false,
      dealInfo: null
    }
  },
  methods: {
    fetchDealInfo () {
      dealAPI.show(this.dealId)
        .then(({ data: result }) => {
          this.$set(this, 'dealInfo', result.data || null)
        })
        .catch((error) => {
          console.error(error)
          this.$set(this, 'dealInfo', null)
        })
    },
    changePrograms (id) {
      if (id) {
        const changedProgramIndex = this.selectedPrograms.indexOf(id)
        if (changedProgramIndex === -1) {
          this.selectedPrograms.push(id)
        } else {
          this.selectedPrograms.splice(changedProgramIndex, 1)
        }
      }
      console.warn('changePrograms', this.selectedPrograms, id)
      this.$emit('input', this.selectedPrograms)
    },
    refresh (filters) {
      this.selectedCompany = null
      this.selectedPrograms = []
      this.changePrograms()
      this.fetch(filters || this.filters)
    },
    addNewProgram (id) {
      if (this.loadingAddNewProgram) {
        return
      }
      this.loadingAddNewProgram = true
      // Выбрать новую программу
      APIDealMortgage.add(this.dealId, id)
        .then(() => {
          // console.warn(this.$options.name, 'addNewProgram:', data)
          this.listSelectedPrograms()
        })
        .catch((error) => {
          console.error(this.$options.name, ' addNewProgram:', error)
        })
        .finally(() => {
          this.loadingAddNewProgram = false
        })
    },
    listSelectedPrograms () {
      if (this.loadingDealPrograms) {
        return
      }
      this.loadingDealPrograms = true
      // Получение списка выбранных программ по заявке
      if (this.dealId && this.dealId > 0) {
        APIDealMortgage.list(this.dealId)
          .then(({ data }) => {
            this.dealSelectedPrograms = data?.data || []
          })
          .catch((error) => {
            console.error(this.$options.name, ' LIST Program', error)
          })
          .finally(() => {
            this.loadingDealPrograms = false
          })
      }
    }
  },
  computed: {
    hasDealClosed () {
      return this.dealInfo && (this.dealInfo.status.stage.value === 'success' || this.dealInfo.status.stage.value === 'fail')
    },
    firstSumPercent () {
      return (this.filters.firstSum && this.filters.sum ? this.filters.firstSum * 100 / this.filters.sum : 0).toFixed(1)
    },
    headers () {
      const forFilter = tableOptions
      for (const col of forFilter) {
        col.visible = !(this.isMatrixCalc && (col.value.indexOf('Flat') !== -1))
        if (!this.isMatrixCalc && (col.value === 'period')) {
          col.visible = false
        }
        if (col.value === 'data-table-select') {
          col.visible = this.showSelect
        }
      }
      return forFilter
    },
    // Нужно ли запускать расчёты матрицы субсид ставок внутри элементов таблицы
    isMatrixCalc () {
      return !!this.filters.subsidizedDealType
    },
    uniqCompanyList () {
      const companies = []
      // console.log('uniqCompanyList', this.data)
      if (Array.isArray(this.data)) {
        for (const { program } of this.data) {
          // console.log(program.name, program)
          if (program?.company?.id && companies.indexOf(c => c.id !== program.company.id) === -1) {
            companies.push(program.company)
          }
        }
        // console.log('uniqCompanyList ', { companies })
      }
      return companies
    },
    // TODO DEV - что с этим делать - как то по иному выбирать компании ???
    filteredDataList () {
      // Пока ЖК не выбрали - покажем пустой список
      if (this.filters?.isOnlySubsidized && !this.filters.complexId) {
        return []
      }
      // TODO WTF is Old???
      const filteredForCompany = this.data.filter(item => this.selectedCompany ? item?.program?.company?.id === this.selectedCompany : true)
      return filteredForCompany.filter(item => {
        return this.filters?.isOnlySubsidized ? (Array.isArray(item.otherRates) && item.otherRates.length) : true
      })
    },
    discountOptions () {
      let discountsList = (this.item?.matrixPays || []).map(item => item?.matrixItem?.discount || null)
      discountsList = [...new Set(discountsList)]
      const optList = [
        {
          label: 'Выберите скидку',
          disabled: true,
          value: null
        },
        ...discountsList.map((value) => {
          return {
            label: `${value} %`,
            value
          }
        })
      ]
      return optList.length > 1 ? optList : null
    },
    termOptions () {
      if (!this.discountRowLocal) {
        return [
          {
            label: 'Выберите срок',
            disabled: true,
            value: null
          }
        ]
      }
      const targetMatrixItems = (this.item?.matrixPays || []).filter(item => item?.matrixItem?.discount === this.discountRowLocal)
      let termsList = (targetMatrixItems || []).map(item => item?.matrixItem?.term || null)
      termsList = [...new Set(termsList)]
      return [
        {
          label: 'Выберите срок',
          disabled: true,
          value: null
        },
        ...mortgageTermList.filter((item) => {
          return termsList.includes(item.value)
        })
      ]
    },
    selectedMatrixItem () {
      return (this.item?.matrixPays || [])?.find(item => {
        return item?.matrixItem?.discount === this.discountRowLocal && item?.matrixItem?.term && item?.matrixItem?.term === this.termRowLocal
      })
    },
    usagePrograms () {
      return Array.isArray(this.dealSelectedPrograms) ? this.dealSelectedPrograms.map(i => i?.program?.id) : []
    }
  },
  mounted () {
    this.listSelectedPrograms()
    if (this.dealId) {
      this.fetchDealInfo()
    }
  }
}
</script>
<style lang="less">
.variant-matrix-table-container {
  .v-data-table--fixed-header>.v-data-table__wrapper {
    overflow: unset;
  }
  .custom-sub-row {
    margin-top: 5px;
    margin-bottom: 3px;
    min-height: 63px;
  }
  .flex-item-three-cols {
    display: flex;
    div > {
      position: relative;
      width: 33%;
      text-align: center;
      div {
        width: 100%;
      }
    }
    div.center:after {
      content:"/";
      position: absolute;
      top: 0;
      left: 0;
    }
    div.center:before {
      content:"/";
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  table {
    tr {
      td {
        vertical-align: top;
        height: 100%;
      }
      &:nth-child(even) {
        background-color: whitesmoke;
      }
    }
  }
}
</style>
