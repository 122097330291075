<template>
  <div class="tw-font-manrope tw-z-0 tw-h-full">
    <div class="tw-w-full tw-h-full tw-px-[14px] xl:tw-px-[24px] 2xl:tw-px-[26px] tw-pt-[10px] tw-bg-white tw-rounded-t-[20px]">
      <slot>
        <div class="tw-text-center">
          Контент
        </div>
      </slot>
    </div>
    <slot
      v-if="false"
      name="right-bar"
    >
      <!--  TODO заготовка места для панели мессенджера  -->
      <div
        :class="messengerWidthClass"
        class="tw-relative"
      >
        <div class="tw-absolute">
          <div
            class="tw-fixed tw-z-[0] tw-top-0 tw-right-0 tw-bg-white tw-h-full tw-overflow-hidden tw-flex tw-flex-col tw-gap-[10px]"
            :class="messengerWidthClass"
          >
            <div :class="topUiHeightClass" />
            <div class="tw-m-auto">
              <div class="tw-bg-an-flat-bg-main tw-w-[35px] tw-min-h-[35px] tw-rounded-full tw-flex tw-items-center tw-justify-center">
                S
              </div>
            </div>
            <div class="tw-flex-1 tw-grow tw-overflow-hidden">
              <div class="tw-flex tw-flex-col tw-items-center tw-justify-start tw-gap-[10px]">
                <div
                  v-for="i in 12"
                  :key="i"
                >
                  <div class="tw-bg-an-flat-bg-main tw-w-[35px] tw-min-h-[35px] tw-rounded-full tw-flex tw-items-center tw-justify-center">
                    <svg
                      width="15"
                      height="17"
                      viewBox="0 0 15 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.4"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7.5 1.5C5.84596 1.5 4.58775 2.98526 4.8597 4.61679L5.0822 5.95179C5.0822 5.9518 5.08219 5.95179 5.0822 5.95179C5.2792 7.13371 6.30181 8 7.5 8C8.6982 8 9.7208 7.13371 9.9178 5.9518L10.1403 4.61679C10.4123 2.98526 9.15404 1.5 7.5 1.5ZM3.8733 4.7812C3.49976 2.54011 5.22804 0.5 7.5 0.5C9.77196 0.5 11.5002 2.54012 11.1267 4.78121L10.9042 6.1162C10.6268 7.78028 9.18705 9 7.5 9C5.81295 9 4.37317 7.78029 4.0958 6.11621L3.8733 4.7812C3.8733 4.7812 3.8733 4.7812 3.8733 4.7812ZM5.47087 9.58183C5.56375 9.84189 5.42822 10.128 5.16817 10.2209L2.24774 11.2639C2.24773 11.2639 2.24774 11.2639 2.24774 11.2639C1.94925 11.3705 1.75 11.6532 1.75 11.9702V13.8901C1.75 14.0422 1.75619 14.1572 1.777 14.2497C1.79643 14.336 1.82267 14.375 1.84552 14.3971C2.04523 14.59 2.50875 14.8715 3.42397 15.1021C4.33023 15.3305 5.64346 15.5 7.5 15.5C9.35654 15.5 10.6698 15.3305 11.576 15.1021C12.4912 14.8715 12.9548 14.59 13.1545 14.3971C13.1773 14.375 13.2036 14.336 13.223 14.2497C13.2438 14.1572 13.25 14.0422 13.25 13.8901V11.9702C13.25 11.6532 13.0508 11.3705 12.7523 11.2639C12.7523 11.2639 12.7523 11.2639 12.7523 11.2639L9.83183 10.2209C9.57178 10.128 9.43625 9.84189 9.52913 9.58183C9.622 9.32178 9.90811 9.18625 10.1682 9.27913L13.0886 10.3221C13.7851 10.5709 14.25 11.2306 14.25 11.9702V13.8901C14.25 14.1831 14.2465 14.7325 13.8493 15.1163C13.473 15.4797 12.82 15.8199 11.8204 16.0718C10.8119 16.326 9.41215 16.5 7.5 16.5C5.58785 16.5 4.18811 16.326 3.17959 16.0718C2.18003 15.8199 1.52696 15.4797 1.15073 15.1163C0.753455 14.7325 0.75 14.1831 0.75 13.8901V11.9702C0.75 11.2306 1.21488 10.5709 1.91139 10.3221L4.83183 9.27913C5.09189 9.18625 5.378 9.32178 5.47087 9.58183Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div class="tw-mx-auto tw-mb-[10px]">
              <div class="tw-bg-an-flat-bg-main tw-w-[35px] tw-min-h-[35px] tw-rounded-full tw-flex tw-items-center tw-justify-center">
                P
              </div>
            </div>
          </div>
        </div>
      </div>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'TwBasicContentWrapper',
  props: {
    topUiHeightClass: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      messengerWidthClass: 'tw-w-[60px]'
    }
  }
}
</script>

<style scoped>

</style>
