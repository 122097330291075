<template>
  <div
    v-if="propScheme"
    :class="wrapperClass"
  >
    <template v-for="(prop, index) in propScheme">
      <TwPanelContentBlockFieldView
        :key="prop.key + index"
        :data-prop-type="prop.type"
        :data-prop-key="prop.key"
        :content-width-class="prop.type === 'Polygon' ? 'tw-min-w-[450px]' : 'tw-min-w-[250px]'"
        :class="[prop.type === 'Polygon' ? 'tw-min-w-[450px]' : 'tw-min-w-[250px]']"
        class="tw-flex-1"
      >
        <template #title>
          {{ prop.label || prop.key }} {{ prop.unit ? `(${prop.unit})` : '' }}
        </template>
        <div class="tw-w-full tw-flex-1">
          <!--            {{prop.key}} -&#45;&#45; {{properties[prop.key] === null}}-->
          <div
            v-if="properties[prop.key] === null"
            class="text-muted"
          >
            Не указано
          </div>
          <div v-else-if="prop.type === 'Boolean'">
            {{ properties[prop.key] ? 'Да' : 'Нет' }}
          </div>
          <div v-else-if="prop.type === 'Enum'">
            <div v-if="Array.isArray(prop.options) && prop.options.length">
              <template v-if="prop.multiple">
                <span
                  v-for="(val, valIndex) in properties[prop.key]"
                  :key="valIndex"
                >
                  {{ val.label }}<span v-if="valIndex < properties[prop.key].length - 1">, </span>
                </span>
              </template>
              <template v-else>
                {{ properties[prop.key] && properties[prop.key].label }}
              </template>
            </div>
            <div v-else>
              <code class="error--text">Отсутствует схема свойств для Enum</code> {{ prop.key }}: {{ properties[prop.key] }}
            </div>
          </div>
          <div v-else-if="prop.type === 'Text' || prop.type === 'String'">
            {{ properties[prop.key] }}
          </div>
          <div v-else-if="prop.type === 'Int' || prop.type === 'Float'">
            {{ properties[prop.key] }}
          </div>
          <div v-else-if="prop.type === 'Date'">
            {{ $Global.DateFormat.DateShort(properties[prop.key]) }}
          </div>
          <div
            v-else-if="prop.type === 'Polygon'"
            class="tw-min-w-[450px]"
          >
            <RealtyComplexBuildingMapMarking
              :view-only="true"
              :buildings-list="(otherObjects && otherObjects.allObjectsMapPolygon) || []"
              :value="properties[prop.key]"
            />
          </div>
          <div v-else>
            <code class="error--text">Не описан тип поля</code> {{ prop.key }}[{{ prop.type }}]: {{ properties[prop.key] }}
          </div>
        </div>
      </TwPanelContentBlockFieldView>
    </template>
  </div>
  <div
    v-else
    class="tw-text-an-flat-disabled-text tw-text-center"
  >
    Свойства не указаны
  </div>
</template>

<script>
import { GridWrapperForPanelShowAndForm } from '@/utils/tw-classes'
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'
import RealtyComplexBuildingMapMarking from '@/views/Newbuildings/Editor/OtherComponents/Map/RealtyComplexBuildingMapMarking.vue'

export default {
  name: 'RealtyObjectPropsShow',
  components: { RealtyComplexBuildingMapMarking, TwPanelContentBlockFieldView },
  mixins: [ViewPanelShowMixin],
  props: {
    targetPropKey: {
      type: String,
      default: 'prop'
    },
    hasGrid: {},
    properties: {},
    scheme: {},
    otherObjects: {
      type: Object,
      default: null
    }
  },
  computed: {
    // Свойства которые доступны к показу
    propScheme () {
      return Array.isArray(this.scheme[this.targetPropKey]) && this.scheme[this.targetPropKey].length ? this.scheme[this.targetPropKey] : []
    },
    wrapperClass () {
      return this.hasGrid ? GridWrapperForPanelShowAndForm.threeCols : 'tw-flex tw-flex-wrap tw-gap-y-[12px] tw-gap-x-[26px] tw-w-full'
    }
  }
}
</script>
