export function ResponceErrorMessageGenerator (error) {
  let message = ''
  if (error?.response?.data) {
    if (typeof error.response.data === 'string') {
      message = error.response.data
    } else if (typeof error.response.data === 'object') {
      for (const key in error.response.data) {
        if (Array.isArray(error?.response?.data?.[key])) {
          for (const err of error?.response?.data[key]) {
            message += `${err}; `
          }
        } else {
          message += `${error?.response?.data?.[key]}; `
        }
      }
    } else {
      message = error.response.data
    }
  }
  return message
}

export default {}
