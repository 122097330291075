<template>
  <div class="tw-bg-white tw-rounded-md tw-py-[16px]">
    <div class="tw-flex tw-gap-[14px] tw-text-main-sm2 tw-pb-[10px] tw-px-[18px] tw-font-semibold tw-border-b tw-border-solid">
      <div
        v-for="(item, index) in list"
        :key="index"
        class="tw-pb-[3px] tw-px-[1px] hover:tw-text-an-flat-dark-blue-link"
        :class="activeTabIndex === index ? 'tw-border-b-2 tw-border-solid tw-border-an-flat-dark-blue-link tw-text-an-flat-dark-blue-link tw-cursor-default':'tw-cursor-pointer'"
        @click.stop="item.action(item, $event)"
      >
        {{ item.title }}
      </div>
    </div>
    <div
      v-if="isComment || isActiveComment"
      class="tw-p-[18px]"
    >
      <div class="">
        <div class="">
          <div
            v-if="!commentEdit"
            class="tw-border tw-border-solid tw-px-[15px] tw-pt-[10px] tw-pb-[25px] tw-rounded-sm tw-cursor-pointer hover:tw-text-an-flat-dark-blue-link"
            @click.prevent="commentEdit = true"
          >
            Оставьте комментарий
          </div>
          <form-comments-send
            v-if="commentEdit"
            :active="commentEdit"
            :entity-id="entityId"
            :entity="entity"
            @cancel="commentEdit = false; activeTabIndex = 0"
            @success="addComment"
          />
        </div>
        <!--                              @success="addComment"-->
      </div>
    </div>
    <div v-if="notificationForm">
      <div class="">
        <div class="">
          <form-notification-send
            v-if="notificationForm"
            :active-tab="activeTab"
            :type="type"
            :entity-id="entityId"
            :entity="entity"
            :person-id="personId"
            :active="notificationForm"
            @success="addNotification"
            @cancel="closeNotification"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import FormCommentsSend from '@/components/core/Chronology/formCommentsSend.vue'
import FormNotificationSend from '@/components/core/Chronology/formNotificationSend.vue'
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
export default {
  name: 'ChronologyTopActionsBlock',
  components: { FormNotificationSend, FormCommentsSend },
  mixins: [ViewPanelShowMixin],
  props: {
    id: {
      type: [String, Number, null],
      default: null
    },
    entity: {
      type: String,
      required: true // person | lead | deal
    },
    entityId: {
      type: [String, Number, null],
      default: null
    },
    personId: {
      type: [String, Number, null],
      default: null
    },
    entityInfo: {}
  },
  data () {
    return {
      activeTab: '',
      activeTabIndex: 0,
      type: '',
      commentEdit: false,
      loading: false,
      isComment: false,
      isActiveComment: true,
      notificationForm: false,
      list: [
        {
          title: 'Комментарий',
          action: () => {
            this.activeTabIndex = 0
            this.isComment = true
            this.isActiveComment = true
            this.notificationForm = false
          }
        },
        {
          title: 'Звонок',
          action: () => {
            this.activeTabIndex = 1
            this.isComment = false
            this.isActiveComment = false
            this.commentEdit = false
            this.notificationForm = true
            this.sendTypeForm('outgoing_call')
            this.sendActiveTabForm(this.activeTabIndex = 1)
          }
        },
        {
          title: 'Встреча',
          action: () => {
            this.activeTabIndex = 2
            this.isComment = false
            this.isActiveComment = false
            this.commentEdit = false
            this.notificationForm = true
            this.sendTypeForm('meet')
            this.sendActiveTabForm(this.activeTabIndex = 2)
          }
        },
        {
          title: 'Письмо',
          action: () => {
            this.activeTabIndex = 3
            this.showEmailPanel()
            this.isComment = true
            this.commentEdit = false
            this.notificationForm = false
            this.sendActiveTabForm(this.activeTabIndex = 0)
          }
        }
      ]
    }
  },
  methods: {
    showEmailPanel () {
      this.showEmailCard({
        forDeal: this.entity === 'deal' && this.entityInfo ? this.entityInfo : null,
        toPerson: null
      })
    },
    closeNotification () {
      this.activeTabIndex = 0
      this.notificationForm = false
      this.isComment = true
    },
    sendTypeForm (type) {
      this.type = type
    },
    sendActiveTabForm (activeTabForm) {
      this.activeTab = activeTabForm
    },
    addComment () {
      this.commentEdit = false
      this.$emit('refresh')
    },
    addNotification () {
      this.$emit('refresh')
      this.closeNotification()
    }
  }
}
</script>

<style scoped>
.top-menu-item {
  border-bottom: 1px solid #f0f1f2;
}
.menu-btn {
  color: #555c6d;
  font: 14px/47px "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  cursor: pointer;
  text-transform: none;
  margin-left: 18px;
  padding: 10px 0;
}
.menu-btn:hover {
  border-bottom: 2px solid #3f51b5;
  color: #3f51b5;
}
.active {
  border-bottom: 2px solid #3f51b5;
  color: #3f51b5;
}
.top-menu {
  background-color: white;
}
</style>
