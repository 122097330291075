import { Circle as CircleStyle, Stroke, Style, Fill } from 'ol/style'
import { Control, FullScreen } from 'ol/control.js'
import { getCenter } from 'ol/extent'
import MultiPoint from 'ol/geom/MultiPoint.js'
import ElementPointerRotate from './ElementPointerRotate'
import { radToDeg, fixedAngle, degreeNormalize360, degToRad } from './rotateHelpers'

export default {}

export const drawingStyles = {
  showOther: [
    new Style({
      stroke: new Stroke({
        color: 'rgba(100, 208, 255, 1)',
        width: 2
      }),
      fill: new Fill({
        color: 'rgba(100, 208, 255, 0.5)'
      })
    })
  ],
  edit: [
    new Style({
      stroke: new Stroke({
        color: 'rgba(255, 0, 0, 1)',
        width: 2
      }),
      fill: new Fill({
        color: 'rgba(255, 0, 0, 0.5)'
      })
    }),
    new Style({
      image: new CircleStyle({
        radius: 5,
        fill: new Fill({
          color: 'white'
        }),
        stroke: new Stroke({
          color: 'rgba(255, 0, 0, 1)',
          width: 4
        })
      })
    })
  ],
  show: [
    new Style({
      stroke: new Stroke({
        color: 'rgba(255, 0, 0, 1)',
        width: 4
      }),
      fill: new Fill({
        color: 'rgba(255, 0, 0, 0.2)'
      })
    }),
    new Style({
      image: new CircleStyle({
        radius: 5,
        fill: new Fill({
          color: 'white'
        }),
        stroke: new Stroke({
          color: 'rgba(255, 0, 0, 1)',
          width: 4
        })
      }),
      geometry: function (feature) {
        // return the coordinates of the first ring of the polygon
        const coordinates = feature.getGeometry().getCoordinates()[0]
        return new MultiPoint(coordinates)
      }
    })
  ]
}

// TODO DEL OLD
export class ViewRotateControl extends Control {
  constructor (optOptions) {
    const options = optOptions || {}
    // TODO не нужно - но может пригодиться
    // const buttonReset = document.createElement('button')
    // buttonReset.className = 'tw-px-[12px] tw-border-2 tw-border-solid tw-border-white tw-py-[2px] tw-bg-gray-600 tw-text-white tw-rounded-sm hover:tw-bg-gray-900'
    // buttonReset.innerHTML = '⇵'
    // buttonReset.title = 'Сброс поворота'

    // const buttonLeft = document.createElement('button')
    // buttonLeft.className = 'tw-px-[12px] tw-border-2 tw-border-solid tw-border-white tw-py-[2px] tw-bg-gray-600 tw-text-white tw-rounded-sm hover:tw-bg-gray-900'
    // buttonLeft.innerHTML = '↺'
    // buttonLeft.title = 'Поворот влево'

    // const buttonRight = document.createElement('button')
    // buttonRight.className = 'tw-px-[12px] tw-border-2 tw-border-solid tw-border-white tw-py-[2px] tw-bg-gray-600 tw-text-white tw-rounded-[10px] hover:tw-bg-gray-900'
    // buttonRight.innerHTML = '↻'
    // buttonRight.title = 'Поворот вправо'

    const buttonFit = document.createElement('button')
    buttonFit.className = 'tw-mx-[12px] tw-p-[12px] tw-text-[#1B1918] tw-bg-white tw-rounded-[10px] hover:tw-bg-[#7A8797] hover:tw-text-white'
    buttonFit.innerHTML = `
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.75 5.91667C7.82787 5.9939 7.92021 6.05501 8.02175 6.09648C8.12328 6.13795 8.23199 6.15897 8.34167 6.15833C8.56028 6.15741 8.76978 6.07062 8.925 5.91667L10.4333 4.41667C10.511 4.33952 10.5728 4.24782 10.615 4.1468C10.6573 4.04579 10.6792 3.93744 10.6796 3.82795C10.68 3.71845 10.6588 3.60995 10.6173 3.50864C10.5757 3.40733 10.5147 3.3152 10.4375 3.2375C10.3603 3.1598 10.2686 3.09806 10.1676 3.0558C10.0666 3.01354 9.95828 2.99159 9.84878 2.9912C9.62764 2.99042 9.41525 3.07752 9.25833 3.23333L9.16667 3.33333V0.833333C9.16667 0.61232 9.07887 0.400358 8.92259 0.244078C8.76631 0.0877973 8.55435 0 8.33333 0C8.11232 0 7.90036 0.0877973 7.74408 0.244078C7.5878 0.400358 7.5 0.61232 7.5 0.833333V3.33333L7.40833 3.24167C7.33087 3.16356 7.2387 3.10156 7.13715 3.05926C7.0356 3.01695 6.92668 2.99517 6.81667 2.99517C6.70666 2.99517 6.59774 3.01695 6.49619 3.05926C6.39464 3.10156 6.30247 3.16356 6.225 3.24167C6.06979 3.3978 5.98267 3.60901 5.98267 3.82917C5.98267 4.04932 6.06979 4.26053 6.225 4.41667L7.75 5.91667Z" fill="currentColor"/>
      <path d="M5.925 7.7L4.425 6.19167C4.34785 6.11397 4.25615 6.05223 4.15514 6.00997C4.05412 5.96771 3.94578 5.94576 3.83628 5.94537C3.72678 5.94498 3.61828 5.96617 3.51698 6.00771C3.41567 6.04926 3.32353 6.11035 3.24583 6.1875C3.08891 6.34331 3.00032 6.55508 2.99954 6.77622C2.99876 6.99736 3.08585 7.20975 3.24167 7.36667L3.33333 7.5H0.833333C0.61232 7.5 0.400358 7.5878 0.244078 7.74408C0.0877973 7.90036 0 8.11232 0 8.33333C0 8.55435 0.0877973 8.76631 0.244078 8.92259C0.400358 9.07887 0.61232 9.16667 0.833333 9.16667H3.33333L3.24167 9.25833C3.08646 9.41447 2.99934 9.62568 2.99934 9.84583C2.99934 10.066 3.08646 10.2772 3.24167 10.4333C3.31953 10.5106 3.41188 10.5717 3.51341 10.6131C3.61494 10.6546 3.72366 10.6756 3.83333 10.675C4.05195 10.6741 4.26145 10.5873 4.41667 10.4333L5.93333 8.90833C6.01475 8.82992 6.07936 8.73576 6.12323 8.63159C6.16711 8.52742 6.18933 8.41541 6.18855 8.30238C6.18777 8.18935 6.16401 8.07765 6.1187 7.9741C6.07339 7.87054 6.00749 7.77728 5.925 7.7Z" fill="currentColor"/>
      <path d="M8.89155 10.6994C8.73541 10.5441 8.5242 10.457 8.30405 10.457C8.08389 10.457 7.87269 10.5441 7.71655 10.6994L6.20822 12.1994C6.0513 12.3552 5.9627 12.5669 5.96192 12.7881C5.96114 13.0092 6.04823 13.2216 6.20405 13.3785C6.35986 13.5354 6.57163 13.624 6.79277 13.6248C7.01391 13.6256 7.2263 13.5385 7.38322 13.3827L7.49988 13.3327V15.8327C7.49988 16.0537 7.58768 16.2657 7.74396 16.4219C7.90024 16.5782 8.1122 16.666 8.33322 16.666C8.55423 16.666 8.76619 16.5782 8.92247 16.4219C9.07875 16.2657 9.16655 16.0537 9.16655 15.8327V13.3327L9.25822 13.4244C9.33608 13.5016 9.42843 13.5627 9.52996 13.6042C9.63149 13.6456 9.74021 13.6667 9.84988 13.666C9.96014 13.6656 10.0692 13.6432 10.1708 13.6003C10.2723 13.5574 10.3644 13.4948 10.4415 13.416C10.5968 13.2599 10.6839 13.0487 10.6839 12.8285C10.6839 12.6084 10.5968 12.3972 10.4415 12.241L8.89155 10.6994Z" fill="currentColor"/>
      <path d="M15.8335 7.50047H13.3335L13.4252 7.4088C13.5804 7.25267 13.6675 7.04146 13.6675 6.8213C13.6675 6.60115 13.5804 6.38994 13.4252 6.2338C13.3477 6.1557 13.2556 6.0937 13.154 6.05139C13.0525 6.00909 12.9435 5.9873 12.8335 5.9873C12.7235 5.9873 12.6146 6.00909 12.5131 6.05139C12.4115 6.0937 12.3193 6.1557 12.2419 6.2338L10.7335 7.7588C10.5783 7.91494 10.4912 8.12615 10.4912 8.3463C10.4912 8.56646 10.5783 8.77767 10.7335 8.9338L12.2252 10.4421C12.3031 10.5194 12.3954 10.5805 12.4969 10.6219C12.5985 10.6634 12.7072 10.6844 12.8169 10.6838C12.9265 10.6844 13.0353 10.6634 13.1368 10.6219C13.2383 10.5805 13.3307 10.5194 13.4085 10.4421C13.5637 10.286 13.6509 10.0748 13.6509 9.85464C13.6509 9.63448 13.5637 9.42327 13.4085 9.26714L13.3335 9.16714H15.8335C16.0546 9.16714 16.2665 9.07934 16.4228 8.92306C16.5791 8.76678 16.6669 8.55482 16.6669 8.3338C16.6669 8.11279 16.5791 7.90083 16.4228 7.74455C16.2665 7.58827 16.0546 7.50047 15.8335 7.50047Z" fill="currentColor"/>
      <path d="M7.2832 8.3332C7.2832 8.54087 7.34479 8.74388 7.46016 8.91655C7.57554 9.08922 7.73952 9.2238 7.93139 9.30328C8.12325 9.38275 8.33437 9.40354 8.53805 9.36303C8.74173 9.32251 8.92882 9.22251 9.07567 9.07567C9.22251 8.92882 9.32251 8.74173 9.36303 8.53805C9.40354 8.33437 9.38275 8.12325 9.30328 7.93139C9.2238 7.73952 9.08922 7.57554 8.91655 7.46016C8.74388 7.34479 8.54087 7.2832 8.3332 7.2832C8.05473 7.2832 7.78765 7.39383 7.59074 7.59074C7.39383 7.78765 7.2832 8.05473 7.2832 8.3332Z" fill="currentColor"/>
    </svg>
    `
    buttonFit.title = 'Оптимальный размер'

    const element = document.createElement('div')
    element.className = 'tw-flex tw-gap-[8px] tw-justify-end tw-items-center tw-mt-[10px]'

    // element.appendChild(buttonLeft)
    // element.appendChild(buttonReset)
    // element.appendChild(buttonRight)
    element.appendChild(buttonFit)

    super({
      element: element,
      target: options.target
    })

    // buttonLeft.addEventListener('click', this.handleLeftRotate.bind(this, optOptions.extent), false)
    // buttonReset.addEventListener('click', this.handleResetRotate.bind(this), false)
    // buttonRight.addEventListener('click', this.handleRightRotate.bind(this, optOptions.extent), false)
    buttonFit.addEventListener('click', this.handleFitExtent.bind(this, optOptions.extent), false)
  }

  // handleResetRotate () {
  //   this.getMap().getView().animate({
  //     rotation: 0,
  //     duration: 100
  //   })
  // }

  // handleLeftRotate (extent) {
  //   this.getMap().getView().animate({
  //     anchor: getCenter(extent), // точка притяжения при повороте
  //     rotation: this.getMap().getView().getRotation() - degToRad(15),
  //     duration: 100
  //   })
  // }

  // handleRightRotate (extent) {
  //   this.getMap().getView().animate({
  //     anchor: getCenter(extent), // точка притяжения при повороте
  //     rotation: this.getMap().getView().getRotation() + degToRad(15),
  //     duration: 100
  //   })
  // }

  handleFitExtent (extent) {
    this.getMap().getView().animate({
      fit: this.getMap().getView().fit(extent),
      duration: 100
    })
  }
}

export class CompasRotateControl extends Control {
  extent = []
  compasImageView = null
  compasImageRotatable = null
  elRotator = null
  buttonsBlock = null
  buttonEditCompas = null
  buttonApplyCompas = null
  buttonCancelCompas = null
  buttonFit = null
  hasEdit = false
  startedRadianAngle = 0 // Стартовое состояние компаса для возможности отмены

  constructor (optOptions) {
    const options = optOptions || {}

    const buttonFit = document.createElement('button')
    buttonFit.className = 'tw-pointer-events-auto tw-ml-auto tw-mt-[20px] tw-mr-[20px] tw-p-[12px] tw-text-[#1B1918] tw-bg-white tw-rounded-[10px] hover:tw-bg-[#7A8797] hover:tw-text-white'
    buttonFit.innerHTML = `
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.75 5.91667C7.82787 5.9939 7.92021 6.05501 8.02175 6.09648C8.12328 6.13795 8.23199 6.15897 8.34167 6.15833C8.56028 6.15741 8.76978 6.07062 8.925 5.91667L10.4333 4.41667C10.511 4.33952 10.5728 4.24782 10.615 4.1468C10.6573 4.04579 10.6792 3.93744 10.6796 3.82795C10.68 3.71845 10.6588 3.60995 10.6173 3.50864C10.5757 3.40733 10.5147 3.3152 10.4375 3.2375C10.3603 3.1598 10.2686 3.09806 10.1676 3.0558C10.0666 3.01354 9.95828 2.99159 9.84878 2.9912C9.62764 2.99042 9.41525 3.07752 9.25833 3.23333L9.16667 3.33333V0.833333C9.16667 0.61232 9.07887 0.400358 8.92259 0.244078C8.76631 0.0877973 8.55435 0 8.33333 0C8.11232 0 7.90036 0.0877973 7.74408 0.244078C7.5878 0.400358 7.5 0.61232 7.5 0.833333V3.33333L7.40833 3.24167C7.33087 3.16356 7.2387 3.10156 7.13715 3.05926C7.0356 3.01695 6.92668 2.99517 6.81667 2.99517C6.70666 2.99517 6.59774 3.01695 6.49619 3.05926C6.39464 3.10156 6.30247 3.16356 6.225 3.24167C6.06979 3.3978 5.98267 3.60901 5.98267 3.82917C5.98267 4.04932 6.06979 4.26053 6.225 4.41667L7.75 5.91667Z" fill="currentColor"/>
      <path d="M5.925 7.7L4.425 6.19167C4.34785 6.11397 4.25615 6.05223 4.15514 6.00997C4.05412 5.96771 3.94578 5.94576 3.83628 5.94537C3.72678 5.94498 3.61828 5.96617 3.51698 6.00771C3.41567 6.04926 3.32353 6.11035 3.24583 6.1875C3.08891 6.34331 3.00032 6.55508 2.99954 6.77622C2.99876 6.99736 3.08585 7.20975 3.24167 7.36667L3.33333 7.5H0.833333C0.61232 7.5 0.400358 7.5878 0.244078 7.74408C0.0877973 7.90036 0 8.11232 0 8.33333C0 8.55435 0.0877973 8.76631 0.244078 8.92259C0.400358 9.07887 0.61232 9.16667 0.833333 9.16667H3.33333L3.24167 9.25833C3.08646 9.41447 2.99934 9.62568 2.99934 9.84583C2.99934 10.066 3.08646 10.2772 3.24167 10.4333C3.31953 10.5106 3.41188 10.5717 3.51341 10.6131C3.61494 10.6546 3.72366 10.6756 3.83333 10.675C4.05195 10.6741 4.26145 10.5873 4.41667 10.4333L5.93333 8.90833C6.01475 8.82992 6.07936 8.73576 6.12323 8.63159C6.16711 8.52742 6.18933 8.41541 6.18855 8.30238C6.18777 8.18935 6.16401 8.07765 6.1187 7.9741C6.07339 7.87054 6.00749 7.77728 5.925 7.7Z" fill="currentColor"/>
      <path d="M8.89155 10.6994C8.73541 10.5441 8.5242 10.457 8.30405 10.457C8.08389 10.457 7.87269 10.5441 7.71655 10.6994L6.20822 12.1994C6.0513 12.3552 5.9627 12.5669 5.96192 12.7881C5.96114 13.0092 6.04823 13.2216 6.20405 13.3785C6.35986 13.5354 6.57163 13.624 6.79277 13.6248C7.01391 13.6256 7.2263 13.5385 7.38322 13.3827L7.49988 13.3327V15.8327C7.49988 16.0537 7.58768 16.2657 7.74396 16.4219C7.90024 16.5782 8.1122 16.666 8.33322 16.666C8.55423 16.666 8.76619 16.5782 8.92247 16.4219C9.07875 16.2657 9.16655 16.0537 9.16655 15.8327V13.3327L9.25822 13.4244C9.33608 13.5016 9.42843 13.5627 9.52996 13.6042C9.63149 13.6456 9.74021 13.6667 9.84988 13.666C9.96014 13.6656 10.0692 13.6432 10.1708 13.6003C10.2723 13.5574 10.3644 13.4948 10.4415 13.416C10.5968 13.2599 10.6839 13.0487 10.6839 12.8285C10.6839 12.6084 10.5968 12.3972 10.4415 12.241L8.89155 10.6994Z" fill="currentColor"/>
      <path d="M15.8335 7.50047H13.3335L13.4252 7.4088C13.5804 7.25267 13.6675 7.04146 13.6675 6.8213C13.6675 6.60115 13.5804 6.38994 13.4252 6.2338C13.3477 6.1557 13.2556 6.0937 13.154 6.05139C13.0525 6.00909 12.9435 5.9873 12.8335 5.9873C12.7235 5.9873 12.6146 6.00909 12.5131 6.05139C12.4115 6.0937 12.3193 6.1557 12.2419 6.2338L10.7335 7.7588C10.5783 7.91494 10.4912 8.12615 10.4912 8.3463C10.4912 8.56646 10.5783 8.77767 10.7335 8.9338L12.2252 10.4421C12.3031 10.5194 12.3954 10.5805 12.4969 10.6219C12.5985 10.6634 12.7072 10.6844 12.8169 10.6838C12.9265 10.6844 13.0353 10.6634 13.1368 10.6219C13.2383 10.5805 13.3307 10.5194 13.4085 10.4421C13.5637 10.286 13.6509 10.0748 13.6509 9.85464C13.6509 9.63448 13.5637 9.42327 13.4085 9.26714L13.3335 9.16714H15.8335C16.0546 9.16714 16.2665 9.07934 16.4228 8.92306C16.5791 8.76678 16.6669 8.55482 16.6669 8.3338C16.6669 8.11279 16.5791 7.90083 16.4228 7.74455C16.2665 7.58827 16.0546 7.50047 15.8335 7.50047Z" fill="currentColor"/>
      <path d="M7.2832 8.3332C7.2832 8.54087 7.34479 8.74388 7.46016 8.91655C7.57554 9.08922 7.73952 9.2238 7.93139 9.30328C8.12325 9.38275 8.33437 9.40354 8.53805 9.36303C8.74173 9.32251 8.92882 9.22251 9.07567 9.07567C9.22251 8.92882 9.32251 8.74173 9.36303 8.53805C9.40354 8.33437 9.38275 8.12325 9.30328 7.93139C9.2238 7.73952 9.08922 7.57554 8.91655 7.46016C8.74388 7.34479 8.54087 7.2832 8.3332 7.2832C8.05473 7.2832 7.78765 7.39383 7.59074 7.59074C7.39383 7.78765 7.2832 8.05473 7.2832 8.3332Z" fill="currentColor"/>
    </svg>
    `
    buttonFit.title = 'Оптимальный размер'

    // Блок для обертки кнопок управления
    const buttonsBlock = document.createElement('div')
    buttonsBlock.className = 'tw-flex tw-gap-[10px] tw-mb-[13px]'

    const buttonEditCompas = document.createElement('button')
    buttonEditCompas.className = 'tw-pointer-events-auto tw-w-[172px] tw-uppercase tw-font-bold tw-z-[10] tw-text-[13px] tw-text-center tw-py-[10px] tw-bg-[#263643] tw-text-white tw-rounded-[10px] hover:tw-bg-an-flat-black-table' // 'tw-px-[12px] tw-absolute tw-right-0 tw-bottom-0'
    buttonEditCompas.title = 'Стороны света - поверните изображение в соответствии с ним'
    buttonEditCompas.innerText = 'Изменить'
    buttonsBlock.appendChild(buttonEditCompas)

    const buttonApplyCompas = document.createElement('button')
    buttonApplyCompas.className = 'tw-pointer-events-auto tw-hidden tw-w-[228px] tw-uppercase tw-font-bold tw-z-[10] tw-text-[13px] tw-text-center tw-py-[10px] tw-bg-[#498BE4] tw-text-white tw-rounded-[8px] hover:tw-bg-[#498BE4]/80' // 'tw-px-[12px] tw-absolute tw-right-0 tw-bottom-0'
    buttonApplyCompas.title = ''
    buttonApplyCompas.innerText = 'Подтвердить положение'
    buttonsBlock.appendChild(buttonApplyCompas)

    const buttonCancelCompas = document.createElement('button')
    buttonCancelCompas.className = 'tw-pointer-events-auto tw-hidden tw-w-[140px] tw-uppercase tw-font-bold tw-z-[10] tw-text-[13px] tw-text-center tw-py-[10px] tw-bg-[#E2E7EE]/80 tw-text-an-flat-black-table tw-rounded-[8px] hover:tw-bg-[#E2E7EE]' // 'tw-px-[12px] tw-absolute tw-right-0 tw-bottom-0'
    buttonCancelCompas.title = ''
    buttonCancelCompas.innerText = 'Отмена'
    buttonsBlock.appendChild(buttonCancelCompas)

    // Большое изображение для вращения
    const compasImageRotatable = document.createElement('img')
    compasImageRotatable.draggable = false
    compasImageRotatable.src = '/img/compas/compas.svg'
    compasImageRotatable.className = 'tw-pointer-events-auto tw-w-[400px] tw-h-[90%] tw-hidden tw-opacity-[85%] tw-cursor-pointer'
    compasImageRotatable.style.userSelect = 'none'

    // Малое изображение для представления в углу
    const compasImageView = document.createElement('img')
    compasImageView.draggable = false
    compasImageView.src = '/img/compas/compas.svg'
    compasImageView.className = 'tw-pointer-events-auto tw-rounded-full tw-h-[122px] tw-m-[20px] tw-cursor-pointer'
    compasImageView.style.userSelect = 'none'

    const element = document.createElement('div')
    element.className = '!tw-pointer-events-none tw-absolute tw-right-0 tw-bottom-0 tw-flex tw-justify-center tw-items-center tw-flex-col tw-transition-all tw-duration-100 tw-ease-out'

    element.appendChild(buttonFit)
    element.appendChild(compasImageView)

    if (options.editable) {
      element.appendChild(compasImageRotatable)
      element.appendChild(buttonsBlock)
    }

    super({
      element: element,
      target: options.target
    })
    this.compasImageRotatable = compasImageRotatable
    this.compasImageView = compasImageView
    this.buttonsBlock = buttonsBlock
    this.buttonEditCompas = buttonEditCompas
    this.buttonApplyCompas = buttonApplyCompas
    this.buttonCancelCompas = buttonCancelCompas
    this.buttonFit = buttonFit

    buttonEditCompas.addEventListener('click', this.handleEditable.bind(this), false)
    buttonApplyCompas.addEventListener('click', this.handleEditable.bind(this), false)
    buttonCancelCompas.addEventListener('click', this.handleCancel.bind(this), false)
    compasImageView.addEventListener('click', this.rotateViewToNord.bind(this), false)
    buttonFit.addEventListener('click', this.handleFitExtent.bind(this), false)

    this.extent = optOptions.extent || []

    this.elRotator = new ElementPointerRotate({ element: compasImageRotatable })
    this.elRotator.disableRotate = true
  }

  angleChangeHandler (value) {
    console.warn('Слушатель изменения угла по умолчанию', value)
  }

  rotateViewToNord () {
    console.warn('rotateViewToNord', fixedAngle(this.getMap().getView().getRotation()), this.elRotator.currentRadian)
    this.getMap().getView().animate({
      anchor: getCenter(this.extent), // точка притяжения при повороте
      rotation: fixedAngle(this.getMap().getView().getRotation()) ? 0 : -this.elRotator.currentRadian,
      duration: 100
    })
    setTimeout(() => {
      this.getMap().getView().animate({
        fit: this.getMap().getView().fit(this.extent),
        duration: 100
      })
    }, 250)
    this.actualizeCompasView(fixedAngle(this.getMap().getView().getRotation()) ? this.elRotator.currentRadian : 0)
  }

  actualizeCompasView (rad) {
    this.compasImageView.style.transform = 'rotate(' + rad + 'rad)'
  }

  handleEditable () {
    this.hasEdit = !this.hasEdit
    if (this.hasEdit) {
      // Если изображение повернуто то верну ему 0 угол СЕВЕР-ЮГ
      if (this.getMap().getView().getRotation()) {
        this.rotateViewToNord()
      }
      this.startedRadianAngle = this.elRotator.currentRadian
      this.element.classList.add('tw-w-full')
      this.element.classList.add('tw-h-full')
      this.element.classList.add('tw-bg-[#2636434D]')

      this.compasImageRotatable.classList.add('!tw-block')
      this.compasImageView.classList.add('tw-hidden')

      this.buttonsBlock.className = 'tw-flex tw-gap-[10px] tw-mb-[13px] tw-px-[12px] tw-py-[10px] tw-rounded-md tw-bg-white'
      this.buttonEditCompas.classList.add('!tw-hidden')
      this.buttonApplyCompas.classList.add('!tw-block')
      this.buttonCancelCompas.classList.add('!tw-block')
      this.buttonFit.classList.add('!tw-hidden')
    } else {
      this.startedRadianAngle = 0
      this.compasImageRotatable.classList.remove('!tw-block')
      this.compasImageView.classList.remove('tw-hidden')

      this.element.classList.remove('tw-w-full')
      this.element.classList.remove('tw-h-full')
      this.element.classList.remove('tw-bg-[#2636434D]')

      this.buttonsBlock.className = 'tw-flex tw-gap-[10px] tw-mb-[13px]'
      this.buttonEditCompas.classList.remove('!tw-hidden')
      this.buttonApplyCompas.classList.remove('!tw-block')
      this.buttonCancelCompas.classList.remove('!tw-block')
      this.buttonFit.classList.remove('!tw-hidden')
      this.actualizeCompasView(this.elRotator.currentRadian)
    }
    this.angleChangeHandler(degreeNormalize360(radToDeg(this.elRotator.currentRadian)))
    this.elRotator.disableRotate = !this.hasEdit
  }

  handleCancel () {
    console.log('cancel', this.startedRadianAngle)
    this.elRotator.setCurrentRadian(this.startedRadianAngle)
    this.handleEditable()
  }

  handleFitExtent () {
    this.getMap().getView().animate({
      fit: this.getMap().getView().fit(this.extent),
      duration: 100
    })
  }

  updateAngle (angle) {
    // console.log('updateAngle', angle, degToRad(angle))
    const inputRad = degToRad(angle)
    this.elRotator.setCurrentRadian(inputRad)
    this.actualizeCompasView(inputRad)
  }
}

export class FullScreenControl extends FullScreen {
  constructor () {
    super({
      source: 'fullscreen'
    })
    this.element.classList.add('!tw-pointer-events-none')
    this.element.classList.add('!tw-leading-none')
    // this.target.classList.add('tw-bg-[#263643]')
  }
}
