<template>
  <TwPanelContentBlock class="tw-mb-[6px]">
    <template #title>
      <div class="tw-w-full tw-grid tw-grid-cols-3 tw-gap-[20px] tw-items-center">
        <div>
          Текущие параметры подбора
          <span
            v-if="usageParamsCount"
            class="tw-ml-[5px] tw-bg-an-flat-green tw-text-white tw-px-[5px] tw-rounded-full"
          >
            {{ usageParamsCount }}
          </span>
        </div>
        <button
          class="tw-bg-an-flat-grey-3 tw-font-medium tw-text-white tw-rounded-sm tw-border tw-px-[20px] tw-py-[5px] hover:tw-shadow enabled:hover:tw-bg-an-flat-dark-blue-link"
          @click.stop="openSelectionInNewTab"
        >
          Открыть подбор
        </button>
      </div>
    </template>
    <div class="tw-flex tw-gap-[12px] tw-flex-col">
      <div
        v-if="loading"
        class="tw-w-full tw-text-center tw-py-[35px]"
      >
        <TwCircleLoader />
      </div>
      <div
        v-else-if="usageParamsCount"
        class="tw-grid tw-gap-[30px] tw-grid-cols-[repeat(auto-fill,minmax(300px,1fr))]"
      >
        <TwPanelContentBlockFieldView
          v-for="(field, index) in fields"
          :key="index"
        >
          <template #title>
            {{ field.label }}
          </template>
          <div class="tw-font-semibold">
            {{ field.value }}
          </div>
        </TwPanelContentBlockFieldView>
      </div>
      <div
        v-else
        class="tw-text-center tw-text-main-sm tw-text-an-flat-disabled-text tw-p-[12px]"
      >
        Параметры подбора не заданы
      </div>
    </div>
  </TwPanelContentBlock>
</template>

<script>
import API from '@/Api/Crm/Deal/BuyNb/SelectionFilter'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
export default {
  name: 'DealByNbSelectionFiltersShow',
  components: { TwPanelContentBlock, TwPanelContentBlockFieldView, TwCircleLoader },
  props: {
    dealId: {
      required: true
    }
  },
  data () {
    return {
      loading: false,
      fields: [],
      info: {}
    }
  },
  computed: {
    usageParamsCount () {
      return Object.values(this.info)?.filter(item => {
        if (item !== null && item !== undefined) {
          if (Array.isArray(item)) {
            return !!item.length
          }
          return true
        }
        return false
      })?.length || 0
    }
  },
  mounted () {
    this.fetchInfo()
  },
  methods: {
    fetchInfo () {
      this.loading = true
      API.show(this.dealId)
        .then(({ data: result }) => {
          this.$set(this, 'info', result?.data || {})
          this.$set(this, 'fields', result?.fields || [])
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    openSelectionInNewTab () {
      window.open(`/tools/selection?dealId=${this.dealId}`, '_blank')
    }
  }
}
</script>
