// realty, house, garage, refinance, military, cash
export const loanPurpose = [
  {
    label: 'Покупка квартиры или апартаментов',
    value: 'REALTY'
  },
  {
    label: 'Строительство\\покупка дома',
    value: 'HOUSE'
  },
  {
    label: 'Гараж',
    value: 'GARAGE'
  },
  {
    label: 'Рефинансирование',
    value: 'REFINANCE'
  },
  {
    label: 'Наличные под залог недвижимости',
    value: 'CASH'
  }
]

// MARRIED, CIVIL_MARRIAGE, SINGLE, WIDOWER, DIVORCED
export const marriageStatus = [
  {
    label: 'В браке',
    value: 'MARRIED'
  },
  {
    label: 'Не в браке',
    value: 'SINGLE'
  },
  {
    label: 'Вдовец\\вдова',
    value: 'WIDOWER'
  },
  {
    label: 'В разводе',
    value: 'DIVORCED'
  }
]
// Получение семейного положения домклика из значения установленного нашей CRM
export const comparisonMarriageStatus = function (marriageStatusCRM: string) {
  if (!marriageStatusCRM) {
    return null
  }
  return {
    not_marriage: 'SINGLE',
    marriage: 'MARRIED',
    widow: 'WIDOWER',
    divorced: 'DIVORCED'
  }?.[marriageStatusCRM] || null
}

// WONT_CLASSIFIED, IN_PROGRESS, DONE, ERROR
export const classificationStatus = {
  WONT_CLASSIFIED: {
    text: 'Не требуется',
    color: 'gray'
  },
  IN_PROGRESS: {
    text: 'В процессе',
    color: 'blueviolet'
  },
  DONE: {
    text: 'Успех',
    color: 'green'
  },
  ERROR: {
    text: 'Ошибка',
    color: 'red'
  }
}

// SBERBANK_SALARY, INDIVIDUAL_ENTREPRENEUR, WITH_DOCUMENT, PENSION, WITHOUT_DOCUMENT, MATERNITY_LEAVE, ADVOCATE, NOTARY, SELF_EMPLOYED
export const jobIncomeType = [
  {
    label: 'Зарплатный клиент Сбербанка',
    value: 'SBERBANK_SALARY'
  },
  {
    label: 'ИП',
    value: 'INDIVIDUAL_ENTREPRENEUR'
  },
  {
    label: 'Справка\\Выписка из ПФР',
    value: 'WITH_DOCUMENT'
  },
  {
    label: 'Пенсионер',
    value: 'PENSION'
  },
  {
    label: 'Без подтвержения (Ипотека по двум документам)',
    value: 'WITHOUT_DOCUMENT'
  },
  {
    label: 'Декретный отпуск',
    value: 'MATERNITY_LEAVE'
  },
  {
    label: 'Адвокат',
    value: 'ADVOCATE'
  },
  {
    label: 'Нотариус',
    value: 'NOTARY'
  },
  {
    label: 'Самозанятый',
    value: 'SELF_EMPLOYED'
  }
]

// DOM_CLICK, LIFE_INSURANCE, DEVELOPER_COMMON, PFR, ONLINE_REGISTRATION
export const discounts = [
  {
    label: 'Скидка при покупке недвижимости на витрине Домклик',
    value: 'DOM_CLICK'
  },
  {
    label: 'Скидка при страховании жизни и здоровья',
    value: 'LIFE_INSURANCE'
  },
  {
    label: 'Скидка от застройщика',
    value: 'DEVELOPER_COMMON'
  },
  {
    label: 'ПФР',
    value: 'PFR'
  },
  {
    label: 'Скидка при регистрации сделки онлайн',
    value: 'ONLINE_REGISTRATION'
  }
]

// SECONDARY, UNDER_CONSTRUCTION, HOME_CONSTRUCTION, COUNTRY_ESTATE, UNTARGETED, REFINANCING, MILITARY, WITH_STATE_SUPPORT, GARAGE, PREFERENTIAL_HOUSE_CONSTRUCTION, FAR_EAST, WITH_STATE_SUPPORT_2020
export const products = [
  {
    label: 'Готовое жильё',
    value: 'SECONDARY'
  },
  {
    label: 'Новостройка',
    value: 'UNDER_CONSTRUCTION'
  },
  {
    label: 'Строительство дома',
    value: 'HOME_CONSTRUCTION'
  },
  {
    label: 'Загородный дом, земля',
    value: 'COUNTRY_ESTATE'
  },
  {
    label: 'Наличные под залог недвижимости',
    value: 'UNTARGETED'
  },
  {
    label: 'Рефинансирование',
    value: 'REFINANCING'
  },
  {
    label: 'Военная ипотека(пока не доступна)',
    value: 'MILITARY',
    disable: true
  },
  {
    label: 'Господдержка для семей с детьми',
    value: 'WITH_STATE_SUPPORT'
  },
  {
    label: 'Машино-место, гараж',
    value: 'GARAGE'
  },
  {
    label: '',
    value: 'PREFERENTIAL_HOUSE_CONSTRUCTION'
  },
  {
    label: 'Дальневосточная ипотека',
    value: 'FAR_EAST'
  },
  {
    label: 'Господдержка 2020',
    value: 'WITH_STATE_SUPPORT_2020'
  }
]

// HECTARE, SECONDARY, UNDER_CONSTRUCTION, HOME_CONSTRUCTION, IT_PROGRAM
export const subProducts = [
  {
    label: 'Дальневосточный гектар(ДВ)',
    value: 'HECTARE'
  },
  {
    label: 'Готовое жильё (ДВ или Рефинансирование)',
    value: 'SECONDARY'
  },
  {
    label: 'Новостройка (ДВ или Рефинансирование)',
    value: 'UNDER_CONSTRUCTION'
  },
  {
    label: 'Строительство дома(ДВ)',
    value: 'HOME_CONSTRUCTION'
  },
  {
    label: 'IT программа',
    value: 'IT_PROGRAM'
  }
]

export const programsType = [
  {
    label: 'Рассчитано',
    value: 'CALCULATED',
    text: 'Рассчитано',
    desc: 'Заявку можно подать по таким условиям'
  },
  {
    label: 'Маленький первоначальный взнос',
    value: 'LOW_INIT_FEE',
    text: 'Маленький первоначальный взнос',
    desc: 'Можно подать заявку, если увеличить величину ПВ'
  },
  {
    label: 'Маленькая сумма кредита',
    value: 'LOW_CREDIT_SUM',
    text: 'Маленькая сумма кредита',
    desc: 'Заявку можно подать, если сумма кредита будет больше'
  }
]

export const borrowerCategory = [
  {
    label: 'Клиент СБ1',
    value: 'vip'
  },
  {
    label: 'Сотрудник Сбера или ДЗО',
    value: 'employee'
  },
  {
    label: 'Зарплатный клиент Сбера',
    value: 'salaryClient'
  },
  {
    label: 'Подтверждение справкой или выпиской из ПФР',
    value: 'canConfirmIncome'
  },
  {
    label: 'Без подтверждения дохода',
    value: 'cannotConfirmIncome'
  }
]

export const applicationType = {
  BORROWER: 'Основной заёмщик',
  COBORROWER: 'Созаёмщик'
}

export const education = [
  {
    label: 'Ученая степень',
    value: 'ACADEMIC_DEGREE_MBA'
  },
  {
    label: 'Несколько высших образований',
    value: 'SEVERAL_HIGHER_EDUCATION'
  },
  {
    label: 'Законченное высшее',
    value: 'HIGHER_EDUCATION_COMPLETED'
  },
  {
    label: 'Незаконченное высшее(требуется указать курс)',
    value: 'HIGHER_EDUCATION_UNCOMPLETED'
  },
  {
    label: 'Среднее специальное',
    value: 'SECONDARY_SPECIAL_EDUCATION'
  },
  {
    label: 'Среднее',
    value: 'SECONDARY_EDUCATION'
  },
  {
    label: 'Ниже среднего',
    value: 'SECONDARY_EDUCATION_BELOW'
  }
]

export const citizenship = [
  {
    label: 'Гражданин РФ',
    value: 'RU'
  },
  {
    label: 'Иностранец',
    value: 'OTHER'
  }
]

// LESS_SIX_MONTHS, SIX_TO_TWELVE_MONTHS, ONE_TO_THREE_YEAR, THREE_TO_FIVE_YEAR
export const jobSeniority = [
  {
    label: 'Менее 6 месяцев',
    value: 'LESS_SIX_MONTHS'
  },
  {
    label: 'От 6 до 12 месяцев',
    value: 'SIX_TO_TWELVE_MONTHS'
  },
  {
    label: 'от 1 до 3 лет',
    value: 'ONE_TO_THREE_YEAR'
  },
  {
    label: 'От 3 до 5 лет',
    value: 'THREE_TO_FIVE_YEAR'
  }
]

export const incomeVerificationDocument = [
  {
    label: 'Трудовая книжка',
    value: 'EMPLOYMENT_HISTORY'
  },
  {
    label: 'Электронная трудовая книжка в формате ПДФ',
    value: 'DIGITAL_EMPLOYMENT_HISTORY'
  },
  {
    label: 'Выписка из ПФР, полученная онлайн(СЗИ-6)',
    value: 'SZI6'
  },
  {
    label: 'Выписка из ПФР в формате xml(СЗИ-6)',
    value: 'SZI6_XML'
  }
]

export const sample = [
  {
    label: '',
    value: ''
  },
  {
    label: '',
    value: ''
  }
]

export const dealStatuses = {
  ARCHIVED: 'В архиве',
  DRAFT: 'Черновик',
  SENT_TO_BANK: 'Отправлено в банк',
  LOAN_APPROVED: 'Кредит одобрен',
  CREDIT_ISSUED: 'Кредит выдан',
  BANK_REJECTED: 'Отказ Банка',
  CLIENT_REJECTED: 'Отказ Клиента',
  RETURNED_FOR_REVISION: 'Возвращено на исправление'
}

export const isEndStatus = (status: string) => {
  const endStatuses = ['LOAN_APPROVED', 'BANK_REJECTED', 'CLIENT_REJECTED', 'CREDIT_ISSUED', 'ARCHIVED', 'SENT_TO_BANK']
  return endStatuses.includes(status)
}

export const acceptanceStatuses = {
  NOT_SENT: {
    text: 'Не подписана',
    color: 'secondary'
  },
  REQUESTED: {
    text: 'Отправлен СМС запрос',
    color: 'primary'
  },
  APPROVED: {
    text: 'Подписано',
    color: 'success'
  }
}

export const gender = [
  {
    label: 'Не выбрано',
    value: null
  },
  {
    label: 'Муж.',
    value: 'MALE'
  },
  {
    label: 'Жен.',
    value: 'FEMALE'
  }
]

export const docTypes = [
  {
    label: 'Трудовая книжка',
    value: 'WORK_BOOK'
  },
  {
    label: 'Электронная трудовая книжка',
    value: 'DIGITAL_WORK_BOOK'
  },
  {
    label: 'Свидетельство о заключении брака',
    value: 'MARRIAGE_CERTIFICATE'
  },
  {
    label: 'Налоговая декларация',
    value: 'TAX_RETURN'
  },
  {
    label: 'Брачный договор',
    value: 'MARRIAGE_CONTRACT'
  },
  {
    label: 'Подлинник/нотариальная копия свидетельства о государственной регистрации в качестве индивидуального предпринимателя',
    value: 'STATE_REGISTRATION'
  },
  {
    label: 'Паспорт гражданина РФ',
    value: 'PASSPORT'
  },
  {
    label: 'Справка о размере начисленной/выплаченной пенсии за последний месяц',
    value: 'DOC_AMOUNT_OF_PENSION'
  },
  {
    label: '3-НДФЛ/ Справка о доходах и налогах',
    value: 'DOC_3NDFL'
  },
  {
    label: 'Справка доп дохода(гпх, договор аре)',
    value: 'ADDITIONAL_INCOME_CERTIFICATE'
  },
  {
    label: 'Свидетельство о рождении ребенка',
    value: 'BIRTH_CERTIFICATE'
  },
  {
    label: 'Справка об инвалидности ребёнка',
    value: 'CHILD_DISABILITY_CERTIFICATE'
  },
  {
    label: 'Сертификат на дальневосточный гектар',
    value: 'FAR_EAST_HECTARE_CERTIFICATE'
  },
  {
    label: 'Справка о мораторном отказе в кредите',
    value: 'MORATORIUM_CREDIT_REJECT_CERTIFICATE'
  },
  {
    label: 'Справка о немораторном отказе в кредите',
    value: 'CREDIT_REJECT_CERTIFICATE'
  },
  {
    label: 'Подтверждение доп дохода(3НДФЛ)',
    value: 'ADDITIONAL_INCOME_CONFIRMATION'
  },
  {
    label: 'Дополнительный документы для без подтвеждения дохода. ЖК2Д*/',
    value: 'ADDITIONAL_DOCUMENT'
  },
  {
    label: 'Справка по форме 2НДФЛ',
    value: 'DOC_2NDFL'
  },
  {
    label: 'Справка по форме Банка',
    value: 'BANK_FORM'
  },
  {
    label: 'СНИЛС',
    value: 'SNILS'
  },
  {
    label: 'Водительское удостоверение',
    value: 'DRIVERS_LICENSE'
  },
  {
    label: 'Загранпаспорт',
    value: 'INTERNATIONAL_PASSPORT'
  },
  {
    label: 'Военный билет',
    value: 'MILITARY_ID'
  },
  {
    label: 'Удостоверение военнослужащего',
    value: 'SERVICEMAN_CERTIFICATE'
  },
  {
    label: 'Удостоверение сотрудника федеральных органов власти',
    value: 'FEDERAL_GOVERNMENT_EMPLOYEE_ID'
  },
  {
    label: 'Свидетельство о смерти',
    value: 'WIDOWER_CERTIFICATE'
  },
  {
    label: 'Свидетельство о разводе',
    value: 'DIVORCE_CERTIFICATE'
  },
  {
    label: 'Свидетельство НИС',
    value: 'MILITARY_MORTGAGE_CERTIFICATE'
  },
  {
    label: 'Удостоверение адвоката',
    value: 'LAWYER_CERTIFICATE'
  },
  {
    label: 'Свидетельство о временной регистрации',
    value: 'TEMP_REGISTRATION_CERTIFICATE'
  },
  {
    label: 'Другое',
    value: 'OTHER'
  },
  {
    label: 'Не резидент / Паспорт иностранного гражданина',
    value: 'NON_RESIDENT_PASSPORT'
  },
  {
    label: 'Заверенный перевод паспорта на русский язык',
    value: 'NON_RESIDENT_PASSPORT_CERTIFIED_TRANSLATION'
  },
  {
    label: 'Миграционная карта (с действующим сроком пребывания в РФ)',
    value: 'MIGRATION_CARD'
  },
  {
    label: 'Документ о временной/постоянной регистрации иностранного гражданина на территории РФ',
    value: 'TEMPORARY_OR_PERMANENT_REGISTRATION'
  },
  {
    label: 'Документ подтверждающий право иностранного гражданина на пребывание (проживание) в РФ (вид на жительство разрешение на временное проживание виза)',
    value: 'RIGHT_TO_STAY_IN_RUSSIA'
  },
  {
    label: 'Дополнительный документ (по запросу менеджера)',
    value: 'NON_RESIDENT_ADDITIONAL_DOC'
  }
]

// Данные о паспорте
export const previousPassportDefault = {
  series: '', // Серия паспорта
  issueDate: '', // Дата выдачи
  number: '' // Номер паспорта
}

export const passportDefault = {
  series: '', // Серия паспорта
  number: '', // Номер паспорта
  birthPlace: '', // Место рождения
  issuer: '', // Орган выдачи паспорта
  issueDate: '', // Дата выдачи паспорта в формате 'yyyy-MM-dd'
  subdivisionCode: '' // Код подразделения
}

export const DaDataAddressDefaultForm = {
  postalCode: '', // Индекс
  country: '', // Страна
  regionType: '', // Тип региона (сокращенный)
  region: '', // Регион
  areaType: '', // Тип района в регионе (сокращенный)
  area: '', // Тип района в регионе
  cityType: '', // Тип города (сокращенный)
  city: '', // Тип города
  settlementType: '', // Тип населенного пункта (сокращенный
  settlement: '', // Населенный пункт
  streetType: '', // Тип улицы (сокращенный)
  street: '', // Улица
  houseType: '', // Тип дома (сокращенный)
  house: '', // Дом
  blockType: '', // Тип корпуса/строения (сокращенный)
  blockTypeFull: '', // Тип корпуса/строения
  block: '', // Корпус/строение
  flatType: '', // Тип квартиры (сокращенный)
  flat: '' // Квартира
}

// Поля которые не нужно показывать закомменчены
export const DaDataAddressFieldNames = {
  postalCode: 'Индекс',
  country: 'Страна',
  // regionType: 'Тип региона (сокращенный)',
  region: 'Регион',
  // areaType: 'Тип района в регионе (сокращенный)',
  area: 'Тип района в регионе',
  // cityType: 'Тип города (сокращенный)',
  city: 'Тип города',
  // settlementType: 'Тип населенного пункта (сокращенный',
  settlement: 'Населенный пункт',
  // streetType: 'Тип улицы (сокращенный)',
  street: 'Улица',
  // houseType: 'Тип дома (сокращенный)',
  house: 'Дом',
  // blockType: 'Тип корпуса/строения (сокращенный)',
  blockTypeFull: 'Тип корпуса/строения',
  block: 'Корпус/строение',
  // flatType: 'Тип квартиры (сокращенный)',
  flat: 'Квартира'
}
