<template>
  <TwPopoverShort v-if="title">
    <template #trigger>
      <span
        :class="styleClasses"
        :style="customStyle"
      >
        {{ label }}
      </span>
    </template>
    <template #content>
      <div v-html="title" />
    </template>
  </TwPopoverShort>
  <span
    v-else
    :class="styleClasses"
    class="tw-cursor-default"
    :style="customStyle"
  >
    {{ label }}
  </span>
</template>

<script>
import TwPopoverShort from '@/components/tw-ui/popover/TwPopoverShort.vue'

export default {
  name: 'TwBadgeLabel',
  components: { TwPopoverShort },
  props: {
    label: {
      type: [String, Number],
      default: null
    },
    title: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: 'grey' // grey, green, orange, red, blue, white
    },
    bordered: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'sm' // xs, sm, md, lg
    },
    rounded: {
      type: String,
      default: 'none' // none, sm, md, lg, full
    },
    customStyle: {
      type: [String, Object],
      default: null
    }
  },
  computed: {
    styleClasses () {
      const result = ['tw-font-medium']

      // обводка
      if (this.bordered) {
        result.push('tw-border')
        result.push('tw-border-solid')

        // цвета обводка
        if (this.color === 'red') {
          result.push('tw-border-an-flat-red-main')
        } else if (this.color === 'green') {
          result.push('tw-border-an-flat-green-btn-bg-active')
        } else if (this.color === 'blue') {
          result.push('tw-border-an-flat-blue-btn-active')
        } else if (this.color === 'orange') {
          result.push('tw-border-an-flat-orange-warning')
        } else if (this.color === 'white') {
          result.push('tw-border-white')
        } else {
          result.push('tw-border-an-flat-gray-4')
        }
      }

      // скругление
      if (this.rounded === 'sm') {
        result.push('tw-rounded-sm')
      } else if (this.rounded === 'md') {
        result.push('tw-rounded-md')
      } else if (this.rounded === 'lg') {
        result.push('tw-rounded-lg')
      } else if (this.rounded === 'full') {
        result.push('tw-rounded-full')
      } else {
        result.push('tw-rounded-none')
      }

      // цвета
      if (this.color === 'red') {
        result.push('tw-bg-an-flat-red-main/20')
        result.push('tw-text-an-flat-red-main')
      } else if (this.color === 'green') {
        result.push('tw-bg-an-flat-green-btn-bg-active/20')
        result.push('tw-text-an-flat-green-btn-bg-active')
      } else if (this.color === 'blue') {
        result.push('tw-bg-an-flat-blue-btn-active/20')
        result.push('tw-text-an-flat-blue-btn-active')
      } else if (this.color === 'orange') {
        result.push('tw-bg-an-flat-orange-warning/20')
        result.push('tw-text-an-flat-orange-warning')
      } else if (this.color === 'white') {
        result.push('tw-bg-white/20')
        result.push('tw-text-white')
      } else {
        result.push('tw-bg-an-flat-gray-4/20')
        result.push('tw-text-an-flat-gray-4')
      }

      // размеры
      if (this.size === 'xs') {
        result.push('tw-px-[7px] tw-py-[2px]')
        result.push('tw-text-main-xs2')
      } else if (this.size === 'sm') {
        result.push('tw-px-[10px] tw-py-[2px]')
        result.push('tw-text-main-sm2')
      } else if (this.size === 'md') {
        result.push('tw-px-[12px] tw-py-[2px]')
        result.push('tw-text-main-md')
      } else {
        result.push('tw-px-[14px] tw-py-[2px]')
        result.push('tw-text-main-lg')
      }
      return result
    }
  }
}
</script>
