<template>
  <div>
    <TwPanelContentBlock class="tw-mb-[6px]">
      <template #title>
        Настройки Жилищно накопительный кооператив(ЖНК)
      </template>
      <template #action>
        <TwPanelContentBlockActionButton
          v-if="!targetInfo"
          type="add"
          @click="onEditItem(0)"
        >
          добавить
        </TwPanelContentBlockActionButton>
        <TwPanelContentBlockActionButton
          type="edit"
          @click="onEditItem(targetInfo.id)"
        >
          изменить
        </TwPanelContentBlockActionButton>
      </template>
      <div>
        <div
          v-if="loading"
          class="tw-p-[24px] tw-flex tw-text-center"
        >
          <TwCircleLoader class="tw-m-auto" />
        </div>
        <div
          v-else
          class="tw-p-[24px]"
        >
          <table
            v-if="targetInfo"
            class="tw-table tw-table-auto tw-w-full tw-text-left"
          >
            <tbody>
              <tr class="tw-border-b">
                <td class="tw-p-[7px] tw-text-an-flat-disabled-text">
                  Активно
                </td>
                <td class="tw-p-[7px] tw-font-medium">
                  {{ targetInfo.active ? 'Да':'Нет' }}
                </td>
              </tr>
              <tr class="tw-border-b">
                <td class="tw-p-[7px] tw-text-an-flat-disabled-text">
                  Срок
                </td>
                <td class="tw-p-[7px] tw-font-medium">
                  {{ targetInfo.termDesc || 'Нет' }}
                </td>
              </tr>
              <tr class="tw-border-b">
                <td class="tw-p-[7px] tw-text-an-flat-disabled-text">
                  Первоначальный взнос
                </td>
                <td class="tw-p-[7px] tw-font-medium">
                  {{ targetInfo.initialPaymentAmountDesc || 'Нет' }}
                </td>
              </tr>
              <tr class="tw-border-b">
                <td class="tw-p-[7px] tw-text-an-flat-disabled-text">
                  Расходы
                </td>
                <td class="tw-p-[7px] tw-font-medium">
                  {{ targetInfo.expensesDesc || 'Нет' }}
                </td>
              </tr>
            </tbody>
          </table>
          <div
            v-else
            class="tw-w-full tw-text-center"
          >
            Нет данных
          </div>
        </div>
      </div>
    </TwPanelContentBlock>
    <TwDialog
      :value="editDialog"
      max-width="600px"
      @input="editDialog = $event"
    >
      <template #header>
        <div class="tw-text-h4">
          Информация ЖНК
        </div>
      </template>
      <template #body>
        <div>
          <BuilderHousingConsumerCooperativeInfoForm
            :id="editedId"
            :company-id="companyId"
            @cancel="editDialog = false"
            @create="refresh"
            @update="refresh"
          />
        </div>
      </template>
    </TwDialog>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import DeveloperHousingConsumerCooperativeAPI from '@/Api/Rtd/Company/DeveloperHousingConsumerCooperative'
import TwDialog from '@/components/tw-ui/modal/TwDialog.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import BuilderHousingConsumerCooperativeInfoForm
  from '@/views/Dictionary/General/Company/developer/SettingsTab/BuilderHousingConsumerCooperativeInfo/BuilderHousingConsumerCooperativeInfoForm.vue'
import TwPanelContentBlockActionButton from '@/components/tw-ui/panels/TwPanelContentBlockActionButton.vue'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'

export default defineComponent({
  name: 'BuilderHousingConsumerCooperativeInfo',
  components: {
    TwPanelContentBlock,
    TwPanelContentBlockActionButton,
    BuilderHousingConsumerCooperativeInfoForm,
    TwCircleLoader,
    TwDialog
  },
  props: {
    companyId: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      list: [],
      loading: false,

      editDialog: false,
      editedId: 0
    }
  },
  computed: {
    targetInfo () {
      return this.list && this.list.length && this.list[0]
    }
  },
  mounted () {
    this.refresh()
  },
  methods: {
    onEditItem (itemId) {
      this.editDialog = true
      this.editedId = itemId
    },
    fetch () {
      this.editDialog = false
      this.editedId = 0
      this.loading = true
      DeveloperHousingConsumerCooperativeAPI.getList({ companyId: this.companyId })
        .then(({ data: result }) => {
          this.list = result?.data || []
        })
        .catch((error) => {
          console.error(this.$options.name, error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    refresh () {
      this.fetch()
    }
  }
})
</script>

<style scoped>

</style>
