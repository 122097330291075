import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ru from 'vuetify/lib/locale/ru'
import 'font-awesome/css/font-awesome.min.css'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { ru },
    current: 'ru'
  },
  icons: {
    iconfont: 'fa4'
  }
})

//
// import 'font-awesome/css/font-awesome.min.css'
// import Vue from 'vue'
// import Vuetify from 'vuetify/lib'
// import ru from 'vuetify/src/locale/ru'
//
// export default new Vuetify({
//   icons: {
//     iconfont: 'fa4' // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4'
//   },
//   lang: {
//     locales: { ru },
//     current: 'ru'
//   },
//   theme: {
//     options: { customProperties: true }
//   }
// })
//
// Vue.use(Vuetify)
