<template>
  <div
    v-if="formPermissionActionsStatus && formPermissionActionsStatus[formType]"
    class="tw-font-manrope"
  >
    <div style="display: none">
      {{ isEdited }}
    </div>
    <div class="my-3">
      <div
        v-if="formType !== 'create'"
        class="pb-3"
      >
        <label class="text-muted">Статус:</label>
        <div>
          {{ preset.status && preset.status.name }}
        </div>
      </div>
    </div>
    <div class="tw-mt-[5px]">
      <div
        ref="personField"
        class="tw-mb-[6px] tw-flex tw-items-end"
      >
        <TwSelectRelations
          v-model="form.personId"
          :multi="false"
          :dense="true"
          :allow-create="true"
          label="Клиент"
          list-label="Найти контакт"
          relation-name="person"
          :persistent-label="true"
          placeholder="Не выбран"
          :disabled="!personEditable || formProcessing"
          class="tw-flex-1"
          :error-text="$v.form.personId.$invalid ? 'Заполните поле':''"
          @input="$v.form.personId.$touch()"
        />
        <button
          v-if="!personEditable"
          class="tw-group tw-px-[12px] tw-py-[10px]"
          @click.prevent="personSelectIntro"
        >
          <svg
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.5 2C5.35787 2 2 5.35786 2 9.5C2 13.6421 5.35787 17 9.5 17C13.6421 17 17 13.6421 17 9.5C17 5.35786 13.6421 2 9.5 2ZM0 9.5C0 4.25329 4.2533 0 9.5 0C14.7467 0 19 4.25329 19 9.5C19 14.7467 14.7467 19 9.5 19C4.2533 19 0 14.7467 0 9.5Z"
              fill="black"
              class="group-hover:tw-fill-an-flat-blue-btn tw-fill-an-flat-grey-3"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.78172 6.31251C9.41804 6.25012 9.04401 6.31847 8.72588 6.50543C8.40775 6.6924 8.16606 6.98592 8.04361 7.33401C7.86034 7.855 7.28942 8.12877 6.76844 7.9455C6.24745 7.76223 5.97367 7.19131 6.15694 6.67032C6.43613 5.87668 6.98719 5.20745 7.71251 4.78117C8.43784 4.35489 9.29063 4.19906 10.1198 4.34129C10.949 4.48353 11.7012 4.91464 12.243 5.55826C12.7847 6.20175 13.0812 7.01614 13.0801 7.85726C13.0796 9.24177 12.0544 10.143 11.3535 10.6103C10.9726 10.8642 10.5987 11.0504 10.3241 11.1725C10.1854 11.2342 10.0683 11.281 9.98367 11.3133C9.94125 11.3294 9.90671 11.342 9.88136 11.351L9.85028 11.3619L9.84016 11.3654L9.8365 11.3666L9.83503 11.3671C9.83473 11.3672 9.8338 11.3675 9.51757 10.4188L9.8338 11.3675C9.30985 11.5422 8.74353 11.259 8.56888 10.7351C8.39444 10.2117 8.67674 9.64612 9.1995 9.47076C9.19967 9.47071 9.19934 9.47082 9.1995 9.47076L9.21134 9.46661C9.22351 9.46228 9.24403 9.45484 9.27168 9.44431C9.32715 9.42317 9.41033 9.38997 9.51182 9.34486C9.71764 9.25339 9.98444 9.11928 10.2441 8.94616C10.8243 8.55934 11.0801 8.17964 11.0801 7.85634L11.0801 7.85485C11.0806 7.48585 10.9506 7.12856 10.7129 6.84626C10.4753 6.56397 10.1454 6.37489 9.78172 6.31251ZM8.5859 13.8355C8.5859 13.2832 9.03362 12.8355 9.5859 12.8355H9.59444C10.1467 12.8355 10.5944 13.2832 10.5944 13.8355C10.5944 14.3878 10.1467 14.8355 9.59444 14.8355H9.5859C9.03362 14.8355 8.5859 14.3878 8.5859 13.8355ZM9.1995 9.47076C9.19967 9.47071 9.19934 9.47082 9.1995 9.47076Z"
              fill="black"
              class="group-hover:tw-fill-an-flat-blue-btn tw-fill-an-flat-grey-3"
            />
          </svg>
        </button>
      </div>
      <PersonLeadDealInfo
        v-show="form.personId"
        ref="isNeedViewPersonLeadAndDeals"
        :person-id="form.personId"
        :errors="viewedLeadAndDealsErrors"
        :required-view="isNeedViewPersonLeadAndDeals"
        @need-view="leadDealNeedViewChange"
      />
    </div>
    <div class="tw-mt-[12px]">
      <TwSelectRelations
        ref="responsibleUserId"
        v-model="form.responsibleUserId"
        :multi="false"
        :dense="true"
        label="Ответственный"
        list-label="Найти сотрудника"
        relation-name="user"
        :persistent-label="true"
        placeholder="Не выбран"
        :disabled="formProcessing"
        :error-text="$v.form.responsibleUserId.$invalid ? 'Заполните поле':''"
        @input="$v.form.responsibleUserId.$touch()"
      />
    </div>

    <TwTextarea
      v-model="form.comment"
      rows="5"
      maxlength="70"
      class="tw-mt-[12px]"
      resize="y"
      autocomplete="off"
      persistent-label
      placeholder="Комментарий"
      :disabled="formProcessing"
      :show-label="true"
    />

    <div
      v-if="isNeedViewPersonLeadAndDeals"
      class="tw-mt-[15px] tw-text-center tw-text-main-xs tw-text-an-flat-red-main"
    >
      Ознакомьтесь с существующими Лидами и Заявками по клиенту!
    </div>

    <TwCardSimpleFormSaveBlock
      class="tw-mt-[15px]"
      :loading="formProcessing"
      :cancel-confirm="isEdited"
      :is-sticky="false"
      :disable-success="formProcessing || !form.personId || isNeedViewPersonLeadAndDeals"
      :disable-cancel="formProcessing || (formType === 'create' && !isEdited)"
      :visible="true"
      @success="handleSubmit"
      @cancel="handleCancel"
    />
  </div>
  <div
    v-else-if="formProcessing"
    class="tw-py-3 tw-flex tw-justify-center"
  >
    <TwCircleLoader />
  </div>
  <div v-else>
    <PermissionDenied text="Недостаточно прав для данного действия!" />
  </div>
</template>

<script>
import API from '@/Api/Crm/Lead'
import { required } from 'vuelidate/lib/validators'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import { RouteKeys } from '@/router/types'
import MainFormLesson from '@/views/Lead/lessons/MainForm'
import PermissionDenied from '@/views/PermissionDenied'
import PersonLeadDealInfo from '@/views/Person/Other/PersonLeadDealInfo'
import TwCardSimpleFormSaveBlock from '@/components/tw-ui/forms/TwCardSimpleFormSaveBlock.vue'
import TwTextarea from '@/components/tw-ui/ui-kit/input/TwTextarea.vue'
import TwSelectRelations from '@/components/tw-ui/ui-kit/input/select-relations/TwSelectRelations.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'

export default {
  name: 'LeadMainForm',
  components: {
    TwCircleLoader,
    TwSelectRelations,
    TwTextarea,
    TwCardSimpleFormSaveBlock,
    PersonLeadDealInfo,
    PermissionDenied
  },
  mixins: [FormCreateEditMixin],
  props: {
    disableRedirect: {
      type: Boolean,
      default: false
    },
    defaultFormFields: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      isNeedViewPersonLeadAndDeals: false,
      API: API,
      form: {
        personId: null,
        comment: '',
        responsibleUserId: null
      }
    }
  },
  // Модель для валидации, которую Vuelidate превратит в computed-поле $v
  validations: {
    isNeedViewPersonLeadAndDeals: {
      isViewed: value => value === false
    },
    form: {
      // Название поля должно совпадать с полем в form
      personId: {
        required: (v) => !!(v && !isNaN(parseInt(v)) && parseInt(v) && parseInt(v) > 0)
      },
      responsibleUserId: {
        required
      }
    }
  },
  computed: {
    personEditable () {
      // Клиента нельзя менять после создания и прохождения некоего времени(26000с=433м=7,22ч)
      return this.preset.createdAt ? ((Date.now() - new Date(this.preset.createdAt).getTime()) / 1000) < 26000 : true
    },
    viewedLeadAndDealsErrors () {
      const errors = []
      if (!this.$v.isNeedViewPersonLeadAndDeals.$dirty) return errors
      if (!this.$v.isNeedViewPersonLeadAndDeals.isViewed) {
        errors.push('Обязательное поле')
        this.$Global.scrollToElem(this.$refs?.isNeedViewPersonLeadAndDeals?.$el)
      }
      return errors
    }
  },
  watch: {
    'form.personId' (newValue, oldValue) {
      console.error(newValue, oldValue)
      this.isNeedViewPersonLeadAndDeals = true
    }
  },
  methods: {
    leadDealNeedViewChange (event) {
      this.isNeedViewPersonLeadAndDeals = event
    },
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          const adaperMap = {
            // from(show): to(send)
            person: 'personId'
          }
          for (const key in this.preset) {
            if (adaperMap[key] !== undefined) {
              if (adaperMap[key].indexOf('Id') !== -1) {
                console.warn('presetData ', this.preset[key] && this.preset[key].id, this.defaultFormFields[adaperMap[key]])
                this.$set(this.form, adaperMap[key], (this.preset[key] && this.preset[key].id) || this.defaultFormFields[adaperMap[key]])
              }
            } else {
              this.$set(this.form, key, this.preset[key])
            }
          }
        }
        resolve()
      })
    },
    handleSubmit () {
      this.$v.$reset()
      this.$v.$touch()
      // console.log('handleSubmit ', this.$v)
      // console.log('$invalid ', this.$v.form.$invalid)
      if (!this.$v.$invalid) {
        if (this.formProcessing === true) {
          return
        }

        this.formProcessing = true
        const request = this.formType === 'create'
          ? API.store(this.form)
          : API.update(this.targetId, this.form)

        request.then(({ data }) => {
          if (this.formType === 'create') {
            this.$emit('create', data.data)
            // если текущий путь равен пути создания то редирект в просмотр
            if (this.$route.name === `${RouteKeys.lead}-create`) {
              this.$router.replace({
                name: `${RouteKeys.lead}-show`,
                params: { id: data.data.id }
              })
            }
          } else {
            this.$emit('update', data.data)
          }
        }).finally(() => {
          this.formProcessing = false
        })
      }
    },
    personSelectIntro () {
      this.$store.dispatch('introTeach/showLesson', {
        options: MainFormLesson,
        steps: [
          {
            element: this.$refs.personField
          }
        ]
      })
    }
  }
}
</script>
