<template>
  <TwPanelContent>
    <SurveyInterviewForm
      v-if="isEdit"
      :create-params="{ surveyId }"
      @changeEdit="isEdit = false"
      @updated="$emit('refresh')"
    />
    <SurveyInterviewShow
      v-else
      :survey-id="surveyId"
      :is-finished="info.isFinishedInterview"
      @changeEdit="isEdit = true"
    />
  </TwPanelContent>
</template>

<script>
import TwPanelContent from '@/components/tw-ui/panels/TwPanelContent.vue'
import SurveyInterviewShow from '@/views/Company/UserSurveys/Interview/SurveyInterviewShow.vue'
import SurveyInterviewForm from '@/views/Company/UserSurveys/Interview/SurveyInterviewForm.vue'

export default {
  name: 'SurveyInterviewBlock',
  components: {
    SurveyInterviewForm,
    SurveyInterviewShow,
    TwPanelContent
  },
  props: {
    info: {
      required: true
    }
  },
  data () {
    return {
      isEdit: false,
      formProcessing: false,
      loading: false,
      error: null
    }
  },
  computed: {
    surveyId () {
      return this.info?.id || null
    }
  }
}
</script>

<style scoped>

</style>
