<template>
  <div class="tw-font-manrope tw-text-main-sm">
    <div
      v-if="currentFileInfo"
      class=" tw-p-2"
    >
      <div class="tw-flex tw-justify-between tw-items-center tw-h-full">
        <TwCircleLoader v-if="currentFileInfo.loading || !currentFileInfo.fileServer" />
        <span
          v-else-if="currentFileInfo.error"
          class="tw-text-an-flat-red-main"
        >Error</span>
        <template v-else-if="currentFileInfo.fileServer.mime && currentFileInfo.fileServer.mime.indexOf('image') !== -1">
          <img
            class="tw-cursor-zoom-in tw-rounded-sm tw-border tw-border-solid"
            :src="getFileResizeByKey(currentFileInfo.fileServer, 'default')"
            alt="img"
            @click="viewImages(files, 0)"
            @error="imageLoadError(files, 0)"
          >
        </template>
        <svg
          v-else
          width="15"
          height="20"
          viewBox="0 0 15 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.94727 5.19922C8.94727 5.91541 9.52992 6.49805 10.2461 6.49805H14.7363V17.8906C14.7363 19.0161 13.8207 19.9316 12.6953 19.9316H2.30469C1.17928 19.9316 0.263672 19.0161 0.263672 17.8906V2.97266C0.263672 1.84723 1.17928 0.931641 2.30469 0.931641H8.94727V5.19922ZM6.00523 14.291H3.30664C2.99922 14.291 2.75 14.5403 2.75 14.8477C2.75 15.1551 2.99922 15.4043 3.30664 15.4043H6.00523C6.31265 15.4043 6.56187 15.1551 6.56187 14.8477C6.56187 14.5403 6.31265 14.291 6.00523 14.291ZM3.30664 11.3223C2.99922 11.3223 2.75 11.5715 2.75 11.8789C2.75 12.1863 2.99922 12.4355 3.30664 12.4355H11.4707C11.7781 12.4355 12.0273 12.1863 12.0273 11.8789C12.0273 11.5715 11.7781 11.3223 11.4707 11.3223H3.30664ZM12.0273 8.91016C12.0273 8.60275 11.7781 8.35352 11.4707 8.35352H3.30664C2.99922 8.35352 2.75 8.60275 2.75 8.91016C2.75 9.21756 2.99922 9.4668 3.30664 9.4668H11.4707C11.7781 9.4668 12.0273 9.21756 12.0273 8.91016ZM10.2461 5.38437C10.1438 5.38437 10.0605 5.30115 10.0605 5.19882L10.0606 1.16211C10.2263 1.24866 10.381 1.35825 10.5198 1.48959L14.098 4.87479C14.2554 5.02371 14.3864 5.19638 14.4882 5.38437H10.2461Z"
            fill="#C4C7CF"
          />
        </svg>
      </div>
      <div
        v-if="currentFileInfo.error"
        class="tw-text-an-flat-red-main"
      >
        Ошибка получения файла!
      </div>
    </div>
    <div
      v-else
      class="tw-text-an-flat-disabled-text tw-text-center tw-py-[15px]"
    >
      Файл планировки не загружен!
    </div>
  </div>
</template>

<script>
import { getFileResizeByKey } from '@/plugins/helpers/fileHelpers'
import API from '@/Api/Crm/File'
import FileUploadMixin from '@/mixins/FileUploadMixin'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'

export default {
  name: 'TwSimpleImageViewById',
  components: { TwCircleLoader },
  mixins: [FileUploadMixin],
  props: {
    imageMaxHeight: {
      type: String,
      default: '400px'
    }
  },
  data () {
    return {
      API: this.api || API
    }
  },
  computed: {
    currentFileInfo () {
      if (Array.isArray(this.files) && this.files.length) {
        // console.warn('this.files', this.files)
        return this.files[0]
      }
      return null
    }
  },
  methods: {
    getFileResizeByKey: getFileResizeByKey
  }
}
</script>
