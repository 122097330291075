export const mortgageTermList = [
  {
    label: 'Не выбрано',
    value: null
  },
  {
    label: '6 месяцев',
    value: 6
  },
  {
    label: '12 месяцев',
    value: 12
  },
  {
    label: '24 месяца',
    value: 24
  },
  {
    label: '36 месяцев',
    value: 36
  },
  {
    label: '60 месяцев',
    value: 60
  },
  {
    label: '84 месяца',
    value: 84
  },
  {
    label: '120 месяцев',
    value: 120
  },
  {
    label: '240 месяцев',
    value: 240
  },
  {
    label: '360 месяцев',
    value: 360
  }
]
