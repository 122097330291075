<template>
  <div>
    <div class="tw-font-normal tw-text-sm tw-text-[#545961] tw-mb-[6px]">
      {{ field.fieldNum }}. {{ field.label }}
    </div>
    <div v-if="field.type === 'input'">
      <div v-if="field.inputType === 'radiobutton'">
        <TwSelectButton
          :multi="false"
          color="blue"
          :value="value"
          :options="field.options"
          dense
          :disabled="disabled"
          @input="onInput"
        />
      </div>
      <div v-else-if="field.inputType === 'number'">
        <TwInputText
          type="number"
          :value="value"
          dense
          :disabled="disabled"
          @input="onInput"
        />
      </div>
      <div v-else-if="field.inputType === 'text'">
        <TwTextarea
          v-if="field.maxLen && field.maxLen > 150"
          rows="3"
          :maxlength="field.maxLen"
          :value="value"
          dense
          :disabled="disabled"
          @input="onInput"
        />
        <TwInputText
          v-else
          type="text"
          :max-length="field.maxLen"
          :value="value"
          dense
          :disabled="disabled"
          @input="onInput"
        />
      </div>
      <div v-else>
        {{ field }}
      </div>
    </div>
    <div v-else-if="field.type === 'select'">
      <TwSelect
        placeholder="Выбрать"
        :dense="true"
        :options="field.options"
        :value="value"
        :disabled="disabled"
        @input="onInput"
      />
    </div>
    <div v-else>
      {{ field }}
    </div>
  </div>
</template>

<script>
import TwSelectButton from '@/components/tw-ui/ui-kit/button/TwSelectButton.vue'
import TwInputText from '@/components/tw-ui/ui-kit/input/TwInputText.vue'
import TwSelect from '@/components/tw-ui/ui-kit/input/TwSelect.vue'
import TwTextarea from '@/components/tw-ui/ui-kit/input/TwTextarea.vue'

export default {
  name: 'TwFormGeneratorField',
  components: { TwTextarea, TwSelect, TwInputText, TwSelectButton },
  props: {
    value: {
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    field: {
      type: Object,
      required: true
    }
  },
  mounted () {
    if (this.value === null && this.value !== this.field.defaultValue) {
      this.onInput(this.field.defaultValue)
    }
  },
  methods: {
    onInput (eventData) {
      this.$emit('input', { field: this.field.key, value: eventData })
    }
  }
}
</script>
