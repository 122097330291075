<template>
  <v-autocomplete
    v-bind="$props"
    ref="select"
    v-model="select"
    :hide-details="!(rules.length)"
    persistent-placeholder
    outlined
    dense
    attach
    :multiple="multiple"
    type="text"
    :items="items"
    no-filter
    :class="classesComponent"
    :clearable="clearable"
    :chips="chips"
    :loading="loading"
    :search-input.sync="searchStr"
    :hide-no-data="noDataHide"
    no-data-text="Не найдено"
    item-value="id"
    item-text="name"
    :placeholder="placeholder"
    :label="label"
    return-object
    flat
    :rules="rules"
    @input="input"
    @click:clear="clearSelected"
  />
</template>

<script>
import API from '@/Api/Mortgage/Search'
import AsyncSelectMixin from '@/components/select/AsyncSelectMixin'

export default {
  name: 'VariantComplexSelect',
  mixins: [AsyncSelectMixin],
  props: {
    value: {},
    commissionPayType: {
      type: Array,
      default: () => {
        return []
      }
    },
    required: {
      type: Boolean,
      default: false
    },
    classesComponent: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      API,
      noDataHide: false,
      searchStr: ''
    }
  },
  watch: {
    commissionPayType: {
      handler () {
        this.clearSelected()
        this.searchFromServer()
      },
      deep: true
    }
  },
  mounted () {
    this.searchFromServer('')
  },
  methods: {
    searchFromServer (searchString) {
      console.warn(`${this.$options.name} - searchFromServer: `, searchString)
      if (this.loading) return

      this.loading = true
      this.API.commissionOptimizeComplexes({
        commissionPayType: this.commissionPayType,
        name: searchString?.trim() || undefined
      }).then(({ data }) => {
        console.warn('commissionOptimizeComplexes', data)
        this.items = data?.data || []
        this.noDataHide = false
      })
        .catch((error) => {
          console.error({ error })
        })
        .finally(() => {
          this.loading = false
          if (this.$refs.select) {
            this.$refs.select.updateMenuDimensions()
          }
        })
    },
    fetchValueInfo (id) {
      this.$store.dispatch('tmpCache/realty/fetchComplexById', id).then((data) => {
        // console.warn('=====~~~~~~~~', data)
        this.select = data ? {
          id: data.id,
          name: data.name
        } : null
      })
    },
    clearSelected () {
      this.select = this.multiple ? [] : null
      this.input()
    }
  }
}
</script>
