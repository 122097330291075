<template>
  <div>
    <div class="tw-font-manrope tw-text-main-sm2 tw-max-w-[100%] tw-px-[26px] tw-py-[18px] tw-bg-white tw-min-h-screen">
      <template v-if="isCardContentVisible">
        <!--   ЗАГОЛОВОК   -->
        <TwPanelTitle>
          <template #left>
            <div class="tw-text-h4 tw-font-bold">
              <div
                v-if="isNew"
                class="title text-muted"
              >
                Новый документ
              </div>
              <div v-else-if="data">
                <span>{{ data.name }}</span>
                <span class="tw-text-an-flat-disabled-text tw-font-medium"> # {{ data.id }}</span>
              </div>
              <div v-else>
                ~
              </div>
            </div>
          </template>
          <template #right>
            <MenuEntityActions
              v-if="permissions"
              :permissions="permissions"
              @delete="deleteCardItem"
            />
          </template>
        </TwPanelTitle>

        <!--   ВКЛАДКИ   -->
        <TwPanelTabsFull
          v-model="currentTab"
          :items="tabList"
        >
          <template #content.main>
            <TwPanelContent>
              <div class="tw-flex-1 tw-min-w-[400px]">
                <TwPanelContentBlock class="tw-mb-[6px]">
                  <template #title>
                    <div>
                      О документе
                    </div>
                  </template>
                  <template #action>
                    <TwPanelContentBlockActionButton
                      :disabled="isNew || loading"
                      type="edit"
                      @click="editMain = !editMain"
                    >
                      {{ editMain ? 'отменить':'изменить' }}
                    </TwPanelContentBlockActionButton>
                  </template>
                  <DocumentMainForm
                    v-if="editMain"
                    :id="data ? data.id : 0"
                    ref="EditMain"
                    @create="onCreated"
                    @update="onUpdated"
                    @cancel="editMain = false"
                  />
                  <DocumentMainShow
                    v-else
                    :dictionary="dictionary"
                    :info="data"
                    @refresh="refresh"
                  />
                </TwPanelContentBlock>
              </div>
              <div class="tw-flex-1" />
            </TwPanelContent>
          </template>
        </TwPanelTabsFull>
      </template>
      <!--
      Блок заполняющий пространство при отсутствуии контента
      для прижатия блока сохранения к нижней части страницы
    -->
      <div class="tw-flex tw-justify-center tw-items-center">
        <TwCircleLoader
          v-if="loading && !isCardContentVisible"
          width="50px"
          height="50px"
        />
        <div v-else-if="error">
          <PermissionDenied :text="error === 403 ? 'Недостаточно прав для просмотра!' : 'Ошибка соединения - Обратитесь к администратору!'" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/Api/Crm/Dictionary/General/Document/index'
import CardMixin from '@/mixins/CardMixin'
import MenuEntityActions from '@/components/core/ViewPanel/Card/entity-actions/menuEntityActions.vue'
import TwPanelContent from '@/components/tw-ui/panels/TwPanelContent.vue'
import TwPanelContentBlockActionButton from '@/components/tw-ui/panels/TwPanelContentBlockActionButton.vue'
import TwPanelTabsFull from '@/components/tw-ui/panels/TwPanelTabsFull.vue'
import TwPanelTitle from '@/components/tw-ui/panels/TwPanelTitle.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import DocumentMainForm from '@/views/Dictionary/General/Document/main/DocumentMainForm.vue'
import DocumentMainShow from '@/views/Dictionary/General/Document/main/DocumentMainShow.vue'
import PermissionDenied from '@/views/PermissionDenied.vue'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'

export default {
  name: 'DocumentCard',
  components: {
    TwCircleLoader,
    PermissionDenied,
    TwPanelContentBlock,
    TwPanelTitle,
    DocumentMainShow,
    DocumentMainForm,
    TwPanelTabsFull,
    TwPanelContentBlockActionButton,
    MenuEntityActions,
    TwPanelContent
  },
  mixins: [CardMixin],
  data () {
    return {
      API: API,
      currentTab: 'main'
    }
  },
  computed: {
    tabList () {
      return [
        {
          label: 'Общая информация',
          key: 'main'
        }
      ]
    }
  }
}
</script>
