import { GetterTree } from 'vuex'
import {
  RootState,
  PrevPage
} from '@/store/types'
import { get } from 'local-storage'

export const getters: GetterTree<RootState, RootState> = {
  getPageTitle (state: RootState): string {
    const title = state.pageTitle || ''
    return `${title}`
  },
  getPrevPage (state: RootState): PrevPage {
    return state.prevPage || get<PrevPage>('prevPage') || null
  },
  getBreadcrumbs (state: RootState): any {
    return state.breadcrumbs
  },
  getPageLoadingStatus (state: RootState): boolean {
    return state.loadingPage
  },
  getPageErrorStatus (state: RootState): boolean {
    return state.errorPage
  },
  getDeploymentType (state: RootState): string {
    return state.deploymentType
  },
  getApiUrl (state: RootState): string {
    return state.apiUrl
  },
  statusMiniLeftPanel (state: RootState): boolean {
    return state.isMiniLeftPanel
  }
}

export default null
