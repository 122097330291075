import { AxiosResponse } from 'axios'
import HTTP from '@/Api/http'
import { AuthAxiosDefaultConfig } from '@/Api/Review'

const path = '/realtor-interview'

export default {
  create (surveyId: number): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.get(`${path}/form-init/${surveyId}`, $config)
  },
  show (surveyId: number): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.get(`${path}/${surveyId}`, $config)
  },
  update (surveyId: number, data: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.put(`${path}/${surveyId}`, data, $config)
  }
}
