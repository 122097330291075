<template>
  <span
    v-if="disabled || isCloneTarget"
    :class="[styleClass]"
  >
    <slot>
      {{ id }}
    </slot>
  </span>
  <a
    v-else
    :href="hashLink"
    :class="styleClass"
    @click.stop="showPanel(config)"
  >
    <slot>
      {{ id }}
    </slot>
  </a>
</template>

<script>
import qs from 'qs'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ViewPanelLink',
  props: {
    id: {
      type: [Number, String],
      required: true
    },
    component: {
      type: String,
      required: true
    },
    viewType: {
      type: String,
      default: 'panel'
    },
    label: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    noLinkStyle: {
      type: Boolean,
      default: false
    },
    isCloseConfirm: {
      type: Boolean,
      default: false
    },
    componentProps: {
      type: Object,
      default: () => {
        return {}
      }
    },
    callback: {
      type: Function,
      default: () => {
        //
      }
    }
  },
  methods: {
    ...mapActions({
      showPanel: 'viewPanels/showPanel'
    })
  },
  computed: {
    ...mapGetters({
      targetPanel: 'viewPanels/targetPanel'
    }),
    config () {
      return {
        label: this.label,
        isCloseConfirm: this.isCloseConfirm,
        component: this.component,
        viewType: this.viewType,
        props: {
          id: this.id,
          ...this.componentProps
        },
        callback: this.callback
      }
    },
    hashLink () {
      const strHashParams = qs.stringify(this.config, {
        arrayFormat: 'brackets'
      })
      return `#${strHashParams}`
    },
    styleClass () {
      return this.noLinkStyle || this.disabled || this.isCloneTarget ? 'panel-view-link-no-style' : 'panel-view-link'
    },
    isCloneTarget () {
      return !!(this.targetPanel && this.targetPanel?.component === this.component && this.targetPanel?.props?.id === this.id) || false
    }
  }
}
</script>

<style scoped>
</style>
