import { AxiosResponse } from 'axios'
import { AuthAxiosDefaultConfig } from '@/Api/Auth'
import HTTP from '@/Api/http'

const path = '/twofactor'

export default {
  change (userId: number, data: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.post(`${path}/id/${userId}`, data, $config)
  },
  generate (): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.post(`${path}/generate`, {}, $config)
  },
  verify (data: object, token: string): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig,
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
    return HTTP.post(`${path}/verify`, data, $config)
  },
  status (userId: number): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.get(`${path}/status/${userId}`, $config)
  }
}
