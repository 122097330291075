<template>
  <div>
    <TwPanelContentBlock
      v-if="dealReviewId"
      no-data-text="Отсутствует"
      class="tw-mb-[6px] tw-min-w-[400px]"
    >
      <template #title>
        Комментарии
      </template>
      <template #action>
        <button
          :disabled="disabled"
          class="tw-text-main-xs enabled:tw-text-an-flat-gray-4 enabled:hover:tw-text-an-flat-dark-blue-link-active disabled:tw-text-an-flat-disabled-text"
          @click="created = true"
        >
          Добавить
        </button>
      </template>
      <template #default>
        <div
          v-if="!created"
          class="tw-flex tw-gap-[12px] tw-flex-col"
        >
          <ul v-if="Array.isArray(commentsReview) && commentsReview.length">
            <li
              v-for="(item, index) in commentsReview"
              :key="index"
              class="tw-flex tw-justify-between tw-p-[13px] tw-border-b tw-border-solid tw-border-an-flat-back-main last:tw-border-b-0"
            >
              <div class="tw-flex tw-flex-row tw-gap-[10px] tw-items-start">
                <div>
                  <svg
                    width="35"
                    height="35"
                    viewBox="0 0 35 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_406_2238)">
                      <path
                        d="M17.5 35.0001C27.165 35.0001 35 27.165 35 17.5001C35 7.83508 27.165 6.10352e-05 17.5 6.10352e-05C7.83502 6.10352e-05 0 7.83508 0 17.5001C0 27.165 7.83502 35.0001 17.5 35.0001Z"
                        fill="#F4F6F8"
                      />
                      <path
                        opacity="0.4"
                        d="M15 18.7501L12.0796 19.7931C11.5821 19.9707 11.25 20.442 11.25 20.9702V22.8902C11.25 23.2048 11.2718 23.5381 11.4981 23.7567C12.0741 24.3131 13.7313 25.0001 17.5 25.0001C21.2687 25.0001 22.9259 24.3131 23.5019 23.7567C23.7282 23.5381 23.75 23.2048 23.75 22.8902V20.9702C23.75 20.442 23.4179 19.9707 22.9204 19.7931L20 18.7501M17.5 17.5001C16.0574 17.5001 14.8262 16.4571 14.589 15.0341L14.3665 13.6991C14.0438 11.7627 15.537 10.0001 17.5 10.0001C19.463 10.0001 20.9562 11.7627 20.6335 13.6991L20.411 15.0341C20.1738 16.4571 18.9426 17.5001 17.5 17.5001Z"
                        stroke="black"
                        stroke-linecap="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_406_2238">
                        <rect
                          width="35"
                          height="35"
                          fill="white"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div class="tw-flex tw-flex-col tw-gap-[3px]">
                  <ViewPanelLink
                    :id="item.creatorId"
                    label="Сотрудник"
                    component="employee-show"
                  >
                    <EmployeeName
                      :id="item.creatorId"
                      class="tw-text-an-flat-black-main tw-font-semibold"
                    />
                  </ViewPanelLink>
                  <span class="tw-block tw-text-[12px] tw-text-an-flat-not-active-input tw-max-w-[450px]">{{ item.text }}</span>
                </div>
              </div>
              <span class="tw-block tw-text-an-flat-not-active-input tw-text-[12px]">{{ item.createdAt }}</span>
            </li>
          </ul>
          <div
            v-else
            class="tw-flex tw-w-full tw-justify-center"
          >
            <b class="tw-block tw-text-[14px] tw-text-an-flat-not-active-input">Комментариев к этой заявке нет</b>
          </div>
        </div>
        <div
          v-else
          class="tw-flex tw-flex-col tw-gap-[14px]"
        >
          <span class="tw-block tw-text-[16px] tw-text-an-flat-black-main">Добавить комментарий</span>
          <TwTextarea
            v-model="comment.text"
            rows="5"
            class="tw-mt-[15px]"
            :disabled="disabled"
            placeholder="Комментарий"
            :show-label="true"
          />
          <div class="tw-flex tw-flex-row tw-gap-[14px]">
            <button
              class="tw-bg-an-flat-blue-btn2 tw-text-white tw-w-fit tw-px-2 tw-py-[7px] tw-rounded-sm tw-border tw-border-solid enabled:hover:tw-text-an-flat-black-main enabled:hover:tw-bg-an-flat-bg-main disabled:tw-opacity-50"
              :disabled="disabled"
              @click="createComments"
            >
              Отправить
            </button>
            <button
              class="tw-bg-an-flat-red-main tw-text-white tw-w-fit tw-px-2 tw-py-[7px] tw-rounded-sm tw-border tw-border-solid enabled:hover:tw-text-an-flat-black-main enabled:hover:tw-bg-an-flat-bg-main disabled:tw-opacity-50"
              :disabled="disabled"
              @click="created = false"
            >
              Отмена
            </button>
          </div>
        </div>
      </template>
    </TwPanelContentBlock>
  </div>
</template>

<script>
import EmployeeName from '@/components/other/employee/EmployeeName.vue'
import { mapGetters } from 'vuex'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink.vue'
import { DealReview } from '@/Api/Review'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwTextarea from '@/components/tw-ui/ui-kit/input/TwTextarea.vue'

export default {
  name: 'DealReviewCommentsDialog',
  components: {
    TwPanelContentBlock,
    EmployeeName,
    ViewPanelLink,
    TwTextarea
  },
  props: {
    dealReviewId: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      disabled: false,
      created: false,
      commentsReview: {},
      comment: {
        text: '',
        creatorId: 1,
        createdAt: ''
      }
    }
  },
  methods: {
    createComments () {
      this.comment.creatorId = this.user.id
      this.disabled = true
      DealReview.createComment(this.dealReviewId, this.comment)
        .catch(error => console.error('Error DealReview.createComment', error))
        .finally(() => {
          this.comment.text = ''
          this.created = false
          this.listComments()
        })
    },
    listComments () {
      DealReview.listComments(this.dealReviewId).then(({ data: result }) => {
        this.commentsReview = result?.data || []
      }).catch((error) => {
        console.error('ERROR DealReview.listComments', error)
      }).finally(this.disabled = false)
    }
  },
  computed: {
    ...mapGetters({
      user: 'profile/getUser'
    })
  },
  mounted () {
    this.listComments()
  }
}
</script>
