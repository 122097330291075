import { RouteKeys } from '@/router/types'

export default {
  path: `${RouteKeys['lead-status']}`,
  name: RouteKeys['lead-status'],
  redirect: { name: `${RouteKeys['lead-status']}-list` },
  meta: { name: 'Лид - Статусы', icon: 'application-statuses' },
  component: () => import('@/views/Dictionary/Lead/Status/Index.vue'),
  children: [
    {
      path: 'list',
      name: `${RouteKeys['lead-status']}-list`,
      meta: { name: 'Список статусов для Лидов', icon: 'application-statuses', hideInMenu: true },
      component: () => import('@/views/Dictionary/Lead/Status/List.vue')
    },
    {
      path: ':id/edit',
      name: `${RouteKeys['lead-status']}-edit`,
      meta: { name: 'Редактирование статус Лид', icon: 'application-statuses', hideInMenu: true },
      component: () => import('@/views/Dictionary/Lead/Status/Edit.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    },
    {
      path: 'create',
      name: `${RouteKeys['lead-status']}-create`,
      meta: { name: 'Добавление статус Лид', icon: 'application-statuses', hideInMenu: true },
      component: () => import('@/views/Dictionary/Lead/Status/Edit.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    },
    {
      path: ':id',
      name: `${RouteKeys['lead-status']}-show`,
      meta: { name: 'Просмотр статуса Лида', icon: 'application-statuses', hideInMenu: true },
      component: () => import('@/views/Dictionary/Lead/Status/Edit.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    }
  ]
}
