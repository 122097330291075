import { RouteKeys } from '@/router/types'

export default {
  path: RouteKeys.dev,
  name: RouteKeys.dev,
  meta: { name: 'Для разработчиков', icon: 'for-developers', isAdmin: true },
  // redirect: { name: `${RouteKeys.dev}-realty-miner` },
  component: () => import('@/views/Dev/Index.vue'),
  children: [
    {
      path: 'ilia',
      name: `${RouteKeys.dev}-ilia`,
      meta: {
        name: 'Разработка Илья',
        icon: 'frontend-developers'
      },
      component: () => import('@/views/Dev/Ilia/Index.vue')
    },
    {
      path: 'vlad',
      name: `${RouteKeys.dev}-vlad`,
      meta: {
        name: 'Разработка Влад',
        icon: 'frontend-developers'
      },
      component: () => import('@/views/Dev/Vlad/Index.vue')
    },
    {
      path: 'ivan',
      name: `${RouteKeys.dev}-ivan`,
      meta: {
        name: 'Разработка Иван',
        icon: 'frontend-developers'
      },
      component: () => import('@/views/Dev/Ivan/Index.vue')
    },
    {
      path: 'nasya',
      name: `${RouteKeys.dev}-nasya`,
      meta: {
        name: 'Разработка Настя',
        icon: 'frontend-developers'
      },
      component: () => import('@/views/Dev/Nasya/Index.vue')
    },
    {
      path: 'ui-kit',
      name: `${RouteKeys.dev}-ui-kit`,
      meta: {
        name: 'UI KIT',
        icon: 'ui-kit'
      },
      component: () => import('@/views/Dev/UiKit/Index.vue')
    },
    {
      path: 'sample-list-card',
      name: `${RouteKeys.dev}-sample-list-card`,
      meta: {
        name: 'sample-list-card',
        icon: 'sample-list-card'
      },
      component: () => import('@/views/Dev/SampleListCard/List.vue')
    }
  ]
}
