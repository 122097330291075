<template>
  <div class="tw-h-full tw-flex tw-items-center tw-justify-center">
    <div class="tw-bg-white tw-text-an-flat-red-main tw-px-[15px] tw-py-[10px] tw-mx-[5px] tw-my-[10px] tw-rounded-lg">
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'PermissionDenied',
  props: {
    text: {
      type: String,
      default: 'Недостаточно прав для просмотра раздела!'
    }
  }
}
</script>
