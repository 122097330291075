<template>
  <TwPanelContentBlock>
    <template #title>
      <div>
        Сведения об активах
      </div>
    </template>
    <div>
      <PersonRealty
        :person-id="personId"
        :list="realty"
        @refresh="$emit('refresh')"
      />
      <PersonCars
        :person-id="personId"
        :list="cars"
        @refresh="$emit('refresh')"
      />
    </div>
  </TwPanelContentBlock>
</template>

<script>
import PersonRealty from '@/views/Person/hipotec/assets/PersonRealty'
import PersonCars from '@/views/Person/hipotec/assets/PersonCars'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'

export default {
  name: 'PersonAssetsBlock',
  components: { TwPanelContentBlock, PersonCars, PersonRealty },
  props: {
    isFormEdit: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    personInfo: {
      type: Object,
      default: () => {
        return {}
      }
    },
    dictionary: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    realty () {
      return (this.personInfo && this.personInfo.realty) || []
    },
    cars () {
      return (this.personInfo && this.personInfo.cars) || []
    },
    personId () {
      return this.personInfo && this.personInfo.id
    }
  }
}
</script>
