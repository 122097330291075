import Vue from 'vue'
import { Module } from 'vuex'
import { RootState } from '@/store/types'
import { UsersState } from './types'
import userAPI from '@/Api/Auth/User/index'
import store from '../../store/index'

const namespaced = true

export const users: Module<UsersState, RootState> = {
  namespaced,
  state: {
    users: {}
  },
  actions: {
    fetchUsersById: function ({ commit }, id: number | string) {
      commit('SET_USER', { id })
      return userAPI.showShort(id)
        .then(({ data }: any) => {
          // console.log('fetchUsersById', { id, ...(data.data || {}) })
          commit('SET_USER', { id, ...(data.data || {}) })
        })
        .catch((error: any) => {
          console.error(error)
          commit('SET_USER', { id, hasError: true })
        })
    },
    fetchUsersByIdFull: function ({ commit }, id: number | string) {
      commit('SET_USER', { id })
      return userAPI.show(id)
        .then(({ data }: any) => {
          // console.log('fetchUsersById', { id, ...(data.data || {}) })
          commit('SET_USER', { id, ...(data.data || {}) })
          return data.data || null
        })
        .catch((error: any) => {
          console.error(error)
          commit('SET_USER', { id, hasError: true })
          return null
        })
    },
    searchUsers: function ({ commit }, search: string) {
      return userAPI.getList({ search })
        .then(({ data }) => {
          // console.log('searchUsers', data.data)
          if (Array.isArray(data?.data) && data.data.length) {
            data.data.forEach((user: any) => {
              commit('SET_USER', {
                id: user.id,
                data: user
              })
            })
          }
          return data?.data || []
        })
        .catch((error) => {
          console.error(error)
        })
    }
  },
  mutations: {
    SET_USER (state, payload) {
      // console.log('SET_USER', payload)
      // параметр времени запроса
      const reqTime = new Date().getTime()
      if (Array.isArray(payload)) {
        payload.forEach((item) => {
          item.rt = reqTime
          Vue.set(state.users, item.id, item)
        })
      } else if (payload.id) {
        payload.rt = reqTime
        Vue.set(state.users, payload.id, payload?.data || payload)
      } else {
        console.error('Необработанное условие при попытке добавить User в tmpCache')
      }
    }
  },
  getters: {
    getUserById: (state) => (id: number | string) => {
      if (state.users[id] === undefined) {
        store.dispatch('users/fetchUsersById', id)
      }
      return state.users[id] || null
    },
    cachedUsers: (state) => {
      return Object.values(state.users) || []
    }
  }
}
