<template>
  <div>
    <!--  <div>-->
    <!--    value: {{value}}<br/>-->
    <!--    notPersonFiles: {{notPersonFiles}}<br/>-->
    <!--    allPersonsFileIds: {{targetPersonsCalcData.allPersonsFileIds}}<br/>-->
    <!--    selectedFileIds: {{selectedFileIds}}-->
    <!--  </div>-->
    <v-btn
      color="primary"
      :text="isBtnText"
      :disabled="disabled"
      small
      @click="isOpen = true"
    >
      <v-icon
        small
        left
      >
        fa-folder-open
      </v-icon>
      {{ btnText }}
    </v-btn>
    <!-- ОКНО с выбором файлов -->
    <v-dialog
      v-model="isOpen"
      persistent
      max-width="600"
    >
      <v-card>
        <v-card-title class="title primary white--text">
          Файлы документов клиента для прикрепления
        </v-card-title>
        <v-divider />
        <v-card-text
          v-if="loading"
          class="text-center pa-3"
        >
          <v-progress-circular
            size="40"
            color="primary"
            indeterminate
          />
        </v-card-text>
        <v-card-text
          v-else
          class="text-left py-0"
        >
          <v-treeview
            v-model="selectedFileIds"
            selectable
            open-on-click
            item-disabled="locked"
            :items="targetPersonsCalcData.tree"
            transition
            @input="onSelect"
          >
            <template #prepend="{ item, open }">
              <v-icon v-if="item.isPerson">
                fa-user
              </v-icon>
              <v-icon v-else-if="!item.isFile">
                {{ open ? 'fa-folder-open' : 'fa-folder' }}
              </v-icon>
              <v-icon v-else>
                fa-book
              </v-icon>
            </template>
            <template #label="{ item }">
              <div
                :title="item.locked ? 'Нет файлов':''"
                :style="{ cursor: item.locked ? 'not-allowed' : 'pointer' }"
                class="cursor-pointer d-flex item-hovered-visible-block"
              >
                <div
                  class="d-flex flex-column"
                  style="line-height: normal"
                >
                  <small class="text-muted">
                    {{ item.type }}
                  </small>
                  <div class="font-weight-bold">
                    {{ item.name }}
                  </div>
                </div>
              </div>
            </template>
          </v-treeview>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            :disabled="loading"
            text
            @click="onApplySelected"
          >
            Применить
          </v-btn>
          <v-btn
            :disabled="loading"
            text
            @click="isOpen = false"
          >
            Отмена
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import personAPI from '@/Api/Crm/Person'
export default {
  name: 'PersonSelectDocumentFiles',
  props: {
    btnText: {
      type: String,
      default: 'Прикрепить файлы клиентов'
    },
    targetPersons: {
      type: Array,
      required: true
    },
    value: {
      type: Array,
      default: () => []
    },
    isBtnText: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    loading: false,
    isOpen: false,
    selectedFileIds: [],
    targetPersonsInfo: []
  }),
  computed: {
    notPersonFiles () {
      return (this.value || [])?.filter(id => {
        return !this.targetPersonsCalcData.allPersonsFileIds.includes(id)
      })
    },
    targetPersonsCalcData () {
      const tree = []
      const allPersonsFileIds = []
      if (this.targetPersonsInfo.length) {
        for (const person of this.targetPersonsInfo) {
          const personItem = {
            id: (person?.id || null) + '_person',
            type: 'Контакт',
            name: this.$Global.peopleName(person, 'fio') || 'Нет имени',
            isPerson: true,
            locked: false,
            children: []
          }
          if (Array.isArray(person?.documents) && person?.documents.length) {
            for (const document of person.documents) {
              const docItem = {
                id: (document?.id || null) + '_document',
                name: document?.type?.label || 'Неизвестный документ',
                type: 'Документ',
                children: []
              }
              if (Array.isArray(document?.files) && document.files.length) {
                for (const file of document.files) {
                  const fileItem = {
                    id: file?.id || null,
                    isFile: true,
                    locked: false,
                    name: file?.type?.label || 'Файл без типа',
                    type: `Файл: ${file?.name || 'Без названия'}`
                  }
                  docItem.children.push(fileItem)
                  allPersonsFileIds.push(file?.id)
                }
              } else {
                docItem.locked = true
              }
              personItem.children.push(docItem)
            }
          } else {
            personItem.locked = true
          }
          // console.warn({ personItem })
          tree.push(personItem)
        }
      }
      return {
        allPersonsFileIds,
        tree
      }
    }
  },
  watch: {
    isOpen (newValue) {
      if (newValue) {
        this.initPersons()
      }
    }
  },
  methods: {
    onApplySelected () {
      const outputArray = [...new Set([...this.selectedFileIds, ...this.notPersonFiles])]
      console.error({ outputArray })
      this.$emit('input', outputArray)
      this.isOpen = false
    },
    onSelect: function (...args) {
      console.warn(args)
    },
    fetchPersonById: function (id) {
      return personAPI.show(id)
        .then(({ data }) => {
          this.targetPersonsInfo.push(data.data)
        })
        .catch((error) => {
          console.error(error)
        })
    },
    async initPersons () {
      this.loading = true
      this.targetPersonsInfo = []
      if (Array.isArray(this.targetPersons) && this.targetPersons.length) {
        for (const id of this.targetPersons) {
          await this.fetchPersonById(id)
        }
      }
      this.$nextTick(() => {
        this.selectedFileIds = this.value || []
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
