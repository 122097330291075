import { Module } from 'vuex'
import { RootState } from '@/store/types'
import { get, set } from 'local-storage'
import { cloneDeep } from 'lodash'
import Vue from 'vue'
import { TwFiltersState } from '@/store/twfilter/types'
import { ServicesSchema, TServicesSchemaKeys } from '@/store/twfilter/servicesSchema'

const namespaced = true

const dealTypeLocalStorageKey = 'crm/Deal/Type/Selected'

export const twFilter: Module<TwFiltersState, RootState> = {
  namespaced,
  state: {
    originalSchema: {}, // схема по умолчанию по типам
    currentFiltersSchema: {}, // схема по текущих используемых фильтров по типам
    savedUserFields: [] // Поля фильтров которые сохранил пользователь // TODO сохран. фильтров отложены
  },
  actions: {
    async fetchFilterService ({ state, commit, dispatch }, serviceKey: TServicesSchemaKeys) {
      return new Promise((resolve) => {
        // console.warn('fetchFilterService', serviceKey, targetService)
        // TODO не загружаем если фильтры получены ранее
        if (state.originalSchema[serviceKey]) {
          console.log('Схема фильтров - присутствует: ', serviceKey)
          return resolve(true)
        }
        // Получаю сервис из справочника
        const targetService: any = ServicesSchema[serviceKey] || null
        // проверяю найден ли сервис в справочнике
        if (targetService && targetService.listInit) {
          commit('SET_ORIGINAL_SCHEMA', {
            [serviceKey]: {
              loading: true
            }
          })
          targetService.listInit().then(({ data: result }: any) => {
            console.warn('Получены фильтры списка - ', serviceKey, result.data)

            // Выбор значения фильтраТИП заявки по умолчанию
            // Приоритет Локаное значение - Серверное значение - null
            if (serviceKey === ServicesSchema.DealList.key) {
              // Локальное-сохраненное значение фильтра у пользователя
              const localDefaultValue = get(dealTypeLocalStorageKey) || null
              // Перебираю поля ищу поле ТИП и провожу назначение типа по умолчанию
              const typeFieldInfo = result.data?.filters?.find((filterField: any) => {
                return filterField.name === 'type'
              })
              if (typeFieldInfo) {
                if (Array.isArray(typeFieldInfo?.options) && typeFieldInfo.options.length) {
                  // Для начала проверю еслть ли локальное значение в доступном списке
                  if (localDefaultValue && typeFieldInfo.options.some((opt: any) => opt?.value === localDefaultValue)) {
                    // значение есть в доступном списке
                    // Изменяю значение по умолчанию
                    typeFieldInfo.defaultValue = localDefaultValue
                  } else {
                    // если значение отсутствет - ничего не делаю, чтобы пременилось серверное значение defaultValue
                    set(dealTypeLocalStorageKey, null)
                  }
                }
              }
            }

            commit('SET_ORIGINAL_SCHEMA', {
              [serviceKey]: {
                ...result.data,
                loading: false
              }
            })
            dispatch('initCurrentFilters', serviceKey)
            resolve(true)
          }).catch((error: any) => {
            console.error('Ошибка получения фильтров для списка - ', serviceKey, error)
            commit('SET_ORIGINAL_SCHEMA', {
              [serviceKey]: {
                error: true,
                msg: error,
                loading: false
              }
            })
            resolve(null)
          })
        } else {
          console.error('Сервис с данным кодом не добавлен в справочник запросов!', serviceKey)
          resolve(null)
        }
      })
    },
    // TODO сохран. фильтров отложены
    saveUserFields ({ commit }, payload) {
      // console.warn('saveUserFields', payload)
      commit('SET_SAVE_FIELDS', payload)
    },
    changeCurrentFields ({ commit }, data) {
      // console.warn('changeCurrentFields', data)
      if (!data.schemaKey || !data.fields) console.error('ERROR: changeCurrentFields [schemaKey] && [fields] - required!')
      commit('SET_CURRENT_FILTER_BY_KEY', data)
    },
    // копирование и выборка из оригинальных фильтров, только тех которые должны быть показаны с списке фильтров
    initCurrentFilters ({ commit, state }, serviceKey) {
      // console.warn('initCurrentFilters [schemaKey]]', schemaKey)
      return new Promise((resolve: any) => {
        // console.warn('initCurrentFilters', schemaKey, state.originalSchema[schemaKey])
        if (!serviceKey) return console.error('ERROR: initCurrentFilters [serviceKey]] - required!')
        if (!Array.isArray(state.originalSchema[serviceKey]?.filters)) return console.error('ERROR: initCurrentFilters невалидная схема фильтров: ', serviceKey)
        // получаю поля фильтра по ключу TODO - тут в будущем встроится выбор схемы сохраненных фильтров
        const targetSchemaFields = cloneDeep(state.originalSchema[serviceKey].filters)
        const currentFilterFields: any = []
        if (targetSchemaFields) {
          // console.warn('СХЕМА initCurrentFilters [schemaKey]]', schemaKey, targetSchemaFields)
          // инициализирую фильтры по умолчанию
          targetSchemaFields.forEach((item: any) => {
            // console.log('ПОЛЕ initCurrentFilters', item.key, item, ' defaultValue: ', item.defaultValue)
            // Только поля помеченные для использования
            if (item?.useDefault) {
              // console.log('TwFilter initCurrentFilters[0]', item.key, item.useDefault, item.defaultValue, item)
              if (item.defaultValue !== null && item.defaultValue !== undefined) {
                // console.log('TwFilter initCurrentFilters[1]', item.key, item.type, item.defaultValue)
                // для разных типов разная инициализация
                if (item.type.indexOf('enum') !== -1) {
                  // console.log('TwFilter initCurrentFilters[2]', item.key, item.multi, item.type, item.defaultValue)
                  // TODO isArray() оставлено для поддержания работы enum в OS & CRM сервисах
                  if (item.multi || Array.isArray(item.defaultValue)) {
                    // console.log('TwFilter initCurrentFilters[3]', item.key, item.type, item.defaultValue)
                    const [firstValue = null] = item.defaultValue // тут беру первый элемент массива
                    if (firstValue) {
                      // console.log('TwFilter initCurrentFilters[4]', item.key, item.type, item.defaultValue)
                      // console.error('firstValue', firstValue)
                      currentFilterFields.push({
                        ...item,
                        inputValue: firstValue,
                        inputText: item.options.find((i: any) => i.value === firstValue)?.label
                      })
                    } else {
                      currentFilterFields.push({
                        ...item
                      })
                    }
                  } else {
                    // console.log('TwFilter initCurrentFilters[2.1]', item.key, item.type, item.defaultValue)
                    // const [firstValue = null] = Object.entries(item.defaultValue) // а тут создаю массив из объекта
                    currentFilterFields.push({
                      ...item,
                      inputValue: item.defaultValue,
                      inputText: item.options.find((i: any) => i.value === item.defaultValue)?.label || '~'
                    })
                  }
                } else {
                  // console.log('TwFilter initCurrentFilters[2.2]', item.key, item.type, item.defaultValue)
                  currentFilterFields.push({
                    ...item,
                    inputValue: item.defaultValue,
                    inputText: item.label
                  })
                }
              } else {
                // console.log('TwFilter initCurrentFilters[END], получен фильтр по умолчанию!', item.type, item.defaultValue)
                // console.warn('TwFilter initCurrentFilters, нет значения по умолчанию!', item)
                currentFilterFields.push({
                  ...item
                })
              }
            }
          })
        }
        // console.warn('RESULT initCurrentFilters [serviceKey]: ', serviceKey, currentFilterFields)
        commit('SET_CURRENT_FILTER_BY_KEY', { serviceKey, fields: currentFilterFields })
        resolve()
      })
    }
  },
  mutations: {
    SET_ORIGINAL_SCHEMA (state, schemaData) {
      // console.warn('SET_ORIGINAL_SCHEMA', schemaData)
      if (!state.originalSchema) {
        Vue.set(state, 'originalSchema', {})
      }
      for (const key in schemaData) {
        Vue.set(state.originalSchema, key, schemaData[key])
      }
    },
    // TODO сохран. фильтров отложены
    SET_SAVE_FIELDS (state, payloadData) {
      state.savedUserFields.push(payloadData)
    },
    SET_CURRENT_FILTER_BY_KEY (state, payloadData) {
      // console.warn('SET_CURRENT_FILTER_BY_KEY', payloadData)
      const cloneFields = cloneDeep(payloadData.fields)
      Vue.set(state.currentFiltersSchema, payloadData.serviceKey, cloneFields)
    },
    SET_CURRENT_FILTER_VALUE_BY_KEY (state, payloadData) {
      // console.warn('SET_CURRENT_FILTER_VALUE_BY_KEY', payloadData)
      const indexField = state.currentFiltersSchema[payloadData.schemaKey].findIndex((field: any) => field.key === payloadData.fieldKey)
      // console.warn('SET_CURRENT_FILTER_VALUE_BY_KEY', indexField)
      if (indexField !== -1) {
        Vue.set(state.currentFiltersSchema[payloadData.schemaKey][indexField], 'inputValue', payloadData.inputValue)
        if (payloadData?.inputText) {
          Vue.set(state.currentFiltersSchema[payloadData.schemaKey][indexField], 'inputText', payloadData.inputText)
        }
      }
      // TODO костыль [0] записать тип сделки по умолчанию
      if (payloadData.schemaKey === ServicesSchema.DealList.key && payloadData.fieldKey === 'type' && payloadData.inputValue) {
        // console.warn('payloadDatapayloadData', payloadData)
        set(dealTypeLocalStorageKey, payloadData.inputValue)
      }
    },
    // TODO UNUSE - очистка состояния текущих фильтров - использую initCurrentFilters
    CLEAR_CURRENT_FILTERS_BY_KEY (state, schemaKey) {
      // console.warn('CLEAR_CURRENT_FILTERS_BY_KEY', schemaKey)
      for (const index in state.currentFiltersSchema[schemaKey]) {
        Vue.set(state.currentFiltersSchema[schemaKey][index], 'inputValue', undefined)
      }
    },
    // очистка состояния конкретного поля текущего фильтра
    CLEAR_CURRENT_FILTER_FIELD_BY_KEY (state, payloadData) {
      // console.warn('CLEAR_CURRENT_FILTER_FIELD_BY_KEY', payloadData.schemaKey, payloadData.fieldKey)
      const indexField = state.currentFiltersSchema[payloadData.schemaKey].findIndex((field: any) => field.key === payloadData.fieldKey)
      Vue.set(state.currentFiltersSchema[payloadData.schemaKey][indexField], 'inputValue', undefined)
    }
  },
  getters: {
    filterLoadingByKey: (state) => (key: string) => {
      return !!state?.originalSchema[key]?.loading
    },
    getFilterSchemaByKey: (state) => (key: string) => {
      return state?.originalSchema[key] || null
    },
    getCurrentFilterSchemaByKey: (state) => (key: string) => {
      return state?.currentFiltersSchema[key] || null
    },
    getUserFieldsByKey: (state) => (key: string) => {
      return (Array.isArray(state?.savedUserFields) && state?.savedUserFields.filter(item => item.schemaKey === key)) || []
    }
  }
}
