<template>
  <div
    class="tw-font-manrope"
  >
    <div style="display: none">
      {{ isEdited }}
    </div>
    <div>
      <TwFlexCol>
        <TwFlexCell>
          <TwSelect
            v-model="form.type"
            label="Тип компании"
            placeholder="Выбрать"
            :multi="false"
            :dense="true"
            :disable-error-border="false"
            :persistent-label="true"
            :clearable="false"
            :disabled="formProcessing || formType !== 'create' || !!presetType"
            :options="dictionary.type"
            :error-text="$v.form.type.$invalid ? 'Заполните поле':''"
          />
          <small
            v-if="formType !== 'create'"
            class="tw-text-an-flat-orange-warning"
          >* Запрещено изменять</small>
        </TwFlexCell>
        <TwFlexCell v-if="subTypeOptions">
          <TwSelect
            v-model="form.subtype"
            :label="form.type === 'developer' ? 'Виды строительства':'Подтипы компании'"
            placeholder="Выбрать"
            :multi="true"
            :dense="true"
            :disable-error-border="false"
            :persistent-label="true"
            :clearable="false"
            :disabled="formProcessing"
            :options="subTypeOptions"
            :error-text="$v.form.subtype.$invalid ? 'Заполните поле':''"
          />
        </TwFlexCell>
        <TwFlexCell>
          <TwInputText
            v-model="form.name"
            type="text"
            label="Название компании"
            placeholder="Введите название"
            :disabled="formProcessing"
            :dense="true"
            :disable-error-border="false"
            :clearable="false"
            :show-label="true"
            :persistent-label="true"
            :error-text="$v.form.name.$invalid ? 'Обязательное поле':''"
          />
        </TwFlexCell>
        <TwFlexCell>
          <div class="tw-flex tw-justify-between tw-text-an-flat-blue-btn-active tw-text-main-sm2 tw-mb-[10px]">
            <div>
              Логотип компании:
            </div>
          </div>
          <TwUploadFilesList
            label="Изображение c логотипом компании"
            :value="form.imageId ? [form.imageId] : []"
            :is-public="true"
            :api="crmFileAPI"
            :has-inline="false"
            :multiple="false"
            @input="form.imageId = $event[0] || null"
          />
        </TwFlexCell>
        <TwFlexCell v-if="form.type === 'bank' || form.type === 'insurance'">
          <TwInputText
            v-model="form.rewardPercent"
            type="text"
            max-length="4"
            sub-type="float"
            label="Процент вознаграждения"
            placeholder="Введите процент вознаграждения"
            :disabled="formProcessing"
            :dense="true"
            :disable-error-border="false"
            :clearable="false"
            :show-label="true"
            :persistent-label="true"
          >
            <template #append>
              %
            </template>
          </TwInputText>
        </TwFlexCell>
        <TwFlexCell>
          <TwSelectRelations
            v-model="form.parentId"
            placeholder="Выбрать"
            label="Основная компания"
            list-label="Найти компанию"
            :multi="false"
            :dense="true"
            :cleanable="true"
            :persistent-label="true"
            relation-name="company"
          />
        </TwFlexCell>
        <TwFlexCell>
          <TwSelect
            v-model="form.cityUuid"
            label="Город"
            placeholder="Выбрать"
            :multi="false"
            :dense="true"
            :disable-error-border="false"
            :persistent-label="true"
            :clearable="false"
            :disabled="formProcessing"
            :options="cityList"
          />
        </TwFlexCell>
        <TwFlexCell>
          <TwCheckBox
            v-model="form.active"
            :disabled="formProcessing"
            background-and-border-color="tw-bg-an-flat-green-btn-bg tw-border-an-flat-green-btn-bg"
            label="Активность"
          />
        </TwFlexCell>
        <TwFlexCell>
          <InputCompanyContacts v-model="form.contacts" />
        </TwFlexCell>
      </TwFlexCol>

      <TwCardSimpleFormSaveBlock
        class="tw-mt-[15px]"
        :loading="formProcessing"
        :cancel-confirm="isEdited"
        :is-sticky="false"
        :disable-success="!isEdited || formProcessing"
        :disable-cancel="formProcessing"
        :visible="true"
        @success="handleSubmit"
        @cancel="handleCancel"
      />
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import citySampleList from './../citySampleList'
import API from '@/Api/Crm/Company'
import crmFileAPI from '@/Api/Crm/File'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import { RouteKeys } from '@/router/types'
import InputCompanyContacts from '@/components/input/InputCompanyContacts.vue'
import TwFlexCol from '@/components/tw-ui/ui-kit/flex-block/TwFlexCol.vue'
import TwFlexCell from '@/components/tw-ui/ui-kit/flex-block/TwFlexCell.vue'
import TwSelect from '@/components/tw-ui/ui-kit/input/TwSelect.vue'
import TwInputText from '@/components/tw-ui/ui-kit/input/TwInputText.vue'
import TwCardSimpleFormSaveBlock from '@/components/tw-ui/forms/TwCardSimpleFormSaveBlock.vue'
import TwSelectRelations from '@/components/tw-ui/ui-kit/input/select-relations/TwSelectRelations.vue'
import TwCheckBox from '@/components/tw-ui/ui-kit/input/TwCheckBox.vue'
import TwUploadFilesList from '@/components/tw-ui/ui-kit/files/TwUploadFilesList.vue'

export default {
  name: 'CompanyMainForm',
  components: {
    TwUploadFilesList,
    TwCardSimpleFormSaveBlock,
    InputCompanyContacts,
    TwFlexCol,
    TwFlexCell,
    TwInputText,
    TwSelectRelations,
    TwCheckBox,
    TwSelect
  },
  mixins: [FormCreateEditMixin],
  props: {
    presetType: {
      type: String,
      default: null
    },
    disableRedirect: {
      type: Boolean,
      default: false
    },
    defaultFormFields: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      cityList: citySampleList,
      crmFileAPI,
      API,
      form: {
        active: false,
        type: null,
        parentId: null,
        imageId: null,
        cityUuid: null,
        rewardPercent: 0,
        name: '',
        contacts: []
      }
    }
  },
  validations () {
    return {
      form: {
        type: {
          required
        },
        subtype: {
          ...(this.subTypeOptions) && {
            required
          }
        },
        name: {
          required
        }
      }
    }
  },
  methods: {
    handleSubmit () {
      this.$v.$reset()
      this.$v.$touch()
      // console.warn('handleSubmit', this.$v.form.$invalid === false, this.formProcessing)
      if (!this.$v.$invalid) {
        if (this.formProcessing === true) {
          return
        }

        this.formProcessing = true
        const request = this.formType === 'create'
          ? API.store(this.form)
          : API.update(this.targetId, this.form)

        request.then(({ data }) => {
          if (this.formType === 'create') {
            this.$emit('create', data.data)
            // если текущий путь равен пути создания то редирект в просмотр
            if (this.$route.name === `${RouteKeys.company}-create`) {
              this.$router.replace({
                name: `${RouteKeys.company}-show`,
                params: { id: data.data.id }
              })
            }
          } else {
            this.$emit('update', data.data)
          }
        }).finally(() => {
          this.formProcessing = false
        })
      }
    },
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          for (const key in this.preset) {
            if (key === 'type' && typeof this.preset[key] === 'object') {
              this.$set(this.form, key, this.preset[key].value)
            } else if (key === 'subtype') {
              this.$set(this.form, 'subtype', this.preset[key]?.map(st => st.value) || [])
            } else if (key === 'parent') {
              this.$set(this.form, 'parentId', this.preset[key]?.id || null)
            } else if (key === 'image') {
              this.$set(this.form, 'imageId', this.preset[key]?.id || null)
            } else if (key === 'reward') {
              this.$set(this.form, 'rewardPercent', this.preset[key] || 0)
            } else if (key === 'contacts') {
              this.$set(this.form, 'contacts', this.preset[key].map(c => {
                c.department = c.department.value
                return c
              }) || [])
            } else {
              this.$set(this.form, key, this.preset[key])
            }
          }
          if (this.presetType && !this.form.type) {
            this.form.type = this.presetType
          }
        }
        resolve()
      })
    }
  },
  computed: {
    companyType () {
      if (this.formType === 'create') {
        return this.form?.type || null
      }
      return this.preset?.type?.value || null
    },
    subTypeDict () {
      return this.dictionary?.subtype || {}
    },
    // если нет опций для типа поле subtype - скрывается и становится необязательным
    subTypeOptions () {
      return Array.isArray(this.subTypeDict?.[this.companyType]) ? this.subTypeDict[this.companyType] : null
    }
  }
}
</script>
