<template>
  <div
    v-if="info"
    class="tw-flex tw-gap-[14px] tw-flex-col"
  >
    <TwPanelContentBlockFieldView v-if="contractInfo">
      <template #title>
        Номер договора
      </template>
      <div>
        <span v-if="noLinkId">
          № {{ contractInfo.contractNum }}
        </span>
        <ViewPanelLink
          v-else
          :id="contractInfo.id"
          label="Договор"
          component="deal-contract-show"
          :callback="() => { $emit('refresh') }"
        >
          № {{ contractInfo.contractNum }}
        </ViewPanelLink>
        <span
          v-if="isArchive"
          class="tw-px-[6px] tw-py-[3px] tw-bg-an-flat-blue-btn tw-text-white tw-rounded-sm tw-text-main-xs2 tw-mx-[6px]"
        >В архиве</span>
        <span
          v-if="isToOffice"
          class="tw-px-[6px] tw-py-[3px] tw-bg-an-flat-blue-btn tw-text-white tw-rounded-sm tw-text-main-xs2 tw-mx-[6px]"
        >Сдан в офис</span>
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView v-if="contractInfo">
      <template #title>
        Дата заключения
      </template>
      <div v-if="contractInfo.contractDate">
        {{ $Global.DateFormat.DateFull(contractInfo.contractDate) }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView no-data-text="Бесплатно">
      <template #title>
        Комиссия агенства
      </template>
      <div v-if="!info.free">
        {{ (info.commissionSum || 0).toLocaleString() }} руб.
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView v-if="info.free">
      <template #title>
        Бесплатная услуга на основании
      </template>
      <div>
        <div>
          {{ info.freeCondition && info.freeCondition.label }}
        </div>
        <div>
          <div v-if="info.relatedContract">
            <small>
              Связанный договор:
              <ViewPanelLink
                :id="info.relatedContract.id"
                class="font-weight-medium"
                label="Договор"
                component="deal-contract-show"
              >
                № {{ info.relatedContract.contractNum }}
              </ViewPanelLink>
            </small>
          </div>
          <div v-if="info.relatedContract && info.relatedContract.deal">
            <small>
              Связанная сделка:
              <ViewPanelLink
                :id="info.relatedContract.deal.id"
                class="font-weight-medium"
                label="Заявка"
                component="deal-show"
              >
                <span
                  v-if="info.relatedContract.deal.status"
                  :class="info.relatedContract.deal.status.code === 'success' ? 'tw-text-an-flat-green-btn-bg-active tw-bg-an-flat-green-btn-bg-active/20':'tw-text-an-flat-orange-warning tw-bg-an-flat-orange-lighten'"
                >
                  {{ info.relatedContract.deal.status.name }} - {{ info.relatedContract.deal.name }}
                </span>
              </ViewPanelLink>
            </small>
          </div>
          <div v-if="info.relatedDeal && info.relatedDeal.id">
            <small>Связанная сделка:</small>
            <div>
              <ViewPanelLink
                :id="info.relatedDeal.id"
                label="Заявка"
                component="deal-show"
              >
                {{ info.relatedDeal.name }}
              </ViewPanelLink>
              <span
                title="Статус связанной сделки"
                class="tw-ml-[6px] tw-px-[10px] tw-py-[2px] tw-text-main-sm2 tw-font-medium tw-rounded-sm"
                :class="info.relatedDeal.status.code === 'success' ? 'tw-text-an-flat-green-btn-bg-active tw-bg-an-flat-green-btn-bg-active/20':'tw-text-an-flat-orange-warning tw-bg-an-flat-orange-lighten'"
              >
                {{ info.relatedDeal.status.name }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView v-if="contractInfo && contractInfo.deal">
      <template #title>
        Клиент от риелтора
      </template>
      <div v-if="contractInfo.deal.byRealtorId">
        <ViewPanelLink
          :id="contractInfo.deal.byRealtorId"
          label="Пользователь"
          component="employee-show"
        >
          <EmployeeName :id="contractInfo.deal.byRealtorId" />
        </ViewPanelLink>
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView v-if="contractInfo && contractInfo.userId">
      <template #title>
        Зарегистрировал номер договора
      </template>
      <div>
        <ViewPanelLink
          :id="contractInfo.userId"
          label="Пользователь"
          component="employee-show"
        >
          <EmployeeName :id="contractInfo.userId" />
        </ViewPanelLink>
        <div
          v-if="contractInfo.createdAt"
          class="tw-text-an-flat-disabled-text tw-text-main-sm2"
        >
          Создан: {{ $Global.DateFormat.DateTimeShort(contractInfo.createdAt) }}
        </div>
        <div
          v-if="contractInfo.createdAt && contractInfo.updatedAt && contractInfo.createdAt !== contractInfo.updatedAt"
          class="tw-text-an-flat-disabled-text tw-text-main-sm2"
        >
          Изменён: {{ $Global.DateFormat.DateTimeShort(contractInfo.updatedAt) }}
        </div>
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView v-if="contractInfo && contractInfo.signerUserId">
      <template #title>
        Подписал договор
      </template>
      <div v-if="contractInfo.signerUserId">
        <ViewPanelLink
          :id="contractInfo.signerUserId"
          label="Пользователь"
          component="employee-show"
        >
          <EmployeeName :id="contractInfo.signerUserId" />
        </ViewPanelLink>
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView v-if="info && info.finishDate">
      <template #title>
        Дата сделки
      </template>
      <div>
        <span>{{ $Global.DateFormat.DateFull(info.finishDate) }}</span>
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView v-if="info.comment">
      <template #title>
        Примечание
      </template>
      <div>
        <span>{{ info.comment }}</span>
      </div>
    </TwPanelContentBlockFieldView>
  </div>
  <div
    v-else
    class="text-muted text-center"
  >
    Нет данных
  </div>
</template>

<script>
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink'
import EmployeeName from '@/components/other/employee/EmployeeName'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'

export default {
  name: 'DealDocumentShow',
  components: { TwPanelContentBlockFieldView, EmployeeName, ViewPanelLink },
  props: {
    noLinkId: {
      type: Boolean,
      default: false
    },
    info: {},
    dictionary: {}
  },
  computed: {
    contractInfo () {
      return this.info?.contract || null
    },
    isArchive () {
      return this.contractInfo?.archive?.status?.value === 'accept'
    },
    isToOffice () {
      return this.contractInfo?.toOffice?.status?.value === 'accept'
    }
  }
}
</script>
