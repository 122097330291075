<template>
  <div class="tw-p-2 tw-flex">
    <div class="tw-m-auto tw-p-[24px] tw-border tw-border-solid tw-rounded-md">
      <div class="tw-text-h3 tw-text-an-flat-red-main">
        Внимание!
      </div>
      <div class="tw-text-an-flat-gray-4">
        Произошла ошибка получения информации с сервера
      </div>
      <div class="tw-mt-[18px] tw-text-center">
        <div class="tw-text-an-flat-red-main tw-font-medium tw-text-main-md">
          Попробуйте перезагрузить страницу!
        </div>
        <div class="tw-text-an-flat-gray-4 tw-text-main-sm">
          Если ошибка повторяется, обратитесь к администратору
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ErrorFetch'
}
</script>
