import { AxiosResponse } from 'axios'
import HTTP from '@/Api/http'

// const path = '/deal/mortgage'
const path = (dealId: number) => `/deal/${dealId}/mortgage/selection-params`

export default {
  // create (): Promise<AxiosResponse> {
  //   const $config = {}
  //   return HTTP.get(`${path}/create`, $config)
  // },
  store (dealId: number, data: any): Promise<AxiosResponse> {
    return HTTP.post(path(dealId), data)
  },
  update (dealId: number, data: any): Promise<AxiosResponse> {
    return HTTP.put(path(dealId), data)
  },
  show (dealId: number): Promise<AxiosResponse> {
    const $config = {}
    return HTTP.get(path(dealId), $config)
  }
}
