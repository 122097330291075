<template>
  <div
    v-cloak
    v-if="info"
    class="tw-flex tw-gap-[12px] tw-flex-col"
  >
    <TwPanelContentBlockFieldView>
      <template #title>
        Сделка
      </template>
      <div v-if="info.deal && info.deal.id">
        <ViewPanelLink
          :id="info.deal.id"
          label="Заявка"
          component="deal-show"
        >
          {{ info.deal.name || info.deal.id }}
        </ViewPanelLink>
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Ответственный по сделке
      </template>
      <div v-if="info.deal && info.deal.responsibleId">
        <ViewPanelLink
          :id="info.deal.responsibleId"
          label="Сотрудник"
          component="employee-show"
        >
          <EmployeeName
            :id="info.deal.responsibleId"
            is-avatar
          />
        </ViewPanelLink>
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Дата сделки
      </template>
      <div v-if="info.dealDate">
        {{ info.dealDate }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Номер договора
      </template>
      <div v-if="info.contractNum">
        {{ info.contractNum }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Стоимость объекта
      </template>
      <div v-if="info.objectPrice">
        {{ (info.objectPrice).toLocaleString('ru') }} руб.
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Предварительная комиссия
      </template>
      <div
        v-if="info.commission"
      >
        <span class="tw-text-main-lg tw-font-medium">{{ (info.commission).toLocaleString('ru') }}</span> руб.
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Компания
      </template>
      <div v-if="reservation && reservation.company">
        <ViewPanelLink
          :id="reservation.company.value"
          label="Компания"
          component="company-show"
        >
          {{ reservation.company.label }}
        </ViewPanelLink>
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView no-data-text="Нет данных">
      <template #title>
        Дата изменения
      </template>
      <div>
        {{ $Global.DateFormat.DateTimeShortMonthLong(info.updatedAt || info.createdAt) }}
      </div>
    </TwPanelContentBlockFieldView>
  </div>
</template>

<script>
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink.vue'
import EmployeeName from '@/components/other/employee/EmployeeName.vue'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'

export default {
  name: 'ResultMainShow',
  components: { TwPanelContentBlockFieldView, ViewPanelLink, EmployeeName },
  props: {
    info: {},
    dictionary: {}
  },
  computed: {
    reservation () {
      return this.info?.reservation || null
    }
  }
}
</script>
