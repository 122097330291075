import { AxiosResponse } from 'axios'
import HTTP from '@/Api/http'
import { AuthAxiosDefaultConfig } from '@/Api/ObjectStorage'

const path = '/fias/search'

export default {
  searchByAddress (param?: object): Promise<AxiosResponse> {
    // param = { address: '', fiasLevel: 'house' | 'street' }
    const $config: any = {
      ...AuthAxiosDefaultConfig,
      params: param
    }
    return HTTP.get(`${path}/by-address`, $config)
  }
}
