<template>
  <TwPanelTabsFull
    v-model="currentTab"
    :items="tabList"
  >
    <template #content.DealBounceHandling>
      <DealBounceHandlingTab
        :deal-data="regroupDealData.mainDeal"
        @refresh="refreshDeal"
      />
    </template>
    <template #content.main>
      <DealGeneralTab
        :deal="regroupDealData.mainDeal"
        :deal-state="dealState"
        :dictionary="dictionary"
        :permissions="permissions"
        :is-new="isNew"
        :edit-main="editMain"
        :loading="loading"
        @changeEdit="editMain = $event"
        @refresh="refreshDeal"
        @create="refreshDeal"
        @update="refreshDeal"
      />
    </template>
    <template #content.client>
      <DealInsuranceClientTab :deal-data="dealData" />
    </template>
    <template #content.myCompanyDocs>
      <DealInsuranceResultBlock
        :deal-data="dealData"
        @refresh="refreshDeal"
      />
    </template>
    <template #content.files>
      <DealFilesTab
        :deal="regroupDealData.mainDeal"
        :dictionary="dictionary"
        :permissions="permissions"
        :is-new="isNew"
        :loading="loading"
      />
    </template>
  </TwPanelTabsFull>
</template>

<script>
import TwPanelTabsFull from '@/components/tw-ui/panels/TwPanelTabsFull.vue'
import DealGeneralTab from '@/views/Deal/TypeViews/DealGeneralTab'
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import DealFilesTab from '@/views/Deal/TypeViews/DealFilesTab.vue'
import { mapGetters } from 'vuex'
import DealBounceHandlingTab from '@/views/Deal/DealBounceHandling/DealBounceHandlingTab.vue'
import DealInsuranceClientTab from '@/views/Deal/TypeViews/insurance/Client/DealInsuranceClientTab.vue'
import DealInsuranceResultBlock from '@/views/Deal/TypeViews/insurance/Result/DealInsuranceResultBlock.vue'

export default {
  name: 'DealInsurance',
  components: {
    DealInsuranceResultBlock,
    DealInsuranceClientTab,
    DealBounceHandlingTab,
    DealFilesTab,
    TwPanelTabsFull,
    DealGeneralTab
  },
  mixins: [ViewPanelShowMixin],
  props: {
    dictionary: {
      type: Object,
      default: () => {
        return {}
      }
    },
    dealState: {
      default: null
    },
    permissions: {
      type: Object,
      default: () => {
        return {}
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    isNew: {
      type: Boolean,
      default: false
    },
    presetTab: {
      type: String,
      default: null
    },
    dealData: {
      required: true
    }
  },
  data () {
    return {
      updateProcessing: false,
      currentTab: this.presetTab || 'main',
      editMain: false
    }
  },
  methods: {
    currentTabChange (tabKey) {
      console.warn('currentTabChange', tabKey)
      this.currentTab = tabKey || 'main'
    },
    refreshDeal (v) {
      this.editMain = false
      this.$emit('refresh', v)
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'profile/isAdmin',
      allowNamespacesDict: 'profile/allowNamespacesDict'
    }),
    tabList () {
      return [
        {
          label: 'Общие',
          key: 'main'
        },
        {
          label: 'Клиент',
          key: 'client',
          disabled: this.isNew || this.loading || this.updateProcessing
        },
        {
          label: 'Документы компании',
          key: 'myCompanyDocs',
          disabled: this.isNew || this.loading || this.updateProcessing
        }
      ]
    },
    regroupDealData () {
      const { programs = [], dealApprovalInfo, ...variantFilters } = this.typedDealData || {}
      // console.log(this.$options.name, programs)
      // добавляю к фильтрам значения из основной Заявки - TODO клиентов добавлять не нужно??? Ибо тут не создание а обновление
      Object.assign(variantFilters || {}, {
        personId: this.dealData?.person?.id,
        otherPersons: Array.isArray(this.dealData?.otherPersons) && this.dealData?.otherPersons.length ? this.dealData?.otherPersons.map(c => c.id) : []
      })
      return {
        mainDeal: this.dealData,
        mortgageDeal: this.typedDealData,
        dealApprovalInfo,
        variantFilters,
        programs
      }
    }
  },
  watch: {
    // при переходах между вкладками отключаю редактирование
    currentTab () {
      this.editMain = false
    }
  }
}
</script>
