<template>
  <div
    v-if="loading"
    class="tw-p-2 tw-flex"
  >
    <TwCircleLoader class="tw-m-auto" />
  </div>
  <div v-else-if="Array.isArray(serviceList) && serviceList.length">
    <TwPopoverShort
      :open-by-hover="false"
      :disabled="loading"
    >
      <template #trigger>
        <TwPanelActionButton
          class="tw-min-h-[32px]"
          title="Создать заявку в другой отдел"
          color="blue"
          :disabled="loading"
        >
          Заявка на услугу
        </TwPanelActionButton>
      </template>
      <template #content>
        <div class="tw-max-w-[200px]">
          <ul>
            <li
              v-for="(item, index) in serviceList"
              :key="index"
              :disabled="loading"
              class="tw-block tw-px-[10px] tw-py-[6px] tw-cursor-pointer hover:tw-underline"
              @click.prevent="openModalFromService(item)"
            >
              <span v-if="item.name && item.code">{{ item.name }}</span>
            </li>
          </ul>
        </div>
      </template>
    </TwPopoverShort>
    <TwDialog
      v-model="openModal"
      max-width="800px"
      :is-close-btn="!(successCreateMsg)"
      @close="onCloseModal"
    >
      <template #header>
        <div v-if="!successCreateMsg">
          <div class="tw-text-h5">
            Новая заявка на {{ targetService.name }}
          </div>
          <div v-if="targetService.description">
            {{ targetService.description }}
          </div>
        </div>
      </template>
      <template #body>
        <div class="tw-pr-[6px]">
          <div
            v-if="successCreateMsg"
            class="tw-flex tw-flex-col tw-py-[50px] tw-text-center"
          >
            <div
              class="tw-text-h4 tw-mb-[30px]"
              v-html="$Global.nl2br(successCreateMsg)"
            />
            <TwPanelActionButton
              class="tw-m-auto"
              size="lg"
              @click.prevent="onCloseModal"
            >
              Закрыть
            </TwPanelActionButton>
          </div>
          <DealRelationCreateForm
            v-else
            :deal-id="dealId"
            :service-code="targetService.code"
            @created="relatedDealOnCreated"
            @cancel="onCloseModal"
          />
        </div>
      </template>
    </TwDialog>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import API from '@/Api/Crm/Deal/RelatedServices'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import TwDialog from '@/components/tw-ui/modal/TwDialog.vue'
import DealRelationCreateForm from './DealRelationCreateForm.vue'
import TwPanelActionButton from '@/components/tw-ui/panels/TwPanelActionButton.vue'
import TwPopoverShort from '@/components/tw-ui/popover/TwPopoverShort.vue'

export default defineComponent({
  name: 'DealRelationsAllowServicesBlock',
  components: { TwCircleLoader, TwPanelActionButton, TwDialog, DealRelationCreateForm, TwPopoverShort },
  props: {
    dealId: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      loading: false,
      targetService: null,
      createdResult: null,
      serviceList: [],
      openModal: false
    }
  },
  methods: {
    relatedDealOnCreated (eventData) {
      this.$set(this, 'createdResult', eventData)
      this.$emit('refresh')
    },
    onCloseModal () {
      this.targetService = null
      this.$set(this, 'createdResult', null)
      this.openModal = false
    },
    // Получение списка кодов
    fetchSevices () {
      if (this.dealId) {
        this.loading = true
        API.list(this.dealId).then(({ data }) => {
          console.warn('FETCH fetchSevices: ', data?.data)
          if (typeof data?.data === 'object') {
            this.serviceList = Object.values(data?.data)
          }
        }).catch(error => {
          console.error('Error fetchSevices: ', error)
          this.serviceList = []
        }).finally(() => {
          this.loading = false
        })
      }
    },
    openModalFromService (item) {
      this.targetService = item
      this.openModal = true
    }
  },
  computed: {
    successCreateMsg () {
      return this.createdResult && this.createdResult.message
    }
  },
  mounted () {
    this.fetchSevices()
  }
})
</script>

<style scoped>

</style>
