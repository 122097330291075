import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './assets/tailwind.css'

import VueSocketIO from 'vue-socket.io'
import SocketIO from 'socket.io-client'
import { SERVICES } from '@/Api/Services'
import { IoOptions } from '@/plugins/SocketIoConfig'

import './plugins/vuelidate.js'
import global from './plugins/global'
import './plugins/clickboard'
import './plugins/helpers/ErrorHandler'
import './plugins/browser-detect.js'

import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

Vue.component('Multiselect', Multiselect)

Vue.config.productionTip = false
// Vue.config.devtools = false

Vue.prototype.$isDev = process.env.NODE_ENV !== 'production'

Vue.use(global, { router, store, vuetify })

// Сокеты - конфигурация
Vue.use(new VueSocketIO({
  debug: process.env.NODE_ENV !== 'production',
  connection: SocketIO(`${process.env.NODE_ENV === 'production' ? SERVICES.MESSENGER.host : 'http://localhost'}:7855`, IoOptions),
  vuex: {
    store,
    actionPrefix: 'SOCKET_'
  }
}))

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
