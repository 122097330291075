import { RouteKeys } from '@/router/types'

export default {
  path: RouteKeys.reports,
  name: RouteKeys.reports,
  redirect: { name: `${RouteKeys.reports}-api-list` },
  meta: { name: 'Отчёты', icon: 'main-reports' },
  component: () => import('@/views/Reports/Index.vue'),
  children: [
    {
      path: 'api-list',
      name: `${RouteKeys.reports}-api-list`,
      meta: { name: 'Список отчётов', icon: 'reports', hideInMenu: true },
      component: () => import('@/views/Reports/_DynamicReports/ReportsApiListPage.vue')
    },
    {
      path: 'detail/:serviceKey/:reportCode',
      name: `${RouteKeys.reports}-detail`,
      meta: { name: 'Отчёт', icon: 'reports', hideInMenu: true },
      props: (route: any) => ({ serviceKey: route.params.serviceKey, reportCode: route.params.reportCode, title: route.params.title }),
      component: () => import('@/views/Reports/_DynamicReports/ReportDetailPage.vue')
    }
  ]
}
