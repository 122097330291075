import { render, staticRenderFns } from "./TwBasicContentWrapper.vue?vue&type=template&id=953d944a&scoped=true"
import script from "./TwBasicContentWrapper.vue?vue&type=script&lang=js"
export * from "./TwBasicContentWrapper.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "953d944a",
  null
  
)

export default component.exports