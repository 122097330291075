import { SERVICES } from '@/Api/Services'
import DealReview from '@/Api/Review/DealReview'
import DealReviewData from '@/Api/Review/DealReviewData'
import Sipuni from '@/Api/Review/Sipuni'
import Poll from '@/Api/Review/Poll'

export const AuthAxiosDefaultConfig: any = {
  serviceKey: SERVICES.REVIEW.key
}

export {
  DealReview,
  DealReviewData,
  Sipuni,
  Poll
}
