import Vue from 'vue'
import { Module } from 'vuex'
import { RootState } from '@/store/types'
import { ReportsState } from './types'

const namespaced = true

export const reports: Module<ReportsState, RootState> = {
  namespaced,
  state: {
    charts: {},
    structure: {
      realtyTypes: [],
      offices: [],
      groups: [],
      realtors: []
    }
  },
  actions: {
    fetchOffices: function ({ commit }, reportAPI) {
      return reportAPI.getOfficeList()
        .then(({ data }: any) => {
          console.log('fetchOffices', data.data)
          commit('SET_STRUCTURE', { key: 'offices', data: data.data || [] })
        })
        .catch((error: any) => {
          console.error(error)
        })
        .finally(() => {
          console.log('finally')
        })
    },
    fetchRealtors: function ({ commit }, reportAPI) {
      return reportAPI.getRealtorList()
        .then(({ data }: any) => {
          console.log('fetchRealtors', data.data)
          commit('SET_STRUCTURE', { key: 'realtors', data: data.data || [] })
        })
        .catch((error: any) => {
          console.error(error)
        })
        .finally(() => {
          console.log('finally')
        })
    },
    fetchGroups: function ({ commit }, reportAPI) {
      return reportAPI.getGroupList()
        .then(({ data }: any) => {
          console.log('fetchGroups', data)
          commit('SET_STRUCTURE', { key: 'groups', data: data.data || [] })
        })
        .catch((error: any) => {
          console.error(error)
        })
        .finally(() => {
          console.log('finally')
        })
    },
    fetchRealtyTypes: function ({ commit }, reportAPI) {
      return reportAPI.getRealtyTypeList()
        .then(({ data }: any) => {
          console.log('fetchRealtyTypes', data)
          commit('SET_STRUCTURE', { key: 'realtyTypes', data: data.data || [] })
        })
        .catch((error: any) => {
          console.error(error)
        })
        .finally(() => {
          console.log('finally')
        })
    }
  },
  mutations: {
    SET_STRUCTURE (state, payload) {
      Vue.set(state.structure, payload.key, payload.data)
      // state.structure[payload.key] = payload.data
    },
    SET_CHART_BY_KEY (state, payload) {
      Vue.set(state.charts, payload.key, payload.data)
      // state.charts[payload.key] = payload.data
    }
  },
  getters: {
    offices (state) {
      return state.structure.offices
    },
    realtyTypes (state) {
      return state.structure.realtyTypes
    },
    groups (state) {
      return state.structure.groups
    },
    realtors (state) {
      return state.structure.realtors
    },
    chartDataByKey: (state) => (key: string) => {
      return state.charts[key] || []
    }
  }
}
