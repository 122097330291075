<template>
  <div class="tw-my-[15px]">
    <div class="tw-grid xl:tw-grid-cols-2 md:tw-grid-cols-1 tw-gap-y-[12px] tw-gap-x-[26px] tw-w-full">
      <div>
        <TwInputText
          type="text"
          placeholder="Бюджет"
          max-length="13"
          sub-type="int"
          :required="true"
          :error-text="!form.budget ? 'Обязательное поле' : ''"
          :value="$Global.inputFormatPrice.getStringSpaced(form.budget)"
          :show-label="true"
          :persistent-label="true"
          :dense="true"
          :disabled="formProcessing"
          @input="form.budget = $Global.inputFormatPrice.getNumber($event)"
        >
          <template #requiredSymbol>
            *
          </template>
          <template #append>
            руб.
          </template>
        </TwInputText>
      </div>
      <div>
        <TwSelect
          v-model="form.payType"
          label="Форма оплаты"
          placeholder="Выбрать"
          :required="true"
          :error-text="!form.payType ? 'Обязательное поле' : ''"
          :options="dictionary.payType"
          :dense="true"
          :persistent-label="true"
          :disabled="formProcessing"
        />
      </div>
      <div v-if="form.hasMortgagePayType || (form.payType === 'mortgage' || form.payType === 'mortgageNotApproved')">
        <TwSelectRelations
          v-model="form.bankId"
          :multi="false"
          :dense="true"
          :persistent-label="true"
          label="Банк"
          placeholder="Выберите банк"
          list-label="Найти банк"
          relation-name="bank"
          :disabled="formProcessing"
        />
      </div>
      <div v-if="form.hasMortgagePayType || (form.payType === 'mortgage' || form.payType === 'mortgageNotApproved')">
        <TwSelect
          v-model="form.mortgageProgramId"
          label="Программа ипотеки"
          placeholder="Выбрать"
          :required="false"
          :options="dictionary.mortgageProgram"
          :dense="true"
          :persistent-label="true"
          :disabled="formProcessing"
        />
      </div>
      <div>
        <TwInputText
          type="text"
          placeholder="Наличные, сумма"
          max-length="13"
          sub-type="int"
          :required="true"
          :error-text="!form.paySum ? 'Обязательное поле' : ''"
          :value="$Global.inputFormatPrice.getStringSpaced(form.paySum)"
          :show-label="true"
          :persistent-label="true"
          :dense="true"
          :disabled="formProcessing"
          @input="form.paySum = $Global.inputFormatPrice.getNumber($event)"
        >
          <template #append>
            руб.
          </template>
        </TwInputText>
      </div>
      <div>
        <TwSelect
          v-model="form.purpose"
          label="Цель строительства"
          placeholder="Выбрать"
          :required="true"
          :error-text="!form.purpose ? 'Обязательное поле' : ''"
          :options="dictionary.purpose"
          :dense="true"
          :persistent-label="true"
          :disabled="formProcessing"
        />
      </div>
      <div>
        <div class="tw-ml-[5px] tw-text-an-flat-blue-btn-active tw-text-main-sm2 tw-mb-[10px]">
          Наличие участка
        </div>
        <TwInputSwitch
          v-model="form.hasLand"
          input-class-checked="checked:tw-bg-an-flat-green-btn-bg-active"
          :disabled="formProcessing"
        >
          <template #label>
            <span class="tw-relative tw-z-10 tw-text-an-flat-grey-4 tw-font-manrope tw-font-semibold tw-text-[14px]">
              <span :class="form.hasLand ? 'tw-text-an-flat-green-btn-bg-active' : 'tw-text-an-flat-red-main'">{{ form.hasLand ? 'Есть' : 'Нет' }}</span>
            </span>
          </template>
        </TwInputSwitch>
      </div>
      <div v-if="!!form.hasLand">
        <div class="tw-ml-[5px] tw-text-an-flat-blue-btn-active tw-text-main-sm2 tw-mb-[10px]">
          ГПЗУ
        </div>
        <TwInputSwitch
          v-model="form.hasGpzu"
          input-class-checked="checked:tw-bg-an-flat-green-btn-bg-active"
          :disabled="formProcessing"
        >
          <template #label>
            <span class="tw-relative tw-z-10 tw-text-an-flat-grey-4 tw-font-manrope tw-font-semibold tw-text-[14px]">
              <span :class="form.hasGpzu ? 'tw-text-an-flat-green-btn-bg-active' : 'tw-text-an-flat-red-main'">{{ form.hasGpzu ? 'Есть' : 'Нет' }}</span>
            </span>
          </template>
        </TwInputSwitch>
      </div>
      <div v-if="!form.hasLand">
        <TwInputText
          type="text"
          placeholder="Стоимость участка, до"
          max-length="13"
          sub-type="int"
          :value="$Global.inputFormatPrice.getStringSpaced(form.landPaySum)"
          :show-label="true"
          :persistent-label="true"
          :dense="true"
          :disabled="formProcessing"
          @input="form.landPaySum = $Global.inputFormatPrice.getNumber($event)"
        >
          <template #append>
            руб.
          </template>
        </TwInputText>
      </div>
      <div v-if="!form.hasLand">
        <TwTextarea
          v-model="form.requirementsToLand"
          placeholder="Требования к участку"
          :dense="true"
          rows="5"
          maxlength="250"
          autocomplete="off"
          persistent-label
          :show-label="true"
          :disabled="formProcessing"
        />
      </div>
      <div class="tw-flex tw-flex-row tw-gap-[6px] tw-items-center">
        <TwInputMinMaxFields
          label="Площадь участка"
          :value="[form.landAreaFrom, form.landAreaTo]"
          suffix="сот."
          :min="0"
          :max="10000"
          :dense="false"
          :disabled="false"
          @input="onInputKitchenArea($event)"
        />
      </div>
      <div>
        <TwSelect
          v-model="form.floors"
          label="Кол-во этажей"
          placeholder="Выбрать"
          :required="true"
          :error-text="!form.floors ? 'Обязательное поле' : ''"
          :options="dictionary.floors"
          :dense="true"
          :persistent-label="true"
          :disabled="formProcessing"
        />
      </div>
      <div>
        <TwSelect
          v-model="form.bathroomCount"
          label="Кол-во сан-узлов"
          placeholder="Выбрать"
          :options="dictionary.bathroomCount"
          :dense="true"
          :persistent-label="true"
          :disabled="formProcessing"
        />
      </div>
      <div>
        <TwInputText
          v-model.number="form.rooms"
          type="text"
          placeholder="Кол-во комнат"
          max-length="2"
          sub-type="int"
          :required="true"
          :error-text="!form.rooms ? 'Обязательное поле' : ''"
          :show-label="true"
          :persistent-label="true"
          :is-money-format="false"
          :dense="true"
          :disabled="formProcessing"
        />
      </div>
      <div>
        <TwSelect
          v-model="form.area"
          label="Площадь дома"
          placeholder="Выбрать"
          :required="true"
          :error-text="!form.area ? 'Обязательное поле' : ''"
          :options="dictionary.area"
          :dense="true"
          :persistent-label="true"
          :disabled="formProcessing"
        />
      </div>
      <div>
        <TwSelect
          v-model="form.additionalBuildings"
          label="Дополнительно"
          placeholder="Выбрать"
          :options="dictionary.additionalBuildings"
          :dense="true"
          :multi="true"
          :persistent-label="true"
          :disabled="formProcessing"
        />
      </div>
      <div>
        <TwSelect
          v-model="form.roof"
          label="Крыша"
          placeholder="Выбрать"
          :options="dictionary.roof"
          :dense="true"
          :multi="false"
          :persistent-label="true"
          :disabled="formProcessing"
        />
      </div>
    </div>
    <div class="tw-mt-[5px]">
      <div class="tw-ml-[5px] tw-text-an-flat-blue-btn-active tw-text-main-sm2 tw-mb-[5px]">
        Точка на карте:
      </div>
      <YandexMapRealtyObject
        class="tw-border tw-border-solid"
        draw-mode="POINT"
        :view-only="false"
        :is-preset-default-coords="false"
        :coords="adaptedMapGeoPoint"
        style="width: 100%; height: 300px;"
        @coords-change="mapPointChange"
      />
    </div>
    <TwCardSimpleFormSaveBlock
      class="tw-mt-[15px]"
      :loading="formProcessing"
      :cancel-confirm="true"
      :is-sticky="true"
      :disable-success="formProcessing"
      :disable-cancel="formProcessing"
      :visible="true"
      @success="handleSubmit"
      @cancel="handleCancel"
    />
  </div>
</template>

<script>
import API from '@/Api/Crm/Deal/HouseDevelop/Questionnaire'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'

import TwCardSimpleFormSaveBlock from '@/components/tw-ui/forms/TwCardSimpleFormSaveBlock.vue'
import TwInputText from '@/components/tw-ui/ui-kit/input/TwInputText.vue'
import TwSelect from '@/components/tw-ui/ui-kit/input/TwSelect.vue'
import TwInputSwitch from '@/components/tw-ui/ui-kit/input/TwInputSwitch.vue'
import YandexMapRealtyObject from '@/components/Map/YandexMapRealtyObject.vue'
import TwTextarea from '@/components/tw-ui/ui-kit/input/TwTextarea.vue'
import TwInputMinMaxFields from '@/components/tw-ui/ui-kit/input/TwInputMinMaxFields.vue'
import TwSelectRelations from '@/components/tw-ui/ui-kit/input/select-relations/TwSelectRelations.vue'

export default {
  name: 'DealHouseDevelopQuestionnaireForm',
  components: {
    TwSelectRelations,
    TwInputMinMaxFields,
    TwTextarea,
    YandexMapRealtyObject,
    TwInputSwitch,
    TwSelect,
    TwInputText,
    TwCardSimpleFormSaveBlock
  },
  mixins: [FormCreateEditMixin],
  props: {
    dealId: {
      type: Number,
      required: true
    },
    presetValues: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      API: API,
      form: {},
      adaptedMapGeoPoint: null,
      createQueryParams: {
        dealId: null
      },
      serverErrors: null
    }
  },
  validations () {
    return {
      form: {}
    }
  },
  methods: {
    clearMortgageDate () {
      this.form.hasMortgagePayType = null
      this.form.bankId = null
      this.form.mortgageProgramId = null
    },
    onInputKitchenArea (valueArr) {
      this.form.landAreaFrom = valueArr[0]
      this.form.landAreaTo = valueArr[1]
    },
    mapPointChange (eventData) {
      console.warn('mapPointChange: ', eventData)
      this.adaptedMapGeoPoint = null
      this.form.mapPoint = Object.values(eventData)
    },
    handleSubmit () {
      console.warn('handleSubmit', this.form, this.$v.form.$invalid)
      this.$v.$reset()
      this.$v.form.$touch()
      if (!this.$v.form.$invalid) {
        this.formProcessing = true

        if (!this.form.landPaySum) {
          this.form.landPaySum = null
        }

        if (!this.form.payType || (this.form.payType !== 'mortgage' && this.form.payType !== 'mortgageNotApproved')) {
          this.clearMortgageDate()
        }

        const request = this.formType === 'create'
          ? API.store(this.dealId, { ...this.form })
          : API.update(this.dealId, { ...this.form })

        request.then(({ data: result }) => {
          console.warn('SEND SUCCESS', result)
          this.$emit('refresh')
        }).catch((error) => {
          console.error('SEND ERROR', error)
        }).finally(() => {
          this.formProcessing = false
        })
      }
    },
    presetData () {
      console.warn(this.preset, this.presetValues)
      return new Promise((resolve) => {
        if (this.preset) {
          for (const key in this.preset) {
            if (key === 'mapPoint') {
              if (Array.isArray(this.preset[key]) && this.preset[key].length === 2) {
                this.adaptedMapGeoPoint = {
                  lat: this.preset[key][0],
                  lon: this.preset[key][1]
                }
              }
              this.$set(this.form, key, this.preset[key] || [])
            } else if (key === 'area' && this.preset[key]) {
              this.$set(this.form, 'area', this.preset[key].value || this.preset[key] || null)
            } else if (key === 'floors' && this.preset[key]) {
              this.$set(this.form, 'floors', this.preset[key].value || this.preset[key] || null)
            } else if (key === 'payType' && this.preset[key]) {
              this.$set(this.form, 'payType', this.preset[key].value || this.preset[key] || null)
            } else if (key === 'purpose' && this.preset[key]) {
              this.$set(this.form, 'purpose', this.preset[key].value || this.preset[key] || null)
            } else if (key === 'roof' && this.preset[key]) {
              this.$set(this.form, 'roof', this.preset[key].value || this.preset[key] || null)
            } else if (key === 'bathroomCount' && this.preset[key]) {
              this.$set(this.form, 'bathroomCount', this.preset[key].value || this.preset[key] || null)
            } else if (key === 'additionalBuildings' && this.preset[key]) {
              this.$set(this.form, 'additionalBuildings', this.preset[key].value || this.preset[key] || null)
            } else {
              if (this.presetValues?.[key]) {
                this.$set(this.form, key, this.presetValues[key])
              } else {
                this.$set(this.form, key, this.preset[key])
              }
            }
          }
        }
        resolve()
      })
    }
  }
}
</script>

<style scoped>

</style>
