<template>
  <div
    v-if="formProcessing"
    class="tw-py-3 tw-flex tw-w-full"
  >
    <TwCircleLoader class="tw-m-auto" />
  </div>
  <div v-else>
    <div style="display: none">
      {{ isEdited }}
    </div>
    <TwFlexCol>
      <TwFlexCell>
        <TwSelect
          v-model="form.level"
          label="Инвест. привлекательность"
          placeholder="Выберите из списка"
          :options="dictionary.level"
          :disabled="formProcessing"
          :dense="true"
          :persistent-label="true"
          :error-text="form.level ? '':'Обязательное поле'"
        />
      </TwFlexCell>
      <TwFlexCell>
        <TwTextarea
          v-model="form.comment"
          rows="5"
          label="Комментарий"
          maxlength="250"
          show-length
          resize="y"
          :persistent-label="true"
          :show-label="true"
          :disabled="formProcessing"
          :error-text="form.comment ? '':'Обязательное поле'"
        />
      </TwFlexCell>
      <TwFlexCell>
        <TwCardSimpleFormSaveBlock
          :loading="formProcessing"
          :cancel-confirm="true"
          :is-sticky="true"
          :dense="true"
          :disable-success="formProcessing"
          :disable-cancel="formProcessing"
          :visible="true"
          @success="handleSubmit"
          @cancel="handleCancel"
        >
          <template #info>
            <div
              v-if="serverErrorMessage"
              class=" tw-text-an-flat-red-main tw-text-center tw-p-[8px] tw-mb-[5px] tw-bg-white"
            >
              {{ serverErrorMessage }}
            </div>
          </template>
        </TwCardSimpleFormSaveBlock>
      </TwFlexCell>
    </TwFlexCol>
  </div>
</template>

<script>
import complexInvestAppealAPI from '@/Api/ObjectStorage/Complex/InvestAppeal'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import TwFlexCol from '@/components/tw-ui/ui-kit/flex-block/TwFlexCol.vue'
import TwFlexCell from '@/components/tw-ui/ui-kit/flex-block/TwFlexCell.vue'
import TwCardSimpleFormSaveBlock from '@/components/tw-ui/forms/TwCardSimpleFormSaveBlock.vue'
import { required } from 'vuelidate/lib/validators'
import TwSelect from '@/components/tw-ui/ui-kit/input/TwSelect.vue'
import TwTextarea from '@/components/tw-ui/ui-kit/input/TwTextarea.vue'

export default {
  name: 'RealtyComplexInvestAppealForm',
  components: {
    TwSelect,
    TwTextarea,
    TwCircleLoader,
    TwFlexCell,
    TwFlexCol,
    TwCardSimpleFormSaveBlock
  },
  mixins: [FormCreateEditMixin],
  props: {
    complexId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      API: complexInvestAppealAPI,
      relatedObjectId: this.complexId
    }
  },
  validations () {
    return {
      form: {
        level: {
          required
        },
        comment: {
          required
        }
      }
    }
  },
  methods: {
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          for (const key in this.preset) {
            if (key === 'level') {
              this.$set(this.form, key, this.preset[key]?.value || null)
            } else {
              this.$set(this.form, key, this.preset[key])
            }
          }
          if (!this.form?.complexId) {
            this.form.complexId = this.complexId || null
          }
        }
        resolve()
      })
    },
    handleSubmit () {
      this.$v.form.$reset()
      this.$v.form.$touch()
      if (!this.$v.form.$invalid) {
        if (this.formProcessing === true) {
          return
        }

        this.formProcessing = true

        const request = this.formType === 'create'
          ? this.API.store(this.form)
          : this.API.update(this.targetId, this.form)

        request.then(({ data }) => {
          if (this.formType === 'create') {
            this.$emit('create', data.data)
          } else {
            this.$emit('update', data.data)
          }
        }).catch((error) => {
          this.serverErrorMessage = error?.serverErrorMessage || 'Ошибка!'
        }).finally(() => {
          this.formProcessing = false
        })
      }
    }
  }
}
</script>
