<template>
  <div
    v-if="loading"
    class="tw-flex tw-justify-center tw-items-center"
  >
    <TwCircleLoader
      width="50px"
      height="50px"
    />
  </div>
  <TwPanelContent v-else>
    <div class="tw-flex-1">
      <!--О КЛИЕНТЕ-->
      <TwPanelContentBlock
        v-if="showMainBlock"
        class="tw-mb-[6px]"
      >
        <template #title>
          <div class="tw-flex tw-items-center tw-gap-[7px]">
            <span
              v-if="data && data.confirmed"
              :title="confirmedTextTrue"
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4 0C1.79086 0 0 1.79086 0 4V12C0 14.2091 1.79086 16 4 16H12C14.2091 16 16 14.2091 16 12V4C16 1.79086 14.2091 0 12 0H4ZM12.2312 6.95981C12.6383 6.58662 12.6658 5.95405 12.2926 5.54693C11.9194 5.13981 11.2868 5.11231 10.8797 5.4855L6.70696 9.31053L5.12006 7.85587C4.71294 7.48268 4.08037 7.51018 3.70718 7.9173C3.33399 8.32442 3.36149 8.95699 3.76861 9.33018L6.03124 11.4043L6.70696 12.0237L7.38269 11.4043L12.2312 6.95981Z"
                  fill="#27AA54"
                />
              </svg>
            </span>
            О {{ personTypes.isChildren ? 'ребёнке' : 'клиенте' }}
          </div>
        </template>
        <template #action>
          <button
            :disabled="isNew || loading"
            class="tw-text-main-xs enabled:hover:tw-text-an-flat-dark-blue-link"
            @click="editMain = !editMain"
          >
            {{ editMain ? 'отменить':'изменить' }}
          </button>
        </template>
        <div>
          <PersonMainForm
            v-if="editMain"
            :id="data ? data.id : 0"
            ref="EditMain"
            :person-types="personTypes"
            :person-object-id="personObjectId"
            @create="changeBlock('editMain')"
            @update="changeBlock('editMain')"
            @cancel="editMain = false"
          />
          <PersonMainShow
            v-else
            :person-types="personTypes"
            :dictionary="dictionary"
            :info="data"
          />
        </div>
      </TwPanelContentBlock>
      <!-- Работа и Доходы -->
      <TwPanelContentBlock
        v-if="data"
        class="tw-mb-[6px]"
      >
        <template #title>
          <div>
            Работа и Доходы
          </div>
        </template>
        <template #action>
          <button
            :disabled="isNew || loading"
            class="tw-text-main-xs enabled:hover:tw-text-an-flat-dark-blue-link"
            @click="editSalary = !editSalary"
          >
            {{ editSalary ? 'отменить':'изменить' }}
          </button>
          <button
            v-if="data.id && data.salary && data.salary.id && editSalary"
            class="tw-text-main-xs enabled:tw-text-an-flat-red-main"
            @click="deleteSalary(data.salary)"
          >
            Удалить
          </button>
        </template>
        <div>
          <PersonSalaryForm
            v-if="editSalary"
            :id="data && data.salary ? data.salary.id : 0"
            ref="salary"
            dense-fields
            :person-id="data.id"
            @create="changeBlock('editSalary')"
            @update="changeBlock('editSalary')"
            @refresh="changeBlock('editSalary')"
            @cancel="editSalary = false"
          />
          <PersonSalaryShow
            v-else
            :dictionary="dictionary"
            :info="data.salary"
          />
        </div>
      </TwPanelContentBlock>
      <!-- Кредиты -->
      <TwPanelContentBlock
        v-if="data"
        class="tw-mb-[6px]"
      >
        <PersonCreditsBlock
          ref="credits"
          :disabled="isNew || loading"
          :person-info="data"
          @create="refresh"
          @update="refresh"
          @refresh="refresh"
        />
      </TwPanelContentBlock>
    </div>
    <div class="tw-flex-1">
      <!-- Семья -->
      <TwPanelContentBlock
        v-if="data"
        class="tw-mb-[6px]"
      >
        <template #title>
          <div>
            Семья
          </div>
        </template>
        <template #action>
          <TwPanelContentBlockActionButton
            :disabled="isNew || loading"
            type="edit"
            @click="editFamily = !editFamily"
          >
            {{ editFamily ? 'отменить':'изменить' }}
          </TwPanelContentBlockActionButton>
        </template>
        <div>
          <PersonFamilyForm
            v-if="editFamily"
            :id="data && data.family ? data.family.id : 0"
            ref="family"
            dense-fields
            :person-id="data.id"
            @create="changeBlock('editFamily')"
            @update="changeBlock('editFamily')"
            @refresh="changeBlock('editFamily')"
            @cancel="editFamily = false"
          />
          <PersonFamilyShow
            v-else
            :dictionary="dictionary"
            :info="data.family"
          />
        </div>
      </TwPanelContentBlock>
      <!-- Дети до 18 -->
      <TwPanelContentBlock
        v-if="data"
        class="tw-mb-[6px]"
      >
        <template #title>
          <div>
            Дети до 18 лет
          </div>
        </template>
        <template #action>
          <button
            :disabled="isNew || loading"
            class="tw-text-main-xs enabled:hover:tw-text-an-flat-green"
            @click="addChild"
          >
            добавить
          </button>
        </template>
        <div>
          <PersonFamilyChildren
            :parent-id="data.id"
            :list="data.children"
            @refresh="refresh"
          />
        </div>
      </TwPanelContentBlock>

      <!-- Активы -->
      <PersonAssetsBlock
        v-if="data"
        class="tw-mb-[6px]"
        :disabled="isNew || loading"
        :person-info="data"
        @refresh="refresh"
      />
      <!-- Семья -->
      <TwPanelContentBlock
        v-if="data"
        class="tw-mb-[6px]"
      >
        <template #title>
          <div>
            Доп. информация
          </div>
        </template>
        <template #action>
          <button
            :disabled="isNew || loading"
            class="tw-text-main-xs enabled:hover:tw-text-an-flat-dark-blue-link"
            @click="editAdditional = !editAdditional"
          >
            {{ editAdditional ? 'отменить':'изменить' }}
          </button>
        </template>
        <div>
          <PersonAdditionalInfoForm
            v-if="editAdditional"
            :id="data && data.additionalInfo ? data.additionalInfo.id : 0"
            ref="additionalInfo"
            dense-fields
            :person-id="data.id"
            @create="changeBlock('editAdditional')"
            @update="changeBlock('editAdditional')"
            @refresh="changeBlock('editAdditional')"
            @cancel="editAdditional = false"
          />
          <PersonAdditionalInfoShow
            v-else
            :dictionary="dictionary"
            :info="data.additionalInfo"
          />
        </div>
      </TwPanelContentBlock>
    </div>
  </TwPanelContent>
</template>

<script>
import CardMixin from '@/mixins/CardMixin'
import API from '@/Api/Crm/Person'
import salaryAPI from '@/Api/Crm/Person/Salary'
import PersonMainForm from '@/views/Person/main/PersonMainForm'
import PersonMainShow from '@/views/Person/main/PersonMainShow'
import PersonSalaryForm from '@/views/Person/hipotec/PersonSalaryForm'
import PersonSalaryShow from '@/views/Person/hipotec/PersonSalaryShow'
import PersonCreditsBlock from '@/views/Person/hipotec/PersonCreditsBlock'
import PersonFamilyForm from '@/views/Person/hipotec/PersonFamilyForm'
import PersonFamilyShow from '@/views/Person/hipotec/PersonFamilyShow'
import PersonFamilyChildren from '@/views/Person/hipotec/PersonFamilyChildren'
import PersonAssetsBlock from '@/views/Person/hipotec/PersonAssetsBlock'
import PersonAdditionalInfoShow from '@/views/Person/hipotec/PersonAdditionalInfoShow'
import PersonAdditionalInfoForm from '@/views/Person/hipotec/PersonAdditionalInfoForm'
import { mapActions } from 'vuex'
import { confirmedTextTrue } from '@/views/Person/personDict'
import TwPanelContent from '@/components/tw-ui/panels/TwPanelContent.vue'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import TwPanelContentBlockActionButton from '@/components/tw-ui/panels/TwPanelContentBlockActionButton.vue'

export default {
  name: 'PersonHipotecTab',
  components: {
    TwCircleLoader,
    TwPanelContentBlock,
    TwPanelContent,
    PersonAdditionalInfoForm,
    PersonAdditionalInfoShow,
    PersonAssetsBlock,
    PersonFamilyChildren,
    PersonFamilyShow,
    PersonFamilyForm,
    PersonCreditsBlock,
    PersonSalaryShow,
    PersonSalaryForm,
    PersonMainShow,
    PersonMainForm,
    TwPanelContentBlockActionButton
  },
  mixins: [CardMixin],
  props: {
    showMainBlock: {
      type: Boolean,
      default: false
    },
    type: {
      type: [String, null],
      default: ''
    },
    personObjectId: {
      type: [Number, null],
      default: null
    }
  },
  data () {
    return {
      confirmedTextTrue,
      API: API,
      editAdditional: false, // статус состояния формы доп информации
      editSalary: false, // статус состояния формы информации о доходах
      editFamily: false // статус состояния формы информации о семье
    }
  },
  methods: {
    ...mapActions({
      showPanel: 'viewPanels/showPanel'
    }),
    deleteSalary (salaryInfo) {
      // подтверждение перед обнулением
      if (window.confirm('Подтвердите удаление информации!')) {
        this.editSalary = false
        salaryAPI.delete(salaryInfo.id).finally(() => {
          this.refresh()
        })
      }
    },
    changeBlock (blockKey) {
      this.$set(this, blockKey, false)
      this.refresh()
    },
    addChild () {
      console.log('addChild Start')
      this.showPanel({
        component: 'person-show',
        props: {
          id: 0,
          workToClose: true,
          type: 'children',
          personObjectId: this.data.id
        },
        label: 'Ребёнок',
        callback: () => {
          console.log('addChild callback')
          this.refresh()
        }
      })
    },
    refresh () {
      console.warn('refresh', this.$options.name, this.id, this.data, this.isNew)
      this.fetch()
    }
  },
  computed: {
    personTypes () {
      return {
        isChildren: this.data?.types?.includes('children') || this.type === 'children'
      }
    }
  },
  watch: {
    id (newValue, oldValue) {
      if (newValue && newValue !== oldValue) {
        this.refresh()
      }
    }
  },
  mounted () {
    //
  }
}
</script>

<style scoped>

</style>
