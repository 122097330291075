<template>
  <div
    v-cloak
    v-if="info"
    class="tw-flex tw-gap-[12px] tw-flex-col"
  >
    <TwPanelContentBlockFieldView no-data-text="Нет примечания">
      <template #title>
        Примечание
      </template>
      <div v-if="info.note">
        {{ info.note }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView no-data-text="Нет Названия">
      <template #title>
        Название
      </template>
      <div v-if="info.name">
        {{ info.name }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView v-if="info.subtype">
      <template #title>
        Вид заявки
      </template>
      <div v-if="info.subtype">
        {{ info.subtype && info.subtype.label }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView v-if="info.createdAt">
      <template #title>
        Дата создания
      </template>
      <div v-if="info.createdAt">
        {{ $Global.DateFormat.DateTimeShortMonthLong(info.createdAt) }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Статус
      </template>
      <div v-if="info.status">
        {{ info.status.name }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView v-if="info.dateChangeStatus">
      <template #title>
        Дата смены статуса
      </template>
      <div v-if="info.dateChangeStatus">
        {{ $Global.DateFormat.DateTimeShort(info.dateChangeStatus) }}
      </div>
    </TwPanelContentBlockFieldView>
    <!-- КОНТАКТЫ - КЛИЕНТЫ -->
    <TwPanelContentBlockFieldView>
      <template #title>
        Контакты
      </template>
      <div>
        <div v-if="info.person">
          <ViewPanelLink
            :id="info.person.id"
            label="Контакт"
            component="person-show"
          >
            {{ $Global.peopleName(info.person, 'fio') }}
          </ViewPanelLink>
          <span class="text-muted">oсн.</span>
          <div
            v-if="info.person"
            class="tw-flex"
          >
            <template v-if="Array.isArray(info.person.phones) && info.person.phones.length">
              <div
                v-for="(phone, index) in info.person.phones"
                :key="index"
                class=""
              >
                <div class="tw-flex tw-mt-[4px]">
                  <SipCallLink
                    v-if="phone.phone"
                    :class="phone.main ? 'tw-font-medium' : ''"
                    :phone="phone.phone"
                  />
                  <template v-if="index < info.person.phones.length - 1">
                    <span class="tw-mr-[7px]">,</span>
                  </template>
                </div>
              </div>
            </template>
          </div>
        </div>
        <template
          v-if="Array.isArray(info.otherPersons) && info.otherPersons.length"
        >
          <div
            v-for="(person, oc_index) of info.otherPersons"
            :key="oc_index+'-otherPersons'"
          >
            <div
              v-if="person"
              class="tw-mt-[12px]"
            >
              <ViewPanelLink
                :id="person.id"
                label="Контакт"
                component="person-show"
              >
                {{ $Global.peopleName(person, 'fio') }}
              </ViewPanelLink>
              <div class="tw-flex tw-mt-[4px]">
                <template v-if="Array.isArray(person.phones) && person.phones.length">
                  <div
                    v-for="(phone, index) in person.phones"
                    :key="index"
                    class=""
                  >
                    <div class="tw-flex">
                      <SipCallLink
                        v-if="phone.phone"
                        :class="phone.main ? 'tw-font-medium' : ''"
                        :phone="phone.phone"
                      />
                      <template v-if="index < person.phones.length - 1">
                        <span class="tw-mr-[7px]">,</span>
                      </template>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </template>
      </div>
    </TwPanelContentBlockFieldView>
    <!-- ОТВЕТСТВЕННЫЕ - ПОЛЬЗОВАТЕЛИ -->
    <TwPanelContentBlockFieldView>
      <template #title>
        Ответственные
      </template>
      <div>
        <div v-if="info.responsibleUserId">
          <TwPanelContentUserInfo
            :user-id="info.responsibleUserId"
            :warn-is-not-work-phone="true"
          />
        </div>
        <template
          v-if="Array.isArray(info.otherResponsibleUsersId) && info.otherResponsibleUsersId.length"
        >
          <div
            v-for="(userId, ou_index) of info.otherResponsibleUsersId"
            :key="ou_index+'-otherResponsibleUsersId'"
          >
            <div
              v-if="userId"
              class="mt-2"
            >
              <ViewPanelLink
                :id="userId"
                label="Пользователь"
                component="employee-show"
              >
                <EmployeeName :id="userId" />
              </ViewPanelLink>
            </div>
          </div>
        </template>
      </div>
    </TwPanelContentBlockFieldView>
    <!-- От риелтора -->
    <TwPanelContentBlockFieldView v-if="info.byRealtorId">
      <template #title>
        От риелтора
      </template>
      <div>
        <div>
          <ViewPanelLink
            :id="info.byRealtorId"
            label="Пользователь"
            component="employee-show"
          >
            <EmployeeName :id="info.byRealtorId" />
          </ViewPanelLink>
        </div>
      </div>
    </TwPanelContentBlockFieldView>

    <!-- СОЗДАТЕЛЬ -->
    <TwPanelContentBlockFieldView v-if="info.creatorId">
      <template #title>
        Создал
      </template>
      <div>
        <div>
          <ViewPanelLink
            :id="info.creatorId"
            label="Пользователь"
            component="employee-show"
          >
            <EmployeeName :id="info.creatorId" />
          </ViewPanelLink>
        </div>
      </div>
    </TwPanelContentBlockFieldView>
  </div>
</template>

<script>
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink'
import EmployeeName from '@/components/other/employee/EmployeeName'
import SipCallLink from '@/components/telephony/SipCallLink.vue'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'
import TwPanelContentUserInfo from '@/components/tw-ui/panels/TwPanelContentUserInfo.vue'

export default {
  name: 'DealMainShow',
  components: { TwPanelContentUserInfo, TwPanelContentBlockFieldView, SipCallLink, EmployeeName, ViewPanelLink },
  props: {
    info: {},
    dictionary: {}
  },
  methods: {
    refreshParent (id) {
      console.warn(this.$options.name, ' refreshParent ', id)
      this.$emit('refresh')
    }
  }
}
</script>

<style scoped>

</style>
