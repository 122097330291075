export default {}

// Расчет радиана
export function radCalc (pointY, pointX) {
  return fixedAngle(Math.atan2(pointY, pointX))
}
// Радиан в градусы
export function radToDeg (radian) {
  return fixedAngle(radian * (180 / Math.PI))
}
// Градус в радиан
export function degToRad (degree) {
  return fixedAngle(degree * (Math.PI / 180))
}
// Нормализация 0-360
export function degreeNormalize360 (deg) {
  return fixedAngle(deg >= 0 ? deg % 360 : (360 + deg) % 360)
}

// Разница между двумя радианами
export function radDiffBetween (newRad, oldRad) {
  return radCalc(Math.sin(newRad - oldRad), Math.cos(newRad - oldRad))
}

export function fixedAngle (value) {
  return Number((Math.round(value * 1e2) / 1e2).toFixed(2)) // Number(number.toFixed(2))
}

export function roundByKoef (value, k = 5) {
  return (Math.floor(value / 5) + Math.floor((value % k) / (k / 2))) * k
}
