<template>
  <component :is="tag">
    {{ prefix }}{{ structureName }}{{ suffix }}
  </component>
</template>

<script>
export default {
  name: 'StructureItemName',
  props: {
    item: {
      type: Object,
      required: true
    },
    tag: {
      type: String,
      default: 'div'
    },
    prefix: {
      type: String,
      default: ''
    },
    suffix: {
      type: String,
      default: ''
    }
  },
  computed: {
    structureName () {
      if (!this.item?.name) {
        console.log(this.item.entity?.value)
        const { label, value } = this.item.entity
        if (value) {
          return `${label || value} ${this.item[value].name}`
        }
        return 'Без названия'
      }
      return this.item?.name
    }
  }
}
</script>
