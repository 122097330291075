<template>
  <div class="an-gallery-slider">
    <div
      v-if="Array.isArray(viewImages) && viewImages.length > 0"
      class="swiper-container"
      :class="uniqName"
    >
      <!-- Кол-во изображений -->
      <div
        class="an-gallery-slider--images-count tw-flex tw-items-center tw-gap-[9px]"
        :style="{ top: '26px', left: '26px' }"
      >
        <div>{{ viewImages.length }}</div>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.00052 10.1334C9.17873 10.1334 10.1339 9.17824 10.1339 8.00003C10.1339 6.82182 9.17873 5.8667 8.00052 5.8667C6.82231 5.8667 5.86719 6.82182 5.86719 8.00003C5.86719 9.17824 6.82231 10.1334 8.00052 10.1334Z"
            fill="white"
          />
          <path
            d="M6.00065 1.33325L4.78065 2.66659H2.66732C1.93398 2.66659 1.33398 3.26659 1.33398 3.99992V11.9999C1.33398 12.7333 1.93398 13.3333 2.66732 13.3333H13.334C14.0673 13.3333 14.6673 12.7333 14.6673 11.9999V3.99992C14.6673 3.26659 14.0673 2.66659 13.334 2.66659H11.2207L10.0007 1.33325H6.00065ZM8.00065 11.3333C6.16065 11.3333 4.66732 9.83992 4.66732 7.99992C4.66732 6.15992 6.16065 4.66659 8.00065 4.66659C9.84065 4.66659 11.334 6.15992 11.334 7.99992C11.334 9.83992 9.84065 11.3333 8.00065 11.3333Z"
            fill="white"
          />
        </svg>
      </div>
      <!-- IMAGES -->
      <div
        class="swiper-wrapper an-gallery-slider-block"
        data-selector=".an-gallery-slider-item"
      >
        <template v-for="(img, index) in viewImages">
          <div
            :key="index + '-gallery'"
            class="swiper-slide an-gallery-slider-item"
            :data-src="img.original || img.fullVideo"
            @click="openImageViewer(index)"
          >
            <!-- Фон - размытое превью-->
            <div
              :style="{ backgroundImage: `url(${img.thumb || img.original})` }"
              class="an-gallery-slider-item--bg"
            />
            <!-- Само изображение - вписанное в блок -->
            <div
              :style="{ backgroundImage: `url(${img.original || img.thumb})` }"
              class="an-gallery-slider-item--image"
            />
            <div
              v-if="img.label"
              class="tw-absolute  tw-top-[1em] tw-right-[1em] tw-z-10"
            >
              <TwBadgeLabel
                :label="img.label"
                rounded="full"
                color="blue"
                size="sm"
              />
            </div>
            <!-- Иконка запуска плеера для видео -->
            <div
              v-if="img.fullVideo"
              class="an-gallery-slider-item--videoplay"
            >
              <img alt="object-gallery-play-video">
            </div>
          </div>
        </template>
      </div>
      <!-- MANAGE -->
      <div
        v-show="showPagination"
        class="swiper-pagination"
      />
      <div
        v-show="showNavigation"
        class="swiper-button-next"
      />
      <div
        v-show="showNavigation"
        class="swiper-button-prev"
      />
    </div>
    <template v-else>
      <div
        style="height: 450px"
        class="slider-none-image"
      />
    </template>
    <!--  THUMBS  -->
    <div
      v-if="!hideThumbs && Array.isArray(viewImages) && viewImages.length > 0"
      class="an-thumbs-list tw-grid tw-grid-cols-[repeat(auto-fill,98px)]"
    >
      <template v-for="(img, index) in viewImages">
        <div
          :key="index + '-thumbs'"
          class="an-thumbs-list__item"
          :style="{ backgroundImage: `url(${img.thumb})` }"
          :class="activeIndex === index ? 'active' : ''"
          @click="slideTo(index)"
        />
      </template>
    </div>
  </div>
</template>

<script>
import SwiperMixin from '@/mixins/SwiperMixin'
import TwBadgeLabel from '@/components/tw-ui/ui-kit/views/TwBadgeLabel.vue'

export default {
  name: 'TwImagesGallery',
  mixins: [SwiperMixin],
  components: { TwBadgeLabel },
  props: {
    images: {
      type: Array,
      default: () => []
    },
    hideThumbs: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      activeIndex: 0
    }
  },
  computed: {
    viewImages () {
      return this.images.map((img) => {
        // Для видео с ютуба гененрирую ссылки
        if (img.youtube_code) {
          img.thumb = `https://img.youtube.com/vi/${img.youtube_code}/0.jpg`
          img.fullVideo = `https://www.youtube.com/watch?v=${img.youtube_code}?autoplay=1`
        }
        return img
      })
    }
  },
  methods: {
    openImageViewer (startIndex) {
      this.$store.dispatch('imageViewer/showImages', {
        files: this.viewImages?.map((img) => {
          return {
            path: img.original
          }
        }),
        startIndex: startIndex
      })
    }
  }
}
</script>

<style lang="scss">
.an-gallery-slider {
  position: relative;
  $nav-btn-size: 36px;
  .swiper-button-next {
    border: 1px solid rgba(0, 0, 0, 0.1);
    bottom: 36px;
    top: unset;
    right: 26px;
    left: auto;
    background: white;
    width: $nav-btn-size;
    height: $nav-btn-size;
    border-radius: 50%;
    transition: all .15s ease-in;
    &:hover {
      border-color: red;
    }
    &:hover:after {
      color: red;
    }
    &:active {
      border-color: red;
      background: red;
    }
    &:active:after {
      color: white;
    }
    &:disabled {
      opacity: 50;
    }
    &:disabled:after {
      opacity: 50;
    }
    &:after {
      transition: all .15s ease-in;
      color: #424242;
      font-size: 15px;
      font-weight: 800;
      margin-top: 1px;
      margin-left: 3px;
    }
  }
  .swiper-button-prev {
    border: 1px solid rgba(0, 0, 0, 0.1);
    position: absolute;
    bottom: 36px;
    top: unset;
    right: 66px;
    left: auto;
    background: white;
    width: $nav-btn-size;
    height: $nav-btn-size;
    border-radius: 50%;
    transition: all .15s ease-in;
    &:hover {
      border-color: red;
    }
    &:hover:after {
      color: red;
    }
    &:active {
      border-color: red;
      background: red;
    }
    &:active:after {
      color: white;
    }
    &:disabled {
      opacity: 50;
    }
    &:disabled:after {
      opacity: 50;
    }
    &:after {
      transition: all .15s ease-in;
      color: #424242;
      font-size: 15px;
      font-weight: 800;
      margin-top: 1px;
      margin-right: 3px;
    }
  }
  .swiper-button-disabled {
    user-select: none;
    background-color: rgba(255, 255, 255, 0.7);
    &:after {
      opacity: 1;
    }
  }
  .swiper-pagination {
    position: relative;
    padding-top: 20px;
    bottom: 0;
    .swiper-pagination-bullet {
      background-color: red;
    }
  }

  &--images-count {
    user-select: none;
    position: absolute;
    z-index: 2;
    background: rgb(0 0 0 / 30%);
    transition: 0.3s ease all;
    color: white;
    margin: 0;
    padding: 7px 10px;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    cursor:pointer;
  }
  &--plan-button {
    user-select: none;
    position: absolute;
    z-index: 2;
    background: rgb(0 0 0 / 30%);
    border-radius: 50%;
    transition: 0.3s ease all;
    border: 1px solid rgb(255 255 255 / 20%);
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
    }
    >img {
      position: absolute;
      bottom: 50%;
      right: 50%;
      transform: translate(50%, 50%);
    }
  }
  .an-gallery-slider-block {
    position: relative;
    margin-bottom: 20px;

    .swiper-pagination {
      position: relative;
      padding-top: 20px;
      bottom: 0;
      .swiper-pagination-bullet {
        background-color: red;
      }
    }

    &.in-mobile {
      border: none;
      .an-gallery-slider-item {
        min-height: 250px;
        border-radius: 20px;
        overflow: hidden;
      }
    }
  }
  .an-thumbs-list {
    //@include gridContainer(repeat(auto-fill, 98px));
    justify-items: center;
    justify-content: space-between;
    column-gap: 6px;
    row-gap: 12px;
    &__item {
      width: 100px;
      height: 56px;
      background-color: lightgrey;
      border-radius: 4px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      border: 1px solid transparent;
      transition: all .15s ease-in;
      &:not(.active) {
        cursor: pointer;
        opacity: 0.5;
        &:hover {
          border-color: green;
          opacity: 0.7;
        }
      }
    }
  }
  .an-thumb-slider-block {
    height: 70px;
    margin-top: 20px;
  }
  .an-gallery-slider-item {
    position: relative;
    min-height: 425px;
    cursor: pointer;
    border-radius: 20px;
    overflow: hidden;
    &--bg {
      overflow: hidden;
      z-index: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      filter: blur(15px);
      opacity: 0.5;
    }
    &--image {
      //backdrop-filter: blur(10px);
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: transparent;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
    &--videoplay {
      //backdrop-filter: blur(10px);
      z-index: 2;
      position: absolute;
      width: 7vw;
      top: 50%;  /* position the top  edge of the element at the middle of the parent */
      left: 50%; /* position the left edge of the element at the middle of the parent */
      transform: translate(-50%, -50%); /* This is a shorthand of
                                         translateX(-50%) and translateY(-50%) */
    }
  }
  .slider-none-image {
    background-image: url("@/assets/images/no_image.svg");
    background-position: center;
    background-size: cover;
    max-width: 676px;
    min-width: 95%;
    //height: 450px;
    height: 328px;
    border-radius: 12px;
  }
}
</style>
