import { RouteKeys } from '@/router/types'
import dictPermissionRules from '@/Permissions/dictRules'

export default {
  path: RouteKeys.dealResultManagement,
  name: RouteKeys.dealResultManagement,
  redirect: { name: `${RouteKeys.dealResultManagement}-list` },
  meta: { name: 'Проверка сделок', icon: 'checking-transactions', ruleNamespace: dictPermissionRules.AppServicePermissionRulesCRMDealsNbDealNbResultManagementRule },
  component: () => import('@/views/ObjectStorage/Index.vue'),
  children: [
    {
      path: 'list',
      name: `${RouteKeys.dealResultManagement}-list`,
      meta: { name: 'Проверка сделок', icon: 'home', hideInMenu: true },
      component: () => import('@/views/Newbuildings/ResultManagement/List.vue')
    }
  ]
}
