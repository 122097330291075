import { IOptions } from '@/store/intro-teach/types'

const options: IOptions = {
  nextLabel: 'Далее',
  prevLabel: 'Назад',
  doneLabel: 'Закрыть',
  showBullets: false,
  tooltipClass: 'intro-custom-tooltip',
  steps: [
    {
      title: 'Запрещено',
      intro: 'Редактирование контакта разрешено только сразу после создания'
    }
  ]
}

export default options
