import Vue from 'vue'
import { Module } from 'vuex'
import { RootState } from '@/store/types'
import { TmpCacheState } from './types'
import uiListAPI from '@/Api/Crm/UI/List'
import leadStatusAPI from '@/Api/Crm/Lead/Status'
import dealStatusAPI from '@/Api/Crm/Deal/Status'
import personAPI from '@/Api/Crm/Person'
import companyAPI from '@/Api/Crm/Company'
import store from '../../store/index'
import { fias } from './modules/fias'
import { realty } from './modules/realty'
import { auth } from './modules/auth'
import { telephony } from './modules/telephony'

const namespaced = true

export const tmpCache: Module<TmpCacheState, RootState> = {
  namespaced,
  state: {
    leadStatus: {},
    dealStatus: {},
    person: {},
    company: {}
  },
  actions: {
    // Статусы лидов
    fetchLeadStatusList: function ({ commit }) {
      return uiListAPI.leadInterfaceStatus()
        .then(({ data }) => {
          // console.log('fetchLeadStatusList', data.data)
          commit('SET_LEAD_STATUS', data.data)
          return data?.data || []
        })
        .catch((error) => {
          console.error(error)
        })
    },
    fetchLeadStatusById: function ({ commit }, id: number | string) {
      return leadStatusAPI.show(id)
        .then(({ data }) => {
          // console.log('fetchLeadStatusById', data.data)
          commit('SET_LEAD_STATUS', data.data)
        })
        .catch((error) => {
          console.error(error)
        })
    },
    // Статусы сделок
    fetchDealStatusList: function ({ commit }) {
      return uiListAPI.dealInterfaceStatus()
        .then(({ data }) => {
          // console.log('fetchDealStatusList', data.data)
          commit('SET_DEAL_STATUS', data.data)
          return data?.data || []
        })
        .catch((error) => {
          console.error(error)
        })
    },
    fetchDealStatusById: function ({ commit }, id: number | string) {
      return dealStatusAPI.show(id)
        .then(({ data }) => {
          // console.log('fetchDealStatusById', data.data)
          commit('SET_DEAL_STATUS', data.data)
        })
        .catch((error) => {
          console.error(error)
        })
    },
    // Персоны
    fetchPersonList: function ({ commit }, search: string) {
      // TODO Используется в старом компоненты выбора - который используется в фильтрах 2й версии
      return personAPI.getList({ search, limit: 150 })
        .then(({ data }) => {
          // console.log('fetchPersonList', data.data)
          commit('SET_PERSON', data.data)
          return data?.data || []
        })
        .catch((error) => {
          console.error(error)
        })
    },
    fetchPersonById: function ({ commit }, id: number | string, isFull = false) {
      commit('SET_PERSON', { id })
      if (isFull) {
        return personAPI.show(id)
          .then(({ data }) => {
            // console.log('fetchPersonById', data.data)
            commit('SET_PERSON', data.data)
            return data.data
          })
          .catch((error) => {
            console.error(error)
          })
      }
      return personAPI.showShort(id)
        .then(({ data }) => {
          // console.log('fetchPersonById', data.data)
          commit('SET_PERSON', data.data)
          return data.data
        })
        .catch((error) => {
          console.error(error)
        })
    },
    // Компании
    fetchCompanyList: function ({ commit }, params: any) {
      return companyAPI.getList(params)
        .then(({ data }) => {
          // console.log('fetchCompanyList', data.data)
          commit('SET_COMPANY', data.data)
          return data?.data || []
        })
        .catch((error) => {
          console.error(error)
        })
    },
    fetchCompanyById: function ({ commit }, id: number | string) {
      commit('SET_COMPANY', { id })
      return companyAPI.show(id)
        .then(({ data }) => {
          // console.log('fetchCompanyById', data.data)
          commit('SET_COMPANY', data.data)
        })
        .catch((error) => {
          console.error(error)
        })
    }
  },
  mutations: {
    SET_LEAD_STATUS (state, payload) {
      // console.log('SET_LEAD_STATUS', payload)
      // параметр времени запроса
      const reqTime = new Date().getTime()
      if (Array.isArray(payload)) {
        payload.forEach((item) => {
          item.rt = reqTime
          Vue.set(state.leadStatus, item.id, item)
        })
      } else if (payload.id) {
        payload.rt = reqTime
        Vue.set(state.leadStatus, payload.id, payload)
      } else {
        console.error('Необработанное условие при попытке добавить leadStatus в tmpCache')
      }
    },
    SET_DEAL_STATUS (state, payload) {
      // console.log('SET_DEAL_STATUS', payload)
      // параметр времени запроса
      const reqTime = new Date().getTime()
      if (Array.isArray(payload)) {
        payload.forEach((item) => {
          item.rt = reqTime
          Vue.set(state.dealStatus, item.id, item)
        })
      } else if (payload.id) {
        payload.rt = reqTime
        Vue.set(state.dealStatus, payload.id, payload)
      } else {
        console.error('Необработанное условие при попытке добавить dealStatus в tmpCache')
      }
    },
    SET_PERSON (state, payload) {
      // console.log('SET_PERSON', payload)
      // параметр времени запроса
      const reqTime = new Date().getTime()
      if (Array.isArray(payload)) {
        payload.forEach((item) => {
          item.rt = reqTime
          Vue.set(state.person, item.id, item)
        })
      } else if (payload.id) {
        payload.rt = reqTime
        Vue.set(state.person, payload.id, payload)
      } else {
        console.error('Необработанное условие при попытке добавить Person в tmpCache')
      }
    },
    SET_COMPANY (state, payload) {
      // console.log('SET_COMPANY', payload)
      // параметр времени запроса
      const reqTime = new Date().getTime()
      if (Array.isArray(payload)) {
        payload.forEach((item) => {
          item.rt = reqTime
          Vue.set(state.company, item.id, item)
        })
      } else if (payload.id) {
        payload.rt = reqTime
        Vue.set(state.company, payload.id, payload)
      } else {
        console.error('Необработанное условие при попытке добавить Company в tmpCache')
      }
    }
  },
  getters: {
    getLeadStatusById: (state) => (id: number) => {
      if (state.leadStatus[id] === undefined) {
        store.dispatch('tmpCache/fetchLeadStatusById', id)
      }
      return state.leadStatus[id] || null
    },
    cachedLeadStatusList: (state) => {
      return Object.values(state.leadStatus) || []
    },
    getDealStatusById: (state) => (id: number) => {
      if (state.dealStatus[id] === undefined) {
        store.dispatch('tmpCache/fetchDealStatusById', id)
      }
      return state.dealStatus[id] || null
    },
    cachedDealStatusList: (state) => {
      return Object.values(state.dealStatus) || []
    },
    getPersonById: (state) => (id: number) => {
      if (state.person[id] === undefined) {
        store.dispatch('tmpCache/fetchPersonById', id)
      }
      return state.person[id] || null
    },
    cachedPersonList: (state) => {
      return Object.values(state.person) || []
    },
    getCompanyById: (state) => (id: number) => {
      if (state.company[id] === undefined) {
        store.dispatch('tmpCache/fetchCompanyById', id)
      }
      return state.company[id] || null
    },
    cachedCompanyList: (state) => {
      return Object.values(state.company) || []
    }
  },
  modules: {
    auth,
    fias,
    telephony,
    realty
  }
}
