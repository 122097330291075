export default {
  data () {
    return {
      pinningProcess: false,
      API: null
    }
  },
  methods: {
    doneChange (attachEntityData) {
      if (this.pinningProcess) return
      const { id, done } = attachEntityData || {}
      if (id) {
        this.pinningProcess = true
        this.API.update(id, {
          done: !done
        })
          .then(() => {
            this.$emit('refresh')
            this.done = true
          })
          .catch((error) => {
            console.error('pindone', error)
          })
          .finally(() => {
            this.pinningProcess = false
          })
      } else {
        console.error(this.$options.name, 'ERROR ID', attachEntityData)
      }
    }
  }
}
