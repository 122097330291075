<template>
  <div
    :class="horizontal ? ' tw-flex tw-flex-wrap tw-gap-x-[15px] tw-gap-y-[5px]':''"
    class="tw-font-manrope tw-text-main-sm2"
  >
    <div
      :class="horizontal ? 'tw-w-[140px] 2xl:tw-w-[220px]':'tw-mb-[5px]'"
      class="tw-text-an-flat-not-active-input tw-flex tw-gap-x-[15px] tw-gap-y-[5px] tw-flex-wrap tw-justify-between"
    >
      <slot name="title" />
      <slot name="subtitle" />
    </div>
    <div
      class="tw-flex-1 tw-text-main-sm tw-font-normal"
      :class="[contentWidthClass]"
    >
      <div
        v-if="$slots.default"
        style="word-wrap: break-word;"
      >
        <slot>Содержимое</slot>
      </div>
      <div
        v-else
        class="tw-text-an-flat-disabled-text"
      >
        {{ noDataText }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TwPanelContentBlockFieldView',
  props: {
    horizontal: {
      type: Boolean,
      default: true
    },
    noDataText: {
      type: String,
      default: 'Не указано'
    },
    contentWidthClass: {
      type: String,
      default: 'tw-min-w-[240px]'
    }
  }
}
</script>
