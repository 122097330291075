<template>
  <div
    class="swiper-container"
    :class="uniqName"
  >
    <div class="swiper-wrapper">
      <slot name="slides" />
    </div>
    <div
      v-show="showPagination"
      class="swiper-pagination"
    />
    <div
      v-show="showNavigation"
      class="swiper-button-next"
    />
    <div
      v-show="showNavigation"
      class="swiper-button-prev"
    />
  </div>
</template>

<script>
import SwiperMixin from '@/mixins/SwiperMixin'

export default {
  name: 'ImageGallery',
  mixins: [SwiperMixin]
}
</script>

<style scoped>
.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-slide {
  display: flex;
}
.swiper-slide :first-child {
  margin: auto;
}

.swiper-wrapper {
  display: flex;
  align-items: center;
}
.swiper-button-next, .swiper-button-prev{
  height: 100%;
  top: 22px;
  width: 50px;
}
.swiper-button-next:hover, .swiper-button-prev:hover{
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
}
.swiper-button-prev{
  left: 0;
}
.swiper-button-next{
  right: 0;
}
</style>
