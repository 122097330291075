<template>
  <div
    v-if="info"
    class="tw-flex tw-gap-[12px] tw-flex-col"
  >
    <TwPanelContentBlockFieldView>
      <template #title>
        Статус
      </template>
      <div v-if="info.status">
        {{ info.status.label }}
      </div>
      <div v-else>
        отсутствует
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Программа
      </template>
      <div v-if="info.program">
        <ViewPanelLink
          :id="info.program.id"
          label="Программа"
          component="mortgage-program-show"
          :callback="refresh"
        >
          {{ info.program.name }}
        </ViewPanelLink>
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Банк
      </template>
      <div v-if="info.program && info.program.company">
        <ViewPanelLink
          :id="info.program.company.id"
          label="Компания"
          component="company-show"
          :callback="refresh"
        >
          {{ info.program.company.name }}
        </ViewPanelLink>
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Клиент
      </template>
      <div v-if="additionalData.person">
        <ViewPanelLink
          :id="additionalData.person.id"
          label="Контакт"
          component="person-show"
          :callback="refresh"
        >
          {{ $Global.peopleName(additionalData.person, 'fi') }}
        </ViewPanelLink>
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Сотрудник банка
      </template>
      <div
        :class="[info.contact ? '' : 'tw-bg-an-flat-red-main/20 tw-p-[8px]']"
        class="tw-rounded-sm"
      >
        <div class="">
          <div class="tw-mb-[8px]">
            <small class="tw-text-an-flat-gray-4">Контакт</small>
            <div v-if="info.contact && info.contact.person">
              <ViewPanelLink
                :id="info.contact.person.id"
                label="Контакт"
                component="person-show"
                :callback="refresh"
              >
                {{ $Global.peopleName(info.contact.person, 'fi') }}
              </ViewPanelLink>
            </div>
            <div
              v-else
              class="tw-text-main-sm2"
            >
              отсутствует
            </div>
          </div>
          <div class="">
            <small class="tw-text-an-flat-gray-4">Email для писем</small>
            <div v-if="info.contact && info.contact.contact">
              {{ info.contact.contact }}
            </div>
            <div
              v-else
              class="tw-text-main-sm2"
            >
              отсутствует
            </div>
          </div>
        </div>
        <div
          v-if="!info.contact"
          class="tw-mt-[6px] tw-text-main-xs tw-text-an-flat-red-main"
        >
          * Выберите контакт сотрудника банка для отправки писем
        </div>
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Комментарий
      </template>
      <div v-if="info.comment">
        <span v-html="$Global.nl2br(info.comment)" />
      </div>
    </TwPanelContentBlockFieldView>
  </div>
</template>

<script>
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'

export default {
  name: 'MortgageProgramWorksheetMainShow',
  components: { TwPanelContentBlockFieldView, ViewPanelLink },
  mixins: [ViewPanelShowMixin],
  props: {
    info: {},
    additionalData: {},
    dictionary: {}
  },
  methods: {
    refresh () {
      this.$emit('refresh')
    }
  }
}
</script>
