<template>
  <PageViewDefaultWrapper
    ref="wrapper"
    header-class="mb-2"
    :permission-denied="permissionDenied"
    relative
  >
    <template>
      <TwTableBase
        schema-key="BuilderSiteLogList"
        tbody-classes="tw-text-xs"
        :items="data"
        :headers="headers"
        :table-fixed="false"
        :loading="loading"
        :pagination="pagination"
        :fixed-header-margin="0"
        :table-columns-management="false"
        @headersUpdate="headers = $event"
        @changePagination="changePagination"
      >
        <template #item.createdAt="{ item }">
          {{ $Global.DateFormat.DateTimeShort(item.createdAt) || '~' }}
        </template>
        <template #item.error="{ item }">
          <p style="color: red">
            {{ item.error }}
          </p>
        </template>
        <template #item.result="{ item }">
          <p :style="item.result === 'success' ? 'color: green' : 'color: red'">
            {{ item.result }}
          </p>
        </template>
      </TwTableBase>
    </template>
  </PageViewDefaultWrapper>
</template>

<script>
import { API } from '@/Api/BuilderImporter'
import PageViewDefaultWrapper from '@/components/core/ViewDefault/PageViewDefaultWrapper.vue'
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import TwTableBase from '@/components/tw-ui/table/TwTableBase.vue'
import headers from './tableOptions'

export default {
  name: 'BuilderSiteLogList',
  components: { PageViewDefaultWrapper, TwTableBase },
  mixins: [ViewPanelShowMixin],
  props: {
    id: {
      type: [Number, null],
      default: null
    }
  },
  data () {
    return {
      API: API.Log,
      defaultFilters: {
        source: 'site',
        sourceId: this.id
      },
      loading: false,
      permissionDenied: false,

      data: [],
      pagination: {},
      itemsPerPage: 25,
      headers
    }
  },
  methods: {
    changePagination (eventData) {
      this.fetch({
        ...eventData
      })
    },
    fetch (params = {}) {
      if (this.loading) {
        return
      }

      if (!this.API) {
        throw Error('not set API')
      }

      this.permissionDenied = false
      this.loading = true
      // eslint-disable-next-line no-param-reassign
      params = { ...params, ...this.defaultFilters }

      this.API.getList(params)
        .then((response) => {
          this.data = response.data.data
          if (response.data.meta) {
            this.pagination = response.data.meta
            this.itemsPerPage = response.data.meta.per_page || 25
          }
        })
        .catch((error) => {
          // обработка отсутствия прав на просмотр
          if (error?.response?.status === 403) {
            this.permissionDenied = true
          }
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  mounted () {
    this.fetch()
  }
}
</script>
