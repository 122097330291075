import { TableTypes } from '@/components/tw-ui/table/TableTypes'

const options: TableTypes = [

  {
    dataKey: 'emailFolder.createdAt',
    value: 'emailFolder.createdAt',
    colStyle: '',
    text: 'Дата',
    thClass: 'tw-text-main-sm3 tw-text-left tw-text-[12px] tw-font-semibold tw-text-an-flat-blue-light-2 tw-whitespace-nowrap tw-px-[12px] tw-pt-[16px] tw-pb-[12px] tw-bg-white',
    tdClass: 'tw-text-center tw-text-an-flat-gray-4 tw-border-t tw-border-opacity tw-p-[12px] tw-whitespace-nowrap'
  },
  {
    dataKey: 'emailConfigContact',
    value: 'emailConfigContact',
    colStyle: '',
    text: 'От кого',
    thClass: 'tw-text-main-sm3 tw-text-left tw-text-[12px] tw-font-semibold tw-text-an-flat-blue-light-2 tw-whitespace-nowrap tw-px-[12px] tw-pt-[16px] tw-pb-[12px] tw-bg-white',
    tdClass: 'tw-text-center tw-border-t tw-border-opacity tw-p-[12px] tw-whitespace-nowrap'
  },
  {
    dataKey: 'contacts',
    value: 'contacts',
    colStyle: '',
    text: 'Кому',
    thClass: 'tw-text-main-sm3 tw-text-left tw-text-[12px] tw-font-semibold tw-text-an-flat-blue-light-2 tw-whitespace-nowrap tw-px-[12px] tw-pt-[16px] tw-pb-[12px] tw-bg-white',
    tdClass: 'tw-text-center tw-border-t tw-border-opacity tw-p-[12px] tw-whitespace-nowrap'
  },
  {
    dataKey: 'theme',
    value: 'theme',
    colStyle: '',
    text: 'Тема',
    thClass: 'tw-text-main-sm3 tw-text-left tw-text-[12px] tw-font-semibold tw-text-an-flat-blue-light-2 tw-whitespace-nowrap tw-px-[12px] tw-pt-[16px] tw-pb-[12px] tw-py-[8px] tw-bg-white',
    tdClass: 'tw-text-left tw-text-an-flat-gray-4 tw-border-t tw-border-opacity tw-p-[12px] tw-whitespace-nowrap'
  },
  {
    dataKey: 'status.label',
    value: 'status.label',
    colStyle: '',
    text: 'Статус',
    thClass: 'tw-text-main-sm3 tw-text-left tw-text-[12px] tw-font-semibold tw-text-an-flat-blue-light-2 tw-whitespace-nowrap tw-px-[12px] tw-pt-[16px] tw-pb-[12px] tw-py-[8px] tw-bg-white',
    tdClass: 'tw-text-left tw-border-t tw-border-opacity tw-p-[12px] tw-whitespace-nowrap'
  }
]

export default options
