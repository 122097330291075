<template>
  <TwFlexCol v-if="info">
    <TwFlexCell>
      <TwPanelContentBlockFieldView>
        <template #title>
          Логотип
        </template>
        <div
          v-if="info.logoFile"
        >
          <img
            alt="logo"
            style="width: 150px; height: auto"
            :src="info.logoFile.url"
            @click="viewImage(info.logoFile.url)"
          >
        </div>
      </TwPanelContentBlockFieldView>
    </TwFlexCell>
    <TwFlexCell>
      <TwPanelContentBlockFieldView>
        <template #title>
          Название
        </template>
        <div v-if="info.name">
          {{ info.name }}
        </div>
      </TwPanelContentBlockFieldView>
    </TwFlexCell>
    <TwFlexCell>
      <TwPanelContentBlockFieldView>
        <template #title>
          О компании
        </template>
        <div v-if="info.description">
          {{ info.description }}
        </div>
      </TwPanelContentBlockFieldView>
    </TwFlexCell>
    <TwFlexCell>
      <TwPanelContentBlockFieldView>
        <template #title>
          Код
        </template>
        <a
          v-if="info.code"
          target="_blank"
          :href="getDeveloperSitePublicUrl(info.code)"
        >
          {{ info.code }}
        </a>
      </TwPanelContentBlockFieldView>
    </TwFlexCell>
    <TwFlexCell>
      <TwPanelContentBlockFieldView>
        <template #title>
          Дата основания
        </template>
        <div v-if="info.dateFoundation">
          {{ info.dateFoundation }}
        </div>
      </TwPanelContentBlockFieldView>
    </TwFlexCell>
    <TwFlexCell v-if="info.videoLink">
      <TwPanelContentBlockFieldView>
        <template #title>
          Видео презентация
        </template>
        <a
          :href="info.videoLink"
          target="_blank"
        >{{ info.videoLink }}</a>
      </TwPanelContentBlockFieldView>
    </TwFlexCell>
    <TwFlexCell>
      <TwPanelContentBlockFieldView>
        <template #title>
          Скрывать цену
        </template>
        {{ info.hidePrice ? 'Да' : 'Нет' }}
      </TwPanelContentBlockFieldView>
    </TwFlexCell>
    <TwFlexCell>
      <TwPanelContentBlockFieldView>
        <template #title>
          Приоритет
        </template>
        {{ info.priority ? 'Да' : 'Нет' }}
      </TwPanelContentBlockFieldView>
    </TwFlexCell>
    <TwFlexCell>
      <TwPanelContentBlockFieldView>
        <template #title>
          Активный
        </template>
        {{ info.active ? 'Да' : 'Нет' }}
      </TwPanelContentBlockFieldView>
    </TwFlexCell>
  </TwFlexCol>
</template>

<script>
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import { SERVICES } from '@/Api/Services'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'
import TwFlexCell from '@/components/tw-ui/ui-kit/flex-block/TwFlexCell.vue'
import TwFlexCol from '@/components/tw-ui/ui-kit/flex-block/TwFlexCol.vue'

export default {
  name: 'RealtyBuilderMainShow',
  components: {
    TwFlexCell,
    TwFlexCol,
    TwPanelContentBlockFieldView
  },
  mixins: [ViewPanelShowMixin],
  props: {
    info: {}
  },
  methods: {
    getDeveloperSitePublicUrl (code) {
      return `${SERVICES.SITE.host}/developer/${code}/`
    },
    viewImage (url) {
      this.$store.dispatch('imageViewer/showImages', {
        files: [
          {
            path: url
          }
        ],
        startIndex: 0
      })
    }
  }
}
</script>
