import { AxiosResponse } from 'axios'
import HTTP, { downloadFile } from '../../http'
import { AuthAxiosDefaultConfig } from '@/Api/Support'

const path = '/file'

export default {
  upload (data: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig,
      headers: {
        'Content-Type': 'multipart/form-data;charset=utf-8'
      }
    }
    return HTTP.post(`${path}`, data, $config)
  },
  show (id: number): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.get(`${path}/${id}`, $config)
  },
  download (url: string, fileName: string) {
    downloadFile(url, fileName)
  }
}
