import { AxiosResponse } from 'axios'
import HTTP from '@/Api/http'

const path = '/mortgage/domclick/deals'

export default {
  validate (id: number): Promise<AxiosResponse> {
    return HTTP.get(`${path}/${id}/validate`)
  },
  send (id: number): Promise<AxiosResponse> {
    return HTTP.post(`${path}/${id}/send`)
  },
  offices (id: number): Promise<AxiosResponse> {
    return HTTP.get(`${path}/${id}/offices`)
  },
  acceptances (id: number): Promise<AxiosResponse> {
    return HTTP.post(`${path}/${id}/acceptances`)
  },
  show (id: number): Promise<AxiosResponse> {
    return HTTP.get(`${path}/${id}`)
  },
  // Создаю в связке с ID программы нашей CRM
  store (dealProgramId: number, data: object): Promise<AxiosResponse> {
    return HTTP.post(`${path}/${dealProgramId}`, data)
  },
  // Изменяю уже на основе выданного после создания ID
  update (id: number, data: object): Promise<AxiosResponse> {
    return HTTP.put(`${path}/${id}`, data)
  },
  delete (id: number): Promise<AxiosResponse> {
    const $config = {}
    return HTTP.delete(`${path}/${id}`, $config)
  },
  // Роуты для работы с анкетами заявки
  applicationCreate (dealId: number, data: object): Promise<AxiosResponse> {
    return HTTP.post(`${path}/${dealId}/applications`, data)
  },
  applicationUpdate (dealId: number, applicationId: number, data: object): Promise<AxiosResponse> {
    return HTTP.put(`${path}/${dealId}/applications/${applicationId}`, data)
  },
  applicationDelete (dealId: number, applicationId: number): Promise<AxiosResponse> {
    const $config = {}
    return HTTP.delete(`${path}/${dealId}/applications/${applicationId}`, $config)
  },
  applicationAcceptance (dealId: number, applicationId: number, data: object): Promise<AxiosResponse> {
    return HTTP.put(`${path}/${dealId}/applications/${applicationId}/acceptance`, data)
  },
  getDebugAcceptanceCode (phone: string): Promise<AxiosResponse> {
    const $config: any = {
      params: {
        phone
      }
    }
    return HTTP.get('/mortgage/domclick/acceptanceCodeForTestServer', $config)
  },
  // Роуты для работы с документами анкеты
  applicationDocCreate (dealId: number, applicationId: number, data: object): Promise<AxiosResponse> {
    return HTTP.post(`${path}/${dealId}/applications/${applicationId}/documents`, data)
  },
  applicationDocUpdate (dealId: number, applicationId: number, documentId: number, data: object): Promise<AxiosResponse> {
    return HTTP.put(`${path}/${dealId}/applications/${applicationId}/documents/${documentId}`, data)
  },
  applicationDocDelete (dealId: number, applicationId: number, documentId: number): Promise<AxiosResponse> {
    const $config = {}
    return HTTP.delete(`${path}/${dealId}/applications/${applicationId}/documents/${documentId}`, $config)
  }
}
