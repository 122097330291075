<template>
  <div
    v-if="user"
    class="tw-flex tw-items-center tw-pt-[8px] tw-pb-[8px] tw-pr-[15px] tw-pl-[15px] tw-bg-an-flat-gray-15/60 tw-rounded-[5px]"
  >
    <div
      v-if="hasAvatar"
      class="tw-mr-[9px] tw-h-[35px] tw-w-[35px] tw-rounded-full tw-bg-gray-50 tw-border-solid tw-border tw-border-white tw-overflow-hidden"
    >
      <img
        v-if="avatar"
        :src="avatar"
        alt="Авартар"
      >
      <div
        v-else
        class="tw-flex tw-items-center tw-justify-center tw-w-full tw-h-full"
      >
        <svg
          class="tw-mx-auto"
          width="22"
          height="28"
          viewBox="0 0 22 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.181 14.1717C16.8473 13.5762 17.3137 12.7982 17.5191 11.9396C17.7244 11.081 17.6591 10.1819 17.3317 9.36017C17.0044 8.53842 16.4302 7.83236 15.6844 7.33452C14.9387 6.83669 14.0562 6.57031 13.1527 6.57031C12.2491 6.57031 11.3666 6.83669 10.6209 7.33452C9.87519 7.83236 9.30101 8.53842 8.97363 9.36017C8.64626 10.1819 8.58097 11.081 8.78631 11.9396C8.99166 12.7982 9.45807 13.5762 10.1244 14.1717C8.63599 14.7608 7.36133 15.7695 6.4632 17.0688C5.56506 18.3682 5.0842 19.8993 5.08203 21.4665C5.08203 21.6986 5.17651 21.9212 5.34468 22.0853C5.51285 22.2494 5.74094 22.3415 5.97877 22.3415H20.3266C20.5644 22.3415 20.7925 22.2494 20.9607 22.0853C21.1289 21.9212 21.2233 21.6986 21.2233 21.4665C21.2212 19.8993 20.7403 18.3682 19.8422 17.0688C18.944 15.7695 17.6694 14.7608 16.181 14.1717ZM10.4625 10.9665C10.4625 10.4474 10.6202 9.93985 10.9158 9.50817C11.2115 9.07649 11.6316 8.74003 12.1232 8.54135C12.6148 8.34267 13.1557 8.29069 13.6775 8.39198C14.1994 8.49326 14.6787 8.74327 15.055 9.11038C15.4312 9.4775 15.6874 9.94523 15.7912 10.4544C15.895 10.9636 15.8417 11.4914 15.6381 11.9711C15.4345 12.4507 15.0897 12.8607 14.6473 13.1491C14.2049 13.4376 13.6848 13.5915 13.1527 13.5915C12.4392 13.5915 11.7549 13.315 11.2504 12.8227C10.7459 12.3304 10.4625 11.6627 10.4625 10.9665ZM6.93918 20.5915C7.15374 19.1328 7.90012 17.7984 9.04141 16.8332C10.1827 15.868 11.6424 15.3367 13.1527 15.3367C14.663 15.3367 16.1227 15.868 17.264 16.8332C18.4052 17.7984 19.1516 19.1328 19.3662 20.5915H6.93918Z"
            fill="#A2ABB5"
          />
          <path
            d="M6.61389 9.24397C6.73382 9.23297 6.85025 9.19851 6.95623 9.14264C7.06221 9.08677 7.15558 9.01064 7.23074 8.91879C7.30591 8.82694 7.36134 8.72126 7.39372 8.60804C7.42611 8.49483 7.43478 8.37641 7.41923 8.25986C7.40368 8.1433 7.36423 8.03099 7.30322 7.92965C7.24221 7.8283 7.1609 7.73998 7.06414 7.66997C6.96738 7.59995 6.85716 7.54968 6.74005 7.52215C6.62294 7.49461 6.50135 7.49039 6.38253 7.50972C5.73842 7.59434 5.13019 7.84885 4.62335 8.24582C4.11652 8.64279 3.73029 9.16718 3.50629 9.76249C3.28229 10.3578 3.229 11.0015 3.35217 11.6241C3.47534 12.2468 3.77029 12.8249 4.20525 13.2961C3.12541 13.7897 2.21235 14.5737 1.57332 15.5561C0.934284 16.5384 0.595813 17.6783 0.597664 18.8418C0.597664 19.0739 0.692141 19.2965 0.860313 19.4606C1.02848 19.6247 1.25657 19.7168 1.4944 19.7168H3.28788C3.52571 19.7168 3.7538 19.6247 3.92197 19.4606C4.09014 19.2965 4.18462 19.0739 4.18462 18.8418C4.18462 18.6098 4.09014 18.3872 3.92197 18.2231C3.7538 18.059 3.52571 17.9668 3.28788 17.9668H2.48082C2.65976 17.1046 3.10163 16.3152 3.74846 15.7023C4.39529 15.0895 5.21688 14.6817 6.10544 14.5325C6.2056 14.513 6.3015 14.4766 6.3888 14.4248C6.4257 14.4293 6.46283 14.4317 6.5 14.4318C6.72691 14.4304 6.9448 14.345 7.10976 14.193C7.27472 14.0409 7.37447 13.8335 7.38892 13.6126C7.40336 13.3916 7.33142 13.1735 7.1876 13.0023C7.04377 12.831 6.83876 12.7193 6.61389 12.6897C6.18887 12.6305 5.80002 12.4236 5.51873 12.1071C5.23744 11.7906 5.08257 11.3858 5.08257 10.9668C5.08257 10.5479 5.23744 10.143 5.51873 9.82657C5.80002 9.51008 6.18887 9.30322 6.61389 9.24397Z"
            fill="#A2ABB5"
          />
        </svg>
      </div>
    </div>
    <div class="tw-flex tw-flex-col tw-py-[4px]">
      <template v-if="userId">
        <ViewPanelLink
          v-if="hasLink"
          :id="userId"
          label="Пользователь"
          component="employee-show"
        >
          {{ $Global.peopleName(user, formatName) }}
        </ViewPanelLink>
        <div v-else>
          {{ $Global.peopleName(user, formatName) }}
        </div>
        <EmployeePosition
          v-if="userId && hasPosition"
          :id="userId"
          class="tw-block tw-text-an-flat-gray-4"
        />
        <div
          v-if="hasPhone && workPhone"
          class="tw-block tw-text-an-flat-gray-4"
        >
          Тел. {{ workPhone }}
        </div>
      </template>
      <template v-else>
        ~
      </template>
    </div>
    <div class="tw-ml-auto tw-flex tw-gap-[6px] tw-items-center">
      <TwPopoverShort
        v-if="warnIsNotWorkPhone && !workPhone"
        :z-index="999"
      >
        <template #trigger>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_477_3066)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M13.3382 14.2811L14.4713 13.6248C14.8246 13.4202 15.2391 13.439 15.5725 13.6747L17.4563 15.0063C17.8952 15.3166 17.9905 15.919 17.6688 16.3495C17.3471 16.7803 17.0194 17.1611 16.5202 17.6491C16.3402 17.825 16.1051 17.8941 15.8587 17.8431C11.6559 16.9728 7.02736 12.3442 6.15703 8.1414C6.10603 7.89497 6.17498 7.65985 6.35095 7.47994C6.83901 6.98072 7.21978 6.65302 7.65051 6.33122C8.08111 6.00952 8.68354 6.10486 8.99372 6.54371L10.3254 8.42757C10.561 8.76094 10.5798 9.17546 10.3752 9.52876L9.71897 10.6618C10.6674 11.9785 12.0215 13.3326 13.3382 14.2811Z"
                fill="#1B1918"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 0.515671C18.3426 0.515671 23.4843 5.65734 23.4843 12C23.4843 18.3427 18.3426 23.4843 12 23.4843C5.65734 23.4843 0.515717 18.3427 0.515717 12C0.515717 5.65734 5.65734 0.515671 12 0.515671ZM12 2.85942C6.9518 2.85942 2.85937 6.9518 2.85937 12C2.85937 14.1016 3.56906 16.0374 4.76142 17.5813L17.5813 4.76142C16.0375 3.56906 14.1017 2.85942 12 2.85942ZM19.2386 6.41873L6.41869 19.2386C7.9627 20.4309 9.89831 21.1406 12 21.1406C17.0482 21.1406 21.1406 17.0482 21.1406 12C21.1406 9.89836 20.4308 7.96275 19.2386 6.41873Z"
                fill="#EE0033"
              />
            </g>
            <defs>
              <clipPath id="clip0_477_3066">
                <rect
                  width="24"
                  height="24"
                  fill="white"
                />
              </clipPath>
            </defs>
          </svg>
        </template>
        <template #content>
          <div class="tw-w-[200px]">
            У данного сотрудника не указан рабочий телефон - звонки не будут записаны и прикреплены к лиду или заявке
          </div>
        </template>
      </TwPopoverShort>
      <TwPopoverShort
        v-if="user && user.active === false"
        :z-index="999"
      >
        <template #trigger>
          <svg
            width="18"
            height="17"
            viewBox="0 0 18 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.562 14.8414C18.1429 13.8387 18.1466 12.6435 17.5694 11.6445L11.7749 1.60958C11.2014 0.599425 10.1653 0 9.00348 0C7.84162 0 6.80557 0.603125 6.23204 1.60588L0.430164 11.6519C-0.147064 12.662 -0.143364 13.8646 0.441264 14.8673C1.01849 15.859 2.05084 16.4547 3.2053 16.4547H14.7794C15.9376 16.4547 16.9773 15.8516 17.562 14.8414Z"
              fill="#E63114"
            />
            <path
              d="M8.99978 10.5042C8.49656 10.5042 8.07474 10.926 8.07474 11.4292C8.07474 11.9324 8.49656 12.3542 8.99978 12.3542C9.48451 12.3542 9.92483 11.9324 9.90262 11.4514C9.92483 10.9223 9.50671 10.5042 8.99978 10.5042Z"
              fill="white"
            />
            <path
              d="M8.77038 4.01024C8.33006 4.13605 8.05624 4.53567 8.05624 5.02039C8.07844 5.3127 8.09695 5.60872 8.11915 5.90103C8.18205 7.01479 8.24495 8.10634 8.30786 9.22009C8.33006 9.59749 8.62237 9.87129 8.99979 9.87129C9.37721 9.87129 9.67322 9.57899 9.69172 9.19789C9.69172 8.96848 9.69172 8.75757 9.71392 8.52446C9.75462 7.81032 9.79903 7.09619 9.83973 6.38206C9.86193 5.91953 9.90263 5.45701 9.92483 4.99449C9.92483 4.82798 9.90263 4.67997 9.83973 4.53197C9.65102 4.11755 9.2107 3.90664 8.77038 4.01024Z"
              fill="white"
            />
          </svg>
        </template>
        <template #content>
          <div class="tw-w-[200px]">
            Учетная запись сотрудника не активна
          </div>
        </template>
      </TwPopoverShort>
    </div>
  </div>
</template>

<script>
import { employeeAvatarListGetLink } from '@/views/Company/Employee/employeeHelpers'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink'
import EmployeePosition from '@/components/other/employee/EmployeePosition.vue'
import TwPopoverShort from '@/components/tw-ui/popover/TwPopoverShort.vue'
export default {
  name: 'TwPanelContentUserInfo',
  components: { TwPopoverShort, EmployeePosition, ViewPanelLink },
  props: {
    userId: {
      type: Number,
      required: true
    },
    formatName: {
      type: String,
      default: 'fio'
    },
    hasLink: {
      type: Boolean,
      default: true
    },
    hasPhone: {
      type: Boolean,
      default: false
    },
    hasPosition: {
      type: Boolean,
      default: true
    },
    hasAvatar: {
      type: Boolean,
      default: true
    },
    warnIsNotWorkPhone: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    user () {
      return this.$store.getters['users/getUserById'](this.userId)
    },
    avatar () {
      return this.user?.file ? employeeAvatarListGetLink(this.user.file) : null
    },
    workPhone () {
      return this.user?.workPhone ? this.user.workPhone : null
    }
  }
}
</script>

<style scoped>

</style>
