import faq from './content/faq'
import article from './content/article'
import service from './content/service'
import newbuildings from './newbuildings'
import mortgage from './mortgage'
import catalog from './catalog'
import dictPermissionRules from '@/Permissions/dictRules'

export default {
  path: 'site-developer-admin',
  name: 'site-admin',
  meta: { name: 'Сайт админ.', icon: 'admin', isAdmin: false, ruleNamespace: dictPermissionRules.AppServicePermissionRulesANFLATContentSiteContentRule },
  redirect: { name: 'review-list' },
  component: () => import('@/views/Site/Admin/Index.vue'),
  children: [
    article,
    faq,
    service,
    newbuildings,
    catalog,
    mortgage
  ]
}
