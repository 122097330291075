<template>
  <div>
    <TwSelect
      placeholder="Выбрать должность"
      class="tw-min-w-[250px]"
      :label="label"
      :dense="dense"
      :cleanable="true"
      :persistent-label="true"
      :options="relativeOptionsList"
      :value="value"
      :disabled="disabled"
      :error-text="errorText"
      select-place-max-height="300px"
      @input="onInputId"
    >
      <template #listTop>
        <div class="tw-sticky tw-top-0 tw-bg-white tw-text-main-sm2 tw-font-normal tw-px-[14px] tw-pb-[10px]">
          <svg
            class="tw-absolute tw-top-[12px] tw-left-[24px]"
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2 5.5C2 3.567 3.567 2 5.5 2C7.433 2 9 3.567 9 5.5C9 7.433 7.433 9 5.5 9C3.567 9 2 7.433 2 5.5ZM5.5 0C2.46243 0 0 2.46243 0 5.5C0 8.53757 2.46243 11 5.5 11C6.6575 11 7.73148 10.6424 8.61752 10.0317L11.2929 12.7071C11.6834 13.0976 12.3166 13.0976 12.7071 12.7071C13.0976 12.3166 13.0976 11.6834 12.7071 11.2929L10.0317 8.61752C10.6424 7.73148 11 6.6575 11 5.5C11 2.46243 8.53757 0 5.5 0Z"
              fill="#7A8797"
            />
          </svg>
          <input
            v-model="searchStr"
            class="tw-rounded-sm tw-w-full tw-pl-[30px] tw-pr-[16px] tw-py-[8px] tw-border tw-border-solid tw-outline-0 disabled:tw-bg-an-flat-disabled-input-bg disabled:tw-text-an-flat-disabled-input-text"
            :maxlength="200"
            placeholder="Поиск"
          >
        </div>
      </template>
      <template #options="{ options, getIsCheckedItem, clickToItem }">
        <div
          v-for="opt in options"
          :key="opt.value"
          :class="[getIsCheckedItem(opt) ? 'tw-text-an-flat-blue-btn tw-bg-an-flat-red-lighten hover:tw-bg-an-flat-red-lighten':'']"
          class="tw-leading-normal tw-cursor-pointer tw-py-[10px] tw-pl-[18px] tw-pr-[6px] hover:tw-bg-an-flat-bg-main"
          @click.stop="clickToItem(opt)"
        >
          <div>
            {{ opt.label || '-' }}
          </div>
        </div>
      </template>
    </TwSelect>
  </div>
</template>

<script>
import API from '@/Api/Auth/Structure/Position'
import TwSelect from '@/components/tw-ui/ui-kit/input/TwSelect.vue'

export default {
  name: 'TwPositionSelect',
  components: { TwSelect },
  props: {
    value: {},
    label: {
      type: String,
      default: 'Должность'
    },
    dense: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    errorText: {
      type: Array,
      default: null
    }
  },
  data () {
    return {
      dataList: [],
      searchStr: ''
    }
  },
  methods: {
    fetch () {
      this.loading = true
      API.getList({ limit: 999 })
        .then((response) => {
          this.dataList = response?.data?.data?.map((item) => {
            return {
              label: item.name,
              value: item.id
            }
          })
        })
        .catch(() => {
          this.dataList = []
        })
        .finally(() => {
          this.loading = false
        })
    },
    onInputId (id) {
      this.searchStr = ''
      const optionItem = this.dataList.find(item => item.value === id)
      if (optionItem === undefined) {
        this.$emit('input', undefined)
        this.$emit('inputFullData', undefined)
      } else {
        this.$emit('input', id)
        this.$emit('inputFullData', optionItem)
      }
    }
  },
  computed: {
    relativeOptionsList () {
      const searchStr = this.searchStr?.toLowerCase() || ''
      return (Array.isArray(this.dataList) && this.dataList.filter((item) => {
        return item.label?.toLowerCase()?.includes(searchStr)
      })) || []
    }
  },
  mounted () {
    this.fetch()
  }
}
</script>
