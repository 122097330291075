<template>
  <div>
    <div style="display: none">
      {{ isEdited }}
    </div>
    <TwFlexCol>
      <TwFlexCell>
        <TwInputText
          type="text"
          placeholder="Ежемесячный доход"
          max-length="13"
          :persistent-label="true"
          :value="$Global.inputFormatPrice.getStringSpaced(form.salaryMonth)"
          :disabled="formProcessing"
          :show-label="true"
          :dense="true"
          :error-text="$v.form.salaryMonth.$invalid ? '*Обязательное поле':''"
          @input="form.salaryMonth = $Global.inputFormatPrice.getNumber($event)"
        >
          <template #append>
            руб.
          </template>
        </TwInputText>
      </TwFlexCell>
      <TwFlexCell>
        <TwInputText
          v-model="form.experience"
          type="date"
          autocomplete="off"
          label="Дата начала работы(Общий стаж)"
          min="1920-01-01"
          :dense="true"
          :max="new Date().toISOString().slice(0, 10)"
          :disabled="formProcessing"
          :show-label="true"
          :error-text="$v.form.experience.$invalid ? '*Обязательное поле':''"
          :persistent-label="true"
        />
      </TwFlexCell>
      <TwFlexCell>
        <TwInputText
          v-model="form.experienceLast"
          type="date"
          autocomplete="off"
          label="Дата начала работы на послед. месте работы"
          min="1920-01-01"
          :dense="true"
          :max="new Date().toISOString().slice(0, 10)"
          :disabled="formProcessing"
          :show-label="true"
          :persistent-label="true"
        />
      </TwFlexCell>
      <TwFlexCell>
        <TwSelect
          v-model="form.confirmationMethod"
          label="Способ подтверждения дохода"
          placeholder="Не выбрано"
          :dense="true"
          :disable-error-border="false"
          :persistent-label="true"
          :options="dictionary.typeConfirm"
          :clearable="true"
          :disabled="formProcessing"
          :error-text="$v.form.confirmationMethod.$invalid ? '*Обязательное поле':''"
        />
      </TwFlexCell>
      <TwFlexCell class="tw-text-h5">
        Зарплатный проект:
      </TwFlexCell>
      <TwSelectRelations
        v-model="form.salaryProjectCompanyId"
        :multi="false"
        :dense="true"
        :persistent-label="true"
        label="Банк"
        placeholder="Выберите банк"
        list-label="Найти банк"
        relation-name="bank"
        :disabled="formProcessing"
      />
      <TwInputBankCard
        ref="salaryProjectCard"
        v-model="form.salaryProjectCard"
        class="tw-my-[12px] tw-flex-1"
        :disabled="false"
        :disable-error-border="false"
        :show-label="true"
        :persistent-label="true"
        :dense="true"
        :error-text="$v.form.salaryProjectCard.$invalid ? '*Обязательное поле':''"
        label="Номер зарплатной карты"
        @input="$v.form.salaryProjectCard.$touch()"
      />
      <TwFlexCell class="tw-text-h5">
        Основное место работы:
      </TwFlexCell>
      <TwFlexCell>
        <TwInputText
          v-model="form.mainCompanyName"
          type="text"
          placeholder="Название"
          :persistent-label="true"
          :disabled="formProcessing"
          :show-label="true"
          :dense="true"
        />
      </TwFlexCell>
      <TwFlexCell>
        <TwInputPhone
          v-model="form.mainCompanyPhone"
          :disabled="false"
          :disable-error-border="false"
          :show-label="true"
          :persistent-label="true"
          :dense="true"
          label="Телефон"
        />
      </TwFlexCell>
      <TwFlexCell>
        <TwInputText
          v-model="form.mainCompanyInn"
          type="text"
          placeholder="ИНН"
          :persistent-label="true"
          :disabled="formProcessing"
          :show-label="true"
          :error-text="$v.form.mainCompanyInn.$invalid ? '*Обязательное поле':''"
          :dense="true"
        />
      </TwFlexCell>
      <TwFlexCell>
        <TwInputText
          v-model="form.mainCompanyAddress"
          type="text"
          placeholder="Адрес"
          :persistent-label="true"
          :disabled="formProcessing"
          :show-label="true"
          :dense="true"
        />
      </TwFlexCell>
      <TwFlexCell>
        <TwInputText
          v-model="form.mainCompanyDirection"
          type="text"
          placeholder="Сфера деятельности"
          :persistent-label="true"
          :disabled="formProcessing"
          :show-label="true"
          :dense="true"
        />
      </TwFlexCell>
      <TwFlexCell>
        <TwInputText
          v-model="form.mainCompanyDirection"
          type="text"
          placeholder="Должность"
          :persistent-label="true"
          :disabled="formProcessing"
          :show-label="true"
          :dense="true"
        />
      </TwFlexCell>
      <TwFlexCell>
        <TwInputText
          v-model="form.mainCompanySite"
          type="text"
          placeholder="Веб сайт"
          :persistent-label="true"
          :disabled="formProcessing"
          :show-label="true"
          :dense="true"
        />
      </TwFlexCell>

      <TwFlexCell>
        <TwCheckBox
          v-model="isAdditionalSalary"
          :disabled="formProcessing"
          label="Есть подтвержденный дополнительный доход"
        />
      </TwFlexCell>
      <template v-if="isAdditionalSalary">
        <TwFlexCell class="tw-text-h5">
          Дополнительный доход:
        </TwFlexCell>
        <TwFlexCell>
          <TwInputText
            type="text"
            placeholder="Сумма дополнительного дохода"
            max-length="13"
            :persistent-label="true"
            :value="$Global.inputFormatPrice.getStringSpaced(form.additionalSalarySum)"
            :disabled="formProcessing"
            :show-label="true"
            :dense="true"
            @input="form.additionalSalarySum = $Global.inputFormatPrice.getNumber($event)"
          >
            <template #append>
              руб.
            </template>
          </TwInputText>
        </TwFlexCell>
        <TwFlexCell class="tw-text-h5">
          Дополнительное место работы:
        </TwFlexCell>
        <TwFlexCell>
          <TwInputText
            v-model="form.additionalCompanyName"
            type="text"
            placeholder="Название"
            :persistent-label="true"
            :disabled="formProcessing"
            :show-label="true"
            :dense="true"
          />
        </TwFlexCell>
        <TwFlexCell>
          <TwInputPhone
            v-model="form.additionalCompanyPhone"
            class="tw-mt-[12px] tw-flex-1"
            :disabled="false"
            :disable-error-border="false"
            :show-label="true"
            :persistent-label="true"
            :dense="true"
            label="Телефон"
          />
        </TwFlexCell>
        <TwFlexCell>
          <TwInputText
            v-model="form.additionalCompanyInn"
            type="text"
            placeholder="ИНН"
            :persistent-label="true"
            :disabled="formProcessing"
            :show-label="true"
            :error-text="$v.form.additionalCompanyInn.$invalid ? '*Обязательное поле':''"
            :dense="true"
          />
        </TwFlexCell>
        <TwFlexCell>
          <TwInputText
            v-model="form.additionalCompanyAddress"
            type="text"
            placeholder="Адрес"
            :persistent-label="true"
            :disabled="formProcessing"
            :show-label="true"
            :dense="true"
          />
        </TwFlexCell>
        <TwFlexCell>
          <TwInputText
            v-model="form.additionalCompanyDirection"
            type="text"
            placeholder="Сфера деятельности"
            :persistent-label="true"
            :disabled="formProcessing"
            :show-label="true"
            :dense="true"
          />
        </TwFlexCell>
        <TwFlexCell>
          <TwInputText
            v-model="form.additionalCompanyPosition"
            type="text"
            placeholder="Должность"
            :persistent-label="true"
            :disabled="formProcessing"
            :show-label="true"
            :dense="true"
          />
        </TwFlexCell>
      </template>
      <TwFlexCell>
        <TwCheckBox
          v-model="form.accumulation"
          :disabled="formProcessing"
          label="Есть накопления"
        />
      </TwFlexCell>
    </TwFlexCol>

    <TwCardSimpleFormSaveBlock
      class="tw-mt-[15px]"
      :loading="formProcessing"
      bg-color="transparent"
      :cancel-confirm="true"
      :is-sticky="false"
      :visible="true"
      @success="handleSubmit"
      @cancel="handleCancel"
    />
  </div>
</template>

<script>
import API from '@/Api/Crm/Person/Salary/index'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import { required } from 'vuelidate/lib/validators'
import validateInn from '@/plugins/helpers/validateInn'
import TwInputPhone from '@/components/tw-ui/ui-kit/custom/TwInputPhone.vue'
import TwInputBankCard from '@/components/tw-ui/ui-kit/custom/TwInputBankCard.vue'
import TwSelectRelations from '@/components/tw-ui/ui-kit/input/select-relations/TwSelectRelations.vue'
import TwCardSimpleFormSaveBlock from '@/components/tw-ui/forms/TwCardSimpleFormSaveBlock.vue'
import TwFlexCell from '@/components/tw-ui/ui-kit/flex-block/TwFlexCell.vue'
import TwFlexCol from '@/components/tw-ui/ui-kit/flex-block/TwFlexCol.vue'
import TwInputText from '@/components/tw-ui/ui-kit/input/TwInputText.vue'
import TwSelect from '@/components/tw-ui/ui-kit/input/TwSelect.vue'
import TwCheckBox from '@/components/tw-ui/ui-kit/input/TwCheckBox.vue'

export default {
  name: 'PersonSalaryForm',
  components: {
    TwSelectRelations,
    TwInputBankCard,
    TwInputPhone,
    TwCardSimpleFormSaveBlock,
    TwFlexCell,
    TwFlexCol,
    TwInputText,
    TwSelect,
    TwCheckBox
  },
  mixins: [FormCreateEditMixin],
  props: {
    personId: {
      type: Number,
      required: true
    },
    denseFields: {
      type: Boolean,
      default: false
    },
    disableRedirect: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      API: API,
      form: {
        personId: this.personId,
        id: null,
        salaryProjectCompanyId: null, // integer*ID Банк
        salaryProjectCard: null, // string Зарплатная карта
        confirmationMethod: null, // string*Enum Подтверждение дохода
        salaryMonth: null, // number*float Доход в месяц
        experience: null, // string*date Общий стаж, дата
        experienceLast: null, // string*date Стаж на последнем месте работы, дата
        additionalSalarySum: null, // number*float Сумма дополнительного дохода
        accumulation: null, // boolean Накопления

        mainCompanyName: null, // string Название основного места работы
        mainCompanyInn: null, // string ИНН основного места работы
        mainCompanyAddress: null, // string Адрес основного места работы
        mainCompanyDirection: null, // string Сфера деятельности основного места работы
        mainCompanyPosition: null, // string Должность на основном месте работы
        mainCompanyPhone: null, // string телефон основного места работы
        mainCompanySite: null, // string сайт основного места работы

        additionalCompanyInn: null, // string ИНН дополнительного места работы
        additionalCompanyName: null, // string Название дополнительного места работы
        additionalCompanyAddress: null, // string Адрес дополнительного места работы
        additionalCompanyDirection: null, // string Сфера деятельности дополнительного места работы
        additionalCompanyPosition: null, // string Должность на дополнительном месте работы
        additionalCompanyPhone: null // string телефон дополнительного места работы
      },
      additionalSalaryLocal: false,
      expDateMenu: false,
      expDateMenuLast: false,
      serverErrors: null
    }
  },
  validations: {
    form: {
      mainCompanyInn: {
        isValid: function (v) {
          const errors = {}
          validateInn(v, errors)
          if (v) {
            return !errors.message
          } else {
            return true
          }
        }
      },
      additionalCompanyInn: {
        isValid: function (v) {
          const errors = {}
          validateInn(v, errors)
          if (v) {
            return !errors.message
          } else {
            return true
          }
        }
      },
      salaryMonth: {
        required: (v) => !!(v && !isNaN(parseFloat(v)) && parseFloat(v))
      },
      experience: {
        required
      },
      confirmationMethod: {
        required
      },
      salaryProjectCard: {
        valideCardNum: (v) => !!(!v || (typeof v === 'string' && /^([0-9 ]){16}/.test(v)))
      }
    }
  },
  computed: {
    isAdditionalSalary: {
      get () {
        return this.form.additionalCompanyName || this.form.additionalSalarySum || this.additionalSalaryLocal
      },
      set (val) {
        if (!val) {
          this.form.additionalSalarySum = 0
        }
        this.additionalSalaryLocal = val
      }
    }
  },
  methods: {
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          const adaperMap = {
            // from(show): to(send)
            confirmationMethod: 'confirmationMethod',
            salaryProjectCompany: 'salaryProjectCompanyId'
          }
          for (const key in this.preset) {
            if (adaperMap[key] !== undefined) {
              if (adaperMap[key].indexOf('Id') !== -1 && (this.preset[key] && this.preset[key].id)) {
                this.$set(this.form, adaperMap[key], this.preset[key].id)
              }
              if (key === 'confirmationMethod') {
                this.$set(this.form, adaperMap[key], this.preset[key].value || null)
              }
            } else {
              this.$set(this.form, key, this.preset[key])
            }
          }
        }
        resolve()
      })
    },
    handleResetCustom () {
      this.handleReset()
    },
    handleSubmit () {
      // console.warn(this.$options.name, ' handleSubmit ', this.formType, this.targetId)
      this.$v.$reset()
      this.$v.form.$touch()
      // console.log('handleSubmit ', this.$v)
      // console.log('$invalid ', this.$v.form.$invalid)
      if (!this.$v.form.$invalid) {
        if (this.formProcessing === true) {
          return
        }

        this.formProcessing = true
        this.serverErrors = null
        if (this.formType === 'create') {
          this.API.store(this.form)
            .then(({ data }) => {
              console.warn(this.$options.name, ' CREATE ', data)
              // this.fetchData(data.data.id)
              this.$emit('create', data.data)
            })
            .catch((error) => {
              if (error?.response?.data) {
                console.error(this.$options.name, error.response.data)
                for (const key in error.response.data) {
                  const [refKey, index] = key.split('.')
                  // console.log(key, refKey, index, field)
                  if (!this.serverErrors) this.serverErrors = {}
                  if (!this.serverErrors[refKey]) this.serverErrors[refKey] = []
                  this.serverErrors[refKey][index] = error.response.data[key][0]
                }
              }
            })
            .finally(() => {
              this.formProcessing = false
            })
        } else if (this.formType === 'edit' && this.targetId) {
          this.API.update(this.targetId, this.form)
            .then(({ data }) => {
              console.warn(this.$options.name, ' UPDATE ', data.data)
              this.$emit('update', data.data)
            })
            .catch((error) => {
              if (error?.response?.data) {
                console.error(this.$options.name, error.response.data)
                for (const key in error.response.data) {
                  const [refKey, index] = key.split('.')
                  // console.log(key, refKey, index, field)
                  if (!this.serverErrors) this.serverErrors = {}
                  if (!this.serverErrors[refKey]) this.serverErrors[refKey] = []
                  this.serverErrors[refKey][index] = error.response.data[key][0]
                }
              }
            })
            .finally(() => {
              this.formProcessing = false
            })
        } else {
          alert(`Error handleSubmit: ${this.$options.name}`)
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
