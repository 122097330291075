<template>
  <div
    style="outline: none; display: contents"
    tabindex="0"
    :title="disabled ? '':''"
  >
    <div
      ref="trigger"
      :class="disabled ? 'tw-cursor-default': 'tw-cursor-default'"
      :style="{ display: popoverTriggerDisplay }"
      v-on="eventListeners"
    >
      <slot name="trigger">
        clickMe
      </slot>
    </div>
    <div
      v-show="open"
      ref="tooltip"
      class="tw-font-manrope tw-font-semibold tw-text-main-xs tw-px-[12px] tw-py-[5px] tw-rounded-md"
      :class="[colorClasses, contentClasses || 'a-tooltip-short']"
      :style="{ zIndex }"
      @click.stop="clickInContent"
    >
      <div
        v-if="open"
        v-click-outside="clickOutSide"
      >
        <slot
          name="content"
          v-bind="{ onClose }"
        >
          popout info content
        </slot>
      </div>
      <div
        v-if="!hideArrow"
        class="tooltip-arrow"
        :class="colorClasses"
        data-popper-arrow
      />
    </div>
  </div>
</template>

<script>
import { createPopper } from '@popperjs/core'

export default {
  name: 'TwPopoverShort',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    parentBoundary: {
      type: Boolean,
      default: false
    },
    hideArrow: {
      type: Boolean,
      default: false
    },
    noCloseClickOutside: {
      type: Boolean,
      default: false
    },
    openByHover: {
      type: Boolean,
      default: true
    },
    contentClasses: {
      type: String,
      default: null
    },
    popoverTriggerDisplay: {
      type: String,
      default: 'inline'
    },
    offset: {
      type: Array,
      default: () => [10, 10]
    },
    zIndex: {
      default: 99
    },
    color: {
      type: String,
      default: 'dark' // dark | gray | white
    }
  },
  data () {
    return {
      popperInstance: null,
      open: false
    }
  },
  computed: {
    colorClasses () {
      switch (this.color) {
        case 'dark':
          return 'tw-bg-[#50545E] tw-text-white '
        case 'gray':
          return 'tw-bg-[#E2E6EB] tw-text-[#50545E] '
        case 'white':
          return 'tw-bg-white tw-text-[#50545E] '
        default:
          return 'tw-bg-[#50545E] tw-text-white '
      }
    },
    eventListeners () {
      return {
        ...this.$listeners,
        ...(this.openByHover) && {
          pointerenter: () => {
            this.onOpen()
          },
          pointerleave: () => {
            this.onClose()
          }
        },
        ...(!this.openByHover) && {
          click: () => {
            this.onToggle()
          }
        }
      }
    }
  },
  beforeDestroy () {
    this.popperInstance = null
  },
  methods: {
    onUpdate () {
      this.popperInstance.update()
    },
    stopPropagation (event) {
      event.stopPropagation()
    },
    clickInContent (event) {
      event.stopPropagation()
      console.log('Popper Content click')
    },
    onToggle () {
      // console.warn(this.$options.name, 'onToggle')
      if (this.open) {
        this.onClose()
      } else {
        this.onOpen()
      }
    },
    onOpen () {
      // console.warn(this.$options.name, 'onOpen')
      if (this.disabled) return
      this.open = true
      const trigger = this.$refs.trigger
      const tooltip = this.$refs.tooltip
      this.popperInstance = createPopper(trigger, tooltip, {
        // elementContext: 'reference',
        // boundary: document.body,
        // placement: 'left-bottom',
        // strategy: 'fixed',
        modifiers: [
          {
            name: 'preventOverflow',
            options: {
              rootBoundary: this.parentBoundary ? this.$parent.$el : document,
              padding: 5
            }
          },
          {
            name: 'offset',
            options: {
              offset: this.offset
            }
          }
          // {
          //   name: 'flip',
          //   options: {
          //     padding: 0
          //     // flipVariations: false,
          //     // altBoundary: true,
          //     // rootBoundary: 'viewport', // document || 'viewport'  by default
          //     // boundary: trigger.$el || this.parentEl || document.body,
          //     // fallbackPlacements: ['top', 'right'] // by default, all the placements are allowed
          //   }
          // }
        ]
      })
      this.$emit('onOpen')
    },
    onClose () {
      // console.warn(this.$options.name, 'onClose')
      this.open = false
      this.$emit('onClose')
      this.popperInstance = null
    },
    clickOutSide () {
      // закрываю - если это не запрещено
      if (!this.noCloseClickOutside) {
        this.onClose()
      }
    }
  }
}
</script>
<style scoped>
.a-tooltip-short {
  display: inline-block;
  -webkit-box-shadow: 0 0 8px 0 rgba(34, 60, 80, 0.34);
  -moz-box-shadow: 0 0 8px 0 rgba(34, 60, 80, 0.34);
  box-shadow: 0 0 8px 0 rgba(34, 60, 80, 0.34);
}

.tooltip-arrow,
.tooltip-arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
}

.tooltip-arrow {
  visibility: hidden;
}

.tooltip-arrow::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
}

.a-tooltip-short[data-popper-placement^='top'] > .tooltip-arrow {
  bottom: -4px;
}

.a-tooltip-short[data-popper-placement^='bottom'] > .tooltip-arrow {
  top: -4px;
}

.a-tooltip-short[data-popper-placement^='left'] > .tooltip-arrow {
  right: -4px;
}

.a-tooltip-short[data-popper-placement^='right'] > .tooltip-arrow {
  left: -4px;
}

</style>
