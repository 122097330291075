<template>
  <div
    v-cloak
    v-if="info"
    class="tw-flex tw-flex-col tw-gap-[18px]"
  >
    <TwPanelContentBlockFieldView>
      <template #title>
        Застройщик
      </template>
      <template #default>
        <div v-if="info.company">
          <ViewPanelLink
            :id="info.company.id"
            label="Застройщик"
            component="company-show"
          >
            {{ info.company.name }}
          </ViewPanelLink>
        </div>
        <div v-else>
          Не указано
        </div>
      </template>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Парсер
      </template>
      <template #default>
        <div v-if="info.parser">
          {{ info.parser }}
        </div>
        <div v-else>
          Не указано
        </div>
      </template>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Комментарий
      </template>
      <div
        v-if="info.comment"
        v-html="$Global.nl2br(info.comment)"
      />
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Активный
      </template>
      <template #default>
        {{ info.active ? 'Да' : 'Нет' }}
      </template>
    </TwPanelContentBlockFieldView>
  </div>
</template>

<script>
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'

export default {
  name: 'ImportBuilderFileMainShow',
  components: { TwPanelContentBlockFieldView, ViewPanelLink },
  mixins: [ViewPanelShowMixin],
  props: {
    info: {},
    dictionary: {}
  }
}
</script>
