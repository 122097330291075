import { render, staticRenderFns } from "./DealLeadInfoShow.vue?vue&type=template&id=0ab8b326&scoped=true"
import script from "./DealLeadInfoShow.vue?vue&type=script&lang=js"
export * from "./DealLeadInfoShow.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ab8b326",
  null
  
)

export default component.exports