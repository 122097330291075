import { AxiosResponse } from 'axios'
import HTTP from '@/Api/http'
import { AuthAxiosDefaultConfig } from '@/Api/Mortgage/index'

const path = '/mortgage/search'

export default {
  getList (param?: object): Promise<AxiosResponse> {
    const $config = {
      ...AuthAxiosDefaultConfig,
      param
    }
    console.warn('param: ', param)
    // Получение списка программ при помощи POST запроса
    return HTTP.post(`${path}`, param, $config)
  },

  create (): Promise<AxiosResponse> {
    // Получение списка программ при помощи POST запроса
    const $config = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.get(`${path}/create`, $config)
  },

  // Фильтрация списка ЖК для выбора в подборе
  commissionOptimizeComplexes (params?: any): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig,
      params
    }
    return HTTP.get(`${path}/commissionOptimizeComplexes`, $config)
  }
}
