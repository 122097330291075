<template>
  <div class="">
    <div
      v-if="!loading && objLists"
      class="tw-grid tw-grid-cols-1 xl:tw-grid-cols-2 tw-gap-[10px]"
    >
      <div
        v-for="(type, typeIndex) of typeOrderDraw"
        :key="typeIndex"
        class="tw-flex tw-flex-row tw-flex-wrap tw-w-full"
      >
        <TwPanelContentBlock
          class="tw-w-full tw-mb-[6px] tw-border tw-border-solid"
          content-classes="tw-bg-inherit"
        >
          <template #title>
            <div
              v-if="objLists[type]"
              class="pt-2 primary--text"
            >
              {{ objLists[type].label }}:
            </div>
          </template>
          <ul
            v-if="objLists[type]"
            class="tw-grid tw-grid-cols-2 3xl:tw-grid-cols-3 tw-gap-[12px]"
          >
            <li
              v-for="(item, index) of objLists[type].items"
              v-show="(onlySubsidized && item.subsidized) || (!onlySubsidized)"
              :key="index"
            >
              <TwCheckBox
                background-and-border-color="tw-bg-an-flat-dark-blue-link tw-border-an-flat-dark-blue-link"
                :label="item.name"
                :disabled="isDisableTypeSelect(type, item)"
                :error-messages="errors"
                class="category-checkbox ma-0"
                :value="infoTypeOrder[item.type.value] === item.id"
                @input="eventCheckbox(item)"
              />
              <div
                disabled="true"
                class="tw-block tw-pl-[25px] tw-font-medium tw-text-[11px] tw-text-an-flat-grey-3"
              >
                <div
                  :class="warningValues && isWarningFirstPayment(item) ? 'error--text' : ''"
                  :title="warningValues && isWarningFirstPayment(item) ? 'Первоначальный взнос - Не соответствует условиям подбора' : 'ПВ - первоначальный взнос'"
                >
                  {{ genItemFirstPaymentText(item) }}
                </div>
                <div
                  :class="warningValues && isWarningSum(item) ? 'error--text' : ''"
                  :title="warningValues && isWarningSum(item) ? 'Сумма кредита - Не соответствует условиям подбора' : 'СК - сумма кредита'"
                >
                  {{ genItemSumText(item) }}
                </div>
              </div>
            </li>
          </ul>
        </TwPanelContentBlock>
      </div>
      <button
        type="button"
        class="tw-w-fit tw-h-fit tw-pt-[8px] tw-pb-[10px] tw-px-[12px] tw-font-bold tw-text-[12px] tw-text-[#4B5763] tw-uppercase
        tw-ml-auto tw-mt-auto tw-border tw-border-solid tw-border-[#EAECEE] tw-rounded-sm
        hover:tw-opacity-50 active:tw-opacity-70"
        @click="$emit('copy')"
      >
        Копировать/Импортировать параметры
      </button>
    </div>
    <div v-else>
      <v-skeleton-loader
        boilerplate
        class="mx-auto"
        type="image"
      />
    </div>
  </div>
</template>

<script>
import API from '@/Api/Mortgage/ProgramCategory/index'
import {
  isWarningFirstPayment,
  isWarningSum,
  genItemFirstPaymentText,
  genItemSumText
} from '@/views/Dictionary/Mortgage/ProgramCategory/MortgageProgramCategoryHelper'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwCheckBox from '@/components/tw-ui/ui-kit/input/TwCheckBox.vue'

export default {
  name: 'ProgramCategorySelect',
  components: {
    TwCheckBox,
    TwPanelContentBlock
  },
  props: {
    value: {},
    required: {},
    onlySubsidized: {},
    placeholder: {},
    warningValues: {
      type: Object,
      default: null
    },
    isSimpleSelect: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    API: API,
    items: [],
    infoTypeOrder: {
      object: null,
      program: null,
      other: null
    },
    typeOrderDraw: ['object', 'program', 'other'],

    loading: false,
    selected: []
  }),
  computed: {
    errors () {
      return this.required ? this.value.length ? [] : ['required'] : []
    },
    objLists () {
      const result = {}
      for (const item of this.items) {
        if (item?.type?.value) {
          if (!result[item?.type?.value]) {
            result[item?.type?.value] = {
              value: item.type.value,
              label: item.type.label,
              items: []
            }
          }
          result[item.type.value].items.push(item)
        }
      }
      return result
    }
  },
  watch: {
    // При установке только субсид - нужно сбросить выбранные
    onlySubsidized () {
      this.input([])
    },
    value: {
      handler (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.selected = Array.isArray(newValue) ? newValue : []
          this.infoTypeOrderInit()
        }
      },
      immediate: true
    }
  },
  mounted () {
    this.fetchCategories()
  },
  methods: {
    isWarningSum (item) {
      return isWarningSum(item, this.warningValues)
    },
    isWarningFirstPayment (item) {
      return isWarningFirstPayment(item, this.warningValues)
    },
    genItemSumText: genItemSumText,
    genItemFirstPaymentText: genItemFirstPaymentText,
    eventCheckbox (item) {
      if (this.selected.includes(this.infoTypeOrder[item.type.value])) {
        this.selected = this.selected.filter(element => element !== this.infoTypeOrder[item.type.value])
        if (this.infoTypeOrder[item.type.value] !== item.id) {
          this.infoTypeOrder[item.type.value] = item.id
          this.selected.push(this.infoTypeOrder[item.type.value])
        } else {
          this.infoTypeOrder[item.type.value] = null
        }
      } else {
        this.infoTypeOrder[item.type.value] = item.id
        this.selected.push(this.infoTypeOrder[item.type.value])
      }
      this.input(this.selected || [])
    },
    input (values) {
      this.$emit('input', values || [])
    },
    fetchCategories () {
      console.warn(`${this.$options.name} - fetchCategories`)
      if (this.loading) return

      this.loading = true

      this.API.getList({ limit: 999, active: true })
        .then((response) => {
          this.items = response?.data?.data || []
        })
        .catch((error) => {
          console.error({ error })
        })
        .finally(() => {
          this.loading = false
          this.infoTypeOrderInit()
        })
    },
    infoTypeOrderInit () {
      for (const item of this.items) {
        if (this.selected.includes(item.id)) {
          this.infoTypeOrder[item.type.value] = item.id
        }
      }
    },
    // проверка на активность с учётом категории
    isDisableTypeSelect (type, targetItem) {
      // console.warn('isDisableTypeSelect', type, targetItem)
      return this.isSimpleSelect && !this.selected.includes(targetItem.id) && !!this.objLists[type].items.find(cat => this.selected.includes(cat.id))
    }
  }
}
</script>
