import lodashDebounce from 'lodash/debounce'

export default {
  methods: {
    changeSort: lodashDebounce(function (value) {
      console.log('changeSort', value)
      this.fetch()
    }, 300),
    onSortUpdate (eventData = {}) {
      console.log('onSortUpdate', this.defaultFilters, eventData)
      this.defaultFilters.sortField = eventData?.sortField || null
      this.defaultFilters.sortDirection = eventData?.sortDirection ? eventData?.sortDirection : null
      this.changeSort()
    },
    /**
     * @deprecated Старый вариант используется для сортировок Vuetify таблицы <v-data-table/>
     */
    onSortByField (event) {
      console.log('onSortByField', this.defaultFilters, event)
      this.defaultFilters.sortField = event || null
      this.changeSort()
    },
    /**
     * @deprecated Старый вариант используется для сортировок Vuetify таблицы <v-data-table/>
     */
    onSortDirection (event) {
      console.log('onSortDirection', this.defaultFilters, event)
      this.defaultFilters.sortDirection = event !== undefined ? event ? 'asc' : 'desc' : null
      this.changeSort()
    }
  }
}
