<template>
  <div>
    <TwSelect
      label="Дом/Строение в нашей системе"
      :error-text="errorText"
      :dense="dense"
      :value="value"
      :disabled="disabled"
      :multi="multi"
      :options="options"
      @input="$emit('input', $event)"
    />
  </div>
</template>

<script>
import { API } from '@/Api/ObjectStorage'
import TwSelect from '@/components/tw-ui/ui-kit/input/TwSelect.vue'

export default {
  name: 'CustomBuildingSelector',
  components: { TwSelect },
  props: {
    value: {},
    errorText: {},
    multi: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    complexId: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      loading: false,
      data: []
    }
  },
  computed: {
    options () {
      return this.data.map((item) => {
        return {
          label: item.addressNumber,
          value: item.id
        }
      })
    }
  },
  watch: {
    complexId: {
      handler (newValue) {
        if (newValue) {
          this.fetch()
        }
      },
      immediate: true
    }
  },
  methods: {
    fetch (params) {
      this.loading = true
      API.RealtyNewBuildings.objects(this.complexId, params)
        .then((response) => {
          this.data = response.data.data
        })
        .catch((error) => {
          // обработка отсутствия прав на просмотр
          if (error?.response?.status === 403) {
            this.permissionDenied = true
          }
          this.data = []
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped>

</style>
