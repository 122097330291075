<template>
  <div>
    <div
      v-if="loading"
      class="tw-h-full tw-w-full tw-flex tw-justify-center tw-items-center tw-py-5"
    >
      <TwCircleLoader
        height="50px"
        width="50px"
      />
    </div>
    <div v-else>
      <div
        :class="fromPanel ? 'tw-px-[26px] tw-py-[18px]':''"
        class="tw-font-manrope tw-text-main-sm2 tw-max-w-[100%] tw-bg-white tw-min-h-screen"
      >
        <TwPanelTitle v-if="fromPanel">
          <template #left>
            <div class="tw-text-h4 tw-font-bold">
              <div
                v-if="data && data.company"
                class="tw-text-h4 tw-font-bold"
              >
                Требования банка
                <span class="tw-uppercase">
                  {{ data.company.name }}
                </span>
                <span class="tw-text-an-flat-disabled-text tw-font-medium">
                  # {{ data.id }}
                </span>
              </div>
            </div>
          </template>
        </TwPanelTitle>
        <TwPanelTabsFull
          v-model="currentTab"
          :items="tabList"
        >
          <template #content.main>
            <TwPanelContent>
              <div class="tw-flex tw-flex-row tw-gap-[6px] tw-flex-1">
                <!--  LEFT  -->
                <div class="tw-w-2/3 tw-rounded-md tw-h-fit">
                  <TwPanelContentBlock>
                    <template #title>
                      <div>
                        О требованиях
                      </div>
                    </template>
                    <template #action>
                      <TwPanelContentBlockActionButton
                        :disabled="isNew"
                        type="edit"
                        @click="editMain = !editMain"
                      >
                        {{ editMain ? 'отменить' : 'изменить' }}
                      </TwPanelContentBlockActionButton>
                    </template>
                    <RequirementMainForm
                      v-if="editMain"
                      :id="data ? data.id : 0"
                      ref="EditMain"
                      @create="onCreated"
                      @update="onUpdated"
                    />
                    <RequirementMainShow
                      v-else
                      :dictionary="dictionary"
                      :info="data"
                      @refresh="refresh"
                    />
                  </TwPanelContentBlock>
                </div>
                <!--  RIGHT  -->
                <div class="tw-w-1/3 tw-rounded-md tw-h-fit">
                  <TwPanelContentBlock>
                    <template #title>
                      <div>
                        Данные <span class="text-muted" />
                      </div>
                    </template>
                    <b>В разработке...</b>
                  </TwPanelContentBlock>
                </div>
              </div>
            </TwPanelContent>
          </template>
          <template #content.matrixSale>
            <DiscountMatrixTab :company-id="companyId" />
          </template>
        </TwPanelTabsFull>
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/Api/Mortgage/Requirement/index'
import CardMixin from '@/mixins/CardMixin'
import RequirementMainForm from '@/views/Mortgage/Requirement/main/RequirementMainForm'
import RequirementMainShow from '@/views/Mortgage/Requirement/main/RequirementMainShow'
import DiscountMatrixTab from '@/views/Mortgage/Requirement/DiscountMatrix/DiscountMatrixTab'
import TwPanelTabsFull from '@/components/tw-ui/panels/TwPanelTabsFull.vue'
import TwPanelContent from '@/components/tw-ui/panels/TwPanelContent.vue'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import TwPanelTitle from '@/components/tw-ui/panels/TwPanelTitle.vue'
import TwPanelContentBlockActionButton from '@/components/tw-ui/panels/TwPanelContentBlockActionButton.vue'

export default {
  name: 'RequirementCard',
  components: {
    TwPanelContentBlockActionButton,
    TwPanelTitle,
    TwCircleLoader,
    TwPanelContent,
    TwPanelTabsFull,
    TwPanelContentBlock,
    DiscountMatrixTab,
    RequirementMainShow,
    RequirementMainForm
  },
  mixins: [CardMixin],
  data () {
    return {
      API: API,
      currentTab: 'main'
    }
  },
  computed: {
    companyId () {
      return this.data?.company?.id || null
    },
    tabList () {
      return [
        {
          label: 'Общие',
          key: 'main'
        },
        {
          label: 'Матрицы скидок',
          key: 'matrixSale',
          disabled: this.isNew
        }
      ]
    }
  }
}
</script>
