import { AxiosResponse } from 'axios'
import HTTP from '@/Api/http'
import { AuthAxiosDefaultConfig } from '@/Api/ObjectStorage'

const path = '/realty/newbuildings'

export default {
  getComplexImage (complexId: string): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.get(`${path}/${complexId}/highlight`, $config)
  },
  setComplexImage (data: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.post(`${path}/highlight`, data, $config)
  },
  updateHousesCoordinates (data: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.post(`${path}/highlight/houses`, data, $config)
  },
  removeComplexImage (complexId: string): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.delete(`${path}/${complexId}/highlight`, $config)
  }
}
