<template>
  <div v-if="info">
    <div :class="wrapperClasses">
      <TwPanelContentBlockFieldView v-if="info.status">
        <template #title>
          Статус
        </template>
        <div>
          {{ info.status.label }}
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView>
        <template #title>
          Объект страхования
        </template>
        <div v-if="info.insuranceObjectId && dictionary.insuranceObject && dictionary.insuranceObject.find(i => i.value === info.insuranceObjectId)">
          {{ dictionary.insuranceObject.find(i => i.value === info.insuranceObjectId).label || 'Адрес не указан' }}
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView>
        <template #title>
          Оставшаяся сумма по ипотеке
        </template>
        <div v-if="info.remainingAmountMortgage">
          {{ (info.remainingAmountMortgage || 0).toLocaleString('ru') }} <Rouble />
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView>
        <template #title>
          Дата пролонгации
        </template>
        <div v-if="info.payDate">
          {{ $Global.DateFormat.DateShort(info.payDate) }}
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView>
        <template #title>
          Сумма страхования имущества
        </template>
        <div v-if="info.insuranceObjectSum">
          {{ (info.insuranceObjectSum || 0).toLocaleString('ru') }} <Rouble />
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView>
        <template #title>
          Сумма страхования жизни
        </template>
        <div v-if="info.insuranceLifeSum">
          {{ (info.insuranceLifeSum || 0).toLocaleString('ru') }} <Rouble />
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView>
        <template #title>
          Сумма страхования титула
        </template>
        <div v-if="info.insuranceTitleSum">
          {{ (info.insuranceTitleSum || 0).toLocaleString('ru') }} <Rouble />
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView>
        <template #title>
          Сумма страхования(Итого)
        </template>
        <div v-if="info.insuranceSum">
          {{ (info.insuranceSum || 0).toLocaleString('ru') }} <Rouble />
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView>
        <template #title>
          Вознаграждение агентству
        </template>
        <div v-if="info.commission">
          {{ (info.commission || 0).toLocaleString('ru') }} <Rouble />
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView>
        <template #title>
          Дата создания
        </template>
        <div v-if="info.createdAt">
          {{ $Global.DateFormat.DateTimeShort(info.createdAt) }}
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView v-if="info.updatedAt && (info.updatedAt !== info.createdAt)">
        <template #title>
          Дата изменения
        </template>
        <div v-if="info.updatedAt">
          {{ $Global.DateFormat.DateTimeShort(info.updatedAt) }}
        </div>
      </TwPanelContentBlockFieldView>
    </div>
  </div>
  <div
    v-else
    class="tw-py-[24px] tw-text-an-flat-disabled-text tw-text-center"
  >
    Не заполнена
  </div>
</template>

<script>
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'
import { GridWrapperForPanelShowAndForm } from '@/utils/tw-classes'
import Rouble from '@/components/symbol/Rouble.vue'

export default {
  name: 'DealInsuranceResultShow',
  components: { Rouble, TwPanelContentBlockFieldView },
  props: {
    info: {
      type: Object,
      default: null
    },
    dictionary: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      wrapperClasses: GridWrapperForPanelShowAndForm.threeCols
    }
  },
  computed: {
    purposePurchase () {
      return this.dictionary.purposePurchase.find(i => i.value === this.info.purposePurchase)
    },
    payMethod () {
      return this.dictionary.payMethod.find(i => i.value === this.info.payMethod)
    },
    reservation () {
      return this.dictionary.reservation.find(i => i.value === this.info.reservationId)
    }
  }
}
</script>
