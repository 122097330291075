import { AxiosResponse } from 'axios'
import HTTP from '@/Api/http'

const path = '/estimation/analyze/apartment'
const AuthAxiosDefaultConfig: any = {
  serviceKey: 'REALTY_MINER'
}

export default {
  analyzeApartment (data: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig,
      timeout: 110000
    }
    return HTTP.post(`${path}`, data, $config)
  },
  analyzeApartmentGraph (data: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.post(`${path}/graph`, data, $config)
  },
  analyzeApartmentPdf (requestUuid: string, subPath: string): Promise<boolean> {
    const $config: any = {
      ...AuthAxiosDefaultConfig,
      params: {
        requestUuid
      },
      responseType: 'blob'
    }
    return new Promise((resolve) => {
      HTTP.get(`${path}/${subPath}?requestUuid=${requestUuid}`, $config).then((response) => {
        // console.warn('response: ', response)
        // Генерирую локальную ссылку из пришедшего бинарника
        const anchor = document.createElement('a')
        document.body.appendChild(anchor)
        const blob = new Blob([response.data], { type: response.data?.type || 'application/pdf' })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        const typeFileName = subPath === 'pdf' ? `Анализ рыночной стоимости квартиры ${requestUuid}.pdf` : `Анализ рынка ${requestUuid}.pdf`
        // console.warn('typeFileName', typeFileName, 'subPath', subPath)
        let fileName: any = response?.headers['content-disposition']?.split('filename*=utf-8\'\'') || []
        fileName = Array.isArray(fileName) && fileName.length ? decodeURIComponent(fileName[1]) : ''
        link.setAttribute('download', fileName || typeFileName) // or any other extension
        document.body.appendChild(link)
        link.click()
        // удаляю ссылку и чищу память
        window.URL.revokeObjectURL(url)
        resolve(true)
      }).catch((error) => {
        console.error('error: ', !!error)
        if (error.response) {
          console.error('error1: ', !!error)
          // Request made and server responded
          console.log(error.response.data)
          console.log(error.response.status)
          console.log(error.response.headers)
        } else if (error.request) {
          console.error('error2: ', !!error)
          // The request was made but no response was received
          console.log(error.request)
          console.log('Error2', error.message)
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error3', error.message)
        }
        resolve(false)
      })
    })
  },
  filtersCreate (params: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig,
      params: params || {}
    }
    return HTTP.get(`${path}/create`, $config)
  }
}
