<template>
  <div>
    <div
      v-if="initProcess"
      class="tw-p-2 tw-flex"
    >
      <TwCircleLoader class="tw-m-auto" />
    </div>
    <div v-else>
      <TwInputText
        :value="statusInfo.name"
        type="text"
        :disabled="true"
        label="Переход в статус"
        :dense="true"
        :show-label="true"
        :persistent-label="true"
      />
      <TwInputText
        v-if="statusInfo.code === 'success'"
        ref="contractFinishDate"
        v-model="form.contractFinishDate"
        type="date"
        :disabled="initProcess"
        :max="currentDate"
        :label="contractFinishDateLabel"
        :dense="true"
        :show-label="true"
        :persistent-label="true"
        :error-text="form.contractFinishDate ? '':'Обязательное поле'"
        class="tw-mt-[12px]"
      />
      <TwCardSimpleFormSaveBlock
        class="tw-mt-[15px]"
        :loading="initProcess"
        :cancel-confirm="false"
        :is-sticky="true"
        :disable-success="initProcess || !form.contractFinishDate"
        :disable-cancel="initProcess"
        :visible="true"
        :dense="false"
        success-title="Сохранить"
        cancel-title="Отмена"
        @success="onApply"
        @cancel="onCancel"
      />
    </div>
  </div>
</template>

<script>
import API from '@/Api/Crm/Deal/index'
import TwCircleLoader from '../tw-ui/ui-kit/TwCircleLoader.vue'
import TwInputText from '../tw-ui/ui-kit/input/TwInputText.vue'
import TwCardSimpleFormSaveBlock from '../tw-ui/forms/TwCardSimpleFormSaveBlock.vue'

export default {
  name: 'DealSuccessStatusForm',
  components: { TwCircleLoader, TwInputText, TwCardSimpleFormSaveBlock },
  props: {
    statusInfo: {
      required: true
    },
    dealId: {}
  },
  data () {
    return {
      initProcess: false,
      currentDate: new Date().toISOString().slice(0, 10),
      contractFinishDateLabel: 'Дата завершения договора',
      form: {
        contractFinishDate: new Date().toISOString().slice(0, 10)
      }
    }
  },
  mounted () {
    this.initProcess = true
    API.initChangeStatus(this.dealId)
      .then(({ data }) => {
        this.contractFinishDateLabel = data?.ui?.contractFinishDateLabel || 'Дата завершения договора'
        if (!data?.ui?.needContractFinishDate) {
          this.$emit('apply', {})
        } else if (data.data?.contractFinishDate) {
          this.form.contractFinishDate = data.data.contractFinishDate
        }
      })
      .catch((error) => {
        console.error(this.$options.name, error)
      })
      .finally(() => {
        this.initProcess = false
      })
  },
  methods: {
    onApply () {
      if (this.form.contractFinishDate) {
        this.$emit('apply', this.form)
        this.form = {
          contractFinishDate: this.currentDate || ''
        }
      }
    },
    onCancel () {
      this.form = {
        contractFinishDate: this.currentDate || ''
      }
      this.$emit('cancel')
    }
  }
}
</script>

<style scoped>

</style>
