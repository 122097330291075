<template>
  <div
    v-if="loading"
    class="tw-flex tw-justify-center"
  >
    <TwCircleLoader />
  </div>
  <div v-else-if="Array.isArray(data) && data.length">
    <div
      v-for="(item, index) in data"
      :key="index"
    >
      <ViewPanelLink
        :id="item.id"
        label="Компания"
        component="company-show"
      >
        {{ item && item.name }}
      </ViewPanelLink>
    </div>
  </div>
  <div
    v-else
    class="tw-text-center tw-text-an-flat-disabled-text"
  >
    Отсутствуют
  </div>
</template>

<script>
import API from '@/Api/Crm/Company'
import DataListMixin from '@/mixins/DataListMixin'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'

export default {
  name: 'CompanyFilials',
  components: { TwCircleLoader, ViewPanelLink },
  mixins: [DataListMixin],
  props: {
    companyId: {
      type: [Number, String],
      required: true
    }
  },
  data () {
    return {
      API: API,
      defaultFilters: {
        parentId: [this.companyId]
      }
    }
  },
  mounted () {
    this.fetch()
  }
}
</script>

<style scoped>

</style>
