import HTTP from '@/Api/http'
import { AuthAxiosDefaultConfig } from '@/Api/DocCheck'

export const path = '/kontur/subject/requisite/inn'

export default {
  fetchPersonInn (data: object) {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.post(path, data, $config)
  }
}
