export const employeeAvatarProfileGetLink = (fileObj: any) => {
  if (fileObj?.url) {
    return fileObj?.resize?.crmProfile || fileObj?.url
  }
  return null
}

export const employeeAvatarListGetLink = (fileObj: any) => {
  if (fileObj?.url) {
    return fileObj?.resize?.crmList || fileObj?.url
  }
  return null
}
