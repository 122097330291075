<template>
  <div>
    <div
      v-if="loading"
      class="tw-text-center"
    >
      <TwCircleLoader />
    </div>
    <div v-else-if="info">
      <div class="tw-grid 2xl:tw-grid-cols-3 lg:tw-grid-cols-2 tw-gap-y-[12px] tw-gap-x-[26px] tw-w-full">
        <TwPanelContentBlockFieldView
          v-if="isVisibleFromType.fullName"
          class="tw-min-w-[250px]"
          no-data-text="Не указано"
        >
          <template #title>
            ФИО
          </template>
          <div v-if="info.fullName">
            {{ info.fullName }}
          </div>
        </TwPanelContentBlockFieldView>
        <TwPanelContentBlockFieldView
          v-if="isVisibleFromType.phone"
          class="tw-min-w-[250px]"
          no-data-text="Не указано"
        >
          <template #title>
            Номер телефона
          </template>
          <div v-if="info.phone">
            +{{ info.phone }}
          </div>
        </TwPanelContentBlockFieldView>
        <TwPanelContentBlockFieldView
          v-if="isVisibleFromType.goal"
          class="tw-min-w-[250px]"
          no-data-text="Не указано"
        >
          <template #title>
            Цель покупки
          </template>
          <div v-if="info.goal">
            {{ getLabelFromDict('goal', info.goal) }}
          </div>
        </TwPanelContentBlockFieldView>
        <TwPanelContentBlockFieldView
          v-if="isVisibleFromType.objectType"
          class="tw-min-w-[250px]"
          no-data-text="Не указано"
        >
          <template #title>
            Тип объекта
          </template>
          <div v-if="info.objectType">
            {{ getLabelFromDict('objectType', info.objectType) }}
          </div>
        </TwPanelContentBlockFieldView>
        <TwPanelContentBlockFieldView
          v-if="isVisibleFromType.complexIds"
          class="tw-min-w-[250px]"
          no-data-text="Не указано"
        >
          <template #title>
            ЖК
          </template>
          <div v-if="Array.isArray(info.complexIds) && info.complexIds.length">
            <div
              v-for="complexId in info.complexIds"
              :key="complexId"
            >
              <ViewPanelLink
                :id="complexId"
                label="ЖК"
                component="realty-complex-show-page"
              >
                <RealtyComplexName :id="complexId" />
              </ViewPanelLink>
            </div>
          </div>
        </TwPanelContentBlockFieldView>
        <TwPanelContentBlockFieldView
          v-if="isVisibleFromType.roomsCount"
          class="tw-min-w-[250px]"
          no-data-text="Не указано"
        >
          <template #title>
            Количество комнат
          </template>
          <div v-if="Array.isArray(info.roomsCount) && info.roomsCount.length">
            {{ info.roomsCount.map(value => getLabelFromDict('roomsCount', value)).join(', ') }}
          </div>
        </TwPanelContentBlockFieldView>
        <TwPanelContentBlockFieldView
          v-if="isVisibleFromType.commonAreaMin"
          class="tw-min-w-[250px]"
          no-data-text="Не указано"
        >
          <template #title>
            Общая площадь
          </template>
          <div v-if="info.commonAreaMin">
            <span v-if="info.commonAreaMin">
              от <b>{{ info.commonAreaMin || 0 }}</b> м²
            </span>
          </div>
        </TwPanelContentBlockFieldView>
        <TwPanelContentBlockFieldView
          v-if="isVisibleFromType.landAreaMin"
          class="tw-min-w-[250px]"
          no-data-text="Не указано"
        >
          <template #title>
            Площадь З/У
          </template>
          <div v-if="info.landAreaMin">
            {{ info.landAreaMin }} сот.
          </div>
        </TwPanelContentBlockFieldView>
        <TwPanelContentBlockFieldView
          v-if="isVisibleFromType.budgetMax"
          class="tw-min-w-[250px]"
          no-data-text="Отсутствует"
        >
          <template #title>
            Бюджет на покупку
          </template>
          <div v-if="info.budgetMin || info.budgetMax">
            <span v-if="info.budgetMin">
              от <b>{{ (Number(info.budgetMin) || 0).toLocaleString('ru') }}</b> руб.
            </span>
            <span v-if="info.budgetMax">
              до <b>{{ (Number(info.budgetMax) || 0).toLocaleString('ru') }}</b> руб.
            </span>
          </div>
        </TwPanelContentBlockFieldView>
        <TwPanelContentBlockFieldView
          v-if="isVisibleFromType.paymentMethod"
          class="tw-min-w-[250px]"
          no-data-text="Не указано"
        >
          <template #title>
            Способ оплаты
          </template>
          <div v-if="info.paymentMethod">
            {{ getLabelFromDict('paymentMethod', info.paymentMethod) }}
          </div>
        </TwPanelContentBlockFieldView>
        <TwPanelContentBlockFieldView
          v-if="isVisibleFromType.initialPayment"
          class="tw-min-w-[250px]"
          no-data-text="Не указано"
        >
          <template #title>
            Первоначальный взнос(ПВ)
          </template>
          <div>
            {{ info.initialPayment ? 'Есть':'Нет' }}
          </div>
        </TwPanelContentBlockFieldView>
        <TwPanelContentBlockFieldView
          v-if="isVisibleFromType.subsidy"
          class="tw-min-w-[250px]"
          no-data-text="Не указано"
        >
          <template #title>
            Особые условия покупки
          </template>
          <div v-if="info.subsidy">
            {{ getLabelFromDict('subsidy', info.subsidy) }}
          </div>
        </TwPanelContentBlockFieldView>
        <!--СКРЫТЫЕ В ФОРМЕ-->
        <TwPanelContentBlockFieldView
          v-if="isVisibleFromType.kitchenAreaMin"
          class="tw-min-w-[250px]"
          no-data-text="Не указано"
        >
          <template #title>
            Площадь кухни
          </template>
          <div v-if="info.kitchenAreaMin">
            <span v-if="info.kitchenAreaMin">
              от <b>{{ info.kitchenAreaMin || 0 }}</b> м²
            </span>
          </div>
        </TwPanelContentBlockFieldView>
        <TwPanelContentBlockFieldView
          v-if="isVisibleFromType.readyQuarter"
          class="tw-min-w-[250px]"
          no-data-text="Не указано"
        >
          <template #title>
            Срок сдачи
          </template>
          <div v-if="Array.isArray(info.readyQuarter) && info.readyQuarter.length">
            {{ info.readyQuarter.map(value => getLabelFromDict('readyQuarter', value)).join(', ') }}
          </div>
        </TwPanelContentBlockFieldView>
        <TwPanelContentBlockFieldView
          v-if="isVisibleFromType.decoration"
          class="tw-min-w-[250px]"
          no-data-text="Не указано"
        >
          <template #title>
            Отделка
          </template>
          <div v-if="Array.isArray(info.decoration) && info.decoration.length">
            {{ info.decoration.map(value => getLabelFromDict('decoration', value)).join(', ') }}
          </div>
        </TwPanelContentBlockFieldView>
        <TwPanelContentBlockFieldView
          v-if="isVisibleFromType.wcType"
          class="tw-min-w-[250px]"
          no-data-text="Не указано"
        >
          <template #title>
            Тип с/у
          </template>
          <div v-if="info.wcType">
            {{ getLabelFromDict('wcType', info.wcType) }}
          </div>
        </TwPanelContentBlockFieldView>
        <TwPanelContentBlockFieldView
          v-if="isVisibleFromType.sidesWindow"
          class="tw-min-w-[250px]"
          no-data-text="Не указано"
        >
          <template #title>
            Направление окон
          </template>
          <div v-if="Array.isArray(info.sidesWindow) && info.sidesWindow.length">
            {{ info.sidesWindow.map(value => getLabelFromDict('sidesWindow', value)).join(', ') }}
          </div>
        </TwPanelContentBlockFieldView>
        <TwPanelContentBlockFieldView
          v-if="isVisibleFromType.balconyType"
          class="tw-min-w-[250px]"
          no-data-text="Не указано"
        >
          <template #title>
            Балкон
          </template>
          <div v-if="info.balconyType">
            {{ getLabelFromDict('balconyType', info.balconyType) }}
          </div>
        </TwPanelContentBlockFieldView>
        <TwPanelContentBlockFieldView
          v-if="isVisibleFromType.contractType"
          class="tw-min-w-[250px]"
          no-data-text="Не указано"
        >
          <template #title>
            Тип договора
          </template>
          <div v-if="info.contractType">
            {{ getLabelFromDict('contractType', info.contractType) }}
          </div>
        </TwPanelContentBlockFieldView>
      </div>
    </div>
    <div
      v-else
      class="tw-py-[24px] tw-text-an-flat-disabled-text tw-text-center"
    >
      Не заполнена
    </div>
  </div>
</template>

<script>
import QuestionnaireAPI from '@/Api/Crm/Deal/BuyNb/Questionnaire'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'
import RealtyComplexName from '@/components/other/complex/RealtyComplexName.vue'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink.vue'
import VisibleFieldsMixin from '@/views/Deal/TypeViews/buyNb/Client/VisibleFieldsMixin'

export default {
  name: 'DealBuyNbQuestionnaireShow',
  components: { ViewPanelLink, RealtyComplexName, TwPanelContentBlockFieldView, TwCircleLoader },
  mixins: [VisibleFieldsMixin],
  props: {
    dealId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      form: {},
      info: null,
      dict: null
    }
  },
  mounted () {
    this.fetchInfo()
  },
  methods: {
    getLabelFromDict (propKey, value) {
      if (this.dict && Array.isArray(this.dict[propKey])) {
        return this.dict[propKey].find((item) => {
          return item.value === value
        })?.label || '~'
      }
      return ''
    },
    fetchInfo () {
      this.loading = true
      QuestionnaireAPI.show(this.dealId)
        .then(({ data: result }) => {
          console.warn('fetchInfo', result)
          this.$set(this, 'info', result?.data?.id ? result?.data : null)
          this.$set(this, 'form', result?.data || {})
          this.$set(this, 'dict', result?.dictionary || null)
          this.$emit('changeEditableId', result?.data?.id)
        })
        .catch((error) => {
          console.error('fetchInfo', error)
          this.$set(this, 'info', null)
          this.$set(this, 'form', {})
          this.$set(this, 'dict', null)
          this.$emit('changeEditableId', 0)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped>

</style>
