import { RouteKeys } from '@/router/types'
import dictPermissionRules from '@/Permissions/dictRules'

export default {
  path: RouteKeys.mortgageUserComments,
  name: RouteKeys.mortgageUserComments,
  redirect: { name: `${RouteKeys.mortgageUserComments}-list` },
  meta: { name: 'Список комментариев', icon: 'list-of-comments', ruleNamespace: dictPermissionRules.AppServicePermissionRulesCRMMortgageProgramsReadProgramRule },
  component: () => import('@/views/Mortgage/Index.vue'),
  children: [
    {
      path: 'list',
      name: `${RouteKeys.mortgageUserComments}-list`,
      meta: { name: 'Список комментариев', icon: 'list-of-comments', hideInMenu: true },
      component: () => import('@/views/Mortgage/UserComments/List.vue')
    },
    {
      path: ':id/edit',
      name: `${RouteKeys.mortgageUserComments}-edit`,
      meta: { name: 'Редактирование комментария', icon: 'list-of-comments', hideInMenu: true },
      component: () => import('@/views/Mortgage/UserComments/Edit.vue'),
      props: (route:any) => ({ id: route.params.id })
    },
    {
      path: 'create',
      name: `${RouteKeys.mortgageUserComments}-create`,
      meta: { name: 'Добавление комментария', icon: 'list-of-comments', hideInMenu: true },
      component: () => import('@/views/Mortgage/UserComments/Edit.vue'),
      props: (route:any) => ({ id: route.params.id })
    },
    {
      path: ':id',
      name: `${RouteKeys.mortgageUserComments}-show`,
      meta: { name: 'Просмотр комментария', icon: 'list-of-comments', hideInMenu: true },
      component: () => import('@/views/Mortgage/UserComments/Edit.vue'),
      props: (route:any) => ({ id: route.params.id })
    }
  ]
}
