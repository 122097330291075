import { Module } from 'vuex'
import { RootState } from '@/store/types'
import { CardsState } from './types'

const namespaced = true

export const cards: Module<CardsState, RootState> = {
  namespaced,
  state: {},
  actions: {
    saveBlockSuccess () {
      // console.warn('saveBlockSuccess')
    },
    saveBlockCancel () {
      // console.warn('saveBlockCancel')
    }
  }
}
