<template>
  <TwUnAuthWrapper
    :show-close="result && !!result.token"
    @close="clickOnClose"
  >
    <template #title>
      <div>
        <div>
          Регистрация
        </div>
        <div
          v-if="!result"
          class="tw-text-[12px] tw-font-light tw-text-an-flat-grey-4"
        >
          Введите <strong>номер телефона</strong>, на него мы отправим код подтверждения
        </div>
      </div>
    </template>
    <template #content>
      <!-- Форма ТЕЛЕФОН 1 -->
      <div v-if="!result">
        <form
          class="tw-flex tw-flex-col tw-max-w-full"
          @submit="onAddPhone"
        >
          <TwInputPhone
            v-model="phone"
            :disabled="formProcessing"
            :persistent-label="true"
            :dense="false"
            :error-text="errorPhone"
            label="Телефон"
          />
          <div
            v-if="serverErrorMessage"
            class="tw-mt-[20px] tw-text-an-flat-red-main tw-text-center tw-text-main-sm"
          >
            {{ serverErrorMessage }}
          </div>
          <button
            type="submit"
            :disabled="formProcessing"
            class="tw-mt-[20px] tw-bg-an-flat-blue-dark-2 tw-text-white tw-p-[15px] tw-rounded-[10px] tw-text-center tw-w-full tw-font-bold tw-text-[14px] enabled:hover:tw-bg-an-flat-blue-dark-1 enabled:hover:tw-text-white disabled:tw-opacity-50"
            @click.prevent="onAddPhone"
          >
            {{ formProcessing ? 'Подождите' : 'Продолжить' }}
          </button>
          <div class="tw-mx-[10px] tw-mt-[10px] tw-text-main-xs tw-font-light tw-text-an-flat-grey-4">
            Продолжая, вы принимаете условия <span class="tw-cursor-pointer tw-text-an-flat-dark-blue-link tw-underline hover:tw-no-underline">Пользовательского соглашения</span> и <span class="tw-cursor-pointer tw-text-an-flat-dark-blue-link tw-underline hover:tw-no-underline">Политики конфиденциальности</span>
          </div>
        </form>
      </div>
      <!-- Форма КОД ПОДТВЕРЖДЕНИЯ 2 -->
      <div v-else-if="!phoneConfirmed && result && result.token">
        <form
          class="tw-flex tw-flex-col tw-max-w-full"
          @submit="onConfirmPhone"
        >
          <TwInputText
            v-model="code"
            type="text"
            label="Код подтверждения"
            placeholder="Введите код из СМС "
            max-length="6"
            :persistent-label="true"
            :show-label="true"
            :dense="false"
            :disabled="formProcessing"
            :error-text="errorCode"
          />
          <div
            v-if="serverErrorMessage"
            class="tw-mt-[20px] tw-text-an-flat-red-main tw-text-center tw-text-main-sm"
          >
            {{ serverErrorMessage }}
          </div>
          <button
            :disabled="formProcessing"
            class="tw-mt-[20px] tw-bg-an-flat-blue-dark-2 tw-text-white tw-p-[15px] tw-rounded-[10px] tw-text-center tw-w-full tw-font-bold tw-text-[14px] enabled:hover:tw-bg-an-flat-blue-dark-1 enabled:hover:tw-text-white disabled:tw-opacity-50"
            @click.prevent="onConfirmPhone"
          >
            {{ formProcessing ? 'Подождите' : 'Отправить' }}
          </button>
          <button
            :disabled="!!secondsToResend"
            class="tw-mt-[10px] tw-text-an-flat-blue-dark-2 tw-bg-white tw-p-[5px] tw-rounded-sm tw-text-center tw-w-full tw-font-normal tw-text-[14px] enabled:hover:tw-bg-an-flat-blue-dark-1 enabled:hover:tw-text-white disabled:tw-opacity-50"
            @click.prevent="onResendCode"
          >
            <span v-if="secondsToResend">
              Запросить код повторно можно через {{ secondsToResend }} секунд
            </span>
            <span v-else>
              Запросить код повторно
            </span>
          </button>
        </form>
      </div>
      <!-- Форма ПАРОЛЯ 3 -->
      <div v-else-if="phoneConfirmed">
        <div class="tw-text-[12px] tw-font-light tw-text-an-flat-grey-4">
          <strong>Придумайте пароль:</strong>
          <div
            class="tw-my-[10px]"
            :class="errorPassword ? 'tw-text-an-flat-red-main':''"
          >
            - Не менее <strong>6</strong> символов и не более <strong>20</strong>
            <br>
            - Хотя бы одна <strong>цифра</strong>
            <br>
            - Хотя бы одна <strong>заглавная</strong> и одна <strong>строчная</strong> буква
            <br>
            - Хотя бы один <strong>спец. символ</strong>
          </div>
        </div>
        <form
          class="tw-flex tw-flex-col tw-max-w-full"
          @submit.prevent="onSetPassword"
        >
          <TwUnAuthInputText
            v-model="password"
            :required="true"
            label-text="Пароль"
            placeholder="Пароль"
            autocomplete="new-password"
            type="password"
            :error-text="errorPassword"
            :disabled="formProcessing"
            @input="validatePassword"
          />
          <TwUnAuthInputText
            v-model="passwordConfirm"
            :required="true"
            label-text="Повторите пароль"
            placeholder="Повторите пароль"
            autocomplete="new-password"
            type="password"
            :error-text="errorPassword"
            :disabled="formProcessing"
            @input="validatePassword"
          />
          <div
            v-if="serverErrorMessage"
            class="tw-mt-[20px] tw-text-an-flat-red-main tw-text-center tw-text-main-sm"
          >
            {{ serverErrorMessage }}
          </div>
          <button
            :disabled="formProcessing"
            type="submit"
            class="tw-mt-[20px] tw-bg-an-flat-blue-dark-2 tw-text-white tw-p-[15px] tw-rounded-[10px] tw-text-center tw-w-full tw-font-bold tw-text-[14px] hover:tw-bg-an-flat-blue-dark-1 hover:tw-text-white disabled:tw-opacity-50"
            @click.prevent="onSetPassword"
          >
            {{ formProcessing ? 'Подождите' : 'Завершить регистрацию' }}
          </button>
        </form>
      </div>
      <div
        v-else
        class="tw-text-center tw-text-green-600"
      >
        <div>
          Регистрация завершена
        </div>
      </div>
    </template>
  </TwUnAuthWrapper>
</template>

<script>
import TwUnAuthInputText from '@/components/tw-ui/un-auth-ui/TwUnAuthInputText.vue'
import TwInputPhone from '@/components/tw-ui/ui-kit/custom/TwInputPhone.vue'
import TwInputText from '@/components/tw-ui/ui-kit/input/TwInputText.vue'
import TwUnAuthWrapper from '@/components/tw-ui/un-auth-ui/TwUnAuthWrapper.vue'
import SignUpAPI from '@/Api/Auth/SignUp'

export default {
  name: 'TwRegistrationView',
  components: { TwUnAuthWrapper, TwInputPhone, TwInputText, TwUnAuthInputText },
  data () {
    return {
      phone: '',
      errorPhone: '',
      code: '',
      errorCode: '',
      secondsToResend: 0,

      phoneConfirmed: false,

      password: '',
      passwordConfirm: '',
      errorPassword: '',

      formProcessing: false,
      token: '',

      result: null,
      serverErrorMessage: null
    }
  },
  methods: {
    clickOnClose () {
      if (this.result?.token) {
        this.result = null
        this.phoneConfirmed = false
      } else {
        this.$router.back()
      }
    },
    runResendTimer (seconds = 60) {
      this.secondsToResend = seconds
      const timerId = setInterval(() => {
        this.secondsToResend -= 1
        if (this.secondsToResend <= 0) {
          clearInterval(timerId)
        }
      }, 1000)
    },
    validatePhone () {
      const regex = /7([0-9]{10})/
      console.warn('validatePhone', this.phone, regex.test(this.phone))
      return regex.test(this.phone)
    },
    onAddPhone () {
      this.serverErrorMessage = null
      this.errorPhone = null
      if (!this.validatePhone()) {
        this.errorPhone = 'Неправильный телефон'
        return
      }
      this.formProcessing = true
      SignUpAPI.addPhone({ phone: this.phone })
        .then(({ data }) => {
          console.warn(this.$options.name, data)
          this.result = data.data
          this.runResendTimer(data?.data?.secondsToResend)
        })
        .catch((error) => {
          this.serverErrorMessage = error.serverErrorMessage
        })
        .finally(() => {
          this.formProcessing = false
        })
    },
    onConfirmPhone () {
      this.serverErrorMessage = null
      this.errorCode = null
      this.phoneConfirmed = false
      if (!this.code || this.code.length < 4) {
        this.errorCode = 'Неверный код'
        return
      }
      this.formProcessing = true
      SignUpAPI.confirmPhone({ code: this.code, token: this.result.token })
        .then(({ data }) => {
          console.warn(this.$options.name, data)
          this.phoneConfirmed = true
          this.code = null
        })
        .catch((error) => {
          this.serverErrorMessage = error.serverErrorMessage
        })
        .finally(() => {
          this.formProcessing = false
        })
    },
    onResendCode () {
      this.serverErrorMessage = null
      this.formProcessing = true
      SignUpAPI.resendCode({ token: this.result.token })
        .then(({ data }) => {
          console.warn(this.$options.name, data)
          this.result = data.data
          this.code = null
          this.runResendTimer(data?.data?.secondsToResend)
        })
        .catch((error) => {
          this.serverErrorMessage = error.serverErrorMessage
        })
        .finally(() => {
          this.formProcessing = false
        })
    },
    validatePassword () {
      const regex = /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W]).{6,20})/
      if (!regex.test(this.password)) {
        if (!this.errorPassword) {
          this.errorPassword = 'Пароль не соответствует требованиям'
        }
        return false
      } else if (this.password !== this.passwordConfirm) {
        if (!this.errorPassword) {
          this.errorPassword = 'Пароль и подтверждение пароля различаются'
        }
        return false
      }
      this.errorPassword = null
      return true
    },
    onSetPassword () {
      this.errorPassword = null
      if (!this.validatePassword()) {
        return
      }
      this.serverErrorMessage = null
      this.formProcessing = true
      SignUpAPI.setPassword({ token: this.result.token, password: this.password, passwordConfirm: this.passwordConfirm })
        .then(({ data }) => {
          console.warn(this.$options.name, data)
          this.successAuth(data)
        })
        .catch((error) => {
          this.serverErrorMessage = error.serverErrorMessage
        })
        .finally(() => {
          this.formProcessing = false
        })
    },
    successAuth (data) {
      this.$store.commit('profile/setToken', data)
      this.$nextTick(() => {
        this.$router.push('/').then(() => {
          window.location?.reload()
        })
      })
    }
  },
  created () {
    // при наличии токена в параметрах - меняю интерфейс на ввод нового пароля
    if (this.$route?.query?.token) {
      // console.log(this.$route.query.token)
      this.token = this.$route.query.token
    }
    // при наличии email в параметрах - подставляю email
    if (this.$route?.query?.email) {
      // console.log(this.$route.query.email)
      this.email = this.$route.query.email
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.$refs?.email?.focus()
    })
  }
}
</script>
