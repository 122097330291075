<template>
  <div class="tw-flex tw-gap-[12px] tw-flex-col">
    <template v-if="info">
      <TwPanelContentBlockFieldView no-data-text="Не состоит в ЧС">
        <template #title>
          В чёрном списке
        </template>
        <div
          v-if="info"
          class="tw-flex tw-items-center tw-gap-[7px]"
        >
          <svg
            width="18"
            height="17"
            viewBox="0 0 18 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.562 14.8414C18.1429 13.8387 18.1466 12.6435 17.5694 11.6445L11.7749 1.60958C11.2014 0.599425 10.1653 0 9.00348 0C7.84162 0 6.80557 0.603125 6.23204 1.60588L0.430164 11.6519C-0.147064 12.662 -0.143364 13.8646 0.441264 14.8673C1.01849 15.859 2.05084 16.4547 3.2053 16.4547H14.7794C15.9376 16.4547 16.9773 15.8516 17.562 14.8414Z"
              fill="#E63114"
            />
            <path
              d="M8.99978 10.5042C8.49656 10.5042 8.07474 10.926 8.07474 11.4292C8.07474 11.9324 8.49656 12.3542 8.99978 12.3542C9.48451 12.3542 9.92483 11.9324 9.90262 11.4514C9.92483 10.9223 9.50671 10.5042 8.99978 10.5042Z"
              fill="white"
            />
            <path
              d="M8.77038 4.01024C8.33006 4.13605 8.05624 4.53567 8.05624 5.02039C8.07844 5.3127 8.09695 5.60872 8.11915 5.90103C8.18205 7.01479 8.24495 8.10634 8.30786 9.22009C8.33006 9.59749 8.62237 9.87129 8.99979 9.87129C9.37721 9.87129 9.67322 9.57899 9.69172 9.19789C9.69172 8.96848 9.69172 8.75757 9.71392 8.52446C9.75462 7.81032 9.79903 7.09619 9.83973 6.38206C9.86193 5.91953 9.90263 5.45701 9.92483 4.99449C9.92483 4.82798 9.90263 4.67997 9.83973 4.53197C9.65102 4.11755 9.2107 3.90664 8.77038 4.01024Z"
              fill="white"
            />
          </svg>
          Да
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView>
        <template #title>
          Дата добавления
        </template>
        <div v-if="info.createdAt">
          {{ $Global.DateFormat.DateTimeShort(info.createdAt) }}
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView>
        <template #title>
          Причина
        </template>
        <div v-if="info.comment">
          {{ info.comment }}
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView>
        <template #title>
          Добавил в ЧС
        </template>
        <div v-if="info.createUserId">
          <ViewPanelLink
            :id="info.createUserId"
            label="Пользователь"
            component="employee-show"
          >
            <EmployeeName :id="info.createUserId" />
          </ViewPanelLink>
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView>
        <template #title>
          Изменил инфо ЧС
        </template>
        <div v-if="info.updateUserId && info.createUserId !== info.updateUserId">
          <ViewPanelLink
            :id="info.updateUserId"
            label="Пользователь"
            component="employee-show"
          >
            <EmployeeName :id="info.updateUserId" />
          </ViewPanelLink>
        </div>
      </TwPanelContentBlockFieldView>
    </template>
    <div
      v-else
      class="tw-w-full tw-text-center tw-text-an-flat-disabled-text"
    >
      Не состоит
    </div>
  </div>
</template>

<script>
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink'
import EmployeeName from '@/components/other/employee/EmployeeName'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'
export default {
  name: 'PersonBlackListShow',
  components: { TwPanelContentBlockFieldView, EmployeeName, ViewPanelLink },
  props: {
    info: {},
    dictionary: {}
  }
}
</script>
