<template>
  <div class="tw-text-main-sm">
    <ul
      v-if="Array.isArray(list) && list.length"
      class="tw-flex tw-gap-[8px] tw-w-[100%] tw-overflow-hidden"
    >
      <li
        v-for="(item, index) in list"
        :key="index"
        class="tw-grow tw-h-[100%]"
      >
        <button
          :disabled="disabled"
          :class="disabled ? 'tw-opacity-30':''"
          class="tw-flex tw-w-[100%] tw-relative tw-items-center tw-h-[37px] tw-leading-[28px]"
          @click="onChangeStatus(item)"
          @mouseover="statusValue === item?.value ? hoveredStatus = '' : hoveredStatus = item?.value"
          @mouseleave="hoveredStatus = ''"
        >
          <span
            v-if="index !== 0"
            class="tw-block tw-h-[100%] tw-w-[8px] tw-absolute tw-border-t-[18.5px] tw-border-b-[19px] tw-border-l-[8px] tw-border-solid tw-top-[0] tw-left-0 tw-border-t-[#CDD6DF] tw-border-b-[#CDD6DF] tw-border-transparent"
            :style="buildStylePrefixArrow(item, index)"
          />
          <span
            class="tw-block tw-p-[5px] tw-h-[100%] tw-w-[100%] tw-text-white tw-font-bold tw-border-solid tw-top-[0] tw-left-0 tw-bg-[#CDD6DF]"
            :style="buildStyleBodyArrow(item, index)"
          >
            {{ item?.label }}
          </span>
          <span
            v-if="index !== list.length - 1"
            class="tw-block  tw-h-[100%] tw-w-[8px] tw-absolute tw-top-[0] tw-right-[-8px]"
            :style="buildStyleSuffixArrow(item, index)"
          />
        </button>
      </li>
    </ul>
    <div
      v-else
      class="tw-p-2 tw-text-center tw-text-an-flat-disabled-text"
    >
      Отсутствует список статусов
    </div>
    <!--  Модалка подтверждения смены статуса  -->
    <div>
      <TwDialog
        :value="acceptModalShow"
        max-width="600px"
        @input="acceptModalShow = $event"
      >
        <template #header>
          <div>
            <div class="tw-text-h4 tw-font-semibold">
              Подтверждение - {{ requestType.label }}
            </div>
            <div class="tw-text-md tw-font-medium">
              Новый статус: <span :style="{ color: tmpNewStatus.color }">{{ tmpNewStatus.label }}</span>
            </div>
          </div>
        </template>
        <template #body>
          <div>
            <div v-if="requestType">
              <TwInputText
                v-if="newStatusForm.status === 'confirmed'"
                v-model="newStatusForm.confirmedBefore"
                class="tw-mt-[12px]"
                type="datetime-local"
                :label="`${requestType.value === 'reservation' ? 'Забронирован' : 'Зафиксирован'} до`"
                :show-label="true"
                :persistent-label="true"
                :min="currentDate"
                :error-text="newStatusForm.confirmedBefore ? '' : 'Заполните поле'"
              />
              <TwInputText
                v-if="requestType.value === 'reservation' && newStatusForm.status === 'confirmed'"
                class="tw-mt-[12px]"
                type="text"
                placeholder="Цена"
                max-length="13"
                :value="$Global.inputFormatPrice.getStringSpaced(newStatusForm.confirmedPrice)"
                :show-label="true"
                :error-text="newStatusForm.confirmedPrice ? '' : 'Заполните поле'"
                @input="newStatusForm.confirmedPrice = $Global.inputFormatPrice.getNumber($event)"
              >
                <template #append>
                  руб.
                </template>
              </TwInputText>
              <TwTextarea
                v-model="newStatusForm.comment"
                rows="5"
                class="tw-mt-[12px]"
                placeholder="Комментарий"
                :error-text="!newStatusForm.comment && (newStatusForm.status === 'rejected' || newStatusForm.status === 'canceled') ? '* Обязательно для заполнения':''"
                :show-label="true"
              />
            </div>
            <div
              v-if="updateStatusError"
              class="tw-mt-[15px] tw-p-[10px] tw-text-center tw-bg-an-flat-red-main tw-text-white tw-rounded-sm"
            >
              {{ updateStatusError }}
            </div>
            <div class="tw-mt-[15px] tw-flex tw-gap-[10px]">
              <button
                :disabled="!newStatusForm.comment && (newStatusForm.status === 'rejected' || newStatusForm.status === 'canceled') || !newStatusForm.confirmedBefore && (newStatusForm.status === 'confirmed')"
                class="tw-grow tw-px-[15px] tw-py-[7px] tw-bg-an-flat-green-btn-bg tw-rounded-sm tw-text-white disabled:tw-opacity-50"
                @click.prevent="onSendStatus"
              >
                Сохранить
              </button>
              <button
                class="tw-w-[100px] tw-px-[15px] tw-py-[7px] tw-bg-an-flat-gray-4 tw-rounded-sm tw-text-white enabled:hover:tw-bg-an-flat-red-main"
                @click.prevent="acceptModalShow = false"
              >
                Отмена
              </button>
            </div>
          </div>
        </template>
      </TwDialog>
    </div>
  </div>
</template>

<script>
import TwDialog from '@/components/tw-ui/modal/TwDialog.vue'
import TwTextarea from '@/components/tw-ui/ui-kit/input/TwTextarea.vue'
import TwInputText from '@/components/tw-ui/ui-kit/input/TwInputText.vue'

export default {
  name: 'DeveloperRequestStatusUi',
  components: { TwInputText, TwTextarea, TwDialog },
  props: {
    statusValue: {
      default: null
    },
    requestType: {
      required: true
    },
    disabled: {
      default: false
    },
    reservationPrice: {
      default: null
    },
    list: {
      default: () => []
    },
    updateStatusError: {
      default: null
    }
  },
  data () {
    return {
      currentDate: new Date().toISOString().slice(0, 10),
      acceptModalShow: false,
      tmpNewStatus: null,
      newStatusForm: {
        confirmedPrice: null,
        confirmedBefore: null,
        status: '',
        comment: ''
      },
      defaultColor: '#CDD6DF',
      hoveredStatus: ''
    }
  },
  watch: {
    acceptModalShow (newValue) {
      if (!newValue) {
        this.$emit('clearError')
      }
    }
  },
  methods: {
    onChangeStatus (status) {
      if (this.statusValue !== status.value) {
        // Добавляю временный объект статуса
        this.tmpNewStatus = status
        // Заполняю\очищаю форму
        this.newStatusForm.status = status.value
        this.newStatusForm.comment = ''
        this.newStatusForm.confirmedBefore = null
        this.newStatusForm.confirmedPrice = this.reservationPrice
        // Открывааю модалку
        this.acceptModalShow = true
      }
    },
    onSendStatus () {
      this.$emit('change', this.newStatusForm)
    },
    onCloseModal () {
      // Закрываю модалку
      this.acceptModalShow = false
    },
    buildStylePrefixArrow (item) {
      return {
        borderColor: 'transparent',
        borderTopColor: this.statusValue === item?.value ? item?.color : this.colorBgButton(item?.color, item?.value),
        borderBottomColor: this.statusValue === item?.value ? item?.color : this.colorBgButton(item?.color, item?.value)
      }
    },
    buildStyleBodyArrow (item, index) {
      return {
        backgroundColor: this.statusValue === item?.value ? item?.color : this.colorBgButton(item?.color, item?.value),
        marginLeft: index !== 0 ? '8px' : '0',
        ...(index === 0) && {
          borderRadius: '6px 0 0 6px'
        },
        ...(index === this.list.length - 1) && {
          borderRadius: '0 6px 6px 0'
        }
      }
    },
    buildStyleSuffixArrow (item) {
      return {
        borderTopWidth: '18px',
        borderBottomWidth: '18px',
        borderTopColor: 'transparent',
        borderBottomColor: 'transparent',
        borderLeftColor: this.statusValue === item?.value ? item?.color : this.colorBgButton(item?.color, item?.value),
        borderLeftWidth: '8px'
      }
    },
    colorBgButton (color, value) {
      let res = this.defaultColor
      if (value === this.hoveredStatus) {
        if (this.hoveredStatus) {
          res = color
        }
      }
      return res
    }
  }
}
</script>
