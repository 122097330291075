import { RouteKeys } from '@/router/types'
import dictPermissionRules from '@/Permissions/dictRules'

export default {
  path: RouteKeys.requestDistribution,
  name: RouteKeys.requestDistribution,
  redirect: { name: `${RouteKeys.requestDistribution}-list` },
  meta: { name: 'Распределение запросов', icon: 'distribution-of-requests', ruleNamespace: dictPermissionRules.AppServicePermissionRulesOBJECTSNewBuildingsBuilderRequestBuilderRequestDistributionSettingsRule },
  component: () => import('@/views/Newbuildings/Index.vue'),
  children: [
    {
      path: 'list',
      name: `${RouteKeys.requestDistribution}-list`,
      meta: { name: 'Распределение запросов', icon: 'home', hideInMenu: true, hText: 'Распределение запросов' },
      component: () => import('@/views/Newbuildings/RequestToDeveloper/RequestDistribution/List.vue')
    },
    {
      path: ':id/edit',
      name: `${RouteKeys.requestDistribution}-edit`,
      meta: { name: 'Редактирование запроса распределения', icon: 'home', hideInMenu: true, hText: 'Редактирование Записи распределения' },
      component: () => import('@/views/Newbuildings/RequestToDeveloper/RequestDistribution/Edit.vue'),
      props: (route:any) => ({ id: route.params.id })
    },
    {
      path: 'create',
      name: `${RouteKeys.requestDistribution}-create`,
      meta: { name: 'Добавление запроса распределения', icon: 'home', hideInMenu: true, hText: 'Добавление Записи распределения' },
      component: () => import('@/views/Newbuildings/RequestToDeveloper/RequestDistribution/Edit.vue'),
      props: (route:any) => ({ id: Number(route?.params?.id || 0) })
    }
  ]
}
