<template>
  <div class="d-flex">
    <!--    {{userEmailFoldersDict}}-->
    <!--    {{valueFolder}}-->
    <v-select
      v-model="selectedEmail"
      attach
      :disabled="disabled"
      class="pa-0 ml-3"
      :solo="!disabled"
      :outlined="disabled"
      :reverse="false"
      :menu-props="{ top: false, offsetY: true }"
      :clearable="false"
      full-width
      :rounded="false"
      :loading="false"
      hide-no-data
      hide-details
      :dense="true"
      hide-selected
      :items="userEmails"
      label="Email"
      no-data-text="Нет вариантов для выбора"
    />
    <v-select
      v-model="selectedFolderId"
      attach
      :disabled="!selectedEmail || disabled"
      class="pa-0 ml-3"
      :solo="!disabled"
      :outlined="disabled"
      :reverse="false"
      :menu-props="{ top: false, offsetY: true }"
      :clearable="false"
      full-width
      :rounded="false"
      :loading="false"
      hide-no-data
      hide-details
      :dense="true"
      hide-selected
      :items="userEmailFoldersDict[selectedEmail]"
      item-text="label"
      item-value="value"
      label="Папка"
      no-data-text="Нет вариантов для выбора"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'EmailBoxAndFolderSelector',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    valueFolder: {
      default: ''
    }
  },
  data () {
    return {
      email: ''
    }
  },
  computed: {
    ...mapGetters({
      UserEmailConfigs: 'profile/UserEmailConfigs'
    }),
    selectedEmail: {
      get () {
        // console.warn('get', this.email)
        if (!this.email && this.userEmails.length) {
          return this.userEmails[0]
        }
        return this.email
      },
      set (val) {
        // console.warn('set', val)
        this.email = val
        this.$emit('inputFolder', this.userEmailFoldersDict[this.selectedEmail]?.[0]?.value || null)
        this.$emit('changeFolder')
      }
    },
    selectedFolderId: {
      get () {
        // console.warn('get', this.value)
        if (!this.valueFolder && Array.isArray(this.userEmailFoldersDict[this.selectedEmail]) && this.userEmailFoldersDict[this.selectedEmail].length) {
          this.$emit('inputFolder', this.userEmailFoldersDict[this.selectedEmail][0].value)
          this.$emit('changeFolder')
        }
        return this.valueFolder
      },
      set (val) {
        // console.warn('set', val)
        this.$emit('inputFolder', val)
        this.$emit('changeFolder')
      }
    },
    userEmailFoldersDict () {
      const result = {}
      for (const emailConfig of this.UserEmailConfigs) {
        result[emailConfig.email] = []
        for (const folder of emailConfig.emailFolders) {
          result[emailConfig.email].push({
            label: folder.type?.label || folder.name,
            value: folder.id
          })
        }
      }
      return result
    },
    userEmails () {
      const result = []
      for (const item of this.UserEmailConfigs) {
        result.push(item.email)
      }
      return result
    }
  }
}
</script>
