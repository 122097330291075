<template>
  <label
    class="tw-ml-[8px] tw-font-manrope tw-font-medium tw-flex tw-gap-[8px] tw-items-center tw-text-left"
    :class="[dense ? 'tw-text-[11px]' : 'tw-text-main-xs']"
  >
    <div
      class="tw-relative tw-h-[16px] tw-w-[16px] tw-min-w-[16px]"
      :class="[dense ? ' tw-h-[16px] tw-w-[16px] tw-min-w-[16px]' : 'tw-h-[18px] tw-w-[18px] tw-min-w-[18px]']"
    >
      <input
        :id="uid"
        type="checkbox"
        :readonly="readonly"
        :disabled="disabled"
        :checked="value"
        :multiple="multi"
        :class="[value ? backgroundAndBorderColor :'tw-bg-white tw-border-an-flat-checkbox-border', radio ? 'tw-rounded-full' : 'tw-rounded-xs', dense ? ' tw-h-[16px] tw-w-[16px]' : 'tw-h-[18px] tw-w-[18px]', (!disabled && !readonly) ? 'hover:tw-cursor-pointer' : '']"
        class="tw-absolute tw-peer tw-appearance-none tw-border tw-border-solid tw-outline-none disabled:tw-opacity-[30%] disabled:tw-cursor-default"
        @input="onInput"
      >
      <svg
        v-show="value"
        class="tw-pointer-events-none tw-absolute tw-top-1/2 tw-left-1/2 tw-transform -tw-translate-x-1/2 -tw-translate-y-1/2"
        width="10"
        height="8"
        viewBox="0 0 10 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.44434 3.59303L3.70696 5.6671L8.55545 1.22266"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
        />
      </svg>
    </div>
    <span
      class="tw-select-none"
      :class="disabled || readonly ? 'tw-cursor-default tw-text-an-flat-disabled-text':'tw-cursor-pointer tw-text-an-flat-black-main'"
    >
      {{ label }}
    </span>
    <slot name="icon" />
  </label>
</template>

<script>
export default {
  name: 'TwCheckBox',
  props: {
    value: {
      type: [Boolean, Number],
      default: false
    },
    returnNumber: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    radio: {
      type: Boolean,
      default: false
    },
    multi: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    backgroundAndBorderColor: {
      type: String,
      default: 'tw-bg-an-flat-red-main tw-border-an-flat-red-main'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    uid () {
      return `${this.$options.name}_${this._uid}`
    }
  },
  methods: {
    onInput (val) {
      // console.warn(this.$options.name, val.target.checked)
      if (this.returnNumber) {
        this.$emit('input', val?.target?.checked ? 1 : 0)
      } else {
        if (typeof this.value === 'boolean') {
          // если булеан то просто переворачиваю значение
          this.$emit('input', !this.value)
        } else {
          // в ином случае снимаю показание чекеда поля ввода
          this.$emit('input', !this.value || !!val?.target?.checked)
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
