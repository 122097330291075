<template>
  <div class="tw-font-manrope">
    <RealtyComplexPage :id="id" />
  </div>
</template>

<script>
import RealtyComplexPage from '@/views/Newbuildings/Complex/RealtyComplexPage/RealtyComplexPage.vue'

export default {
  name: 'RealtyComplexPageCard',
  components: { RealtyComplexPage },
  props: {
    id: {
      type: [Number, String],
      default: 0
    },
    fromPanel: {
      type: Boolean,
      default: false
    },
    workToClose: {
      type: Boolean,
      default: false
    },
    copyData: {
      type: Object,
      default: null
    }
  }
}
</script>
