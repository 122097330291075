<template>
  <!--  items пустой массив потому что полеввода даты расположено в слоте no-data -->
  <v-combobox
    v-model="selectedItems"
    attach=""
    :items="[]"
    chips
    :label="label"
    :placeholder="placeholder"
    multiple
    outlined
    dense
  >
    <template #no-data>
      <div class="py-2 px-4 d-flex align-baseline">
        <!--        <span class="mr-2">Введите дату:</span>-->
        <NativeDateInputField
          v-model="tmpDate"
          type="date"
          :disabled="disabled"
          outlined
          hide-details
          dense
          autocomplete="off"
          @keydown.enter="add"
        />
        <v-btn
          :disabled="!tmpDate"
          color="success"
          small
          text
          @click="add"
        >
          Добавить
        </v-btn>
      </div>
    </template>
    <template #selection="{ attrs, item, select, selected }">
      <v-chip
        class="ma-1"
        small
        outlined
        v-bind="attrs"
        :input-value="selected"
        color="secondary"
        close
        @click="select"
        @click:close="remove(item)"
      >
        <strong>
          {{ $Global.DateFormat.DateShort(item) }}
        </strong>&nbsp;
      </v-chip>
    </template>
  </v-combobox>
</template>

<script>
import NativeDateInputField from '@/components/input/NativeDateInputField'
export default {
  name: 'DatesArrayInput',
  components: { NativeDateInputField },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: 'Даты'
    },
    placeholder: {
      type: String,
      default: 'Добавьте даты'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      tmpDate: ''
    }
  },
  computed: {
    selectedItems: {
      get () {
        return this.value
      },
      // set - не действует на элементы, но всё рабртает благодаря ссылочности(мутации) массива value
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  mounted () {
    if (!Array.isArray(this.selectedItems)) {
      this.selectedItems = []
    }
  },
  methods: {
    add () {
      if (this.tmpDate && new Date(this.tmpDate).toString() !== 'Invalid Date') {
        this.selectedItems.push(this.tmpDate)
        this.tmpDate = ''
      } else {
        console.error('Не валидная дата tmpDate', this.tmpDate)
      }
    },
    remove (item) {
      this.selectedItems.splice(this.selectedItems.indexOf(item), 1)
      this.selectedItems = [...this.selectedItems]
    }
  }
}
</script>

<style scoped>

</style>
