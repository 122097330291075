<template>
  <v-row dense>
    <v-col cols="12">
      <v-text-field
        ref="number"
        v-model="fields.number"
        :rules="numberRules"
        counter
        clearable
        class="mt-3"
        label="Номер"
        type="number"
        autocomplete="off"
      />
    </v-col>
  </v-row>
</template>

<script>
import validateInn from '@/plugins/helpers/validateInn'

export default {
  name: 'InnType',
  props: {
    // набор значений fields из формы
    formType: {},
    value: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      numberRules: [
        (v) => {
          const errors = {}
          validateInn(v, errors) // валидный пример 7743880975
          console.log(errors)
          return !errors.code || errors.message
        }
      ]
    }
  },
  computed: {
    fields: {
      get () {
        return this.value
      }
    }
  }
}
</script>
