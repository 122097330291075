import ProgramCategory from './program-category'

export default {
  path: 'mortgage',
  name: 'mortgage-dictionary',
  redirect: { name: 'program-list' },
  meta: { name: 'Ипотека', icon: 'books-mortgage', isAdmin: true },
  component: () => import('@/views/Dictionary/Index.vue'),
  children: [
    ProgramCategory
  ]
}
