<template>
  <section>
    <h3 class="tw-text-an-flat-black-main tw-font-bold tw-text-[32px] tw-leading-[44px]">
      Ипотечные программы
    </h3>
    <div>
      <SubsidizedProgramsListView :complex-id="complexId" />
    </div>
  </section>
</template>

<script>
import SubsidizedProgramsListView from '@/views/Newbuildings/Complex/subsidizedPrograms/SubsidizedProgramsListView.vue'

export default {
  name: 'PageComplexBlockMortgage',
  components: { SubsidizedProgramsListView },
  props: {
    complexId: {
      type: String,
      default: null
    }
  }
}
</script>

<style scoped>

</style>
