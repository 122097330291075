<template>
  <div class="tw-w-full">
    <div
      v-if="Array.isArray(filesImages)"
      class="tw-flex tw-flex-wrap tw-justify-end"
    >
      <div
        v-for="(file, f_index) in filesImages"
        :key="file.id"
        class="tw-m-[6px]"
      >
        <img
          class="cursor-pointer"
          alt="Прикрепленный файл"
          style="border: 1px solid lightgrey"
          width="40"
          height="40"
          :src="file.path"
          @click="imageShow(f_index)"
        >
        <small
          v-if="file.size"
          class="text-muted"
        >
          {{ $Global.formatBytes(file.size) }}
        </small>
      </div>
    </div>
    <div
      v-if="Array.isArray(filesForDownloads)"
      class="tw-flex tw-flex-wrap tw-justify-end"
    >
      <div
        v-for="(file) in filesForDownloads"
        :key="file.id"
      >
        <div
          class="tw-flex tw-items-center file-download-link tw-p-[5px] tw-whitespace-nowrap"
          style="line-height: normal"
          @click="download(file)"
        >
          <small>
            <span
              class="tw-truncate"
              style="max-width: 190px;"
            >{{ file.name }}</span>
            <span
              v-if="file.size"
              class="tw-text-an-flat-disabled-text"
            >
              {{ $Global.formatBytes(file.size) }}
            </span>
          </small>
        </div>
      </div>
    </div>
    <span
      v-if="!filesImages.length && !filesForDownloads.length"
      class="tw-text-an-flat-disabled-text"
    >Отсутствуют</span>
  </div>
</template>

<script>
import API from '@/Api/Support/File'
import FileShowMixin from '@/mixins/FileShowMixin'

export default {
  name: 'SupportFilesAttached',
  mixins: [FileShowMixin],
  data () {
    return {
      API: API
    }
  }
}
</script>
