import { AxiosResponse } from 'axios'
import HTTP from '@/Api/http'
import { AuthAxiosDefaultConfig } from '@/Api/BuilderImporter'

const path = '/auto-import/file-check'

export default {
  preCheck (data: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig,
      timeout: 300000
    }
    return HTTP.post(`${path}/pre-check`, data, $config)
  },
  show (data: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig,
      timeout: 300000
    }
    return HTTP.post(`${path}/show`, data, $config)
  }
}
