<template>
  <div>
    <div class="tw-flex tw-flex-row tw-gap-[8px] tw-w-full tw-mb-[8px]">
      <button
        v-for="(person, index) of personsList"
        :key="index"
        :disabled="currentPersonId === person.id"
        :class="currentPersonId === person.id ? 'tw-bg-an-flat-blue-btn3 tw-border-an-flat-dark-blue-link' : 'tw-bg-white hover:tw-bg-an-flat-blue-btn3 hover:tw-border-an-flat-blue-btn3'"
        class="tw-w-fit tw-flex tw-flex-row tw-gap-[10px] tw-items-center tw-px-[23px] tw-pt-[16px] tw-pb-[17px] tw-rounded-md tw-border tw-border-solid"
        @click.prevent="currentPersonId = person.id"
      >
        <div>
          <svg
            width="34"
            height="35"
            viewBox="0 0 34 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g>
              <circle
                :class="{'tw-fill-an-flat-dark-blue-link' : currentPersonId === person.id, 'tw-opacity-30' : currentPersonId !== person.id}"
                cx="17"
                cy="17.5"
                r="17"
                fill="#545961"
              />
              <g clip-path="url(#clip0_427_3019)">
                <path
                  d="M16.9995 26.6416C22.0076 26.6416 26.0674 22.5817 26.0674 17.5737C26.0674 12.5657 22.0076 8.50574 16.9995 8.50574C11.9914 8.50574 7.93164 12.5656 7.93164 17.5736C7.93164 22.5816 11.9914 26.6416 16.9995 26.6416ZM19.8693 13.9256C20.3318 13.9256 20.7068 14.3006 20.7068 14.7631C20.7068 15.2256 20.3318 15.6006 19.8693 15.6006C19.4067 15.6006 19.0317 15.2256 19.0317 14.7631C19.0317 14.3006 19.4067 13.9256 19.8693 13.9256ZM14.1298 13.9256C14.5923 13.9256 14.9673 14.3006 14.9673 14.7631C14.9673 15.2256 14.5923 15.6006 14.1298 15.6006C13.6672 15.6006 13.2922 15.2256 13.2922 14.7631C13.2922 14.3006 13.6672 13.9256 14.1298 13.9256ZM12.6143 18.8573C13.0622 18.7423 13.5188 19.0122 13.6338 19.4602C13.9838 20.8232 15.4661 21.8908 17.0086 21.8908C18.5152 21.8908 19.9912 20.8169 20.369 19.446C20.4918 19.0001 20.9529 18.7383 21.3989 18.861C21.8448 18.9839 22.1067 19.445 21.9839 19.8909C21.4067 21.9859 19.2678 23.5658 17.0086 23.5658C14.6998 23.5658 12.5514 21.9798 12.0114 19.8768C11.8964 19.4288 12.1663 18.9724 12.6143 18.8573Z"
                  fill="white"
                />
              </g>
            </g>
            <defs>
              <clipPath id="clip0_427_3019">
                <rect
                  width="18"
                  height="18"
                  fill="white"
                  transform="translate(8 8.5)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div>
          <div
            class="tw-mb-[2px] tw-text-left tw-text-[12px] tw-font-semibold"
            :class="{'tw-text-an-flat-blue-btn4': currentPersonId === person.id, 'tw-text-an-flat-not-active-input': currentPersonId !== person.id}"
          >
            <div v-if="index === 0">
              <span class="tw-block tw-uppercase">основной клиент</span>
            </div>
            <div v-else>
              <span class="tw-block tw-uppercase">созаемщик {{ index }}</span>
            </div>
          </div>
          <div
            class="tw-text-left tw-font-semibold tw-text-[14px]"
            :class="{'tw-text-an-flat-dark-blue-link': currentPersonId === person.id, 'tw-text-an-flat-black-table': currentPersonId !== person.id}"
          >
            {{ $Global.peopleName(person, 'fio') }}
          </div>
        </div>
      </button>
    </div>
    <PersonHipotecTab
      v-if="currentPersonId"
      :id="currentPersonId"
      show-main-block
    />
  </div>
</template>

<script>
import PersonHipotecTab from '@/views/Person/hipotec/PersonHipotecTab'
export default {
  name: 'DealPersonsTab',
  components: { PersonHipotecTab },
  props: {
    dealData: {
      required: true
    }
  },
  data () {
    return {
      currentPersonId: this.dealData?.person?.id || null
    }
  },
  computed: {
    personsList () {
      return [this.dealData?.person, ...this.dealData?.otherPersons]
    }
  }
}
</script>

<style scoped>

</style>
