<template>
  <div>
    <TwSelectRelations
      v-model="form.complexId"
      class="tw-mt-[12px]"
      :disabled="formProcessing"
      label="Все ЖК"
      list-label="Выбрать ЖК"
      relation-name="complex"
      :default-filters="complexFilters"
    />
    <div>
      <TwTextarea
        v-model="form.showTerms"
        rows="5"
        class="tw-mt-[12px]"
        placeholder="Условия показа"
        :disabled="formProcessing"
        :error-text="$v.form.showTerms.$invalid ? 'Заполните поле':''"
        :show-label="true"
      />
      <TwTextarea
        v-model="form.payTerms"
        rows="5"
        class="tw-mt-[12px]"
        placeholder="Условия оплаты"
        :disabled="formProcessing"
        :show-label="true"
      />
    </div>
    <div>
      <TwCardBlockFormActions
        :title-submit="formType === 'create' ? 'Создать' : 'Изменить'"
        :handle-submit="handleSubmit"
        :handle-cancel="handleCancel"
        :disable-submit="formProcessing || $v.form.$invalid"
        :disable-cancel="formProcessing"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { required } from 'vuelidate/lib/validators'
import API from '@/Api/Rtd/Company/DeveloperTerms'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import TwSelectRelations from '@/components/tw-ui/ui-kit/input/select-relations/TwSelectRelations.vue'
import TwTextarea from '@/components/tw-ui/ui-kit/input/TwTextarea.vue'
import TwCardBlockFormActions from '@/components/core/ViewPanel/Card/TwCardBlockFormActions.vue'

export default defineComponent({
  name: 'BuilderDeveloperTermsForm',
  components: { TwCardBlockFormActions, TwTextarea, TwSelectRelations },
  mixins: [FormCreateEditMixin],
  props: {
    companyId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      API: API,
      form: {}
    }
  },
  validations () {
    return {
      form: {
        showTerms: {
          required
        }
      }
    }
  },
  computed: {
    complexFilters () {
      return {
        builderId: this.companyId ? [this.companyId] : []
      }
    }
  },
  methods: {
    handleSubmit () {
      this.$v.$reset()
      this.$v.form.$touch()
      if (this.formProcessing === true) {
        return
      }
      if (!this.$v.form.$invalid) {
        this.formProcessing = true
        this.form.companyId = this.companyId
        const request = this.formType === 'create'
          ? this.API.store(this.form)
          : this.API.update(this.targetId, this.form)

        request.then(({ data }) => {
          if (this.formType === 'create') {
            this.$emit('create', data.data)
          } else {
            this.$emit('update', data.data)
          }
        }).finally(() => {
          this.formProcessing = false
        })
      }
    },
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          for (const key in this.preset) {
            this.$set(this.form, key, this.preset[key])
          }
        }
        resolve()
      })
    }
  }
})
</script>

<style scoped>

</style>
