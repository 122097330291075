<template>
  <div style="display: flex; place-items: center">
    <div class="a-filter-input-field-block">
      <input
        :max="internalValue.to"
        :value="internalValue.from"
        placeholder="с"
        autocomplete="off"
        type="date"
        @input="onInput($event, 0)"
      >
    </div>
    <div style="margin: auto 5px">
      -
    </div>
    <div class="a-filter-input-field-block">
      <input
        :min="internalValue.from"
        :value="internalValue.to"
        placeholder="по"
        autocomplete="off"
        type="date"
        @input="onInput($event, 1)"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'ADateRangeSelect',
  props: {
    selectFirst: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Array, null],
      default: () => null
    }
  },
  data () {
    return {
      internalValue: {
        from: '',
        to: ''
      }
    }
  },
  watch: {
    value: {
      handler (newValue) {
        // this.internalValue = newValue
        if (Array.isArray(newValue) && newValue.length === 2) {
          this.internalValue = {
            from: newValue[0],
            to: newValue[1]
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted () {
    if (!Array.isArray(this.value) || (Array.isArray(this.value) && this.value.length !== 2)) {
      const currDate = new Date().toISOString().slice(0, 10)
      this.$emit('input', [currDate, currDate])
    }
  },
  methods: {
    onInput (event, index) {
      // console.log('onInput this.value ', this.value)
      // console.log('onInput ', index, event.target.value)
      const newValue = this.value
      newValue[index] = event.target.value
      if (index === 0) {
        this.internalValue.from = event.target.value
      } else {
        this.internalValue.to = event.target.value
      }
      // console.log('onInput newValue ', newValue)
      this.$emit('input', newValue)
    }
  }
}
</script>

<style scoped lang="less">
@import "../a-select";
</style>
