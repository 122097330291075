import { AxiosResponse } from 'axios'
import HTTP from '@/Api/http'

const path = '/deal-bounce-handling'

export default {
  listInit (): Promise<AxiosResponse> {
    const $config: any = {}
    return HTTP.get(`${path}/by-deal/list-init`, $config)
  },
  getList (param?: object): Promise<AxiosResponse> {
    const $config = {
      params: param
    }
    return HTTP.get(`${path}/by-deal`, $config)
  },
  getListFromDeal (param?: object): Promise<AxiosResponse> {
    const $config = {
      params: param
    }
    return HTTP.get(`${path}`, $config)
  },
  update (id: number, data: object): Promise<AxiosResponse> {
    return HTTP.put(`${path}/${id}`, data)
  },
  show (id: number): Promise<AxiosResponse> {
    const $config = {}
    return HTTP.get(`${path}/${id}`, $config)
  }
}
