import { Module } from 'vuex'
import { RootState } from '@/store/types'
import Vue from 'vue'
import TableConfigApi from '@/Api/Crm/InterfaceSettings/TableView'

const namespaced = true

const baseConfigData = {
  code: null,
  fields: null,
  limit: 25,
  sortDirection: null,
  sortField: null
}

export const tableConfig: Module<any, RootState> = {
  namespaced,
  state: {},
  actions: {
    readTableConfig ({ commit }, schemaKey: any) {
      return new Promise((resolve) => {
        console.warn('fetchTableConfig', schemaKey)
        commit('SET_TABLE_LOADING', { schemaKey, loading: true })
        TableConfigApi.show(schemaKey).then(({ data }) => {
          commit('SET_TABLE_CONFIG', {
            schemaKey,
            config: {
              ...baseConfigData,
              code: schemaKey,
              ...(data.data)
            }
          })
          resolve(data.data)
        }).catch((error) => {
          console.error('InterfaceSettings/TableView - error show! ', error)
          commit('SET_TABLE_CONFIG', {
            schemaKey,
            config: {
              ...baseConfigData,
              code: schemaKey
            }
          })
          resolve(null)
        })
      }).finally(() => {
        commit('SET_TABLE_LOADING', { schemaKey, loading: false })
      })
    },
    writeTableConfig ({ state, commit }, schemaKey: any) {
      return new Promise((resolve) => {
        commit('SET_TABLE_LOADING', { schemaKey, loading: true })
        TableConfigApi.store(state[schemaKey]).then(async ({ data }) => {
          // await dispatch('readTableConfig', schemaKey)
          commit('SET_TABLE_CONFIG', {
            schemaKey,
            config: {
              ...baseConfigData,
              code: schemaKey,
              ...(data.data)
            }
          })
          resolve(data.data)
        }).catch((error) => {
          console.error('InterfaceSettings/TableView - error store! ', error)
          commit('SET_TABLE_CONFIG', {
            schemaKey,
            config: {
              ...baseConfigData,
              code: schemaKey,
              ...(state[schemaKey])
            }
          })
          resolve(null)
        }).finally(() => {
          commit('SET_TABLE_LOADING', { schemaKey, loading: false })
        })
      })
    },
    resetTableConfig ({ dispatch }, schemaKey: any) {
      return new Promise((resolve) => {
        TableConfigApi.delete(schemaKey).then(async () => {
          await dispatch('readTableConfig', schemaKey)
          resolve(true)
        }).catch((error) => {
          console.error('InterfaceSettings/TableView - error delete! ', error)
          resolve(null)
        })
      })
    }
  },
  mutations: {
    SET_TABLE_CONFIG (state, { schemaKey, config = null }) {
      Vue.set(state, schemaKey, config)
    },
    SET_TABLE_LOADING (state, { schemaKey, loading = false }) {
      if (!state?.[schemaKey]) {
        Vue.set(state, schemaKey, { loading: loading })
      }
      Vue.set(state[schemaKey], 'loading', loading)
    },
    SET_FIELDS_CONFIG (state, { schemaKey, fields = null }) {
      Vue.set(state[schemaKey], 'fields', fields)
    },
    SET_SORT_CONFIG (state, { schemaKey, sortDirection = null, sortField = null }) {
      Vue.set(state[schemaKey], 'sortField', sortField)
      Vue.set(state[schemaKey], 'sortDirection', sortDirection)
    },
    SET_LIMIT_CONFIG (state, { schemaKey, limit = 25 }) {
      Vue.set(state[schemaKey], 'limit', limit)
    }
  },
  getters: {
    currentTableConfigBySchemaKey: (state) => (schemaKey: string) => {
      return state?.[schemaKey] || null
    },
    configLoadingByKey: (state) => (schemaKey: string) => {
      return !!state?.[schemaKey]?.loading
    }
  }
}
