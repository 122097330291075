/* eslint-disable import/prefer-default-export */
import { RootState } from '@/store/types'
import { get } from 'local-storage'

export const state: RootState = {
  pageTitle: 'no title',
  prevPage: undefined,
  deploymentType: 'local',
  apiUrl: '',
  updates: {
    exist: false,
    uiVisible: false
  },
  version: '1.0.0',
  loadingPage: false,
  errorPage: false,
  openedLeftPanelItems: [],
  breadcrumbs: [],
  isMiniLeftPanel: get('isMiniLeftPanel') || false,
  isViewWithClient: get('isViewWithClient') || false
}
