<template>
  <div>
    <div style="display: none">
      {{ isEdited }}
    </div>
    <TwFlexCol>
      <TwFlexCell>
        <TwInputText
          v-model="form.name"
          type="text"
          label="Название"
          placeholder="Введите Название"
          :disabled="formProcessing"
          :dense="true"
          :disable-error-border="false"
          :show-label="true"
          :error-text="!form.name ? 'Обязательное поле':''"
          :persistent-label="true"
        />
      </TwFlexCell>
      <TwFlexCell>
        <TwInputText
          v-model="form.date"
          type="date"
          label="Документ с даты"
          placeholder="Документ с даты"
          :persistent-label="true"
          :show-label="true"
          :error-text="!form.date ? 'Обязательное поле' : ''"
          :dense="true"
          :max="new Date().toISOString().slice(0, 10)"
          min="1950-01-01"
        />
      </TwFlexCell>
      <TwFlexCell>
        <TwInputRadio
          v-model="form.section"
          :disabled="formProcessing"
          :dense="true"
          :show-label="true"
          label="Раздел"
          :options="dictionary.section"
          :error-text="form.section ? '' : 'Обязательное поле'"
        />
      </TwFlexCell>
      <TwFlexCell>
        <TwInputRadio
          v-if="form.section && Array.isArray(subsectionOptions) && subsectionOptions.length"
          v-model="form.subsection"
          :disabled="formProcessing"
          :dense="true"
          :show-label="true"
          label="Группа документов"
          :options="subsectionOptions"
          :error-text="form.subsection ? '' : 'Обязательное поле'"
        />
      </TwFlexCell>
      <TwFlexCell v-if="form.subsection && form.subsection !== 'himself'">
        <TwSelectRelations
          v-if="form.subsection === 'bank'"
          v-model="form.entityId"
          :multi="false"
          :dense="true"
          :persistent-label="true"
          label="Банк"
          placeholder="Выберите банк"
          list-label="Найти банк"
          relation-name="bank"
          :disabled="formProcessing"
          :error-text="form.entityId ? '' : 'Обязательное поле'"
        />
        <TwSelectRelations
          v-if="form.subsection === 'program'"
          v-model="form.entityId"
          :multi="false"
          :dense="true"
          :persistent-label="true"
          label="Программа кредитования"
          placeholder="Выбрать"
          list-label="Найти"
          relation-name="mortgage-program"
          :disabled="formProcessing"
          :error-text="form.entityId ? '' : 'Обязательное поле'"
        />
      </TwFlexCell>
      <TwFlexCell>
        <TwUploadFilesList
          label="Загрузить шаблон документа"
          no-data-text="Нет загруженного документа"
          accept=".docx"
          :value="form.filesId || null"
          :is-public="true"
          :multiple="false"
          :disabled="formProcessing"
          @input="form.filesId = $event || null"
        />
      </TwFlexCell>
      <TwFlexCell>
        <TwCheckBox
          v-model="form.active"
          label="Активность"
          background-and-border-color="tw-bg-an-flat-green-btn-bg tw-border-an-flat-green-btn-bg"
          :disabled="formProcessing"
        />
      </TwFlexCell>
      <TwFlexCell>
        <SectionFieldsView
          v-if="form.subsection && subsectionFields && Array.isArray(subsectionFields.subsection)"
          :info="subsectionFields"
        />
      </TwFlexCell>
    </TwFlexCol>

    <TwCardSimpleFormSaveBlock
      class="tw-mt-[15px]"
      :loading="formProcessing"
      bg-color="transparent"
      :cancel-confirm="true"
      :is-sticky="false"
      :visible="true"
      @success="handleSubmit"
      @cancel="handleCancel"
    />
  </div>
</template>

<script>
import API from '@/Api/Crm/Dictionary/General/Document/index'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import { RouteKeys } from '@/router/types'
import SectionFieldsView from '@/views/Dictionary/General/Document/main/SectionFieldsView/SectionFieldsView.vue'
import TwCardSimpleFormSaveBlock from '@/components/tw-ui/forms/TwCardSimpleFormSaveBlock.vue'
import TwFlexCol from '@/components/tw-ui/ui-kit/flex-block/TwFlexCol.vue'
import TwFlexCell from '@/components/tw-ui/ui-kit/flex-block/TwFlexCell.vue'
import TwInputText from '@/components/tw-ui/ui-kit/input/TwInputText.vue'
import TwInputRadio from '@/components/tw-ui/ui-kit/input/TwInputRadio.vue'
import TwSelectRelations from '@/components/tw-ui/ui-kit/input/select-relations/TwSelectRelations.vue'
import TwUploadFilesList from '@/components/tw-ui/ui-kit/files/TwUploadFilesList.vue'
import TwCheckBox from '@/components/tw-ui/ui-kit/input/TwCheckBox.vue'

export default {
  name: 'DocumentMainForm',
  components: {
    TwCheckBox,
    TwUploadFilesList,
    TwSelectRelations,
    TwInputRadio,
    TwInputText,
    TwFlexCol,
    TwFlexCell,
    TwCardSimpleFormSaveBlock,
    SectionFieldsView
  },
  mixins: [FormCreateEditMixin],
  props: {
    disableRedirect: {
      type: Boolean,
      default: false
    },
    defaultFormFields: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      API: API,
      form: {
        id: 0,
        active: true,
        section: null, // mortgage
        subsection: null,
        name: null,
        date: null,
        entityId: null,
        filesId: []
      }
    }
  },
  computed: {
    subsectionFields () {
      if (this.form.section && this.dictionary && Array.isArray(this.dictionary.section)) {
        const targetSection = this.dictionary.section.find(s => s.value === this.form.section)
        if (targetSection.subsection && this.form.subsection) {
          const subSection = targetSection.subsection.find(s => s.value === this.form.subsection)
          return {
            label: 'Назначения полей - ' + subSection.label,
            fields: [],
            subsection: subSection.subsection || []
          }
        }
      }
      return {}
    },
    subsectionOptions () {
      if (this.form.section && this.dictionary && Array.isArray(this.dictionary.section)) {
        const targetSection = this.dictionary.section.find(s => s.value === this.form.section)
        if (targetSection) {
          return targetSection.subsection
        }
      }
      return []
    }
  },
  watch: {
    'form.subsection' (newVal, oldVal) {
      if (oldVal && newVal !== oldVal) {
        this.form.entityId = null
      }
    }
  },
  methods: {
    handleResetCustom () {
      this.handleReset()
    },
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          const adaperMap = {
            // from(show): to(send)
            files: 'filesId',
            entity: 'entityId'
          }
          for (const key in this.preset) {
            if (adaperMap[key] !== undefined) {
              if (key === 'files' && (!this.preset[key] || !Array.isArray(this.preset[key]) || !this.preset[key].length)) {
                this.$set(this.form, adaperMap[key], null)
              } else if (key === 'files' && (Array.isArray(this.preset[key]) && this.preset[key].length)) {
                this.$set(this.form, adaperMap[key], this.preset[key].map(file => file.id))
              }
              if (key === 'entity') {
                this.$set(this.form, adaperMap[key], (this.preset[key] ? this.preset[key].id : this.preset[adaperMap[key]]))
              }
            } else {
              if (key === 'subsection' && this.preset[key]) {
                this.$set(this.form, key, this.preset[key].value)
              } else if (key === 'section' && this.preset[key]) {
                this.$set(this.form, key, this.preset[key].value)
              } else {
                this.$set(this.form, key, this.preset[key])
              }
            }
          }
        }
        resolve()
      })
    },
    handleSubmit () {
      if (!Array.isArray(this.form.filesId) || !this.form.filesId.length || !this.form.filesId[0]) {
        return alert('Загрузите файл')
      }
      if (!this.form.name) {
        return alert('Введите имя')
      }
      if (this.formProcessing === true) {
        return
      }

      this.formProcessing = true

      const request = this.formType === 'create'
        ? this.API.store(this.form)
        : this.API.update(this.targetId, this.form)

      request.then(({ data }) => {
        if (this.formType === 'create') {
          this.$emit('create', data.data)
          // если текущий путь равен пути создания то редирект в просмотр
          if (this.$route.name === `${RouteKeys.document}-create`) {
            this.$router.replace({
              name: `${RouteKeys.document}-show`,
              params: { id: data.data.id }
            })
          }
        } else {
          this.$emit('update', data.data)
        }
      }).finally(() => {
        this.formProcessing = false
      })
    }
  }
}
</script>
