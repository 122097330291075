<template>
  <v-form
    ref="form"
    class="tw-flex tw-flex-col tw-gap-[22px]"
  >
    <div style="display: none">
      {{ isEdited }}
    </div>
    <TwSelectRelations
      v-model="form.companyId"
      :multi="false"
      :dense="denseFields"
      :persistent-label="true"
      label="Кредитная организация"
      placeholder="Кредитная организация"
      list-label="Кредитная организация"
      relation-name="bank"
      :error-text="companyIdErrors.length > 0 ? companyIdErrors[0] : ''"
      :disabled="formProcessing"
    />
    <v-text-field
      ref="payMonth"
      class="pb-2"
      label="Ежемесячный платеж"
      require
      outlined
      type="text"
      autocomplete="off"
      suffix="руб."
      :dense="denseFields"
      :value="payMonthSpaced"
      :hide-details="!payMonthErrors.length"
      :error-messages="payMonthErrors"
      @input="onInputPayMonth"
      @focus="$event.target.select()"
      @blur="$v.form.payMonth.$touch()"
    />

    <TwCardSimpleFormSaveBlock
      class="tw-mt-[15px]"
      :loading="formProcessing"
      bg-color="transparent"
      :cancel-confirm="true"
      :is-sticky="false"
      :visible="true"
      @success="handleSubmit"
      @cancel="handleCancel"
    />
  </v-form>
</template>

<script>
import API from '@/Api/Crm/Person/Credit/index'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import { required } from 'vuelidate/lib/validators'
import TwSelectRelations from '@/components/tw-ui/ui-kit/input/select-relations/TwSelectRelations.vue'
import TwCardSimpleFormSaveBlock from '@/components/tw-ui/forms/TwCardSimpleFormSaveBlock.vue'

export default {
  name: 'PersonCreditForm',
  components: { TwSelectRelations, TwCardSimpleFormSaveBlock },
  mixins: [FormCreateEditMixin],
  props: {
    personId: {
      type: Number,
      required: true
    },
    denseFields: {
      type: Boolean,
      default: false
    },
    disableRedirect: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      API: API,
      form: {
        id: null,
        personId: this.personId,
        companyId: null,
        payMonth: 0
      }
    }
  },
  computed: {
    payMonthSpaced () {
      return ((this.form.payMonth && this.form.payMonth) || 0).toLocaleString()
    },
    payMonthErrors () {
      const errors = []
      if (!this.$v.form.payMonth.$dirty) return errors
      if (!this.$v.form.payMonth.required) {
        errors.push('Поле обязательное(положительное число)')
        this.$Global.scrollToElem(this.$refs.payMonth.$el)
      }
      return errors
    },
    companyIdErrors () {
      const errors = []
      if (!this.$v.form.companyId.$dirty) return errors
      if (!this.$v.form.companyId.required) {
        errors.push('Поле обязательное')
      }
      return errors
    }
  },
  validations: {
    form: {
      payMonth: {
        required: (v) => !!(v && !isNaN(parseFloat(v)) && parseFloat(v) && parseFloat(v) > 0)
      },
      companyId: {
        required
      }
    }
  },
  methods: {
    onInputPayMonth (value) {
      // eslint-disable-next-line no-irregular-whitespace
      this.form.payMonth = (value && parseFloat(value.replace(/[a-z]/g, '').replace(/,/g, '.').replace(/ | +$/g, ''))) || 0
    },
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          const adaperMap = {
            // from(show): to(send)
            person: 'personId',
            company: 'companyId'
          }
          for (const key in this.preset) {
            if (adaperMap[key] !== undefined) {
              if (adaperMap[key].indexOf('Id') !== -1 && (this.preset[key] && this.preset[key].id)) {
                this.$set(this.form, adaperMap[key], this.preset[key].id)
              }
            } else {
              this.$set(this.form, key, this.preset[key])
            }
          }
        }
        resolve()
      })
    },
    handleResetCustom () {
      this.handleReset()
    },
    handleSubmit () {
      // console.warn(this.$options.name, ' handleSubmit ', this.formType, this.targetId)
      this.$v.$reset()
      this.$v.form.$touch()
      if (!this.$v.form.$invalid) {
        if (this.formProcessing === true) {
          return
        }

        this.formProcessing = true
        this.serverErrors = null
        if (this.formType === 'create') {
          this.API.store(this.form)
            .then(({ data }) => {
              console.warn(this.$options.name, ' CREATE ', data)
              // this.fetchData(data.data.id)
              this.$emit('create', data.data)
            })
            .catch((error) => {
              if (error?.response?.data) {
                console.error(this.$options.name, error.response.data)
                for (const key in error.response.data) {
                  const [refKey, index] = key.split('.')
                  // console.log(key, refKey, index, field)
                  if (!this.serverErrors) this.serverErrors = {}
                  if (!this.serverErrors[refKey]) this.serverErrors[refKey] = []
                  this.serverErrors[refKey][index] = error.response.data[key][0]
                }
              }
            })
            .finally(() => {
              this.formProcessing = false
            })
        } else if (this.formType === 'edit' && this.targetId) {
          this.API.update(this.targetId, this.form)
            .then(({ data }) => {
              console.warn(this.$options.name, ' UPDATE ', data.data)
              this.$emit('update', data.data)
            })
            .catch((error) => {
              if (error?.response?.data) {
                console.error(this.$options.name, error.response.data)
                for (const key in error.response.data) {
                  const [refKey, index] = key.split('.')
                  // console.log(key, refKey, index, field)
                  if (!this.serverErrors) this.serverErrors = {}
                  if (!this.serverErrors[refKey]) this.serverErrors[refKey] = []
                  this.serverErrors[refKey][index] = error.response.data[key][0]
                }
              }
            })
            .finally(() => {
              this.formProcessing = false
            })
        }
      }
    }
  }
}
</script>
