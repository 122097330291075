<template>
  <div
    v-cloak
    v-if="info"
    class="tw-flex tw-gap-[12px] tw-flex-col"
  >
    <TwPanelContentBlockFieldView>
      <template #title>
        Статус публикации на сайте и в подборе
      </template>
      <div>
        <div
          v-if="$Global.DateFormat.hasDateExpiration(info.dateTo) || !info.publish"
        >
          Не опубликовано
        </div>
        <div
          v-else
        >
          Опубликовано
        </div>
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Тип предложения
      </template>
      <div v-if="info.type">
        {{ info.type.label }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Застройщик
      </template>
      <div v-if="info.builder">
        {{ info.builder.name || info.builder.code }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Действует
      </template>
      <div v-if="info.dateTo">
        до {{ (new Date(info.dateTo)).toLocaleDateString() }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Для ЖК
      </template>
      <div v-if="info.complexes">
        {{ info.complexes.map(i => i.name).join(', ') }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Описание для риелтора(для CRM)
      </template>
      <div
        v-if="info.crmDescription"
        v-html="$Global.nl2br(info.crmDescription)"
      />
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Название (для сайта)
      </template>
      <div
        v-if="info.siteName"
        v-html="$Global.nl2br(info.siteName)"
      />
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Краткое описание (для сайта)
      </template>
      <div
        v-if="info.siteShortDescription"
        v-html="$Global.nl2br(info.siteShortDescription)"
      />
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Полное описание (для сайта)
      </template>
      <div
        v-if="info.siteDescription"
        v-html="$Global.nl2br(info.siteDescription)"
      />
    </TwPanelContentBlockFieldView>
  </div>
</template>

<script>
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'

export default {
  name: 'RealtyBuilderStockShow',
  components: { TwPanelContentBlockFieldView },
  mixins: [ViewPanelShowMixin],
  props: {
    info: {},
    dictionary: {}
  }
}
</script>
