<template>
  <div
    v-if="user"
    style="display: inline-flex; justify-content: center; align-items: center"
  >
    <template v-if="userName">
      <div v-if="isAvatar">
        <v-img
          v-if="avatar"
          class="mr-2"
          style="border-radius: 50%"
          width="25px"
          height="25px"
          :src="avatar"
        />
        <div
          v-else
          class="mr-2 d-flex justify-center align-center"
          style="width: 25px; height: 25px;border-radius: 50%; background-color: darkgray"
        >
          <v-icon
            size="18px"
            dark
          >
            fa-user
          </v-icon>
        </div>
      </div>
      <div
        v-if="isName"
        :style="isStyleName"
      >
        {{ userName }}
      </div>
    </template>
    <span
      v-else-if="!user.hasError"
      class="tw-text-an-flat-disabled-text"
    >
      Загрузка
    </span>
    <span
      v-else
      class="tw-text-an-flat-disabled-text"
      @click.stop="onClickError"
    >
      Ошибка
    </span>
  </div>
</template>

<script>
import { employeeAvatarListGetLink } from '@/views/Company/Employee/employeeHelpers'
import { mapActions } from 'vuex'
export default {
  name: 'EmployeeName',
  props: {
    id: {
      type: [Number, String, Array],
      required: true
    },
    isAvatar: {
      type: Boolean,
      default: false
    },
    isName: {
      type: Boolean,
      default: true
    },
    isStyleName: {
      type: String,
      default: ''
    },
    format: {
      type: String,
      default: 'fi'
    }
  },
  methods: {
    ...mapActions({
      fetchUsersById: 'users/fetchUsersById'
    }),
    onClickError () {
      this.fetchUsersById(this.id)
    }
  },
  computed: {
    userName () {
      return this.$Global.peopleName(this.user, this.format)
    },
    user () {
      return this.$store.getters['users/getUserById'](this.id)
    },
    avatar () {
      return this.user?.file ? employeeAvatarListGetLink(this.user.file) : null
    }
  }
}
</script>
