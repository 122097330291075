<template>
  <PageViewDefaultWrapper
    ref="wrapper"
    :permission-denied="permissionDenied"
    relative
  >
    <TwPanelContent class="tw-flex-1">
      <TwPanelContentBlock
        class="tw-flex-1 tw-mb-[6px] tw-overflow-auto"
        :content-classes="''"
      >
        <template #title>
          <div>
            Список клиентов отмеченных как сотрудники данной компании
          </div>
        </template>
        <div>
          <TwTableBase
            :schema-key="filterSchemaKey"
            tbody-classes="tw-text-xs"
            tbody-row-classes="tw-cursor-pointer"
            :items="data"
            :headers="headers"
            :table-fixed="false"
            :loading="loading"
            :fixed-header-margin="0"
            :table-columns-management="false"
            no-data-text="Не найдено"
            :pagination="pagination"
            @sortUpdate="onSortUpdate"
            @headersUpdate="headers = $event"
            @row-click="rowClick"
            @changePagination="changePagination"
          >
            <template #item.confirmed="{ item }">
              <div class="tw-flex tw-justify-center">
                <StatusColorDot
                  v-if="item.blackList"
                  title="Клиент находится в чёрном списке"
                  :color="statusDotsColor.blackList"
                />
                <StatusColorDot
                  v-else-if="item.confirmed"
                  :title="confirmedTextTrue"
                  :color="statusDotsColor.confirmedTrue"
                />
                <StatusColorDot
                  v-else
                  :title="confirmedTextFalse"
                  :color="statusDotsColor.confirmedFalse"
                />
              </div>
            </template>
            <template #item.phones="{ item }">
              <div
                v-if="Array.isArray(item.phones) && item.phones.length"
                class="tw-flex tw-gap-[6px] tw-flex-wrap"
              >
                <div
                  v-for="(info, index) in item.phones"
                  :key="index"
                  class=""
                >
                  <div
                    class="tw-flex tw-flex-nowrap tw-whitespace-nowrap"
                    :class="info.main ? 'tw-font-semibold' : ''"
                  >
                    <SipCallLink
                      v-if="info.phone"
                      style="font-size: 12px"
                      :phone="info.phone"
                    />
                    <template v-if="index < item.phones.length - 1 || item.additionalContacts.length">
                      <span class="">,</span>
                    </template>
                  </div>
                </div>
              </div>
              <div
                v-else
                class="tw-text-center tw-text-an-flat-disabled-text"
              >
                -
              </div>
            </template>
            <template #item.additionalContacts="{ item }">
              <div
                v-if="Array.isArray(item.additionalContacts) && item.additionalContacts.length"
                class="tw-flex tw-gap-[6px] tw-flex-wrap"
              >
                <div
                  v-for="(info, index) in item.additionalContacts"
                  :key="index+'_additional'"
                  class=""
                >
                  <div class="">
                    <span class="tw-text-an-flat-disabled-text">{{ info.type && (info.type.label || info.type) }}:</span>
                    <span class="">{{ info.contact }}</span><template v-if="index < item.additionalContacts.length - 1">
                      ,
                    </template>
                  </div>
                </div>
              </div>
              <div
                v-else
                class="tw-text-center tw-text-an-flat-disabled-text"
              >
                -
              </div>
            </template>
          </TwTableBase>
        </div>
      </TwPanelContentBlock>
    </TwPanelContent>
  </PageViewDefaultWrapper>
</template>

<script>
import personAPI from '@/Api/Crm/Person/index'
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import SipCallLink from '@/components/telephony/SipCallLink'
import TwPanelContent from '@/components/tw-ui/panels/TwPanelContent.vue'
import TwTableBase from '@/components/tw-ui/table/TwTableBase.vue'
import PageViewDefaultWrapper from '@/components/core/ViewDefault/PageViewDefaultWrapper.vue'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import DataListTableSorterMethodsMixin from '@/mixins/DataListTableSorterMethodsMixin'
import StatusColorDot from '@/components/status/StatusColorDot.vue'
import { tableItemStyleOneGen } from '@/components/tw-ui/table/TableTypes'
import { mapActions } from 'vuex'
import { ServicesSchema } from '@/store/twfilter/servicesSchema'
import { confirmedTextFalse, confirmedTextTrue, statusDotsColor } from '@/views/Person/personDict'

const tableOptions = [
  {
    ...tableItemStyleOneGen(true),
    text: 'ID',
    dataKey: 'id',
    colStyle: 'width: 50px;'
  },
  {
    ...tableItemStyleOneGen(true),
    text: 'ФИО',
    dataKey: 'name',
    colStyle: 'width: auto;'
  },
  {
    ...tableItemStyleOneGen(true),
    text: 'Номера телефонов',
    dataKey: 'phones',
    colStyle: 'width: 280px;'
  },
  {
    ...tableItemStyleOneGen(true),
    text: 'Доп. контакты',
    dataKey: 'additionalContacts',
    colStyle: 'width: 280px;'
  },
  {
    ...tableItemStyleOneGen(true),
    text: 'Статус',
    dataKey: 'confirmed',
    colStyle: 'width: 30px;'
  }
]

export default {
  name: 'CompanyPersons',
  components: {
    StatusColorDot,
    TwPanelContentBlock,
    PageViewDefaultWrapper,
    TwTableBase,
    TwPanelContent,
    SipCallLink
  },
  mixins: [ViewPanelShowMixin, DataListTableSorterMethodsMixin],
  props: {
    companyId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      filterSchemaKey: ServicesSchema.PersonList.key,
      API: personAPI,
      defaultFilters: {
        companyId: [this.companyId]
      },
      loading: false,
      permissionDenied: false,
      headers: tableOptions,
      data: [],
      pagination: {},
      confirmedTextTrue,
      confirmedTextFalse,
      statusDotsColor
    }
  },
  methods: {
    ...mapActions({
      initCurrentFilters: 'twFilter/initCurrentFilters',
      fetchFilterService: 'twFilter/fetchFilterService'
    }),
    // инит схемы фильтров
    async initFiltersBySchemaKey () {
      console.warn('initFiltersBySchemaKey', this.filterSchemaKey)
      if (this.filterSchemaKey) {
        // получение схемы фильтров
        await this.fetchFilterService(this.filterSchemaKey)
        // инит полученного
        await this.initCurrentFilters(this.filterSchemaKey)
      } else {
        return new Error(`Не указан ключ схемы фильтров: ${this.filterSchemaKey}`)
      }
      this.fetch()
    },
    rowClick (itemInfo) {
      this.showPersonCard({ id: itemInfo.id }, () => {
        this.fetch()
      })
    },
    changePagination (eventData) {
      this.fetch({
        ...eventData
      })
    },
    fetch (params = {}) {
      if (this.loading) {
        return
      }

      if (!this.API) {
        throw Error('not set API')
      }

      this.permissionDenied = false
      this.loading = true
      // eslint-disable-next-line no-param-reassign
      params = { ...params, ...this.defaultFilters }

      this.API.getListNew(params)
        .then((response) => {
          this.data = response.data.data
          if (response.data.meta) {
            this.pagination = response.data.meta
            this.itemsPerPage = response.data.meta.per_page || 25
          }
        })
        .catch((error) => {
          // обработка отсутствия прав на просмотр
          if (error?.response?.status === 403) {
            this.permissionDenied = true
          }
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  mounted () {
    this.initFiltersBySchemaKey()
  }
}
</script>

<style scoped>

</style>
