<template>
  <div class="item-wrapper mt-5">
    <div class="item-icon">
      <svg
        width="34"
        height="34"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="17"
          cy="17"
          r="17"
          fill="#2D82D0"
          class="tw-fill-an-flat-orange-warning"
        />
        <g clip-path="url(#clip0_308_17577)">
          <path
            d="M17.3376 10.9722C18.3163 10.9722 19.1079 11.711 19.1079 12.6244C19.1079 13.5379 18.3163 14.2767 17.3376 14.2767C16.3589 14.2767 15.5673 13.5379 15.5673 12.6244C15.5673 11.711 16.3589 10.9722 17.3376 10.9722ZM18.9483 15.3133H18.9023H16.0551H15.9508C15.3679 15.3133 14.8984 15.7543 14.8984 16.2955C14.8984 16.8396 15.3709 17.2778 15.9508 17.2778H16.0551V23.0049H14.8984V24.9722H20.102V23.0049H18.9514V15.3133H18.9483Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_308_17577">
            <rect
              width="15"
              height="14"
              fill="white"
              transform="translate(10 9.82324)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
    <div
      v-if="item"
      class="card item-hovered-visible-block tw-border-l tw-border-l-an-flat-orange-warning"
    >
      <div
        v-if="item"
        class="card-wrapper"
        style="width: 100%"
      >
        <div
          class="card-left"
          style="flex: auto"
        >
          <div class="card-header">
            <div class="card-header-info">
              <p class="card-title">
                <span>{{ item.attachEntity && item.attachEntity.label }}:</span>
              </p>
              <p class="card-time">
                {{ $Global.DateFormat.DateTimeShort(item.date) }}
              </p>
            </div>
          </div>
          <div class="card-notification-info">
            <div class="card-notification-text tw-flex tw-flex-col tw-gap-[12px]">
              <TwPanelContentBlockFieldView
                :horizontal="false"
                no-data-text="Нет данных"
              >
                <template #title>
                  Статус по отказу
                </template>
                <div
                  v-if="entityData.status"
                  class="tw-font-medium"
                >
                  {{ entityData.status.label }}
                </div>
              </TwPanelContentBlockFieldView>
              <TwPanelContentBlockFieldView
                :horizontal="false"
                no-data-text="Нет данных"
              >
                <template #title>
                  Ответственный по заявке
                </template>
                <div class="tw-font-medium tw-flex tw-flex-wrap tw-gap-[4px] tw-items-center">
                  <template v-if="entityData.dealResponsibleUserId !== entityData.dealNewResponsibleUserId">
                    <ViewPanelLink
                      v-if="entityData.dealResponsibleUserId"
                      :id="entityData.dealResponsibleUserId"
                      label="Пользователь"
                      component="employee-show"
                    >
                      <EmployeeName :id="entityData.dealResponsibleUserId" />
                    </ViewPanelLink>
                    <span class="tw-text-[24px] tw-text-an-flat-red-main tw-leading-[28px]">➟</span>
                  </template>
                  <ViewPanelLink
                    v-if="entityData.dealNewResponsibleUserId"
                    :id="entityData.dealNewResponsibleUserId"
                    label="Пользователь"
                    component="employee-show"
                  >
                    <EmployeeName :id="entityData.dealNewResponsibleUserId" />
                  </ViewPanelLink>
                </div>
              </TwPanelContentBlockFieldView>
              <TwPanelContentBlockFieldView
                :horizontal="false"
                no-data-text="Нет данных"
              >
                <template #title>
                  Комментарий
                </template>
                <div
                  v-if="entityData.comment"
                  class="tw-font-medium"
                >
                  {{ entityData.comment }}
                </div>
              </TwPanelContentBlockFieldView>
            </div>
            <EmployeeLinkBlock
              v-if="entityData.checkUserId"
              :user-id="entityData.checkUserId"
            />
          </div>
        </div>
        <div class="card-right">
          <ChronologyPinned
            v-if="!(variant === 'other' && item.pin)"
            :id="item.id"
            :pin-status="item.pin"
            @refresh="$emit('refresh')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChronologyPinned from '@/components/core/Chronology/ChronologyPinned'
import EmployeeLinkBlock from '@/components/other/employee/EmployeeLinkBlock'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink.vue'
import EmployeeName from '@/components/other/employee/EmployeeName.vue'

export default {
  name: 'ChronologyDealBounceHandlingView',
  components: {
    EmployeeName,
    ViewPanelLink,
    TwPanelContentBlockFieldView,
    EmployeeLinkBlock,
    ChronologyPinned
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    variant: {
      type: String,
      required: true // other, plan, pin
    }
  },
  computed: {
    entityData () {
      return this.item?.attachEntityData || null
    }
  }
}
</script>

<style scoped>

</style>
