<template>
  <div
    :title="personId ? 'Выберите Email' : 'Не выбран сотрудник'"
    class="tw-flex tw-flex-col tw-gap-[14px]"
  >
    <TwSelectRelations
      v-model="personId"
      return-object
      :multi="false"
      :dense="true"
      :label="`Выбор сотрудника банка: ${company.name || ''}`"
      list-label="Найти контакт"
      relation-name="person"
      prefetch-request
      :persistent-label="true"
      placeholder="Не выбран"
      :default-filters="companyFilter"
      :disabled="!company || loading"
      :error-text="personId ? '':'Заполните поле'"
    />
    <TwSelect
      label="Выбор Email"
      placeholder="Выбрать"
      :options="emails"
      :value="value"
      :return-object="true"
      :dense="true"
      :error-text="value ? '':'Обязательное поле'"
      :disabled="!personId || loading"
      :persistent-label="true"
      @input="$emit('input', $event)"
    />
  </div>
</template>

<script>
import personAPI from '@/Api/Crm/Person'
import API from '@/Api/Crm/Person/AdditionalContact'
import TwSelectRelations from '@/components/tw-ui/ui-kit/input/select-relations/TwSelectRelations.vue'
import TwSelect from '@/components/tw-ui/ui-kit/input/TwSelect.vue'

export default {
  name: 'PersonSelectAdditionalContact',
  components: { TwSelect, TwSelectRelations },
  props: {
    company: {
      type: Object,
      default () {
        return null
      }
    },
    value: {
      default: null
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      personId: null,
      personInfo: null
    }
  },
  computed: {
    emails () {
      return this?.personInfo?.additionalContacts ? this.personInfo.additionalContacts.filter((contact) => {
        return contact?.type === 'email'
      }).map(i => {
        return {
          label: i.contact,
          value: i.id
        }
      }) : []
    },
    companyFilter () {
      return this.company?.id ? { companyId: [this.company.id] } : {}
    }
  },
  watch: {
    personId (newValue) {
      this.fetchPersonById(newValue)
    }
  },
  mounted () {
    if (this.value) {
      this.fetchValueInfo(this.value)
    }
  },
  methods: {
    fetchPersonById: function (id) {
      personAPI.show(id)
        .then(({ data: result }) => {
          this.personInfo = result?.data || null
        })
        .catch((error) => {
          console.error(error)
          this.personInfo = null
        })
    },
    fetchValueInfo (id) {
      this.loading = true
      API.show(id)
        .then(({ data }) => {
          this.personId = data?.data?.person?.id || null
          this.personInfo = data?.data?.person || null
        })
        .catch((error) => {
          console.error({ error })
          this.personId = null
          this.personInfo = null
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped>

</style>
