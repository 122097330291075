<template>
  <div
    v-cloak
    v-if="info"
    class="tw-flex tw-gap-[14px] tw-flex-col"
  >
    <TwPanelContentBlockFieldView>
      <template #title>
        Название
      </template>
      <div v-if="info.name">
        {{ info.name }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Раздел
      </template>
      <div v-if="info.section">
        {{ sectionInfo.sectionLabel }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Группа документов
      </template>
      <div v-if="info.subsection">
        {{ sectionInfo.subsectionLabel }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Связанный объект
      </template>
      <div
        v-if="info.entity && info.subsection"
        class="pa-3 sub-white-bordered-block"
      >
        <div v-if="info.subsection.value === 'bank'">
          <small>
            {{ info.subsection.label }}
          </small>
          <div>
            <ViewPanelLink
              :id="info.entity.id"
              label="Компания"
              component="company-show"
              :callback="refresh"
            >
              {{ info.entity.name }}
            </ViewPanelLink>
          </div>
        </div>
        <div v-if="info.subsection.value === 'program'">
          <small>
            {{ info.subsection.label }}
          </small>
          <div>
            <ViewPanelLink
              :id="info.entity.id"
              label="Программа"
              component="mortgage-program-show"
              :callback="refresh"
            >
              {{ info.entity.name }}
            </ViewPanelLink>
          </div>
        </div>
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Дата документа
      </template>
      <div v-if="info.date">
        {{ info.date }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Загруженный шаблон
      </template>
      <div v-if="Array.isArray(info.files) && info.files.length">
        <TwPanelActionButton
          color="blue"
          @click.prevent="download(info.files[0])"
        >
          Скачать
        </TwPanelActionButton>
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Активность
      </template>
      <div>
        {{ info.active ? 'Да':'Нет' }}
      </div>
    </TwPanelContentBlockFieldView>

    <SectionFieldsView :info="sectionInfo.subsectionFields" />
  </div>
</template>

<script>
import docAPI from '@/Api/Crm/Dictionary/General/Document'
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink.vue'
import SectionFieldsView from '@/views/Dictionary/General/Document/main/SectionFieldsView/SectionFieldsView.vue'
import TwPanelActionButton from '@/components/tw-ui/panels/TwPanelActionButton.vue'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'

export default {
  name: 'DocumentMainShow',
  components: { TwPanelContentBlockFieldView, SectionFieldsView, ViewPanelLink, TwPanelActionButton },
  mixins: [ViewPanelShowMixin],
  props: {
    info: {},
    dictionary: {}
  },
  computed: {
    sectionInfo () {
      const result = {
        sectionLabel: this.info?.section.label,
        subsectionLabel: this.info?.subsection.label
      }
      if (this.dictionary && Array.isArray(this.dictionary.section)) {
        const targetSection = this.dictionary.section.find(s => s.value === this.info.section.value)
        if (Array.isArray(targetSection.subsection) && this.info.subsection.value) {
          const targetSubsection = targetSection.subsection.find(s => s.value === this.info.subsection.value)
          console.log(targetSubsection)
          result.subsectionLabel = targetSubsection.label
          result.subsectionFields = {
            label: 'Назначения полей',
            fields: [],
            subsection: targetSubsection.subsection
          }
        }
      }
      return result
    }
  },
  methods: {
    refresh () {
      this.$emit('refresh')
    },
    download (file) {
      console.log('download file: ', file)
      docAPI.download(file.url, file.name)
    }
  }
}
</script>
