<template>
  <div class="tw-font-manrope tw-flex tw-flex-col tw-gap-[8px]">
    <template v-if="Array.isArray(options) && options.length">
      <button
        v-for="item in options"
        :key="item.value"
        :disabled="disabled || !item.active"
        :class="[item.value === value ? 'tw-border-an-flat-green-subside-text tw-bg-an-flat-green-subside-text tw-bg-opacity-10 tw-cursor-default' : 'tw-border-an-flat-disabled-text enabled:tw-cursor-pointer disabled:tw-opacity-50 enabled:hover:tw-bg-white']"
        class="tw-border tw-border-solid tw-flex tw-gap-[1vw] tw-items-center tw-rounded-[14px] tw-outline-0"
        @click="onSelect(item)"
      >
        <div class="tw-grow tw-text-left tw-pl-[21px] tw-py-[16px]">
          <div
            v-if="!item.active"
            class="tw-text-h6 tw-text-an-flat-red-main tw-uppercase"
          >
            Неактивный
          </div>
          <div class="tw-text-h6">
            {{ item.label }}
          </div>
          <div class="tw-text-main-sm tw-text-an-flat-grey-4">
            {{ item.description }}
          </div>
        </div>
        <div
          class="tw-h-auto tw-self-stretch tw-border-l tw-border-dashed"
          :class="[item.value === value ? 'tw-border-an-flat-green-subside-text' : 'tw-border-an-flat-disabled-text']"
        />
        <div class="tw-text-left tw-min-w-[230px] tw-max-w-[230px] tw-pr-[21px] tw-py-[16px]">
          <div>
            <span class="tw-text-xs">Срок бронирования:</span> <strong class="tw-text-sm tw-text-an-flat-green-subside-text">{{ item.days }} дн.</strong>
          </div>
          <div v-if="item.paid">
            <span class="tw-text-xs">Стоимость услуги:</span> <strong class="tw-text-sm">{{ item.price }}</strong>
          </div>
        </div>
      </button>
    </template>
    <div
      v-else
      class="tw-text-center tw-p-[30px]"
    >
      Нет данных
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReservationTypeSelectRealtyObjectReservation',
  props: {
    value: {
      type: Number,
      default: null
    },
    options: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  methods: {
    onSelect (item) {
      this.$emit('input', item.value)
    }
  }
}
</script>
