<template>
  <div>
    <TwDialog
      :value="value"
      class="tw-max-w-[90%]"
      :max-width="'80%'"
      @input="$emit('input', $event)"
    >
      <template #header>
        <div class="tw-flex tw-w-fit tw-mb-[11px] tw-bg-an-flat-panel-modal-header-bg tw-opacity-80  tw-p-[5px] tw-rounded-[6px]">
          <span class="tw-block tw-px-[20px] tw-pt-[12px] tw-pb-[14px] tw-bg-white tw-rounded-[6px] tw-font-semibold tw-text-13px tw-leading-[120%] tw-text-an-flat-black-main">История изменений</span>
        </div>
      </template>
      <template #body>
        <div class="tw-bg-white tw-p-[20px] tw-rounded-[24px]">
          <div>
            <div
              v-if="id"
              class="tw-bg-an-flat-bg-main tw-px-[31px] tw-pt-[22px] tw-pb-[14px] tw-rounded-tl-[20px] tw-rounded-tr-[20px]
                tw-border tw-border-t-0 tw-border-x-0 tw-border-b-[5px] tw-border-solid tw-border-2 tw-border-white
                tw-font-bold tw-text-14px tw-leading-[19px] tw-text-an-flat-black-main"
            >
              Запрос №{{ id }}
            </div>
            <ul v-if="Array.isArray(dataApiHistory) && dataApiHistory.length">
              <li class="tw-grid tw-gap-[5px] tw-grid-cols-12 tw-px-[32px] tw-py-[14px] odd:tw-bg-an-flat-grey-1 even:tw-bg-white">
                <div class="tw-col-span-2 tw-text-14px tw-font-semibold tw-leading-[19px] tw-text-an-flat-gray-12">
                  Дата
                </div>
                <div class="tw-col-span-6 tw-text-14px tw-font-semibold tw-leading-[19px] tw-text-an-flat-gray-12">
                  Действие
                </div>
                <div class="tw-col-span-2 tw-text-14px tw-font-semibold tw-leading-[19px] tw-text-an-flat-gray-12">
                  Комментарий
                </div>
                <div class="tw-col-span-2 tw-text-14px tw-font-semibold tw-leading-[19px] tw-text-an-flat-gray-12">
                  Пользователь
                </div>
              </li>
              <li
                v-for="(item, index) in dataApiHistory"
                :key="index"
                :title="JSON.stringify(item)"
                class="tw-grid tw-gap-[5px] tw-grid-cols-12 tw-px-[32px] tw-py-[18px] odd:tw-bg-an-flat-grey-1 even:tw-bg-white"
              >
                <template v-if="item">
                  <div class="tw-col-span-2 tw-text-an-flat-gray-13 tw-text-[14px] tw-leading-[19px]">
                    {{ item.createdAt }}
                  </div>
                  <div class="tw-col-span-6 tw-text-an-flat-black-main tw-text-[14px] tw-leading-[19px] tw-font-semibold">
                    <span v-if="item.type.label">
                      {{ item.type.label }}
                    </span>
                    <span
                      v-if="item.last && item.last.color && item.last.label"
                      :style="{ color: item.last.color }"
                    >
                      «{{ item.last.label }}»
                    </span>
                    <span v-if="item.last || item.new">
                      <svg
                        class="tw-inline tw-mx-[10px] tw-relative tw-top-[-1px]"
                        width="16"
                        height="7"
                        viewBox="0 0 16 7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.8169 3.01998L13.2441 0.446979C13.1268 0.329766 12.9679 0.263916 12.8021 0.263916C12.6363 0.263916 12.4774 0.329766 12.3602 0.446979C12.2429 0.564192 12.1771 0.723168 12.1771 0.888932C12.1771 1.0547 12.2429 1.21367 12.3602 1.33089L13.9144 2.8851C13.8979 2.88379 13.8813 2.88257 13.8644 2.88257H0.625C0.45924 2.88257 0.300269 2.94842 0.183058 3.06563C0.065848 3.18284 0 3.34181 0 3.50757C0 3.67333 0.065848 3.8323 0.183058 3.94951C0.300269 4.06672 0.45924 4.13257 0.625 4.13257H13.8203L12.2838 5.66907C12.1666 5.78628 12.1007 5.94527 12.1007 6.11105C12.1007 6.27684 12.1665 6.43584 12.2837 6.55309C12.4009 6.67033 12.5599 6.73622 12.7257 6.73624C12.8915 6.73627 13.0505 6.67044 13.1677 6.55323L15.8169 3.90401C15.875 3.84597 15.921 3.77707 15.9524 3.70124C15.9838 3.62541 16 3.54414 16 3.46206C16 3.37998 15.9838 3.2987 15.9524 3.22287C15.921 3.14704 15.875 3.07814 15.8169 3.0201V3.01998Z"
                          fill="#B1B1B1"
                        />
                      </svg>
                    </span>
                    <span
                      v-if="item.new && item.new.color"
                      :style="{ color: item.new.color }"
                    >
                      «{{ item.new.label }}»
                    </span>
                  </div>
                  <div class="tw-col-span-2 tw-text-[14px] tw-leading-[19px] tw-text-main-sm2">
                    <ReadMoreExpander
                      v-if="item.comment"
                      :text="item.comment"
                      :less-length="30"
                    />
                  </div>
                  <div class="tw-col-span-2 tw-text-[14px] tw-leading-[19px]">
                    <a class="tw-font-medium tw-underline tw-text-an-flat-dark-blue-link">
                      <ViewPanelLink
                        v-if="item.userId"
                        :id="item.userId"
                        label="Пользователь"
                        component="employee-show"
                      >
                        <EmployeeName :id="item.userId" />
                      </ViewPanelLink>
                    </a>
                  </div>
                </template>
              </li>
            </ul>
            <div
              v-else
              class="tw-text-center tw-pt-[12px] tw-text-[20px]"
            >
              <strong>У этого запроса ещё нет истории изменений.</strong>
            </div>
          </div>
        </div>
      </template>
    </TwDialog>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import TwDialog from '@/components/tw-ui/modal/TwDialog.vue'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink.vue'
import EmployeeName from '@/components/other/employee/EmployeeName.vue'
import API from '@/Api/Rtd/RequestToDeveloper/Management/index'
import ReadMoreExpander from '@/components/other/text/ReadMoreExpander.vue'

export default defineComponent({
  name: 'RequestToDeveloperHistoryModal',
  components: { ReadMoreExpander, EmployeeName, ViewPanelLink, TwDialog },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    id: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      dataApiHistory: {}
    }
  },
  watch: {
    value: function () {
      if (this.value === true) {
        API.getHistory(this.id).then(info => {
          this.dataApiHistory = info.data.data
        }).catch(err => {
          console.error('Error get history request!', err)
        })
      }
    }
  }
})
</script>
<style scoped>

</style>
