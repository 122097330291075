<template>
  <div>
    <v-autocomplete
      ref="select"
      v-model="select"
      type="text"
      :items="items"
      :multiple="multiple"
      :rules="rules"
      attach
      :hide-details="!(rules.length)"
      :class="classes"
      hide-selected
      no-filter
      :search-input.sync="searchStr"
      :loading="loading"
      item-value="id"
      :placeholder="placeholder"
      :label="label"
      return-object
      flat
      @input="input"
      @focus="searchFromServer(searchStr || '')"
      @AAAupdate:search-input="searchInputUpdate"
    >
      <template #append-outer>
        <AddNewEmailContact
          :input-value="searchStr"
          @add="addedNewCustomContact"
        />
      </template>
      <template #no-data>
        <v-list-item disabled>
          <v-list-item-content
            v-if="noDataHide"
            class="pa-0"
          >
            <v-list-item-title>
              <span class="wrap grey--text">
                Поиск персоны
              </span>
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-content
            v-else
            class="pb-0"
          >
            <v-list-item-title>
              Ничего не найдено
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template #item="{ item }">
        <v-list-item-content>
          <v-list-item-title>{{ item.name }}</v-list-item-title>
          <v-list-item-subtitle class="ml-2">
            {{ item.email }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
      <template #selection="data">
        <v-chip
          small
          label
          :close="multiple"
          :color="chipColor"
          v-bind="data.attrs"
          :input-value="data.selected"
          @click:close="removeByIndex(data.index)"
        >
          <span>
            {{ data.item.name }}
            <span v-text="` <${data.item.email}>`" />
          </span>
        </v-chip>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import API from '@/Api/Email/Contacts'
import AsyncSelectMixin from '@/components/select/AsyncSelectMixin'
import AddNewEmailContact from '@/components/select/email/AddNewEmailContact'

export default {
  name: 'EmailsContactsAsyncSelect',
  components: { AddNewEmailContact },
  mixins: [AsyncSelectMixin],
  props: {
    createLocal: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    API: API,
    noDataHide: true,
    searchStr: ''
  }),
  watch: {
    value: {
      handler (newValue, OldValue) {
        console.warn(this.$options.name, 'Value INIT change:  ', newValue, OldValue, (!!newValue && !OldValue))
        if (Array.isArray(newValue) && newValue.length) {
          // this.select = newValue
          newValue.forEach((item) => {
            if (item.email && !this.select.some(s => s.email === item.email)) {
              this.items.push(item)
              this.select.push(item)
            }
          })
        }
      },
      immediate: true
    }
  },
  mounted () {
    console.warn(this.value)
  },
  methods: {
    searchInputUpdate (data) {
      console.warn('searchInputUpdate', data)
      this.searchStr = data
    },
    addedNewCustomContact (data) {
      data.id = 'fake-id_' + this.items.length
      this.items.push(data)
      this.select.push(data)
      this.input(this.select)
      this.searchStr = ''
    },
    removeByIndex (index) {
      // console.warn('removeByIndex', index, this.select)
      this.select.splice(index, 1)
      this.input(this.select)
    },
    input (event) {
      console.log('INPUT', event)
      if (event && event.id) {
        this.$emit('input', {
          id: event.id,
          name: event.name,
          email: event.email
        })
        this.$emit('inputFullData', event || null)
      } else if (Array.isArray(event)) {
        this.$emit('input', event.map((v) => {
          return {
            id: v.id,
            name: v.name,
            email: v.email
          }
        }))
        this.$emit('inputFullData', event || [])
      } else if (event === undefined) {
        this.$emit('input', null)
        this.$emit('inputFullData', null)
      } else {
        console.warn('/AsyncSelectMixin/input/Необработанное исключение при вводе данных!', { event })
      }
    },
    fetchValueInfo (data, forArray = false) {
      console.warn(this.$options.name, ' fetchValueInfo ', data)
      if (!data.id) return
      this.loading = true
      this.API.show(data.id)
        .then(({ data: { data } }) => {
          // console.warn(data)
          if (data) {
            if (!forArray) {
              this.items.push(data)
              this.select = data
            } else {
              if (!Array.isArray(this.select)) {
                this.select = []
              }

              if (!this.select.some(i => i.id === data.id)) {
                this.items.push(data)
                this.select.push(data)
              }
            }
          }
        })
        .catch((error) => {
          console.error({ error })
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style>
.v-autocomplete:not(.v-input--is-focused).v-select--chips input {
  max-height: 100%;
}
.v-input__append-inner {
  margin: auto !important;
}
</style>
