<template>
  <div>
    <div class="my-3 text-muted">
      Правоустанавливающие документы:
    </div>
    <div class="mb-3">
      <template v-if="Array.isArray(value) && value.length">
        <div
          v-for="(item, index) in value"
          :key="index"
          class="my-3 d-flex align-center"
        >
          <v-combobox
            attach
            hide-details
            :value="item"
            :items="items"
            label="Тип документа"
            :multiple="false"
            :dense="denseFields"
            outlined
            @input="onTypeInput($event, index)"
          />
          <v-btn
            class="ml-2"
            color="red"
            icon
            x-small
            @click="onRemoveItem(index)"
          >
            <v-icon>fa-trash</v-icon>
          </v-btn>
        </div>

        <v-btn
          x-small
          color="secondary"
          outlined
          class="font-weight-bold"
          @click="addNewItem"
        >
          Добавить ещё
        </v-btn>
      </template>
      <div v-else>
        Нет документов
        <v-btn
          x-small
          color="secondary"
          outlined
          class="font-weight-bold"
          @click="addNewItem"
        >
          Добавить
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
const ariseDocs = [
  'Договор купли продажи',
  'Договор долевого участия',
  'Приватизация',
  'Справка ЖСК о полностью выплаченном пае',
  'Наследство по закону',
  'Наследство по завещанию',
  'Решение суда',
  'Договор дарения',
  'Договор мены',
  'Договор аренды',
  'Соглашение об определении долей',
  'Государственный акт о праве собственности, праве пожизненного владения, бессрочного пользования на зем. участок',
  'Договор ренты',
  'Иной документ'
]

export default {
  name: 'AriseDocumentsSelect',
  props: {
    denseFields: {
      type: Boolean,
      default: false
    },
    value: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    items () {
      return ariseDocs
    }
  },
  created () {
    if (!this.value) {
      this.$emit('input', [])
    }
  },
  methods: {
    addNewItem () {
      // console.log('addNewItem')
      this.$emit('input', [...(this.value || []), ''])
    },
    onRemoveItem (index) {
      // console.log('onRemoveItem')
      const newValue = [...[], ...this.value]
      newValue.splice(index, 1)
      this.$emit('input', newValue)
    },
    onTypeInput (event, index) {
      // console.log('onTypeInput', event, index)
      const newValue = [...[], ...this.value]
      newValue.splice(index, 1, event)
      this.$emit('input', newValue)
    }
  }
}
</script>

<style scoped>

</style>
