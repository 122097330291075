<template>
  <div class="tw-flex tw-flex-col tw-gap-[12px]">
    <div class="tw-mt-[12px] tw-flex tw-justify-between">
      <div class="tw-text-main-md tw-font-medium tw-text-an-flat-gray-4">
        Телефоны:
      </div>
      <TwPanelContentBlockActionButton
        size="md"
        @click.prevent="addNewPhone"
      >
        <span class="tw-font-medium">Добавить номер</span>
      </TwPanelContentBlockActionButton>
    </div>
    <div
      v-for="(item, index) in value"
      :key="index+'-row-tw'"
      class="tw-flex tw-gap-[6px]"
    >
      <TwCheckBox
        class="tw-mt-[21px]"
        :readonly="item.main"
        :disabled="disabled"
        :value="item.main"
        background-and-border-color="tw-bg-an-flat-blue-btn tw-border-an-flat-blue-btn"
        :title="item.main ? 'Основной':'Дополнительный'"
        @input="changeMain(index, $event)"
      />
      <TwInputPhone
        :key="index+'-input-tw'"
        :value="value[index].phone"
        class="tw-flex-1"
        :disabled="disabled"
        :disable-error-border="false"
        :show-label="true"
        :persistent-label="true"
        :dense="true"
        :label="item.main ? 'Основной номер телефона':'Дополнительный номер телефона'"
        :error-text="errorMessages[index]"
        @input="changePhone(index, $event)"
      />
      <button
        v-if="value.length > 1"
        title="Удалить поле"
        class="tw-ml-[6px] tw-mt-[21px] tw-text-an-flat-red-light hover:tw-text-an-flat-red-main"
        @clickю.prevent="removePhone(index)"
      >
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.67361 1.45139C3.67259 0.450377 2.13979 0.360212 1.25 1.25C0.360203 2.1398 0.450368 3.6726 1.45138 4.67362L5.27777 8.50001L1.45139 12.3264C0.450375 13.3274 0.36021 14.8602 1.25 15.75C2.13979 16.6398 3.6726 16.5496 4.67361 15.5486L8.5 11.7222L12.3264 15.5486C13.3274 16.5496 14.8602 16.6398 15.75 15.75C16.6398 14.8602 16.5496 13.3274 15.5486 12.3264L11.7222 8.50001L15.5486 4.67361C16.5496 3.67259 16.6398 2.13979 15.75 1.24999C14.8602 0.360203 13.3274 0.450368 12.3264 1.45138L8.5 5.27778L4.67361 1.45139Z"
            fill="currentColor"
          />
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import TwInputPhone from '@/components/tw-ui/ui-kit/custom/TwInputPhone.vue'
import TwCheckBox from '@/components/tw-ui/ui-kit/input/TwCheckBox.vue'
import TwPanelContentBlockActionButton from '@/components/tw-ui/panels/TwPanelContentBlockActionButton.vue'

export default {
  name: 'InputPersonPhones',
  components: { TwPanelContentBlockActionButton, TwCheckBox, TwInputPhone },
  props: {
    disabled: {
      default: false
    },
    value: {
      default: () => []
    },
    rules: {
      default: () => []
    },
    errorMessages: {
      default: () => []
    }
  },
  data () {
    return {}
  },
  mounted () {
    this.$watch(() => this.value,
      (newValue) => {
        if (!this.value.length) {
          this.addNewPhone()
        }

        if (this.value.length && !this.value.some(v => !!v.main)) {
          newValue[0].main = true
          this.onChange(newValue)
        }
      },
      {
        immediate: true,
        deep: true
      })
  },
  methods: {
    changePhone (index, val) {
      // console.log('changePhone: ', index, val)
      const value = this.value
      value[index].phone = val
      this.onChange(value)
    },
    changeMain (index) {
      // console.log('change main', index, val, this.value)
      const value = this.value
      let ch = 0
      while (ch < value.length) {
        value[ch].main = ch === index
        ch++
      }
    },
    addNewPhone () {
      let value = this.value
      if (!Array.isArray(value)) {
        value = []
      }
      value.push(
        {
          id: null,
          phone: '',
          main: !this.value.length
        }
      )
      this.onChange(value)
    },
    removePhone (index) {
      const value = this.value
      console.log('removePhone', index, value)
      value.splice(index, 1)
      this.onChange(value)
    },
    onChange (value) {
      this.$emit('input', value)
    }
  }
}
</script>
