<template>
  <TwPanelContentBlock class="tw-mb-[6px]">
    <template #title>
      <div>Описание</div>
    </template>
    <template #action>
      <button
        :disabled="!isAllowEdit"
        class="tw-text-main-xs enabled:hover:tw-text-an-flat-dark-blue-link"
        @click="isEdit = !isEdit"
      >
        {{ isEdit ? 'отменить' : 'изменить' }}
      </button>
    </template>
    <div
      v-if="info"
      class="tw-flex tw-gap-[12px] tw-flex-col"
    >
      <RealtyComplexDescForm
        v-if="isEdit"
        :id="description ? description.id : 0"
        :default-form-fields="defaultFormFields"
        @cancel="editFinally"
        @create="editFinally"
        @update="editFinally"
      />
      <RealtyComplexDescShow
        v-else
        :dictionary="dictionary"
        :description="description"
      />
    </div>
    <div
      v-else
      class="tw-w-full tw-text-center"
    >
      Нет данных
    </div>
  </TwPanelContentBlock>
</template>

<script>
import RealtyComplexDescShow from '@/views/Newbuildings/Complex/description/RealtyComplexDescShow'
import RealtyComplexDescForm from '@/views/Newbuildings/Complex/description/RealtyComplexDescForm'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
export default {
  name: 'RealtyComplexDescription',
  components: { TwPanelContentBlock, RealtyComplexDescForm, RealtyComplexDescShow },
  props: {
    info: {},
    dictionary: {},
    isAllowEdit: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isEdit: false
    }
  },
  computed: {
    description () {
      return this.info.description || null
    },
    defaultFormFields () {
      return {
        complexId: this?.info?.id || null
      }
    }
  },
  methods: {
    editFinally (eventData) {
      console.warn(this.$options.name, ' editFinally ', eventData)
      this.isEdit = false
      this.$emit('refresh')
    }
  }
}
</script>
