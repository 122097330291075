import { RouteKeys } from '@/router/types'
import dictPermissionRules from '@/Permissions/dictRules'

export default {
  path: `/${RouteKeys.tools}`,
  name: RouteKeys.tools,
  meta: { name: 'Инструменты', icon: 'tools' },
  redirect: { name: `${RouteKeys.tools}-realty-miner` },
  component: () => import('@/views/Tools/Index.vue'),
  children: [
    {
      path: 'realty-miner',
      name: `${RouteKeys.tools}-realty-miner`,
      meta: {
        name: 'Оценка квартир',
        icon: 'tools-realty-miner'
        // ruleNamespace: dictPermissionRules.AppServicePermissionRulesRMPricePriceReportRule // TODO отключено - включим позднее
      },
      component: () => import('@/views/RealtyMiner/RealityMinerCard.vue')
    },
    {
      path: `${RouteKeys.mortgage}-${RouteKeys.variant}-matrix`,
      name: `${RouteKeys.mortgage}-${RouteKeys.variant}-matrix`,
      meta: { name: 'Ипотека. Подбор', icon: 'mortgage-variant-matrix' },
      component: () => import('@/views/Mortgage/Variant/Matrix/VariantSelectionMatrix.vue')
    },
    // {
    //   path: `${RouteKeys.mortgage}-${RouteKeys.variant}-matrix-old`,
    //   name: `${RouteKeys.mortgage}-${RouteKeys.variant}-matrix-old`,
    //   meta: { name: 'Ипотека. Подбор (старая)', icon: 'filter' },
    //   component: () => import('@/views/Mortgage/Variant/Matrix/VariantSelectionMatrix.vue')
    // },
    {
      path: 'selection',
      name: `${RouteKeys['realty-objects']}-selection`,
      meta: { name: 'Подбор новостройки', icon: 'realty-objects-selection', hText: 'Подбор новостройки', toolbarNotSticky: true },
      component: () => import('@/views/Newbuildings/Selection/NewBuildingSelectionPage.vue')
    },
    {
      path: 'report-generate',
      name: `${RouteKeys.tools}-report-generate`,
      meta: { name: 'Обработка выписок', icon: 'processing-statements', ruleNamespace: dictPermissionRules.AppServicePermissionRulesCRMToolsStatementReportGeneratorRule },
      component: () => import('@/views/Tools/ReportGenerate/ReportGeneratePage.vue')
    },
    {
      path: 'deal-bounce-handling',
      name: `${RouteKeys.tools}-deal-bounce-handling`,
      meta: { name: 'Работа с отказами', icon: 'dealing-with-failures', ruleNamespace: dictPermissionRules.AppServicePermissionRulesCRMDealsDealBounceHandingRule },
      component: () => import('@/views/Deal/DealBounceHandling/List.vue')
    },
    {
      path: 'kontur-check',
      name: `${RouteKeys.tools}-kontur-check`,
      meta: {
        name: 'Проверки Контур',
        icon: 'processing-statements',
        ruleNamespace: dictPermissionRules.AppServicePermissionRulesDocumentCheckKonturPersonCheckRule
      },
      component: () => import('@/views/Tools/DocCheckKontur/List.vue')
    },
    {
      path: 'builder-projects',
      name: `${RouteKeys.tools}-builder-projects`,
      meta: {
        name: 'Каталог проектов ИЖС',
        icon: 'directories'
      },
      component: () => import('@/views/Newbuildings/Editor/BuilderProjects/List.vue')
    },
    {
      path: 'apartment-presentation',
      name: `${RouteKeys.tools}-apartment-presentation`,
      meta: { name: 'Презентация квартиры', icon: '', hideInMenu: true },
      component: () => import('@/views/Newbuildings/ApartmentPresentation/ApartmentPresentation.vue')
    }
  ]
}
