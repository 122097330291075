<template>
  <TwSelect
    class="tw-flex-1 tw-min-w-[230px]"
    :value="value"
    :return-object="true"
    :placeholder="`Выберите ${field.label || 'label'}`"
    :label="field.label || 'label'"
    :multi="false"
    :dense="true"
    :cleanable="true"
    :persistent-label="true"
    :disabled="!(Array.isArray(optionsFromParentList) && optionsFromParentList.length)"
    :options="optionsFromParentList"
    @input="$emit('input', $event)"
    @input-object="$emit('input-object', $event)"
  />
</template>

<script>
import TwSelect from '@/components/tw-ui/ui-kit/input/TwSelect.vue'

export default {
  name: 'TwSelectFromParent',
  components: { TwSelect },
  props: {
    field: {
      type: Object,
      required: true
    },
    value: {
      default: null
    },
    parentValue: {
      default: null
    }
  },
  computed: {
    optionsFromParentList () {
      return this.parentValue ? this.field.options[this.parentValue] : []
    }
  },
  watch: {
    parentValue (newValue, oldValue) {
      if (this.value && newValue && (!Array.isArray(this.field.options[newValue]) || !this.field.options[newValue].some(i => i.value === this.value))) {
        this.clearValue()
      } else if (!newValue && oldValue) {
        this.clearValue()
      }
    }
  },
  methods: {
    clearValue () {
      this.$emit('input', null)
    }
  }
}
</script>
