import { AxiosResponse } from 'axios'
import HTTP from '@/Api/http'
import { AuthAxiosDefaultConfig } from '@/Api/ObjectStorage'

const path = '/realty/selection/new-building/land-house'

export default {
  search (param?: object, otherConfig?: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig,
      params: param,
      ...(otherConfig || {})
    }
    return HTTP.get(`${path}/search`, $config)
  },
  chess (param?: object, otherConfig?: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig,
      params: param,
      ...(otherConfig || {})
    }
    return HTTP.get(`${path}/chess`, $config)
  },
  searchMultiplyField (param?: object, otherConfig?: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig,
      params: param,
      ...(otherConfig || {})
    }
    return HTTP.get(`${path}/search-field`, $config)
  },
  filters (param?: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig,
      params: param
    }
    return HTTP.get(`${path}/filters`, $config)
  }
}
