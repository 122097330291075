import { tableItemStyleOneGen, TBaseTableHeaderItem } from '@/components/tw-ui/table/TableTypes'
import { textColors } from '@/utils/tw-classes'

const options: TBaseTableHeaderItem[] = [
  {
    ...tableItemStyleOneGen(true),
    dataKey: 'id',
    text: 'ID',
    colStyle: 'width: 70px;'
  },
  {
    ...tableItemStyleOneGen(false),
    dataKey: 'company',
    text: 'Застройщик',
    colStyle: 'width: 200px;'
  },
  {
    ...tableItemStyleOneGen(true),
    dataKey: 'processStatus',
    text: 'Статус',
    colStyle: 'width: 180px;'
  },
  {
    ...tableItemStyleOneGen(false),
    dataKey: 'parserTemplate',
    text: 'Шаблон парсера и загруженный файл',
    colStyle: 'width: auto;'
  },
  {
    ...tableItemStyleOneGen(false),
    dataKey: 'creator',
    text: 'Загрузил',
    colStyle: 'width: 200px;'
  },
  {
    ...tableItemStyleOneGen(false),
    dataKey: 'error',
    text: 'Ошибки',
    colStyle: 'width: auto;'
  }
]

export const statusColors = {
  unprocessed: textColors.gray, // - серый
  process: textColors.orange, // - желтый
  load: textColors.yellow, // - желтый
  ready_for_load: textColors.cyan, // - синий
  error_parse: textColors.red, // - красный
  error_load: textColors.red, // - красный
  success: textColors.green // - зеленый
}

export default options
