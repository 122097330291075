import { SERVICES } from '@/Api/Services'
import Filters from '@/Api/BuilderImporter/Filters'
import Log from '@/Api/BuilderImporter/Log'
import File from '@/Api/BuilderImporter/File'
// Site
import importBuilderSite from '@/Api/BuilderImporter/Site/Site'
import importBuilderSiteComparison from '@/Api/BuilderImporter/Site/SiteComparison'
import importBuilderSiteLoader from '@/Api/BuilderImporter/Site/SilteLoader'
// File
import ImportBuilderFile from '@/Api/BuilderImporter/AutoImport/File'
import ImportBuilderFileComparison from '@/Api/BuilderImporter/AutoImport/FileComparison'
import ImportBuilderFileLoader from '@/Api/BuilderImporter/AutoImport/FileLoader'
import ImportBuilderFileCheck from '@/Api/BuilderImporter/AutoImport/FileCheck'
import ImportDocumentFile from '@/Api/BuilderImporter/ImportDocument/File'

export const AuthAxiosDefaultConfig: any = {
  serviceKey: SERVICES.BUILDER_IMPORTER.key
}

export const API: any = {
  Log,
  File,
  Filters,
  importBuilderSite,
  importBuilderSiteComparison,
  importBuilderSiteLoader,
  ImportBuilderFile,
  ImportBuilderFileComparison,
  ImportBuilderFileLoader,
  ImportBuilderFileCheck,
  ImportDocumentFile
}
