<template>
  <div>
    <div style="display: none">
      {{ isEdited }}
    </div>
    <TwFlexCol>
      <TwFlexCell>
        <TwSelect
          v-model="form.status"
          label="Семейное положение"
          placeholder="Не выбрано"
          :dense="true"
          :disable-error-border="false"
          :persistent-label="true"
          :options="dictionary.status"
          :clearable="true"
          :disabled="formProcessing"
        />
      </TwFlexCell>
      <TwFlexCell v-if="form.status === 'marriage'">
        <TwCheckBox
          v-model="form.familyContract"
          :disabled="formProcessing"
          label="Наличие брачного контракта"
        />
      </TwFlexCell>
      <TwFlexCell>
        <TwCardSimpleFormSaveBlock
          bg-color="transparent"
          :loading="formProcessing"
          :cancel-confirm="false"
          :is-sticky="false"
          :disable-success="formProcessing"
          :disable-cancel="formProcessing"
          :visible="true"
          @success="handleSubmit"
          @cancel="handleCancel"
        />
      </TwFlexCell>
    </TwFlexCol>
  </div>
</template>

<script>
import API from '@/Api/Crm/Person/Family/index'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import TwSelect from '@/components/tw-ui/ui-kit/input/TwSelect.vue'
import TwCheckBox from '@/components/tw-ui/ui-kit/input/TwCheckBox.vue'
import TwFlexCol from '@/components/tw-ui/ui-kit/flex-block/TwFlexCol.vue'
import TwFlexCell from '@/components/tw-ui/ui-kit/flex-block/TwFlexCell.vue'
import TwCardSimpleFormSaveBlock from '@/components/tw-ui/forms/TwCardSimpleFormSaveBlock.vue'

export default {
  name: 'PersonFamilyForm',
  // eslint-disable-next-line vue/no-unused-components
  components: { TwCardSimpleFormSaveBlock, TwSelect, TwCheckBox, TwFlexCol, TwFlexCell },
  mixins: [FormCreateEditMixin],
  props: {
    personId: {
      type: Number,
      required: true
    },
    denseFields: {
      type: Boolean,
      default: false
    },
    disableRedirect: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      API: API,
      form: {
        id: null,
        personId: this.personId,
        status: null,
        familyContract: false
      }
    }
  },
  watch: {
    'form.status' () {
      if (this.form.status !== 'marriage') {
        this.form.familyContract = false
      }
    }
  },
  methods: {
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          for (const key in this.preset) {
            if (key === 'status') {
              this.$set(this.form, key, this.preset[key]?.value || null)
            } else {
              this.$set(this.form, key, this.preset[key])
            }
          }
        }
        resolve()
      })
    },
    handleResetCustom () {
      this.handleReset()
    },
    handleSubmit () {
      // console.warn(this.$options.name, ' handleSubmit ', this.formType, this.targetId)
      if (this.formProcessing === true) {
        return
      }

      this.formProcessing = true
      this.serverErrors = null
      if (this.formType === 'create') {
        this.API.store(this.form)
          .then(({ data }) => {
            console.warn(this.$options.name, ' CREATE ', data)
            // this.fetchData(data.data.id)
            this.$emit('create', data.data)
          })
          .catch((error) => {
            if (error?.response?.data) {
              console.error(this.$options.name, error.response.data)
              for (const key in error.response.data) {
                const [refKey, index] = key.split('.')
                // console.log(key, refKey, index, field)
                if (!this.serverErrors) this.serverErrors = {}
                if (!this.serverErrors[refKey]) this.serverErrors[refKey] = []
                this.serverErrors[refKey][index] = error.response.data[key][0]
              }
            }
          })
          .finally(() => {
            this.formProcessing = false
          })
      } else if (this.formType === 'edit' && this.targetId) {
        this.API.update(this.targetId, this.form)
          .then(({ data }) => {
            console.warn(this.$options.name, ' UPDATE ', data.data)
            this.$emit('update', data.data)
          })
          .catch((error) => {
            if (error?.response?.data) {
              console.error(this.$options.name, error.response.data)
              for (const key in error.response.data) {
                const [refKey, index] = key.split('.')
                // console.log(key, refKey, index, field)
                if (!this.serverErrors) this.serverErrors = {}
                if (!this.serverErrors[refKey]) this.serverErrors[refKey] = []
                this.serverErrors[refKey][index] = error.response.data[key][0]
              }
            }
          })
          .finally(() => {
            this.formProcessing = false
          })
      }
    }
  }
}
</script>
