<template>
  <div>
    <div
      class="tw-py-[12px]"
      :class="isUpdateForm ? 'tw-bg-an-flat-orange-lighten':''"
    >
      <div
        v-if="isUpdateForm"
        class="tw-px-[18px]"
      >
        <TaskCommentMainForm
          :id="item.id"
          style="width: 100%"
          :task-id="taskId"
          @cancel="cancelUpdating"
          @update="onUpdate"
        />
      </div>
      <div
        v-else
        class="tw-group tw-flex tw-gap-[12px] tw-items-start tw-w-full"
      >
        <EmployeeAvatar
          :id="item.userId"
          bordered
        />
        <div class="tw-flex-1">
          <div class="tw-flex tw-justify-between tw-items-center tw-mb-[10px]">
            <div class="tw-text-main-sm">
              <ViewPanelLink
                v-if="item.userId"
                :id="item.userId"
                label="Пользователь"
                component="employee-show"
              >
                <EmployeeName :id="item.userId" />
              </ViewPanelLink>
              <strong v-else>Тех. поддержка</strong>
              <span class="tw-ml-3 tw-text-an-flat-disabled-text">
                {{ item.createdAt }}
              </span>
            </div>
            <TwPanelContentBlockActionButton
              v-if="!isUpdatingItem && item.userId && !isNotEditable"
              class="tw-invisible group-hover:tw-visible"
              type="edit"
              title="Отредактировать комментарий"
              @click.stop="startUpdating"
            >
              Изменить
            </TwPanelContentBlockActionButton>
          </div>
          <div
            v-if="item.text"
            class="tw-pb-[10px]"
            v-html="$Global.nl2br(item.text)"
          />
          <div
            v-if="Array.isArray(item.files) && item.files.length"
            class="tw-flex tw-w-full "
          >
            <SupportFilesAttached :files="item.files" />
          </div>
        </div>
      </div>
    </div>
    <hr v-if="divider">
  </div>
</template>

<script>
import SupportFilesAttached from '@/views/Support/files/SupportFilesAttached'
import EmployeeAvatar from '@/components/other/employee/EmployeeAvatar'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink'
import EmployeeName from '@/components/other/employee/EmployeeName'
import TaskCommentMainForm from '@/views/Support/comment/main/TaskCommentMainForm'
import TwPanelContentBlockActionButton from '@/components/tw-ui/panels/TwPanelContentBlockActionButton.vue'
export default {
  name: 'TaskCommentItem',
  components: { TwPanelContentBlockActionButton, TaskCommentMainForm, EmployeeName, ViewPanelLink, EmployeeAvatar, SupportFilesAttached },
  props: {
    taskId: {
      type: Number,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    isUpdatingItem: {
      type: Boolean,
      default: false
    },
    isNotEditable: {
      type: Boolean,
      default: false
    },
    divider: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isUpdateForm: false
    }
  },
  methods: {
    startUpdating () {
      this.isUpdateForm = true
      this.$emit('update', true)
    },
    cancelUpdating () {
      this.isUpdateForm = false
      this.$emit('update', false)
    },
    onUpdate () {
      this.isUpdateForm = false
      this.$emit('refresh')
    }
  }
}
</script>

<style scoped>

</style>
