import { Module } from 'vuex'
import { RootState } from '@/store/types'
import { OsState } from './types'
import { nbSelection } from './modules/nb-selection'
import { nbLandHouseSelection } from './modules/nb-land-house-selection'

const namespaced = true

export const objectStorage: Module<OsState, RootState> = {
  namespaced,
  modules: {
    nbSelection,
    nbLandHouseSelection
  }
}
