import { RouteKeys } from '@/router/types'
import review from './review'
import videoReview from './videoReview'
import dealReview from './dealReview'
import dictPermissionRules from '@/Permissions/dictRules'

export default {
  path: RouteKeys.companyReviews,
  name: RouteKeys.companyReviews,
  meta: { name: 'Отзывы', icon: 'reviews-block', ruleNamespace: dictPermissionRules.AppServicePermissionRulesANFLATReviewReadReviewRule },
  redirect: { name: 'review-list' },
  component: () => import('@/views/Site/Admin/Index.vue'),
  children: [
    review,
    videoReview,
    dealReview
  ]
}
