import { AxiosResponse } from 'axios'
import HTTP from '@/Api/http'
import { AuthAxiosDefaultConfig } from '@/Api/BuilderImporter'

const path = '/auto-import/file-loader'

export default {
  display (param?: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig,
      timeout: 300000,
      params: param
    }
    return HTTP.get(`${path}/display`, $config)
  },
  loadFile (data: { fileId: number }): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.post(`${path}/load-file`, data, $config)
  },
  initComparisonFromFile (data: { fileId: number }): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.post(`${path}/init-comparison-from-file`, data, $config)
  }
}
