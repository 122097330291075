import { AxiosResponse } from 'axios'
import HTTP from '@/Api/http'
import { AuthAxiosDefaultConfig } from '@/Api/Review'

const path = '/deal-review'

export default {
  listInit (): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.get(`${path}/list-init`, $config)
  },
  getList (param?: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig,
      params: param
    }
    return HTTP.get(`${path}`, $config)
  },
  edit (dealId: number): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.get(`${path}/${dealId}`, $config)
  },
  // Просмотреть отзыв
  show (dealId: number): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.get(`${path}/${dealId}`, $config)
  },
  updateNoAnswerCall (dealId: number, data: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.post(`${path}/${dealId}/noAnswerCall`, data, $config)
  },
  delete (dealId: number): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.delete(`${path}/${dealId}`, $config)
  },
  // Управление ботом
  botProcessShow (dealId: number): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.get(`${path}/bot-process/${dealId}`, $config)
  },
  botProcessRegister (dealId: number, data: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.post(`${path}/bot-process/${dealId}`, data, $config)
  },
  listComments (reviewId: number): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.get(`${path}/${reviewId}/comments`, $config)
  },
  createComment (reviewId: number, data: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.post(`${path}/${reviewId}/comments`, data, $config)
  },
  updateStatus (reviedId: number, data: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.put(`${path}/${reviedId}/status`, data, $config)
  }
}
