import { RouteKeys } from '@/router/types'
import dictPermissionRules from '@/Permissions/dictRules'

export default {
  path: RouteKeys.mortgageComplexParams,
  name: RouteKeys.mortgageComplexParams,
  redirect: { name: `${RouteKeys.mortgageComplexParams}-list` },
  meta: { name: 'Параметры ЖК', icon: 'LCD-parameters', ruleNamespace: dictPermissionRules.AppServicePermissionRulesCRMMortgageProgramsReadProgramRule },
  component: () => import('@/views/Mortgage/Index.vue'),
  children: [
    {
      path: 'list',
      name: `${RouteKeys.mortgageComplexParams}-list`,
      meta: { name: 'Список Параметры ЖК', icon: 'LCD-parameters', hideInMenu: true },
      component: () => import('@/views/Mortgage/ComplexParams/List.vue')
    },
    {
      path: ':id/edit',
      name: `${RouteKeys.mortgageComplexParams}-edit`,
      meta: { name: 'Редактирование Параметры ЖК', icon: 'LCD-parameters', hideInMenu: true },
      component: () => import('@/views/Mortgage/ComplexParams/Edit.vue'),
      props: (route:any) => ({ id: route.params.id })
    },
    {
      path: 'create',
      name: `${RouteKeys.mortgageComplexParams}-create`,
      meta: { name: 'Добавление Параметры ЖК', icon: 'LCD-parameters', hideInMenu: true },
      component: () => import('@/views/Mortgage/ComplexParams/Edit.vue'),
      props: (route:any) => ({ id: route.params.id })
    },
    {
      path: ':id',
      name: `${RouteKeys.mortgageComplexParams}-show`,
      meta: { name: 'Просмотр Параметры ЖК', icon: 'LCD-parameters', hideInMenu: true },
      component: () => import('@/views/Mortgage/ComplexParams/Edit.vue'),
      props: (route:any) => ({ id: route.params.id })
    }
  ]
}
