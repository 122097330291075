<template>
  <div
    v-cloak
    v-if="info"
    class="tw-flex tw-gap-[12px] tw-flex-col"
  >
    <TwPanelContentBlockFieldView :horizontal="hasHorizontal">
      <template #title>
        Застройщик
      </template>
      <div v-if="info.builder">
        <ViewPanelLink
          :id="info.builder.id"
          component="company-show"
        >
          {{ info.builder.name }}
        </ViewPanelLink>
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView :horizontal="hasHorizontal">
      <template #title>
        Стоимость
      </template>
      <div v-if="info.price">
        {{ (info.price || 0).toLocaleString('ru') }} руб.
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView :horizontal="hasHorizontal">
      <template #title>
        Площадь
      </template>
      <div v-if="info.plan">
        {{ (info.plan.totalArea || 0).toLocaleString('ru') }} м2
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView :horizontal="hasHorizontal">
      <template #title>
        Планировка
      </template>
      <div v-if="info.plan">
        <ViewPanelLink
          :id="info.plan.id"
          component="realty-plan-show"
        >
          {{ info.plan.name }}
        </ViewPanelLink>
      </div>
    </TwPanelContentBlockFieldView>

    <TwPanelContentBlockFieldView :horizontal="hasHorizontal">
      <template #title>
        Описание
      </template>
      <div v-if="info.description">
        <ReadMoreExpander
          :is-nl-trigger="true"
          :text="info.description"
          :is-clean-html="false"
          :less-length="250"
        />
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView :horizontal="hasHorizontal">
      <template #title>
        Дата изменения
      </template>
      <div>
        {{ $Global.DateFormat.DateTimeShortMonthLong(info.updatedAt || info.createdAt) }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView
      v-if="info.creatorId"
      :horizontal="hasHorizontal"
    >
      <template #title>
        Создал
      </template>
      <div>
        <ViewPanelLink
          :id="info.creatorId"
          label="Пользователь"
          component="employee-show"
        >
          <EmployeeName :id="info.creatorId" />
        </ViewPanelLink>
      </div>
    </TwPanelContentBlockFieldView>
  </div>
</template>

<script>
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink.vue'
import EmployeeName from '@/components/other/employee/EmployeeName.vue'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'
import ReadMoreExpander from '@/components/other/text/ReadMoreExpander.vue'

export default {
  name: 'BuilderProjectShow',
  components: { ReadMoreExpander, TwPanelContentBlockFieldView, EmployeeName, ViewPanelLink },
  props: {
    info: {},
    dictionary: {}
  },
  data () {
    return {
      hasHorizontal: true
    }
  }
}
</script>
