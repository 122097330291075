<template>
  <div
    v-cloak
    v-if="info"
    class="tw-flex tw-gap-[12px] tw-flex-col"
  >
    <TwPanelContentBlockFieldView>
      <template #title>
        Название
      </template>
      <div v-if="info">
        <ViewPanelLink
          :id="info.id"
          label="Лид"
          component="lead-show"
        >
          {{ info.name }}
        </ViewPanelLink>
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Дата создания
      </template>
      <div v-if="info.createdAt">
        {{ $Global.DateFormat.DateTimeShortMonthLong(info.createdAt) }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Комментарий
      </template>
      <div v-if="info.comment">
        <span
          v-if="info.comment"
          v-html="info.comment"
        />
      </div>
    </TwPanelContentBlockFieldView>
  </div>
</template>

<script>
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'

export default {
  name: 'DealLeadInfoShow',
  components: { TwPanelContentBlockFieldView, ViewPanelLink },
  props: {
    info: {}
  }
}
</script>

<style scoped>

</style>
