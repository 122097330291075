import { RouteKeys } from '@/router/types'

export default {
  path: RouteKeys.contract,
  name: RouteKeys.contract,
  redirect: { name: `${RouteKeys.contract}-list` },
  meta: { name: 'Договоры', icon: 'block-company-contract' },
  component: () => import('@/views/Company/Contract/Index.vue'),
  children: [
    {
      path: 'list',
      name: `${RouteKeys.contract}-list`,
      meta: { name: 'Список договоров', icon: 'block-company-contract', hideInMenu: true },
      component: () => import('@/views/Company/Contract/List.vue')
    }
  ]
}
