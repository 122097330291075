<template>
  <div class="tw-w-full tw-px-[35px] tw-py-[28px] tw-rounded-[20px] tw-bg-white">
    <div />
    <div class="tw-flex tw-mb-[25px] tw-gap-[20px]">
      <NativeDateInputField
        v-model="graphFilters.dateFrom"
        class="tw-max-w-[250px] tw-text-right"
        dense
        hide-details
        outlined
        type="month"
        prefix="от"
        :disabled="loading"
      />
      <NativeDateInputField
        v-model="graphFilters.dateTo"
        class="tw-max-w-[250px]"
        dense
        hide-details
        outlined
        type="month"
        prefix="до"
        :disabled="loading"
      />
      <button
        class="tw-text-an-flat-dark-blue-link active:tw-text-an-flat-dark-blue-link-active"
        :disabled="loading"
        @click="changePeriod"
      >
        обновить
      </button>
    </div>
    <div class="tw-w-full">
      <LinearChart
        :chart-data="chartData"
        :loading="loading"
        :height="400"
        :options="chartOptions"
      />
    </div>
  </div>
</template>

<script>
import LinearChart from '@/components/Graph/Linear'
import { API } from '@/Api/ObjectStorage'
import NativeDateInputField from '@/components/input/NativeDateInputField'
import { newsBuildingsColors } from '@/components/Graph/graphColors'

export default {
  name: 'AnalyzeSimpleBuildingGraph',
  components: { NativeDateInputField, LinearChart },
  props: {
    buildingId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      graphFilters: {
        dateFrom: '',
        dateTo: ''
      },
      loading: false,
      chartData: {
        labels: [],
        datasets: []
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: false
        },
        layout: {
          padding: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0
          }
        },
        legend: {
          display: true,
          labels: {
            usePointStyle: true,
            fontSize: 10,
            padding: 15
          },
          position: 'bottom'
        },
        scales: {
          xAxes: [{
            offset: false,
            ticks: {
              display: true
            }
          }],
          yAxes: [{
            offset: false,
            ticks: {
              display: true
            }
          }]
        },
        tooltips: {
          mode: 'label',
          callbacks: {
            label: function (tooltipItem) {
              const price = tooltipItem.yLabel
              return new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(price)
            }
          }
        }
      }
    }
  },
  mounted () {
    const currDate = new Date()
    const fromDate = new Date().setFullYear(currDate.getFullYear() - 3)
    this.graphFilters = {
      dateFrom: new Date(fromDate).toISOString().substr(0, 7),
      dateTo: currDate.toISOString().substr(0, 7)
    }
    this.changePeriod()
  },
  methods: {
    changePeriod () {
      this.$nextTick(() => {
        this.fetchData()
      })
    },
    fetchData () {
      if (this.loading) return
      this.loading = true
      const graphFilters = { ...this.graphFilters, ...{} }
      API.DealAnalyze.priceChangeForBuilding(this.buildingId, graphFilters)
        .then(({ data }) => {
          const { graph = [], labels = [] } = data?.data || {}
          // Данные графика
          const newChartData = {
            labels: graph.map((item) => {
              return item.month || '~'
            }),
            datasets: labels.map((label, labelIndex) => {
              return {
                label: label,
                lineTension: 0,
                fill: false,
                pointBackgroundColor: newsBuildingsColors[labelIndex],
                pointBorderColor: newsBuildingsColors[labelIndex],
                borderColor: newsBuildingsColors[labelIndex],
                data: graph?.map((item) => {
                  return item.price[labelIndex]
                }) || [],
                spanGaps: true // value: null - показать обрыв графика
              }
            })
          }
          this.$set(this, 'chartData', newChartData)
        })
        .catch((error) => {
          console.error('error', error)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped>

</style>
