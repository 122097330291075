import { AxiosResponse } from 'axios'
import HTTP from '@/Api/http'
import { AuthAxiosDefaultConfig } from '@/Api/ObjectStorage'

const path = '/realty/newbuildings/building-chess'

export default {
  info (buildingId: any, params?: any): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig,
      params
    }
    return HTTP.get(`${path}/${buildingId}`, $config)
  }
}
