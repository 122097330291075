<template>
  <TwPanelContent
    class="tw-flex-1 tw-w-full"
    :content-classes="''"
  >
    <TwPanelContentBlock
      class="tw-w-full tw-flex-1 tw-mb-[6px]"
      :content-classes="''"
      no-data-text="Нет данных"
    >
      <template #title>
        <div>
          <div class="tw-flex tw-gap-[8px] tw-justify-center tw-items-center tw-bg-an-flat-gray-15 tw-rounded-sm tw-py-[6px] tw-px-[8px]">
            <button
              :disabled="false"
              :class="activeTab === tabs.LIST ? 'tw-bg-white tw-cursor-default':''"
              class="tw-flex tw-gap-[6px] tw-p-[6px] tw-h-full tw-justify-center tw-items-center tw-rounded-sm tw-px-[14px] tw-text-an-flat-gray-5 enabled:hover:tw-bg-white"
              @click="activeTab = tabs.LIST"
            >
              Список объектов
            </button>
            <button
              :disabled="false"
              :class="activeTab === tabs.MAP ? 'tw-bg-white tw-cursor-default':''"
              class="tw-flex tw-gap-[6px] tw-p-[6px] tw-justify-center tw-items-center tw-rounded-sm tw-px-[14px] enabled:hover:tw-bg-white disabled:tw-text-an-flat-disabled-text"
              @click="activeTab = tabs.MAP"
            >
              Объекты на карте
            </button>
          </div>
        </div>
      </template>
      <template #action>
        <TwSelect
          v-model="createType"
          class="tw-min-w-[260px]"
          placeholder="Создать объект"
          :dense="true"
          :options="typesRealtyOptions"
        />
        <button
          :disabled="!createType"
          class="tw-font-manrope tw-h-full tw-font-bold tw-uppercase tw-text-main-xs tw-text-white tw-bg-an-flat-green-btn-bg tw-rounded-sm tw-whitespace-nowrap tw-px-[20px] tw-py-[6px] enabled:hover:tw-bg-an-flat-green-btn-bg-active enabled:hover:tw-shadow disabled:tw-opacity-50"
          @click="addNew"
        >
          Создать
        </button>
      </template>
      <div>
        <template v-if="activeTab === tabs.LIST">
          <RealtyComplexBuildingList
            ref="objectsItems"
            :complex-id="complexId"
            :complex-type="complexType"
          />
        </template>
        <template v-else-if="activeTab === tabs.MAP">
          <RealtyComplexBuildingsInMap
            ref="objectsItems"
            :complex-id="complexId"
            :complex-type="complexType"
          />
        </template>
        <template v-else>
          activeTab - invalid
        </template>
      </div>
    </TwPanelContentBlock>
  </TwPanelContent>
</template>

<script>
import RealtyComplexBuildingList from '@/views/Newbuildings/Complex/buildings/RealtyComplexBuildingList.vue'
import TwPanelContent from '@/components/tw-ui/panels/TwPanelContent.vue'
import TwSelect from '@/components/tw-ui/ui-kit/input/TwSelect.vue'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import { mapActions } from 'vuex'
import {
  RealtyComplexTypes,
  RealtyObjectTypeOptionsFilter,
  TypesRealtyObjects
} from '@/views/Newbuildings/Editor/typesDict'
import RealtyComplexBuildingsInMap from '@/views/Newbuildings/Complex/buildings/RealtyComplexBuildingsInMap.vue'

export default {
  name: 'RealtyComplexObjectsTab',
  components: { RealtyComplexBuildingsInMap, TwPanelContentBlock, TwSelect, TwPanelContent, RealtyComplexBuildingList },
  props: {
    complexId: {
      type: [Number, String, null],
      default: null
    },
    complexType: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      tabs: {
        LIST: 'list',
        MAP: 'map'
      },
      activeTab: 'list',
      createType: null
    }
  },
  methods: {
    ...mapActions({
      showPanel: 'viewPanels/showPanel'
    }),
    refresh () {
      this.$refs.objectsItems?.refresh()
    },
    addNew () {
      this.showPanel({
        label: '',
        component: 'nb-editor-object',
        props: {
          id: 0,
          type: this.createType,
          complexId: this.complexId
        },
        callback: () => {
          console.warn(this.$options.name, '- addNew')
          this.refresh()
        }
      })
    }
  },
  computed: {
    typesRealtyOptions () {
      if (this.complexType === RealtyComplexTypes.residential_complex.value) {
        return RealtyObjectTypeOptionsFilter([
          TypesRealtyObjects.building.type,
          TypesRealtyObjects.parking.type
        ])
      } else if (this.complexType === RealtyComplexTypes.cottage_village.value) {
        return RealtyObjectTypeOptionsFilter([
          TypesRealtyObjects.land.type
        ])
      }
      return []
    }
  }
}
</script>
