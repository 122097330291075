<template>
  <div>
    <!-- TODO DEBUG
    <div class="red">
      <div>
        field=<small>{{ field }}</small>
      </div>
      <div>
        value=<small>{{ value }}</small>
      </div>
      <div>
        localValue = <small>[{{ localValue }}]</small>
      </div>
    </div> -->
    <div class="tw-flex tw-flex-wrap tw-gap-[8px]">
      <div
        class="tw-w-full tw-flex-1"
      >
        <TwSelect
          :value="localValue.type"
          :options="selectorOptions"
          placeholder="Не выбран"
          :show-label="false"
          :dense="true"
          :persistent-label="false"
          @input="onInputType"
        />
      </div>
      <div
        v-if="localValue && localValue.type === 'interval'"
        class=""
      >
        <ADateRangeSelect
          :value="localValue.value"
          @input="onInputOther('interval', $event)"
        />
      </div>
      <div
        v-if="localValue && (localValue.type === 'next_N_days' || localValue.type === 'last_N_days')"
        style="max-width: 200px; margin-left: 5px"
      >
        <ADateDaysSelect
          v-if="localValue.type === 'next_N_days'"
          :value="localValue.value[0]"
          @input="onInputOther('next_N_days', $event)"
        />
        <ADateDaysSelect
          v-if="localValue.type === 'last_N_days'"
          :value="localValue.value[0]"
          @input="onInputOther('last_N_days', $event)"
        />
      </div>
      <div
        v-if="localValue && localValue.type === 'exact'"
        style="max-width: 200px; margin-left: 5px"
      >
        <ADateSimpleSelect
          :value="localValue"
          default-current
          @input="onInputOther('exact', $event)"
        />
      </div>
      <div
        v-if="localValue && localValue.type === 'quarter'"
        style="max-width: 160px"
      >
        <ADateQuarterSelect
          key="quarter"
          :value="localValue"
          @input="onInputOther('quarter', $event)"
        />
      </div>
      <div
        v-if="localValue && localValue.type === 'month'"
        style="max-width: 160px;"
      >
        <ADateMonthSelect
          key="month"
          :value="localValue"
          @input="onInputOther('month', $event)"
        />
      </div>
      <div
        v-if="localValue && localValue.type === 'year'"
        style="max-width: 160px;"
      >
        <TwSelect
          class="tw-flex-1"
          :value="localValue.value[0]"
          :options="yearOptions"
          placeholder="Выбор"
          :show-label="false"
          :dense="true"
          :persistent-label="false"
          @input="onInputOther('year', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ADateRangeSelect from '@/components/filters/afilter/fields/aselect/ADateSelector/ADateRangeSelect.vue'
import ADateSimpleSelect from '@/components/filters/afilter/fields/aselect/ADateSelector/ADateSimpleSelect.vue'
import ADateMonthSelect from '@/components/filters/afilter/fields/aselect/ADateSelector/ADateMonthSelect.vue'
import ADateQuarterSelect from '@/components/filters/afilter/fields/aselect/ADateSelector/ADateQuarterSelect.vue'
import ADateDaysSelect from '@/components/filters/afilter/fields/aselect/ADateSelector/ADateDaysSelect.vue'
import TwSelect from '@/components/tw-ui/ui-kit/input/TwSelect.vue'

export default {
  name: 'DateIntervalInputField',
  components: { TwSelect, ADateDaysSelect, ADateQuarterSelect, ADateMonthSelect, ADateSimpleSelect, ADateRangeSelect },
  props: {
    hasRounded: {
      type: Boolean,
      default: false
    },
    value: {},
    field: {}
  },
  data () {
    return {
      defaultValues: {
        quarter: [new Date().toISOString().slice(0, 4), 'quarter_1'],
        month: [new Date().toISOString().slice(0, 4), '01'],
        year: new Date().toISOString().slice(0, 4),
        interval: [new Date().toISOString().slice(0, 10), new Date().toISOString().slice(0, 10)],
        exact: new Date().toISOString().slice(0, 10),
        last_N_days: 10,
        next_N_days: 10
      }
    }
  },
  computed: {
    localValue: {
      get () {
        return {
          type: this.value?.type || null,
          value: this.value?.value || []
        }
      },
      set () {
        console.error(this.$options.name, ' - Попытка изменить localValue')
        //
      }
    },
    selectorOptions () {
      const list = []
      list.push({
        label: 'Не задано',
        value: null
      })
      for (const key in this.field.typeInterval) {
        // console.log(key, this.field.typeInterval[key])
        list.push({
          label: this.field.typeInterval[key],
          value: key
        })
      }
      return list
    },
    yearOptions () {
      const years = []
      const currentYear = new Date().getFullYear()
      let startYear = currentYear - 5
      while (startYear < currentYear) {
        startYear++
        years.push({
          value: startYear.toString(),
          label: startYear
        })
      }
      return years.reverse()
    }
  },
  methods: {
    onInputOther (key, value) {
      console.log(this.$options.name, 'onInputOther', key, value)
      switch (key) {
        case 'exact': // Точная дата
          this.$emit('input', { type: this.localValue.type, value: [value] })
          this.$emit('inputFullData', `${value}`)
          break
        case 'interval': // Диапазон
          this.$emit('input', { type: this.localValue.type, value: value })
          this.$emit('inputFullData', `с ${value[0]} по ${value[1]}`)
          break
        case 'quarter': // Квартал
          this.$emit('input', { type: this.localValue.type, value: value })
          this.$emit('inputFullData', `${this.field.typeQuarters[value[1]]}кв. ${value[0]}`)
          break
        case 'month': // Месяц
          this.$emit('input', { type: this.localValue.type, value: value })
          this.$emit('inputFullData', `${this.field.month[Number(value[1]) - 1]} ${value[0]}`)
          break
        case 'year': // Год
          this.$emit('input', { type: this.localValue.type, value: [value] })
          this.$emit('inputFullData', value)
          break
        case 'next_N_days': // Следующие N дней
          this.$emit('input', { type: this.localValue.type, value: [value] })
          this.$emit('inputFullData', this.field.typeInterval[key].replace('N', value))
          break
        case 'last_N_days': // Последние N дней
          this.$emit('input', { type: this.localValue.type, value: [value] })
          this.$emit('inputFullData', this.field.typeInterval[key].replace('N', value))
          break
        default:
          break
      }
    },
    onInputType (event) {
      console.log(this.$options.name, 'onInputType', event, this.defaultValues[event], this.field)
      switch (event) {
        case 'exact': // Точная дата
          this.$emit('input', { type: event, value: [this.defaultValues[event]] })
          this.$emit('inputFullData', `${this.defaultValues[event]}`)
          break
        case 'interval': // Диапазон
          this.$emit('input', { type: event, value: this.defaultValues[event] })
          this.$emit('inputFullData', `с ${this.defaultValues[event][0]} по ${this.defaultValues[event][1]}`)
          break
        case 'quarter': // Квартал
          this.$emit('input', { type: event, value: this.defaultValues[event] })
          this.$emit('inputFullData', `${this.field.typeQuarters[this.defaultValues[event][1]]}кв. ${this.defaultValues[event][0]}`)
          break
        case 'month': // Месяц
          this.$emit('input', { type: event, value: this.defaultValues[event] })
          this.$emit('inputFullData', `${this.field.month[Number(this.defaultValues[event][1]) - 1]} ${this.defaultValues[event][0]}`)
          break
        case 'year': // Год
          this.$emit('input', { type: event, value: [this.defaultValues[event]] })
          this.$emit('inputFullData', this.defaultValues[event])
          break
        case 'next_N_days': // Следующие N дней
          this.$emit('input', { type: event, value: [this.defaultValues[event]] })
          this.$emit('inputFullData', this.field.typeInterval[event].replace('N', this.defaultValues[event]))
          break
        case 'last_N_days': // Последние N дней
          this.$emit('input', { type: event, value: [this.defaultValues[event]] })
          this.$emit('inputFullData', this.field.typeInterval[event].replace('N', this.defaultValues[event]))
          break
        default:
          this.$emit('input', event ? { type: event } : null)
          this.$emit('inputFullData', this.field.typeInterval[event])
          break
      }
    }
  }
}
</script>
