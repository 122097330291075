<template>
  <div v-cloak>
    <div class="my-3">
      <v-form class="mt-3">
        <v-row>
          <v-col cols="6">
            <v-select
              v-model="form.dealType"
              dense
              attach=""
              persistent-placeholder
              :disabled="formProcessing"
              outlined
              :items="dictionary.dealType"
              item-text="label"
              item-value="value"
              placeholder="Не выбрано"
              label="Тип сделки"
            />
            <v-select
              v-model="form.programsId"
              dense
              attach=""
              persistent-placeholder
              multiple
              :disabled="formProcessing"
              outlined
              small-chips
              :items="companyProgramsList"
              item-text="name"
              item-value="id"
              placeholder="Не выбрано"
              label="Программы кредитования"
            />
          </v-col>
          <v-col cols="6">
            <v-checkbox
              v-model="form.discountFromElectronRegistration"
              class="mt-0"
              :disabled="formProcessing"
              hide-details
              color="primary"
            >
              <template #label>
                <small>Считать субсидированную из ставки электронной регистрации</small>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
        <div class="mt-3 mb-5" />

        <div style="background-color: white">
          <div class="py-2 d-flex justify-start">
            <v-btn
              :disabled="formProcessing || (Array.isArray(form.items) && !form.items.length)"
              x-small
              text
              color="warning"
              @click="clearItemsList"
            >
              Очитить список
            </v-btn>
          </div>
          <ol class="collection collection-container">
            <!--     Заголовок таблицы       -->
            <li
              class="item item-container"
              style="position: sticky;top: 0;z-index: 1;"
            >
              <div class="attribute-container">
                <div class="attribute-container one-column">
                  <div class="attribute" />
                </div>
              </div>
              <div class="attribute-container">
                <div class="attribute-container one-column">
                  <div class="attribute">
                    №
                  </div>
                </div>
              </div>
              <div class="attribute-container">
                <div class="attribute-container one-column">
                  <div class="attribute">
                    Дисконт к ставке(%)
                  </div>
                </div>
              </div>
              <div class="attribute-container one-column">
                <div class="attribute">
                  Размер Комиссионного Вознаграждения(КВ)(%)
                </div>
              </div>
              <div class="attribute-container one-column">
                <div class="attribute">
                  Срок ставки(мес.)
                </div>
              </div>
              <div
                style="position: absolute; top: 3px; right: 5px; z-index: 1;"
                title="Добавить строку"
              >
                <v-btn
                  elevation="1"
                  x-small
                  color="success"
                  class="px-1"
                  @click.prevent="addNewItem"
                >
                  <v-icon small>
                    fa-plus
                  </v-icon>
                </v-btn>
              </div>
            </li>
            <!--     Индикатор загрузки       -->
            <li
              v-if="formProcessing"
              class="text-center py-5"
            >
              <v-progress-circular
                size="30"
                width="2"
                color="primary"
                indeterminate
              />
            </li>
            <!--     Строки таблицы       -->
            <template v-else-if="Array.isArray(form.items) && form.items.length">
              <div
                v-for="(item, iIndex) in form.items"
                :id="`row-${iIndex}`"
                :key="iIndex"
                class="item item-container"
              >
                <div class="attribute-container one-column">
                  <div
                    class="attribute px-0 text-center"
                    data-name="actions"
                  >
                    <v-menu
                      bottom
                      right
                      attach=""
                    >
                      <template #activator="{ on, attrs }">
                        <v-btn
                          x-small
                          icon
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>fa-ellipsis-v</v-icon>
                        </v-btn>
                      </template>
                      <v-list dense>
                        <v-list-item
                          dense
                          @click="removeRowItem(iIndex)"
                        >
                          <v-list-item-title class="error--text">
                            <small>
                              Удалить
                            </small>
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                </div>
                <div class="attribute-container one-column">
                  <div
                    class="attribute px-0 text-center"
                    data-name="actions"
                  >
                    {{ iIndex + 1 }}
                  </div>
                </div>
                <div class="attribute-container one-column">
                  <div
                    class="attribute"
                    data-name="discount"
                  >
                    <input
                      v-model.number="item.discount"
                      type="number"
                      style="width: 100%; text-align: right"
                      :readonly="false"
                      placeholder="Дисконт к ставке"
                      min="0"
                      max="100"
                      step="0.1"
                      :class="item.discount ? '' : 'error-input'"
                    >
                  </div>
                </div>
                <div class="attribute-container one-column">
                  <div
                    class="attribute"
                    data-name="commissionReward"
                  >
                    <input
                      v-model.number="item.commissionReward"
                      type="number"
                      style="width: 100%; text-align: right"
                      :readonly="false"
                      placeholder="Размер КВ"
                      min="0"
                      max="100"
                      step="0.1"
                      :class="item.commissionReward ? '' : 'error-input'"
                    >
                  </div>
                </div>
                <div class="attribute-container one-column">
                  <div
                    class="attribute"
                    data-name="term"
                  >
                    <select
                      v-model="item.term"
                      :class="item.term ? '' : 'error-input'"
                      style="width: 100%; text-align: right"
                    >
                      <option
                        v-for="(fieldName, index) in termList"
                        :key="index"
                        :value="fieldName.value"
                      >
                        {{ fieldName.label }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </template>
            <li
              v-else
              class="text-center py-5"
            >
              Не добавлены
            </li>
          </ol>
        </div>
      </v-form>
      <template v-if="Array.isArray(errors) && errors.length">
        <div
          v-for="(errorMessage, index) in errors"
          :key="index"
          class="ma-3 error--text"
        >
          {{ errorMessage }}
        </div>
      </template>
    </div>
    <TwCardSimpleFormSaveBlock
      class="tw-mt-[15px]"
      :loading="formProcessing"
      bg-color="transparent"
      :cancel-confirm="isEdited"
      :disable-cancel="!isEdited"
      :disable-success="!isEdited"
      :is-sticky="false"
      :visible="true"
      @success="handleSubmit"
      @cancel="handleCancel"
    />
  </div>
</template>

<script>
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import API from '@/Api/Mortgage/DiscountMatrix'
import programsAPI from '@/Api/Mortgage/Program/index'
import { mortgageTermList } from '@/views/Mortgage/dict'
import TwCardSimpleFormSaveBlock from '@/components/tw-ui/forms/TwCardSimpleFormSaveBlock.vue'

export default {
  name: 'DiscountMatrixMainEditForm',
  components: { TwCardSimpleFormSaveBlock },
  mixins: [FormCreateEditMixin],
  props: {
    companyId: {
      type: [String, Number],
      default: ''
    },
    copyFromId: {
      type: [String, Number],
      default: ''
    }
  },
  data () {
    return {
      API: API,
      form: {},
      termList: mortgageTermList,
      companyProgramsList: [],
      errors: [],
      defaultItem () {
        return {
          discount: null, // Дисконт к ставке
          commissionReward: null, // Размер КВ
          term: null // Срок ставки
        }
      }
    }
  },
  watch: {
    companyId: {
      handler: function () {
        this.fetchCompanyProgramsList()
      },
      immediate: true
    }
  },
  methods: {
    addNewItem () {
      this.form.items.push(this.defaultItem())
    },
    removeRowItem (index) {
      this.form.items.splice(index, 1)
    },
    clearItemsList () {
      if (confirm('Очитить записи матрицы?')) {
        this.$set(this.form, 'items', [])
      }
    },
    // Валидация элементов матрицы
    isMatrixItemsValid () {
      let isValid = true
      for (const rowIndex in this.form.items) {
        const rowItem = this.form.items[rowIndex]
        if (Object.values(rowItem).some(v => v === null || v === '' || v === false || v === undefined)) {
          document.getElementById(`row-${rowIndex}`).scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' })
          isValid = false
          break
        }
      }
      return isValid
    },
    handleSubmit () {
      console.warn('handleSubmit')
      if (!this.isMatrixItemsValid() || this.formProcessing === true) {
        return
      }

      this.formProcessing = true
      const request = this.formType === 'create'
        ? API.store(this.form)
        : API.update(this.targetId, this.form)

      this.errors = []
      request.then(({ data }) => {
        if (this.formType === 'create') {
          this.$emit('create', data?.data)
        } else {
          this.$emit('update', data?.data)
        }
        this.fetchData()
      }).catch((error) => {
        if (Array.isArray(error.response?.data?.error)) {
          this.errors = error.response?.data?.error
        }
      }).finally(() => {
        this.formProcessing = false
      })
    },
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          for (const key in this.preset) {
            if (key === 'company') {
              if (this.preset[key]?.id) {
                this.$set(this.form, 'companyId', this.preset[key]?.id)
              } else {
                this.$set(this.form, 'companyId', this.companyId)
              }
            } else if (key === 'programs') {
              this.$set(this.form, 'programsId', Array.isArray(this.preset[key]) ? this.preset[key].map(item => item.id) : [])
            } else if (key === 'dealType') {
              if (this.preset[key]?.value) {
                this.$set(this.form, key, this.preset[key]?.value)
              } else {
                this.$set(this.form, key, this.type)
              }
            } else {
              this.$set(this.form, key, this.preset[key])
            }
          }
        }
        // Если есть ID с которого нужно сделать копию
        if (this.copyFromId && this.formType === 'create') {
          this.fetchCopyFrom()
            .then(({ data }) => {
              console.log(this.$options.name, 'fetchCopyFrom:', this.$options.name, data?.data)
              if (Array.isArray(data?.data?.items) && data?.data?.items.length) {
                this.$set(this.form, 'items', data?.data?.items)
              }
            })
            .finally(() => {
              resolve()
            })
        } else {
          resolve()
        }
      })
    },
    fetchCompanyProgramsList () {
      programsAPI.getList({
        limit: 999,
        'MortgageProgram.active': true,
        'MortgageProgram.company': this.companyId
      }).then(({ data }) => {
        this.companyProgramsList = data?.data || []
      }).catch((error) => {
        console.warn('err', error)
      })
    },
    // получить объект из которого нужно скопировать данные
    fetchCopyFrom () {
      return this.API.show(this.copyFromId)
    }
  }
}
</script>

<style scoped lang="less">

@border: 1px solid #bbbbbb;

.error-input {
  border: 1px solid #ff3838;
}

ol.collection {
  font-size: 12px;
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

* {
  box-sizing: border-box;
}

/* Кол-во колонок и их ширина */
.item-container {
  display: grid;
  grid-template-columns: 30px 50px 1fr 1fr 1fr;
}
/* Зебра для разделения строк */
.collection-container>.item-container:nth-child(odd) {
  background-color: ghostwhite;
}

.attribute-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(var(--column-width-min), 1fr));
}

.two-columns {
  --column-width-min: 300px;
}

.one-column {
  --column-width-min: 100%;
  --column-width-max: 300px;
}

.collection {
  position: relative;
  border: @border;
  //border-top: @border;
  //border-left: @border;
}

/* In order to maximize row lines, only display one line for a cell */
.attribute {
  min-height: 30px;
  border-right: @border;
  border-bottom: @border;
  padding: 5px 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  &.input-field {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    input {
      border: 1px solid lightgrey;
      padding: 3px;
      margin-right: 3px;
      width: 80px;
      flex: 1;
      height: 100%;
      outline: none;
      text-align: right;
      &[type="number"] {
        -webkit-appearance: textfield;
        -moz-appearance: textfield;
        appearance: auto;
      }
      &[type=number]::-webkit-inner-spin-button,
      &[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
      }
    }
    &.editable {
      background-color: #ffe8d8;
    }

    .rate-marker {
      cursor: help;
      color: gray;
    }
  }
}

.collection-container>.item-container:first-child {
  background-color: #efefef;
}

/*.item-container:hover {*/
/*  background-color: rgb(200, 227, 252);*/
/*}*/

/* Center header labels */
.collection-container>.item-container:first-child .attribute {
  display: flex;
  align-items: center;
  justify-content: center;
  text-overflow: initial;
  overflow: auto;
  white-space: normal;
  font-weight: 600;
}
</style>
