import { RouteKeys } from '@/router/types'
import dictPermissionRules from '@/Permissions/dictRules'

export default {
  path: RouteKeys.news,
  name: RouteKeys.news,
  redirect: { name: `${RouteKeys.news}-list` },
  meta: { name: 'Новости', icon: 'news', ruleNamespace: dictPermissionRules.AppServicePermissionRulesOBJECTSNewBuildingsBuilderBuilderNews },
  component: () => import('@/views/ObjectStorage/Index.vue'),
  children: [
    {
      path: 'list',
      name: `${RouteKeys.news}-list`,
      meta: { name: 'Новости застройщиков', icon: 'home', hideInMenu: true, hText: 'Новости застройщиков' },
      component: () => import('@/views/Newbuildings/News/List.vue')
    },
    {
      path: ':id/edit',
      name: `${RouteKeys.news}-edit`,
      meta: { name: 'Редактирование новости', icon: 'home', hideInMenu: true, hText: 'Редактирование новости' },
      component: () => import('@/views/Newbuildings/News/Edit.vue'),
      props: (route:any) => ({ id: route.params.id })
    },
    {
      path: 'create',
      name: `${RouteKeys.news}-create`,
      meta: { name: 'Добавление новости', icon: 'home', hideInMenu: true, hText: 'Добавление новости' },
      component: () => import('@/views/Newbuildings/News/Edit.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    }
  ]
}
