import { tableItemStyleOne, tableItemStyleOneGen, TBaseTableHeaderItem } from '@/components/tw-ui/table/TableTypes'

const options: TBaseTableHeaderItem[] = [
  {
    ...tableItemStyleOne,
    dataKey: 'id',
    text: 'ID',
    align: 'center',
    colStyle: 'width: 30px;'
  },
  {
    ...tableItemStyleOneGen(false),
    text: 'Действие',
    dataKey: 'action',
    colStyle: 'width: 50px;'
  },
  {
    ...tableItemStyleOneGen(false),
    text: 'Объект',
    dataKey: 'address',
    colStyle: 'width: auto;'
  },
  {
    ...tableItemStyleOne,
    dataKey: 'active',
    text: 'Актив.',
    colStyle: 'width: 100px;'
  },
  {
    ...tableItemStyleOne,
    text: 'Страховая компания',
    dataKey: 'companyId',
    colStyle: 'width: 150px;'
  },
  {
    ...tableItemStyleOne,
    text: 'Стоимость',
    dataKey: 'paySum',
    align: 'right',
    colStyle: 'width: 100px;'
  },
  {
    ...tableItemStyleOne,
    text: 'Дата',
    dataKey: 'payDate',
    colStyle: 'width: 100px;'
  },
  {
    ...tableItemStyleOne,
    text: 'Ипотека до',
    dataKey: 'mortgageBefore',
    colStyle: 'width: 100px;'
  },
  {
    ...tableItemStyleOneGen(false),
    text: 'Заявка',
    dataKey: 'fromDealId',
    colStyle: 'width: auto;'
  },
  {
    ...tableItemStyleOne,
    dataKey: 'dates',
    text: 'Даты',
    colStyle: 'width: 150px;'
  }
]

export default options
