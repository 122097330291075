<template>
  <div class="tw-grid tw-gap-x-[7px] tw-grid-cols-2">
    <slot name="left">
      <button
        class="tw-px-[33px] tw-py-[10px] tw-rounded-md tw-bg-an-flat-blue-btn
              tw-text-main-sm tw-font-bold tw-text-white
              tw-transition-all tw-duration-200 tw-ease-in-out
              hover:tw-shadow-[0_4px_20px_0_rgba(20,162,249,0.39)]
              focus:tw-outline-2
              active:tw-bg-an-flat-blue-btn-active
              disabled:tw-bg-an-flat-btn-disabled-bg disabled:tw-text-an-flat-disabled-input-text disabled:tw-shadow-none"
        :disabled="disabled"
        @click="$emit('submit')"
      >
        {{ submitText }}
      </button>
      <!--   Кнопка сброса и отмены   -->
      <button
        v-if="showCancelBack"
        type="button"
        class="tw-px-[33px] tw-py-[10px] tw-rounded-md tw-bg-an-flat-gray-15 tw-text-main-sm tw-font-bold
              tw-transition-all tw-duration-200 tw-ease-in-out
              hover:tw-bg-an-flat-gray-3 hover:tw-text-white hover:tw-opacity-80
              focus:tw-outline-2
              active:tw-bg-an-flat-grey-3 active:tw-opacity-100
              disabled:tw-bg-an-flat-btn-disabled-bg disabled:tw-text-an-flat-disabled-input-text disabled:tw-opacity-100"
        @click="goToBack"
      >
        {{ cancelBackText }}
      </button>
    </slot>
    <slot name="right">
      <!--   Кнопка сохранить   -->
      <!--<v-btn :disabled="disabled" elevation="0" color="primary" @click="$emit('submit')">{{submitText}}</v-btn>-->
    </slot>
  </div>
</template>

<script>
export default {
  name: 'EditFormFooter',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    inModal: {
      type: Boolean,
      default: false
    },
    submitText: {
      type: String,
      default: 'Сохранить'
    },
    cancelBackText: {
      type: String,
      default: 'Отмена'
    },
    showCancelBack: {
      type: Boolean,
      default: true
    },
    cancelGoToBack: {
      type: Boolean,
      default: true
    },
    classes: {
      type: Array || String,
      default: () => {
        return []
      }
    }
  },
  methods: {
    goToBack () {
      if (!this.inModal && this.cancelGoToBack) {
        // При наличии истории откатываюсь назад
        if (history && history.length > 2) {
          this.$router.go(-1)
        } else {
          // История отсутствует - Ищу родителя текушей страницы и перехожу на него
          const parent = this.$route?.matched[this.$route.matched.length - 1]?.parent
          if (parent) {
            this.$router.push({ name: parent.name })
          }
        }
      }
      this.$emit('cancel')
    }
  }
}
</script>
