import Vue from 'vue'
import API from '@/Api/Logger/Log'

const isDev = process.env.NODE_ENV !== 'production'
const COMMIT = process.env.VUE_APP_COMMIT_SHA || 'dev'

// TODO ТОЛЬКО ДЛЯ ПРОДА
if (!isDev) {
// Глобальный обработчик ошибок TODO не работает, хз почему(
// window.addEventListener('error', function (event: any) {
//   console.error('onerror Error: ', event, Object.keys(event))
// })

  // Обработка ошибок Vue.js
  Vue.config.errorHandler = (err: Error, vm: Vue, info: string) :void => {
    const component = vm?.$options?.name || ''
    const props = vm?.$props
    const message = err?.toString()
    const parent = info.toString()
    // Контекст - информация о ошибке
    const context = {
      trace: err.stack,
      VUE_APP_COMMIT_SHORT_SHA: process.env?.VUE_APP_COMMIT_SHORT_SHA || 'dev',
      VUE_APP_COMMIT_TAG: process.env?.VUE_APP_COMMIT_TAG || 'dev',
      commit: COMMIT,
      userAgent: navigator.userAgent || '',
      platform: navigator?.platform || '',
      link: location.href || 'None href',
      component,
      parent,
      props
    }
    const logObj = {
      level: 500,
      section: `crm3/front/${component}`,
      channel: isDev ? 'DEV' : 'PROD',
      message: message,
      context: JSON.stringify(context)
    }
    API.sendLog(logObj).catch((error) => {
      console.error('Ошибка отправки логов! ', error)
    })
  }

  // Обработка ошибок от не обработанных catch промисов
  window.addEventListener('unhandledrejection', function (event: any) {
    const ignoredStatuses = [401, 422]
    // Игнорирование отправки ошибки AXIOS
    if (event.reason && event.reason.response && event.reason.response.status) {
      if (ignoredStatuses.indexOf(event.reason.response.status) !== -1) {
        event.preventDefault()
      }
    } else {
      // TODO SEND
      const logObj = {
        level: 400,
        section: `crm3/front/${event.type}`,
        channel: isDev ? 'DEV' : 'PROD',
        message: 'unhandledrejection',
        context: {}
      }
      if (typeof event.reason === 'string') {
        logObj.message = event.reason
        logObj.context = {
          userAgent: navigator.userAgent || '',
          platform: navigator?.platform || '',
          commit: COMMIT,
          VUE_APP_COMMIT_SHORT_SHA: process.env?.VUE_APP_COMMIT_SHORT_SHA || 'dev',
          VUE_APP_COMMIT_TAG: process.env?.VUE_APP_COMMIT_TAG || 'dev',
          link: location.href || 'None href',
          reason: event.reason,
          type: event?.type
        }
      } else if (event?.reason?.message) {
        const { message, stack } = event.reason
        logObj.message = message
        logObj.context = {
          userAgent: navigator.userAgent || '',
          platform: navigator?.platform || '',
          commit: COMMIT,
          VUE_APP_COMMIT_SHORT_SHA: process.env?.VUE_APP_COMMIT_SHORT_SHA || 'dev',
          VUE_APP_COMMIT_TAG: process.env?.VUE_APP_COMMIT_TAG || 'dev',
          link: location.href || 'None href',
          reason: message,
          stack,
          type: event?.type
        }
      } else {
        logObj.message = 'Another unhandledrejection error'
        logObj.context = {
          userAgent: navigator.userAgent || '',
          platform: navigator?.platform || '',
          commit: COMMIT,
          COMMIT_SHORT_SHA: process.env?.VUE_APP_COMMIT_SHORT_SHA || 'dev',
          COMMIT_TAG: process.env?.VUE_APP_COMMIT_TAG || 'dev',
          link: location.href || 'None href',
          reason: 'Another Promice error',
          type: event?.type
        }
      }
      logObj.context = JSON.stringify(logObj.context)
      API.sendLog(logObj).catch((error) => {
        console.error('Ошибка отправки логов! ', error)
      })
    }
  })
}

export default {
  sendLog: API.sendLog
}
