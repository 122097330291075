<template>
  <div
    v-if="info"
    class="tw-flex tw-gap-[14px] tw-flex-col"
  >
    <TwPanelContentBlockFieldView>
      <template #title>
        Номер договора
      </template>
      <div>
        № {{ info.contractNum }}
        <span
          v-if="isArchive"
          class="tw-px-[6px] tw-py-[3px] tw-bg-an-flat-blue-btn tw-text-white tw-rounded-sm tw-text-main-xs2 tw-mx-[6px]"
        >В архиве</span>
        <span
          v-if="isToOffice"
          class="tw-px-[6px] tw-py-[3px] tw-bg-an-flat-blue-btn tw-text-white tw-rounded-sm tw-text-main-xs2 tw-mx-[6px]"
        >Сдан в офис</span>
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Дата заключения
      </template>
      <div v-if="info.contractDate">
        {{ $Global.DateFormat.DateFull(info.contractDate) }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView v-if="info.userId">
      <template #title>
        Зарегистрировал номер договора
      </template>
      <div>
        <ViewPanelLink
          :id="info.userId"
          label="Пользователь"
          component="employee-show"
        >
          <EmployeeName :id="info.userId" />
        </ViewPanelLink>
        <div
          v-if="info.createdAt"
          class="tw-text-an-flat-disabled-text tw-text-main-sm2"
        >
          Создан: {{ $Global.DateFormat.DateTimeShort(info.createdAt) }}
        </div>
        <div
          v-if="info.createdAt && info.updatedAt && info.createdAt !== info.updatedAt"
          class="tw-text-an-flat-disabled-text tw-text-main-sm2"
        >
          Изменён: {{ $Global.DateFormat.DateTimeShort(info.updatedAt) }}
        </div>
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView v-if="info.signerUserId">
      <template #title>
        Подписал договор
      </template>
      <div v-if="info.signerUserId">
        <ViewPanelLink
          :id="info.signerUserId"
          label="Пользователь"
          component="employee-show"
        >
          <EmployeeName :id="info.signerUserId" />
        </ViewPanelLink>
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView v-if="info.contractFinishDate">
      <template #title>
        Дата сделки
      </template>
      <div>
        <span>{{ $Global.DateFormat.DateFull(info.contractFinishDate) }}</span>
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView v-if="info.comment">
      <template #title>
        Примечание
      </template>
      <div>
        <span>{{ info.comment }}</span>
      </div>
    </TwPanelContentBlockFieldView>
    <hr>
    <TwPanelContentBlockFieldView>
      <template #title>
        Комиссия агенства
      </template>
      <div>
        {{ (info.commissionSum || 0).toLocaleString('ru') }}
      </div>
    </TwPanelContentBlockFieldView>
    <hr>
    <TwPanelContentBlockFieldView>
      <template #title>
        Подписан(signed)
      </template>
      <div>
        {{ info.signed ? 'Да':'Нет' }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Закрыт(locked)
      </template>
      <div>
        {{ info.locked ? 'Да':'Нет' }}
      </div>
    </TwPanelContentBlockFieldView>
  </div>
  <div
    v-else
    class="text-muted text-center"
  >
    Нет данных
  </div>
</template>

<script>
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink.vue'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'
import EmployeeName from '@/components/other/employee/EmployeeName.vue'

export default {
  name: 'DealContractShowWrapper',
  components: { EmployeeName, TwPanelContentBlockFieldView, ViewPanelLink },
  props: {
    info: {},
    dictionary: {}
  },
  computed: {
    isArchive () {
      return this.info?.archive?.status?.value === 'accept'
    },
    isToOffice () {
      return this.info?.toOffice?.status?.value === 'accept'
    }
  }
}
</script>
