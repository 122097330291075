<template>
  <div :class="typing.type === 'date_interval' ? '':''">
    <!--    TODO DEBUG-->
    <!--    <div class="blue">-->
    <!--      <div>-->
    <!--        <small>{{field.type}}</small>-->
    <!--      </div>-->
    <!--      <div>-->
    <!--        <small>{{value}}</small>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--  Текстовое поле  -->
    <div
      v-if="typing.type.indexOf('string') !== -1"
      class=""
    >
      <TwInputText
        :value="value"
        :placeholder="field.label"
        :label="field.label"
        :dense="true"
        :cleanable="true"
        :persistent-label="true"
        @input="onInput"
      />
    </div>
    <div
      v-else-if="typing.type === 'number'"
      class=""
    >
      <TwInputText
        type="text"
        sub-type="int"
        max-length="13"
        :value="value"
        :placeholder="field.label"
        :label="field.label"
        :dense="true"
        :cleanable="true"
        :persistent-label="true"
        @input="onInput"
      />
    </div>
    <div
      v-else-if="typing.type === 'dealPhoneType'"
      class=""
    >
      <StringInputField
        :value="value"
        @input="onInput"
      />
    </div>
    <!--  Выбор из списка опций  -->
    <div v-else-if="typing.type === 'enum_sub' && field.parent">
      <TwSelectFromParent
        :parent-value="parentValue"
        :value="value"
        :field="field"
        @input="onInput"
        @input-object="onInputFullData"
      />
    </div>
    <div v-else-if="typing.type === 'enum'">
      <TwSelect
        :value="value"
        :placeholder="field.label"
        :label="field.label"
        :return-object="true"
        :multi="field.multi"
        :dense="true"
        :cleanable="true"
        :persistent-label="true"
        :options="field.options"
        @input="onInput"
        @input-object="onInputFullData"
      />
    </div>
    <div v-else-if="typing.type === 'enum' || typing.type === 'enumObject' || typing.type === 'objectMultiList'">
      <AEnumSimpleSelectForArray
        :value="value"
        :options="field.options"
        @input="onInput"
        @inputFullData="onInputFullData"
      />
    </div>
    <div
      v-else-if="typing.type.indexOf('enum') !== -1"
      class=""
    >
      <AEnumSimpleSelect
        :value="value"
        :options="field.options"
        @input="onInput"
        @inputFullData="onInputFullData"
      />
    </div>
    <div
      v-else-if="typing.type === 'planned_chronology'"
      class=""
    >
      <AEnumSimpleSelect
        :value="value"
        :options="field.options"
        @input="onInput"
        @inputFullData="onInputFullData"
      />
    </div>
    <!--  Выбор интервала даты  -->
    <div v-else-if="typing.type === 'date_interval'">
      <div class="tw-font-normal tw-text-an-flat-dark-blue-link tw-mb-[4px] tw-h-[16px] tw-translate-x-[5px]">
        {{ field.label }}
      </div>
      <DateIntervalInputField
        :value="value"
        :field="field"
        :has-rounded="true"
        @input="onInput"
        @inputFullData="onInputFullData"
      />
    </div>
    <!--  Выбор интервала чисел  -->
    <div v-else-if="typing.type === 'min_max'">
      <TwInputMinMaxFields
        :label="field.label"
        :value="value"
        :dense="true"
        @input="onInputMinMax"
      />
    </div>
    <!--  Выбор из структуры подразделений компании  -->
    <div v-else-if="typing.type === 'companyStructure'">
      <TwCompanyStructureSelect
        :value="value"
        :field="field"
        @input="onInput"
        @inputFullData="onInputFullData"
      />
    </div>
    <!--  Выбор множественные связи с др. сущностями - нр. для поиска из Deal.Person.id  -->
    <!--  Для связей возможно указание вложенности в relationName (Company.Developer) [Сущность].[Тип].[...]  -->
    <!--  Для связей возможно Обновление - новый вариант type="entity"[Сущность] и entity="person"[Тип сущности]  -->
    <div v-else-if="typing.type === 'entity'">
      <div
        v-if="typing.entity === 'complex'"
        class=""
      >
        <TwSelectRelations
          :value="value"
          :return-object="true"
          :placeholder="field.label"
          :label="field.label"
          :list-label="`Выбрать ${field.label}`"
          :multi="field.multi"
          :dense="true"
          :cleanable="true"
          :persistent-label="true"
          relation-name="complex"
          @input="onInput"
          @input-object="onInputFullData"
        />
      </div>
      <div
        v-else-if="typing.entity === 'person'"
        class=""
      >
        <TwSelectRelations
          :value="value"
          :return-object="true"
          :placeholder="field.label"
          :label="field.label"
          :list-label="`Выбрать ${field.label}`"
          :multi="field.multi"
          :dense="true"
          :cleanable="true"
          :persistent-label="true"
          relation-name="person"
          @input="onInput"
          @input-object="onInputFullData"
        />
      </div>
      <div
        v-else-if="typing.entity === 'company'"
        class=""
      >
        <TwSelectRelations
          :value="value"
          :return-object="true"
          :placeholder="field.label"
          :label="field.label"
          :list-label="`Выбрать ${field.label}`"
          :multi="field.multi"
          :dense="true"
          :cleanable="true"
          :persistent-label="true"
          relation-name="company"
          @input="onInput"
          @input-object="onInputFullData"
        />
      </div>
      <div
        v-else-if="typing.entity === 'companyBank'"
        class=""
      >
        <TwSelectRelations
          :value="value"
          :return-object="true"
          :placeholder="field.label"
          :label="field.label"
          :list-label="`Выбрать ${field.label}`"
          :multi="field.multi"
          :dense="true"
          :cleanable="true"
          :persistent-label="true"
          relation-name="bank"
          @input="onInput"
          @input-object="onInputFullData"
        />
      </div>
      <div
        v-else-if="typing.entity === 'developer' || typing.entity === 'companyDeveloper'"
        class=""
      >
        <TwSelectRelations
          :value="value"
          :return-object="true"
          :placeholder="field.label"
          :label="field.label"
          :list-label="`Выбрать ${field.label}`"
          :multi="field.multi"
          :dense="true"
          :cleanable="true"
          :persistent-label="true"
          relation-name="developer"
          @input="onInput"
          @input-object="onInputFullData"
        />
      </div>
      <div
        v-else-if="typing.entity === 'user'"
        class=""
      >
        <TwSelectRelations
          :value="value"
          :return-object="true"
          :placeholder="field.label"
          :label="field.label"
          :list-label="`Выбрать ${field.label}`"
          :multi="field.multi"
          :dense="true"
          :cleanable="true"
          :persistent-label="true"
          relation-name="user"
          @input="onInput"
          @input-object="onInputFullData"
        />
      </div>
      <!--   TODO ниже идут старые поля   -->
      <div v-else-if="typing.entity === 'leadStatus'">
        <TwLeadStatusSelect
          :value="value"
          :label="field.label"
          @input="onInput"
          @inputFullData="onInputFullData"
        />
      </div>
      <div v-else-if="typing.entity === 'dealStatus'">
        <TwDealStatusSelect
          filter-for-type
          :value="value"
          :label="field.label"
          @input="onInput"
          @inputFullData="onInputFullData"
        />
      </div>
      <div v-else-if="typing.entity === 'Complex'">
        <ComplexSearchSelect
          :value="value"
          no-label
          @input="onInput"
          @inputFullData="onInputFullData"
        />
      </div>
      <div v-else-if="typing.entity === 'ComplexBuilderRelation' || typing.entity === 'Builder'">
        <BuilderSearchSelect
          :value="value"
          no-label
          @input="onInput"
          @inputFullData="onInputFullData"
        />
      </div>
      <div v-else-if="field.relationName === 'Company'">
        <CompanySearchSelect
          :value="value"
          no-label
          @input="onInput"
          @inputFullData="onInputFullData"
        />
      </div>
      <div v-else-if="typing.entity === 'Company.Developer'">
        <CompanySearchSelect
          :default-filters="{'Company.type': 'developer'}"
          :value="value"
          no-label
          @input="onInput"
          @inputFullData="onInputFullData"
        />
      </div>
      <div v-else-if="typing.entity === 'Company.Bank'">
        <CompanySearchSelect
          :default-filters="{'Company.type': 'bank'}"
          :value="value"
          no-label
          @input="onInput"
          @inputFullData="onInputFullData"
        />
      </div>
      <small
        v-else
        class="error--text"
      >
        Field Type Template Not Found: [ {{ field }} ]
      </small>
    </div>
    <small
      v-else
      class="error--text"
    >
      Field Type Template Not Found: [ {{ field }} ]
    </small>
  </div>
</template>

<script>
import StringInputField from '@/components/filters/afilter/fields/StringInputField'
import DateIntervalInputField from '@/components/filters/afilter/fields/DateIntervalInputField'
import AEnumSimpleSelect from '@/components/filters/afilter/fields/aselect/AEnumSimpleSelect'
import CompanySearchSelect from '@/components/filters/afilter/fields/apopover/select/CompanySearchSelect'
import AEnumSimpleSelectForArray from '@/components/filters/afilter/fields/aselect/AEnumSimpleSelectForArray'
import BuilderSearchSelect from '@/components/filters/afilter/fields/apopover/select/BuilderSearchSelect'
import ComplexSearchSelect from '@/components/filters/afilter/fields/apopover/select/ComplexSearchSelect'

import TwInputText from '@/components/tw-ui/ui-kit/input/TwInputText.vue'
import TwSelect from '@/components/tw-ui/ui-kit/input/TwSelect.vue'
import TwSelectRelations from '@/components/tw-ui/ui-kit/input/select-relations/TwSelectRelations.vue'
import TwInputMinMaxFields from '@/components/tw-ui/ui-kit/input/TwInputMinMaxFields.vue'
import TwDealStatusSelect from '@/components/filters/twfilters/fields/custom-inputs/TwDealStatusSelect.vue'
import TwLeadStatusSelect from '@/components/filters/twfilters/fields/custom-inputs/TwLeadStatusSelect.vue'
import TwCompanyStructureSelect from '@/components/filters/twfilters/fields/custom-inputs/TwCompanyStructureSelect.vue'
import TwSelectFromParent from '@/components/filters/twfilters/fields/custom-inputs/TwSelectFromParent.vue'

export default {
  name: 'TwFilterFieldsWrapper',
  components: {
    TwSelectFromParent,
    TwCompanyStructureSelect,
    TwLeadStatusSelect,
    TwDealStatusSelect,
    TwInputMinMaxFields,
    TwSelectRelations,
    TwSelect,
    TwInputText,
    ComplexSearchSelect,
    BuilderSearchSelect,
    AEnumSimpleSelectForArray,
    CompanySearchSelect,
    AEnumSimpleSelect,
    DateIntervalInputField,
    StringInputField
  },
  props: {
    field: {},
    value: {},
    parentValue: {}
  },
  computed: {
    typing () {
      return {
        type: this.field.type,
        entity: this.field.relationName || this.field.entity
      }
    }
  },
  methods: {
    onInputMinMax (eventData) {
      // console.log('onInputMinMax', eventData)
      const [min, max] = eventData
      if (min !== null || max !== null) {
        this.onInput(eventData)
        if (min === null && max !== null) {
          this.onInputFullData(`до ${max}`)
        } else if (min !== null && max === null) {
          this.onInputFullData(`от ${min}`)
        } else if (min !== null && max !== null) {
          this.onInputFullData(`от ${min} до ${max}`)
        }
      } else {
        this.onInputFullData('')
        this.onInput([])
      }
    },
    onInputFullData (eventData) {
      // console.warn(this.$options.name, 'onInputFullData', { eventData }, this.field.type)
      if ((this.typing.type === 'entity') && Array.isArray(eventData) && eventData.length) {
        this.$emit('inputText', eventData.map(item => item.label || item.name || item.id).join(', '))
      } else if (this.field.type === 'date_interval') {
        this.$emit('inputText', eventData)
      } else if (this.field.type === 'enum_sub') {
        this.$emit('inputText', eventData?.label || eventData)
      } else if (this.field.type === 'enum') {
        if (this.field.multi) {
          this.$emit('inputText', Array.isArray(eventData) ? eventData.map(i => i?.label || '-').join(', ') : eventData)
        } else {
          this.$emit('inputText', eventData?.label || eventData)
        }
      } else if (this.field.type === 'min_max') {
        this.$emit('inputText', eventData)
      } else {
        this.$emit('inputText', eventData)
        console.error(this.$options.name, ': onInputFullData ERROR - не обработанное входящее значение: ', this.field.type, eventData, Array.isArray(eventData))
      }
    },
    onInput (eventData) {
      // console.warn(this.$options.name, 'onInput', eventData, this.field.type)
      if (eventData?.inputType) {
        // если это событие ввода
        this.$emit('inputValue', eventData.target.value)
      } else if (this.field.type === 'enum') {
        this.$emit('inputValue', eventData)
      } else if (this.field.type === 'enum_sub') {
        this.$emit('inputValue', eventData)
      } else if (this.field.type === 'date_interval') {
        this.$emit('inputValue', eventData)
      } else if (typeof eventData === 'string') {
        this.$emit('inputValue', eventData)
      } else if (typeof eventData === 'number') {
        this.$emit('inputValue', eventData)
      } else if (Array.isArray(eventData) && eventData.length) {
        this.$emit('inputValue', eventData)
      } else {
        this.$emit('inputValue', eventData)
        console.error(this.$options.name, ': onInput ERROR - не обработанное входящее значение: ', eventData)
      }
    }
  }
}
</script>
