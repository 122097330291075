<template>
  <button
    :disabled="!link"
    :title="title"
    class="tw-drop-shadow-lg tw-p-[10px] tw-m-[10px] tw-rounded-md tw-border tw-border-solid tw-text-[#8C94A8] enabled:hover:tw-text-white enabled:hover:tw-bg-an-flat-blue-btn-hover enabled:hover:tw-border-white"
    @click.stop="download"
  >
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.3995 8.51849C14.7574 8.87645 14.7574 9.45688 14.3995 9.81484L9.81614 14.3982C9.64427 14.5701 9.41107 14.6667 9.16797 14.6667C8.92487 14.6667 8.69167 14.5701 8.51979 14.3982L3.93645 9.81484C3.57847 9.45688 3.57847 8.87645 3.93645 8.51849C4.29444 8.16053 4.87483 8.16053 5.23282 8.51849L8.2513 11.537V0.916667C8.2513 0.41041 8.66169 0 9.16797 0C9.67424 0 10.0846 0.41041 10.0846 0.916667V11.537L13.1031 8.51849C13.4611 8.16053 14.0415 8.16053 14.3995 8.51849ZM17.4167 12.832C16.9104 12.832 16.5 13.2424 16.5 13.7487V16.4987H1.83333V13.7487C1.83333 13.2424 1.42292 12.832 0.916667 12.832C0.41041 12.832 0 13.2424 0 13.7487V17.4154C0 17.9216 0.41041 18.332 0.916667 18.332H17.4167C17.9229 18.332 18.3333 17.9216 18.3333 17.4154V13.7487C18.3333 13.2424 17.9229 12.832 17.4167 12.832Z"
        fill="currentColor"
      />
    </svg>
  </button>
</template>

<script>
import { downloadFile } from '@/Api/http'
export default {
  name: 'TwButtonDownloadFileFromLink',
  props: {
    link: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: 'Скачать'
    }
  },
  methods: {
    download () {
      if (this.link) {
        downloadFile(this.link)
      }
    }
  }
}
</script>

<style scoped>

</style>
