<template>
  <section>
    <h3 class="tw-mb-[17px] tw-text-an-flat-black-main tw-font-bold tw-text-[32px] tw-leading-[44px]">
      Расположение на карте
    </h3>
    <div>
      <div class="tw-flex tw-items-center tw-overflow-hidden tw-justify-center tw-rounded-[20px] tw-bg-an-flat-bg-main tw-w-[100%] tw-min-h-[567px]">
        <YandexMapDesignationObjects
          v-if="complexMapPoint"
          :coords="complexMapPoint"
          :poly-coords="complexPolygons"
          :scroll="false"
          class="tw-min-h-[567px] tw-w-[100%]"
        />
        <div
          v-else
          class="tw-text-an-flat-gray-5 tw-font-bold tw-text-[20px]"
        >
          Карта
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import YandexMapDesignationObjects from '@/components/Map/YandexMapDesignationObjects.vue'

export default {
  name: 'PageComplexBlockMap',
  props: {
    complexPolygons: {
      type: Array,
      default: () => []
    },
    complexMapPoint: {
      type: Array,
      default: () => []
    }
  },
  components: { YandexMapDesignationObjects }
}
</script>

<style scoped>

</style>
