<template>
  <div>
    <slot
      name="trigger"
      v-bind="{ on }"
    />
    <TwDialog
      v-model="show"
      :max-width="maxWidth"
      @close="show = false"
    >
      <template #header>
        <div>
          <div class="tw-text-h4 tw-font-semibold">
            {{ title }}
          </div>
        </div>
      </template>
      <template #body>
        <div class="tw-overflow-auto">
          <slot
            name="form"
            v-bind="{ on }"
          />
        </div>
      </template>
    </TwDialog>
  </div>
</template>

<script>
import TwDialog from '../tw-ui/modal/TwDialog.vue'

export default {
  name: 'FormModalWrapper',
  components: { TwDialog },
  props: {
    title: {
      type: String,
      default: 'Заголовок модального окна'
    },
    maxWidth: {
      default: '700px'
    }
  },
  data () {
    return {
      show: false
    }
  },
  methods: {
    on () {
      this.show = !this.show
    }
  }
}
</script>

<style scoped>

</style>
