<template>
  <div class="tw-font-manrope tw-text-main-sm2 tw-flex tw-gap-[10px] tw-bg-an-flat-panel-modal-bg tw-p-[9px] tw-rounded-[14px]">
    <template v-if="Array.isArray(options) && options.length">
      <button
        v-for="item in options"
        :key="item.value"
        :disabled="disabled || !item.active"
        :class="[item.value === value ? 'tw-font-semibold tw-border-an-flat-green-subside-text' : 'disabled:tw-opacity-50']"
        class="tw-flex-1 tw-bg-white tw-border tw-border-solid tw-text-center tw-py-[12px] tw-px-[16px] tw-rounded-[10px] enabled:hover:tw-border-an-flat-green-subside-text"
        @click="onSelect(item)"
      >
        {{ item.label }}
      </button>
    </template>
    <div
      v-else
      class="tw-text-center tw-p-[30px]"
    >
      Нет данных
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaymentMethodSelectRealtyObjectReservation',
  props: {
    value: {
      type: String,
      default: null
    },
    options: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  methods: {
    onSelect (item) {
      this.$emit('input', item.value)
    }
  }
}
</script>
