<template>
  <div>
    <TwPanelContent>
      <TwPanelContentBlock
        class="tw-flex-1 tw-mb-[6px]"
        no-data-text="Нет добавленых документов"
      >
        <template #title>
          <div>
            Анкеты клиента по выбранным программам кредитования
          </div>
        </template>
        <template #action>
          <TwPanelActionButton
            color="blue"
            @click.prevent="$emit('currentTabChange', 'variants')"
          >
            Подобрать еще
          </TwPanelActionButton>
        </template>
        <div>
          <div
            v-if="loading"
            class="tw-flex"
          >
            <TwCircleLoader class="tw-m-auto" />
          </div>
          <table
            v-else
            class="tw-table-auto tw-w-full"
          >
            <thead class="tw-border-b tw-border-solid">
              <tr class="[&>*]:tw-p-[10px] [&>*]:tw-text-left">
                <th>
                  #
                </th>
                <th>
                  Анкеты
                </th>
                <th>
                  Состояние
                </th>
                <th>
                  Банк / Программа
                </th>
                <th>
                  Контакт
                </th>
              </tr>
            </thead>
            <tbody v-if="Array.isArray(dealSelectedPrograms) && dealSelectedPrograms.length">
              <tr
                v-for="(worksheet, index) in dealSelectedPrograms"
                :key="worksheet.id"
                class="[&>*]:tw-p-[12px] even:tw-bg-gray-50 odd:tw-bg-white"
              >
                <td>{{ index + 1 }}</td>
                <td>
                  <ViewPanelLink
                    :id="worksheet.id"
                    label="Анкета"
                    component="mortgage-program-worksheet-show"
                    :component-props="{ dealId }"
                    :callback="() => $emit('refresh')"
                  >
                    Анкета: {{ worksheet?.program?.name }}
                  </ViewPanelLink>
                </td>
                <td>
                  {{ worksheet.status && worksheet.status.label }}
                </td>
                <td>
                  <div class="d-flex">
                    <div v-if="worksheet?.program?.company">
                      <ViewPanelLink
                        :id="worksheet?.program?.company?.id"
                        label="Компания"
                        component="company-show"
                      >
                        {{ worksheet?.program?.company?.name }}
                      </ViewPanelLink>
                    </div>
                    <div class="mx-2">
                      /
                    </div>
                    <div v-if="worksheet?.program">
                      <ViewPanelLink
                        :id="worksheet?.program?.id"
                        label="Программа"
                        component="mortgage-program-show"
                      >
                        {{ worksheet?.program?.name }}
                      </ViewPanelLink>
                    </div>
                  </div>
                </td>
                <td>
                  <div v-if="worksheet.contact">
                    <div v-if="worksheet.contact.person">
                      <ViewPanelLink
                        :id="worksheet.contact.person.id"
                        label="Контакт"
                        component="person-show"
                      >
                        {{ $Global.peopleName(worksheet.contact.person, 'fi') }}
                      </ViewPanelLink>
                    </div>
                    {{ worksheet.contact.type }}:
                    <i v-text="`${worksheet.contact.contact}`" />
                  </div>
                  <div
                    v-else
                    class="tw-text-an-flat-disabled-text"
                  >
                    Не выбран
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr class="[&>*]:tw-p-[10px]">
                <td
                  class="tw-text-center tw-text-an-flat-disabled-text"
                  colspan="99"
                >
                  Отсутствуют
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </TwPanelContentBlock>
    </TwPanelContent>
  </div>
</template>

<script>
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink'
import dealMortgageProgramAPI from '@/Api/Crm/Deal/Mortgage/Program'
import TwPanelContent from '@/components/tw-ui/panels/TwPanelContent.vue'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwPanelActionButton from '@/components/tw-ui/panels/TwPanelActionButton.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'

export default {
  name: 'DealMortgageProgramsWorksheetTab',
  components: { ViewPanelLink, TwPanelContent, TwPanelActionButton, TwPanelContentBlock, TwCircleLoader },
  mixins: [ViewPanelShowMixin],
  props: {
    dictionary: {
      type: Object,
      default: () => {
        return {}
      }
    },
    selectedPrograms: {
      type: Array,
      default: () => []
    },
    mortgageDeal: {
      type: Object,
      default: () => {
        return {}
      }
    },
    dealId: {
      type: Number || null,
      default: null
    },
    contractInfo: {
      type: Object || null,
      default: null
    }
  },
  data () {
    return {
      loading: false,
      dealSelectedPrograms: []
    }
  },
  mounted () {
    this.listSelectedPrograms()
  },
  methods: {
    listSelectedPrograms () {
      this.loading = true
      dealMortgageProgramAPI.list(this.dealId)
        .then(({ data }) => {
          this.dealSelectedPrograms = data?.data || []
        })
        .catch((error) => {
          console.error(this.$options.name, ' LIST Program', error)
          this.dealSelectedPrograms = []
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped>

</style>
