<template>
  <div>
    <TwPanelContentBlock class="tw-mb-[6px]">
      <template #title>
        Информация для фиксации
      </template>
      <template #action>
        <TwPanelContentBlockActionButton
          v-if="!targetFixationInfo"
          type="add"
          @click="onEditItem(0)"
        >
          добавить
        </TwPanelContentBlockActionButton>
        <TwPanelContentBlockActionButton
          type="edit"
          @click="onEditItem(targetFixationInfo.id)"
        >
          изменить
        </TwPanelContentBlockActionButton>
      </template>
      <div>
        <div
          v-if="loading"
          class="tw-flex tw-text-center"
        >
          <TwCircleLoader class="tw-m-auto" />
        </div>
        <div
          v-else
          class=""
        >
          <table
            v-if="targetFixationInfo"
            class="tw-table tw-table-auto tw-w-full tw-text-left"
          >
            <tbody>
              <tr class="tw-border-b">
                <td class="tw-p-[7px] tw-text-an-flat-disabled-text">
                  Срок фиксации
                </td>
                <td class="tw-p-[7px] tw-font-medium">
                  {{ targetFixationInfo.days || '-' }} дн.
                </td>
              </tr>
              <tr class="tw-border-b">
                <td class="tw-p-[7px] tw-text-an-flat-disabled-text">
                  Требуется ходатайство
                </td>
                <td class="tw-p-[7px] tw-font-medium">
                  {{ targetFixationInfo.needPetition ? 'Да':'Нет' }}
                </td>
              </tr>
              <tr
                v-if="targetFixationInfo.description"
                class="tw-border-b"
              >
                <td class="tw-p-[7px] tw-text-an-flat-disabled-text">
                  Описание для риелтора
                </td>
                <td class="tw-p-[7px] tw-font-medium">
                  {{ targetFixationInfo.description || 'Нет' }}
                </td>
              </tr>
              <template v-if="targetFixationInfo.communicationMethod || targetFixationInfo.communicationContact || targetFixationInfo.communicationInfo">
                <tr class="tw-border-b">
                  <td class="tw-p-[7px] tw-text-an-flat-disabled-text">
                    Способ связи
                  </td>
                  <td class="tw-p-[7px] tw-font-medium">
                    <div v-if="targetFixationInfo.communicationMethod">
                      {{ targetFixationInfo.communicationMethod.label || 'Нет' }}
                    </div>
                  </td>
                </tr>
                <tr
                  v-if="targetFixationInfo.communicationContact"
                  class="tw-border-b"
                >
                  <td class="tw-p-[7px] tw-text-an-flat-disabled-text">
                    Контакт
                  </td>
                  <td class="tw-p-[7px] tw-font-medium">
                    {{ targetFixationInfo.communicationContact || 'Нет' }}
                  </td>
                </tr>
                <tr
                  v-if="targetFixationInfo.communicationInfo"
                  class="tw-border-b"
                >
                  <td class="tw-p-[7px] tw-text-an-flat-disabled-text">
                    Доп. инфо
                  </td>
                  <td class="tw-p-[7px] tw-font-medium">
                    {{ targetFixationInfo.communicationInfo || 'Нет' }}
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
          <div
            v-else
            class="tw-w-full tw-text-center"
          >
            Нет данных
          </div>
        </div>
      </div>
    </TwPanelContentBlock>
    <TwDialog
      :value="editDialog"
      max-width="600px"
      @input="editDialog = $event"
    >
      <template #header>
        <div class="tw-text-h4">
          Информация для фиксации
        </div>
      </template>
      <template #body>
        <div>
          <BuilderFixationInfoForm
            :id="editedId"
            :company-id="companyId"
            @cancel="editDialog = false"
            @create="refresh"
            @update="refresh"
          />
        </div>
      </template>
    </TwDialog>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import SettingsFixationInfo from '@/Api/Rtd/RequestToDeveloper/Settings/FixationInfo'
import TwDialog from '@/components/tw-ui/modal/TwDialog.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import BuilderFixationInfoForm
  from '@/views/Dictionary/General/Company/developer/SettingsTab/BuilderFixationInfo/BuilderFixationInfoForm.vue'
import TwPanelContentBlockActionButton from '@/components/tw-ui/panels/TwPanelContentBlockActionButton.vue'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'

export default defineComponent({
  name: 'BuilderFixationInfo',
  components: {
    TwPanelContentBlock,
    TwPanelContentBlockActionButton,
    BuilderFixationInfoForm,
    TwCircleLoader,
    TwDialog
  },
  props: {
    companyId: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      list: [],
      loading: false,

      editDialog: false,
      editedId: 0
    }
  },
  computed: {
    targetFixationInfo () {
      return this.list && this.list.length && this.list[0]
    }
  },
  mounted () {
    this.refresh()
  },
  methods: {
    onEditItem (itemId) {
      this.editDialog = true
      this.editedId = itemId
    },
    fetch () {
      this.editDialog = false
      this.editedId = 0
      this.loading = true
      SettingsFixationInfo.getList({}, this.companyId)
        .then(({ data: result }) => {
          this.list = result?.data || []
        })
        .catch((error) => {
          console.error(this.$options.name, error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    refresh () {
      this.fetch()
    }
  }
})
</script>

<style scoped>

</style>
