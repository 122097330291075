<template>
  <v-container fluid>
    <div
      class="d-flex justify-space-between"
      style="gap: 10px"
    >
      <div style="width: 100%">
        <v-text-field
          v-model.number="internalValue.firstSumMin"
          type="number"
          label="Первоначальный взнос от"
          suffix="%"
          autocomplete="off"
          :rules="[...percentRules]"
          required
        />
      </div>
      <div style="width: 100%">
        <v-text-field
          v-model.number="internalValue.firstSumMax"
          type="number"
          label="Первоначальный взнос до"
          suffix="%"
          autocomplete="off"
          :rules="[...requireRules, ...percentRules]"
          required
        />
      </div>
    </div>
    <div
      class="d-flex justify-space-between"
      style="gap: 10px"
    >
      <div style="width: 100%">
        <v-text-field
          v-model.number="internalValue.rate"
          type="number"
          label="Ставка банка"
          suffix="%"
          autocomplete="off"
          :rules="[...requireRules, ...percentRules]"
          required
        />
      </div>
      <div style="width: 100%">
        <v-text-field
          v-model.number="internalValue.discountFlat"
          type="number"
          label="Дисконт ФЛЭТ"
          suffix="%"
          autocomplete="off"
        />
      </div>
      <div style="width: 100%">
        <v-text-field
          v-model.number="internalValue.discountElectronRegistration"
          type="number"
          label="Дисконт эл. регистрации"
          suffix="%"
          autocomplete="off"
        />
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'MortgageProgramRateForm',
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      requireRules: [
        v => !!v || 'Поле обязательное'
      ],
      percentRules: [
        v => (typeof v === 'number' && v >= 0 && v <= 100) || '% должен быть от 0 до 100'
      ]
    }
  },
  computed: {
    internalValue: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style lang="less">
.input-field-align-right {
  input {
    text-align: right;
  }
}
</style>
