<template>
  <TwDialog
    :value="showDialog"
    max-width="600px"
    :is-close-btn="false"
    :padding-class="''"
    @input="showDialog = $event"
  >
    <template #body>
      <div class="tw-font-manrope tw-text-main-xs tw-p-[12px]">
        <div
          v-if="loading"
          class="tw-flex tw-justify-center tw-items-center tw-p-10"
        >
          <TwCircleLoader />
        </div>
        <div
          v-else-if="list"
          class="tw-w-full tw-h-full"
        >
          <div class="tw-flex tw-justify-between tw-mb-[7px] tw-font-semibold ">
            <div class="tw-flex tw-gap-[9px]">
              <button class="tw-px-[13px] tw-py-[6px] tw-rounded-sm tw-whitespace-nowrap tw-border tw-border-solid tw-border-transparent tw-bg-white hover:tw-border-white disabled:tw-opacity-30 disabled:hover:tw-border-transparent">
                Акции
              </button>
            </div>
            <div class="tw-flex tw-gap-[9px]">
              <button
                class="tw-group tw-border tw-border-solid tw-border-white tw-px-[13px] tw-py-[6px] tw-text-[11px] tw-rounded-sm disabled:tw-opacity-50"
                title="Закрыть"
                @click.stop="showDialog = false"
              >
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    class="group-hover:tw-fill-an-flat-red-main"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.69806 0.294442C1.3096 -0.0981475 0.679797 -0.0981475 0.291337 0.294442C-0.0971125 0.687023 -0.0971125 1.32353 0.291337 1.71611L6.51434 8.00531L0.291381 14.2945C-0.0970987 14.6871 -0.0971288 15.3236 0.291301 15.7162C0.679731 16.1087 1.30954 16.1087 1.69802 15.7161L7.92097 9.42689L14.144 15.7161C14.5325 16.1087 15.1623 16.1087 15.5507 15.7161C15.9392 15.3235 15.9392 14.687 15.5507 14.2944L9.32768 8.00521L15.5506 1.71606C15.9391 1.32346 15.9392 0.686954 15.5507 0.294394C15.1623 -0.0981661 14.5325 -0.0981264 14.144 0.294484L7.92106 6.58363L1.69806 0.294442Z"
                    fill="#848C93"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div class="tw-w-full tw-h-full">
            <div class="tw-min-h-[300px] tw-flex tw-gap-[7px] tw-flex-wrap">
              <div class="tw-relative tw-bg-white tw-rounded-md tw-flex-1 tw-overflow-auto tw-p-[24px]">
                <div
                  v-if="Array.isArray(list) && list.length"
                  class="tw-flex tw-flex-col tw-gap-[20px] tw-max-h-[500px] tw-overflow-auto"
                >
                  <div
                    v-for="(stock, sIndex) in list"
                    :key="sIndex"
                    class="tw-text-main-md"
                  >
                    <div class="tw-text-h6">
                      {{ stock.title || `~` }}
                    </div>
                    <div
                      v-if="stock.description"
                      class="tw-mt-[4px]"
                      v-html="$Global.nl2br(stock.description)"
                    />
                    <div class="tw-text-an-flat-gray-4 tw-mt-[8px]">
                      <template v-if="stock.dateTo">
                        Действует до: {{ $Global.DateFormat.DateShort(stock.dateTo) }}
                      </template>
                    </div>
                  </div>
                </div>
                <div
                  v-else
                  class="tw-flex tw-justify-center tw-items-center tw-p-10 tw-text-an-flat-disabled-text"
                >
                  Нет данных по акциям
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else
          class="tw-flex tw-justify-center tw-items-center tw-p-10 tw-text-an-flat-disabled-text"
        >
          Ошибка получения
        </div>
      </div>
    </template>
  </TwDialog>
</template>

<script>
import { defineComponent } from 'vue'
import { API } from '@/Api/ObjectStorage'
import TwDialog from '@/components/tw-ui/modal/TwDialog.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'

export default defineComponent({
  name: 'NbSelectionApartmentStock',
  components: { TwCircleLoader, TwDialog },
  props: {
    id: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      list: [],
      loading: false,
      showDialog: false
    }
  },
  methods: {
    onShow () {
      this.showDialog = true
      this.fetch()
    },
    fetch () {
      this.loading = true
      API.NbSelection.showObjectStock(this.id)
        .then(({ data: result }) => {
          console.warn(this.$options.name, result)
          this.list = result.data || []
        })
        .catch((error) => {
          console.error(this.$options.name, error)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
})
</script>

<style scoped>

</style>
