import PermissionDenied from '@/views/PermissionDenied'

export default {
  props: {
    id: {
      type: [Number, String],
      default: 0
    }
  },
  components: { PermissionDenied },
  data () {
    return {
      API: null,
      error: false,
      errorData: null,
      loading: false,
      data: null,
      dictionary: null, // Словари
      permissions: null, // Разрешения
      ui: {}, // инструкции для интерфейсов
      additionalData: {}
    }
  },
  methods: {
    fetch (id = this.id || this.data?.id) {
      this.error = false
      this.errorData = null
      this.loading = true

      return this.API.show(id)
        .then((result) => {
          // console.error(this.$options.name, 'Default Fetch:', this.$options.name, result)
          const { data, dictionary, permissions, ui, ...others } = result.data
          this.data = data
          this.dictionary = dictionary
          this.permissions = permissions
          this.ui = ui || {}
          if (others.additionalData && Object.keys(others.additionalData).length) {
            for (const key in others.additionalData) {
              this.$set(this.additionalData, [key], others.additionalData[key])
            }
          }
          if (this.postFetch) {
            this.postFetch(data)
          }
        })
        .catch((error) => {
          // console.error(error)
          // обработка ошибки запроса
          if (error?.response?.data) {
            this.errorData = error?.response?.data || null
          }
          if (error?.response?.status) {
            this.error = error.response.status
          } else {
            this.error = true
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    refresh (event) {
      console.warn('refresh', this.$options.name, event)
      if (this.isNew) {
        console.log('NEW', this.$options.name)
      } else {
        this.fetch()
      }
    }
  },
  computed: {
    isNew () {
      // ID из параметров или при создании нового объекта ID из полученных данных
      return !(this.id) && !(this.data?.id)
    },
    isContentVisible () {
      // принимаю решение - рисовать контент карточки или нет и будут рисоваться прелодер\ошибка
      return !this.error && (!this.loading || !!this.data)
    },
    actions () {
      const { entity } = this.permissions || {}
      return {
        read: entity?.read || null,
        create: entity?.create || null,
        update: entity?.update || null,
        delete: entity?.delete || null
      }
    }
  },
  mounted () {
    // проверка на наличии API
    if (!this.API) {
      alert(`API Not Found: ${this.$options.name}`)
    } else {
      this.refresh()
    }
  }
}
