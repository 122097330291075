// import Vue from 'vue'
import { Module } from 'vuex'
import store from '@/store/index'
import router from '@/router/index'
import { RootState } from '@/store/types'
import { MainMenuState } from './types'

const namespaced = true

export const mainMenu: Module<MainMenuState, RootState> = {
  namespaced,
  state: {
    isManagementMenu: false,
    fullMenuTree: []
  },
  actions: {
    buildFullMenu: function ({ commit }) {
      function recursiveFullMenuGenerator (routes: any, parent?: any) {
        const items: any = []
        for (const route of routes) {
          const { name: routeName, children } = route
          const { name = routeName, icon = null, plannedKey = null, ruleNamespace = null, isAdmin = false, hideInMenu = false, hideIsNotChildren = false } = route?.meta || {}

          const newItem = {
            name,
            routeName,
            ruleNamespace,
            isAdmin,
            hideInMenu,
            hideIsNotChildren,
            path: parent?.path ? `${parent.path}/${route.path}` : route.path,
            plannedKey: plannedKey || null, // ключ значения показа кол-ва запланированных действий
            icon
          }
          items.push({
            ...newItem,
            ...(Array.isArray(children) && children.length) && {
              children: recursiveFullMenuGenerator(children, newItem)
            }
          })
        }
        return items
      }
      const menuItems: any[] = recursiveFullMenuGenerator(router.options.routes)
      commit('SET_FULL_MENU', menuItems)
    }
  },
  mutations: {
    SET_FULL_MENU (state, payload: any[]) {
      state.fullMenuTree = payload
    },
    SET_MANAGEMENT_MENU_STATUS (state, visibleStatus: boolean) {
      state.isManagementMenu = visibleStatus
    }
  },
  getters: {
    getManagementMenuStatus (state) {
      return state.isManagementMenu
    },
    menu: (state) => {
      const isAdmin = store.getters['profile/isAdmin']
      const isManagement = store.getters['profile/isManagement']
      const allowNamespacesDict = store.getters['profile/allowNamespacesDict']
      function recursiveMenuGenerator (routes: any[]): any {
        const items: any = []

        for (const route of routes) {
          // Исключаю скрытые маршруты
          if (route.hideInMenu) continue
          // Исключаю админские маршруты если не админ
          if (route.isAdmin && !isAdmin) continue
          // В роутинге указаны правила, и пользователь не админ. - Админу доступно всё
          if (route.ruleNamespace && !isAdmin) {
            // Сверяю права в роутинге с правами пользователя
            if (allowNamespacesDict && Array.isArray(route.ruleNamespace)) {
              if (!route.ruleNamespace.some((routeRule) => !!allowNamespacesDict[routeRule])) {
                // исключаю пункты на которые нет прав
                continue
              }
            } else if (allowNamespacesDict && !allowNamespacesDict[route.ruleNamespace]) {
              // исключаю пункты на которые нет прав
              continue
            }
          }

          // исключаю двойные слеши
          route.path = route.path.replaceAll('//', '/')

          // Исключаю маршруты управления
          if (route.routeName === 'management' && !isManagement) continue

          items.push({
            ...route,
            ...(Array.isArray(route?.children) && route.children.length) && {
              children: recursiveMenuGenerator(route.children)
            }
          })
        }
        return items
      }
      // БАЗОВЫЕ ЭЛЕМЕНТЫ МЕНЮ
      const itemsBase = recursiveMenuGenerator(state.fullMenuTree)
      // УПРАВЛЕНЧЕСКИЕ ЭЛЕМЕНТЫ МЕНЮ
      const itemsManagement = recursiveMenuGenerator(state.fullMenuTree.find(i => i.routeName === 'management')?.children || [])
      // очищаю список меню от пустых элементов групп меню только если явно указан hideIsNotChildren
      for (let i = 0; i < itemsBase.length; i++) {
        if (Array.isArray(itemsBase[i].children) && !itemsBase[i].children.length && itemsBase[i].hideIsNotChildren) {
          console.error('remove', itemsBase[i])
          itemsBase.splice(i, 1)
        }
      }
      for (let i = 0; i < itemsManagement.length; i++) {
        if (Array.isArray(itemsManagement[i].children) && !itemsManagement[i].children.length && itemsManagement[i].hideIsNotChildren) {
          console.error('remove', itemsManagement[i])
          itemsManagement.splice(i, 1)
        }
      }
      return {
        base: itemsBase,
        management: itemsManagement
      }
    }
  }
}
