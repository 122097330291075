import { SERVICES } from '@/Api/Services'

import Chat from './Chat'
import PlatformAccount from './PlatformAccount'
import PlatformSystem from './PlatformSystem'

export const AuthAxiosDefaultConfig: any = {
  serviceKey: SERVICES.MESSENGER.key
}

export default {
  Chat,
  PlatformSystem,
  PlatformAccount
}
