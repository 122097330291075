<template>
  <div class="tw-font-manrope tw-text-main-sm">
    <TwCircleLoader v-if="loading" />
    <div
      v-else-if="data"
      class="tw-flex tw-flex-wrap"
    >
      <div class="tw-flex-1">
        <div class="tw-flex tw-gap-[4px] tw-items-center tw-mb-[9px]">
          <div class="tw-block tw-leading-[120%]">
            Застройщик:
          </div>
          <div class="tw-font-semibold tw-text-an-flat-dark-blue-link tw-cursor-pointer hover:tw-underline">
            <ViewPanelLink
              v-if="companyId"
              :id="companyId"
              label="Застройщик"
              :no-link-style="false"
              component="company-show"
              :callback="fetch"
            >
              <div v-if="$store.getters['tmpCache/getCompanyById'](companyId)">
                {{ $store.getters['tmpCache/getCompanyById'](companyId).name }}
              </div>
            </ViewPanelLink>
          </div>
        </div>

        <div class="tw-flex tw-gap-[4px] tw-items-center tw-mb-[9px]">
          <div class="tw-block tw-leading-[120%]">
            Жилой комплекс:
          </div>
          <ViewPanelLink
            v-if="data.complexId"
            :id="data.complexId"
            label="ЖК"
            class="tw-font-semibold"
            component="realty-complex-show-page"
          >
            <RealtyComplexName :id="data.complexId" />
          </ViewPanelLink>
        </div>

        <div class="tw-flex tw-gap-[4px] tw-items-center tw-mb-[9px]">
          <div class="tw-block tw-leading-[120%]">
            Комнат:
          </div>
          <div class="tw-font-semibold">
            <span v-if="data.plan">{{ data.plan.studio ? 'Студия':data.plan.roomCount || '~' }}</span><span v-else>~</span>
          </div>
        </div>

        <div class="tw-flex tw-gap-[4px] tw-items-center tw-mb-[9px]">
          <div class="tw-block tw-leading-[120%]">
            Стоимость:
          </div>
          <div class="tw-font-semibold">
            {{ ((data.deal && data.deal.price) || 0).toLocaleString('ru') }}  ₽
          </div>
        </div>
      </div>
      <div class="tw-flex-1">
        <div class="tw-flex tw-gap-[4px] tw-items-center tw-mb-[9px]">
          <div class="tw-block tw-leading-[120%]">
            Номер дома:
          </div>
          <div class="tw-font-semibold">
            {{ data.buildingNum || `~` }}
          </div>
        </div>

        <div class="tw-flex tw-gap-[4px] tw-items-center tw-mb-[9px]">
          <div class="tw-block tw-leading-[120%]">
            Номер квартиры:
          </div>
          <div
            class="tw-font-semibold tw-text-an-flat-dark-blue-link tw-cursor-pointer"
            @click.prevent="openPanel"
          >
            {{ data.apNum || `~` }}
          </div>
        </div>

        <div class="tw-flex tw-gap-[4px] tw-items-center tw-mb-[9px]">
          <div class="tw-block tw-leading-[120%]">
            Площадь,м²:
          </div>
          <div class="tw-font-semibold">
            {{ (data.plan && data.plan.totalArea) || `~` }}
          </div>
        </div>

        <div class="tw-flex tw-gap-[4px] tw-items-center tw-mb-[9px]">
          <div class="tw-block tw-leading-[120%]">
            Этаж:
          </div>
          <div class="tw-font-semibold">
            {{ data.floorNum || `~` }}{{ data.floorCount ? `/${data.floorCount}` : '' }}
          </div>
        </div>

        <div class="tw-flex tw-gap-[4px] tw-items-center tw-mb-[9px]">
          <div class="tw-block tw-leading-[120%]">
            Срок сдачи:
          </div>
          <div class="tw-font-semibold">
            {{ data.completed ? 'Сдан' : data.completeDate || `~` }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="tw-text-center tw-p-[30px]"
    >
      Нет данных
    </div>
  </div>
</template>

<script>
import { API } from '@/Api/ObjectStorage'
import CardMixin from '@/mixins/CardMixin'
import { getFileResizeByKey } from '@/plugins/helpers/fileHelpers'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import RealtyComplexName from '@/components/other/complex/RealtyComplexName.vue'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink.vue'
import { mapActions } from 'vuex'

export default {
  name: 'InformationCommitsReservation',
  components: { ViewPanelLink, RealtyComplexName, TwCircleLoader },
  mixins: [CardMixin],
  props: {
    id: {
      type: String,
      default: null
    },
    companyId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      API: API.NbSelection,
      showMethodKey: 'showObject'
    }
  },
  methods: {
    ...mapActions({
      showPanel: 'viewPanels/showPanel'
    }),
    getFileResizeByKey,
    postFetch (objInfo) {
      this.$emit('changeReservationPrice', objInfo.price || 0)
    },
    openPanel () {
      this.showPanel({
        label: 'Квартира',
        isCloseConfirm: false,
        viewType: 'modal',
        component: 'nb-selection-apartment-modal-show',
        props: {
          id: this.id
        },
        callback: () => {
          console.warn(this.$options.name, '- showPanel')
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
