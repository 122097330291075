export const routeKey = 'review'

export default {
  path: `${routeKey}`,
  name: routeKey,
  redirect: { name: `${routeKey}-list` },
  meta: { name: 'Отзывы', icon: 'reviews-review' },
  component: () => import('@/views/Site/Admin/Company/Review/Index.vue'),
  children: [
    {
      path: 'list',
      name: `${routeKey}-list`,
      meta: { name: 'Список отзывов', icon: 'reviews-review', hideInMenu: true },
      component: () => import('@/views/Site/Admin/Company/Review/List.vue')
    },
    {
      path: ':id/edit',
      name: `${routeKey}-edit`,
      meta: { name: 'Редактирование отзыва', icon: 'reviews-review', hideInMenu: true },
      component: () => import('@/views/Site/Admin/Company/Review/Edit.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    },
    {
      path: 'create',
      name: `${routeKey}-create`,
      meta: { name: 'Добавление отзыва', icon: 'reviews-review', hideInMenu: true },
      component: () => import('@/views/Site/Admin/Company/Review/Edit.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    },
    {
      path: ':id',
      name: `${routeKey}-show`,
      meta: { name: 'Просмотр отзыва', icon: 'reviews-review', hideInMenu: true },
      component: () => import('@/views/Site/Admin/Company/Review/Show.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    }
  ]
}
