<template>
  <div v-resize="onResize">
    <TwPanelContentBlock
      class="tw-mb-[14px] tw-border tw-border-white tw-rounded-[20px]"
      :content-classes="''"
    >
      <template #title>
        Вы можете выбрать необходимый дом
      </template>
      <template #default>
        <div class="tw-p-[6px]">
          <div class="tw-gap-[10px] tw-flex tw-overflow-auto tw-pb-[6px]">
            <button
              v-for="(building) in buildingsList"
              :key="building.id"
              class="tw-max-w-[200px] tw-rounded-sm tw-text-white tw-p-2"
              :class="targetBuildingId === building.id ? 'tw-bg-an-flat-dark-blue-link-active' : 'tw-bg-an-flat-grey-3'"
              @click="onSelectBuildingId(building.id)"
            >
              {{ building.addressNumber ? 'Дом: ' + building.addressNumber : 'Дом' }}
            </button>
          </div>
        </div>
      </template>
    </TwPanelContentBlock>
    <div
      ref="editorWrapper"
      class="tw-relative tw-left-0 tw-top-0 tw-w-fit tw-mb-[40px] tw-rounded-[20px] tw-overflow-hidden tw-w-full"
    >
      <img
        ref="imageRef"
        class="tw-absolute tw-left-0 tw-top-0 tw-z-0"
        :src="image.url"
        alt="Картинка ЖК"
        @load="imageLoaded"
      >
      <canvas
        ref="canvasRef"
        class="tw-bg-an-flat-blue-block-polygon tw-relative tw-z-10 tw-left-0 tw-top-0 tw-w-full tw-h-full tw-max-w-fit tw-max-h-fit"
        tabindex="0"
        style="outline: 0;"
        @keydown.esc="onEnd"
        @pointerdown="onDown"
        @pointermove="onMove"
      />
    </div>
    <TwPanelContentBlock
      v-if="editing"
      class="tw-mb-[14px] tw-border tw-border-white tw-rounded-[20px]"
      :content-classes="''"
    >
      <template #title>
        Вы можете выбрать необходимый дом и на нем создать схему из полигонов
      </template>
      <template #default>
        <div
          v-if="editing"
          class="tw-flex tw-p-[6px] tw-gap-[10px] tw-items-end tw-justify-between"
        >
          <div class="tw-flex">
            <div class="tw-flex tw-flex-col tw-gap-[8px]">
              <button
                v-if="activeHouse && activeHouse.coordinates && activeHouse.coordinates.length"
                :disabled="!targetBuildingId"
                class="tw-bg-an-flat-hover-gray-btn tw-rounded-sm tw-text-white tw-p-2 tw-m-1 hover:tw-opacity-70 active:tw-opacity-90 disabled:tw-bg-an-flat-grey-3 disabled:tw-opacity-60"
                @click="changeHousePolygon('reset')"
              >
                {{ 'Сбросить полигон' }}
              </button>
              <button
                v-else
                :disabled="!targetBuildingId"
                class="tw-bg-an-flat-dark-blue-link-active tw-rounded-sm tw-text-white tw-p-2 tw-m-1 hover:tw-opacity-70 active:tw-opacity-90 disabled:tw-bg-an-flat-grey-3 disabled:tw-opacity-60"
                @click="changeHousePolygon('create')"
              >
                {{ 'Создать полигон' }}
              </button>
            </div>
          </div>
          <div class="tw-flex tw-gap-[13px]">
            <button
              v-if="activeHouse && activeHouse.arrayLastPolygonChanges && Array.isArray(activeHouse.arrayLastPolygonChanges) && activeHouse.arrayLastPolygonChanges.length && activeHouse.coordinates.length"
              class="tw-fit tw-transition-all tw-px-[17px] tw-py-[8px] tw-border-4 tw-border-indigo-500/100 tw-rounded-[6px] tw-text-[#4B5763] tw-uppercase hover:tw-bg-[#FCEAE7] hover:tw-text-an-flat-red-main active:tw-text-white active:tw-bg-an-flat-red-main"
              @click="deleteElementStack"
            >
              Удалить последнее действие
            </button>
            <button
              class="tw-fit tw-transition-all tw-px-[17px] tw-py-[8px] tw-bg-an-flat-blue-btn tw-rounded-[6px] tw-text-white hover:tw-bg-an-flat-blue-btn-hover hover:tw-shadow-[0_4px_20px_0px_rgba(45,130,208,0.52)] active:tw-text-white active:tw-bg-an-flat-blue-btn-active "
              @click="submitCoordsHouses"
            >
              Сохранить
            </button>
          </div>
        </div>
      </template>
    </TwPanelContentBlock>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'

export default defineComponent({
  name: 'ImageHighlightEditor',
  components: { TwPanelContentBlock },
  props: {
    image: {
      type: Object,
      required: true
    },
    buildingsList: {
      type: Array,
      default: () => []
    },
    complexId: {
      type: String,
      default: null
    },
    houses: {
      type: Array,
      default: null
    },
    editing: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      ctx: null,
      selectedPointIndex: null,
      nodeRadius: 10,
      doubleCoordinatePolygon: null,
      clickDoublePointer: null,
      duplicatePointCheck: false,
      arrayHousesHighlight: [],
      activeHouse: null,
      targetBuildingId: null
    }
  },
  computed: {
    housesCoords () {
      const result = []
      for (const house of this.arrayHousesHighlight) {
        if (house?.coordinates?.length) {
          result.push({ realtyObjectId: house?.realtyObjectId, coordinates: house?.coordinates })
        }
      }
      return result
    }
  },
  watch: {
    image: function () {
      this.onResize()
    }
  },
  mounted () {
    if (Array.isArray(this.houses) && this.houses.length && Array.isArray(this.buildingsList) && this.buildingsList.length) {
      this.buildingsList.forEach(building => {
        let houseCoordinates = null
        for (const house of this.houses) {
          if (house?.realtyObjectId === building?.id && house?.coordinates?.length) {
            houseCoordinates = house.coordinates
            break
          }
        }
        if (houseCoordinates && houseCoordinates?.length) {
          this.createItemArrayHouses(building?.id, building?.addressNumber, houseCoordinates)
        } else {
          this.createItemArrayHouses(building?.id, building?.addressNumber, [])
        }
      })
    } else if (Array.isArray(this.buildingsList) && this.buildingsList.length) {
      this.buildingsList.forEach(building => {
        this.createItemArrayHouses(building?.id, building?.addressNumber, [])
      })
    }
    // Отрисовка координат с сервера или создание новых
    this.onResize()
  },
  methods: {
    submitCoordsHouses () {
      console.warn('submitCoordsHouses', this.housesCoords)
      this.$emit('submitCoordsHouses', this.housesCoords)
    },
    onSelectBuildingId (buildingId) {
      for (const house of this.arrayHousesHighlight) {
        if (house?.realtyObjectId === buildingId) {
          this.activeHouse = house
        }
      }
      console.warn('activeHouse: ', this.activeHouse)
      this.targetBuildingId = buildingId
      this.onResize()
    },
    xPointAdapter (event, canvas) {
      return (event.offsetX * canvas.width / canvas.clientWidth | 0)
    },
    yPointAdapter (event, canvas) {
      return (event.offsetY * canvas.height / canvas.clientHeight | 0)
    },
    onDown (event) {
      for (const index in this.activeHouse.coordinates) {
        const x = this.activeHouse.coordinates[index][0]
        const y = this.activeHouse.coordinates[index][1]
        const dx = Math.abs(this.xPointAdapter(event, this.$refs.canvasRef) - x)
        const dy = Math.abs(this.yPointAdapter(event, this.$refs.canvasRef) - y)
        const d = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2))

        const xDouble = this.doubleCoordinatePolygon[index][0]
        const yDouble = this.doubleCoordinatePolygon[index][1]
        const dxDouble = Math.abs(this.xPointAdapter(event, this.$refs.canvasRef) - xDouble)
        const dyDouble = Math.abs(this.yPointAdapter(event, this.$refs.canvasRef) - yDouble)
        const dDouble = Math.sqrt(Math.pow(dxDouble, 2) + Math.pow(dyDouble, 2))

        if (d < this.nodeRadius) {
          if (this.duplicatePointCheck) {
            this.selectedPointIndex = index
            this.duplicatePointCheck = false
          }
          if (this.selectedPointIndex === index) {
            this.onEnd() // Обнулить выбранную точку при повторном клике
            // Парсим в JSON, потому что при методе slice() всё равно происходит копирование на память массива
            const duplicatePolygonsBuildingsArray = JSON.parse(JSON.stringify(this.activeHouse.coordinates))
            this.activeHouse.arrayLastPolygonChanges.push(duplicatePolygonsBuildingsArray)
          } else {
            this.selectedPointIndex = index // Выбрать точку для работы
          }
          break
        } else if (dDouble < this.nodeRadius) {
          if (this.selectedPointIndex === index) {
            this.clickDoublePointer = null
            this.onEnd() // Обнулить выбранную точку при повторном клике
          } else {
            this.clickDoublePointer = this.doubleCoordinatePolygon[index]
            this.selectedPointIndex = Number(index) // Выбрать точку для работы
            this.duplicatePointCheck = true
          }
          break
        }
      }
    },
    onMove (event) {
      if (this.selectedPointIndex !== null && !this.clickDoublePointer && this.editing) {
        this.ctx.clearRect(0, 0, this.$refs.canvasRef.width, this.$refs.canvasRef.height)
        this.activeHouse.coordinates[this.selectedPointIndex][0] = this.xPointAdapter(event, this.$refs.canvasRef) // event.clientX - this.$refs.canvasRef?.getBoundingClientRect().left
        this.activeHouse.coordinates[this.selectedPointIndex][1] = this.yPointAdapter(event, this.$refs.canvasRef) // event.clientY - this.$refs.canvasRef?.getBoundingClientRect().top
        this.drawPolygon()
      } else if (this.selectedPointIndex !== null && this.clickDoublePointer) {
        this.selectedPointIndex++
        this.activeHouse.coordinates.splice(this.selectedPointIndex, 0, this.clickDoublePointer)
        this.clickDoublePointer = null
        this.onResize()
      }
    },

    drawPolygon () {
      this.doubleCoordinatePolygon = []
      this.ctx = this.$refs.canvasRef?.getContext('2d')
      // Берём все полигоны
      for (const house of this.arrayHousesHighlight) {
        const lines = house.coordinates
        this.ctx.strokeStyle = '#ffffff'
        this.ctx.fillStyle = '#B0D9FF'
        this.ctx.lineWidth = 2

        this.ctx.beginPath()
        for (const [x, y] of lines) {
          this.ctx.lineTo(x, y)
        }
        this.ctx.closePath()
        this.ctx.fillStyle = 'rgba(255,255,255,0.24)'
        this.ctx.fill()
        this.ctx.stroke()
        if (house?.realtyObjectId === this.targetBuildingId) {
          for (const index in lines) {
            this.ctx.beginPath()
            // Создаём основные метки по нашим координатам
            const doubleCordsPoints = []
            if (lines[index] && this.editing) {
              if (Number(index) === lines.length - 1) {
                doubleCordsPoints.push((lines[index][0] + lines[0][0]) / 2)
                doubleCordsPoints.push((lines[index][1] + lines[0][1]) / 2)
              } else {
                doubleCordsPoints.push((lines[index][0] + lines[Number(index) + 1][0]) / 2)
                doubleCordsPoints.push((lines[index][1] + lines[Number(index) + 1][1]) / 2)
              }
            }
            this.doubleCoordinatePolygon.push(doubleCordsPoints)
            this.ctx.arc(lines[index][0], lines[index][1], this.nodeRadius, 0, Math.PI * 2, 1)
            this.ctx.arc(this.doubleCoordinatePolygon[index][0], this.doubleCoordinatePolygon[index][1], this.nodeRadius, 0, Math.PI * 2, 1)
            if (Number(index) === this.selectedPointIndex) {
              this.ctx.fillStyle = '#2ED47A'
            } else {
              this.ctx.fillStyle = '#B0D9FF'
            }
            this.ctx.fill()
          }
        }
      }
    },

    onEnd () {
      console.log('onEnd', this.activeHouse)
      this.selectedPointIndex = null
      this.drawPolygon()
    },

    imageLoaded () {
      console.log('imageLoaded')
      this.$nextTick(() => {
        this.onResize()
      })
    },

    onResize () {
      this.$refs.canvasRef.width = this.$refs.imageRef.naturalWidth
      this.$refs.canvasRef.height = this.$refs.imageRef.naturalHeight
      this.drawPolygon()
    },

    changeHousePolygon (change) {
      const defaultPolygon = [[100, 50], [180, 80]]
      let changePolygon = null
      if (change === 'create') {
        changePolygon = defaultPolygon
      } else if (change === 'reset') {
        changePolygon = []
      }
      if (change === 'create' || change === 'reset') {
        if (this.activeHouse && this.activeHouse.realtyObjectId && this.targetBuildingId === this.activeHouse.realtyObjectId) {
          this.activeHouse.coordinates = changePolygon
          for (const house of this.arrayHousesHighlight) {
            if (house.realtyObjectId === this.targetBuildingId) {
              house.coordinates = this.activeHouse.coordinates
              break
            }
          }
          this.onResize()
        }
      } else {
        console.warn('В changeHousePolygon пришёл некоректный change', change)
      }
    },

    /* TODO Функция удаления последних изменений */
    deleteElementStack () {
      if (this.activeHouse.arrayLastPolygonChanges && this.activeHouse.arrayLastPolygonChanges?.length) {
        // Удаляем верхний элемент из стека
        this.activeHouse.arrayLastPolygonChanges = this.activeHouse.arrayLastPolygonChanges.slice(0, -1)
        if (this.activeHouse.arrayLastPolygonChanges.length) {
          // Записываем в полигон дома последний элемент стека
          const elementsNumberArray = this.activeHouse.arrayLastPolygonChanges.length - 1
          this.activeHouse.coordinates = this.activeHouse.arrayLastPolygonChanges[elementsNumberArray].slice()
        } else {
          // Если стэк пуст, то полигон будет дефолтным значением
          this.activeHouse.coordinates = [[100, 50], [180, 80]]
        }
        this.onResize()
      } else {
        console.error('ERROR this.activeHouse.arrayLastPolygonChanges = 0: Стэк пуст!')
      }
    },
    createItemArrayHouses (objectId, houseName, coordinates = []) {
      this.arrayHousesHighlight.push({
        realtyObjectId: objectId || null,
        homeName: houseName || '',
        coordinates: coordinates,
        arrayLastPolygonChanges: []
      })
    }
  }
})
</script>
