<template>
  <div class="tw-my-[16px] tw-px-[16px] tw-py-[20px] tw-bg-white tw-rounded-md">
    <div>
      <VariantFilterMatrix
        :init-data="regroupDealData.variantFilters"
        :deal-id="regroupDealData.mainDeal.id"
        elevation="0"
        @change="changeVariantFilters"
      />
    </div>
    <hr>
    <SubsidizedProgramsSelectorMatrix
      ref="programSelector"
      :filters="variantFilters"
      :disabled="disabled"
      elevation="0"
      :show-select="true"
      :deal-id="regroupDealData.mainDeal.id"
      :lead-id="regroupDealData.mainDeal.lead.id"
      :is-update="true"
    />
  </div>
</template>

<script>
import VariantFilterMatrix from '@/views/Mortgage/Variant/Matrix/VariantFilterMatrix.vue'
import SubsidizedProgramsSelectorMatrix from '@/views/Mortgage/Variant/Matrix/SubsidizedProgramsSelectorMatrix.vue'

export default {
  name: 'DealMortgageProgramsSelectorTab',
  components: { SubsidizedProgramsSelectorMatrix, VariantFilterMatrix },
  props: {
    regroupDealData: {
      required: true
    },
    dictionary: {
      type: Object,
      default: () => {
        return {}
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    variantFilters () {
      return this.regroupDealData?.variantFilters || {}
    }
  },
  methods: {
    changeVariantFilters (newVariantFilters) {
      // обновим программы под по фильтрам
      this.$nextTick(() => {
        this.$refs.programSelector.refresh(newVariantFilters)
      })
    }
  }
}
</script>

<style scoped>

</style>
