<template>
  <div style="display: flex; place-items: center">
    <div class="a-filter-input-field-block">
      <input
        style="width: 50px; text-align: center"
        :value="value"
        placeholder=""
        autocomplete="off"
        type="number"
        @input="onInput"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'ADateDaysSelect',
  props: {
    defaultCurrent: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Number, null],
      default: 7
    }
  },
  methods: {
    onInput (event) {
      console.log('onInput ', event.target.value)
      this.$emit('input', event.target.value)
    }
  }
}
</script>

<style scoped lang="less">
@import "../a-select";
</style>
