<script>
import { Line, mixins } from 'vue-chartjs'

export default {
  name: 'LinearChart',
  extends: Line,
  mixins: [mixins.reactiveProp],
  props: ['chartData', 'options', 'loading'],
  watch: {
    chartData: {
      handler () {
      // console.log('CHANGE chartData', this.chartData)
        this.drawChart()
      }
    // deep: true
    },
    loading: {
      handler () {
      // console.log('CHANGE loading', this.loading)
        this.drawChart()
      }
    }
  },
  mounted () {
    this.drawChart()
    this.addPlugin({
      id: 'no-data-view',
      afterDraw: (chart) => {
        // console.warn('afterDraw', this.loading, chart.data.datasets.length)
        if (chart.data.datasets.length === 0 || this.loading) {
          chart.data.datasets = []
          // No data is present
          const ctx = chart.chart.ctx
          const width = chart.chart.width
          const height = chart.chart.height
          chart.clear()

          ctx.save()
          ctx.textAlign = 'center'
          ctx.textBaseline = 'middle'
          ctx.font = "18px normal 'Helvetica Nueue'"
          ctx.fillText((this.loading ? 'Загрузка...' : 'Нет данных'), width / 2, height / 2)
          ctx.restore()
        }
      }
    })
  },
  methods: {
    drawChart () {
      this.renderChart(this.chartData, this.options)
    }
  }
}
</script>
