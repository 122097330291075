<template>
  <svg
    v-if="type === TypesRealtyObjects.building.type"
    :data-icon-type="type"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.39078 16.9693H5.85437V19.0649H4.39078V16.9693Z"
      fill="#343942"
    />
    <path
      d="M7.31793 16.9693H8.78153V19.0649H7.31793V16.9693Z"
      fill="#343942"
    />
    <path
      d="M20.4903 3L10.2451 7.88973V10.8721L1.46359 15.0633V20.462H0V21.859H21.9539V20.462H20.4903V3ZM19.0267 5.26068V20.462H11.7087V8.75335L19.0267 5.26068ZM2.92719 15.927L10.2451 12.4343V20.462H2.92719V15.927Z"
      fill="#343942"
    />
    <path
      d="M13.1724 16.9693H14.636V19.0649H13.1724V16.9693Z"
      fill="#343942"
    />
    <path
      d="M13.1724 13.4767H14.636V15.5723H13.1724V13.4767Z"
      fill="#343942"
    />
    <path
      d="M13.1724 9.98389H14.636V12.0795H13.1724V9.98389Z"
      fill="#343942"
    />
    <path
      d="M16.0995 16.9693H17.5631V19.0649H16.0995V16.9693Z"
      fill="#343942"
    />
    <path
      d="M16.0995 13.4767H17.5631V15.5723H16.0995V13.4767Z"
      fill="#343942"
    />
    <path
      d="M16.0995 9.98389H17.5631V12.0795H16.0995V9.98389Z"
      fill="#343942"
    />
  </svg>
  <svg
    v-else-if="type === TypesRealtyObjects.parking.type"
    :data-icon-type="type"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.42374 18.4748H15.5763V16.9493C15.5763 16.1069 14.8933 15.4239 14.0509 15.4239H7.94916C7.10671 15.4239 6.42374 16.1069 6.42374 16.9493V18.4748Z"
      stroke="#343942"
      stroke-width="1.4"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.94916 18.4745V20"
      stroke="#343942"
      stroke-width="1.4"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.0508 20V18.4745"
      stroke="#343942"
      stroke-width="1.4"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.0509 15.4236L13.577 13.5282C13.4072 12.8491 12.7971 12.3727 12.0971 12.3727H9.90288C9.2029 12.3727 8.59277 12.8491 8.42299 13.5282L7.94916 15.4236"
      stroke="#343942"
      stroke-width="1.4"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.5254 6.27121H9.47458"
      stroke="#343942"
      stroke-width="1.4"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2 20.0001V9.32211"
      stroke="#343942"
      stroke-width="1.4"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20 20V5.05085L11 2L2 5.05085V9.32203H20"
      stroke="#343942"
      stroke-width="1.4"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
  <svg
    v-else-if="type === TypesRealtyObjects.house.type"
    :data-icon-type="type"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.8624 8.15394L18.3721 7.16041C18.2893 7.07762 18.2893 7.07762 18.2065 6.99482L16.9646 6.16688V3.26908H15.3087V5.17335L12.6593 3.43467C11.7486 2.85511 10.6722 2.85511 9.7615 3.43467L4.04869 7.07762C3.9659 7.07762 3.9659 7.16041 3.8831 7.24321L2.31001 8.15394C1.97883 8.48512 1.89604 8.98189 2.14442 9.31307C2.31001 9.56145 2.55839 9.64424 2.80678 9.64424C2.97236 9.64424 3.13795 9.56145 3.30354 9.47865L3.63472 9.31307V16.3506C3.63472 17.8409 4.79384 19 6.28414 19H8.60238H13.57H15.8883C17.3786 19 18.5377 17.8409 18.5377 16.3506V9.31307L18.8689 9.56145C19.0345 9.64424 19.2 9.72704 19.3656 9.72704C19.614 9.72704 19.8624 9.56145 20.028 9.39586C20.2764 8.98189 20.1936 8.48512 19.8624 8.15394ZM9.43032 17.3441V12.3765C9.43032 11.8797 9.7615 11.5485 10.2583 11.5485H11.9141C12.4109 11.5485 12.7421 11.8797 12.7421 12.3765V17.3441H9.43032ZM16.8818 16.3506C16.8818 16.9301 16.4678 17.3441 15.8883 17.3441H14.398V12.3765C14.398 10.969 13.3217 9.89263 11.9141 9.89263H10.2583C8.85076 9.89263 7.77443 10.969 7.77443 12.3765V17.3441H6.28414C5.70458 17.3441 5.2906 16.9301 5.2906 16.3506V8.23674L10.5066 4.84217C10.8378 4.59379 11.2518 4.59379 11.583 4.84217L16.8818 8.23674V16.3506Z"
      fill="#343942"
    />
  </svg>
  <svg
    v-else-if="type === TypesRealtyObjects.land.type"
    :data-icon-type="type"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_547_34)">
      <path
        d="M22 10.0731L18.7086 8.34516L21.9962 6.21245L11 0.389252L0.00378124 6.21245L3.29136 8.34516L0 10.0731L3.29136 12.2083L0 13.9363L11 21.0722L22 13.9363L18.7086 12.2083L22 10.0731ZM19.4568 6.32481L17.275 7.74029L15.3661 6.55861L17.4058 5.23874L19.4568 6.32481ZM9.81477 8.61685L7.9431 9.82796L5.81143 8.4451L7.73745 7.27275L9.81477 8.61685ZM8.95884 6.52922L10.932 5.32816L12.9585 6.58263L11 7.84995L8.95884 6.52922ZM11 9.38375L12.8732 10.5958L11 11.811L9.1268 10.5958L11 9.38375ZM12.1852 8.61681L14.17 7.33256L16.0804 8.51519L14.0569 9.82788L12.1852 8.61681ZM16.1023 4.5484L14.1546 5.80863L12.1655 4.57728L14.0224 3.44695L16.1023 4.5484ZM11 1.84632L12.6981 2.74561L10.9377 3.81716L9.2506 2.77272L11 1.84632ZM7.93206 3.47109L9.70417 4.56808L7.75838 5.75243L5.89762 4.5484L7.93206 3.47109ZM2.5431 6.32481L4.59404 5.2387L6.53695 6.49583L4.61251 7.66724L2.5431 6.32481ZM2.54688 10.1904L4.53049 9.14902L11 13.3459L17.4695 9.14902L19.4531 10.1904L11 15.6742L2.54688 10.1904ZM19.4531 14.0536L11 19.5373L2.54688 14.0536L4.53049 13.0122L11 17.2091L17.4695 13.0122L19.4531 14.0536Z"
        fill="#343942"
      />
    </g>
    <defs>
      <clipPath id="clip0_547_34">
        <rect
          width="22"
          height="22"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
  <svg
    v-else
    title="Отсутствует иконка типа"
    :data-icon-type="type"
    width="22"
    height="22"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :class="classes"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.5 2C5.35787 2 2 5.35786 2 9.5C2 13.6421 5.35787 17 9.5 17C13.6421 17 17 13.6421 17 9.5C17 5.35786 13.6421 2 9.5 2ZM0 9.5C0 4.25329 4.2533 0 9.5 0C14.7467 0 19 4.25329 19 9.5C19 14.7467 14.7467 19 9.5 19C4.2533 19 0 14.7467 0 9.5Z"
      fill="black"
    />
    <path
      :class="classes"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.78172 6.31251C9.41804 6.25012 9.04401 6.31847 8.72588 6.50543C8.40775 6.6924 8.16606 6.98592 8.04361 7.33401C7.86034 7.855 7.28942 8.12877 6.76844 7.9455C6.24745 7.76223 5.97367 7.19131 6.15694 6.67032C6.43613 5.87668 6.98719 5.20745 7.71251 4.78117C8.43784 4.35489 9.29063 4.19906 10.1198 4.34129C10.949 4.48353 11.7012 4.91464 12.243 5.55826C12.7847 6.20175 13.0812 7.01614 13.0801 7.85726C13.0796 9.24177 12.0544 10.143 11.3535 10.6103C10.9726 10.8642 10.5987 11.0504 10.3241 11.1725C10.1854 11.2342 10.0683 11.281 9.98367 11.3133C9.94125 11.3294 9.90671 11.342 9.88136 11.351L9.85028 11.3619L9.84016 11.3654L9.8365 11.3666L9.83503 11.3671C9.83473 11.3672 9.8338 11.3675 9.51757 10.4188L9.8338 11.3675C9.30985 11.5422 8.74353 11.259 8.56888 10.7351C8.39444 10.2117 8.67674 9.64612 9.1995 9.47076C9.19967 9.47071 9.19934 9.47082 9.1995 9.47076L9.21134 9.46661C9.22351 9.46228 9.24403 9.45484 9.27168 9.44431C9.32715 9.42317 9.41033 9.38997 9.51182 9.34486C9.71764 9.25339 9.98444 9.11928 10.2441 8.94616C10.8243 8.55934 11.0801 8.17964 11.0801 7.85634L11.0801 7.85485C11.0806 7.48585 10.9506 7.12856 10.7129 6.84626C10.4753 6.56397 10.1454 6.37489 9.78172 6.31251ZM8.5859 13.8355C8.5859 13.2832 9.03362 12.8355 9.5859 12.8355H9.59444C10.1467 12.8355 10.5944 13.2832 10.5944 13.8355C10.5944 14.3878 10.1467 14.8355 9.59444 14.8355H9.5859C9.03362 14.8355 8.5859 14.3878 8.5859 13.8355ZM9.1995 9.47076C9.19967 9.47071 9.19934 9.47082 9.1995 9.47076Z"
      fill="black"
    />
  </svg>
</template>

<script>
import { TypesRealtyObjects } from '@/views/Newbuildings/Editor/typesDict'
export default {
  name: 'NbTypeIcon',
  props: {
    type: {
      type: String,
      required: true
    },
    classes: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      TypesRealtyObjects
    }
  }
}
</script>
