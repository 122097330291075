import { RouteKeys } from '@/router/types'

export default {
  path: `/${RouteKeys.link}`,
  name: RouteKeys.link,
  redirect: `${RouteKeys.link}-show`,
  meta: { name: 'Просмотр ссылки', icon: 'lead', hideInMenu: true },
  component: () => import('@/views/Link/Index.vue'),
  children: [
    {
      path: ':type',
      name: `${RouteKeys.link}-show`,
      meta: { name: 'Просмотр ссылки', icon: 'lead', hideInMenu: true },
      component: () => import('@/views/Link/Page.vue'),
      children: [
        {
          path: ':id',
          name: `${RouteKeys.link}-show`,
          meta: { name: 'Просмотр ссылки', icon: 'lead', hideInMenu: true },
          component: () => import('@/views/Link/Page.vue')
        }
      ]
    }
  ]
}
