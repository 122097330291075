<template>
  <div
    v-if="formProcessing"
    class="tw-p-[20px] tw-flex"
  >
    <TwCircleLoader class="tw-m-auto" />
  </div>
  <div
    v-else
    class="tw-font-manrope"
  >
    <div style="display: none">
      {{ isEdited }}
    </div>
    <div>
      <TwCheckBox
        v-model="form.isAddBlackList"
        :disabled="(formType === 'edit' && !isAdmin) || formProcessing"
        :label="form.isAddBlackList ? 'Удалить из черного списка':'Добавить в черный список'"
      />
      <div
        v-if="formType === 'edit'"
        class="tw-my-[10px] tw-text-main-xs tw-text-an-flat-red-main"
      >
        может изменить только администратор
      </div>
      <TwTextarea
        v-if="form.isAddBlackList"
        v-model="form.comment"
        rows="5"
        class="tw-mt-[12px]"
        :error-text="form.comment ? '' : 'Укажите причину добавления'"
        placeholder="Причина добавления в чёрный список"
        :disabled="formProcessing"
        :show-label="true"
      />
    </div>

    <TwCardSimpleFormSaveBlock
      class="tw-mt-[15px]"
      :loading="formProcessing"
      :cancel-confirm="isEdited"
      :is-sticky="false"
      :disable-success="formProcessing || (form.isAddBlackList && !form.comment)"
      :disable-cancel="formProcessing"
      :visible="true"
      @success="handleSubmit"
      @cancel="handleCancel"
    />
  </div>
</template>

<script lang="js">
import API from '@/Api/Crm/Company/BlackList'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import { mapGetters } from 'vuex'
import TwCheckBox from '@/components/tw-ui/ui-kit/input/TwCheckBox.vue'
import TwTextarea from '@/components/tw-ui/ui-kit/input/TwTextarea.vue'
import TwCardSimpleFormSaveBlock from '@/components/tw-ui/forms/TwCardSimpleFormSaveBlock.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'

export default {
  name: 'CompanyBlackListForm',
  components: { TwCircleLoader, TwCardSimpleFormSaveBlock, TwTextarea, TwCheckBox },
  mixins: [FormCreateEditMixin],
  props: {
    companyId: {
      type: Number,
      required: true
    },
    denseFields: {
      type: Boolean,
      default: false
    },
    disableRedirect: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      API: API,
      form: {
        id: null,
        companyId: this.companyId,
        isAddBlackList: false,
        comment: null
      }
    }
  },
  methods: {
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          const adaperMap = {
            // from(show): to(send)
            company: 'companyId'
          }
          for (const key in this.preset) {
            if (adaperMap[key] !== undefined) {
              if (adaperMap[key].indexOf('Id') !== -1 && (this.preset[key] && this.preset[key].id)) {
                this.$set(this.form, adaperMap[key], this.preset[key].id)
              }
            } else {
              this.$set(this.form, key, this.preset[key])
            }
          }
          if (!this.form?.companyId) {
            this.form.companyId = this.companyId
          }
          if (this.preset?.id) {
            this.form.isAddBlackList = true
          }
        }
        resolve()
      })
    },
    handleResetCustom () {
      this.handleReset()
    },
    handleSubmit () {
      // console.warn(this.$options.name, ' handleSubmit ', this.formType, this.targetId)
      if (this.formProcessing === true) {
        return
      }

      this.formProcessing = true
      if (!this.form.isAddBlackList) {
        if (this.targetId) {
          this.API.delete(this.targetId)
            .then(({ data }) => {
              console.warn(this.$options.name, ' delete ', data)
              this.$emit('refresh')
            })
            .catch((error) => {
              console.error(this.$options.name, error)
            })
            .finally(() => {
              this.formProcessing = false
            })
        } else {
          this.$emit('refresh')
        }
      } else if (this.formType === 'create') {
        this.API.store(this.form)
          .then(({ data }) => {
            console.warn(this.$options.name, ' CREATE ', data)
            // this.fetchData(data.data.id)
            this.$emit('create', data.data)
          })
          .catch((error) => {
            console.error(this.$options.name, error)
          })
          .finally(() => {
            this.formProcessing = false
          })
      } else if (this.formType === 'edit' && this.targetId && this.form.isAddBlackList) {
        this.API.update(this.targetId, this.form)
          .then(({ data }) => {
            console.warn(this.$options.name, ' UPDATE ', data.data)
            this.$emit('update', data.data)
          })
          .catch((error) => {
            console.error(this.$options.name, error)
          })
          .finally(() => {
            this.formProcessing = false
          })
      } else {
        alert(`Error handleSubmit: ${this.$options.name}`)
      }
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'profile/isAdmin'
    })
  }
}
</script>
