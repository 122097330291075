<template>
  <div
    v-if="formSchema && Array.isArray(formSchema.blocks) && formSchema.blocks.length"
    class="tw-flex"
  >
    <div :style="{ width: `${100 / formSchema.cols}%` }">
      <div
        v-for="block in formSchema.blocks"
        :key="block.key"
      >
        <TwFormGeneratorBlock
          :block="block"
          :form-data="formData"
          :disabled="disabled"
          @input="$emit('input', $event)"
        />
      </div>
    </div>
  </div>
  <div
    v-else
    class="tw-text-center tw-text-an-flat-disabled-text tw-p-[12px]"
  >
    Невалидная схема формы
  </div>
</template>

<script>
import TwFormGeneratorBlock from '@/views/Company/UserSurveys/FormGenerator/TwFormGeneratorBlock.vue'

export default {
  name: 'TwFormGenerator',
  components: { TwFormGeneratorBlock },
  props: {
    formSchema: {
      type: Object,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>
