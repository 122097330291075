<template>
  <div>
    <TwPanelContentBlock
      v-if="dealState"
      class="tw-mb-[6px]"
      :content-classes="''"
    >
      <template #title>
        <div>
          Информация по одобренной ипотеке
        </div>
      </template>
      <div v-if="dealState.mortgageRelatedDealId">
        <TwPanelContentBlockFieldView class="tw-mb-[14px]">
          <template #title>
            Связанная сделка по ипотеке
          </template>
          <div>
            <ViewPanelLink
              :id="dealState.mortgageRelatedDealId"
              component="deal-show"
            >
              #{{ dealState.mortgageRelatedDealId }}
            </ViewPanelLink>
          </div>
        </TwPanelContentBlockFieldView>

        <MortgageApprovedBanksList
          :deal-id="null"
          :custom-list="dealState.mortgageApprovedBanks"
          :disabled="true"
        />
      </div>
    </TwPanelContentBlock>
    <TwPanelContentBlock v-if="(dealState.resultId && dealState.mortgageRelatedDealId) || isAdmin">
      <template #title>
        <div>
          Информация о выданной ипотеке
        </div>
      </template>
      <!--  Добавить и изменить результат мы можем только в сопровождении  -->
      <template #action>
        <TwPanelContentBlockActionButton
          v-if="hideActionBlockChange"
          :disabled="!dealState.resultId || !dealState.mortgageRelatedDealId"
          :type="dealState.mortgageRelatedDealResultId ? 'edit':'add'"
          @click="editBlock = !editBlock"
        >
          <div v-if="editBlock">
            Отмена
          </div>
          <div v-else>
            {{ dealState.mortgageRelatedDealResultId ? 'изменить' : 'добавить' }}
          </div>
        </TwPanelContentBlockActionButton>
      </template>
      <div v-if="dealState.resultId && dealState.mortgageRelatedDealId">
        <!-- EDIT -->
        <BuyNbResultMortgageForm
          v-if="editBlock"
          :id="dealState.mortgageRelatedDealResultId ? dealData.id : 0"
          :deal-id="dealData.id"
          @create="refresh"
          @update="refresh"
          @cancel="editBlock = false"
        />
        <!-- SHOW -->
        <template v-else-if="!editBlock">
          <BuyNbResultMortgageShow :id="dealData.id" />
        </template>
      </div>
      <div
        v-else-if="!dealState.mortgageRelatedDealId"
        class="tw-text-an-flat-disabled-text tw-text-center"
      >
        Нет информации по связанной Ипотечной сделки
      </div>
      <div
        v-else
        class="tw-text-an-flat-red-main tw-text-center"
      >
        Информация по ипотеке заполняется после заполнения<br> результата по сделке
      </div>
    </TwPanelContentBlock>
  </div>
</template>

<script>
import BuyNbResultMortgageForm from '@/views/Deal/TypeViews/buyNb/ResultMortgage/BuyNbResultMortgageForm'
import BuyNbResultMortgageShow from '@/views/Deal/TypeViews/buyNb/ResultMortgage/BuyNbResultMortgageShow'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwPanelContentBlockActionButton from '@/components/tw-ui/panels/TwPanelContentBlockActionButton.vue'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink.vue'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'
import MortgageApprovedBanksList from '@/views/Deal/TypeViews/mortgage/approvedBanks/MortgageApprovedBanksList.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'BuyNbResultMortgageBlock',
  components: {
    MortgageApprovedBanksList,
    TwPanelContentBlockFieldView,
    ViewPanelLink,
    TwPanelContentBlockActionButton,
    TwPanelContentBlock,
    BuyNbResultMortgageShow,
    BuyNbResultMortgageForm
  },
  props: {
    dealData: {
      required: true
    },
    dealState: {
      default: null
    }
  },
  data () {
    return {
      editBlock: false
    }
  },
  methods: {
    refresh () {
      this.editBlock = false
      this.$emit('refresh')
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'profile/isAdmin'
    }),
    hideActionBlockChange () {
      if (this.dealData?.status?.stage?.value === 'success') {
        return false
      } else return this.dealData?.status?.stage?.value !== 'fail'
    }
  }
}
</script>
