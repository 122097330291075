<template>
  <div>
    <div
      v-if="loading"
      class="tw-h-full tw-w-full tw-flex tw-justify-center tw-items-center tw-py-5"
    >
      <TwCircleLoader
        height="50px"
        width="50px"
      />
    </div>
    <div v-else>
      <div
        :class="fromPanel ? 'tw-px-[26px] tw-py-[18px]':''"
        class="tw-font-manrope tw-text-main-sm2 tw-max-w-[100%] tw-bg-white tw-min-h-screen"
      >
        <TwPanelTitle v-if="fromPanel">
          <template #left>
            <div
              v-if="data"
              class="tw-text-h4 tw-font-bold"
            >
              {{ data.name }}
              <span class="tw-text-an-flat-disabled-text tw-font-medium"># {{ data.id }}</span>
            </div>
            <div
              v-else
              class="tw-text-h4 tw-font-bold"
            >
              Новая программа
            </div>
          </template>
          <template #right>
            <TwPanelActionButton
              v-if="!isNew && !copyData && data"
              :disabled="editMain"
              class="tw-mr-2"
              color="blue"
              @click.stop="createCopy"
            >
              Создать копию
            </TwPanelActionButton>
            <TwPanelActionButton
              v-if="permissions"
              :disabled="editMain"
              title="Удаление"
              color="red"
              @click.prevent="deleteCardItem"
            >
              Удалить
            </TwPanelActionButton>
          </template>
        </TwPanelTitle>
        <TwPanelTabsFull
          v-model="currentTab"
          :items="tabList"
        >
          <template #content.main>
            <TwPanelContent>
              <!-- LEFT -->
              <div class="tw-flex-1">
                <TwPanelContentBlock>
                  <template #title>
                    <div>
                      О программе
                    </div>
                  </template>
                  <template #action>
                    <TwPanelContentBlockActionButton
                      :disabled="isNew"
                      type="edit"
                      @click="editMain = !editMain"
                    >
                      {{ editMain ? 'отменить' : 'изменить' }}
                    </TwPanelContentBlockActionButton>
                  </template>
                  <div v-if="editMain">
                    <MortgageProgramMainForm
                      :id="data ? data.id : 0"
                      ref="EditMain"
                      :copy-data="copyData"
                      @create="onCreated"
                      @update="onUpdated"
                      @cancel="editMain = false"
                    />
                  </div>
                  <template v-else>
                    <!--      ИНТЕРФЕЙС - ПРОСМОТР      -->
                    <MortgageProgramMainShow
                      :dictionary="dictionary"
                      :info="data"
                      @refresh="refresh"
                    />
                  </template>
                </TwPanelContentBlock>
              </div>
            </TwPanelContent>
          </template>
          <template #content.history>
            <TwPanelContentBlock>
              <template #title>
                <div class="tw-flex-1">
                  В разработке...
                </div>
              </template>
            </TwPanelContentBlock>
          </template>
        </TwPanelTabsFull>
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/Api/Mortgage/Program/index'
import CardMixin from '@/mixins/CardMixin'
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import MortgageProgramMainForm from '@/views/Mortgage/Program/main/MortgageProgramMainForm'
import MortgageProgramMainShow from '@/views/Mortgage/Program/main/MortgageProgramMainShow'
import TwPanelContent from '@/components/tw-ui/panels/TwPanelContent.vue'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwPanelTabsFull from '@/components/tw-ui/panels/TwPanelTabsFull.vue'
import { button } from '@/utils/tw-classes'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import TwPanelTitle from '@/components/tw-ui/panels/TwPanelTitle.vue'
import TwPanelActionButton from '@/components/tw-ui/panels/TwPanelActionButton.vue'
import TwPanelContentBlockActionButton from '@/components/tw-ui/panels/TwPanelContentBlockActionButton.vue'

export default {
  name: 'MortgageProgramCard',
  components: {
    TwPanelContentBlockActionButton,
    TwPanelActionButton,
    TwPanelTitle,
    TwCircleLoader,
    TwPanelTabsFull,
    TwPanelContentBlock,
    TwPanelContent,
    MortgageProgramMainShow,
    MortgageProgramMainForm
  },
  mixins: [CardMixin, ViewPanelShowMixin],
  data () {
    return {
      API: API,
      currentTab: 'main',
      createBtnStyle: button.pageListHeaderCreate
    }
  },
  computed: {
    tabList () {
      return [
        {
          label: 'Общие',
          key: 'main'
        },
        {
          label: 'История',
          key: 'history'
        }
      ]
    }
  },
  methods: {
    createCopy () {
      const copyData = { ...JSON.parse(JSON.stringify(this.data || {})), ...{} }
      copyData.id = null
      copyData.name += '(Копия)'
      copyData.rates.forEach(rate => {
        rate.id = null
      })
      console.log('createCopy copyData', copyData)
      this.showMortgageProgramCard({ id: 0, copyData }, () => {
        console.error('createCopy SUCCESS')
      })
      // if (this.$refs?.statusProgress?.createDealByLead) {
      //   this.$refs.statusProgress.createDealByLead()
      // }
    }
  }
}
</script>
