<template>
  <div class="tw-font-manrope tw-ml-[3px]">
    <div
      v-if="label || errorValues"
      class="tw-flex tw-justify-between tw-gap-[8px] tw-min-h-[21px] tw-mb-[4px]"
    >
      <label
        v-if="label"
        :for="uid"
        :class="'tw-text-an-flat-dark-blue-link'"
        class="tw-pointer-events-none tw-font-medium tw-text-main-sm2 tw-left-[5px] "
      >
        {{ label }}
        <span
          v-if="isRequired"
          class="tw-text-an-flat-red-main tw-text-main-sm"
        >
          <slot name="requiredSymbol">*</slot>
        </span>
      </label>
      <label
        v-if="Array.isArray(errorValues) && errorValues.length"
        class="tw-text-[12px] tw-text-an-flat-red-main"
        :for="uid"
      >
        <span
          v-for="(err, index) in errorValues"
          :key="index"
        >
          {{ err }}<span v-if="errorValues.length - 1 > index">,</span>
        </span>
      </label>
    </div>
    <div :class="[wrapperClasses]">
      <div
        v-for="(opt, index) in options"
        :key="index"
        class="tw-flex tw-items-center tw-leading-none"
      >
        <input
          :id="uid + opt.value"
          type="radio"
          :value="opt.value"
          :disabled="disabled"
          :name="uid"
          :class="[sizeClasses]"
          :checked="opt.value === value"
          class="tw-peer tw-text-an-flat-dark-blue-link enabled:tw-cursor-pointer"
          @change="onChange(opt)"
        >
        <label
          :for="uid + opt.value"
          :class="[dense ? 'tw-text-main-xs':'tw-text-main-sm', Array.isArray(errorValues) && errorValues.length ? '!tw-text-an-flat-red-main':'']"
          class="tw-ps-[6px] tw-font-medium tw-text-an-flat-black-main peer-enabled:tw-cursor-pointer peer-disabled:tw-text-an-flat-disabled-input-text"
        >
          {{ opt.label }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TwInputRadio',
  props: {
    value: {
      default: null
    },
    options: {
      type: Array,
      default: () => []
    },
    returnObject: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    showLabel: {
      type: Boolean,
      default: false
    },
    vertical: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    errorText: {
      type: [String, Array],
      default: null
    }
  },
  computed: {
    sizeClasses () {
      if (this.dense) {
        return 'tw-w-[16px] tw-h-[16px]'
      }
      return 'tw-w-[18px] tw-h-[18px]'
    },
    wrapperClasses () {
      if (this.vertical) {
        return 'tw-flex tw-flex-col tw-gap-[8px]'
      }
      return 'tw-flex tw-flex-row tw-flex-wrap tw-gap-[12px]'
    },
    uid () {
      return `${this.$options.name}_${this._uid}`
    },
    isRequired () {
      // определение обязательности поля
      // - если тестовая ошибка имеет дефолтное значение
      // и если текст лейбла показан над полем ввода
      return this.errorText !== null && this.errorText !== ''
    },
    errorValues () {
      if (Array.isArray(this.errorText)) {
        return this.errorText
      } else if (this.errorText) {
        return [this.errorText]
      }
      return []
    },
    isShowErrorLabel () {
      return this.errorText !== null && !this.disabled
    }
  },
  methods: {
    onChange (option) {
      this.$emit('input', option.value)
    }
  }
}
</script>
