<template>
  <v-radio-group
    :disabled="disabled"
    :row="row"
    :hide-details="hideDetails"
    :class="classes"
    :value="value"
    :rules="rules"
    @change="$emit('input', $event)"
  >
    <template #label>
      <span class="font-weight-medium subtitle-2">
        {{ label }}
      </span>
    </template>
    <v-radio
      v-for="(item, key) in optionsList"
      :key="key"
      :disabled="item.disabled"
      :color="fixedColor ? fixedColor : item.color"
      :value="item.value"
    >
      <template #label>
        <small>
          {{ item.label }}
        </small>
      </template>
    </v-radio>
  </v-radio-group>
</template>

<script>
export default {
  name: 'InputRadioGroup',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    row: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    classes: {
      type: Array,
      default: () => []
    },
    rules: {
      type: Array,
      default: () => []
    },
    fixedColor: {},
    options: {
      type: Array,
      default: () => []
    },
    value: {}
  },
  data () {
    return {
      defaultOptions: [
        {
          label: 'Да',
          color: 'green',
          value: true
        },
        {
          label: 'Нет',
          color: 'red',
          value: false
        }
      ]
    }
  },
  computed: {
    optionsList () {
      return (Array.isArray(this.options) && this.options.length) ? this.options : this.defaultOptions
    }
  }
}
</script>
