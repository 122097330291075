import { RouteKeys } from '@/router/types'

export default {
  path: `${RouteKeys.pageMeta}`,
  name: RouteKeys.pageMeta,
  redirect: { name: `${RouteKeys.pageMeta}-list` },
  meta: { name: 'Метатеги страниц каталога', icon: 'admin-catalog-seo-page' },
  component: () => import('@/views/Site/Catalog/Index.vue'),
  children: [
    {
      path: 'list',
      name: `${RouteKeys.pageMeta}-list`,
      meta: { name: 'Список Метатегов страниц', icon: 'admin-catalog-seo-page', hideInMenu: true },
      component: () => import('@/views/Site/Catalog/PageMeta/List.vue')
    },
    {
      path: ':id/edit',
      name: `${RouteKeys.pageMeta}-edit`,
      meta: { name: 'Редактирование Метатегов страницы', icon: 'admin-catalog-seo-page', hideInMenu: true },
      component: () => import('@/views/Site/Catalog/PageMeta/Edit.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    },
    {
      path: 'create',
      name: `${RouteKeys.pageMeta}-create`,
      meta: { name: 'Добавление Метатега страницы', icon: 'admin-catalog-seo-page', hideInMenu: true },
      component: () => import('@/views/Site/Catalog/PageMeta/Edit.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    }
  ]
}
