interface IDict {
  [type: string]: {
    [key: string]: any
  };
}

export const dict:IDict = {
  passportType: {
    series: {
      label: 'Серия',
      value: null
    },
    number: {
      label: 'Номер',
      value: null
    },
    dateIssued: {
      label: 'Дата выдачи',
      value: null
    },
    code: {
      label: 'Код подразделения',
      value: null
    },
    whoIssued: {
      label: 'Кем выдан',
      value: null
    },
    placeBirth: {
      label: 'Место рождения',
      value: null
    },
    placeRegistration: {
      label: 'Место регистрации',
      value: null
    },
    placeResidence: {
      label: 'Место жительства',
      value: null
    }
  },
  foreignPassportType: {
    number: {
      label: 'Номер',
      value: null
    },
    dateIssued: {
      label: 'Дата выдачи',
      value: null
    },
    dateExpire: {
      label: 'Срок действия',
      value: null
    },
    whoIssued: {
      label: 'Кем выдан',
      value: null
    },
    placeBirth: {
      label: 'Место рождения',
      value: null
    },
    placeRegistration: {
      label: 'Место регистрации',
      value: null
    },
    placeResidence: {
      label: 'Место жительства',
      value: null
    }
  },
  snilsType: {
    number: {
      label: 'Номер',
      value: null
    }
  },
  innType: {
    number: {
      label: 'Номер',
      value: null
    }
  }
}

export const dictFileTypes:IDict = {
  passportType: [
    {
      label: '2-3 разворот (с фото)',
      value: 'passport_1'
    },
    {
      label: '4-5 разворот',
      value: 'passport_2'
    },
    {
      label: '6-7 разворот',
      value: 'passport_3'
    },
    {
      label: '8-9 разворот',
      value: 'passport_4'
    },
    {
      label: '10-11 разворот',
      value: 'passport_5'
    },
    {
      label: '12-13 разворот',
      value: 'passport_6'
    },
    {
      label: '14-15 разворот',
      value: 'passport_7'
    },
    {
      label: '16-17 разворот',
      value: 'passport_8'
    },
    {
      label: '18-19 разворот',
      value: 'passport_9'
    }
  ],
  foreignPassportType: [
    {
      label: '2-3 разворот (с фото)',
      value: 'passport_1'
    },
    {
      label: '4-5 разворот',
      value: 'passport_2'
    },
    {
      label: '6-7 разворот',
      value: 'passport_3'
    },
    {
      label: '8-9 разворот',
      value: 'passport_4'
    },
    {
      label: '10-11 разворот',
      value: 'passport_5'
    },
    {
      label: '12-13 разворот',
      value: 'passport_6'
    },
    {
      label: '14-15 разворот',
      value: 'passport_7'
    },
    {
      label: '16-17 разворот',
      value: 'passport_8'
    },
    {
      label: '18-19 разворот',
      value: 'passport_9'
    }
  ]
}

export const formFileTypesFromType = (type:string) => {
  // console.info('formFileTypesFromType: ', { type }, dictFileTypes[type])
  return dictFileTypes[type]
}

export const formFieldsFromTypes = (type:string) => {
  const result:{ [key: string]: any } = {}
  // console.info('formFieldsFromTypes: ', { type }, dict[type])
  if (type && dict[type]) {
    for (const key in dict[type]) {
      result[key] = dict[type][key].value
    }
  } else {
    console.error('formFieldsFromTypes BAT Type: ', type)
  }
  // console.info('formFieldsFromTypes: ', result)
  return result
}

export const fieldsTextFromTypes = () => {
  const result:IDict = {}
  for (const type in dict) {
    if (!result[type]) {
      result[type] = {}
    }
    for (const key in dict[type]) {
      result[type][key] = dict[type][key].label
    }
  }
  return result
}

export const confirmedTextTrue = 'Клиент подтверждён! Есть сделки с участием данного клиента'
export const confirmedTextFalse = 'Клиент не подтверждён! Данный клиент не участвовал в сделках'

export const statusDotsColor = {
  blackList: 'black',
  confirmedTrue: '#22C17B',
  confirmedFalse: '#E63114'
}
