<template>
  <div
    v-if="formProcessing"
    class="tw-py-3 tw-text-center tw-m-auto"
  >
    <TwCircleLoader />
  </div>
  <div
    v-else
    ref="form"
  >
    <div style="display: none">
      {{ isEdited }}
    </div>
    <div class="tw-my-[35px]">
      <TwTextarea
        v-model="form.advantage"
        rows="9"
        label="Полное описание ЖК"
        placeholder="Описание ЖК"
        :disabled="formProcessing"
        persistent-label
        :show-label="true"
      />
    </div>
    <div class="tw-my-[15px]">
      <TwPanelInfoBlock
        type="info"
        class="tw-mb-[6px]"
      >
        <template #title>
          Важно!
        </template>
        <template #text>
          Ниже показанные поля устарели и не используются, оставлены для информации и переноса данных в полное описание ЖК
        </template>
      </TwPanelInfoBlock>
    </div>
    <div class="tw-my-[35px]">
      <TwTextarea
        v-model="form.house"
        rows="5"
        :disabled="true"
        label="Дом"
        placeholder="Описание дома"
        persistent-label
        :show-label="true"
      />
    </div>
    <div class="tw-my-[35px]">
      <TwTextarea
        v-model="form.yard"
        rows="5"
        label="Двор"
        placeholder="Описание двора"
        :disabled="true"
        persistent-label
        :show-label="true"
      />
    </div>

    <div class="tw-my-[35px]">
      <TwTextarea
        v-model="form.featuresHousing"
        rows="5"
        label="Особенности жилья"
        placeholder="Описание особенности жилья"
        :disabled="true"
        persistent-label
        :show-label="true"
      />
    </div>
    <div class="tw-my-[35px]">
      <TwTextarea
        v-model="form.security"
        rows="5"
        label="Безопасность"
        placeholder="Описание безопасность"
        :disabled="true"
        persistent-label
        :show-label="true"
      />
    </div>
    <div class="tw-my-[35px]">
      <TwTextarea
        v-model="form.improvement"
        rows="5"
        label="Благоустройство"
        placeholder="Описание благоустройство"
        :disabled="true"
        persistent-label
        :show-label="true"
      />
    </div>
    <div class="tw-my-[35px]">
      <TwTextarea
        v-model="form.infrastructure"
        rows="5"
        label="Инфрастуктура"
        placeholder="Описание инфрастуктуры"
        :disabled="true"
        persistent-label
        :show-label="true"
      />
    </div>
    <TwCardSimpleFormSaveBlock
      :loading="formProcessing"
      style="z-index: 10"
      cancel-confirm
      :disable-cancel="formType === 'create'"
      is-sticky
      :visible="true"
      @success="handleSubmit"
      @cancel="handleCancel"
    />
  </div>
</template>

<script>
import { API } from '@/Api/ObjectStorage'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import { RouteKeys } from '@/router/types'
import TwCardSimpleFormSaveBlock from '@/components/tw-ui/forms/TwCardSimpleFormSaveBlock.vue'
import TwTextarea from '@/components/tw-ui/ui-kit/input/TwTextarea.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import TwPanelInfoBlock from '@/components/tw-ui/panels/TwPanelInfoBlock.vue'

export default {
  name: 'RealtyComplexDescForm',
  components: {
    TwPanelInfoBlock,
    TwCircleLoader,
    TwTextarea,
    TwCardSimpleFormSaveBlock
  },
  mixins: [FormCreateEditMixin],
  props: {
    disableRedirect: {
      type: Boolean,
      default: false
    },
    defaultFormFields: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      API: API.ComplexDescription,
      form: {
        complexId: this.defaultFormFields?.complexId || null,
        advantage: null,
        security: null,
        improvement: null,
        infrastructure: null,
        featuresHousing: null,
        house: null,
        yard: null
      }
    }
  },
  methods: {
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          for (const key in this.preset) {
            this.$set(this.form, key, this.cleanHtml(this.preset[key]) || '')
          }
        }
        resolve()
      })
    },
    cleanHtml (text) {
      if (text) {
        const tmp = document.createElement('div')
        tmp.innerHTML = text
        return (tmp.textContent || tmp.innerText || '')
      }
      return ''
    },
    handleSubmit () {
      if (this.formProcessing === true) {
        return
      }

      this.formProcessing = true
      const request = this.formType === 'create'
        ? this.API.store(this.form)
        : this.API.update(this.targetId, this.form)

      request.then(({ data }) => {
        if (this.formType === 'create') {
          this.$emit('create', data.data)
          // если текущий путь равен пути создания то редирект в просмотр
          if (this.$route.name === `${RouteKeys.lead}-create`) {
            this.$router.replace({
              name: `${RouteKeys.lead}-show`,
              params: { id: data.data.id }
            })
          }
        } else {
          this.$emit('update', data.data)
        }
      }).finally(() => {
        this.formProcessing = false
      })
    }
  }
}
</script>
