import status from './status'

export default {
  path: 'deal',
  name: 'deal-dictionary',
  redirect: { name: 'deal-status-list' },
  meta: { name: 'Заявки', icon: 'applications', isAdmin: true },
  component: () => import('@/views/Dictionary/Index.vue'),
  children: [
    status
  ]
}
