<template>
  <div
    class="a-filter-enum-select"
    tabindex="0"
  >
    <select
      :value="value"
      @input="onInput"
    >
      <option
        v-for="(fieldName, fieldKey) in options"
        :key="fieldKey"
        :value="fieldKey"
      >
        {{ fieldName }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'AEnumSimpleSelect',
  props: {
    options: {
      type: Object,
      default: () => {
        return {}
      }
    },
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      type: [String, null],
      default: null
    }
  },
  methods: {
    onInput (event) {
      console.warn(event?.type === 'input', event.target.value, event)
      this.$emit('input', event.target.value)
      this.$emit('inputFullData', this.options[event.target.value])
    }
  }
}
</script>

<style scoped lang="less">
.a-filter-enum-select {
  font: 15px "Helvetica Neue", Arial, Helvetica, sans-serif;
  width: 100%;
  border: 1px solid rgba(83, 92, 105, 0.2);
  border-radius: 2px;
  background: #fff;
  min-height: 38px;
  outline: none;
  select {
    padding: 5px 10px 5px 10px;
    width: 100%;
    min-height: 36px;
    outline: none;
  }
}
</style>
