<template>
  <TwPanelContentBlock
    class="tw-my-[6px]"
    :content-classes="''"
  >
    <template #title>
      <div>
        Галерея
      </div>
    </template>
    <template #action>
      <TwPanelContentBlockActionButton
        type="edit"
        :disabled="!isAllowEdit"
        @click="isEdit = !isEdit"
      >
        {{ isEdit ? 'отменить' : 'изменить' }}
      </TwPanelContentBlockActionButton>
    </template>
    <div v-if="isEdit">
      <div
        v-if="currentEditedItemId !== 0"
        class="tw-text-right tw-mb-[12px]"
      >
        <TwPanelContentBlockActionButton
          type="add"
          size="md"
          @click="currentEditedItemId = 0"
        >
          Добавить новый слайд
        </TwPanelContentBlockActionButton>
      </div>
      <div
        v-if="currentEditedItemId === 0"
        class="tw-mb-[12px] tw-rounded-md tw-bg-white"
      >
        <div class="tw-text-h6 tw-p-[14px]">
          Новый слайд
        </div>
        <hr>
        <div class="tw-p-[14px]">
          <RealtyComplexGalleryForm
            :id="0"
            :default-form-fields="defaultFormFields"
            @cancel="editFinally"
            @create="editFinally"
            @update="editFinally"
          />
        </div>
      </div>
      <div
        v-for="(item, index) in gallery"
        :key="index"
        class="tw-mb-[12px] tw-rounded-md tw-bg-white"
      >
        <div class="tw-text-h6 tw-p-[14px] tw-flex tw-w-full">
          Слайд {{ index + 1 }}
          <div class="tw-grow" />
          <TwPanelContentBlockActionButton
            v-if="currentEditedItemId !== item.id"
            type="edit"
            size="md"
            @click="currentEditedItemId = item.id"
          >
            Изменить
          </TwPanelContentBlockActionButton>
          <TwPanelContentBlockActionButton
            v-if="currentEditedItemId === item.id"
            type="delete"
            size="md"
            @click="deleteItem(currentEditedItemId)"
          >
            Удалить слайд
          </TwPanelContentBlockActionButton>
        </div>
        <hr>
        <div
          v-if="currentEditedItemId === item.id"
          class="tw-p-[14px]"
        >
          <RealtyComplexGalleryForm
            :id="item.id || 0"
            :key="index"
            :default-form-fields="defaultFormFields"
            @cancel="editFinally"
            @create="editFinally"
            @update="editFinally"
          />
        </div>
        <div
          v-else
          class="tw-p-[14px]"
        >
          <v-img
            :src="item.file ? item.file.url : defaultImage"
            class="tw-rounded-md"
          />
          <div class="tw-pt-[14px]">
            {{ item.description || 'Нет описания слайда' }}
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div
        v-if="loading"
        class="tw-flex tw-justify-center tw-items-center tw-rounded-md tw-bg-white tw-p-[14px]"
      >
        <TwCircleLoader
          width="30px"
          height="30px"
        />
      </div>
      <RealtyComplexGalleryShow
        v-else
        :gallery="gallery"
        :dictionary="dictionary"
        class=" tw-rounded-md tw-bg-white tw-px-[14px] tw-pt-[14px]"
      />
    </div>
  </TwPanelContentBlock>
</template>

<script>
import RealtyComplexGalleryShow from '@/views/Newbuildings/Complex/gallery/RealtyComplexGalleryShow'
import RealtyComplexGalleryForm from '@/views/Newbuildings/Complex/gallery/RealtyComplexGalleryForm'
import { API } from '@/Api/ObjectStorage'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwPanelContentBlockActionButton from '@/components/tw-ui/panels/TwPanelContentBlockActionButton.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'

export default {
  name: 'RealtyComplexGallery',
  components: { TwCircleLoader, TwPanelContentBlockActionButton, TwPanelContentBlock, RealtyComplexGalleryForm, RealtyComplexGalleryShow },
  props: {
    info: {},
    dictionary: {},
    isAllowEdit: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      API: API.ComplexGallery,
      gallery: [],
      isEdit: false,
      currentEditedItemId: null
    }
  },
  computed: {
    defaultImage () {
      return require('@/assets/images/no_image.svg')
    },
    defaultFormFields () {
      return {
        complexId: this?.info?.id || null
      }
    }
  },
  watch: {
    isEdit (newValue) {
      if (!newValue) {
        this.currentEditedItemId = null
      }
    }
  },
  mounted () {
    this.fetchList()
  },
  methods: {
    fetchList () {
      this.loading = true
      this.API.getList({
        complexId: this?.info?.id,
        limit: 999
      }).then(({ data }) => {
        this.gallery = data?.data || []
      }).catch(() => {
        this.gallery = []
      }).finally(() => {
        this.loading = false
      })
    },
    deleteItem (id) {
      if (confirm('Слайд будет удалён! Вы уверены?')) {
        this.loading = true
        this.API.delete(id).then(() => {
          this.editFinally()
        }).catch(() => {
          this.gallery = []
        }).finally(() => {
          this.loading = false
        })
      }
    },
    editFinally (eventData) {
      console.warn(this.$options.name, ' editFinally ', eventData)
      this.currentEditedItemId = null
      this.fetchList()
    }
  }
}
</script>
