<template>
  <TwPanelContentBlock
    v-if="info"
    no-data-text="Отсутствует"
    class="tw-mb-[6px]"
  >
    <template #title>
      Чеклист закрытия периода
    </template>
    <template #default>
      <div>
        <div
          v-if="formProcessing"
          class="tw-flex tw-justify-center tw-items-center"
        >
          <TwCircleLoader
            width="30px"
            height="30px"
          />
        </div>
        <div
          v-else
          class="tw-flex tw-justify-between"
        >
          <div class="tw-select-none tw-p-[7px] tw-whitespace-nowrap tw-text-center tw-font-medium">
            <TwCheckBox
              class="tw-mx-auto tw-justify-center"
              :value="!!(info.checkList && info.checkList.act)"
              background-and-border-color="tw-bg-an-flat-blue-btn tw-border-an-flat-blue-btn"
              label="Акт"
              :disabled="formProcessing"
              @input="onShowConfirm($event, 'act')"
            />
          </div>
          <div class="tw-select-none tw-p-[7px] tw-whitespace-nowrap tw-text-center tw-font-medium tw-text-an-flat-gray-4">
            <TwCheckBox
              class="tw-mx-auto tw-justify-center"
              :value="!!(info.checkList && info.checkList.verbally_agreed)"
              background-and-border-color="tw-bg-an-flat-blue-btn tw-border-an-flat-blue-btn"
              label="Устная договоренность"
              :disabled="formProcessing"
              @input="onShowConfirm($event, 'verbally_agreed')"
            />
          </div>
          <div class="tw-select-none tw-p-[7px] tw-whitespace-nowrap tw-text-center tw-font-medium tw-text-an-flat-gray-4">
            <TwCheckBox
              class="tw-mx-auto tw-justify-center"
              :value="!!(info.checkList && info.checkList.calc)"
              background-and-border-color="tw-bg-an-flat-blue-btn tw-border-an-flat-blue-btn"
              label="Расчёт*"
              :readonly="true"
              :disabled="formProcessing"
            />
          </div>
          <div class="tw-select-none tw-p-[7px] tw-whitespace-nowrap tw-text-center tw-font-medium tw-text-an-flat-gray-4">
            <TwCheckBox
              class="tw-mx-auto tw-justify-center"
              :value="!!(info.checkList && info.checkList.pay)"
              background-and-border-color="tw-bg-an-flat-green-btn-bg tw-border-an-flat-green-btn-bg"
              label="Оплата*"
              :readonly="true"
              :disabled="formProcessing"
            />
          </div>
        </div>
        <div class="tw-text-right">
          <small>* - проставляется в бухгалтерии</small>
        </div>
        <!--  Подтверждение установки чекбокса  -->
        <TwDialog
          v-model="confirmDialog"
          max-width="400px"
        >
          <template #header>
            <div>
              <div class="tw-text-h4 tw-font-semibold">
                Подтверждение
              </div>
            </div>
          </template>
          <template #body>
            <div>
              <div
                v-if="!!(info.checkList && info.checkList.act) || isAllowActChangePermissions"
                class="tw-font-medium tw-text-an-flat-red-main"
              >
                <div>
                  Отменить действие - невозможно!
                </div>
                <div class="tw-text-an-flat-blue-btn">
                  Подтвердить выполнение этапа?
                </div>
              </div>
              <div
                v-else
                class="tw-font-medium tw-text-an-flat-red-main"
              >
                <div>
                  Недостаточно прав доступа для изменений
                </div>
              </div>
              <div class="tw-mt-[15px] tw-flex tw-gap-[10px]">
                <button
                  v-if="!!(info.checkList && info.checkList.act) || isAllowActChangePermissions"
                  class="tw-grow tw-px-[15px] tw-py-[7px] tw-bg-white tw-rounded-sm tw-text-an-flat-blue-btn disabled:tw-opacity-50 enabled:hover:tw-bg-an-flat-green-btn-bg enabled:hover:tw-text-white"
                  @click.prevent="updateCheckList"
                >
                  Да
                </button>
                <button
                  class="tw-flex-1 tw-w-[100px] tw-px-[15px] tw-py-[7px] tw-bg-an-flat-gray-4 tw-rounded-sm tw-text-white"
                  @click.prevent="confirmDialog = false"
                >
                  Отмена
                </button>
              </div>
            </div>
          </template>
        </TwDialog>
      </div>
    </template>
  </TwPanelContentBlock>
</template>

<script>
import API from '@/Api/Crm/Deal/BuyNb/ResultManagement'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import TwDialog from '@/components/tw-ui/modal/TwDialog.vue'
import TwCheckBox from '@/components/tw-ui/ui-kit/input/TwCheckBox.vue'
import dictPermissionRules from '@/Permissions/dictRules'
import { mapGetters } from 'vuex'

export default {
  name: 'DealNbResultManagementCheckListBlock',
  components: {
    TwCheckBox,
    TwDialog,
    TwCircleLoader,
    TwPanelContentBlock
  },
  mixins: [ViewPanelShowMixin],
  props: {
    info: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      confirmDialog: false,
      formProcessing: false,
      targetChangedItem: {}
    }
  },
  methods: {
    onShowConfirm (checked, fieldKey) {
      this.targetChangedItem = {
        id: this.info.id,
        field: fieldKey,
        checked
      }
      this.confirmDialog = true
    },
    updateCheckList () {
      if (this.formProcessing) {
        return
      }
      API.updateCheckList(this.targetChangedItem.id, this.targetChangedItem)
        .then(({ data: result }) => {
          console.warn('updateCheckList', result)
          this.targetChangedItem = {}
          this.refreshParent()
        })
        .catch((error) => {
          console.error('updateCheckList', error)
        })
        .finally(() => {
          this.confirmDialog = false
          this.formProcessing = false
        })
    },
    refreshParent () {
      this.$emit('refresh')
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'profile/isAdmin',
      allowNamespacesDict: 'profile/allowNamespacesDict'
    }),
    isAllowActChangePermissions () {
      return this.isAdmin || !!this.allowNamespacesDict[dictPermissionRules.AppServicePermissionRulesCRMDealsNbDealNbCompanyPeriodResultManagementActRule]
    }
  }
}
</script>
