<template>
  <TwSelect
    :value="value"
    :return-object="true"
    placeholder="Выберите статус лида"
    :label="label"
    :multi="true"
    :dense="true"
    :cleanable="true"
    :persistent-label="true"
    :options="list"
    @input="$emit('input', $event)"
    @input-object="$emit('inputFullData', $event)"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import TwSelect from '@/components/tw-ui/ui-kit/input/TwSelect.vue'

export default {
  name: 'TwLeadStatusSelect',
  components: { TwSelect },
  props: {
    label: {
      type: String,
      default: null
    },
    value: {
      type: [Array, null],
      default: null
    }
  },
  computed: {
    ...mapGetters({
      cachedLeadStatusList: 'tmpCache/cachedLeadStatusList',
      getLeadStatusById: 'tmpCache/getLeadStatusById'
    }),
    list () {
      return Array.isArray(this.cachedLeadStatusList) && this.cachedLeadStatusList.length ? this.cachedLeadStatusList.map((item) => {
        return {
          label: item.name || '~',
          value: item.id
        }
      }) : []
    }
  }
}
</script>
