<template>
  <div class="combined-price-input tw-text-an-flat-black-main">
    <div class="tw-block tw-relative">
      <div
        v-if="percentShow"
        class="tw-absolute tw-top-0 tw-right-0 tw-z-10 tw-w-fit tw-ml-auto tw-flex tw-items-center tw-gap-[10px] tw-mb-[4px]"
      >
        <div class="tw-flex tw-items-center tw-gap-[4px]">
          <button
            v-for="(val, index) in presetPercentOptions"
            :key="index"
            class="tw-p-[2px] tw-text-an-flat-grey-3 tw-font-medium tw-text-[11px]"
            @click.prevent="onChangeFixedPercent(val)"
          >
            <span>
              {{ val }}%
            </span>
          </button>
        </div>
        <TwCheckBox
          v-model="enableFixedPercent"
          title="Фиксировать процент при изменении стоимости недвижимости"
          label="%"
          :disabled="disabled"
          background-and-border-color="tw-bg-an-flat-dark-blue-link tw-border-an-flat-dark-blue-link"
        />
      </div>
      <div class="tw-relative tw-pt-[25px]">
        <div class="tw-flex tw-flex-col tw-gap-[6px] tw-bg-an-flat-gray-15 tw-px-[15px] tw-pt-[11px] tw-pb-[4px] tw-rounded-t-sm">
          <div class="tw-flex tw-items-center tw-justify-between">
            <span class="tw-text-[11px] tw-text-an-flat-text-gray tw-leading-none">{{ label }}:</span>
          </div>
          <div class="tw-flex tw-flex-row tw-justify-between tw-font-medium tw-text-[14px]">
            <div class="">
              <input
                class="tw-max-w-[140px] tw-p-[4px] focus:tw-outline-0"
                type="text"
                :disabled="disabled"
                :value="number.toLocaleString()"
                @input="changeNumber"
              >
            </div>
            <div>{{ symbolInput }}</div>
            <div
              v-if="percentShow"
              class="tw-text-[14px]"
            >
              {{ percent }}%
            </div>
          </div>
        </div>
        <div class="tw-relative tw-mb-[11px]">
          <input
            v-model="number"
            type="range"
            class="tw-absolute tw-w-full tw-h-1 tw-mb-6 styled-slider"
            :disabled="disabled"
            step="1"
            :max="max || undefined"
            :min="min"
          >
        </div>
        <div class="tw-bg-white tw-flex tw-justify-between tw-items-center tw-px-[1px] tw-text-an-flat-grey-3 tw-font-medium tw-text-[11px]">
          <div>
            {{ (number || 0).toLocaleString() }}
            <slot name="currentLabel">
              <span v-html="bottomLabelText" />
            </slot>
          </div>
          <div>
            {{ (max || 0).toLocaleString() }}
            <slot name="maxLabel">
              <span v-html="bottomLabelText" />
            </slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TwCheckBox from '@/components/tw-ui/ui-kit/input/TwCheckBox.vue'

export default {
  name: 'CombinedPriceInput',
  components: {
    TwCheckBox
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    percentShow: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      default: 0
    },
    max: {
      default: 100000000
    },
    min: {
      default: 0
    },
    bottomLabelText: {
      default: '&#8381;'
    },
    symbolInput: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      enableFixedPercent: false,
      fixedPercent: 30,
      presetPercentOptions: [
        10, 15, 20
      ]
    }
  },
  computed: {
    number: {
      get: function () {
        return (this.value || 0)
      },
      set: function (val) {
        // console.warn('number - ', val, typeof val)
        this.emitValue(val)
      }
    },
    percent: {
      get: function () {
        const calcPercent = parseFloat((this.value && this.max ? this.value * 100 / this.max : 0).toFixed(1))
        if (this.enableFixedPercent) {
          // this.$emit('change-percent', calcPercent)
          this.onChangeFixedPercent(calcPercent)
        }
        return calcPercent
      }
    }
  },
  watch: {
    max (newValue) {
      if (this.fixedPercent && this.enableFixedPercent) {
        this.emitValueFromFixedPercent(newValue)
      }
    },
    fixedPercent (newValue) {
      if (newValue) {
        this.emitValueFromFixedPercent(this.max)
      }
    }
  },
  methods: {
    onChangeFixedPercent (val) {
      this.fixedPercent = val
    },
    onEnableFixedPercent () {
      this.enableFixedPercent = !this.enableFixedPercent
    },
    changeNumber (e) {
      // eslint-disable-next-line no-irregular-whitespace
      const newVal = e.target.value.replace(/ | /g, '')
      this.emitValue(newVal)
    },
    emitValue (value) {
      this.$emit('input', value)
      // console.warn('emitValue', value, this.enableFixedPercent)
    },
    emitValueFromFixedPercent (value) {
      // Округляю - отбрасывая дробную часть
      const sumFromPercent = Math.trunc(value * (this.fixedPercent / 100))
      this.$emit('input', sumFromPercent)
      // console.warn('emitValueFromFixedPercent', sumFromPercent, this.enableFixedPercent)
    }
  }
}
</script>

<style lang="less">
.combined-price-input {
  // TODO Взял генерация инпута
  //https://toughengineer.github.io/demo/slider-styler*/
  /*generated with Input range slider CSS style generator (version 20211225)*/
  input[type=range].styled-slider {
    height: 3px;
    appearance: none;
    transition: all .1s ease-in;
  }

  /*progress support*/
  input[type=range].styled-slider.slider-progress {
    --range: calc(var(--max) - var(--min));
    --ratio: calc((var(--value) - var(--min)) / var(--range));
    --sx: calc(0.5 * 13px + var(--ratio) * (100% - 13px));
  }

  input[type=range].styled-slider:focus {
    outline: none;
  }

  /*webkit*/
  input[type=range].styled-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 13px;
    height: 13px;
    border-radius: 1em;
    background: #FFFFFF;
    border: none;
    box-shadow: 0 2px 10px 2px #223C5033;
    margin-top: calc(3px * 0.5 - 13px * 0.5);
    transition: all .1s ease-in;
  }

  input[type=range].styled-slider::-webkit-slider-runnable-track {
    height: 3px;
    border: none;
    border-radius: 0;
    background: #DAEBFD;
    box-shadow: none;
    transition: all .1s ease-in;
  }

  input[type=range].styled-slider::-webkit-slider-thumb:hover {
    background: #2787F5;
  }

  input[type=range].styled-slider:hover::-webkit-slider-runnable-track {
    background: #DAEBFD;
  }

  input[type=range].styled-slider::-webkit-slider-thumb:active {
    background: #2787F5;
  }

  input[type=range].styled-slider:active::-webkit-slider-runnable-track {
    background: #DAEBFD;
  }

  input[type=range].styled-slider.slider-progress::-webkit-slider-runnable-track {
    background: linear-gradient(#2787F5,#2787F5) 0/var(--sx) 100% no-repeat, #DAEBFD;
  }

  input[type=range].styled-slider.slider-progress:hover::-webkit-slider-runnable-track {
    background: linear-gradient(#0061C3,#0061C3) 0/var(--sx) 100% no-repeat, #DAEBFD;
  }

  input[type=range].styled-slider.slider-progress:active::-webkit-slider-runnable-track {
    background: linear-gradient(#2787F5,#2787F5) 0/var(--sx) 100% no-repeat, #DAEBFD;
  }

  /*mozilla*/
  input[type=range].styled-slider::-moz-range-thumb {
    width: 13px;
    height: 13px;
    border-radius: 1em;
    background: #FFFFFF;
    border: none;
    box-shadow: 0 2px 10px 2px #223C5033;
  }

  input[type=range].styled-slider::-moz-range-track {
    height: 3px;
    border: none;
    border-radius: 0;
    background: #DAEBFD;
    box-shadow: none;
  }

  input[type=range].styled-slider::-moz-range-thumb:hover {
    background: #2787F5;
  }

  input[type=range].styled-slider:hover::-moz-range-track {
    background: #DAEBFD;
  }

  input[type=range].styled-slider::-moz-range-thumb:active {
    background: #2787F5;
  }

  input[type=range].styled-slider:active::-moz-range-track {
    background: #DAEBFD;
  }

  input[type=range].styled-slider.slider-progress::-moz-range-track {
    background: linear-gradient(#2787F5,#2787F5) 0/var(--sx) 100% no-repeat, #DAEBFD;
  }

  input[type=range].styled-slider.slider-progress:hover::-moz-range-track {
    background: linear-gradient(#0061C3,#0061C3) 0/var(--sx) 100% no-repeat, #DAEBFD;
  }

  input[type=range].styled-slider.slider-progress:active::-moz-range-track {
    background: linear-gradient(#2787F5,#2787F5) 0/var(--sx) 100% no-repeat, #DAEBFD;
  }

  /*ms*/
  input[type=range].styled-slider::-ms-fill-upper {
    background: transparent;
    border-color: transparent;
  }

  input[type=range].styled-slider::-ms-fill-lower {
    background: transparent;
    border-color: transparent;
  }

  input[type=range].styled-slider::-ms-thumb {
    width: 13px;
    height: 13px;
    border-radius: 1em;
    background: #FFFFFF;
    border: none;
    box-shadow: 0 2px 10px 2px #223C5033;
    margin-top: 0;
    box-sizing: border-box;
  }

  input[type=range].styled-slider::-ms-track {
    height: 3px;
    border-radius: 0;
    background: #DAEBFD;
    border: none;
    box-shadow: none;
    box-sizing: border-box;
  }

  input[type=range].styled-slider::-ms-thumb:hover {
    background: #2787F5;
  }

  input[type=range].styled-slider:hover::-ms-track {
    background: #DAEBFD;
  }

  input[type=range].styled-slider::-ms-thumb:active {
    background: #2787F5;
  }

  input[type=range].styled-slider:active::-ms-track {
    background: #DAEBFD;
  }

  input[type=range].styled-slider.slider-progress::-ms-fill-lower {
    height: 3px;
    border-radius: 0px 0 0 0px;
    margin: -undefined 0 -undefined -undefined;
    background: #2787F5;
    border: none;
    border-right-width: 0;
  }

  input[type=range].styled-slider.slider-progress:hover::-ms-fill-lower {
    background: #0061C3;
  }

  input[type=range].styled-slider.slider-progress:active::-ms-fill-lower {
    background: #2787F5;
  }
}
</style>
