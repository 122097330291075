<template>
  <div
    class="notify__item"
    :class="`notify__item--${item.type || 'info'}`"
  >
    <div class="tw-mb-auto">
      <svg
        v-if="item.type === 'success'"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16Z"
          fill="#22C17B"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M21.0023 13.19C21.376 13.5966 21.3493 14.2292 20.9427 14.6029L14.4633 20.5582L11.056 17.4265C10.6493 17.0527 10.6227 16.4201 10.9964 16.0135C11.3701 15.6069 12.0027 15.5802 12.4094 15.9539L14.4633 17.8418L19.5893 13.1304C19.9959 12.7567 20.6285 12.7833 21.0023 13.19Z"
          fill="white"
        />
      </svg>
      <svg
        v-else-if="item.type === 'warning'"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16Z"
          fill="#FFC93E"
        />
        <path
          d="M16 20.1417C16.536 20.1417 16.9821 19.7311 17.0272 19.197L17.724 10.8749C17.7646 10.3922 17.6009 9.91509 17.2733 9.55872C16.9461 9.20281 16.484 9 16 9C15.516 9 15.0539 9.20281 14.7267 9.55872C14.399 9.91509 14.2354 10.3922 14.276 10.8749L14.9728 19.197C15.0179 19.7311 15.4641 20.1417 16 20.1417Z"
          fill="white"
        />
        <path
          d="M16 20.7542C15.3797 20.7542 14.8771 21.2568 14.8771 21.8771C14.8771 22.4974 15.3797 23 16 23C16.6203 23 17.1229 22.4974 17.1229 21.8771C17.1229 21.2568 16.6203 20.7542 16 20.7542Z"
          fill="white"
        />
      </svg>
      <svg
        v-else-if="item.type === 'danger'"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16Z"
          fill="#FF5252"
        />
        <path
          d="M16 20.1417C16.536 20.1417 16.9821 19.7311 17.0272 19.197L17.724 10.8749C17.7646 10.3922 17.6009 9.91509 17.2733 9.55872C16.9461 9.20281 16.484 9 16 9C15.516 9 15.0539 9.20281 14.7267 9.55872C14.399 9.91509 14.2354 10.3922 14.276 10.8749L14.9728 19.197C15.0179 19.7311 15.464 20.1417 16 20.1417Z"
          fill="white"
        />
        <path
          d="M16 20.7542C15.3797 20.7542 14.8771 21.2568 14.8771 21.8771C14.8771 22.4974 15.3797 23 16 23C16.6203 23 17.1229 22.4974 17.1229 21.8771C17.1229 21.2568 16.6203 20.7542 16 20.7542Z"
          fill="white"
        />
      </svg>
      <svg
        v-else-if="item.type === 'error'"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.2984 10.2874C9.69194 9.89995 10.3251 9.90486 10.7126 10.2984L11.9333 11.5382L13.1541 10.2984C13.5416 9.90486 14.1747 9.89995 14.5683 10.2874C14.9618 10.6749 14.9667 11.3081 14.5792 11.7016L13.3367 12.9635L14.5792 14.2255C14.9667 14.619 14.9618 15.2522 14.5683 15.6397C14.1747 16.0271 13.5416 16.0222 13.1541 15.6287L11.9333 14.3888L10.7126 15.6287C10.3251 16.0222 9.69194 16.0271 9.2984 15.6397C8.90486 15.2522 8.89995 14.619 9.28743 14.2255L10.53 12.9635L9.28743 11.7016C8.89995 11.3081 8.90486 10.6749 9.2984 10.2874ZM17.0317 10.2874C17.4253 9.89995 18.0584 9.90486 18.4459 10.2984L19.6667 11.5382L20.8874 10.2984C21.2749 9.90486 21.9081 9.89995 22.3016 10.2874C22.6952 10.6749 22.7001 11.3081 22.3126 11.7016L21.0701 12.9635L22.3126 14.2255C22.7001 14.619 22.6952 15.2522 22.3016 15.6397C21.9081 16.0271 21.2749 16.0222 20.8874 15.6287L19.6667 14.3888L18.4459 15.6287C18.0584 16.0222 17.4253 16.0271 17.0317 15.6397C16.6382 15.2522 16.6333 14.619 17.0208 14.2255L18.2633 12.9635L17.0208 11.7016C16.6333 11.3081 16.6382 10.6749 17.0317 10.2874ZM15.8 19.8542C14.7479 19.8542 13.8203 20.4336 13.3196 21.3126C13.0463 21.7925 12.4356 21.96 11.9557 21.6867C11.4758 21.4133 11.3084 20.8027 11.5817 20.3228C12.4181 18.8542 13.9898 17.8542 15.8 17.8542C17.6103 17.8542 19.1819 18.8542 20.0183 20.3228C20.2916 20.8027 20.1242 21.4133 19.6443 21.6867C19.1644 21.96 18.5538 21.7925 18.2804 21.3126C17.7798 20.4336 16.8522 19.8542 15.8 19.8542Z"
          fill="#FF5252"
        />
      </svg>
      <svg
        v-else
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16Z"
          fill="#348BF6"
        />
        <path
          d="M15.9999 13.0921C16.9536 13.0921 17.7295 13.865 17.7295 14.8217V21.74C17.7295 22.6938 16.9565 23.4696 15.9999 23.4696C15.0461 23.4696 14.2703 22.6967 14.2703 21.74V14.8217C14.2703 13.865 15.0461 13.0921 15.9999 13.0921Z"
          fill="white"
        />
        <path
          d="M15.9999 11.9925C16.9551 11.9925 17.7295 11.2181 17.7295 10.2629C17.7295 9.3077 16.9551 8.53333 15.9999 8.53333C15.0446 8.53333 14.2703 9.3077 14.2703 10.2629C14.2703 11.2181 15.0446 11.9925 15.9999 11.9925Z"
          fill="white"
        />
      </svg>
    </div>
    <div class="tw-flex-1">
      <div
        v-if="item.title"
        class="notify-title"
      >
        {{ item.title }}
      </div>
      <div
        v-if="item.desc"
        class="notify-desc"
        v-html="$Global.nl2br(item.desc)"
      />
      <div
        v-if="item.link || item['link-object']"
        class="tw-mt-[5px] tw-flex tw-items-center"
      >
        <div
          v-if="item.link"
          class="notify-link"
        >
          Ссылка:
          <a
            target="_blank"
            :href="item.link"
          >открыть в новой вкладке</a>
        </div>
        <button
          v-if="item['link-object']"
          class="tw-ml-auto tw-font-normal tw-text-main-xs tw-bg-an-flat-blue-btn tw-text-white tw-px-[6px] tw-py-[2px] tw-rounded-full"
          @click="openNotifyObject"
        >
          открыть
        </button>
      </div>
    </div>
    <div class="tw-mb-auto tw-mt-[7px]">
      <button
        title="Закрыть"
        class="notify-close-btn"
        @click.prevent="hide"
      >
        <svg
          width="19"
          height="18"
          viewBox="0 0 19 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.5 0.09375C4.58968 0.09375 0.59375 4.08968 0.59375 9C0.59375 13.9103 4.58968 17.9062 9.5 17.9062C14.4103 17.9062 18.4062 13.9103 18.4062 9C18.4062 4.08968 14.4103 0.09375 9.5 0.09375ZM12.7894 11.0662C13.1219 11.4047 13.1219 11.9509 12.7894 12.2894C12.6172 12.4556 12.3975 12.5387 12.1778 12.5387C11.9581 12.5387 11.7385 12.4556 11.5662 12.2894L9.5 10.2172L7.43376 12.2894C7.26155 12.4556 7.04187 12.5387 6.82218 12.5387C6.60249 12.5387 6.38281 12.4556 6.21064 12.2894C5.87814 11.9509 5.87814 11.4047 6.21064 11.0662L8.28282 9L6.21064 6.93373C5.87814 6.59532 5.87814 6.04904 6.21064 5.7106C6.54908 5.3781 7.09532 5.3781 7.43376 5.7106L9.5 7.78282L11.5662 5.7106C11.9047 5.3781 12.4509 5.3781 12.7894 5.7106C13.1219 6.04904 13.1219 6.59532 12.7894 6.93373L10.7172 9L12.7894 11.0662Z"
            fill="currentColor"
          />
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'

export default {
  name: 'NoficationItem',
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  mixins: [ViewPanelShowMixin],
  methods: {
    openNotifyObject () {
      console.log('OPEN', this.item['link-object'], this.typeResistanceObj)
      const targetEntityObj = this.typeResistanceObj?.[this.item['link-object']?.entity]
      if (targetEntityObj) {
        targetEntityObj.method({ id: this.item['link-object'].id })
      }
    },
    hide () {
      this.$emit('hide', this.item)
    }
  },
  mounted () {
    if (this.item?.timeout && this.item.timeout > 0) {
      setTimeout(() => {
        this.hide()
      }, this.item.timeout)
    }
  }
}
</script>

<style scoped lang="scss">
.notify__item {
  pointer-events: auto;
  @apply tw-bg-white tw-text-an-flat-black-table tw-pr-[16px] tw-py-[15px] tw-pl-[14px]
  tw-w-[450px] tw-mr-[1em] tw-ml-[1em] tw-my-[6px] tw-rounded-[20px]
  tw-flex tw-flex-nowrap tw-justify-start tw-items-center tw-gap-[8px]
}
.notify__item--success {
  box-shadow: 0px 0px 8px 0px #A2ABB547;
}
.notify__item--warning {
  box-shadow: 0px 0px 8px 0px #A2ABB547;
}
.notify__item--danger {
  box-shadow: 0px 0px 8px 0px #A2ABB547;
}
.notify__item--error {
  box-shadow: 0px 0px 8px 0px #FF525280;
  background-color: #FF5252;
  color: white;
  .notify-link {
    a {
      @apply tw-text-white
    }
    @apply tw-font-normal tw-text-[12px] tw-leading-[14px] tw-text-white
  }
  .notify-close-btn {
    @apply tw-rounded-full tw-text-white/80 hover:tw-text-white
  }
}
.notify__item--info {
  box-shadow: 0px 0px 8px 0px #A2ABB547;
}
.notify-title {
  @apply tw-font-bold tw-text-[14px] tw-leading-[20px] tw-flex tw-justify-between
}
.notify-desc {
  @apply tw-font-normal tw-text-[14px] tw-leading-[16px]
}
.notify-link {
  a {
    @apply tw-underline hover:tw-no-underline
  }
  @apply tw-font-normal tw-text-[12px] tw-leading-[14px] tw-text-an-flat-disabled-text tw-mt-[6px]
}
.notify-close-btn {
  @apply tw-rounded-full tw-text-[#C5CBD1] hover:tw-text-an-flat-red-main
}
</style>
