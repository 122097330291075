<template>
  <div>
    <div
      v-if="processing"
      class="tw-flex tw-p-[20px]"
    >
      <TwCircleLoader class="tw-m-auto" />
    </div>
    <div v-else>
      <div class="tw-mt-[10px] tw-flex tw-justify-end">
        <TwPanelActionButton
          color="blue"
          :disabled="processing"
          @click.prevent="fetchDisplayData"
        >
          Обновить
        </TwPanelActionButton>
      </div>
      <div v-if="Array.isArray(data) && data.length">
        <div
          v-for="(item, index) in data"
          :key="index"
          class="tw-mt-[20px]"
        >
          <!-- Building Header -->
          <div class="tw-text-h4">
            <span v-if="item.complex">
              <span class="tw-text-an-flat-gray-4">ЖК:</span> {{ item.complex }}
            </span>
            <span v-if="item.building">
              <span class="tw-text-an-flat-gray-4">Строение:</span> {{ item.building }}
            </span>
          </div>
          <!-- Building apartments List -->
          <div class="tw-mt-[10px]">
            <div>
              <div class="tw-grid tw-grid-cols-6 tw-border-b tw-text-main-sm2 tw-font-semibold">
                <div class="tw-p-[7px]">
                  Номер
                </div>
                <div class="tw-p-[7px]">
                  Статус
                </div>
                <div class="tw-p-[7px]">
                  Цена, руб.
                </div>
                <div class="tw-p-[7px]">
                  Кол-во комнат
                </div>
                <div class="tw-p-[7px]">
                  Этаж
                </div>
                <div class="tw-p-[7px]">
                  Площадь, кв.м.
                </div>
              </div>
            </div>
            <div class="tw-h-[300px] tw-overflow-auto">
              <div
                v-for="ap in item.apartments"
                :key="ap.id"
                class="tw-border-b odd:tw-bg-an-flat-bg-main even:tw-bg-white tw-grid tw-grid-cols-6"
              >
                <div class="tw-p-[7px]">
                  {{ ap.num || '-' }}
                </div>
                <div class="tw-p-[7px]">
                  {{ statusDict[ap.status] }}
                </div>
                <div class="tw-p-[7px]">
                  {{ (ap.price || 0).toLocaleString() }}
                </div>
                <div class="tw-p-[7px]">
                  {{ ap.roomCount || '-' }}
                </div>
                <div class="tw-p-[7px]">
                  {{ ap.floor || '-' }}
                </div>
                <div class="tw-p-[7px]">
                  {{ ap.area || '-' }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="tw-text-center tw-text-an-flat-disabled-text tw-p-[30px]"
      >
        Нет данных
      </div>
      <div
        v-if="errorData"
        class="tw-text-an-flat-red-main tw-p-[10px]"
      >
        <b>Ошибка сервера:</b>
        <pre>
        {{ errorData }}
        </pre>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { API } from '@/Api/BuilderImporter'
import TwPanelActionButton from '@/components/tw-ui/panels/TwPanelActionButton.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'

export default {
  name: 'BuilderSiteLoaderDisplayBlock',
  components: { TwCircleLoader, TwPanelActionButton },
  props: {
    info: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      statusDict: {
        sale: 'В продаже',
        sold: 'Продано',
        reserved: 'Зарезервировано'
      },
      processing: false,
      data: [],
      errorData: null
    }
  },
  computed: {
    builderSiteId () {
      return this?.info?.id || null
    }
  },
  mounted () {
    this.fetchDisplayData()
  },
  methods: {
    fetchDisplayData () {
      this.processing = true
      this.errorData = null
      API.importBuilderSiteLoader.display(this.info.id, {
        fileId: this.builderSiteId
      }).then(({ data }) => {
        console.warn('fetchDisplayData', data)
        this.$set(this, 'data', data?.data || [])
      }).catch((error) => {
        this.$set(this, 'data', [])
        this.errorData = error?.response?.data || null
      }).finally(() => {
        this.processing = false
      })
    }
  }
}
</script>

<style scoped>

</style>
