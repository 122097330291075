import { Module } from 'vuex'
import { RootState } from '@/store/types'

import { SOCKET_EVENT_MAP } from '@/plugins/SocketIoConfig'
import store from '@/store'

const namespaced = true

export const lead: Module<{ createdLeadsCount: number }, RootState> = {
  namespaced,
  state: {
    createdLeadsCount: 0
  },
  actions: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    [`SOCKET_${SOCKET_EVENT_MAP.IN.leadСreated}`]: function ({ state, commit }, message) {
      commit('SET_LEADS_COUNT', state.createdLeadsCount++)
      store.commit('notify/ADD', {
        title: message.title,
        desc: `Лид #${message.id}`,
        type: 'info',
        timeout: 10000,
        visible: true
      })
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    [`SOCKET_${SOCKET_EVENT_MAP.IN.leadStatusChanged}`]: function ({ state }, message) {
      store.commit('notify/ADD', {
        title: message.title,
        desc: `Лид #${message.id} - ${message.status.name}`,
        type: 'info',
        timeout: 10000,
        visible: true
      })
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    [`SOCKET_${SOCKET_EVENT_MAP.IN.leadResponsibleСhanged}`]: function ({ state }, message) {
      store.commit('notify/ADD', {
        title: message.title,
        desc: `Лид #${message.id} - ${message.status.name}`,
        type: 'info',
        timeout: 10000,
        visible: true
      })
    }
  },
  mutations: {
    SET_LEADS_COUNT (state, newCount = 0) {
      state.createdLeadsCount = newCount
    }
  },
  getters: {
    createdLeadsCount: (state) => {
      return state.createdLeadsCount
    }
  }
}
