<template>
  <div
    v-cloak
    v-if="info"
    :class="wrapperClasses"
  >
    <div class="tw-flex tw-flex-col tw-gap-[12px]">
      <TwPanelContentBlockFieldView>
        <template #title>
          Период
        </template>
        <div v-if="info.year && info.month">
          {{ info.month }} / {{ info.year }}
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView>
        <template #title>
          Застройщик
        </template>
        <div v-if="info.company">
          <ViewPanelLink
            :id="info.company"
            label="Застройщик"
            component="employee-show"
          >
            <TwCompanyName :id="info.company" />
          </ViewPanelLink>
        </div>
      </TwPanelContentBlockFieldView>
    </div>
    <div class="tw-flex tw-flex-col tw-gap-[12px]">
      <TwPanelContentBlockFieldView>
        <template #title>
          Сумма комиссии
        </template>
        <div>
          {{ (info.amount || 0).toLocaleString('ru') }}  ₽
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView>
        <template #title>
          Сумма комиссии в Битриксе
        </template>
        <div>
          {{ (info.amountBx || 0).toLocaleString('ru') }}  ₽
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView>
        <template #title>
          Итого комиссия
        </template>
        <div v-if="info.blocked">
          {{ (info.amountTotal || 0).toLocaleString('ru') }}  ₽
        </div>
        <div
          v-else
          class="tw-text-an-flat-gray-4"
        >
          период не закрыт
        </div>
      </TwPanelContentBlockFieldView>
    </div>
    <!--    <TwPanelContentBlockFieldView>-->
    <!--      <template v-slot:title>-->
    <!--        Дата создания-->
    <!--      </template>-->
    <!--      <div>-->
    <!--        {{$Global.DateFormat.DateTimeShort(info.createdAt)}}-->
    <!--      </div>-->
    <!--    </TwPanelContentBlockFieldView>-->
  </div>
</template>

<script>
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink.vue'
import TwCompanyName from '@/components/tw-ui/other/TwCompanyName.vue'
import { GridWrapperForPanelShowAndForm } from '@/utils/tw-classes'

export default {
  name: 'CompanyPeriodResultManagementMainShow',
  components: { TwCompanyName, ViewPanelLink, TwPanelContentBlockFieldView },
  props: {
    info: {},
    dictionary: {}
  },
  data () {
    return {
      wrapperClasses: GridWrapperForPanelShowAndForm.threeCols
    }
  },
  methods: {
    refreshParent (id) {
      console.warn(this.$options.name, ' refreshParent ', id)
      this.$emit('refresh')
    }
  }
}
</script>

<style scoped>

</style>
