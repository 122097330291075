import { TBaseTableHeaderItem, tableItemStyleOneGen } from '@/components/tw-ui/table/TableTypes'

const options: TBaseTableHeaderItem[] = [
  {
    ...tableItemStyleOneGen(),
    text: 'Коттеджный поселок',
    dataKey: 'complex',
    entity: 'complex'
  },
  {
    ...tableItemStyleOneGen(),
    text: 'Номер участка',
    dataKey: 'landNumber'
  },
  {
    ...tableItemStyleOneGen(),
    text: 'Номер дома',
    dataKey: 'houseNumber'
  },
  {
    ...tableItemStyleOneGen(),
    text: 'Площадь участка',
    dataKey: 'landArea'
  },
  {
    ...tableItemStyleOneGen(),
    text: 'Площадь дома',
    dataKey: 'houseArea'
  },
  {
    ...tableItemStyleOneGen(),
    text: 'Дата готовности',
    dataKey: 'completedDate'
  },
  {
    ...tableItemStyleOneGen(),
    text: 'Тип объекта',
    dataKey: 'types'
  },
  {
    ...tableItemStyleOneGen(),
    text: 'Примечание',
    dataKey: 'comment'
  },
  {
    ...tableItemStyleOneGen(),
    text: 'Цена',
    dataKey: 'price'
  },
  {
    ...tableItemStyleOneGen(),
    text: 'Цена за м²/сотку',
    dataKey: 'pricePerUnit'
  },
  {
    ...tableItemStyleOneGen(),
    text: 'С подрядом',
    dataKey: 'withContract'
  }
]

export default options
