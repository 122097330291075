import { AxiosResponse } from 'axios'

import qs from 'qs'
import HTTP, { downloadFile } from '@/Api/http'

const path = '/dictionary/document'

export default {
  getList (param?: object): Promise<AxiosResponse> {
    const $config = {
      params: param
    }
    return HTTP.get(`${path}`, $config)
  },
  create (): Promise<AxiosResponse> {
    const $config = {}
    return HTTP.get(`${path}/create`, $config)
  },
  store (data: object): Promise<AxiosResponse> {
    return HTTP.post(`${path}`, data)
  },
  edit (id: number): Promise<AxiosResponse> {
    const $config = {}

    return HTTP.get(`${path}/${id}`, $config)
  },
  update (id: number, data: object): Promise<AxiosResponse> {
    return HTTP.put(`${path}/${id}`, data)
  },
  show (id: number): Promise<AxiosResponse> {
    const $config = {}
    return HTTP.get(`${path}/${id}`, $config)
  },
  download (url: string, fileName: string) {
    downloadFile(url, fileName)
  },
  downloadPublicFileForParams (url: string, params: any) {
    const queryParams = qs.stringify(params, {
      arrayFormat: 'brackets'
    })
    console.log({ url, queryParams })
    if (!url) return console.error('downloadPublicFileForParams BAD url: ', url)
    const fullUrl = url + queryParams
    downloadFile(fullUrl)
  },
  delete (id: number): Promise<AxiosResponse> {
    const $config = {}
    return HTTP.delete(`${path}/${id}`, $config)
  }
}
