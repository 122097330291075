import SeoPage from './SeoPage'
export const routeKey = 'mortgage'

export default {
  path: routeKey,
  name: `site-admin-${routeKey}`,
  meta: { name: 'Ипотека', icon: 'mortgage' },
  redirect: { name: 'SeoPage-list' },
  component: () => import('@/views/Site/Admin/Index.vue'),
  children: [
    SeoPage
  ]
}
