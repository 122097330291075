<template>
  <TwInputText
    v-model="localMaskedValue"
    type="text"
    :label="label"
    :placeholder="placeholder"
    :show-label="showLabel"
    :disable-error-border="disableErrorBorder"
    :persistent-label="persistentLabel"
    :disabled="disabled"
    :error-text="errorText"
    :dense="dense"
  >
    <template #append>
      <slot name="append" />
    </template>
    <template #prepend>
      <slot name="prepend" />
    </template>
  </TwInputText>
<!--  <div>-->
<!--    {{value}} - {{localMaskedValue}}-->
<!--  </div>-->
</template>

<script>
import IMask from 'imask' // DOC https://imask.js.org/guide.html?#getting-started
import TwInputText from '@/components/tw-ui/ui-kit/input/TwInputText.vue'

export default {
  name: 'TwInputBankCard',
  components: { TwInputText },
  props: {
    label: {
      type: String,
      default: 'Номер банковской карты'
    },
    placeholder: {
      type: String,
      default: '0000-0000-0000-0000'
    },
    value: {
      type: [Number, String],
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    disableErrorBorder: {
      type: Boolean,
      default: false
    },
    showLabel: {
      type: Boolean,
      default: false
    },
    persistentLabel: {
      type: Boolean,
      default: false
    },
    errorText: {
      type: [String, Array],
      default: null
    }
  },
  data () {
    return {
      maskedInstance: null
    }
  },
  computed: {
    localMaskedValue: {
      get () {
        return this.maskedInstance?.value || ''
      },
      set (val) {
        this.maskedInstance.value = val
        this.maskedInstance.updateValue()
        // console.warn(val, this.maskedInstance.value, this.maskedInstance.unmaskedValue, this.maskedInstance)
        this.$emit('input', this.maskedInstance.unmaskedValue)
      }
    }
  },
  watch: {
    value: {
      handler (newValue) {
        this.updateMaskedValue(newValue)
      },
      immediate: true
    }
  },
  mounted () {
    const element = this.$el.getElementsByTagName('input')[0]
    const maskOptions = {
      mask: '0000-0000-0000-0000',
      autofix: true,
      lazy: true // false - если нужно показать пустую маску
    }
    this.maskedInstance = IMask(element, maskOptions)
    this.$nextTick(() => {
      this.updateMaskedValue(this.value)
    })
  },
  methods: {
    updateMaskedValue (val) {
      if (this.maskedInstance) {
        this.localMaskedValue = val?.toString() || ''
      }
    }
  }
}
</script>
