<template>
  <TwPanelContent>
    <TwPanelContentBlock
      class="tw-flex-1 tw-mb-[6px]"
      no-data-text="Нет добавленых документов"
    >
      <template #title>
        <div>
          Набор загруженных документов клиента
        </div>
      </template>
      <template #action>
        <div :title="editDocIndex !== null ? 'Завершите редактирование других документов для создания нового' : ''">
          <button
            :disabled="editDocIndex !== null || loading"
            class="tw-font-manrope tw-font-bold tw-uppercase tw-text-main-xs tw-text-white tw-bg-an-flat-green-btn-bg tw-rounded-sm tw-whitespace-nowrap tw-px-[20px] tw-py-[6px] enabled:hover:tw-bg-an-flat-green-btn-bg-active enabled:hover:tw-shadow disabled:tw-opacity-50"
            @click="addNewDocument"
          >
            Добавить документ
          </button>
        </div>
      </template>
      <div
        v-if="loading"
        class="person-documents-expansion text-center my-5"
      >
        <v-progress-circular
          size="50"
          color="primary"
          indeterminate
        />
      </div>
      <div
        v-else-if="list.length"
        class="person-documents-expansion"
      >
        <v-expansion-panels
          v-model="panel"
          tile
          :readonly="false"
          multiple
        >
          <v-expansion-panel
            v-for="(item, index) in list"
            :key="index"
          >
            <v-expansion-panel-header
              :class="panel.includes(index) ? '' : 'white--text'"
              :color="panel.includes(index) ? 'blue-grey lighten-2' : 'blue-grey darken-2'"
            >
              <div class="font-weight-medium">
                {{ (item.type && item.type.label) || ('Новый документ') }}
                <span
                  v-if="!item.id"
                  class="text-right error--text font-weight-medium"
                >
                  (Не сохранён)
                </span>
              </div>
              <div
                class="text-right mr-3"
                title="Элемент редактируется"
              >
                <v-icon
                  v-if="editDocIndex === index"
                  color="warning"
                >
                  fa-exclamation-circle
                </v-icon>
              </div>
              <template #actions>
                <v-btn
                  icon
                  small
                >
                  <v-icon :color="panel.includes(index) ? '' : 'white'">
                    fa-angle-down
                  </v-icon>
                </v-btn>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pa-0">
              <div
                style="position:relative;height: 35px"
                class="pt-2"
                :title="editDocIndex !== null && editDocIndex !== index ? 'Завершите предыдущие изменения чтобы отредактировать этот документ' : ''"
              >
                <v-btn
                  style="position:absolute;right: 0"
                  small
                  text
                  :disabled="editDocIndex !== null && editDocIndex !== index"
                  :color="editDocIndex !== null && editDocIndex === index ? 'warning':'primary'"
                  @click="editDocument(index)"
                >
                  {{ editDocIndex !== null && editDocIndex === index ? 'отменить':'изменить' }}
                </v-btn>
              </div>
              <PersonDocumentShow
                v-if="editDocIndex !== index"
                :info="item"
              />
              <PersonDocumentForm
                v-else
                :id="item.id"
                :key="index+'- doc - ' + item.id"
                :exist-doc-types="existDocTypes"
                :person-id="personId"
                @create="getDocuments"
                @update="getDocuments"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </TwPanelContentBlock>
  </TwPanelContent>
</template>

<script>
import PersonDocumentForm from '@/views/Person/documents/PersonDocumentForm'
import API from '@/Api/Crm/Person/document'
import PersonDocumentShow from '@/views/Person/documents/PersonDocumentsShow'
import TwPanelContent from '@/components/tw-ui/panels/TwPanelContent.vue'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'

export default {
  name: 'PersonDocumentsTab',
  components: {
    TwPanelContentBlock,
    TwPanelContent,
    PersonDocumentShow,
    PersonDocumentForm
  },
  props: {
    personId: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      loading: false,
      list: [],
      panel: [],

      editDocIndex: null
    }
  },
  computed: {
    existDocTypes () {
      return (Array.isArray(this.list) && this.list.map(doc => doc?.type?.value)) || []
    }
  },
  watch: {
    personId (newValue, oldValue) {
      if (newValue && newValue !== oldValue) {
        this.getDocuments()
      }
    }
  },
  mounted () {
    this.getDocuments()
  },
  methods: {
    editDocument (index) {
      this.editDocIndex = this.editDocIndex !== index ? index : null
      console.log('editDocument', index, this.editDocIndex)
      if (!this.list[index]?.id) {
        this.list.splice(index, 1)
        this.panel = []
      }
    },
    addNewDocument () {
      this.list.unshift({ id: 0 })
      this.panel.push(0)
      this.editDocIndex = 0
    },
    getDocuments () {
      this.loading = true
      API.getListByPersonId({ personId: this.personId })
        .then(({ data }) => {
          console.warn(this.$options.name, data)
          this.list = data.data || []
          this.panel = []
        })
        .catch((error) => {
          console.error(this.$options.name, error)
        })
        .finally(() => {
          this.loading = false
          this.editDocIndex = null
        })
    }
  }
}
</script>

<style scoped>

</style>
