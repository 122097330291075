import { buildInitialHttp } from '@/Api/http'
import { AuthAxiosDefaultConfig } from '@/Api/DocCheck'

export const path = '/kontur/document'

export default {
  downloadDocument (id: number) {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    const link = document.createElement('a')
    link.href = `${buildInitialHttp($config).defaults.baseURL}${path}/${id}/download`
    link.target = '_blank'
    document.body.appendChild(link)
    link.click()
    // удаляю ссылку и чищу память
    document.body.removeChild(link)
  }
}
