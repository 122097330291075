<template>
  <div class="tw-flex tw-flex-col tw-flex-grow tw-overflow-y-auto tw-scrollbar tw-pl-3 tw-pr-5 tw-py-0">
    <TwSidebarMainMenuItem
      v-for="(menuItem, index) in menuList"
      :key="index"
      :item="menuItem"
      :index="index"
      nav-from="path"
      :is-mini-left-panel="isMiniLeftPanel"
    >
      <template #badge>
        <span
          v-if="menuItem.fakeBadgeCounter"
          class="tw-min-w-[30px] tw-max-h-[22px] tw-bg-an-flat-red-main tw-text-white tw-rounded-[4px] tw-text-[12px] tw-font-bold tw-py-1.5 tw-text-center tw-leading-none"
        >
          {{ menuItem.fakeBadgeCounter }}
        </span>
      </template>
    </TwSidebarMainMenuItem>
  </div>
</template>

<script>
import TwSidebarMainMenuItem from '@/components/tw-ui/menu/TwSidebarMainMenuItem.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'TwSidebarBottomMenu',
  components: { TwSidebarMainMenuItem },
  props: {
    isMiniLeftPanel: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'profile/isAdmin'
    }),
    menuList () {
      return [
        ...(this.isAdmin) ? [
          // {
          //   id: 'notify',
          //   icon: 'messenger-footer',
          //   name: 'Уведомления',
          //   path: '/notify',
          //   fakeBadgeCounter: 26
          // },
          {
            id: 'messenger',
            icon: 'messenger-footer',
            name: 'Мессенджер',
            path: '/messenger',
            fakeBadgeCounter: 0
          }
        ] : [],
        {
          id: 'support',
          icon: 'support',
          name: 'Техподдержка',
          path: '/support'
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>
