<template>
  <div
    v-if="loading"
    class="tw-text-center"
  >
    <TwCircleLoader />
  </div>
  <div v-else>
    <TwPanelContentBlock class="tw-mb-[6px]">
      <template #title>
        <div>История страхования объекта клиента</div>
      </template>
      <div v-if="Array.isArray(info) && info.length">
        <table class="tw-w-full tw-table-auto tw-border-collapse tw-border tw-border-slate-400">
          <thead>
            <tr>
              <th class="tw-p-3 tw-border tw-border-slate-300">
                Заявка
              </th>
              <th class="tw-p-3 tw-border tw-border-slate-300">
                Статус
              </th>
              <th class="tw-p-3 tw-border tw-border-slate-300">
                Компания
              </th>
              <th class="tw-p-3 tw-border tw-border-slate-300">
                Дата оплаты
              </th>
              <th class="tw-p-3 tw-border tw-border-slate-300">
                Сумма
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="h in info"
              :key="h.id"
            >
              <td class="tw-p-3 tw-border tw-border-slate-300 tw-text-center">
                {{ h.deal ? h.deal.id : '~' }}
              </td>
              <td class="tw-p-3 tw-border tw-border-slate-300">
                {{ h.status && h.status.label ? h.status.label : h.status }}
              </td>
              <td class="tw-p-3 tw-border tw-border-slate-300">
                <TwCompanyName
                  v-if="h.companyId"
                  :id="h.companyId"
                />
              </td>
              <td class="tw-p-3 tw-border tw-border-slate-300">
                {{ h.payDate ? $Global.DateFormat.DateShort(h.payDate):'' }}
              </td>
              <td class="tw-p-3 tw-border tw-border-slate-300 tw-text-right">
                {{ (h.insuranceSum || 0).toLocaleString('ru') }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        v-else
        class="tw-text-an-flat-disabled-text tw-text-center"
      >
        Нет данных
      </div>
    </TwPanelContentBlock>
  </div>
</template>

<script>
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import API from '@/Api/Crm/Deal/Insurance/Other'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import TwCompanyName from '@/components/tw-ui/other/TwCompanyName.vue'

export default {
  name: 'DealInsuranceHistoryBlock',
  components: {
    TwCompanyName,
    TwCircleLoader,
    TwPanelContentBlock
  },
  props: {
    dealData: {
      required: true
    }
  },
  data () {
    return {
      isEdit: false,
      loading: false,
      info: null,
      dictionary: null
    }
  },
  computed: {
    dealId () {
      return this.dealData?.id || null
    }
  },
  mounted () {
    this.refresh()
  },
  methods: {
    refresh () {
      this.isEdit = false
      this.fetchInfo()
    },
    fetchInfo () {
      this.loading = true
      API.getHistory(this.dealId)
        .then(({ data: result }) => {
          console.warn('fetchInfo', result)
          this.$set(this, 'info', result?.data ? result.data : null)
          this.$set(this, 'dictionary', result?.dictionary || null)
        })
        .catch((error) => {
          console.error('fetchInfo', error)
          this.$set(this, 'info', null)
          this.$set(this, 'dictionary', null)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
