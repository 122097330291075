<template>
  <v-dialog
    v-model="show"
    max-width="1000px"
    persistent
    :fullscreen="false"
    :scrollable="true"
    @keydown.esc="show = false"
  >
    <template #activator="{ on, attrs }">
      <div
        v-bind="attrs"
        v-on="on"
      >
        <slot name="trigger">
          <button :class="buttonClass">
            {{ title }}
          </button>
        </slot>
      </div>
    </template>

    <v-card>
      <v-card-title class="pa-0 ma-0">
        <v-toolbar
          elevation="0"
          height="50px"
          dark
          color="primary"
        >
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            dark
            @click="show = false"
          >
            <v-icon>fa-times</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-divider />
      <v-card-text
        class="py-3"
        style="height: calc(100vh - 50px)"
      >
        <div>
          <v-item-group
            v-model="selectedDocVariant"
            mandatory
          >
            <v-container fluid>
              <v-row>
                <v-col
                  v-for="(variant, index) in docVariants"
                  :key="index"
                  cols="12"
                  md="4"
                >
                  <v-item v-slot="{ active, toggle }">
                    <v-card
                      :color="active ? 'primary' : 'secondary'"
                      dark
                      @click="toggle"
                    >
                      <v-card-title class="pt-1 pb-0 px-2">
                        <small>
                          Вариант {{ index + 1 }}
                        </small>
                      </v-card-title>
                      <v-card-text class="white--text pt-0 pb-2 px-2">
                        <div
                          v-for="(person, pIndex) of variant"
                          :key="pIndex"
                        >
                          <small>
                            <strong>
                              {{ pIndex > 0 ? `Созаёмщик ${pIndex}:`:'Осн. заёмщик:' }}
                            </strong>
                          </small>
                          {{ $Global.peopleName(person, 'fio') }}
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-item>
                </v-col>
              </v-row>
            </v-container>
          </v-item-group>
        </div>
        <div>
          <v-data-table
            :height="height"
            fixed-header
            item-key="id"
            :loading="loading"
            :loading-text="loadingText"
            :no-data-text="noDataText"
            :no-results-text="noResultsText"
            :headers="columns"
            :items="data"
            :items-per-page="itemsPerPage"
            hide-default-footer
            :disable-sort="true"
            @dblclick:row="doubleRowClick"
          >
            <template #item.menu="{ item }">
              <v-btn
                title="Скачать"
                color="primary"
                small
                @click="download(item)"
              >
                <v-icon
                  left
                  small
                >
                  fa-download
                </v-icon> Скачать
              </v-btn>
            </template>
          </v-data-table>
          <DefaultListPagination
            class="text-center pt-2"
            :disabled="loading"
            :pagination="pagination"
            :page-count="pageCount"
            @change="changePagination"
          />
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import DefaultListPagination from '@/components/list/pagination/DefaultListPagination'
import DataListMixin from '@/mixins/DataListMixin'
import API from '@/Api/Crm/Dictionary/General/Document'
import { button } from '@/utils/tw-classes'

export default {
  name: 'DocumentListModal',
  components: { DefaultListPagination },
  mixins: [DataListMixin],
  props: {
    title: {
      type: String,
      default: 'Документы'
    },
    fullscreen: {
      type: Boolean,
      default: false
    },
    dealId: {
      type: [Number, String],
      default: 0
    },
    deal: {},
    dealProgramId: {
      type: Number,
      default: 0
    },
    entityId: {
      type: Number
    },
    section: {
      type: String,
      default: ''
    },
    subsection: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: '300px'
    }
  },
  data () {
    return {
      buttonClass: button.pageListHeaderCreate,
      selectedDocVariant: 0,
      show: false,
      defaultFilters: {
        active: true
      },
      API,
      columns: [
        {
          text: 'Название',
          value: 'name',
          width: 'auto'
        },
        {
          text: '',
          value: 'menu',
          width: '70px'
        }
      ]
    }
  },
  computed: {
    personsList () {
      return this.deal ? [this.deal?.person, ...this.deal?.otherPersons] : []
    },
    docVariants () {
      const origArr = this.personsList
      const variants = []
      for (const i in origArr) {
        const original = [...origArr]
        const removedItem = original.splice(i, 1)
        // console.log(removedItem, original)
        variants.push([...removedItem, ...original])
      }
      // console.warn({ variants })
      return variants
    }
  },
  watch: {
    show: {
      handler (newValue) {
        if (newValue) {
          this.fetch({
            section: this.section,
            subsection: this.subsection,
            entityId: this.entityId
          })
        }
      }
    }
  },
  methods: {
    closeModal () {
      this.show = false
    },
    download (item) {
      const personsIds = this.docVariants[this.selectedDocVariant].map(p => p.id)
      const params = {
        id: item.id,
        dealId: this.dealId || undefined,
        dealProgramId: this.dealProgramId || undefined,
        personId: personsIds[0] || undefined,
        person2Id: personsIds[1] || undefined,
        person3Id: personsIds[2] || undefined
      }
      const url = `${this.$store.getters.getApiUrl}dictionary/document/${item.id}/download?`
      this.API.downloadPublicFileForParams(url, params)
    },
    doubleRowClick (event, rowInfo) {
      console.log('doubleRowClick: ', this.$options.name)
      this.edit(rowInfo.item)
    },
    edit (item) {
      console.log('edit', item)
      this.$router.push({
        name: 'document-edit',
        params: { id: item.id }
      })
    },
    remove (item) {
      console.log('remove', item)
      this.API.delete(item.id).finally(() => {
        this.fetch()
      })
    }
  }
}
</script>

<style scoped>

</style>
