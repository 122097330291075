import { RouteKeys } from '@/router/types'
import dictPermissionRules from '@/Permissions/dictRules'

export default {
  path: `/${RouteKeys.lead}`,
  name: RouteKeys.lead,
  redirect: { name: `${RouteKeys.lead}-list` },
  meta: { name: 'Лиды', icon: 'lead', plannedKey: 'lead', ruleNamespace: dictPermissionRules.AppServicePermissionRulesCRMLeadsReadLeadRule },
  component: () => import('@/views/Lead/Index.vue'),
  children: [
    {
      path: 'page',
      name: `${RouteKeys.lead}-page`,
      redirect: { name: `${RouteKeys.lead}-list` },
      meta: { name: 'Лиды', icon: 'lead', hideInMenu: true },
      component: () => import('@/views/Lead/Index.vue'),
      props: true,
      children: [
        {
          path: 'list',
          name: `${RouteKeys.lead}-list`,
          meta: { name: 'Список лидов', icon: 'lead', hideInMenu: true, hText: 'Список лидов' },
          component: () => import('@/views/Lead/List.vue'),
          props: true
        },
        {
          path: 'kanban',
          name: `${RouteKeys.lead}-kanban`,
          meta: { name: 'Канбан лиды', icon: 'lead', hideInMenu: true, hText: 'Канбан лиды', toolbarNotSticky: true },
          component: () => import('@/views/Lead/kanban/LeadKanbanPage.vue'),
          props: true
        }
      ]
    },
    {
      path: ':id/edit',
      name: `${RouteKeys.lead}-edit`,
      meta: { name: 'Редактирование лида', icon: 'lead', hideInMenu: true },
      component: () => import('@/views/Lead/LeadCard.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    },
    {
      path: 'create',
      name: `${RouteKeys.lead}-create`,
      meta: { name: 'Добавление лида', icon: 'lead', hideInMenu: true },
      component: () => import('@/views/Lead/LeadCard.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    },
    {
      path: ':id',
      name: `${RouteKeys.lead}-show`,
      meta: { name: 'Просмотр лида', icon: 'lead', hideInMenu: true },
      component: () => import('@/views/Lead/LeadCard.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    }
  ]
}
