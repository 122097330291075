<template>
  <div
    v-cloak
    v-if="info"
    class="tw-flex tw-flex-col tw-gap-[22px]"
  >
    <TwPanelContentBlockFieldView :horizontal="false">
      <template #title>
        <span class="tw-text-[16px]">
          Банк
        </span>
      </template>
      <template #default>
        <div
          v-if="info.company"
          class="tw-text-[18px] tw-font-medium"
          :class="info.company.active ? '':'tw-text-an-flat-red-main'"
        >
          <ViewPanelLink
            :id="info.company.id"
            label="Компания"
            component="company-show"
          >
            {{ info.company.name }}
          </ViewPanelLink>
          <span v-if="!info.company.active">
            (неактивен)
          </span>
        </div>
        <div
          v-else
          class="text-muted"
        >
          отсутствует
        </div>
      </template>
    </TwPanelContentBlockFieldView>

    <TwPanelContentBlockFieldView :horizontal="false">
      <template #title>
        <span class="tw-block tw-text-[16px] tw-mb-[16px]">
          Требования
        </span>
      </template>
      <template #default>
        <div v-if="viewObjects && viewObjects.main">
          <div class="tw-flex tw-flex-col tw-gap-[16px]">
            <div
              v-for="(item, key) of viewObjects.main"
              :key="key"
              class="tw-flex tw-flex-col tw-gap-[8px] tw-py-[12px] tw-px-[14px] tw-border tw-border-solid tw-rounded-md"
            >
              <span class="tw-block tw-text-an-flat-disabled-text tw-text-[18px]">{{ key | fieldNameTranslateFilter('main') }}</span>
              <div class="tw-font-medium tw-text-[14px]">
                <span v-if="typeof item === 'boolean'">
                  {{ item ? 'Да' : 'Нет' }}
                </span>
                <span v-else-if="typeof item === 'number'">
                  {{ item }}
                </span>
                <span v-else-if="typeof item === 'string'">
                  {{ item }}
                </span>
                <template v-else-if="key === 'paymentType'">
                  <span v-if="item">
                    {{ item.label }}
                  </span>
                  <span v-else>
                    Не указано
                  </span>
                </template>
                <template v-else>
                  Нет
                </template>
              </div>
              <span
                v-if="fieldNameTranslateFilterRemark(key, item)"
                class="tw-block tw-text-an-flat-disabled-input-text tw-text-[14px] tw-italic"
              >
                * {{ fieldNameTranslateFilterRemark(key, item) }}
              </span>
            </div>
          </div>
        </div>
        <div v-else>
          не указаны
        </div>
      </template>
    </TwPanelContentBlockFieldView>
  </div>
</template>

<script>
import { fieldNameTranslateFilter } from '@/views/Mortgage/Requirement/fieldsDict'
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'

export default {
  name: 'RequirementMainShow',
  components: { TwPanelContentBlockFieldView, ViewPanelLink },
  filters: {
    fieldNameTranslateFilter
  },
  mixins: [ViewPanelShowMixin],
  props: {
    info: {},
    dictionary: {}
  },
  computed: {
    viewObjects () {
      const { company } = this.info
      const { experience, experienceLast, ageMin, ageMax, nationally, goodHistory, militaryId, rono, decreaseToCreditSum, decreaseLowerCreditSum, redemptionBankPledge, transactionBetweenRelatives, paymentType } = this.info
      return {
        company,
        main: { experience, experienceLast, ageMin, ageMax, nationally, goodHistory, militaryId, rono, decreaseToCreditSum, decreaseLowerCreditSum, redemptionBankPledge, transactionBetweenRelatives, paymentType }
      }
    }
  },
  methods: {
    fieldNameTranslateFilterRemark (fieldKey, value) {
      const remarks = fieldNameTranslateFilter(fieldKey, 'remark')
      if (remarks && remarks[value]) {
        return remarks[value]
      }
      return null
    }
  }
}
</script>
