<template>
  <div>
    <div
      v-if="errorText || label"
      class="tw-w-full tw-mb-[2px] tw-flex tw-items-center tw-justify-between"
    >
      <div class="tw-font-medium">
        {{ label }}
      </div>
      <div
        v-show="errorText"
        class="tw-text-right tw-text-[12px] tw-text-an-flat-red-main"
      >
        {{ errorText }}
      </div>
    </div>
    <quillEditor
      ref="myQuillEditor"
      :class="errorText ? 'tw-border tw-border-solid tw-border-an-flat-red-main' : ''"
      :content="content"
      :options="editorOption"
      :disabled="disabled"
      @change="onEditorChange($event)"
      @blur="onEditorBlur($event)"
      @focus="onEditorFocus($event)"
      @ready="onEditorReady($event)"
    />
  </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  [
    'blockquote'
    // 'code-block'
  ],

  // [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: 'ordered' }, { list: 'bullet' }],
  // [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
  [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
  // [{ direction: 'rtl' }], // text direction

  // [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],

  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  // [{ font: [] }],
  [{ align: [] }],
  ['link', 'image', 'video'],

  ['clean'] // remove formatting button
]

const toolbarShortOptions = [
  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: 'ordered' }, { list: 'bullet' }],
  ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  ['blockquote'],
  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ align: [] }],
  ['link'],
  ['clean'] // remove formatting button
]

export default {
  name: 'QuillEmailEditor',
  components: {
    quillEditor
  },
  props: {
    value: {},
    label: {
      type: String,
      default: ''
    },
    errorText: {
      type: String,
      default: ''
    },
    isShort: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    minHeight: {
      type: String
    },
    maxHeight: {
      type: String
    },
    placeholder: {
      type: String,
      default: 'Содержимое...'
    }
  },
  data () {
    return {
      content: '',
      editorOption: {
        modules: {
          toolbar: this.isShort ? toolbarShortOptions : toolbarOptions
        },
        debug: 'warn', // info
        placeholder: this.placeholder,
        readOnly: false,
        theme: 'snow'
      }
    }
  },
  computed: {
    editor () {
      return this.$refs.myQuillEditor.quill
    }
  },
  watch: {
    value (newValue) {
      this.content = newValue
    }
  },
  mounted () {
    // console.log('Current quill editor object', this.editor)
    this.$nextTick(() => {
      if (this.value) {
        this.content = this.value
      }
    })
    if (this.editor && this.editor.container) {
      // this.editor.container.style.minHeight = this.minHeight
      // this.editor.container.style.maxHeight = this.maxHeight
      this.editor.root.style.minHeight = this.minHeight
      this.editor.root.style.maxHeight = this.maxHeight
      this.editor.root.style.overflowY = 'scroll'
    }
  },
  methods: {
    onEditorBlur () {
      // console.log('editor blur!', quill)
    },
    onEditorFocus () {
      // console.log('editor focus!', quill)
    },
    onEditorReady () {
      // console.log('editor ready!', quill)
    },
    onEditorChange ({ html }) {
      // console.log('editor change!', quill, html, text)
      this.$emit('input', html)
    }
  }
}
</script>
