<template>
  <TwPanelContentBlock
    class="tw-mb-[6px]"
    no-data-text="Не указано"
  >
    <template #title>
      <div>
        Подтвержденая коммиссия
      </div>
    </template>
    <template #action>
      <TwPanelContentBlockActionButton
        :disabled="formProcessing"
        :type="hasEdit ? 'delete':'edit'"
        @click.prevent="hasEdit = !hasEdit"
      >
        {{ hasEdit ? 'отмена':'изменить' }}
      </TwPanelContentBlockActionButton>
    </template>
    <div
      v-if="info && !hasEdit"
      class="tw-flex tw-flex-col tw-gap-[12px]"
    >
      <TwPanelContentBlockFieldView>
        <template #title>
          Сумма подтвержденной коммиссии
        </template>
        <div>
          {{ (info.commission).toLocaleString('ru') }} руб.
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView no-data-text="Нет данных">
        <template #title>
          Дата изменения
        </template>
        <div>
          {{ $Global.DateFormat.DateTimeShortMonthLong(info.updatedAt || info.createdAt) }}
        </div>
      </TwPanelContentBlockFieldView>
    </div>
    <div
      v-else-if="hasEdit"
      class="tw-flex tw-flex-col tw-gap-[12px]"
    >
      <TwInputText
        :disabled="formProcessing"
        type="text"
        label="Сумма коммиссии"
        placeholder="Введите сумму"
        max-length="13"
        :persistent-label="true"
        :show-label="true"
        :dense="true"
        :value="$Global.inputFormatPrice.getStringSpaced(form.commission)"
        @input="form.commission = $Global.inputFormatPrice.getNumber($event)"
      >
        <template #append>
          руб.
        </template>
      </TwInputText>
      <div
        v-if="serverErrorMessage"
        class="tw-text-an-flat-red-main"
      >
        {{ serverErrorMessage }}
      </div>

      <TwCardSimpleFormSaveBlock
        :loading="formProcessing"
        :cancel-confirm="true"
        :is-sticky="false"
        :disable-success="formProcessing"
        :disable-cancel="formProcessing"
        :visible="true"
        :dense="true"
        @success="handleSubmit"
        @cancel="handleCancel"
      />
    </div>
  </TwPanelContentBlock>
</template>

<script>
import API from '@/Api/Accounting/BuyNb/Result/Confirm'
import TwCardSimpleFormSaveBlock from '@/components/tw-ui/forms/TwCardSimpleFormSaveBlock.vue'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwPanelContentBlockActionButton from '@/components/tw-ui/panels/TwPanelContentBlockActionButton.vue'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'
import TwInputText from '@/components/tw-ui/ui-kit/input/TwInputText.vue'

export default {
  components: {
    TwPanelContentBlockFieldView,
    TwPanelContentBlockActionButton,
    TwPanelContentBlock,
    TwCardSimpleFormSaveBlock,
    TwInputText
  },
  props: {
    resultId: {
      type: Number,
      required: true
    },
    info: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      hasEdit: false,
      formProcessing: false,
      form: {
        commission: this.info?.commission || null
      },
      serverErrorMessage: null
    }
  },
  methods: {
    refreshParent () {
      this.hasEdit = false
      this.$emit('refresh')
    },
    handleCancel () {
      this.$emit('cancel')
    },
    handleSubmit () {
      this.serverErrorMessage = null
      this.formProcessing = true
      const request = this.info?.id ? API.update : API.store
      request(this.resultId, this.form).then(() => {
        this.refreshParent()
      }).catch((error) => {
        this.serverErrorMessage = error.serverErrorMessage
      }).finally(() => {
        this.formProcessing = false
      })
    }
  }
}
</script>

<style scoped>

</style>
