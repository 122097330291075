<template>
  <div>
    <v-card-subtitle class="d-flex">
      Кредиты
      <v-spacer />
      <v-btn
        v-if="!isCreateNew && !editBlock"
        x-small
        text
        color="green"
        :disabled="disabled"
        @click="addNewCredit"
      >
        Добавить
      </v-btn>
    </v-card-subtitle>
    <v-divider />
    <v-card-text class="py-2">
      <div>
        <!-- Добавление нового кредита -->
        <PersonCreditForm
          v-if="editBlock && isCreateNew"
          ref="newCreditForm"
          class="pa-2"
          style="border: 1px dashed lightgrey; border-radius: 10px"
          :person-id="personId"
          dense-fields
          @create="refresh"
          @update="refresh"
          @cancel="refresh"
        />
        <template v-if="credits.length">
          <div
            v-for="(credit) in credits"
            :key="credit.id"
          >
            <div class="my-2 px-3 sub-white-bordered-block">
              <v-container
                v-cloak
                class="ma-0 pa-0"
                fluid
              >
                <v-row
                  class="item-hovered-visible-block"
                  no-gutters
                  align="center"
                  justify="center"
                >
                  <v-col>
                    <PersonCreditForm
                      v-if="editBlock && editCreditId === credit.id"
                      :id="credit.id"
                      ref="updateCreditForm"
                      class="ma-2 pa-2"
                      style="border: 1px dashed lightgrey; border-radius: 10px"
                      :person-id="personId"
                      dense-fields
                      @create="refresh"
                      @update="refresh"
                      @cancel="refresh"
                    />
                    <PersonCreditShow
                      v-else
                      :info="credit"
                    />
                  </v-col>
                  <v-col
                    v-if="!editBlock && !isCreateNew && !editCreditId"
                    cols="3"
                    class="item-hidden-block text-right"
                  >
                    <v-btn
                      block
                      class="my-2 font-weight-bold"
                      x-small
                      text
                      color="primary"
                      @click="onEditCredit(credit)"
                    >
                      Изменить
                    </v-btn>
                    <v-btn
                      block
                      class="my-2 font-weight-bold"
                      x-small
                      text
                      color="error"
                      @click="onDeleteCredit(credit)"
                    >
                      Удалить
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="text-muted text-center">
            Нет кредитов
          </div>
        </template>
      </div>
    </v-card-text>
  </div>
</template>

<script>
import API from '@/Api/Crm/Person/Credit/index'
import PersonCreditShow from '@/views/Person/hipotec/PersonCreditShow'
import PersonCreditForm from '@/views/Person/hipotec/PersonCreditForm'

export default {
  name: 'PersonCreditsBlock',
  components: { PersonCreditForm, PersonCreditShow },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    personInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      editBlock: false,
      editCreditId: null,
      isCreateNew: false
    }
  },
  computed: {
    credits () {
      return (this.personInfo && this.personInfo.credits) || []
    },
    personId () {
      return this.personInfo && this.personInfo.id
    }
  },
  methods: {
    refresh () {
      this.editBlock = false
      this.isCreateNew = false
      this.editCreditId = null
      this.$emit('refresh')
    },
    handleSubmit () {
      console.warn('handleSubmit')
      if (this.isCreateNew) {
        this.$refs.newCreditForm.$once('create', () => {
          this.refresh()
        })
        this.$refs.newCreditForm.handleSubmit()
      } else {
        const targetRef = this.$refs.updateCreditForm.find(credit => credit.id === this.editCreditId)
        targetRef.$once('update', () => {
          this.refresh()
        })
        if (targetRef) {
          targetRef.handleSubmit()
        }
      }
    },
    onEditCredit (credit) {
      this.editCreditId = credit.id
      this.editBlock = true
      this.$emit('onEditCredit', true)
    },
    onDeleteCredit (credit) {
      const isAllow = confirm('Вы уверены?')
      if (isAllow) {
        API.delete(credit.id).finally(() => {
          this.refresh()
        })
      }
    },
    addNewCredit () {
      console.warn('addNewCredit')
      this.isCreateNew = !this.isCreateNew
      this.editBlock = true
      this.$emit('onEditCredit', true)
      // скролл вниз после открытия интерфейса создания, чтобы блок сохранения не перекрывал поля - CRM3-1002
      this.$nextTick(() => {
        // Все панели
        const panelEls = document.getElementsByClassName('view-panel')
        // Последняя панель - тоесть текущая
        const targetPanelEl = panelEls[panelEls.length - 1]
        if (targetPanelEl) {
          targetPanelEl.scrollTop = targetPanelEl.scrollHeight
        }
      })
    }
  }
}
</script>
