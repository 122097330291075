<template>
  <TwDialog
    :value="open"
    @input="open = $event"
  >
    <template #header>
      <div class="tw-text-h4 tw-font-semibold">
        Опрос по сделке #{{ dealId }}
      </div>
    </template>
    <template #body>
      <div>
        <SurveyDealForm
          v-if="isEdit"
          :create-params="{ dealId }"
          @onCancel="open = false"
          @updated="refresh"
        />
        <div v-else>
          <SurveyDealShow :deal-id="dealId" />
        </div>
      </div>
    </template>
  </TwDialog>
</template>

<script>
import TwDialog from '@/components/tw-ui/modal/TwDialog.vue'
import SurveyDealForm from '@/views/Company/UserSurveys/Deal/SurveyDealForm.vue'
import SurveyDealShow from '@/views/Company/UserSurveys/Deal/SurveyDealShow.vue'

export default {
  name: 'SurveyDealViewModal',
  components: {
    SurveyDealShow,
    SurveyDealForm,
    TwDialog
  },
  data () {
    return {
      open: false,
      isEdit: false,
      dealId: null
    }
  },
  methods: {
    onOpenModal (dealId, isEdit = true) {
      console.warn('onOpenModal', dealId)
      this.$set(this, 'dealId', dealId)
      this.$set(this, 'isEdit', isEdit)
      this.open = true
    },
    refresh () {
      this.isEdit = false
      this.$emit('refresh')
    }
  }
}
</script>
