import Vue from 'vue'
import { Module } from 'vuex'
import { RootState } from '@/store/types'
import { TmpCacheState } from '../types'
import { API } from '@/Api/ObjectStorage'
import store from '@/store/index'

const namespaced = true

export const realty: Module<TmpCacheState, RootState> = {
  namespaced,
  state: {
    objectSelection: {},
    complex: {},
    builder: {}
  },
  actions: {
    // Застройщики
    fetchBuilderList: function ({ commit }, search: string) {
      return API.Builder.getList({ search, limit: 150 })
        .then(({ data }: any) => {
          // console.log('fetchBuilderList', data.data)
          commit('SET_BUILDER', data.data)
          return data?.data || []
        })
        .catch((error: any) => {
          console.error(error)
        })
    },
    fetchBuilderById: function ({ commit }, id: number | string) {
      commit('SET_BUILDER', { id })
      return API.Builder.show(id)
        .then(({ data }: any) => {
          // console.log('fetchBuilderById', data.data)
          commit('SET_BUILDER', data.data)
        })
        .catch((error: any) => {
          console.error(error)
          commit('SET_BUILDER', { id, hasError: true })
        })
    },
    // Комплексы
    fetchComplexList: function ({ commit }, search: string) {
      return API.Complex.getList({ search, limit: 150 })
        .then(({ data }: any) => {
          console.log('fetchComplexList', data.data)
          commit('SET_COMPLEX', data.data)
          return data?.data || []
        })
        .catch((error: any) => {
          console.error(error)
        })
    },
    fetchComplexById: function ({ commit }, id: number | string) {
      commit('SET_COMPLEX', { id })
      return API.Complex.showShort(id)
        .then(({ data }: any) => {
          // console.log('fetchComplexById', data.data)
          commit('SET_COMPLEX', data.data)
          return data.data
        })
        .catch((error: any) => {
          console.error(error)
          commit('SET_COMPLEX', { id, hasError: true })
        })
    },
    // Объекты из подбора
    fetchObjectSelectionById: function ({ commit }, id: string) {
      commit('SET_OBJECT_SELECTION', { id })
      return API.NbSelection.showObject(id)
        .then(({ data }: any) => {
          // console.log('fetchObjectSelectionById', data.data)
          commit('SET_OBJECT_SELECTION', data.data)
          return data.data
        })
        .catch((error: any) => {
          console.error(error)
          commit('SET_OBJECT_SELECTION', { id, hasError: true })
        })
    }
  },
  mutations: {
    SET_BUILDER (state, payload) {
      // console.log('SET_BUILDER', payload)
      // параметр времени запроса
      const reqTime = new Date().getTime()
      if (Array.isArray(payload)) {
        payload.forEach((item) => {
          item.rt = reqTime
          Vue.set(state.builder, item.id, item)
        })
      } else if (payload.id) {
        payload.rt = reqTime
        Vue.set(state.builder, payload.id, payload)
      } else {
        console.error('Необработанное условие при попытке добавить Builder в tmpCache')
      }
    },
    SET_COMPLEX (state, payload) {
      // console.log('SET_COMPLEX', payload)
      // параметр времени запроса
      const reqTime = new Date().getTime()
      if (Array.isArray(payload)) {
        payload.forEach((item) => {
          item.rt = reqTime
          Vue.set(state.complex, item.id, item)
        })
      } else if (payload.id) {
        payload.rt = reqTime
        Vue.set(state.complex, payload.id, payload)
      } else {
        console.error('Необработанное условие при попытке добавить Complex в tmpCache')
      }
    },
    SET_OBJECT_SELECTION (state, payload) {
      console.log('SET_OBJECT_SELECTION', payload)
      // параметр времени запроса
      const reqTime = new Date().getTime()
      if (Array.isArray(payload)) {
        payload.forEach((item) => {
          item.rt = reqTime
          Vue.set(state.objectSelection, item.id, item)
        })
      } else if (payload.id) {
        payload.rt = reqTime
        Vue.set(state.objectSelection, payload.id, payload)
      } else {
        console.error('Необработанное условие при попытке добавить ObjectSelection в tmpCache')
      }
    }
  },
  getters: {
    getBuilderById: (state) => (id: number) => {
      if (state.builder[id] === undefined) {
        store.dispatch('tmpCache/realty/fetchBuilderById', id)
      }
      return state.builder[id] || null
    },
    cachedBuilderList: (state) => {
      return Object.values(state.builder) || []
    },
    getComplexById: (state) => (id: number) => {
      if (state.complex[id] === undefined) {
        store.dispatch('tmpCache/realty/fetchComplexById', id)
      }
      return state.complex[id] || null
    },
    cachedComplexList: (state) => {
      return Object.values(state.complex) || []
    },
    getObjectSelectionById: (state) => (id: number) => {
      if (state.objectSelection[id] === undefined) {
        store.dispatch('tmpCache/realty/fetchObjectSelectionById', id)
      }
      return state.objectSelection[id] || null
    }
  }
}
