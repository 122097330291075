import { tableItemStyleOne, tableItemStyleOneGen, TBaseTableHeaderItem } from '@/components/tw-ui/table/TableTypes'

const options: TBaseTableHeaderItem[] = [
  {
    ...tableItemStyleOneGen(false),
    dataKey: 'id',
    text: 'ID',
    colStyle: 'width: 100px;'
  },
  {
    ...tableItemStyleOneGen(false),
    text: 'Тип сделки',
    dataKey: 'type'
  },
  {
    ...tableItemStyleOne,
    ...tableItemStyleOneGen(false),
    text: 'Название',
    dataKey: 'name',
    colStyle: 'min-width: 300px;'
  },
  {
    ...tableItemStyleOneGen(true),
    text: 'Статус',
    dataKey: 'status'
  },
  {
    ...tableItemStyleOneGen(false),
    text: 'Дата статуса',
    dataKey: 'statusChangeDate',
    colStyle: 'width: 120px;'
  },
  {
    ...tableItemStyleOneGen(false),
    text: 'Дела',
    dataKey: 'planned',
    colStyle: 'min-width: 200px;'
  },
  {
    ...tableItemStyleOneGen(false),
    text: 'Клиент',
    dataKey: 'person',
    colStyle: 'width: 250px;'
  },
  {
    ...tableItemStyleOneGen(false),
    text: 'Дата создания',
    dataKey: 'createdAt',
    colStyle: 'width: 150px;'
  }
]

export default options
