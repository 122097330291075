import { SERVICES } from '@/Api/Services'
import Dictionaries from './Dictionaries'
import DictionaryEnumsOptions from './Dictionaries/Options'
import Builder from './Builder'
import BuilderStock from './Builder/stock'
import BuilderNews from './Builder/news'
import BuilderProject from './Builder/project'
import Complex from './Complex'
import ComplexDescription from './Complex/Description'
import ComplexAdvantages from './Complex/Advantages'
import ComplexGallery from './Complex/Gallery'
import ConstructionProgress from './Complex/ConstructionProgress'
import ComplexDocuments from './Complex/Documents'
import RealtyObject from './Realty/Objects'
import File from './File'
import Fias from './Fias'
import ReformZkh from './ReformZkh'
import DictionariesType from './Dictionaries/Type'
import RealtyNewBuildings from './Realty/ComplexNewbuildings'
import SiteNewBuildings from './Site/NewBuildings/index'
import SiteNewBuildingsSeoPage from './Site/NewBuildings/SeoPage'
import SiteCatalogSeoPageResale from './Site/Catalog/SeoPageResale'
import SiteCatalogMetaPage from './Site/Catalog/PageMeta'
import ComplexPlatformId from './Ads/ComplexPlatformId'
import PlatformAccount from './Ads/PlatformAccount'
import DistributionText from './Ads/DistributionText'
import PlatformPackage from './Ads/PlatformPackage'
import Platform from './Ads/Platform'
import DaData from './DaData'
import DealAnalyze from './Deal/Analyze'
import NbSelection from './Realty/Selection/NewBuilding'
import NbLandHouseSelection from './Realty/Selection/LandHouse'
import ComplexHighlight from './Realty/Newbuildings/Highlight'
import Editor from './Realty/Editor/index'
import BuildingChess from './Realty/Newbuildings/BuildingChess'

export const AuthAxiosDefaultConfig: any = {
  serviceKey: SERVICES.OS.key
}

export const API: any = {
  Fias,
  ReformZkh,
  File,
  Dictionaries,
  DictionaryEnumsOptions,
  DictionariesType,
  RealtyObject,
  Builder,
  BuilderStock,
  BuilderNews,
  BuilderProject,
  Complex,
  ComplexDescription,
  ComplexAdvantages,
  ComplexGallery,
  ComplexDocuments,
  ConstructionProgress,
  RealtyNewBuildings,
  SiteNewBuildings,
  SiteNewBuildingsSeoPage,
  DaData,
  ComplexPlatformId,
  PlatformAccount,
  Platform,
  DistributionText,
  PlatformPackage,
  SiteCatalogMetaPage,
  SiteCatalogSeoPageResale,
  NbSelection,
  NbLandHouseSelection,
  DealAnalyze,
  ComplexHighlight,
  Editor,
  BuildingChess
}
