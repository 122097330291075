<template>
  <div class="item-wrapper mt-5">
    <div
      :style="{backgroundColor: listColors[variant].bg}"
      class="item-icon"
    >
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.51483 12.3477C7.70692 12.3477 7.02969 12.9524 7.02969 13.6738C7.02969 14.3953 7.70692 15 8.51483 15C9.29306 15 9.99999 14.3953 9.96433 13.7057C9.99999 12.9471 9.3287 12.3477 8.51483 12.3477Z"
          fill="white"
        />
        <path
          d="M8.14654 3.03744C7.43962 3.21781 7 3.79074 7 4.48567C7.03564 4.90475 7.06536 5.32915 7.101 5.74823C7.20199 7.345 7.30297 8.90994 7.40397 10.5067C7.43962 11.0478 7.90892 11.4403 8.51486 11.4403C9.1208 11.4403 9.59604 11.0212 9.62575 10.4749C9.62575 10.146 9.62574 9.84359 9.66139 9.50939C9.72673 8.48554 9.79803 7.4617 9.86337 6.43787C9.89901 5.77475 9.96436 5.11164 10 4.44854C10 4.20982 9.96436 3.99762 9.86337 3.78543C9.5604 3.19129 8.85347 2.88891 8.14654 3.03744Z"
          fill="white"
        />
      </svg>
    </div>
    <div
      v-if="item"
      class="card item-hovered-visible-block"
    >
      <div
        v-if="item"
        class="card-wrapper"
        style="width: 100%"
      >
        <div
          class="card-left"
          style="flex: auto"
        >
          <div class="card-header">
            <div class="card-header-info">
              <p class="card-title">
                <span>{{ item.attachEntity && item.attachEntity.label }}:</span>
              </p>
              <p class="card-time">
                {{ $Global.DateFormat.DateTimeShort(item.date) }}
              </p>
            </div>
          </div>
          <div class="card-notification-info">
            <div class="card-notification-text d-flex">
              <span class="mr-3">
                <ViewPanelLink
                  :id="item.attachEntityId"
                  label="Объект"
                  component="nb-selection-apartment-modal-show"
                >
                  Ссылка на объект {{ item.attachEntityId }}
                </ViewPanelLink>
              </span>
            </div>
            <!--            <EmployeeLinkBlock :userId="entityData.userId"/>-->
          </div>
        </div>
        <div class="card-right">
          <ChronologyPinned
            v-if="!(variant === 'other' && item.pin)"
            :id="item.id"
            :pin-status="item.pin"
            @refresh="$emit('refresh')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { listColors } from '@/components/core/Chronology/ListConfig'
import ChronologyPinned from '@/components/core/Chronology/ChronologyPinned'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink'

export default {
  name: 'ChronologyBookingView',
  components: { ViewPanelLink, ChronologyPinned },
  props: {
    item: {
      type: Object,
      required: true
    },
    variant: {
      type: String,
      required: true // other, plan, pin
    }
  },
  computed: {
    listColors () {
      return listColors
    },
    entityData () {
      return this.item?.attachEntityData || null
    },
    toStatus () {
      return this.entityData?.status?.name || null
    },
    fromStatus () {
      return this.entityData?.oldStatus?.name || null
    }
  }
}
</script>

<style scoped>

</style>
