import { RouteKeys } from '@/router/types'

export default {
  path: RouteKeys.document,
  name: RouteKeys.document,
  redirect: { name: `${RouteKeys.document}-list` },
  meta: { name: 'Документы', icon: 'common-documentation' },
  component: () => import('@/views/Dictionary/General/Document/Index.vue'),
  children: [
    {
      path: 'list',
      name: `${RouteKeys.document}-list`,
      meta: { name: 'Список документов', icon: 'common-documentation', hideInMenu: true },
      component: () => import('@/views/Dictionary/General/Document/List.vue')
    },
    {
      path: ':id/edit',
      name: `${RouteKeys.document}-edit`,
      meta: { name: 'Редактирование документа', icon: 'common-documentation', hideInMenu: true },
      component: () => import('@/views/Dictionary/General/Document/DocumentCard.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    },
    {
      path: 'create',
      name: `${RouteKeys.document}-create`,
      meta: { name: 'Добавление документа', icon: 'common-documentation', hideInMenu: true },
      component: () => import('@/views/Dictionary/General/Document/DocumentCard.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    },
    {
      path: ':id',
      name: `${RouteKeys.document}-show`,
      meta: { name: 'Просмотр документа', icon: 'common-documentation', hideInMenu: true },
      component: () => import('@/views/Dictionary/General/Document/DocumentCard.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    }
  ]
}
