<template>
  <ImageGallery
    v-if="Array.isArray(list) && list.length"
    ref="slider-image"
    :name="$options.name"
    show-navigation
    :loop="false"
    :centered-slides="true"
    :slides-per-view="1"
  >
    <template #slides>
      <div
        v-for="(item, index) in list"
        :key="index"
        class="swiper-slide tw-flex tw-flex-col"
        style="position:relative;"
      >
        <img
          height="400px"
          alt="изображение из галереи"
          class="tw-rounded-md"
          :src="item.url"
          @click.prevent="viewImages(index)"
        >
        <div class="tw-flex-1" />
        <div class="tw-p-[14px]">
          {{ item.description }}
        </div>
      </div>
    </template>
  </ImageGallery>
  <div
    v-else
    class="tw-text-an-flat-disabled-text tw-text-center tw-pb-[14px]"
  >
    Не добавлена
  </div>
</template>

<script>
import ImageGallery from '@/components/other/images/ImageGallery'
export default {
  name: 'RealtyComplexGalleryShow',
  components: { ImageGallery },
  props: {
    gallery: {
      default: null
    },
    dictionary: {}
  },
  computed: {
    list () {
      return this.gallery.map(item => {
        return {
          url: item?.file?.url || this.defaultImage,
          description: item.description || 'Нет описания слайда'
        }
      })
    },
    defaultImage () {
      return require('@/assets/images/no_image.svg')
    }
  },
  methods: {
    viewImages (startIndex) {
      console.warn('asdasdasd', startIndex, this.list)
      this.$store.dispatch('imageViewer/showImages', {
        files: this.list.map((item) => {
          return {
            path: item.url
          }
        }),
        startIndex: startIndex
      })
    }
  }
}
</script>
