<template>
  <div class="tw-flex tw-gap-[8px] tw-items-center">
    <slot name="left" />
    <svg
      v-if="value"
      :width="size"
      :height="size"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.75488 11.0371C1.75488 5.92848 5.89626 1.78711 11.0049 1.78711C16.1135 1.78711 20.2549 5.92848 20.2549 11.0371C20.2549 16.1457 16.1135 20.2871 11.0049 20.2871C5.89626 20.2871 1.75488 16.1457 1.75488 11.0371ZM11.0049 0.287109C5.06783 0.287109 0.254883 5.10005 0.254883 11.0371C0.254883 16.9742 5.06783 21.7871 11.0049 21.7871C16.942 21.7871 21.7549 16.9742 21.7549 11.0371C21.7549 5.10005 16.942 0.287109 11.0049 0.287109ZM15.0352 9.06741C15.3281 8.77455 15.3281 8.29967 15.0352 8.00677C14.7423 7.71388 14.2675 7.71388 13.9746 8.00677L9.50488 12.4764L8.03522 11.0068C7.74232 10.7139 7.26744 10.7139 6.97455 11.0068C6.68166 11.2997 6.68166 11.7745 6.97455 12.0674L8.97455 14.0674C9.26749 14.3603 9.74228 14.3603 10.0352 14.0674L15.0352 9.06741Z"
        fill="#22C17B"
      />
    </svg>
    <svg
      v-else
      :width="size"
      :height="size"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11 22C4.93436 22 0 17.0656 0 11C0 4.93436 4.93436 0 11 0C17.0656 0 22 4.93436 22 11C22 17.0656 17.0656 22 11 22ZM11 1.375C5.69252 1.375 1.375 5.69252 1.375 11C1.375 16.3075 5.69252 20.625 11 20.625C16.3075 20.625 20.625 16.3075 20.625 11C20.625 5.69252 16.3075 1.375 11 1.375ZM7.59741 15.0901C7.42134 15.0901 7.24527 15.0233 7.1115 14.8885C6.84294 14.6199 6.84294 14.1846 7.1115 13.916L10.0276 10.9998L7.11066 8.08316C6.84193 7.81461 6.84193 7.37921 7.11066 7.11066C7.37921 6.84193 7.81461 6.84193 8.08316 7.11066L11 10.0274L13.9177 7.10965C14.1864 6.84109 14.6218 6.84109 14.8904 7.10965C15.1589 7.3782 15.1589 7.8136 14.8904 8.08232L11.9726 11L14.8893 13.9168C15.1581 14.1854 15.1581 14.6208 14.8893 14.8893C14.7547 15.0233 14.5787 15.0901 14.4026 15.0901C14.2267 15.0901 14.0506 15.0233 13.9168 14.8885L11.0003 11.9723L8.084 14.8885C7.94838 15.0233 7.77248 15.0901 7.59741 15.0901Z"
        fill="#E63114"
      />
    </svg>
    <slot name="right" />
  </div>
</template>

<script>
export default {
  name: 'TwBooleanIcon',
  props: {
    value: {
      type: [Boolean, String, Number],
      default: false
    },
    size: {
      type: String,
      default: '22'
    }
  }
}
</script>

<style scoped>

</style>
