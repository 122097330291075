<template>
  <TwPanelContentBlock class="tw-mb-[6px]">
    <template #title>
      Список результатов по сделкам
    </template>
    <template #action>
      <TwPanelContentBlockActionButton
        type="edit"
        @click="refreshParent"
      >
        обновить список
      </TwPanelContentBlockActionButton>
    </template>
    <table class="tw-table tw-table-auto tw-w-full">
      <thead class="tw-border-b">
        <tr>
          <th class="tw-p-[7px] tw-text-left">
            Ответственный / ID заявки
          </th>
          <th class="tw-p-[7px] tw-text-left">
            Номер договора
          </th>
          <th class="tw-p-[7px] tw-text-center">
            Рассрочка
          </th>
          <th class="tw-p-[7px] tw-text-right">
            Комиссия, ₽
          </th>
          <th class="tw-p-[7px] tw-text-center">
            Расчёт
          </th>
          <th class="tw-p-[7px] tw-text-center">
            Акт
          </th>
          <th class="tw-p-[7px] tw-text-center">
            Оплата, ₽
          </th>
        </tr>
      </thead>
      <tbody v-if="info && Array.isArray(info.results) && info.results.length">
        <tr
          v-for="(item, index) in info.results"
          :key="index"
          class="tw-border-b odd:tw-bg-an-flat-bg-main even:tw-bg-white"
        >
          <td class="tw-p-[7px] tw-whitespace-nowrap tw-text-left">
            <ViewPanelLink
              v-if="item.deal && item.deal.responsibleUserId"
              :id="item.deal.responsibleUserId"
              label=""
              no-link-style
              component="employee-show"
            >
              <EmployeeName :id="item.deal.responsibleUserId" />
            </ViewPanelLink>
            <ViewPanelLink
              v-if="item.deal"
              :id="item.deal.id"
              class="tw-ml-3"
              component="deal-show"
            >
              #{{ item.deal.id }}
            </ViewPanelLink>
          </td>
          <td class="tw-p-[7px] tw-whitespace-nowrap tw-font-medium tw-text-left">
            {{ item.contractNum }}
          </td>
          <td class="tw-select-none tw-p-[7px] tw-whitespace-nowrap tw-text-center tw-font-medium">
            <TwCheckBox
              class="tw-mx-auto tw-justify-center"
              :value="item.partialPayment"
              :readonly="true"
              :radio="true"
              background-and-border-color="tw-bg-an-flat-blue-btn tw-border-an-flat-blue-btn"
              :disabled="formProcessing"
            />
          </td>
          <td class="tw-p-[7px] tw-whitespace-nowrap tw-text-right tw-font-medium">
            <div
              class="tw-flex tw-justify-end tw-items-center tw-gap-[6px]"
              :title="item.commissionConfirmed ? 'Комиссия подтверждена':'Комиссия не подтверждена'"
            >
              <div>
                {{ (item.commission || 0).toLocaleString('ru') }}
              </div>
              <TwBooleanIcon
                :value="item.commissionConfirmed"
                size="14"
              />
            </div>
          </td>
          <td class="tw-select-none tw-p-[7px] tw-whitespace-nowrap tw-text-center tw-font-medium">
            <TwCheckBox
              class="tw-mx-auto tw-justify-center"
              :value="!!(item.checkList && item.checkList.calc)"
              background-and-border-color="tw-bg-an-flat-blue-btn tw-border-an-flat-blue-btn"
              :disabled="formProcessing"
              @input="onShowConfirm(item, 'calc', $event)"
            />
          </td>
          <td class="tw-select-none tw-p-[7px] tw-whitespace-nowrap tw-text-center tw-font-medium">
            <TwCheckBox
              class="tw-mx-auto tw-justify-center"
              :value="!!(item.checkList && item.checkList.act)"
              background-and-border-color="tw-bg-an-flat-blue-btn tw-border-an-flat-blue-btn"
              :disabled="formProcessing"
              @input="onShowConfirm(item, 'act', $event)"
            />
          </td>
          <td class="tw-select-none tw-p-[7px] tw-whitespace-nowrap tw-text-center tw-font-medium">
            <TwCheckBox
              class="tw-mx-auto tw-justify-center"
              :value="!!(item.checkList && item.checkList.pay)"
              background-and-border-color="tw-bg-an-flat-green-btn-bg tw-border-an-flat-green-btn-bg"
              :disabled="formProcessing"
              @input="onShowConfirm(item, 'pay', $event)"
            />
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <th
            colspan="99"
            class="tw-p-[7px] tw-text-an-flat-disabled-text"
          >
            Нет данных
          </th>
        </tr>
      </tbody>
    </table>
    <div class="tw-mt-[12px] tw-text-right tw-text-main-md tw-font-medium">
      <div>
        <span class="tw-text-an-flat-gray-4">Итого комиссия: </span><strong>{{ (resultsTotalAll || 0).toLocaleString('ru') }}</strong>
        <div class="tw-text-an-flat-gray-4 tw-text-main-xs">
          в т.ч. из Битрикс: {{ (info.amountBx || 0).toLocaleString('ru') }}
        </div>
      </div>
      <div class="tw-mt-1">
        <span class="tw-text-an-flat-gray-4">Итого оплачено: </span>
        <strong :class="isAllSuccessfullyPaid ? 'tw-text-an-flat-green-btn-bg-active' : 'tw-text-an-flat-orange-warning'">{{ (resultsTotalPay || 0).toLocaleString('ru') }}</strong>
        <div
          v-if="!isAllSuccessfullyPaid"
          class="tw-text-an-flat-orange-warning"
        >
          <small>* Итоговая комиссия не совпадает с суммой принятых платежей!</small>
        </div>
      </div>
    </div>

    <!--  Подтверждение установки чекбокса  -->
    <TwDialog
      v-model="confirmDialog"
      max-width="400px"
    >
      <template #header>
        <div>
          <div class="tw-text-h4 tw-font-semibold">
            Подтверждение
          </div>
        </div>
      </template>
      <template #body>
        <div>
          <div
            v-if="(isAllowBasePermissions && targetChangedItem.checked) || isAllowAdminPermissions"
            class="tw-font-medium tw-text-an-flat-red-main"
          >
            <div>
              Отменить действие - невозможно!
            </div>
            <div class="tw-text-an-flat-blue-btn">
              Подтвердить выполнение этапа?
            </div>
          </div>
          <div
            v-else
            class="tw-font-medium tw-text-an-flat-red-main"
          >
            <div>
              Недостаточно прав доступа для изменений
            </div>
          </div>
          <div class="tw-mt-[15px] tw-flex tw-gap-[10px]">
            <button
              v-if="(isAllowBasePermissions && targetChangedItem.checked) || isAllowAdminPermissions"
              class="tw-grow tw-px-[15px] tw-py-[7px] tw-bg-white tw-rounded-sm tw-text-an-flat-blue-btn disabled:tw-opacity-50 enabled:hover:tw-bg-an-flat-green-btn-bg enabled:hover:tw-text-white"
              @click.prevent="updateCheckList"
            >
              Да
            </button>
            <button
              class="tw-flex-1 tw-w-[100px] tw-px-[15px] tw-py-[7px] tw-bg-an-flat-gray-4 tw-rounded-sm tw-text-white"
              @click.prevent="confirmDialog = false"
            >
              Отмена
            </button>
          </div>
        </div>
      </template>
    </TwDialog>
  </TwPanelContentBlock>
</template>

<script>
import API from '@/Api/Crm/Deal/BuyNb/ResultManagement'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink.vue'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwPanelContentBlockActionButton from '@/components/tw-ui/panels/TwPanelContentBlockActionButton.vue'
import EmployeeName from '@/components/other/employee/EmployeeName.vue'
import TwDialog from '@/components/tw-ui/modal/TwDialog.vue'
import TwCheckBox from '@/components/tw-ui/ui-kit/input/TwCheckBox.vue'
import TwBooleanIcon from '@/components/tw-ui/ui-kit/icons/TwBooleanIcon.vue'

export default {
  name: 'CompanyPeriodResultManagementResultsBlock',
  components: { TwBooleanIcon, TwCheckBox, TwDialog, EmployeeName, TwPanelContentBlockActionButton, TwPanelContentBlock, ViewPanelLink },
  props: {
    info: {},
    isAllowBasePermissions: {
      type: Boolean,
      default: false
    },
    isAllowAdminPermissions: {
      type: Boolean,
      default: false
    },
    dictionary: {}
  },
  data () {
    return {
      confirmDialog: false,
      formProcessing: false,
      targetChangedItem: null
    }
  },
  computed: {
    resultsTotalAll () {
      return Array.isArray(this.info.results) && this.info.results.length ? this.info.results.reduce((total, item) => {
        return (item?.commission || 0) + total
      }, 0) : 0
    },
    resultsTotalPay () {
      return Array.isArray(this.info.results) && this.info.results.length ? this.info.results.reduce((total, item) => {
        return item?.checkList?.pay ? (item?.commission || 0) + total : total
      }, 0) : 0
    },
    isAllSuccessfullyPaid () {
      return this.resultsTotalAll === this.resultsTotalPay
    }
  },
  watch: {
    confirmDialog (newValue) {
      if (!newValue) {
        this.targetChangedItem = null
      }
    }
  },
  methods: {
    onShowConfirm (targetItem, field, checked) {
      this.targetChangedItem = {
        id: targetItem.id,
        field,
        checked
      }
      this.confirmDialog = true
    },
    updateCheckList () {
      if (this.formProcessing) {
        return
      }
      API.updateCheckList(this.targetChangedItem.id, this.targetChangedItem)
        .then(({ data: result }) => {
          console.warn('updateCheckList', result)
          this.refreshParent()
        })
        .catch((error) => {
          // this.serverError = error?.response?.data
          console.error('updateCheckList', error)
        })
        .finally(() => {
          this.confirmDialog = false
          this.formProcessing = false
        })
    },
    refreshParent () {
      this.$emit('refresh')
    }
  }
}
</script>

<style scoped>

</style>
