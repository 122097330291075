<template>
  <div>
    <div
      v-if="!hideTitle"
      class="font-weight-medium"
    >
      Прикреплённые файлы:
    </div>
    <div
      v-if="Array.isArray(filesImages)"
      class="d-flex flex-wrap"
    >
      <div
        v-for="(file, f_index) in filesImages"
        :key="file.id"
        class="ma-1"
      >
        <v-img
          class="cursor-pointer"
          style="border: 1px solid lightgrey"
          :width="imageSize"
          :height="imageSize"
          :src="file.preview"
          @click="imageShow(f_index)"
        />
        <small
          v-if="file.size"
          class="text-muted"
        >
          {{ $Global.formatBytes(file.size) }}
        </small>
      </div>
    </div>
    <div
      v-if="Array.isArray(filesForDownloads)"
      class="d-flex flex-wrap"
    >
      <div
        v-for="(file) in filesForDownloads"
        :key="file.id"
      >
        <div
          class="d-flex align-center file-download-link pa-1"
          style="max-width: 190px;line-height: normal;overflow-wrap: anywhere"
          @click="download(file)"
        >
          <v-icon
            color="primary"
            class="ma-1"
            x-small
          >
            fa-file
          </v-icon>
          <small>
            {{ file.name }}
            <span
              v-if="file.size"
              class="text-muted text-no-wrap"
            >
              {{ $Global.formatBytes(file.size) }}
            </span>
          </small>
        </div>
      </div>
    </div>
    <small
      v-if="!filesImages.length && !filesForDownloads.length"
      class="text-muted"
    >Отсутствуют</small>
  </div>
</template>

<script>
import API from '@/Api/Crm/File'
import FileShowMixin from '@/mixins/FileShowMixin'

export default {
  name: 'EmailFilesAttached',
  mixins: [FileShowMixin],
  data () {
    return {
      API: API
    }
  }
}
</script>
