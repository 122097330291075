import { RouteKeys } from '@/router/types'

export default {
  path: `${RouteKeys['deal-status']}`,
  name: RouteKeys['deal-status'],
  redirect: { name: `${RouteKeys['deal-status']}-list` },
  meta: { name: 'Заявка - Статусы', icon: 'application-statuses' },
  component: () => import('@/views/Dictionary/Deal/Status/Index.vue'),
  children: [
    {
      path: 'list',
      name: `${RouteKeys['deal-status']}-list`,
      meta: { name: 'Список статусов для Заявок', icon: 'application-statuses', hideInMenu: true },
      component: () => import('@/views/Dictionary/Deal/Status/List.vue')
    },
    {
      path: ':id/edit',
      name: `${RouteKeys['deal-status']}-edit`,
      meta: { name: 'Редактирование статус Заявки', icon: 'application-statuses', hideInMenu: true },
      component: () => import('@/views/Dictionary/Deal/Status/Edit.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    },
    {
      path: 'create',
      name: `${RouteKeys['deal-status']}-create`,
      meta: { name: 'Добавление статус Заявки', icon: 'application-statuses', hideInMenu: true },
      component: () => import('@/views/Dictionary/Deal/Status/Edit.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    },
    {
      path: ':id',
      name: `${RouteKeys['deal-status']}-show`,
      meta: { name: 'Просмотр статуса Заявки', icon: 'application-statuses', hideInMenu: true },
      component: () => import('@/views/Dictionary/Deal/Status/Edit.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    }
  ]
}
