import { RouteKeys } from '@/router/types'
import dictPermissionRules from '@/Permissions/dictRules'

// import dealCommission from './deal-commission'
// dealCommission,
export default {
  path: `${RouteKeys['deal-commission']}`,
  name: RouteKeys['deal-commission'],
  redirect: { name: `${RouteKeys['deal-commission']}-list` },
  meta: { name: 'Оплата по договору', icon: 'accounting', ruleNamespace: dictPermissionRules.AppServicePermissionRulesCRMAccountingReadAccountingCommissionRule },
  component: () => import('@/views/Reports/Index.vue'),
  children: [
    {
      path: 'list',
      name: `${RouteKeys['deal-commission']}-list`,
      meta: { name: 'Оплата по договору', icon: 'report', hideInMenu: true },
      component: () => import('@/views/Reports/DealCommission/List.vue')
    },
    {
      path: ':id/edit',
      name: `${RouteKeys['deal-commission']}-edit`,
      meta: { name: 'Ред. запись оплата по договору', icon: 'report', hideInMenu: true },
      component: () => import('@/views/Reports/DealCommission/Edit.vue'),
      props: (route:any) => ({ id: route.params.id })
    },
    {
      path: 'create',
      name: `${RouteKeys['deal-commission']}-create`,
      meta: { name: 'Доб. запись оплата по договору', icon: 'report', hideInMenu: true },
      component: () => import('@/views/Reports/DealCommission/Edit.vue'),
      props: (route:any) => ({ id: route.params.id })
    },
    {
      path: ':id',
      name: `${RouteKeys['deal-commission']}-show`,
      meta: { name: 'Просм. запись оплата по договору', icon: 'report', hideInMenu: true },
      component: () => import('@/views/Reports/DealCommission/Edit.vue'),
      props: (route:any) => ({ id: route.params.id })
    }
  ]
}
