<template>
  <div class="tw-font-manrope tw-w-full">
    <div
      v-if="loading"
      class="tw-p-5 tw-text-center tw-m-auto"
    >
      <TwCircleLoader />
    </div>
    <div v-if="data && Array.isArray(objectList) && objectList.length">
      <RealtyComplexBuildingMapMarking
        :view-only="true"
        :disabled="true"
        :buildings-list="objectList"
        map-height="60vh"
        :value="[]"
        @polygon-click="clickToObject"
      />
      <div
        v-if="notPolygonsObjects.length"
        class="tw-p-5 tw-m-auto"
      >
        <div class="tw-font-semibold">
          Данные объекты не отмечены на карте:
        </div>
        <span
          v-for="(item, index) in notPolygonsObjects"
          :key="item.id"
          class="tw-text-an-flat-dark-blue-link tw-font-medium tw-cursor-pointer hover:tw-underline"
          @click.stop="clickToObject(item)"
        >
          {{ item.title }}<span v-if="index < notPolygonsObjects.length - 1">, </span>
        </span>
      </div>
    </div>
    <div
      v-else
      class="tw-p-5 tw-text-center tw-m-auto tw-text-an-flat-red-main"
    >
      Нет информации для отображения на карте
    </div>
  </div>
</template>

<script>
import { API } from '@/Api/ObjectStorage'
import RealtyComplexBuildingMapMarking
  from '@/views/Newbuildings/Editor/OtherComponents/Map/RealtyComplexBuildingMapMarking.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import { mapActions } from 'vuex'

export default {
  name: 'RealtyComplexBuildingsInMap',
  components: { TwCircleLoader, RealtyComplexBuildingMapMarking },
  props: {
    complexId: {
      type: [Number, String, null],
      default: null
    },
    complexType: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      API: API.RealtyNewBuildings,
      loading: false,
      permissionDenied: false,
      data: []
    }
  },
  methods: {
    ...mapActions({
      showPanel: 'viewPanels/showPanel'
    }),
    clickToObject (item) {
      this.showPanel({
        label: '',
        component: 'nb-editor-object',
        props: {
          id: item.id,
          type: item.type.value,
          complexId: this.complexId,
          presetTabEdit: true
        },
        callback: () => {
          console.warn(this.$options.name, '- showPanel')
          this.refresh()
        }
      })
    },
    // обновление списка объектов из локальных параметров компонента, с сохранением пагинации
    refresh () {
      this.fetch({
        page: 1
      })
    },
    fetch (params = {}) {
      if (this.loading) {
        return
      }

      if (!this.API) {
        throw Error('not set API')
      }

      this.data = []
      this.permissionDenied = false
      this.loading = true
      params.limit = 999

      this.API.objects(this.complexId, params)
        .then((response) => {
          this.data = response.data.data
          if (response.data.meta) {
            this.pagination = response.data.meta
          }
          // SENDING HOUSE DATA TO THE EDITOR
          this.$emit('updateComplexList', response.data.data)
        })
        .catch((error) => {
          // обработка отсутствия прав на просмотр
          if (error?.response?.status === 403) {
            this.permissionDenied = true
          }
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  computed: {
    objectList () {
      return Array.isArray(this.data) && this.data.length ? this.data.map((item) => {
        return {
          id: item.id,
          title: `${item.type?.label} №${item.addressNumber}`,
          type: item.type,
          mapPolygon: item.mapPolygon || []
        }
      }) : []
    },
    notPolygonsObjects () {
      return this.objectList.filter(item => !(Array.isArray(item.mapPolygon) && item.mapPolygon.length))
    }
  },
  mounted () {
    this.fetch()
  }
}
</script>
