<template>
  <div>
    <div style="display: none">
      {{ isEdited }}
    </div>
    <TwFlexCol>
      <TwFlexCell>
        <TwSelect
          v-model="form.reservationId"
          label="Объект Бронь"
          placeholder="Выбрать"
          :options="dictionary.reservation || []"
          :return-object="true"
          :dense="true"
          :error-text="$v.form.reservationId.$invalid ? '*Обязательное поле':''"
          :disabled="formProcessing"
          :persistent-label="true"
          @input-object="reservationChange"
        />
      </TwFlexCell>
      <TwFlexRow>
        <TwFlexCell>
          <TwInputText
            ref="objectPrice"
            type="text"
            placeholder="Стоимость объекта"
            max-length="13"
            :value="$Global.inputFormatPrice.getStringSpaced(form.objectPrice)"
            :disabled="formProcessing"
            :show-label="true"
            :dense="true"
            :error-text="$v.form.objectPrice.$invalid ? 'Заполните поле':''"
            @input="form.objectPrice = $Global.inputFormatPrice.getNumber($event)"
          >
            <template #append>
              руб.
            </template>
          </TwInputText>
        </TwFlexCell>
        <TwFlexCell>
          <TwInputText
            ref="dealDate"
            v-model="form.dealDate"
            type="date"
            placeholder="Дата сделки"
            :max="currentDate"
            :error-text="$v.form.dealDate.$invalid ? '*Обязательное поле':''"
            :disabled="formProcessing"
            :show-label="true"
            :dense="true"
            :persistent-label="true"
          />
        </TwFlexCell>
      </TwFlexRow>
      <TwFlexRow>
        <TwFlexCell>
          <TwInputText
            ref="contractNum"
            v-model="form.contractNum"
            type="text"
            placeholder="Номер договора"
            :max="currentDate"
            :error-text="$v.form.contractNum.$invalid ? '*Обязательное поле':''"
            :disabled="formProcessing"
            :dense="true"
            :show-label="true"
            :persistent-label="true"
          >
            <template #prepend>
              №
            </template>
          </TwInputText>
        </TwFlexCell>
        <TwFlexCell>
          <TwInputText
            ref="deadlinePayDDU"
            v-model="form.deadlinePayDDU"
            type="date"
            placeholder="Крайняя дата полной оплаты ДДУ"
            :min="currentDate"
            :error-text="$v.form.deadlinePayDDU.$invalid ? '*Обязательное поле':''"
            :disabled="formProcessing"
            :dense="true"
            :show-label="true"
            :persistent-label="true"
          />
        </TwFlexCell>
      </TwFlexRow>
      <TwFlexRow>
        <TwFlexCell>
          <TwSelect
            v-model="form.payMethod"
            label="Способ оплаты"
            placeholder="Выбрать"
            :options="dictionary.payMethod || []"
            :error-text="$v.form.payMethod.$invalid ? '*Обязательное поле':''"
            :disabled="formProcessing"
            :dense="true"
            :persistent-label="true"
          />
        </TwFlexCell>
        <TwFlexCell>
          <TwSelect
            v-model="form.purposePurchase"
            label="Цель приобретения"
            placeholder="Выбрать"
            :options="dictionary.purposePurchase || []"
            :error-text="$v.form.purposePurchase.$invalid ? '*Обязательное поле':''"
            :disabled="formProcessing"
            :dense="true"
            :persistent-label="true"
          />
        </TwFlexCell>
      </TwFlexRow>
      <TwFlexCell>
        <TwInputText
          ref="preliminaryCommission"
          type="text"
          placeholder="Предварительная комиссия"
          max-length="13"
          :value="$Global.inputFormatPrice.getStringSpaced(form.preliminaryCommission)"
          :disabled="formProcessing"
          :dense="true"
          :show-label="true"
          :error-text="$v.form.preliminaryCommission.$invalid ? 'Заполните поле':''"
          @input="form.preliminaryCommission = $Global.inputFormatPrice.getNumber($event)"
        >
          <template #append>
            руб.
          </template>
        </TwInputText>
      </TwFlexCell>
    </TwFlexCol>
    <div class="tw-my-[15px]">
      <div class="tw-mb-[15px] tw-font-semibold">
        Прикрепление файлов договора:
      </div>
      <TwUploadFilesList
        v-model="form.contractFileIds"
        label="Загрузить файлы ДДУ"
        no-data-text="Нет прикрепленнных файлов"
        :has-inline="true"
        :is-public="true"
        :multiple="true"
        :disabled="formProcessing"
      />
    </div>
    <TwCardSimpleFormSaveBlock
      :loading="formProcessing"
      bg-color="transparent"
      :is-sticky="false"
      :visible="true"
      @success="handleSubmit"
      @cancel="handleCancel"
    />
  </div>
</template>

<script>
import API from '@/Api/Crm/Deal/BuyNb/Result'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import { required } from 'vuelidate/lib/validators'
import TwInputText from '@/components/tw-ui/ui-kit/input/TwInputText.vue'
import TwSelect from '@/components/tw-ui/ui-kit/input/TwSelect.vue'
import TwUploadFilesList from '@/components/tw-ui/ui-kit/files/TwUploadFilesList.vue'
import TwCardSimpleFormSaveBlock from '@/components/tw-ui/forms/TwCardSimpleFormSaveBlock.vue'
import TwFlexCell from '@/components/tw-ui/ui-kit/flex-block/TwFlexCell.vue'
import TwFlexCol from '@/components/tw-ui/ui-kit/flex-block/TwFlexCol.vue'
import TwFlexRow from '@/components/tw-ui/ui-kit/flex-block/TwFlexRow.vue'

export default {
  name: 'DealBuyNbResultForm',
  components: { TwFlexRow, TwFlexCol, TwFlexCell, TwCardSimpleFormSaveBlock, TwUploadFilesList, TwSelect, TwInputText },
  mixins: [FormCreateEditMixin],
  props: {
    dealId: {
      type: Number,
      required: true
    },
    denseFields: {
      type: Boolean,
      default: false
    },
    disableRedirect: {
      type: Boolean,
      default: false
    },
    defaultFormFields: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      currentDate: new Date().toISOString().slice(0, 10),
      API: API,
      form: {
        id: null,
        dealId: null, // Заявка
        deadlinePayDDU: null, // Крайняя дата оплаты ДДУ клиентом
        dealDate: null, // Дата сделки
        dealType: null, // Объект выбран (тип заявки)
        objectPrice: 0, //  объекта
        preliminaryCommission: 0, // Предварительная Комиссия
        purposePurchase: null, // Цель приобретения
        payMethod: null, // Способ оплаты
        reservationId: null, // Объект ID
        contractNum: null, // Номер договора ДДУ, или ДКП
        objectData: null
      }
    }
  },
  validations: {
    form: {
      preliminaryCommission: {
        required: function (v) {
          return !!(v && !isNaN(parseFloat(v)) && parseFloat(v) && parseFloat(v) > 0)
        }
      },
      objectPrice: {
        required: function (v) {
          return !!(v && !isNaN(parseFloat(v)) && parseFloat(v) && parseFloat(v) > 0)
        }
      },
      payMethod: {
        required
      },
      purposePurchase: {
        required
      },
      dealDate: {
        required
      },
      dealType: {
        required
      },
      contractNum: {
        required
      },
      deadlinePayDDU: {
        required
      },
      reservationId: {
        required
      }
    }
  },
  created () {
    this.createQueryParams = {
      dealId: this.dealId
    }
  },
  methods: {
    reservationChange (value) {
      console.warn('reservationChange ', value)
      this.form.objectId = value?.objectId || null
      this.form.objectPrice = value?.confirmedPrice || 0
      this.form.preliminaryCommission = value?.preliminaryCommission || 0
    },
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          const adaperMap = {
            // from(show): to(send)
            deal: 'dealId'
          }
          for (const key in this.preset) {
            if (adaperMap[key] !== undefined) {
              if (adaperMap[key].indexOf('Id') !== -1) {
                console.warn('presetData ', this.preset[key] && this.preset[key].id, this.defaultFormFields[adaperMap[key]])
                this.$set(this.form, adaperMap[key], (this.preset[key] && this.preset[key].id) || this.defaultFormFields[adaperMap[key]])
              }
            } else {
              this.$set(this.form, key, this.preset[key])
            }
          }
        }
        resolve()
      })
    },
    handleResetCustom () {
      this.handleReset()
    },
    handleSubmit () {
      // console.warn(this.$options.name, ' handleSubmit ', this.formType, this.targetId)
      this.$v.$reset()
      this.$v.form.$touch()
      console.warn(this.$options.name, ' handleSubmit ', this.$v.form, this.$v.form.$invalid)
      if (!this.$v.form.$invalid) {
        if (this.formProcessing === true) {
          return
        }

        this.formProcessing = true
        this.serverErrors = null
        if (this.formType === 'create') {
          this.API.store(this.dealId, this.form)
            .then(({ data }) => {
              console.warn(this.$options.name, ' CREATE ', data)
              // this.fetchData(data.data.id)
              this.$emit('create', data.data)
            })
            .catch((error) => {
              if (error?.response?.data) {
                console.error(this.$options.name, error.response.data)
                for (const key in error.response.data) {
                  const [refKey, index] = key.split('.')
                  // console.log(key, refKey, index, field)
                  if (!this.serverErrors) this.serverErrors = {}
                  if (!this.serverErrors[refKey]) this.serverErrors[refKey] = []
                  this.serverErrors[refKey][index] = error.response.data[key][0]
                }
              }
            })
            .finally(() => {
              this.formProcessing = false
            })
        } else if (this.formType === 'edit' && this.targetId) {
          this.API.update(this.targetId, this.form)
            .then(({ data }) => {
              console.warn(this.$options.name, ' UPDATE ', data.data)
              this.$emit('update', data.data)
            })
            .catch((error) => {
              if (error?.response?.data) {
                console.error(this.$options.name, error.response.data)
                for (const key in error.response.data) {
                  const [refKey, index] = key.split('.')
                  // console.log(key, refKey, index, field)
                  if (!this.serverErrors) this.serverErrors = {}
                  if (!this.serverErrors[refKey]) this.serverErrors[refKey] = []
                  this.serverErrors[refKey][index] = error.response.data[key][0]
                }
              }
            })
            .finally(() => {
              this.formProcessing = false
            })
        }
      }
    }
  }
}
</script>
