<template>
  <section v-if="investAppealInfo">
    <h3 class="tw-text-an-flat-black-main tw-font-bold tw-text-[32px] tw-leading-[44px]">
      Инвестиционная привлекательность
      <div
        v-if="investAppealInfo.level"
        class="tw-text-an-flat-blue-btn  tw-text-h2 tw-mt-[11px]"
      >
        {{ investAppealInfo.level.label }}
      </div>
    </h3>
    <div
      class="tw-mt-[11px]"
      v-html="$Global.nl2br(investAppealInfo.comment)"
    />
  </section>
</template>

<script>
import complexInvestAppealAPI from '@/Api/ObjectStorage/Complex/InvestAppeal'

export default {
  name: 'PageComplexBlockInvestAppeal',
  props: {
    complexId: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      loading: false,
      investAppealInfo: null
    }
  },
  methods: {
    fetchInvestAppealInfo () {
      if (this.complexId) {
        this.loading = true
        complexInvestAppealAPI.showByComplexId(this.complexId).then(({ data: result }) => {
          this.investAppealInfo = result?.data
        }).catch(() => {
          this.investAppealInfo = null
        }).finally(() => {
          this.loading = false
        })
      }
    }
  },
  mounted () {
    this.fetchInvestAppealInfo()
  }
}
</script>

<style scoped>

</style>
