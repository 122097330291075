<template>
  <div class="tw-font-manrope tw-text-main-sm2 tw-max-w-[100%] tw-px-[26px] tw-py-[18px] tw-bg-white tw-min-h-screen">
    <template v-if="isCardContentVisible">
      <!--   ЗАГОЛОВОК   -->
      <TwPanelTitle>
        <template #left>
          <div class="tw-text-h4 tw-font-bold">
            <div
              v-if="isNew"
              class="title text-muted"
            >
              Новый лид
            </div>
            <div v-else-if="data">
              <span>{{ data.name }}</span>
              <span class="tw-text-an-flat-disabled-text tw-font-medium"> # {{ data.id }}</span>
            </div>
            <div v-else>
              ~
            </div>
          </div>
        </template>
        <template #right>
          <template v-if="!isNew">
            <TwPanelActionButton
              v-if="!isNew && data && !data.deal"
              color="gray"
              @click="showVariantSelection"
            >
              Провести подбор - ИПОТЕКА
            </TwPanelActionButton>
            <TwPanelActionButton
              v-if="!isNew && data && !data.deal"
              color="blue"
              @click="createDeal"
            >
              Создать заявку
            </TwPanelActionButton>
          </template>
          <!-- TODO debug permissions -->
          <MenuEntityActions
            v-if="permissions"
            :permissions="permissions"
            @delete="deleteCardItem"
          />
        </template>
      </TwPanelTitle>

      <!--   СТАТУС   -->
      <TwPanelContent :wrapper-classes="''">
        <leadStatusProgress
          v-if="data"
          ref="statusProgress"
          :current-status="data.status"
          :lead-id="data.id"
          :lead="data"
          :disabled="loading || !actions.update"
          class="tw-mb-[10px]"
          @changeStatus="changeStatus"
        />
      </TwPanelContent>

      <!--   ВКЛАДКИ   -->
      <TwPanelTabsFull
        v-model="currentTab"
        :items="tabList"
      >
        <template #content.main>
          <TwPanelContent>
            <div class="tw-flex-1">
              <TwPanelContentBlock
                v-if="data"
                class="tw-mb-[6px]"
              >
                <template #title>
                  <div>
                    O заявке
                  </div>
                </template>
                <TwPanelContentBlockFieldView
                  v-if="data && data.deal && data.deal.id"
                  :horizontal="false"
                >
                  <template #title>
                    <strong>
                      {{ data.deal.type && data.deal.type.label }}
                    </strong>
                  </template>
                  <template #subtitle>
                    <div
                      v-if="data.deal.createdAt"
                      class="tw-text-an-flat-black-main"
                    >
                      Создана: {{ $Global.DateFormat.DateTimeShortMonthLong(data.deal.createdAt) }}
                    </div>
                  </template>
                  <div>
                    <ViewPanelLink
                      :id="data.deal.id"
                      label="Заявка"
                      component="deal-show"
                    >
                      {{ data.deal.name }} #{{ data.deal.id }}
                    </ViewPanelLink>
                  </div>
                </TwPanelContentBlockFieldView>
                <div
                  v-else
                  class="tw-text-an-flat-disabled-text"
                >
                  Заявка не создана
                </div>
              </TwPanelContentBlock>
              <TwPanelContentBlock class="tw-mb-[6px]">
                <template #title>
                  <div>
                    О лиде
                  </div>
                </template>
                <template
                  v-if="permissions && permissions.entity && permissions.entity.update"
                  #action
                >
                  <TwPanelContentBlockActionButton
                    :disabled="isNew || loading"
                    type="edit"
                    @click="editMain = !editMain"
                  >
                    {{ editMain ? 'отменить':'изменить' }}
                  </TwPanelContentBlockActionButton>
                </template>
                <div>
                  <LeadMainForm
                    v-if="editMain"
                    :id="data ? data.id : 0"
                    ref="EditMain"
                    :default-form-fields="defaultFormFields"
                    @create="onCreated"
                    @update="onUpdated"
                    @cancel="onCancelAndClose"
                  />
                  <LeadMainShow
                    v-else
                    :dictionary="dictionary"
                    :info="data"
                    @refresh="refresh"
                  />
                </div>
              </TwPanelContentBlock>
              <TwPanelContentBlock
                v-if="data && data.extId"
                class="tw-mb-[6px]"
              >
                <template #title>
                  <div>
                    Поставщик
                  </div>
                </template>
                <TwPanelContentBlockFieldView no-data-text="Нет примечания">
                  <template #title>
                    <div v-if="data.extService.value">
                      {{ data.extService.label }}
                    </div>
                  </template>
                  <div v-if="data.extService.value === 'bx'">
                    <LinkToBitrix
                      type="lead"
                      :bx-id="data.extId"
                    />
                  </div>
                  <div v-else>
                    ID: {{ data.extId }}
                  </div>
                </TwPanelContentBlockFieldView>
              </TwPanelContentBlock>
            </div>
            <div class="tw-flex-1">
              <TwPanelContentBlock
                v-if="data"
                :content-classes="''"
                class="tw-mb-[6px]"
              >
                <TwChronologyRoot
                  v-if="!isNew && data"
                  ref="chronology"
                  :entity-id="data.id"
                  :person-id="targetPersonId"
                  entity="lead"
                />
              </TwPanelContentBlock>
            </div>
          </TwPanelContent>
        </template>
        <template #content.integrations>
          <LeadIntegrationTab :info="data" />
        </template>
      </TwPanelTabsFull>
    </template>
    <!--
      Блок заполняющий пространство при отсутствуии контента
      для прижатия блока сохранения к нижней части страницы
    -->
    <div class="tw-flex tw-justify-center tw-items-center">
      <TwCircleLoader
        v-if="loading && !isCardContentVisible"
        width="50px"
        height="50px"
      />
      <div v-else-if="error">
        <PermissionDenied :text="error === 403 ? 'Недостаточно прав для просмотра!' : 'Ошибка соединения - Обратитесь к администратору!'" />
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/Api/Crm/Lead'
import LeadMainShow from '@/views/Lead/main/LeadMainShow'
import LeadMainForm from '@/views/Lead/main/LeadMainForm'
import CardMixin from '@/mixins/CardMixin'
import leadStatusProgress from '@/components/status/LeadStatusProgress'
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink'
import LinkToBitrix from '@/components/bitrix/LinkToBitrix'
import MenuEntityActions from '@/components/core/ViewPanel/Card/entity-actions/menuEntityActions'
import PermissionDenied from '@/views/PermissionDenied'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import TwPanelTitle from '@/components/tw-ui/panels/TwPanelTitle.vue'
import TwPanelContent from '@/components/tw-ui/panels/TwPanelContent.vue'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'
import TwPanelTabsFull from '@/components/tw-ui/panels/TwPanelTabsFull.vue'
import TwPanelActionButton from '@/components/tw-ui/panels/TwPanelActionButton.vue'
import LeadIntegrationTab from '@/views/Lead/integrations/LeadIntegrationTab.vue'
import TwPanelContentBlockActionButton from '@/components/tw-ui/panels/TwPanelContentBlockActionButton.vue'
import TwChronologyRoot from '@/components/core/Chronology/tw-dev/TwChronologyRoot.vue'

export default {
  name: 'LeadCard',
  components: {
    TwChronologyRoot,
    TwPanelContentBlockActionButton,
    LeadIntegrationTab,
    TwPanelActionButton,
    TwPanelTabsFull,
    TwPanelContentBlockFieldView,
    TwPanelContentBlock,
    TwPanelContent,
    TwPanelTitle,
    TwCircleLoader,
    PermissionDenied,
    MenuEntityActions,
    LinkToBitrix,
    ViewPanelLink,
    leadStatusProgress,
    LeadMainForm,
    LeadMainShow
  },
  mixins: [CardMixin, ViewPanelShowMixin],
  props: {
    personId: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      API: API,
      currentTab: 'main'
    }
  },
  computed: {
    tabList () {
      return [
        {
          label: 'Общая информация',
          key: 'main'
        },
        {
          label: 'Интеграции',
          key: 'integrations',
          disabled: this.isNew
        }
      ]
    },
    targetPersonId () {
      return this.data?.person?.id || null
    },
    cardCopyTitle () {
      let title = 'Лид'
      title += this.data.person ? ` от ${this.data.person.surname} ${this.data.person.name}` : ''
      title += this.data.id ? ` #${this.data.id}` : ''
      return this.data?.name || title
    },
    defaultFormFields () {
      return {
        personId: this.personId || null
      }
    }
  },
  watch: {
    loading (newValue) {
      if (!newValue && this.$refs?.chronology?.fetch) {
        this.$refs.chronology.fetch()
      }
    }
  },
  methods: {
    createDeal () {
      console.log('createDeal')
      if (this.$refs?.statusProgress?.createDealByLead) {
        this.$refs.statusProgress.createDealByLead()
      }
    },
    changeStatus () {
      this.$emit('callback', {})
      this.refresh()
    },
    showVariantSelection () {
      console.warn(this.$options.name, 'showVariantSelection', this.id, this.data?.id)
      this.showVariantSelectionCard({
        initialValues: {
          leadId: this.data?.id,
          personId: this.data?.person?.id
        },
        workToClose: true
      })
    }
  }
}
</script>
