function genItemSumText (item: any = {}) {
  if (!item.sumMin && !item.sumMin) {
    return 'СК: не указана'
  }
  return `СК: от ${(item.sumMin || 0) / 1000000} до ${(item.sumMax || 0) / 1000000} млн.р`
}

function genItemFirstPaymentText (item: any = {}) {
  if (!item.firstSumMin && !item.firstSumMax) {
    return 'ПВ: не указан'
  }
  return `ПВ: от ${item.firstSumMin || 0}% до ${item.firstSumMax || 0}%`
}

function isWarningFirstPayment (item: any = {}, restrictions: any = {}) {
  const restFirstSum = parseInt(restrictions?.firstSum)
  const restSum = parseInt(restrictions?.sum)
  const calcFirstSumPercent = parseFloat((restFirstSum && restSum ? restFirstSum * 100 / restSum : 0)?.toFixed(1))
  // Если не удалось посчитать процент
  if (isNaN(calcFirstSumPercent)) {
    return false
  }
  const firstSumMin = parseInt(item.firstSumMin)
  const firstSumMax = parseInt(item.firstSumMax)
  // Не указаны поля в элементе
  if (isNaN(firstSumMin) && isNaN(firstSumMax)) {
    return false
  }
  // если значение входит в разброс между мин и макс программы
  return Number(calcFirstSumPercent) < Number(firstSumMin) || Number(calcFirstSumPercent) > Number(firstSumMax)
}

function isWarningSum (item: any = {}, restrictions: any = {}) {
  const restSum = parseInt(restrictions?.sum)
  const restFirstSum = parseInt(restrictions?.firstSum)
  // Расчитываю сумму кредита
  const creditSum = restSum - restFirstSum
  // Если не удалось расчитать сумму кредита
  if (isNaN(creditSum)) {
    return false
  }
  const sumMin = parseInt(item.sumMin)
  const sumMax = parseInt(item.sumMax)
  // Не указаны поля в элементе
  if (isNaN(sumMin) && isNaN(sumMax)) {
    return false
  }
  // если значение входит в разброс между мин и макс программы
  return Number(creditSum) < Number(sumMin) || Number(creditSum) > Number(sumMax)
}

export {
  genItemSumText,
  genItemFirstPaymentText,
  isWarningFirstPayment,
  isWarningSum
}
