import { AxiosResponse } from 'axios'
import HTTP from '@/Api/http'
import { AuthAxiosDefaultConfig } from '@/Api/ObjectStorage'

const path = '/reform-zkh'

export default {
  searchHouse (houseUuid: string): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.get(`${path}/house/${houseUuid}`, $config)
  }
}
